import React, { Component } from "react";
import PropTypes from "prop-types";
import { Loader, Rating, Breadcrumb, Tab } from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import { Pane, Loading } from "shared/components/layouts";
import SlideImage from "./SlideImage";
import Config from "constants/Config";
import { StoreInfo } from "shared/components";
import { get } from "lodash";
import { Container } from "shared/components";
import { TradingHours } from "../common/trading-hours";
import Reviews from "./Reviews";
import Detail from "./Detail";
import BestMatched from "./BestMatched";
import Delivery from "./Delivery";
import NewsLetter from "./NewsLetter";
import { formatDecimal } from "shared/helpers";
import Video from "./Video";
import { Currency } from "shared/components";
import { StockDetailLabel } from "../../../../shared/components";
import ServiceOption from "./ServiceOption";

class ServiceDetail extends Component {
  static propTypes = {
    id: PropTypes.any,
    auth: PropTypes.object,
    detail: PropTypes.object,
    onRateService: PropTypes.func,
    intl: intlShape.isRequired,
    onGetServiceDetail: PropTypes.func,
    onReviewService: PropTypes.func,
    rateService: PropTypes.func,
  };

  state = {
    detail: {},
    added: false,
    adding: false,
    rating: 0,
    comment: "",
    loading: false,
    pageLoading: false,
    open: false,
    fetching: false,
    fetched: false,
    optionIds: [],
    children: {}
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.detail) {
      this.getOptionIds(nextProps.detail.data.options);
    }
  }

  includesOption = (id) => {
    return this.state.optionIds.find((child) => child.id === id);
  };

  getTotalPriceOptions = () => {
    let totalPirce = 0;

    if (
      !this.props.detail ||
      !this.props.detail.data.options ||
      !this.props.detail.data.options.length
    )
      return 0;

    this.props.detail.data.options.forEach((option) => {
      const { childrens } = option;

      childrens.forEach((children) => {
        const childIncludes = this.includesOption(children.id);
        if (childIncludes) {
          totalPirce +=
            Number(children.price || 0) * Number(childIncludes.quantity);
        }
      });
    });

    return totalPirce;
  };

  getOptionIds(options) {
    if (options && options.length) {
      const ids = [];
      let children = {};
      options.forEach((option) => {
        const { childrens } = option;
        children = childrens.find((child) => child.isCheck);
        ids.push(
          ...childrens
            .filter((child) => child.isCheck)
            .map((child) => ({ id: child.id, quantity: 1 }))
        );
      });

      if (children) this.setState({ children });

      this.setState({ optionIds: ids });
      return;
    }

    this.setState({ optionIds: [] });
  }

  getDetail = () => {
    this.setState({
      fetching: true,
      fetched: false,
    });
    this.props
      .onGetServiceDetail({ id: this.props.id })
      .then(() => {
        this.setState({
          fetching: false,
          fetched: true,
        });
      })
      .catch(() => {
        this.setState({
          fetching: false,
          fetched: true,
        });
      });
    this.getOptionIds(this.props.detail.data.options);
    this.props.onGetReviews({
      id: this.props.id,
      type: Config.SERVICE_TYPE,
    });
  };

  onRate = (e, { rating }) => {
    this.setState({ rating });
  };

  updateOptionQuantity = (id, quantity, type, item) => {
    if (type !== 3) return;
    let updateOptionIds = this.state.optionIds || [];
    const index = updateOptionIds.findIndex((item) => item.id === id);

    if (index === -1) return;
    const qty = updateOptionIds[index].quantity + quantity;
    if (qty <= 0) return;

    updateOptionIds[index].quantity = qty;
    this.setState({ optionIds: updateOptionIds });
  };

  updateOptionIds = (id, check, deleteIds = []) => {
    let updateOptionIds = this.state.optionIds || [];

    if (deleteIds && deleteIds.length) {
      updateOptionIds = updateOptionIds.filter(
        (item) => !deleteIds.includes(item.id)
      );
    }

    if (!check) {
      updateOptionIds = updateOptionIds.filter((item) => item.id !== id);
    } else {
      const updateOptionId = updateOptionIds.find((item) => item.id === id);
      if (!updateOptionId) {
        updateOptionIds.push({ id: id, quantity: 1 });
      }
    }

    this.setState({ optionIds: updateOptionIds });
  };

  onSubmit = () => {
    const { detail } = this.props;
    if (detail.data) {
      let data = detail.data;
      let booking = {
        id: data.id,
        name: data.name,
        originalPrice: data.originalPrice,
        promotion: data.promotion,
        duration: data.duration,
        userId: data.userId
      }
      if (this.state.children) booking.children = this.state.children
      this.props.addStoreBookingsRequest(booking);

      this.props.history.push(`/store-booking/${data.userId}`);
    }
  };

  componentDidMount() {
    this.getDetail();

    this._notificationSystem = this.refs.notificationSystem;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getDetail();
    }
  }

  renderTab = () => {
    const panes = [
      {
        menuItem: "Details",
        render: () => (
          <Tab.Pane attached={false}>
            <Detail detail={this.props.detail.data.description} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "VIDEOS",
        render: () => (
          <Tab.Pane attached={false}>
            <Video detail={this.props.detail.data} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "REVIEWRS",
        render: () => (
          <Tab.Pane attached={false}>
            <Reviews
              onReviewService={this.props.onReviewService}
              onGetServiceDetail={this.props.onGetServiceDetail}
              onGetServiceReview={this.props.onReviewService}
              onNotification={this._notificationSystem}
              id={this.props.id}
              reviews={this.props.reviews}
            />
          </Tab.Pane>
        ),
      },
    ];
    return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
  };

  render() {
    const { detail, id } = this.props;
    const { fetched, fetching } = this.state;
    return (
      <>
        {!fetching && fetched ? (
          <Container data={detail && detail.data}>
            <Pane className="breadcrumb-container">
              <Pane className="container">
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Breadcrumb className="breadcrumb-list">
                      <Breadcrumb.Section className="item" href={`/`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/details/home.svg"
                          }
                          alt="home"
                          className="icon"
                        />
                      </Breadcrumb.Section>
                      <Breadcrumb.Section
                        className="item"
                        href={`/services?category=${detail.data.categoryId}`}
                      >
                        {detail.data.categoryName}
                      </Breadcrumb.Section>
                      <Breadcrumb.Section className="item active" active>
                        <span className="item-norm">
                          T-
                          {detail.data.name}
                        </span>
                      </Breadcrumb.Section>
                    </Breadcrumb>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
            <Pane className="details-container">
              <Pane className="container">
                <Pane className="row">
                  <Pane className="col-lg-9 col-md-12 col-sm-12">
                    <Pane className="category-details mb-5">
                      <Pane className="row">
                        <Pane className="col-lg-6">
                          <SlideImage
                            detail={detail.data}
                            id={id}
                            optionIds={this.state.optionIds || []}
                            onSubmit={this.onSubmit}
                          />
                          <Pane className="category-content">
                            {/* <h5 className="category-type">
                              CATEGORY:
                              <span className="type">
                                {detail.data.categoryName}
                              </span>
                            </h5> */}
                            <h2 className="category-title mt-3">
                              {detail.data.name}
                            </h2>
                            {/* <Rating
                              rating={detail.data.averageRating}
                              icon="star"
                              size="huge"
                              maxRating="5"
                              disabled
                            /> */}
                            {get(detail, "data.promotion.percent") ? (
                              <>
                                <h4 className="category-price mt-3">
                                  <Currency
                                    price={formatDecimal(
                                      get(detail, "data.originalPrice") -
                                        (get(detail, "data.originalPrice") *
                                          get(
                                            detail,
                                            "data.promotion.percent"
                                          )) /
                                          100
                                    )}
                                  />
                                </h4>
                                <h4 className="category-price text-underline">
                                  <FormattedMessage
                                    id="was"
                                    defaultMessage="was"
                                  />
                                  <Currency
                                    price={formatDecimal(
                                      get(detail, "data.originalPrice")
                                    )}
                                  />
                                </h4>
                              </>
                            ) : (
                              <h4 className="category-price category-price-red  mt-3">
                                <Currency
                                  price={formatDecimal(
                                    Number(detail.data.originalPrice) +
                                      this.getTotalPriceOptions()
                                  )}
                                />
                              </h4>
                            )}
                            {/* <StockDetailLabel
                              inStock={detail.data.inventoryStatus}
                              type="services"
                            /> */}
                          </Pane>
                        </Pane>
                        <Pane className="col-lg-6">
                          <Pane className="comment-container comment-container-desktop mb-3">
                            {this.renderTab()}
                          </Pane>
                        </Pane>
                      </Pane>
                      <Pane className="row">
                        <Pane className="col-12">
                          <ServiceOption
                            updateOptionQuantity={this.updateOptionQuantity}
                            updateOptionIds={this.updateOptionIds}
                            optionIds={this.state.optionIds}
                            options={this.props.detail.data.options}
                          />
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                  <Pane className="col-lg-3 col-md-12 col-sm-12">
                    {detail.data.userId && (
                      <Pane className="bussiness-reponsive">
                        <StoreInfo
                          auth={this.props.auth}
                          detail={detail.data}
                        />
                      </Pane>
                    )}
                    <Pane className="inner-box mt-4">
                      <h2 className="inner-title">Duration</h2>
                      <Pane className="bussiness-content">
                        <ul className="bussiness-list">
                          <li className="bussiness-item">
                            <span className="category-norm">
                              {detail.data.duration}
                            </span>
                          </li>
                        </ul>
                      </Pane>
                    </Pane>
                    <Pane className="inner-box mt-4">
                      <h2 className="inner-title">
                        <FormattedMessage
                          id="trading_hours"
                          defaultMessage="Trading Hours"
                        />{" "}
                      </h2>
                      {/* <Pane className="bussiness-content"> */}
                      <TradingHours tradingHours={detail.data.tradingHours} />
                      {/* </Pane> */}
                    </Pane>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-9 col-md-12">
                    <Pane className="comment-container comment-container-responsive mb-3">
                      {this.renderTab()}
                    </Pane>
                  </Pane>
                </Pane>
                {/* <BestMatched
                  onNotification={this._notificationSystem}
                  categoryId={detail.data.categoryId}
                /> */}
              </Pane>
            </Pane>
            <Delivery />
            <NewsLetter />
          </Container>
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}

        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

export default injectIntl(withRouter(ServiceDetail));
