import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { MasterLayout } from 'components/layout'
import { getBanners } from 'actions/banner'
import { LearnMore } from 'components/scenes/learn-more'

class HomePage extends Component {
  static propTypes = {
    slides: PropTypes.object,
    getSlides: PropTypes.func
  }

  render() {
    return (
      <MasterLayout>
        <LearnMore />
      </MasterLayout>
    );
  }
}

const mapStateToProps = state => ({
  slides: state.banners
})

const mapDispatchToProps = dispatch => ({
  getSlides: bindActionCreators(getBanners, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
