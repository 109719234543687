import React, { Component } from 'react'
import { Pane } from 'shared/components/layouts'
import ProductItem from './ProductItem'

class ProductList extends Component {
  onSelectItem = (id) => {
    this.props.onSelectItem(id)
  }

  render() {
    const productList = this.props.products && this.props.products.map(item => {
      return (
        <ProductItem
          key={item.id}
          id={item.id}
          name={item.name}
          image={item.primaryImage}
          price={item.originalPrice}
          onClick={this.onSelectItem.bind(this, item)}
          itemsSelected={this.props.itemsSelected}
        />
      )
    })

    return (
      <Pane className='category-list'>
        <Pane className='adds-wrapper'>
          { productList }
        </Pane>
      </Pane>
    );
  }
}

export default ProductList;
