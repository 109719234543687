import Config from "constants/Config";
import { formatJson } from "shared/helpers";
const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const id = params.id || "";
  const name = params.name || "";
  return {
    method: "GET",
    url: `task?user_id=${id}&name=*${name}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};
const parseResponse = (response) => {
  const tasks = [];
  if (response.data) {
    response.data.forEach((item) => {
      const data = formatJson(response, item.type, item.id);
      tasks.push(data);
    });
  }
  const data = tasks;
  return data;
};
export { exec, parseResponse };