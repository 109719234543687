
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { Pane } from 'shared/components/layouts';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class ShowMap extends Component {
    static propTypes = {
        address: PropTypes.string,
      };
    static defaultProps = {
        center: {
          lat: 59.95,
          lng: 30.33
        },
        zoom: 11
    };
    render() {
        return (
            <Pane>
                <Pane style={{ height: '50vh', width: '100vh' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyB6YzsFhHPJpXuJC5SriCINfxOMUUGjxUg' }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Location"
                    />
                </GoogleMapReact>
                </Pane>
            </Pane>
        );
    }
}
     
export default ShowMap;