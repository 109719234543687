import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Icon } from 'semantic-ui-react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { formatDecimal } from 'shared/helpers';

class BookedServices extends Component {

  onChangeRoute = (id) => {
    this.props.history.push(`bookings/${id}/detail`);
  }

  render() {
    const bookingList = this.props.bookedServices.results.map((item) => {
      return (
        <Table.Row className="cursor-pointer" key={item.id} onClick={() => this.onChangeRoute(item.id)}>
          <Table.Cell>{item.id}</Table.Cell>
          <Table.Cell>{item.serviceName}</Table.Cell>
          <Table.Cell>
            <b>{moment.utc(item.date).format('DD/MM/YYYY')}</b>
          </Table.Cell>
          <Table.Cell>AUD ${formatDecimal(item.total)}</Table.Cell>
          <Table.Cell>{item.paymentMethod}</Table.Cell>
          <Table.Cell>
            <span className={`badge badge-${item.status.toLowerCase()}`}>
              {item.status}
            </span>
          </Table.Cell>

          <Table.Cell>
            <Icon name="eye" />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table className="unstackable" singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="service_name"
                defaultMessage="Service name"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="appointment_date"
                defaultMessage="Appointment date"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="total" defaultMessage="Total" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="payment_method"
                defaultMessage="Payment method"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="status" defaultMessage="Status" />
            </Table.HeaderCell>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{bookingList}</Table.Body>
      </Table>
    );
  }
}

export default withRouter(BookedServices);
