import * as types from 'constants/ActionTypes'
const initialState = {
    results: [],
}

const posCart = (state = initialState, action) => {
    switch (action.type) {
        case types.RECEIVE_POS_CART:
            state.posCart = action.posCart;
            return state;
        case types.DELETE_POS_CART:
            let { productId } = action
            let index = state.results.findIndex(x => x.productId == productId);
            state.results.splice(index, 1);
            return { ...state };
        case types.ADD_POS_CART:
            let { item } = action
            state.results.push(item);
            return { ...state };
        case types.UPDATE_POS_CART:
            let carts = state.results
            index = carts.findIndex(item => item.productId == action.payload.productId)
            if (action.payload.isQuantity) {
                carts[index].quantity = action.payload.data
            } else {
                carts[index].optionInfo = action.payload.data
            }
            state.results = carts;
            return { ...state}
        case types.RESET_POS_CART:
            state.results = []
            return { ...state };
        default:
            return state
    }
}

export default posCart;