import Config from "constants/Config";
import { formatJson, formatRoute } from "shared/helpers";

const exec = (params) => {
  return {
    method: "GET",
    url: `/services/${params.id}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const service = formatJson(response, response.data.type, response.data.id);

  let promotion = {};
  let user = {};

  if (service.promotion) {
    promotion = {
      id: service.promotion.id,
      percent: Number(service.promotion.percent),
      startDate: service.promotion.startDate,
      endDate: service.promotion.endDate,
      primaryImage:
        service.promotion.primaryImage && service.promotion.primaryImage.url,
    };
  }

  if (service.user) {
    user = {
      id: service.user.id,
      name: service.user.fullname,
      // slug: formatRoute(service.name, '-'),
      slug: formatRoute(
        service.user && service.user.business && service.user.business.name,
        "-"
      ),
      email: service.user.email,
      address: service.user.address,
      phone: service.user.phone,
      profileImage: service.user.image && service.user.image.url,
      backgroundImage:
        service.user.backgroundImage && service.user.backgroundImage.url,
      qrcode: service.user.qrcode && service.user.qrcode.url,
      business: Object.assign({}, service.user.business),
    };

    delete user.business.user;
  }

  const options = [];

  (service.options || []).forEach((option) => {
    const group = option.optionGroup || {};
    const index = options.findIndex((option) => option.id === group.id);
    if (index === -1) {
      options.push({
        id: group.id,
        name: group.name,
        type: group.type,
        autoAddOptions: false,
        dpOrdSeq: group.dpOrdSeq,
        delt: "N",
        childrens: [
          {
            id: option.id,
            name: option.name,
            dpOrdSeq: option.dpOrdSeq,
            price: Number(option.price),
            delt: "N",
            autoAddOptions: false,
            isCheck: !!option.isCheck,
          },
        ],
      });
    } else {
      options[index].childrens.push({
        id: option.id,
        name: option.name,
        dpOrdSeq: option.dpOrdSeq,
        price: Number(option.price),
        delt: "N",
        autoAddOptions: false,
        isCheck: !!option.isCheck,
      });
    }

    return options;
  });

  options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
  options.forEach((option) =>
    option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq)
  );

  const data = {
    id: service.id,
    name: service.name,
    originalPrice: service.price,
    primaryImage: service.primaryImage && service.primaryImage.url,
    subImage1: service.subImage1 && service.subImage1.url,
    subImage2: service.subImage2 && service.subImage2.url,
    subImage3: service.subImage3 && service.subImage3.url,
    subImage4: service.subImage4 && service.subImage4.url,
    subImage5: service.subImage5 && service.subImage5.url,
    slug: `${formatRoute(service.name, "-")}-${service.id}`,
    description: service.description,
    averageRating:
      service.statistic && service.statistic.rating
        ? service.statistic.rating
        : 0,
    tradingHours: service.tradingHours,
    duration: service.duration,
    userId: user && user.id,
    user,
    categoryId: service.category && service.category.id,
    categoryName: service.category && service.category.name,
    inventoryStatus: service.inventoryStatus,
    promotion,
    options,
  };

  return data;
};

export { exec, parseResponse };
