import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import ReactPaginate from 'react-paginate';
import { Pane, Loading } from 'shared/components/layouts';
import { getBookedServices, getBookings } from 'actions/bookings';
import { getServicesByUserId } from 'actions/myServices';
import BookedServices from './buyers/BookedServices';
import BookingList from './sellers/BookingList';

class Bookings extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    services: PropTypes.object,
    getBookedServices: PropTypes.func,
  };

  getBookedServices = (params) => {
    this.props.getBookedServices(params);
  };

  handleBookedServicePageClick = (data) => {
    const params = {
      userId: this.props.userInfo.id,
      page: data.selected + 1,
    };

    this.props.getServicesByUserId(params, true);
  };

  handlePageClick = (data) => {
    const params = {
      id: this.props.userInfo.id,
      page: data.selected + 1,
    };

    this.props.getServicesByUserId(params, true);
  };

  componentDidMount() {
    const params = {
      userId: this.props.userInfo.id,
      page: 1,
    };

    this.getBookedServices(params);
    this.props.getBookings({ userId: this.props.userInfo.id });

    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    const { bookedServices, bookingsForSeller, userInfo } = this.props;

    return (
      <Pane className="overview-container" id="anchor">
        {userInfo.business.is_active === 1 && (
          <Pane className="product-container order-container">
            <h3 className="block-title pt10 text-uppercase">
              <FormattedMessage
                id="manage_your_services"
                defaultMessage="Manage your serivces"
              />
            </h3>
            <Pane className="products-grids overflow-auto">
              {!bookingsForSeller.fetched && (
                <Dimmer active inverted>
                  <Loading />
                </Dimmer>
              )}
              <BookingList
                data={bookingsForSeller.results}
                notification={this._notificationSystem}
              />
              {bookingsForSeller.fetched &&
                !bookingsForSeller.fetching &&
                bookingsForSeller.results &&
                bookingsForSeller.results.length <= 0 && (
                  <Pane className="block-no-content">
                    <p>
                      <FormattedMessage
                        id="notification.you_have_not_had_any_services_yet"
                        defaultMessage={`You haven't had any services yet.`}
                      />
                    </p>
                  </Pane>
                )}
              <Pane className="clearfix" />
            </Pane>
            <Pane className="pagination-bar text-center">
              <nav className="d-inline-b">
                {bookingsForSeller.totalPages > 1 ? (
                  <ReactPaginate
                    previousLabel="❮"
                    nextLabel="❯"
                    breakLabel={<button className="btn-break-paging">...</button>}
                    breakClassName={'break-me'}
                    previousClassName={'page-item'}
                    nextClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    pageCount={bookingsForSeller.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    disableInitialCallback={true}
                  />
                ) : null}
              </nav>
            </Pane>
          </Pane>
        )}
        <Pane className="product-container order-container">
          <h3 className="block-title pt10 text-uppercase">
            <FormattedMessage
              id="booking_management"
              defaultMessage="Booking management"
            />
          </h3>
          <Pane className="products-grids overflow-auto">
            {!bookedServices.fetched && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            <BookedServices
              userInfo={userInfo}
              bookedServices={bookedServices}
            />
            {bookedServices.fetched &&
              !bookedServices.fetching &&
              bookedServices.results &&
              bookedServices.results.length <= 0 && (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.you_have_not_booked_any_services_yet"
                      defaultMessage={`You haven't booked any services yet.`}
                    />
                  </p>
                </Pane>
              )}
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {bookedServices.totalPages > 1 ? (
                <ReactPaginate
                  previousLabel="❮"
                  nextLabel="❯"
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={'break-me'}
                  previousClassName={'page-item'}
                  nextClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  pageCount={bookedServices.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              ) : null}
            </nav>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  bookedServices: state.bookedServices,
  bookingsForSeller: state.bookingsForSeller,
});

const bindDispatchToProps = (dispatch) => ({
  getBookings: bindActionCreators(getBookings, dispatch),
  getBookedServices: bindActionCreators(getBookedServices, dispatch),
  getServicesByUserId: bindActionCreators(getServicesByUserId, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(Bookings);
