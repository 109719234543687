import Config from 'constants/Config'

const exec = params => {
  return {
    method: 'POST',
    url: `/auth/${params.provider}`,
    baseURL: Config.NEW_API_URL,
    data: params
  }
}

export { exec }
