import React, { Component } from 'react';
import { Pane, Loading } from "shared/components/layouts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { LoadingSearch } from "components/common/loading";
import { getDigitalsShareByUserId } from 'actions/digitalShare';
import { getNotisByUser } from 'actions/notis';
import UserAdapter from "services/users/adapter";
import { List } from "./list"

class DigitalsShare extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      items: []
    };
  }

  componentDidMount() {
    this.getDigitalsShare();

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  getDigitalsShare = () => {
    this.setState({ loading: true });
    this.props.getDigitalsShareByUserId({
      user_id: this.props.userInfo.id,
    }).then((response) => {
      const data = UserAdapter.digitalsShareBrowse.parseResponse(response.data);
      if (data.length > 0 && data[0].updateRead) {
        this.props.getNotisByUser({ id: this.props.userInfo.id });
      }
      this.setState({ loading: false, items: data });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { loading, items } = this.state;
    return (
      <Pane className="row" id="anchor">
        <Pane className="product-container service-container col-12">
          <Pane className="product-heading">
            <h3 className="block-title pt10 mt-0 mr-0 text-uppercase title-width-header">
              <FormattedMessage id="your_requested" defaultMessage="Your requested" />
            </h3>
          </Pane>
          {!loading ? (
              <>
                {items.length > 0 ? (
                  <List items={items} />
                ) : (
                  <Pane className="text-center mt-5">
                    <FormattedMessage
                      id="notification.you_have_not_requested_any_digital_shares_yet"
                      defaultMessage={`You haven't requested any digital shares yet.`}
                    />
                  </Pane>
                )}
              </>
            ) : (
              <LoadingSearch type="white" />
            )}
        </Pane>
      </Pane>
    );
  }
}


const bindStateToProps = (state) => ({
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getNotisByUser: bindActionCreators(getNotisByUser, dispatch),
  getDigitalsShareByUserId: bindActionCreators(getDigitalsShareByUserId, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(DigitalsShare));