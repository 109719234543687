import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { InlineMessage } from 'shared/components';
import { handleError } from 'shared/helpers';
import NotificationSystem from 'react-notification-system';

class ResetPasswordForm extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  state = {
    data: {
      email: '',
      password: '',
      confirmPassword: '',
      token: '',
    },
    loading: false,
    redirect: false,
    errors: {},
  };

  _notificationSystem = null;

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (
      !queryString.parse(this.props.location.search).email ||
      !queryString.parse(this.props.location.search).token
    ) {
      errors.message = intl.formatMessage({
        id: 'notification.please_try_again',
      });
    }
    if (!data.password) {
      errors.password = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (
      data.password &&
      (data.password.length < 6 ||
        data.password.search(/[0-9]/) < 0 ||
        data.password.search(/[A-Z]/) < 0 ||
        data.password.search(/[a-z]/) < 0)
    ) {
      errors.password = intl.formatMessage({ id: 'validate.invalid_password' });
    }
    if (!data.confirmPassword) {
      errors.confirmPassword = intl.formatMessage({
        id: 'validate.require_field',
      });
    }
    if (data.confirmPassword !== data.password) {
      errors.confirmPassword = intl.formatMessage({
        id: 'validate.invalid_confirm_password',
      });
    }

    return errors;
  };

  onSubmit = () => {
    let errors = {};
    const { intl } = this.props;

    errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = {
        email: queryString.parse(this.props.location.search).email,
        password: this.state.data.password,
        token: queryString.parse(this.props.location.search).token,
      };

      this.props
        .resetPassword(data)
        .then((res) => {
          this.setState({ loading: false });
          this.clearData();
          this._notificationSystem.addNotification({
            message: intl.formatMessage({ id: 'notification.successfully' }),
            level: 'success',
            onRemove: () => {
              this.props.history.push('/signin');
            },
          });
        })
        .catch((errors) => {
          this.setState({ loading: false });
          this._notificationSystem.addNotification({
            message: handleError(errors, errors.response.statusText),
            level: 'error',
          });
        });
    }
  };

  clearData = () => {
    this.setState({
      data: {
        password: '',
        confirmPassword: '',
      },
    });
  };

  render() {
    const { data, loading, errors } = this.state;

    return (
      <Form onSubmit={this.onSubmit} noValidate>
        <Form.Field error={!!errors.email} className="form-group">
          <label htmlFor="newPassword">
            <FormattedMessage id="new_password" defaultMessage="New password" />
          </label>
          <input
            type="password"
            id="newPassword"
            name="password"
            value={data.password}
            className="form-control"
            onChange={this.onChange}
          />
          {errors.password && <InlineMessage text={errors.password} />}
        </Form.Field>
        <Form.Field error={!!errors.password} className="form-group">
          <span className="form-note">
            <FormattedMessage
              id="notification.require_character"
              defaultMessage="Your password must be at least 6 characters with a minimum of 1 uppercase character and a numeric character."
            />
          </span>
        </Form.Field>
        <Form.Field error={!!errors.email} className="form-group">
          <label htmlFor="confirmPassword">
            <FormattedMessage
              id="confirm_new_password"
              defaultMessage="Confirm new password"
            />
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={data.confirmPassword}
            className="form-control"
            onChange={this.onChange}
          />
          {errors.confirmPassword && (
            <InlineMessage text={errors.confirmPassword} />
          )}
        </Form.Field>
        <Form.Field className="form-group">
          {errors.message && (
            <InlineMessage text={errors.message} className="col-md-8" />
          )}
        </Form.Field>
        <Form.Field className="form-group">
          <Button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={loading}
            loading={loading}
          >
            <FormattedMessage id="confirm" defaultMessage="Confirm" />
          </Button>
        </Form.Field>
        <NotificationSystem ref="notificationSystem" />
      </Form>
    );
  }
}

export default withRouter(injectIntl(ResetPasswordForm));
