import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';

class Business extends Component {
  render() {
    return (
      <Pane className="business-container mt-5 mb-5">
        <Pane className="container">
          <Pane className="row align-items-center mt-5 mb-5 pt-4 pb-4">
            <Pane className="col-lg-5">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInLeft"
              >
                <Pane className="business-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/about/mission.png'
                    }
                    alt="mission"
                    className="img"
                  />
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-7">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInRight"
              >
                <Pane className="business-content">
                  <h3 className="business-title">
                    <FormattedMessage
                      id="our_mission"
                      defaultMessage="OUR MISSION"
                    />
                  </h3>
                  <h3 className="business-title-main">
                    <FormattedMessage
                      id="making_commerce_better_for_everyone"
                      defaultMessage="Making commerce better for everyone"
                    />
                  </h3>
                  <p className="business-norm">
                    <FormattedMessage
                      id="cardbey_was_founded"
                      defaultMessage="Cardbey was founded in 2015 in Melbourne"
                    />
                  </p>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
          <Pane className="row flex-lg-row flex-column-reverse business-our mt-5 mb-5 pt-4 pb-4">
            <Pane className="col-lg-7">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInLeft"
              >
                <Pane className="business-content">
                  <h3 className="business-title">
                    <FormattedMessage
                      id="our_purpose"
                      defaultMessage="OUR PURPOSE"
                    />
                  </h3>
                  <p className="business-norm">
                    <FormattedMessage
                      id="we_believe_in_the_transformative"
                      defaultMessage=" We believe in the transformative power of technology to
                      allow everyone to start, run, and grow their own business.
                      We work to connect buyers and sellers within one strong
                      community."
                    />
                  </p>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-5">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInRight"
              >
                <Pane className="business-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/about/purpose.png'
                    }
                    alt="mission"
                    className="img"
                  />
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
          <Pane className="row business-our business-our-comnunity mt-5 mb-5 pt-4 pb-4">
            <Pane className="col-lg-5">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInLeft"
              >
                <Pane className="business-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/about/community.png'
                    }
                    alt="mission"
                    className="img"
                  />
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-7">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInRight"
              >
                <Pane className="business-content">
                  <h3 className="business-title">
                    <FormattedMessage
                      id="our_community"
                      defaultMessage="Our community"
                    />
                  </h3>
                  <p className="business-norm">
                    We work to serve SMEs all over the world. Practically
                    anybody who has products/services to sell and who are
                    interested in reaching new and more customers. Customer
                    satisfaction is of paramount importance to Cardbey and we
                    always aim for the highest customer satisfaction.
                  </p>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default Business;
