import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Image, Icon, Tab } from 'semantic-ui-react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import moment from 'moment'
import Datetime from 'react-datetime'
import { Pane } from 'shared/components/layouts'
import InlineMessage from 'shared/components/InlineMessage'
import MyProducts from '../MyProducts'
import MyServices from '../MyServices'
import { formatRoute } from 'shared/helpers'
import Config from 'constants/Config'

class CreatePromotionForm extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    getAllPromotions: PropTypes.func.isRequired,
    createPromotion: PropTypes.func.isRequired,
    intl: intlShape.isRequired
  }

  state = {
    userInfo: {},
    image: '',
    imageFile: '',
    data: {
      title: '',
      image: '',
      percentDiscount: 0,
      description: '',
      items: []
    },
    startDate: '',
    endDate: '',
    errors: {},
    loading: false,
    open: false
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    })
  }

  onChangeAvatar = (e) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        this.setState({
          image: reader.result,
          imageFile: file
        })
      }
      reader.readAsDataURL(file)
    }
  }

  onChangeTime(name, value) {
    this.setState({
      [name]: value.format('YYYY-MM-DD')
    })
  }

  toggleTab = () => {
    this.setState({
      open: !this.state.open
    })
  }

  validate = (data) => {
    const errors = {}
    const { intl } = this.props

    if (!data.title) {
      errors.title = intl.formatMessage({ id: 'validate.require_field' })
    }
    if (!data.items || data.items.length <= 0) {
      errors.listProduct = intl.formatMessage({ id: 'validate.choose_products_or_services' })
    }
    if (!this.state.startDate) {
      errors.startDate = intl.formatMessage({ id: 'validate.require_field' })
    }
    if (!this.state.endDate) {
      errors.endDate = intl.formatMessage({ id: 'validate.require_field' })
    }
    if (moment(this.state.startDate, 'DD/MM/YYYY').unix() > moment(this.state.endDate, 'DD/MM/YYYY').unix()) {
      errors.startDate = intl.formatMessage({ id: 'validate.invalid_compare_date' })
    }

    return errors
  }

  onSelectItem = (item) => {
    const array = this.state.data.items
    const index = array.findIndex(arrItem => arrItem.id === item.id)
    if (index === -1) {
      array.push(item)
    } else {
      array.splice(index, 1)
    }

    this.setState({
      data: {
        ...this.state.data,
        items: array
      }
    })
  }

  createPromotion = () => {
    const errors = this.validate(this.state.data)
    const { intl } = this.props
    const name = this.props.userInfo && this.props.userInfo.displayName ? formatRoute(this.props.userInfo.displayName) : ''
    const products = []
    const services = []

    this.state.data.items.forEach(item => {
      if (item.type === Config.PRODUCT_TYPE) {
        products.push(item.id)
      }
      if (item.type === Config.SERVICE_TYPE) {
        services.push(item.id)
      }
    })

    this.setState({ errors })
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })

      const data = new FormData()
      data.append('primary_image', this.state.imageFile)
      data.append('percent', this.state.data.percentDiscount)
      products.length > 0 && data.append('product_id', products)
      services.length > 0 && data.append('service_id', services)
      data.append('description', this.state.data.description)
      data.append('start_date', this.state.startDate)
      data.append('end_date', this.state.endDate)

      this.props.createPromotion(data).then(response => {
        this.setState({
          loading: false,
          open: false,
          image: '',
          imageFile: '',
          data: {
            ...this.state.data,
            percentDiscount: 0,
            items: [],
            description: '',
          },
          startDate: '',
          endDate: ''
        })
        // this.props.getAllPromotions()

        this.props.history.push(`/u/${name}/promotions`)
      }).catch(() => {
        errors.message = intl.formatMessage({ id: 'notification.please_try_again' })
        this.setState({
          loading: false
        })
      })
    }

  }

  removeImage = () => {
    this.setState({
      image: '',
      imageFile: ''
    })
  }

  render() {
    const { data, errors, loading } = this.state
    const { intl } = this.props

    const panes = [
      { menuItem: intl.formatMessage({ id: 'my_products' }), render: () => <Tab.Pane attached={false}><MyProducts userId={this.props.userInfo.id} onSelectItem={this.onSelectItem} itemsSelected={this.state.data.items} /></Tab.Pane> },
      { menuItem: intl.formatMessage({ id: 'my_services' }), render: () => <Tab.Pane attached={false}><MyServices userId={this.props.userInfo.id} onSelectItem={this.onSelectItem} itemsSelected={this.state.data.items} /></Tab.Pane> }
    ]

    const yesterday = Datetime.moment().subtract(1, 'day')
    const valid = (current) => {
      return current.isAfter(yesterday)
    }

    return (
      <Form onSubmit={this.createPromotion} autoComplete="off">
        <Button color="orange" onClick={this.toggleTab} type='button'><Icon name="plus" /> <FormattedMessage id='notification.select_your_products_and_services' defaultMessage='Select your products and services' /></Button>
        {
          this.state.data.items.length > 0
          ? ` ${this.state.data.items.length} ${this.state.data.items.length > 1 ? 'items' : 'item'} selected`
          : null
        }
        {
          this.state.open &&
          <Tab className='tab-choose-data' menu={{ secondary: true, pointing: true }} panes={panes} />
        }
        { errors.listProduct && <InlineMessage text={errors.listProduct} /> }
        <Form.Field className='form-group'>
          <label htmlFor="image" className="col-form-label"><FormattedMessage id='promotion_banner' defaultMessage='Promotion Banner' /></label>
          <input
            type="file"
            id="image"
            name="image"
            placeholder=""
            className="form-control"
            value={data.image}
            onChange={this.onChangeAvatar}
          />
          {
            this.state.image &&
            <Pane className='slider-content' style={{ padding: '10px 0' }}>
              <Pane className='slide-item' style={{ float: 'left', padding: '0 5px' }}>
                <Icon name='remove circle' onClick={this.removeImage.bind(this)} />
                {/* <Image src={this.state.image} size='small' /> */}
                {
                  this.state.image.indexOf('video') < 0 ? // Image
                    <Image src={this.state.image} size="small" />
                    : // Video
                        <video width="150" controls >
                          <source src={this.state.image} id="video_here"/>
                            Your browser does not support HTML5 video.
                        </video>

                }
              </Pane>
            </Pane>
          }
        </Form.Field>
        <Pane className='clearfix' />
        <Form.Field error={!!errors.title} className='form-group'>
          <label htmlFor="email" className="col-form-label"><FormattedMessage id='promotion_name' defaultMessage='Promotion Name' /></label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder=""
            className="form-control"
            value={data.title}
            onChange={this.onChange}
          />
          { errors.title && <InlineMessage text={errors.title} /> }
        </Form.Field>
        <Form.Field error={!!errors.startDate} className='form-group'>
          <label htmlFor="openTime" className="col-form-label"><FormattedMessage id='start_date' defaultMessage='Start date' /></label>
          <Datetime
            closeOnSelect={true}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            onChange={this.onChangeTime.bind(this, 'startDate')}
            inputProps={{ 'placeholder': 'DD/MM/YYYY', 'name': 'startDate', readOnly: true }}
            isValidDate={valid}
            value={this.state.startDate}
          />
          { errors.startDate && <InlineMessage text={errors.startDate} /> }
        </Form.Field>
        <Form.Field error={!!errors.endDate} className='form-group'>
          <label htmlFor="closeTime" className="col-form-label"><FormattedMessage id='end_date' defaultMessage='End date' /></label>
          <Datetime
            closeOnSelect={true}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            onChange={this.onChangeTime.bind(this, 'endDate')}
            inputProps={{ 'placeholder': 'DD/MM/YYYY', 'name': 'endDate', readOnly: true }}
            isValidDate={valid}
            value={this.state.endDate}
          />
          { errors.endDate && <InlineMessage text={errors.endDate} /> }
        </Form.Field>
        <Form.Field error={!!errors.percentDiscount} className='form-group'>
          <label htmlFor="email" className="col-form-label"><FormattedMessage id='percent_discount' defaultMessage='Percent Discount' /> (%)</label>
          <input
            type="number"
            id="percentDiscount"
            name="percentDiscount"
            placeholder=""
            className="form-control"
            value={data.percentDiscount}
            onChange={this.onChange}
          />
          { errors.percentDiscount && <InlineMessage text={errors.percentDiscount} /> }
        </Form.Field>
        <Form.Field error={!!errors.description} className='form-group'>
          <label htmlFor="email" className="col-form-label"><FormattedMessage id='description' defaultMessage='Description' /></label>
          <input
            type="text"
            id="description"
            name="description"
            placeholder=""
            className="form-control"
            value={data.description}
            onChange={this.onChange}
          />
          { errors.description && <InlineMessage text={errors.description} /> }
        </Form.Field>
        { errors.message && <InlineMessage text={errors.message} /> }
        <Button type="submit" className="submit" color="yellow" disabled={loading} loading={loading} fluid>
          <FormattedMessage id='create' defaultMessage='Create' />
        </Button>
      </Form>
    )
  }
}

export default injectIntl(CreatePromotionForm)
