import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { getProductDetail, reviewProduct , getOptionsByProduct} from 'actions/products';
import { getReviews } from 'actions/reviews';
import { addItemToCart } from 'actions/cart';
import { ProductDetail } from 'components/scenes/products/detail';
import { getIdFromSlug } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';
import BussinessList from 'components/common/bussiness/BussinessList';
import { signin, signinSocial } from 'actions/auth';

class ProductDetailPage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    getProductDetail: PropTypes.func,
    addItemToCart: PropTypes.func,
    signin: PropTypes.func.isRequired,
    signinSocial: PropTypes.func.isRequired,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <BussinessList />
          <ProductDetail
            auth={this.props.auth}
            cart={this.props.cart}
            reviews={this.props.reviews}
            options={this.props.options}
            detail={this.props.productDetail}
            onAddItemToCart={this.props.addItemToCart}
            onReviewProduct={this.props.reviewProduct}
            onGetProductReview={this.props.getReviews}
            id={getIdFromSlug(this.props.match.params.id)}
            onGetProductDetail={this.props.getProductDetail}
            onGetOptionsByProduct={this.props.getOptionsByProduct}
            signin={this.props.signin}
            signinSocial={this.props.signinSocial}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  productDetail: state.productDetail,
  reviews: state.reviews,
  options: state.options,
  cart: state.cart,
});

const bindDispatchToProps = (dispatch) => ({
  getProductDetail: bindActionCreators(getProductDetail, dispatch),
  addItemToCart: bindActionCreators(addItemToCart, dispatch),
  reviewProduct: bindActionCreators(reviewProduct, dispatch),
  getReviews: bindActionCreators(getReviews, dispatch),
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
  getOptionsByProduct: bindActionCreators(getOptionsByProduct, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(ProductDetailPage);
