import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import OrderedCartItem from "./OrderedCartItem";
import { formatDecimal } from "shared/helpers";
import Config from "constants/Config";
import { Pane } from 'shared/components/layouts';

class OrderedCartList extends Component {
  static propTypes = {
    detail: PropTypes.object,
  };


  isDineIn = (detail) => {
    if (detail.cartItems) {
      const items = detail.cartItems.find(
        (item) =>
          item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN ||
          item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
      );
      return !!items;
    }
    return false;
  };

  render() {
    const { detail } = this.props;

    return (
      <>
        <Pane className="order-list">
          {detail.cartItems &&
            detail.cartItems.map((item) => {
              return (
                <OrderedCartItem
                  key={item.id}
                  detail={detail}
                  data={item}
                />
              );
            })}
          <Pane className="order-total">
            <FormattedMessage id="total" defaultMessage="TOTAL" />: AUD ${formatDecimal(detail.total)}
          </Pane>
          <Pane className="order-note">
            <FormattedMessage id="note" defaultMessage="Note" />{detail.note || 'None'}
          </Pane>
        </Pane>
      </>
    );
  }
}

export default OrderedCartList;
