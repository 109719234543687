import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CartItem from './CartItem';

class CartList extends Component {
  render() {
    const { cart } = this.props;

    const cartList =
      cart &&
      cart.map((item, index) => {
        return <CartItem key={item.id} item={item} index={index} />;
      });

    if (this.props.type === 'responsive') {
      return (
        <>
          {cart &&
            cart.map((item, index) => {
              return (
                <CartItem
                  key={item.id}
                  item={item}
                  index={index}
                  type={this.props.type}
                />
              );
            })}
        </>
      );
    }
    return (
      <table>
        <tbody>{cartList}</tbody>
      </table>
    );
  }
}

export default CartList;
