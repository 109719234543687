import React, { Component } from 'react';
import { Pane, Loading } from "shared/components/layouts";
import ContactList from './list/ContactList';
import { Dimmer, Loader } from 'semantic-ui-react';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { handleError } from 'shared/helpers';
import NotificationSystem from 'react-notification-system';
import {
  getMyContacts,
  deleteMyContacts
} from 'actions/userContact';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Config from "constants/Config";

class UserContacts extends Component {
  static propTypes = {
    getMyContacts: PropTypes.func,
    deleteMyContacts: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.state = {
      loading: false,
      userContacts: []
    };
  }

  getMyContacts = () => {
    this.setState({
      loading: true,
    });
    this.props
      .getMyContacts()
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  removeContact = (id, type) => {
    const { intl } = this.props;

    this.setState({ loading: true })

    let data = {
      follower_id: id,
      type: type
    }

    this.props.deleteMyContacts(data).then((res) => {

      this.setState({ loading: false })
      this.getMyContacts()
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: 'notification.contact.delete' }),
        level: 'success',
      });
    }).catch((errors) => {
      this.setState({ loading: false })
      this._notificationSystem.addNotification({
        message: handleError(
          errors,
          intl.formatMessage({ id: 'notification.please_try_again' })
        ),
        level: 'error',
      });
    });
  };

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
    this.getMyContacts()

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    const { loading } = this.state;
    const { userContacts } = this.props;
    const contacts = userContacts.filter(item => item.type === Config.WALLET_TYPE.CONTACT);
    const menus = userContacts.filter(item => item.type === Config.WALLET_TYPE.MENU);
    return (
      <Pane className="overview-container wallet" id="anchor">
        <Pane className="product-container order-container">
          <h3 className="block-title pt10 text-uppercase mb-3">
            <i className="icon-wallet mr-2"></i>Wallet
          </h3>
          <Pane className="products-grids mb-3" style={{ backgroundColor: "unset" }}>
            {loading && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {!loading && !isEmpty(userContacts) && (
              <Pane>
                <h4 className="wallet-title p-0"><i className="fas fa-address-book mr-2"></i>My Contacts</h4>
                <Pane className="wallet-table">
                  {!loading && isEmpty(contacts) ? (
                      <Pane className="block-no-content">
                        <p>
                          You haven't had any contact yet.
                        </p>
                      </Pane>
                    ) : (
                    <ContactList removeContact={this.removeContact} userContacts={contacts} />
                    )}
                </Pane>
                <h4 className="wallet-title p-0"><i className="icon-menu mr-2"></i>My Menus</h4>
                <Pane className="wallet-table">
                  {!loading && isEmpty(menus) ? (
                    <Pane className="block-no-content">
                      <p>
                        You haven't had any menu yet.
                      </p>
                    </Pane>
                  ) : (
                    <ContactList removeContact={this.removeContact} userContacts={menus} />
                  )}
                </Pane>
              </Pane>
            )}
            {!loading &&
              isEmpty(userContacts) && (
                <Pane className="block-no-content">
                  <p>
                    You haven't had any wallets yet.
                  </p>
                </Pane>
              )}
            <Pane className="clearfix" />
          </Pane>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth.user,
  userContacts: state.userContacts.results,
});

const bindDispatchToProps = (dispatch) => ({
  getMyContacts: bindActionCreators(getMyContacts, dispatch),
  deleteMyContacts: bindActionCreators(deleteMyContacts, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(UserContacts));