import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { MasterLayout } from 'components/layout'
import { getBookingDetailsOfBuyers } from 'actions/bookings'
import { pushNotification } from 'actions/notification'
import { BookingSuccess } from 'components/scenes/services/booking-success'
import { Pane } from 'shared/components/layouts'

class BookingSuccessPage extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    getBookingDetailsOfBuyers: PropTypes.func
  }

  render() {
    return (
      <MasterLayout>
        <Pane className='main-container main-category'>
          <BookingSuccess
            detail={this.props.detail}
            userInfo={this.props.userInfo}
            onGetBookingDetailsOfBuyers={this.props.getBookingDetailsOfBuyers}
            onPushNotification={this.props.pushNotification}
            history={this.props.history}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = state => ({
  userInfo: state.auth && state.auth.user,
  detail: state.buyingBookingDetail.data
})

const bindDispatchToProps = dispatch => ({
  getBookingDetailsOfBuyers: bindActionCreators(getBookingDetailsOfBuyers, dispatch),
  pushNotification: bindActionCreators(pushNotification, dispatch)
})

export default connect(bindStateToProps, bindDispatchToProps)(BookingSuccessPage)
