import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Icon, Table } from "semantic-ui-react";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactPaginate from "react-paginate";
import { Pane, Loading } from "shared/components/layouts";
import queryString from "query-string";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatRoute } from "shared/helpers";
import { getFirstScanQrPromotionsByUserId, removeFirstScanQrPromotion } from "actions/scanQrPromotion";
import { confirmAlert } from "react-confirm-alert";
class ScanQrPromotions extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      srName: "",
      size: 20,
      page: parseInt(queryString.parse(search).page, 10) || 1,
    };
  }
  componentWillUnmount() {
    this.unlisten();
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getFirstScanQrPromotionsByUserId();
    });
    this.getFirstScanQrPromotionsByUserId();

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  getFirstScanQrPromotionsByUserId = (params) => {
    const { userInfo } = this.props;
    this.props.getFirstScanQrPromotionsByUserId(
      {
        id: userInfo.id,
        size: this.state.size,
        name: this.state.srName,
      },
      true
    );
  };
  deleteItem = (id) => {
    this.props.removeFirstScanQrPromotion({ id }).then(() => this.getFirstScanQrPromotionsByUserId());
  };
  onSearch = () => {
    this.getFirstScanQrPromotionsByUserId();
  };
  handlePageClick = (data) => {
    const params = {
      id: this.props.userInfo.id,
      page: data.selected + 1,
      size: this.state.size,
      name: this.state.srName,
    };
    this.props.getFirstScanQrPromotionsByUserId(params, true);
  };
  changeRoute = (id) => {
    const { userInfo } = this.props;
    this.props.history.push(
      `/u/${formatRoute(userInfo.displayName)}/first-scan-qr-promotion/${id}/edit`
    );
  };
  viewCustomer = (id) => {
    const { userInfo } = this.props;
    this.props.history.push(
      `/u/${formatRoute(userInfo.displayName)}/first-scan-qr-promotion/${id}/detail`
    );
  }
  showConfirmDelete = (id) => {
    const { intl } = this.props;
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: `${intl.formatMessage({
        id: "notification.are_you_sure_you_want_to_delete_this",
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => {
            this.deleteItem(id);
          },
        },
        {
          label: intl.formatMessage({ id: "no" }),
        },
      ],
    });
  };

  render() {
    const { userInfo, firstScanQrPromotions } = this.props;
    let activeItems = []
    if(firstScanQrPromotions.results && firstScanQrPromotions.results.length){
        activeItems = firstScanQrPromotions.results.filter(function (item) {
        if(item.status === 'Active' || item.status === 'Upcoming'){
          return item;
        }
    });
    }
    return (
      <Pane className="overview-container" id="anchor">
        <Pane className="product-container order-container">
          <h3 className="block-title pt10 text-uppercase">
            <span>First scan QR Promotion List</span>
          </h3>
          <Pane className="product-heading">
            {
              activeItems.length == 0 && (
                <Link
                  to={`/u/${formatRoute(userInfo.displayName)}/first-scan-qr-promotion/create`}
                  className="btn btn-circle btn-circle-primary d-none d-lg-block"
                >

                  <span>Create New Promotion</span>
                </Link>
              )
            }

          </Pane>
          <Pane className="products-grids">
            {!firstScanQrPromotions.fetched && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id="name" defaultMessage="Name" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="staff_start_date"
                      defaultMessage="Start Date"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="staff_start_date"
                      defaultMessage="Start Date"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <span>Percent</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <span>Max price discount</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="staff_status"
                      defaultMessage="Status"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="staff_actions"
                      defaultMessage="Actions"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {firstScanQrPromotions.results && firstScanQrPromotions.results.length
                  ? firstScanQrPromotions.results.map((promotion) => (
                      <Table.Row key={promotion.id}>
                        <Table.Cell>{promotion.name}</Table.Cell>
                        <Table.Cell>
                          {moment
                            .utc(promotion.startDate, "YYYY-MM-DD HH:mm")
                            .format("YYYY-MM-DD")}
                        </Table.Cell>
                        <Table.Cell>
                          {moment
                            .utc(promotion.endDate, "YYYY-MM-DD")
                            .format("YYYY-MM-DD")}
                        </Table.Cell>
                        <Table.Cell>{promotion.percent}%</Table.Cell>
                        <Table.Cell>AUD ${parseFloat(promotion.maxDiscount).toFixed(2)}</Table.Cell>
                        <Table.Cell>{promotion.status}</Table.Cell>
                        <Table.Cell>
                          <Pane
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              justifyContent: "flex-start",
                            }}
                            className="adds-actions"
                           >
                            <a onClick={() => this.changeRoute(promotion.id)}>
                              <Icon name="pencil" />
                            </a>
                            <a onClick={() => this.viewCustomer(promotion.id)}>
                              <Icon name="eye" />
                            </a>
                            <a onClick={() => this.showConfirmDelete(promotion.id)}>
                              <Icon name="trash" />
                            </a>
                          </Pane>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  : null}
              </Table.Body>
            </Table>
            {firstScanQrPromotions.fetched &&
              !firstScanQrPromotions.fetching &&
              firstScanQrPromotions.results &&
              firstScanQrPromotions.results.length <= 0 && (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.you_have_not_had_any_services_yet"
                      defaultMessage={`You haven't had any services yet.`}
                    />
                  </p>
                </Pane>
              )}
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {firstScanQrPromotions.totalPages > 1 ? (
                <ReactPaginate
                  previousLabel={
                    <FormattedMessage
                      id="pagination.previous"
                      defaultMessage="previous"
                    />
                  }
                  nextLabel={
                    <FormattedMessage
                      id="pagination.next"
                      defaultMessage="next"
                    />
                  }
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={"break-me"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  pageCount={firstScanQrPromotions.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              ) : null}
            </nav>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  firstScanQrPromotions: state.myFirstScanQrPromotions,
});
const bindDispatchToProps = (dispatch) => ({
  getFirstScanQrPromotionsByUserId: bindActionCreators(getFirstScanQrPromotionsByUserId, dispatch),
  removeFirstScanQrPromotion: bindActionCreators(removeFirstScanQrPromotion, dispatch),
});
export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(ScanQrPromotions));