import * as CustomerAddV1 from './customer.add.v1.action'
import * as CustomerCheckCodeV1 from './customer.check-code.v1.action'

export default {
  // Send contact information
  add: {
    callAPI: params => CustomerAddV1.exec(params),
    parseResponse: response => CustomerAddV1.parseResponse(response)
  },
  checkCode: {
    callAPI: params => CustomerCheckCodeV1.exec(params),
    parseResponse: response => CustomerCheckCodeV1.parseResponse(response)
  }
}
