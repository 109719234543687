import * as types from 'constants/ActionTypes'
import BannerAdapter from 'services/banners/adapter'

const initialState = {
  results: [],
  error: null,
  fetching: false,
  fetched: false
}

const banners = (state = initialState, action = {}) => {
  switch (action.type) {

    case types.REQUEST_BANNERS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_BANNERS_SUCCESS:
      return Object.assign({}, state, {
        results: BannerAdapter.browse.parseResponse(action.payload.data),
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_BANNERS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        results: [],
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

export { banners }