import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';
import Config from 'constants/Config';
import { formatRoute } from 'shared/helpers';

class FeatureVideoItem extends Component {
  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    video.play();
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  renderItem(index) {
    const slug =
      this.props.items[index].user &&
      this.props.items[index].user.business != null
        ? formatRoute(this.props.items[index].user.business.name, '-')
        : '';
    if (this.props.items && this.props.items.length > index) {
      return (
        <>
          <Link
            className="inner-link"
            to={`/${slug}/videos/${this.props.items[index].id}`}
          >
            <video
              src={this.props.items[index].video.url}
              onMouseOver={this.playVideo}
              onMouseOut={this.stopVideo}
              ref="videoPlayer"
              controls
              className="inner-img"
            />
          </Link>
        </>
      );
    }
    return '';
  }

  renderItemResponsive() {
    const { items } = this.props;
    if (items) {
      const slug =
        items.user && items.user.business != null
          ? formatRoute(items.user.business.name, '-')
          : '';
      return (
        <Link className="inner-link" to={`/${slug}/videos/${items.id}`}>
          <video
            src={items.video.url}
            onMouseOver={this.playVideo}
            onMouseOut={this.stopVideo}
            ref="videoPlayer"
            controls
            className="inner-img"
          />
        </Link>
      );
    }
    return '';
  }

  render() {
    if (this.props.device !== 'responsive') {
      if (this.props.items.length === 4) {
        return (
          <>
            <Pane className="inner">{this.renderItem(0)}</Pane>
            <Pane className="inner inner-row">
              <Pane className="inner-children">{this.renderItem(1)}</Pane>
              <Pane className="inner-children">{this.renderItem(2)}</Pane>
            </Pane>
            <Pane className="inner">{this.renderItem(3)}</Pane>
          </>
        );
      }
      if (this.props.items.length === 2 || this.props.items.length === 3) {
        return (
          <>
            <Pane className="inner inner-first">{this.renderItem(0)}</Pane>
            <Pane className="inner inner-two">{this.renderItem(1)}</Pane>
          </>
        );
      }
      if (this.props.items.length === 1) {
        return (
          <>
            <Pane className="inner inner-only">{this.renderItem(0)}</Pane>
          </>
        );
      }
      return null;
    }
    return <Pane className="slider-item">{this.renderItemResponsive()}</Pane>;
  }
}

export default FeatureVideoItem;
