import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import { StoreList } from "components/common/stores";
import InfiniteScroll from "react-infinite-scroller";
import BusinessAdapter from "services/businesses/adapter";
import { LoadingSearch } from "components/common/loading";
import { BusinessLogoList } from "../home";
import { BannerSlideShow } from "../home";

class JustListed extends Component {
  static propTypes = {
    auth: PropTypes.object,
    onSearchStores: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      results: [],
      page: 1,
      totalPages: 0,
      totalItems: 0,
      loadingMore: false,
      hasMoreItems: true,
      data: {
        results: [],
      },
    };
  }

  componentDidMount() {
    const params = {
      page: this.state.page,
      keyword: this.state.keyword,
    };

    this.searchStores(params, true);
  }

  searchStores = (params, load) => {
    this.setState({ loading: load });
    this.props
      .onSearchStores(params)
      .then((response) => {
        this.setState({
          results: BusinessAdapter.browse.parseResponse(response.data),
          totalPages: response.data.meta.totalPages,
          totalItems: response.data.meta.totalItems,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  loadItems = () => {
    const params = {
      page: this.state.page + 1,
    };
    if (
      !this.state.loadingMore &&
      this.state.totalItems > 20 &&
      this.state.totalItems > this.state.results.length
    ) {
      this.setState({
        hasMoreItems: false,
        loadingMore: true,
      });
      this.props
        .onSearchStores(params)
        .then((response) => {
          this.setState((prevState) => ({
            hasMoreItems: true,
            page: prevState.page + 1,
            results: prevState.results.concat(
              BusinessAdapter.browse.parseResponse(response.data)
            ),
            loadingMore: false,
          }));
        })
        .catch(() => {
          this.setState({ loading: false, loadingMore: false });
        });
    }
  };


  render() {
    const { auth, banners } = this.props;
    const {
      results,
      loading,
      loadingMore,
      hasMoreItems,
    } = this.state;
    return (
      <>
        <BusinessLogoList />
        <Pane className="container product-container product-list-container">
          <Pane className="container">
            <Pane className="row">
              <Pane className="col-lg-12 col-md-12">
                <Pane className="search-container">
                  <Pane>
                    {loading && <LoadingSearch type="white" />}
                    {(!_.isEmpty(results) ? (
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadItems}
                        hasMore={hasMoreItems}
                        isReverse={false}
                      >
                        <StoreList auth={auth} stores={results} justListed={true} />
                        {loadingMore && <LoadingSearch />}
                      </InfiniteScroll>
                    ) : (
                      <></>
                    ))}
                    {!loading && _.isEmpty(results) ? <Empty /> : null}
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane></>
    );
  }
}

export default withRouter(JustListed);
