import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { MasterLayout } from 'components/layout';
import { getProductsSlidePromotion } from'actions/products';
import { getBusinesses } from 'actions/business';
import { getVideos } from 'actions/videos';
import { Pane } from 'shared/components/layouts';
import {
  Video,
  BannerIntro,
  BusinessLogoList,
} from 'components/scenes/video';
import { Placeholder, Segment } from 'semantic-ui-react';
import StoreList from 'components/scenes/video/filter/StoreList';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { getReviewsVideo, reviewsVideo } from 'actions/reviews';
import NotificationSystem from 'react-notification-system';
import { viewVideos } from 'actions/myVideos';
import { signin, signinSocial } from 'actions/auth';
class VideoPage extends Component {
  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
  }
  static propTypes = {
    intl: intlShape.isRequired,
    businesses: PropTypes.object.isRequired,
  };

  state = {
    store: this.props.location.search !== '' ? queryString.parse(this.props.location.search).store : null,
    page: this.props.location.search !== '' ? (parseInt(queryString.parse(this.props.location.search).page, 10) || 1) : 1,
    size: 20,
    loadingMore: false,
    fetched: false,
    fetching: false,
    hasMoreItems: true,
    data: {
      results: [],
    },
    slideVideoData: [],
  };

  loadItems = () => {
    const params = {
      page: this.state.page + 1,
      store: this.state.store,
    };
    if (
      !this.state.loadingMore &&
      this.props.allVideos.totalItems > 20 &&
      this.props.allVideos.totalItems > this.state.data.results.length
    ) {
      this.setState({
        hasMoreItems: false,
        loadingMore: true,
      });
      this.props.getVideos(params).then(() => {
        this.setState(prevState=>({
          hasMoreItems: true,
          page: prevState.page + 1,
          data: {
            results: prevState.data.results.concat(this.props.allVideos.results),
          },
          loadingMore: false,
        }));
      });
    }
  };

  getAllVideo= (params) => {
    this.setState({
      fetching: true,
      fetched: false,
    });
    this.props
      .getVideos({
        ...params
      })
      .then(() => {
        this.setState({
          fetching: false,
          fetched: true,
          data: this.props.allVideos,
        });
      });
  };

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
      const qp = queryString.parse(location.search);
      const params = {};
      Object.keys(qp) &&
        Object.keys(qp).forEach((key) => {
          params[key] = qp[key];
        });
      this.getAllVideo(params);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    const params = {
      page: this.state.page,
      store: this.state.store,
      size: 20,
    };
    this.getAllVideo(params);
    window.scrollTo(0, 0);
    this._notificationSystem = this.refs.notificationSystem;
  }

  onChooseVideo = (record) => {
    this.setState({
      slideVideoData: this.props.allVideos.results.filter(
        (item) => item.id === record.id
      ),
    });
    window.scrollTo(0, 0);
  };

  render() {
    const { fetched, fetching, data, slideVideoData } = this.state;
    const { auth } = this.props;
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
        {slideVideoData.length ? ( 
            <BannerIntro 
              allVideos={slideVideoData}
              onGetReviewsVideo={this.props.getReviewsVideo}
              onReviewsVideo={this.props.reviewsVideo}
              onNotification={this._notificationSystem}
              reviews={this.props.reviews}
              auth={auth}
              onViewVideo={this.props.viewVideos}
              signin={this.props.signin}
              signinSocial={this.props.signinSocial}
            />
          ) : null }
          {slideVideoData.length === 0 && data.results.length > 0 ? ( 
            <BannerIntro 
              allVideos={data.results.length > 0 ? [data.results[0]]: data.results}
              onGetReviewsVideo={this.props.getReviewsVideo}
              onReviewsVideo={this.props.reviewsVideo}
              onNotification={this._notificationSystem}
              reviews={this.props.reviews}
              auth={auth}
              onViewVideo={this.props.viewVideos}
              signin={this.props.signin}
              signinSocial={this.props.signinSocial}
            />
          ) : null }
          {slideVideoData.length === 0 && data.results.length === 0 ? ( 
            <Pane className="empty-container">
            <Pane className="empty-content">
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/icons/video.png'
                }
                alt="empty"
                className="img-empty"
              />
              <h2 className="empty-title">No video</h2>
            </Pane>
          </Pane>
          ) : null }
          <BusinessLogoList/>
          <Pane className="container product-container product-list-container">
            <Pane className="container">
              <Pane className="row">
                <Pane className="col-lg-3 col-md-12">
                  <Pane className="inner-box">
                    <h2
                      className="inner-title"
                    >
                      <FormattedMessage id="stores" defaultMessage="Stores" />
                      <span className="dropdown-inner">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/icons/arrow-inner.svg'
                          }
                          alt="arrow"
                        />
                      </span>
                    </h2>
                    <Pane>
                      <Pane className="categories-list list-filter">
                        {this.props.businesses.results.length > 0 && (
                          <StoreList
                            filters={this.props.businesses.results}
                            type="videos"
                          />
                        )}
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane className="col-lg-9 col-md-12">
                {!fetching && fetched ? (
                  <>
                    {!_.isEmpty(data.results) ? (
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadItems}
                        hasMore={this.state.hasMoreItems}
                        isReverse={false}
                      >
                        <Video
                          allVideos={data}
                          onChooseVideo={this.onChooseVideo}
                          onReviewsVideo={this.props.getReviewsVideo}
                        />
                        {this.state.loadingMore && (
                          <Pane className="loading-container">
                            <Pane className="container">
                              <Pane className="row">
                                <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                                  <Segment raised>
                                    <Placeholder>
                                      <Placeholder.Header className="mb-3">
                                        <Placeholder.Image
                                          rectangular
                                          className="mb-3"
                                        />
                                      </Placeholder.Header>
                                      <Placeholder.Paragraph>
                                        <Placeholder.Line length="medium" />
                                        <Placeholder.Line length="short" />
                                      </Placeholder.Paragraph>
                                    </Placeholder>
                                  </Segment>
                                </Pane>
                                <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                                  <Placeholder>
                                    <Placeholder.Header className="mb-3">
                                      <Placeholder.Image
                                        rectangular
                                        className="mb-3"
                                      />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                      <Placeholder.Line length="medium" />
                                      <Placeholder.Line length="short" />
                                    </Placeholder.Paragraph>
                                  </Placeholder>
                                </Pane>
                                <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                                  <Placeholder>
                                    <Placeholder.Header className="mb-3">
                                      <Placeholder.Image
                                        rectangular
                                        className="mb-3"
                                      />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                      <Placeholder.Line length="medium" />
                                      <Placeholder.Line length="short" />
                                    </Placeholder.Paragraph>
                                  </Placeholder>
                                </Pane>
                                <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                                  <Placeholder>
                                    <Placeholder.Header className="mb-3">
                                      <Placeholder.Image
                                        rectangular
                                        className="mb-3"
                                      />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                      <Placeholder.Line length="medium" />
                                      <Placeholder.Line length="short" />
                                    </Placeholder.Paragraph>
                                  </Placeholder>
                                </Pane>
                              </Pane>
                            </Pane>
                          </Pane>
                        )}
                      </InfiniteScroll>
                    ) : (
                      <Pane className="empty-container mt-3">
                        <Pane className="empty-content">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/assets/images/icons/empty.png'
                            }
                            alt="empty"
                            className="img-empty"
                          />
                          <h2 className="empty-title">No items</h2>
                        </Pane>
                      </Pane>
                    )}
                  </>
                ) : (
                  <Pane className="loading-adds-wrapper">
                    <Pane className="container">
                      <Pane className="row">
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Segment raised>
                            <Placeholder>
                              <Placeholder.Header className="mb-3">
                                <Placeholder.Image rectangular className="mb-3" />
                              </Placeholder.Header>
                              <Placeholder.Paragraph>
                                <Placeholder.Line length="medium" />
                                <Placeholder.Line length="short" />
                              </Placeholder.Paragraph>
                            </Placeholder>
                          </Segment>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                )}
                </Pane>
              </Pane>
            </Pane>
          </Pane>
          <NotificationSystem ref="notificationSystem" />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => {
  return ({
    productsSlidePromotion: state.productsSlidePromotion,
    businesses: state.businesses,
    allVideos: state.allVideos,
    reviews: state.reviews,
    auth: state.auth,
  });
} 

const bindDispatchToProps = (dispatch) => ({
  getProductsSlidePromotion: bindActionCreators(
    getProductsSlidePromotion,
    dispatch
  ),
  getBusinesses: bindActionCreators(getBusinesses, dispatch),
  getVideos: bindActionCreators(getVideos, dispatch),
  getReviewsVideo: bindActionCreators(getReviewsVideo, dispatch),
  reviewsVideo: bindActionCreators(reviewsVideo, dispatch),
  viewVideos: bindActionCreators(viewVideos, dispatch),
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(VideoPage));
