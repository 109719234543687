import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const id = params.id || '';
  return {
    method: 'GET',
    url: `users/${id}/videos?page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const data = [];
  if (response.data) {
    response.data.forEach((item) => {
      const service = formatJson(response, item.type, item.id);
      data.push(service);
    });
  }
  return data;
};

export { exec, parseResponse };
