import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { injectIntl } from 'react-intl'
import { Pane } from 'shared/components/layouts'
import Config from 'constants/Config'

const SendVerifyEmailForm = ({ email }) => {
  return (
    <Pane className='page-not-found-content text-center'>
      <h1>Welcome !</h1>
      <img width="5%" aria-hidden src="/assets/images/gallery/inbox.png" alt="image" />
      <br />
      <br />
      <p>We sent an email to {email}</p>
      <p> To continue, please check your email and verify your account.</p>
      <p>Didn't receive the email?</p>
      <a href={Config.NEW_API_URL + '/email-verification/resend?email=' + email}>
        <Button color='orange' content="Resend Email" />
      </a>
    </Pane>
  )
}


SendVerifyEmailForm.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object,
}

SendVerifyEmailForm.defaultProps = {
  history: {}
}

export default withRouter(injectIntl(SendVerifyEmailForm))
