import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { createMyStaff } from "actions/staffs";
import { getProjectsByUserId } from "actions/projects";
import CreateStaffForm from "./CreateStaffForm";
import { Pane } from 'shared/components/layouts';
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';
class CreateStaff extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }
  componentDidMount = () => {
    const { userInfo } = this.props;
    this.props.getProjectsByUserId({ id: userInfo.id, size: 999999 });

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  };
  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/staffs`,
        name: 'Staff'
      },
      {
        url: ``,
        name: 'Create new staff',
        active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="text-center mb-4" id="anchor">
        </Pane>
        <CreateStaffForm
          userInfo={this.props.userInfo}
          history={this.props.history}
          createMyStaff={this.props.createMyStaff}
          projects={this.props.projects.results || []}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  projects: state.myProjects,
});
const bindDispatchToProps = (dispatch) => ({
  createMyStaff: bindActionCreators(createMyStaff, dispatch),
  getProjectsByUserId: bindActionCreators(getProjectsByUserId, dispatch),
});
export default connect(mapStateToProps, bindDispatchToProps)(CreateStaff);