import React from 'react'
import { MasterLayout } from 'components/layout'
import { NotFound } from 'shared/components'
import { Pane } from 'shared/components/layouts'

class NotFoundPage extends React.Component {
  render() {
    return (
      <MasterLayout>
        <Pane className='container not-found-container'>
          <NotFound />
        </Pane>
      </MasterLayout>
    )
  }
}

export default NotFoundPage
