import _ from "lodash";
import PropTypes from "prop-types";
import Config from "constants/Config";
import React, { Component } from "react";
import { Pane, Loading } from "shared/components/layouts";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { withRouter, Link } from "react-router-dom";
import { PriceItem, PromotionLabel, StockLabel } from "shared/components";
import { Cart } from "components/common/cart";
import { Rating, Dimmer } from 'semantic-ui-react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeAllCart } from "actions/cart";

class ListItem extends Component {
  state = {
    added: false,
    adding: false,
    play: 0,
    loadingAddCart: false
  };

  isProductOptions = () => {
    if (this.props.item.options && this.props.item.options.length) return true;
    return false;
  };

  addItemToCart = () => {
    const { intl, item, cartItems } = this.props;

    if (this.isProductOptions()) {
      this.props.cartModal.setCartView({
        ...this.props.item,
      });
      this.props.cartModal.openModal();
      return;
    }

    this.setState({ added: false, adding: true });
    this.setState({ loadingAddCart: true });

    if (cartItems && cartItems.length > 0 && item.userId !== cartItems[0].userId) {
      this.props.removeAllCart();
    }

    this.props
      .onAddItemToCart({ product_id: this.props.id })
      .then(() => {
        this.setState({ added: true, adding: false });
        this.setState({ loadingAddCart: false });
      })
      .catch(() => {
        this.setState({ adding: false });
        this.setState({ loadingAddCart: false });
        this.props.onNotification.addNotification({
          message: intl.formatMessage({ id: "notification.please_try_again" }),
          level: "error",
        });
      });
  };

  renderInfo = () => {
    const { item } = this.props;
    return (
      <div className="adds-info adds-info-item">
        <PriceItem
          promotion={item && item.promotion}
          originalPrice={item && item.originalPrice}
        />
        <button className="btn-order" onClick={this.addItemToCart}>
          <span className="icon-plus" />
        </button>
      </div>
    );
  };

  convertRedirect = (value) => {
    if (this.props.type === "bussiness") {
      return `/${value}`;
    }
    return `/products/${value}`;
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  render() {
    const { item, name, image, id, type, modeView, description } = this.props;
    const { added, loadingAddCart } = this.state;
    const extImage = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];
    const ext = image ? image.substr(image.lastIndexOf(".") + 1) : "";
    if (modeView === Config.MODE_VIEW_MENU.MODE_LIST) {
      return (
        <Pane className="adds-item adds-list-view">
          {loadingAddCart && (
            <Dimmer active inverted>
              <Loading />
            </Dimmer>
          )}
          <Pane className="adds-content">
            <Pane className="col-lg-7 col-md-6 col-sm-12 p-0">
              <Link
                className="adds-norm"
                to={
                  type === "bussiness"
                    ? this.convertRedirect(item.slug)
                    : this.convertRedirect(id)
                }
              >
                <h5 title={name}>
                  <b>{name}</b>
                </h5>
              </Link>
            </Pane>
            <Pane className="col-lg-2 col-md-3 col-sm-12 p-0">
              <div className="group-price">
                <PriceItem
                  promotion={item && item.promotion}
                  originalPrice={item && item.originalPrice}
                />
                <button className="btn-order-sm d-none d-md-block d-sm-none d-lg-block float-right" onClick={this.addItemToCart}>
                  <span className="icon-plus" />
                </button>
                <div style={{ clear: "both" }}></div>
              </div>
            </Pane>
            <Pane className="col-lg-3 col-md-3 pr-md-0">
              <button className="btn-order d-none d-md-block d-sm-none d-lg-block float-right" onClick={this.addItemToCart}>
                <span className="icon-plus" />
              </button>
            </Pane>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className="col-lg-3 col-md-6 col-sm-6 col-12 pl-0 pr-0 pb-0">
        {loadingAddCart && (
          <Dimmer active inverted>
            <Loading />
          </Dimmer>
        )}
        <Pane className="adds-item">
          <Link className="hover-image" to={this.convertRedirect(item.slug)}>
            {extImage.indexOf(ext) >= 0 ? (
              <Pane
                className="adds-images adds-images-stock"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : `url${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                }}
              >
                <StockLabel
                  inStock={item && item.inventoryStatus}
                  type={type}
                />
                <PromotionLabel
                  promotion={item && item.promotion}
                  originalPrice={item && item.originalPrice}
                />
                <Cart added={added || !!item.added} type="products" />
              </Pane>
            ) : (
              <Pane className="adds-images adds-images-stock">
                {image ? (
                  <Pane>
                    {ext !== "" ? <i className="fa fa-video" /> : ""}
                    <video
                      className="carbeyvideo-list"
                      playsInline={1}
                      onMouseOver={this.playVideo}
                      onMouseOut={this.stopVideo}
                      muted={!this.state.play}
                    >
                      <source src={image} id="video_here" />
                      Your browser does not support HTML5 video.
                    </video>
                    <StockLabel
                      inStock={item && item.inventoryStatus}
                      type={type}
                    />
                    <PromotionLabel
                      promotion={item && item.promotion}
                      originalPrice={item && item.originalPrice}
                    />
                    <Cart added={added || !!item.added} type="products" />
                  </Pane>
                ) : (
                  <Pane
                    className="adds-images adds-images-stock "
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                    }}
                  >
                    <StockLabel
                      inStock={item && item.inventoryStatus}
                      type={type}
                    />
                  </Pane>
                )}
              </Pane>
            )}
          </Link>
          <Pane className="adds-content">
            <Link
              to={
                type === 'bussiness'
                  ? this.convertRedirect(item.slug)
                  : this.convertRedirect(id)
              }
            >
              <h5 className="adds-norm" title={name}>
                <b>{name}</b>
              </h5>
            </Link>
            <ul className="list-star">
              <Rating
                disabled
                icon='star'
                rating={item.rating || 0}
                maxRating={1}
                className="mr-1"
                size='small'
              />
              <span>{item.rating || 'No Rating'}</span>
            </ul>
            {this.renderInfo()}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
ListItem.propTypes = {
  auth: PropTypes.object,
  item: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  rating: PropTypes.number,
  intl: intlShape.isRequired,
  onAddItemToCart: PropTypes.func,
  onNotification: PropTypes.object,
  type: PropTypes.string,
  description: PropTypes.string,
};
ListItem.defaultProps = {
  type: "",
};


const bindDispatchToProps = (dispatch) => ({
  removeAllCart: bindActionCreators(removeAllCart, dispatch),
});

export default connect(null, bindDispatchToProps)(injectIntl(withRouter(ListItem)));
