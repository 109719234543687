import { Form, Button } from 'semantic-ui-react';
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Validator from 'validator';
import { InlineMessage } from 'shared/components';
import _ from 'lodash';
import Config from 'constants/Config';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { handleError } from 'shared/helpers';


class ClaimContact extends Component {
	static propTypes = {
		intl: intlShape.isRequired,
		onNotification: PropTypes.object,
		businessInfo: PropTypes.object,
	};

	constructor(props, context) {
		super(props, context);

		this.state = {
			data: {
				name: '',
				email: '',
				note: ''
			},
			loading: false,
			errors: {}
		};
	}

	onChange = (e) => {
		this.setState({
			data: { ...this.state.data, [e.target.name]: e.target.value },
		});
	};

	validate = (data) => {
		const errors = {};
		const { intl } = this.props;

		if (!data.name) {
			errors.name = intl.formatMessage({ id: 'validate.require_field' });
		}
		if (!data.email) {
			errors.email = intl.formatMessage({ id: 'validate.require_field' });
		}
		if (data.email && !Validator.isEmail(data.email)) {
			errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
		}

		return errors;
	};

	onSubmit = async () => {
		const { intl } = this.props;
		const cookies = new Cookies();
		let errors = {};
		errors = this.validate(this.state.data);

		this.setState({ errors });
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true });

			const formData = new URLSearchParams();

			formData.append('name', this.state.data.name);
			formData.append('email', this.state.data.email);
			formData.append('note', this.state.data.note);
			formData.append('businessName', this.props.businessInfo.name || '');
			formData.append('businessId', this.props.businessInfo.userId || '');

			await axios({
				method: 'POST',
				headers: { Authorization: `Bearer ${cookies.get('accessToken')}` },
				url: `${Config.NEW_API_URL}/claim-business`,
				data: formData,
			}).then((res) => {
				this.props.onNotification.addNotification({
					message: intl.formatMessage({ id: "claim_success_message" }),
					level: "success",
				});
				this.setState({
					loading: false, data: {name: '', email: '', note: ''}
				});
				setTimeout(() => {
					this.props.onCloseModal();
				}, 1500)
			}).catch((errors) => {
				errors.message = handleError(
					errors,
					this.props.intl.formatMessage({
						id: 'notification.please_try_again',
					})
				);
				this.setState({ errors, loading: false });
			});

		}
	};

	render() {
		const { errors, loading, data } = this.state;
		const {
			openModal,
			onCloseModal,
			intl
		} = this.props;

		return (
			<Modal
				open={openModal}
				onClose={onCloseModal}
				center
				showCloseIcon={true}
			>
				<Pane className="login-container popup-login rounded">
					<Pane className="wrap-login d-flex align-items-center justify-content-center flex-column">
						<h4 className="login-title" style={{ fontSize: '25px' }}>Claim your business</h4>
						<Form className="w-100" onSubmit={this.onSubmit} noValidate>
							<Form.Field error={!!errors.name} className="form-item">
								<label htmlFor="name" className="form-label">
									<FormattedMessage
										id="name"
										defaultMessage="Name"
									/>
								</label>
								<input
									className="form-input"
									id="name"
									type="text"
									name="name"
									placeholder={intl.formatMessage({ id: 'name' })}
									value={data.name || ''}
									onChange={this.onChange}
								/>
								{errors.name && <InlineMessage text={errors.name} />}
							</Form.Field>
							<Form.Field error={!!errors.email} className="form-item">
								<label htmlFor="email" className="form-label">
									<FormattedMessage
										id="email"
										defaultMessage="Email"
									/>
								</label>
								<input
									className="form-input"
									id="email"
									type="text"
									name="email"
									placeholder={intl.formatMessage({ id: 'email' })}
									value={data.email || ''}
									onChange={this.onChange}
								/>
								{errors.email && <InlineMessage text={errors.email} />}
							</Form.Field>
							<Form.Field className="form-item">
								<label htmlFor="note" className="form-label">
									<FormattedMessage
										id="note"
										defaultMessage="Note"
									/>
								</label>
								<textarea
									className="form-input"
									id="note"
									type="text"
									name="note"
									placeholder="Enter note"
									rows="4"
									value={data.note || ''}
									onChange={this.onChange}
								/>
							</Form.Field>
							{errors.message && (
								<InlineMessage text={errors.message} />
							)}
							<Pane className="form-item form-button">
								<Button
									disabled={loading}
									loading={loading}
									type="submit"
									className="btn btn-circle btn-form"
								>
									Send
								</Button>
							</Pane>
						</Form>
					</Pane>
				</Pane>
			</Modal>
		);
	}
}

export default withRouter(injectIntl(ClaimContact));
