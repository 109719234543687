import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = params => {
  return {
    method: 'GET',
    url: `screens/count`,
    baseURL: Config.NEW_API_URL
  };
};

const parseResponse = response => {
  if (response && response.data) {

    const scanCount = formatJson(response, response.data.type, response.data.id)
    return {
       total_screen: scanCount.totalScreen,
      share_active: scanCount.shareActive,
      share_pending: scanCount.sharePending

    }
  }
  return {a:1};
};

export { exec, parseResponse };
