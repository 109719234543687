import Config from 'constants/Config'

const exec = params => {
  return {
    method: 'POST',
    url: `/products/${params.id}/reviews`,
    baseURL: Config.NEW_API_URL,
    data: {
      stars: params.rating,
      comment: params.comment
    }
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
