import Config from 'constants/Config'

const exec = (params) => {
  params.append('_method', 'POST')

  return {
    method: 'POST',
    baseURL: Config.NEW_API_URL,
    url: `/users/reference/${params.get('id')}?code=${params.get('code')}`,
    data: params
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
