import React, { Component } from 'react'
import Config from 'constants/Config'
import { Label } from 'semantic-ui-react'
import { Pane } from 'shared/components/layouts'
import { formatDecimal } from 'shared/helpers'

class ProductItem extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selected: false
    }
  }

  onSelect = () => {
    this.setState({
      selected: !this.state.selected
    })
    this.props.onClick()
  }

  componentDidMount() {
    const item = this.props.itemsSelected.filter(item => item.id === this.props.id)

    if (item.length > 0) {
      this.setState({
        selected: !this.state.selected
      })
    }
  }

  render() {
    return (
      <Pane className='item-list make-grid' onClick={this.onSelect}>
        {
          this.state.selected
          ? <Label attached='top right' icon='check' color='green' />
          : null
        }
        <Pane className='no-padding photobox'>
          <Pane className='add-image'>
            <Pane className='list-item-image' style={{ backgroundImage: `url(${this.props.image || ''})` }}>
              { this.props.image && <img src={Config.API_URL + this.props.image} alt={this.props.name} className="img-responsive" /> }
            </Pane>
          </Pane>
        </Pane>
        <Pane className='add-desc-box'>
          <Pane className='ads-details'>
            <h5 className="add-title pb0">{this.props.name}</h5>
          </Pane>
        </Pane>
        <Pane className='text-right price-box'>
          <h2 className="item-price pb5">AUD ${formatDecimal(this.props.price)}</h2>
        </Pane>
      </Pane>
    )
  }
}

export default ProductItem
