import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Config from 'constants/Config';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share';

class LikeShareCollection extends Component {
  static propTypes = {
    onGetLikesVideo: PropTypes.func,
    onGetUserLikesVideo: PropTypes.func,
    onLikesVideo: PropTypes.func,
    intl: intlShape.isRequired,
    item: PropTypes.object,
    onOpenModal:PropTypes.func,
  };
  state = {
    likes: 0,
  };
  getLikeVideo = () => {
    this.props.onGetLikesVideo({
      id: this.props.id,
      model: 'Collection',
    }).then(res => {
      this.setState({ likes: res.data.data.length })
    });
    this.props.onGetUserLikesVideo({
      id: this.props.id,
      user_id: this.props.idUser,
      model: 'Collection',
    });
  };

  likeVideo = () => {
    if (!this.props.idUser) {
      this.props.onOpenModal();
      return false;
    }
    const { intl } = this.props;
    this.props
      .onLikesVideo({
        id: this.props.id,
        model: 'Collection',
        like: true,
      })
      .then(() => {
        this.getLikeVideo();
        if (this.props.userLike.length === 0) {
          this.props.onNotification.addNotification({
            message: intl.formatMessage({ id: 'notification.thank_for_like' }),
            level: 'success',
          });
        }
        this.props.onGetCollectionsByUserId();
      })
      .catch(res => {
        this.setState({ liked: false });
        this.props.onNotification.addNotification({
          message: intl.formatMessage({ id: 'notification.please_try_again' }),
          level: 'error',
        });
      });
  };
  componentDidMount() {
    this.getLikeVideo();

    this._notificationSystem = this.refs.notificationSystem;
  }
  render() {
    return (
      <Pane className="">
        <Pane id="sharesocial"
          className="d-flex" style={{fontSize: "12px", alignItems: "center"}}>
          <Pane className="mr-3">
            <Pane onClick={this.props.isFront ? this.likeVideo : undefined}>
              <img
                src={
                  `${process.env.PUBLIC_URL}/assets/images/icons/${(this.props.userLike.length > 0 && this.props.userLike[0].like === 1) ? 'like-blue.svg':'like-gray.svg'}`
                }
                alt="image"
                aria-hidden
                className="icon mr-2 mb-1"
                style={{ cursor: this.props.isFront ? 'pointer' : 'unset' }}
              />
              <span style={{fontSize: '14px', fontWeight: "bold"}}>{this.props.like.results.length == 0 ? 'like' : this.props.like.results.length}</span>
            </Pane>
          </Pane>
          <List className="mt-0">
            <List.Item 
              style={{ display: 'inline-flex' }}>
              <List.Content className="d-flex mt-1">
                <i className="fa fa-share-alt mr10" />
                <FormattedMessage id="share" defaultMessage="Share" />:
              </List.Content>
              <List.Content className="item">
                <FacebookShareButton
                  url={Config.SITE_URL + this.props.shareUrl}
                >
                  <FacebookIcon size={20} round={true} />
                </FacebookShareButton>
              </List.Content>
              <List.Content className="item">
                <TwitterShareButton url={Config.SITE_URL + this.props.shareUrl}>
                  <TwitterIcon size={20} round={true} />
                </TwitterShareButton>
              </List.Content>
              <List.Content className="item">
                <EmailShareButton url={Config.SITE_URL + this.props.shareUrl}>
                  <EmailIcon size={20} round={true} />
                </EmailShareButton>
              </List.Content>
              <List.Content className="item">
                <LinkedinShareButton
                  url={Config.SITE_URL + this.props.shareUrl}
                >
                  <LinkedinIcon size={20} round={true} />
                </LinkedinShareButton>
              </List.Content>
              <List.Content className="item">
                <WhatsappShareButton
                  url={Config.SITE_URL + this.props.shareUrl}
                >
                  <WhatsappIcon size={20} round={true} />
                </WhatsappShareButton>
              </List.Content>
            </List.Item>
          </List>
        </Pane>
      </Pane>
    );
  }
}
LikeShareCollection.propTypes = {};
export default withRouter(injectIntl(LikeShareCollection));
