import { get } from 'lodash';
import ListItem from './ListItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';

class ProductList extends Component {
  static propTypes = {
    auth: PropTypes.object,
    products: PropTypes.array,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
  };

  render() {
    const {
      match: { params },
    } = this.props;
    const list =
      this.props.products &&
      this.props.products.map((item, index) => {
        if (get(params, 'id') && get(params, 'idChidren')) {
          return (
            <ListItem
              ListItem
              key={item.id}
              item={item}
              id={item.id}
              name={item.name}
              index={index}
              image={item.primaryImage}
              price={item.originalPrice}
              rating={item.rating}
              onAddItemToCart={this.props.onAddItemToCart}
              onAddItemToGuestCart={this.props.onAddItemToGuestCart}
              onNotification={this.props.onNotification}
              modeView={this.props.modeView}
              auth={this.props.auth}
              type="products"
            />
          );
        }
        if (get(params, 'id') && !get(params, 'idChidren')) {
          return (
            <ListItem
              key={item.id}
              item={get(item.user) || {}}
              id={item.userId}
              name={get(item, 'user.business.name')}
              index={index}
              image={get(item, 'user.backgroundImage.url')}
              price={item.originalPrice}
              rating={item.rating}
              auth={this.props.auth}
              type="stores"
            />
          );
        }
        if (get(params, 'type') && !get(params, 'id')) {
          return (
            <ListItem
              key={item.id}
              item={item}
              id={item.id}
              name={item.name}
              index={index}
              image={get(item, 'primaryImage.url')}
              price={item.originalPrice}
              rating={item.rating}
              auth={this.props.auth}
              type={this.props.type}
            />
          );
        }
        return (
          <ListItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.name}
            index={index}
            image={get(item, 'primaryImage.url')}
            price={item.originalPrice}
            rating={item.rating}
            auth={this.props.auth}
            type={this.props.type}
          />
        );
      });

    return (
      <Pane className="adds-block mt-0">
        <Pane className="row pt-3 pb-3">{list}</Pane>
      </Pane>
    );
  }
}

ProductList.propTypes = {
  type: PropTypes.string,
};

ProductList.defaultProps = {
  type: '',
};

export default withRouter(ProductList);
