import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import BannerItem from './BannerItem';
import ReviewsVideo from './ReviewsVideo';
import PropTypes from 'prop-types';
import { LoginModal } from "shared/components";

class BannerInfo extends Component {
  static propTypes = {
    onGetReviewsVideo: PropTypes.func,
    onNotification: PropTypes.object,
    onReviewsVideo: PropTypes.func,
    onViewVideo: PropTypes.func,
  };

  state = {
    idVideo: '',
    openModal: false
  };

  renderListVideos(videos) {
    let videoItems = [];
    return videos.map((item,index) => {
      videoItems.push(item);
      if ((index + 1) % 4 === 0) {
        const videoItemsDraw = videoItems;
        videoItems = [];
        return (
          <BannerItem
            items={videoItemsDraw}
            key={'Video' + index}
            type={this.props.type}
            onViewVideo={this.props.onViewVideo}
          />
        );
      } else if (index === videos.length - 1) {
        const videoItemsDraw = videoItems;
        videoItems = [];
        return (
          <BannerItem
            items={videoItemsDraw}
            key={'Video' + index}
            type={this.props.type}
            onViewVideo={this.props.onViewVideo}
          />
        );
      }
      return null;
    });
  }

  renderListVideosResponsive(videos) {
    return videos.map((item, index) => {
      return (
        <BannerItem
          items={item}
          key={'Video' + index}
          type={this.props.type}
          device="responsive"
          onViewVideo={this.props.onViewVideo}
        />
      );
    });
  }

  checkIdVideo = (idVideo) => {
    if(idVideo){
      return idVideo;
    }
  };

  onOpenModal = () => {
		this.setState({
			openModal: true,
		});
	};

  onCloseModal = () => {
		this.setState({
			openModal: false,
		});
	};

  render() {
    const idVideo = this.props.allVideos && this.props.allVideos.length > 0 ? this.props.allVideos[0].id : '';
    return (
      <Pane className="featured-container pt-4">
        <LoginModal 
          onNotification={this.props.onNotification}
          signin={this.props.signin}
          signinSocial={this.props.signinSocial}
          auth={this.props.auth}
          onCloseModal={this.onCloseModal}
          openModal={this.state.openModal}
        />
        <Pane className="container">
          <Pane className="featured-content">
            <Pane className="row row-slider">
              {this.props.allVideos.length <= 0 && (
                <Pane className="empty-container col-lg-8 col-md-8 col-sm-12 col-12" style={{width:'100%'}}>
                  <Pane className="empty-content">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/default-image.png'
                      }
                      alt="empty"
                      className="img-empty"
                    />
                    <h2 className="empty-title">No items</h2>
                  </Pane>
                </Pane>
              )}
              {this.props.allVideos.length > 0 && (
                <Pane className="video-now-slider col-lg-8 col-md-12 col-sm-12 col-12">
                  {this.renderListVideos(this.props.allVideos)}
                </Pane>
              )}
              {this.props.allVideos.length > 0 && (
                <Pane className="video-now-slider-responsive">
                  {this.renderListVideosResponsive(this.props.allVideos)}
                </Pane>
              )}
              <Pane className="col-lg-4 col-md-12 col-sm-12 col-12">
                {this.props.allVideos.length > 0 && this.props.allVideos[0].id !== '' && (
                <ReviewsVideo
                  onGetReviewsVideo={this.props.onGetReviewsVideo}
                  onNotification={this.props.onNotification}
                  reviews={this.props.reviews}
                  id={idVideo}
                  videoData={this.props.allVideos}
                  auth={this.props.auth}
                  onReviewsVideo={this.props.onReviewsVideo}
                  onOpenModal={this.onOpenModal}
                  />
                )}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default BannerInfo;
