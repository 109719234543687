import ListItem from './ListItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';

class Screens extends Component {
  static propTypes = {
    data: PropTypes.array,
    type: PropTypes.string,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
  };

  render() {
    const listScreen =
      this.props.data &&
      this.props.data.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            item={item}
            control={true}
            onDelete={this.props.onDelete}
            id={item.id}
          />
        );
      });
    return (
      <>
        <Pane className="adds-block mt-1">
          <Pane className="row pt-3 pb-3">{listScreen}</Pane>
        </Pane>
      </>
    );
  }
}

export default Screens;
