import React, { Component } from "react";
import PropTypes from "prop-types";
import { Pane } from "shared/components/layouts";
import ListItem from "./ListItem";

class ServiceList extends Component {
  static propTypes = {
    auth: PropTypes.object,
    services: PropTypes.array,
  };

  filterItems = (type = null) => {
    const { services, items } = this.props;

    const list =
      services &&
      services.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            item={item}
            items={items}
            index={index}
            auth={this.props.auth}
            type={this.props.type}
            addStoreBookingsRequest={this.props.addStoreBookingsRequest}
            deleteStoreBookingsRequest={this.props.deleteStoreBookingsRequest}
          />
        );
      });

    return list;
  };

  render() {
    return (
      <Pane className="adds-block block-menu-image-list-view">
        <Pane className="row pt-lg-3 pb-lg-3 align-items-center">
          <Pane className="col-12">
            <Pane className="row">{this.filterItems()}</Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
ServiceList.propTypes = {
  type: PropTypes.string,
};

ServiceList.defaultProps = {
  type: "",
};

export default ServiceList;
