import React, { createRef} from 'react';
import { connect } from 'react-redux';
import { isEmpty } from "lodash";
import { withRouter, Link } from 'react-router-dom';
import { Pane, Loading } from 'shared/components/layouts';
import Currency from 'shared/components/Currency';
import { MasterLayout } from 'components/layout';
import { bindActionCreators } from 'redux';
import { Form, Image, Icon, Button, Card } from "semantic-ui-react";
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Modal from "react-responsive-modal";
import NotificationSystem from 'react-notification-system';
import { getScreensByUserId } from 'actions/screens';
import { InlineMessage } from 'shared/components';
import { createMyDigitalShare } from 'actions/digitalShare';
import { LoginModal } from "shared/components";
import { signin, signinSocial } from 'actions/auth';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import { formatRoute } from 'shared/helpers';
import { Select , Dropdown} from "semantic-ui-react";
import TreeSelect from "rc-tree-select";
import foodOrderAdapter from "services/categories/adapter";
import {
	getFoodOrderCategories,
	getProductCategories,
	getServiceCategories,
  } from "actions/category";
const maxSizeInBytes = 50 * 1024 * 1024; //30 mb
const options = [
	{
	  value: "Food",
	  text: "Food",
	},
	{
	  value: "Product",
	  text: "Product",
	},
	{
	  value: "Service",
	  text: "Service",
	},
  ];
class ScreenAdvPage extends React.Component {

	constructor(props, context) {
		super(props, context);
		this._notificationSystem = null;
		this.state = {
			screen: [],
			loadingScreen: false,
			loading: false,
			images: null,
			imageFiles: null,
			openModal: false,
			data: {
				screen_id: null,
				host_id: null,
				type: null,
			},
			errors: {},
			isVideo: false,
			openModalLogin: false,
			viewState: {
				latitude: -37.7841368,
				longitude: 144.8319178,
				zoom: 14
			},
			geojson: [],
			popupInfo: null,
			isShowMap: false,
			isShowDetail:false,
			screenDetail:{},
			area: {
				state: "",
				city: null
			  },
			states: [],
			cities: [],
			loadingArea: false,
			businessType: "",
			businessCategoryId: null,
			categories: [],
		};
		this.mapRef = createRef();

	}
	nestPath = (data) => {
		if (!isEmpty(data)) {
		  return data.map((item, index) => ({
			index,
			key: item.id,
			pId: item.id,
			title: item.name,
			value: item.id,
			disabled: !isEmpty(item.children),
			children: this.nestPath(item.children),
		  }));
		}
		return [];
	  };
	getAreas = () => {
		this.setState({loadingArea: true});
		this.setState(
			{states: this.getStates(), cities: this.getCities(this.state.area.state)},
			this.setState({loadingArea: false})
		);
	  }
	  getCategory = (type) =>{
		if ( type === "Food" ) {
			this.props
			  .getFoodOrderCategories({
				 group: "Food"
			  })
			  .then((response) => {
				this.setState({
				  categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
					response.data
				  ),
				});
			  });
		  } else
		  if (type === "Product") {
			this.props.getProductCategories({ group: "Product" }).then((response) => {
			  this.setState({
				categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
				  response.data
				),
			  });
			});
		  } else
		  if (type === "Service") {
			this.props.getServiceCategories().then((response) => {
			  this.setState({
				categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
				  response.data
				),
			  });
			});
		  }
	  }
	  getStates = () => {
		return this.props.area.areas.states.map((state) => ({ text: state.name, value: state.isoCode })) || [];
	  }

	  getCities = (stateId) => {
		return this.props.area.areas.cities.filter(city => stateId != '' && city.stateCode == stateId).map((city) => ({ text: city.name, value: city.name })) || [];
	  }

	componentDidMount() {
		this._notificationSystem = this.refs.notificationSystem;
		this.getScreensByUserId();
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.getPosition);
		}
		this.getAreas();
		this.getCategory(this.state.businessType);
	}

	getPosition = (position) => {
		this.setState({ viewState: {...this.state.viewState, latitude:position.coords.latitude, longitude: position.coords.longitude} });
	}

	getScreensByUserId() {
		this.setState({ loadingScreen: true });
		this.props
			.getScreensByUserId({
				activate: 1,
				size: 100,
				admin_active_flg: 1
			})
			.then(() => {
				let screens = [];
				let geojson = [];
				if (this.props.screens && this.props.screens.results.length) {
					screens = this.props.screens.results;
					screens.map((item, index) => {
						if (item.user.business && item.user.business.lng && item.user.business.lat) {
							geojson.push({
								longitude: item.user.business.lng,
								latitude: item.user.business.lat,
								index: index
							})
							this.setState({ geojson: geojson });
						}
					})
				}
				this.setState({ loadingScreen: false });
			})
			.catch(() => {
				this.setState({ loadingScreen: false });
			});
	}

	validate = () => {
		const errors = {};
		const { imageFiles } = this.state;
		if (!imageFiles) {
			errors.images = 'Please upload your file!';
		} else if (imageFiles.size >= maxSizeInBytes) {
			errors.images = 'File size is too large. Please choose a smaller file. Max size 30Mb';
		}
		return errors;
	};

	createMyDigitalShare = () => {
		const { intl, auth } = this.props;
		const errors = this.validate();
		const { data, imageFiles } = this.state;

		this.setState({ errors });
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true });

			const dataForm = new FormData();
			if (imageFiles && imageFiles.type) {
				dataForm.append(`image_${data.type.toLowerCase()}`, imageFiles || "");
			}
			dataForm.append("screen_id", data.screen_id || "");
			dataForm.append("host_id", data.host_id || "");
			dataForm.append("renter_id", auth.user.id || "");

			this.props
				.createMyDigitalShare(dataForm)
				.then(() => {
					this.setState({
						loading: false,
						openModal: false,
						images: [],
						imageFiles: [],
						data: {},
					});
					this._notificationSystem.addNotification({
						message: intl.formatMessage({ id: 'notification.successfully' }),
						level: 'success',
					});
				})
				.catch((error) => {
					const errors = {};
					if(error.response && error.response.data && error.response.data.errors && error.response.data.errors.length){
						this._notificationSystem.addNotification({
							message: error.response.data.errors[0].detail,
							level: 'error',
						});
						errors.images = error.response.data.errors[0].detail;
					} else {
						this._notificationSystem.addNotification({
							message: intl.formatMessage({ id: 'notification.please_try_again' }),
							level: 'error',
						});
					}
					this.setState({
						loading: false,
						errors,
					});
				});
		}
	};

	onChangeImage = (e) => {
		const file = e.target.files[0];
		let errors = {};
		if (file) {
			const reader = new FileReader();
			if (file.size >= maxSizeInBytes) {
				errors.images = 'File size is too large. Please choose a smaller file. Max size 30Mb';
			}
			this.setState({ errors });
			reader.onloadend = () => {
				this.setState({
					images: URL.createObjectURL(file),
					imageFiles: file,
				});
				if (file.type.includes("video")) {
					this.setState({ isVideo: true }, () => {
						let video = document.querySelector("#idVideo");
						video.setAttribute("src", URL.createObjectURL(file))
					});
				} else {
					this.setState({ isVideo: false });
				}
			}
			reader.readAsDataURL(file);
		}
	};

	onCloseModal = () => {
		this.setState({
			openModal: false,
			data: {},
			images: null,
			imageFiles: null,
			isVideo: false,
			errors: {}
		});
	};

	onOpenModalLogin = () => {
		this.setState({ openModalLogin: true });
	};

	onCloseModalLogin = () => {
		this.setState({ openModalLogin: false });
	};

	onOpenModal = (item) => {
		if (!this.props.auth.user.id) {
			this.onOpenModalLogin();
			return false;
		}

		this.setState({
			openModal: true,
			data: { screen_id: item.id, host_id: item.user.id, type: item.type }
		});
	};
	onOpenDetail = (item, index)=>{

		const data = this.props.screens.results[index];
		this.setState({ isShowDetail: true , screenDetail:data});
	}
	renderGeo = (item, index) => {
		return (
			<Marker key={`marker-${index}`} longitude={item.longitude} latitude={item.latitude}>
				<img className="marker-img"
				onMouseOut={() => this.updatePopupInfo(null)}
				onMouseOver={() => this.updatePopupInfo(item)} src={process.env.PUBLIC_URL + '/assets/images/icon-tv.png'}
				onClick={() => this.onOpenDetail(item, index)}
				 />
			</Marker>
		)
	}

	_renderPopup() {
		const { popupInfo } = this.state;

		if (popupInfo) {
			let user = this.props.screens.results[popupInfo.index].user;

			return (
				popupInfo && (
					<Popup
						tipSize={5}
						anchor="bottom"
						longitude={popupInfo.longitude}
						latitude={popupInfo.latitude}
						closeOnClick={false}
						onClose={() => this.setState({ popupInfo: null })}
						style={{ borderRadius: "20px" }}
					>
						<Card>
							<Image src={user.image.url} wrapped ui={false} />
							<Card.Content>
								<Card.Header><Link to={`/${user.id}`} target="_blank">{user.business.name}</Link></Card.Header>
								<Card.Meta>
									<span className='date'>{user.business.address}</span>
								</Card.Meta>
							</Card.Content>
						</Card>
					</Popup>
				)
			);
		}
	}
	filterCategory = (value) => {
		this.setState({businessType: value});
		this.getCategory(value)
	}
	filterArea = (type, value) => {
		const { area } = this.state;
		if (type === 'State') {
		  this.setState({ area: { ...area, state: value, city: null } }, () => {
			let cities = [];
			if (this.props.area.areas.cities) {
			  cities = [{text: 'Select city', value: null}, ...this.getCities(value)];
			}
			this.setState({cities: cities});
		  })
		} else {
		  this.setState({ area: { ...area, city: value } }, () => {
			//this.props.onAddArea(this.state.area)
			//this.search();
		  })
		}
	  }
	updatePopupInfo = (item) => {
		let popupInfo = null;
		if(item != null){
			popupInfo = {
				longitude: item.longitude,
				latitude: item.latitude,
				index: item.index
			}
		}
		this.setState({ popupInfo: popupInfo });
	};
	dateDiffDay = (date) =>{
		const today = new Date();
		const date2 = new Date(date);
		const diffTime =  (today - date2);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays <= 1;
	}
	onClickBtnSearch = (eventType) =>{

		if(eventType == 'Search'){
		this.props
			.getScreensByUserId({
				activate: 1,
				size: 100,
				state: this.state.area.state,
				city: this.state.area.city,
				bus_type: this.state.businessType,
				category_id: this.state.businessCategoryId,
				filter: 1,
				admin_active_flg: 1
			})
			.then(() => {
				let screens = [];
				let geojson = [];
				if (this.props.screens && this.props.screens.results.length) {
					screens = this.props.screens.results;

					screens.map((item, index) => {
						if (item.user.business && item.user.business.lng && item.user.business.lat) {
							geojson.push({
								longitude: item.user.business.lng,
								latitude: item.user.business.lat,
								index: index
							})
							this.setState({ geojson: geojson });
						}
					})
				}
				//this.setState({ loadingScreen: false });
			})
			.catch(() => {
				this.setState({ loadingScreen: false });
			});
		} else {
			this.setState({
				area: {
					state: "",
					city: null
				  },
				businessType: "",
				businessCategoryId: null,
			});
			this.getScreensByUserId();
		}
	}
	onClickScreen = (item) =>{

		this.mapRef.current.flyTo({center: [item.user.business.lng, item.user.business.lat], zoom: 14});
		this.setState({ isShowDetail: true , screenDetail:item});
		if(window.innerWidth <= 768){
			this.setState({ isShowMap: !this.state.isShowMap })
		}
	}
	onSelectTreeSelect = (value) => {
		this.setState({ businessCategoryId: value});
	  };
	render() {
		const { loadingScreen, errors, openModal, loading, data,
			 openModalLogin, geojson, viewState, isShowMap,
			 isShowDetail, screenDetail, area, businessType,
			 businessCategoryId, categories} = this.state;
		const { screens, auth, signin, signinSocial } = this.props;

		 const listScreen = screens && screens.results.map((item, index) => {

			return (
				<Pane key={index} className={`screen ${index == 0 ? 'active' : ''}`}  onClick={() => this.onClickScreen(item)} >
					<Pane className="screen-img">
						<img src={item.primaryImage ? item.primaryImage.url : `${process.env.PUBLIC_URL}/assets/images/default-image.png`} alt="" />
						{this.dateDiffDay(item.createdAt) && (<Pane className='new-label'><span>New</span></Pane>)}
						<Pane className="screen-price">
							<Pane>
								<Currency price={(item.price)} /> /play
							</Pane>
						</Pane>
						<Pane className="screen-price-mobile">
							<Pane>
								<Currency price={(item.price)} /> /play-22cwww
							</Pane>
						</Pane>
					</Pane>
					<Pane className="screen-info">
						<Pane className="screen-name">{item.name} - <em className="store-address">{item.type}</em></Pane>
						<Pane>
							<Link to={`/${item.user ? item.user.id : ''}`} target="_blank">
							<span className="store-name">{item.user ? item.user.business.name : ''}</span>
							</Link>
						</Pane>
						<Pane className="screen-name"><em className="store-address">{item.user ? item.user.business.category.group : ''}</em> - <em className="store-address">{item.user ? item.user.business.category.name : ''}</em></Pane>
						<Button
							size="mini"
							icon
							className="btn btn-claim"
							onClick={() => this.onOpenModal(item)}
						>
							Request Share
						</Button>
						<Pane className="store-address">
							<em><span className="icon-location"></span> {item.user ? item.user.business.address : ''}</em>
						</Pane>
					</Pane>
				</Pane>
			)
		}) ;
		return (
			<MasterLayout>
				<Pane className="screen-ads">
					<LoginModal
						onNotification={this._notificationSystem}
						signin={signin}
						signinSocial={signinSocial}
						auth={auth}
						onCloseModal={this.onCloseModalLogin}
						openModal={openModalLogin}
						isCheckout={true}
					/>
					{!loadingScreen ? (
						<Pane className="map">
							<Pane className={`result ${isShowMap ? 'is-show' : ''}`}>
								<Pane><a className='ui mini icon button btn btn-claim btn-back-store' href={`/u/${formatRoute( auth.user.displayName )}`} ><i className="fas fa-backward"></i> Back to Stores</a></Pane>
								<Pane className="main-title">
									Screen Advertising Share <Icon onClick={() => this.setState({ isShowMap: !isShowMap })} name={`${isShowMap ? 'list ul' : 'map outline'}`} />
								</Pane>
								{!this.state.loadingArea && (
								<Pane className="mb-3 search-box">
								<Select
									placeholder='Select state'
									value={area.state}
									options={this.state.states}
									onChange={(e, { value }) => this.filterArea('State', value)}
									className="mr-2 select-box"
								/>
								<Dropdown
									placeholder='Select suburb/city'
									value={area.city}
									onChange={(e, { value }) => this.filterArea('City', value)}
									options={this.state.cities}
									className="mr-2 select-box"
									fluid
									search
									selection
								/>

								<Select
									placeholder='Select Type'
									value={businessType}
									onChange={(e, { value }) => this.filterCategory(value)}
									options={options}
									className="mr-2 select-box"
								/>
								<TreeSelect
									className="mr-2 select-box tree-select"
									transitionName="rc-tree-select-dropdown-slide-up"
									choiceTransitionName="rc-tree-select-selection__choice-zoom"
									dropdownStyle={{
										maxHeight: 300,
										overflow: "auto",
									}}
									treeNodeFilterProp="title"
									placeholder={<i>Select</i>}
									searchPlaceholder="Search..."
									treeDefaultExpandAll
									treeData={this.nestPath(categories)}
									value={businessCategoryId}
									onChange={() =>this.onMultipleChange}
                              		onSelect={(value) => this.onSelectTreeSelect(value)}
									allowClear
									/>
									<Button className='mr-2 ui mini icon button btn btn-claim btn-filter' type='button'
										onClick={() => this.onClickBtnSearch('Reset')}
									>
										<i className="fas fa-retweet"></i> Reset
									</Button>
									<Button className=' mr-2 ui mini icon button btn btn-claim btn-filter' type='button'
										onClick={() => this.onClickBtnSearch('Search')}
									>
										<i className="fas fa-filter"></i> Apply Filter
									</Button>
								</Pane>
								)}
								{screens.results.length?listScreen:(<h2>Data not found</h2>)}
							</Pane>
							{process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN && (
								<ReactMapGL
									ref={this.mapRef}
									initialViewState={viewState}
									mapboxAccessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}
									mapStyle="mapbox://styles/summap/cljsdhrgw018w01r59jklecqo"
									>
									{geojson.map(this.renderGeo)}
									{this._renderPopup()}

								</ReactMapGL>
							)}
							{isShowDetail &&
								<Pane className='screen-detail'>
									<Card>
										<Card.Content>
											<Card.Header>
											<Pane className="dt-btn-close">
												<Button
													size="mini"
													icon
													className="btn btn-claim"
													onClick={()=> this.setState({ isShowDetail: !isShowDetail })}
												>
													<i className="far fa-times-circle"></i>
												</Button>
											</Pane>
												<span className='business-name'>{screenDetail.user.business.name}</span>
											</Card.Header>
											<Card.Meta>
												<Pane  >
													<Pane className="screen-img">
														<img src={screenDetail.primaryImage ? screenDetail.primaryImage.url : `${process.env.PUBLIC_URL}/assets/images/default-image.png`} alt="" />

													</Pane>
													<Pane className="screen-info">
														<Pane className="screen-name">{screenDetail.name} - <em className="store-address">{screenDetail.type}</em></Pane>
														<Link to={`/${screenDetail.user.id}`} target="_blank">
															<span className="store-name"><strong>{screenDetail.user.business.name}</strong></span></Link>
														<Pane className="screen-price">
															<Pane>
																<Currency price={(screenDetail.price)} /> /play
															</Pane>
														</Pane>
														{ screenDetail.items && screenDetail.items.length && screenDetail.shareSlot &&
															<Pane>
																<strong>Available slot</strong> {screenDetail.shareSlot - screenDetail.items.length}
															</Pane>
														}
														<Pane className="store-address">
															<em><span className="icon-location"></span> {screenDetail.user.business.address}</em>
														</Pane>

														<Pane className="store-address">
															<em>{screenDetail.user.business.description}</em>
														</Pane>
														<Link target="_blank" to={{pathname:`https://www.google.com/maps?q=${screenDetail.user.business.address}`}}>
															<strong>Map view
																<div className="box-icon" data-tip data-for={`location`}>
																	<span className="icon-map" />
																</div>
															</strong>
														</Link><br/>
														<Pane className="tag">
															<span className='type'>{screenDetail.user.business.category.group}</span> -
															<span className='category'>{screenDetail.user.business.category.name}</span>
														</Pane>
														<Button
															size="mini"
															icon
															className="btn btn-claim btn-request"
															onClick={() => this.onOpenModal(screenDetail)}
														>
															Request Share
														</Button>

													</Pane>
												</Pane>
											</Card.Meta>
										</Card.Content>
									</Card>
								</Pane>
							}
						</Pane>
					) : (
						<Loading />
					)}
					<Modal
						open={openModal}
						onClose={this.onCloseModal}
						center
						showCloseIcon={true}
					>
						<Pane className="login-container popup-login rounded">
							<Form className="form-overview pb-2" onSubmit={this.createMyDigitalShare}>
								<Form.Field className="form-item text-center">
									<input
										type="file"
										id="image-menu"
										className="form-input-file"
										onChange={this.onChangeImage}
									/>
									<br />
									<label htmlFor="image-menu" className="form-label-file">
										<img
											src={
												process.env.PUBLIC_URL +
												`/assets/images/icons/upload.svg`
											}
											alt="upload"
											className="icon"
										/>
										Upload a photo/video
									</label>
									<Pane
										className="slider-content-main d-flex"
										style={{ padding: "10px 0" }}
									>
										<>
											{!this.state.isVideo ? (
												<Pane
													className="slide-item"
													style={{ float: "left" }}
												>
													<Image src={this.state.images} size="small" />
												</Pane>
											) : (
												<Pane
													className="slide-item"
													style={{ float: "left" }}
												>
													<video width="150" controls type='video/mp4' id="idVideo"></video>
												</Pane>
											)}
										</>
									</Pane>
								</Form.Field>
								{errors.images && <InlineMessage text={errors.images} />}
								<Pane className="row">
									<Pane className="col-lg-12 text-center">
										<Button
											type="submit"
											className="btn btn-save mt-4"
											disabled={loading}
											loading={loading}
										>
											Request
										</Button>
									</Pane>
								</Pane>
								<Pane className="text-warning-upload">
									Please upload file with type {data.type}!<br/>
								</Pane>
								{
									data.type == 'Horizontal' &&
									<Pane>
									This image is oriented horizontally, with its width being greater than its height.
									</Pane>

								}
								{
									data.type == 'Vertical' &&
									<Pane>
									This picture is oriented vertically, with its height being greater than its width.
									</Pane>

								}
							</Form>
						</Pane>
					</Modal>
				</Pane>
				<NotificationSystem ref="notificationSystem" />
			</MasterLayout>
		);
	}
}
const mapStateToProps = (state) => ({
	screens: state.screens,
	auth: state.auth,
	area: state.area
});

const mapDispatchToProps = (dispatch) => ({
	getScreensByUserId: bindActionCreators(getScreensByUserId, dispatch),
	createMyDigitalShare: bindActionCreators(createMyDigitalShare, dispatch),
	signin: bindActionCreators(signin, dispatch),
	signinSocial: bindActionCreators(signinSocial, dispatch),
	getProductCategories: bindActionCreators(getProductCategories, dispatch),
	getServiceCategories: bindActionCreators(getServiceCategories, dispatch),
  	getFoodOrderCategories: bindActionCreators(getFoodOrderCategories, dispatch),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(injectIntl(ScreenAdvPage)));
