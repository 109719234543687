import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Validator from "validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { subScribe } from "actions/auth";
import { Button } from "semantic-ui-react";
import NotificationSystem from "react-notification-system";
import { injectIntl, FormattedMessage } from "react-intl";

class Newsletter extends Component {
  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.state = {
      open: false,
      email: "",
      loading: false,
    };
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
    setTimeout(() => {
      let count = sessionStorage.getItem("countNewSletter");
      if (!count) {
        sessionStorage.setItem("countNewSletter", 1);
      } else if (parseInt(count) === 1) {
        sessionStorage.setItem("countNewSletter", parseInt(count) + 1);
      } else if (parseInt(count) === 2) {
        return false;      
      }
      this.setState({
        open: !localStorage.getItem("hiddenNewSletter"),
      });
    }, 30000);
  }

  onCancel = () => {
    this.setState({
      open: false,
    });
  };

  onChangeCheckbox = (e) => {
    localStorage.setItem("hiddenNewSletter", e.target.checked);
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = (email) => {
    const errors = {};
    const { intl } = this.props;

    if (!email) {
      errors.email = intl.formatMessage({ id: "validate.require_field" });
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: "validate.require_field" }),
        level: "error",
      });
    }
    if (email && !Validator.isEmail(email)) {
      errors.email = intl.formatMessage({ id: "validate.invalid_email" });
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: "validate.invalid_email" }),
        level: "error",
      });
    }
    return errors;
  };

  onSubmit = (event) => {
    event.preventDefault();
    let errors = {};
    errors = this.validate(this.state.email);
    const { intl } = this.props;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loading: true,
      });
      this.props.subScribe({ email: this.state.email }).then(() => {
        this._notificationSystem.addNotification({
          message: intl.formatMessage({
            id:
              "notification.you_have_successfully_signed_up_for_the_newsletter",
          }),
          level: "success",
        });
        this.setState({
          loading: false,
          open: false,
        });
      });
    }
  };

  render() {
    const { loading } = this.state;
    const { intl } = this.props;

    return (
      <>
        <Modal
          className="popup-qrcode"
          open={this.state.open}
          center
          showCloseIcon={false}
          onClose={this.onCancel}
        >
          <div className="popup-container">
            <span className="icon ic-common-close" onClick={this.onCancel} />
            <div className="popup-more">
              <img
                src={`${process.env.PUBLIC_URL
                  }/assets/images/layouts/logo-footer.png`}
                className="logo"
                alt="caterwin logo"
              />
              <img
                src={`${process.env.PUBLIC_URL
                  }/assets/images/popup/best-deal.png`}
                className="best-deal"
                alt="best-deal"
              />
            </div>
            <div className="popup-content text-center pr-2 pl-2  pr-lg-4 pl-lg-4  pr-md-4 pl-md-4 pr-sm-4 pl-sm-4">
              <h2 className="content-title">
                <FormattedMessage
                  id="sign_up_newsletter"
                  defaultMessage="SIGN UP NEWSLETTER"
                />
              </h2>
              <p className="content-norm">
                <FormattedMessage
                  id="sign_up_our_newsletter_and_save_25_off_for_the_next_purchase"
                  defaultMessage="Sign up our newsletter and save 25% off for the next purchase!"
                />
              </p>
              <p className="content-norm">
                <FormattedMessage
                  id="subscirbe_to_our_newsletters"
                  defaultMessage="Subscirbe to our newsletters and don't miss new arrivals, the
                latest fashion updates and our promotions."
                />
              </p>
              <form
                className="content-form mt-0 mt-lg-2"
                onSubmit={this.onSubmit}
              >
                <div className="form-item">
                  <input
                    type="text"
                    className="form-input text-center"
                    name="email"
                    placeholder={intl.formatMessage({
                      id: "enter_your_email_address",
                    })}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-item form-button mt-2">
                  <Button
                    type="submit"
                    className="btn btn-circle btn-subcribe"
                    loading={loading}
                  >
                    <FormattedMessage
                      id="subscribe"
                      defaultMessage="SUBSCRIBE"
                    />
                  </Button>
                </div>
              </form>
              <p className="content-norm text-italic mt-2">
                <FormattedMessage
                  id="your_information_will_never_be_shared_with_any_third_party"
                  defaultMessage="Your information will never be shared with any third party."
                />
              </p>
              <label className="label-checbox">
                <FormattedMessage
                  id="dont_show_this_popup_again"
                  defaultMessage="DONT' SHOW THIS POPUP AGAIN"
                />
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={this.onChangeCheckbox}
                />
              </label>
            </div>
          </div>
        </Modal>
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

Newsletter.propTypes = {};
const bindDispatchToProps = (dispatch) => ({
  subScribe: bindActionCreators(subScribe, dispatch),
});

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(injectIntl(Newsletter));
