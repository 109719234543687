import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { MasterLayout } from 'components/layout';
import { SigninForm } from 'components/scenes/signin';
import { signin, signinSocial } from 'actions/auth';
import { updateInfo } from 'actions/user';
import { Pane } from 'shared/components/layouts';

class SigninPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    auth: PropTypes.object.isRequired,
    signin: PropTypes.func.isRequired,
    updateInfo: PropTypes.func.isRequired,
    signinSocial: PropTypes.func.isRequired,
  };

  render() {
    const redirect = queryString.parse(this.props.location.search).redirect;

    return (
      <MasterLayout>
        <Pane className="login-container">
          <Pane className="login-more" />
          <Pane className="wrap-login d-flex align-items-center justify-content-center flex-column">
            <h3 className="login-title">Sign In</h3>
            <SigninForm
              signin={this.props.signin}
              signinSocial={this.props.signinSocial}
              auth={this.props.auth}
              history={this.props.history}
              redirect={redirect}
              guestCart={this.props.guestCart}
              updateInfo={this.props.updateInfo}
            />
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  guestCart: state.guestCart,
});

const bindDispatchToProps = (dispatch) => ({
  signin: bindActionCreators(signin, dispatch),
  updateInfo: bindActionCreators(updateInfo, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(SigninPage);
