import { Form, Button, Comment } from 'semantic-ui-react';
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { InlineMessage } from 'shared/components';


class ReviewModal extends Component {
	static propTypes = {
		onGetReviewsVideo: PropTypes.func,
		onNotification: PropTypes.object,
		intl: intlShape.isRequired,
		onReview: PropTypes.func,
	};

	constructor(props, context) {
		super(props, context);

		this.state = {
			comment: '',
			isRequired: true,
			loading: false,
			reviews: {
				results: []
			}
		};
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
			isRequired: e.target.value ? false : true
		});
	};

	onSubmit = () => {
		if (!this.props.auth.user.id) {
			this.props.onOpenModal('login')
			return false;
		}

		const { intl, idStore } = this.props;

		const data = {
			id: idStore,
			comment: this.state.comment,
			model: 'User',
		};

		this.setState({ loading: true });
		this.props.onReview(data).then(() => {
			this.setState({
				loading: false,
				comment: '',
			});
			this.props.getDetail();
			this.props.onNotification.addNotification({
				message: intl.formatMessage({
					id: 'notification.thank_for_review',
				}),
				level: 'success',
			});
		})
			.catch(() => {
				this.setState({ loading: false });
				this.props.onNotification.addNotification({
					message: intl.formatMessage({ id: 'notification.please_try_again' }),
					level: 'error',
				});
			});
	}

	render() {
		const { comment, loading, isRequired } = this.state;
		const {
			nameBanner,
			reviews,
			openModal,
			onCloseModal
		} = this.props;
		return (
			<Modal
				open={openModal}
				onClose={onCloseModal}
				center
				showCloseIcon={true}
			>
				<Pane className="login-container popup-review">
					<Pane className="w-100 d-flex justify-content-center flex-column">
						<h2 className="review-title pt-2 text-center"><em>{nameBanner}</em></h2>
						<Form onSubmit={this.onSubmit} className="mt-2 w-100">
							<Pane className="comment-form">
								<Form.Field className="form-item mt-0">
									<textarea
										placeholder="Write your own review"
										name="comment"
										className="w-100 p-3"
										rows={3}
										value={comment}
										onChange={this.onChange}
									/>
								</Form.Field>
							</Pane>
							<Pane className="comment-form">
								<Button
									size="tiny"
									disabled={loading || isRequired}
									loading={loading}
								>
									<FormattedMessage id="post" defaultMessage="Post" />
								</Button>
							</Pane>
						</Form>
						<Pane className="list-comment mt-4">
							{reviews && reviews.results.length > 0 ? (
								<Comment.Group>
									{reviews.results.map((item, index) => {
										return (
											<Pane className="comment-item" key={index}>
												<Pane className="comment-avatar col-2">
													<img
														src={item.user.profileImage}
														alt="ava1"
														className="comment-img"
													/>
												</Pane>
												<Pane className="comment-content col-10">
													<h2 className="comment-name">{item.user.name}</h2>
													<p className="comment-norm">{item.comment}</p>
												</Pane>
											</Pane>
										);
									})}
								</Comment.Group>
							) : (
								<Pane className="block-no-content">
									<p>
										<FormattedMessage
											id="notification.no_comments"
											defaultMessage="No comments"
										/>
									</p>
								</Pane>
							)}
						</Pane>
					</Pane>
				</Pane>
			</Modal>
		);
	}
}

export default withRouter(injectIntl(ReviewModal));
