import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Pane } from 'shared/components/layouts';
import { getBusinesses } from 'actions/business';
// import { Map, GoogleApiWrapper,Marker } from 'google-maps-react';
import moment from 'moment';
import BusinessAdapter from '../../../../services/businesses/adapter';

class LeftArea extends Component {
  static propTypes = {
    businesses: PropTypes.object.isRequired,
    display: PropTypes.string,
  };
  state = {
    lat: 10.796073,
    lng: 106.700546,
    id: -1,
    date: new Date(),
    numbersecond: 1,
    business: null,
    index: 0,
    showNotification: false,
    businesses: null,
  };

  componentDidMount() {
    this.getInfoBusiness();
    this.showNotificationWithTimeOut();
  }

  showNotificationWithTimeOut() {
    setTimeout(() => {
      this.showNotificationWithTimeOut();
      this.showNotification();
    }, 1000 * 10);
  }

  showNotification() {
    if (this.state.businesses == null) {
      return;
    }
    let index = this.state.index;
    const businesses = this.state.businesses;
    if (index >= businesses.length) {
      index = 0;
    }
    const business = businesses[index].business;
    index += 1;
    //* 2020/04/12 duc-dcm disable function getData (location of store) (S)*/
    //this.getData(business.address,business , index );
    //show notification
    this.setState(
      {
        // lat: location.lat,
        // lng: location.lng,
        business,
        index,
        showNotification: true,
        date: business.updatedAt,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showNotification: false,
          });
        }, 1000 * 3);
      }
    );
    //* 2020/04/12 duc-dcm disable function getData (location of store) (E)*/
  }

  getInfoBusiness() {
    const length =
      this.state.businesses != null ? this.state.businesses.length : 0;
    this.props.getBusinesses().then((res) => {
      const business = BusinessAdapter.browse.parseResponse(res.data);
      if (business.length !== length) {
        // this.props.businesses = business
        this.setState({
          index: 0,
          businesses: business,
        });
      }
    });
  }

  getData(address, business, index) {
    // create a new XMLHttpRequest
    const xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      // update the state of the component with the result here
      const location = JSON.parse(xhr.responseText).results[0].geometry.location;
      this.setState(
        {
          lat: location.lat,
          lng: location.lng,
          business,
          index,
          showNotification: true,
          date: business.updatedAt,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showNotification: false,
            });
          }, 1000 * 3);
        }
      );
    });
    // open the request with the verb and the url
    xhr.open(
      'POST',
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        encodeURIComponent(address) +
        '&key=AIzaSyBYqW220YCixHrf3wQzwKYMtj2JjZqb3Xs'
    );
    // send the request
    xhr.send();
  }

  render() {
    const startDate = moment(this.state.date);
    const timeEnd = moment();
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    let stringDate = '';
    if (diffDuration.days() > 0) {
      stringDate = diffDuration.days() + ' days ago by';
    } else if (diffDuration.hours() > 1) {
      stringDate = diffDuration.hours() + ' hours ago by';
    } else if (diffDuration.minutes() > 0) {
      stringDate = diffDuration.minutes() + ' minutes ago by';
    } else {
      stringDate = ' 1 minute ago by';
    }
    //notification
    const classNotificationCss =
      'leftfloating ' +
      (this.state.showNotification ? 'notification-show' : 'notification-hide');
    return (
      <Pane className={classNotificationCss} id="leftfloatingarea">
        {this.state.businesses != null &&
          this.state.businesses.length > 0 && (
          <Pane>
            {/* 2020/04/12 disable show map */}
            {/* <Map
              google={this.props.google}
              style={mapStyles}
              initialCenter= {{lat: this.state.lat, lng: this.state.lng}}
              center= {{lat: this.state.lat, lng: this.state.lng}}
              zoom={25} >
               <Marker style= {markerStyles}
                title={this.state.business != null? this.state.business.name : ""}
                name={this.state.business != null? this.state.business.name : ""}
                position={{lat: this.state.lat, lng: this.state.lng}} />
              </Map> */}
            <img
              src={process.env.PUBLIC_URL + 'assets/images/demo.png'}
              alt={
                this.state.business != null ? this.state.business.name : ''
              }
            />
            {/* 2020/04/12 disable show map */}
            <Pane className="content">
              <h3>
                <b>
                  {this.state.business != null
                    ? this.state.business.name
                    : ''}
                </b>
              </h3>
              <p className="first">
                {this.state.business != null
                  ? this.state.business.address
                  : ''}
              </p>
              <p className="second">
                {stringDate}
                <span className="textblue">Analisist</span>
              </p>
            </Pane>
          </Pane>
        )}
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  businesses: state.businesses,
});

const bindDispatchToProps = (dispatch) => ({
  getBusinesses: bindActionCreators(getBusinesses, dispatch),
});

//export default GoogleApiWrapper({ apiKey: 'AIzaSyA-SzNwPOuCBEbag7O8u53hO6roXlC1PZo' }) (connect(bindStateToProps, bindDispatchToProps)(injectIntl(LeftArea)))
export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(LeftArea));
