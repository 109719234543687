import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { get } from 'lodash';
import { Pane } from 'shared/components/layouts';
import Config from 'constants/Config';
import { formatRoute } from 'shared/helpers';

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    play: 0,
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    video.play();
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  onChooseVideo = () => {
    this.props.onChooseVideo(this.props.item);
    this.props.onReviewsVideo({
      id: this.props.item.id,
      type: Config.VIDEO_TYPE,
    });
    this.setState({
      play: 0,
    });
  };

  render() {
    const { name, image, viewVideo, likeVideo } = this.props;
    const ext = image ? image.substr(image.lastIndexOf('.') + 1) : '';
    const slug = get(this.props, 'item.user.business.name')
      ? formatRoute(get(this.props, 'item.user.business.name'), '-')
      : '';
    return (
      <Pane className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
        <Pane className="adds-item">
          <Pane style={{width: '100%'}} onClick={this.onChooseVideo}>
            <Pane
              className="adds-videos-wrapper" style={{height: '100px'}}
            >
              {ext !== '' ? <i className="fa fa-video" /> : ''}
              <video
                className="myvideo-list"
                onMouseOver={this.playVideo}
                onMouseOut={this.stopVideo}
                muted={!this.state.play}
              >
                <source src={image} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            </Pane>
          </Pane>
          <Pane className="adds-content pl-2" style={{cursor: 'unset'}}>
            {slug !== '' && (
              <Link to={`/${slug}/videos/${this.props.item.id}`}>
                <Pane className="row">
                  <Pane className="col-12 d-flex p-0 align-items-center">
                    <img
                      src={get(this.props.item, 'user.image.url')}
                      alt="sell"
                      className="avatar-img"
                      style={{
                        width: 25,
                        height: 25,
                        border: '1px solid red',
                        borderRadius: '25px',
                        backgroundColor: '#303130',
                      }}
                    />
                    <Pane className="adds-norm p-0 ml-2" title={name}>
                      {name}
                    </Pane>
                  </Pane>
                  <Pane className="col-12 p-0">
                    <Pane
                      className="adds-name"
                      user={get(this.props.item, 'user.fullname')}
                    >
                      {get(this.props.item, 'user.fullname')}
                    </Pane>
                    <Pane className="row adds-norm p-0" title={viewVideo}>
                      {viewVideo} views
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/assets/images/icons/like-gray.svg'
                        }
                        alt="image"
                        aria-hidden
                        className="icon"
                        style={{
                          height: 16,
                          margin: '0 5px 4px 10px',
                        }}
                      />
                      {likeVideo}
                    </Pane>
                  </Pane>
                </Pane>
              </Link>
            )}
            {slug === '' && (
              <Pane className="row">
                <Pane className="col-12 d-flex p-0 align-items-center">
                  <img
                    src={get(this.props.item, 'user.image.url')}
                    alt="sell"
                    className="avatar-img"
                    style={{
                      width: 25,
                      height: 25,
                      border: '1px solid red',
                      borderRadius: '25px',
                      backgroundColor: '#303130',
                    }}
                  />
                  <Pane className="adds-norm p-0 ml-2" title={name}>
                    {name}
                  </Pane>
                </Pane>
                <Pane className="col-12 p-0">
                  <Pane
                    className="adds-name"
                    user={get(this.props.item, 'user.fullname')}
                  >
                    {get(this.props.item, 'user.fullname')}
                  </Pane>
                  <Pane className="row adds-norm p-0" title={viewVideo}>
                    {viewVideo} views
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/icons/like-gray.svg'
                      }
                      alt="image"
                      aria-hidden
                      className="icon"
                      style={{
                        height: 16,
                        margin: '0 5px 4px 10px',
                      }}
                    />
                    {likeVideo}
                  </Pane>
                </Pane>
              </Pane>
            )}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
