import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dimmer, Icon, TextArea, Button } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import NotificationSystem from 'react-notification-system';
import { Pane, Loading } from 'shared/components/layouts';
import { getBuyingOrderDetail, requestCancel } from 'actions/orders';
import { getNotisByUser } from 'actions/notis';
import { logout } from 'actions/auth';
import { pushNotification } from 'actions/notification';
import OrderSummary from './OrderSummary';
import OrderSubDetail from './OrderSubDetail';
import OrderedCartList from './OrderedCartList';
import { get, isEmpty } from "lodash";
import Config from 'constants/Config';
import { handleError } from 'shared/helpers';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-responsive-modal';
import { Breadcumb } from '../../../common/breadcumb';
import { formatRoute } from 'shared/helpers';

class BuyingOrderDetail extends Component {
  static propTypes = {
    getBuyingOrderDetail: PropTypes.func,
    pushNotification: PropTypes.func,
    requestCancel: PropTypes.func,
    userInfo: PropTypes.object,
    business: PropTypes.object,
    logout: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    loading: false,
    data: this.props.data || {},
    reason: '',
    open: false,
    openReason: false,
    errors: {},
    loadingRequestCancel: false,
    loadingAcceptCancel: false,
    loadingStatus: false,
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  getBuyingOrderDetail = (load) => {
    const orderId =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.orderId;

    this.setState({ loading: load });
    this.props
      .getBuyingOrderDetail({ id: orderId })
      .then(() => {
        if (load && this.props.detail.updateRead) {
          this.props.getNotisByUser({id: this.props.userInfo.id});
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.getBuyingOrderDetail(true);

    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      errors: {},
    });
  };

  onOpenReasonModal = () => {
    this.setState({
      openReason: true,
    });
  };

  onCloseReasonModal = () => {
    this.setState({
      openReason: false,
    });
  };

  requestCancel = () => {
    const { intl, detail } = this.props;
    // const errors = {}
    const orderId = this.props.match.params.orderId;
    const params = {
      id: orderId,
      reason: this.state.reason,
    };

    const data = detail && detail.cartItems && detail.cartItems.length > 0 && detail.cartItems[0];

    this.setState({ loadingRequestCancel: true });
    this.onCloseModal();
    this.props
      .requestCancel(params)
      .then((response) => {
        this.getBuyingOrderDetail(false);

        this.setState({
          loadingRequestCancel: false,
        });

        // Push notification to seller
        // this.props
        //   .pushNotification({
        //     object_id: orderId,
        //     object_type: 'Order',
        //     target_id: data.seller.id,
        //     target_type: 'Seller',
        //     summary: `Request cancel in Order #${orderId}`,
        //     meta: {
        //       order_type: 'selling',
        //       order_id: orderId,
        //       url: `/orders/${orderId}/selling`,
        //     },
        //   })
        //   .catch();

        this._notificationSystem.addNotification({
          message: intl.formatMessage({
            id: 'notification.you_have_sent_request_successfully',
          }),
          level: 'success',
        });
      })
      .catch((errors) => {
        this.setState({ loadingRequestCancel: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({
              id: 'notification.can_not_request_cancel_this_product',
            })
          ),
          level: 'error',
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      this.props &&
      prevProps.match.params.orderId !== this.props.match.params.orderId
    ) {
      this.getBuyingOrderDetail(true);
    }
  }

  render() {
    const { detail } = this.props;
    const { loading } = this.state;
    const data = detail && detail.cartItems && detail.cartItems.length > 0 && detail.cartItems[0];
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/orders`,
        name: 'Orders'
      },
      {
        url: ``, name: 'Order detail', active: true
      }
    ];
    return (
      <Pane className="overview-container">
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="product-container order-container mb-5">
          <h3 className="block-title pt10 text-uppercase">
            <FormattedMessage
              id="buying_orders"
              defaultMessage="Buying orders"
            />
          </h3>
          <Pane className="order-detail" id="anchor">
            {loading ? (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            ) : detail && detail.id ? (
              <OrderSummary detail={detail} />
            ) : (
              <Pane className="no-detail" style={{ textAlign: 'center' }}>
                <FormattedMessage
                  id="notification.cannot_get_order_details"
                  defaultMessage="Cannot get order details"
                />
              </Pane>
            )}
          </Pane>
          <Pane className="order-detail mt20">
            <h3 className="block-title order-business-name">
              <>
                {detail && detail.cartItems && !isEmpty(detail.cartItems[0]) && (
                  <Link to={`/${detail.cartItems[0].seller.id}/menu`} >
                    {detail.cartItems[0].seller.businessName}
                  </Link>
                )}
              </>
            </h3>
            <Pane className="products-grids">
              <Pane className="card-body">
                {detail &&
                  detail.cartItems && (
                    <OrderedCartList
                      detail={detail}
                    />
                  )}
              </Pane>
            </Pane>
            <Pane className="order-detail">
              <h3 className="block-title card-body">
                <FormattedMessage
                  id="order.detail"
                  defaultMessage="Order details"
                />
              </h3>
              {loading && (
                <Dimmer active inverted>
                  <Loading />
                </Dimmer>
              )}
              {!loading &&
                get(detail, 'id') && <OrderSubDetail detail={detail} />}
              {!loading &&
                !get(detail, 'id') && (
                  <Pane className="no-detail" style={{ textAlign: 'center' }}>
                    <FormattedMessage
                      id="notification.cannot_get_order_details"
                      defaultMessage="Cannot get order details"
                    />
                  </Pane>
                )}
            </Pane>
          </Pane>
          {!loading && data && (
            <Pane className="change-status">
              {data.denyCancelReason && (
                <Pane>
                  <Pane className="text-requested">
                    <FormattedMessage
                      id="notification.your_request_has_been_denied_by_seller"
                      defaultMessage="Your request has been denied by seller"
                    />
                    .
                    <Icon
                      name="info circle"
                      onClick={this.onOpenReasonModal}
                      data-tip
                      data-for={`${data.id}`}
                    />
                  </Pane>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="float"
                    id={`${data.id}`}
                  >
                    <span>
                      <FormattedMessage
                        id="notification.click_to_view_detail"
                        defaultMessage="Click to view detail"
                      />
                    </span>
                  </ReactTooltip>
                </Pane>
              )}
              {data.status !== Config.ORDER_STATUS.CANCELLED &&
                data.status !== Config.ORDER_STATUS.DELIVERED &&
                data.status !== Config.ORDER_STATUS.DONE ? (
                !data.cancelStatus ? (
                  <Button
                    className="btn-confirm"
                    size="mini"
                    color="orange"
                    onClick={this.onOpenModal}
                    loading={this.state.loadingRequestCancel}
                    disabled={this.state.loadingRequestCancel}
                  >
                    <FormattedMessage
                      id="request_cancel"
                      defaultMessage="Request cancel"
                    />
                  </Button>
                ) : (
                  data.cancelStatus === 'REQUESTED' && (
                    <Pane className="text-requested">
                      <FormattedMessage
                        id="notification.you_have_sent_cancellation_request"
                        defaultMessage="You have sent cancellation request"
                      />
                    </Pane>
                  )
                )
              ) : null}
              <Modal
                open={this.state.openReason}
                onClose={this.onCloseReasonModal}
                center
                showCloseIcon={false}
              >
                <Pane className="react-confirm-alert-body">
                  <h1>
                    <FormattedMessage id="reason" defaultMessage="Reason" />
                  </h1>
                  <p>{data.denyCancelReason}</p>
                  <Pane className="react-confirm-alert-button-group">
                    <button onClick={this.onCloseReasonModal}>
                      <FormattedMessage id="close" defaultMessage="Close" />
                    </button>
                  </Pane>
                </Pane>
              </Modal>
              <Modal
                open={this.state.open}
                onClose={this.onCloseModal}
                center
                showCloseIcon={false}
              >
                <Pane className="react-confirm-alert-body">
                  <h1>
                    <FormattedMessage
                      id="confirmation"
                      defaultMessage="Confirmation"
                    />
                  </h1>
                  <p>
                    <FormattedMessage
                      id="notification.are_you_sure_you_want_to_cancel_this"
                      defaultMessage="Are you sure you want to cancel this"
                    />
                    ?
                  </p>
                  <TextArea
                    name="reason"
                    className="form-control"
                    value={this.state.reason}
                    placeholder="Your reason"
                    onChange={this.onChange}
                  />
                  {/* { errors.reason && <InlineMessage text={errors.reason} /> } */}
                  <Pane className="react-confirm-alert-button-group">
                    <button onClick={() => this.requestCancel(data.id)}>
                      <FormattedMessage id="yes" defaultMessage="Yes" />
                    </button>
                    <button onClick={this.onCloseModal}>
                      <FormattedMessage id="no" defaultMessage="No" />
                    </button>
                  </Pane>
                </Pane>
              </Modal>
            </Pane>
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  detail: state.buyingOrderDetail.data,
  business: state.auth.user.business,
});

const bindDispatchToProps = (dispatch) => ({
  getBuyingOrderDetail: bindActionCreators(getBuyingOrderDetail, dispatch),
  requestCancel: bindActionCreators(requestCancel, dispatch),
  pushNotification: bindActionCreators(pushNotification, dispatch),
  logout: bindActionCreators(logout, dispatch),
  getNotisByUser: bindActionCreators(getNotisByUser, dispatch),
});

export default withRouter(injectIntl(
  connect(
    mapStateToProps,
    bindDispatchToProps
  )(BuyingOrderDetail)
)
);
