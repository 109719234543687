import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  const page = params.page || 1
  const size = params.size || 20

  return {
    method: 'GET',
    url: `/buyers/${params.userId}/bookings?page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = (response) => {
  const bookings = []

  if (response.data) {
    response.data.forEach(item => {
      const booking = formatJson(response, item.type, item.id)

      let paymentMethod = ''

      if (booking.payment && booking.payment.transactionType) {
        paymentMethod = booking.payment.transactionType
      } else {
        paymentMethod = booking.paymentMethod !== '' ? booking.paymentMethod : 'Credit card'
      }

      bookings.push({
        id: booking.id,
        serviceName: booking.meta.serviceName,
        date: booking.date,
        paymentMethod,
        total: booking.itemsTotal,
        status: booking.status
      })
    })
  }

  return bookings
}

export { exec, parseResponse }
