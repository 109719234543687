import * as types from 'constants/ActionTypes'
import ReviewAdapter from 'services/reviews/adapter'

const initialState = {
  results: [],
  error: null,
  fetching: false,
  fetched: false
}

const reviews = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_REVIEWS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_REVIEWS_SUCCESS:
      return Object.assign({}, state, {
        results: ReviewAdapter.browse.parseResponse(action.payload.data),
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_REVIEWS_FAILURE:
      return Object.assign({}, state, {
        results: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { reviews }
