import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { editMyProject, getProjectDetail } from "actions/projects";
import EditProjectForm from "./EditProjectForm";
class EditProject extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }
  render() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="heading-title mt-2">
            <FormattedMessage id="edit_project" defaultMessage="Edit Project" />
          </h3>
        </div>
        <EditProjectForm
          id={this.props.match.params.projectId}
          userInfo={this.props.userInfo}
          history={this.props.history}
          editMyProject={this.props.editMyProject}
          getProjectDetail={this.props.getProjectDetail}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});
const bindDispatchToProps = (dispatch) => ({
  editMyProject: bindActionCreators(editMyProject, dispatch),
  getProjectDetail: bindActionCreators(getProjectDetail, dispatch),
});
export default connect(mapStateToProps, bindDispatchToProps)(EditProject);