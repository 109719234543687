import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { injectIntl } from "react-intl";
import { formatDecimal } from "shared/helpers";
import { Currency } from "shared/components";
import { Button, Icon } from "semantic-ui-react";
import { Cart } from "components/common/cart";
import { FormattedMessage } from "react-intl";
import SlideImage from "../../components/scenes/products/detail/SlideImage";
import { get } from "lodash";
import StockDetailLabel from "./StockDetailLabel";

class ProductOptionModal extends Component {
  static propTypes = {};
  state = {
    activeIndex: 0,
    optionIds: [],
    loading: false,
  };

  constructor(props, context) {
    super(props, context);
  }

  setActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };

  onGetCurrent = (originalPrice, promotion) => {
    if (promotion && promotion.percent) {
      return formatDecimal(
        originalPrice - (originalPrice * promotion.percent) / 100
      );
    }
    return formatDecimal(originalPrice);
  };

  getTotalPriceOptions = () => {
    let totalPirce = 0;

    const { detail } = this.props;

    if (!detail || !detail.options || !detail.options.length) return 0;

    this.getOptions().forEach((option) => {
      const { childrens } = option;

      childrens.forEach((children) => {
        const childIncludes = this.includesOption(children.id);
        if (childIncludes) {
          totalPirce +=
            Number(children.price || 0) * Number(childIncludes.quantity);
        }
      });
    });

    return totalPirce;
  };

  getOptions = () => {
    const { isEdit, detail } = this.props;

    if (isEdit) {
      return detail.options || [];
    }

    return [];
  };

  updateOptionQuantity = (id, quantity, type) => {
    if (type !== 3) return;
    let updateOptionIds = this.state.optionIds || [];
    const index = updateOptionIds.findIndex((item) => item.id === id);

    if (index === -1) return;
    const qty = updateOptionIds[index].quantity + quantity;
    if (qty <= 0) return;

    updateOptionIds[index].quantity = qty;
    this.setState({ optionIds: updateOptionIds });
  };

  updateOptionIds = (id, check, deleteIds = []) => {
    let updateOptionIds = this.state.optionIds || [];

    if (deleteIds && deleteIds.length) {
      updateOptionIds = updateOptionIds.filter(
        (item) => !deleteIds.includes(item.id)
      );
    }

    if (!check) {
      updateOptionIds = updateOptionIds.filter((item) => item.id !== id);
    } else {
      const updateOptionId = updateOptionIds.find((item) => item.id === id);
      if (!updateOptionId) {
        updateOptionIds.push({ id: id, quantity: 1 });
      }
    }

    this.setState({ optionIds: updateOptionIds });
  };

  getOptionIds(detail) {
    const { options, optionInfo } = detail;
    if (optionInfo && optionInfo.length) {
      this.setState({ optionIds: optionInfo });
      return;
    }

    if (options && options.length) {
      const ids = [];
      options.forEach((option) => {
        const { childrens } = option;
        ids.push(
          ...childrens
            .filter((child) => child.isCheck)
            .map((child) => ({ id: child.id, quantity: 1 }))
        );
      });

      this.setState({ optionIds: ids });
      return;
    }

    this.setState({ optionIds: [] });
  }

  includesOption = (id) => {
    return this.state.optionIds.find((child) => child.id === id);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.detail) {
      this.getOptionIds(nextProps.detail);
    }
  }

  onAddCartItem = async () => {
    this.setState({ loading: true }, async () => {
      this.props.onAddCartItem(this.state.optionIds || [], this.props.detail);
      this.setState({ loading: false });
    });
  };

  render() {
    const { detail } = this.props;
    return (
      <Modal
        open={this.props.openModal}
        showCloseIcon={false}
        onClose={this.props.onClose}
        onEntered={() => {
          this.setState({ loading: false });
          this.getOptionIds(this.props.detail);
        }}
      >
        <Pane className="sidebar-container sidebar-option">
          <Pane className="sidebar-header">
            <span className="text-header">{detail.categoryName}</span>
            <span className="icon-close" onClick={this.props.onClose}>
              X
            </span>
          </Pane>
          <Pane className="sidebar-detail">
            <Pane className="details-container">
              <Pane className="category-details">
                <Pane className="thumb-image">
                    <img
                      src={
                        detail.primaryImage ||
                        process.env.PUBLIC_URL + `/assets/images/default-image.png`
                      }
                      alt={detail.name || ""}
                      className="category-img"
                      ref={(img) => (this.img = img)}
                      onError={() =>
                        (this.img.src =
                          process.env.PUBLIC_URL + `/assets/images/default-image.png`)
                      }
                    />
                </Pane>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="sidebar-content">
            <Pane className="sidebar-title">{detail.name}</Pane>
            {/* <Pane className="sidebar-des">
            </Pane> */}
            <Pane className="details-container">
              <Pane className="category-details">
                <Pane className="category-content">
                  <StockDetailLabel
                    inStock={detail.inventoryStatus}
                    type="products"
                  />
                </Pane>
              </Pane>
            </Pane>
            <Pane className="details-container">
              <Pane className="category-details">
                <Pane className="category-content">
                  {get(detail, "promotion.percent") && (
                    <>
                      <h4 className="category-price">
                        <Currency
                          price={this.onGetCurrent(
                            Number(get(detail, "originalPrice")) +
                              this.getTotalPriceOptions(),
                            detail.promotion
                          )}
                        />
                      </h4>
                      <h4 className="category-price text-underline">
                        <Currency
                          price={formatDecimal(
                            Number(get(detail, "originalPrice")) +
                              this.getTotalPriceOptions()
                          )}
                        />
                      </h4>
                    </>
                  )}
                  {!get(detail, "promotion.percent") && (
                    <h4 className="category-price category-price-red">
                      <Currency
                        price={
                          Number(detail.originalPrice) +
                          this.getTotalPriceOptions()
                        }
                      />
                    </h4>
                  )}
                </Pane>
              </Pane>
            </Pane>
          </Pane>
          {this.getOptions().length !== 0 ? (
            <Pane className="sidebar-option-content">
              {this.getOptions().map((option) => {
                const { childrens, type, name } = option;

                const jsxOptDetails = childrens.map((children) => {
                  let control = (
                    <input
                      type={"checkbox"}
                      id={children.id}
                      name={"checkbox" + "_" + option.name}
                      onChange={(e) => {
                        this.updateOptionIds(children.id, e.target.checked, []);
                      }}
                      checked={!!this.includesOption(children.id)}
                    />
                  );

                  if (type === 1) {
                    control = (
                      <input
                        type="radio"
                        onChange={() => {
                          this.updateOptionIds(
                            children.id,
                            true,
                            childrens.map((chil) => chil.id)
                          );
                        }}
                        checked={!!this.includesOption(children.id)}
                      />
                    );
                  }

                  return (
                    <Pane className="sidebar-option-item" key={children.id}>
                      <Pane className="option-control">
                        <Pane className="option-control-left">
                          {control}
                          <label htmlFor={children.name}>{children.name}</label>
                        </Pane>
                        <Pane className="price-options">
                          {children.price > 0 && (
                            <Pane>
                              <span>+</span>
                              <Currency price={formatDecimal(children.price)} />
                            </Pane>
                          )}

                          {type === 3 ? (
                            <Pane className="option-quantity">
                              <Pane
                                className="option-button"
                                onClick={() => {
                                  this.updateOptionQuantity(
                                    children.id,
                                    1,
                                    type
                                  );
                                }}
                              >
                                +
                              </Pane>
                              <Pane className="option-number">
                                {!!this.state.optionIds.find(
                                  (item) => item.id === children.id
                                )
                                  ? this.state.optionIds.find(
                                      (item) => item.id === children.id
                                    ).quantity
                                  : 1}
                              </Pane>
                              <Pane
                                className="option-button"
                                onClick={() => {
                                  this.updateOptionQuantity(
                                    children.id,
                                    -1,
                                    type
                                  );
                                }}
                              >
                                -
                              </Pane>
                            </Pane>
                          ) : null}
                        </Pane>
                      </Pane>
                    </Pane>
                  );
                });

                return (
                  <Pane key={option.id} className={`sidebar-group-options`}>
                    <Pane className="sidebar-group-top">
                      <span className="sidebar-group-text">{name}</span>
                    </Pane>
                    <Pane className="sidebar-option-list">{jsxOptDetails}</Pane>
                  </Pane>
                );
              })}
            </Pane>
          ) : null}
          {detail.modeCart ? (
            <Pane
              className="warpping-btn"
              onClick={() => {
                this.props.onUpdateCart(detail, this.state.optionIds);
              }}
            >
              <Button loading={this.state.loading} className="btn-add-cart">
                Update Cart
              </Button>
            </Pane>
          ) : (
            <Pane className="block-btn">
              <Button
                loading={this.state.loading}
                disabled={this.state.loading || detail.modeCart || false}
                className="btn btn-circle btn-category"
                onClick={this.onAddCartItem}
              >
                <Cart added={detail.modeCart || false} type="details" />
                <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
              </Button>
            </Pane>
          )}
        </Pane>
      </Modal>
    );
  }
}

export default withRouter(injectIntl(ProductOptionModal));
