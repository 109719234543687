import { get } from "lodash";
import Config from "constants/Config";
import { formatJson } from "shared/helpers";

const exec = (params) => {
  return {
    method: "GET",
    url: `/carts/${params.uuid}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const cartResponse = formatJson(
    response,
    response.data.type,
    response.data.id
  );
  const payAtStore = !response.included.some(
    (item) =>
      item.type === "CartItem" && item.attributes.deliveryMethod === "DELIVERY"
  );

  const cart = {
    items: [],
    id: cartResponse.id,
    deliveryFee: cartResponse.deliveryFee,
    deliveryInfo: cartResponse.deliveryInfo,
    itemsTotal: cartResponse.itemsTotal,
    paymentMethod: cartResponse.paymentMethod,
    tax: cartResponse.tax,
    tableId: cartResponse.tableId,
    total: cartResponse.total,
    tableName: cartResponse.table ? cartResponse.table.name : '',
    payAtStore,
  };

  cartResponse.items &&
    cartResponse.items.forEach((item) => {
      const cartItem = formatJson(response, item.type, item.id);
      const options = [];

      if (cartItem) {
        (cartItem.product.options || []).forEach((option) => {
          const group = option.optionGroup || {};
          const index = options.findIndex((option) => option.id === group.id);

          if (index === -1) {
            options.push({
              id: group.id,
              name: group.name,
              type: group.type,
              autoAddOptions: false,
              dpOrdSeq: group.dpOrdSeq,
              delt: "N",
              childrens: [
                {
                  id: option.id,
                  name: option.name,
                  dpOrdSeq: option.dpOrdSeq,
                  price: Number(option.price),
                  delt: "N",
                  autoAddOptions: false,
                  isCheck: !!option.isCheck,
                },
              ],
            });
          } else {
            options[index].childrens.push({
              id: option.id,
              name: option.name,
              dpOrdSeq: option.dpOrdSeq,
              price: Number(option.price),
              delt: "N",
              autoAddOptions: false,
              isCheck: !!option.isCheck,
            });
          }

          return options;
        });

        options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
        options.forEach((option) =>
          option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq)
        );

        cart.items.push({
          id: cartItem.id,
          name: cartItem.product.name,
          categoryName: get(cartItem, 'product.category.name'),
          primaryImage:
            cartItem.product.primaryImage && cartItem.product.primaryImage.url,
          productId: cartItem.productId,
          quantity: Number(cartItem.quantity),
          optionInfo: cartItem.optionInfo,
          deliveryMethod: cartItem.deliveryMethod,
          originalPrice: Number(cartItem.product.price),
          price: Number(cartItem.product.price),
          promotionPrice: Number(cartItem.product.promotionPrice),
          userId: get(cartItem, "business.userId"),
          lat: get(cartItem, "business.lat"),
          lng: get(cartItem, "business.lng"),
          postCode: get(cartItem, "business.user.postcode"),
          address: get(cartItem, "business.user.address"),
          phone: get(cartItem, "business.user.phone"),
          email: get(cartItem, "business.user.email"),
          fullname: get(cartItem, "business.user.fullname"),
          options: options,
          inventoryStatus: cartItem.product.inventoryStatus
        });
      }
    });
  return cart;
};

export { exec, parseResponse };
