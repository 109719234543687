import * as types from 'constants/ActionTypes'
import api from 'api'

// Buying Orders
export const getBuyingOrders = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BUYING_ORDERS, types.RECEIVE_BUYING_ORDERS_SUCCESS, types.RECEIVE_BUYING_ORDERS_FAILURE],
  payload: {
    request: api.orders.buyers.get(params)
  }
})

export const getBuyingOrderDetail = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BUYING_ORDER_DETAIL, types.RECEIVE_BUYING_ORDER_DETAIL_SUCCESS, types.RECEIVE_BUYING_ORDER_DETAIL_FAILURE],
  payload: {
    request: api.orders.buyers.detail(params)
  }
})

export const requestCancel = (params) => (dispatch) => dispatch({
  type: types.REQUEST_CANCEL_BUYING_ORDER,
  payload: {
    request: api.orders.buyers.requestCancel(params)
  }
})

// Selling Orders
export const getSellingOrders = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_SELLING_ORDERS, types.RECEIVE_SELLING_ORDERS_SUCCESS, types.REQUEST_SELLING_ORDERS],
  payload: {
    request: api.orders.sellers.get(params)
  }
})

export const getSellingOrderDetail = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_SELLING_ORDER_DETAIL, types.RECEIVE_SELLING_ORDER_DETAIL_SUCCESS, types.RECEIVE_SELLING_ORDER_DETAIL_FAILURE],
  payload: {
    request: api.orders.sellers.detail(params)
  }
})

export const acceptCancel = (params) => (dispatch) => dispatch({
  type: types.ACCEPT_CANCEL_SELLING_ORDER,
  payload: {
    request: api.orders.sellers.acceptCancel(params)
  }
})
export const cancel = (params) => (dispatch) => dispatch({
  type: types.SELLER_CANCEL_ORDER,
  payload: {
    request: api.orders.sellers.cancel(params)
  }
})


export const denyCancel = (params) => (dispatch) => dispatch({
  type: types.DENY_CANCEL_SELLING_ORDER,
  payload: {
    request: api.orders.sellers.denyCancel(params)
  }
})

export const changeStatus = (params) => (dispatch) => dispatch({
  type: types.CHANGE_ORDER_STATUS,
  payload: {
    request: api.orders.sellers.changeStatus(params)
  }
})

export const getOrdersBookings = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_ORDERS_BOOKINGS, types.RECEIVE_ORDERS_BOOKINGS_SUCCESS, types.RECEIVE_ORDERS_BOOKINGS_FAILURE],
  payload: {
    request: api.orders.dashboard.get(params)
  }
})
