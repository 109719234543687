const HandleError = (errors, errorText) => {
  let errorMessage = errorText

  if (errors && errors.response && errors.response.data && errors.response.data.errors[0]) {
    switch (errors.response.status) {
      case 500:
        errorMessage = errorText
        break
      case 400:
      case 402:
      case 404:
        errorMessage = errors.response.data.errors[0].detail
        break
      default:
        errorMessage = errorText
        break
    }
  }

  return errorMessage
}

export default HandleError
