import * as types from 'constants/ActionTypes'
import ProductAdapter from 'services/products/adapter'
import ServiceAdapter from 'services/services/adapter'

const initialState = {
  results: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const searchProducts = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SEARCH_PRODUCTS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_SEARCHING_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        results: ProductAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_SEARCHING_PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const searchServices = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SEARCH_SERVICES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_SEARCHING_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        results: ServiceAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_SEARCHING_SERVICES_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

export { searchProducts, searchServices }
