import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  return {
    method: 'GET',
    url: `/business/${params.id}/reviews`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const reviews = []

  if (response.data) {
    response.data.forEach(item => {
      const review = formatJson(response, item.type, item.id)

      reviews.push({
        id: item.attributes.id,
        comment: item.attributes.comment,
        createdAt: item.attributes.createdAt,
        createdAtDiff: item.attributes.createdAtDiff,
        stars: Number(item.attributes.stars),
        isHide: item.attributes.isHide,
        image1: review.image1 && review.image1.url,
        image2: review.image2 && review.image2.url,
        image3: review.image3 && review.image3.url,
        image4: review.image4 && review.image4.url,
        image5: review.image5 && review.image5.url,
        user: {
          id: review.user && review.user.id,
          name: review.user && review.user.fullname,
          email: review.user && review.user.email,
          address: review.user && review.user.address,
          phone: review.user && review.user.phone,
          profileImage: review.user && review.user.image && review.user.image.url,
        }
      })
    })
  }

  return reviews
}

export { exec, parseResponse }