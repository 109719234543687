import React from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';

const Tables = ({ tables }) => {
	return (
		<Pane
			className={`row align-items-end`}>
			{tables.length > 0 ? (
				tables.map((item, index) => {
					return (
						<Pane key={index} className="col-lg-2 col-4">
							<Pane className="table-item">
								<Pane className="total-new-orders">
									{item.countNewOrders > 0 && (
										<Pane>
											{item.countNewOrders} <img
												src={`${process.env.PUBLIC_URL
													}/assets/images/dashboard/new.gif`}
												className="new-logo"
												alt="new-logo"
											/>
										</Pane>
									)}
								</Pane>
								<Pane className="table-body">
									<Pane className="table-label">Table</Pane>
									<Pane className="table-name">{item.name}</Pane>
								</Pane>
							</Pane>
						</Pane>
					);
				})
			) : (
				<Pane className="block-no-content w-100">
					<p>
						No table
					</p>
				</Pane>
			)}
		</Pane>
	);
};

Tables.propTypes = {
	tables: PropTypes.array,
};

Tables.defaultProps = {
	tables: [],
};

export default Tables;
