import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import ContactListItem from './ContactListItem';

class ContactList extends Component {
  static propTypes = {
  };

  render() {
    const userContacts = this.props.userContacts.map((item) => {
      return (
        <ContactListItem
          key={item.id}
          removeContact={this.props.removeContact}
          avatar={item.user.profileImage }
          name={item.user.name}
          id={item.follower_id}
          type={item.type}
        />
      )});

    return (
      <Table>
        <Table.Body>{userContacts}</Table.Body>
      </Table>
    );
  }
}

export default withRouter(ContactList);
