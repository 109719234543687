import Config from 'constants/Config';

const exec = (params) => {
  return {
    method: 'POST',
    url: `/deliveries`,
    baseURL: Config.NEW_API_URL,
    data: params,
  };
};

const parseResponse = (response) => {
  const data = {
    ...response,
  };
  return data;
};

export { exec, parseResponse };
