import * as ServiceBrowseV1 from './service.browse.v1.action'
import * as ServiceBrowseSlideV1 from './service.browseslide.v1.action'
import * as ServiceReviewV1 from './service.review.v1.action'
import * as ServiceReadV1 from './service.read.v1.action'
import * as ServiceSearchV1 from './service.search.v1.action'
import * as ServiceGetTimeSlotsV1 from './service.time-slot.browse.v1.action'
import * as ServiceFeatureV1 from './service.featured.v1.action'
import * as ServiceBookV1 from './service.book.v1.action'
import * as ServiceGetOptionsV1 from "./service.get-option.v1.action";

export default {
  // get all services
  browse: {
    callAPI: params => ServiceBrowseV1.exec(params),
    parseResponse: response => ServiceBrowseV1.parseResponse(response)
  },
  browseslide: {
    callAPI: params => ServiceBrowseSlideV1.exec(params),
    parseResponse: response => ServiceBrowseSlideV1.parseResponse(response)
  },
  // get service detail
  read: {
    callAPI: params => ServiceReadV1.exec(params),
    parseResponse: response => ServiceReadV1.parseResponse(response)
  },
  // review service
  review: {
    callAPI: id => ServiceReviewV1.exec(id),
    parseResponse: response => ServiceReviewV1.parseResponse(response)
  },
  // rating service
  getTimeSlots: {
    callAPI: id => ServiceGetTimeSlotsV1.exec(id),
    parseResponse: response => ServiceGetTimeSlotsV1.parseResponse(response)
  },
  // book a service
  book: {
    callAPI: id => ServiceBookV1.exec(id),
    parseResponse: response => ServiceBookV1.parseResponse(response)
  },
  // search service
  search: {
    callAPI: id => ServiceSearchV1.exec(id),
    parseResponse: response => ServiceSearchV1.parseResponse(response)
  },
  // featured service
  featured: {
    callAPI: params => ServiceFeatureV1.exec(params),
    parseResponse: response => ServiceFeatureV1.parseResponse(response)
  },
  options: {
    callAPI: (params) => ServiceGetOptionsV1.exec(params),
    parseResponse: (response) => ServiceGetOptionsV1.parseResponse(response),
  },
}
