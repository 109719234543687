import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Pane, Loading } from 'shared/components/layouts';
import BusinessLogoItem from './BusinessLogoItem';
import { getBusinessCboosts } from 'actions/business';
// const NUM_OF_LOGO = 6;

class BusinessLogoList extends Component {
  static propTypes = {
    auth: PropTypes.object,
    getBusinessCboosts: PropTypes.func.isRequired,
    businessLogos: PropTypes.object,
  };

  componentDidMount() {
    this.props.getBusinessCboosts();
  }

  renderList(businessLogos) {
    if (!businessLogos) {
      return;
    }
    const arrays = [
      {
        id: 1,
        name: this.props.intl.formatMessage({
          id: 'promotions',
          defaultMessage: 'Promotions',
        }),
        url: '/assets/images/home/Promotion.svg',
        link: 'promotions',
      },
      // {
      //   id: 2,
      //   name: this.props.intl.formatMessage({
      //     id: 'trending',
      //     defaultMessage: 'Trending',
      //   }),
      //   url: '/assets/images/home/trending.svg',
      //   link: '/',
      // },
      // {
      //   id: 3,
      //   name: this.props.intl.formatMessage({
      //     id: 'giftcards',
      //     defaultMessage: 'giftcards',
      //   }),
      //   url: '/assets/images/home/giftcard.svg',
      //   link: '/',
      // },
      {
        id: 4,
        name: this.props.intl.formatMessage({
          id: 'best_picks',
          defaultMessage: 'BEST PICKS',
        }),
        url: '/assets/images/home/bestpick.svg',
        link: 'product-picks',
      },
      {
        id: 5,
        name: this.props.intl.formatMessage({
          id: 'just_listed',
          defaultMessage: 'just_listed',
        }),
        url: '/assets/images/home/newcoming.svg',
        link: 'just-listed',
      },
      // {
      //   id: 6,
      //   name: this.props.intl.formatMessage({
      //     id: 'oppshop',
      //     defaultMessage: 'OPPSHOP',
      //   }),
      //   url: '/assets/images/home/oppshop.svg',
      //   link: '/',
      // },
    ];
    return arrays.map((item) => {
      return (
        <BusinessLogoItem
          id={item.id}
          key={item.id}
          business={item}
          auth={this.props.auth}
        />
      );
    });
  }

  render() {
    const { businessLogos } = this.props;
    return (
      <Pane className="bussiness-container">
        <Pane className="container">
          <Pane className="row">
            {!businessLogos.fetched && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {this.renderList(businessLogos.results)}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  businessLogos: state.businessLogos,
});

const mapDispatchToProps = (dispatch) => ({
  getBusinessCboosts: bindActionCreators(getBusinessCboosts, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BusinessLogoList));
