import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';

class Choose extends Component {
  render() {
    return (
      <Pane className="choose-container d-flex align-items-center">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-12">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="choose-content text-center">
                  <h3 className="choose-title">
                    <FormattedMessage
                      id="choose_cardbey"
                      defaultMessage="Choose Cardbey"
                    />
                  </h3>
                  <p className="choose-norm">
                    <FormattedMessage
                      id="we_are_continually_changing_and_adapting"
                      defaultMessage="We are continuously changing and adapting to the market and
                    technology and strive to be a market leader in the online
                    marketplace. We are constantly adding new features, tools,
                    functions, and services. Follow us on social media to stay
                    up-to-date."
                    />
                  </p>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default Choose;
