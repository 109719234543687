import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Language from "./Language";
import { Pane } from "shared/components/layouts";

class Footer extends PureComponent {
  render() {
    return (
      <footer className="footer-container">
        <Pane className="container">
          <Pane className="row pb-4">
            <Pane className="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-lg-left text-md-left text-left">
              <Pane className="footer-logo">
                <img
                  src={`${
                    process.env.PUBLIC_URL
                  }/assets/images/layouts/logo-footer.png`}
                  className="footer-img"
                  alt="caterwin logo"
                />
              </Pane>
            </Pane>
            <Pane className="col-md-6 col-lg-3 col-xl-3 col-md-6 col-12">
              <Pane className="footer-col">
                <ul className="footer-nav">
                  <li className="footer-norm">CATERWIN PTY LTD</li>
                  <li className="footer-norm">
                    Melbourne Victoria. Australia
                  </li>
                  <li className="footer-norm">ABN: 68 639 126 159</li>
                  <li className="footer-norm">ACN: 639 126 159</li>
                </ul>
              </Pane>
            </Pane>
            <Pane className="col-md-6 col-lg-1 col-xl-1 col-12 text-lg-left text-md-left text-left mt-lg-0 mt-md-0 mt-2">
              <Pane className="footer-col">
                {/* <ul className="footer-nav">
                  <li className="footer-norm">
                    Melbourne Victoria. Australia
                  </li>
                </ul> */}
              </Pane>
            </Pane>
            <Pane className="col-md-8 col-lg-6 col-xl-5 pt-3">
              <Pane className="row footer-menu">
                <Pane className="footer-col">
                  <NavLink to="/" className="footer-link">
                    <FormattedMessage id="home" defaultMessage="Home" />
                  </NavLink>
                </Pane>
                <Pane className="footer-col">
                  <NavLink to="/products" className="footer-link">
                    <FormattedMessage id="products" defaultMessage="Products" />
                  </NavLink>
                </Pane>
                <Pane className="footer-col">
                  <NavLink to="/services" className="footer-link">
                    <FormattedMessage id="services" defaultMessage="Services" />
                  </NavLink>
                </Pane>
                <Pane className="footer-col">
                  <NavLink to="/contact-us" className="footer-link">
                    <FormattedMessage id="contact" defaultMessage="Contact" />
                  </NavLink>
                </Pane>
                <Pane className="footer-col">
                  <NavLink to="/faqs" className="footer-link">
                    <FormattedMessage id="faqs" defaultMessage="FAQs" />
                  </NavLink>
                </Pane>
                <Pane className="footer-col">
                  <NavLink to="/about-us" className="footer-link">
                    <FormattedMessage id="about_us" defaultMessage="About Us" />
                  </NavLink>
                </Pane>
                {/* <Pane className="footer-col">
                  <NavLink to="/about-us" className="footer-link">
                    Blogs
                  </NavLink>
                </Pane> */}
              </Pane>
            </Pane>
          </Pane>
          <hr className="footer-dot" />
          <Pane className="row pt-4 text-left text-lg-left text-md-left">
            <Pane className="col-lg-6 col-md-6">
              <Pane className="info">
                <h2 className="footer-title">
                  © 2023 Cardbey. All Rights Reserved.
                </h2>
                <Pane className="information">
                  <NavLink to="/terms" className="footer-norm">
                    Terms of Service
                  </NavLink>
                  <NavLink to="/policy" className="footer-norm">
                    Privacy Policy
                  </NavLink>
                </Pane>
              </Pane>
            </Pane>
            <Pane className="col-lg-6 col-md-6 text-left text-lg-right text-md-right">
              <ul className="footer-social">
                <li className="social-item">
                  <Language for="language-dropdown-footer" />
                </li>
                <li className="social-item">
                  <a
                    href="https://www.instagram.com/cardbeymarketplace"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/about/instgram.svg`}
                      alt="tweeter"
                    />
                  </a>
                </li>
                <li className="social-item">
                  <a
                    href="https://twitter.com/cardbey"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/about/twiter.svg`}
                      alt="tweeter"
                    />
                  </a>
                </li>
                <li className="social-item">
                  <a
                    href="https://www.facebook.com/cardbeymarketplace"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/about/fb.svg`}
                      alt="facebook"
                    />
                  </a>
                </li>
                <li className="social-item">
                  <a
                    href="https://www.pinterest.com/cardbeymarketplace/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/about/pinterest.svg`}
                      alt="gg"
                    />
                  </a>
                </li>
              </ul>
            </Pane>
          </Pane>
        </Pane>
      </footer>
    );
  }
}

export default Footer;
