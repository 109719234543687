import _ from 'lodash';
import NotFound from './NotFound';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';

class Container extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    return (
      <Pane className="page-container">
        {!_.isEmpty(this.props.data) ? this.props.children : <NotFound />}
      </Pane>
    );
  }
}

export default Container;
