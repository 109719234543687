import { Form, Button, Icon, Rating, Image as ImageS, } from 'semantic-ui-react';
import React, { Component } from "react";
import Config from "constants/Config";
import { Pane } from "shared/components/layouts";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { InlineMessage } from 'shared/components';


class ReviewStoreModal extends Component {
	static propTypes = {
		onGetReviewsVideo: PropTypes.func,
		onNotification: PropTypes.object,
		intl: intlShape.isRequired,
		onReview: PropTypes.func,
	};

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: false,
			data: {
				rating: 0,
				comment: ''
			},
			imageFiles: [],
			imageFilesName: [],
			images: [],
			errors: {}
		};
	}

	removeImage = (index) => {
		const images = this.state.images;
		const imageFiles = this.state.imageFiles;

		images.splice(index, 1);
		imageFiles.splice(index, 1);

		this.setState({
			images,
			imageFiles,
		});
	};

	onChangeImage = (e) => {
		const files = e.target.files;
		if (files) {
			Object.keys(files).forEach((key, index) => {
				if (
					index < 5 &&
					this.state.images.length < 5 &&
					this.state.images.length + index < 5
				) {
					const reader = new FileReader();
					const value = files[key];
					reader.onloadend = () => {
						const images = this.state.images;
						const imageFiles = this.state.imageFiles;
						const imageFilesName = this.state.imageFilesName;

						images.push(reader.result);
						imageFiles.push(files[index]);
						imageFilesName.push(files[index].name);

						this.setState({
							images,
							imageFiles,
							imageFilesName
						});
					};
					reader.readAsDataURL(value);
				}
			});
		}
	};

	resetForm = () => {
		this.setState({
			data: { comment: '', rating: 0, imageFiles: [], imageFilesName: [], images: []},
		});
	}

	onChange = (e) => {
		this.setState({
			data: { ...this.state.data, [e.target.name]: e.target.value },
		});
	};

	onSubmit = () => {
		const { data, imageFiles } = this.state;

		const { intl, storeInfo } = this.props;

		const dataForm = new FormData();

		dataForm.append("comment", data.comment || "");
		dataForm.append("stars", data.rating || "");
		dataForm.append("image1", imageFiles[0] || "");
		dataForm.append("image2", imageFiles[1] || "");
		dataForm.append("image3", imageFiles[2] || "");
		dataForm.append("image4", imageFiles[3] || "");
		dataForm.append("image5", imageFiles[4] || "");

		let params = {
			id: storeInfo.business.id,
			data: dataForm
		}

		this.setState({ loading: true });
		this.props.reviewBusiness(params).then(() => {
			this.setState({
				loading: false,
			});
			this.resetForm();
			this.props.getReviewBusiness();
			this.props.onCloseModal();
			this.props.onNotification.addNotification({
				message: intl.formatMessage({
					id: 'notification.thank_for_review',
				}),
				level: 'success',
			});
		})
			.catch(() => {
				this.setState({ loading: false });
				this.props.onNotification.addNotification({
					message: intl.formatMessage({ id: 'notification.please_try_again' }),
					level: 'error',
				});
			});
	}

	onRate = (e, { rating }) => {
		this.setState({ data: { ...this.state.data, rating: rating } });
	};

	onClose = () => {
		this.resetForm();
		this.props.onCloseModal();
	}

	render() {
		const { loading, data } = this.state;
		const {
			storeInfo,
			openModal,
		} = this.props;
		return (
			<Modal
				open={openModal}
				onClose={this.onClose}
				center
				showCloseIcon={true}
			>
				<Pane className="login-container popup-review">
					<Pane className="w-100 d-flex justify-content-center flex-column">
						<h2 className="review-title py-4 text-center"><em>{storeInfo.business.name}</em></h2>
						<ul className="py-4 list-star justify-content-center">
							<Rating
								rating={data.rating}
								icon="star"
								maxRating="5"
								size="massive"
								onRate={this.onRate}
							/>
						</ul>
						<Form onSubmit={this.onSubmit} className="form-overview mt-2 w-100">
							<Pane className="comment-form">
								<Form.Field className="form-item mt-0">
									<textarea
										placeholder="Write your own review"
										name="comment"
										className="w-100 p-2"
										rows={4}
										value={data.comment}
										onChange={this.onChange}
									/>
								</Form.Field>
							</Pane>
							<Pane className="col-lg-12">
								<Form.Field className="form-item">
									<Pane className="text-center">
										<input
											type="file"
											id="image-menu"
											className="form-input-file"
											multiple
											value={data.image}
											onChange={this.onChangeImage}
										/>
										<br />
										<label htmlFor="image-menu" className="form-label-file">
											<img
												src={
													process.env.PUBLIC_URL + `/assets/images/icons/upload.svg`
												}
												alt="upload"
												className="icon"
											/>
											Upload a photo/video
										</label>
									</Pane>
									<Pane
										className="slider-content-main d-flex"
										style={{ padding: "10px 0" }}
									>
										{this.state.images &&
											this.state.images.map((item, index) => {
												const extVideo = Config.extVideo.includes(this.state.imageFilesName[index].substr(this.state.imageFilesName[index].lastIndexOf(".") + 1));
												if (!extVideo) {
													return (
														<Pane
															className="slide-item mr-2"
															key={index}
														>
															<Icon
																name="remove circle"
																onClick={this.removeImage.bind(this, index)}
															/>
															<ImageS src={item} size="small" />
														</Pane>
													);
												}
												return (
													<Pane
														className="slide-item"
														key={index}
														style={{ float: "left" }}
													>
														<Icon
															name="remove circle"
															onClick={this.removeImage.bind(this, index)}
														/>
														<video width="150" controls>
															<source src={item} id="video_here" type="video/mp4" />
															Your browser does not support HTML5 video.
														</video>
													</Pane>
												);
											})}
									</Pane>
								</Form.Field>
							</Pane>
							<Pane className="comment-form text-right">
								<Button
									size="tiny"
									disabled={loading || this.state.data.rating === 0}
									loading={loading}
								>
									<FormattedMessage id="post" defaultMessage="Post" />
								</Button>
							</Pane>
						</Form>
					</Pane>
				</Pane>
			</Modal>
		);
	}
}

export default withRouter(injectIntl(ReviewStoreModal));
