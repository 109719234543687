import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Pane } from "shared/components/layouts";
import { formatRoute, handleError, quill } from "shared/helpers";
import InlineMessage from "shared/components/InlineMessage";
import { Button, Form, Image, Icon } from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";

class CreateDigitalForm extends Component {
  static propTypes = {
    products: PropTypes.object,
    intl: intlShape.isRequired,
  };

  state = {
    imageHorizontal: [],
    imageHorizontalFiles: [],
    imageVertical: [],
    imageVerticalFiles: [],
    data: {
      title: "",
      description: "",
    },
    errors: {},
    open: false,
    categories: [],
    loading: false,
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onChangeImageHorizontal = (e) => {
    const { files } = e.target;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.imageHorizontal.length < 6 &&
          this.state.imageHorizontal.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const imageHorizontal = this.state.imageHorizontal;
            const imageHorizontalFiles = this.state.imageHorizontalFiles;

            imageHorizontal.push(reader.result);
            imageHorizontalFiles.push(files[index]);

            this.setState({
              errors: { ...this.state.errors, ['imageHorizontalFiles']: '' },
            });

            this.setState({
              imageHorizontal,
              imageHorizontalFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  onChangeImageVertical = (e) => {
    const { files } = e.target;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.imageVertical.length < 6 &&
          this.state.imageVertical.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const imageVertical = this.state.imageVertical;
            const imageVerticalFiles = this.state.imageVerticalFiles;

            imageVertical.push(reader.result);
            imageVerticalFiles.push(files[index]);

            this.setState({
              errors: { ...this.state.errors, ['imageVerticalFiles']: '' },
            });

            this.setState({
              imageVertical,
              imageVerticalFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  removeImage = (index) => {
    const imageHorizontal = this.state.imageHorizontal;
    const imageHorizontalFiles = this.state.imageHorizontalFiles;

    imageHorizontal.splice(index, 1);
    imageHorizontalFiles.splice(index, 1);

    this.setState({
      imageHorizontal,
      imageHorizontalFiles,
    });
  };

  removeImageVertical = (index) => {
    const imageVertical = this.state.imageVertical;
    const imageVerticalFiles = this.state.imageVerticalFiles;

    imageVertical.splice(index, 1);
    imageVerticalFiles.splice(index, 1);

    this.setState({
      imageVertical,
      imageVerticalFiles,
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl, type } = this.props;

    if (!data.title) {
      errors.title = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
   if(type === 'Horizontal' && isEmpty(this.state.imageHorizontalFiles)){
      errors.imageHorizontalFiles = intl.formatMessage({
        id: "validate.require_field",
      });
   }

    if ( type === 'Vertical' && isEmpty(this.state.imageVerticalFiles)) {
      errors.imageVerticalFiles = intl.formatMessage({
        id: "validate.require_field",
      });
    }

    return errors;
  };

  createDigital = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new FormData();
      data.append("title", this.state.data.title);
      if (
        !isEmpty(this.state.imageHorizontalFiles) &&
        this.state.imageHorizontalFiles[0].type
      ) {
        data.append(
          "image_horizontal",
          this.state.imageHorizontalFiles[0] || ""
        );
      }
      if (
        !isEmpty(this.state.imageVerticalFiles) &&
        this.state.imageVerticalFiles[0].type
      ) {
        data.append("image_vertical", this.state.imageVerticalFiles[0] || "");
      }
      data.append("description", this.state.data.description);
      data.append("user_id", this.props.userInfo.id || "");
      data.append("screen_id", this.props.screenId || "");

      this.props
        .createMyDigital(data)
        .then(() => {
          this.setState({
            loading: false,
            open: false,
            imageHorizontal: [],
            imageHorizontalFiles: [],
            imageVertical: [],
            imageVerticalFiles: [],
            data: {
              ...this.state.data,
              name: "",
              description: "",
            },
          });
          this.props.history.push(
            `/u/${formatRoute(
              this.props.userInfo.displayName
            )}/digital-slideshow/screen/${this.props.screenId}/edit`
          );
        })
        .catch((error) => {
          const errors = {};
          errors.message = handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );

          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  render() {
    const { errors, data, loading } = this.state;
    const { type } = this.props;
    return (
      <Form className="form-overview pb-5" onSubmit={this.createDigital}>
        <Pane className="form-content">
          {type === 'Horizontal' ? (
            <Pane className="row">
              <Pane className="col-lg-12">
                <Form.Field
                  error={!!errors.imageHorizontalFiles}
                  className="form-item"
                >
                  <label htmlFor="Image" className="form-label">
                    <FormattedMessage
                      id="image_horizontal"
                      defaultMessage="Image Horizontal"
                    />
                  </label>
                  {isEmpty(this.state.imageHorizontal) && (
                    <>
                      <input
                        type="file"
                        id="image-menu"
                        className="form-input-file"
                        value={data.imageVerticalFiles}
                        onChange={this.onChangeImageHorizontal}
                      />
                      <br />
                      <label htmlFor="image-menu" className="form-label-file">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/icons/upload.svg`
                          }
                          alt="upload"
                          className="icon"
                        />
                        Upload a photo/video
                      </label>
                    </>
                  )}
                  <Pane
                    className="slider-content-main"
                    style={{ padding: "10px 0" }}
                  >
                    {this.state.imageHorizontal &&
                      this.state.imageHorizontal.map((item, index) => {
                        if (item.indexOf("video") < 0) {
                          return (
                            <Pane
                              className="slide-item"
                              key={index}
                              style={{ float: "left" }}
                            >
                              <Icon
                                name="remove circle"
                                onClick={() => this.removeImage(this, index)}
                              />
                              <Image src={item} size="small" />
                            </Pane>
                          );
                        }
                        return (
                          <Pane
                            className="slide-item"
                            key={index}
                            style={{ float: "left" }}
                          >
                            <Icon
                              name="remove circle"
                              onClick={() => this.removeImage(index)}
                            />
                            <video width="150" controls>
                              <source src={item} id="video_here" />
                              Your browser does not support HTML5 video.
                            </video>
                          </Pane>
                        );
                      })}
                  </Pane>
                  {errors.imageHorizontalFiles && (
                    <InlineMessage text={errors.imageHorizontalFiles} />
                  )}
                </Form.Field>
              </Pane>
            </Pane>
          ) : (
            <Pane className="row">
              <Pane className="col-lg-12">
                <Form.Field
                  error={!!errors.imageVerticalFiles}
                  className="form-item"
                >
                  <label htmlFor="Image" className="form-label">
                    <FormattedMessage
                      id="image_vertical"
                      defaultMessage="Image Vertical"
                    />
                  </label>
                  {isEmpty(this.state.imageVertical) && (
                    <>
                      <input
                        type="file"
                        id="image-menu-vertical"
                        className="form-input-file"
                        value={data.imageHorizontalFiles}
                        onChange={this.onChangeImageVertical}
                      />
                      <br />
                      <label
                        htmlFor="image-menu-vertical"
                        className="form-label-file"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/icons/upload.svg`
                          }
                          alt="upload"
                          className="icon"
                        />
                        Upload a photo/video
                      </label>
                    </>
                  )}
                  <Pane
                    className="slider-content-main"
                    style={{ padding: "10px 0" }}
                  >
                    {this.state.imageVertical &&
                      this.state.imageVertical.map((item, index) => {
                        if (item.indexOf("video") < 0) {
                          return (
                            <Pane
                              className="slide-item"
                              key={index}
                              style={{ float: "left" }}
                            >
                              <Icon
                                name="remove circle"
                                onClick={() =>
                                  this.removeImageVertical(this, index)
                                }
                              />
                              <Image src={item} size="small" />
                            </Pane>
                          );
                        }
                        return (
                          <Pane
                            className="slide-item"
                            key={index}
                            style={{ float: "left" }}
                          >
                            <Icon
                              name="remove circle"
                              onClick={() => this.removeImageVertical(index)}
                            />
                            <video width="150" controls>
                              <source src={item} id="video_here" />
                              Your browser does not support HTML5 video.
                            </video>
                          </Pane>
                        );
                      })}
                  </Pane>
                  {errors.imageVerticalFiles && (
                    <InlineMessage text={errors.imageVerticalFiles} />
                  )}
                </Form.Field>
              </Pane>
            </Pane>
          )}

          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.title} className="form-item">
                <label htmlFor="title" className="form-label">
                  <FormattedMessage id="title" defaultMessage="title" />
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder=""
                  className="form-input"
                  value={data.title}
                  onChange={this.onChange}
                />
                {errors.title && <InlineMessage text={errors.title} />}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.description} className="form-item">
                <label htmlFor="description" className="form-label">
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                </label>
                <ReactQuill
                  value={data.description}
                  onChange={(content) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        description: content,
                      },
                    }));
                  }}
                  formats={quill.formats}
                  modules={quill.modules}
                />
                {errors.description && (
                  <InlineMessage text={errors.description} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              {errors.message && <InlineMessage text={errors.message} />}
            </Pane>
          </Pane>
        </Pane>
        <Pane className="row">
          <Pane className="col-lg-12 text-center">
            <Button
              type="submit"
              className="btn btn-save mt-4"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="create" defaultMessage="Create" />
            </Button>
          </Pane>
        </Pane>
      </Form>
    );
  }
}

export default injectIntl(CreateDigitalForm);
