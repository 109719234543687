import * as types from 'constants/ActionTypes'
import CartAdapter from 'services/carts/adapter'
import GuestCartAdapter from 'services/guestCart/adapter'

const initialState = {}

const cart = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.RECEIVE_SHOPPING_CART_SUCCESS:
      localStorage.setItem('cart', JSON.stringify(CartAdapter.browse.parseResponse(action.payload.data)))
      return Object.assign({}, state, CartAdapter.browse.parseResponse(action.payload.data))
    case types.RECEIVE_SHOPPING_CART_FAILURE:
      localStorage.removeItem('cart')
      localStorage.removeItem('deliveryInfo')
      return {}
    case types.ADD_TO_CART_SUCCESS:
      localStorage.setItem('cart', JSON.stringify(CartAdapter.browse.parseResponse(action.payload.data)))
      return Object.assign({}, state, CartAdapter.browse.parseResponse(action.payload.data))
    case types.REMOVE_TO_CART_SUCCESS:
      localStorage.setItem('cart', JSON.stringify(CartAdapter.browse.parseResponse(action.payload.data)))
      return Object.assign({}, state, CartAdapter.browse.parseResponse(action.payload.data))
    case types.CHANGE_QUANLITY_SUCCESS:
      localStorage.setItem('cart', JSON.stringify(CartAdapter.browse.parseResponse(action.payload.data)))
      return Object.assign({}, state, CartAdapter.browse.parseResponse(action.payload.data))
    case types.CHANGE_OPTION_SUCCESS:
      localStorage.setItem('cart', JSON.stringify(CartAdapter.browse.parseResponse(action.payload.data)))
      return Object.assign({}, state, CartAdapter.browse.parseResponse(action.payload.data))
    case types.CHANGE_DELIVERY_SUCCESS:
      localStorage.setItem('cart', JSON.stringify(CartAdapter.browse.parseResponse(action.payload.data)))
      return Object.assign({}, state, CartAdapter.browse.parseResponse(action.payload.data))
    case types.REMOVE_ALL_CART_SUCCESS:
      localStorage.removeItem('cart')
      localStorage.removeItem('deliveryInfo')
      return {}
    case types.CLEAR_DATA:
      return {}
    default:
      return state
  }
}

const initialGuestCartState = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')) : {}

const guestCart = (state = initialGuestCartState, action = {}) => {
  let guestCart = {}
  switch (action.type) {
    case types.RECEIVE_GUEST_CART_SUCCESS:
      return Object.assign({}, state)
    case types.ADD_TO_GUEST_CART_SUCCESS:
      guestCart = Object.assign({}, state)
      guestCart.items = guestCart.items || []
      guestCart.payment = {
        totalPayment: 0
      }
      guestCart.allowPayAtStore = false

      const product = action.payload.item
      let index = guestCart.items.findIndex(item => item.id === product.id)

      if (index >= 0) {
        guestCart.items[index].quantity = guestCart.items[index].quantity + 1
        guestCart.items[index] = updateCartItem(guestCart.items[index])
      } else {
        product.quantity = 1
        product.deliveryMethod = 'DELIVERY'
        guestCart.items.push(updateCartItem(product))
      }

      guestCart.payment.totalPayment = updateTotalPayment(guestCart)
      guestCart.allowPayAtStore = updatePaymentMethod(guestCart)

      localStorage.setItem('guestCart', JSON.stringify(GuestCartAdapter.parseResponse(guestCart)))
      return Object.assign({}, state, GuestCartAdapter.parseResponse(guestCart))
    case types.REMOVE_TO_GUEST_CART_SUCCESS:
      guestCart = Object.assign({}, state)
      index = guestCart.items.findIndex(item => item.id === action.payload.id)

      guestCart.items.splice(index, 1)

      guestCart.payment.totalPayment = updateTotalPayment(guestCart)
      guestCart.allowPayAtStore = updatePaymentMethod(guestCart)

      localStorage.setItem('guestCart', JSON.stringify(GuestCartAdapter.parseResponse(guestCart)))
      return Object.assign({}, state, GuestCartAdapter.parseResponse(guestCart))
    case types.CHANGE_DELIVERY_GUEST_CART_SUCCESS:
      guestCart = Object.assign({}, state)
      index = guestCart.items.findIndex(item => item.id === action.payload.id)

      guestCart.items[index].deliveryMethod = action.payload.deliveryMethod
      guestCart.allowPayAtStore = updatePaymentMethod(guestCart)

      localStorage.setItem('guestCart', JSON.stringify(GuestCartAdapter.parseResponse(guestCart)))
      return Object.assign({}, state, GuestCartAdapter.parseResponse(guestCart))
    case types.CHANGE_QUANTITY_GUEST_CART_SUCCESS:
      guestCart = Object.assign({}, state)
      index = guestCart.items.findIndex(item => item.id === action.payload.id)

      guestCart.items[index].quantity = action.payload.quantity

      if (guestCart.items[index].quantity <= 0) {
        guestCart.items[index].quantity = 1
      }

      guestCart.items[index] = updateCartItem(guestCart.items[index])

      guestCart.payment.totalPayment = updateTotalPayment(guestCart)
      guestCart.allowPayAtStore = updatePaymentMethod(guestCart)

      localStorage.setItem('guestCart', JSON.stringify(GuestCartAdapter.parseResponse(guestCart)))
      return Object.assign({}, state, GuestCartAdapter.parseResponse(guestCart))
    case types.CLEAR_DATA:
      return {}
    default:
      return state
  }
}

const updateCartItem = (product) => {
  if (product.promotion && product.promotion.percentDiscount) {
    product.discountPrice = product.originalPrice * product.promotion.percentDiscount / 100
    product.finalPrice = product.quantity * (product.originalPrice - product.discountPrice)
  } else {
    product.discountPrice = 0
    product.finalPrice = product.quantity * product.originalPrice
  }

  return product
}

const updateTotalPayment = (cart) => {
  let total = 0
  cart.items.forEach(item => {
    total = total + item.finalPrice
  })

  return total
}

const updatePaymentMethod = (cart) => {
  let allowPayAtStore = true

  const index = cart.items.findIndex(item => !item.deliveryMethod)

  if (index >= 0) {
    allowPayAtStore = false
  }

  return allowPayAtStore
}

export { cart, guestCart }
