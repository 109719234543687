import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import { Currency } from "shared/components";
import { Button } from "semantic-ui-react";
import ListItem from "./ListItem";
import { get } from "lodash";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";

class FloatList extends Component {

  handleTotal = () => {
    let total = 0;
    if (this.props.items.length > 0) {
      this.props.items.map((item) => {
        let totalPriceOp = 0;

        if (item.children) {
          totalPriceOp += item.children.price;
        }

        total += item.originalPrice + totalPriceOp;
      })
    }

    return total;
  }

  handleEst = () => {
    let est = 0;
    let time = 0;
    const { items, timeSlotSelected } = this.props;
    items && items.map((item) => {
      est = Math.round((parseFloat(est) + parseFloat(item.duration)) * 1e12) / 1e12
    });
    if (est > 0) {
      let duration = est * 60;
      time = duration;
      let hours = Math.trunc(duration / 60);
      let minutes = duration % 60;
      est = `${hours}h${minutes > 0 ? minutes : ''}`;
    }

    return `${est} duration, ends at ${moment(timeSlotSelected, 'HH:mm').add(parseInt(time), 'minutes').format('HH:mm')}`;
  }

  componentWillReceiveProps() {
    this.handleEst();
  }

  render() {
    const { storeInfo, items, step, date, timeSlotSelected, staff, discountPercent, maxDiscount  } = this.props;
    let discountPrice = 0;
		let strMaxDiscount='';
		let total = this.handleTotal();
		if(discountPercent){
			discountPrice =  total*(discountPercent/100);
		}
		if(maxDiscount > 0 && discountPrice > maxDiscount){
		strMaxDiscount =  `Max AUD ${parseFloat(maxDiscount).toFixed(2)}`;
		discountPrice = maxDiscount;
		}
    const list =
      items && items.map((item, index) => {
        return (
          <ListItem
            key={!item.children ? item.id : item.children.id}
            item={item}
            index={index}
          />
        );
      });
    return (
      <Pane className="float_cart">
        <Pane className="block_cart store-booking">
          <Link
            className=""
            to={`/${storeInfo.id}/services`}
          >
            <Pane className="store_info">
              <Pane className="title">
                <Pane className="title_head">
                  <FormattedMessage
                    id="store_booking"
                    defaultMessage="STORE'S BOOKING"
                  ></FormattedMessage>
                </Pane>
                <Pane className="store_name">
                  {storeInfo.business ? storeInfo.business.name : ""}
                </Pane>
                <Pane className="store_addr">
                  {storeInfo.business ? storeInfo.business.address : ""}
                </Pane>
              </Pane>
              <img
                src={
                  get(storeInfo, "profileImage") ||
                  process.env.PUBLIC_URL + "/assets/images/user.jpg"
                }
                alt="logo"
                className="logo_store"
              />
            </Pane>
          </Link>
          {step === 3 && (
            <Pane className="time-info">
              <Pane className="time-info__book">
                {moment(date).format("D MMMM YYYY")} at {timeSlotSelected}
              </Pane>
              <Pane className="time-info__est">
                {this.handleEst()}
              </Pane>
            </Pane>
          )}
          {items.length > 0 ? (
            <Pane className="items">{list}</Pane>
          ) : (
            <Pane className="empty-container">
              <Pane className="empty-content">
                <h4 className="empty-title">
                  No services selected yet
                </h4>
              </Pane>
            </Pane>
          )}
          {discountPercent && (
            <>
            <Pane className="total">
              <Pane className="total_title">
                <span>Total Items</span>
                :
              </Pane>
              <Pane className="total_price">
                {this.handleTotal() > 0 ? (
                  <Currency price={this.handleTotal()} />
                ) : (
                  <>free</>
                )}
              </Pane>
            </Pane>
            <Pane className="total">
              <Pane className="total_title">
                <span>Discount ({discountPercent}% {strMaxDiscount})</span> :
              </Pane>
              <Pane className="total_price">
                {this.handleTotal() > 0 ? (
                  <Currency price={discountPrice} />
                ) : (
                  <>free</>
                )}
              </Pane>
            </Pane>
            </>
          )}
          <Pane className="total">
            <Pane className="total_title">
              <FormattedMessage
                id="total"
                defaultMessage="TOTAL"
              ></FormattedMessage>
              :
            </Pane>
            <Pane className="total_price" style={{color:'#e22121'}}>
              {this.handleTotal() > 0 ? (
                <Currency price={this.handleTotal() - discountPrice} />
              ) : (
                <>free</>
              )}
            </Pane>
          </Pane>


          {step !== 1 && (
            <Pane className="staff">
              <Pane className="staff-title">
                Staff
              </Pane>
              <Pane className="staff-info">
                <Pane className="staff-info__img">
                  <img 
                    src={`${staff && staff.images && staff.images.length > 0 ? staff.images[0].url : `${process.env.PUBLIC_URL}/assets/images/icon-admin.png`}`}
                    alt="staff img"
                  />
                </Pane>
                <Pane className="staff-info__name">
                  {staff ? staff.fullname : 'STORE PICK FOR YOU'}
                </Pane>
              </Pane>
            </Pane>
          )}
        </Pane>
        {items.length > 0 && step === 1 && (
          <Button
            className="btn btn-process btn-book-now mt-4"
            onClick={() => this.props.handleStep(2)}
          >
            <FormattedMessage
              id="book_now"
              defaultMessage="Book now"
            />
          </Button>
        )}
      </Pane>
    );
  }
}

export default withRouter(FloatList);
