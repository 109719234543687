import * as types from 'constants/ActionTypes'
import api from 'api'

// Services
export const getServicesByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_SERVICES, types.RECEIVE_MY_SERVICES_SUCCESS, types.RECEIVE_MY_SERVICES_FAILURE],
  payload: {
    request: api.user.services(params)
  }
})

export const getCategoriesByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_SERVICES, types.RECEIVE_MY_SERVICE_CATEGORIES_SUCCESS, types.RECEIVE_MY_SERVICES_FAILURE],
  payload: {
    request: api.user.serviceCategories(params)
  }
})

// Create a service
export const createMyService = (params) => (dispatch) => dispatch({
  type: types.CREATE_MY_SERVICE,
  payload: {
    request: api.user.createService(params)
  }
})

// Get service detail
export const getMyServiceDetail = (params) => (dispatch) => dispatch({
  type: types.MY_SERVICE_DETAIL,
  payload: {
    request: api.user.serviceDetail(params)
  }
})

// Edit service detail
export const editMyService = (params) => (dispatch) => dispatch({
  type: types.EDIT_MY_SERVICE,
  payload: {
    request: api.user.editService(params)
  }
})

// Edit service detail
export const deleteMyService = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_SERVICE,
  payload: {
    request: api.user.deleteService(params)
  }
})
