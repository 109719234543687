import React, { PureComponent } from 'react';
import { Pane } from 'shared/components/layouts';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';

class Banner extends PureComponent {
  render() {
    return (
      <Pane className="delivery-container mt-5 mb-5">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-4 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                delay={0.5}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="delivery-card text-lg-center text-left">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/landing/stores.png'
                    }
                    alt="stores"
                    className="delivery-img"
                  />
                  <Pane className="delivery-content pt-2 pb-2">
                    <h3 className="delivery-title">
                      <FormattedMessage
                        id="get_your_store_ready_in_3_minutes"
                        defaultMessage="Get your store ready in 3 minutes"
                      />
                    </h3>
                    <p className="delivery-norm">
                      <FormattedMessage
                        id="whether_you_selling_physical"
                        defaultMessage="Whether you’re selling physical products or providing
                        services, Cardbey’s got you covered. Start selling
                        anywhere for free."
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-4 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                delay={1}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="delivery-card text-lg-center text-left">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/landing/delivery.png'
                    }
                    alt="stores"
                    className="delivery-img"
                  />
                  <Pane className="delivery-content pt-2 pb-2">
                    <h3 className="delivery-title">
                      <FormattedMessage
                        id="cheaper_simpler_home_delivery_service"
                        defaultMessage="Cheaper, simpler Home Delivery Service"
                      />
                    </h3>
                    <p className="delivery-norm">
                      <FormattedMessage
                        id="one_click_shipping_availabel_at_checkout_for_your_customers"
                        defaultMessage="One-click shipping available at checkout for your customers, shipped by Cardbey. No need for third-party delivery."
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-4 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                delay={1.5}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="delivery-card text-lg-center text-left">
                  <img
                    src={
                      process.env.PUBLIC_URL + '/assets/images/landing/star.png'
                    }
                    alt="stores"
                    className="delivery-img"
                  />
                  <Pane className="delivery-content pt-2 pb-2">
                    <h3 className="delivery-title">
                      <FormattedMessage
                        id="all_in_one_service_for_store_owners"
                        defaultMessage="All-in-one service for store owners"
                      />
                    </h3>
                    <p className="delivery-norm">
                      <FormattedMessage
                        id="widen_your_reach"
                        defaultMessage="Widen your reach, reduce marketing effort with Cardbey Classified Ads"
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default Banner;
