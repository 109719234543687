import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { getCollectionsByUserId, createMyCollection } from 'actions/myCollections';
import { getCollectionCategories, createCategory } from 'actions/category';
import CreateCollectionForm from './CreateCollectionForm';

class CreateCollection extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getServiceCategories: PropTypes.func,
    createMyCollection: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    getCollectionsByUserId: PropTypes.func.isRequired,
  };

  getMyCollections = () => {
    this.props.getCollectionsByUserId({ id: this.props.userInfo.id });
  };

  componentDidMount() {
    this.props.getCollectionCategories();
  }

  render() {
    return (
      <>
        <Pane className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="heading-title mt-2">
            <FormattedMessage
              id="create_new_collection"
              defaultMessage="Create new collection"
            />
          </h3>
        </Pane>
        <CreateCollectionForm
          userInfo={this.props.userInfo}
          categories={this.props.categories}
          getMyCollections={this.getMyCollections}
          createMyCollection={this.props.createMyCollection}
          createCategory={this.props.createCategory}
          history={this.props.history}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.collectionCategories,
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getCollectionsByUserId: bindActionCreators(getCollectionsByUserId, dispatch),
  getCollectionCategories: bindActionCreators(getCollectionCategories, dispatch),
  createMyCollection: bindActionCreators(createMyCollection, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(CreateCollection);
