import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { formatDecimal } from "shared/helpers";
import Config from "constants/Config";
import { Pane } from 'shared/components/layouts';

class OrderSummary extends Component {
  static propTypes = {
    posCart: PropTypes.object,
  };


  render() {
    const { posCart, total } = this.props;

    return (
      <Pane className="order-summary card-body">
          <>
            <Pane className="summary-body">
              <Pane className="summary-item">
                AUD ${formatDecimal(total)} - {posCart.length} {`item${posCart.length > 1 ? 's' : ''}`} - <span className={`badge badge-new`}>
                  NEW
                </span>
              </Pane>
              <Pane className="summary-user-info">
                POS Order
              </Pane>
            </Pane>
          </>
        <Pane className="summary-method">{this.props.delivery_method}</Pane>
        <Pane className="summary-body">
          Table:&nbsp;
          <span className={`badge badge-done`}>
            {this.props.table}
          </span>
        </Pane>
        <Pane className="summary-body">
          <em className="summary-time">Order Time: {moment().format("DD-MM-YYYY HH:mm")}</em>
        </Pane>
      </Pane>
    );
  }
}

export default OrderSummary;
