import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = params => {
	return {
		method: 'GET',
		baseURL: Config.NEW_API_URL,
		url: `/digital-share?user_id=${params.user_id}`
	};
};

const parseResponse = response => {
	if (response.data) {
		return response.data.map(item => ({
			...formatJson(response, item.type, item.id)
		}));
	}
	return [];
};

export { exec, parseResponse };