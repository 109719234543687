import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';

class CategoryItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
  };

  static defaultProps = {
    id: 0,
    name: '',
  };

  render() {
    return (
      <li className="category-item">
        <Pane
          onClick={() => this.props.onChangeCate(this.props.id)}
          className={`category-link ${this.props.cate == this.props.id &&
            'category-all'}`}
        >
          {this.props.name}
        </Pane>
      </li>
    );
  }
}

export default CategoryItem;
