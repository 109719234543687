import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import ReactTooltip from 'react-tooltip';
import { Rating } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';

class ListCollection extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    rating: PropTypes.number,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };


  changeRoute = () => {
    this.props.history.push(`${this.props.type}/${this.props.id}/edit`);
  };

  showConfirmDelete = () => {
    const { intl } = this.props;

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({
        id: 'notification.are_you_sure_you_want_to_delete_this',
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.deleteItem();
          },
        },
        {
          label: intl.formatMessage({ id: 'no' }),
        },
      ],
    });
  };

  deleteItem = () => {
    this.props.onDelete({ id: this.props.id });
  };

  render() {
    const { item, name, image, rating } = this.props;
    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const ext = image != null ? image.substr(image.lastIndexOf('.') + 1) : '';
    return (
      <Pane className="collections col-sm-6 col-12">
        <Pane className="adds-item flex-column">
          <Link to={`${this.props.type}/${this.props.id}/edit`}>
            {extImage.includes(ext) && (
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`,
                }}
              >
              </Pane>
            )} {!extImage.includes(ext) && ext && (
              <Pane className="adds-images">
                {extImage.indexOf(ext) <= 0}
                {ext !== '' ? <i className="fa fa-video" /> : ''}
                <video
                  className="carbeyvideo-list"
                  controls
                >
                  <source src={image} id="video_here" />
                  Your browser does not support HTML5 video.
                </video>
              </Pane>
            )} {!ext && item.typeCollect !== 'Post'  && (
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/default-image.png)`
                }}
              ></Pane>
            )}
          </Link>
          <Pane className="adds-content">
            <Link to={`/${this.props.type}/${this.props.item.slug}`}>
              <h5 className="adds-norm" title={name}>
                {name}
              </h5>
            </Link>
            <Pane
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
            <Rating
              rating={rating}
              icon="star"
              size="small"
              maxRating="5"
              disabled
            />
            {this.props.control && (
              <Pane className="adds-actions">
                <span onClick={this.changeRoute} data-tip data-for="edit">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/edit.svg`
                    }
                    alt="cart"
                    className="icon-edit"
                  />
                </span>
                <span
                  onClick={this.showConfirmDelete}
                  data-tip
                  data-for="remove"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/delete.svg`
                    }
                    alt="cart"
                    className="icon-delete"
                  />
                </span>
              </Pane>
            )}
          </Pane>
        </Pane>
        <ReactTooltip place="top" type="dark" effect="solid" id="edit">
          <FormattedMessage id="edit" defaultMessage="Edit" />
        </ReactTooltip>
        <ReactTooltip place="top" type="dark" effect="solid" id="remove">
          <FormattedMessage id="delete" defaultMessage="Delete" />
        </ReactTooltip>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListCollection));
