import * as types from 'constants/ActionTypes';
import UserAdapter from 'services/users/adapter';

const initialState = {
  results: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
};

const digitals = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.RECEIVE_MY_DIGITALS:
      return Object.assign({}, state, {
        fetching: true
      });
    case types.RECEIVE_MY_DIGITALS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.digitalsBrowse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      });
    case types.RECEIVE_MY_DIGITALS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      });
    default:
      return state;
  }
};

export { digitals };
