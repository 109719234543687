import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { MasterLayout } from 'components/layout'
import { getServiceDetail, getTimeSlots } from 'actions/services'
import StoreBooking from '../../components/scenes/services/store-booking/StoreBooking'

import { Pane } from 'shared/components/layouts'
import BussinessList from 'components/common/bussiness/BussinessList'
import { checkCode } from "actions/customer";
class BookingPage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    getServiceDetail: PropTypes.func,
    getTimeSlots: PropTypes.func
  }

  render() {
    return (
      <MasterLayout>
        <Pane className='main-container main-category pt2 pb-5'>
          <Pane className="product-container">
            <Pane className="container">
              <StoreBooking
                location={this.props.location}
                history={this.props.history}
                checkCode={this.props.checkCode}
              />
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = state => ({
  auth: state.auth,
  serviceDetail: state.serviceDetail
})

const bindDispatchToProps = dispatch => ({
  getServiceDetail: bindActionCreators(getServiceDetail, dispatch),
  getTimeSlots: bindActionCreators(getTimeSlots, dispatch),
  checkCode: bindActionCreators(checkCode, dispatch),
})

export default connect(bindStateToProps, bindDispatchToProps)(BookingPage)