import * as types from "constants/ActionTypes";
import api from "api";
export const getProjectsByUserId = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_MY_PROJECTS,
      types.RECEIVE_MY_PROJECTS_SUCCESS,
      types.RECEIVE_MY_PROJECTS_FAILURE,
    ],
    payload: {
      request: api.user.projects(params),
    },
  });
export const getProjectDetail = (params) => (dispatch) =>
  dispatch({
    types: types.MY_PROJECT_DETAIL,
    payload: {
      request: api.user.readProject(params),
    },
  });
export const createMyProject = (params) => (dispatch) =>
  dispatch({
    types: types.CREATE_MY_PROJECT,
    payload: {
      request: api.user.addProject(params),
    },
  });
export const editMyProject = (params) => (dispatch) =>
  dispatch({
    types: types.EDIT_MY_PROJECT,
    payload: {
      request: api.user.editProject(params),
    },
  });
export const removeMyProject = (params) => (dispatch) =>
  dispatch({
    types: types.REMOVE_MY_PROJECT,
    payload: {
      request: api.user.removeProject(params),
    },
  });