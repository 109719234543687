import { get } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { QRCode } from "shared/components";
import Modal from "react-responsive-modal";
import { Pane } from "shared/components/layouts";
import ReactTooltip from "react-tooltip";
import Content from "../../../common/readmore/ReadMore";
import {
  Rating, List, Loader,
  Dimmer,
} from "semantic-ui-react";

class UserInfo extends Component {
  static propTypes = {
    UserInfo: PropTypes.object,
  };

  state = {
    open: false,
    loadingAvatar: false,
    imageAvaRender: "",
    imageAvaFile: "",
  };

  onOpenModal = () => {
    this.setState({ open: true },
      () => {
        let tawkBtn = document.querySelector('[title="chat widget"]');
        if (tawkBtn) {
          tawkBtn.style.display = 'none';
        }
      })
  };

  onCloseModal = () => {
    this.setState({ open: false },
      () => {
        let tawkBtn = document.querySelector('[title="chat widget"]');
        if (tawkBtn) {
          tawkBtn.style.display = 'block';
        }
      });
  };

  componentDidMount(prevProps, prevState) {
    const name = this.props.isShowQRCode;
    if (name && name === 'true') {
      this.onOpenModal();
    }
  }

  
  cancelUpload = () => {
    this.setState({ imageAvaRender: "" });
  }

  onChangeAvatar = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          imageAvaRender: reader.result,
          imageAvaFile: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  uploadImage = () => {
    const data = new FormData();

    if (this.state.imageAvaFile) {
      data.append("id", this.props.storeInfo.id);
      data.append("image", this.state.imageAvaFile);

      this.setState({ loadingAvatar: true });

      this.props
        .updateUserInfo(data)
        .then((response) => {
          this.setState({ loadingAvatar: false });
          window.location.reload();
        })
        .catch((errors) => {
          this.setState({ loadingAvatar: false });
        });
    }
  };

  render() {
    const { storeInfo } = this.props;
    return (
      <Pane className="container">
        <Pane className="row">
          <Pane className="col-lg-3 col-md-12 col-12">
            <Pane className="logo-stores">
            {this.state.loadingAvatar ? (
                <Pane>
                  <Dimmer active inverted>
                    <Loader size="small" />
                  </Dimmer>
                </Pane>
              ) : null}
              {this.state.imageAvaRender ? (
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    this.state.imageAvaRender
                      ? this.state.imageAvaRender
                      : process.env.PUBLIC_URL + "/assets/images/user.jpg"
                  }
                  alt="logo"
                  className="logo-img"
                />
              ) : (
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    get(storeInfo, "profileImage")
                      ? get(storeInfo, "profileImage")
                      : process.env.PUBLIC_URL + "/assets/images/user.jpg"
                  }
                  alt="logo"
                  className="logo-img"
                />
              )}
              <div className="block-qr-code" onClick={this.onOpenModal} data-tip data-for={`tooltip-qr-card`}>
                <span className="ic-common-qr-code" />
              </div>
              <input
                type="file"
                id="image-avatar"
                className="form-input-file d-none"
                name="image"
                placeholder=""
                value=""
                accept="image/gif, image/jpeg, image/png"
                disabled={this.state.loadingAvatar}
                onChange={this.onChangeAvatar}
              />
              <label htmlFor="image-avatar" className="edit-avatar">
                <i className="icon-camera" />
              </label>
              {this.state.imageAvaRender && (
                <Pane className="block-action">
                  <Pane className="block-action__btn btn-cancel" onClick={this.cancelUpload}>Cancel</Pane>
                  <Pane className="block-action__btn btn-save" onClick={this.uploadImage}>Save</Pane>
                </Pane>
              )}
              <div className="popover__content">Digital card</div>
              <ReactTooltip
                place="top"
                type="dark"
                effect="float"
                id={"tooltip-qr-card"}
              >
                Click to get digital card
              </ReactTooltip>
              <Modal
                className="popup-qrcode"
                classNames={{
                  closeIcon: 'customIconBtn'
                }}
                open={this.state.open}
                onClose={this.onCloseModal}
                center
                showCloseIcon={true}
              >
                <QRCode
                  onClose={this.onCloseModal}
                  qrcode={storeInfo.qrcode}
                  storeInfo={storeInfo}
                />
              </Modal>
            </Pane>
          </Pane>
          <Pane className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-center">
            <Pane className="heading-content">
              <Pane className="heading-info">
                <Pane className="heading-left">
                  <h2 className="heading-title store-title-page">{storeInfo.displayName || ""}</h2>
                  <Pane className="heading-norm bussiness-description font-italic font-weight-normal pb-2">{storeInfo.sub_title || ""}
                    &nbsp;<Content text={storeInfo.description || ""} />
                  </Pane>
                  {/* <Pane className="heading-norm bussiness-description" title={storeInfo.description || ""}>
                    <Content text={storeInfo.description || ""} />
                  </Pane> */}
                </Pane>
                <Pane className="heading-profile w-md-50">
                  <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                    <a
                      href={`https://www.google.com/maps?q=${storeInfo.address
                        }`}
                      className="link"
                    >
                      <div className="box-icon" data-tip data-for={`location`}>
                        <span className="icon-map" />
                      </div>
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="float"
                        id={"location"}
                      >
                        {storeInfo.address || ""}
                      </ReactTooltip>
                    </a>
                    <div className="directions-text">DIRECTIONS</div>
                  </span>
                  <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                    <a href={`tel:${storeInfo.phoneNumber}`} className="link">
                      <div className="box-icon" data-tip data-for={`phone`}>
                        <span className="ic-common-phone icon" />
                      </div>
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="float"
                        id={"phone"}
                      >
                        {storeInfo.phoneNumber || ""}
                      </ReactTooltip>
                    </a>
                    <Pane className="directions-text">CALL</Pane>
                  </span>
                  <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                    <a href={`mailto:${storeInfo.email}`} className="link">
                      <div className="box-icon" data-tip data-for={`email`}>
                        <span className="ic-common-mail icon" />
                      </div>
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="float"
                        id={"email"}
                      >
                        {storeInfo.email || ""}
                      </ReactTooltip>
                    </a>
                    <Pane className="directions-text">EMAIL</Pane>
                  </span>
                  {storeInfo.url_website && (
                    <span className="profile-name">
                      <a
                        target="_blank"
                        href={storeInfo.url_website}
                        className="link"
                      >
                        <div className="box-icon">
                          <span className="icon-globe" />
                        </div>
                        {
                          storeInfo.url_website && (
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="float"
                              id={"websiteUrl"}
                            >
                              {storeInfo.url_website || ""}
                            </ReactTooltip>
                          )
                        }
                      </a>
                      <Pane className="directions-text">WEBSITE</Pane>
                    </span>
                  )}
                </Pane>
              </Pane>
              <Pane className="heading-trading">
                <Pane className="heading-top mt-2">
                  <span className="ic-common-toggle toggle-menu-top" onClick={this.props.onChangeToggleMenu} />
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default UserInfo;
