import PropTypes from "prop-types";
import _, { isEmpty } from "lodash";
import { connect } from "react-redux";
import queryString from "query-string";
import React, { Component } from "react";
import update from "immutability-helper";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { ServiceList } from "../list/services";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import { getAllServices } from "actions/services";
import { LoadingSearch } from "components/common/loading";
import { ScrollMenu } from "components/common/scrollMenu";
import { addStoreBookingsRequest } from "actions/storeBookings";

class StoreServices extends Component {
  static propTypes = {
    match: PropTypes.object,
    getAllServices: PropTypes.func,
  };

  state = {
    storeId: "",
    services: {},
    totalPages: 0,
    fetched: false,
    modeView: "gridView",
    serviceCategories: [],
    fetchingServices: false,
    page: queryString.parse(this.props.location.search).page || 1,
    height: 0,
  };

  getStoreServices = async () => {
    this.setState({
      fetchingServices: true,
      fetched: false,
    });
    const {
      params: { storeId },
    } = this.props.match;
    this.props.getAllServices({
      store: storeId,
      size: 1000,
      images: "",
    }).then(() => {
      this.setState({
        fetchingServices: false,
        fetched: true,
      });
    });
    this.onSetDefaultView(this.props.storeInfo);
  };

  componentWillMount() {
    this.setState({
      height: window.innerHeight,
    });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getStoreServices();
    });

    this.getStoreServices();

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }


  handleScroll = () => {
    const { services } = this.props;
    this.setState(
      {
        height: window.innerHeight,
      },
      () => {
        const banner = document.getElementById("banner-container");
        const info = document.getElementById("info-container");
        const video = document.getElementById("video-container");
        const menu = document.getElementById("anchor");
        const stickyBanner = banner && banner.offsetHeight;
        const stickyInfo = info && info.offsetHeight;
        const stickyVideo = video && video.offsetHeight;
        const items = _.chain(services.results)
          .groupBy((x) => x.category && x.category.name)
          .map((item, key) => Object.assign({}, { name: key, items: item }))
          .sort(
            (a, b) =>
              new Date(_.get(b.items[0], "category.createdAt")).getTime() /
              1000 -
              new Date(_.get(a.items[0], "category.createdAt")).getTime() / 1000
          )
          .value();
        const itemsScroll = items.map((item) => {
          const itemMenu = document.getElementById(item.name);
          return {
            ...item,
            scroll: itemMenu && itemMenu.offsetTop + 500,
          };
        });
        let sum = 0;
        if (window.innerWidth < 992) {
          sum = stickyBanner + stickyInfo;
        } else {
          sum = stickyBanner + stickyInfo + stickyVideo;
        }
        if (window.pageYOffset > sum) {
          const itemScroll = itemsScroll.filter(
            (item) => item.scroll >= window.scrollY
          );
          if (!isEmpty(itemScroll)) {
            this.setState({
              selected: itemScroll[0].name,
            });
          }
          menu && menu.classList.add("menu-breadcrum-sticky");
        } else {
          const itemScroll = itemsScroll.filter(
            (item) => item.scroll >= window.scrollY
          );
          if (!isEmpty(itemScroll)) {
            this.setState({
              selected: itemScroll[0].name,
            });
          }
          menu && menu.classList.remove("menu-breadcrum-sticky");
        }
      }
    );
  };

  onSetDefaultView = (storeInfo) => {
    if (!isEmpty(storeInfo)) {
      this.setState({
        modeView: storeInfo.business.isGridView === 1 ? 'gridView' : 'listView',
      });
    }
  };

  onSelect = (key) => {
    this.setState(
      {
        keySort: key,
        selected: key,
      },
      () => {
        const elementScroll = document.getElementById(key);
        if (elementScroll) {
          elementScroll.scrollIntoView();
        }
      }
    );
  };

  sortByName = (data) => {
    if (this.state.keySort) {
      const index = data.findIndex((item) => item.name === this.state.keySort);
      const dataNew = update(data, {
        $splice: [[index, 1], [0, 0, data[index]]],
      });
      return dataNew;
    }
    return data;
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === "gridView" ? "listView" : "gridView",
      keySort: null,
    }));
  };

  render() {
    const {
      auth,
      match: { params },
      services
    } = this.props;
    const { fetched, fetchingServices, selected } = this.state;
    const items = _.chain(services.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .value();

    return (
      <>
        <Pane className="tab-heading tab-heading-stores service-before" id="anchor">
          <h3 className="heading-title mt-3">
            <FormattedMessage id="services" defaultMessage="Services" />
          </h3>
          {!_.isEmpty(items) && (
            <Pane
              className="category-list menu-breadcrum mt-3"
              id="menu-breadcrum"
            >
              <ScrollMenu
                items={items}
                onSelect={this.onSelect}
                selected={selected}
              />
            </Pane>
          )}
          <Pane className="view d-flex justify-content-end mt-3">
            <span className="list-view" onClick={this.onChangeModeView}>
              <img
                src={
                  process.env.PUBLIC_URL + this.state.modeView === "gridView"
                    ? "/assets/images/icons/list-view.svg"
                    : "/assets/images/icons/grid-view.svg"
                }
                className="image-logo anchor-image-logo"
                alt="list-view"
              />
            </span>
          </Pane>
        </Pane>

        {!fetchingServices && fetched ? (
          <>
            {isEmpty(items) && <Empty type="white" />}
            {!isEmpty(items) && items.map((category, index) => {
              if (!category.items.length) {
                return <></>;
              }
              return (
                <React.Fragment key={index}>
                  <Pane key={category.name} id={category.name}>
                    <Pane className="category-name"><strong>{category.name}</strong></Pane>
                    <ServiceList
                      auth={auth}
                      name={category.name}
                      services={category.items}
                      modeView={this.state.modeView}
                      serviceModal={this.props.serviceModal}
                      addStoreBookingsRequest={this.props.addStoreBookingsRequest}
                    />
                  </Pane>
                </React.Fragment>);
            })}
          </>
        ) : (
          <LoadingSearch type="white" />
        )}
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  stores: state.stores,
  services: state.services,
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  getAllServices: bindActionCreators(getAllServices, dispatch),
  addStoreBookingsRequest: bindActionCreators(addStoreBookingsRequest, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(StoreServices);
