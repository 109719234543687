import React  from 'react'
import PropTypes from 'prop-types'

const InlineMessage = ({ type, text }) => {
  return (
    <label className={`notification-message ${type} show`}>{text}</label>
  )
}

InlineMessage.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string
}

InlineMessage.defaultProps = {
  type: 'error'
}

export default InlineMessage
