import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink, Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { getShoppingCart } from "actions/cart";
import { getAllProducts } from "actions/products";
import { logout, getUserInfo } from "actions/auth";
import { saveUserDevice } from "actions/notification";
import SearchBox from "./SearchBox";
import N_SearchBox from "./N_SearchBox";
import Language from "./Language";
import { Pane } from "shared/components/layouts";
import { Select } from "semantic-ui-react";
import GuestUserContact from "shared/components/GuestUserContact";
import { addCoordinate } from "actions/coordinates";
import { getAreas, addType, addArea, getAreaByBusiness } from "actions/area";
import { getNotisByUser } from "actions/notis";
import { searchQuick } from "actions/search";
import Pusher from "pusher-js";
import Config from "constants/Config";

const foodOrders = [
  {
    id: "159",
    name: "Restaurants",
    children: [
      {
        id: "191",
        name: "Korean Restaurants",
      },
      {
        id: "190",
        name: "Japanese Restaurants",
      },
      {
        id: "189",
        name: "Chinese Restaurants",
      },
      {
        id: "188",
        name: "European Restaurants",
      },
      {
        id: "170",
        name: "Arabic Restaurants",
      },
      {
        id: "169",
        name: "Vietnamese Restaurants",
      },
      {
        id: "168",
        name: "Italian Restaurants",
      },
      {
        id: "167",
        name: "African Restaurants",
      },
    ],
  },
  {
    id: "158",
    name: "Grocery Sellers",
  },
  {
    id: "157",
    name: "Fresh Food Sellers",
    children: [
      {
        id: "162",
        name: "Vegetable Sellers",
      },
      {
        id: "161",
        name: "SeaFood Sellers",
      },
      {
        id: "160",
        name: "Meat Sellers",
      },
    ],
  },
];
class Header extends React.Component {
  static propTypes = {
    auth: PropTypes.object,
    cart: PropTypes.any,
    getShoppingCart: PropTypes.func,
    getAllProducts: PropTypes.func,
    getUserInfo: PropTypes.func,
    searchQuick: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      visible: false,
      promotions: 0,
      height: 0,
      area: {
        state: "VIC",
        city: null,
      },
      states: [],
      showSearch: false,
    };
    this.subscribeToPusher = this.subscribeToPusher.bind(this);
    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      height: window.innerHeight,
    });
    window.addEventListener("scroll", this.handleScroll);
  }

  onSetVisible = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible,
    }));
  };

  saveUserDevice = (userId, playerId) => {
    this.props
      .saveUserDevice({ userId: userId.toString(), token: playerId })
      .then(() => {
        localStorage.setItem(
          "deviceInfo",
          JSON.stringify({
            userId,
            playerId,
          })
        );
      });
  };

  componentWillUnmount() {
    this.setState({
      height: window.innerHeight,
    });
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState(
      {
        height: window.innerHeight,
      },
      () => {
        const header = document.getElementById("header-container");
        const header_sp = document.getElementById("header-sp");
        const sticky = header.offsetTop;
        if (window.pageYOffset - 80 > sticky) {
          header.classList.add("header-sticky");
          header_sp.classList.add("header-sticky");
        } else {
          header.classList.remove("header-sticky");
          header_sp.classList.remove("header-sticky");
        }
      }
    );
  };

  componentDidMount() {
    const { auth } = this.props;
    const OneSignal = window.OneSignal || [];

    if (localStorage.getItem("cartUUID")) {
      if (auth.isAuthenticated) {
        this.props.getUserInfo({ userId: auth.user.id }).then(() => {
          this.props.getShoppingCart();
        });
      } else {
        this.props.getShoppingCart();
      }
    }
    // if (OneSignal && auth.isAuthenticated) {
    //   OneSignal.push(() => {
    //     // user has subscribed
    //     OneSignal.getUserId((id) => {
    //       if (id) {
    //         const deviceInfo = localStorage.getItem("deviceInfo")
    //           ? JSON.parse(localStorage.getItem("deviceInfo"))
    //           : {};

    //         if (deviceInfo && deviceInfo.playerId && deviceInfo.userId) {
    //           if (
    //             deviceInfo.playerId !== id ||
    //             Number(deviceInfo.userId) !== Number(auth.user.id)
    //           ) {
    //             // save user's device
    //             this.saveUserDevice(auth.user.id, id);
    //           }
    //         } else {
    //           // save user's device
    //           this.saveUserDevice(auth.user.id, id);
    //         }
    //       }
    //     });
    //   });
    // }

    if (auth.isAuthenticated && auth.user) {
      this.subscribeToPusher();
      this.props.getNotisByUser({ id: auth.user.id });
    }

    this.getAreas();

    // this.initPosition();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  initCoordinates = () => {
    return new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  initPosition = async () => {
    if (!localStorage.getItem("lat") && !localStorage.getItem("lng")) {
      if (navigator.geolocation) {
        let res = await this.initCoordinates();
        let lat = res.coords.latitude;
        let lng = res.coords.longitude;
        let data = {
          lat,
          lng,
        };
        this.props.addCoordinate(data);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }
  };

  getAreas = () => {
    this.props.getAreas().then(() => {
      if (this.props.area && this.props.area.user_area) {
        this.setState(
          { area: { ...this.state.area, ...this.props.area.user_area } },
          () => {
            this.props.getAreaByBusiness().then(() => {
              let states = [];
              if (this.props.area.areaBusiness.states) {
                states = this.getStates(this.props.area.areaBusiness.states);
              }
              this.setState({ states: states });
            });
          }
        );
      }
    });
  };

  getStates = (states) => {
    return (
      this.props.area.areas.states
        .filter((state) => states.includes(state.isoCode))
        .map((state) => ({ text: state.name, value: state.isoCode })) || []
    );
  };

  filterArea = (value) => {
    const { area } = this.state;
    this.setState({ area: { ...area, state: value, city: null } }, () => {
      this.props.addArea(this.state.area);
      this.props.history.push(`/`);
    });
  };

  subscribeToPusher() {
    const { auth } = this.props;
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      encrypted: true,
      cluster: "ap4",
    });
    // Subscribe to the channel we specified in our Laravel Event
    var channel = pusher.subscribe("my-channel");
    // Bind a function to a Event (the full Laravel class)
    channel.bind("my-event", (data) => {
      if (data && data.target_id && auth.user.id == data.target_id) {
        this.props.getNotisByUser({ id: auth.user.id }).then(() => {
          let audio = document.getElementsByClassName("audio-element")[0];
          setTimeout(() => {
            let promise = audio.play();
            if (promise !== undefined) {
              promise.then((_) => {}).catch((error) => {});
            }
          }, 1000);
          let noti_order = document.querySelectorAll(".noti-order");
          noti_order.forEach((element) =>
            element.classList.add("noti-animation")
          );
          setTimeout(() => {
            noti_order.forEach((element) =>
              element.classList.remove("noti-animation")
            );
          }, 5000);
        });
      }
    });
  }

  goToLink = (objectType, objectId, status) => {
    const { user } = this.props.auth;
    const name =
      user && user.displayName
        ? user.displayName.replace(/ /g, "_").toLowerCase()
        : "";
    let path = "";
    const targetSeller = [
      Config.ORDER_STATUS.NEW,
      Config.ORDER_STATUS.CANCEL_REQUESTED,
    ].includes(status);
    const linkOrder = `/u/${name}/orders/${objectId}/${
      targetSeller ? "selling" : "buying"
    }/detail`;
    const linkBooking = `/u/${name}/bookings${
      targetSeller ? "/sellers" : ""
    }/${objectId}/detail`;
    const linkDigitalShareHost = `/u/${name}/digital-slideshow/screen/${objectId}/edit`;
    const linkDigitalShareRenter = `/u/${name}/digitals-share`;
    switch (objectType) {
      case Config.TYPE_ORDER:
        path = linkOrder;
        break;
      case Config.TYPE_BOOKING:
        path = linkBooking;
        break;
      case Config.TYPE_DIGITAL_SHARE_HOST:
        path = linkDigitalShareHost;
        break;
      case Config.TYPE_DIGITAL_SHARE_RENTER:
        path = linkDigitalShareRenter;
        break;
      default:
        path = `/u/${name}`;
    }
    this.props.history.push(path);
    this.onSetVisible();
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        visible: false,
      });
    }
  }

  onChangeMenu = (event) => {
    if (event.target.checked) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }
  };

  logout = () => {
    this.props.history.push("/");
    this.props.logout();
  };

  onRedirect = (path) => this.props.history.push(path);

  toggleSearch = () => {
    this.setState({ showSearch: !this.state.showSearch }, () => {
      if (this.state.showSearch) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    });
  };

  detectType = (type) => {
    this.props.addType(type);
    this.props.history.push(`/`);
  };

  render() {
    const { visible, area } = this.state;
    const { user, isAuthenticated } = this.props.auth;
    const {
      cart,
      location: { pathname },
      notis,
    } = this.props;
    const name =
      user && user.displayName
        ? user.displayName.replace(/ /g, "_").toLowerCase()
        : "";
    return (
      <>
        <header
          className="header-container"
          id="header-container"
          ref={this.wrapperRef}
        >
          <Pane className="header-logo">
            <audio className="audio-element" playsInline>
              <source
                src={`/assets/sounds/noti.mp3?v=${Math.random()}`}
                preload="none"
              />
            </audio>
            {/* <Pane>
              <Menu visible={visible} onSetVisible={this.onSetVisible} />
              <Icon
                name="bars"
                className="toggle-menu-header"
                onClick={() => {
                  this.onSetVisible();
                }}
              />
            </Pane> */}
            <Link className="navbar-brand pl-2" to={"/"}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/layouts/Logo-Header.png`}
                className="image-logo"
                alt="caterwin logo"
              />
            </Link>
          </Pane>
          <Pane className="menu-desktop ml-2">
            <ul
              className={`navbar-menu ${isAuthenticated &&
                "navbar-authenticated"}`}
            >
              <li className="menu-item">
                <span
                  onClick={() => this.detectType("Food")}
                  className={`nav-link ${
                    this.props.area.type === "Food" ? "type-active" : ""
                  }`}
                >
                  <FormattedMessage id="food" defaultMessage="Food" />
                </span>
              </li>
              <li className="menu-item">
                <span
                  onClick={() => this.detectType("Product")}
                  className={`nav-link ${
                    this.props.area.type === "Product" ? "type-active" : ""
                  }`}
                >
                  <FormattedMessage id="products" defaultMessage="Products" />
                </span>
              </li>
              <li className="menu-item">
                <span
                  onClick={() => this.detectType("Service")}
                  className={`nav-link ${
                    this.props.area.type === "Service" ? "type-active" : ""
                  }`}
                >
                  <FormattedMessage id="services" defaultMessage="Services" />
                </span>
              </li>
              <li className="menu-item">
                <Select
                  value={area.state}
                  options={this.state.states}
                  onChange={(e, { value }) => this.filterArea(value)}
                  className="mr-2"
                />
              </li>
              <li className="menu-item pr-4 nav-search-content">
                <N_SearchBox
                  class="search-box search-box-desktop"
                  id="search-dropdown"
                  area={this.props.area}
                  showSearch
                  toggleSearch={this.toggleSearch}
                  searchQuick={this.props.searchQuick}
                />
              </li>
            </ul>
          </Pane>
          {/* <SearchBox toggleSearch={this.toggleSearch} class="search-box search-box-desktop" id="search-dropdown" /> */}
          <Pane className="menu-desktop">
            <ul
              className={`navbar-menu ${isAuthenticated &&
                "navbar-authenticated"}`}
            >
              {/* <li className="menu-item">
                <span className="nav-link">
                  <FormattedMessage id="food_order" />
                  <img
                    src={`${process.env.PUBLIC_URL
                      }/assets/images/icons/arrow-language.svg`}
                    alt="store"
                    className="icon-down"
                  />
                  <ul className="dropdown-navbar">
                    {foodOrders.map((item) => (
                      <li className="menu-item" key={item.id}>
                        <span
                          className="nav-link"
                          onClick={() =>
                            this.onRedirect(`/food-order/${item.id}`)
                          }
                        >
                          {item.name}
                        </span>
                        <ul className="dropdown-navbar-children">
                          {item.children &&
                            item.children.map((itemChildren) => (
                              <li
                                className="menu-item"
                                key={item.id + itemChildren.id}
                              >
                                <NavLink
                                  className="nav-link"
                                  activeClassName="active"
                                  to={`/food-order/${item.id}/${itemChildren.id}`}
                                >
                                  {itemChildren.name}
                                </NavLink>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </span>
              </li> */}
              <li className="menu-item">
                <Pane
                  className="nav-link"
                  onClick={() => this.props.history.push("/screen-advertising")}
                >
                  <span>C-net</span>
                </Pane>
              </li>
              <li className="menu-item">
                <span className="nav-link">
                  <FormattedMessage
                    id="cardbey"
                    defaultMessage="Cardbey Shop"
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/arrow-language.svg`}
                    alt="store"
                    className="icon-down"
                  />
                  <ul className="dropdown-navbar">
                    <li className="menu-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/search?type=Service"
                      >
                        <FormattedMessage id="home.book_your_service" />
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/search?type=Product"
                      >
                        <FormattedMessage id="nav.shop_now" />
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/landing"
                      >
                        Landing Page
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/videos"
                      >
                        <FormattedMessage id="shows" defaultMessage="Shows" />
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={"/delivery-services"}
                      >
                        <FormattedMessage
                          id="instant_delivery"
                          defaultMessage="instant delivery"
                        />
                      </NavLink>
                    </li>
                  </ul>
                </span>
              </li>
              {/* <li className="menu-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to={"/delivery-services"}
                >
                  <FormattedMessage
                    id="instant_delivery"
                    defaultMessage="instant delivery"
                  />
                </NavLink>
              </li> */}
              {/* {!isAuthenticated && (
                <li className="menu-item pr-3">
                  <GuestUserContact isMobile={false} />
                </li>
              )} */}
              <li className="menu-item pr-4 block-search-mobile">
                <Pane onClick={this.toggleSearch}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/layouts/search-white.svg`}
                    className="icon-search"
                    alt="search logo"
                  />
                </Pane>
              </li>
              {isAuthenticated && (
                <li className="menu-item menu-last bell-item">
                  <Pane className="build-cart notifications">
                    <i
                      className="icon-bell noti-order"
                      onClick={this.onSetVisible}
                    ></i>
                    {notis &&
                      notis.length > 0 &&
                      notis.filter((item) => !item.isRead).length > 0 && (
                        <span className="cart-total noti-new">
                          {notis.filter((item) => !item.isRead).length}
                        </span>
                      )}
                    <ul
                      className={`noti-list ${visible ? "d-block" : "d-none"}`}
                    >
                      {notis && notis.length > 0 ? (
                        notis.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() =>
                                this.goToLink(
                                  item.objectType,
                                  item.objectId,
                                  item.status
                                )
                              }
                              className={`noti-list__item ${
                                !item.isRead ? "unread" : ""
                              }`}
                            >
                              <p className="noti-list__item-content">
                                {!item.isRead && <span className="dot"></span>}
                                {item.value}
                              </p>
                              <span className="noti-list__item-date">
                                {item.createdAt}
                              </span>
                            </li>
                          );
                        })
                      ) : (
                        <Pane className="text-center">
                          <FormattedMessage
                            id="notification.you_have_not_had_any_notifications_yet"
                            defaultMessage={`You haven't had any notifications yet`}
                          />
                        </Pane>
                      )}
                    </ul>
                  </Pane>
                </li>
              )}
              <li className="menu-item pr-3">
                <Pane
                  className="build-cart"
                  onClick={() => this.props.history.push("/cart")}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/layouts/cart.svg`}
                    className="icon-cart"
                    alt="caterwin logo"
                  />
                  <span className="cart-total">
                    <span className="number">
                      {cart && cart.items && cart.items.length > 0
                        ? cart.items.length
                        : 0}
                    </span>
                  </span>
                </Pane>
              </li>
              {!isAuthenticated && (
                <li className="menu-item">
                  <span className="nav-link">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/layouts/icon-user2.png`}
                      className="icon-search"
                      alt="user logo"
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/arrow-language.svg`}
                      alt="store"
                      className="icon-down"
                    />
                    <ul className="dropdown-navbar">
                      <li className="menu-item">
                        <NavLink
                          className="nav-link"
                          activeClassName="active"
                          to="/signin"
                        >
                          <FormattedMessage id="sign_in" />
                        </NavLink>
                      </li>
                      <li className="menu-item">
                        <NavLink
                          className="nav-link"
                          activeClassName="active"
                          to="/signup"
                        >
                          <FormattedMessage id="sign_up" />
                        </NavLink>
                      </li>
                    </ul>
                  </span>
                </li>
              )}
              <li className="menu-item menu-last">
                <Language for="language-dropdown" />
              </li>
              {/* {!isAuthenticated && (
                <li className="menu-item">
                  <Pane className="build-shop build-shop_pc">
                    <button
                      className="btn-build"
                      onClick={() => this.props.history.push("/learn-more")}
                    >
                      <FormattedMessage id="created_a_free_shop" />
                    </button>
                  </Pane>
                </li>
              )} */}
              {isAuthenticated && (
                <li className="menu-item">
                  <div className="profile">
                    <span className="profile-name">{user.displayName}</span>{" "}
                    &nbsp;&nbsp;
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/layouts/arrow-2.svg`}
                      alt="arrow"
                      className="icon-down"
                    />
                    <Pane className="dropdown-profiles">
                      <Link to={`/u/${name}/`} className="item">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/home-white.svg`}
                          alt="booking"
                          className="icon"
                        />
                        <span className="norm">
                          {user.business.is_active === 0 ? (
                            <FormattedMessage
                              id="my_sites"
                              defaultMessage="My Sites"
                            />
                          ) : (
                            <FormattedMessage
                              id="my_stores"
                              defaultMessage="My Stores"
                            />
                          )}
                        </span>
                      </Link>
                      <Link to={`/u/${name}/wallet`} className="item">
                        <i className="icon-wallet text-white pr-3"></i>
                        <span className="norm">
                          <FormattedMessage
                            id="wallet"
                            defaultMessage="Wallet"
                          />
                        </span>
                      </Link>
                      <Link to={`/u/${name}/orders`} className="item">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/order-white.svg`}
                          alt="booking"
                          className="icon"
                        />
                        <span className="norm">
                          <FormattedMessage
                            id="my_order"
                            defaultMessage="My order"
                          />
                        </span>
                      </Link>
                      <Link to={`/u/${name}/settings`} className="item">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/setting-white.svg`}
                          alt="booking"
                          className="icon"
                        />
                        <span className="norm">
                          <FormattedMessage
                            id="profiles_setting"
                            defaultMessage="Profiles Setting"
                          />
                        </span>
                      </Link>
                      <span className="item" onClick={() => this.logout()}>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/logout-white.svg`}
                          alt="booking"
                          className="icon"
                        />
                        <span className="norm">
                          <FormattedMessage
                            id="logout"
                            defaultMessage="Sign out"
                          />
                        </span>
                      </span>
                    </Pane>
                  </div>
                </li>
              )}
            </ul>
          </Pane>
          <Pane className="menu-responsive">
            <li className="menu-item">
              {/* <input
                className="checkbox-search"
                type="checkbox"
                id="search-menu"
              /> */}
              <Pane
                onClick={this.toggleSearch}
                className="menu-icon icon-search mb-0"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/layouts/search-white.svg`}
                  className="icon-search"
                  alt="search logo"
                />
              </Pane>
              {/* <SearchBox class="search-box" id="search-dropdown-responsive" /> */}
            </li>
            {/* {!isAuthenticated && (
              <li className="menu-item">
                <GuestUserContact isMobile={true} />
              </li>
            )} */}
            {isAuthenticated && (
              <li className="menu-item">
                <Pane
                  style={{ color: "#fff", fontSize: "22px", width: "15px" }}
                  className="build-cart notifications"
                >
                  <i
                    className="icon-bell noti-order"
                    onClick={this.onSetVisible}
                  ></i>
                  {notis &&
                    notis.length > 0 &&
                    notis.filter((item) => !item.isRead).length > 0 && (
                      <span className="cart-total noti-new">
                        {notis.filter((item) => !item.isRead).length}
                      </span>
                    )}
                  <ul className={`noti-list ${visible ? "d-block" : "d-none"}`}>
                    {notis && notis.length > 0 ? (
                      notis.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this.goToLink(
                                item.objectType,
                                item.objectId,
                                item.status
                              )
                            }
                            className={`noti-list__item ${
                              !item.isRead ? "unread" : ""
                            }`}
                          >
                            <p className="noti-list__item-content">
                              {!item.isRead && <span className="dot"></span>}
                              {item.value}
                            </p>
                            <span className="noti-list__item-date">
                              {item.createdAt}
                            </span>
                          </li>
                        );
                      })
                    ) : (
                      <Pane className="text-center">
                        <FormattedMessage
                          id="notification.you_have_not_had_any_notifications_yet"
                          defaultMessage={`You haven't had any notifications yet`}
                        />
                      </Pane>
                    )}
                  </ul>
                </Pane>
              </li>
            )}
            <li className="menu-item">
              <Pane
                className="build-cart"
                onClick={() => this.props.history.push("/cart")}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/layouts/cart.svg`}
                  className="icon-cart"
                  alt="caterwin logo"
                />
                <span className="cart-total">
                  {cart && cart.items && cart.items.length > 0
                    ? cart.items.length
                    : 0}
                </span>
              </Pane>
            </li>
            <li className="menu-item">
              <input
                className="menu-btn"
                type="checkbox"
                id="menu-btn"
                onChange={this.onChangeMenu}
              />
              <label className="menu-icon mb-0" htmlFor="menu-btn">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/arrow-menu.svg`}
                  className="icon"
                  alt="caterwin logo"
                />
              </label>
              <ul className="nav-responsive">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/screen-advertising"
                  >
                    C net
                  </NavLink>
                </li>
                {!isAuthenticated && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/videos"
                      >
                        <FormattedMessage id="shows" defaultMessage="Shows" />
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/services"
                      >
                        <FormattedMessage id="home.book_your_service" />
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/products"
                      >
                        <FormattedMessage id="nav.shop_now" />
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/food-order"
                      >
                        <FormattedMessage id="food_order" />
                      </NavLink>
                    </li> */}
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/landing"
                      >
                        Landing Page
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/signin"
                      >
                        <FormattedMessage id="sign_in" />
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/signup"
                      >
                        <FormattedMessage id="sign_up" />
                      </NavLink>
                    </li>
                  </>
                )}
                {isAuthenticated && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/u/${name}/overview`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/home-white.svg`}
                          alt="booking"
                          className="icon"
                        />
                        <span className="norm">
                          {user.business.is_active === 0 ? (
                            <FormattedMessage
                              id="my_sites"
                              defaultMessage="My Sites"
                            />
                          ) : (
                            <FormattedMessage
                              id="my_stores"
                              defaultMessage="My Stores"
                            />
                          )}
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/u/${name}/wallet`}
                      >
                        <i className="icon-wallet text-white pr-3"></i>
                        <span className="norm">
                          <FormattedMessage
                            id="wallet"
                            defaultMessage="Wallet"
                          />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/u/${name}/orders`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/order-white.svg`}
                          alt="booking"
                          className="icon"
                        />
                        <span className="norm">
                          <FormattedMessage
                            id="my_order"
                            defaultMessage="My order"
                          />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/u/${name}/settings`}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/setting-white.svg`}
                          alt="booking"
                          className="icon"
                        />
                        <span className="norm">
                          <FormattedMessage
                            id="profiles_setting"
                            defaultMessage="Profiles Setting"
                          />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item" onClick={() => this.logout()}>
                      <span className="nav-link">
                        <FormattedMessage
                          id="logout"
                          defaultMessage="Sign out"
                        />
                      </span>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <Language for="language-dropdown-responsive" />
                </li>
                <li className="nav-item">
                  <Pane className="build-shop">
                    <button
                      className="btn-build"
                      onClick={() => this.props.history.push("/learn-more")}
                    >
                      <FormattedMessage id="created_a_free_shop" />
                    </button>
                  </Pane>
                </li>
              </ul>
            </li>
          </Pane>
          <N_SearchBox
            class="search-box search-box-desktop"
            id="search-dropdown"
            area={this.props.area}
            showSearch={this.state.showSearch}
            toggleSearch={this.toggleSearch}
            searchQuick={this.props.searchQuick}
          />
        </header>
        <header id="header-sp" className="header-sp">
          <ul
            className={`navbar-menu ${isAuthenticated &&
              "navbar-authenticated"}`}
          >
            <li className="menu-item">
              <Select
                value={area.state}
                options={this.state.states}
                onChange={(e, { value }) => this.filterArea(value)}
              />
            </li>
            <li className="menu-item">
              <span
                onClick={() => this.detectType("Food")}
                className={`nav-link ${
                  this.props.area.type === "Food" ? "type-active" : ""
                }`}
              >
                <FormattedMessage id="food" defaultMessage="Food" />
              </span>
            </li>
            <li className="menu-item">
              <span
                onClick={() => this.detectType("Product")}
                className={`nav-link ${
                  this.props.area.type === "Product" ? "type-active" : ""
                }`}
              >
                <FormattedMessage id="products" defaultMessage="Products" />
              </span>
            </li>
            <li className="menu-item">
              <span
                onClick={() => this.detectType("Service")}
                className={`nav-link ${
                  this.props.area.type === "Service" ? "type-active" : ""
                }`}
              >
                <FormattedMessage id="services" defaultMessage="Services" />
              </span>
            </li>
          </ul>
        </header>
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
  notis: state.notis.results,
  area: state.area,
});

const bindDispatchToProps = (dispatch) => ({
  saveUserDevice: bindActionCreators(saveUserDevice, dispatch),
  getShoppingCart: bindActionCreators(getShoppingCart, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  getAllProducts: bindActionCreators(getAllProducts, dispatch),
  logout: bindActionCreators(logout, dispatch),
  addCoordinate: bindActionCreators(addCoordinate, dispatch),
  addArea: bindActionCreators(addArea, dispatch),
  addType: bindActionCreators(addType, dispatch),
  getAreas: bindActionCreators(getAreas, dispatch),
  getNotisByUser: bindActionCreators(getNotisByUser, dispatch),
  getAreaByBusiness: bindActionCreators(getAreaByBusiness, dispatch),
  searchQuick: bindActionCreators(searchQuick, dispatch),
});

export default withRouter(
  connect(bindStateToProps, bindDispatchToProps)(Header)
);
