import Config from 'constants/Config'
import { formatRoute, formatJson } from 'shared/helpers'

const exec = params => {
  const page = 1
  const size = 20
  const category = ''
  const store = ''
  const sort = ''
  const discount = `>0`
  const country = params && params.country || 'aus'

  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/services?category_id=${category}&user_id=${store}&sort=${sort}&promotion.id=${discount}&country=${country}&page[size]=${size}&page[number]=${page}`
  }
}

const parseResponse = response => {

  const services = []

  if (response.data) {
    response.data.forEach(item => {
      const service = formatJson(response, item.type, item.id)

      let promotion = {}

      if (service.promotion) {
        promotion = {
          id: service.promotion.id,
          percent: Number(service.promotion.percent),
          startDate: service.promotion.startDate,
          endDate: service.promotion.endDate,
          primaryImage: service.promotion.primaryImage && service.promotion.primaryImage.url,
        }
      }

      services.push({
        id: service.id,
        name: service.name,
        description: service.description,
        slug: `${formatRoute(service.name, '-')}-${service.id}`,
        primaryImage: service.primaryImage && service.primaryImage.url,
        rating: service.statistic && service.statistic.rating ? Number(service.statistic.rating) : 0,
        userId: service.user && service.user.id,
        originalPrice: Number(service.price),
        promotionPrice: Number(service.promotionPrice),
        type: 'services',
        inventoryStatus: service.inventoryStatus,
        promotion
      })
    })
  }

  return services
}

export { exec, parseResponse }
