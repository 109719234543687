import * as types from 'constants/ActionTypes';
import api from 'api';

// Products
export const getScreensByUserId = (params) => (dispatch) => dispatch({
  types: [types.RECEIVE_MY_SCREENS, types.RECEIVE_MY_SCREENS_SUCCESS, types.RECEIVE_MY_SCREENS_FAILURE],
  payload: {
    request: api.user.screens(params)
  }
});



export const getScreenCount = (params) => (dispatch) => dispatch({
  types: [types.COUNT_MY_SCREEN, types.COUNT_MY_SCREEN_SUCCESS],
  payload: {
    request: api.user.screenCount(params)
  }
});

// Edit screen detail
export const deleteMyScreen = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_SCREEN,
  payload: {
    request: api.user.screenRemove(params)
  }
});

// Create a digital
export const createMyScreen = (params) => (dispatch) => dispatch({
  type: types.CREATE_MY_SCREEN,
  payload: {
    request: api.user.screenAdd(params)
  }
});

// Get screen detail
export const getMyScreenDetail = (params) => (dispatch) => dispatch({
  type: types.MY_SCREEN_DETAIL,
  payload: {
    request: api.user.screenRead(params)
  }
});

// Edit digital detail
export const editMyScreen = (params) => (dispatch) => dispatch({
  type: types.EDIT_MY_SCREEN,
  payload: {
    request: api.user.screenEdit(params)
  }
});
// UPDATE digital SCREEN ONLINE TIME
export const updateScreenOnline = (params) => (dispatch) => dispatch({
  type: types.UPDATE_MY_SCREEN_ONLINE,
  payload: {
    request: api.user.screenUpdateOnline(params)
  }
});

// Screen Share
export const getDigitalShare = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_SCREEN_SHARE,
  payload: {
    request: api.user.DigitalShare(params)
  }
});