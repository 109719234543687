import PropTypes from "prop-types";
import _, { isEmpty, get } from "lodash";
import { connect } from "react-redux";
import { ProductList } from "../list";
import queryString from "query-string";
import React, { Component } from "react";
import update from "immutability-helper";
import { bindActionCreators } from "redux";
import { addItemToCart } from "actions/cart";
import { FormattedMessage } from "react-intl";
import { searchStores } from "actions/search";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import { getProductsByUserId } from "actions/myProducts";
import { ScrollMenu } from "components/common/scrollMenu";
import { LoadingSearch } from "components/common/loading";
import Newsletter from "../newsletter/Newsletter";
import { QRCode } from "shared/components";
import Modal from "react-responsive-modal";
import ReactTooltip from "react-tooltip";
import { addScanCounts } from "actions/scanCounts";
import { removeAllCart } from "actions/cart";
import { getUserInfo } from "actions/auth";
import NotificationSystem from "react-notification-system";
import { addCustomer } from "actions/customer";
import PhoneInput from 'react-phone-input-2';
class StoreMenu extends Component {

  static propTypes = {
    auth: PropTypes.object,
    match: PropTypes.object,
    searchStores: PropTypes.func,
    getProductsByUserId: PropTypes.func,
    addScanCounts: PropTypes.func,
    getUserInfo: PropTypes.func,
    removeAllCart: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.state = {
      products: {},
      storeId: "",
      data: [],
      page: queryString.parse().page || 1,
      fetchingProducts: false,
      fetched: false,
      totalPages: 0,
      modeView: "gridView",
      height: 0,
      isAction: false,
      open: false,
      errors: {},
      customer:{
        fullname:'',
        phone:'',
        email:'',
      },
      fromScan: queryString.parse(this.props.location.search).fromScan? true : false,
      userForm: false,
      messageNoti: 'You have received a discount code, please check your email or SMS!',
      showSMSmessage:false,
    };
  }

  onOpenModal = () => {
    this.setState({ open: true }, () => {
      let tawkBtn = document.querySelector('[title="chat widget"]');
      if (tawkBtn) {
        tawkBtn.style.display = "none";
      }
    });
  };

  onCloseModal = () => {
    this.setState({ open: false }, () => {
      let tawkBtn = document.querySelector('[title="chat widget"]');
      if (tawkBtn) {
        tawkBtn.style.display = "block";
      }
    });
  };

  getStoreProducts = async () => {
    const {
      params: { storeId },
    } = this.props.match;

    this.setState({
      fetchingProducts: true,
      fetched: false,
    });

    this.props
      .getProductsByUserId({
        id: storeId,
        size: 1000,
        images: "",
        type: "Menu"
      })
      .then(() => {
        if (this.props.storeInfo.business && this.props.storeInfo.business.type !== 'Food') {
          this.props.history.replace(`/page-not-found`);
        } else {
          this.onDetectScan();
          this.onSetDefaultView(this.props.storeInfo);
          this.setState({
            fetchingProducts: false,
            fetched: true,
          }, () => {
            this.lazy();
          });
        }
      });
  };

  componentWillMount() {
    this.setState({
      height: window.innerHeight,
    });
    window.addEventListener("scroll", this.handleScroll);

    const {  storeInfo } = this.props;
    if(this.state.fromScan &&  storeInfo.business.firstScanFlg == 1){
      this.setState({userForm:true});
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getStoreProducts();
    });

    this.getStoreProducts();

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }

    this._notificationSystem = this.refs.notificationSystem;
  }

  lazy = () => {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    if ("IntersectionObserver" in window) {
      let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy");
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });
      lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
    }
  }

  groupByCategories = (data) => {
    return _.chain(data)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .value();
  };

  handleScroll = () => {
    const { products } = this.props;
    const { isAction } = this.state;
    this.setState(
      {
        height: window.innerHeight,
      },
      () => {
        const banner = document.getElementById("banner-container");
        const info = document.getElementById("info-container");
        const menu = document.getElementById("anchor");
        const stickyBanner = banner && banner.offsetHeight;
        const stickyInfo = info && info.offsetHeight;
        const items = this.groupByCategories(products.results);
        const itemElements = items.map((item) => {
          const itemElement = document.getElementById(item.name);
          return {
            ...item,
            scroll:
              itemElement &&
              itemElement.offsetTop +
              itemElement.offsetHeight +
              stickyBanner +
              stickyInfo -
              250,
          };
        });
        let sum = stickyBanner + stickyInfo;
        if (window.pageYOffset > sum) {
          const scrollElement = itemElements.filter(
            (item) => item.scroll >= window.scrollY
          );
          if (!isEmpty(scrollElement) && !isAction) {
            this.setState({
              selected: get(scrollElement[0], "name"),
            });
          }
          menu && menu.classList.add("menu-breadcrum-sticky");
        } else {
          const scrollElement = itemElements.filter(
            (item) => item.scroll >= window.scrollY
          );
          if (!isEmpty(scrollElement) && !isAction) {
            this.setState({
              selected: get(scrollElement[0], "name"),
            });
          }
          menu && menu.classList.remove("menu-breadcrum-sticky");
        }
      }
    );
  };

  onSetDefaultView = (storeInfo) => {
    if (!isEmpty(storeInfo)) {
      this.setState({
        modeView: storeInfo.business.isGridView === 1 ? "gridView" : "listView",
      });
    }
  };

  onSelect = (key) => {
    const self = this;
    this.setState(
      {
        keySort: key,
        selected: key,
        isAction: true,
      },
      () => {
        const elementScroll = document.getElementById(key);
        if (elementScroll) {
          window.scroll({
            top: elementScroll.offsetTop + 330,
            behavior: "smooth",
          });
          setTimeout(
            () =>
              self.setState({
                isAction: false,
              }),
            1000
          );
        }
      }
    );
  };

  sortByName = (data, selected) => {
    if (selected) {
      const index = data.findIndex((item) => item.name === selected);
      const dataNew = update(data, {
        $splice: [
          [index, 1],
          [0, 0, data[index]],
        ],
      });
      return dataNew;
    }
    return data;
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === "gridView" ? "listView" : "gridView",
      keySort: null,
    }));
  };

  onDetectScan = () => {
    const {
      location: { search },
    } = this.props;
    const isFromScan = queryString.parse(search).fromScan;
    if (isFromScan && (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      this.props.addScanCounts({ user_id: this.props.storeInfo.id }).then(() => {
        this.props.history.replace(this.props.location.pathname);
      });
    }
  };
  onCloseUserForm = () =>{
    this.setState({ userForm: false });
  }
  onFormInputChange = (e) => {
    this.setState(
      {customer: {
        ...this.state.customer,
        [e.target.name]: e.target.value
       }});
  };

  onChangePhone = (phone) => {
    this.setState((prevState) => ({
      customer: {
        ...prevState.customer,
        phone,
      },
    }));
  };

  validateForm = () => {
    const { fullname, email, phone } = this.state.customer;
    let errors = {};
    if (!fullname.trim()) {
      errors.fullname = 'Name is required';
    }
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is not valid';
    }
    if (!phone.trim()) {
      errors.phone = 'Phone is required';
    }

    this.setState({ errors });

    // Return true if there are no errors, false otherwise
    return Object.keys(errors).length === 0;
  };
  onSubmitForm = () => {
    const {  storeInfo } = this.props;
    const isValid = this.validateForm();
    if(isValid){
      this.props.addCustomer({
        ...this.state.customer,
        business_id:  storeInfo.business.id,
        first_scan_promotion_id: storeInfo.business.firstScanPromotionId,
      }).then((response) => {
        if (response && response.status == 201) {
          this.setState({showSMSmessage:true});
        }
        if (response && response.status == 200) {
          this.setState({showSMSmessage:true});
          this.setState({messageNoti: `This is your ${storeInfo.business.firstScanDiscount}%.discount promotion code. Thank you`});
        }
      }).catch((err) => {
        if( err.response.data && err.response.data.errors){
          let errors = {};
          errors[err.response.data.errors[0].source.pointer] = err.response.data.errors[0].detail;
          this.setState({ errors });
        }
      });
    }
  }
  render() {
    const { products, auth, storeInfo, cart } = this.props;
    const { fetched, fetchingProducts, selected, errors, customer } = this.state;
    const items = _.chain(products.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .value();
    return (
      <>
        <Pane className="tab-heading tab-heading-stores menu-store" id="anchor">
          <h3 className="heading-title mr-2 mt-0 d-flex justify-content-between">
              <Pane className="heading-title__menu">
                <Pane className="block-qr-code__menu" onClick={this.onOpenModal} data-tip data-for={`tooltip-qr-menu`}>
                  <span className="ic-common-qr-code" />
                </Pane>
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="float"
                  id={"tooltip-qr-menu"}
                >
                  Click to get digital menu
                </ReactTooltip>
                <Pane className="qr-code__menu">
                  <FormattedMessage id="our_menu" defaultMessage="Our Menu" />
                  <Pane className="menu-popover__content">Digital menu</Pane>
                </Pane>
              </Pane>
              {this.props.methodModal.isShowTable &&(
                <Pane className="method-block d-lg-none">
                  <span className="sub-header__type">
                    {this.props.methodModal.methodType}
                    <span className="label-table">{this.props.methodModal.tableName}</span>
                  </span>
                  <span className="btn-change" onClick={this.props.methodModal.onOpeModalMethod}>Change</span>
                </Pane>
              )}
            <Modal
              className="popup-qrcode"
              classNames={{
                closeIcon: 'customIconBtn'
              }}
              open={this.state.open}
              onClose={this.onCloseModal}
              center
              showCloseIcon={true}
            >
              <QRCode
                onClose={this.onCloseModal}
                qrcode={storeInfo.qrcodeMenu}
                storeInfo={storeInfo}
                isMenu={true}
              />
            </Modal>
          </h3>
          {!_.isEmpty(items) && (
            <Pane className="category-list menu-breadcrum" id="menu-breadcrum">
              <ScrollMenu
                items={items}
                onSelect={this.onSelect}
                selected={selected}
              />
            </Pane>
          )}
          <Pane className="view d-flex justify-content-end mt-0">
            <span className="list-view" onClick={this.onChangeModeView}>
              <img
                src={
                  process.env.PUBLIC_URL + this.state.modeView === "gridView"
                    ? "/assets/images/icons/list-view.svg"
                    : "/assets/images/icons/grid-view.svg"
                }
                className="image-logo anchor-image-logo"
                alt="list-view"
              />
            </span>
          </Pane>
        </Pane>
        {!fetchingProducts && fetched ? (
          <>
            {isEmpty(items) && <Empty type="white" />}
            {!isEmpty(items) &&
              items.map((category) => (
                <Pane key={category.name} id={category.name}>
                  <Pane className="category-name">
                    <strong>{category.name}</strong>
                  </Pane>
                  <ProductList
                    cart={cart}
                    auth={auth}
                    products={category.items}
                    onAddItemToCart={this.props.addItemToCart}
                    onRemoveAllCart={this.props.removeAllCart}
                    modeView={this.state.modeView}
                    cartModal={this.props.cartModal}
                    methodModal={this.props.methodModal}
                    onNotification={this._notificationSystem}
                  />
                  <Pane className="clearfix" />
                </Pane>
              ))}
          </>
        ) : (
          <LoadingSearch type="white" />
        )}

        <NotificationSystem ref="notificationSystem" />
        <Modal
            classNames={{
              closeIcon: 'customIconBtn',
              modal: 'popup-promotion-scan',
            }}
            open={this.state.userForm}
            onClose={this.onCloseUserForm}
            center
            showCloseIcon={true}
          >
            <Pane className="card-body popup-body-content">
              <Pane className="block-image">
                <img className="offer-image" src={`${process.env.PUBLIC_URL}/assets/images/popup/offer.png`} alt="offer" />
                {!this.state.showSMSmessage && (
                  <h2 className="modal-title">Congrats! You’re eligible for our special <b><em>{storeInfo.business.firstScanDiscount || 0}%</em></b> discount, please enter your info to get the promotion code</h2>
                )}
              </Pane>
              <Pane className="row">
                {this.state.showSMSmessage &&(
                  <Pane className="col-md-12 form-group">
                  <h3>{this.state.messageNoti}</h3>
                  </Pane>
                )}
                {!this.state.showSMSmessage && (
                  <>
                    <Pane className="col-md-12 form-group mb-0">
                      <label htmlFor="name" className="col-form-label">Name:</label>
                      <input className="form-control" type="text" name="fullname" onChange={this.onFormInputChange} />
                      {errors.fullname && <span className="error">{errors.fullname}</span>}
                    </Pane>
                    <Pane className="col-md-12 form-group mb-0">
                      <label htmlFor="email" className="col-form-label">Email:</label>
                      <input className="form-control" type="text" name="email" onChange={this.onFormInputChange} />
                      {errors.email && <span className="error">{errors.email}</span>}
                    </Pane>
                    <Pane className="col-md-12 form-group">
                      <label htmlFor="name" className="col-form-label">Phone:</label>
                      <PhoneInput
                        country={'au'}
                        value={customer.phone}
                        onChange={(phone) => this.onChangePhone(phone)}
                        style={{ width: '100%' }}
                      />
                      {errors.phone && <span className="error">{errors.phone}</span>}
                    </Pane>
                    <Pane className="col-md-12 form-group text-center">
                      <button type="button" className="btn btn-claim" onClick={this.onSubmitForm}>SEND</button>
                    </Pane>
                  </>
                )}
              </Pane>
            </Pane>
        </Modal>
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  stores: state.stores,
  products: state.myProducts,
  auth: state.auth,
  cart: state.cart,
});

const bindDispatchToProps = (dispatch) => ({
  searchStores: bindActionCreators(searchStores, dispatch),
  addItemToCart: bindActionCreators(addItemToCart, dispatch),
  getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
  addScanCounts: bindActionCreators(addScanCounts, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  removeAllCart: bindActionCreators(removeAllCart, dispatch),
  addCustomer: bindActionCreators(addCustomer, dispatch),
});

export default connect(bindStateToProps, bindDispatchToProps)(StoreMenu);
