import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import ReviewsCollection from "./ReviewsCollection";
import LikeShareCollection from "./LikeShareCollection";
import PropTypes from "prop-types";
import moment from "moment";
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import { LoginModal } from "shared/components";
import Config from "constants/Config";
class MainCollection extends Component {
  static propTypes = {
    onGetReviewsVideo: PropTypes.func,
    onNotification: PropTypes.object,
    onReviewsVideo: PropTypes.func,
    onGetLikesVideo: PropTypes.func,
    onGetUserLikesVideo: PropTypes.func,
    onLikesVideo: PropTypes.func,
    onViewVideo: PropTypes.func,
    onAddLikeVideo: PropTypes.func,
    onGetCollectionsByUserId: PropTypes.func,
    intl: intlShape.isRequired,
    signin: PropTypes.func.isRequired,
  };

  state = {
    openModal: false,
    extVideo : Config.extVideo
  }

  onPlay = (second) => {
    this.props.onViewVideo({
      id: this.props.data[0].id,
      model: 'Collection',
    });
  };

  onOpenModal = () => {
		this.setState({
			openModal: true,
		});
	};

  onCloseModal = () => {
		this.setState({
			openModal: false,
		});
	};

  render() {
    const {
      auth,
      onNotification,
      signin,
      signinSocial,
    } = this.props;

    const {
      extVideo
    } = this.state;

    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];

    const firstItem = this.props.data.length > 0 && this.props.data[0]

    let ext = firstItem.primaryImage != null ? firstItem.primaryImage.substr(firstItem.primaryImage.lastIndexOf('.') + 1) : '';

    const representVideo = (
      <>
        {firstItem ? (
          <Pane className="list-slider">
            <LoginModal 
              onNotification={onNotification}
              signin={signin}
              signinSocial={signinSocial}
              auth={auth}
              onCloseModal={this.onCloseModal}
              openModal={this.state.openModal}
            />
            <Pane className="slider-content-main">
              {extVideo.includes(ext) && (
                <video
                  ref="videoPlayer"
                  controls
                  className="banner-image-over"
                  onPlay={this.onPlay}
                >
                  <source src={firstItem.primaryImage} />
                  Your browser does not support HTML5 video.
                </video>
              )} {extImage.includes(ext) && (
                <Pane
                  className="adds-images"
                  style={{
                    background: `url(${firstItem.primaryImage}) no-repeat center center`,
                    backgroundSize: 'contain',
                    height: "400px",
                  }}
                ></Pane>
              )} {!ext && !extImage.includes(ext) && !extVideo.includes(ext) && (
                <Pane
                  dangerouslySetInnerHTML={{
                    __html: firstItem.description,
                  }}
                />
              )}
            </Pane>
            <div className="product-heading">
              <h3
                className="block-title text-uppercase"
                style={{ marginRight: "auto", fontWeight: "bold" }}
              >
                {firstItem.name}
              </h3>
            </div>
            <Pane className="info-main d-flex justify-content-between">
              <Pane style={{fontSize: '14px'}}>
                { firstItem.typeCollect === 'Video' && (
                  <span><b>{firstItem.views} views&nbsp;&nbsp;•&nbsp;</b></span>
                )}
                <em>{moment().diff(moment(firstItem.created_at), 'hours') > 24 ? moment(firstItem.created_at).format(" MMMM D YYYY") : moment(firstItem.created_at).fromNow()}</em>
              </Pane>
              <LikeShareCollection
                id={firstItem.id}
                shareUrl={this.props.shareUrl}
                onGetLikesVideo={this.props.onGetLikesVideo}
                onGetUserLikesVideo={this.props.onGetUserLikesVideo}
                onGetCollectionsByUserId={this.props.onGetCollectionsByUserId}
                onLikesVideo={this.props.onLikesVideo}
                onNotification={this.props.onNotification}
                idUser={this.props.auth.user.id}
                userLike={this.props.userLike}
                like={this.props.like}
                item={firstItem}
                isFront={this.props.isFront}
                onOpenModal={this.onOpenModal}
              />
            </Pane>
            <ReviewsCollection
              onGetReviewsVideo={this.props.onGetReviewsVideo}
              onNotification={this.props.onNotification}
              idUser={this.props.auth.user.id}
              reviews={this.props.reviews}
              id={firstItem.id}
              onReviewsVideo={this.props.onReviewsVideo}
              isFront={this.props.isFront}
              onOpenModal={this.onOpenModal}
            />
          </Pane>
        ) : (
          <Pane></Pane>
        )}
      </>
    );

    return (
      <Pane
        className="video-container video-category"
        style={{ boxShadow: "none" }}
      >
        <Pane className="videos-slider">
          {representVideo}
        </Pane>
      </Pane>
    );
  }
}

export default injectIntl(MainCollection);
