import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { MasterLayout } from 'components/layout';
import { Pane } from 'shared/components/layouts';
import { getBuyingOrderDetail } from 'actions/orders';
import { pushNotification } from 'actions/notification';
import { OrderSuccess } from 'components/scenes/cart/order-success';

class OrderSuccessPage extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    detail: PropTypes.object,
    pushNotification: PropTypes.func,
    getBuyingOrderDetail: PropTypes.func,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <OrderSuccess
            userInfo={this.props.userInfo}
            detail={this.props.detail}
            onGetBuyingOrderDetail={this.props.getBuyingOrderDetail}
            onPushNotification={this.props.pushNotification}
            orderId={queryString.parse(this.props.location.search).orderId}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  detail: state.buyingOrderDetail.data,
  userInfo: state.auth && state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  pushNotification: bindActionCreators(pushNotification, dispatch),
  getBuyingOrderDetail: bindActionCreators(getBuyingOrderDetail, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(OrderSuccessPage);
