import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  const page = params.page || 1
  const size = params.size || 20

  return {
    method: 'GET',
    url: `/n_sellers/${params.id}/orders?${params.method ? `method=${params.method}&` : ``}${params.user_id ? `user_id=${params.user_id}&` : ``}${params.status ? `status=${params.status}&` : ``}page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const orders = []

  response.data && response.data.forEach(item => {
    const order = formatJson(response, item.type, item.id)

    let paymentMethod = ''

    if (order.payment && order.payment.transactionType) {
      paymentMethod = order.payment.transactionType
    } else {
      paymentMethod = order.paymentMethod !== '' ? order.paymentMethod : 'Credit card'
    }

    let obj = {
      id: order.id,
      orderDate: order.createdAt,
      total: order.total,
      paymentMethod,
      status: order.status,
      guestName: order.guestName,
      guestPhone: order.guestPhone,
      guestEmail: order.guestEmail,
      guestNote: order.guestNote,
      mode: order.mode,
      tableName: order.table ? order.table.name : ''
    }

    obj.customer = {
      name: order.fullname,
      email: order.email
    }

    obj.delivery_method = order.deliveryMethod

    orders.push(obj)
  })

  return orders
}

export { exec, parseResponse }
