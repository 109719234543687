import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Slider from 'react-slick'
import { List } from 'semantic-ui-react'
import { MasterLayout } from 'components/layout'
import { formatRoute } from 'shared/helpers'
import { Pane } from 'shared/components/layouts'

class GuidePage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  }

  render() {
    const name = this.props.auth.user && this.props.auth.user.displayName ? formatRoute(this.props.auth.user.displayName) : ''
    const { isAuthenticated } = this.props.auth
    const settings = {
      autoplay: false,
      autoplaySpeed: 5000,
      arrows: true,
      dots: true,
      fade: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
      <MasterLayout>
        <Pane className='container'>
          <Pane className='row justify-content-center clearfix'>
            <Pane className='col-12 col-md-11'>
              <Pane className='guide-line-container'>
                <Pane className='inner-box'>
                  <h5 className="main-title gray"><FormattedMessage id="welcome_to_cardbey" defaultMessage='Welcome to Cardbey' />, <strong>{this.props.auth.user && this.props.auth.user.displayName}</strong></h5>
                  <Pane className="guide-line-list">
                    <Pane className="row">
                      <Pane className="col-12 col-md-5">
                        <List Paneided relaxed>
                          <List.Item>
                            <List.Content>
                              <List.Header><Link to={isAuthenticated ? `/u/${name}/overview` : `/signin?redirect=overview`}><FormattedMessage id="view_your_store" defaultMessage='View your store' /></Link></List.Header>
                              <List.Description as='a'><FormattedMessage id="you_have_had_a_online_store_click_to_view" defaultMessage='You have had an online store, click to view' /></List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Pane>
                      <Pane className="col-12 col-md-7">
                        <Slider {...settings}>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-01.jpg`} alt="Cardbey" />
                          </Pane>
                        </Slider>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-12 col-md-5">
                        <List Paneided relaxed>
                          <List.Item>
                            <List.Content>
                              <List.Header><Link to={isAuthenticated ? `/u/${name}/settings` : `/signin?redirect=settings`}><FormattedMessage id="update_your_profile" defaultMessage='Update your profile' /></Link></List.Header>
                              <List.Description as='a'><FormattedMessage id="you_can_update_your_profile" defaultMessage='You can update your profile: Name, avatar, phone, address, your business profile ...' /></List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Pane>
                      <Pane className="col-12 col-md-7">
                        <Slider {...settings}>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-02.jpg`} alt="Cardbey" />
                          </Pane>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-02.1.jpg`} alt="Cardbey" />
                          </Pane>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-02.2.jpg`} alt="Cardbey" />
                          </Pane>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-02.3.jpg`} alt="Cardbey" />
                          </Pane>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-02.4.jpg`} alt="Cardbey" />
                          </Pane>
                        </Slider>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-12 col-md-5">
                        <List Paneided relaxed>
                          <List.Item>
                            <List.Content>
                              <List.Header><Link to={isAuthenticated ? `/u/${name}/products/create` : `/signin?redirect=products/create`}><FormattedMessage id="create_your_product_now" defaultMessage='Create your product now' /></Link></List.Header>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Pane>
                      <Pane className="col-12 col-md-7">
                        <Slider {...settings}>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-03.jpg`} alt="Cardbey" />
                          </Pane>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-03.1.jpg`} alt="Cardbey" />
                          </Pane>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-03.2.jpg`} alt="Cardbey" />
                          </Pane>
                        </Slider>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-12 col-md-5">
                        <List Paneided relaxed>
                          <List.Item>
                            <List.Content>
                              <List.Header><Link to={isAuthenticated ? `/u/${name}/services/create` : `/signin?redirect=services/create`}><FormattedMessage id="create_your_serivce_now" defaultMessage='Create your service now' /></Link></List.Header>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Pane>
                      <Pane className="col-12 col-md-7">
                        <Slider {...settings}>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-04.jpg`} alt="Cardbey" />
                          </Pane>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-04.1.jpg`} alt="Cardbey" />
                          </Pane>
                          <Pane className="slide-item">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/guide/image-04.2.jpg`} alt="Cardbey" />
                          </Pane>
                        </Slider>
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    )
  }
}

const bindStateToProps = state => ({
  auth: state.auth
})

export default connect(bindStateToProps)(GuidePage)
