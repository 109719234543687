import * as types from 'constants/ActionTypes'
import UserAdapter from 'services/users/adapter'
import normalize from 'json-api-normalizer'

const initialState = {
  results: [],
  schema: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const myVideos = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_VIDEOS:
      return Object.assign({}, state, {
        ...state,
        fetching: true
      })
    case types.RECEIVE_MY_VIDEOS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.videoBrowse.parseResponse(action.payload.data),
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_VIDEOS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

const videos = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_VIDEOS:
      return Object.assign({}, state, {
        ...state,
        fetching: true
      })
    case types.RECEIVE_VIDEOS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.videoBrowseAll.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_VIDEOS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const videoById = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_VIDEOS:
      return Object.assign({}, state, {
        ...state,
        fetching: true
      })
    case types.RECEIVE_MY_VIDEOS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.videoDataBrowse.parseResponse(action.payload.data),
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_VIDEOS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

export { myVideos, videos, videoById }
