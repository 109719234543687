import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import { withRouter } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';
import Newsletter from '../newsletter/Newsletter';

class Wherever extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
    };
  }

  onConnected = () => {
    this.setState({
      open: true,
    });
  };

  onCancelConnected = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <Pane className="wherever-container pt-5 pb-5">
        <Pane className="container">
          <Pane className="row mb-5">
            <Pane className="col-lg-6 offset-lg-3">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="heading-container text-center pb-4">
                  <h2 className="heading-title">
                    <FormattedMessage
                      id="with_you_wherever_you_going"
                      defaultMessage="With you wherever you’re going"
                    />
                  </h2>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
          <Pane className="row flex-lg-row flex-column align-items-end pt-5 pb-5">
            <Pane className="col-lg-7">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInLeft"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + '/assets/images/landing/sell.png'
                  }
                  alt="sell"
                  className="wherever-img"
                />
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-5">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInRight"
              >
                <Pane className="wherever-content">
                  <h2 className="wherever-title">
                    <FormattedMessage
                      id="sell_deliver_your_products_everywhere"
                      defaultMessage="Sell & deliver your products everywhere"
                    />
                  </h2>
                  <p className="wherever-norm">
                    <FormattedMessage
                      id="use_one_platform_to_sell_to_anyone"
                      defaultMessage="Use one platform to sell to anyone, anywhere. Deliver orders with no hassle and no middleman."
                    />
                  </p>
                  <button className="btn btn-circle btn-circle-primary wherever-button mt-3">
                    <FormattedMessage
                      id="explore_ways_to_sell"
                      defaultMessage="Explore ways to sell"
                    />
                  </button>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
          <Pane className="row flex-lg-row flex-column-reverse align-items-center pt-5 pb-5">
            <Pane className="col-lg-5">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInLeft"
              >
                <Pane className="wherever-content">
                  <h2 className="wherever-title">
                    <FormattedMessage
                      id="get_booked_on_the_go"
                      defaultMessage="Get booked on the go"
                    />
                  </h2>
                  <p className="wherever-norm">
                    <FormattedMessage
                      id="whether_you_an_agency"
                      defaultMessage="Whether you’re an agency, a restaurant, a beauty parlor or
                      other services, start getting more international & local
                      bookings with just one online store."
                    />
                  </p>
                  <button className="btn btn-circle btn-circle-primary wherever-button mt-3">
                    <FormattedMessage
                      id="explore_ways_to_get_bookings"
                      defaultMessage="Explore ways to get bookings"
                    />
                  </button>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-7">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInRight"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + '/assets/images/landing/booked.png'
                  }
                  alt="sell"
                  className="wherever-img"
                />
              </ScrollAnimation>
            </Pane>
          </Pane>
          <Pane className="row flex-lg-row flex-column align-items-center pt-5 pb-5">
            <Pane className="col-lg-7">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInLeft"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + '/assets/images/landing/step.png'
                  }
                  alt="sell"
                  className="wherever-img"
                />
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-5">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInRight"
              >
                <Pane className="wherever-content">
                  <h2 className="wherever-title">
                    <FormattedMessage
                      id="let_get_social"
                      defaultMessage="Let’s get social"
                    />
                  </h2>
                  <p className="wherever-norm">
                    <FormattedMessage
                      id="connect_with_customers_and_businesses_to_make_buying"
                      defaultMessage="Connect with customers and businesses to make buying/selling experience more enjoyable"
                    />
                  </p>
                  <button
                    className="btn btn-circle btn-circle-primary wherever-button mt-3"
                    onClick={this.onConnected}
                    
                  >
                    <FormattedMessage
                      id="stay_connected"
                      defaultMessage="Stay connected"
                    />
                  </button>
                </Pane>
              </ScrollAnimation>
              <Newsletter open={this.state.open} onCancelConnected={this.onCancelConnected} />
            </Pane>
          </Pane>
          <Pane className="row flex-lg-row flex-column-reverse align-items-center pt-5 pb-5">
            <Pane className="col-lg-5">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInLeft"
              >
                <Pane className="wherever-content">
                  <h2 className="wherever-title">
                    <FormattedMessage
                      id="get_help_at_every_step_of_the_way"
                      defaultMessage="Get help at every step of the way"
                    />
                  </h2>
                  <p className="wherever-norm">
                    <strong>
                      <FormattedMessage
                        id="cardbey_support"
                        defaultMessage="Cardbey Support"
                      />
                    </strong>
                    :{' '}
                    <FormattedMessage
                      id="contact_support_24_7"
                      defaultMessage="Contact support 24/7, whether you’re troubleshooting
                        issues or seeking business advice"
                    />
                  </p>
                  <button
                    className="btn btn-circle btn-circle-primary wherever-button mt-3"
                    onClick={() => this.props.history.push('contact-us')}
                  >
                    <FormattedMessage
                      id="contact_support"
                      defaultMessage="Contact support"
                    />
                  </button>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-7">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInRight"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + '/assets/images/landing/social.png'
                  }
                  alt="sell"
                  className="wherever-img"
                />
              </ScrollAnimation>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(Wherever);
