import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { formatDecimal } from "shared/helpers";
import Config from "constants/Config";
import { Pane } from 'shared/components/layouts';
import { Link } from 'react-router-dom';

class OrderSummary extends Component {
  static propTypes = {
    detail: PropTypes.object,
  };

  isDineIn = (detail) => {
    if (detail.cartItems) {
      const items = detail.cartItems.find(
        (item) =>
          item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN ||
          item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
      );
      return !!items;
    }
    return false;
  };

  render() {
    const { detail } = this.props;
    const seller = detail.cartItems && detail.cartItems.length > 0 ? detail.cartItems[0].seller : null;

    return (
      <Pane className="order-summary card-body">
        <Pane className="summary-body">
          <Pane className="summary-item">
            AUD ${formatDecimal(detail.total)} - {detail.cartItems.length} {`item${detail.cartItems.length > 1 ? 's' : ''}`} - {detail.paymentMethod}
          </Pane>
          {seller && (
            <Link to={`/${seller.id}/menu`}>
              <Pane className="summary-user-info">
                {seller.businessName} - Phone ({seller.phone})
              </Pane>
            </Link>
          )}
          {detail.paymentMethod !== Config.PAYMENT_METHOD.PAY_BY_CASH && (
            <Pane className="summary-body">
              Payment method:&nbsp;
              {detail.paymentStatus}
            </Pane>
          )}
        </Pane>
        {detail.refundAmount > 0 && (
          <Pane className="summary-body">
            <FormattedMessage
              id="refund_price"
              defaultMessage="Refund price"
            />
            : {formatDecimal(detail.refundAmount)}
          </Pane>
        )}
        {!this.isDineIn(detail) && (
          <>
            <Pane className="summary-method">{detail.cartItems[0] ? detail.cartItems[0].deliveryMethod : ''}</Pane>
            <Pane className="summary-body">
              {detail.shippingAddress}
            </Pane>
          </>
        )}
        {this.isDineIn(detail) && (
          <>
            <Pane className="summary-method">{detail.cartItems[0] ? detail.cartItems[0].deliveryMethod : ''}</Pane>
            <Pane className="summary-body">
              Table:&nbsp;
              <span className={`badge badge-done`}>
                {detail.table && detail.table.name}
              </span>
              &nbsp;&nbsp;-&nbsp;&nbsp;Status:&nbsp;
              <span className={`badge badge-${detail.status.toLowerCase()}`}>
                {detail.status}
              </span>
            </Pane>
          </>
        )}
        <Pane className="summary-body">
          <em className="summary-time">Order Time: {moment.utc(detail.orderDate).format("DD-MM-YYYY HH:mm")}</em>
        </Pane>
      </Pane>
    );
  }
}

export default OrderSummary;
