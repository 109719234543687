import * as types from 'constants/ActionTypes'
import api from 'api'

// Products
export const getProductsByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_PRODUCTS, types.RECEIVE_MY_PRODUCTS_SUCCESS, types.RECEIVE_MY_PRODUCTS_FAILURE],
  payload: {
    request: api.user.products(params)
  }
})

export const getFoodCategoriesByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_PRODUCTS, types.RECEIVE_MY_FOOD_CATEGORY_SUCCESS, types.RECEIVE_MY_PRODUCTS_FAILURE],
  payload: {
    request: api.user.foodCategories(params)
  }
})
export const getOtherCategoriesByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_PRODUCTS, types.RECEIVE_MY_OTHER_CATEGORY_SUCCESS, types.RECEIVE_MY_PRODUCTS_FAILURE],
  payload: {
    request: api.user.otherCategories(params)
  }
})

// Create a product
export const createMyProduct = (params) => (dispatch) => dispatch({
  type: types.CREATE_MY_PRODUCT,
  payload: {
    request: api.user.createProduct(params)
  }
})

// Get product detail
export const getMyProductDetail = (params) => (dispatch) => dispatch({
  type: types.MY_PRODUCT_DETAIL,
  payload: {
    request: api.user.productDetail(params)
  }
})

// Edit product detail
export const editMyProduct = (params) => (dispatch) => dispatch({
  type: types.EDIT_MY_PRODUCT,
  payload: {
    request: api.user.editProduct(params)
  }
})

// Edit product detail
export const deleteMyProduct = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_PRODUCT,
  payload: {
    request: api.user.deleteProduct(params)
  }
})


export const deleteMyProductPicks = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_PRODUCT,
  payload: {
    request: api.user.deleteProductPick(params)
  }
})
