import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';

class CategoryItem extends Component {
  render() {
    if (this.props.type === 'services') {
      return (
        <Pane className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
          <Pane className="category-item text-center p-0">
            <Link to={`/${this.props.type}?category=${this.props.id}`}>
              <img
                src={
                  this.props.image ? this.props.image : process.env.PUBLIC_URL + '/assets/images/default-image.png'
                }
                ref={(img) => (this.img = img)}
                onError={() =>
                  (this.img.src =
                    process.env.PUBLIC_URL + `/assets/images/default-image.png`)
                }
                alt={this.props.name}
                className="category-img"
              />
              <h2 className="category-title">{this.props.name}</h2>
            </Link>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
        <Pane className="category-item text-center p-0">
          <Link to={`/${this.props.type}?category=${this.props.id}`}>
            <img
              src={
                this.props.image ? this.props.image : process.env.PUBLIC_URL + '/assets/images/default-image.png'
              }
              ref={(img) => (this.img = img)}
              onError={() =>
                (this.img.src =
                  process.env.PUBLIC_URL + `/assets/images/default-image.png`)
              }
              alt={this.props.name}
              className="category-img"
            />
            <h2 className="category-title">{this.props.name}</h2>
          </Link>
        </Pane>
      </Pane>
    );
  }
}

CategoryItem.propTypes = {
  item: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
};

export default CategoryItem;
