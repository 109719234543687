import * as ProductBrowseV1 from "./product.browse.v1.action";
import * as ProductReviewV1 from "./product.review.v1.action";
import * as ProductGetReviewV1 from "./product.get-review.v1.action";
import * as ProductReadV1 from "./product.read.v1.action";
import * as ProductSearchV1 from "./product.search.v1.action";
import * as ProductFeatureV1 from "./product.featured.v1.action";
import * as ProductPicksBrowseV1 from "./product-picks.browse.v1.action";
import * as ProductGetOptionsV1 from "./product.get-option.v1.action";

export default {
  // get all products
  browse: {
    callAPI: (params) => ProductBrowseV1.exec(params),
    parseResponse: (response) => ProductBrowseV1.parseResponse(response),
  },
  browsePicks: {
    callAPI: (params) => ProductPicksBrowseV1.exec(params),
    parseResponse: (response) => ProductPicksBrowseV1.parseResponse(response),
  },
  // get product detail
  read: {
    callAPI: (params) => ProductReadV1.exec(params),
    parseResponse: (response) => ProductReadV1.parseResponse(response),
  },
  // review product
  review: {
    callAPI: (params) => ProductReviewV1.exec(params),
    parseResponse: (response) => ProductReviewV1.parseResponse(response),
  },
  // get review product
  getReview: {
    callAPI: (params) => ProductGetReviewV1.exec(params),
    parseResponse: (response) => ProductGetReviewV1.parseResponse(response),
  },
  // search product
  search: {
    callAPI: (id) => ProductSearchV1.exec(id),
    parseResponse: (response) => ProductSearchV1.parseResponse(response),
  },
  // featured product
  featured: {
    callAPI: (params) => ProductFeatureV1.exec(params),
    parseResponse: (response) => ProductFeatureV1.parseResponse(response),
  },
  options: {
    callAPI: (params) => ProductGetOptionsV1.exec(params),
    parseResponse: (response) => ProductGetOptionsV1.parseResponse(response),
  },
};
