import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage } from 'react-intl';
import BookedServiceItem from './BookedServiceItem';
import { formatDecimal } from 'shared/helpers';

class BookedServiceList extends Component {
  static propTypes = {
    detail: PropTypes.object,
  };

  state = {
    loading: false,
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  render() {
    const { detail } = this.props;

    return (
      <Pane className="order-list">
        {detail.bookingItems &&
          detail.bookingItems.map((item) => {
            return (
              <BookedServiceItem
                key={item.id}
                data={item}
                detail={detail}
              />
            );
          })}
        <Pane className="order-total">
          <FormattedMessage id="total" defaultMessage="TOTAL" />: AUD ${formatDecimal(detail.total)}
        </Pane>
        <Pane className="order-note">
          <FormattedMessage id="note" defaultMessage="Note" />{detail.mode ? (detail.guestNote || 'None') : (detail.note || 'None')}
        </Pane>
      </Pane>
    );
  }
}

export default BookedServiceList;
