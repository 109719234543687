import * as DigitalShareAddV1 from "./digital-share.add.v1.action";
import * as DigitalShareEditV1 from "./digital-share.edit.v1.action";
import * as DigitalShareDeleteV1 from "./digital-share.delete.v1.action";
import * as DigitalShareBrowseV1 from "./digital-share.browse.v1.action";

export default {
  browse: {
    callAPI: params => DigitalShareBrowseV1.exec(params),
    parseResponse: response => DigitalShareBrowseV1.parseResponse(response)
  },
  add: {
    callAPI: (params) => DigitalShareAddV1.exec(params),
    parseResponse: (response) => DigitalShareAddV1.parseResponse(response),
  },
  edit: {
    callAPI: (params) => DigitalShareEditV1.exec(params),
    parseResponse: (response) => DigitalShareEditV1.parseResponse(response),
  },
  delete: {
    callAPI: (params) => DigitalShareDeleteV1.exec(params),
    parseResponse: (response) => DigitalShareDeleteV1.parseResponse(response),
  },
};
