import moment from 'moment';
import PropTypes from 'prop-types';
import Config from 'constants/Config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-responsive-modal';
import { bindActionCreators } from 'redux';
import { handleError } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';
import { pushNotification } from 'actions/notification';
import { cancelRequestDelivery } from 'actions/delivery';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Table, Button, TextArea, Icon } from 'semantic-ui-react';

class BuyingOrderItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    orderDate: PropTypes.string,
    paymentMethod: PropTypes.string,
    total: PropTypes.number,
    status: PropTypes.string,
    link: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loadingRequestCancel: false,
      open: false,
      reason: '',
      openReason: false,
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onOpenReasonModal = () => {
    this.setState({
      openReason: true,
    });
  };

  onCloseReasonModal = () => {
    this.setState({
      openReason: false,
    });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      errors: {},
    });
  };

  requestCancel = (itemId) => {
    const { intl, type } = this.props;
    // const errors = {}
    const params = {
      id: itemId,
      reason: this.state.reason,
    };

    this.setState({ loadingRequestCancel: true });
    this.onCloseModal();
    this.props
      .onRequestCancel(params)
      .then((response) => {
        if (type === 'DELIVERY_SCHEDULE') {
          this.props.getDeliverySchedule(false);
        } else {
          this.props.getDelivery(false);
        }
        this.setState({
          loadingRequestCancel: false,
        });
        // Push notification to seller
        // this.props
        //   .onPushNotification({
        //     object_id: itemId,
        //     object_type: 'Delivery',
        //     target_id: get(item, 'user.id'),
        //     target_type: 'Seller',
        //     summary: `Request cancel in Delivery #${itemId}`,
        //     meta: {
        //       order_type: 'selling',
        //       order_id: get(item, 'user.id'),
        //       url: `/u/${get(item, 'user.id')}/delivery-service`,
        //     },
        //   })
        //   .catch(() => {
        //     this.props.notificationSystem.addNotification({
        //       message: "Something's wrong about notification",
        //       level: 'error',
        //     });
        //   });

        this.props.notificationSystem.addNotification({
          message: intl.formatMessage({
            id: 'notification.you_have_sent_request_successfully',
          }),
          level: 'success',
        });
      })
      .catch((errors) => {
        this.setState({ loadingRequestCancel: false });
        this.props.notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({
              id: 'notification.can_not_request_cancel_this_product',
            })
          ),
          level: 'error',
        });
      });
  };

  render() {
    const { item, type } = this.props;
    return (
      <Table.Row>
        <Table.Cell>{item.id}</Table.Cell>
        <Table.Cell>{item.receiverName}</Table.Cell>
        <Table.Cell>
          <p className="text-gray pt0 pb0">
            - Delivery address:
            <br /> {item.receiverAddress}
          </p>
          <p className="text-gray pt0 pb0">
            - Recipient's email:
            <br /> {item.receiverEmail}
          </p>
          <p className="text-gray pt0 pb0">
            - Recipient's phone number:
            <br /> {item.receiverPhone}
          </p>
        </Table.Cell>
        {type === 'DELIVERY_SCHEDULE' && (
          <>
            {item.pickupTime &&
              item.expectDeliveryTime && (
                <Table.Cell>
                  {item.pickupTime && (
                    <p>
                      -Pickup: <br />
                      {moment.unix(item.pickupTime).format(
                        'DD-MM-YYYY HH:mm'
                      )}
                    </p>
                  )}
                  {item.expectDeliveryTime && (
                    <p>
                      -Expect Delivery:
                      <br />
                      {moment.unix(item.expectDeliveryTime).format(
                        'DD-MM-YYYY HH:mm'
                      )}
                    </p>
                  )}
                </Table.Cell>
              )}
          </>
        )}
        <Table.Cell>
          {item.status && (
            <span className="text-gray">
              <span className={`badge badge-${item.status.toLowerCase()}`}>
                <FormattedMessage
                  id={`order.${item.status.toLowerCase()}`}
                  defaultMessage={item.status}
                />
              </span>
            </span>
          )}
        </Table.Cell>
        <Table.Cell>
          {item.reason && (
            <Pane>
              <p>
                <FormattedMessage
                  id="notification.your_request_has_been_denied_by_seller"
                  defaultMessage="Your request has been denied by seller"
                />
                .
                <Icon
                  name="info circle"
                  onClick={this.onOpenReasonModal}
                  data-tip
                  data-for={`${item.id}`}
                />
              </p>
              <ReactTooltip
                place="top"
                type="dark"
                effect="float"
                id={`${item.id}`}
              >
                <span>
                  <FormattedMessage
                    id="notification.click_to_view_detail"
                    defaultMessage="Click to view detail"
                  />
                </span>
              </ReactTooltip>
            </Pane>
          )}
          {item.status === Config.ORDER_STATUS.NEW &&
          item.status !== Config.ORDER_STATUS.DELIVERED &&
          item.status !== Config.ORDER_STATUS.DONE ? (
            !item.reason ? (
              <Button
                size="mini"
                color="orange"
                onClick={this.onOpenModal}
                loading={this.state.loadingRequestCancel}
                disabled={this.state.loadingRequestCancel}
                style={{
                  paddingRight: '5px',
                  paddingLeft: '5px',
                }}
              >
                <FormattedMessage
                  id="request_cancel"
                  defaultMessage="Request cancel"
                />
              </Button>
            ) : (
              item.cancelStatus === 'REQUESTED' && (
                <p>
                  <FormattedMessage
                    id="notification.you_have_sent_cancellation_request"
                    defaultMessage="You have sent cancellation request"
                  />
                </p>
              )
            )
          ) : null}
          <Modal
            open={this.state.openReason}
            onClose={this.onCloseReasonModal}
            center
            showCloseIcon={false}
          >
            <Pane className="react-confirm-alert-body">
              <h1>
                <FormattedMessage id="reason" defaultMessage="Reason" />
              </h1>
              <p>{item.reason}</p>
              <Pane className="react-confirm-alert-button-group">
                <button onClick={this.onCloseReasonModal}>
                  <FormattedMessage id="close" defaultMessage="Close" />
                </button>
              </Pane>
            </Pane>
          </Modal>
          <Modal
            open={this.state.open}
            onClose={this.onCloseModal}
            center
            showCloseIcon={false}
          >
            <Pane className="react-confirm-alert-body">
              <h1>
                <FormattedMessage
                  id="confirmation"
                  defaultMessage="Confirmation"
                />
              </h1>
              <p>
                <FormattedMessage
                  id="notification.are_you_sure_you_want_to_cancel_this"
                  defaultMessage="Are you sure you want to cancel this"
                />
                ?
              </p>
              <TextArea
                name="reason"
                className="form-control"
                value={this.state.reason}
                placeholder="Your reason"
                onChange={this.onChange}
              />
              {/* { errors.reason && <InlineMessage text={errors.reason} /> } */}
              <Pane className="react-confirm-alert-button-group">
                <button onClick={() => this.requestCancel(item.id)}>
                  <FormattedMessage id="yes" defaultMessage="Yes" />
                </button>
                <button onClick={this.onCloseModal}>
                  <FormattedMessage id="no" defaultMessage="No" />
                </button>
              </Pane>
            </Pane>
          </Modal>
        </Table.Cell>
        <Table.Cell>
          <Link to={this.props.link}>
            <Icon name="eye" />
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  onRequestCancel: bindActionCreators(cancelRequestDelivery, dispatch),
  onPushNotification: bindActionCreators(pushNotification, dispatch),
});

export default connect(
  null,
  bindDispatchToProps
)(injectIntl(BuyingOrderItem));
