import * as types from 'constants/ActionTypes'
import OrderAdapter from 'services/orders/adapter'

const initialState = {
  data: {},
  error: null,
  fetching: false,
  fetched: false
}
const buyingOrderDetail = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BUYING_ORDER_DETAIL:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_BUYING_ORDER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        data: OrderAdapter.buyers.read.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_BUYING_ORDER_DETAIL_FAILURE:
      return Object.assign({}, state, {
        data: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        data: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}
  
const sellingOrderDetail = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_SELLING_ORDER_DETAIL:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_SELLING_ORDER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        data: OrderAdapter.sellers.read.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_SELLING_ORDER_DETAIL_FAILURE:
      return Object.assign({}, state, {
        data: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        data: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { buyingOrderDetail, sellingOrderDetail }
