import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Pane extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    as: PropTypes.string,
  }

  render() {
    const { as } = this.props
    const props = Object.assign({}, this.props)

    delete props.as

    const Tag = `${as}`

    return (
      <Tag { ...props }>
        { this.props.children }
      </Tag>
    )
  }
}

Pane.defaultProps = {
  as: 'div'
}

export default Pane
