import Config from 'constants/Config'

const exec = params => {
  return {
    method: 'POST',
    url: `/likeVideo/${params.id}`,
    baseURL: Config.NEW_API_URL,
    data: {
      like: params.like,
      model: params.model || '',
    }
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
