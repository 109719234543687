import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';

class BannerIntro extends Component {
  render() {
    const { storeInfo } = this.props;
    return (
      <Pane className="banner-container" id="banner-container">
        <Pane
          className="banner-item"
          style={{
            backgroundImage: `url(${
              storeInfo
                ? `${storeInfo.backgroundImage ||
                    process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`}`
                : process.env.PUBLIC_URL + `/assets/images/default-image.png`
            })`,
          }}
        />
      </Pane>
    );
  }
}

BannerIntro.propTypes = {};

export default BannerIntro;
