import * as types from 'constants/ActionTypes'

const initialState = {
  info: {},
  error: null,
  fetching: false,
  fetched: false
}

const myBusiness = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_BUSINESS_INFO:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_MY_BUSINESS_INFO_SUCCESS:
      return Object.assign({}, state, {
        info: action.payload.data,
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_BUSINESS_INFO_FAILURE:
      return Object.assign({}, state, {
        info: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        info: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export default myBusiness
