import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = params => {
  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: params.hash ? `/slide-screens/${params.hash}` : `/screens/${params.id}` 
  };
};
const parseResponse = response => {
  const itemNew = formatJson(response, response.data.type, response.data.id);
  return itemNew
};

export { exec, parseResponse };