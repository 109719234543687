import Config from "constants/Config";
const exec = (params) => {
  return {
    method: "DELETE",
    url: `/task-member?id[]=${params.id}`,
    baseURL: Config.NEW_API_URL,
  };
};
const parseResponse = (response) => {
  const data = {
    ...response,
  };
  return data;
};
export { exec, parseResponse };