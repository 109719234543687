import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Validator from 'validator';
import { InlineMessage } from 'shared/components';
import { handleError } from 'shared/helpers';
import NotificationSystem from 'react-notification-system';

class ForgotPasswordForm extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: {
        email: '',
      },
      loading: false,
      redirect: false,
      errors: {},
    };
    this._notificationSystem = null;
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.email) {
      errors.email = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
    }

    return errors;
  };

  onSubmit = () => {
    let errors = {};
    const { intl } = this.props;

    errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = {
        email: this.state.data.email,
      };

      this.props
        .forgotPassword(data)
        .then(() => {
          this.setState({ loading: false });
          this.clearData();
          this._notificationSystem.addNotification({
            message: intl.formatMessage({ id: 'notification.forgot_password_success' }),
            level: 'success',
            onRemove: () => {
              this.props.history.push('/signin');
            },
          });
        })
        .catch((errors) => {
          this.setState({ loading: false });
          this._notificationSystem.addNotification({
            message: handleError(errors, errors.response.statusText),
            level: 'error',
          });
        });
    }
  };

  clearData = () => {
    this.setState({
      data: {
        email: '',
      },
    });
  };

  render() {
    const { data, loading, errors } = this.state;
    const { intl } = this.props;

    return (
      <Form onSubmit={this.onSubmit} className="login-form forgot-pw" noValidate>
        <Form.Field error={!!errors.email} className="form-item">
          <label htmlFor="email" className="form-label">
            E-MAIL
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder={intl.formatMessage({ id: 'email' })}
            value={data.email}
            className="form-input"
            onChange={this.onChange}
          />
          {errors.email && <InlineMessage text={errors.email} />}
        </Form.Field>
        <Form.Field className="form-item form-button">
          <Button
            type="submit"
            className="btn btn-circle btn-form"
            disabled={loading}
            loading={loading}
          >
            <FormattedMessage id="send" defaultMessage="Send" />
          </Button>
        </Form.Field>
        <NotificationSystem ref="notificationSystem" />
      </Form>
    );
  }
}

export default withRouter(injectIntl(ForgotPasswordForm));
