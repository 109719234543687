import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  const page = params.page || 1
  const size = params.size || 20

  return {
    method: 'GET',
    url: `/product-picks?page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const categories = [];

  if (response.data) {
    response.data.forEach((item) => {
      const service = formatJson(response, item.type, item.id);
      categories.push(service);
    });
  }
  const data = categories;

  return data;
}

export { exec, parseResponse }
