import * as types from 'constants/ActionTypes';
import UserAdapter from 'services/users/adapter';

const initialState = {
  results: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false,
};

const screenCount = (state = initialState, action = {}) => {
  switch (action.type) {


    case types.COUNT_MY_SCREEN:
    return Object.assign({}, state, {
      fetching: true
    });

    case types.COUNT_MY_SCREEN_SUCCESS:
      return Object.assign({}, state, {
        results:UserAdapter.screenCount.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true,
      });
    default:
      return state;
  }
};

export { screenCount };