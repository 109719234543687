import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table, Icon, Image } from 'semantic-ui-react';
import Config from "constants/Config";

class ContactListItem extends Component {
  static propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    link: PropTypes.string,
    avatar: PropTypes.string,
  };

  render() {
    return (
      <Table.Row>
        <Table.Cell>
          <Link to={`/${this.props.type === Config.WALLET_TYPE.CONTACT ? this.props.id : `${this.props.id}/menu`}`} target="_blank">
            <Image
                src={this.props.avatar ? `${this.props.avatar}` : ""}
              />
            </Link>
        </Table.Cell>
        <Table.Cell style={{ verticalAlign: "middle"}} >{this.props.name || ''}</Table.Cell>
        <Table.Cell style={{ verticalAlign: "middle", whiteSpace: "nowrap"}} >
          <Link to={`/${this.props.type === Config.WALLET_TYPE.CONTACT ? this.props.id : `${this.props.id}/menu`}`} target="_blank">
            <Icon name="eye" />
          </Link>
            <Icon onClick={() => this.props.removeContact(this.props.id, this.props.type)} style={{color: "#16A085", cursor: "pointer", marginLeft: "10px"}} name="trash" />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default ContactListItem;
