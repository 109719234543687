import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import OrderSummary from './OrderSummary';
import OrderedCartList from './OrderedCartList';

class PreviewDetail extends Component {
  static propTypes = {
    detail: PropTypes.object,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      detail: {},
      loading: false,
      reason: "",
      errors: {},
      open: false,
      openCancelModal: false,
      reasonValidate: ""
    };
    this._notificationSystem = null;
  }


  componentDidMount() {
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  isOptions = (item) => {
    if (!item.options) return false;
    if (!item.options.length) return false;

    return true;
  };

  includesOption = (id, item) => {
    const { optionInfo } = item;
    if (!isEmpty(optionInfo)) {
      return optionInfo.find((child) => child.id === id);
    }
  };

  getSelectedOptions = (item) => {
    if (!this.isOptions(item)) return [];

    const { options } = item;
    let selectdOptions = [];

    if (!isEmpty(options)) {
      options.map((option) => {
        const { childrens } = option;
        childrens.forEach((child) => {
          if (this.includesOption(child.id, item)) {
            selectdOptions.push({
              ...child,
              quantity: this.includesOption(child.id, item).quantity,
            });
          }
        });
      });
    }

    return selectdOptions;
  };

  mappingSelectOptionToText = (item) => {
    const selectedOptions = this.getSelectedOptions(item);
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${child.name
          }`
      )
      .join(", ");
  };


  render() {
    const { posCart, total } = this.props;
    return (
      <Pane className="overview-container">
        <Pane className="product-container order-container mb-3">
          <Pane className="order-detail">
            <OrderSummary delivery_method={this.props.delivery_method} table={this.props.table} total={total} posCart={posCart} />
            <Pane className="products-grids table-order">
              <Pane className="card-body">
                <OrderedCartList
                  note={this.props.note}
                  posCart={posCart}
                  total={total}
                />
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(PreviewDetail));