import React, { Component } from 'react';
import { Placeholder, Segment } from 'semantic-ui-react';
import { Pane } from 'shared/components/layouts';
import { ListProducts } from './list';
import ProductAdapter from 'services/products/adapter';
import InfiniteScroll from 'react-infinite-scroller';

class BestPicker extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      results: [],
      page: 0,
      keyword: this.props.query,
      totalPages: 0,
      totalItems: 0,
      loadingMore: false,
      hasMoreItems: true,
      data: [],
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.props.getAllProductPicks({ page: 1 }).then((response) => {
      this.setState((prevState) => ({
        loading: false,
        page: prevState.page + 1,
        data: ProductAdapter.browsePicks.parseResponse(response.data),
        totalPages: response.data.meta.totalPages,
        totalItems: response.data.meta.totalItems,
      }));
    });
  }

  loadItems = () => {
    const params = {
      page: this.state.page + 1,
    };
    if (
      !this.state.loadingMore &&
      this.state.totalItems > 20 &&
      this.state.totalItems > this.state.data.length
    ) {
      this.setState({
        hasMoreItems: false,
        loadingMore: true,
      });
      this.props
        .getAllProductPicks(params)
        .then((response) => {
          this.setState((prevState) => ({
            hasMoreItems: true,
            page: prevState.page + 1,
            data: prevState.data.concat(
              ProductAdapter.browsePicks.parseResponse(response.data)
            ),
            loadingMore: false,
          }));
        })
        .catch(() => {
          this.setState({ loading: false, loadingMore: false });
        });
    }
  };

  renderLoading = (type = '') => {
    return (
      <Pane
        className={`loading-container ${type === 'white' &&
          'loading-adds-wrapper loading-white'}`}
      >
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-2 col-md-3 col-sm-4 col-12">
              <Segment raised>
                <Placeholder>
                  <Placeholder.Header className="mb-3">
                    <Placeholder.Image rectangular className="mb-3" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Pane>
            <Pane className="col-lg-2 col-md-3 col-sm-4 col-12">
              <Placeholder>
                <Placeholder.Header className="mb-3">
                  <Placeholder.Image rectangular className="mb-3" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Pane>
            <Pane className="col-lg-2 col-md-3 col-sm-4 col-12">
              <Placeholder>
                <Placeholder.Header className="mb-3">
                  <Placeholder.Image rectangular className="mb-3" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Pane>
            <Pane className="col-lg-2 col-md-3 col-sm-4 col-12">
              <Placeholder>
                <Placeholder.Header className="mb-3">
                  <Placeholder.Image rectangular className="mb-3" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Pane>
            <Pane className="col-lg-2 col-md-3 col-sm-4 col-12">
              <Placeholder>
                <Placeholder.Header className="mb-3">
                  <Placeholder.Image rectangular className="mb-3" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Pane>
            <Pane className="col-lg-2 col-md-3 col-sm-4 col-12">
              <Placeholder>
                <Placeholder.Header className="mb-3">
                  <Placeholder.Image rectangular className="mb-3" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  };

  render() {
    const { loading, data } = this.state;
    return (
      <Pane className="col-md-12 page-content col-thin-left">
        <Pane className="search-container">
          {loading ? <>{this.renderLoading('white')}</> : null}
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadItems}
            hasMore={this.state.hasMoreItems}
            isReverse={false}
          >
            <ListProducts products={data} />
            {this.state.loadingMore && <>{this.renderLoading()}</>}
          </InfiniteScroll>
        </Pane>
      </Pane>
    );
  }
}

export default BestPicker;
