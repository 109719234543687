import * as types from 'constants/ActionTypes'
const initialState = {
    coordinates: [],
    error: null,
    fetching: false,
    fetched: false
}

const coordinates = (state = initialState, action) => {
    let { lat, lng } = action
    let data = {
        lat, lng
    }
    switch (action.type) {
        case types.RECEIVE_COORDINATES:
            state.coordinates = action.coordinates;
            return state;
        case types.ADD_COORDINATES:
            state.coordinates = data;
            localStorage.setItem("coordinates", JSON.stringify(data));
            return { ...state };
        default:
            return state
    }
}

export default coordinates;