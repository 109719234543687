import Config from 'constants/Config'

const exec = (params) => {
  params._method = 'PATCH'

  return {
    method: 'POST',
    baseURL: Config.NEW_API_URL,
    url: `/reviews/${params.id}`,
    data: params
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }