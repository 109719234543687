import PropTypes from "prop-types";
import _, { isEmpty, get } from "lodash";
import { connect } from "react-redux";
import queryString from "query-string";
import React, { Component } from "react";
import Config from "constants/Config";
import { Form, Radio, Button } from "semantic-ui-react";
import { formatJson } from "shared/helpers";
import { bindActionCreators } from "redux";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { ServiceList } from "./list/index";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import { InlineMessage, LoginModal } from "shared/components";
import { handleError } from "shared/helpers";
import { getAllServices, book } from "actions/services";
import { LoadingSearch } from "components/common/loading";
import { ScrollMenu } from "components/common/scrollMenu";
import { ScrollStaff } from "./scrollStaff";
import { getStoreInfo } from "actions/stores";
import UserAdapter from "services/users/adapter";
import { withRouter } from 'react-router-dom';
import FloatList from './float-list/FloatList'
import BottomCart from './float-list/BottomCart'
import NotificationSystem from "react-notification-system";
import { signin, signinSocial } from 'actions/auth';
import { TimeSlots } from "./time-slots";
import moment from "moment";
import PaymentForm from "components/scenes/cart/payment/PaymentForm";
import { StripeProvider, Elements } from "react-stripe-elements";
import "react-dates/initialize";
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
} from "react-dates";
import { getTimeSlots } from 'actions/services'
import { deleteStoreBookingsRequest, addStoreBookingsRequest, resetStoreBookingsRequest } from "actions/storeBookings";
import { getStaffsByUserId } from "actions/staffs";

class StoreBooking extends Component {
  static propTypes = {
    match: PropTypes.object,
    getAllServices: PropTypes.func,
    intl: intlShape.isRequired,
    signin: PropTypes.func.isRequired,
    signinSocial: PropTypes.func.isRequired,
    book: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
  }

  state = {
    date: moment(),
    errors: [],
    storeId: "",
    services: {},
    totalPages: 0,
    fetched: false,
    modeView: "gridView",
    serviceCategories: [],
    fetchingServices: false,
    page: queryString.parse(this.props.location.search).page || 1,
    storeInfo: {},
    height: 0,
    step: 1,
    timeSlots: [],
    closeDay: [],
    loadingTimeSlots: true,
    timeSlotSelected: null,
    note: "",
    paymentMethod: Config.PAYMENT_METHOD.PAY_BY_CREDIT,
    orderId: "",
    cardNumber: "",
    cardFirstName: "",
    cardLastName: "",
    cardType: Config.CARD_TYPES.MASTER_CARD,
    cardExpireMonth: "",
    cardExpireYear: "",
    cvv: "",
    cardExpiry: "",
    errors: {},
    openModal: false,
    loadingBooking: false,
    members: [],
    membersSelected: Config.STAFF_ID_DEFAULT,
    promotionCode: "",
    discountPercent: false,
    maxDiscount: 0,
  };

  getStoreByName = () => {
    const {
      params: { storeId },
    } = this.props.match;
    this.setState({
      fetchingServices: true,
      fetched: false,
    });

    this.props.getStoreInfo({ store: storeId }).then((response) => {
      if (response) {
        const store = UserAdapter.read.parseResponse(response.data);
        if (!store) {
          this.props.history.replace(`/page-not-found`);
        }
        this.setState({
          closedDay: this.closedDay(store),
        });
        this.setState({ storeInfo: store });
        this.getStoreServices();
      } else {
        this.props.history.replace(`/page-not-found`);
      }
    });
  };

  getStoreServices = async () => {
    const {
      params: { storeId },
    } = this.props.match;
    this.props.getAllServices({
      store: storeId,
      size: 100,
      images: "",
    }).then(() => {
      this.setState({
        fetchingServices: false,
        fetched: true,
      });
    });
  };

  getStaffs() {
    const {
      params: { storeId },
    } = this.props.match;
    this.props
      .getStaffsByUserId({ id: storeId, size: 100, isWorking: true })
      .then((response) => {
        if (response && response.data) {
          let members = [];
          if (response && response.data) {
            response.data.data.forEach((item) => {
              const data = formatJson(response.data, item.type, item.id);
              members.push(data);
            });
          }
          this.setState({ members: members }, () => {
            this.getTimeslots(this.state.storeInfo, moment(this.state.date).format("YYYY-MM-DD"));
          });
        }
      });
  }
  onClearDiscountCode = () => {
    this.setState({ errors: { ...this.state.errors, promotionCode: '' },
        discountPercent: false,
        promotionCode: '',
        maxDiscount:0
  });
  }
  onApplyDiscountCode = () => {

    if(this.state.promotionCode !='') {
      this.props.checkCode({discount_code: this.state.promotionCode}).then((response) => {
        const userId = get(this.state.storeInfo, "business.userId");
        if(userId == response.data.data.attributes.userId && response.data.data.attributes.codeExpired == false){
          this.setState({
            errors: { ...this.state.errors, promotionCode: '' },
            discountPercent: response.data.data.attributes.discount,
            maxDiscount: response.data.data.attributes.maxDiscount,
           });
        } else {
          this.setState({ errors: { ...this.state.errors, promotionCode: 'Code does not exist in store' }, discountPercent: false });
        }
      }).catch((error) => {
        this.setState({ errors: { ...this.state.errors, promotionCode: 'Code does not exist in store or has been used.'  }, discountPercent: false});
      });
    }

  }
  componentWillMount() {
    this.setState({
      height: window.innerHeight,
    });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.unlisten();
  }

  componentDidMount() {
    const {
      params: { storeId },
    } = this.props.match;

    this.unlisten = this.props.history.listen(() => {
      this.getStoreByName();
    });

    const { storeBookings } = this.props;

    if (storeBookings.results.length > 0 && parseInt(storeBookings.results[0].userId) !== parseInt(storeId)) {
      this.props.resetStoreBookingsRequest();
    }

    this.getStoreByName();

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }

    this._notificationSystem = this.refs.notificationSystem;
  }


  handleScroll = () => {
    const { services } = this.props;
    this.setState(
      {
        height: window.innerHeight,
      },
      () => {
        const banner = document.getElementById("banner-container");
        const info = document.getElementById("info-container");
        const video = document.getElementById("video-container");
        const menu = document.getElementById("anchor");
        const stickyBanner = banner && banner.offsetHeight;
        const stickyInfo = info && info.offsetHeight;
        const stickyVideo = video && video.offsetHeight;
        const items = _.chain(services.results)
          .groupBy((x) => x.category && x.category.name)
          .map((item, key) => Object.assign({}, { name: key, items: item }))
          .sort(
            (a, b) =>
              new Date(_.get(b.items[0], "category.createdAt")).getTime() /
              1000 -
              new Date(_.get(a.items[0], "category.createdAt")).getTime() / 1000
          )
          .value();
        const itemsScroll = items.map((item) => {
          const itemMenu = document.getElementById(item.name);
          return {
            ...item,
            scroll: itemMenu && itemMenu.offsetTop + 500,
          };
        });
        let sum = 0;
        if (window.innerWidth < 992) {
          sum = stickyBanner + stickyInfo;
        } else {
          sum = stickyBanner + stickyInfo + stickyVideo;
        }
        if (window.pageYOffset > sum) {
          const itemScroll = itemsScroll.filter(
            (item) => item.scroll >= window.scrollY
          );
          if (!isEmpty(itemScroll)) {
            this.setState({
              selected: itemScroll[0].name,
            });
          }
          menu && menu.classList.add("menu-breadcrum-sticky");
        } else {
          const itemScroll = itemsScroll.filter(
            (item) => item.scroll >= window.scrollY
          );
          if (!isEmpty(itemScroll)) {
            this.setState({
              selected: itemScroll[0].name,
            });
          }
          menu && menu.classList.remove("menu-breadcrum-sticky");
        }
      }
    );
  };

  onSelect = (key) => {
    this.setState(
      {
        keySort: key,
        selected: key,
      },
      () => {
        const elementScroll = document.getElementById(key);
        if (elementScroll) {
          elementScroll.scrollIntoView();
        }
      }
    );
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closedDay = (storeInfo) => {
    let tradingHours = storeInfo.business.tradingHours;
    const closedDay = [];

    tradingHours &&
      Object.keys(tradingHours) &&
      Object.keys(tradingHours).forEach((key) => {
        if (
          tradingHours[key] &&
          tradingHours[key].off
        ) {
          closedDay.push(key);
        }
      });

    return closedDay;
  };

  getTimeslots = (storeInfo, date = null) => {
    this.setState({ loadingTimeSlots: true });
    const { storeBookings } = this.props;
    let est = 0;
    storeBookings.results && storeBookings.results.map((item) => {
      est = Math.round((parseFloat(est) + parseFloat(item.duration)) * 1e12) / 1e12
    });
    this.props
      .getTimeSlots({ businessId: storeInfo.business ? storeInfo.business.id : '', staffId: this.state.membersSelected, date, from_store: true, duration: est * 60 })
      .then((response) => {
        const timeSlot = formatJson(
          response.data,
          response.data.data.type,
          response.data.data.id
        );
        let data = [];
        if (timeSlot.availableTimes) {
          data = Object.keys(timeSlot.availableTimes[date]).map(
            (k) => timeSlot.availableTimes[date][k]
          );
        }
        this.setState(
          {
            loadingTimeSlots: false,
            timeSlots: data,
          },
          () => { }
        );
      })
      .catch(() => {
        this.setState({ loadingTimeSlots: false });
      });
  };

  onChangeTimeSlot = (index) => {
    this.setState({ timeSlotSelected: index }, () => {
      this.handleStep(3);
    });
  };

  handleStep = (step) => {
    if (step === 2) {
      this.getStaffs();
    }
    this.setState({ step: step })
  }

  handleSubmit = (index) => {
    if (!this.props.auth.user.id) {
      this.setState({ openModal: true });

      return false;
    }

    this.setState({ timeSlotSelected: this.state.timeSlots[index].from }, () => {
      this.handleStep(3);
    });
  }

  onDateChange = (date) => {
    this.setState({ date }, () => {
      this.getTimeslots(this.state.storeInfo, moment(date).format("YYYY-MM-DD"));
    });
  };

  onChangeRadio = (e, { value }) => this.setState({ paymentMethod: value });

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleBooking = (token) => {
    const { intl, storeBookings } = this.props;
    const { date, note, paymentMethod, timeSlotSelected } = this.state;
    const items = storeBookings.results;
    let services = [];
    items && items.length > 0 && items.map(item => {
      let children = {};
      let data = { 'service_id': item.id };
      if (!isEmpty(item.children)) {
        children.id = item.children.id;
        children.quantity = 1;
        children.price = item.children.price;
        data.children = JSON.stringify(children);
      }
      services.push(JSON.stringify(data));
    })

    const data = {
      services: services,
      date: moment(date).format("YYYY-MM-DD"),
      note: note,
      payment_method: paymentMethod,
      staff_id: this.state.membersSelected || Config.STAFF_ID_DEFAULT,
      booking_time: timeSlotSelected,
      booking_time_est: this.timeEst() || timeSlotSelected,
      promotion_code: this.state.promotionCode
    };

    if (token) {
      data.token_stripe = token;
    }

    this.setState({ loadingBooking: true });
    this.props
      .book(data)
      .then((response) => {
        this.setState({ loadingBooking: false });
        this.props.resetStoreBookingsRequest();
        if (this.props.history) {
          this.props.history.push(
            `/booking-success?orderId=${response.data.data.id}`
          );
        } else {
          window.location = `/booking-success?orderId=${response.data.data.id}`;
        }
      })
      .catch((errors) => {
        this.setState({ loadingBooking: false });

        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };


  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    const cardElementContainer = document.getElementsByClassName(
      "StripeElement"
    )[0];
    if (
      cardElementContainer &&
      this.state.paymentMethod === Config.PAYMENT_METHOD.PAY_BY_CREDIT
    ) {
      let cardElementEmpty = cardElementContainer.classList.contains(
        "StripeElement--empty"
      );
      let cardElementInvalid = cardElementContainer.classList.contains(
        "StripeElement--invalid"
      );
      if (cardElementInvalid || cardElementEmpty) {
        errors.creditCard = intl.formatMessage({
          id: "validate.invalid_credit_info",
        });
      }
    }

    if (!data.paymentMethod) {
      errors.paymentMethod = intl.formatMessage({
        id: "validate.please_select_a_payment_method",
      });
    }

    return errors;
  };

  checkout = () => {
    const errors = this.validate(this.state) || {};

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (this.state.paymentMethod === Config.PAYMENT_METHOD.PAY_BY_CASH) {
        this.handleBooking();
      } else {
        this.createCreditCardForm();
      }
    }
  };

  createCreditCardForm = async () => {
    const { intl } = this.props;

    try {
      const { token } = await this.requestPaymentToken();
      if (token && token.id) {
        this.handleBooking(token.id);
      }
    } catch (e) {
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: "notification.please_try_again" }),
        level: "error",
      });
    }
  }

  onSelectStaff = (id) => {
    if (this.state.membersSelected === id) {
      return;
    }
    this.setState({ membersSelected: id }, () => {
      this.getTimeslots(this.state.storeInfo, moment(this.state.date).format("YYYY-MM-DD"));
    });
  }

  timeEst = () => {
    let est = 0;
    let time = 0;
    const { storeBookings } = this.props;
    storeBookings.results && storeBookings.results.map((item) => {
      est = Math.round((parseFloat(est) + parseFloat(item.duration)) * 1e12) / 1e12
    });
    if (est > 0) {
      let duration = est * 60;
      time = duration;
      let hours = Math.trunc(duration / 60);
      let minutes = duration % 60;
      est = `${hours}h${minutes > 0 ? minutes : ''}`;
    }

    return moment(this.state.timeSlotSelected, 'HH:mm').add(parseInt(time), 'minutes').format('HH:mm');
  }

  render() {
    const {
      auth,
      signin,
      signinSocial,
      services,
      storeBookings,
      intl
    } = this.props;
    const { fetched, fetchingServices, selected, storeInfo, step, closedDay, errors, openModal, date, timeSlotSelected, paymentMethod, loadingBooking, note, members, membersSelected } = this.state;
    const items = _.chain(services.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .value();
    const userInfo = auth && auth.user;
    const staff = members.length > 0 ? members.filter(item => item.id == membersSelected)[0] || null : null;
    return (
      <>
        <LoginModal
          onNotification={this._notificationSystem}
          signin={signin}
          signinSocial={signinSocial}
          auth={auth}
          onCloseModal={this.onCloseModal}
          openModal={openModal}
          isCheckout={true}
        />
        <Pane className="row store-booking">
          {!fetchingServices && fetched ? (
            <>
              <Pane className="col-12 step-booking__title">
                {step > 1 && (<i onClick={() => this.handleStep(step - 1)} className="fa fa-arrow-left" />)} Step {step} of 3
              </Pane>
              {step === 1 && (
                <Pane className="col-lg-8">
                  <h3 className="heading-title">
                    Select services
                  </h3>
                  <Pane className="tab-heading tab-heading-stores service-before" id="anchor">
                    {!_.isEmpty(items) && (
                      <Pane
                        className="category-list menu-breadcrum mt-3"
                        id="menu-breadcrum"
                      >
                        <ScrollMenu
                          items={items}
                          onSelect={this.onSelect}
                          selected={selected}
                        />
                      </Pane>
                    )}
                  </Pane>

                  {isEmpty(items) && <Empty type="white" />}
                  {!isEmpty(items) && items.map((category, index) => {
                    if (!category.items.length) {
                      return <></>;
                    }
                    return (
                      <React.Fragment key={index}>
                        <Pane key={category.name} id={category.name}>
                          <Pane className="category-name"><strong>{category.name}</strong></Pane>
                          <ServiceList
                            auth={auth}
                            name={category.name}
                            services={category.items}
                            modeView={this.state.modeView}
                            serviceModal={this.props.serviceModal}
                            items={storeBookings.results}
                            addStoreBookingsRequest={this.props.addStoreBookingsRequest}
                            deleteStoreBookingsRequest={this.props.deleteStoreBookingsRequest}
                          />
                        </Pane>
                      </React.Fragment>);
                  })}
                </Pane>
              )}
              {step === 2 && (
                <Pane className="col-lg-8">
                  <h3 className="heading-title">Select time, sfaff</h3>
                  <Pane
                    className="staff-list mt-3"
                  >
                    <Pane className={`staff-item staff-pick text-uppercase`}>
                      <Pane className="staff-img">
                        <img onClick={() => this.onSelectStaff(Config.STAFF_ID_DEFAULT)} className="ava-img" src={
                          process.env.PUBLIC_URL +
                          `/assets/images/icon-admin.png`
                        } alt="icon admin" />
                        {this.state.membersSelected === Config.STAFF_ID_DEFAULT && (
                          <img className="icon-check" src={`${process.env.PUBLIC_URL}/assets/images/icon-check.png`} alt="icon check" />
                        )}
                      </Pane>
                      <span className="staff-name">
                        Store <br /> pick for you
                      </span>
                    </Pane>
                    {!_.isEmpty(this.state.members) && (
                      <ScrollStaff
                        items={this.state.members}
                        onSelect={this.onSelectStaff}
                        selected={this.state.membersSelected}
                      />
                    )}
                  </Pane>
                  <form className="booking-form">
                    <Pane className="row">
                      <Pane className="col-lg-6">
                        <Pane className="form-item">
                          <DayPickerSingleDateController
                            isOutsideRange={(day) =>
                              !isInclusivelyAfterDay(day, moment())
                            }
                            enableOutsideDays={false}
                            numberOfMonths={1}
                            date={date}
                            onDateChange={this.onDateChange}
                            isDayBlocked={(momentDate) => {
                              const dayString = momentDate.format("ddd");
                              return (
                                closedDay.indexOf(
                                  dayString.toLowerCase()
                                ) !== -1
                              );
                            }}
                          />
                        </Pane>
                      </Pane>
                      <Pane className="col-lg-6">
                        <Pane className="form-item">
                          <TimeSlots
                            staff={staff}
                            timeSlots={this.state.timeSlots}
                            loading={this.state.loadingTimeSlots}
                            handleSubmit={this.handleSubmit}
                          />
                          {errors.timeSlots && (
                            <InlineMessage text={errors.timeSlots} />
                          )}
                        </Pane>
                      </Pane>
                    </Pane>
                  </form>
                </Pane>
              )}
              {step === 3 && (
                <Pane className="col-lg-5 checkout-container">
                  <h3 className="heading-title">Booking confirm</h3>
                  <Pane className="checkout-content">
                    <Pane className="checkout-info">
                      <h2 className="checkout-title mb-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/cart/user.svg`
                          }
                          className="icon"
                          onChange={this.onChangeRadio}
                        />
                        <FormattedMessage
                          id="customer_information"
                          defaultMessage="Customer Information"
                        />
                      </h2>
                      <Pane className="check-label">
                        <FormattedMessage
                          id="full_name"
                          defaultMessage="Full name"
                        />
                        :{" "}
                        <span className="check-name">{userInfo.displayName}</span>
                      </Pane>
                      <Pane className="check-label">
                        <FormattedMessage id="email" defaultMessage="Email" />:
                        <span className="check-name">{userInfo.email}</span>
                      </Pane>
                      <Pane className="check-label">
                        <FormattedMessage id="phone" defaultMessage="Phone" />:
                        <span className="check-name">{userInfo.phoneNumber}</span>
                      </Pane>
                      <Pane className="check-label">
                        <Form.Field
                            error={!!errors.promotionCode}
                            className="form-item"
                          >
                            <label
                              htmlFor="promotionCode"
                              className="form-label"
                            >
                              <span>Promotion Code</span>
                            </label>
                            <input
                              type="text"
                              id="promotionCode"
                              name="promotionCode"
                              placeholder=""
                              className="form-input"
                              value={this.state.promotionCode}
                              onChange={this.onChange}
                            />
                            {errors.promotionCode && (
                              <label className="notification-message show">{errors.promotionCode}</label>
                            )}
                        </Form.Field>
                        {
                          this.state.discountPercent && (
                              <Button className="btn" onClick={()=>this.onClearDiscountCode()}>
                                Clear code
                              </Button>
                            )
                          }
                        {
                          !this.state.discountPercent && (
                            <Button className="btn" onClick={()=>this.onApplyDiscountCode()}>
                              Apply code
                            </Button>
                          )
                        }
                      </Pane>
                      <Pane className="check-label">
                        <Form.Field
                          error={!!errors.note}
                          className="form-item"
                        >
                          <label
                            htmlFor="note"
                            className="form-label"
                          >
                            <FormattedMessage
                              id="note"
                              defaultMessage="Note"
                            />
                          </label>
                          <textarea
                            id="note"
                            name="note"
                            value={note}
                            rows="2"
                            className="form-input"
                            onChange={this.onChange}
                          />
                          {errors.note && (
                            <InlineMessage
                              text={errors.note}
                            />
                          )}
                        </Form.Field>
                      </Pane>
                      <h2 className="checkout-title">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/cart/payment.svg`
                          }
                          alt="users"
                          className="icon"
                        />
                        <FormattedMessage
                          id="payment_method"
                          defaultMessage="PAYMENT METHOD"
                        />
                      </h2>
                      <Pane className="choose-payment">
                        <Form.Field className="choose-item mr-3">
                          <Radio
                            label={intl.formatMessage({ id: "pay_at_store" })}
                            name="paymentMethod"
                            className="choose-radio"
                            value={Config.PAYMENT_METHOD.PAY_BY_CASH}
                            checked={
                              paymentMethod ===
                              Config.PAYMENT_METHOD.PAY_BY_CASH
                            }
                            onChange={this.onChangeRadio}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL + `/assets/images/cash.png`
                            }
                            alt="users"
                            className="icon"
                          />
                        </Form.Field>
                        <Form.Field className="choose-item">
                          <Radio
                            label={intl.formatMessage({ id: "pay_by_credit" })}
                            name="paymentMethod"
                            className="choose-radio"
                            value={Config.PAYMENT_METHOD.PAY_BY_CREDIT}
                            checked={
                              paymentMethod ===
                              Config.PAYMENT_METHOD.PAY_BY_CREDIT
                            }
                            onChange={this.onChangeRadio}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/cart/credit.png`
                            }
                            alt="users"
                            className="icon"
                          />
                        </Form.Field>
                        <Pane className="clearfix pb10" />
                      </Pane>
                      {errors.paymentMethod && (
                        <InlineMessage text={errors.paymentMethod} />
                      )}
                    </Pane>
                    {paymentMethod ===
                      Config.PAYMENT_METHOD.PAY_BY_CREDIT && (
                        <Pane className="checkout-info">
                          <h2 className="checkout-title">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/images/cart/payment.svg`
                              }
                              alt="users"
                              className="icon"
                            />
                            <FormattedMessage
                              id="payment_information"
                              defaultMessage="Payment information"
                            />
                          </h2>
                          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                            <Elements>
                              <PaymentForm
                                requestPaymentToken={(request) =>
                                  (this.requestPaymentToken = request)
                                }
                              />
                            </Elements>
                          </StripeProvider>
                          {errors.creditCard && (
                            <InlineMessage text={errors.creditCard} />
                          )}
                        </Pane>
                      )}
                  </Pane>
                  <Pane className="mt-2">
                    <Button
                      color="yellow"
                      className="btn btn-process btn-process-yellow w-100"
                      id="payment-button"
                      loading={loadingBooking}
                      disabled={loadingBooking}
                      onClick={this.checkout}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/cart/lock.svg`
                        }
                        alt="lock"
                        className="icon"
                      />
                      PROCEED TO CHECK OUT
                    </Button>
                  </Pane>
                </Pane>
              )}
              <Pane className={`${step === 3 ? 'col-lg-7 step-3' : 'col-lg-4'}`}>
                <FloatList staff={staff} timeSlotSelected={timeSlotSelected} date={date} step={step} handleStep={this.handleStep} storeInfo={storeInfo} items={storeBookings.results}  discountPercent={this.state.discountPercent} maxDiscount={this.state.maxDiscount}/>
              </Pane>
              {storeBookings.results.length > 0 && step === 1 && (
                <BottomCart handleStep={this.handleStep} storeInfo={storeInfo} items={storeBookings.results} discountPercent={this.state.discountPercent} maxDiscount={this.state.maxDiscount}/>
              )}
            </>
          ) : (
            <Pane className="w-100">
              <LoadingSearch type="white" />
            </Pane>
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  stores: state.stores,
  services: state.services,
  auth: state.auth,
  storeBookings: state.storeBookings
});

const bindDispatchToProps = (dispatch) => ({
  getAllServices: bindActionCreators(getAllServices, dispatch),
  getStoreInfo: bindActionCreators(getStoreInfo, dispatch),
  getTimeSlots: bindActionCreators(getTimeSlots, dispatch),
  getStaffsByUserId: bindActionCreators(getStaffsByUserId, dispatch),
  addStoreBookingsRequest: bindActionCreators(addStoreBookingsRequest, dispatch),
  deleteStoreBookingsRequest: bindActionCreators(deleteStoreBookingsRequest, dispatch),
  resetStoreBookingsRequest: bindActionCreators(resetStoreBookingsRequest, dispatch),
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
  book: bindActionCreators(book, dispatch)
});

export default withRouter(injectIntl(connect(
  bindStateToProps,
  bindDispatchToProps
)(StoreBooking)));
