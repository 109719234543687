import _, { get } from 'lodash';
import PropTypes from 'prop-types';
import Config from 'constants/Config';
import React, { Component } from 'react';
import { Rating } from 'semantic-ui-react';
import { Pane } from 'shared/components/layouts';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import { PriceItem, PromotionLabel } from 'shared/components';

class ListItem extends Component {
  state = {
    added: false,
    adding: false,
  };

  state = {
    added: false,
    adding: false,
    play: 0,
  };

  addItemToCart = () => {
    this.setState({ added: false, adding: true });

    this.props
      .onAddItemToCart({ product_id: this.props.id })
      .then(() => {
        this.setState({ added: true, adding: false });
      })
      .catch(() => {
        this.setState({ adding: false });
      });
  };

  renderInfo = () => {
    const { item, auth } = this.props;
    if (_.get(item, 'userId') === _.get(auth, 'user.id')) {
      return null;
    }
    return (
      this.props.type !== 'bussiness' && (
        <div className="adds-info">
          <PriceItem
            promotion={item && item.promotion}
            originalPrice={item && item.originalPrice}
          />
          <img
            onClick={this.addItemToCart}
            src={process.env.PUBLIC_URL + `/assets/images/shopnow/cart.svg`}
            alt="cart"
            className="icon-cart"
          />
        </div>
      )
    );
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    // var isPlaying = video.currentTime > 0 && !video.paused;
    // if(isPlaying){
    //   video.pause();
    // }
    video.currentTime = 0;
    setTimeout(
      (v) => {
        //this.stopVideo.apply(null,target);
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  convertRedirect = (value) => {
    const {
      match: { params },
    } = this.props;
    if (get(params, 'type') && !get(params, 'id')) {
      return `/food-order/${get(params, 'type')}/${value}`;
    }
    if (get(params, 'type') && get(params, 'id') && !get(params, 'idChidren')) {
      return `/${value}`;
    }
    if (get(params, 'type') && get(params, 'id') && get(params, 'idChidren')) {
      return `/products/${value}`;
    }
    return `/food-order/${value}`;
  };

  render() {
    const { name, image } = this.props;
    if (this.props.type === 'products') {
      const { name, image, rating } = this.props;
      const { added } = this.state;
      const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
      const ext = image !== null ? image.substr(image.lastIndexOf('.') + 1) : '';
      return (
        <Pane className="col-lg-3 col-md-4 col-sm-6 col-12">
          <Pane className="adds-item">
            <Link to={this.convertRedirect(this.props.item.slug)}>
              {extImage.indexOf(ext) >= 0 ? (
                <Pane
                  className="adds-images"
                  style={{
                    backgroundImage: image
                      ? `url(${image})`
                      : process.env.PUBLIC_URL +
                        `/assets/images/default-image.png`,
                  }}
                >
                  <PromotionLabel
                    promotion={this.props.item && this.props.item.promotion}
                    originalPrice={
                      this.props.item && this.props.item.originalPrice
                    }
                  />
                  {added && (
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/icons/check.svg`
                      }
                      alt="check"
                      className="icon-check"
                    />
                  )}
                </Pane>
              ) : (
                <Pane className="adds-images">
                  {ext !== '' ? <i className="fa fa-video" /> : ''}
                  <video
                    className="carbeyvideo-list"
                    playsInline={1}
                    onMouseOver={this.playVideo}
                    onMouseOut={this.stopVideo}
                    muted={!this.state.play}
                  >
                    <source src={image} id="video_here" />
                    Your browser does not support HTML5 video.
                  </video>
                  <PromotionLabel
                    promotion={this.props.item && this.props.item.promotion}
                    originalPrice={
                      this.props.item && this.props.item.originalPrice
                    }
                  />
                  {added && (
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/icons/check.svg`
                      }
                      alt="check"
                      className="icon-check"
                    />
                  )}
                </Pane>
              )}
            </Link>
            <Pane className="adds-content">
              <Link
                to={
                  this.props.type === 'bussiness'
                    ? this.convertRedirect(this.props.item.slug)
                    : this.convertRedirect(this.props.id)
                }
              >
                <h5 className="adds-norm" title={name}>
                  {name}
                </h5>
              </Link>
              {this.props.type !== 'bussiness' && (
                <ul className="list-star mb-2">
                  <Rating
                    rating={rating}
                    icon="star"
                    size="small"
                    maxRating="5"
                    disabled
                  />
                </ul>
              )}
              {this.renderInfo()}
            </Pane>
          </Pane>
        </Pane>
      );
    }
    if (this.props.type === 'stores') {
      return (
        <Pane className="col-lg-3 col-md-4 col-sm-6 col-12">
          <Pane className="adds-item">
            <Link to={this.convertRedirect(this.props.id)}>
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : `url(${process.env.PUBLIC_URL +
                        `/assets/images/default-image.png`})`,
                }}
              >
                <PromotionLabel
                  promotion={this.props.item && this.props.item.promotion}
                  originalPrice={
                    this.props.item && this.props.item.originalPrice
                  }
                />
              </Pane>
            </Link>
            <Pane className="adds-content">
              <Link to={this.convertRedirect(this.props.id)}>
                <h5 className="adds-norm" title={name}>
                  {name}
                </h5>
              </Link>
            </Pane>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className="col-lg-3 col-md-4 col-sm-6 col-12">
        <Pane className="adds-item">
          <Link to={this.convertRedirect(this.props.id)}>
            <Pane
              className="adds-images"
              style={{
                height: '150px',
                backgroundImage: image
                  ? `url(${image})`
                  : `url(${process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`})`,
              }}
            >
              <PromotionLabel
                promotion={this.props.item && this.props.item.promotion}
                originalPrice={this.props.item && this.props.item.originalPrice}
              />
            </Pane>
          </Link>
          {/* <Pane className="adds-content">
            <Link
              to={
                this.props.type === 'bussiness'
                  ? this.convertRedirect(this.props.item.slug)
                  : this.convertRedirect(this.props.id)
              }
            >
              <h5 className="adds-norm" title={name}>
                {name}
              </h5>
            </Link>
          </Pane> */}
        </Pane>
      </Pane>
    );
  }
}
ListItem.propTypes = {
  auth: PropTypes.object,
  item: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  rating: PropTypes.number,
  intl: intlShape.isRequired,
  onAddItemToCart: PropTypes.func,
  onNotification: PropTypes.object,
  type: PropTypes.string,
};
ListItem.defaultProps = {
  type: '',
};

export default withRouter(injectIntl(ListItem));
