import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { logout } from 'actions/auth';
import { User } from 'components/scenes/user';
import { Pane } from 'shared/components/layouts';
import BussinessList from 'components/common/bussiness/BussinessList';

class UserPage extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    logout: PropTypes.func,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <BussinessList />
          <Pane className="business">
            <User
              userInfo={this.props.userInfo}
              logout={this.props.logout}
              routes={this.props.route.routes}
            />
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  logout: bindActionCreators(logout, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(UserPage);
