import { NowList } from './list';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import ReactPaginate from 'react-paginate';
import { FormattedMessage } from 'react-intl';
import { Pane, Loading } from 'shared/components/layouts';
import { Dimmer, Loader } from 'semantic-ui-react';
import NotificationSystem from 'react-notification-system';
import { getDelivery, getDeliverySchedule } from 'actions/delivery';

class Orders extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loadingNow: false,
      loadingSchedule: false,
    };
    this._notificationSystem = null;
  }

  getDelivery = (params, load) => {
    this.setState({
      loadingNow: true,
    });
    this.props
      .getDelivery(
        {
          ...params,
          id: this.props.userInfo.id,
          type: 'DELIVERY_NOW',
        },
        load
      )
      .then(() => {
        this.setState({
          loadingNow: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingNow: false,
        });
      });
  };

  getDeliverySchedule = (params, load) => {
    this.setState({
      loadingSchedule: true,
    });
    this.props
      .getDeliverySchedule(
        {
          ...params,
          id: this.props.userInfo.id,
          type: 'DELIVERY_SCHEDULE',
        },
        load
      )
      .then(() => {
        this.setState({
          loadingSchedule: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingSchedule: false,
        });
      });
  };

  handleDeliveryNowPageClick = (data) => {
    const params = {
      page: data.selected + 1,
      id: this.props.userInfo.id,
    };

    this.getDelivery(params, true);
  };

  handleDeliverySchedulePageClick = (data) => {
    const params = {
      page: data.selected + 1,
      id: this.props.userInfo.id,
    };

    this.getDeliverySchedule(params, true);
  };

  componentDidMount() {
    const params = {
      page: 1,
    };
    this.getDelivery(params);
    this.getDeliverySchedule(params);
    this._notificationSystem = this.refs.notificationSystem;
  }

  render() {
    const {
      userInfo,
      deliveries,
      deliveriesSchedule,
      location: { pathname },
    } = this.props;
    const { loadingSchedule, loadingNow } = this.state;
    return (
      <Pane className="overview-container">
        <Pane className="product-container order-container">
          <Pane className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="block-title text-uppercase pb-0 align-self-center">
              DELIVERY NOW
            </h3>
            <Link className="btn btn-delivery" to={`${pathname}/create`}>
              Create
            </Link>
          </Pane>
          <Pane className="products-grids" style={{ overflowX: 'auto' }}>
            {loadingNow && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {!loadingNow && (
              <NowList
                userInfo={userInfo}
                deliveries={deliveries}
                notificationSystem={this._notificationSystem}
                getDelivery={this.getDelivery}
                getDeliverySchedule={this.getDeliverySchedule}
              />
            )}
            {!loadingNow &&
              isEmpty(deliveries.results) && (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.you_have_not_had_any_orders_yet"
                      defaultMessage={`You haven't had any orders yet.`}
                    />
                  </p>
                </Pane>
              )}
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {deliveries.totalPages > 1 ? (
                <ReactPaginate
                  previousLabel={
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/pagination/icon-prev.svg`
                      }
                      alt="icon-prev"
                      className="icon icon-prev"
                    />
                  }
                  nextLabel={
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/pagination/icon-next.svg`
                      }
                      alt="icon-prev"
                      className="icon icon-next"
                    />
                  }
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={'break-me'}
                  previousClassName={'page-item'}
                  nextClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  pageCount={deliveries.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleDeliveryNowPageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              ) : null}
            </nav>
          </Pane>
        </Pane>
        <Pane className="product-container order-container">
          <Pane className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="block-title text-uppercase pb-0 align-self-center">
              DELIVERY SCHEDULE
            </h3>
          </Pane>
          <Pane className="products-grids" style={{ overflowX: 'auto' }}>
            {loadingSchedule && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {!loadingSchedule && (
              <NowList
                userInfo={userInfo}
                deliveries={deliveriesSchedule}
                type="DELIVERY_SCHEDULE"
                notificationSystem={this._notificationSystem}
                getDelivery={this.getDelivery}
                getDeliverySchedule={this.getDeliverySchedule}
              />
            )}
            {!loadingSchedule &&
              isEmpty(deliveriesSchedule.results) && (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.you_have_not_had_any_orders_yet"
                      defaultMessage={`You haven't had any orders yet.`}
                    />
                  </p>
                </Pane>
              )}
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {deliveriesSchedule.totalPages > 1 ? (
                <ReactPaginate
                  previousLabel={
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/pagination/icon-prev.svg`
                      }
                      alt="icon-prev"
                      className="icon icon-prev"
                    />
                  }
                  nextLabel={
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/pagination/icon-next.svg`
                      }
                      alt="icon-prev"
                      className="icon icon-next"
                    />
                  }
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={'break-me'}
                  previousClassName={'page-item'}
                  nextClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  pageCount={deliveriesSchedule.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleDeliverySchedulePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              ) : null}
            </nav>
          </Pane>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  deliveries: state.deliveries,
  deliveriesSchedule: state.deliveriesSchedule,
});

const bindDispatchToProps = (dispatch) => ({
  getDelivery: bindActionCreators(getDelivery, dispatch),
  getDeliverySchedule: bindActionCreators(getDeliverySchedule, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(Orders);
