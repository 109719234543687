import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { List } from './list';
import PropTypes from 'prop-types';

class Videos extends Component {
  static propTypes = {
    onChooseVideo: PropTypes.func,
    onReviewsVideo: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
  }

  render() {
    const { allVideos } = this.props;
    return (
      <>
        <Pane className="col-lg-12 col-md-12 p-0">
          {
            allVideos.results.length <= 0 && (
              <Pane className="empty-container">
                <Pane className="empty-content">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/icons/empty.png'
                    }
                    alt="empty"
                    className="img-empty"
                  />
                  <h2 className="empty-title">No items</h2>
                </Pane>
              </Pane>
            )
          }
          {allVideos.results.length ? (
            <List
              data={allVideos.results}
              onChooseVideo={this.props.onChooseVideo}
              onReviewsVideo={this.props.onReviewsVideo}
            />
          ) : (
            <></>
          )}
        </Pane>
      </>
    );
  }
}

const bindStateToProps = () => ({
});

const bindDispatchToProps = () => ({
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(Videos));
