import Config from "constants/Config";
import React, { Component } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import { FacebookShareButton } from "react-share";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";

class Menu extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      tableId: queryString.parse(search).tableId || null,
      MENU_STORE: [
        {
          url: "",
          icon: "overview",
          label: "OVERVIEW",
          language: "overview",
        },
        {
          url: "videos",
          icon: "video-player",
          label: "Our Video",
          language: "our_video",
        },
        {
          url: "promotions",
          icon: "promotion",
          label: "Promotions",
          language: "promotions",
        },
      ]
    };
  }
  
  componentDidMount() {
    let type = this.props.storeInfo.business.type || 'Food'
    type = ['Food', 'Product', 'Service'].includes(type) ? type : 'Food'
    let myMenu = {
      'Food': {
        url: "menu",
        icon: "menu",
        label: "Our Menu",
        language: "our_menu",
      },
      'Product': {
        url: "products",
        icon: "product",
        label: "Our Products",
        language: "our_products",
      },
      'Service': {
        url: "services",
        icon: "service",
        label: "Our Services",
        language: "our_services",
      },
    }[type]
    let newMENU_STORE = this.state.MENU_STORE
    newMENU_STORE.splice(1, 0 ,myMenu)
    // if (type === 'Food') {
      newMENU_STORE.splice(2, 0 ,{
        url: "services",
        icon: "service",
        label: "Our Services",
        language: "our_services",
      })
    // }
    this.setState({
      MENU_STORE: newMENU_STORE
    })
  }

  render() {
    const {
      storeInfo,
      location: { pathname },
      toggleMenu,
    } = this.props;


    const { tableId } = this.state;
    return (
      <Pane
        className={classnames("inner-box inner-overview", {
          "inner-overvier-active": toggleMenu,
        })}
      >
        <Pane className="category-content">
          {storeInfo &&
            storeInfo.business && (
              <ul className="category-list">
                {this.state.MENU_STORE.map((item, index) => {
                  return (
                    <li className="category-item" key={index}>
                      {item.url ? (
                        <Link
                          className={
                            pathname === `/${storeInfo.id}/${item.url}`
                              ? "category-link category-active"
                              : "category-link"
                          }
                          to={
                            tableId
                              ? `/${storeInfo.id}/${item.url}?tableId=${tableId}`
                              : `/${storeInfo.id}/${item.url}`
                          }
                        >
                          <span className={`icon ic-common-${item.icon}`} />
                          <FormattedMessage
                            id={item.language}
                            defaultMessage={item.label}
                          />
                        </Link>
                      ) : (
                        <Link
                          className={
                            pathname === `/${storeInfo.id}`
                              ? "category-link category-active"
                              : "category-link"
                          }
                          to={
                            tableId
                              ? `/${storeInfo.id}?tableId=${tableId}`
                              : `/${storeInfo.id}`
                          }
                        >
                          <span className={`icon ic-common-${item.icon}`} />
                          <FormattedMessage
                            id={item.language}
                            defaultMessage={item.label}
                          />
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
        </Pane>
      </Pane>
    );
  }
}
export default withRouter(Menu);