import { get } from 'lodash';
import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = (data) => {
  if (get(data, 'user_id') && get(data, 'screen_id')) {
    return {
      method: 'GET',
      baseURL: Config.NEW_API_URL,
      url: `/digitals?user_id=${get(data, 'user_id')}&screen_id=${get(data, 'screen_id')}`
    }
  }
  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/digitals`
  }
}

const parseResponse = response => {
  const data = [];
  if (response.data) {
    response.data.forEach((item) => {
      const itemNew = formatJson(response, item.type, item.id);
      data.push(itemNew);
    });
  }
  return data;
}

export { exec, parseResponse }
