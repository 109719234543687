import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Config from "constants/Config";
import ReactTooltip from "react-tooltip";
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Table, Radio, Icon } from 'semantic-ui-react';
import { isEmpty, get } from "lodash";

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      extVideo: Config.extVideo
    };
  }

  changeRoute = () => {
    this.props.history.push(`digital-slideshow/screen/${this.props.id}/edit`);
  };

  showConfirmDelete = () => {
    const { intl } = this.props;

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({
        id: 'notification.are_you_sure_you_want_to_delete_this',
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.deleteItem();
          },
        },
        {
          label: intl.formatMessage({ id: 'no' }),
        },
      ],
    });
  };

  deleteItem = () => {
    this.props.onLoading();
    this.props.deleteMyDigitalShare({ id: this.props.item.id }).then(() => {
      this.props.getMyDigitalShare();
    }).catch(() => {
      this.props.offLoading();
    });
  };

  toggle = () => {
    const { intl, item } = this.props;

    const idMsg = item.status ? 'notification.are_you_sure_you_want_to_decline_this_request' : 'notification.are_you_sure_you_want_to_accept_this_request';

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({
        id: idMsg,
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.onSubmitToggleActive();
          },
        },
        { label: intl.formatMessage({ id: 'no' }), },
      ],
    });
  };

  onSubmitToggleActive = () => {
    const { intl, item } = this.props;

    this.props.onLoading();

    const data = {
      id: item.id,
      status: !item.status,
    };

    this.props
      .editMyDigitalShare(data)
      .then((res) => {
        this.props.getMyDigitalShare();
      })
      .catch((errors) => {
        this.props.offLoading();
      });
  };

  render() {
    const { item, type } = this.props;
    let image = get(item, `image${type}`) ? get(item, `image${type}.url`) : '';
    let isVideo = image && !isEmpty(image) && this.state.extVideo.includes(image.substr(image.lastIndexOf(".") + 1))
    return (
      <Table.Row key={item.id}>
        <Table.Cell>{item.screenId}</Table.Cell>
        <Table.Cell>{item.user.fullname}
          <Icon
            name="info circle"
            data-tip
            data-for={`item-info${item.id}`}
          />
          <ReactTooltip
            place="top"
            type="dark"
            effect="float"
            id={`item-info${item.id}`}
          >
            {item.user.email} - {`${item.user.address} ${item.user.address && item.user.postcode
              ? `, ${item.user.postcode}`
              : item.user.postcode
              }`}
          </ReactTooltip>
        </Table.Cell>
        <Table.Cell>
          {isVideo ? (
            <video controls playsInline allowFullScreen={false} width="100%" height="100">
              <source src={image} id="video_here" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          ) : (
            <img src={image} alt="" />
          )}
        </Table.Cell>
        <Table.Cell>
          <Radio
            toggle
            onChange={this.toggle}
            checked={item.status}
          />
        </Table.Cell>
        <Table.Cell>
          <i className="icon-contact icon-trash" onClick={() => { this.showConfirmDelete() }}></i>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default withRouter(injectIntl(ListItem));
