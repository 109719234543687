import * as types from "constants/ActionTypes";
import { Cookies } from "react-cookie";
import api from "api";
import AuthAdapter from "services/auth/adapter";
import { addItemToCart } from "./cart";
import { formatJson } from "shared/helpers";

const cookies = new Cookies();

// Signin
export const requestToken = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_LOGIN,
      types.RECEIVE_LOGIN_SUCCESS,
      types.RECEIVE_LOGIN_FAILURE,
    ],
    payload: {
      request: api.user.login(params),
    },
  });

export const socicalRequestToken = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_LOGIN,
      types.RECEIVE_LOGIN_SUCCESS,
      types.RECEIVE_LOGIN_FAILURE,
    ],
    payload: {
      request: api.user.socialLogin(params),
    },
  });

export const createUser = (params) => (dispatch) =>
  dispatch({
    type: types.CREATE_NEW_USER,
    payload: {
      request: api.user.signup(params),
    },
  });

export const requestUserInfo = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_USER_INFO,
      types.RECEIVE_USER_INFO_SUCCESS,
      types.RECEIVE_USER_INFO_FAILURE,
    ],
    payload: {
      request: api.user.getInfo(params),
    },
  });

export const getUserInfo = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestUserInfo({ userId: params.userId }))
      .then((response) => {
        const userData = response.data;

        const user = formatJson(userData, userData.data.type, userData.data.id);

        if (user.cart && user.cart.uuid) {
          localStorage.setItem("cartUUID", user.cart.uuid);
        }

        resolve(response);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
};

export const signin = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestToken(params))
      .then((response) => {
        const data = AuthAdapter.login.parseResponse(response.data);
        cookies.set("accessToken", data.accessToken, { path: "/" });
        cookies.set("refreshToken", data.refreshToken, { path: "/" });

        dispatch(getUserInfo({ userId: data.userId }))
          .then((userResponse) => {
            dispatch(mergeCart());

            resolve(userResponse);
          })
          .catch((errors) => {
            reject(errors);
          });
      })
      .catch((errors) => {
        reject(errors);
      });
  });
};

export const signinSocial = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(socicalRequestToken(params))
      .then((response) => {
        const data = AuthAdapter.socialLogin.parseResponse(response.data);
        cookies.set("accessToken", data.accessToken, { path: "/" });
        cookies.set("refreshToken", data.refreshToken, { path: "/" });

        dispatch(getUserInfo({ userId: data.userId }))
          .then((userResponse) => {
            dispatch(mergeCart());

            resolve(userResponse);
          })
          .catch((errors) => {
            reject(errors);
          });
      })
      .catch((errors) => {
        reject(errors);
      });
  });
};

export const mergeCart = () => (dispatch, getState) => {
  const cart = getState().cart;

  if (cart && cart.items && cart.items.length > 0) {
    cart.items.forEach((item) => {
      dispatch(
        addItemToCart({
          product_id: item.productId,
          delivery_method: item.deliveryMethod,
          quantity: item.quantity,
        })
      );
    });
  }
};

// Signup
export const signup = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(createUser(params))
      .then((response) => {
        resolve(response);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
};

// Logout
export const logoutSuccess = () => (dispatch) =>
  dispatch({
    type: types.LOGOUT,
  });

export const logout = () => (dispatch) => {
  cookies.remove("userInfo", { path: "/" });
  cookies.remove("accessToken", { path: "/" });
  cookies.remove("refreshToken", { path: "/" });

  localStorage.removeItem("cartUUID");
  localStorage.removeItem("cart");
  localStorage.removeItem("deliveryInfo");

  dispatch(logoutSuccess());
  dispatch({ type: types.CLEAR_DATA });
};

// subscribe
export const subScribe = (params) => (dispatch) =>
  dispatch({
    type: types.REQUEST_SUBSCRIBE,
    payload: {
      request: api.user.subscribe(params),
    },
  });
