import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Placeholder, Segment } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import NotificationSystem from 'react-notification-system';
import queryString from 'query-string';
import { Pane } from 'shared/components/layouts';
import { List } from '../list';
import { deleteMyProductPicks } from 'actions/myProducts';
import { getProductPicksByUserId } from 'actions/myProductPicks';
import { formatRoute, handleError } from 'shared/helpers';
import { isEmpty } from 'lodash';

class MyProductPicks extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    productPicks: PropTypes.object,
    getProductPicksByUserId: PropTypes.func,
    deleteMyProduct: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    page: parseInt(queryString.parse(this.props.location.search).page, 10) || 1,
    fetching: false,
    fetched: false,
    selected: '',
    keySort: null,
    modeView: 'gridView',
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  getProductPicksByUserId = (params) => {
    const { userInfo } = this.props;
    this.props
      .getProductPicksByUserId(
        {
          id: userInfo.id,
          group: params.group,
          size: 1000,
          images: this.state.modeView === 'gridView' ? '' : null,
        },
        params.load
      )
      .then(() => {
        this.onSetDefaultView(this.props.storeInfo);
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  };

  onSetDefaultView = (storeInfo) => {
    if (!isEmpty(storeInfo)) {
      this.setState({
        modeView: storeInfo.business.isGridView === 1 ? 'gridView' : 'listView',
      });
    }
  };

  deleteItem = (params) => {
    const { intl } = this.props;

    if (this.props.productPicks.results.length === 1) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }

    this.props
      .deleteMyProductPicks(params)
      .then(() => {
        this.getProductPicksByUserId({ page: this.state.page });
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });
      });
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getProductPicksByUserId({ load: true });
    });

    this.getProductPicksByUserId({ load: true });

    this._notificationSystem = this.refs.notificationSystem;
  }

  onSelect = (key) => {
    this.setState({
      keySort: key,
    });
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === 'gridView' ? 'listView' : 'gridView',
      keySort: null,
    }));
  };

  render() {
    const { productPicks, userInfo } = this.props;
    const { fetched, fetching } = this.state;
    return (
      <Pane className="overview-container">
        <Pane className="product-container">
          <div className="product-heading">
            <h3
              className="block-title pt10 text-uppercase"
              style={{ marginRight: 'auto' }}
            >
              <FormattedMessage id="my_catalog" defaultMessage="Catalog" />
            </h3>
            <Link
              to={`/u/${formatRoute(
                userInfo.displayName
              )}/product-picks/create`}
              className="btn btn-circle btn-circle-primary"
            >
              <FormattedMessage
                id="create_new_product"
                defaultMessage="Create a new product"
              />
            </Link>
            <Pane className="view d-flex justify-content-end mt-2">
              <span className="list-view" onClick={this.onChangeModeView}>
                <img
                  src={
                    process.env.PUBLIC_URL + this.state.modeView === 'gridView'
                      ? '/assets/images/icons/list-view.svg'
                      : '/assets/images/icons/grid-view.svg'
                  }
                  className="image-logo"
                  alt="list-view"
                />
              </span>
            </Pane>
          </div>
          {!fetching && fetched ? (
            <>
              {productPicks.results.length ? (
                <List
                  data={productPicks.results}
                  type="amazon"
                  control={true}
                  modeView={this.state.modeView}
                  onDelete={this.deleteItem}
                />
              ) : (
                <Pane className="empty-container">
                  <Pane className="empty-content">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/icons/empty.png'
                      }
                      alt="empty"
                      className="img-empty"
                    />
                    <h2 className="empty-title">No items</h2>
                  </Pane>
                </Pane>
              )}
            </>
          ) : (
            <Pane className="loading-adds-wrapper loading-white">
              <Pane className="container">
                <Pane className="row">
                  <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                    <Segment raised>
                      <Placeholder>
                        <Placeholder.Header className="mb-3">
                          <Placeholder.Image rectangular className="mb-3" />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                          <Placeholder.Line length="medium" />
                          <Placeholder.Line length="short" />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </Segment>
                  </Pane>
                  <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                    <Placeholder>
                      <Placeholder.Header className="mb-3">
                        <Placeholder.Image rectangular className="mb-3" />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                        <Placeholder.Line length="short" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Pane>
                  <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                    <Placeholder>
                      <Placeholder.Header className="mb-3">
                        <Placeholder.Image rectangular className="mb-3" />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                        <Placeholder.Line length="short" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Pane>
                  <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                    <Placeholder>
                      <Placeholder.Header className="mb-3">
                        <Placeholder.Image rectangular className="mb-3" />
                      </Placeholder.Header>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                        <Placeholder.Line length="short" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  productPicks: state.myProductPicks,
});

const bindDispatchToProps = (dispatch) => ({
  getProductPicksByUserId: bindActionCreators(
    getProductPicksByUserId,
    dispatch
  ),
  deleteMyProductPicks: bindActionCreators(deleteMyProductPicks, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(MyProductPicks))
);
