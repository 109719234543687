import moment from "moment";
import { isEmpty, get } from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PaymentForm from "./PaymentForm";
import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { Pane } from "shared/components/layouts";
import { formatRoute, handleError } from "shared/helpers";
import NotificationSystem from "react-notification-system";
import InlineMessage from "shared/components/InlineMessage";
import { Button, Form, Image, Icon } from "semantic-ui-react";
import { StripeProvider, Elements } from "react-stripe-elements";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import Datetime from "react-datetime";
import PlacesAutocomplete from "react-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import imageCompression from "browser-image-compression";

class DeliveryServiceForm extends Component {
  static propTypes = {
    products: PropTypes.object,
    intl: intlShape.isRequired,
    createDelivery: PropTypes.func.isRequired,
  };

  state = {
    images: [],
    imageFiles: [],
    data: {
      address: "",
      receiverName: "",
      receiverPhone: "",
      receiverEmail: "",
      receiverAddress: "",
    },
    type: "",
    errors: {},
    open: false,
    startDate: "",
    categories: [],
    loading: false,
    pickupTime: "",
    expectDeliveryTime: "",
    pointer: "",
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  onChangeAddress = (receiverAddress) => {
    this.setState({
      data: { ...this.state.data, receiverAddress },
    });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      errors: {},
      type: "",
    });
  };

  onChangeTime = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onChangePhone = (receiverPhone) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        receiverPhone,
      },
    }));
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  onChangeImage = async (e) => {
    const files = e.target.files;
    const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (files) {
      Object.keys(files).forEach(async (key, index) => {
        if (
          index < 6 &&
          this.state.images.length < 6 &&
          this.state.images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = await imageCompression(files[index], options);
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images.push(reader.result);
            imageFiles.push(value);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;
    const { imageFiles } = this.state;
    if (!data.receiverName) {
      errors.receiverName = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (!data.receiverAddress) {
      errors.receiverAddress = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (!data.receiverEmail) {
      errors.receiverEmail = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (isEmpty(imageFiles)) {
      errors.images = intl.formatMessage({
        id: "validate.require_field",
      });
    }

    return errors;
  };

  validateSubmit = (data) => {
    const errors = {};
    const { intl } = this.props;
    const { imageFiles, pickupTime, expectDeliveryTime } = this.state;
    if (!data.receiverName) {
      errors.receiverName = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (!data.receiverAddress) {
      errors.receiverAddress = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (!data.receiverPhone) {
      errors.receiverPhone = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (!data.receiverEmail) {
      errors.receiverEmail = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (isEmpty(imageFiles)) {
      errors.images = intl.formatMessage({
        id: "validate.require_field",
      });
    }

    if (!pickupTime) {
      errors.pickupTime = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (moment(pickupTime).diff(moment(), "minutes") <= 0) {
      errors.pickupTime = intl.formatMessage({
        id: "validate.date_pickup_now",
      });
    }
    if (!expectDeliveryTime) {
      errors.expectDeliveryTime = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (moment(expectDeliveryTime).diff(moment(), "minutes") <= 0) {
      errors.expectDeliveryTime = intl.formatMessage({
        id: "validate.date_pickup_now",
      });
    }
    if (moment(expectDeliveryTime).diff(moment(pickupTime), "minutes") < 50) {
      errors.expectDeliveryTime = intl.formatMessage({
        id: "validate.date_expect_delivery",
      });
    }

    return errors;
  };

  deliveryNow = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        open: true,
        type: "DELIVERY_NOW",
      });
    }
  };

  onSave = async () => {
    const data = new FormData();
    data.append("receiver_name", this.state.data.receiverName);
    data.append("receiver_address", this.state.data.receiverAddress);
    data.append("receiver_phone", this.state.data.receiverPhone);
    data.append("type", this.state.type);
    if (this.state.type === "DELIVERY_SCHEDULE") {
      data.append(
        "pickup_time",
        moment(this.state.pickupTime)
          .utc()
          .unix()
      );
      data.append(
        "expect_delivery_time",
        moment(this.state.expectDeliveryTime)
          .utc()
          .unix()
      );
    }
    data.append("receiver_email", this.state.data.receiverEmail);
    this.state.imageFiles.forEach((item, index) => {
      data.append(`image_${index + 1}`, this.state.imageFiles[index] || "");
    });
    const { intl } = this.props;
    try {
      const { token } = await this.requestPaymentToken();
      if (token && token.id) {
        data.append("token_stripe", token.id);
        this.setState({ loading: true });
        this.props
          .createDelivery(data)
          .then(() => {
            this.props.history.push(
              `/u/${formatRoute(
                this.props.userInfo.displayName
              )}/delivery-service`
            );
          })
          .catch((error) => {
            const errors = {};
            errors.message = handleError(
              error,
              this.props.intl.formatMessage({
                id: "notification.please_try_again",
              })
            );
            this.setState({
              loading: false,
              errors,
              pointer:
                get(error, "response.data.errors[0].source.pointer") || "",
            });
          });
      } else {
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: "notification.please_try_again" }),
          level: "error",
        });
      }
    } catch (e) {
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: "notification.please_try_again" }),
        level: "error",
      });
    }
  };

  onSubmit = () => {
    const errors = this.validateSubmit(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        open: true,
        type: "DELIVERY_SCHEDULE",
      });
    }
  };

  renderErrorProfile = () => {
    const { errors, pointer } = this.state;
    const { userInfo } = this.props;
    const name =
      userInfo && userInfo.displayName
        ? userInfo.displayName.replace(/ /g, "_").toLowerCase()
        : "";
    if (pointer && pointer.search("sender") > -1) {
      return (
        <Link
          to={`/u/${name}/settings`}
          style={{ cursor: "pointer" }}
          className="notice"
        >
          Please follow the link to update your: {errors.message} to finish the
          order. Thank your
        </Link>
      );
    }
    return <InlineMessage text={errors.message} />;
  };

  render() {
    const { errors, data, loading, images, type } = this.state;
    const { intl } = this.props;
    const renderFunc = ({
      getInputProps,
      getSuggestionItemProps,
      suggestions,
    }) => (
      <Pane>
        <input
          {...getInputProps()}
          className="form-input"
          placeholder={intl.formatMessage({ id: "address" })}
        />
        <Pane className="autocomplete-dropdown-container">
          {suggestions.map((suggestion, index) => (
            <Pane {...getSuggestionItemProps(suggestion)} key={index}>
              <span>{suggestion.description}</span>
            </Pane>
          ))}
        </Pane>
      </Pane>
    );
    const yesterday = Datetime.moment().subtract(1, "day");
    const valid = (current) => {
      return current.isAfter(yesterday);
    };
    return (
      <Form className="form-overview pb-5" onSubmit={this.deliveryNow}>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          center
          showCloseIcon={false}
        >
          <Pane className="overview-modal">
            <h1 className="title">Delivery review</h1>
            <div className="list-item">
              <div className="item">
                <p className="norm">Images: </p>

                <div className="list-image">
                  {images &&
                    images.map((item, index) => (
                      <div className="item-image" key={index}>
                        <img src={item} alt="mission" className="img" />
                      </div>
                    ))}
                </div>
              </div>
              <div className="item">
                <p className="norm">
                  Deliver to: <span>{data.receiverName}</span>
                </p>
              </div>
              <div className="item">
                <p className="norm">
                  Delivery address: <span>{data.receiverAddress}</span>
                </p>
              </div>
              <div className="item">
                <p className="norm">
                  Recipient's email: <span>{data.receiverEmail}</span>
                </p>
              </div>
              <div className="item">
                <p className="norm">
                  Recipient's phone number: <span>+{data.receiverPhone}</span>
                </p>
              </div>
              {type === "DELIVERY_SCHEDULE" && (
                <>
                  <div className="item">
                    <p className="norm">
                      Pickup Date & Time:{" "}
                      <span>
                        {moment(this.state.pickupTime).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="item">
                    <p className="norm">
                      Delivery Date & Time:{" "}
                      <span>
                        {moment(this.state.expectDeliveryTime).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </span>
                    </p>
                  </div>
                </>
              )}
              <div className="item">
                <p className="norm">
                  Delivery free: <span>$5.00</span>
                </p>
              </div>
              <hr />
              <div className="item">
                <h2 className="checkout-title">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/cart/payment.svg`
                    }
                    alt="users"
                    className="icon"
                  />
                  <FormattedMessage
                    id="payment_information"
                    defaultMessage="Payment information"
                  />
                </h2>
                <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                  <Elements>
                    <PaymentForm
                      requestPaymentToken={(request) =>
                        (this.requestPaymentToken = request)
                      }
                    />
                  </Elements>
                </StripeProvider>
              </div>
              <hr />
              <div className="item">{this.renderErrorProfile()}</div>
            </div>
            <p />
            <Pane className="overview-modal-footer">
              <Button
                type="button"
                className="btn btn-ok"
                disabled={loading}
                loading={loading}
                onClick={this.onSave}
              >
                Confirm
              </Button>
              <Button
                className="btn"
                onClick={this.onCloseModal}
                disabled={loading}
                loading={loading}
              >
                Cancel
              </Button>
            </Pane>
          </Pane>
        </Modal>
        <Pane className="form-content">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.images} className="form-item">
                <label
                  htmlFor="Image"
                  className="form-label form-label-required"
                >
                  Product Images
                </label>
                <input
                  type="file"
                  id="image-menu"
                  className="form-input-file"
                  multiple
                  value={data.image}
                  onChange={this.onChangeImage}
                />
                <br />
                <label htmlFor="image-menu" className="form-label-file">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/upload.svg`
                    }
                    alt="upload"
                    className="icon"
                  />
                  Upload a photo
                </label>
                <Pane
                  className="slider-content-main"
                  style={{ padding: "10px 0" }}
                >
                  {this.state.images &&
                    this.state.images.map((item, index) => (
                      <Pane
                        className="slide-item"
                        key={index}
                        style={{ float: "left" }}
                      >
                        <Icon
                          name="remove circle"
                          onClick={this.removeImage.bind(this, index)}
                        />
                        <Image src={item} size="small" />
                      </Pane>
                    ))}
                </Pane>
                {errors.images && <InlineMessage text={errors.images} />}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.receiverName} className="form-item">
                <label
                  htmlFor="receiverName"
                  className="form-label form-label-required"
                >
                  Deliver to
                </label>
                <input
                  type="text"
                  id="receiverName"
                  name="receiverName"
                  placeholder="Buyer name"
                  className="form-input"
                  value={data.receiverName}
                  onChange={this.onChange}
                />
                {errors.receiverName && (
                  <InlineMessage text={errors.receiverName} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field
                error={!!errors.receiverAddress}
                className="form-item"
              >
                <label
                  htmlFor="receiverAddress"
                  className="form-label form-label-required"
                >
                  Delivery address
                </label>
                <PlacesAutocomplete
                  value={data.receiverAddress}
                  onChange={this.onChangeAddress}
                  searchOptions={{
                    componentRestrictions: { country: "au" },
                  }}
                >
                  {renderFunc}
                </PlacesAutocomplete>
                {errors.receiverAddress && (
                  <InlineMessage text={errors.receiverAddress} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.receiverPhone} className="form-item">
                <label
                  htmlFor="receiverPhone"
                  className="form-label form-label-required"
                >
                  Recipient's phone number
                </label>
                <PhoneInput
                  country={"au"}
                  value={data.receiverPhone}
                  onChange={(phone) => this.onChangePhone(phone)}
                  style={{ width: "100%" }}
                />
                {errors.receiverPhone && (
                  <InlineMessage text={errors.receiverPhone} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.receiverEmail} className="form-item">
                <label
                  htmlFor="receiverEmail"
                  className="form-label form-label-required"
                >
                  Recipient's email
                </label>
                <input
                  type="text"
                  id="receiverEmail"
                  name="receiverEmail"
                  placeholder="Email"
                  className="form-input"
                  value={data.receiverEmail}
                  onChange={this.onChange}
                />
                {errors.receiverEmail && (
                  <InlineMessage text={errors.receiverEmail} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row mt-2">
            <Pane className="col-lg-12">
              <p className="description">
                Delivery fee: $5.00
                <span className="notice">
                  This flat rate delivery fee is just only applied for item
                  under 5kg, to be delivered within 10km
                </span>
              </p>
            </Pane>
          </Pane>
          <Form.Field className="form-item">
            {errors.message && (
              <InlineMessage text={errors.message} className="col-md-8" />
            )}
          </Form.Field>
          <Pane className="row">
            <Pane className="col-lg-12">
              {errors.message && <InlineMessage text={errors.message} />}
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12 text-center">
              <Button
                type="submit"
                className="btn btn-save-yellow mt-4"
                disabled={loading}
                loading={loading}
                onClick={this.deliveryNow}
              >
                Deliver Now
              </Button>
            </Pane>
          </Pane>
          <hr className="dot-bottom" />
          <Pane className="row">
            <Pane className="col-lg-12">
              <h3 className="heading-norm mt-2">Schedule delivery</h3>
            </Pane>
            <Pane className="col-lg-12">
              <Pane className="group-pickup">
                <div className="item">
                  <Form.Field error={!!errors.pickupTime} className="form-item">
                    <div className="item-header">
                      <label
                        htmlFor="deliveryTime"
                        className="form-label form-label-required"
                      >
                        Pickup Date & Time
                      </label>
                    </div>
                    <Datetime
                      showToday={true}
                      closeOnSelect={true}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      onChange={(event) =>
                        this.onChangeTime(event, "pickupTime")
                      }
                      inputProps={{
                        placeholder: "DD/MM/YYYY HH:mm",
                        name: "pickupTime",
                      }}
                      isValidDate={valid}
                      value={this.state.pickupTime}
                    />
                    {errors.pickupTime && (
                      <InlineMessage text={errors.pickupTime} />
                    )}
                  </Form.Field>
                </div>
                <div className="item">
                  <Form.Field
                    error={!!errors.expectDeliveryTime}
                    className="form-item"
                  >
                    <div className="item-header">
                      <label
                        htmlFor="deliveryTime"
                        className="form-label form-label-required"
                      >
                        Expect Delivery Date & Time
                      </label>
                    </div>
                    <Datetime
                      showToday={true}
                      closeOnSelect={true}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      onChange={(event) =>
                        this.onChangeTime(event, "expectDeliveryTime")
                      }
                      inputProps={{
                        placeholder: "DD/MM/YYYY HH:mm",
                        name: "expectDeliveryTime",
                      }}
                      isValidDate={valid}
                      value={this.state.expectDeliveryTime}
                    />
                    {errors.expectDeliveryTime && (
                      <InlineMessage text={errors.expectDeliveryTime} />
                    )}
                  </Form.Field>
                </div>
              </Pane>
            </Pane>
            <Pane className="col-lg-12 text-center">
              <Button
                type="button"
                className="btn btn-save-yellow mt-4"
                disabled={loading}
                loading={loading}
                onClick={this.onSubmit}
              >
                Submit
              </Button>
            </Pane>
          </Pane>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Form>
    );
  }
}

export default injectIntl(DeliveryServiceForm);
