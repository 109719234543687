import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';

class Detail extends Component {
  render() {
    const { detail } = this.props;
    return (
      <Pane className="tab-details">
        <h2 className="tab-title">Details</h2>
        <Pane
          dangerouslySetInnerHTML={{
            __html: detail,
          }}
        />
      </Pane>
    );
  }
}

Detail.propTypes = {};

export default Detail;
