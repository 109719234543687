import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Pane } from 'shared/components/layouts';

class ErrorPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };

  render() {
    return (
      <Pane className="notice-container">
        <Pane className="notice-content">
          <h3 className="notice-title">Hi there...</h3>
          <p className="notice-norm">We are under reconstruction.</p>
          <p className="notice-norm">See you soon. Thank you.</p>
        </Pane>
        <img
          src={process.env.PUBLIC_URL + '/assets/images/logo-black.png'}
          className="notice-logo"
          alt="caterwin logo"
        />
      </Pane>
    );
  }
}

export default injectIntl(ErrorPage);
