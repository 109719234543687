import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  return {
    method: 'GET',
    url: `/${params.hash ? `slide-screens` : `screens`}/${params.hash ?  params.hash : params.screen_id}/digital-share?admin_active_flg=1${params.status ? '&status=1' : ''}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = (response) => {
  let digitals_share = []

  if (response.data) {
    response.data.forEach(item => {
      const screen_share = formatJson(response, item.type, item.id)

      digitals_share.push(screen_share)
    })
  }

  return digitals_share;
};

export { exec, parseResponse }
