import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { Comment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Config from 'constants/Config';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button, Rating, Form } from 'semantic-ui-react';

class Reviews extends Component {
  static propTypes = {
    onReviewService: PropTypes.func,
    onGetServiceReview: PropTypes.func,
    onGetServiceDetail: PropTypes.func,
    intl: intlShape.isRequired,
    onNotification: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      detail: {},
      reviews: [],
      added: false,
      adding: false,
      rating: 0,
      comment: '',
      loading: false,
      pageLoading: false,
      open: false,
    };
  }

  getDetail = () => {
    this.props.onGetServiceDetail({ id: this.props.id });

    this.props.onGetServiceDetail({
      id: this.props.id,
      type: Config.SERVICE_TYPE,
    });
  };
  onSubmit = () => {
    const { intl } = this.props;
    if (this.state.rating === 0) {
      this.props.onNotification.addNotification({
        message: intl.formatMessage({
          id: 'notification.please_choose_rating',
        }),
        level: 'error',
      });
    } else {
      const data = {
        id: this.props.id,
        comment: this.state.comment,
        rating: this.state.rating,
      };

      this.setState({ loading: true });
      this.props
        .onReviewService(data)
        .then(() => {
          this.setState({
            loading: false,
            comment: '',
            rating: 0,
          });
          this.getDetail();

          this.props.onNotification.addNotification({
            message: intl.formatMessage({
              id: 'notification.thank_for_review',
            }),
            level: 'success',
          });
        })
        .catch(() => {
          this.setState({ loading: false });
          this.props.onNotification.addNotification({
            message: intl.formatMessage({
              id: 'notification.please_login_to_make_comments',
            }),
            level: 'error',
          });
        });
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onRate = (e, { rating }) => {
    this.setState({ rating });
  };

  render() {
    const { reviews, rating } = this.props;
    return (
      <Pane className="tab-details">
        <h2 className="tab-title">WRITE YOUR OWN REVIEW</h2>
        <Rating
          rating={rating}
          icon="star"
          maxRating="5"
          size="large"
          onRate={this.onRate}
        />
        <Form onSubmit={this.onSubmit} className="mt-2">
          <Pane className="comment-form">
            <textarea
              placeholder=""
              name="comment"
              className="form-input"
              value={this.state.comment}
              onChange={this.onChange}
              style={{ height: 150 }}
            />
          </Pane>
          <Pane className="comment-form">
            <Button
              color="orange"
              size="tiny"
              disabled={this.state.loading}
              loading={this.state.loading}
              className="button btn-comment mt-1"
            >
              <FormattedMessage id="send" defaultMessage="Send" />
            </Button>
          </Pane>
        </Form>
        <Pane className="list-comment mt-4">
          {reviews && reviews.results.length > 0 ? (
            <Comment.Group>
              {reviews.results.map((item) => {
                return (
                  <Pane className="comment-item">
                    <Pane className="comment-avatar">
                      <img
                        src={item.user.profileImage}
                        alt="ava1"
                        className="comment-img"
                      />
                    </Pane>
                    <Pane className="comment-content">
                      <h2 className="comment-name">{item.user.name}</h2>
                      <Rating
                        rating={item.stars}
                        disabled
                        icon="star"
                        maxRating="5"
                        size="small"
                      />
                      <p className="comment-norm">{item.comment}</p>
                    </Pane>
                  </Pane>
                );
              })}
            </Comment.Group>
          ) : (
            <Pane className="block-no-content">
              <p>
                <FormattedMessage
                  id="notification.no_comments"
                  defaultMessage="No comments"
                />
              </p>
            </Pane>
          )}
        </Pane>
      </Pane>
    );
  }
}

Reviews.propTypes = {};

export default withRouter(injectIntl(Reviews));
