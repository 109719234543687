// Buyers
import * as OrderBuyerBrowseV1 from './buyers/order-buyer.browse.v1.action'
import * as OrderBuyerReadV1 from './buyers/order-buyer.read.v1.action'
import * as OrderBuyerRequestCancelV1 from './buyers/order-buyer.request-cancel.v1.action'
// Sellers
import * as OrderSellerBrowseV1 from './sellers/order-seller.browse.v1.action'
import * as OrderSellerReadV1 from './sellers/order-seller.read.v1.action'
import * as OrderSellerAcceptCancelV1 from './sellers/order-seller.accept-cancel.v1.action'
import * as OrderSellerCancelV1 from './sellers/order-seller.cancel.v1.action'
import * as OrderSellerDenyCancelV1 from './sellers/order-seller.deny-cancel.v1.action'
import * as OrderSellerChangeStatusV1 from './sellers/order-seller.change-status.v1.action'
import * as OrderBookingBrowseV1 from './dashboard/orders-dashboard.read.action'

export default {
  buyers: {
    // Get list of buyer's orders
    browse: {
      callAPI: params => OrderBuyerBrowseV1.exec(params),
      parseResponse: response => OrderBuyerBrowseV1.parseResponse(response)
    },
    // Get buyer's order detail
    read: {
      callAPI: params => OrderBuyerReadV1.exec(params),
      parseResponse: response => OrderBuyerReadV1.parseResponse(response)
    },
    // Buyers - Request cancel an order
    requestCancel: {
      callAPI: params => OrderBuyerRequestCancelV1.exec(params),
      parseResponse: response => OrderBuyerRequestCancelV1.parseResponse(response)
    }
  },
  sellers: {
    // Get list of seller's orders
    browse: {
      callAPI: params => OrderSellerBrowseV1.exec(params),
      parseResponse: response => OrderSellerBrowseV1.parseResponse(response)
    },
    // Get seller's order detail
    read: {
      callAPI: params => OrderSellerReadV1.exec(params),
      parseResponse: response => OrderSellerReadV1.parseResponse(response)
    },

    cancel: {
      callAPI: params => OrderSellerCancelV1.exec(params),
      parseResponse: response => OrderSellerCancelV1.parseResponse(response)
    },
    // Sellers - Accept cancel an order
    acceptCancel: {
      callAPI: params => OrderSellerAcceptCancelV1.exec(params),
      parseResponse: response => OrderSellerAcceptCancelV1.parseResponse(response)
    },
    // Sellers - Deny cancel an order
    denyCancel: {
      callAPI: params => OrderSellerDenyCancelV1.exec(params),
      parseResponse: response => OrderSellerDenyCancelV1.parseResponse(response)
    },
    // Sellers - Change status an order
    changeStatus: {
      callAPI: params => OrderSellerChangeStatusV1.exec(params),
      parseResponse: response => OrderSellerChangeStatusV1.parseResponse(response)
    }
  },
  dashboard: {
    browse: {
      callAPI: params => OrderBookingBrowseV1.exec(params),
      parseResponse: response => OrderBookingBrowseV1.parseResponse(response)
    },
  }
}
