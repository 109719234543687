import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { N_Category } from 'shared/components';
import { City } from 'shared/components';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';

class N_InderBox extends Component {
  render() {
    const {
      type,
      city,
      cate,
      title,
      toggle,
      category,
      cities,
      className,
      nameToggle,
      match: { url },
    } = this.props;
    
    return (
      <Pane className={classnames('inner-box', className)}>
        <h2
          className="inner-title"
          onClick={() => this.props.onOpenToggle(nameToggle)}
        >
          <FormattedMessage id={title} defaultMessage={title} />
          <span className="dropdown-inner">
            <img
              src={
                process.env.PUBLIC_URL + '/assets/images/icons/arrow-inner.svg'
              }
              alt="arrow"
              className={`icon ${toggle ? 'icon-open' : ''}`}
            />
          </span>
        </h2>
        <Pane
          className={`inner-responsive ${
            toggle ? 'inner-responsive-open' : ''
          }`}
        >
            {(type === 'products') && (
                <Pane className="categories-list list-filter">
                    {!isEmpty(category) && (
                        <N_Category cate={cate} categories={category} onChangeCate={this.props.onChangeCate} />
                    )}
                </Pane>
            )}
            {(type === 'city') && (
                <Pane className="categories-list list-filter">
                    {!isEmpty(cities) && (
                        <City city={city} cities={cities} onFilterArea={this.props.onFilterArea} />
                    )}
                </Pane>
            )}
        </Pane>
      </Pane>
    );
  }
}

N_InderBox.propTypes = {};

export default withRouter(N_InderBox);