import api from 'api';
import * as types from 'constants/ActionTypes';

// Create a delivery
export const createDelivery = (params) => (dispatch) =>
  dispatch({
    type: types.CREATE_DELIVERY,
    payload: {
      request: api.delivery.createDelivery(params),
    },
  });

// Cancel Request a delivery
export const cancelRequestDelivery = (params) => (dispatch) =>
  dispatch({
    type: types.REQUEST_CANCEL_DELIVERY,
    payload: {
      request: api.delivery.request(params),
    },
  });

export const getDelivery = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_DELIVERY,
      types.RECEIVE_DELIVERY_SUCCESS,
      types.RECEIVE_DELIVERY_FAILURE,
    ],
    payload: {
      request: api.delivery.get(params),
    },
  });

export const getDeliverySchedule = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_DELIVERY_SCHEDULE,
      types.RECEIVE_DELIVERY_SCHEDULE_SUCCESS,
      types.RECEIVE_DELIVERY_SCHEDULE_FAILURE,
    ],
    payload: {
      request: api.delivery.get(params),
    },
  });

export const getDeliveryDetail = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_DELIVERY_DETAIL,
      types.RECEIVE_DELIVERY_DETAIL_SUCCESS,
      types.RECEIVE_DELIVERY_DETAIL_FAILURE,
    ],
    payload: {
      request: api.delivery.detail(params),
    },
  });
