import * as PromotionsBrowseV1 from './promotions.browse.v1.action'
import * as PromotionsBrowseSlideV1 from './promotions.browseslide.v1.action'

export default {
  // get all products
  browse: {
    callAPI: params => PromotionsBrowseV1.exec(params),
    parseResponse: response => PromotionsBrowseV1.parseResponse(response)
  },
  browseslide: {
    callAPI: params => PromotionsBrowseSlideV1.exec(params),
    parseResponse: response => PromotionsBrowseSlideV1.parseResponse(response)
  },
}
