import * as types from 'constants/ActionTypes'
import api from 'api'

const removeAllCartSuccess = () => ({
  type: types.REMOVE_ALL_CART_SUCCESS
})

export const requestShoppingCart = () => (dispatch) => dispatch({
  types: [types.REQUEST_SHOPPING_CART, types.RECEIVE_SHOPPING_CART_SUCCESS, types.RECEIVE_SHOPPING_CART_FAILURE],
  payload: {
    request: api.cart.get({ uuid: localStorage.getItem('cartUUID') })
  }
})

export const createUserCart = () => (dispatch) => dispatch({
  type: types.CREATE_USER_CART,
  payload: {
    request: api.cart.create()
  }
})

export const addItemToCart = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem('cartUUID')) {
      params.uuid = localStorage.getItem('cartUUID')

      dispatch({
        type: types.ADD_TO_CART,
        payload: {
          request: api.cart.add(params)
        }
      }).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    } else {
      dispatch(createUserCart()).then(response => {
        localStorage.setItem('cartUUID', response.data.data.attributes.uuid)
        params.uuid = response.data.data.attributes.uuid

        dispatch({
          type: types.ADD_TO_CART,
          payload: {
            request: api.cart.add(params)
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  })
}

export const removeToCart = (params) => (dispatch) => dispatch({
  type: types.REMOVE_TO_CART,
  payload: {
    request: api.cart.remove({
      ...params,
      uuid: localStorage.getItem('cartUUID')
    })
  }
})

export const changeQuantity = (params) => (dispatch) => dispatch({
  type: types.CHANGE_QUANLITY,
  payload: {
    request: api.cart.updateQuantity({
      ...params,
      uuid: localStorage.getItem('cartUUID')
    })
  }
})

export const changeOption = (params) => (dispatch) => dispatch({
  type: types.CHANGE_OPTION,
  payload: {
    request: api.cart.updateOption({
      ...params,
      uuid: localStorage.getItem('cartUUID')
    })
  }
})

export const changeDelivery = (params) => (dispatch) => dispatch({
  type: types.CHANGE_DELIVERY,
  payload: {
    request: api.cart.changeDeliveryMethod({
      ...params,
      uuid: localStorage.getItem('cartUUID')
    })
  }
})

export const removeAllCart = () => (dispatch) => {
  localStorage.removeItem('cartUUID')
  dispatch(removeAllCartSuccess())
}

export const updateInfo = (params) => (dispatch) => dispatch({
  type: types.UPDATE_CART_INFO,
  payload: {
    request: api.cart.update({
      ...params,
      uuid: localStorage.getItem('cartUUID')
    })
  }
})

export const getShoppingCart = () => (dispatch) => {
  const cartUUID = localStorage.getItem('cartUUID')

  return new Promise((resolve, reject) => {
    if (cartUUID) {
      dispatch(requestShoppingCart()).then(response => {
        resolve(response)
      }).catch(errors => {
        localStorage.removeItem('cartUUID')
        reject(errors)
      })
    } else {
      resolve()
    }
  })
}

export const checkout = (params) => (dispatch) => dispatch({
  type: types.CHECKOUT,
  payload: {
    request: api.cart.checkout({
      ...params,
      uuid: localStorage.getItem('cartUUID')
    })
  }
})

export const checkoutPOS = (params) => (dispatch) => dispatch({
  type: types.CHECKOUT_POS,
  payload: {
    request: api.cart.checkoutPOS({
      ...params,
    })
  }
})

export const requestClientAuthorization = () => (dispatch) => dispatch({
  type: types.REQUEST_CLIENT_AUTHORIZATION,
  payload: {
    request: api.payment.requestClientAuthorization()
  }
})

export const payment = (params) => (dispatch) => dispatch({
  type: types.PAYMENT_CREDIT,
  payload: {
    request: api.cart.paymentPaypal(params)
  }
})

export const orderUserCart = (params) => (dispatch) => dispatch({
  type: types.ORDER_USER_CART,
  payload: {
    request: api.cart.order(params)
  }
})
