import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { Pane } from 'shared/components/layouts';

class TimeSlotItem extends Component {
  static propTypes = {
    fromTime: PropTypes.any,
    toTime: PropTypes.any,
    index: PropTypes.any,
    available: PropTypes.bool,
    onChangeTimeSlot: PropTypes.func,
  };

  state = {
    detail: {},
    loading: false,
  };

  onChange = () => {
    this.props.onChangeTimeSlot(this.props.index);
  };

  render() {
    const { index, available, fromTime, toTime } = this.props;

    return (
      <Pane className="time-slot-item">
        <Pane className="row pt10">
          <Pane className="col-1">
            <Checkbox
              label=""
              className="checkbox-time"
              value={index}
              onChange={this.onChange}
              disabled={!available}
            />
          </Pane>
          <Pane className="col-2">
            {available ? (
              fromTime
            ) : (
              <span style={{ color: '#c00' }}>{fromTime}</span>
            )}
          </Pane>
          <Pane className="col-1 col-sm-1">
            <span>-</span>
          </Pane>
          <Pane className="col-2">
            {available ? (
              toTime
            ) : (
              <span style={{ color: '#c00' }}>{toTime}</span>
            )}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default TimeSlotItem;
