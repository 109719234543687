import React  from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Pane } from 'shared/components/layouts'

const NotFound = ({ history, intl }) => {
  return (
    <Pane className='page-not-found-content text-center'>
      <h2 className="main-title">404</h2>
      <p className="main-description"><FormattedMessage id='notification.the_page_you_requested_was_not_found' defaultMessage='Oops! The page you requested was not found!' /></p>
      <Button color='orange' icon='home' className="btn btn-circle" content={intl.formatMessage({ id: 'go_back_to_home' })} onClick={() => history.push('/')} />
    </Pane>
  )
}

NotFound.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object,
}

NotFound.defaultProps = {
  history: {}
}

export default withRouter(injectIntl(NotFound))
