import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import SellingOrderItem from './SellingOrderItem';
import Config from "constants/Config";

class SellingOrderList extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
  };

  render() {
    const {
      location: { pathname },
      method
    } = this.props;
    const listOrder = this.props.orders.results.map((item) => {
      return (
        <SellingOrderItem
          key={item.id}
          id={item.id}
          orderDate={item.orderDate}
          paymentMethod={item.paymentMethod}
          total={item.total}
          customer={item.customer}
          mode={item.mode}
          guestName={item.guestName}
          status={item.status}
          method={method}
          tableName={item.tableName}
          link={`${pathname}/${item.id}/selling/detail`}
          viewOrderDetail={this.props.viewOrderDetail}
        />
      );
    });

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="order_date" defaultMessage="Order date" />
            </Table.HeaderCell>
            {[Config.DELIVERY_METHOD.DINE_IN, Config.DELIVERY_METHOD.TAKE_AWAY].includes(method) && (
              <Table.HeaderCell>
                Table Name
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              <FormattedMessage
                id="customer_name"
                defaultMessage="Customer name"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="total_payment"
                defaultMessage="Total payment"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="order_status"
                defaultMessage="Order status"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{listOrder}</Table.Body>
      </Table>
    );
  }
}

export default withRouter(SellingOrderList);
