import React, { Component } from 'react';
import Slider from 'react-slick';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage } from 'react-intl';

class Customer extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // initialSlide: 2
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Pane className="customer-container">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Slider className="customer-slider" {...settings}>
                <Pane>
                  <Pane className="slider-item">
                    <Pane className="customer-content text-center">
                      <h3 className="customer-title">
                        Hear from our sellers & customers
                      </h3>
                      <p className="customer-norm">
                        <FormattedMessage
                          id="cardbey_is_in_a_class"
                          defaultMessage=" Cardbey is in a class of its own when it comes to
                          e-commerce."
                        />
                      </p>
                      <h4 className="customer-name">Caitlyn Williams</h4>
                    </Pane>
                    <Pane className="customer-img">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/about/person.png`}
                        alt="person"
                        className="img"
                      />
                    </Pane>
                  </Pane>
                </Pane>
                <Pane>
                  <Pane className="slider-item">
                    <Pane className="customer-content text-center">
                      <h3 className="customer-title">
                        Hear from our sellers & customers
                      </h3>
                      <p className="customer-norm">
                        <FormattedMessage
                          id="cardbey_has_helped_me_increase"
                          defaultMessage="Cardbey has helped me increase my sales by 25%..."
                        />
                      </p>
                      <h4 className="customer-name">Caitlyn Williams</h4>
                    </Pane>
                    <Pane className="customer-img">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/testimonials/image_1.jpeg`}
                        alt="person"
                        className="img"
                      />
                    </Pane>
                  </Pane>
                </Pane>
                <Pane>
                  <Pane className="slider-item">
                    <Pane className="customer-content text-center">
                      <h3 className="customer-title">
                        Hear from our sellers & customers
                      </h3>
                      <p className="customer-norm">
                        <FormattedMessage
                          id="cardbey_is_a_complete_product"
                          defaultMessage="Cardbey is a complete product, offering a bustling"
                        />
                      </p>
                      <h4 className="customer-name">Caitlyn Williams</h4>
                    </Pane>
                    <Pane className="customer-img">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/testimonials/image_3.jpeg`}
                        alt="person"
                        className="img"
                      />
                    </Pane>
                  </Pane>
                </Pane>
                <Pane>
                  <Pane className="slider-item">
                    <Pane className="customer-content text-center">
                      <h3 className="customer-title">
                        Hear from our sellers & customers
                      </h3>
                      <p className="customer-norm">
                        <FormattedMessage
                          id="cardbey_is_in_a_class"
                          defaultMessage=" Cardbey is in a class of its own when it comes to
                          e-commerce."
                        />
                      </p>
                      <h4 className="customer-name">Caitlyn Williams</h4>
                    </Pane>
                    <Pane className="customer-img">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/testimonials/image_2.jpeg`}
                        alt="person"
                        className="img"
                      />
                    </Pane>
                  </Pane>
                </Pane>
              </Slider>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default Customer;
