import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Pane, Loading } from "shared/components/layouts";
import UserAdapter from "services/users/adapter";
import InlineMessage from "shared/components/InlineMessage";
import { formatRoute, handleError, quill } from "shared/helpers";
import { Button, Form, Image, Icon, Loader } from "semantic-ui-react";
import Config from 'constants/Config';

class EditDigitalForm extends Component {
  static propTypes = {
    products: PropTypes.object,
  };

  state = {
    imageHorizontal: [],
    imageHorizontalFiles: [],
    imageVertical: [],
    imageVerticalFiles: [],
    data: {
      name: "",
      description: "",
    },
    errors: {},
    loading: false,
    fetched: false,
    fetching: false,
    extVideo: Config.extVideo
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onChangeImageHorizontal = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.imageHorizontal.length < 6 &&
          this.state.imageHorizontal.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const imageHorizontal = this.state.imageHorizontal;
            const imageHorizontalFiles = this.state.imageHorizontalFiles;

            imageHorizontal.push(reader.result);
            imageHorizontalFiles.push(files[index]);

            this.setState({
              errors: { ...this.state.errors, ['imageHorizontalFiles']: '' },
            });

            this.setState({
              imageHorizontal,
              imageHorizontalFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  onChangeImageVertical = (e) => {
    const { files } = e.target;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.imageVertical.length < 6 &&
          this.state.imageVertical.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const imageVertical = this.state.imageVertical;
            const imageVerticalFiles = this.state.imageVerticalFiles;

            imageVertical.push(reader.result);
            imageVerticalFiles.push(files[index]);

            this.setState({
              errors: { ...this.state.errors, ['imageVerticalFiles']: '' },
            });

            this.setState({
              imageVertical,
              imageVerticalFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  removeImage = (index) => {
    const imageHorizontal = this.state.imageHorizontal;
    const imageHorizontalFiles = this.state.imageHorizontalFiles;

    imageHorizontal.splice(index, 1);
    imageHorizontalFiles.splice(index, 1);

    this.setState({
      imageHorizontal,
      imageHorizontalFiles,
    });
  };

  removeImageVertical = (index) => {
    const imageVertical = this.state.imageVertical;
    const imageVerticalFiles = this.state.imageVerticalFiles;

    imageVertical.splice(index, 1);
    imageVerticalFiles.splice(index, 1);

    this.setState({
      imageVertical,
      imageVerticalFiles,
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl, type } = this.props;

    if (!data.title) {
      errors.title = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }

    if(type === 'Horizontal' && isEmpty(this.state.imageHorizontalFiles)){
      errors.imageHorizontalFiles = intl.formatMessage({
        id: "validate.require_field",
      });
   }

    if ( type === 'Vertical' && isEmpty(this.state.imageVerticalFiles)) {
      errors.imageVerticalFiles = intl.formatMessage({
        id: "validate.require_field",
      });
    }

    return errors;
  };

  editProduct = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new FormData();

      data.append("title", this.state.data.title);
      if (
        !isEmpty(this.state.imageHorizontalFiles) &&
        this.state.imageHorizontalFiles[0].type
      ) {
        data.append(
          "image_horizontal",
          this.state.imageHorizontalFiles[0] || ""
        );
      }
      if (
        !isEmpty(this.state.imageVerticalFiles) &&
        this.state.imageVerticalFiles[0].type
      ) {
        data.append("image_vertical", this.state.imageVerticalFiles[0] || "");
      }
      data.append("description", this.state.data.description);
      data.append("user_id", this.props.userInfo.id || "");
      data.append("screen_id", this.props.screenId || "");
      data.append("id", this.props.id);

      this.props
        .editMyDigital(data)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push(
            `/u/${formatRoute(
              this.props.userInfo.displayName
            )}/digital-slideshow/screen/${this.props.screenId}/edit`
          );
        })
        .catch((error) => {
          const errors = {};
          errors.message = handleError(error, "Can't create new a product");
          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  componentDidMount() {
    const imageHorizontal = [];
    const imageHorizontalFiles = [];
    const imageVertical = [];
    const imageVerticalFiles = [];
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props
      .getMyDigitalDetail({ id: this.props.id })
      .then((response) => {
        const item = UserAdapter.digitalRead.parseResponse(response.data);
        if (item.imageHorizontal && item.imageHorizontal.url) {
          imageHorizontal.push(item.imageHorizontal.url);
          imageHorizontalFiles.push(item.imageHorizontal.url);
        }
        if (item.imageVertical && item.imageVertical.url) {
          imageVertical.push(item.imageVertical.url);
          imageVerticalFiles.push(item.imageVertical.url);
        }
        this.setState({
          imageHorizontal,
          imageHorizontalFiles,
          imageVertical,
          imageVerticalFiles,
          data: {
            title: item.title || "",
            description: item.description || "",
          },
          fetched: true,
          fetching: false,
        });
      })
      .catch(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  }

  render() {
    const { errors, data, loading, fetched, fetching, extVideo, imageHorizontal, imageVertical } = this.state;
    return (
      <Form className="form-overview pb-5" onSubmit={this.editProduct}>
        {fetched && !fetching ? (
          <>
            <Pane className="form-content">
              {this.props.type === 'Horizontal' ? (
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field
                      error={!!errors.imageHorizontalFiles}
                      className="form-item"
                    >
                      <label htmlFor="Image" className="form-label">
                        <FormattedMessage
                          id="image_horizontal"
                          defaultMessage="Image Horizontal"
                        />
                      </label>
                      {isEmpty(this.state.imageHorizontal) && (
                        <>
                          <input
                            type="file"
                            id="image-menu"
                            className="form-input-file"
                            value={data.imageVerticalFiles}
                            onChange={this.onChangeImageHorizontal}
                          />
                          <br />
                          <label htmlFor="image-menu" className="form-label-file">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/images/icons/upload.svg`
                              }
                              alt="upload"
                              className="icon"
                            />
                            Upload a photo/video
                          </label>
                        </>
                      )}
                      <Pane
                        className="slider-content-main"
                        style={{ padding: "10px 0" }}
                      >
                        {imageHorizontal &&
                          imageHorizontal.map((item, index) => {
                            if (!extVideo.includes(item.substr(item.lastIndexOf('.') + 1))) {
                              return (
                                <Pane
                                  className="slide-item"
                                  key={index}
                                  style={{ float: "left" }}
                                >
                                  <Icon
                                    name="remove circle"
                                    onClick={() => this.removeImage(this, index)}
                                  />
                                  <Image src={item} size="small" />
                                </Pane>
                              );
                            }
                            return (
                              <Pane
                                className="slide-item"
                                key={index}
                                style={{ float: "left" }}
                              >
                                <Icon
                                  name="remove circle"
                                  onClick={() => this.removeImage(index)}
                                />
                                <video width="150" controls>
                                  <source src={item} id="video_here" />
                                  Your browser does not support HTML5 video.
                                </video>
                              </Pane>
                            );
                          })}
                      </Pane>
                      {errors.imageHorizontalFiles && (
                        <InlineMessage text={errors.imageHorizontalFiles} />
                      )}
                    </Form.Field>
                  </Pane>
                </Pane>
              ) : (
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field
                      error={!!errors.imageVerticalFiles}
                      className="form-item"
                    >
                      <label htmlFor="Image" className="form-label">
                        <FormattedMessage
                          id="image_vertical"
                          defaultMessage="Image Vertical"
                        />
                      </label>
                      {isEmpty(imageVertical) && (
                        <>
                          <input
                            type="file"
                            id="image-menu-vertical"
                            className="form-input-file"
                            value={data.imageHorizontalFiles}
                            onChange={this.onChangeImageVertical}
                          />
                          <br />
                          <label
                            htmlFor="image-menu-vertical"
                            className="form-label-file"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/images/icons/upload.svg`
                              }
                              alt="upload"
                              className="icon"
                            />
                            Upload a photo/video
                          </label>
                        </>
                      )}
                      <Pane
                        className="slider-content-main"
                        style={{ padding: "10px 0" }}
                      >
                        {imageVertical &&
                          imageVertical.map((item, index) => {
                            if (item.indexOf("video") < 0) {
                              return (
                                <Pane
                                  className="slide-item"
                                  key={index}
                                  style={{ float: "left" }}
                                >
                                  <Icon
                                    name="remove circle"
                                    onClick={() =>
                                      this.removeImageVertical(this, index)
                                    }
                                  />
                                  <Image src={item} size="small" />
                                </Pane>
                              );
                            }
                            return (
                              <Pane
                                className="slide-item"
                                key={index}
                                style={{ float: "left" }}
                              >
                                <Icon
                                  name="remove circle"
                                  onClick={() => this.removeImageVertical(index)}
                                />
                                <video width="150" controls>
                                  <source src={item} id="video_here" />
                                  Your browser does not support HTML5 video.
                                </video>
                              </Pane>
                            );
                          })}
                      </Pane>
                      {errors.imageVerticalFiles && (
                        <InlineMessage text={errors.imageVerticalFiles} />
                      )}
                    </Form.Field>
                  </Pane>
                </Pane>
              )}
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field error={!!errors.title} className="form-item">
                    <label htmlFor="title" className="form-label">
                      <FormattedMessage id="title" defaultMessage="title" />
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      placeholder=""
                      className="form-input"
                      value={data.title}
                      onChange={this.onChange}
                    />
                    {errors.title && <InlineMessage text={errors.title} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    error={!!errors.description}
                    className="form-item"
                  >
                    <label htmlFor="description" className="form-label">
                      <FormattedMessage
                        id="description"
                        defaultMessage="Description"
                      />
                    </label>
                    <ReactQuill
                      value={data.description}
                      onChange={(content) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            description: content,
                          },
                        }));
                      }}
                      formats={quill.formats}
                      modules={quill.modules}
                    />
                    {errors.description && (
                      <InlineMessage text={errors.description} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  {errors.message && <InlineMessage text={errors.message} />}
                </Pane>
              </Pane>
            </Pane>
            <Pane className="row">
              <Pane className="col-lg-12 text-center">
                <Button
                  type="submit"
                  className="btn btn-save mt-4"
                  disabled={loading}
                  loading={loading}
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </Pane>
            </Pane>
          </>
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}
      </Form>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  products: state.myProducts,
});

export default withRouter(
  connect(bindStateToProps)(injectIntl(EditDigitalForm))
);
