import * as ScanCountReadV1 from './scanCount.read.v1.action'
import * as ScanCountAddV1 from './scanCount.add.v1.action'

export default {
  read: {
    callAPI: params => ScanCountReadV1.exec(params),
    parseResponse: response => ScanCountReadV1.parseResponse(response)
  },
  add: {
    callAPI: params => ScanCountAddV1.exec(params),
    parseResponse: response => ScanCountAddV1.parseResponse(response)
  },
}
