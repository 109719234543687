import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { editMyStaff, getStaffDetail } from "actions/staffs";
import EditStaffForm from "./EditStaffForm";
import { Pane } from 'shared/components/layouts';
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';
class EditStaff extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(
          this.props.userInfo.displayName
        )}/staffs`, name: 'Staff'
      },
      {
        url: ``, name: 'Update staff', active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <div className="text-center mb-4" id="anchor">
        </div>
        <EditStaffForm
          id={this.props.match.params.staffId}
          userInfo={this.props.userInfo}
          history={this.props.history}
          editMyStaff={this.props.editMyStaff}
          getStaffDetail={this.props.getStaffDetail}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});
const bindDispatchToProps = (dispatch) => ({
  editMyStaff: bindActionCreators(editMyStaff, dispatch),
  getStaffDetail: bindActionCreators(getStaffDetail, dispatch),
});
export default connect(mapStateToProps, bindDispatchToProps)(EditStaff);