import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'shared/components/layouts'
import PromotionItem from './PromotionItem'

class PromotionList extends Component {
  static propTypes = {
    promotions: PropTypes.array,
    userInfo: PropTypes.object,
    type: PropTypes.string,
    boostPromotion: PropTypes.func.isRequired
  }

  state = {
    data: []
  }

  ele = (items) => {
    const list = items.map(promotion => {
      return (
        <PromotionItem
          key={promotion.id}
          id={promotion.id}
          item={promotion}
          name={promotion.item.name}
          image={promotion.primaryImage}
          startDate={promotion.startDate}
          endDate={promotion.endDate}
          price={promotion.item.price}
          promotionPrice={promotion.item.promotionPrice}
          percentDiscount={promotion.percent}
          type={promotion.type}
          userInfo={this.props.userInfo}
          history={this.props.history}
          boostPromotion={this.props.boostPromotion}
        />
      )
    });

    return list;
  }

  renderEle = () => {
    let grouped = [];
    this.props.promotions.forEach(function (a) {
      grouped[a.masterPromotionId] = grouped[a.masterPromotionId] || [];
      grouped[a.masterPromotionId].push(a);
    });

    this.setState({ data: grouped });
  }

  componentDidMount() {
    this.renderEle();
  }

  render() {
    return (
      <Pane className='category-list'>
        <Pane className="adds-block">
          {
            this.state.data.map((item) => {
              return (
                <>
                  {item[0].description &&(
                    <Pane className="row">
                      <Pane className="col-12 category-name">
                        {item[0].description}
                      </Pane>
                    </Pane>
                  )}
                  <Pane className="row">
                    {this.ele(item)}
                  </Pane>
                </>
              )
            })
          }
        </Pane>
      </Pane>
    );
  }
}

export default PromotionList
