import React from 'react'
import { withRouter } from 'react-router-dom';
import { MasterLayout } from 'components/layout'
import { Pane } from 'shared/components/layouts'
import queryString from 'query-string';
import { Button } from 'semantic-ui-react'
import Config from 'constants/Config'
import axios from 'axios';
import { Cookies } from 'react-cookie';

class SendVerifyEmail extends React.Component {
  state = {
    loading: false,
    email: ''
  }

  resend = async () => {
    const cookies = new Cookies();
    this.setState({
      loading: true,
    });
    await axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookies.get('accessToken')}`
      },
      url: `${Config.NEW_API_URL}/email-verification/resend?email=${encodeURIComponent(this.state.email)}`,
    })
      .then((response) => {
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        this.props.history.replace(`/page-not-found`);
      });
  }

  componentDidMount() {
    let email = queryString.parse(this.props.location.search).email;
    if (!email) {
      this.props.history.replace(`/page-not-found`);
    } 
    this.setState({ email: email });
  }

  render() {
    let { email, loading } = this.state;
    return (
      <MasterLayout>
        <Pane className='container not-found-container'>
          <Pane className='page-not-found-content text-center'>
            <h1>Welcome !</h1>
            <img width="5%" aria-hidden src="/assets/images/gallery/inbox.png" alt="image" />
            <br />
            <br />
            <p>We sent an email to {email}</p>
            <p> To continue, please check your email and verify your account.</p>
            <p>Didn't receive the email?</p>
            <Button onClick={this.resend} loading={loading} disabled={loading} color='blue' content="Resend Email" />
          </Pane>
        </Pane>
      </MasterLayout>
    )
  }
}

export default withRouter(SendVerifyEmail)
