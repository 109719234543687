import { get } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { QRCode } from "shared/components";
import Modal from "react-responsive-modal";
import { ShowMap } from "shared/components";
import { Pane } from "shared/components/layouts";
import {
  Rating, List, Loader,
  Dimmer,
} from "semantic-ui-react";
import { TradingHours } from "../common/trading-hours";
import ReactTooltip from "react-tooltip";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  ViberIcon,
  ViberShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LineIcon,
  LineShareButton
} from "react-share";
import Content from "../../../common/readmore/ReadMore";

class BusinessInfo extends Component {
  static propTypes = {
    businessInfo: PropTypes.object,
  };

  state = {
    open: false,
    openMap: false,
    flag: false,
    loadingAvatar: false,
    imageAvaRender: "",
    imageAvaFile: "",
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenMapModal = () => {
    this.setState({ openMap: true });
  };

  onCloseMapModal = () => {
    this.setState({ openMap: false });
  };

  cancelUpload = () => {
    this.setState({ imageAvaRender: "" });
  }

  onChangeAvatar = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          imageAvaRender: reader.result,
          imageAvaFile: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  uploadImage = () => {
    const data = new FormData();

    if (this.state.imageAvaFile) {
      data.append("id", this.props.userInfo.id);
      data.append("image", this.state.imageAvaFile);

      this.setState({ loadingAvatar: true });

      this.props
        .updateUserInfo(data)
        .then((response) => {
          this.setState({ loadingAvatar: false });
          window.location.reload();
        })
        .catch((errors) => {
          this.setState({ loadingAvatar: false });
        });
    }
  };

  render() {
    const { businessInfo, userInfo } = this.props;
    const shareURL = `https://cardbey.com/${businessInfo.id}`;
    return (
      <Pane className="container">
        <Pane className="row">
          <Pane className="col-lg-3 col-md-12 col-12">
            <Pane className="logo-stores">
              {this.state.loadingAvatar ? (
                <Pane>
                  <Dimmer active inverted>
                    <Loader size="small" />
                  </Dimmer>
                </Pane>
              ) : null}
              {this.state.imageAvaRender ? (
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    this.state.imageAvaRender
                      ? this.state.imageAvaRender
                      : process.env.PUBLIC_URL + "/assets/images/user.jpg"
                  }
                  alt="logo"
                  className="logo-img"
                />
              ) : (
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    get(userInfo, "profileImage")
                      ? get(userInfo, "profileImage")
                      : process.env.PUBLIC_URL + "/assets/images/user.jpg"
                  }
                  alt="logo"
                  className="logo-img"
                />
              )}
              <div className="block-qr-code" onClick={this.onOpenModal} data-tip data-for={`tooltip-qr-card`}>
                <span className="ic-common-qr-code" />
              </div>
              <input
                type="file"
                id="image-avatar"
                className="form-input-file d-none"
                name="image"
                placeholder=""
                value=""
                accept="image/gif, image/jpeg, image/png"
                disabled={this.state.loadingAvatar}
                onChange={this.onChangeAvatar}
              />
              <label htmlFor="image-avatar" className="edit-avatar">
                <i className="icon-camera" />
              </label>
              {this.state.imageAvaRender && (
                <Pane className="block-action">
                  <Pane className="block-action__btn btn-cancel" onClick={this.cancelUpload}>Cancel</Pane>
                  <Pane className="block-action__btn btn-save" onClick={this.uploadImage}>Save</Pane>
                </Pane>
              )}
              <ReactTooltip
                place="top"
                type="dark"
                effect="float"
                id={"tooltip-qr-card"}
              >
                Click to get digital card
              </ReactTooltip>
              <Modal
                className="popup-qrcode"
                classNames={{
                  closeIcon: 'customIconBtn'
                }}
                open={this.state.open}
                onClose={this.onCloseModal}
                center
                showCloseIcon={true}
              >
                <QRCode
                  onClose={this.onCloseModal}
                  qrcode={get(userInfo, "qrcode")}
                  storeInfo={userInfo}
                />
              </Modal>
            </Pane>
          </Pane>
          {get(businessInfo, "name") && (
            <Pane className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-end">
              <Pane className="heading-content">
                <Pane className="heading-info">
                  <h2 className="heading-title store-title-page">{businessInfo.name || ""}</h2>
                  <Pane className="d-none heading-status d-lg-flex align-items-start">
                    <Rating
                      disabled
                      icon='star'
                      rating={get(businessInfo, "rating") || 0}
                      maxRating={1}
                      className="mr-1"
                      size='huge'
                    />
                    <span>{get(businessInfo, "rating") || 'No Rating'}</span>
                  </Pane>
                  <Pane className="heading-norm bussiness-description font-italic font-weight-normal pb-2">{businessInfo.subTitle || ""}
                    &nbsp;<Content text={businessInfo.description || ""} />
                  </Pane>
                  <Pane className="heading-profile w-md-50">
                    <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                      <a
                        href={`https://www.google.com/maps?q=${businessInfo.address
                          }`}
                        className="link link-address"
                        target={"_blank"}
                      >
                        <div className="box-icon" data-tip data-for={`location`}>
                          <span className="icon-map" />
                        </div>
                        <ReactTooltip
                          place="top"
                          type="dark"
                          effect="float"
                          id={"location"}
                        >
                          {businessInfo.address || ""}
                        </ReactTooltip>
                      </a>
                      <div className="directions-text">DIRECTIONS</div>
                    </span>
                    <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                      <a href={`tel:${businessInfo.phone}`} className="link">
                        <div className="box-icon" data-tip data-for={`phone`}>
                          <span className="ic-common-phone icon" />
                        </div>
                        <ReactTooltip
                          place="top"
                          type="dark"
                          effect="float"
                          id={"phone"}
                        >
                          {businessInfo.phone || ""}
                        </ReactTooltip>
                      </a>
                      <Pane className="directions-text">CALL</Pane>
                    </span>
                    <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                      <a href={`mailto:${businessInfo.email}`} className="link">
                        <div className="box-icon" data-tip data-for={`email`}>
                          <span className="ic-common-mail icon" />
                        </div>
                        <ReactTooltip
                          place="top"
                          type="dark"
                          effect="float"
                          id={"email"}
                        >
                          {businessInfo.email || ""}
                        </ReactTooltip>
                      </a>
                      <Pane className="directions-text">EMAIL</Pane>
                    </span>
                    <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                      <a href={businessInfo.websiteUrl != null ? `http://${businessInfo.websiteUrl}` : `https://cardbey.com/${userInfo.business.id}`} className="link" target={"_blank"}>
                        <div className="box-icon" data-tip data-for={`websiteUrl`}>
                          <span className="icon-globe" />
                        </div>
                        {
                          businessInfo.websiteUrl && (
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="float"
                              id={"websiteUrl"}
                            >
                              {businessInfo.websiteUrl || ""}
                            </ReactTooltip>
                          )
                        }
                      </a>
                      <Pane className="directions-text">WEBSITE</Pane>
                    </span>
                    <span className="position-relative profile-name float-sm-left float-lg-left float-md-left w-10">
                      <div onClick={() => this.setState({ flag: !this.state.flag })} className="box-icon" data-tip data-for={`share`}>
                        <span className="icon ic-common-share" />
                      </div>
                      <Pane className="directions-text">SHARE</Pane>
                      <List horizontal className={`share-list share-list-ref ${this.state.flag ? 'd-flex' : 'hide'} `}>
                                <List.Content>
                                  <FacebookShareButton className="share-item" url={shareURL}>
                                    <FacebookIcon size={32} round={true} />
                                  </FacebookShareButton>
                                </List.Content>
                                <List.Content>
                                  <TwitterShareButton className="share-item" url={shareURL}>
                                    <TwitterIcon size={32} round={true} />
                                  </TwitterShareButton>
                                </List.Content>
                                <List.Content>
                                  <EmailShareButton className="share-item" url={shareURL}>
                                    <EmailIcon size={32} round={true} />
                                  </EmailShareButton>
                                </List.Content>
                                <List.Content>
                                  <LinkedinShareButton className="share-item" url={shareURL}>
                                    <LinkedinIcon size={32} round={true} />
                                  </LinkedinShareButton>
                                </List.Content>
                                <List.Content>
                                  <ViberShareButton className="share-item" url={shareURL}>
                                    <ViberIcon size={32} round={true} />
                                  </ViberShareButton>
                                </List.Content>

                                <List.Content>
                                  <TelegramShareButton className="share-item" url={shareURL}>
                                    <TelegramIcon size={32} round={true} />
                                  </TelegramShareButton>
                                </List.Content>

                                <List.Content>
                                  <WhatsappShareButton className="share-item" url={shareURL}>
                                    <WhatsappIcon size={32} round={true} />
                                  </WhatsappShareButton>
                                </List.Content>
                                <List.Content>
                                  <LineShareButton className="share-item" url={shareURL}>
                                    <LineIcon size={32} round={true} />
                                  </LineShareButton>
                                </List.Content>

                              </List>
                    </span>
                  </Pane>
                </Pane>
                <TradingHours tradingHours={businessInfo.tradingHours} onChangeToggleMenu={this.props.onChangeToggleMenu} rating={get(userInfo, "rating") || 0} />
              </Pane>
            </Pane>
          )}
        </Pane>
      </Pane>
    );
  }
}

export default BusinessInfo;
