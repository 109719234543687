import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage } from 'react-intl';

class LearnMore extends Component {
  changeRoute = () => {
    this.props.history.push(
      this.props.auth.isAuthenticated ? `/learn-more` : '/signup'
    );
  };

  render() {
    return (
      <Pane className="learn-more-container">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-8 offset-lg-2">
              <Pane className="heading-container text-center">
                <h2 className="heading-title">
                  <FormattedMessage
                    id="home.managing_building_your_dream"
                    defaultMessage="You're managing a business, building your dream one or thinking of Start-up"
                  />
                </h2>
                <p className="heading-norm">
                  <FormattedMessage id="home.with_just_few_simple_steps" />
                </p>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-8 offset-lg-2 col-md-12 offset-lg-0">
              <Pane className="process-content pt-5">
                <img
                  src={
                    process.env.PUBLIC_URL + '/assets/images/home/process.png'
                  }
                  alt="process"
                  className="process-img"
                />
              </Pane>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-8 offset-lg-2 text-center">
              <strong className="strong">
                <FormattedMessage id="home.we_all_know_business_sometimes" />
                <FormattedMessage id="home.cardbey_help_you_to_make_it_easier" />
              </strong>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-8 offset-lg-2 text-center">
              <button
                className="btn btn-circle btn-learn-more"
                onClick={this.changeRoute}
              >
                <FormattedMessage id="learn_more" />
              </button>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
const bindStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(bindStateToProps)(withRouter(LearnMore));
