import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Pane, Loading } from "shared/components/layouts";
import {
  getPromotionProductsByStoreId,
  getSlides,
} from "actions/stores";
import _, { isEmpty } from "lodash";
import { searchStores } from "actions/search";
import { formatJson, isNumberic, formatRoute } from "shared/helpers";
import Slideshow from "../show/Slideshow";

class StoreOverview extends Component {
  static propTypes = {
    auth: PropTypes.object,
    match: PropTypes.object,
    getPromotionProductsByStoreId: PropTypes.func,
  };

  state = {
    slides: {},
    products: {},
    services: {},
    selected: "",
    storeInfo: {},
    foodCategories: [],
    fetchedMenu: false,
    fetchingMenu: false,
    modeView: "gridView",
    serviceCategories: [],
    promotionProducts: {},
    promotionServices: {},
    fetchedProducts: false,
    fetchedServices: false,
    fetchingProducts: false,
    fetchingServices: false,
    modeViewMenu: "gridView",
    modeViewProduct: "gridView",
  };

  getStorePromotionProducts = () => {
    this.props
      .getPromotionProductsByStoreId({ store: this.props.storeInfo.id })
      .then((response) => {
        this.setState({
          promotionProducts: response.data,
        });
      });
  };

  getStoreSlides = () => {
    const storeId = this.props.storeInfo.id;
    this.setState({
      fetchedSlider: false,
      fetchingSlider: true,
    });
    this.props.getSlides({ store: storeId }).then(() => {
      this.setState({
        slides: this.props.stores.slides[storeId],
        fetchedSlider: true,
        fetchingSlider: false,
      });
    });
  };

  componentDidMount() {
    this.getStoreSlides();
    this.getStorePromotionProducts();
  }


  render() {
    const { slides, isVideo, fetchedSlider, fetchingSlider } = this.state;

    return (
      <>
        {fetchedSlider && !fetchingSlider ? (
          <>
            {slides.results && !isVideo && <Slideshow data={slides.results} />}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  stores: state.stores,
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  getSlides: bindActionCreators(getSlides, dispatch),
  getPromotionProductsByStoreId: bindActionCreators(
    getPromotionProductsByStoreId,
    dispatch
  ),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(StoreOverview);
