import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl, intlShape } from 'react-intl';
import { MasterLayout } from 'components/layout';
import { searchStores } from 'actions/search';
import { getBanners } from 'actions/banner';
import {
  JustListed,
  BusinessLogoList,
} from 'components/scenes/just-listed';
import { Pane } from 'shared/components/layouts';
import _ from "lodash";

class JustListedPage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    searchStores: PropTypes.func,
    slides: PropTypes.object,
    getSlides: PropTypes.func,
    intl: intlShape.isRequired,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <JustListed
            auth={this.props.auth}
            onSearchStores={this.props.searchStores}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  searchStores: bindActionCreators(searchStores, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(JustListedPage));
