import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import { Placeholder, Segment } from 'semantic-ui-react';

class LoadingAdds extends Component {
  render() {
    if (this.props.type === 'BESTMATCHED') {
      return (
        <Pane className="loading-adds-wrapper">
          <Pane className="container">
            <Pane className="row">
              <Pane className="col-lg-2 col-md-4 col-sm-6 col-6">
                <Segment raised>
                  <Placeholder>
                    <Placeholder.Header className="mb-3">
                      <Placeholder.Image rectangular className="mb-3" />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="medium" />
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Segment>
              </Pane>
              <Pane className="col-lg-2 col-md-4 col-sm-6 col-6">
                <Placeholder>
                  <Placeholder.Header className="mb-3">
                    <Placeholder.Image rectangular className="mb-3" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Pane>
              <Pane className="col-lg-2 col-md-4 col-sm-6 col-6">
                <Placeholder>
                  <Placeholder.Header className="mb-3">
                    <Placeholder.Image rectangular className="mb-3" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Pane>
              <Pane className="col-lg-2 col-md-4 col-sm-6 col-6">
                <Placeholder>
                  <Placeholder.Header className="mb-3">
                    <Placeholder.Image rectangular className="mb-3" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Pane>
              <Pane className="col-lg-2 col-md-4 col-sm-6 col-6">
                <Placeholder>
                  <Placeholder.Header className="mb-3">
                    <Placeholder.Image rectangular className="mb-3" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Pane>
              <Pane className="col-lg-2 col-md-4 col-sm-6 col-6">
                <Placeholder>
                  <Placeholder.Header className="mb-3">
                    <Placeholder.Image rectangular className="mb-3" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className="loading-adds-wrapper">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
              <Segment raised>
                <Placeholder>
                  <Placeholder.Header className="mb-3">
                    <Placeholder.Image rectangular className="mb-3" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Pane>
            <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
              <Placeholder>
                <Placeholder.Header className="mb-3">
                  <Placeholder.Image rectangular className="mb-3" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Pane>
            <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
              <Placeholder>
                <Placeholder.Header className="mb-3">
                  <Placeholder.Image rectangular className="mb-3" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Pane>
            <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
              <Placeholder>
                <Placeholder.Header className="mb-3">
                  <Placeholder.Image rectangular className="mb-3" />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

LoadingAdds.propTypes = {};

export default LoadingAdds;
