import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Pane, Loading } from 'shared/components/layouts';
import TimeSlotItem from './TimeSlotItem';

const TimeSlots = ({ timeSlots, loading, onChangeTimeSlot }) => {
  return (
    <Pane
      className={`time-slot-content ${
        timeSlots.length > 10 ? `col-sm-12 f-two-column` : `col-sm-12`
      }`}
      style={{
        position: 'relative',
      }}
    >
      {loading && (
        <Dimmer active inverted>
          <Loading />
        </Dimmer>
      )}
      {!loading && timeSlots.length > 0 ? (
        timeSlots.map((item, index) => {
          return (
            <TimeSlotItem
              key={index}
              index={index}
              fromTime={item.from}
              toTime={item.to}
              available={true}
              onChangeTimeSlot={onChangeTimeSlot}
            />
          );
        })
      ) : (
        <p>No timeslots</p>
      )}
    </Pane>
  );
};

TimeSlots.propTypes = {
  timeSlots: PropTypes.array,
  loading: PropTypes.bool,
  onChangeTimeSlot: PropTypes.func,
};

TimeSlots.defaultProps = {
  timeSlots: [],
  loading: false,
};

export default TimeSlots;
