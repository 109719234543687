import ReactQuill from "react-quill";
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import InlineMessage from "shared/components/InlineMessage";
import { Button, Form, Image, Icon } from "semantic-ui-react";
import { formatRoute, handleError, quill } from "shared/helpers";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";

class CreateProductForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  state = {
    images: [],
    imageFiles: [],
    data: {
      title: "",
      description: "",
    },
    errors: {},
    open: false,
    loading: false,
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.images.length < 6 &&
          this.state.images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.title) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
    return errors;
  };

  createProduct = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      const data = new FormData();
      data.append("title", this.state.data.title);
      data.append("video", this.state.imageFiles[0] || "");
      data.append("description", this.state.data.description);

      this.props
        .createMyVideo(data)
        .then(() => {
          this.setState({
            loading: false,
            open: false,
            images: [],
            imageFiles: [],
            data: {
              ...this.state.data,
              name: "",
              categoryId: 1,
              description: "",
              price: "",
            },
          });
          this.props.history.push(
            `/u/${formatRoute(this.props.userInfo.displayName)}/my-videos`
          );
        })
        .catch((error) => {
          const errors = {};
          errors.message = handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );

          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  render() {
    const { errors, data, loading } = this.state;
    return (
      <Form className="form-overview pb-5" onSubmit={this.createProduct}>
        <Pane className="form-content">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field className="form-item">
                <label htmlFor="Image" className="form-label">
                  Video
                </label>
                {this.state.images.length === 0 && (
                  <>
                    <input
                      type="file"
                      id="image-menu"
                      className="form-input-file"
                      multiple
                      value={data.image}
                      onChange={this.onChangeImage}
                    />
                    <br />
                    <label htmlFor="image-menu" className="form-label-file">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/icons/upload.svg`
                        }
                        alt="upload"
                        className="icon"
                      />
                      Upload a video
                    </label>
                  </>
                )}
                <Pane
                  className="slider-content-main"
                  style={{ padding: "10px 0" }}
                >
                  {this.state.images &&
                    this.state.images.map((item, index) => {
                      if (item.indexOf("video") < 0) {
                        // Image
                        return (
                          <Pane
                            className="slide-item"
                            key={index}
                            style={{ float: "left" }}
                          >
                            <Icon
                              name="remove circle"
                              onClick={this.removeImage.bind(this, index)}
                            />
                            <Image src={item} size="small" />
                          </Pane>
                        );
                      }
                      // Video
                      return (
                        <Pane
                          className="slide-item"
                          key={index}
                          style={{ float: "left" }}
                        >
                          <Icon
                            name="remove circle"
                            onClick={this.removeImage.bind(this, index)}
                          />
                          <video width="300" height="300" controls>
                            <source src={item} id="video_here" />
                            Your browser does not support HTML5 video.
                          </video>
                        </Pane>
                      );
                    })}
                </Pane>
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.title} className="form-item">
                <label htmlFor="email" className="form-label">
                  <FormattedMessage id="name" defaultMessage="Name" />
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder=""
                  className="form-input"
                  value={data.title}
                  onChange={this.onChange}
                />
                {errors.title && <InlineMessage text={errors.title} />}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.description} className="form-item">
                <label htmlFor="description" className="form-label">
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                </label>
                <ReactQuill
                  value={this.state.data.description}
                  onChange={(content) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        description: content,
                      },
                    }));
                  }}
                  formats={quill.formats}
                  modules={quill.modules}
                />
                {errors.description && (
                  <InlineMessage text={errors.description} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              {errors.message && <InlineMessage text={errors.message} />}
            </Pane>
          </Pane>
        </Pane>
        <Pane className="row">
          <Pane className="col-lg-12 text-center">
            <Button
              type="submit"
              className="btn btn-save mt-4"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="create" defaultMessage="Create" />
            </Button>
          </Pane>
        </Pane>
      </Form>
    );
  }
}

export default injectIntl(CreateProductForm);
