import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import {
  getPromotionProductsByStoreId,
  getPromotionServicesByStoreId,
} from "actions/stores";
import { searchStores } from "actions/search";
import { Empty } from "components/common/empty";
import StorePromotionList from "./StorePromotionList";
import { isNumberic } from "shared/helpers";
import { LoadingSearch } from "components/common/loading";
import { getStoreInfo } from "actions/stores";

class StorePromotions extends Component {
  static propTypes = {
    match: PropTypes.object,
    getPromotionProductsByStoreId: PropTypes.func.isRequired,
    getPromotionServicesByStoreId: PropTypes.func.isRequired,
  };

  state = {
    storeId: "",
    promotionProducts: {},
    promotionServices: {},
    fetchingPromotionProducts: false,
    fetchingPromotionServices: false,
    fetchedPromotionProducts: false,
    fetchedPromotionServices: false,
  };

  getStoreByName = () => {
    const {
      params: { storeId },
    } = this.props.match;

    this.props.getStoreInfo({ store: storeId }).then((response) => {
      this.getPromotionProducts();
    });
  };

  getPromotionProducts = () => {
    const {
      params: { storeId },
    } = this.props.match;

    this.setState({
      fetchingPromotionProducts: true,
      promotionProducts: this.props.stores.promotionProducts[storeId] || {},
    });

    this.props
      .getPromotionProductsByStoreId({ store: storeId })
      .then((response) => {
        this.setState({
          promotionProducts: this.props.stores.promotionProducts[storeId],
          fetchingPromotionProducts: false,
          fetchedPromotionProducts: true,
        });
      })
      .catch((errors) => {
        this.setState({ fetchingPromotionProducts: false });
      });
  };

  getPromotionServices = () => {
    const {
      params: { storeId },
    } = this.props.match;

    this.setState({
      fetchingPromotionServices: true,
      promotionServices: this.props.stores.promotionServices[storeId] || {},
    });

    this.props
      .getPromotionServicesByStoreId({ store: storeId })
      .then((response) => {
        this.setState({
          promotionServices: response.data,
          fetchingPromotionServices: false,
          fetchedPromotionServices: true,
        });
      })
      .catch((errors) => {
        this.setState({ fetchingPromotionServices: false });
      });
  };

  getAllPromotions = () => {
    this.getStoreByName();
  };

  handlePageClick = (data) => {
    this.setState(
      {
        page: data.selected + 1,
      },
      () => {
        this.props.history.push({
          search: `?page=${this.state.page}`,
        });
      }
    );
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location) => {
      this.getAllPromotions({
        page: parseInt(queryString.parse(location.search).page, 10),
      });
    });

    this.getAllPromotions({ page: this.state.page });

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.location.search && prevState.page !== 1) {
      this.setState({ page: 1 });
    }
    if (
      prevProps.location.search &&
      isNumberic(queryString.parse(prevProps.location.search).page) &&
      parseInt(queryString.parse(prevProps.location.search).page, 10) !==
      parseInt(prevState.page, 10)
    ) {
      this.setState({
        page: parseInt(queryString.parse(prevProps.location.search).page, 10),
      });
    }
  }

  render() {
    const {
      promotionProducts,
      fetchedPromotionProducts,
      fetchingPromotionProducts,
    } = this.state;

    return (
      <>
        <Pane className="tab-heading tab-heading-stores promotion-before" id="anchor">
          <h3 className="heading-title mt-0" id="anchor">
            <FormattedMessage id="promotions" defaultMessage="Promotions" />
          </h3>
        </Pane>
        <Pane className="products-grids">
          {!fetchingPromotionProducts && fetchedPromotionProducts ? (
            <>
              {isEmpty(promotionProducts.results) && <Empty type="white" />}
              {!isEmpty(promotionProducts.results) &&
                <StorePromotionList
                  promotions={promotionProducts.results}
                  type="products"
                />
              }
              <Pane className="clearfix" />
            </>
          ) : (
            <LoadingSearch type="white" />
          )}
        </Pane>
        <Pane className="pagination-bar text-center">
          <nav className="d-inline-b">
            {promotionProducts.totalPages > 1 ? (
              <ReactPaginate
                initialPage={this.state.page - 1}
                forcePage={this.state.page - 1}
                previousLabel={
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/pagination/icon-prev.svg`
                    }
                    alt="icon-prev"
                    className="icon icon-prev"
                  />
                }
                nextLabel={
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/pagination/icon-next.svg`
                    }
                    alt="icon-prev"
                    className="icon icon-next"
                  />
                }
                breakLabel={<button className="btn-break-paging">...</button>}
                breakClassName={"break-me"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                pageCount={promotionProducts.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                disableInitialCallback={true}
              />
            ) : null}
          </nav>
        </Pane>
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  stores: state.stores,
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  searchStores: bindActionCreators(searchStores, dispatch),
  getPromotionProductsByStoreId: bindActionCreators(
    getPromotionProductsByStoreId,
    dispatch
  ),
  getPromotionServicesByStoreId: bindActionCreators(
    getPromotionServicesByStoreId,
    dispatch
  ),
  getStoreInfo: bindActionCreators(getStoreInfo, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(StorePromotions);
