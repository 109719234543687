import React from 'react'
import { withRouter } from 'react-router-dom';
import { MasterLayout } from 'components/layout'
import { Button } from 'semantic-ui-react'
import { Pane } from 'shared/components/layouts'
import queryString from 'query-string';
import axios from 'axios';
import Config from 'constants/Config';
import { Cookies } from 'react-cookie';

class VerifyEmail extends React.Component {

	state = {
		loading: false
	}

	onRedirect = () => {
		this.props.history.push(`/signin`);
	};

	verify = async () => {
		const cookies = new Cookies();
		let token = queryString.parse(this.props.location.search).token;
		let email = queryString.parse(this.props.location.search).email;
		if (token && email) {
			this.setState({
				loading: true,
			});
			await axios({
				method: 'GET',
				headers: {
					Authorization: `Bearer ${cookies.get('accessToken')}`
				},
				url: `${Config.NEW_API_URL}/email-verification/check/${token}/${email}`,
			})
				.then((response) => {
					this.setState({
						loading: false,
					});
				})
				.catch(() => {
					this.setState({
						loading: false,
					});
					this.props.history.replace(`/page-not-found`);
				});
		} else {
			this.props.history.replace(`/page-not-found`);
		}
	}

	componentDidMount() {
		this.verify();
	}

	render() {
		let { loading } = this.state;
		return (
			<MasterLayout>
				<Pane className='container not-found-container'>
					<Pane className='page-not-found-content text-center'>
						<h1>Welcome !</h1>
						<Pane className="mb-4">
							<img width="20%" aria-hidden src="/assets/images/logo-white.png" alt="image" />
						</Pane>
						{!loading ? (
							<Button onClick={() =>
								this.onRedirect()
							} color='blue' content="Your account verified, now let's explore Cardbey" />
						) : (
							<h3>Verifying...</h3>
						)}
					</Pane>
				</Pane>
			</MasterLayout>
		)
	}
}

export default withRouter(VerifyEmail)
