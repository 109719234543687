import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { Checkout } from 'components/scenes/cart/checkout';
import { updateInfo, checkout, removeAllCart } from 'actions/cart';
import { Pane } from 'shared/components/layouts';
import { getUserInfo } from 'actions/auth';
import { checkCode } from "actions/customer";
class CartPage extends Component {
  static propTypes = {
    checkout: PropTypes.func.isRequired,
    updateInfo: PropTypes.func.isRequired,
    removeAllCart: PropTypes.func,
  };

  render() {
    const { cart } = this.props;
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <Checkout
            auth={this.props.auth}
            cart={cart}
            history={this.props.history}
            onUpdateInfo={this.props.updateInfo}
            onCheckout={this.props.checkout}
            onRemoveAllCart={this.props.removeAllCart}
            getUserInfo={this.props.getUserInfo}
            checkCode={this.props.checkCode}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

const bindDispatchToProps = (dispatch) => ({
  checkout: bindActionCreators(checkout, dispatch),
  updateInfo: bindActionCreators(updateInfo, dispatch),
  removeAllCart: bindActionCreators(removeAllCart, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  checkCode: bindActionCreators(checkCode, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(CartPage);
