import React, { Component } from "react";
import { isEmpty } from "lodash";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Pane } from "shared/components/layouts";
import { Scrollbars } from "react-custom-scrollbars";
import List from "./List";

class Slideshow extends Component {
  render() {
    const { data } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      auto: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const arrayVideos = [];
    const arrayProducts = [];
    const extImage = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];

    const slideItem =
      !isEmpty(data) &&
      data.filter((item) => item.primaryImage).map((item, index) => {
        const ext =
          item.primaryImage &&
          item.primaryImage.substr(item.primaryImage.lastIndexOf(".") + 1);
        if (extImage.indexOf(ext) >= 0) {
          arrayProducts.push(item);
        } else {
          arrayVideos.push(item);
        }
        return (
          <Pane className="list-slider" key={index}>
            <div className="product-heading">
              <h3
                className="block-title text-uppercase"
                style={{ marginRight: "auto", fontWeight: "bold" }}
              >
                {item.name}
              </h3>
            </div>
            <Pane className="slider-content-main">
              {extImage.indexOf(ext) >= 0 ? ( // Image
                <Link to={`/${item.type}/${item.slug}`} key={index}>
                  <img
                    src={`${item.primaryImage}`}
                    alt="banner2"
                    className="banner-image-over"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                  />
                </Link>
              ) : (
                // Video
                <Pane>
                  {item.primaryImage && (
                    <video
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                      }}
                      controls
                      className="banner-image-over"
                    >
                      <source src={item.primaryImage} />
                      Your browser does not support HTML5 video.
                    </video>
                  )}
                </Pane>
              )}
            </Pane>
            {item.viewVideo !== null && (
              <Pane className="d-flex justify-content-end mt-1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/icons/view.png"}
                  alt="image"
                  aria-hidden
                  className="mr-1"
                  style={{
                    cursor: "pointer",
                    width: 21,
                    height: 21,
                  }}
                />
                <p className="mr-2">{item.viewVideo} views -</p>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/like-gray.svg"
                  }
                  alt="image"
                  aria-hidden
                  className="mr-1"
                  style={{
                    cursor: "pointer",
                    width: 17,
                    height: 17,
                  }}
                />
                <p className="mr-2">{item.likeVideo} likes</p>
              </Pane>
            )}
          </Pane>
        );
      });

    return (
      <Pane className="row video-container store_overview" style={{ boxShadow: "none" }}>
        <Slider
          className="videos-slider col-xl-8 col-lg-8 col-sm-12 col-12 p-lg-0"
          {...settings}
        >
          {slideItem}
        </Slider>
        <Pane className="video-list col-xl-4 col-lg-4 col-sm-12 col-12 p-lg-0">
          <div className="product-heading">
            <h3
              className="block-title text-uppercase"
              style={{ marginRight: "auto" }}
            >
              Collection
            </h3>
          </div>
          {arrayVideos.length > 0 && (
            <Scrollbars
              style={{
                height: "83%",
                minHeight: "300px",
              }}
            >
              <List data={arrayVideos} />
            </Scrollbars>
          )}
          {arrayVideos.length === 0 &&
            arrayProducts.length > 0 && (
              <Scrollbars
                style={{
                  height: "90%",
                  minHeight: "300px",
                }}
              >
                <List data={arrayProducts} />
              </Scrollbars>
            )}
        </Pane>
      </Pane>
    );
  }
}

export default Slideshow;
