import * as OptionGroupAddV1 from "./option-group.add.v1.action";
import * as OptionGroupEditV1 from "./option-group.edit.v1.action";
import * as OptionGroupDeleteV1 from "./option-group.delete.v1.action";

export default {
  add: {
    callAPI: (params) => OptionGroupAddV1.exec(params),
    parseResponse: (response) => OptionGroupAddV1.parseResponse(response),
  },
  edit: {
    callAPI: (params) => OptionGroupEditV1.exec(params),
    parseResponse: (response) => OptionGroupEditV1.parseResponse(response),
  },
  delete: {
    callAPI: (params) => OptionGroupDeleteV1.exec(params),
    parseResponse: (response) => OptionGroupDeleteV1.parseResponse(response),
  },
};
