import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import ReactPaginate from 'react-paginate';
import { FormattedMessage } from 'react-intl';
import { Pane, Loading } from 'shared/components/layouts';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  getPromotionProductsByUserId,
  getPromotionServicesByUserId,
  boostPromotion,
} from 'actions/myPromotions';
import PromotionList from './PromotionList';
import { isNumberic, formatRoute } from 'shared/helpers';
import { Empty } from 'components/common/empty';

class Promotion extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    promotionProducts: PropTypes.object.isRequired,
    promotionServices: PropTypes.object.isRequired,
    getPromotionProductsByUserId: PropTypes.func.isRequired,
    getPromotionServicesByUserId: PropTypes.func.isRequired,
    boostPromotion: PropTypes.func.isRequired,
  };

  state = {
    page: parseInt(queryString.parse(this.props.location.search).page, 10) || 1,
  };

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  getAllPromotions = (params) => {
    this.props.getPromotionProductsByUserId({
      store: this.props.userInfo.id,
      page: params.page,
    });
    // this.props.getPromotionServicesByUserId({ store: this.props.userInfo.id })
  };

  handlePageClick = (data) => {
    this.setState(
      {
        page: data.selected + 1,
      },
      () => {
        this.props.history.push({
          search: `?page=${this.state.page}`,
        });
      }
    );
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location) => {
      this.getAllPromotions({
        page: parseInt(queryString.parse(location.search).page, 10),
      });
    });

    this.getAllPromotions({ page: this.state.page });

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.location.search && prevState.page !== 1) {
      this.setState({ page: 1 });
    }
    if (
      prevProps.location.search &&
      isNumberic(queryString.parse(prevProps.location.search).page) &&
      parseInt(queryString.parse(prevProps.location.search).page, 10) !==
        parseInt(prevState.page, 10)
    ) {
      this.setState({
        page: parseInt(queryString.parse(prevProps.location.search).page, 10),
      });
    }
  }

  render() {
    const { userInfo, promotionProducts } = this.props;
    const name =
      userInfo && userInfo.displayName ? formatRoute(userInfo.displayName) : '';

    return (
      <Pane className="overview-container" id="anchor">
        <Pane className="product-container">
          <Link
            to={`/u/${name}/promotions/create`}
            className="btn btn-circle btn-circle-primary float-right"
          >
            <FormattedMessage
              id="create_new_promotion"
              defaultMessage="Create new a promotion"
            />
          </Link>
          <h3 className="block-title pt10 text-uppercase">
            <FormattedMessage id="promotions" defaultMessage="Promotions" />
          </h3>
          <Pane className="clearfix" />
          <Pane className="products-grids">
            {!promotionProducts.fetched && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {!isEmpty(promotionProducts.results) ? (
              <PromotionList
                promotions={promotionProducts.results}
                userInfo={this.props.userInfo}
                history={this.props.history}
                boostPromotion={this.props.boostPromotion}
                type="products"
              />
            ) : (
              <Empty />
            )}
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {promotionProducts.totalPages > 1 ? (
                <ReactPaginate
                  initialPage={this.state.page - 1}
                  forcePage={this.state.page - 1}
                  previousLabel={
                    <FormattedMessage
                      id="pagination.previous"
                      defaultMessage="previous"
                    />
                  }
                  nextLabel={
                    <FormattedMessage
                      id="pagination.next"
                      defaultMessage="next"
                    />
                  }
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={'break-me'}
                  previousClassName={'page-item'}
                  nextClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  pageCount={promotionProducts.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  disableInitialCallback={true}
                />
              ) : null}
            </nav>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  promotionProducts: state.promotionProducts,
  promotionServices: state.promotionServices,
});

const bindDispatchToProps = (dispatch) => ({
  getPromotionProductsByUserId: bindActionCreators(
    getPromotionProductsByUserId,
    dispatch
  ),
  getPromotionServicesByUserId: bindActionCreators(
    getPromotionServicesByUserId,
    dispatch
  ),
  boostPromotion: bindActionCreators(boostPromotion, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(Promotion);
