import * as types from 'constants/ActionTypes';
import api from 'api';

// Products
export const getDigitalsByUserId = (params) => (dispatch) => dispatch({
  types: [types.RECEIVE_MY_DIGITALS, types.RECEIVE_MY_DIGITALS_SUCCESS, types.RECEIVE_MY_DIGITALS_FAILURE],
  payload: {
    request: api.user.digitals(params)
  }
});

// Edit product detail
export const deleteMyProduct = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_DIGITAL,
  payload: {
    request: api.user.digitalRemove(params)
  }
});

// Create a digital
export const createMyDigital = (params) => (dispatch) => dispatch({
  type: types.CREATE_MY_DIGITAL,
  payload: {
    request: api.user.digitalAdd(params)
  }
});

// Get product detail
export const getMyDigitalDetail = (params) => (dispatch) => dispatch({
  type: types.MY_DIGITAL_DETAIL,
  payload: {
    request: api.user.digitalRead(params)
  }
});

// Edit digital detail
export const editMyDigital = (params) => (dispatch) => dispatch({
  type: types.EDIT_MY_DIGITAL,
  payload: {
    request: api.user.DigitalEdit(params)
  }
});



