import React, { Component } from "react";
import { Pane, Loading } from "shared/components/layouts";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { Dimmer, Icon } from "semantic-ui-react";
class AssignTaskModal extends Component {
  state = {
    search: "",
    loading: false,
  };
  getStaffsAssigned = () => {
    return this.props.memberTasks.map((member) => (
      <Pane className="item-staff-assigned" key={member.id}>
        <Pane className="staff-avatar">
          <img
            src={
              member.staff.images && member.staff.images.length
                ? member.staff.images[0].url
                : process.env.PUBLIC_URL + "/assets/images/default-avatar.png"
            }
            className="staff-avatar__img"
          />
        </Pane>
        <Pane className="staff-info">
          <span className="staff-info__fullname">{member.staff.fullname}</span>
          <span onClick={() => this.removeMemeberFromTask(member.id)}>
            <Icon className="staff-icon__close" name="close" />
          </span>
        </Pane>
      </Pane>
    ));
  };
  removeMemeberFromTask = (id) => {
    this.setState({ loading: true });
    this.props.removeMyTaskMember({ id }).then((res) => {
      this.props.reloadData(this.props.taskId);
      this.setState({ loading: false });
    });
  };
  assignTaskToStaff = (staff) => {
    this.setState({ loading: true });
    const data = new FormData();
    data.append("task_id", this.props.taskId);
    data.append("staff_id", staff.id);
    this.props.createMyTaskMember(data).then((res) => {
      this.props.reloadData(this.props.taskId);
      this.setState({ loading: false });
    });
  };
  getUnassignStaffs = () => {
    return this.props.assignStaffs
      .filter((staff) => staff.fullname.indexOf(this.state.search) !== -1)
      .map((staff) => (
        <Pane className="item-staff-not-assigned" key={staff.id}>
          <Pane className="container-info-staff">
            <Pane className="staff-not-assigned__img">
              <img
                width={60}
                height={60}
                src={
                  staff.images && staff.images.length
                    ? staff.images[0].url
                    : process.env.PUBLIC_URL +
                      "/assets/images/default-avatar.png"
                }
                className="staff-avatar-not-assigned__img"
              />
            </Pane>
            <Pane className="staff-not-assigned__info">
              <span className="staff-not-assigned__name">{staff.fullname}</span>
              <span className="staff-not-assgined__position">
                {staff.phone}
              </span>
            </Pane>
          </Pane>
          <Pane className="item-staff-not-assigned__control product-container">
            <button
              className="btn btn-circle-primary"
              onClick={() => this.assignTaskToStaff(staff)}
            >
              Assign task
            </button>
          </Pane>
        </Pane>
      ));
  };
  render() {
    const { openModal, onCloseModal } = this.props;
    return (
      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        showCloseIcon={true}
      >
        {this.state.loading ? <Loading /> : null}
        <Pane
          className="login-container popup-assign-task"
          style={{ minWidth: "470px" }}
        >
          <Pane className="modal-assign-task">
            <Pane className="modal-assign-task__header">
              <span className="modal-assign-task__header__title">
                {this.props.taskName} - Team member
              </span>
            </Pane>
            <Pane className="list-staffs-assigned">
              {this.getStaffsAssigned()}
            </Pane>
            <Pane className="container-search-staff">
              <Icon className="staff-icon__search" name="search" size="small" />
              <input
                type="text"
                placeholder="Filter staff name"
                className="staff_input__search"
                onChange={(e) =>
                  this.setState({ search: e.target.value || "" })
                }
              />
            </Pane>
            <Pane className="list-staffs-not-assigned">
              {this.getUnassignStaffs()}
            </Pane>
          </Pane>
        </Pane>
      </Modal>
    );
  }
}
export default withRouter(injectIntl(AssignTaskModal));