import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';

class Empty extends Component {
  render() {
    if (this.props.type === 'white') {
      return (
        <Pane className="empty-container empty-container-white">
          <Pane className="empty-content">
            <img
              src={process.env.PUBLIC_URL + '/assets/images/icons/empty.png'}
              alt="empty"
              className="img-empty"
            />
            <h2 className="empty-title">No items</h2>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className="empty-container mt-3">
        <Pane className="empty-content">
          <img
            src={process.env.PUBLIC_URL + '/assets/images/icons/empty.png'}
            alt="empty"
            className="img-empty"
          />
          <h2 className="empty-title">No items</h2>
        </Pane>
      </Pane>
    );
  }
}

Empty.propTypes = {};

export default Empty;
