import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import ReactTooltip from 'react-tooltip';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';

class ListItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    html_code: PropTypes.string,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    play: 0,
  };

  changeRoute = () => {
    this.props.history.push(`${this.props.type}/${this.props.id}/edit`);
  };

  showConfirmDelete = () => {
    const { intl } = this.props;

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({
        id: 'notification.are_you_sure_you_want_to_delete_this',
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.deleteItem();
          },
        },
        {
          label: intl.formatMessage({ id: 'no' }),
        },
      ],
    });
  };

  deleteItem = () => {
    this.props.onDelete({ id: this.props.id });
  };

  render() {
    const { item } = this.props;
    return (
      <Pane className="col-lg-3 col-md-4 col-sm-6 col-12">
        <Pane className="adds-item">
          <Pane className="adds-content">
            <Pane
              dangerouslySetInnerHTML={{
                __html: item.htmlCode,
              }}
            />
            {this.props.control && (
              <Pane className="adds-actions">
                <span onClick={this.changeRoute} data-tip data-for="edit">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/edit.svg`
                    }
                    alt="cart"
                    className="icon-edit"
                  />
                </span>
                <span
                  onClick={this.showConfirmDelete}
                  data-tip
                  data-for="remove"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/delete.svg`
                    }
                    alt="cart"
                    className="icon-delete"
                  />
                </span>
              </Pane>
            )}
          </Pane>
          <ReactTooltip place="top" type="dark" effect="solid" id="edit">
            <FormattedMessage id="edit" defaultMessage="Edit" />
          </ReactTooltip>
          <ReactTooltip place="top" type="dark" effect="solid" id="remove">
            <FormattedMessage id="delete" defaultMessage="Delete" />
          </ReactTooltip>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
