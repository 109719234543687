import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, Radio, Button, Checkbox } from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { StripeProvider, Elements } from "react-stripe-elements";
import { Pane } from "shared/components/layouts";
import CartList from "./CartList";
import Config from "constants/Config";
import { pickBy, identity, get, isEmpty } from "lodash";
import { InlineMessage } from "shared/components";
import { handleError } from "shared/helpers";
import TotalPayment from "components/scenes/cart/TotalPayment";
import CartEmpty from "components/scenes/cart/CartEmpty";
import PaymentForm from "./PaymentForm";
import PhoneInput from 'react-phone-input-2';
import NotificationSystem from "react-notification-system";
import Iframe from "react-iframe";

class Checkout extends Component {
  static propTypes = {
    auth: PropTypes.object,
    cart: PropTypes.object,
    onRemoveAllCart: PropTypes.func,
    onCheckout: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
  }

  state = {
    orderId: "",
    deliveryAddress: "",
    deliveryPostCode: "",
    payAtStore: "",
    note: "",
    paymentMethod: Config.PAYMENT_METHOD.PAY_BY_CREDIT,
    errors: {},
    checked: false,
    loading: false,
    isShipToMyAddress: false,
    guestName: "",
    guestEmail: "",
    guestPhone: "",
    guestNote: "",
    promotionCode: "",
    discountPercent: false,
    maxDiscount: 0,
    lat: "",
    lng: "",
    start_location: "",
    end_location: "",
    start_postcode: 3031,
    end_postcode: 3127,
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeRadio = (e, { value }) => this.setState({ paymentMethod: value });

  onChangePhone = (phone) => {
    this.setState({
      guestPhone: phone
    });
  };

  onChangeAddress = (address) => {
    this.setState({
      deliveryAddress: address,
    },
      () => {
        geocodeByAddress(address)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            this.setState((prevState) => ({
              lat: latLng.lat,
              lng: latLng.lng,
              end_location: `${latLng.lat},${latLng.lng}`,
            }));
          })
          .catch(() => { });
      });
  };

  validate = async (data) => {
    const errors = {};
    const { cart, intl, auth } = this.props;

    const cardElementContainer = document.getElementsByClassName('StripeElement')[0];
    if (cardElementContainer && this.state.paymentMethod === Config.PAYMENT_METHOD.PAY_BY_CREDIT) {
      let cardElementEmpty = cardElementContainer.classList.contains('StripeElement--empty');
      let cardElementInvalid = cardElementContainer.classList.contains('StripeElement--invalid');
      if (cardElementInvalid || cardElementEmpty) {
        errors.creditCard = intl.formatMessage({
          id: "validate.invalid_credit_info",
        });
      }
    }

    if(data.promotionCode !=''){
        await this.props.checkCode({discount_code: data.promotionCode}).then((response) => {
      }).catch((error) => {
        errors.promotionCode = 'Code does not exist in store or has been used.'
      });
    }
    if (!data.paymentMethod) {
      errors.paymentMethod = intl.formatMessage({
        id: "validate.please_select_a_payment_method",
      });
    }

    if (!cart.payAtStore) {
      if (!data.deliveryAddress) {
        errors.deliveryAddress = intl.formatMessage({
          id: "validate.require_field",
        });
      }
      if (!data.deliveryPostCode) {
        errors.deliveryPostCode = intl.formatMessage({
          id: "validate.require_field",
        });
      }

      if (!data.end_location && data.deliveryAddress) {
        errors.end_location = "Please recheck delivery address or pick address suggest under field input after fill your address";
      }
    }

    if (!auth.isAuthenticated) {
      if (!data.guestName) {
        errors.guestName = intl.formatMessage({
          id: "validate.require_field",
        });
      }
      if (!data.guestPhone) {
        errors.guestPhone = intl.formatMessage({
          id: "validate.require_field",
        });
      }
    }

    return errors;
  };

  payment = (token) => {
    const { intl, auth } = this.props;

    const params = {
      token_stripe: token,
    };

    if (!this.props.auth.isAuthenticated) {
      params.guestName = this.state.guestName;
      params.guestEmail = this.state.guestEmail;
      params.guestPhone = this.state.guestPhone;
      params.guestNote = this.state.guestNote;
      params.mode = "GUEST";
    }
    params.promotionCode = this.state.promotionCode;
    this.setState({ loading: true });

    this.props
      .onCheckout(params)
      .then((response) => {
        this.setState({ loading: false });

        this.props.onRemoveAllCart();
        if (auth.isAuthenticated) {
          this.props.getUserInfo({
            userId: get(auth, "user.id"),
          });
        }
        if (this.props.history) {
          this.props.history.push(
            `/checkout-success?orderId=${response.data.data.id}`
          );
        } else {
          window.location.href = `/checkout-success?orderId=${response.data.data.id
            }`;
        }
      })
      .catch((errors) => {
        this.setState({ loading: false });

        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };
  onClearDiscountCode = () => {
    this.setState({ errors: { ...this.state.errors, promotionCode: '' },
        discountPercent: false,
        promotionCode: '',
        maxDiscount:0
  });
  }
  onApplyDiscountCode = () => {

    if(this.state.promotionCode !='') {
      this.props.checkCode({discount_code: this.state.promotionCode}).then((response) => {
        const userId = get(this.props.cart, "items[0].userId");
        if(userId == response.data.data.attributes.userId && response.data.data.attributes.codeExpired == false){
          this.setState({
            errors: { ...this.state.errors, promotionCode: '' },
            discountPercent: response.data.data.attributes.discount,
            maxDiscount: response.data.data.attributes.maxDiscount,
           });
        } else {
          this.setState({ errors: { ...this.state.errors, promotionCode: 'Code does not exist in store' }, discountPercent: false });
        }
      }).catch((error) => {
        this.setState({ errors: { ...this.state.errors, promotionCode: 'Code does not exist in store or has been used.'  }, discountPercent: false});
      });
    }

  }
  createCreditCardForm = async () => {
    const { intl } = this.props;

    try {
      const { token } = await this.requestPaymentToken();
      if (token && token.id) {
        this.payment(token.id);
      }
    } catch (e) {
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: "notification.please_try_again" }),
        level: "error",
      });
    }
  };

  checkout = async () => {
    const errors = await this.validate(this.state) || {};
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.requestCheckout();
    }
  };

  requestCheckout = () => {
    this.setState({ loading: true });
    const data = {
      deliveryAddress: "",
      deliveryPostCode: "",
      paymentMethod: this.state.paymentMethod === "CASH" ? "CASH" : "",
      payAtStore: this.props.cart.payAtStore,
    };

    if (!this.props.cart.payAtStore) {
      data.deliveryAddress = this.state.deliveryAddress;
      data.deliveryPostCode = this.state.deliveryPostCode;
    }


    const params = {
      guestName: "",
      guestEmail: "",
      guestPhone: "",
      guestNote: "",
      mode: "",
      note: this.state.note,
      promotionCode: this.state.promotionCode
    }

    if (!this.props.auth.isAuthenticated) {
      params.guestName = this.state.guestName;
      params.guestEmail = this.state.guestEmail;
      params.guestPhone = this.state.guestPhone;
      params.guestNote = this.state.guestNote;
      params.mode = "GUEST";
    }

    this.props.onUpdateInfo(data).then(() => {
      if (this.state.paymentMethod === Config.PAYMENT_METHOD.PAY_BY_CASH) {
        this.props
          .onCheckout(params)
          .then((response) => {
            this.props.onRemoveAllCart();
            if (this.props.history) {
              this.props.history.push(
                `/checkout-success?orderId=${response.data.data.id}`
              );
            } else {
              window.location.href = `/checkout-success?orderId=${response.data.data.id
                }`;
            }
          })
          .catch((errors) => {
            this.setState({ loading: false });
          });
      } else {
        this.createCreditCardForm();
        this.setState({ loading: false });
      }
    });
  };

  toggle = () => {
    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        if (this.state.checked) {
          const { address, postCode } = this.props.auth.user;
          const latEnd = get(this.props.auth.user, "lat");
          const lngEnd = get(this.props.auth.user, "lng");
          this.setState({
            deliveryAddress: address,
            deliveryPostCode: postCode,
            end_location: `${latEnd},${lngEnd}`,
          });
        }
      }
    );
  };

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;

    const { cart } = this.props;
    this.onSetLatLng(cart);

    const deliveryInfo = JSON.parse(localStorage.getItem("deliveryInfo"));

    if (deliveryInfo) {
      this.setState({
        orderId: deliveryInfo.orderId,
        deliveryAddress: deliveryInfo.deliveryAddress,
        deliveryPostCode: deliveryInfo.deliveryPostCode,
        paymentMethod: this.props.cart.allowPayAtStore
          ? deliveryInfo.paymentMethod
          : "",
      });
    }

    if (!this.props.cart.payAtStore) {
      this.setState({
        paymentMethod: Config.PAYMENT_METHOD.PAY_BY_CREDIT
      })
    }
  }

  onSetLatLng = (cart) => {
    if (!isEmpty(cart.items)) {
      const latStart = get(cart, "items[0].lat");
      const lngStart = get(cart, "items[0].lng");
      const postCodeStart = get(cart, "items[0].postCode");
      this.setState({
        start_location: `${latStart},${lngStart}`,
        start_postcode: postCodeStart,
      });
    }
  };

  convertParamSearch = (search) => {
    const arr = [];
    Object.keys(pickBy(search, identity)).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(pickBy(search, identity), key)) {
        arr.push(`${key}=${pickBy(search, identity)[key]}`);
      }
    });
    return arr.join("&");
  };

  isPickup = (items) => {
    const pickup = items.find(
      (item) =>
        item.deliveryMethod === Config.DELIVERY_METHOD.PICKUP ||
        item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN ||
        item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
    );
    return !!pickup;
  };

  isDelivery = (items) => {
    const delivery = items.find(
      (item) =>
        item.deliveryMethod === Config.DELIVERY_METHOD.DELIVERY
    );
    return !!delivery;
  };

  isDineIn = (items) => {
    const dine_in = items.find(
      (item) =>
        item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
    );
    return !!dine_in;
  };

  render() {
    const { cart, auth, intl } = this.props;
    const { errors, start_location, end_location, start_postcode, deliveryPostCode } = this.state;
    const renderFunc = ({
      getInputProps,
      getSuggestionItemProps,
      suggestions,
    }) => (
      <Pane className="autocomplete-root">
        <input {...getInputProps()} className="form-input" />
        <Pane className="autocomplete-dropdown-container">
          {suggestions.map((suggestion) => (
            <Pane {...getSuggestionItemProps(suggestion)}>
              <span>{suggestion.description}</span>
            </Pane>
          ))}
        </Pane>
      </Pane>
    );

    return (
      <Pane className="checkout-container pt-5 pb-5">
        <Pane className="container">
          {cart.items && cart.items.length > 0 ? (
            <>
              <Pane className="row mb-4">
                <Pane className="col-lg-5 col-12 mt-lg-0 mt-3">
                  <Link to="/cart" className="btn btn-process back_to_cart">
                    <img
                      src={process.env.PUBLIC_URL + '/assets/images/layouts/cart.svg'}
                      alt="lock"
                      className="icon"
                    />
                    <FormattedMessage
                      id="cart.back_to_cart"
                      defaultMessage="Back to cart"
                    />
                  </Link>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-5">
                  <Pane className="checkout-content">
                    {auth.isAuthenticated && (
                      <Pane className="checkout-info">
                        <h2 className="checkout-title mb-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/cart/user.svg`
                            }
                            alt="users"
                            className="icon"
                          />
                          <FormattedMessage
                            id="customer_information"
                            defaultMessage="Customer Information"
                          />
                        </h2>
                        <p className="check-label">
                          <FormattedMessage
                            id="full_name"
                            defaultMessage="Full name"
                          />
                          :{" "}
                          <span className="check-name">
                            {auth.user.displayName}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="email" defaultMessage="Email" />
                          :<span className="check-name">{auth.user.email}</span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="phone" defaultMessage="Phone" />
                          :
                          <span className="check-name">
                            {auth.user.phoneNumber}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="address"
                            defaultMessage="Address"
                          />
                          :
                          <span className="check-name">
                            {auth.user.address}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="post_code"
                            defaultMessage="Post code"
                          />
                          :{" "}
                          <span className="check-name">
                            {auth.user.postCode}
                          </span>
                        </p>
                      </Pane>
                    )}
                    {this.isDelivery(cart.items) && (
                      <Pane className="checkout-info">
                        <h2 className="checkout-title">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/cart/location.svg`
                            }
                            alt="users"
                            className="icon"
                          />
                          <FormattedMessage
                            id="shipping_address"
                            defaultMessage="SHIPPING ADDRESS"
                          />
                        </h2>
                        {auth.isAuthenticated && (
                          <Checkbox
                            label={intl.formatMessage({
                              id: "ship_to_my_address",
                              defaultMessage: "Ship to my address",
                            })}
                            onChange={this.toggle}
                            checked={this.state.checked}
                            className="label-checkbox"
                          />
                        )}

                        <Form className="form-checkout">
                          <Form.Field
                            error={!!errors.deliveryAddress}
                            className="form-item"
                          >
                            <label
                              htmlFor="deliveryAddress"
                              className="form-label"
                            >
                              <FormattedMessage
                                id="delivery_address"
                                defaultMessage="Delivery Address"
                              />
                            </label>
                            <PlacesAutocomplete
                              value={this.state.deliveryAddress}
                              onChange={this.onChangeAddress}
                            >
                              {renderFunc}
                            </PlacesAutocomplete>
                            {errors.deliveryAddress && (
                              <InlineMessage text={errors.deliveryAddress} />
                            )}
                            {errors.end_location && (
                              <InlineMessage text={errors.end_location} />
                            )}
                          </Form.Field>
                          {!this.state.checked && (
                            <>
                              <Form.Field
                                error={!!errors.deliveryPostCode}
                                className="form-item"
                              >
                                <label
                                  htmlFor="deliveryPostCode"
                                  className="form-label"
                                >
                                  <FormattedMessage
                                    id="post_code1"
                                    defaultMessage="Delivery PostCode"
                                  />
                                </label>
                                <input
                                  type="text"
                                  id="deliveryPostCode"
                                  name="deliveryPostCode"
                                  placeholder=""
                                  className="form-input"
                                  value={this.state.deliveryPostCode}
                                  onChange={this.onChange}
                                />
                                {errors.deliveryPostCode && (
                                  <InlineMessage
                                    text={errors.deliveryPostCode}
                                  />
                                )}
                              </Form.Field>
                            </>
                          )}
                        </Form>
                      </Pane>
                    )}
                    {!auth.isAuthenticated && (
                      <Pane className="checkout-info">
                        <Form className="form-checkout">
                          <Form.Field
                            error={!!errors.guestName}
                            className="form-item"
                          >
                            <label
                              htmlFor="guestName"
                              className="form-label"
                            >
                              <FormattedMessage
                                id="your_name"
                                defaultMessage="Your Name"
                              />
                            </label>
                            <input
                              type="text"
                              id="guestName"
                              name="guestName"
                              placeholder=""
                              className="form-input"
                              value={this.state.guestName}
                              onChange={this.onChange}
                            />
                            {errors.guestName && (
                              <InlineMessage text={errors.guestName} />
                            )}
                          </Form.Field>
                          <Form.Field
                            error={!!errors.guestPhone}
                            className="form-item"
                          >
                            <label
                              htmlFor="guestPhone"
                              className="form-label"
                            >
                              <FormattedMessage
                                id="your_phone"
                                defaultMessage="Your Phone"
                              />
                            </label>
                            <PhoneInput
                              country={'au'}
                              value={this.state.guestPhone}
                              onChange={(phone) => this.onChangePhone(phone)}
                              style={{ width: '100%' }}
                              placeholder={intl.formatMessage({ id: 'phone' })}
                            />
                            {errors.guestPhone && (
                              <InlineMessage text={errors.guestPhone} />
                            )}
                          </Form.Field>
                          <Form.Field
                            error={!!errors.guestEmail}
                            className="form-item"
                          >
                            <label
                              htmlFor="guestEmail"
                              className="form-label"
                            >
                              <FormattedMessage
                                id="your_email"
                                defaultMessage="Your Email"
                              />
                            </label>
                            <input
                              type="text"
                              id="guestEmail"
                              name="guestEmail"
                              placeholder=""
                              className="form-input"
                              value={this.state.guestEmail}
                              onChange={this.onChange}
                            />
                            {errors.guestEmail && (
                              <InlineMessage text={errors.guestEmail} />
                            )}
                          </Form.Field>
                          <Form.Field
                            error={!!errors.guestNote}
                            className="form-item"
                          >
                            <label
                              htmlFor="guestNote"
                              className="form-label"
                            >
                              <FormattedMessage
                                id="note"
                                defaultMessage="Note"
                              />
                            </label>
                            <textarea
                              id="guestNote"
                              name="guestNote"
                              value={this.state.guestNote}
                              rows="2"
                              className="form-input"
                              onChange={this.onChange}
                            />
                            {errors.guestNote && (
                              <InlineMessage
                                text={errors.guestNote}
                              />
                            )}
                          </Form.Field>
                        </Form>
                      </Pane>
                    )}
                    {auth.isAuthenticated && (
                      <Pane className="checkout-info">
                        <Form className="form-checkout">
                          <label
                            htmlFor="guestNote"
                            className="form-label"
                          >
                            <FormattedMessage
                              id="note"
                              defaultMessage="Note"
                            />
                          </label>
                          <Form.Field error={!!errors.note}
                            className="form-item">
                            <textarea
                              id="note"
                              name="note"
                              value={this.state.note}
                              rows="2"
                              className="form-input"
                              onChange={this.onChange}
                            />
                            {errors.note && (
                              <InlineMessage
                                text={errors.note}
                              />)}
                          </Form.Field>
                        </Form>
                      </Pane>
                    )}
                    <Pane className="checkout-info">
                      <h2 className="checkout-title">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/cart/discount2.svg`
                          }
                          alt="users"
                          className="icon"
                        />
                         <span>Promotion code</span>
                      </h2>
                      <Pane className="promotion-info">
                      <Form className="form-checkout">
                          <Form.Field
                              error={!!errors.promotionCode}
                              className="form-item"
                            >
                              <label
                                htmlFor="promotionCode"
                                className="form-label"
                              >
                                <span>Promotion Code</span>
                              </label>
                              <input
                                type="text"
                                id="promotionCode"
                                name="promotionCode"
                                placeholder=""
                                className="form-input"
                                value={this.state.promotionCode}
                                onChange={this.onChange}
                              />
                              {errors.promotionCode && (
                                <label className="notification-message show">{errors.promotionCode}</label>
                              )}
                          </Form.Field>
                          {
                            this.state.discountPercent && (
                              <Button className="btn" onClick={()=>this.onClearDiscountCode()}>
                                Clear code
                              </Button>
                            )
                          }
                          {
                            !this.state.discountPercent && (
                              <Button className="btn" onClick={()=>this.onApplyDiscountCode()}>
                                Apply code
                              </Button>
                            )
                          }


                        </Form>
                      </Pane>
                      {errors.promotion && (
                        <InlineMessage text={errors.promotion} />
                      )}
                    </Pane>
                    <Pane className="checkout-info">
                      <h2 className="checkout-title">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/cart/payment.svg`
                          }
                          alt="users"
                          className="icon"
                        />
                        <FormattedMessage
                          id="payment_method"
                          defaultMessage="PAYMENT METHOD"
                        />
                      </h2>
                      <Pane className="choose-payment">
                        {/* {get(cart, "typeCategory") !== "Food" && ( */}
                        <Form.Field className="choose-item mr-3">
                          <Radio
                            label={intl.formatMessage({ id: this.isDineIn(cart.items) ? "pay_at_counter" : "pay_by_cash" })}
                            name="paymentMethod"
                            className="choose-radio"
                            value={Config.PAYMENT_METHOD.PAY_BY_CASH}
                            checked={
                              this.state.paymentMethod ===
                              Config.PAYMENT_METHOD.PAY_BY_CASH
                            }
                            onChange={this.onChangeRadio}
                          // disabled={!cart.payAtStore}
                          />
                          <img
                            src={process.env.PUBLIC_URL + `/assets/images/cash.png`}
                            alt="users"
                            className="icon"
                          />
                        </Form.Field>
                        {/* )} */}
                        <Form.Field className="choose-item">
                          <Radio
                            label={intl.formatMessage({ id: "pay_by_credit" })}
                            name="paymentMethod"
                            className="choose-radio"
                            value={Config.PAYMENT_METHOD.PAY_BY_CREDIT}
                            checked={
                              this.state.paymentMethod ===
                              Config.PAYMENT_METHOD.PAY_BY_CREDIT
                            }
                            onChange={this.onChangeRadio}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/cart/credit.png`
                            }
                            alt="users"
                            className="icon"
                          />
                        </Form.Field>
                        <Pane className="clearfix pb10" />
                      </Pane>
                      {errors.paymentMethod && (
                        <InlineMessage text={errors.paymentMethod} />
                      )}
                    </Pane>
                    {this.state.paymentMethod === Config.PAYMENT_METHOD.PAY_BY_CREDIT && (
                      <Pane className="checkout-info">
                        <h2 className="checkout-title">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/cart/payment.svg`
                            }
                            alt="users"
                            className="icon"
                          />
                          <FormattedMessage
                            id="payment_information"
                            defaultMessage="Payment information"
                          />
                        </h2>
                        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                          <Elements>
                            <PaymentForm
                              requestPaymentToken={(request) =>
                                (this.requestPaymentToken = request)
                              }
                            />
                          </Elements>
                        </StripeProvider>
                        {errors.creditCard && (
                          <InlineMessage text={errors.creditCard} />
                        )}
                      </Pane>
                    )}
                  </Pane>
                </Pane>
                <Pane className="col-lg-7 mt-lg-0 mt-3">
                  {!isEmpty(
                    !isEmpty(cart.items) &&
                    cart.items.filter(
                      (item) =>
                        item.deliveryMethod === Config.DELIVERY_METHOD.DELIVERY
                    )
                  ) && (
                      <>
                        <Pane className="cart-table cart-table-desktop">
                          <h2 className="cart-title mb-3">
                            <FormattedMessage
                              id="delivery"
                              defaultMessage="DELIVERY"
                            />
                          </h2>
                          <CartList
                            cart={
                              !isEmpty(cart.items) &&
                              cart.items.filter(
                                (item) =>
                                  item.deliveryMethod ===
                                  Config.DELIVERY_METHOD.DELIVERY
                              )
                            }
                          />
                        </Pane>
                        <Pane className="cart-table cart-table-responsive">
                          <CartList
                            cart={
                              !isEmpty(cart.items) &&
                              cart.items.filter(
                                (item) =>
                                  item.deliveryMethod ===
                                  Config.DELIVERY_METHOD.DELIVERY
                              )
                            }
                            type="responsive"
                          />
                        </Pane>
                      </>
                    )}
                  {!isEmpty(
                    !isEmpty(cart.items) &&
                    cart.items.filter(
                      (item) =>
                        item.deliveryMethod === Config.DELIVERY_METHOD.PICKUP
                    )
                  ) && (
                      <>
                        <Pane className="cart-table cart-table-desktop mt-3">
                          <h2 className="cart-title mb-3">
                            <FormattedMessage
                              id="pickup"
                              defaultMessage="PICKUP"
                            />
                          </h2>
                          <Pane className="cart-list-content">
                            <CartList
                              cart={
                                !isEmpty(cart.items) &&
                                cart.items.filter(
                                  (item) =>
                                    item.deliveryMethod ===
                                    Config.DELIVERY_METHOD.PICKUP
                                )
                              }
                            />
                          </Pane>
                        </Pane>
                        <Pane className="cart-table cart-table-responsive">
                          <CartList
                            cart={
                              !isEmpty(cart.items) &&
                              cart.items.filter(
                                (item) =>
                                  item.deliveryMethod ===
                                  Config.DELIVERY_METHOD.PICKUP
                              )
                            }
                            type="responsive"
                          />
                        </Pane>
                      </>
                    )}

                  {!isEmpty(
                    !isEmpty(cart.items) &&
                    cart.items.filter(
                      (item) =>
                        item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
                    )
                  ) && (
                      <>
                        <Pane className="cart-table cart-table-desktop mt-3">
                          <h2 className="cart-title mb-3">
                            <FormattedMessage
                              id="dine_in"
                              defaultMessage="DINE IN"
                            />
                          </h2>
                          <Pane className="cart-list-content">
                            <CartList
                              cart={
                                !isEmpty(cart.items) &&
                                cart.items.filter(
                                  (item) =>
                                    item.deliveryMethod ===
                                    Config.DELIVERY_METHOD.DINE_IN
                                )
                              }
                            />
                          </Pane>
                        </Pane>
                        <Pane className="cart-table cart-table-responsive">
                          <CartList
                            cart={
                              !isEmpty(cart.items) &&
                              cart.items.filter(
                                (item) =>
                                  item.deliveryMethod ===
                                  Config.DELIVERY_METHOD.DINE_IN
                              )
                            }
                            type="responsive"
                          />
                        </Pane>
                      </>
                    )}
                  {!isEmpty(
                    !isEmpty(cart.items) &&
                    cart.items.filter(
                      (item) =>
                        item.deliveryMethod ===
                        Config.DELIVERY_METHOD.TAKE_AWAY
                    )
                  ) && (
                      <>
                        <Pane className="cart-table cart-table-desktop mt-3">
                          <h2 className="cart-title mb-3">
                            <FormattedMessage
                              id="take_away"
                              defaultMessage="TAKEAWAY"
                            />
                          </h2>
                          <Pane className="cart-list-content">
                            <CartList
                              cart={
                                !isEmpty(cart.items) &&
                                cart.items.filter(
                                  (item) =>
                                    item.deliveryMethod ===
                                    Config.DELIVERY_METHOD.TAKE_AWAY
                                )
                              }
                            />
                          </Pane>
                        </Pane>
                        <Pane className="cart-table cart-table-responsive">
                          <CartList
                            cart={
                              !isEmpty(cart.items) &&
                              cart.items.filter(
                                (item) =>
                                  item.deliveryMethod ===
                                  Config.DELIVERY_METHOD.TAKE_AWAY
                              )
                            }
                            type="responsive"
                          />
                        </Pane>
                      </>
                    )}

                  <TotalPayment totalItems={cart.total} discountPercent={this.state.discountPercent} maxDiscount={this.state.maxDiscount}/>
                  {start_location &&
                    end_location &&
                    deliveryPostCode &&
                    process.env.REACT_APP_SHOW_BHD_MAP == "true" ? (
                    <Pane className="row mt-4">
                      <Pane className="col-lg-12">
                        <Pane className="map-delivery">
                          <Iframe
                            url={
                              process.env.REACT_APP_MAP_KCATER +
                              `?${this.convertParamSearch({
                                start_postcode,
                                start_location,
                                deliveryPostCode,
                                end_location,
                                length: 2,
                                width: 3,
                                height: 1,
                                weight: 1,
                                map_height: "500px",
                                "access-token":
                                  process.env.REACT_APP_ACCESS_TOKEN_KCATER,
                                clientId: process.env.REACT_APP_CLIENTID_KCATER,
                                clientSecret:
                                  process.env.REACT_APP_CLIENTSECRET_KCATER,
                              })}`
                            }
                            className="map-container"
                          />
                        </Pane>
                      </Pane>
                    </Pane>
                  ) : null}
                </Pane>
              </Pane>
              <Pane className="row mt-3 flex-lg-row flex-column-reverse">

                <Pane className="col-lg-12 col-12 text-lg-right text-left">
                  <Button
                    className="btn btn-process btn-process-yellow"
                    onClick={this.checkout}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/cart/lock.svg`
                      }
                      alt="lock"
                      className="icon"
                    />
                    <FormattedMessage
                      id="proceed_to_check_out"
                      defaultMessage="PROCEED TO CHECK OUT"
                    />
                  </Button>
                </Pane>
              </Pane>
            </>
          ) : (
            <CartEmpty />
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

export default injectIntl(Checkout);
