import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { createMyProject } from "actions/projects";
import CreateProjectForm from "./CreateProjectForm";
class CreateProject extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }
  render() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="heading-title mt-2">
            <FormattedMessage
              id="create_new_project"
              defaultMessage="Create new project"
            />
          </h3>
        </div>
        <CreateProjectForm
          userInfo={this.props.userInfo}
          history={this.props.history}
          createMyProject={this.props.createMyProject}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});
const bindDispatchToProps = (dispatch) => ({
  createMyProject: bindActionCreators(createMyProject, dispatch),
});
export default connect(mapStateToProps, bindDispatchToProps)(CreateProject);