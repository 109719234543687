import PropTypes from "prop-types";
import _, { isEmpty, get } from "lodash";
import { connect } from "react-redux";
import { ProductList } from "./list";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Empty } from "components/common/empty";
import { Pane, Loading } from "shared/components/layouts";
import { Currency } from "shared/components";
import { getProductsByUserId } from "actions/myProducts";
import { getTablesByUserId } from "actions/myTables";
import UserAdapter from "services/users/adapter";
import { LoadingSearch } from "components/common/loading";
import { Button, Tab, Dimmer } from 'semantic-ui-react';
import NotificationSystem from "react-notification-system";
import ProductOptionModal from "./ProductOptionModal";
import { injectIntl, intlShape } from "react-intl";
import { Link, withRouter } from 'react-router-dom';
import { addPosCart, updatePosCart, resetPosCart } from "actions/posCart";
import CartItem from "./CartItem";
import { checkoutPOS } from 'actions/cart';
import Config from "constants/Config";
import { OrderList } from "./orders/list";
import SellingOrderDetail from "./orders/list/seller/detail/SellingOrderDetail";
import PreviewDetail from "./orders/preview/detail/PreviewDetail";
import Modal from "react-responsive-modal";
import { Checkbox, Form } from "semantic-ui-react";
import { InlineMessage } from 'shared/components';
import queryString from "query-string";

class POS extends React.Component {
	static propTypes = {
		auth: PropTypes.object,
		match: PropTypes.object,
		getProductsByUserId: PropTypes.func,
		intl: intlShape.isRequired,
	};

	constructor(props, context) {
		super(props, context);
		this._notificationSystem = null;
		const {
			location: { search },
		} = props;
		this.state = {
			products: {},
			type: queryString.parse(search).type || null,
			fetchingProducts: false,
			fetched: false,
			modeView: "gridView",
			isAction: false,
			cartView: {},
			openModalDetail: false,
			open: false,
			loadingCheckout: false,
			loadingTable: false,
			tables: [],
			note: "",
			tableId: "",
			method: Config.DELIVERY_METHOD.DINE_IN,
			activeIndex: 0,
			orderId: "",
			isPreview: false,
			payment_method: Config.PAYMENT_METHOD.PAY_BY_CASH,
			tab: 1,
			cateFilter: '',
			categories: [],
			cateKeySearch: '',
			productKeySearch: '',
		};
	}

	onChange = (e) => this.setState({ [e.target.name]: e.target.value });

	handleChange = (e, { value }) => this.setState({ payment_method: value });

	onCloseModalDetail = () => {
		this.setState({ openModalDetail: false, cartView: {} });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};

	onOpenModalDetail = () => {
		this.setState({ openModalDetail: true });
	};

	onOpenModal = () => {
		this.setState({ open: true });
	};

	setCartView = (cartViewData) => {
		this.setState((prev) => ({
			cartView: { ...prev.cartView, ...cartViewData },
		}));
	};

	getStoreProducts = async (userInfo) => {
		this.setState({
			fetchingProducts: true,
			fetched: false,
		});

		this.props
			.getProductsByUserId({
				id: userInfo.id,
				size: 1000,
				images: "",
				type: "Menu"
			})
			.then(() => {
				this.setState({
					fetchingProducts: false,
					fetched: true,
				}, () => {
					this.lazy();
				});
				this.getListById(userInfo.business.id);
			});
	};

	getListById = (businessId) => {
		this.setState({ loadingTable: true });
		const { auth } = this.props;
		const name =
			auth.user && auth.user.displayName
				? auth.user.displayName.replace(/ /g, "_").toLowerCase()
				: "";
		this.props
			.getTablesByUserId({ business_id: businessId })
			.then((response) => {
				if (response) {
					this.setState({ tables: UserAdapter.tablesBrowse.parseResponse(response.data) }, () => {
						if (this.state.tables.length == 0 && this.state.type == 'TABLE') {
							this.props.history.replace(`/u/${name}`);
						}
					});
				}
				this.setState({ loadingTable: false });
			})
			.catch(() => {
				this.setState({ loadingTable: false })
				this.props.history.replace(`/u/${name}`);
			});
	};


	lazy = () => {
		var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
		if ("IntersectionObserver" in window) {
			let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
				entries.forEach(function (entry) {
					if (entry.isIntersecting) {
						let lazyImage = entry.target;
						lazyImage.src = lazyImage.dataset.src;
						lazyImage.classList.remove("lazy");
						lazyImageObserver.unobserve(lazyImage);
					}
				});
			});
			lazyImages.forEach(function (lazyImage) {
				lazyImageObserver.observe(lazyImage);
			});
		}
	}

	handleScroll = () => {
		const { products } = this.props;
		const { isAction } = this.state;
		this.setState(
			{
				height: window.innerHeight,
			},
			() => {
				const banner = document.getElementById("banner-container");
				const info = document.getElementById("info-container");
				const menu = document.getElementById("anchor");
				const stickyBanner = banner && banner.offsetHeight;
				const stickyInfo = info && info.offsetHeight;
				const items = this.groupByCategories(products.results);
				const itemElements = items.map((item) => {
					const itemElement = document.getElementById(item.name);
					return {
						...item,
						scroll:
							itemElement &&
							itemElement.offsetTop +
							itemElement.offsetHeight +
							stickyBanner +
							stickyInfo -
							250,
					};
				});
				let sum = stickyBanner + stickyInfo;
				if (window.pageYOffset > sum) {
					const scrollElement = itemElements.filter(
						(item) => item.scroll >= window.scrollY
					);
					if (!isEmpty(scrollElement) && !isAction) {
						this.setState({
							selected: get(scrollElement[0], "name"),
						});
					}
					menu && menu.classList.add("menu-breadcrum-sticky");
				} else {
					const scrollElement = itemElements.filter(
						(item) => item.scroll >= window.scrollY
					);
					if (!isEmpty(scrollElement) && !isAction) {
						this.setState({
							selected: get(scrollElement[0], "name"),
						});
					}
					menu && menu.classList.remove("menu-breadcrum-sticky");
				}
			}
		);
	};

	groupByCategories = (data) => {
		return _.chain(data)
			.groupBy((x) => x.category && x.category.name)
			.map((item, key) => Object.assign({}, { name: key, items: item }))
			.value();
	};

	componentWillMount() {
		this.setState({
			height: window.innerHeight,
		});
		window.addEventListener("scroll", this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
		this.unlisten();
	}

	componentDidMount() {
		const { auth, posCart } = this.props;
		const { type } = this.state;
		if (!auth.user.id) {
			this.props.history.replace(`/sign-in`);
		}

		if (!type) {
			this.setState({ tab: 2 });
		}

		if (posCart.results.length > 0 && posCart.results.some(item => item.userId !== auth.user.id)) {
			this.props.resetPosCart();
		}

		this.unlisten = this.props.history.listen(() => {
			this.getStoreProducts(auth.user);
		});

		this.getStoreProducts(auth.user);

		const elementScroll = document.getElementById("anchor");
		if (elementScroll) {
			setTimeout(() => {
				elementScroll.scrollIntoView({ behavior: "smooth" });
			}, 1000)
		}

		this._notificationSystem = this.refs.notificationSystem;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const categories = _.chain(nextProps.products.results)
			.groupBy((x) => x.category && x.category.name)
			.map((item, key) => Object.assign({}, { name: key, items: item }))
			.value();

		this.setState({ categories });
	}


	onSelect = (key) => {
		const self = this;
		this.setState(
			{
				keySort: key,
				selected: key,
				isAction: true,
			},
			() => {
				const elementScroll = document.getElementById(key);
				if (elementScroll) {
					window.scroll({
						top: elementScroll.offsetTop + 330,
						behavior: "smooth",
					});
					setTimeout(
						() =>
							self.setState({
								isAction: false,
							}),
						1000
					);
				}
			}
		);
	};

	addCart = (item, optionInfo) => {
		let product = {
			categoryName: item.categoryName,
			productId: item.id,
			primaryImage: item.primaryImage,
			price: item.originalPrice,
			promotionPrice: item.promotionPrice,
			quantity: 1,
			optionInfo: optionInfo,
			options: item.options,
			originalPrice: item.originalPrice,
			name: item.name
		}
		this.props.addPosCart(product);
		this.onCloseModalDetail();
	}

	updateCart = (productId, optionInfo) => {
		this.props.updatePosCart(false, productId, optionInfo);
		this.onCloseModalDetail();
	}

	handleTotal = () => {
		let total = 0;
		this.props.posCart.results.map((item) => {
			const { options, optionInfo } = item;
			let totalPriceOp = 0;

			if (options && options.length && optionInfo) {
				options.filter((option) => {
					const { childrens } = option;
					childrens.find((child) => {
						const selected = optionInfo.find((o) => o.id === child.id);
						if (selected) {
							totalPriceOp += Number(child.price) * Number(selected.quantity);
						}
					});
				});
			}

			total += (item.promotionPrice + totalPriceOp) * item.quantity;
		});

		return total;
	};

	preview = () => {
		this.setState({ isPreview: true, activeIndex: 1 });
	}

	submit = () => {
		let carts = this.handleCartItem();
		let data = {
			carts: JSON.stringify(carts),
			note: this.state.note,
			payment_method: this.state.payment_method,
			total: this.handleTotal(),
			tableId: this.state.tableId ? this.state.tableId : null
		}

		this.setState({ loadingCheckout: true });
		this.props.checkoutPOS(data).then((res) => {
			if (res.data) {
				this.setState({ loadingCheckout: false });
				this._notificationSystem.addNotification({
					message: 'Created new order successfully!',
					level: "success",
				});
				this.props.resetPosCart();
				this.onCloseModal();
				this.setState({ note: "", tableId: "" }, () => {
					this.viewOrderDetail(res.data.data.orderId);
				})
			}
		}).catch(() => {
			this.setState({ loadingCheckout: false });
			this._notificationSystem.addNotification({
				message: this.props.intl.formatMessage({ id: "notification.please_try_again" }),
				level: "error",
			});
		});
	}

	handleCartItem = () => {
		let cart = [];
		this.props.posCart.results.map((item) => {
			const { options, optionInfo } = item;
			let totalPriceOp = 0;

			if (options && options.length && optionInfo) {
				options.filter((option) => {
					const { childrens } = option;
					childrens.find((child) => {
						const selected = optionInfo.find((o) => o.id === child.id);
						if (selected) {
							totalPriceOp += Number(child.price) * Number(selected.quantity);
						}
					});
				});
			}

			let price = item.promotionPrice + totalPriceOp;

			let obj = {
				delivery_method: this.state.method,
				quantity: item.quantity,
				name: item.name,
				price: price,
				promotion_price: price,
				productTotal: (price * item.quantity),
				product_id: item.productId,
				seller_id: this.props.auth.user.id,
				option_info: optionInfo ? JSON.stringify(optionInfo) : null
			}

			cart.push(obj);
		});

		return cart;
	};

	handleTabChange = (e, { activeIndex }) => {
		this.setState({ activeIndex, isPreview: false })
	}

	renderTab = () => {
		const { posCart } = this.props;
		const { activeIndex } = this.state;
		const cartList =
			posCart && posCart.results.length > 0 &&
			posCart.results.map((item, index) => {
				return (
					<CartItem
						key={item.id}
						item={item}
						index={index}
						cartModal={{
							cartView: this.state.cartView,
							openModal: this.onOpenModalDetail,
							setCartView: this.setCartView,
						}}
					/>
				);
			});
		const panes = [
			{
				menuItem: 'Order',
				render: () =>
					<Tab.Pane attached='top'>
						{posCart.results.length > 0 ? (
							<Pane className="items">{cartList}</Pane>
						) : (
							<Pane className="empty-container pt-4">
								<Pane className="empty-content">
									<img
										src={
											process.env.PUBLIC_URL + "/assets/images/plate_empty.png"
										}
										alt="empty"
										className="img-empty"
									/>
									<h4 className="empty-title">
										<Pane>Let's order now</Pane>
									</h4>
								</Pane>
							</Pane>
						)}
					</Tab.Pane>,
			},
			{
				menuItem: 'Order detail',
				render: () =>
					<Tab.Pane attached='top'>
						{this.state.isPreview ? (
							<PreviewDetail delivery_method={this.state.method} table={this.state.tables.length > 0 && this.state.tables.filter(item => item.id == this.state.tableId).length > 0 && this.state.tables.filter(item => item.id == this.state.tableId)[0]['name'] || ''} note={this.state.note} posCart={this.props.posCart.results} total={this.handleTotal()} />
						) : (
							<SellingOrderDetail backToOrderList={this.backToOrderList} orderId={this.state.orderId} />
						)}
					</Tab.Pane>,
			},
			{
				menuItem: 'Order list',
				render: () =>
					<Tab.Pane attached='top'>
						<OrderList viewOrderDetail={this.viewOrderDetail} />
					</Tab.Pane>,
			},
		];
		return <Tab activeIndex={activeIndex} onTabChange={this.handleTabChange} menu={{ secondary: true, pointing: true }} panes={panes} />;
	};

	viewOrderDetail = (orderId) => {
		this.setState({ orderId }, () => {
			this.setState({ isPreview: false }, () => {
				this.setState({ activeIndex: 1 });
			})
		})
	}

	backToOrderList = () => {
		this.setState({ activeIndex: 2, isPreview: false })
	}

	chooseTable = (table_id) => {
		this.setState({ tableId: table_id }, () => {
			this.setState({ tab: 2 });
		});
	}

	changeCate = (name) => {
		this.setState({ cateFilter: name }, () => {
			this.setState({ tab: 3 });
		});
	}

	backCate = () => {
		let new_tab = 2;
		if (this.state.type == 'TABLE') {
			new_tab = this.state.tab === 3 ? 2 : 1;
		}
		this.setState({ tab: new_tab, cateFilter: '' }, () => {
			if (this.state.tab === 1) {
				this.props.resetPosCart();
				this.setState({ tableId: "" })
			}
		});
	}

	fullPage = () => {
		var el = document.documentElement,
			rfs = el.requestFullscreen
				|| el.webkitRequestFullScreen
				|| el.mozRequestFullScreen
				|| el.msRequestFullscreen
			;

		rfs.call(el);
	}

	onChangeKeyword = (event) => {
		const { tab } = this.state;
		if (tab == 2) {
			this.setState({ cateKeySearch: event.target.value }, () => {
				const { products } = this.props;

				let categories = _.chain(products.results)
					.groupBy((x) => x.category && x.category.name)
					.map((item, key) => Object.assign({}, { name: key, items: item }))
					.value();

				if (this.state.cateKeySearch) {
					categories = categories.filter((x) => x.name.toLocaleLowerCase().includes(this.state.cateKeySearch))
				}
				this.setState({ categories });
			})
		} else if (tab == 3) {
			this.setState({ productKeySearch: event.target.value });
		}
	}

	renderProducts = (items) => {
		return items.filter(item => item.name.toLocaleLowerCase().includes(this.state.productKeySearch))
	}

	render() {
		const { auth, posCart } = this.props;
		const { fetched, fetchingProducts, loadingTable, tables, tableId, note, method, activeIndex, open, tab, cateFilter, categories, type } = this.state;

		const name =
			auth.user && auth.user.displayName
				? auth.user.displayName.replace(/ /g, "_").toLowerCase()
				: "";

		let tableName = !loadingTable && tables.length > 0 && tables.filter(item => item.id == tableId).length > 0 && tables.filter(item => item.id == tableId)[0]['name'] || '';
		return (
			<>
				<Modal
					open={open}
					onClose={this.onCloseModal}
					center
					showCloseIcon={true}
				>
					<Pane className="pos-payment-confirm">
						<Form className="d-flex justify-content-around align-items-center">
							<Pane>
								<Form.Field className="d-flex align-items-center">
									<Checkbox
										radio
										label="Pay at Counter"
										name="checkboxRadioGroup"
										value={Config.PAYMENT_METHOD.PAY_BY_CASH}
										checked={this.state.payment_method === Config.PAYMENT_METHOD.PAY_BY_CASH}
										onChange={this.handleChange}
									/>
									<img
										src={process.env.PUBLIC_URL + `/assets/images/cash.png`}
										alt="users"
										className="img-cash ml-2"
									/>
								</Form.Field>
							</Pane>
							<Pane>
								<Form.Field className="d-flex align-items-center">
									<Checkbox
										radio
										label="Pay by Credit"
										name="checkboxRadioGroup"
										value={Config.PAYMENT_METHOD.PAY_BY_CREDIT}
										checked={this.state.payment_method === Config.PAYMENT_METHOD.PAY_BY_CREDIT}
										onChange={this.handleChange}
									/>
									<img
										src={
											process.env.PUBLIC_URL +
											`/assets/images/cart/credit.png`
										}
										alt="users"
										className="img-credit ml-2"
									/>
								</Form.Field>
							</Pane>
						</Form>
						<Pane className="react-confirm-alert-button-group">
							<button className="btn-claim" onClick={this.submit}>
								OK
							</button>
						</Pane>
					</Pane>
				</Modal>
				<ProductOptionModal
					openModal={this.state.openModalDetail}
					onClose={this.onCloseModalDetail}
					detail={this.state.cartView}
					onUpdateCart={(item, optionIds) => {
						this.updateCart(item.productId, optionIds)
					}}
					onAddCartItem={(optionIds, item) => {
						let option_info = optionIds.length
							? optionIds
							: null;
						this.addCart(item, option_info);
					}}
					isEdit={true}
				/>
				{!fetchingProducts && fetched ? (
					<>
						<Pane className="row">
							<Pane className="col-7">
								<Pane className="store-info">
									<img
										src={
											get(this.props.auth.user, "profileImage") ||
											process.env.PUBLIC_URL + "/assets/images/user.jpg"
										}
										alt="logo"
										className="logo-img"
									/>
									<Pane className="store-info__name ml-2">
										{this.props.auth.user.business.name}
									</Pane>
								</Pane>
								<Pane className="fullscreen" onClick={this.fullPage}>
									<img
										src={process.env.PUBLIC_URL + `/assets/images/icons/fullscreen.png`}
										alt="users"
									/>
								</Pane>
								{tab !== 1 && (
									<Pane class="arrow-prev">
										<Pane onClick={this.backCate}>
											{tab !== 1 && (
												<img
													src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.png"}
													className="image-logo"
													alt="list-view"
													id="arrow-prev"
												/>
											)}
											{tab === 2 && type == 'TALBE' ? 'Tables' : 'Categories'}
										</Pane>
										{tab !== 1 && (
											<Pane className="search-field">
												<img
													src={process.env.PUBLIC_URL + '/assets/images/layouts/search.svg'}
													alt="store"
													className="icon-search"
												/>
												<input
													type="text"
													className='search-box'
													placeholder="Search.."
													value={tab == 2 ? this.state.cateKeySearch : this.state.productKeySearch}
													autoComplete="off"
													onChange={this.onChangeKeyword}
													autoFocus
												/>
											</Pane>
										)}
									</Pane>
								)}
								{type == 'TABLE' && tab === 1 && (
									<Pane className="col-12 p-0">
										<Pane className="comment-container mb-3">
											<Pane className={`tables col-12`}>
												{!loadingTable ? (
													<Pane
														className={`row align-items-end`}>
														{tables.length > 0 ? (
															tables.map((item, index) => {
																return (
																	<Pane key={index} onClick={() => this.chooseTable(item.id)} className="col-lg-3 col-4">
																		<Pane className="table-item">
																			<Pane className="total-new-orders">
																				<Pane className="table-label">Table</Pane>
																			</Pane>
																			<Pane className="table-body">
																				<Pane className="table-name">{item.name}</Pane>
																			</Pane>
																		</Pane>
																	</Pane>
																);
															})
														) : (
															<Pane className="block-no-content w-100">
																<p>
																	No table
																</p>
															</Pane>
														)}
													</Pane>
												) : (
													<Pane className="dashboard-loading">
														<Dimmer active inverted>
															<Loading />
														</Dimmer>
													</Pane>
												)}
											</Pane>
										</Pane>
									</Pane>
								)}
								{tab === 2 && (
									<Pane className="pos-categories">
										{!isEmpty(categories) && categories.map((category) => (
											<Pane key={category.name} onClick={() => this.changeCate(category.name)} className="pos-categories__item">
												{category.name}
											</Pane>
										))}
									</Pane>
								)}
								{tab === 3 && (
									<>
										{isEmpty(categories) && <Empty type="white" />}
										{!isEmpty(categories) &&
											categories.filter(obj => obj.name == cateFilter).map((category) => (
												<Pane key={category.name} id={category.name}>
													<Pane className="category-name">
														<strong>{category.name}</strong>
													</Pane>
													<ProductList
														posCart={posCart.results}
														auth={auth}
														products={this.renderProducts(category.items)}
														addPosCart={this.props.addPosCart}
														updatePosCart={this.props.updatePosCart}
														modeView={this.state.modeView}
														cartModal={{
															cartView: this.state.cartView,
															openModal: this.onOpenModalDetail,
															setCartView: this.setCartView,
														}}
														methodModal={this.props.methodModal}
														onNotification={this._notificationSystem}
													/>
													<Pane className="clearfix" />
												</Pane>
											))}
									</>
								)}
							</Pane>
							<Pane className="col-5">
								<Pane className="pos-bill">
									<Pane>
										<Pane className="nav">
											<Pane className="methods d-flex">
												<Pane onClick={() => this.setState({ method: Config.DELIVERY_METHOD.DINE_IN })} className={`method-item mr-2 ${method == Config.DELIVERY_METHOD.DINE_IN ? 'active' : ''}`}>Dine in</Pane>
												<Pane onClick={() => this.setState({ method: Config.DELIVERY_METHOD.TAKE_AWAY })} className={`method-item ${method == Config.DELIVERY_METHOD.TAKE_AWAY ? 'active' : ''}`}>Take away</Pane>
											</Pane>
											<Pane className="d-flex">
												<Link to={`/u/${name}/`} className="nav-item">
													<img
														src={`${process.env.PUBLIC_URL
															}/assets/images/icons/home-white.svg`}
														alt="booking"
														className="icon"
													/>
													<span className="norm">
														Stores
													</span>
												</Link>
												<Link to={`/u/${name}/orders?delivery_type=DINE_IN`} className="nav-item mr-0">
													<span class="icon ic-common-order"></span>
													<span className="norm">
														Orders
													</span>
												</Link>
											</Pane>
										</Pane>
										{!type? (
											<>
												{loadingTable && (
													<Pane>Tables Loading...</Pane>
												)}
												{!loadingTable && tables.length > 0 && (
													<Pane className="form-overview">
														<Pane className="form-item">
															<select
																className="form-select"
																name="tableId"
																id="tableId"
																value={tableId}
																onChange={this.onChange}
															>
																<option value="">
																	--- Select table ---
																</option>
																{tables.map((item) => {
																	return (
																		<option value={item.id} key={item.id}>
																			{item.name}
																		</option>
																	);
																})}
															</select>
														</Pane>
													</Pane>
												)}
											</>
										) : (
											<>
												{tableId && (
													<Pane class="table-label">Table: {tableName}</Pane>
												)}
											</>
										)}

										{this.renderTab()}
										{posCart.results.length > 0 && activeIndex == 0 && (
											<>
												<Pane className="total">
													<Pane className="total_title">
														Payable Amount
														:
													</Pane>
													<Pane className="total_price">
														<Currency price={this.handleTotal()} />
													</Pane>
												</Pane>
												<Pane className="total">
													<Pane className="total_title">
														Taxable Items
													</Pane>
												</Pane>
												<Pane className="total">
													<Pane className="total_title">
														Total includes GST
													</Pane>
													<Pane className="total_price">
														<Currency price={this.handleTotal()} />
													</Pane>
												</Pane>
											</>
										)}
									</Pane>
									<Pane className="mb-5">
										{this.state.activeIndex == 0 && (
											<Pane>
												<label
													htmlFor="note"
													className="form-label"
												>
													Note
												</label>
												<textarea
													id="note"
													name="note"
													value={note}
													onChange={this.onChange}
													rows="3"
													className="form-input"
												/>
											</Pane>
										)}
										<Pane className="bill-action">
											{this.state.isPreview && this.state.activeIndex == 1 && (
												<>
													<Button
														className="btn btn-back"
														onClick={() => this.setState({ isPreview: false, activeIndex: 0 })}
													>
														BACK
													</Button>
													<Button
														style={{ margin: "0 auto" }}
														className="btn btn-proceed"
														onClick={this.onOpenModal}
													>
														PROCEED
													</Button>
												</>
											)}
											{!this.state.isPreview && this.state.activeIndex == 0 && (
												<>
													<Button
														className="btn btn-cancel"
														onClick={this.props.resetPosCart}
													>
														DELETE
													</Button>
													<Button
														disabled={posCart.results.length === 0}
														className="btn btn-proceed"
														loading={this.state.loadingCheckout}
														onClick={this.preview}
													>
														PREVIEW
													</Button>
												</>
											)}
										</Pane>
									</Pane>
								</Pane>
							</Pane>
						</Pane>
					</>
				) : (
					<LoadingSearch type="white" />
				)}
				<NotificationSystem ref="notificationSystem" />
			</>
		);
	}
}

const bindStateToProps = (state) => ({
	stores: state.stores,
	products: state.myProducts,
	auth: state.auth,
	cart: state.cart,
	posCart: state.posCart
});

const bindDispatchToProps = (dispatch) => ({
	checkoutPOS: bindActionCreators(checkoutPOS, dispatch),
	addPosCart: bindActionCreators(addPosCart, dispatch),
	updatePosCart: bindActionCreators(updatePosCart, dispatch),
	resetPosCart: bindActionCreators(resetPosCart, dispatch),
	getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
	getTablesByUserId: bindActionCreators(getTablesByUserId, dispatch),
});

export default withRouter(injectIntl(connect(bindStateToProps, bindDispatchToProps)(POS)));