import * as types from 'constants/ActionTypes';
import { Cookies } from 'react-cookie';
import UserAdapter from 'services/users/adapter';

const cookies = new Cookies();
const userInfo = cookies.get('userInfo') || {};

const initialState = {
  user: userInfo,
  errors: {},
  isAuthenticated: userInfo && userInfo.id ? true : false,
  fetching: false
};

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case types.REQUEST_LOGIN:
      return Object.assign({}, state, {
        fetching: true
      });
    case types.RECEIVE_LOGIN_FAILURE:
      return Object.assign({}, state, {
        user: {},
        errors: action.error.response && action.error.response.data && action.error.response.data.errors[0],
        isAuthenticated: false,
        fetching: false
      });
    case types.LOGOUT:
      return Object.assign({}, state, {
        user: {},
        errors: {},
        isAuthenticated: false,
        fetching: false
      });
    case types.RECEIVE_USER_INFO_SUCCESS:
      cookies.set('userInfo', JSON.stringify(UserAdapter.read.parseResponse(action.payload.data)), { path: '/' });

      return Object.assign({}, state, {
        user: UserAdapter.read.parseResponse(action.payload.data),
        isAuthenticated: true,
        errors: {},
        fetching: false
      });
    default:
      return state;
  }
}
