import React, { Component } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Pane } from "shared/components/layouts";
import ListItem from "./ListItem";

class List extends Component {
  static propTypes = {
    data: PropTypes.array,
  };

  render() {
    const list =
      this.props.data &&
      this.props.data.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.name}
            viewVideo={item.viewVideo}
            likeVideo={item.likeVideo}
            slug={item.slug}
            type={item.type}
            like={this.props.like}
            image={get(item, "primaryImage")}
          />
        );
      });
    return (
      <>
        <Pane className="adds-block mt-0 store_overview_list">
          <Pane className="row pb-3">{list}</Pane>
        </Pane>
      </>
    );
  }
}

export default List;
