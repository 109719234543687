import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Pane, Loading } from 'shared/components/layouts';
import CategoryItem from './CategoryItem';
import { getProductCategories, getServiceCategories } from 'actions/category';
import Config from 'constants/Config';
import { FormattedMessage } from 'react-intl';

class CategoryList extends Component {
  PropTypes = {
    getProductCategories: PropTypes.func.isRequired,
    getServiceCategories: PropTypes.func.isRequired,
    productCats: PropTypes.object,
    serviceCats: PropTypes.object,
  };

  // componentDidMount() {
  //   // this.loadCategoryList(this.props.type);
  //   var params = {id: '30,5,28,1,45,32,91,15,90,4,89,82', sort: 'name'};
  //     this.props.getProductCategories(params);
  //     var params2 = {id: '88,87,86,85,84,81,79,77,73,72,71,67', sort: 'name'};

  //     this.props.getServiceCategories(params2);
  // }

  // loadCategoryList(catType) {
  //   this.setState({ type: catType });
  //   if (catType === Config.PRODUCT_TYPE) {
  //     var params = {id: '30,5,28,1,45,32,91,15,90,4,89,82', sort: 'name'};
  //     this.props.getProductCategories(params);
  //   } else if (catType === Config.SERVICE_TYPE) {
  //     var params2 = {id: '88,87,86,85,84,81,79,77,73,72,71,67', sort: 'name'};

  //     this.props.getServiceCategories(params2);
  //   }
  // }

  renderList(categoryList) {
    return categoryList.map((item, index) => {
      return (
        <CategoryItem
          key={item.id}
          id={item.id}
          item={item}
          index={index}
          type={this.props.type}
          name={item.name}
          image={item.image}
        />
      );
    });
  }

  render() {
    const { type, categories } = this.props;
    return (
      <Pane className="category-container">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Pane className="heading-container text-lg-left text-md-left text-left">
                <h2 className="heading-title">
                  {type === Config.PRODUCT_TYPE ? (
                    <FormattedMessage
                      id="home.browse_by_product_category"
                      defaultMessage="Browse by Product Category"
                    />
                  ) : (
                    <FormattedMessage
                      id="home.browse_by_service_category"
                      defaultMessage="Browse by Service Category"
                    />
                  )}
                </h2>
                <p className="heading-norm">
                  <FormattedMessage
                    id="home.enjoy_exploring_or_picking_up"
                    defaultMessage="Enjoy exploring or picking up things you need from these vast ranges of supply."
                  />
                </p>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="row">
            {!this.props[type].fetched && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {// this.renderList(this.props[type].results.slice(0, NUM_OF_CATEGORY))
              this.renderList(categories.results)}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

CategoryList.propTypes = {
  getProductCategories: PropTypes.func.isRequired,
  [Config.PRODUCT_TYPE]: PropTypes.object,
};

const bindStateToProps = (state) => ({
  [Config.PRODUCT_TYPE]: state.productCategories,
  [Config.SERVICE_TYPE]: state.serviceCategories,
});

const bindDispatchToProps = (dispatch) => ({
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  getServiceCategories: bindActionCreators(getServiceCategories, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(CategoryList);
