import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import StartYourOnline from './StartYourOnline';

class LearnMore extends Component {
  render() {
    return (
      <Pane className="learn-container">
        <StartYourOnline />
      </Pane>
    );
  }
}

export default LearnMore;
