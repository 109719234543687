import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { Currency } from "shared/components";
import { Button } from "semantic-ui-react";
import CartItem from "./CartItem";
import { get } from "lodash";
import { withRouter } from 'react-router-dom';

class BottomCart extends Component {
	render() {
		const { cart } = this.props;

		return (
			<Pane className="bottom_cart">
				<Pane className="block_cart">
					<Pane
						className="build-cart"
						onClick={() => this.props.history.push("/cart")}
					>
						<img
							src={`${process.env.PUBLIC_URL}/assets/images/layouts/cart.svg`}
							className="icon-cart"
							alt="caterwin logo"
						/>
						<span className="cart-total">
							{cart && cart.items && cart.items.length > 0
								? cart.items.length
								: 0}
						</span>
					</Pane>
					<Pane className="total">
						<Currency price={cart.total} />
					</Pane>
				</Pane>
				<Button onClick={() => this.props.history.push("/cart")}>
					<FormattedMessage
						id="view_cart"
						defaultMessage="VIEW CART"
					></FormattedMessage>
				</Button>
			</Pane>
		);
	}
}

export default withRouter(BottomCart);