import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage } from 'react-intl';

class SocialBox extends Component {
  render() {
    return (
      <Pane className="social-container">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
              <Pane className="social-content">
                <h2 className="social-title">
                  <FormattedMessage id="home.we_are_here_on_app_stores" />
                </h2>
                <p className="social-norm">
                  <FormattedMessage id="home.let_go_mobile_new" />
                </p>
                <ul className="social-list">
                  <li className="social-item">
                    <a href="/">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/assets/images/home/googlestore.png'
                        }
                        alt="googlestore"
                        className="social-img"
                      />
                    </a>
                  </li>
                  <li className="social-item">
                    <a href="/">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/assets/images/home/appstore.png'
                        }
                        alt="appstore"
                        className="social-img"
                      />
                    </a>
                  </li>
                </ul>
              </Pane>
            </Pane>
            <Pane className="social-bg">
              <img
                src={process.env.PUBLIC_URL + '/assets/images/home/phone2.png'}
                alt="phone2"
                className="social-img"
              />
            </Pane>
          </Pane>
        </Pane>
        <Pane className="social-bg-reponsive">
          <img
            src={process.env.PUBLIC_URL + '/assets/images/home/bg-social-reponsive.png'}
            alt="social"
            className="social-img-reponsive"
          />
        </Pane>
      </Pane>
    );
  }
}
const bindStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(bindStateToProps)(injectIntl(withRouter(SocialBox)));
