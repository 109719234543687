import _ from 'lodash';
import Config from 'constants/Config'

const exec = params => {
  const keyword = params.keyword || ''
  const page = params && params.page ? params.page : 1
  const size = params && params.size ? params.size : 20
  const country = params && params.country || 'aus'
  let search_area = params && params.state ? `&state=${params.state}` : ``;
  if (params && params.city) search_area = `${search_area}&city=${params.city}`
  // const lat = params && params.lat || 0.0
  // const lng = params && params.lng || 0.0
  if(_.get(params,'isAll')){
    return {
      method: 'GET',
      url: `/search?page[size]=${size}&page[number]=${page}&country=${country}${search_area}&name=${keyword}`,
      // url: `/search?page[size]=${size}&page[number]=${page}&lat=${lat}&lng=${lng}&country=${country}&name=${keyword}`,
      baseURL: Config.NEW_API_URL
    }
  }
  if(_.get(params,'isUser')){
    return {
      method: 'GET',
      url: `/business?page[size]=${size}&page[number]=${page}&country=${country}&name=${keyword}&isUser=true$`,
      baseURL: Config.NEW_API_URL
    }
  }
  return {
    method: 'GET',
    url: `/business?page[size]=${size}&page[number]=${page}&country=${country}${search_area}&name=${keyword}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
