import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl, intlShape } from 'react-intl';
import { MasterLayout } from 'components/layout';
import { getAllServices, getServicesSlidePromotion } from 'actions/services';
import { getServiceCategories } from 'actions/category';
import { getBusinesses, getBusinessByCategory } from 'actions/business';
import {
  Services,
  BannerIntro,
  BusinessLogoList,
} from 'components/scenes/services';
import { checkParams } from '../../shared/helpers';
import { Pane } from 'shared/components/layouts';
import { InderBox } from 'components/common/categories';

class ServicePage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    services: PropTypes.object.isRequired,
    servicesSlidePromotion: PropTypes.object.isRequired,
    serviceCategories: PropTypes.object.isRequired,
    businesses: PropTypes.object.isRequired,
    getAllServices: PropTypes.func.isRequired,
    getServicesSlidePromotion: PropTypes.func.isRequired,
    getServiceCategories: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  state = {
    value: queryString.parse(this.props.location.search).discount,
  };

  handleChange = (e, { value }) => {
    this.setState(
      {
        value: !value,
      },
      () => {
        this.props.history.push(
          `?discount=${!value}${checkParams(
            'discount',
            this.props.location.search
          )}`
        );
      }
    );
  };

  componentDidMount() {
    const params = {
      page: 1,
    };
    this.props.getServiceCategories();
    this.props.getBusinesses();
    this.props.getServicesSlidePromotion(params);
  }

  onOpenToggle = (type) => {
    this.setState((prevState) => ({
      [type]: !prevState[type],
    }));
  };

  render() {
    const {
      serviceCategories,
      servicesSlidePromotion,
      businesses,
    } = this.props;
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <BannerIntro data={servicesSlidePromotion} />
          <BusinessLogoList />
          <Pane className="container product-container product-list-container">
            <Pane className="row">
              <Pane className="col-lg-3 col-md-12">
                <InderBox
                  isTab={true}
                  type="services"
                  className="mb-5"
                  title="categories"
                  nameToggle="toggleCategory"
                  onOpenToggle={this.onOpenToggle}
                  category={serviceCategories.results}
                  toggle={!!this.state.toggleCategory}
                />
                <InderBox
                  type="stores"
                  title="stores"
                  nameToggle="toggleStores"
                  category={businesses.results}
                  onOpenToggle={this.onOpenToggle}
                  toggle={!!this.state.toggleStores}
                />
                <InderBox
                  type="promotion"
                  title="promotions"
                  nameToggle="togglePromotion"
                  onOpenToggle={this.onOpenToggle}
                  toggle={!!this.state.togglePromotion}
                />
              </Pane>
              <Services
                auth={this.props.auth}
                data={this.props.services}
                onGetAllServices={this.props.getAllServices}
                servicesPromotions={this.props.servicesSlidePromotion}
                categories={this.props.serviceCategories.results}
                getBusinessByCategory={this.props.getBusinessByCategory}
                businessesByCategory={this.props.businessesByCategory}
              />
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  services: state.services,
  servicesSlidePromotion: state.servicesSlidePromotion,
  serviceCategories: state.serviceCategories,
  businesses: state.businesses,
  businessesByCategory: state.businessesByCategory,
});

const bindDispatchToProps = (dispatch) => ({
  getAllServices: bindActionCreators(getAllServices, dispatch),
  getServicesSlidePromotion: bindActionCreators(
    getServicesSlidePromotion,
    dispatch
  ),
  getServiceCategories: bindActionCreators(getServiceCategories, dispatch),
  getBusinesses: bindActionCreators(getBusinesses, dispatch),
  getBusinessByCategory: bindActionCreators(getBusinessByCategory, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(ServicePage));
