import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Config from "constants/Config";
import { Pane } from "shared/components/layouts";
import { LoadingSearch } from "components/common/loading";
import { Rating } from 'semantic-ui-react';
import List from "./list/List";
import { getReviewBusiness } from 'actions/business';
import { editReview } from 'actions/reviews';
import { getUserInfo } from "actions/auth";
import BusinessAdapter from "services/businesses/adapter";

class Review extends Component {
  constructor(props, context) {
    super(props, context);
  }

  state = {
    loading: false,
    reviews: [],
  };


  componentDidMount() {
    this.getReviewBusiness();
  }

  getReviewBusiness = () => {
    this.setState({ loading: true });
    this.props.getReviewBusiness({
      id: this.props.storeInfo.business.id,
    }).then((response) => {
      const data = BusinessAdapter.getReview.parseResponse(response.data);
      this.setState({ loading: false, reviews: data });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { loading, reviews } = this.state;
    const { storeInfo } = this.props;
    return (
      <Pane className="store-overview">
        <Pane className="review-nums">
          <ul className="list-star">
            <Pane className="total-star-title">{storeInfo.business.rating > 0 ? storeInfo.business.rating : 'No Rating'}</Pane>
            <Rating
              disabled
              icon='star'
              rating={storeInfo.business.rating}
              maxRating={5}
              className="mr-1"
              size='huge'
            />
          </ul>
          {!loading && (
            <Pane className="total-review-title">{reviews.length > 0 ? `${reviews.length} reviews` : ''}</Pane>
          )}
        </Pane>
        {!loading ? (
          <>
            {reviews.length > 0 ? (
              <Pane className="review-list">
                <List reviews={reviews} storeId={storeInfo.id} editReview={this.props.editReview} getUserInfo={this.props.getUserInfo} />
              </Pane>
            ) : (
              <Pane className="text-center mt-5">
                No reviews yet.
              </Pane>
            )}
          </>
        ) : (
          <LoadingSearch type="white" />
        )}
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  storeInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getReviewBusiness: bindActionCreators(getReviewBusiness, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  editReview: bindActionCreators(editReview, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(Review);