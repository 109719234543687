import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { createCategory } from 'actions/category';
import { getProductCategories } from 'actions/category';
import CreateCategoryForm from './CreateCategoryForm';

class CreateCategory extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getProductCategories: PropTypes.func,
    createCategory: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getProductCategories();
  }

  render() {
    return (
      <Pane className="overview-container create-product">
        <h3 className="block-title text-uppercase">
          <FormattedMessage
            id="create_new_category"
            defaultMessage="Create new category"
          />
        </h3>
        <Pane className="products-grids">
          <Pane className="card-body">
            <CreateCategoryForm
              userInfo={this.props.userInfo}
              categories={this.props.categories}
              createCategory={this.props.createCategory}
              history={this.props.history}
            />
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.productCategories,
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(CreateCategory);
