import * as types from 'constants/ActionTypes'
import ProductAdapter from 'services/products/adapter'

const initialState = {
  data: {},
  error: null,
  fetching: false,
  fetched: false
}

const productDetail = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_PRODUCT_DETAIL:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_PRODUCT_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        data: ProductAdapter.read.parseResponse(action.payload.data),
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_PRODUCT_DETAIL_FAILURE:
      return Object.assign({}, state, {
        data: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        data: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { productDetail }
