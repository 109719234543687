import PropTypes from "prop-types";
import Config from "constants/Config";
import { Link } from "react-router-dom";
import { get, isEmpty } from "lodash";
import PaymentForm from "./PaymentForm";
import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { handleError } from "shared/helpers";
import { Pane } from "shared/components/layouts";
import NotificationSystem from "react-notification-system";
import TotalPayment from "components/scenes/cart/TotalPayment";
import CartList from "components/scenes/cart/checkout/CartList";
import { StripeProvider, Elements } from "react-stripe-elements";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";

class Payment extends Component {
  static propTypes = {
    auth: PropTypes.object,
    cart: PropTypes.object,
    history: PropTypes.object,
    onPayment: PropTypes.func,
    location: PropTypes.object,
    intl: intlShape.isRequired,
    onRemoveAllCart: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.state = {
      cvv: "140",
      errors: {},
      orderId: "",
      loading: false,
      deliveryState: "",
      paymentMethod: "",
      cardLastName: "An",
      deliveryAddress: "",
      deliveryCity: "",
      deliveryCountry: "",
      cardExpiry: "02/20",
      deliveryPostCode: "",
      cardExpireYear: "20",
      cardFirstName: "Ninh",
      cardExpireMonth: "02",
      cardNumber: "4811 8661 4463 8348",
      cardType: Config.CARD_TYPES.MASTER_CARD,
    };
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeRadio = (e, { value }) => {
    this.setState({ cardType: value });
  };

  onChangeAddress = (address) => {
    this.setState({
      deliveryAddress: address,
    });
  };

  validate = () => {
    const errors = {};

    return errors;
  };

  payment = (token) => {
    const { intl, auth } = this.props;

    const params = {
      token_stripe: token,
    };

    this.setState({ loading: true });

    this.props
      .onCheckout(params)
      .then((response) => {
        this.setState({ loading: false });

        this.props.onRemoveAllCart();
        this.props.getUserInfo({
          userId: get(auth, "user.id"),
        });
        if (this.props.history) {
          this.props.history.push(
            `/checkout-success?orderId=${response.data.data.id}`
          );
        } else {
          window.location = `/checkout-success?orderId=${
            response.data.data.id
          }`;
        }
      })
      .catch((errors) => {
        this.setState({ loading: false });

        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  createCreditCardForm = async () => {
    const { intl } = this.props;

    try {
      const { token } = await this.requestPaymentToken();
      if (token && token.id) {
        this.payment(token.id);
      }
    } catch (e) {
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: "notification.please_try_again" }),
        level: "error",
      });
    }
  };

  componentDidMount() {
    const deliveryInfo = JSON.parse(localStorage.getItem("deliveryInfo"));

    if (deliveryInfo) {
      this.setState({
        deliveryAddress: deliveryInfo.deliveryAddress,
        deliveryPostCode: deliveryInfo.deliveryPostCode,
        paymentMethod: deliveryInfo.paymentMethod,
      });
    }

    this._notificationSystem = this.refs.notificationSystem;
  }

  render() {
    const { cart, auth, intl } = this.props;
    return (
      <Pane className="checkout-container pt-5 pb-5">
        <Pane className="container">
          {cart.items && cart.items.length > 0 ? (
            <>
              <Pane className="row">
                <Pane className="col-lg-3">
                  <Pane className="checkout-content">
                    {auth.isAuthenticated && (
                      <Pane className="checkout-info">
                        <h2 className="checkout-title mb-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/cart/user.svg`
                            }
                            alt="users"
                            className="icon"
                          />
                          <FormattedMessage
                            id="customer_information"
                            defaultMessage="Customer Information"
                          />
                        </h2>
                        <p className="check-label">
                          <FormattedMessage
                            id="full_name"
                            defaultMessage="Full name"
                          />
                          :{" "}
                          <span className="check-name">
                            {auth.user.displayName}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="email" defaultMessage="Email" />
                          :<span className="check-name">{auth.user.email}</span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="phone" defaultMessage="Phone" />
                          :
                          <span className="check-name">
                            {auth.user.phoneNumber}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="address"
                            defaultMessage="Address"
                          />
                          :
                          <span className="check-name">
                            {auth.user.address}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="post_code"
                            defaultMessage="Post code"
                          />
                          :{" "}
                          <span className="check-name">
                            {auth.user.postCode}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="delivery_country"
                            defaultMessage="Delivery country"
                          />
                          :{" "}
                          <span className="check-name">
                            {this.state.deliveryCountry}
                          </span>
                        </p>
                      </Pane>
                    )}
                    {!cart.payAtStore && (
                      <Pane className="checkout-info">
                        <h2 className="checkout-title">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/cart/location.svg`
                            }
                            alt="users"
                            className="icon"
                          />
                          <FormattedMessage
                            id="shipping_address"
                            defaultMessage="SHIPPING ADDRESS"
                          />
                        </h2>
                        <p className="check-label">
                          <FormattedMessage
                            id="delivery_address"
                            defaultMessage="Delivery address"
                          />
                          :{" "}
                          <span className="check-name">
                            {this.state.deliveryAddress}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="delivery_postcode"
                            defaultMessage="Delivery postcode"
                          />
                          :{" "}
                          <span className="check-name">
                            {this.state.deliveryPostCode}
                          </span>
                        </p>
                      </Pane>
                    )}
                    <Pane className="checkout-info">
                      <h2 className="checkout-title">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/cart/payment.svg`
                          }
                          alt="users"
                          className="icon"
                        />
                        <FormattedMessage
                          id="payment_method"
                          defaultMessage="PAYMENT METHOD"
                        />
                      </h2>
                      <Pane className="choose-payment">
                        {this.state.paymentMethod ===
                          Config.PAYMENT_METHOD.PAY_BY_CREDIT ||
                        this.state.paymentMethod === "" ? (
                          <Pane className="payment-credit">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/images/cart/credit.png`
                              }
                              alt="users"
                              className="icon"
                            />
                            <span>
                              {intl.formatMessage({ id: "pay_by_credit" })}
                            </span>
                          </Pane>
                        ) : null}
                      </Pane>
                    </Pane>
                    <Pane className="checkout-info">
                      <h2 className="checkout-title">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/cart/payment.svg`
                          }
                          alt="users"
                          className="icon"
                        />
                        <FormattedMessage
                          id="payment_information"
                          defaultMessage="Payment information"
                        />
                      </h2>
                      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                        <Elements>
                          <PaymentForm
                            requestPaymentToken={(request) =>
                              (this.requestPaymentToken = request)
                            }
                          />
                        </Elements>
                      </StripeProvider>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane className="col-lg-9 mt-lg-0 mt-3">
                  {!isEmpty(
                    !isEmpty(cart.items) &&
                      cart.items.filter(
                        (item) =>
                          item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
                      )
                  ) && (
                    <>
                      <Pane className="cart-table cart-table-desktop">
                        <h2 className="cart-title mb-3">
                          <FormattedMessage
                            id="delivery"
                            defaultMessage="DELIVERY"
                          />
                        </h2>
                        <CartList
                          cart={cart.items.filter(
                            (item) =>
                              item.deliveryMethod ===
                              Config.DELIVERY_METHOD.DELIVERY
                          )}
                        />
                      </Pane>
                      <Pane className="cart-table cart-table-responsive">
                        <CartList
                          cart={cart.items.filter(
                            (item) =>
                              item.deliveryMethod ===
                              Config.DELIVERY_METHOD.DELIVERY
                          )}
                          type="responsive"
                        />
                      </Pane>
                    </>
                  )}

                  {!isEmpty(
                    !isEmpty(cart.items) &&
                      cart.items.filter(
                        (item) =>
                          item.deliveryMethod === Config.DELIVERY_METHOD.PICKUP
                      )
                  ) && (
                    <>
                      <Pane className="cart-table cart-table-desktop mt-3">
                        <h2 className="cart-title mb-3">
                          <FormattedMessage
                            id="pickup"
                            defaultMessage="PICKUP"
                          />
                        </h2>
                        <Pane className="cart-list-content">
                          <CartList
                            cart={cart.items.filter(
                              (item) =>
                                item.deliveryMethod ===
                                Config.DELIVERY_METHOD.PICKUP
                            )}
                          />
                        </Pane>
                      </Pane>
                      <Pane className="cart-table cart-table-responsive">
                        <CartList
                          cart={cart.items.filter(
                            (item) =>
                              item.deliveryMethod ===
                              Config.DELIVERY_METHOD.PICKUP
                          )}
                          type="responsive"
                        />
                      </Pane>
                    </>
                  )}

                  {!isEmpty(
                    !isEmpty(cart.items) &&
                      cart.items.filter(
                        (item) =>
                          item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
                      )
                  ) && (
                    <>
                      <Pane className="cart-table cart-table-desktop mt-3">
                        <h2 className="cart-title mb-3">
                          <FormattedMessage
                            id="dine_in"
                            defaultMessage="DINE IN"
                          />
                        </h2>
                        <Pane className="cart-list-content">
                          <CartList
                            cart={cart.items.filter(
                              (item) =>
                                item.deliveryMethod ===
                                Config.DELIVERY_METHOD.DINE_IN
                            )}
                          />
                        </Pane>
                      </Pane>
                      <Pane className="cart-table cart-table-responsive">
                        <CartList
                          cart={cart.items.filter(
                            (item) =>
                              item.deliveryMethod ===
                              Config.DELIVERY_METHOD.DINE_IN
                          )}
                          type="responsive"
                        />
                      </Pane>
                    </>
                  )}
                  {!isEmpty(
                    !isEmpty(cart.items) &&
                      cart.items.filter(
                        (item) =>
                          item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
                      )
                  ) && (
                    <>
                      <Pane className="cart-table cart-table-desktop mt-3">
                        <h2 className="cart-title mb-3">
                          <FormattedMessage
                            id="take_away"
                            defaultMessage="Take Away"
                          />
                        </h2>
                        <Pane className="cart-list-content">
                          <CartList
                            cart={
                              !isEmpty(cart.items) &&
                              cart.items.filter(
                                (item) =>
                                  item.deliveryMethod ===
                                  Config.DELIVERY_METHOD.TAKE_AWAY
                              )
                            }
                          />
                        </Pane>
                      </Pane>
                      <Pane className="cart-table cart-table-responsive">
                        <CartList
                          cart={
                            !isEmpty(cart.items) &&
                            cart.items.filter(
                              (item) =>
                                item.deliveryMethod ===
                                Config.DELIVERY_METHOD.TAKE_AWAY
                            )
                          }
                          type="responsive"
                        />
                      </Pane>
                    </>
                  )}

                  <TotalPayment />
                </Pane>
              </Pane>
              <Pane className="row mt-3 flex-lg-row flex-column-reverse">
                <Pane className="col-lg-3 col-12 mt-lg-0 mt-3">
                  <Link to="/cart" className="btn btn-process">
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/cart/cart.svg`
                      }
                      alt="lock"
                      className="icon"
                    />
                    <FormattedMessage
                      id="cart.back_to_cart"
                      defaultMessage="Back to cart"
                    />
                  </Link>
                </Pane>
                <Pane className="col-lg-9 col-12 text-lg-right text-left">
                  <Button
                    className="btn btn-process btn-process-yellow"
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    onClick={this.createCreditCardForm}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/cart/lock.svg`
                      }
                      alt="lock"
                      className="icon"
                    />
                    <FormattedMessage
                      id="proceed_to_check_out"
                      defaultMessage="PROCEED TO CHECK OUT"
                    />
                  </Button>
                </Pane>
              </Pane>
            </>
          ) : (
            <Pane className="cart-no-items" style={{ textAlign: "center" }}>
              <p>
                <FormattedMessage
                  id="cart.no_items_in_your_cart"
                  defaultMessage="No items in your cart"
                />
              </p>
              <Link
                to="/"
                className="button-continue"
                style={{ maxWidth: "300px", margin: "15px auto" }}
              >
                <FormattedMessage
                  id="cart.continue_shopping"
                  defaultMessage="Continue shopping"
                />
              </Link>
            </Pane>
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

export default injectIntl(Payment);
