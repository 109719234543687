import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Image, List } from "semantic-ui-react";
import { Pane, Loading } from "shared/components/layouts";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
} from "react-share";
import { FormattedMessage, intlShape, injectIntl } from "react-intl";
import NotificationSystem from 'react-notification-system';
import { handleError } from 'shared/helpers';
import { Dimmer, Loader } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import Config from "constants/Config";
import { getUserContactsRequest, deleteUserContactsRequest, addUserContactsRequest, addMyContacts, getMyContacts, deleteMyContacts } from "../../actions/userContact";
import ReactTooltip from "react-tooltip";

class QRCode extends Component {
  static propTypes = {
    qrcode: PropTypes.string,
    storeInfo: PropTypes.object,
    intl: intlShape.isRequired,
    auth: PropTypes.object,
    getMyContacts: PropTypes.func,
    addMyContacts: PropTypes.func,
    deleteMyContacts: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this._notificationSystem = null;
    this.state = {
      flag: false,
      isSaved: false,
      loading: false,
      isOwner: this.props.auth.user.id === this.props.storeInfo.id,
      wallet_type: Config.WALLET_TYPE.CONTACT,
      isBgVideo: false,
    };
  }

  print = () => {
    const prtContent = document.getElementById("print-document");

    const WinPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(prtContent.innerHTML);

    setTimeout(function () {
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    }, 500);
  };


  onSubmit = () => {
    const { intl } = this.props;
    if (!this.props.auth.isAuthenticated) {
      let contact = {
        avatar: this.props.storeInfo.profileImage,
        name: !this.props.Product ? this.props.storeInfo.displayName : this.props.storeInfo.name,
        id: this.props.storeInfo.id,
        type: this.state.wallet_type
      }

      if (this.state.isSaved) {
        this.props.deleteUserContactsCompo(this.props.storeInfo.id, this.state.wallet_type);
      } else {
        this.props.addUserContactsCompo(contact);
      }
      this.setState({ isSaved: !this.state.isSaved }, () => {
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: this.state.isSaved ? 'notification.contact.add' : 'notification.contact.delete' }),
          level: 'success',
        });
      })

    } else {

      let data = {
        follower_id: this.props.storeInfo.id,
        type: this.state.wallet_type
      }

      let func = this.state.isSaved ? this.props.deleteMyContacts(data) : this.props.addMyContacts(data)

      this.setState({ loading: true })

      func.then((res) => {

        this.setState({ loading: false })

        this.setState({ isSaved: !this.state.isSaved })
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: this.state.isSaved ? 'notification.contact.add' : 'notification.contact.delete' }),
          level: 'success',
        });
      })
        .catch((errors) => {
          this.setState({ loading: false })
          this._notificationSystem.addNotification({
            message: handleError(
              errors,
              intl.formatMessage({ id: 'notification.please_try_again' })
            ),
            level: 'error',
          });
        });
    }
  };

  setIsSaved = (userContacts) => {
    if (userContacts.length < 0) {
      return false
    }

    let isSaved = userContacts.some(item => {
      let type = this.props.isMenu ? Config.WALLET_TYPE.MENU : Config.WALLET_TYPE.CONTACT;
      return (item.follower_id === this.props.storeInfo.id && item.type === type)
    })

    this.setState({ isSaved: isSaved })
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
    this.setState({ wallet_type: this.props.isMenu ? Config.WALLET_TYPE.MENU : Config.WALLET_TYPE.CONTACT })
    if (this.props.auth.isAuthenticated) {
      this.props.getMyContacts().then(() => {
        this.setIsSaved(this.props.myContacts);
      });
    } else {
      let oldContacts = localStorage.getItem("userContacts");
      let userContacts = JSON.parse(oldContacts) || [];
      this.props.getUserContactsCompo(userContacts)
      userContacts = userContacts.map(item => {
        return {
          ...item,
          follower_id: item.id
        }
      });
      this.setIsSaved(userContacts);
    }
  }

  render() {
    const { storeInfo, qrcode } = this.props
    const { loading, isOwner, wallet_type } = this.state;
    const shareURL = `${Config.SITE_URL}/${storeInfo.id}`;
    const styleMessage = {
      NotificationItem: {
        DefaultStyle: {
          backgroundColor: "#131c33",
          color: "#ffffff"
        },
        error: {
          color: 'red'
        }
      }
    }
    const textAction = this.state.isSaved ? 'remove' : 'add';
    const textAction2 = this.state.isSaved ? 'from' : 'to';
    const textToopTip = wallet_type === Config.WALLET_TYPE.MENU ? `Click to ${textAction} menu ${textAction2} your wallet` : `Click to ${textAction} contact ${textAction2} your wallet`
    return (
      <Pane>
        <Pane id="print-document">
          <Pane
            className="card-body popup-body-content popup-qrcode"
            style={{
              padding: "30px",
              width: "325px",
              border: "1px solid #cecece"
            }}>
            {!this.props.isBanner && storeInfo.backgroundImage && !Config.extVideo.includes(storeInfo.backgroundImage.substr(storeInfo.backgroundImage.lastIndexOf(".") + 1)) && (
              <Pane className="background-logo" >
                <Image
                  className="image-background-logo"
                  src={
                    storeInfo.backgroundImage ? `${storeInfo.backgroundImage}` : ""
                  }
                  style={{ width: "100%" }}
                />
              </Pane>
            )}
            <Pane className="qr-code__name" style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "16px",
              margin: "10px auto 0 auto"
            }}>
              {storeInfo.business.is_active == 1 ? storeInfo.business.name : storeInfo.fullName}
            </Pane>
            <Pane className="qr-code__desc" style={{
              textAlign: "center"
            }}>
              {storeInfo.business.description && storeInfo.business.is_active == 1 ?
                storeInfo.business.description :
                (storeInfo.description ? storeInfo.description : '')}
            </Pane>
            <Image
              className="qr-code"
              src={qrcode ? `${qrcode}` : ""}
              style={{ display: "block", margin: "0 auto", maxWidth: "300px" }}
            />
          </Pane>
        </Pane>
        {loading && (
          <Dimmer active inverted className="dimmer-qr">
            <Loading />
          </Dimmer>
        )}
        {!this.props.isBanner && (
          <Pane id="sharesocial" className="popup-qrcode popup-qrcode__bot">
            <List horizontal style={{ display: "flex", alignItems: "center" }}>
              <List.Item
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center"
                }}
              >
                <List.Content onClick={this.onSubmit} className={`item-qr ${isOwner ? 'hide' : ''}`} data-tip data-for={`tooltip-add`}>
                  <i
                    className={`fas color-white ${`fa-address-book ${this.state.isSaved ? "" : "ani-contact"}`
                      }`}
                    style={{
                      backgroundColor: this.state.isSaved ? "gray" : "red",
                      borderRadius: "96px",
                      boxShadow: "0 0 2px #888",
                      padding: "0.4em 0.45em",
                      fontSize: "13px"
                    }}></i>
                  <span className="icon-qr__text">
                    <FormattedMessage id="print_qr_code1" defaultMessage={this.state.isSaved ? "Added" : "Add"} />
                  </span>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="float"
                    id={"tooltip-add"}
                  >
                    {textToopTip}
                  </ReactTooltip>
                </List.Content>
                <List.Content>
                  <Link className="item-qr" onClick={this.props.onClose} to={`/${storeInfo.id}/videos`} >
                    <i className="icon-qr icon-videocam"></i>
                    <span className="icon-qr__text">
                      <FormattedMessage id="qr_code_shows" defaultMessage="Shows" />
                    </span>
                  </Link>
                </List.Content>
                <List.Content>
                  <a href={`https://www.google.com/maps?q=${storeInfo.business.is_active == 1 ? storeInfo.business.address : storeInfo.address
                    }`} className="item-qr" target="_blank">
                    <i className="icon-qr icon-location color-white" style={{ backgroundColor: "#ef9e20" }}></i>
                    <span className="icon-qr__text">
                      <FormattedMessage id="qr_code_map" defaultMessage="Map" />
                    </span>
                  </a>
                </List.Content>
                {/* <List.Content className="item-qr">
                    <i className="icon-qr icon-chat-1 color-white" style={{backgroundColor: "cadetblue"}}></i>
                    <span className="icon-qr__text">
                      <FormattedMessage id="print_qr_code1" defaultMessage="SMS" />
                    </span>
                  </List.Content> */}
                <List.Content>
                  <a className="item-qr" href={`tel:${storeInfo.business.is_active == 1 ? storeInfo.business.phone : storeInfo.phone}`} >
                    <i className="icon-qr icon-phone color-white" style={{ backgroundColor: "green" }}></i>
                    <span className="icon-qr__text">
                      <FormattedMessage id="qr_code_call" defaultMessage="Call" />
                    </span>
                  </a>
                </List.Content>
                <List.Content onClick={() => this.setState({ flag: !this.state.flag })} className="position-relative item-qr">
                  <i className="icon-qr icon-link-1 color-white" style={{ backgroundColor: "darkblue" }}></i>
                  <span className="icon-qr__text">
                    <FormattedMessage id="qr_code_share" defaultMessage="Share" />
                  </span>
                  <List horizontal className={`share-list ${this.state.flag ? 'd-flex' : 'hide'} `}>
                    <List.Content>
                      <FacebookShareButton className="share-item" url={shareURL}>
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                    </List.Content>
                    <List.Content>
                      <TwitterShareButton className="share-item" url={shareURL}>
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                    </List.Content>
                    <List.Content>
                      <EmailShareButton className="share-item" url={shareURL}>
                        <EmailIcon size={32} round={true} />
                      </EmailShareButton>
                    </List.Content>
                    <List.Content>
                      <LinkedinShareButton className="share-item" url={shareURL}>
                        <LinkedinIcon size={32} round={true} />
                      </LinkedinShareButton>
                    </List.Content>
                  </List>
                </List.Content>
                <List.Content>
                  <span className="item-qr" onClick={this.print}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/icon-print.png"}
                      alt=""
                      width="40"
                      style={{ height: '24px', objectFit: 'contain' }}
                    />
                    <span className="icon-qr__text" >
                      <FormattedMessage id="print_qr_code" defaultMessage="Print Card" />
                    </span>
                  </span>
                </List.Content>
              </List.Item>
            </List>
          </Pane>
        )}
        <NotificationSystem ref="notificationSystem" style={styleMessage} />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  myContacts: state.userContacts.results
});

const bindDispatchToProps = (dispatch) => ({
  addUserContactsCompo: bindActionCreators(addUserContactsRequest, dispatch),
  getUserContactsCompo: bindActionCreators(getUserContactsRequest, dispatch),
  deleteUserContactsCompo: bindActionCreators(deleteUserContactsRequest, dispatch),
  getMyContacts: bindActionCreators(getMyContacts, dispatch),
  addMyContacts: bindActionCreators(addMyContacts, dispatch),
  deleteMyContacts: bindActionCreators(deleteMyContacts, dispatch),
})

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(QRCode));
