import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { Payment } from 'components/scenes/cart/payment';
import { checkout, removeAllCart, requestClientAuthorization, orderUserCart } from 'actions/cart';
import { getUserInfo } from 'actions/auth';
import { Pane } from 'shared/components/layouts';

class PaymentPage extends Component {
  static propTypes = {
    requestClientAuthorization: PropTypes.func,
    removeAllCart: PropTypes.func
  }

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <Payment
            auth={this.props.auth}
            cart={this.props.cart}
            location={this.props.location}
            history={this.props.history}
            onCheckout={this.props.checkout}
            onRequestClientAuthorization={this.props.requestClientAuthorization}
            onRemoveAllCart={this.props.removeAllCart}
            orderUserCart={this.props.orderUserCart}
            getUserInfo={this.props.getUserInfo}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = state => ({
  auth: state.auth,
  cart: state.cart
});

const bindDispatchToProps = dispatch => ({
  checkout: bindActionCreators(checkout, dispatch),
  requestClientAuthorization: bindActionCreators(requestClientAuthorization, dispatch),
  removeAllCart: bindActionCreators(removeAllCart, dispatch),
  orderUserCart: bindActionCreators(orderUserCart, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
});

export default connect(bindStateToProps, bindDispatchToProps)(PaymentPage);
