import Config from "constants/Config";
import { formatJson } from "shared/helpers";

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const id = params.id || "";

  return {
    method: "GET",
    url: `/customers?first_scan_promotion_id=${id}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const staffs = [];
  if (response.data) {
    response.data.forEach((item) => {
      const data = formatJson(response, item.type, item.id);
      staffs.push(data);
    });
  }
  const data = staffs;
  return data;
};

export { exec, parseResponse };