import { get } from 'lodash';
import Config from 'constants/Config';
import { formatRoute, formatJson } from 'shared/helpers';

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const id = params.id || '';
  const category_id = params.category_id || '';
  const images = params.images === null ? null : '';

  return {
    method: 'GET',
    url: `/collections?user_id=${id}&images.id=${images}&category_id=${category_id}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const collections = [];

  if (response.data) {
    response.data.forEach((item) => {
      const collection = formatJson(response, item.type, item.id);
      if (!collection.user) {
        return;
      }

      collections.push({
        id: collection.id,
        name: collection.name,
        description: collection.description,
        typeCollect: collection.type,
        slug: `${formatRoute(collection.name, '-')}-${collection.id}`,
        category_id: get(collection, 'category.id'),
        category: get(collection, 'category'),
        primaryImage: collection.primaryImage && collection.primaryImage.url,
        views: collection.views,
        likes: collection.likes,
        userId: collection.user.id,
        created_at: collection.createdAt,
        category_name: get(collection, 'category.name'),
      });
    });
  }

  const data = collections;

  return data;
};

export { exec, parseResponse };
