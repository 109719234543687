import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from 'actions/auth';
import React, { Component } from 'react';
import OrderSummary from './OrderSummary';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { requestCancel } from 'actions/orders';
import { Pane, Loading } from 'shared/components/layouts';
import { Dimmer, Loader } from 'semantic-ui-react';
import { getDeliveryDetail } from 'actions/delivery';
import { pushNotification } from 'actions/notification';
import NotificationSystem from 'react-notification-system';

class BuyingOrderDetail extends Component {
  static propTypes = {
    logout: PropTypes.func,
    userInfo: PropTypes.object,
    business: PropTypes.object,
    requestCancel: PropTypes.func,
    pushNotification: PropTypes.func,
    getBuyingOrderDetail: PropTypes.func,
  };

  state = {
    loading: false,
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  getBuyingOrderDetail = (load) => {
    const deliveryId =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.deliveryId;

    this.setState({ loading: load });
    this.props
      .getDeliveryDetail({ id: deliveryId })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.getBuyingOrderDetail(true);

    this._notificationSystem = this.refs.notificationSystem;
  }

  render() {
    const { detail } = this.props;
    return (
      <Pane className="overview-container">
        <Pane className="product-container order-container mb-5">
          <h3 className="block-title pt10 text-uppercase">Delivery</h3>
          <Pane className="order-detail">
            <h3 className="block-title pt20 pb20 pl20">Delivery Detail</h3>
            <Pane className="products-grids">
              <Pane className="card-body">
                {this.state.loading ? (
                  <Dimmer active inverted>
                    <Loading />
                  </Dimmer>
                ) : detail && detail.id ? (
                  <OrderSummary detail={detail} />
                ) : (
                  <Pane className="no-detail" style={{ textAlign: 'center' }}>
                    <FormattedMessage
                      id="notification.cannot_get_order_details"
                      defaultMessage="Cannot get order details"
                    />
                  </Pane>
                )}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  detail: state.deliveryDetail.data,
  business: state.auth.user.business,
});

const bindDispatchToProps = (dispatch) => ({
  getDeliveryDetail: bindActionCreators(getDeliveryDetail, dispatch),
  requestCancel: bindActionCreators(requestCancel, dispatch),
  pushNotification: bindActionCreators(pushNotification, dispatch),
  logout: bindActionCreators(logout, dispatch),
});

export default withRouter(
  connect(
    mapStateToProps,
    bindDispatchToProps
  )(BuyingOrderDetail)
);
