import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Checkbox, Radio } from "semantic-ui-react";
import { Pane } from "shared/components/layouts";
import { Currency } from "../../../../shared/components";
import { formatDecimal } from "shared/helpers";

class Detail extends Component {
  onGetCurrent = (originalPrice) => {
    return formatDecimal(originalPrice);
  };

  render() {
    const { intl, detail } = this.props;
    return (
      <Pane className="tab-details">
        <h2 className="tab-title">
          {intl.formatMessage({
            id: "details",
            defaultMessage: "Details",
          })}
        </h2>
        <Pane
          dangerouslySetInnerHTML={{
            __html: detail,
          }}
        />
      </Pane>
    );
  }
}

Detail.propTypes = {};

export default injectIntl(Detail);
