import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Checkbox, Icon, Radio } from "semantic-ui-react";
import { Pane } from "shared/components/layouts";
import Config from "constants/Config";
import {
  removeToCart,
  changeQuantity,
  changeDelivery,
  changeOption,
} from "actions/cart";
import { Currency } from "shared/components";
import ReactTooltip from "react-tooltip";

class CartItem extends Component {
  static propTypes = {
    removeToCart: PropTypes.func.isRequired,
    changeDelivery: PropTypes.func.isRequired,
    changeQuantity: PropTypes.func.isRequired,
  };

  state = {
    loadingDelivery: false,
    loadingRemove: false,
    totalPrice: 0,
    viewOption: {
      openModal: false,
      detail: {},
      isEdit: true,
    },
  };

  removeItem = (item) => {
    const params = {
      id: item.id,
    };

    this.setState({ loadingRemove: true });

    this.props
      .removeToCart(params)
      .then(() => this.setState({ loadingRemove: false }))
      .catch(() => this.setState({ loadingRemove: false }));
  };

  changeQuanlity = (item, action) => {
    const params = {
      id: item.id,
      quantity: item.quantity,
    };

    this.setState({ loadingRemove: false });

    switch (action) {
      case "inc":
        params.quantity = item.quantity + 1;
        this.props.changeQuantity(params);
        break;
      case "dec":
        params.quantity = item.quantity - 1;
        this.props.changeQuantity(params);
        break;
      default:
        break;
    }
  };

  changeOption = (item, optionIds) => {
    const params = {
      id: item.id,
      optionInfo: JSON.stringify(optionIds),
    };

    this.props.changeOption(params);
  };

  changeDeliveryMethod = (item) => {
    const params = {
      id: item.id,
      deliveryMethod:
        item.deliveryMethod === Config.DELIVERY_METHOD.DELIVERY
          ? Config.DELIVERY_METHOD.PICKUP
          : Config.DELIVERY_METHOD.DELIVERY,
    };

    this.setState({ loadingDelivery: true });

    this.props.changeDelivery(params).then(() => {
      this.setState({ loadingDelivery: true });
      this.props.onChangeType(params.deliveryMethod)
    });
  };

  changeDeliveryMethodDineIn = (item) => {
    const params = {
      id: item.id,
      deliveryMethod:
        item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
          ? Config.DELIVERY_METHOD.TAKE_AWAY
          : Config.DELIVERY_METHOD.DINE_IN,
    };

    this.setState({ loadingDelivery: true });

    this.props
      .changeDelivery(params)
      .then(() => {
        this.setState({ loadingDelivery: false });
      })
      .catch(() => {
        this.setState({ loadingDelivery: false });
      });
  };

  isOptions = () => {
    if (!this.props.item.options) return false;
    if (!this.props.item.options.length) return false;

    return true;
  };

  includesOption = (id) => {
    const { optionInfo } = this.props.item;
    return optionInfo.find((child) => child.id === id);
  };

  getSelectedOptions = () => {
    if (!this.isOptions()) return [];

    const { options } = this.props.item;
    let selectdOptions = [];

    options.map((option) => {
      const { childrens } = option;
      childrens.forEach((child) => {
        if (this.includesOption(child.id)) {
          selectdOptions.push({
            ...child,
            quantity: this.includesOption(child.id).quantity,
          });
        }
      });
    });
    return selectdOptions;
  };

  mappingSelectOptionToText = () => {
    const selectedOptions = this.getSelectedOptions();
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${
            child.name
          }`
      )
      .join(", ");
  };

  onViewCartOption = () => {
    this.props.cartModal.setCartView({ ...this.props.item, modeCart: true });
    this.props.cartModal.openModal();
  };

  getTotalPriceOptions = () => {
    const list = this.getSelectedOptions();

    let total = 0;

    list.forEach((item) => {
      total += item.price * item.quantity;
    });

    return total;
  };

  getTotalPriceItemCart = () => {
    const { item } = this.props;

    return (item.promotionPrice + this.getTotalPriceOptions()) * item.quantity;
  };

  render() {
    const { item, type } = this.props;
    return (
      <>
        <tr>
          <td>
            <Link to={`/products/${item.productId}`}>
              <img
                src={
                  item.primaryImage
                    ? item.primaryImage
                    : process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`
                }
                size="tiny"
                ref={(img) => (this.img = img)}
                alt="products"
                className="image img-table"
                onError={() =>
                  (this.img.src =
                    process.env.PUBLIC_URL + `/assets/images/default-image.png`)
                }
              />
            </Link>
          </td>
          <td className="col-info">
            <Link to={`/products/${item.productId}`} className="table-name">
              <b>{item.name}</b>
            </Link>
            <span className="price">
              <Currency price={item.price} />
            </span>
            <Pane className="table-quantily">
              <span
                className="operator"
                onClick={this.changeQuanlity.bind(this, item, "dec")}
              >
                -
              </span>
              <span className="operator">{item.quantity}</span>
              <span
                className="operator"
                onClick={this.changeQuanlity.bind(this, item, "inc")}
              >
                +
              </span>
            </Pane>
            {this.isOptions() ? (
              <Pane className="view-options">
                <span onClick={this.onViewCartOption}>
                  <Icon name="edit" />
                  {this.mappingSelectOptionToText()}
                </span>
              </Pane>
            ) : null}
          </td>
          <td className="align-middle">
            <span className="price total-price">
              <Currency price={this.getTotalPriceItemCart()} />
            </span>
          </td>
          {[Config.DELIVERY_METHOD.DINE_IN, Config.DELIVERY_METHOD.TAKE_AWAY].includes(type) && (
            <td className="col-button align-middle">
              {item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN ? (
                <Button
                  size="mini"
                  icon
                  color="yellow"
                  className="btn-table btn-pickup"
                  loading={this.state.loadingDelivery}
                  disabled={this.state.loadingDelivery}
                  onClick={() => this.changeDeliveryMethodDineIn(item)}
                >
                  <FormattedMessage id="take_away" defaultMessage="Take Away" />
                </Button>
              ) : (
                <Button
                  size="mini"
                  icon
                  color="yellow"
                  className="btn-table btn-pickup"
                  loading={this.state.loadingDelivery}
                  disabled={this.state.loadingDelivery}
                  onClick={() => this.changeDeliveryMethodDineIn(item)}
                >
                  <FormattedMessage id="dine_in" defaultMessage="Dine in" />
                </Button>
              )}
              <Button
                size="mini"
                className="btn-table"
                icon
                loading={this.state.loadingRemove}
                disabled={this.state.loadingRemove}
                onClick={this.removeItem.bind(this, item)}
              >
                <FormattedMessage id="remove" defaultMessage="Remove" />
              </Button>
            </td>
          )}
          {![Config.DELIVERY_METHOD.DINE_IN, Config.DELIVERY_METHOD.TAKE_AWAY].includes(type) && (
            <td className="col-button align-middle">
              {item.deliveryMethod === Config.DELIVERY_METHOD.PICKUP ? (
                <>
                  <Button
                    size="mini"
                    icon
                    color="yellow"
                    className="btn-table btn-pickup"
                    loading={this.state.loadingDelivery}
                    disabled={this.state.loadingDelivery}
                    onClick={this.changeDeliveryMethod.bind(this, item)}
                    data-tip
                    data-for={`tooltip-delivery`}
                  >
                    <span className="ic-common-delivery mr-1" />
                    <FormattedMessage id="delivery" defaultMessage="Delivery" />
                  </Button>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="float"
                    id={"tooltip-delivery"}
                  >
                    Click to move item to Delivery
                  </ReactTooltip>
                </>
              ) : (
                <>
                  <Button
                    size="mini"
                    icon
                    color="yellow"
                    className="btn-table btn-pickup"
                    loading={this.state.loadingDelivery}
                    disabled={this.state.loadingDelivery}
                    onClick={this.changeDeliveryMethod.bind(this, item)}
                    data-tip
                    data-for={`tooltip-pickup`}
                  >
                    <span className="ic-common-pickup mr-1" />
                    <FormattedMessage id="pickup" defaultMessage="Pickup" />
                  </Button>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="float"
                    id={"tooltip-pickup"}
                  >
                    Click to move item to Pickup
                  </ReactTooltip>
                </>
              )}
              {/* <span className="icon-trash cart_trash" onClick={this.removeItem.bind(this, item)}></span> */}
              <Button
                size="mini"
                className="btn-table"
                icon
                loading={this.state.loadingRemove}
                disabled={this.state.loadingRemove}
                onClick={this.removeItem.bind(this, item)}
                style={{
                  border: "none",
                  marginLeft: "20px",
                  padding: 0,
                }}
              >
                <span className="icon-trash cart_trash" />
                {/* <FormattedMessage id="remove" defaultMessage="Remove" /> */}
              </Button>
            </td>
          )}
        </tr>
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  removeToCart: bindActionCreators(removeToCart, dispatch),
  changeQuantity: bindActionCreators(changeQuantity, dispatch),
  changeDelivery: bindActionCreators(changeDelivery, dispatch),
  changeOption: bindActionCreators(changeOption, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(withRouter(CartItem)));
