import * as types from 'constants/ActionTypes'
import api from 'api'

export const addCustomer = (params) => (dispatch) => dispatch({
  type: types.CUSTOMER_ADD_INFO,
  payload: {
    request: api.customer.add(params)
  }
})
export const checkCode = (params) => (dispatch) => dispatch({
  type: types.CUSTOMER_ADD_CHECK_CODE,
  payload: {
    request: api.customer.checkCode(params)
  }
})
