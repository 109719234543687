const ConfigCommon = require('./Config.common')

const Config = {
  SITE_URL: 'https://cardbey.com',
  API_URL: 'https://api.cardbey.com',
  AUTH2_API_URL: 'https://api.cardbey.com',
  NEW_API_URL: 'https://api.cardbey.com',
  FACEBOOK_CLIENT_ID: '2575460632698393',
  GOOGLE_CLIENT_ID: '364210914850-6q8lfb4ovl5tphb2rmujsb757nqu97l5.apps.googleusercontent.com',
  IMAGE_URL: 'https://api.cardbey.com',
  NOTIFICATION_API_URL: 'https://notification.cardbey.com',
  ...ConfigCommon
}

export default Config
