import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import Header from '../partials/Header';
import Footer from '../partials/Footer';

class UserLayout extends Component {
  render() {
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {})
    );

    return (
      <Pane className="wrapper-container">
        <Header />
        <main className="main-container">{childrenWithProps}</main>
        <Footer />
      </Pane>
    );
  }
}

export default UserLayout;
