module.exports = {
    DELIVERY_METHOD: {
        PICKUP: 'PICKUP',
        DELIVERY: 'DELIVERY',
        DINE_IN: 'DINE_IN',
        TAKE_AWAY: 'TAKE_AWAY'
    },
    ORDER_STATUS: {
        PENDING: 'PENDING',
        NEW: 'NEW',
        CANCELLED: 'CANCELLED',
        CONFIRMED: 'CONFIRMED',
        PREPARING: 'PREPARING',
        DELIVERED: 'DELIVERED',
        DONE: 'DONE',
        CANCEL_REQUESTED: 'REQUESTED',
        CANCEL_APPROVED: 'APPROVED',
        CANCEL_DENIED: 'DENIED',
    },
    PAYMENT_METHOD: {
        PAY_BY_CASH: 'CASH',
        PAY_BY_CREDIT: 'CREDIT',
        PAY_BY_PAYPAL: 'PAYPAL'
    },
    CARD_TYPES: {
        MASTER_CARD: 'mastercard',
        VISA_CARD: 'visa',
        PAYPAL: 'paypal'
    },
    MODE_VIEW_MENU: {
        MODE_LIST: "listView",
        MODE_GIRD: "girdView"
    },
    PRODUCT_TYPE: 'products',
    SERVICE_TYPE: 'services',
    VIDEO_TYPE: 'videos',
    VIDEO_TIME: 20000,
    BUSINESS_TYPE: {
        FOOD: "Food",
        PRODUCT: "Product",
        SERVICE: "Service"
    },
    WALLET_TYPE: {
        MENU: "Menu",
        CONTACT: "Contact",
    },
    extVideo: ['ogm', 'wvm', 'mpg', 'webm', 'ogv', 'omv', 'asx', 'mpeg', 'mp4', 'avi', 'mov', 'm4v'],
    SCREEN_TYPE: {
        HORIZONTAL: 'Horizontal',
        VERTICAL: 'Vertical'
    },
    TYPE_ORDER: "ORDER",
    TYPE_BOOKING: "BOOKING",
    TYPE_DIGITAL_SHARE_HOST: "DIGITAL_SHARE_HOST",
    TYPE_DIGITAL_SHARE_RENTER: "DIGITAL_SHARE_RENTER",
    STAFF_ID_DEFAULT: 9999,
    ID_ADMIN: 1
}
