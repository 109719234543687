import React from 'react';
import PropTypes from 'prop-types';
import { checkDay } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';
import TradingHourItem from './TradingHourItem';

const TradingHours = ({ tradingHours }) => {
  const paramtradingHours = tradingHours;
  // eslint-disable-next-line no-param-reassign
  tradingHours = {
    Mon: {
      from: '',
      to: '',
      off: false,
    },
    Tue: {
      from: '',
      to: '',
      off: false,
    },
    Wed: {
      from: '',
      to: '',
      off: false,
    },
    Thu: {
      from: '',
      to: '',
      off: false,
    },
    Fri: {
      from: '',
      to: '',
      off: false,
    },
    Sat: {
      from: '',
      to: '',
      off: false,
    },
    Sun: {
      from: '',
      to: '',
      off: false,
    },
  };
  if (paramtradingHours) {
    Object.keys(paramtradingHours).forEach((item) => {
      const key = item.charAt(0).toUpperCase() + item.slice(1);
      // if (this.state.tradingHour[item].off || !this.state.tradingHour[item].from || !this.state.tradingHour[item].to) {
      if (!paramtradingHours[item].from || !paramtradingHours[item].to) {
        tradingHours[key].from = '00:00';
        tradingHours[key].to = '01:00';
        tradingHours[key].off = true;
      } else {
        tradingHours[key].from = paramtradingHours[item].from;
        tradingHours[key].to = paramtradingHours[item].to;
        tradingHours[key].off = paramtradingHours[item].off;
      }
    });
  }
  let firstItem = Object.values(tradingHours)[0];
  let tradingHour = Object.values(tradingHours).filter((item, key) => {
    return JSON.stringify(item) == JSON.stringify(firstItem) && key === 0;
  })
  return (
    <Pane className="heading-trading">
      {/* <h2 className="heading-title">Trading Hour</h2> */}
      <Pane className="bussiness-content">
        <ul className="bussiness-list">
          {Object.keys(tradingHour) &&
            Object.keys(tradingHour).map((key, index) => {
              return (
                // tradingHours[key] && (tradingHours[key].from || tradingHours[key].to) && !tradingHours[key].off
                  (tradingHour[key].from || tradingHour[key].to) ? (
                    <TradingHourItem
                      key={key + index}
                      from={tradingHour[key].from}
                      to={tradingHour[key].to}
                      off={tradingHour[key].off ? ' Closed' : ''}
                    />
                  ) : null
              );
            })}
        </ul>
      </Pane>
    </Pane>
  );
};

TradingHours.propTypes = {
  tradingHours: PropTypes.object,
};

TradingHours.defaultProps = {
  tradingHours: {},
};

export default TradingHours;
