import * as DeliveryV1 from './user-delivery.v1.action';
import * as DeliveryReadV1 from './user-delivery-read.v1.action';
import * as DeliveryBrowseV1 from './user-delivery-browse.v1.action';
import * as DeliveryRequestCancelV1 from './user-delivery-request-cancel.v1.action';

export default {
  delivery: {
    callAPI: (params) => DeliveryV1.exec(params),
    parseResponse: (response) => DeliveryV1.parseResponse(response),
  },
  browse: {
    callAPI: (params) => DeliveryBrowseV1.exec(params),
    parseResponse: (response) => DeliveryBrowseV1.parseResponse(response),
  },
  read: {
    callAPI: (params) => DeliveryReadV1.exec(params),
    parseResponse: (response) => DeliveryReadV1.parseResponse(response),
  },
  request: {
    callAPI: (params) => DeliveryRequestCancelV1.exec(params),
    parseResponse: (response) =>
      DeliveryRequestCancelV1.parseResponse(response),
  },
};
