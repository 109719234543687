import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Pane } from "shared/components/layouts";

class FeatureProductItem extends Component {
  renderItem(index) {
    if (this.props.items && this.props.items.length > index) {
      return (
        <Link
          className="inner-link"
          to={`/${this.props.type}/${this.props.items[index].slug}`}
        >
          <img
            src={
              this.props.items[index].primaryImage
                ? `${this.props.items[index].primaryImage}?w=400`
                : process.env.PUBLIC_URL + `/assets/images/default-image.png`
            }
            alt={this.props.items[index].name}
            className="inner-img"
            ref={(img) => (this.img = img)}
            onError={() =>
              (this.img.src =
                process.env.PUBLIC_URL + `/assets/images/default-image.png`)
            }
          />
        </Link>
      );
    }
    return "";
  }

  renderItemResponsive() {
    const { items } = this.props;
    if (items) {
      return (
        <Link className="inner-link" to={`/${this.props.type}/${items.slug}`}>
          <img
            src={
              items.primaryImage
                ? `${items.primaryImage}?w=400`
                : process.env.PUBLIC_URL + `/assets/images/default-image.png`
            }
            alt={items.name}
            className="inner-img"
          />
        </Link>
      );
    }
    return "";
  }

  render() {
    if (this.props.device !== "responsive") {
      if (this.props.items.length === 4) {
        return (
          <>
            <Pane className="inner">{this.renderItem(0)}</Pane>
            <Pane className="inner inner-row">
              <Pane className="inner-children">{this.renderItem(1)}</Pane>
              <Pane className="inner-children">{this.renderItem(2)}</Pane>
            </Pane>
            <Pane className="inner">{this.renderItem(3)}</Pane>
          </>
        );
      }
      if (this.props.items.length === 2 || this.props.items.length === 3) {
        return (
          <>
            <Pane className="inner inner-first">{this.renderItem(0)}</Pane>
            <Pane className="inner inner-two">{this.renderItem(1)}</Pane>
          </>
        );
      }
      if (this.props.items.length === 1) {
        return (
          <>
            <Pane className="inner inner-only">{this.renderItem(0)}</Pane>
          </>
        );
      }
      return null;
    }
    return <Pane className="slider-item">{this.renderItemResponsive()}</Pane>;
  }
}

export default FeatureProductItem;
