import Config from 'constants/Config';
import { formatRoute, formatJson } from 'shared/helpers';

const exec = (params) => {
  return {
    method: 'GET',
    url: `/users/${params.userId}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  let business = {};

  const user = formatJson(response, response.data.type, response.data.id);
  if (user && user.business) {
    business = {
      id: user.business.id,
      name: user.business.name,
      email: user.business.email,
      description: user.business.description,
      phone: user.business.phone,
      address: user.business.address,
      state: user.business.state,
      city: user.business.city,
      postCode: user.business.postcode,
      tradingHours: user.business.tradingHours,
      type: user.business.type,
      category_id: user.business.category && user.business.category.id,
      isGridView: user.business.isGridView,
      is_active: user.business.isActive,
      firstScanFlg: user.business.firstScanFlg,
      firstScanDiscount: user.business.firstScanDiscount,
      firstScanPromotionId: user.business.firstScanPromotionId,
      lat: user.business.lat,
      lng: user.business.lng,
      websiteUrl: user.business.urlWebsite,
      userId: user.business.userId,
      subTitle: user.business.subTitle,
      rating: user.business.statistic && user.business.statistic.rating ? parseFloat(user.business.statistic.rating).toFixed(1) : 0
    };
  }

  const data = {
    business,
    id: user.id,
    uuid: user.uuid,
    reference: user.reference,
    referenceUserId: user.referenceUserId,
    fullName: user.fullname,
    displayName: user.fullname,
    slug: formatRoute(user.fullname, '-'),
    email: user.email,
    address: user.address,
    phoneNumber: user.phone,
    postCode: user.postcode,
    country: user.country,
    claimed: user.claimed,
    isFirstOrder: user.isFirstOrder,
    backgroundImage: user.backgroundImage && user.backgroundImage.url,
    profileImage: user.image && user.image.url,
    qrcode: user.qrcode && user.qrcode.url,
    rating: 5,
    lat: user.lat,
    lng: user.lng,
    qrcodeMenu: user.qrcodeMenu && user.qrcodeMenu.url,
    userContacts: user.userContacts && user.userContacts,
    isFirstLogin: user.isFirstLogin,
    description: user.description,
    url_website: user.urlWebsite,
    sub_title: user.subTitle,
  };

  return data;
};

export { exec, parseResponse };
