import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Icon, Table } from "semantic-ui-react";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactPaginate from "react-paginate";
import { Pane, Loading } from "shared/components/layouts";
import queryString from "query-string";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatRoute, formatJson } from "shared/helpers";
import { getTasksByUserId, removeMyTask } from "actions/tasks";
import { getMyStaffAssign } from "actions/staffs";
import {
  getTaskMembersByUserId,
  createMyTaskMember,
  removeMyTaskMember,
} from "actions/taskMembers";
import { confirmAlert } from "react-confirm-alert";
import { AssignTaskModal } from "../../../../shared/components";
class Tasks extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      srName: "",
      size: 20,
      page: parseInt(queryString.parse(search).page, 10) || 1,
      openModal: false,
      assignStaffs: [],
      memberTasks: [],
      taskId: "",
      taskName: "",
    };
  }
  onOpenModal = (task) => {
    const { id: taskId, name } = task;
    this.setState({ openModal: true, taskId, taskName: name });
    this.loadDataModal(taskId);
  };
  onCloseModal = () => {
    this.setState({ openModal: false, taskId: "", taskName: "" });
  };
  loadDataModal = (taskId) => {
    const { userInfo } = this.props;
    this.props
      .getMyStaffAssign({ id: userInfo.id, taskId })
      .then((response) => {
        let assignStaffs = [];
        if (response && response.data) {
          response.data.data.forEach((item) => {
            const data = formatJson(response.data, item.type, item.id);
            assignStaffs.push(data);
          });
        }
        this.setState({ assignStaffs });
      });
    this.props
      .getTaskMembersByUserId({ id: userInfo.id, taskId })
      .then((response) => {
        if (response && response.data) {
          let memberTasks = [];
          if (response && response.data) {
            response.data.data.forEach((item) => {
              const data = formatJson(response.data, item.type, item.id);
              memberTasks.push(data);
            });
          }
          this.setState({ memberTasks });
        }
      });
  };
  componentWillUnmount() {
    this.unlisten();
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getTasksByUserId();
    });
    this.getTasksByUserId();
  }
  getTasksByUserId = (params) => {
    const { userInfo } = this.props;
    this.props.getTasksByUserId(
      {
        id: userInfo.id,
        size: this.state.size,
        name: this.state.srName,
      },
      true
    );
  };
  deleteItem = (id) => {
    this.props.removeMyTask({ id }).then(() => this.getTasksByUserId());
  };
  onSearch = () => {
    this.getTasksByUserId();
  };
  handlePageClick = (data) => {
    const params = {
      id: this.props.userInfo.id,
      page: data.selected + 1,
      size: this.state.size,
      name: this.state.srName,
    };
    this.props.getTasksByUserId(params, true);
  };
  changeRoute = (id) => {
    const { userInfo } = this.props;
    this.props.history.push(
      `/u/${formatRoute(userInfo.displayName)}/task/${id}/edit`
    );
  };
  showConfirmDelete = (id) => {
    const { intl } = this.props;
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: `${intl.formatMessage({
        id: "notification.are_you_sure_you_want_to_delete_this",
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => {
            this.deleteItem(id);
          },
        },
        {
          label: intl.formatMessage({ id: "no" }),
        },
      ],
    });
  };
  render() {
    const { userInfo, tasks } = this.props;
    return (
      <Pane className="overview-container">
        <AssignTaskModal
          assignTaskModal={this.state.dataAssignTaskModal}
          openModal={this.state.openModal}
          onCloseModal={this.onCloseModal}
          assignStaffs={this.state.assignStaffs}
          memberTasks={this.state.memberTasks}
          taskId={this.state.taskId}
          taskName={this.state.taskName}
          createMyTaskMember={this.props.createMyTaskMember}
          removeMyTaskMember={this.props.removeMyTaskMember}
          reloadData={this.loadDataModal}
        />
        <Pane className="product-container order-container">
          <h3 className="block-title pt10 text-uppercase">
            <FormattedMessage id="task_list" defaultMessage="Project List" />
          </h3>
          <Pane className="product-heading">
            <Pane className="area-search">
              <Pane className="search-box">
                <Pane className="search-field">
                  <input
                    type="text"
                    name="term"
                    className="search-input"
                    value={this.state.srName}
                    placeholder="Search Name ..."
                    onChange={(e) => {
                      this.setState({ srName: e.target.value });
                    }}
                  />
                </Pane>
              </Pane>
              <button
                onClick={this.onSearch}
                className="white btn btn-circle btn-circle-primary d-none d-lg-block"
              >
                <FormattedMessage id="search" defaultMessage="Search" />
              </button>
            </Pane>
            <Link
              to={`/u/${formatRoute(userInfo.displayName)}/task/create`}
              className="btn btn-circle btn-circle-primary d-none d-lg-block"
            >
              <FormattedMessage
                id="create_new_task"
                defaultMessage="Create New Project"
              />
            </Link>
          </Pane>
          <Pane className="products-grids">
            {!tasks.fetched && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id="name" defaultMessage="Name" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="task_start_date"
                      defaultMessage="Start Date"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="task_end_date"
                      defaultMessage="End Date"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="task_status"
                      defaultMessage="Status"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="task_actions"
                      defaultMessage="Actions"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tasks.results && tasks.results.length
                  ? tasks.results.map((task) => (
                      <Table.Row>
                        <Table.Cell>{task.name}</Table.Cell>
                        <Table.Cell>
                          {moment
                            .utc(task.startDate, "YYYY-MM-DD HH:mm")
                            .format("YYYY-MM-DD")}
                        </Table.Cell>
                        <Table.Cell>
                          {moment
                            .utc(task.endDate, "YYYY-MM-DD")
                            .format("YYYY-MM-DD")}
                        </Table.Cell>
                        <Table.Cell>{task.status}</Table.Cell>
                        <Table.Cell>
                          <Pane
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              justifyContent: "flex-start",
                            }}
                            className="adds-actions"
                          >
                            <a onClick={() => this.changeRoute(task.id)}>
                              <Icon name="pencil" />
                            </a>
                            <a onClick={() => this.showConfirmDelete(task.id)}>
                              <Icon name="trash" />
                            </a>
                            <a onClick={() => this.onOpenModal(task)}>
                              <Icon name="inbox" />
                            </a>
                          </Pane>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  : null}
              </Table.Body>
            </Table>
            {tasks.fetched &&
              !tasks.fetching &&
              tasks.results &&
              tasks.results.length <= 0 && (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.you_have_not_had_any_services_yet"
                      defaultMessage={`You haven't had any services yet.`}
                    />
                  </p>
                </Pane>
              )}
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {tasks.totalPages > 1 ? (
                <ReactPaginate
                  previousLabel={
                    <FormattedMessage
                      id="pagination.previous"
                      defaultMessage="previous"
                    />
                  }
                  nextLabel={
                    <FormattedMessage
                      id="pagination.next"
                      defaultMessage="next"
                    />
                  }
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={"break-me"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  pageCount={tasks.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              ) : null}
            </nav>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  tasks: state.myTasks,
});
const bindDispatchToProps = (dispatch) => ({
  getTasksByUserId: bindActionCreators(getTasksByUserId, dispatch),
  removeMyTask: bindActionCreators(removeMyTask, dispatch),
  getMyStaffAssign: bindActionCreators(getMyStaffAssign, dispatch),
  getTaskMembersByUserId: bindActionCreators(getTaskMembersByUserId, dispatch),
  createMyTaskMember: bindActionCreators(createMyTaskMember, dispatch),
  removeMyTaskMember: bindActionCreators(removeMyTaskMember, dispatch),
});
export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(Tasks));