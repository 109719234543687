import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Pane } from "shared/components/layouts";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { formatRoute } from "shared/helpers";
import ReactPlayer from "react-player";

class BestPickItem extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    const { type } = this.props;
    const slug =
      this.props.item.user && this.props.item.user.business != null
        ? formatRoute(this.props.item.user.business.name, "-")
        : "";
    const item =
      type === "STORE" ? (
        <Pane className="bestpicks-item text-center">
          <Link to={`/${this.props.item.slug}`}>
            <Pane>
              <img
                src={this.props.item.src}
                alt="logo-1"
                className="bestpicks-img"
                style={{ minHeight: "162px" }}
              />
              <h2 className="bestpicks-title">{this.props.item.name}</h2>
            </Pane>
          </Link>
        </Pane>
      ) : (
        <Pane
          className="bestpicks-item text-center"
          style={{ position: "relative" }}
        >
          <Link to={`/${slug}/videos`}>
            <ReactPlayer
              url={this.props.item.video.url}
              className="bestpicks-video"
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
            />
            <h2 className="bestpicks-title">{this.props.item.title}</h2>
          </Link>
          <ReactTooltip
            effect="solid"
            id="business-profile"
            type="dark"
            wrapper="div"
            html={true}
          />
        </Pane>
      );
    return item;
  }
}

BestPickItem.propTypes = {
  type: PropTypes.string,
};

export default BestPickItem;
