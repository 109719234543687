import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import YouTube from 'react-youtube';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

class Application extends Component {
  render() {
    const opts = {
      playerVars: {
        autoplay: 1,
        showinfo: 1,
        frameborder: 1,
        disablekb: 1,
        fs: 1, //zoom screen
        hl: 0,
        listType: 0,
        loop: 1,
        mute: 1,
        rel: 0,
      },
    };
    return (
      <Pane className="application-container">
        <Pane className="container">
          <Pane className="application-content">
            <Pane className="row">
              <Pane className="col-lg-6">
                <Pane className="application-video">
                  <YouTube
                    videoId={this.props.intl.formatMessage({
                      id: 'video_id_youtue',
                      defaultMessage: 'Y0mmqexQ5SA',
                    })}
                    opts={opts}
                    containerClassName="video-container"
                    className="video-custom"
                  />
                </Pane>
              </Pane>
              <Pane className="col-lg-6 d-flex align-items-center">
                <Pane className="application-info text-lg-left text-md-left text-center">
                  <h3 className="application-title">
                    <FormattedMessage id="home.going_socialized" />
                  </h3>
                  <p className="application-norm">
                    <FormattedMessage id="home.we_are_standing_out_with_millions" />
                  </p>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
const bindStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(bindStateToProps)(injectIntl(Application)));
