import { List } from '../list';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Pane } from 'shared/components/layouts';
import { withRouter, Link } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import {
  getCollectionsByUserId,
  getCategoriesByUserId,
  deleteMyCollection,
} from 'actions/myCollections';
import _, { isEmpty } from 'lodash';
import update from 'immutability-helper';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { formatRoute, handleError } from 'shared/helpers';
import { LoadingSearch } from 'components/common/loading';
import {
  updateInfo,
} from "actions/user";
import { getUserInfo } from "actions/auth";
import {
  updateMyBusinessInfo,
} from "actions/myBusiness";

const MenuItem = ({ text, selected }) => {
  return <div className={`menu-item ${selected ? 'active' : ''}`}>{text}</div>;
};

// All items component
// Important! add unique key
export const Menu = (list, selected) => {
  return list.map((el) => {
    const { name } = el;
    return <MenuItem text={name} key={name} selected={selected} />;
  });
};

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
  text: (
    <img
      src={process.env.PUBLIC_URL + '/assets/images/icons/prev-tab.svg'}
      className="image-logo"
      alt="list-view"
    />
  ),
  className: 'arrow-prev',
});
const ArrowRight = Arrow({
  text: (
    <img
      src={process.env.PUBLIC_URL + '/assets/images/icons/next-tab.svg'}
      className="image-logo"
      alt="list-view"
    />
  ),
  className: 'arrow-next',
});
class Collections extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    collections: PropTypes.object,
    getCollectionsByUserId: PropTypes.func,
    deleteMyCollection: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      data: [],
      totalPages: 0,
      selected: '',
      keySort: null,
      fetching: false,
      fetched: false,
      loading: false
    };
    this._notificationSystem = null;
  }

  getCollectionsByUserId = () => {
    const { userInfo } = this.props;

    this.props
      .getCollectionsByUserId({
        id: userInfo.id,
        size: 100,
        group: 'Menu',
        images: '',
      })
      .then(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  };

  deleteItem = (params) => {
    const { intl } = this.props;

    if (this.props.collections.results.length === 1) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }

    this.props
      .deleteMyCollection(params)
      .then(() => {
        this.getCollectionsByUserId();
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });
      });
  };

  componentWillUnmount() {
    this.unlisten();
  }

  async componentDidMount() {
    this.unlisten = this.props.history.listen(async () => {
      await this.getCollectionsByUserId();
    });

    await this.getCollectionsByUserId();

    this._notificationSystem = this.refs.notificationSystem;
  }

  onSelect = (key) => {
    this.setState({
      keySort: key,
    });
  };

  sortByName = (data) => {
    if (this.state.keySort) {
      const index = data.findIndex((item) => item.name === this.state.keySort);
      const dataNew = update(data, {
        $splice: [[index, 1], [0, 0, data[index]]],
      });
      return dataNew;
    }
    return data;
  };

  render() {
    const { userInfo, collections, intl } = this.props;
    const { selected, fetched, fetching, dataBusiness, loading } = this.state;
    const items = _.chain(collections.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .sort(
        (a, b) =>
          new Date(_.get(b.items[0], 'category.createdAt')).getTime() / 1000 -
          new Date(_.get(a.items[0], 'category.createdAt')).getTime() / 1000
      )
      .value();

    return (
      <>
        <div className="overview-container">
          <div className="product-heading">
            <h3 className="heading-title mt-2">
              <FormattedMessage id="my_collections" defaultMessage="Collections" />
            </h3>
            <div className="scroll-bar collection-scroll">
              <ScrollMenu
                data={Menu(this.sortByName(items), selected)}
                arrowLeft={ArrowLeft}
                arrowRight={ArrowRight}
                selected={selected}
                onSelect={this.onSelect}
                translate={0}
                alignCenter={false}
                wheel={false}
              />
            </div>
            <Link
              to={`/u/${formatRoute(
                userInfo.displayName
              )}/collections/create`}
              className="btn btn-circle btn-circle-primary d-none d-lg-block"
            >
              <FormattedMessage
                id="create_new_collection"
                defaultMessage="Create a new collection"
              />
            </Link>
          </div>
        </div>
        <Link
          to={`/u/${formatRoute(
            userInfo.displayName
          )}/collections/create?group=menu`}
          className="btn btn-circle btn-circle-primary d-lg-none mb-2 w-100"
        >
          <FormattedMessage
            id="create_new_product"
            defaultMessage="Create a new product"
          />
        </Link>
        <Pane className="product-container">
          {!fetching && fetched ? (
            <>
              {!isEmpty(this.sortByName(items)) &&
                this.sortByName(items).map((category) => {
                  if (isEmpty(category.items)) {
                    return <></>;
                  }
                  return (
                    <React.Fragment key={category.name}>
                      <Pane className="products-grids">
                        <List
                          data={category.items}
                          name={category.name}
                          type="collections"
                          control={true}
                          onDelete={this.deleteItem}
                        />
                        <Pane className="clearfix" />
                      </Pane>
                    </React.Fragment>
                  );
                })}
            </>
          ) : (
            <LoadingSearch type="white" />
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  collections: state.myCollections,
});

const bindDispatchToProps = (dispatch) => ({
  getCollectionsByUserId: bindActionCreators(getCollectionsByUserId, dispatch),
  getCategoriesByUserId: bindActionCreators(
    getCategoriesByUserId,
    dispatch
  ),
  deleteMyCollection: bindActionCreators(deleteMyCollection, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  updateMyBusinessInfo: bindActionCreators(updateMyBusinessInfo, dispatch),
  updateUserInfo: bindActionCreators(updateInfo, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(Collections))
);
