import * as types from 'constants/ActionTypes';
import CategoryAdapter from 'services/categories/adapter';

const initialState = {
  results: [],
  error: null,
  fetching: false,
  fetched: false,
};

const productCategories = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_PRODUCT_CATEGORIES:
      return Object.assign({}, state, {
        fetching: true,
      });
    case types.RECEIVE_PRODUCT_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        results: CategoryAdapter.productCategoryBrowse.parseResponse(
          action.payload
        ).data,
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_PRODUCT_CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        results: [],
        fetching: false,
        fetched: true,
      });
    default:
      return state;
  }
};

const serviceCategories = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_SERVICE_CATEGORIES:
      return Object.assign({}, state, {
        fetching: true,
      });
    case types.RECEIVE_SERVICE_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        results: CategoryAdapter.serviceCategoryBrowse.parseResponse(
          action.payload
        ).data,
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_SERVICE_CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        results: [],
        fetching: false,
        fetched: true,
      });
    default:
      return state;
  }
};

const collectionCategories = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_COLLECTION_CATEGORIES:
      return Object.assign({}, state, {
        fetching: true,
      });
    case types.RECEIVE_COLLECTION_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        results: CategoryAdapter.collectionCategoryBrowse.parseResponse(
          action.payload
        ).data,
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_COLLECTION_CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        results: [],
        fetching: false,
        fetched: true,
      });
    default:
      return state;
  }
};

export { productCategories, serviceCategories, collectionCategories };
