import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Pane } from 'shared/components/layouts';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.requestStripeToken = this.requestStripeToken.bind(this);
  }

  componentDidMount() {
    this.props.requestPaymentToken(this.requestStripeToken);
  }

  requestStripeToken = () => {
    return new Promise((resolve, reject) => {
      this.props.stripe.createToken().then((result) => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  };

  render() {
    return (
      <Pane className="card-information">
        <CardElement hidePostalCode={true} />
      </Pane>
    );
  }
}

export default injectStripe(PaymentForm);
