import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 100;
  const sort = params.sort || '';

  return {
    method: 'GET',
    url: `/business?page[size]=${size}&page[number]=${page}&sort=${sort}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const categories = [];

  if (response.data) {
    response.data.forEach((item) => {
      const data = formatJson(response, item.type, item.id);
      categories.push(data);
    });
  }

  const data = categories;

  return data;
};

export { exec, parseResponse };
