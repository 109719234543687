import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Pane } from "shared/components/layouts";
import _, { isEmpty} from "lodash";
import { getUserInfo } from "actions/auth";
import { ShowCollection } from 'components/common/collections/show-collections';

class UserOverview extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { storeInfo } = this.props
    return (
      <Pane>
          <ShowCollection isFront={true} userId={storeInfo.id} />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  userInfo: state.auth && state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(UserOverview);
