import Config from 'constants/Config'
import { formatJson  } from "shared/helpers";

const exec = params => {
  const keyword = params.keyword || ''
  const page = params.page || 1
  const size = params.size || 20
  const userId = params.userId || ''
  const type = params.type || 'Menu'
  const country = params && params.country || 'aus'
  let search_area = params && params.state ? `&state=${params.state}` : ``;
  if (params && params.city) search_area = `${search_area}&city=${params.city}`
  
  return {
    method: 'GET',
    url: `/products?country=${country}${search_area}&name=*${keyword}*&user_id=${userId}&page[size]=${size}&page[number]=${page}&type=${type}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
