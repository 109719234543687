import Config from "constants/Config";
import { formatRoute, formatJson } from "shared/helpers";

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const id = params.id || "";
  const group = params.group || "";
  const category_id = params.category_id || "";
  const images = params.images === null ? null : "";
  const type = params.type ? params.type : "";
  return {
    method: "GET",
    url: `users/${id}/products?user_id=${id}&images.id=${images}&category_id=${category_id}&group=${group}&page[size]=${size}&page[number]=${page}&type=${type}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const products = [];

  if (response.data) {
    response.data.forEach((item) => {
      const product = formatJson(response, item.type, item.id);
      if (!product) {
        return;
      }
      let promotion = {};

      if (product.promotion) {
        promotion = {
          id: product.promotion.id,
          percent: Number(product.promotion.percent),
          startDate: product.promotion.startDate,
          endDate: product.promotion.endDate,
        };
      }

      const options = [];

      (product.options || []).forEach((option) => {
        const group = option.optionGroup || {};
        const index = options.findIndex((option) => option.id === group.id);
        if (index === -1) {
          options.push({
            id: group.id,
            name: group.name,
            type: group.type,
            autoAddOptions: false,
            dpOrdSeq: group.dpOrdSeq,
            delt: "N",
            childrens: [
              {
                id: option.id,
                name: option.name,
                dpOrdSeq: option.dpOrdSeq,
                price: Number(option.price),
                delt: "N",
                autoAddOptions: false,
                isCheck: !!option.isCheck,
              },
            ],
          });
        } else {
          options[index].childrens.push({
            id: option.id,
            name: option.name,
            dpOrdSeq: option.dpOrdSeq,
            price: Number(option.price),
            delt: "N",
            autoAddOptions: false,
            isCheck: !!option.isCheck,
          });
        }

        return options;
      });

      options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
      options.forEach((option) =>
        option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq)
      );

      products.push({
        id: product.id,
        name: product.name,
        slug: `${formatRoute(product.name, "-")}-${product.id}`,
        primaryImage: product.primaryImage && product.primaryImage.url,
        rating: 0,
        userId: product.user.id,
        category: product.category,
        categoryName: product.category.name,
        description: product.description,
        originalPrice: Number(product.price),
        promotionPrice: Number(product.promotionPrice),
        promotion,
        inventoryStatus: product.inventoryStatus,
        type: Config.PRODUCT_TYPE,
        options,
      });
    });
  }

  const data = products;

  return data;
};

export { exec, parseResponse };
