import { List } from './list';
import { Screens } from './screens';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Pane } from 'shared/components/layouts';
import { withRouter, Link } from 'react-router-dom';
import { formatRoute, handleError } from 'shared/helpers';
import { LoadingSearch } from 'components/common/loading';
import NotificationSystem from 'react-notification-system';
import { getDigitalsByUserId, deleteMyProduct } from 'actions/digitals';
import { getScreensByUserId, deleteMyScreen } from 'actions/screens';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

class Digitals extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = this.props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      fetching: false,
      fetched: false,
      keySort: null,
      loadingScreen: false,
    };
    this._notificationSystem = null;
  }

  getDigitalsByUserId = (params) => {
    const { userInfo } = this.props;
    this.props
      .getDigitalsByUserId(
        {
          id: userInfo.id,
          size: 100,
        },
        params.load
      )
      .then(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      })
      .catch(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  };

  getScreensByUserId = () => {
    const { userInfo } = this.props;
    this.setState({loadingScreen: true});
    this.props
      .getScreensByUserId({
          id: userInfo.id,
          size: 100,
        })
      .then(() => {
        this.setState({loadingScreen: false});
      })
      .catch(() => {
        this.setState({loadingScreen: false});
      });
  };

  deleteItem = (params) => {
    const { intl } = this.props;

    if (this.props.digitals.results.length === 1) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }

    this.props
      .deleteMyScreen(params)
      .then(() => {
        this.getScreensByUserId({ page: this.state.page });
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });
      });
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getDigitalsByUserId({ load: true });
      this.getScreensByUserId();
    });

    this.getDigitalsByUserId({ load: true });
    this.getScreensByUserId();

    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    const { userInfo, digitals, screens } = this.props;
    const { fetched, fetching, loadingScreen } = this.state;
    return (
      <Pane className="overview-container" id="anchor">
        <Pane className="product-container">
          <div className="product-heading justify-content-end">
            <Link
              to={`/u/${formatRoute(
                userInfo.displayName
              )}/digital-slideshow/screen/create`}
              className="btn btn-circle btn-circle-primary"
            >
              <FormattedMessage
                id="create_screen_digital"
                defaultMessage="Create a screen digital"
              />
            </Link>
          </div>
          {!loadingScreen ? (
            <>
              {screens.results.length <= 0 && (
                <Pane className="empty-container">
                  <Pane className="empty-content">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/icons/empty.png'
                      }
                      alt="empty"
                      className="img-empty"
                    />
                    <h2 className="empty-title">No items</h2>
                  </Pane>
                </Pane>
              )}
              {screens.results.length ? (
                <Screens
                  data={screens.results}
                  onDelete={this.deleteItem}
                  userInfo={this.props.userInfo}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <LoadingSearch type="white" />
          )}
          {/* {!fetching && fetched ? (
            <>
              {digitals.results.length <= 0 && (
                <Pane className="empty-container">
                  <Pane className="empty-content">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/icons/empty.png'
                      }
                      alt="empty"
                      className="img-empty"
                    />
                    <h2 className="empty-title">No items</h2>
                  </Pane>
                </Pane>
              )}
              {digitals.results.length ? (
                <List
                  data={digitals.results}
                  onDelete={this.deleteItem}
                  getServicesByUserId={this.props.getServicesByUserId}
                  userInfo={this.props.userInfo}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <LoadingSearch type="white" />
          )} */}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  digitals: state.digitals,
  screens: state.screens,
});

const bindDispatchToProps = (dispatch) => ({
  getDigitalsByUserId: bindActionCreators(getDigitalsByUserId, dispatch),
  deleteMyProduct: bindActionCreators(deleteMyProduct, dispatch),
  getScreensByUserId: bindActionCreators(getScreensByUserId, dispatch),
  deleteMyScreen: bindActionCreators(deleteMyScreen, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(Digitals))
);
