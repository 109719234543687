import * as types from "constants/ActionTypes";
import api from "api";

// Products
export const getTablesByUserId = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_MY_TABLES,
      types.REQUEST_MY_TABLES_SUCCESS,
      types.REQUEST_MY_TABLES_FAILURE,
    ],
    payload: {
      request: api.user.tables(params),
    },
  });

export const deleteMyTables = (params) => (dispatch) =>
  dispatch({
    type: types.DELETE_MY_TABLES,
    payload: {
      request: api.user.deleteTables(params),
    },
  });

export const createMyTables = (params) => (dispatch) =>
  dispatch({
    type: types.CREATE_MY_TABLES,
    payload: {
      request: api.user.createTables(params),
    },
  });

export const editMyTables = (params) => (dispatch) =>
  dispatch({
    type: types.EDIT_MY_TABLES,
    payload: {
      request: api.user.editTables(params),
    },
  });

// Get product detail
export const getMyTableDetail = (params) => (dispatch) =>
  dispatch({
    type: types.MY_TABLE_DETAIL,
    payload: {
      request: api.user.readTables(params),
    },
  });
