import * as types from 'constants/ActionTypes';
import DeliveryAdapter from 'services/delivery/adapter';
import normalize from 'json-api-normalizer';

const initialState = {
  results: [],
  schema: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false,
  data: {},
};

const deliveryDetail = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_DELIVERY_DETAIL:
      return Object.assign({}, state, {
        fetching: true,
      });
    case types.RECEIVE_DELIVERY_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        data: DeliveryAdapter.read.parseResponse(action.payload.data),
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_DELIVERY_DETAIL_FAILURE:
      return Object.assign({}, state, {
        data: {},
        error: action.error,
        fetching: false,
        fetched: true,
      });
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        data: {},
        error: null,
        fetching: false,
        fetched: false,
      });
    default:
      return state;
  }
};

const deliveriesSchedule = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_DELIVERY_SCHEDULE:
      return Object.assign({}, state, {
        ...state,
        fetching: true,
      });
    case types.RECEIVE_DELIVERY_SCHEDULE_SUCCESS:
      return Object.assign({}, state, {
        results: DeliveryAdapter.browse.parseResponse(action.payload.data),
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true,
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_DELIVERY_SCHEDULE_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true,
      });
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false,
      });
    default:
      return state;
  }
};

const deliveries = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_DELIVERY:
      return Object.assign({}, state, {
        ...state,
        fetching: true,
      });
    case types.RECEIVE_DELIVERY_SUCCESS:
      return Object.assign({}, state, {
        results: DeliveryAdapter.browse.parseResponse(action.payload.data),
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true,
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_DELIVERY_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true,
      });
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false,
      });
    default:
      return state;
  }
};

export { deliveries, deliveriesSchedule, deliveryDetail };
