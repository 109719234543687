import React, { Component } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import ReactTooltip from "react-tooltip";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import Config from "constants/Config";

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    play: 0,
  };

  changeRoute = () => {
    this.props.history.push(`${this.props.type}/${this.props.id}/edit`);
  };

  showConfirmDelete = () => {
    const { intl } = this.props;

    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: `${intl.formatMessage({
        id: "notification.are_you_sure_you_want_to_delete_this",
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => {
            this.deleteItem();
          },
        },
        {
          label: intl.formatMessage({ id: "no" }),
        },
      ],
    });
  };

  deleteItem = () => {
    this.props.onDelete({ id: this.props.id });
  };

  downloadQr = async (id, name) => {
    const img = document.createElement("img");   // Create in-memory image
    img.addEventListener("load", () => {
        const a = document.createElement("a");   // Create in-memory anchor
        a.href = img.src;                        // href toward your server-image
        a.download = name;               // :)
        a.click();                               // Trigger click (download)
    });
    img.src = `${Config.NEW_API_URL}/down_qrcode_table/${id}`;     
    // await fetch(url, {
    //   method: 'get',
    //   headers: {
    //     'Access-Control-Allow-Origin': '*'
    //   },
    // })
    //   .then(resp => resp.blob())
    //   .then(blob => {
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.setAttribute("download", name);
    //     link.href = url;
    //     link.click();
    //   })
    //   .catch(() => alert('An error sorry'));
  }

  render() {
    const { item } = this.props;
    return (
      <Pane className="col-lg-3 col-md-4 col-sm-6 col-12 item-table">
        <Pane className="adds-item">
          <Pane
            className="adds-images"
            style={{
              backgroundImage: `url(${get(item, "qrcode.url")})`,
            }}
          >
            <i onClick={() => this.downloadQr(item.id, item.name)} class="fa fa-download" aria-hidden="true"></i>
          </Pane>
          <Pane className="adds-content">
            <Link to={`${this.props.type}/${this.props.id}/edit`}>
              <h5 className="adds-norm" title={item.name}>
                {item.name}
              </h5>
            </Link>
            {this.props.control && (
              <Pane className="adds-actions">
                <span onClick={this.changeRoute} data-tip data-for="edit">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/edit.svg`
                    }
                    alt="cart"
                    className="icon-edit"
                  />
                </span>
                <span
                  onClick={this.showConfirmDelete}
                  data-tip
                  data-for="remove"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/delete.svg`
                    }
                    alt="cart"
                    className="icon-delete"
                  />
                </span>
              </Pane>
            )}
          </Pane>
        </Pane>
        <ReactTooltip place="top" type="dark" effect="solid" id="edit">
          <FormattedMessage id="edit" defaultMessage="Edit" />
        </ReactTooltip>
        <ReactTooltip place="top" type="dark" effect="solid" id="remove">
          <FormattedMessage id="delete" defaultMessage="Delete" />
        </ReactTooltip>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
