import * as types from 'constants/ActionTypes'
import api from 'api'

export const getBanners = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BANNERS, types.RECEIVE_BANNERS_SUCCESS, types.RECEIVE_BANNERS_FAILURE],
  payload: {
    request: api.banner.getAll(params)
  }
})

export const getBannersDigital = (data) => (dispatch) => dispatch({
  types: [types.REQUEST_BANNERS_DIGITAL, types.RECEIVE_BANNERS_DIGITAL_SUCCESS, types.RECEIVE_BANNERS_DIGITAL_FAILURE],
  payload: {
    request: api.banner.getDigitalAll(data)
  }
})