import React from "react";
import PropTypes from "prop-types";

const Currency = ({ price, unit }) => {
  return (
    <span className="price-number">
      {`${unit || ``}${Number.parseFloat(price)
        .toFixed(2)
        .toString()
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
    </span>
  );
};

Currency.propTypes = {
  unit: PropTypes.string,
  price: PropTypes.any,
};

Currency.defaultProps = {
  unit: "AUD $",
  price: 0,
};

export default Currency;
