import axios from 'axios';
import { getActionTypes } from 'redux-axios-middleware';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Cookies } from 'react-cookie';
import { camelizeKeys } from 'humps';
import Config from 'constants/Config';
import moment from 'moment';

const cookies = new Cookies();

export const apiClients = {
  default: {
    client: axios.create({
      baseURL: Config.API_URL,
      responseType: 'json',
      transformResponse: [
        function(data) {
          return camelizeKeys(
            typeof data !== 'object' && typeof data !== 'string'
              ? JSON.parse(data)
              : data,
            {
              process(key, convert, options) {
                return moment(key, moment.ISO_8601).isValid() ? key : convert(key, options);
              },
            }
          );
        },
      ],
    }),
  },
};

export const middlewareConfig = {
  interceptors: {
    request: [
      ({ dispatch }, config) => {
        dispatch(showLoading());
        if (cookies.get('accessToken')) {
          if (config.headers.isKcater) {
            config.headers['Authorization'] = `Bearer ${
              config.headers['access-token']
            }`;
            config.headers['Content-Type'] = 'application/json';
            delete config.headers['access-token'];
            delete config.headers['isKcater'];
          } else {
            config.headers['Authorization'] = `Bearer ${cookies.get(
              'accessToken'
            )}`;
          }
        }
        return config;
      },
    ],
  },
  onSuccess: ({ action, next, response, dispatch }, options) => {
    dispatch(hideLoading());

    const nextAction = {
      type: getActionTypes(action, options)[1],
      payload: response,
      meta: {
        previousAction: action,
      },
    };
    next(nextAction);
    return response;
  },
  onError: ({ action, next, error, dispatch }, options) => {
    dispatch(hideLoading());

    // if (error.response && error.response.status === 401) {
    //   dispatch(logout())
    //   return Promise.reject(error)
    // }

    let errorObject;

    if (!error.response) {
      errorObject = {
        data: error.message,
        status: 0,
      };
      if (process.env.NODE_ENV !== 'production') {
        console.log('HTTP Failure in Axios', error);
      }
    } else {
      errorObject = error;
    }
    const nextAction = {
      type: getActionTypes(action, options)[2],
      error: errorObject,
      meta: {
        previousAction: action,
      },
    };

    next(nextAction);

    return Promise.reject(errorObject);
  },
};
