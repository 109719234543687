import _ from "lodash";
import PropTypes from "prop-types";
import { ServiceList } from "./list";
import queryString from "query-string";
import React, { Component } from "react";
import { checkParams } from "shared/helpers";
import { withRouter } from "react-router-dom";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import InfiniteScroll from "react-infinite-scroller";
import { FormattedMessage, injectIntl } from "react-intl";
import { ScrollMenuProduct } from "components/common/scrollMenu";
import { LoadingAdds, LoadingContainer } from "components/common/loading";
import { ServiceOptionModal } from "../../../shared/components";

let isMounted = true;
/**
 * Set isMounted
 * @param {boolean} value
 * @returns {boolean} value of isMounted
 */
const setIsMounted = (value = true) => {
  isMounted = value;
  return isMounted;
};
/**
 * Get isMounted
 * @returns {boolean} value of isMounted
 */
const getIsMounted = () => isMounted;
class Services extends Component {
  static propTypes = {
    auth: PropTypes.object,
    data: PropTypes.object,
    onGetAllServices: PropTypes.func,
    servicesPromotions: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const items = [105, 88, 86, 85, 84, 73, 66];
    const {
      location: { search },
    } = props;
    this.state = {
      category:
        queryString.parse(search).category ||
        items[Math.floor(Math.random() * items.length)],
      size: 20,
      keySort: null,
      fetched: false,
      fetching: false,
      hasMoreItems: true,
      loadingMore: false,
      modeView: "gridView",
      sort: queryString.parse(search).sort,
      store: queryString.parse(search).store,
      discount: queryString.parse(search).discount,
      page: parseInt(queryString.parse(search).page, 10) || 1,
      data: {
        results: [],
      },
      serviceDetail: {},
      openModalServiceDetail: false,
    };
    setIsMounted(true);
  }

  onCloseModalServiceDetail = () => {
    this.setState({ openModalServiceDetail: false, serviceDetail: {} });
  };

  onOpenModalServiceDetail = () => {
    this.setState({ openModalServiceDetail: true });
  };

  setServiceDetail = (data) => {
    this.setState((prev) => ({
      serviceDetail: { ...prev.serviceDetail, ...data },
    }));
  };

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
      const qp = queryString.parse(location.search);
      const params = {};
      Object.keys(qp) &&
        Object.keys(qp).forEach((key) => {
          params[key] = qp[key];
        });
      this.getServices(params);
    });
  }

  componentWillUnmount() {
    setIsMounted(false);
    this.unlisten();
  }

  componentDidMount() {
    const { data } = this.props;
    const params = {
      page: this.state.page,
      store: this.state.store,
      discount: this.state.discount,
      sort: this.state.sort,
      category: this.state.category,
    };

    if (this.props.match && this.props.match.params.id) {
      params.id = this.props.match.params.id;

      this.getServices(params);
    } else if (data && data.fetched) {
      this.getServices(params);
    } else {
      this.getServices(params);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      this.props &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      const params = {
        page: 1,
        storeId: queryString.parse(this.props.location.search).store,
      };

      if (this.props.match && this.props.match.params.id) {
        params.id = this.props.match.params.id;
      }
      this.getServices(params);
    }
  }

  options = [
    {
      value: "+price",
      label: this.props.intl.formatMessage({
        id: "price.low_to_high",
        defaultMessage: "Price: Low to High",
      }),
    },
    {
      value: "-price",
      label: this.props.intl.formatMessage({
        id: "price.high_to_low",
        defaultMessage: "Price: High to Low",
      }),
    },
    {
      value: "statistic.rating",
      label: this.props.intl.formatMessage({
        id: "rating.high_to_low",
        defaultMessage: "Rating: Low to High",
      }),
    },
    {
      value: "-statistic.rating",
      label: this.props.intl.formatMessage({
        id: "rating.low_to_high",
        defaultMessage: "Rating: High to Low",
      }),
    },
  ];

  setStateData = (state, callback) => {
    if (!getIsMounted()) {
      return;
    }
    this.setState(state, callback);
  };

  handleChangeSelect = (selectedOption) => {
    const { history, location } = this.props;
    const { value } = selectedOption.target;
    if (!value) {
      this.setStateData(
        {
          sort: "",
        },
        () => {
          history.push({
            search: ``,
          });
        }
      );
    } else {
      this.setStateData(
        {
          sort: value,
        },
        () => {
          history.push({
            search: `?sort=${this.state.sort}${checkParams(
              "sort",
              location.search
            )}`,
          });
        }
      );
    }
  };

  getServices = (params) => {
    if (params.id) {
      this.setStateData({ categoryId: params.id });
    }
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props
      .onGetAllServices({
        ...params,
        images: this.state.modeView === "gridView" ? "" : null,
      })
      .then(() => {
        this.setStateData({
          fetched: true,
          fetching: false,
          data: this.props.data,
        });
      });
  };

  getStoreByCategory = (id) => {
    this.setStateData({
      fetched: false,
      fetching: true,
    });
    this.props
      .getBusinessByCategory({
        category: id,
        type: "services",
        images: this.state.modeView === "gridView" ? "" : null,
      })
      .then(() => {
        this.setStateData({
          fetched: true,
          fetching: false,
        });
      });
  };

  handlePageClick = (data) => {
    this.setState(
      {
        page: data.selected + 1,
      },
      () => {
        this.props.history.push({
          search: `?page=${this.state.page}${checkParams(
            "page",
            this.props.location.search
          )}`,
        });
        window.scrollTo(0, 0);
      }
    );
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === "gridView" ? "listView" : "gridView",
    }));
  };

  onSelect = (key) => {
    this.setStateData(
      {
        keySort: key,
        category: key,
        page: 1,
        size: 20,
      },
      () => {
        if (this.state.keySort) {
          this.props.history.push(`/services?category=${this.state.keySort}`);
        } else {
          this.props.history.push(`/services`);
        }
      }
    );
  };

  getByCategory = (data) => {
    if (this.state.keySort) {
      return data.filter(
        (item) => _.get(item, "category.id") === this.state.keySort
      );
    }
    return data;
  };

  loadItems = () => {
    const params = {
      page: this.state.page + 1,
      store: this.state.store,
      discount: this.state.discount,
      sort: this.state.sort,
      category: this.state.category,
    };
    if (
      !this.state.loadingMore &&
      this.props.data.totalItems > 20 &&
      this.props.data.totalItems > this.state.data.results.length
    ) {
      this.setState({
        hasMoreItems: false,
        loadingMore: true,
      });
      this.props.onGetAllServices(params).then(() => {
        this.setState((prevState) => ({
          hasMoreItems: true,
          page: prevState.page + 1,
          data: {
            results: prevState.data.results.concat(this.props.data.results),
          },
          loadingMore: false,
        }));
      });
    }
  };

  render() {
    const { fetched, fetching, selected, data } = this.state;
    const { categories } = this.props;
    return (
      <Pane className="col-lg-9 col-md-12">
        <ServiceOptionModal
          openModal={this.state.openModalServiceDetail}
          onClose={this.onCloseModalServiceDetail}
          detail={this.state.serviceDetail}
        />
        <Pane className="tab-heading">
          <Pane className="category-list">
            <ScrollMenuProduct
              items={categories}
              onSelect={this.onSelect}
              selected={selected}
            />
            <span onClick={() => this.onSelect(null)} className="category-link">
              <FormattedMessage id="view_all" defaultMessage="View All" />
            </span>
          </Pane>
          <Pane className="category-sort">
            <span className="sort-title">SORT BY</span>
            <select
              className="category-select"
              name="format"
              value={this.state.sort}
              onChange={this.handleChangeSelect}
              id="format"
            >
              {this.options.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </Pane>
          <span className="list-view" onClick={this.onChangeModeView}>
            <img
              src={
                process.env.PUBLIC_URL + this.state.modeView === "gridView"
                  ? "/assets/images/icons/list-view.svg"
                  : "/assets/images/icons/grid-view.svg"
              }
              className="image-logo"
              alt="list-view"
            />
          </span>
        </Pane>
        <Pane className="clearfix" />
        <Pane className="product-list">
          {!fetching && fetched ? (
            <>
              {!_.isEmpty(data.results) ? (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadItems}
                  hasMore={this.state.hasMoreItems}
                  isReverse={false}
                >
                  <ServiceList
                    auth={this.props.auth}
                    services={data.results}
                    modeView={this.state.modeView}
                    serviceModal={{
                      serviceDetail: this.state.serviceDetail,
                      openModal: this.onOpenModalServiceDetail,
                      setServiceDetail: this.setServiceDetail,
                    }}
                  />
                  {this.state.loadingMore && <LoadingContainer />}
                </InfiniteScroll>
              ) : (
                <Empty />
              )}
            </>
          ) : (
            <LoadingAdds />
          )}
        </Pane>
      </Pane>
    );
  }
}

export default injectIntl(withRouter(Services));
