import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OrderedCartItem from './OrderedCartItem';
import { formatDecimal } from 'shared/helpers';
import { Pane } from "shared/components/layouts";

class OrderedCartList extends Component {
  static propTypes = {
    detail: PropTypes.object,
    data: PropTypes.object,
  };
  render() {
    const { detail } = this.props;
    return (
      <Pane className="order-list">
        {detail.cartItems &&
          detail.cartItems.map((item) => (
            <OrderedCartItem
              key={item.id}
              detail={this.props.detail}
              data={item}
              orderUserCart={this.props.orderUserCart}
              userInfo={this.props.userInfo}
            />
          ))}
        <Pane className="order-total">
          <FormattedMessage id="total" defaultMessage="TOTAL" />: AUD ${formatDecimal(detail.total)}
        </Pane>
        <Pane className="order-note">
          <FormattedMessage id="note" defaultMessage="Note" />{detail.mode ? (detail.guestNote || 'None') : (detail.note || 'None')}
        </Pane>
      </Pane>
    );
  }
}

export default OrderedCartList;
