import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Dimmer, Select } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import ReactPaginate from 'react-paginate';
import { isEmpty } from 'lodash';
import { Pane, Loading } from 'shared/components/layouts';
import { getBuyingOrders, getSellingOrders } from 'actions/orders';
import BuyingOrderList from './buyer/BuyingOrderList';
import SellingOrderList from './seller/SellingOrderList';
import queryString from 'query-string';

class Orders extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    getBuyingOrders: PropTypes.func,
    getSellingOrders: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loadingSelling: false,
      loadingBuying: false,
      method: queryString.parse(this.props.location.search).delivery_type || 'DELIVERY',
      methods: [
        {
          text: "DELIVERY",
          value: "DELIVERY",
        },
        {
          text: "PICKUP",
          value: "PICKUP",
        },
        {
          text: "DINE_IN",
          value: "DINE_IN",
        },
        {
          text: "TAKE_AWAY",
          value: "TAKE_AWAY",
        }
      ]
    };
  }

  getBuyingOrders = (params, load) => {
    this.setState({
      loadingBuying: true,
    });
    this.props
      .getBuyingOrders(params, load)
      .then(() => {
        this.setState({
          loadingBuying: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingBuying: false,
        });
      });
  };

  getSellingOrders = (params, load) => {
    this.setState({
      loadingSelling: true,
    });
    this.props
      .getSellingOrders(params, load)
      .then(() => {
        this.setState({
          loadingSelling: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingSelling: false,
        });
      });
  };

  handleBuyingPageClick = (data) => {
    const params = {
      page: data.selected + 1,
      id: this.props.userInfo.id,
    };

    this.getBuyingOrders(params, true);
  };

  handleSellingPageClick = (data) => {
    const params = {
      page: data.selected + 1,
      id: this.props.userInfo.id,
      method: this.state.method
    };

    this.getSellingOrders(params, true);
  };

  componentDidMount() {
    const params = {
      page: 1,
      id: this.props.userInfo.id,
      method: this.state.method
    };

    this.getBuyingOrders(params);
    this.getSellingOrders(params);
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  filterDeliveryMethod = (e, value) => {
    this.setState({
      method: value,
    }, () => {
      const params = {
        page: 1,
        id: this.props.userInfo.id,
        method: this.state.method
      };

      this.getSellingOrders(params);
    });
  }

  render() {
    const { buyingOrders, sellingOrders, userInfo } = this.props;
    const { loadingSelling, loadingBuying, method, methods } = this.state;
    return (
      <Pane className="overview-container" id="anchor">
        <Pane className="product-container order-container">
          {userInfo.business.is_active === 1 && (
            <Pane className="product-container order-container">
              <h3 className="block-title pt10 text-uppercase d-flex justify-content-between">
                <span>{`${method} ORDERS`}</span>
                <Pane>
                  <Select
                    className="text-xs"
                    placeholder='Select delivery method'
                    value={method}
                    options={methods}
                    onChange={(e, { value }) => this.filterDeliveryMethod(e, value)}
                  />
                </Pane>
              </h3>
              <Pane className="products-grids table-orders">
                {loadingSelling && (
                  <Dimmer active inverted>
                    <Loading />
                  </Dimmer>
                )}
                {!loadingSelling && (
                  <SellingOrderList method={method} userInfo={userInfo} orders={sellingOrders} />
                )}
                {!loadingSelling &&
                  isEmpty(sellingOrders.results) && (
                    <Pane className="block-no-content">
                      <p>
                        <FormattedMessage
                          id="notification.you_have_not_had_any_orders_yet"
                          defaultMessage={`You haven't had any orders yet.`}
                        />
                      </p>
                    </Pane>
                  )}
                <Pane className="clearfix" />
              </Pane>
              <Pane className="pagination-bar text-center">
                <nav className="d-inline-b">
                  {sellingOrders.totalPages > 1 ? (
                    <ReactPaginate
                      previousLabel="❮"
                      nextLabel="❯"
                      breakLabel={<button className="btn-break-paging">...</button>}
                      breakClassName={'break-me'}
                      previousClassName={'page-item'}
                      nextClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextLinkClassName={'page-link'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      pageCount={sellingOrders.totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handleSellingPageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  ) : null}
                </nav>
              </Pane>
            </Pane>
          )}
          <h3 className="block-title pt10 text-uppercase">
            <FormattedMessage
              id="your_orders"
              defaultMessage="Your orders"
            />
          </h3>
          <Pane className="products-grids table-orders">
            {loadingBuying && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {!loadingBuying && (
              <BuyingOrderList userInfo={userInfo} orders={buyingOrders} />
            )}
            {!loadingBuying &&
              isEmpty(buyingOrders.results) && (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.you_have_not_placed_any_orders_yet"
                      defaultMessage={`You haven't placed any orders yet.`}
                    />
                  </p>
                </Pane>
              )}
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {buyingOrders.totalPages > 1 ? (
                <ReactPaginate
                  previousLabel={
                    <FormattedMessage
                      id="pagination.previous"
                      defaultMessage="previous"
                    />
                  }
                  nextLabel={
                    <FormattedMessage
                      id="pagination.next"
                      defaultMessage="next"
                    />
                  }
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={'break-me'}
                  previousClassName={'page-item'}
                  nextClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  pageCount={buyingOrders.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleBuyingPageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              ) : null}
            </nav>
          </Pane>
        </Pane>

      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  buyingOrders: state.buyingOrders,
  sellingOrders: state.sellingOrders,
});

const bindDispatchToProps = (dispatch) => ({
  getBuyingOrders: bindActionCreators(getBuyingOrders, dispatch),
  getSellingOrders: bindActionCreators(getSellingOrders, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(Orders);
