import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const type = params.type || '';
  const userId = params.id || '';
  return {
    method: 'GET',
    url: `/users/${userId}/deliveries?type=${type}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const data = [];
  if (response.data) {
    response.data.forEach((item) => {
      const items = formatJson(response, item.type, item.id);
      data.push(items);
    });
  }
  return data;
};

export { exec, parseResponse };
