import PropTypes from "prop-types";
import _, { isEmpty, get } from "lodash";
import { connect } from "react-redux";
import { ProductList } from "../list";
import queryString from "query-string";
import React, { Component } from "react";
import update from "immutability-helper";
import { bindActionCreators } from "redux";
import { addItemToCart } from "actions/cart";
import { FormattedMessage } from "react-intl";
import { searchStores } from "actions/search";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import { getProductsByStoreId } from "actions/stores";
import { isNumberic } from "shared/helpers";
import { getProductsByUserId } from "actions/myProducts";
import { LoadingSearch } from "components/common/loading";
import { ScrollMenu } from "components/common/scrollMenu";
import { getUserInfo } from "actions/auth";

class StoreProducts extends Component {
  static propTypes = {
    auth: PropTypes.object,
    match: PropTypes.object,
    searchStores: PropTypes.func,
    getProductsByStoreId: PropTypes.func,
    getProductsByUserId: PropTypes.func,
    getUserInfo: PropTypes.func.isRequired,
  };

  state = {
    storeId: "",
    products: {},
    totalPages: 0,
    fetched: false,
    modeView: "gridView",
    fetchingProducts: false,
    page: queryString.parse(this.props.location.search).page || 1,
    height: 0,
  };

  getStoreProducts = () => {
    const {
      params: { storeId },
    } = this.props.match;

    this.setState({
      fetchingProducts: true,
      products: this.props.stores.products[storeId] || {},
      fetched: false,
    });

    this.props
      .getProductsByUserId({
        id: storeId,
        group: "Product",
        size: 1000,
        images: "",
        type: "Product",
      })
      .then((response) => {
        if (this.props.storeInfo.business.type !== "Product") {
          this.props.history.replace(`/page-not-found`);
        } else {
          this.setState({
            products: this.props.stores.products[storeId],
            totalPages: 0,
            fetchingProducts: false,
            fetched: true,
          }, () => {
            this.lazy();
          });
          this.onSetDefaultView(this.props.storeInfo);
        }
      })
      .catch((errors) => {
        this.setState({ fetchingProducts: false });
      });
  };

  componentWillMount() {
    this.setState({
      height: window.innerHeight,
    });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getStoreProducts();
    });

    this.getStoreProducts();

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }

  lazy = () => {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    if ("IntersectionObserver" in window) {
      let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;
            if (lazyImage.dataset.src) {
              lazyImage.src = lazyImage.dataset.src;
            } else {
              lazyImage.src = `${process.env.PUBLIC_URL}/assets/images/default-image.png`;
            }
            lazyImage.classList.remove("lazy");
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });
      lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.location.search && prevState.page !== 1) {
      this.setState({ page: 1 });
    }
    if (
      prevProps.location.search &&
      isNumberic(queryString.parse(prevProps.location.search).page) &&
      parseInt(queryString.parse(prevProps.location.search).page, 10) !==
        parseInt(prevState.page, 10)
    ) {
      this.setState({
        page: parseInt(queryString.parse(prevProps.location.search).page, 10),
      });
    }
  }

  groupByCategories = (data) => {
    return _.chain(data)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .value();
  };

  handleScroll = () => {
    const { storeProducts } = this.props;
    const { isAction } = this.state;
    this.setState(
      {
        height: window.innerHeight,
      },
      () => {
        const banner = document.getElementById("banner-container");
        const info = document.getElementById("info-container");
        const menu = document.getElementById("anchor");
        const stickyBanner = banner && banner.offsetHeight;
        const stickyInfo = info && info.offsetHeight;
        const items = this.groupByCategories(storeProducts.results);
        const itemElements = items.map((item) => {
          const itemElement = document.getElementById(item.name);
          return {
            ...item,
            scroll:
              itemElement &&
              itemElement.offsetTop +
                itemElement.offsetHeight +
                stickyBanner +
                stickyInfo -
                250,
          };
        });
        let sum = stickyBanner + stickyInfo;
        if (window.pageYOffset > sum) {
          const scrollElement = itemElements.filter(
            (item) => item.scroll >= window.scrollY
          );
          if (!isEmpty(scrollElement) && !isAction) {
            this.setState({
              selected: get(scrollElement[0], "name"),
            });
          }
          menu && menu.classList.add("menu-breadcrum-sticky");
        } else {
          const scrollElement = itemElements.filter(
            (item) => item.scroll >= window.scrollY
          );
          if (!isEmpty(scrollElement) && !isAction) {
            this.setState({
              selected: get(scrollElement[0], "name"),
            });
          }
          menu && menu.classList.remove("menu-breadcrum-sticky");
        }
      }
    );
  };

  onSetDefaultView = (storeInfo) => {
    if (!isEmpty(storeInfo)) {
      this.setState({
        modeView: storeInfo.business.isGridView === 1 ? "gridView" : "listView",
      });
    }
  };

  onSelect = (key) => {
    const self = this;
    this.setState(
      {
        keySort: key,
        selected: key,
        isAction: true,
      },
      () => {
        const elementScroll = document.getElementById(key);
        if (elementScroll) {
          window.scroll({
            top: elementScroll.offsetTop + 330,
            behavior: "smooth",
          });
          setTimeout(
            () =>
              self.setState({
                isAction: false,
              }),
            1000
          );
        }
      }
    );
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === "gridView" ? "listView" : "gridView",
      keySort: null,
    }));
  };

  sortByName = (data) => {
    if (this.state.keySort) {
      const index = data.findIndex((item) => item.name === this.state.keySort);
      const dataNew = update(data, {
        $splice: [
          [index, 1],
          [0, 0, data[index]],
        ],
      });
      return dataNew;
    }
    return data;
  };

  render() {
    const { fetchingProducts, auth, fetched, selected } = this.state;
    const { storeProducts, cart } = this.props;
    const items = _.chain(storeProducts.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .value();
    return (
      <>
        <Pane className="tab-heading product-before" id="anchor">
          <h3 className="heading-title mt-3">
            <FormattedMessage id="our_products" defaultMessage="Our Products" />
          </h3>
          {!isEmpty(storeProducts.results) && (
            <Pane
              className="category-list menu-breadcrum mt-3"
              id="menu-breadcrum"
            >
              <ScrollMenu
                items={items}
                onSelect={this.onSelect}
                selected={selected}
              />
            </Pane>
          )}
          <Pane className="view d-flex justify-content-end mt-3">
            <span className="list-view" onClick={this.onChangeModeView}>
              <img
                src={
                  process.env.PUBLIC_URL + this.state.modeView === "gridView"
                    ? "/assets/images/icons/list-view.svg"
                    : "/assets/images/icons/grid-view.svg"
                }
                className="image-logo anchor-image-logo"
                alt="list-view"
              />
            </span>
          </Pane>
        </Pane>
        {!fetchingProducts && fetched ? (
          <>
            {isEmpty(items) && <Empty type="white" />}
            {!isEmpty(items) &&
              items.map((category) => (
                <Pane key={category.name} id={category.name}>
                  <Pane className="category-name">
                    <strong>{category.name}</strong>
                  </Pane>
                  <ProductList
                    cart={cart}
                    auth={auth}
                    products={category.items}
                    onAddItemToCart={this.props.addItemToCart}
                    modeView={this.state.modeView}
                    cartModal={this.props.cartModal}
                  />
                  <Pane className="clearfix" />
                </Pane>
              ))}
          </>
        ) : (
          <LoadingSearch type="white" />
        )}
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  stores: state.stores,
  storeProducts: state.myProducts,
  auth: state.auth,
  cart: state.cart,
});

const bindDispatchToProps = (dispatch) => ({
  searchStores: bindActionCreators(searchStores, dispatch),
  addItemToCart: bindActionCreators(addItemToCart, dispatch),
  getProductsByStoreId: bindActionCreators(getProductsByStoreId, dispatch),
  getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
});

export default connect(bindStateToProps, bindDispatchToProps)(StoreProducts);
