import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MasterLayout } from 'components/layout';
import { ForgotPasswordForm } from 'components/scenes/forgot-password';
import { forgotPassword } from 'actions/user';
import { Pane } from 'shared/components/layouts';

class ForgotPasswordPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    forgotPassword: PropTypes.func.isRequired,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="login-container">
          <Pane className="login-more" />
          <Pane className="wrap-login d-flex align-items-center justify-content-center flex-column">
            <h3 className="login-title">
              <FormattedMessage
                id="forgot_password"
                defaultMessage="FORGOT PASSWORD"
              />
            </h3>
            <p className="login-norm">
              We will send passwor recovery instructions to your registered
              email.
              <br />
              Please check in main mailbox and spam box.
            </p>
            <ForgotPasswordForm forgotPassword={this.props.forgotPassword} />
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  forgotPassword: bindActionCreators(forgotPassword, dispatch),
});

export default connect(
  null,
  bindDispatchToProps
)(ForgotPasswordPage);
