import * as ProductCategoryBrowseV1 from './product-category.browse.v1.action'
import * as ServiceCategoryBrowseV1 from './service-category.browse.v1.action'
import * as CollectionCategoryBrowseV1 from './collection-category.browse.v1.action'
import * as FoodOrderCategoryV1 from './food-order-category.browser.v1.action';
import * as DetailCategoryV1 from './detail-category.browser.v1.action';

export default {
  // Get product categories
  productCategoryBrowse: {
    callAPI: params => ProductCategoryBrowseV1.exec(params),
    parseResponse: response => ProductCategoryBrowseV1.parseResponse(response)
  },
  // Get service categories
  serviceCategoryBrowse: {
    callAPI: params => ServiceCategoryBrowseV1.exec(params),
    parseResponse: response => ServiceCategoryBrowseV1.parseResponse(response)
  },
  // Get collection categories
  collectionCategoryBrowse: {
    callAPI: params => CollectionCategoryBrowseV1.exec(params),
    parseResponse: response => CollectionCategoryBrowseV1.parseResponse(response)
  },
  foodOrderCategoryBrowse: {
    callAPI: params => FoodOrderCategoryV1.exec(params),
    parseResponse: response => FoodOrderCategoryV1.parseResponse(response)
  },
  detailCategory: {
    callAPI: params => DetailCategoryV1.exec(params),
    parseResponse: response => DetailCategoryV1.parseResponse(response)
  }
}
