import * as types from 'constants/ActionTypes'
import UserAdapter from 'services/users/adapter'
import normalize from 'json-api-normalizer'

const initialState = {
  foodCategories: [],
  otherCategories: [],
  results: [],
  schema: [],
  promotions:[],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const myProductPicks = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_PRODUCTS:
      return Object.assign({}, state, {
        ...state,
        fetching: true
      })
    case types.RECEIVE_MY_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.productPicksBrowse.parseResponse(action.payload.data),
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    
    default:
      return state
  }
}

export { myProductPicks }
