import React from 'react';
import SocialLogin from 'react-social-login';

class SocialButton extends React.Component {
  render() {
    const Element = this.props.element;
    return (
      <Element
        className="button-social"
        onClick={this.props.triggerLogin}
        {...this.props}
      >
        {this.props.children}
      </Element>
    );
  }
}

export default SocialLogin(SocialButton);
