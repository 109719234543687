import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import BestPickItem from "./BestPickItem";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";

export default class BestPicks extends Component {
  renderList(videos) {
    const arrays = [
      {
        src: process.env.PUBLIC_URL + "/assets/images/home/business/nuskin.png",
        name: "Nuskin",
        slug: "nuskin-halee",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/tigerlili.png",
        name: "Tiger Lilli",
        slug: "tigerlilli",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/galaxyign.png",
        name: "GalaxSigns",
        slug: "galaxsigns",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/bundoora.png",
        name: "Bundoora Fruit Market",
        slug: "bundoora-fruit-market",
      },
      {
        src:
          process.env.PUBLIC_URL +
          "/assets/images/home/business/specialnoodle.png",
        name: "Pho Ngon St Albans",
        slug: "pho-ngon-st-albans",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/bestnails.png",
        name: "Best Nails Ever",
        slug: "bestnailsever",
      },
      {
        src:
          process.env.PUBLIC_URL +
          "/assets/images/home/business/constructiongroup.png",
        name: "Lee Construction Group Pty Ltd",
        slug: "lee-construction-group-pty-ltd",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/rainbow.png",
        name: "Rainbow Nail & Beauty Salon",
        slug: "rainbow",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/images/home/business/trendy.png",
        name: "Trendy Studio",
        slug: "trendy-studio",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/barbers.png",
        name: "ATS BARBERS",
        slug: "ats-barbers",
      },
    ];
    const arrayDisplay = [];
    arrays.slice(0, 5).map((item, index) => {
      let itemDisplay = item;
      itemDisplay.type = "STORE";

      arrayDisplay.push(itemDisplay);
      if (videos && index < videos.results.length) {
        itemDisplay = videos.results[index];
        itemDisplay.type = "VIDEO";
        arrayDisplay.push(itemDisplay);
      }
      return null;
    });
    return arrayDisplay.map((item, index) => {
      return <BestPickItem key={index} item={item} type={item.type} />;
    });
  }

  onGetArray = (videos) => {
    const arrays = [
      {
        src: process.env.PUBLIC_URL + "/assets/images/home/business/nuskin.png",
        name: "Nuskin",
        slug: "nuskin-halee",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/tigerlili.png",
        name: "Tiger Lilli",
        slug: "tigerlilli",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/galaxyign.png",
        name: "GalaxSigns",
        slug: "galaxsigns",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/bundoora.png",
        name: "Bundoora Fruit Market",
        slug: "bundoora-fruit-market",
      },
      {
        src:
          process.env.PUBLIC_URL +
          "/assets/images/home/business/specialnoodle.png",
        name: "Pho Ngon St Albans",
        slug: "pho-ngon-st-albans",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/bestnails.png",
        name: "Best Nails Ever",
        slug: "bestnailsever",
      },
      {
        src:
          process.env.PUBLIC_URL +
          "/assets/images/home/business/constructiongroup.png",
        name: "Lee Construction Group Pty Ltd",
        slug: "lee-construction-group-pty-ltd",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/rainbow.png",
        name: "Rainbow Nail & Beauty Salon",
        slug: "rainbow",
      },
      {
        src: process.env.PUBLIC_URL + "/assets/images/home/business/trendy.png",
        name: "Trendy Studio",
        slug: "trendy-studio",
      },
      {
        src:
          process.env.PUBLIC_URL + "/assets/images/home/business/barbers.png",
        name: "ATS BARBERS",
        slug: "ats-barbers",
      },
    ];
    const arrayDisplay = [];
    arrays.slice(0, 5).map((item, index) => {
      let itemDisplay = item;
      itemDisplay.type = "STORE";

      arrayDisplay.push(itemDisplay);
      if (videos && index < videos.results.length) {
        itemDisplay = videos.results[index];
        itemDisplay.type = "VIDEO";
        arrayDisplay.push(itemDisplay);
      }
      return null;
    });
    return arrayDisplay;
  };

  render() {
    const { videos } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 3000,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 678,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    };

    return (
      <Pane className="bestpicks-container">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-6 offset-lg-3">
              <Pane className="heading-container text-center">
                <h2 className="heading-title">
                  <FormattedMessage id="best_pick" />
                </h2>
                <p className="heading-norm">
                  <FormattedMessage id="begin_the_bests_in_the_lits" />
                </p>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="bestpicks-list">
            <Slider {...settings}>
              {this.onGetArray(videos).map((item, index) => (
                <div key={index}>
                  <BestPickItem item={item} type={item.type} />
                </div>
              ))}
            </Slider>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
