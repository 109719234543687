import * as OptionsAddV1 from "./options.add.v1.action";
import * as OptionsEditV1 from "./options.edit.v1.action";
import * as OptionsDeleteV1 from "./options.delete.v1.action";

export default {
  add: {
    callAPI: (params) => OptionsAddV1.exec(params),
    parseResponse: (response) => OptionsAddV1.parseResponse(response),
  },
  edit: {
    callAPI: (params) => OptionsEditV1.exec(params),
    parseResponse: (response) => OptionsEditV1.parseResponse(response),
  },
  delete: {
    callAPI: (params) => OptionsDeleteV1.exec(params),
    parseResponse: (response) => OptionsDeleteV1.parseResponse(response),
  },
};
