import Config from 'constants/Config'
import { formatRoute, formatJson } from 'shared/helpers'

const exec = params => {
  return {
    method: 'GET',
    url: `/users/${params.store}/slides`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const slides = []
  if (response.data) {
    response.data.forEach(item => {
      
      const slide = formatJson(response, item.type, item.id)
      let type = ''

      if (slide.product && slide.product.id) {
        type = 'product'
      }

      if (slide.service && slide.service.id) {
        type = 'service'
      }
      if (slide.video && slide.video.id) {
        type = 'video'
      }
      slides.push({
        id: slide[type].id,
        name: type === 'video' ? slide[type].title : slide[type].name,
        slug: type === 'video'? `${formatRoute(slide[type].user.business.name, '-')}` : `${formatRoute(slide[type].name, '-')}-${slide[type].id}`,
        primaryImage: type === 'video' ? slide[type].video.url : slide[type].primaryImage && slide[type].primaryImage.url,
        rating: 0,
        userId: type === 'video' ? slide[type].user.id: slide[type].userId,
        originalPrice: Number(slide[type].price),
        promotionPrice: Number(slide[type].promotionPrice),
        type: type === 'video' ? Config.VIDEO_TYPE: type === 'product' ? Config.PRODUCT_TYPE : Config.SERVICE_TYPE,
        viewVideo: type === 'video' ? slide[type].viewVideo : null,
        likeVideo: type === 'video' ? slide[type].likeVideo : null,
      })
    })
  }

  return slides
}

export { exec, parseResponse }
