import React from 'react';
import { get } from 'lodash';
import Validator from 'validator';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import queryString from 'query-string';
import { handleError } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';
import { InlineMessage } from 'shared/components';
import NotificationSystem from 'react-notification-system';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

class QuickSignupForm extends React.Component {

  static propTypes = {
    quickSignup: PropTypes.func.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      data: {
        lat: '',
        lng: '',
        address: '',
        fullName: '',
        password: '',
        country: 'aus',
        confirmPassword: '',
        email: get(query, 'email'),
        businessName:''
      },
      accept: false,
      loading: false,
      errors: {},
    };
  }

  _notificationSystem = null;

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }



  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };



  onChangeAddress = (address) => {
    this.setState(
      {
        data: { ...this.state.data, address },
      },
      () => {
        geocodeByAddress(address)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            this.setState((prevState) => ({
              data: {
                ...prevState.data,
                lat: latLng.lat,
                lng: latLng.lng,
              },
            }));
          })
          .catch(() => {});
      }
    );
  };

  onChangeAccept = () => {
    this.setState({ accept: !this.state.accept });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.fullName) {
      errors.fullName = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (!data.email) {
      errors.email = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
    }
    if (!data.address) {
      errors.address = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (!data.password) {
      errors.password = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (
      data.password &&
      (data.password.length < 6 ||
        data.password.search(/[0-9]/) < 0 ||
        data.password.search(/[A-Z]/) < 0 ||
        data.password.search(/[a-z]/) < 0)
    ) {
      errors.password = intl.formatMessage({ id: 'validate.invalid_password' });
    }


    return errors;
  };

  onSubmit = () => {
    let errors = {};
    errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new URLSearchParams();

      data.append('fullname', `${this.state.data.fullName.trim()}`);
      data.append('email', this.state.data.email.trim());
      data.append('password', this.state.data.password.trim());
      data.append('address', this.state.data.address.trim());
      data.append('lat', this.state.data.lat);
      data.append('lng', this.state.data.lng);
      data.append('businessName', this.state.data.businessName);
      data.append('country', this.state.data.country);
      data.append("code", queryString.parse(this.props.history.location.search).code || '');
      this.props
        .quickSignup(data)
        .then((response) => {
          this.setState({ loading: false });

          this._notificationSystem.addNotification({
            message: this.props.intl.formatMessage({
              id: 'notification.your_account_has_been_created_successfully',
            }),
            level: 'success',
          });
          this.props.history.push('/send-verify-email?email='+encodeURIComponent(response.data.data.attributes.email));

        })
        .catch((errors) => {
          errors.message = handleError(
            errors,
            this.props.intl.formatMessage({
              id: 'notification.please_try_again',
            })
          );
          this.setState({ errors, loading: false });
        });
    }
  };

  render() {
    const { data, errors, accept, loading } = this.state;
    const {
      intl,
      history: { location },
    } = this.props;
    const renderFunc = ({
      getInputProps,
      getSuggestionItemProps,
      suggestions,
    }) => (
      <Pane>
        <input
          {...getInputProps()}
          className="form-input"
          placeholder={intl.formatMessage({ id: 'address' })}
        />
        <Pane className="autocomplete-dropdown-container">
          {suggestions.map((suggestion, index) => (
            <Pane {...getSuggestionItemProps(suggestion)} key={index}>
              <span>{suggestion.description}</span>
            </Pane>
          ))}
        </Pane>
      </Pane>
    );

    return (
      <>
        <Form onSubmit={this.onSubmit} className="login-form" noValidate>
          <Form.Field error={!!errors.fullName} className="form-item">
            <label
              htmlFor="fullName"
              className="form-label form-label-required"
            >
              <FormattedMessage id="full_name" defaultMessage="Full Name" />
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder={intl.formatMessage({ id: 'full_name' })}
              value={data.fullName}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.fullName && <InlineMessage text={errors.fullName} />}
          </Form.Field>
          <Form.Field error={!!errors.email} className="form-item">
            <label htmlFor="email" className="form-label form-label-required">
              <FormattedMessage id="email" defaultMessage="Email" />
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={intl.formatMessage({ id: 'email' })}
              value={data.email}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.email && <InlineMessage text={errors.email} />}
          </Form.Field>
          <Form.Field error={!!errors.password} className="form-item">
            <label
              htmlFor="password"
              className="form-label form-label-required"
            >
              <FormattedMessage id="password" defaultMessage="Password" />
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder={intl.formatMessage({ id: 'password' })}
              value={data.password}
              className="form-input"
              onChange={this.onChange}
              autoComplete="new-password"
            />
            <span className="help-password">
              <FormattedMessage id="help_password" />
            </span>
            {errors.password && <InlineMessage text={errors.password} />}
          </Form.Field>

          <Form.Field error={!!errors.address} className="form-item">
            <label htmlFor="address" className="form-label form-label-required">
              <FormattedMessage id="address" defaultMessage="Address" />
            </label>
            <PlacesAutocomplete
              value={data.address}
              onChange={this.onChangeAddress}
              searchOptions={{
                componentRestrictions: { country: 'au' },
              }}
            >
              {renderFunc}
            </PlacesAutocomplete>
            {errors.address && <InlineMessage text={errors.address} />}
          </Form.Field>

          <Form.Field error={!!errors.businessName} className="form-item">
            <label
              htmlFor="businessName"
              className="form-label form-label-required"
            >
              <FormattedMessage id="businessName" defaultMessage="Business Name" />
            </label>
            <input
              type="text"
              id="businessName"
              name="businessName"
              placeholder="Business Name"
              value={data.businessName}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.fullName && <InlineMessage text={errors.businessName} />}
          </Form.Field>

          <Form.Field className="form-item">
            <label className="label-checbox">
              <input
                type="checkbox"
                className="checkbox"
                name="accept"
                value={accept}
                onChange={this.onChangeAccept}
              />
              <span className="checkmark" />I have read and agree to the&nbsp;
              <Link to="terms" className="link">
                <strong>Terms and Conditions</strong>
              </Link>
            </label>
          </Form.Field>
          <Form.Field className="form-item">
            {errors.message && (
              <InlineMessage text={errors.message} className="col-md-8" />
            )}
          </Form.Field>
          <Form.Field className="form-item form-button">
            <Button
              type="submit"
              className="btn btn-circle btn-form"
              disabled={!accept || loading}
              loading={loading}
            >
              <FormattedMessage id="sign_up" defaultMessage="Sign up" />
            </Button>
          </Form.Field>
          <p className="text-registered">
            Already registered?&nbsp;
            <Link
              to={`/signin${location.search}`}
              className="link-registered link-not-underline"
            >
              Login!
            </Link>
          </p>
        </Form>
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

export default withRouter(injectIntl(QuickSignupForm));
