import { get } from 'lodash';
import Config from 'constants/Config'
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const user_id = get(params, 'user_id') || ''
  const id = (params && params.id) || ''
  const sort = (params && params.sort) || ''

  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/categories?group=Collection&id[]=${id}&user_id=${user_id}&sort=${sort}&page[size]=100`
  }
}

const parseResponse = response => {
  response.data.data.forEach((item) => {
    const product = formatJson(response.data, item.type, item.id);
    item.children = product.children || [];
    item.name = item.attributes.name;
    item.type = item.attributes.group.toLowerCase();
    item.image =
      item.attributes.primaryImage && item.attributes.primaryImage.url;
    item.category = product.children || [];
  });

  const data = {
    ...response,
    data: response.data.data
  }

  return data
}

export { exec, parseResponse }
