import React, { Component } from 'react';
import { MasterLayout } from 'components/layout';
import { Pane } from 'shared/components/layouts';
import { DeliveryService } from 'components/scenes/delivery-services';

class DeliveryServicePage extends Component {
  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <DeliveryService history={this.props.history} />
        </Pane>
      </MasterLayout>
    );
  }
}

DeliveryServicePage.propTypes = {};

export default DeliveryServicePage;
