
import React, { useState } from "react";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <span className="text font-italic font-weight-normal">
        <>
        {text.length > 100 ? (
          <>
            {isReadMore ? text.slice(0, 40) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
              {isReadMore ? " More..." : " Close"}
            </span>
          </>
        ) : (
          <span>{text || ''}</span>
        )}
        </>
      </span>
    );
  };
    
  const Content = ({text}) => {
    return (
        <ReadMore>
            {text}
        </ReadMore>
    );
  };
  export default Content;