import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';

class TotalPayment extends Component {
  render() {
    return (
      <Pane className="empty-container">
        <Pane className="empty-content">
          <img
            src={process.env.PUBLIC_URL + '/assets/images/icons/empty.png'}
            alt="empty"
            className="img-empty"
          />
          <h2 className="empty-title">
            <FormattedMessage
              id="cart.no_items_in_your_cart"
              defaultMessage="No items in your cart"
            />
          </h2>
          <Link className="btn btn-circle btn-continue mt-3" to="/search?type=Food">
            <FormattedMessage
              id="cart.continue_shopping"
              defaultMessage="Continue shopping"
            />
          </Link>
        </Pane>
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.auth.isAuthenticated ? state.cart : state.guestCart,
});

export default connect(mapStateToProps)(TotalPayment);
