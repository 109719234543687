import _, { pickBy, identity } from "lodash";
import moment from "moment";
import Iframe from "react-iframe";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Config from "constants/Config";
import React, { Component } from "react";
import { Currency } from "shared/components";
import { formatRoute } from "shared/helpers";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import OrderAdapter from "services/orders/adapter";
import axios from 'axios';

class OrderSuccess extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    detail: PropTypes.object,
    orderId: PropTypes.string,
    onGetBuyingOrderDetail: PropTypes.func,
    onPushNotification: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      start_location: "",
      end_location: "",
    };
  }

  getOrderDetail = (orderId) => {
    const { detail } = this.props;

    this.props.onGetBuyingOrderDetail({ id: orderId, firstRead: true }).then((response) => {
      const sellers = [];
      const parseResponse = OrderAdapter.buyers.read.parseResponse(
        response.data
      );
      parseResponse &&
        parseResponse.cartItems &&
        detail.cartItems &&
        detail.cartItems.forEach((item) => {
          sellers.push(item.seller.id);
        });
      this.onSetLatLng(detail);
      if (detail && detail.cartItems.length > 0) {
          this.notifi(detail, orderId);
      }
      
      // setTimeout(() => {
      //   this.props
      //     .onPushNotification({
      //       object_id: orderId,
      //       object_type: "Order",
      //       target_id: sellers.join(","),
      //       target_type: "Seller",
      //       summary: `New Order #${orderId}`,
      //       meta: {
      //         order_type: "selling",
      //         order_id: orderId,
      //         url: `/orders/${orderId}/selling`,
      //       },
      //     })
      //     .catch(() => {
      //       if (this._notificationSystem) {
      //         this._notificationSystem.addNotification({
      //           message: "Something's wrong about notification",
      //           level: "error",
      //         });
      //       }
      //     });
      // }, 1500);
    });
  };

  notifi = (detail, orderId) => {
    let params = {
      receiverIds: [],
      heading: {},
      content: '',
      customData: {}
    };
    params.receiverIds = [detail.cartItems[0].seller.id.toString()];
    params.heading = 'Cardbey';
    params.content = `New Order #${orderId}`;
    params.customData = {
      screen: 'SellingOrderDetail',
      data: {
        orderId,
      },
    }
    axios({
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: 'Basic MjRmNTU5MWEtZmNiYi00MGVjLTlhMjktZGJjNmIzNTZmNGUw',
        'content-type': 'application/json',
      },
      url: 'https://onesignal.com/api/v1/notifications',
      data: JSON.stringify({
        app_id: '9b9e4918-465c-4dbd-83a3-8958c7d2b07e',
        include_aliases: {
          external_id: params.receiverIds
        },
        headings: { en: params.heading },
        contents: { en: params.content },
        name: 'NEW_ORDER',
        target_channel: 'push',
        data: params.customData,
        ios_badgeType: 'Increase',
        ios_badgeCount: 1,
      })
    });
  }

  onSetLatLng = (details) => {
    if (!_.isEmpty(details)) {
      const latStart = _.get(details, "cartItems[0].seller.lat");
      const lngStart = _.get(details, "cartItems[0].seller.lng");
      const latEnd = _.get(details, "user.lat");
      const lngEnd = _.get(details, "user.lng");
      this.setState({
        start_location: `${latStart},${lngStart}`,
        end_location: `${latEnd},${lngEnd}`,
      });
    }
  };

  componentDidMount() {
    const { orderId } = this.props;

    if (orderId) {
      this.getOrderDetail(orderId);
    }
  }

  isOptions = (item) => {
    if (!item.options) return false;
    if (!item.options.length) return false;

    return true;
  };

  includesOption = (id, item) => {
    const { optionInfo } = item;
    if (!optionInfo) return null;

    return optionInfo.find((child) => child.id === id);
  };

  getSelectedOptions = (item) => {
    if (!this.isOptions(item)) return [];

    const { options } = item;
    let selectdOptions = [];

    options.map((option) => {
      const { childrens } = option;
      childrens.forEach((child) => {
        if (this.includesOption(child.id, item)) {
          selectdOptions.push({
            ...child,
            quantity: this.includesOption(child.id, item).quantity,
          });
        }
      });
    });
    return selectdOptions;
  };

  getTotalPriceOptions = (item) => {
    let totalPirce = 0;

    if (!item || !item.options || !item.options.length) return 0;

    item.options.forEach((option) => {
      const { childrens } = option;

      childrens.forEach((children) => {
        const childIncludes = this.includesOption(children.id, item);
        if (childIncludes) {
          totalPirce +=
            Number(children.price || 0) * Number(childIncludes.quantity);
        }
      });
    });
    return Number(item.quantity || 1) * totalPirce;
  };

  convertParamSearch = (search) => {
    const arr = [];
    Object.keys(pickBy(search, identity)).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(pickBy(search, identity), key)) {
        arr.push(`${key}=${pickBy(search, identity)[key]}`);
      }
    });
    return arr.join("&");
  };

  productTotalPrice = (item) => {
    return (
      (Number(item.promotionPrice) + this.getTotalPriceOptions(item)) *
        item.quantity || 1
    );
  };

  mappingSelectOptionToText = (item) => {
    const selectedOptions = this.getSelectedOptions(item);
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${
            child.name
          }`
      )
      .join(", ");
  };

  isDineIn = (detail) => {
    if (detail.cartItems) {
      const items = detail.cartItems.find(
        (item) =>
          item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN ||
          item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
      );
      return !!items;
    }
    return false;
  };

  render() {
    const { detail, userInfo } = this.props;
    const { start_location, end_location } = this.state;
    let linkStore = `${
      detail.cartItems
        ? detail.cartItems[0]
          ? detail.cartItems[0].seller.id
          : ""
        : ""
    }`;

    return (
      <Pane className="checkout-container pt-5 pb-5">
        <Pane className="container">
          <Pane className="row">
            <h2 className="title-checkout mb-3">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/check-green.svg"
                }
                alt="green"
                className="icon"
              />
              <FormattedMessage
                id="notification.your_order_has_been_saved_successfully"
                defaultMessage="Your order has been saved successfully"
              />
            </h2>
            <Pane className="checkout-content">
              <Pane className="row">
                <Pane className="col-lg-4">
                  <Pane className="checkout-info">
                    <h2 className="checkout-title mb-2">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/cart/user.svg"
                        }
                        alt="users"
                        className="icon"
                      />
                      <FormattedMessage
                        id="order_infomation"
                        defaultMessage="ORDER INFOMATION"
                      />
                    </h2>
                    <p className="check-label">
                      <FormattedMessage
                        id="order_number"
                        defaultMessage="Order number"
                      />
                      : <span className="check-name">{detail.id}</span>
                    </p>
                    <p className="check-label">
                      <FormattedMessage
                        id="order_date"
                        defaultMessage="Order date"
                      />
                      :
                      <span className="check-name">
                        {moment
                          .utc(detail.orderDate)
                          .format("DD-MM-YYYY HH:mm")}
                      </span>
                    </p>
                    <p className="check-label">
                      <FormattedMessage id="total" defaultMessage="Total" />:
                      <span className="check-name">
                        <Currency price={detail.total} />
                      </span>
                    </p>
                    <p className="check-label">
                      <FormattedMessage
                        id="payment_method"
                        defaultMessage="Payment method"
                      />
                      :{" "}
                      <span className="check-name">
                        {detail.paymentMethod !== ""
                          ? detail.paymentMethod
                          : "Credit card"}
                      </span>
                    </p>
                    {
                        detail.firstScanDiscount > 0 &&(
                          <p className="check-label">
                            <FormattedMessage id="discount" defaultMessage="Discount" />
                            :&nbsp;
                            <span className="check-name">
                                {detail.firstScanDiscount}% (Max <Currency price={detail.maxDiscount} />)&nbsp;
                                <Currency price={detail.itemsTotal - detail.total} />
                            </span>
                          </p>
                        )
                      }
                    <p className="check-label">
                      <FormattedMessage id="status" defaultMessage="Status" />
                      :&nbsp;
                      <span className="check-name">
                        {detail.status && (
                          <span
                            className={`badge badge-${detail.status.toLowerCase()}`}
                          >
                            <FormattedMessage
                              id={`order.${detail.status.toLowerCase()}`}
                              defaultMessage={detail.status}
                            />
                          </span>
                        )}
                      </span>
                    </p>
                  </Pane>
                </Pane>
                <Pane className="col-lg-4">
                  <Pane className="checkout-info">
                    <h2 className="checkout-title mb-2">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/cart/user.svg"
                        }
                        alt="users"
                        className="icon"
                      />
                      <FormattedMessage
                        id="customer_information"
                        defaultMessage="ORDER INFOMATION"
                      />
                    </h2>
                    {detail.mode !== "GUEST" && (
                      <>
                        <p className="check-label">
                          <FormattedMessage
                            id="full_name"
                            defaultMessage="Full name"
                          />
                          :{" "}
                          <span className="check-name">
                            {_.get(userInfo, "fullName")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="email" defaultMessage="Email" />
                          :
                          <span className="check-name">
                            {_.get(userInfo, "email")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="phone" defaultMessage="Phone" />
                          :
                          <span className="check-name">
                            {_.get(userInfo, "phoneNumber")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="address"
                            defaultMessage="Address"
                          />
                          :
                          <span className="check-name">
                            {_.get(userInfo, "address")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="post_code"
                            defaultMessage="Postcode"
                          />
                          :&nbsp;
                          <span className="check-name">
                            {_.get(userInfo, "postCode")}
                          </span>
                        </p>
                      </>
                    )}
                    {detail.mode === "GUEST" && (
                      <>
                        <p className="check-label">
                          Guest Name :{" "}
                          <span className="check-name">{detail.guestName}</span>
                        </p>
                        <p className="check-label">
                          Guest Phone :{" "}
                          <span className="check-name">
                            {detail.guestPhone}
                          </span>
                        </p>
                        <p className="check-label">
                          Guest Email :{" "}
                          <span className="check-name">
                            {detail.guestEmail}
                          </span>
                        </p>
                        <p className="check-label">
                          Note:
                          <span
                            className="check-name"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {detail.guestNote}
                          </span>
                        </p>
                      </>
                    )}
                  </Pane>
                </Pane>
                {!this.isDineIn(detail) && (
                  <Pane className="col-lg-4">
                    <Pane className="checkout-info">
                      <h2 className="checkout-title mb-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/cart/location.svg"
                          }
                          alt="users"
                          className="icon"
                        />
                        <FormattedMessage
                          id="shipping_address"
                          defaultMessage="Shipping address "
                        />
                      </h2>
                      {!_.isEmpty(detail.deliveryInfo) && (
                        <>
                          <p className="check-label">
                            <FormattedMessage
                              id="delivery_address"
                              defaultMessage="Delivery address"
                            />
                            :{" "}
                            <span className="check-name">
                              {_.get(detail, "deliveryInfo.deliveryAddress")}
                            </span>
                          </p>
                          <p className="check-label">
                            <FormattedMessage
                              id="delivery_postcode"
                              defaultMessage="Delivery postCode"
                            />
                            :
                            <span className="check-name">
                              {_.get(detail, "deliveryInfo.deliveryPostcode")}
                            </span>
                          </p>
                        </>
                      )}
                      {detail.mode === "GUEST" &&
                        _.isEmpty(detail.deliveryInfo) && (
                          <p className="check-label">PICK UP</p>
                        )}
                    </Pane>
                  </Pane>
                )}
              </Pane>
            </Pane>
            <Pane className="cart-order w-100">
              <Pane className="row mt-4">
                <Pane className="col-lg-12">
                  <Pane className="cart-table cart-table-desktop">
                    <h2 className="cart-title mb-3">
                      <FormattedMessage
                        id="order_list"
                        defaultMessage="Order list"
                      />
                    </h2>
                    <table>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>
                            <FormattedMessage
                              id="image"
                              defaultMessage="Image"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="product_name"
                              defaultMessage="Product Name"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="quantity"
                              defaultMessage="Quantity"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="total_price"
                              defaultMessage="Total Price"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="seller"
                              defaultMessage="seller"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {detail.cartItems &&
                          detail.cartItems.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>
                                  <Link to={`/products/${item.productId}`}>
                                    <img
                                      src={
                                        item.primaryImage
                                          ? item.primaryImage
                                          : process.env.PUBLIC_URL +
                                            `/assets/images/default-image.png`
                                      }
                                      size="tiny"
                                      ref={(img) => (this.img = img)}
                                      alt="products"
                                      className="image img-table"
                                      onError={() =>
                                        (this.img.src =
                                          process.env.PUBLIC_URL +
                                          `/assets/images/default-image.png`)
                                      }
                                    />
                                  </Link>
                                </td>
                                <td className="col-name">
                                  <h5 className="table-name">{item.name}</h5>
                                  {this.isOptions(item) ? (
                                    <Pane className="view-options">
                                      <h5
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {this.mappingSelectOptionToText(item)}
                                      </h5>
                                    </Pane>
                                  ) : null}
                                </td>
                                <td className="col-quanlity">
                                  {item.quantity}
                                </td>
                                <td>
                                  <Currency
                                    price={this.productTotalPrice(item)}
                                  />
                                </td>
                                <td>
                                  <p className="text-gray pt0 pb0">
                                    {_.get(item, "seller.business.name") ? (
                                      <Link
                                        to={`/${formatRoute(
                                          item.seller.business.name
                                        )}`}
                                      >
                                        {item.seller.name}
                                      </Link>
                                    ) : (
                                      item.seller.name
                                    )}
                                  </p>
                                  <p className="text-gray pt0 pb0">
                                    {item.seller.phone}
                                  </p>
                                  <p className="text-gray pt0 pb0">
                                    {item.seller.email}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Pane>
                  <Pane className="cart-table cart-table-responsive">
                    {detail.cartItems &&
                      detail.cartItems.map((item, index) => (
                        <div className="cart-item" key={index}>
                          <div className="cart-image">
                            <img
                              src={
                                item.primaryImage
                                  ? item.primaryImage
                                  : process.env.PUBLIC_URL +
                                    `/assets/images/default-image.png`
                              }
                              size="tiny"
                              ref={(img) => (this.img = img)}
                              alt="products"
                              className="image img-table"
                              onError={() =>
                                (this.img.src =
                                  process.env.PUBLIC_URL +
                                  `/assets/images/default-image.png`)
                              }
                            />
                            <Pane></Pane>
                          </div>
                          <div className="cart-quanlity mt-3 mb-3">
                            <div className="total-quanlity">
                              <p className="quanlity-norm">
                                <FormattedMessage
                                  id="quantity"
                                  defaultMessage="Quantity"
                                />
                              </p>
                              <span className="quanlity-number">
                                {item.quantity}
                              </span>
                            </div>
                            <div className="total">
                              <span className="price">
                                <Currency
                                  price={
                                    Number(item.promotionPrice || 0) +
                                    this.getTotalPriceOptions(item)
                                  }
                                />
                              </span>
                              <span className="price total-price">
                                <Currency
                                  price={
                                    (Number(item.promotionPrice || 0) +
                                      this.getTotalPriceOptions(item)) *
                                    item.quantity
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Pane>
                </Pane>
              </Pane>
              <Pane className="row mt-4">
                <Pane className="col-lg-12 text-center">
                  <Pane className="map-delivery">
                    {start_location &&
                    end_location &&
                    !this.isDineIn(detail) &&
                    !_.isEmpty(detail.deliveryInfo) &&
                    process.env.REACT_APP_SHOW_BHD_MAP === "true" ? (
                      <Iframe
                        url={
                          process.env.REACT_APP_MAP_KCATER +
                          `?${this.convertParamSearch({
                            start_postcode: 3031,
                            start_location,
                            end_postcode: 3127,
                            end_location,
                            length: 2,
                            width: 3,
                            height: 1,
                            weight: 1,
                            map_height: "700px",
                            "access-token":
                              process.env.REACT_APP_ACCESS_TOKEN_KCATER,
                            clientId: process.env.REACT_APP_CLIENTID_KCATER,
                            clientSecret:
                              process.env.REACT_APP_CLIENTSECRET_KCATER,
                          })}`
                        }
                        className="map-container"
                      />
                    ) : null}
                  </Pane>
                </Pane>
              </Pane>
              <Pane className="row mt-4">
                <Pane className="col-lg-12 text-center">
                  <Link
                    to={linkStore}
                    className="btn btn-process btn-continue-dark mr-2"
                  >
                    <i className="ic-common-overview font-weight-bold mr-2"></i>
                    <FormattedMessage
                      id="back_to_store"
                      defaultMessage="Back to Store"
                    />
                  </Link>
                  <Link
                    to="/search?type=Food"
                    className="btn btn-process btn-continue-dark"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/layouts/cart.svg"
                      }
                      alt="lock"
                      className="icon"
                    />
                    <FormattedMessage
                      id="cart.continue_shopping"
                      defaultMessage="Continue Shopping"
                    />
                  </Link>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default OrderSuccess;
