import React, { Component } from 'react'
import { Pane } from "shared/components/layouts";

class Loading extends Component {
	render() {
		return (
			<Pane className="common-loading">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</Pane>
		)
	}
}

export default Loading
