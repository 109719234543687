import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Pane } from "shared/components/layouts";
import { deletePosCart, updatePosCart } from "actions/posCart";
import { Currency } from "shared/components";
import { Icon } from "semantic-ui-react";

class CartItem extends Component {
  static propTypes = {
    changeQuantity: PropTypes.func.isRequired,
  };


  isOptions = () => {
    if (!this.props.item.options) return false;
    if (!this.props.item.options.length) return false;

    return true;
  };

  includesOption = (id) => {
    const { optionInfo } = this.props.item;
    if (!optionInfo) return null;
    return optionInfo.find((child) => child.id === id);
  };

  getSelectedOptions = () => {
    if (!this.isOptions()) return [];

    const { options } = this.props.item;
    let selectdOptions = [];

    options.map((option) => {
      const { childrens } = option;
      childrens.forEach((child) => {
        if (this.includesOption(child.id)) {
          selectdOptions.push({
            ...child,
            quantity: this.includesOption(child.id).quantity,
          });
        }
      });
    });
    return selectdOptions;
  };

  mappingSelectOptionToText = () => {
    const selectedOptions = this.getSelectedOptions();
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${child.name
          }`
      )
      .join(", ");
  };

  onViewCartOption = () => {
    this.props.cartModal.setCartView(this.props.item);
    this.props.cartModal.openModal();
  };

  getTotalPriceOptions = () => {
    const list = this.getSelectedOptions();

    let total = 0;

    list.forEach((item) => {
      total += item.price * item.quantity;
    });

    return total;
  };

  changeQuanlity = (item, action) => {
    let quantity = action === "inc" ? item.quantity + 1 : item.quantity - 1;
    if (quantity > 0 ){
      this.props.updatePosCart(true, item.productId, quantity);
    }
  };

  viewOptionPopup = () => {
    this.props.cartModal.openModal();
    this.props.cartModal.setCartView({ ...this.props.item, modeCart: true });
  };

  render() {
    const { item } = this.props;
    return (
      <Pane className="item-container">
        <Pane className="item">
          <Link to={`/products/${item.productId}`} className="name">
            <b>{item.name}</b>
          </Link>
          <Pane className="quantity">
            <span
              className="operator"
              onClick={this.changeQuanlity.bind(this, item, "dec")}
            >
              -
            </span>
            <span className="operator">{item.quantity}</span>
            <span
              className="operator"
              onClick={this.changeQuanlity.bind(this, item, "inc")}
            >
              +
            </span>
          </Pane>
          <Pane className="price total-price">
            <Currency
              price={
                (item.promotionPrice + this.getTotalPriceOptions()) *
                item.quantity
              }
            />
          </Pane>
          <span
            style={{ textAlign: "right" }}
            className="text-options"
            onClick={() =>
              this.props.deletePosCart(item.productId)
            }
          >
            <Icon name="trash" />
          </span>
        </Pane>
        <Pane className="item">
          {this.isOptions() ? (
            <span className="text-options" onClick={this.viewOptionPopup}>
              <Icon name="edit" />
              {this.mappingSelectOptionToText()}
            </span>
          ) : null}


        </Pane>
      </Pane>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  updatePosCart: bindActionCreators(updatePosCart, dispatch),
  deletePosCart: bindActionCreators(deletePosCart, dispatch),
});

export default connect(null, bindDispatchToProps)(CartItem);
