import * as types from 'constants/ActionTypes'
import api from 'api'

// business logos
export const getBusinessLogos = () => (dispatch) => dispatch({
  types: [types.REQUEST_BUSINESS_LOGOS, types.RECEIVE_BUSINESS_LOGOS_SUCCESS, types.RECEIVE_BUSINESS_LOGOS_FAILURE],
  payload: {
    request: api.business.getBusinessLogos()
  }
})

// business logos
export const getBusinessCboosts = () => (dispatch) => dispatch({
  types: [types.REQUEST_BUSINESS_LOGOS, types.RECEIVE_BUSINESS_LOGOS_SUCCESS, types.RECEIVE_BUSINESS_LOGOS_FAILURE],
  payload: {
    request: api.business.getBusinessCboosts()
  }
})

// business logos
export const getBusinesses = () => (dispatch) => dispatch({
  types: [types.REQUEST_BUSINESSES, types.RECEIVE_BUSINESSES_SUCCESS, types.RECEIVE_BUSINESSES_FAILURE],
  payload: {
    request: api.business.browse()
  }
})

// Search business

export const searchBusiness = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BUSINESS_SEARCH, types.RECEIVE_BUSINESS_SEARCH_SUCCESS, types.RECEIVE_BUSINESS_SEARCH_FAILURE],
  payload: {
    request: api.business.search(params)
  }
})

// Get bussiness by catogory
export const getBusinessByCategory = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BUSINESSES_BY_GROUP, types.REQUEST_BUSINESSES_BY_GROUP_SUCCESS, types.REQUEST_BUSINESSES_BY_GROUP_FAILURE],
  payload: {
    request: api.business.getBussinessByCatogory(params)
  }
})

export const reviewBusiness = (params) => (dispatch) => dispatch({
  type: types.REVIEW_BUSINESS,
  payload: {
    request: api.business.review(params)
  }
})

export const getReviewBusiness = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_BUSINESS_REVIEW,
  payload: {
    request: api.business.getReview(params)
  }
})
