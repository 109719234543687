import Config from "constants/Config";
import { formatJson } from "shared/helpers";
const exec = (params) => {
  const serviceId = params.serviceId || "";
  const date = params.date || "";
  const times = params.times || "";
  return {
    method: "GET",
    url: `service-member/booking/available?service_id=${serviceId}&date=${date}`,
    baseURL: Config.NEW_API_URL,
  };
};
const parseResponse = (response) => {
  const services = [];
  if (response.data) {
    response.data.forEach((item) => {
      const data = formatJson(response, item.type, item.id);
      services.push(data);
    });
  }
  const data = services;
  return data;
};
export { exec, parseResponse };