import React, { Component } from 'react';
import { Pane } from "shared/components/layouts";
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { getUserContactsRequest, deleteUserContactsRequest } from "../../actions/userContact";
import Config from "constants/Config";
class GuestUserContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
          flag: false,
        };
    }

    initContact = () => {
      let oldUserContacts = localStorage.getItem("userContacts");
      let userContacts = [];
      if (oldUserContacts) {
        userContacts = JSON.parse(oldUserContacts) || [];
      }
      this.props.getUserContactsCompo(userContacts)
    }

    onRemoveContact = (id, tpyeBusiness) => {
      this.props.deleteUserContactsCompo(id, tpyeBusiness);
      this.forceUpdate();
    };
    
    componentDidMount(){      
      this.initContact();
    }

    render() {
        let { userContacts, isMobile } = this.props;
        const contacts = this.props.userContacts && this.props.userContacts.length > 0 ? this.props.userContacts.filter(item => item.type === Config.WALLET_TYPE.CONTACT) : [];
        const menus = this.props.userContacts && this.props.userContacts.length > 0 ? this.props.userContacts.filter(item => item.type === Config.WALLET_TYPE.MENU) : [];
        return (
            <Pane className="guest-contacts" tabIndex={0} onBlur={() => this.setState({ flag: false })}>
              {userContacts && userContacts.length > 0}
              <i className="icon-wallet" onClick={ isMobile ?  () => this.setState({ flag: !this.state.flag }) : undefined}>
              {userContacts && userContacts.length > 0 && (
                <span className="contact-total">
                  <span className="number">{userContacts.length}</span>
                </span>
              )}
              </i>
              <ul className={`guest-contacts-list ${this.state.flag ? 'block' : 'hide'} `}>
                {contacts && contacts.length > 0 && (
                  <li className="guest-contacts-list__title"><i className="fas fa-address-book"></i>My Contact</li>
                )}
                {contacts && contacts.length > 0 && (
                    contacts.map((item, index) => (    
                        <li key={index} className="guest-contacts__item">
                            <Link to={`/${item.id}`}>
                                <img
                                    src={item.avatar}
                                    style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                        border: "1px solid #000"
                                    }}
                                />
                                <span className="guest-contacts__item-name">{item.name}</span>
                            </Link>
                            <i className="icon-contact icon-trash" onClick={() => this.onRemoveContact(item.id, Config.WALLET_TYPE.CONTACT)}></i>
                        </li>
                      ))
                )}
                {menus && menus.length > 0 && (
                  <li className={`guest-contacts-list__title ${contacts.length > 0 ? 'mt-3' : ''}`}><i className="icon-menu"></i>My Menu</li>
                )}
                {menus && menus.length > 0 && (
                    menus.map((item, index) => (    
                        <li key={index} className="guest-contacts__item">
                            <Link to={`/${item.id}/menu`}>
                                <img
                                    src={item.avatar}
                                    style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                        border: "1px solid #000"
                                    }}
                                />
                                <span className="guest-contacts__item-name">{item.name}</span>
                            </Link>
                            <i className="icon-contact icon-trash" onClick={() => this.onRemoveContact(item.id, Config.WALLET_TYPE.MENU)}></i>
                        </li>
                      ))
                )}
                {userContacts && userContacts.length === 0 && (
                    <div style={{ textAlign: "center" }}>
                        <FormattedMessage
                            id="notification.you_have_not_had_any_orders_yet1"
                            defaultMessage={`You haven't had any user contacts yet.`}
                        />
                    </div>
                )}
              </ul>
            </Pane>
        );
    }
};

const mapStateToProps = state => {
  return { 
    userContacts: state.userContacts.userContacts
   };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserContactsCompo: (userContacts) => {
      dispatch(getUserContactsRequest(userContacts));
    },
    deleteUserContactsCompo: (id, tpyeBusiness) => {
      dispatch(deleteUserContactsRequest(id, tpyeBusiness));
    }
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(GuestUserContact);
