import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Select,
  Image as ImageS,
  Icon,
  Checkbox,
  Radio,
} from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { get, isEmpty } from "lodash";
import InlineMessage from "shared/components/InlineMessage";
import { Pane } from "shared/components/layouts";
import { formatRoute, handleError, quill } from "shared/helpers";
import TradingHourItem from "./TradingHourItem";
import createClass from "create-react-class";
import SelectImage from "react-select";
import Config from "constants/Config";
import ReactQuill from "react-quill";
import TreeSelect from "rc-tree-select";

class CreateServiceForm extends Component {
  static propTypes = {
    categories: PropTypes.object.isRequired,
    getMyServices: PropTypes.func.isRequired,
    createMyService: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    history: PropTypes.object,
    intl: intlShape.isRequired,
  };

  state = {
    imageCategory: {
      label: "/images/category/18.Collectables.png",
      value: "/images/category/18.Collectables.png",
    },
    default_images: [
      {
        label: "/images/category/18.Collectables.png",
        value: "/images/category/18.Collectables.png",
      },
      {
        label: "/images/category/17.Coins.png",
        value: "/images/category/17.Coins.png",
      },
      {
        label: "/images/category/16.Clothing,Shoes,Accessories.png",
        value: "/images/category/16.Clothing,Shoes,Accessories.png",
      },
      {
        label: "/images/category/7.Cameras.png",
        value: "/images/category/7.Cameras.png",
      },
      {
        label: "/images/category/6.Business.png",
        value: "/images/category/6.Business.png",
      },
      {
        label: "/images/category/5.Books,Magazines.png",
        value: "/images/category/5.Books,Magazines.png",
      },
      {
        label: "/images/category/3.Art.png",
        value: "/images/category/3.Art.png",
      },
      {
        label: "/images/category/2.Antiques.png",
        value: "/images/category/2.Antiques.png",
      },
      {
        label: "/images/category/56.Consultingservices.png",
        value: "/images/category/56.Consultingservices.png",
      },
      {
        label: "/images/category/57.Educationservices.png",
        value: "/images/category/57.Educationservices.png",
      },
      {
        label: "/images/category/58.Legalservices.png",
        value: "/images/category/58.Legalservices.png",
      },
      {
        label: "/images/category/59.Healthandbeautyservices.png",
        value: "/images/category/59.Healthandbeautyservices.png",
      },
      {
        label: "/images/category/65.Handymanservices.png",
        value: "/images/category/65.Handymanservices.png",
      },
      {
        label: "/images/category/66.Cleaningservices.png",
        value: "/images/category/66.Cleaningservices.png",
      },
      {
        label: "/images/category/67.Constructionservices.png",
        value: "/images/category/67.Constructionservices.png",
      },
      {
        label: "/images/category/71.Entertainmentservices.png",
        value: "/images/category/71.Entertainmentservices.png",
      },
      {
        label: "/images/category/72.House-careservices.png",
        value: "/images/category/72.House-careservices.png",
      },
      {
        label: "/images/category/73.Financialservices.png",
        value: "/images/category/73.Financialservices.png",
      },
      {
        label: "/images/category/77.Foodservices.png",
        value: "/images/category/77.Foodservices.png",
      },
      {
        label: "/images/category/78.Informationservices.png",
        value: "/images/category/78.Informationservices.png",
      },
      {
        label: "/images/category/19.Computers-Tablets&Networking.png",
        value: "/images/category/19.Computers-Tablets&Networking.png",
      },
      {
        label: "/images/category/15.Cars,Bikes,Boats.png",
        value: "/images/category/15.Cars,Bikes,Boats.png",
        width: "70px",
      },
      {
        label: "/images/category/4.Baby.png",
        value: "/images/category/4.Baby.png",
      },
    ],
    newImageCategory: false,
    images: [],
    category_images: [],
    imageFiles: [],
    imageCategoryFiles: [],
    data: {
      name: "",
      categoryId: "",
      unitOfPrice: "AUD",
      description: "",
      price: "",
      duration: 0,
      note: "",
      inventoryStatus: true,
    },
    days: [
      { key: "Mon", value: "Mon", text: "Monday" },
      { key: "Tue", value: "Tue", text: "Tuesday" },
      { key: "Wed", value: "Wed", text: "Wednesday" },
      { key: "Thu", value: "Thu", text: "Thusday" },
      { key: "Fri", value: "Fri", text: "Friday" },
      { key: "Sat", value: "Sat", text: "Saturday" },
      { key: "Sun", value: "Sun", text: "Sunday" },
    ],
    types: [
      { key: "1", value: 1, text: "Single Value" },
      { key: "2", value: 2, text: "Multi Value" },
      { key: "3", value: 3, text: "Included Quantity" },
    ],
    staffs: [],
    options: [],
    tradingHour: {
      Mon: {
        from: "",
        to: "",
        off: false,
      },
      Tue: {
        from: "",
        to: "",
        off: false,
      },
      Wed: {
        from: "",
        to: "",
        off: false,
      },
      Thu: {
        from: "",
        to: "",
        off: false,
      },
      Fri: {
        from: "",
        to: "",
        off: false,
      },
      Sat: {
        from: "",
        to: "",
        off: false,
      },
      Sun: {
        from: "",
        to: "",
        off: false,
      },
    },
    isApplyForAll: false,
    isVisibleApply: false,
    dataCategory: {
      name: "",
      group: "Service",
    },
    newCategory: false,
    errors: {},
    categories: [],
    groups: [],
    open: false,
    loading: false,
  };

  componentDidMount() {
    const { userInfo } = this.props;
    if (get(userInfo, "business.tradingHours")) {
      const tradingHour = this.state.tradingHour;
      Object.keys(get(userInfo, "business.tradingHours")).forEach((item) => {
        const key = item.charAt(0).toUpperCase() + item.slice(1);
        if (
          !get(userInfo, "business.tradingHours")[item].from ||
          !get(userInfo, "business.tradingHours")[item].to
        ) {
          tradingHour[key].from = "";
          tradingHour[key].to = "";
          tradingHour[key].off = false;
        } else {
          tradingHour[key].from = get(userInfo, "business.tradingHours")[
            item
          ].from;
          tradingHour[key].to = get(userInfo, "business.tradingHours")[item].to;
          tradingHour[key].off = get(userInfo, "business.tradingHours")[
            item
          ].off;
        }
      });
      this.setState((prevState) => ({
        tradingHour,
        isApplyForAll: !prevState.isApplyForAll,
      }));
    }
  }

  applyForAll = () => {
    const tradingHour = this.state.tradingHour;
    Object.keys(this.state.tradingHour).forEach((item) => {
      const key = item.charAt(0).toUpperCase() + item.slice(1);
      tradingHour[key].from = this.state.tradingHour.Mon.from;
      tradingHour[key].to = this.state.tradingHour.Mon.to;
      tradingHour[key].off = this.state.tradingHour.Mon.off;
    });
    this.setState((prevState) => ({
      tradingHour,
      isApplyForAll: !prevState.isApplyForAll,
    }));
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  onChangeCategory = (e) => {
    this.setState({
      dataCategory: {
        ...this.state.dataCategory,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSelectGroup = (e, s) => {
    this.setState({
      dataCategory: { ...this.state.dataCategory, [s.name]: s.value },
    });
  };

  onChangeHours = (month, name, value) => {
    if (month == 'Mon' && !this.state.isVisibleApply) {
      this.setState({ isVisibleApply: true })
    }
    this.setState({
      tradingHour: {
        ...this.state.tradingHour,
        [month]: {
          ...this.state.tradingHour[month],
          [name]: value,
        },
      },
    });
  };

  onChangeToggle = (month, name, value) => {
    this.setState({
      tradingHour: {
        ...this.state.tradingHour,
        [month]: {
          ...this.state.tradingHour[month],
          [name]: value,
        },
      },
    });
  };

  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.images.length < 6 &&
          this.state.images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  handleChangeImageCategory = (e, { value }) => {
    this.setState({
      newImageCategory: value,
    });
  };

  onChangeCategoryImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.category_images.length < 6 &&
          this.state.category_images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.category_images;
            const imageFiles = this.state.imageCategoryFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              category_images: images,
              imageCategoryFiles: imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  handleGroup = () => {
    let groups = [];
    if (this.state.group === "") {
      groups = [
        {
          text: "Menu",
          value: "Menu",
        },
        {
          text: "Service",
          value: "Service",
        },
      ];
    } else if (this.state.group === "menu") {
      groups = [
        {
          text: "Menu",
          value: "Menu",
        },
      ];
    } else {
      groups = [
        {
          text: "Service",
          value: "Service",
        },
      ];
    }
    this.setState({
      groups,
    });
  };

  handleChangeCheckbox = (e, { value }) => {
    this.setState({
      newCategory: value,
    });
  };

  handleDuration = (action, e) => {
    e.preventDefault();
    const duration = Number(this.state.data.duration);

    this.setState({
      data: {
        ...this.state.data,
        duration:
          action === "plus"
            ? duration + 15
            : duration - 15 >= 0
              ? duration - 15
              : 0,
      },
    });
  };

  handleCategory = (categories) => {
    const array = [];
    categories &&
      categories.results &&
      categories.results.forEach((item) => {
        array.push({
          key: item.id,
          value: item.id,
          text: item.name,
        });
      });

    this.setState({
      categories: array,
    });
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  removeCategoryImage = (index) => {
    const images = this.state.category_images;
    const imageFiles = this.state.imageCategoryFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      category_images: images,
      imageCategoryFiles: imageFiles,
    });
  };

  validate = (data, dataCategory) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.price) {
      errors.price = intl.formatMessage({ id: "validate.require_field" });
    }
    if (data.price && data.price <= 0) {
      errors.price = intl.formatMessage({ id: "validate.invalid_price" });
    }
    if (this.state.newCategory === false && !data.categoryId) {
      errors.categoryId = intl.formatMessage({ id: "validate.require_field" });
    }
    if (this.state.newCategory === true) {
      if (!dataCategory.name) {
        errors.category_name = intl.formatMessage({
          id: "validate.require_field",
        });
      }
      if (!dataCategory.group) {
        errors.category_group = intl.formatMessage({
          id: "validate.require_field",
        });
      }
    }

    return errors;
  };

  createService = async () => {
    const errors = this.validate(this.state.data, this.state.dataCategory);
    const { userInfo } = this.props;
    let categoryId;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      if (this.state.newCategory === true) {
        const dataCategory = new FormData();
        if (this.state.imageCategoryFiles[0]) {
          dataCategory.append(
            "primary_image",
            this.state.imageCategoryFiles[0]
          );
        }
        if (this.state.newImageCategory === false) {
          dataCategory.append("primary_image", this.state.imageCategory.value);
        }
        dataCategory.append("name", this.state.dataCategory.name);
        dataCategory.append("group", this.state.dataCategory.group);
        dataCategory.append("user_id", userInfo.id);
        dataCategory.append(
          "sub_image_1",
          this.state.imageCategoryFiles[1] || ""
        );
        dataCategory.append(
          "sub_image_2",
          this.state.imageCategoryFiles[2] || ""
        );
        dataCategory.append(
          "sub_image_3",
          this.state.imageCategoryFiles[3] || ""
        );
        dataCategory.append(
          "sub_image_4",
          this.state.imageCategoryFiles[4] || ""
        );
        dataCategory.append(
          "sub_image_5",
          this.state.imageCategoryFiles[5] || ""
        );

        try {
          const {
            data: { data },
          } = await this.props.createCategory(dataCategory);
          this.setState({
            loading: false,
            open: false,
            dataCategory: {
              ...this.state.dataCategory,
            },
          });
          categoryId = data.id;
          this.addService(categoryId);
        } catch (error) {
          const errors = {};
          errors.message =
            "Category: " +
            handleError(
              error,
              this.props.intl.formatMessage({
                id: "notification.please_try_again",
              })
            );

          this.setState({
            loading: false,
            errors,
          });
        }
      } else {
        this.addService(this.state.data.categoryId);
      }
    }
  };

  buildFormData = (object) => {
    const formData = new FormData();
    for (const key in object) {
      formData.append(key, object[key]);
    }

    return formData;
  };

  assignStaffsService = async (staffs, service_id) => {
    const handlers = [];
    for (const staff_id of staffs) {
      const requestServiceMember = this.buildFormData({
        staff_id: staff_id,
        service_id: service_id,
      });
      handlers.push(this.props.createMyServiceMember(requestServiceMember));
    }
    await Promise.all(handlers);
  };

  onAddOptions = async (options, service_id) => {
    let index = 0;
    for (const option of options) {
      if (option.name && option.type) {
        const resOpt = await this.props.createMyOptionGroup(
          this.buildFormData({
            name: option.name,
            type: option.type,
            dp_ord_seq: index,
          })
        );
        const { id: option_group_id } = resOpt.data.data;
        const handlers = [];
        let indexChildren = 0;
        for (const children of option.childrens) {
          if (children.name && this.notEmptyNumber(children.price)) {
            handlers.push(
              this.props.createMyOption(
                this.buildFormData({
                  name: children.name,
                  price: Number(children.price || 0),
                  isCheck: children.isCheck ? 1 : 0,
                  service_id: service_id,
                  option_group_id: option_group_id,
                  dp_ord_seq: indexChildren,
                })
              )
            );
            indexChildren++;
          }
        }

        await Promise.all(handlers);
        index++;
      }
    }

    this.setState({
      loading: false,
      open: false,
      images: [],
      imageFiles: [],
      data: {
        ...this.state.data,
        name: "",
        description: "",
        price: "",
      },
    });
    this.props.history.push(
      `/u/${formatRoute(this.props.userInfo.displayName)}/services`
    );
  };

  addService = (categoryId) => {
    const tradingHours = {
      Mon: {
        from: "",
        to: "",
        off: false,
      },
      Tue: {
        from: "",
        to: "",
        off: false,
      },
      Wed: {
        from: "",
        to: "",
        off: false,
      },
      Thu: {
        from: "",
        to: "",
        off: false,
      },
      Fri: {
        from: "",
        to: "",
        off: false,
      },
      Sat: {
        from: "",
        to: "",
        off: false,
      },
      Sun: {
        from: "",
        to: "",
        off: false,
      },
    };

    Object.keys(this.state.tradingHour).forEach((item) => {
      const key = item.charAt(0).toUpperCase() + item.slice(1);
      if (
        !this.state.tradingHour[item].from ||
        !this.state.tradingHour[item].to
      ) {
        tradingHours[key].from = "00:00";
        tradingHours[key].to = "01:00";
        tradingHours[key].off = true;
      } else {
        tradingHours[key].from = this.state.tradingHour[item].from;
        tradingHours[key].to = this.state.tradingHour[item].to;
        tradingHours[key].off = this.state.tradingHour[item].off;
      }
    });

    const data = new FormData();
    data.append("name", this.state.data.name);
    data.append("description", this.state.data.description);
    data.append("category_id", categoryId);
    data.append("duration", this.state.data.duration / 60);
    data.append("currency_unit", this.state.data.unitOfPrice);
    data.append("price", this.state.data.price);
    data.append("trading_hours", JSON.stringify(tradingHours));
    data.append("primary_image", this.state.imageFiles[0] || "");
    data.append("sub_image_1", this.state.imageFiles[1] || "");
    data.append("sub_image_2", this.state.imageFiles[2] || "");
    data.append("sub_image_3", this.state.imageFiles[3] || "");
    data.append("sub_image_4", this.state.imageFiles[4] || "");
    data.append("sub_image_5", this.state.imageFiles[5] || "");
    data.append("inventory_status", this.state.data.inventoryStatus ? 1 : 0);
    this.props
      .createMyService(data)
      .then((response) => {
        const { data } = response.data;
        const { id: service_id } = data;
        if (this.state.staffs.length) {
          this.assignStaffsService(this.state.staffs, service_id);
        }
        if (this.state.options.length) {
          this.onAddOptions(this.state.options, service_id);
        } else {
          this.props.history.push(
            `/u/${formatRoute(this.props.userInfo.displayName)}/services`
          );
        }
      })
      .catch((error) => {
        const errors = {};
        errors.message =
          "Service: " +
          handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );

        this.setState({
          loading: false,
          errors,
        });
      });
  };

  renderGroupOptions = () => {
    const result = [];

    this.state.options.forEach(({ name, type, delt, childrens }, index) => {
      if (delt === "N") {
        result.push(
          <React.Fragment key={index}>
            <Pane className="col-lg-5">
              <Form.Field className="form-item">
                <label htmlFor="name" className="form-label">
                  <FormattedMessage
                    id="option_group_name"
                    defaultMessage="Name"
                  />
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder=""
                  className="form-input"
                  value={name}
                  onChange={(e) => this.onChangeOption(e, index)}
                />
              </Form.Field>
            </Pane>
            <Pane className="col-lg-5">
              <Form.Field className="form-item">
                <label htmlFor="name" className="form-label">
                  <FormattedMessage
                    id="option_group_type"
                    defaultMessage="Name"
                  />
                </label>
                <Select
                  value={type}
                  options={this.state.types}
                  name="type"
                  onChange={(e, { value }) => {
                    e.target.name = "type";
                    e.target.value = value;
                    this.onChangeOption(e, index);
                  }}
                />
              </Form.Field>
            </Pane>
            <Pane className="col-lg-2 align-items-center d-lg-flex">
              {name && type ? (
                <>
                  <Icon
                    name="trash alternate"
                    className="mt-4"
                    size="large"
                    onClick={() => this.handleDeleteGroupOption(index)}
                  />
                  <Icon
                    name="arrow alternate circle down"
                    className="mt-4"
                    size="large"
                    onClick={() => this.onChangeOrder(index, false)}
                  />
                  <Icon
                    name="arrow alternate circle up"
                    className="mt-4"
                    size="large"
                    onClick={() => this.onChangeOrder(index, true)}
                  />
                </>
              ) : null}
            </Pane>
          </React.Fragment>
        );

        childrens.forEach((children, indexChildren) => {
          if (children.delt === "N") {
            result.push(
              <React.Fragment>
                <Pane className="col-lg-1 align-items-center pl-5 justify-content-lg-center d-lg-flex mt-4">
                  <Form.Field>
                    {children.name && this.notEmptyNumber(children.price) ? (
                      type === 1 ? (
                        <Radio
                          name={"radio" + index}
                          value={indexChildren}
                          checked={children.isCheck}
                          onChange={(e) =>
                            this.onChangeRadio(index, indexChildren)
                          }
                        />
                      ) : (
                        <Checkbox
                          name={"check" + index}
                          checked={children.isCheck}
                          onChange={(e, { checked }) => {
                            this.onChangeCheckbox(
                              checked,
                              index,
                              indexChildren
                            );
                          }}
                        />
                      )
                    ) : null}
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-4">
                  <Form.Field className="form-item">
                    <label htmlFor="name" className="form-label">
                      <FormattedMessage
                        id="option_children_name"
                        defaultMessage="Name"
                      />
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder=""
                      className="form-input"
                      value={children.name}
                      onChange={(e) =>
                        this.onChangeChildren(e, index, indexChildren)
                      }
                    />
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-4">
                  <Form.Field className="form-item">
                    <label htmlFor="name" className="form-label">
                      <FormattedMessage
                        id="option_children_price"
                        defaultMessage="Price"
                      />
                    </label>
                    <input
                      type="number"
                      name="price"
                      placeholder=""
                      className="form-input"
                      value={children.price}
                      onChange={(e) =>
                        this.onChangeChildren(e, index, indexChildren)
                      }
                    />
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-3 align-items-center d-lg-flex">
                  {children.name && this.notEmptyNumber(children.price) ? (
                    <>
                      <Icon
                        name="trash alternate"
                        className="mt-4"
                        size="large"
                        onClick={() =>
                          this.handleDeleteChildOption(index, indexChildren)
                        }
                      />
                      <Icon
                        name="arrow alternate circle down"
                        className="mt-4"
                        size="large"
                        onClick={() =>
                          this.onChangeOrderChildren(
                            index,
                            indexChildren,
                            false
                          )
                        }
                      />
                      <Icon
                        name="arrow alternate circle up"
                        className="mt-4"
                        size="large"
                        onClick={() =>
                          this.onChangeOrderChildren(index, indexChildren, true)
                        }
                      />
                    </>
                  ) : null}
                </Pane>
              </React.Fragment>
            );
          }
        });
      }
    });

    return result;
  };

  onChangeOption = (e, index) => {
    const options = this.state.options;
    const option = options[index];

    option[e.target.name] = e.target.value;
    options[index] = option;

    if (e.target.name === "type" && e.target.value == 1) {
      const indexChil = option.childrens.findIndex((el) => !!el.isCheck);

      if (indexChil !== -1 && option.childrens) {
        option.childrens = option.childrens.map((o, index) => {
          if (index === indexChil) {
            return { ...o, isCheck: true };
          }

          return { ...o, isCheck: false };
        });
      }
    }

    this.setState({ options }, () => {
      this.autoAddOptionChildren(index);
    });
  };

  onChangeChildren = (e, indexOption, indexChildren) => {
    const { options } = this.state;
    const children = options[indexOption].childrens[indexChildren];
    children[e.target.name] = e.target.value;

    options[indexOption].childrens[indexChildren] = children;

    this.setState({ options }, () => {
      this.autoAddNextChildren(indexOption, indexChildren);
    });
  };

  createChildrenOption = (autoAddOptions = false) => {
    return { name: "", price: 0, delt: "N", autoAddOptions, isCheck: false };
  };

  createOption = (autoAddOptions = false) => {
    return {
      name: "",
      type: 1,
      autoAddOptions,
      delt: "N",
      childrens: [],
    };
  };

  handleDeleteChildOption = (index, indexChildren) => {
    const { options } = this.state;
    if (options[index].childrens[indexChildren].id) {
      options[index].childrens[indexChildren].delt = "Y";
    } else {
      options[index].childrens.splice(indexChildren, 1);
    }

    this.setState({ options });
  };

  onChangeRadio = (indexParent, indexChildren) => {
    const { options } = this.state;

    let { childrens } = options[indexParent];

    childrens = childrens.map((child, indexChil) => ({
      ...child,
      isCheck: indexChil === indexChildren,
    }));

    options[indexParent].childrens = childrens;

    this.setState({ options });
  };

  onChangeCheckbox = (checked, indexParent, indexChildren) => {
    const { options } = this.state;
    let { childrens } = options[indexParent];
    childrens[indexChildren].isCheck = checked;

    options[indexParent].childrens = childrens;
    this.setState({ options });
  };

  autoAddNextChildren = (index, indexChildren) => {
    const { options } = this.state;
    if (!options.length || !options[index]) return;

    const option = options[index];
    const { childrens } = option;

    const children = childrens[indexChildren];

    const isEnterPrice = this.notEmptyNumber(children.price);

    if (children.autoAddOptions && children.name && isEnterPrice) {
      childrens[indexChildren].autoAddOptions = false;
      childrens.push(this.createChildrenOption(true));
      options[index].childrens = childrens;
      this.setState({ options });
    }
  };

  notEmptyNumber = (number) => {
    return number !== null && number !== undefined && number !== "";
  };

  autoAddOptionChildren = (index) => {
    const options = this.state.options;
    if (!options.length || !options[index]) return;

    const option = options[index];
    const { autoAddOptions, name, type } = option;

    if (autoAddOptions && name && type) {
      const filters = option.childrens.filter((o) => !!o.isCheck);

      if (!filters || !filters.length) {
        option.childrens.push({
          ...this.createChildrenOption(true),
          isCheck: true,
        });
      } else {
        option.childrens.push(this.createChildrenOption(true));
      }

      option.autoAddOptions = false;
      options[index] = option;
      this.setState({ options });
    }
  };

  getOptions = () => {
    if (!this.state.options || !this.state.options.length) return [];

    const options = this.state.options.map((option) => {
      let { childrens } = option;

      childrens = childrens.filter((children) => children.delt === "N");
      option.childrens = childrens;

      return option;
    });
    const result = options.filter((option) => option.delt === "N");

    return result;
  };

  addGroupOption = () => {
    const options = [...this.state.options, this.createOption(true)];
    this.setState({ options });
  };

  handleDeleteGroupOption = (index) => {
    const { options } = this.state;

    let option = options[index];

    if (!option.id) {
      options.splice(index, 1);
    } else {
      option.delt = "Y";
      if (option.childrens && option.childrens.length) {
        option.childrens = option.childrens
          .map((child) => {
            if (child.id) {
              return { ...child, delt: "Y" };
            }

            return null;
          })
          .filter((child) => child);
      }

      options[index] = option;
    }

    this.setState({ options });
  };

  onChangeOrder = (index, up = true) => {
    const options = this.state.options;
    if (!options || !options.length) return;

    if (index < 0) return;
    if (index > options.length - 1) return;

    let nextIndex = index - 1;
    if (!up) nextIndex = index + 1;

    if (nextIndex < 0) return;
    if (nextIndex > options.length - 1) return;

    const currentOption = options[index];
    const nextOption = options[nextIndex];

    options[index] = nextOption;
    options[nextIndex] = currentOption;

    this.setState({ options });
  };

  onChangeOrderChildren = (indexGrp, index, up = true) => {
    const options = this.state.options;

    if (!options || !options.length) return;
    const { childrens } = options[indexGrp];
    if (!childrens || !childrens.length) return;

    let nextIndex = index - 1;
    if (!up) nextIndex = index + 1;

    if (nextIndex < 0) return;
    if (nextIndex > childrens.length - 1) return;

    const currentOption = childrens[index];
    const nextOption = childrens[nextIndex];

    childrens[index] = nextOption;
    childrens[nextIndex] = currentOption;

    options[indexGrp].childrens = childrens;
    this.setState({ options });
  };

  setImageCategory = (a) => {
    this.setState({
      imageCategory: a,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCategory(nextProps.categories);
    this.handleGroup();
  }

  nestPath = (data) => {
    if (!isEmpty(data)) {
      return data.map((item, index) => ({
        index,
        key: item.id,
        pId: item.id,
        title: item.name,
        value: item.id,
        children: this.nestPath(item.children),
      }));
    }
    return [];
  };

  onSelectTreeSelect = (value) => {
    this.setState((prevState) => ({
      data: { ...prevState.data, categoryId: value },
    }));
  };

  onSelectStaff = (id) => {
    if (this.state.staffs.includes(id)) {
      this.setState((prev) => ({
        staffs: prev.staffs.filter((staffId) => staffId !== id),
      }));
      return;
    }
    this.setState((prev) => ({
      staffs: [...prev.staffs, id],
    }));
  };

  render() {
    const { errors, data, loading, dataCategory, isVisibleApply } = this.state;
    const { intl } = this.props;
    const { results: staffs } = this.props.staffs;
    return (
      <Form className="form-overview pb-5" onSubmit={this.createService}>
        <Pane className="form-content">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field className="form-item">
                <label htmlFor="Image" className="form-label">
                  <FormattedMessage id="image" defaultMessage="Image" />
                </label>
                <input
                  type="file"
                  id="image-menu"
                  className="form-input-file"
                  multiple
                  value={data.image}
                  onChange={this.onChangeImage}
                />
                <br />
                <label htmlFor="image-menu" className="form-label-file">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/upload.svg`
                    }
                    alt="upload"
                    className="icon"
                  />
                  Upload a photo/video
                </label>
                <Pane
                  className="slider-content-main d-flex"
                  style={{ padding: "10px 0" }}
                >
                  {this.state.images &&
                    this.state.images.map((item, index) => {
                      if (item.indexOf("video") < 0) {
                        // Image
                        return (
                          <Pane className="slide-item mr-2" key={index}>
                            <Icon
                              name="remove circle"
                              onClick={this.removeImage.bind(this, index)}
                            />
                            <ImageS src={item} size="small" />
                          </Pane>
                        );
                      }
                      // Video
                      return (
                        <Pane
                          className="slide-item"
                          key={index}
                          style={{ float: "left" }}
                        >
                          <Icon
                            name="remove circle"
                            onClick={this.removeImage.bind(this, index)}
                          />
                          <video width="150" controls>
                            <source src={item} id="video_here" />
                            Your browser does not support HTML5 video.
                          </video>
                        </Pane>
                      );
                    })}
                </Pane>
              </Form.Field>
            </Pane>
          </Pane>
          {/* <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field className="form-item">
                <label htmlFor="staff-list" className="form-label">
                  <FormattedMessage id="staffList" defaultMessage="Staffs" />
                </label>
                <Pane className="service-staff-list">
                  {staffs &&
                    staffs.length &&
                    staffs.map((staff) => {
                      let url =
                        process.env.PUBLIC_URL +
                        "/assets/images/default-avatar.png";
                      if (staff.images && staff.images.length) {
                        const [image] = staff.images;
                        url = image.url;
                      }
                      return (
                        <Pane
                          className="staff-container"
                          onClick={() => this.onSelectStaff(staff.id)}
                        >
                          <Pane className="staff-image">
                            <img src={url} />
                          </Pane>
                          <Pane className="staff-info">{staff.fullname}</Pane>
                          {this.state.staffs.includes(staff.id) ? (
                            <Pane className="staff-check">
                              <Icon name="check" size="large" />
                            </Pane>
                          ) : null}
                        </Pane>
                      );
                    })}
                </Pane>
              </Form.Field>
            </Pane>
          </Pane> */}
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.name} className="form-item">
                <label htmlFor="name" className="form-label">
                  <FormattedMessage id="name" defaultMessage="Name" />
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder=""
                  className="form-input"
                  value={data.name}
                  onChange={this.onChange}
                />
                {errors.name && <InlineMessage text={errors.name} />}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.description} className="form-item">
                <label htmlFor="description" className="form-label">
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                </label>
                <ReactQuill
                  value={this.state.data.description}
                  onChange={(content) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        description: content,
                      },
                    }));
                  }}
                  formats={quill.formats}
                  modules={quill.modules}
                />
                {errors.description && (
                  <InlineMessage text={errors.description} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field className="form-item">
                <label htmlFor="categoryId" className="form-label">
                  <FormattedMessage
                    id="inventory_name"
                    defaultMessage="In stock"
                  />
                </label>
                <Pane className="rdt">
                  <Radio
                    toggle
                    checked={this.state.data.inventoryStatus}
                    onChange={() =>
                      this.setState({
                        data: {
                          ...this.state.data,
                          inventoryStatus: !this.state.data.inventoryStatus,
                        },
                      })
                    }
                  />
                </Pane>
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field>
                <Checkbox
                  radio
                  label={intl.formatMessage({
                    id: "select.category",
                    defaultMessage: "Select category",
                  })}
                  name="isNewCategory"
                  value={false}
                  checked={this.state.newCategory === false}
                  onChange={this.handleChangeCheckbox}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label={intl.formatMessage({
                    id: "select.category_add",
                    defaultMessage: "Add new category",
                  })}
                  name="isNewCategory"
                  value={true}
                  checked={this.state.newCategory === true}
                  onChange={this.handleChangeCheckbox}
                />
              </Form.Field>
            </Pane>
          </Pane>
          {this.state.newCategory === false ? (
            <Pane className="row">
              <Pane className="col-lg-12">
                <Form.Field className="form-item" error={!!errors.categoryId}>
                  <label htmlFor="categoryId" className="form-label">
                    <FormattedMessage
                      id="category"
                      defaultMessage="Unit Price"
                    />
                  </label>
                  <TreeSelect
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
                    placeholder={<i>Select</i>}
                    searchPlaceholder="Search..."
                    treeDefaultExpandAll
                    treeData={this.nestPath(this.props.categories.results)}
                    treeNodeFilterProp="title"
                    onChange={this.onMultipleChange}
                    onSelect={this.onSelectTreeSelect}
                    allowClear
                  />
                  {errors.categoryId && (
                    <InlineMessage text={errors.categoryId} />
                  )}
                </Form.Field>
              </Pane>
            </Pane>
          ) : (
            <>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    error={!!errors.category_name}
                    className="form-group"
                  >
                    <label htmlFor="category_name" className="form-label">
                      <FormattedMessage
                        id="category_name"
                        defaultMessage="Category Name"
                      />
                    </label>
                    <input
                      type="text"
                      id="category_name"
                      name="name"
                      placeholder=""
                      className="form-control"
                      value={dataCategory.name}
                      onChange={this.onChangeCategory}
                    />
                    {errors.category_name && (
                      <InlineMessage text={errors.category_name} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
              {/* <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    control={Select}
                    scrolling
                    className="form-item"
                    name="group"
                    error={!!errors.category_group}
                    label={intl.formatMessage({
                      id: "group",
                      defaultMessage: "Group",
                    })}
                    value={dataCategory.group}
                    options={this.state.groups}
                    placeholder={this.props.intl.formatMessage({
                      id: "select",
                      defaultMessage: "Select Category",
                    })}
                    onChange={this.onSelectGroup}
                  />
                </Pane>
              </Pane> */}
              <Form.Field>
                <Checkbox
                  radio
                  label={intl.formatMessage({
                    id: "select.image",
                    defaultMessage: "Select a image",
                  })}
                  name="newImageCategory"
                  value={false}
                  checked={this.state.newImageCategory === false}
                  onChange={this.handleChangeImageCategory}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label={intl.formatMessage({
                    id: "select.image_add",
                    defaultMessage: "Add new image",
                  })}
                  name="newImageCategory"
                  value={true}
                  checked={this.state.newImageCategory === true}
                  onChange={this.handleChangeImageCategory}
                />
              </Form.Field>
              {this.state.newImageCategory === false ? (
                <Form.Field className="form-item">
                  <label htmlFor="category_image" className="form-label">
                    <FormattedMessage
                      id="category_image"
                      defaultMessage="Category Image"
                    />
                  </label>
                  <SelectImage
                    valueComponent={createClass({
                      render() {
                        return (
                          <Pane
                            className="Select-value"
                            title={
                              this.props.option ? this.props.option.label : ""
                            }
                          >
                            <span className="Select-value-label">
                              {this.props.option && (
                                <img
                                  width="70px"
                                  height="50px"
                                  alt="selectImage"
                                  src={
                                    Config.NEW_API_URL + this.props.option.value
                                  }
                                />
                              )}
                              {this.props.children}
                            </span>
                          </Pane>
                        );
                      },
                    })}
                    options={this.state.default_images}
                    placeholder={intl.formatMessage({
                      id: "select.image",
                      defaultMessage: "Select a image",
                    })}
                    onChange={this.setImageCategory}
                    value={this.state.imageCategory}
                    optionComponent={createClass({
                      propTypes: {
                        children: PropTypes.node,
                        className: PropTypes.string,
                        isDisabled: PropTypes.bool,
                        isFocused: PropTypes.bool,
                        isSelected: PropTypes.bool,
                        onFocus: PropTypes.func,
                        onSelect: PropTypes.func,
                        option: PropTypes.object.isRequired,
                      },
                      handleMouseDown(event) {
                        event.preventDefault();
                        event.stopPropagation();
                        this.props.onSelect(this.props.option, event);
                      },
                      handleMouseEnter(event) {
                        this.props.onFocus(this.props.option, event);
                      },
                      handleMouseMove(event) {
                        if (this.props.isFocused) return;
                        this.props.onFocus(this.props.option, event);
                      },
                      render() {
                        return (
                          <Pane
                            className={this.props.className}
                            onMouseDown={this.handleMouseDown}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseMove={this.handleMouseMove}
                            title={this.props.option.label}
                          >
                            <img
                              width={
                                this.props.option.width
                                  ? this.props.option.width
                                  : "50px"
                              }
                              height={
                                this.props.option.height
                                  ? this.props.option.height
                                  : "50px"
                              }
                              alt="img"
                              src={Config.NEW_API_URL + this.props.option.value}
                            />
                          </Pane>
                        );
                      },
                    })}
                  />
                </Form.Field>
              ) : (
                <Form.Field className="form-group">
                  <label htmlFor="category_image">
                    <FormattedMessage
                      id="category_image"
                      defaultMessage="Category Image"
                    />
                  </label>
                  <input
                    type="file"
                    id="category_image"
                    name="category_image"
                    placeholder=""
                    className="form-control"
                    value={dataCategory.image}
                    onChange={this.onChangeCategoryImage}
                  />
                  <Pane
                    className="slider-content-main d-flex"
                    style={{ padding: "10px 0" }}
                  >
                    {this.state.category_images &&
                      this.state.category_images.map((item, index) => {
                        return (
                          <Pane className="slide-item mr-2" key={index}>
                            <Icon
                              name="remove circle"
                              onClick={this.removeCategoryImage.bind(
                                this,
                                index
                              )}
                            />
                            <ImageS src={item} size="small" />
                          </Pane>
                        );
                      })}
                  </Pane>
                </Form.Field>
              )}
            </>
          )}
          {errors.categoryId && <InlineMessage text={errors.categoryId} />}
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.duration} className="form-item">
                <label htmlFor="duration" className="form-label">
                  <FormattedMessage id="duration" defaultMessage="Duration" /> (
                  <FormattedMessage id="minutes" defaultMessage="Minutes" />)
                </label>
                <input
                  type="number"
                  id="duration"
                  name="duration"
                  placeholder=""
                  className="form-control"
                  disabled={true}
                  value={data.duration}
                  onChange={this.onChange}
                />
                <Button
                  className="btn-duration minus"
                  onClick={this.handleDuration.bind(this, "minus")}
                >
                  -
                </Button>
                <Button
                  className="btn-duration plus"
                  onClick={this.handleDuration.bind(this, "plus")}
                >
                  +
                </Button>
                {errors.duration && <InlineMessage text={errors.duration} />}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.price} className="form-item">
                <label htmlFor="price" className="form-label">
                  <FormattedMessage
                    id="unit_price"
                    defaultMessage="Unit Price"
                  />
                  (AUD)
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="price"
                  name="price"
                  placeholder=""
                  className="form-input"
                  value={data.price}
                  onChange={this.onChange}
                />
                {errors.price && <InlineMessage text={errors.price} />}
              </Form.Field>
            </Pane>
          </Pane>
          {/* <Pane className="row mt-2">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.tradingHour}>
                <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="tradingHours" className="form-label">
                    <FormattedMessage
                      id="trading_hours"
                      defaultMessage="Trading hours"
                    />
                  </label>
                  {isVisibleApply && (
                    <Button
                      type="button"
                      className="btn btn-save mt-4"
                      onClick={this.applyForAll}
                    >
                      Apply for all
                    </Button>
                  )}
                </div>
                <Pane className="trading-hours-list">
                  {Object.keys(this.state.tradingHour).map((key) => {
                    return (
                      <TradingHourItem
                        key={key}
                        textMonth={key}
                        from={this.state.tradingHour[key].from}
                        to={this.state.tradingHour[key].to}
                        off={this.state.tradingHour[key].off}
                        onChangeHours={this.onChangeHours}
                        onChangeToggle={this.onChangeToggle}
                        isApplyForAll={this.state.isApplyForAll}
                        type="create"
                      />
                    );
                  })}
                </Pane>
              </Form.Field>
            </Pane>
          </Pane> */}
          {errors.message && <InlineMessage text={errors.message} />}
          {errors.category_message && (
            <InlineMessage text={errors.category_message} />
          )}
          <Pane className="row">
            <Pane className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center">
                <label htmlFor="optionGroup" className="form-label">
                  <FormattedMessage
                    id="option_group"
                    defaultMessage="Group Option"
                  />
                </label>
                <Button
                  type="button"
                  className="btn btn-save mt-4"
                  onClick={this.addGroupOption}
                >
                  Add Group
                </Button>
              </div>
            </Pane>
            {this.renderGroupOptions()}
          </Pane>
        </Pane>
        <Pane className="row">
          <Pane className="col-lg-12 text-center">
            <Button
              type="submit"
              className="btn btn-save mt-4"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="create" defaultMessage="Create" />
            </Button>
          </Pane>
        </Pane>
      </Form>
    );
  }
}

export default injectIntl(CreateServiceForm);
