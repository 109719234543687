import { List } from '../list';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Pane, Loading } from 'shared/components/layouts';
import { withRouter, Link } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import {
  getProductsByUserId,
  getFoodCategoriesByUserId,
  deleteMyProduct,
} from 'actions/myProducts';
import _, { isEmpty } from 'lodash';
import update from 'immutability-helper';
import { formatRoute, handleError } from 'shared/helpers';
import { LoadingSearch } from 'components/common/loading';
import { ScrollMenu } from 'components/common/scrollMenu';
import {
  Form,
  Loader,
  Dimmer,
  Checkbox
} from "semantic-ui-react";
import {
  updateInfo,
} from "actions/user";
import { getUserInfo } from "actions/auth";
import {
  updateMyBusinessInfo,
} from "actions/myBusiness";

const MenuItem = ({ text, selected }) => {
  return <div className={`menu-item ${selected ? 'active' : ''}`}>{text}</div>;
};

// All items component
// Important! add unique key
export const Menu = (list, selected) => {
  return list.map((el) => {
    const { name } = el;
    return <MenuItem text={name} key={name} selected={selected} />;
  });
};

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
  text: (
    <img
      src={process.env.PUBLIC_URL + '/assets/images/icons/prev-tab.svg'}
      className="image-logo"
      alt="list-view"
    />
  ),
  className: 'arrow-prev',
});
const ArrowRight = Arrow({
  text: (
    <img
      src={process.env.PUBLIC_URL + '/assets/images/icons/next-tab.svg'}
      className="image-logo"
      alt="list-view"
    />
  ),
  className: 'arrow-next',
});
class MyMenu extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    products: PropTypes.object,
    getProductsByUserId: PropTypes.func,
    deleteMyProduct: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      data: [],
      totalPages: 0,
      selected: '',
      keySort: null,
      modeView: 'gridView',
      fetching: false,
      fetched: false,
      dataBusiness: {
        isGridView: 1,
      },
      loading: false
    };
    this._notificationSystem = null;
  }

  getProductsByUserId = () => {
    const { userInfo } = this.props;

    this.props
      .getProductsByUserId({
        id: userInfo.id,
        size: 1000,
        group: 'Menu',
        type: "Menu",
        images: this.state.modeView === 'gridView' ? '' : null,
      })
      .then(() => {
        this.onSetDefaultView(userInfo);
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  };

  deleteItem = (params) => {
    const { intl } = this.props;

    if (this.props.products.results.length === 1) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }

    this.props
      .deleteMyProduct(params)
      .then(() => {
        this.getProductsByUserId();
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });
      });
  };

  onSetDefaultView = (storeInfo) => {
    if (!isEmpty(storeInfo)) {
      this.setState({
        modeView: storeInfo.business.isGridView === 1 ? 'gridView' : 'listView',
      });
    }
  };

  componentWillUnmount() {
    this.unlisten();
  }

  async componentDidMount() {
    this.unlisten = this.props.history.listen(async () => {
      await this.getProductsByUserId();
    });

    await this.getProductsByUserId();

    this._notificationSystem = this.refs.notificationSystem;

    const { userInfo } = this.props;
    this.setState({
      dataBusiness: {
        ...this.state.dataBusiness,
        isGridView: userInfo.business.isGridView || 0,
      }
    })

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  onSubmit = async () => {
    const { intl, userInfo } = this.props;

    this.setState({ loading: true });

    const data = {
      id: userInfo.business.id,
      isGridView: this.state.dataBusiness.isGridView,
    };

    await this.props
      .updateMyBusinessInfo(data)
      .then((res) => {
        this.props.getUserInfo({ userId: userInfo.id })
        this.setState((prevState) => ({
          modeView: data.isGridView === 0 ? 'listView' : 'gridView',
          keySort: null,
        }));

        this.setState({ loading: false });
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: "notification.successfully" }),
          level: "success"
        });
      })
      .catch((errors) => {
        this.setState({ loading: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  onSelect = (key) => {
    this.setState({
      keySort: key,
    });
  };

  sortByName = (data) => {
    if (this.state.keySort) {
      const index = data.findIndex((item) => item.name === this.state.keySort);
      const dataNew = update(data, {
        $splice: [[index, 1], [0, 0, data[index]]],
      });
      return dataNew;
    }
    return data;
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === 'gridView' ? 'listView' : 'gridView',
      keySort: null,
    }));
  };

  handleChangeCheckbox = (e, { value }) => {
    const { intl } = this.props;
    let text = intl.formatMessage({ id: "confirm_show_items_type" });
    if (window.confirm(text) == true) {
      this.setState(prevState => ({
        dataBusiness: { ...prevState.dataBusiness, isGridView: value },
      }), () => {
        this.onSubmit();
      });
    } else {
      return false;
    }
  };

  render() {
    const { userInfo, products, intl } = this.props;
    const { selected, fetched, fetching, dataBusiness, loading } = this.state;
    const items = _.chain(products.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .sort(
        (a, b) =>
          new Date(_.get(b.items[0], 'category.createdAt')).getTime() / 1000 -
          new Date(_.get(a.items[0], 'category.createdAt')).getTime() / 1000
      )
      .value();

    return (
      <>
        <div className="overview-container" id="anchor">
          <div className="product-heading">
            <div className="col-lg-9 p-0 m-0">
              <h3 className="block-title pt10 mt-0 mr-0 text-uppercase title-width-header float-left">
                <FormattedMessage id="my_menu" defaultMessage="My Menu" />
              </h3>
              <div className="scroll-bar">
                <ScrollMenu
                  items={this.sortByName(items)}
                  onSelect={this.onSelect}
                  selected={selected}
                />
              </div>
            </div>
            <div className="col-lg-3 p-0 m-0">
              <Link
                to={`/u/${formatRoute(
                  userInfo.displayName
                )}/menu/create?group=menu`}
                className="btn btn-circle btn-circle-primary d-none d-lg-block"
              >
                <FormattedMessage
                  id="create_new_product"
                  defaultMessage="Create a new product"
                />
              </Link>
            </div>


          </div>
        </div>
        <div className="overview-container2">
          <div className="product-heading">
            <div className="default-view">
              {loading && (
                <Dimmer active inverted>
                  <Loading />
                </Dimmer>
              )}
              {!loading && (
                <Form>
                  <Pane className="row">
                    <Pane className="col-lg-12">
                      <Pane className="default-view__checkbox">
                        <Form.Field className="choose-item">
                          <Checkbox
                            radio
                            label={intl.formatMessage({
                              id: "choose_list_text",
                              defaultMessage: "List View",
                            })}
                            name="isGridView"
                            value={0}
                            checked={dataBusiness.isGridView === 0}
                            onChange={this.handleChangeCheckbox}
                          />
                        </Form.Field>
                        <Form.Field className="choose-item">
                          <Checkbox
                            radio
                            label={intl.formatMessage({
                              id: "choose_grid_text",
                              defaultMessage: "Grid View (have pictures)",
                            })}
                            name="isGridView"
                            value={1}
                            checked={dataBusiness.isGridView === 1}
                            onChange={this.handleChangeCheckbox}
                          />
                        </Form.Field>
                      </Pane>
                    </Pane>
                  </Pane>
                </Form>
              )}
            </div>
            <Pane className="view d-flex justify-content-end mt-2">
              <span className="list-view" onClick={this.onChangeModeView}>
                <img
                  src={
                    process.env.PUBLIC_URL + this.state.modeView === 'gridView'
                      ? '/assets/images/icons/list-view.svg'
                      : '/assets/images/icons/grid-view.svg'
                  }
                  className="image-logo"
                  alt="list-view"
                />
              </span>
            </Pane>
          </div>
        </div>
        <Link
          to={`/u/${formatRoute(
            userInfo.displayName
          )}/menu/create?group=menu`}
          className="btn btn-circle btn-circle-primary d-lg-none mb-2 w-100"
        >
          <FormattedMessage
            id="create_new_product"
            defaultMessage="Create new item"
          />
        </Link>
        <Pane className="product-container">
          {!fetching && fetched ? (
            <>
              {!isEmpty(this.sortByName(items)) &&
                this.sortByName(items).map((category) => {
                  if (isEmpty(category.items)) {
                    return <></>;
                  }
                  return (
                    <React.Fragment key={category.name}>
                      <Pane className="category-name"><strong>{category.name}</strong></Pane>
                      <Pane className="products-grids">
                        <List
                          data={category.items}
                          name={category.name}
                          type="menu"
                          control={true}
                          onDelete={this.deleteItem}
                          modeView={this.state.modeView}
                        />
                        <Pane className="clearfix" />
                      </Pane>
                    </React.Fragment>
                  );
                })}
            </>
          ) : (
            <LoadingSearch type="white" />
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  products: state.myProducts,
});

const bindDispatchToProps = (dispatch) => ({
  getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
  getFoodCategoriesByUserId: bindActionCreators(
    getFoodCategoriesByUserId,
    dispatch
  ),
  deleteMyProduct: bindActionCreators(deleteMyProduct, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  updateMyBusinessInfo: bindActionCreators(updateMyBusinessInfo, dispatch),
  updateUserInfo: bindActionCreators(updateInfo, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(MyMenu))
);
