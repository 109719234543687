import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { Comment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Config from 'constants/Config';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Moment from 'moment';
import { formatRoute } from 'shared/helpers'
import { InlineMessage } from 'shared/components';
import {
  Button,
  Rating,
  Form,
} from 'semantic-ui-react';

class ReviewsVideo extends Component {
  static propTypes = {
    onGetReviewsVideo: PropTypes.func,
    onNotification: PropTypes.object,
    intl: intlShape.isRequired,
    onReviewsVideo: PropTypes.func,
  };

  state = {
    detail: {},
    reviews: [],
    added: false,
    adding: false,
    rating: 0,
    comment: '',
    loading: false,
    pageLoading: false,
    open: false,
    showMoreDescription: false,
    showMoreComment: false,
    errors: {},
  };

  getDetail = () => {
    this.props.onGetReviewsVideo({
      id: this.props.id,
      type: Config.VIDEO_TYPE,
    });
  };

  onSubmit = () => {
    const { intl } = this.props;
    const data = {
      id: this.props.id,
      comment: this.state.comment,
      rating: this.state.rating,
    };

    if (!this.props.auth.user.id) {
      this.props.onOpenModal();
      return false;
    }
    let errors = {};    
    if (!this.state.comment) {
      errors.comment = intl.formatMessage({ id: 'validate.require_field' });
    } else {
      this.setState({ loading: true });
      this.props
        .onReviewsVideo(data)
        .then(() => {
          this.setState({
            loading: false,
            comment: '',
            rating: 0,
          });
          this.getDetail();
          this.props.onNotification.addNotification({
            message: intl.formatMessage({ id: 'notification.thank_for_review' }),
            level: 'success',
          });
        })
        .catch(() => {
          this.setState({ loading: false });
          this.props.onNotification.addNotification({
            message: intl.formatMessage({ id: 'notification.please_login_to_make_comments' }),
            level: 'error',
          });
        });
    }
    this.setState({ errors });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onRate = (e, { rating }) => {
    this.setState({ rating });
  };

  onClickShowMoreDescription = () => {
    this.setState({
      showMoreDescription: !this.state.showMoreDescription
    });
  };

  onClickShowMoreComment = () => {
    this.setState({
      showMoreComment: !this.state.showMoreComment
    });
  };
  
  componentDidMount() {
    this.getDetail();
    this._notificationSystem = this.refs.notificationSystem;
  }

  render() {
    const { reviews } = this.props;
    const { errors } = this.state;
    Moment.locale('en');
    const dataItem = this.props.videoData[0];
    const date = Moment(dataItem.updatedAt).format('MMM d,YYYY');
    const slug = dataItem && dataItem.user.business != null ? formatRoute(dataItem.user.business.name) : '';
    return (
      <Pane className="tab-details" style={{padding: 10}}>
        <Pane className="list-comment">
          {this.props.videoData.length > 0 && (
            <Link to={`/${slug}/videos/${dataItem.id}`}>
              <Pane className="user-video row">
                <Pane className="comment-avatar pr-2 pl-3">
                  <img
                    src={dataItem.user.image.url}
                    alt="ava1"
                    className="comment-img"
                    style={{ 
                      width: 45,
                      height: 45,
                      border: '1px solid red',
                      borderRadius: '25px',
                      backgroundColor: '#303130'
                    }}
                  />
                </Pane>
                <Pane className="user-content col-lg-8 col-md-8 col-sm-8 col-8 pl-0">
                  <p 
                    className="user-name mb-0 pl-0" 
                    style={{
                      fontWeight: 'bold',
                      fontSize: 19,
                      color: 'black'
                    }}>
                    {dataItem.user.fullname}
                  </p>
                  <p className="video-title mb-0 pl-0" style={{color: 'black'}}>{dataItem.title}</p>
                </Pane>
              </Pane>
            </Link>
          )}
          <Pane className="mt-2" style={{backgroundColor: 'gray', height: 1}}></Pane>
          {this.state.showMoreDescription === true && (
            <Scrollbars 
              style={{
                height: '140px',
                width: '100%'
                }}
              >
              {this.props.videoData.length > 0 && (
                <Pane className="video">
                  <Pane className="video-content">
                    <Pane className="video-item mt-2">
                      <p 
                        className="video-title mb-2 mr-1"
                        style={{
                        fontWeight: 'bold',
                        fontSize: 16
                      }}>
                        {dataItem.title}
                      </p>
                      <Pane className="video-date row pl-3" >
                        <span><b>{this.props.videoData[0].viewVideo} views&nbsp;&nbsp;•&nbsp;</b></span>
                        <em className='mr-2'>{date}</em>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                          '/assets/images/icons/like-gray.svg'
                          }
                          alt="image"
                          aria-hidden
                          className="icon mr-1"
                          style={{ 
                            cursor: 'pointer',
                            height: 18,
                            width: 18
                          }}
                        />
                        <p>{this.props.videoData[0].likeVideo !== 0 ? this.props.videoData[0].likeVideo : 'likes'}</p>
                      </Pane>
                    </Pane>
                    <Pane className="video-item row mt-2">
                      <p className="content" 
                        dangerouslySetInnerHTML={{
                        __html: dataItem.description,
                      }}/>
                    </Pane>
                  </Pane>
                </Pane>
              )}
            </Scrollbars>
          )}
          {this.state.showMoreDescription === false && (
            <Pane className="video" style={{ overflow: 'hidden' }}>
              {this.props.videoData.length > 0 && (
                <Pane className="video-content">
                  <Pane className="video-item mt-2">
                    <p className="video-title mb-2 mr-1" style={{fontWeight: 'bold', fontSize: 16,}}>{dataItem.title}</p>
                    <Pane className="video-date d-flex" >
                      <span><b>{this.props.videoData[0].viewVideo} views&nbsp;&nbsp;•&nbsp;</b></span>
                      <em className='mr-2'>{date}</em>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                        '/assets/images/icons/like-gray.svg'
                        }
                        alt="image"
                        aria-hidden
                        className="icon mr-1"
                        style={{ 
                          cursor: 'pointer',
                          height: 18,
                          width: 18
                        }}
                      />
                      <p>{this.props.videoData[0].likeVideo !== 0 ? this.props.videoData[0].likeVideo : 'likes'}</p>
                    </Pane>
                  </Pane>
                  <Pane className="video-item row mt-2">
                    <p className="content" 
                      dangerouslySetInnerHTML={{
                      __html: dataItem.description,
                    }}/>
                  </Pane>
                </Pane>
              )}
            </Pane>
          )}
          <p className="mb-0 d-flex justify-content-end mt-2" style={{cursor: 'pointer'}} onClick={this.onClickShowMoreDescription}>More..</p>
          <Pane className="mt-0" style={{backgroundColor: 'gray', height: 1}}></Pane>
          <h2 className="tab-title mb-0">Comment</h2>
          {this.state.showMoreComment === true && (
            <Scrollbars 
              style={{
                height: '100px',
                width: '100%'
                }}
              >
              {reviews && reviews.results.length > 0 ? (
                <Comment.Group className="mt-0">
                  {reviews.results.map((item,index) => {
                    return (
                      <Pane className="comment-item row mt-1" key={index}>
                        <Pane className="comment-avatar pr-2 pl-3">
                          <img
                            src={item.user.profileImage}
                            alt="ava1"
                            className="comment-img"
                            style={{ 
                              width: 35,
                              height: 35,
                              border: '1px solid red',
                              borderRadius: '25px',
                              backgroundColor: '#303130'
                            }}
                          />
                        </Pane>
                        <Pane className="comment-content col-lg-8 col-md-8 col-sm-8 col-8 pl-0">
                          <p className="comment-name mr-0 pl-0 mb-0" style={{fontWeight: 'bold'}}>{item.user.name}</p>
                          <p className="comment-norm  mb-0">{item.comment}</p>
                        </Pane>
                      </Pane>
                    );
                  })}
                </Comment.Group>
              ) : (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.no_comments"
                      defaultMessage="No comments"
                    />
                  </p>
                </Pane>
              )}
            </Scrollbars>
          )}
          {this.state.showMoreComment === false && (
            <Pane className="comment" style={{ width: '100%' }}>
              {reviews && reviews.results.length > 0 ? (
                <Comment.Group className="mt-0">
                  {reviews.results.map((item,index) => {
                    return (
                      <Pane className="comment-item row mt-1" key={index}>
                        <Pane className="comment-avatar pr-2 pl-3">
                          <img
                            src={item.user.profileImage}
                            alt="ava1"
                            className="comment-img"
                            style={{ 
                              width: 35,
                              height: 35,
                              border: '1px solid red',
                              borderRadius: '25px',
                              backgroundColor: '#303130'
                            }}
                          />
                        </Pane>
                        <Pane className="comment-content col-lg-8 col-md-8 col-sm-8 col-8 pl-0">
                          <p className="comment-name mr-0 pl-0 mb-0" style={{fontWeight: 'bold'}}>{item.user.name}</p>
                          <p className="comment-norm  mb-0">{item.comment}</p>
                        </Pane>
                      </Pane>
                    );
                  })}
                </Comment.Group>
              ) : (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.no_comments"
                      defaultMessage="No comments"
                    />
                  </p>
                </Pane>
              )}
            </Pane>
          )}
        </Pane>
        <p className="mb-0 d-flex justify-content-end mt-2" style={{cursor: 'pointer'}} onClick={this.onClickShowMoreComment}>More..</p>
        <Pane className="mt-0"
          style={{
            backgroundColor: 'gray',
            height: 1
          }}>
        </Pane>
        {/* <Rating
          rating={this.state.rating}
          icon="star"
          maxRating="5"
          size="large"
          onRate={this.onRate}
          className="mt-1"
        /> */}
        <Form onSubmit={this.onSubmit}>
          <Pane className="row">
            {this.props.auth.user.profileImage && (
              <Pane className="comment-form pr-0 pl-3 mt-1">
                <img
                  src={this.props.auth ? this.props.auth.user.profileImage : ''}
                  alt="ava1"
                  className="comment-img"
                  style={{ 
                    width: 35,
                    height: 35,
                    border: '1px solid red',
                    borderRadius: '25px',
                    backgroundColor: '#303130'
                  }}
                />
              </Pane>
            )}
            {this.props.auth.user.profileImage && (
              <Pane className="comment-form col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 mt-1 pr-0 pl-1">
                <Form.Field error={!!errors.comment} className="form-item">
                  <textarea
                    rows={3}
                    placeholder=""
                    name="comment"
                    className="form-input"
                    value={this.state.comment}
                    onChange={this.onChange}
                    style={{ width: '100%' }}
                  />
                  {errors.comment && <InlineMessage text={errors.comment} />}
                </Form.Field>
              </Pane>
            )}
            {!this.props.auth.user.profileImage && (
              <Pane className="comment-form col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 mt-1 pr-0 pl-3">
                <Form.Field error={!!errors.comment} className="form-item">
                  <textarea
                    rows={3}
                    placeholder=""
                    name="comment"
                    className="form-input"
                    value={this.state.comment}
                    onChange={this.onChange}
                    style={{ width: '100%' }}
                  />
                {errors.comment && <InlineMessage text={errors.comment} />}
                </Form.Field>
              </Pane>
            )}
            <Pane className="comment-form col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 pl-1">
              <Button
                size="tiny"
                disabled={this.state.loading}
                loading={this.state.loading}
                className="btn btn-circle-primary btn-comment mt-1"
                style={{color: '#fff'}}
              >
                <FormattedMessage id="send" defaultMessage="Send" />
              </Button>
            </Pane>
          </Pane>
        </Form>
      </Pane>
    );
  }
}

ReviewsVideo.propTypes = {};
export default withRouter(injectIntl(ReviewsVideo));
