import * as types from 'constants/ActionTypes'
const initialState = {
    user_area: {
        state: 'VIC',
        city: null
    },
    areaBusiness: {},
    areas: {},
    type: 'Food',
    error: null,
}

const area = (state = initialState, action) => {
    let { stateArea, cityArea } = action
    let data = {
        state: stateArea,
        city: cityArea
    }
    switch (action.type) {
        case types.RECEIVE_AREAS_BY_BUSINESS:
            return Object.assign({}, state, {
                fetching: true,
            });
        case types.RECEIVE_AREAS_BY_BUSINESS_SUCCESS:
            Object.assign(state, {
                areaBusiness: action.payload.data,
            });
            return { ...state };
        case types.RECEIVE_AREAS_BY_BUSINESS_FAILURE:
            return Object.assign(state, {
                areaBusiness: [],
                error: action.error,
            });
        case types.RECEIVE_AREAS:
            return Object.assign({}, state, {
                fetching: true,
            });
        case types.RECEIVE_AREAS_SUCCESS:
            Object.assign(state, {
                areas: action.payload.data,
            });
            return { ...state };
        case types.RECEIVE_AREAS_FAILURE:
            return Object.assign(state, {
                areas: [],
                error: action.error,
            });
        case types.ADD_USER_AREA:
            Object.assign(state.user_area, data);
            return { ...state };
        case types.ADD_TYPE:
            Object.assign(state, {
                type: action.searchType,
            });
            return { ...state };
        default:
            return state
    }
}

export default area;