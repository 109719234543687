import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { getMyVideostDetail, editMyVideo } from 'actions/myVideos';
import EditVideoForm from './EditVideoForm';
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';

class CreateProduct extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/my-videos`,
        name: 'Videos'
      },
      {
        url: ``,
        name: 'Edit video',
        active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="text-center mb-4" id="anchor">
        </Pane>
        <EditVideoForm
          userInfo={this.props.userInfo}
          id={this.props.match.params.videoId}
          editMyVideo={this.props.editMyVideo}
          getMyVideostDetail={this.props.getMyVideostDetail}
          history={this.props.history}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  editMyVideo: bindActionCreators(editMyVideo, dispatch),
  getMyVideostDetail: bindActionCreators(getMyVideostDetail, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(CreateProduct);
