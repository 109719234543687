import * as types from 'constants/ActionTypes'
import BookingAdapter from 'services/bookings/adapter'

const initialState = {
  results: [],
  error: null,
  totalItems: 0,
  totalPages: 0,
  fetching: false,
  fetched: false
}

const bookedServices = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BOOKED_SERVICES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_BOOKED_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        results: BookingAdapter.buyers.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: null,
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_BOOKED_SERVICES_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        totalItems: 0,
        totalPages: 0,
        results: [],
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

const bookingsForSeller = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BOOKINGS_FOR_SELLER:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_BOOKINGS_FOR_SELLER_SUCCESS:
      return Object.assign({}, state, {
        results: BookingAdapter.sellers.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: null,
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_BOOKINGS_FOR_SELLER_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        totalItems: 0,
        totalPages: 0,
        results: [],
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { bookedServices, bookingsForSeller }
