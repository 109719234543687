import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';

class Banner extends Component {
  render() {
    return <Pane className="about-more" />;
  }
}

export default Banner;
