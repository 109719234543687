import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { orderUserCart } from 'actions/cart';
import { Pane } from 'shared/components/layouts';
import { createDelivery } from 'actions/delivery';
import DeliveryServiceForm from './DeliveryServiceForm';

class DeliveryService extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    createDelivery: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Pane>
        <Pane className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="heading-title mt-2">Delivery</h3>
        </Pane>
        <DeliveryServiceForm
          history={this.props.history}
          userInfo={this.props.userInfo}
          orderUserCart={this.props.orderUserCart}
          createDelivery={this.props.createDelivery}
        />
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  createDelivery: bindActionCreators(createDelivery, dispatch),
  orderUserCart: bindActionCreators(orderUserCart, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(DeliveryService);
