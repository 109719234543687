import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { addItemToCart } from "actions/cart";
import BestMatchedItem from "./BestMatchedItem";
import { Pane } from "shared/components/layouts";
import { getAllProducts } from "actions/products";
import { LoadingAdds } from "components/common/loading";
import { formatJson, formatRoute, currentCart } from "shared/helpers";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import ProductAdapter from "services/products/adapter";

class BestMatched extends Component {
  static propTypes = {
    auth: PropTypes.object,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
    intl: intlShape.isRequired,
  };

  state = {
    added: false,
    adding: false,
    fetching: false,
    fetched: false,
    data: [],
  };

  componentDidMount() {
    let params = {};
    if (!isEmpty(this.props.data)) {
      params = {
        size: 6,
        store: get(this.props, "data[0].userId"),
      };
    }
    this.setState({
      fetched: false,
      fetching: true,
      data: {
        results: [],
      },
    });
    this.props
      .getAllProducts(params)
      .then((response) => {
        const products = ProductAdapter.browse.parseResponse(response.data);
        this.setState({
          fetched: true,
          fetching: false,
          data: products,
        });
      })
      .catch(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  }

  render() {
    const { fetched, fetching, data } = this.state;
    return (
      <Pane className="best_matched">
        <Pane className="heading-container mb-0 mb-lg-3 text-left">
          {/* <h3
            className="heading-title"
            style={{ fontSize: '18px', paddingBottom: 0 }}
          >
            <FormattedMessage id="add_to_order" defaultMessage="ADD TO ORDER" />
          </h3> */}
          <p className="heading-norm">
            <FormattedMessage
              id="choose_items_to_add_in_your_order"
              defaultMessage="Choose items to add in your order"
            />
          </p>
        </Pane>
        <Pane className="adds-block">
          {!fetching && fetched ? (
            <Pane className="row">
              {!isEmpty(data) &&
                currentCart(data, this.props.cart).map((item, index) => (
                  <BestMatchedItem
                    key={item.id}
                    item={item}
                    id={item.id}
                    name={item.name}
                    index={index}
                    image={item.primaryImage}
                    price={item.originalPrice}
                    rating={item.rating}
                    onAddItemToCart={this.props.onAddItemToCart}
                    onAddItemToGuestCart={this.props.onAddItemToGuestCart}
                    onNotification={this.props.onNotification}
                    auth={this.props.auth}
                    cartModal={this.props.cartModal}
                  />
                ))}
            </Pane>
          ) : (
            <LoadingAdds type="BESTMATCHED" />
          )}
        </Pane>
      </Pane>
    );
  }
}
BestMatched.propTypes = {
  getAllProducts: PropTypes.func.isRequired,
  products: PropTypes.object,
};

const bindStateToProps = (state) => ({
  products: state.products,
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  getAllProducts: bindActionCreators(getAllProducts, dispatch),
  onAddItemToCart: bindActionCreators(addItemToCart, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(BestMatched));
