// Buyers
import * as BookingBuyerBrowseV1 from './buyers/booking-buyer.browse.v1.action'
import * as BookingBuyerReadV1 from './buyers/booking-buyer.read.v1.action'
import * as BookingBuyerRequestCancelV1 from './buyers/booking-buyer.request-cancel.v1.action'
// Sellers
import * as BookingSellerReadV1 from './sellers/booking-seller.read.v1.action'
import * as BookingSellerBrowseV1 from './sellers/booking-seller.browse.v1.action'
import * as BookingSellerAcceptCancelV1 from './sellers/booking-seller.accept-cancel.v1.action'
import * as BookingSellerDenyCancelV1 from './sellers/booking-seller.deny-cancel.v1.action'
import * as BookingSellerChangeStatusV1 from './sellers/booking-seller.change-status.v1.action'

export default {
  buyers: {
    // Get list of buyer's bookings
    browse: {
      callAPI: params => BookingBuyerBrowseV1.exec(params),
      parseResponse: response => BookingBuyerBrowseV1.parseResponse(response)
    },
    // Get buyer's booking detail
    read: {
      callAPI: params => BookingBuyerReadV1.exec(params),
      parseResponse: response => BookingBuyerReadV1.parseResponse(response)
    },
    // Buyers - Request cancel a booking
    requestCancel: {
      callAPI: params => BookingBuyerRequestCancelV1.exec(params),
      parseResponse: response => BookingBuyerRequestCancelV1.parseResponse(response)
    }
  },
  sellers: {
    // Get list of seller's bookings
    browse: {
      callAPI: params => BookingSellerBrowseV1.exec(params),
      parseResponse: response => BookingSellerBrowseV1.parseResponse(response)
    },
    // Get seller's booking detail
    read: {
      callAPI: params => BookingSellerReadV1.exec(params),
      parseResponse: response => BookingSellerReadV1.parseResponse(response)
    },
    // Sellers - Accept cancel a booking
    acceptCancel: {
      callAPI: params => BookingSellerAcceptCancelV1.exec(params),
      parseResponse: response => BookingSellerAcceptCancelV1.parseResponse(response)
    },
    // Sellers - Deny cancel a booking
    denyCancel: {
      callAPI: params => BookingSellerDenyCancelV1.exec(params),
      parseResponse: response => BookingSellerDenyCancelV1.parseResponse(response)
    },
    // Sellers - Change status an order
    changeStatus: {
      callAPI: params => BookingSellerChangeStatusV1.exec(params),
      parseResponse: response => BookingSellerChangeStatusV1.parseResponse(response)
    }
  }
}
