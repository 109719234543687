import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MasterLayout } from 'components/layout';
import { Store } from 'components/scenes/store';
import { Pane } from 'shared/components/layouts';
import { getStoreInfo, getSlides } from 'actions/stores';
import { searchStores } from 'actions/search';
import BussinessList from 'components/common/bussiness/BussinessList';

class StorePage extends Component {
  static propTypes = {
    match: PropTypes.object,
    storeInfo: PropTypes.object,
    searchStores: PropTypes.func,
    getStoreInfo: PropTypes.func,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <BussinessList />
          <Pane className="business">
            <Store
              storeId={this.props.match.params.storeId}
              storeInfo={this.props.storeInfo}
              onGetStoreInfo={this.props.getStoreInfo}
              onSearchStores={this.props.searchStores}
              routes={this.props.route.routes}
              getSlides={this.props.getSlides}
              stores={this.props.stores}
              auth={this.props.auth}
            />
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = state => ({
  storeInfo: state.stores.info,
  stores: state.stores,
  auth: state.auth
});

const bindDispatchToProps = dispatch => ({
  getStoreInfo: bindActionCreators(getStoreInfo, dispatch),
  searchStores: bindActionCreators(searchStores, dispatch),
  getSlides: bindActionCreators(getSlides, dispatch),
});

export default connect(bindStateToProps, bindDispatchToProps)(StorePage);
