import React, { Component } from "react";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import { Button, Form, Image as ImageS } from "semantic-ui-react";
import { Pane } from "shared/components/layouts";
import { formatRoute, handleError, quill, formatJson } from "shared/helpers";
import InlineMessage from "shared/components/InlineMessage";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import Datetime from "react-datetime";
import moment from "moment";
class CreateProjectForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };
  state = {
    errors: {},
    loading: false,
    data: {
      name: "",
      description: "",
      startDate: "",
      endDate: "",
    },
  };
  validate = (data) => {
    const errors = {};
    const { intl } = this.props;
    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.startDate) {
      errors.startDate = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.endDate) {
      errors.endDate = intl.formatMessage({ id: "validate.require_field" });
    }
    if (
      moment(data.startDate, "YYYY-MM-DD").unix() >
      moment(data.endDate, "YYYY-MM-DD").unix()
    ) {
      errors.startDate = intl.formatMessage({
        id: "validate.invalid_compare_date",
      });
    }
    return errors;
  };
  editProject = async () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (errors && errors.length) return;
    this.setState({ loading: true });
    this.addProject();
  };
  addProject = () => {
    const { userInfo, id } = this.props;
    const dataProject = new FormData();
    dataProject.append("name", this.state.data.name);
    dataProject.append("description", this.state.data.description);
    dataProject.append("start_date", this.state.data.startDate);
    dataProject.append("end_date", this.state.data.endDate);
    dataProject.append("user_id", userInfo.id);
    dataProject.append("id", id);
    this.props
      .editMyProject(dataProject)
      .then((response) => {
        this.setState({
          loading: false,
          data: {
            ...this.state.data,
            name: "",
            description: "",
            startDate: "",
            endDate: "",
          },
        });
        this.props.history.push(
          `/u/${formatRoute(this.props.userInfo.displayName)}/projects`
        );
      })
      .catch((error) => {
        const errors = {};
        errors.message =
          "Project: " +
          handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );
        this.setState({
          loading: false,
          errors,
        });
      });
  };
  onChangeTime(name, value) {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value.format("YYYY-MM-DD"),
      },
    });
  }
  componentDidMount() {
    this.getProjectDetail();
  }
  getProjectDetail = () => {
    this.props.getProjectDetail({ id: this.props.id }).then((response) => {
      if (response && response.data) {
        const project = formatJson(
          response.data,
          response.data.data.type,
          response.data.data.id
        );
        this.setState({
          data: {
            ...this.state.data,
            name: project.name,
            description: project.description || "",
            startDate: moment(project.startDate, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            ),
            endDate: moment(project.endDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          },
        });
      }
    });
  };
  onChangeText = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  render() {
    const { errors, data, loading } = this.state;
    const { intl } = this.props;
    return (
      <Form className="form-overview pb-5" onSubmit={this.editProject}>
        <Pane className="form-content">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.name} className="form-item">
                <label htmlFor="name" className="form-label">
                  <FormattedMessage id="name" defaultMessage="Name" />
                </label>
                <input
                  maxLength={65}
                  className="form-input"
                  value={data.name}
                  onChange={(e) => this.onChangeText("name", e.target.value)}
                  type="text"
                  id="name"
                  name="name"
                />
                {errors.name && <InlineMessage text={errors.name} />}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.description} className="form-item">
                <label htmlFor="description" className="form-label">
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                </label>
                <ReactQuill
                  value={data.description}
                  onChange={(content) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        description: content,
                      },
                    }));
                  }}
                  formats={quill.formats}
                  modules={quill.modules}
                />
                {errors.description && (
                  <InlineMessage text={errors.description} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-6">
              <Form.Field error={!!errors.startDate} className="form-item">
                <label htmlFor="openTime" className="form-label">
                  <FormattedMessage
                    id="start_date"
                    defaultMessage="Start date"
                  />
                </label>
                <Datetime
                  closeOnSelect={true}
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false}
                  onChange={this.onChangeTime.bind(this, "startDate")}
                  inputProps={{ placeholder: "YYYY-MM-DD", name: "startDate", readOnly: true }}
                  value={data.startDate}
                />
                {errors.startDate && <InlineMessage text={errors.startDate} />}
              </Form.Field>
            </Pane>
            <Pane className="col-lg-6">
              <Form.Field error={!!errors.endDate} className="form-item">
                <label htmlFor="closeTime" className="form-label">
                  <FormattedMessage id="end_date" defaultMessage="End date" />
                </label>
                <Datetime
                  closeOnSelect={true}
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false}
                  onChange={this.onChangeTime.bind(this, "endDate")}
                  inputProps={{ placeholder: "YYYY-MM-DD", name: "endDate", readOnly: true }}
                  value={data.endDate}
                />
                {errors.endDate && <InlineMessage text={errors.endDate} />}
              </Form.Field>
            </Pane>
          </Pane>
        </Pane>
        <Pane className="row">
          <Pane className="col-lg-12 text-center">
            <Button
              type="submit"
              className="btn btn-save mt-4"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="edit" defaultMessage="Edit" />
            </Button>
          </Pane>
        </Pane>
      </Form>
    );
  }
}
export default injectIntl(CreateProjectForm);