import { List } from "../list";
import PropTypes from "prop-types";
import _, { isEmpty } from "lodash";
import { connect } from "react-redux";
import queryString from "query-string";
import React, { Component } from "react";
import update from "immutability-helper";
import { bindActionCreators } from "redux";
import { Empty } from "components/common/empty";
import { Pane, Loading } from "shared/components/layouts";
import { withRouter, Link } from "react-router-dom";
import { formatRoute, handleError } from "shared/helpers";
import { LoadingSearch } from "components/common/loading";
import { ScrollMenu } from "components/common/scrollMenu";
import NotificationSystem from "react-notification-system";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { getServicesByUserId, deleteMyService } from "actions/myServices";
import { Form, Loader, Dimmer, Checkbox } from "semantic-ui-react";
import { updateInfo } from "actions/user";
import { getUserInfo } from "actions/auth";
import { updateMyBusinessInfo } from "actions/myBusiness";
import { ServiceOptionModal } from "../../../../shared/components";

class MyService extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    services: PropTypes.object,
    getServicesByUserId: PropTypes.func,
    deleteMyService: PropTypes.func,
    intl: intlShape.isRequired,
    updateUserInfo: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      data: [],
      totalPages: 0,
      modeView: "gridView",
      fetched: false,
      fetching: false,
      selected: "",
      keySort: null,
      dataBusiness: {
        isGridView: 1,
      },
      loading: false,
      serviceDetail: {},
      openModalServiceDetail: false,
    };
    this._notificationSystem = null;
  }

  onCloseModalServiceDetail = () => {
    this.setState({ openModalServiceDetail: false, serviceDetail: {} });
  };

  onOpenModalServiceDetail = () => {
    this.setState({ openModalServiceDetail: true });
  };

  setServiceDetail = (data) => {
    this.setState((prev) => ({
      serviceDetail: { ...prev.serviceDetail, ...data },
    }));
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === "gridView" ? "listView" : "gridView",
      keySort: null,
    }));
  };

  getServicesByUserId = async () => {
    const { userInfo } = this.props;
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props
      .getServicesByUserId({
        id: userInfo.id,
        size: 1000,
        images: this.state.modeView === "gridView" ? "" : null,
      })
      .then(() => {
        this.onSetDefaultView(userInfo);
        this.setState({
          fetched: true,
          fetching: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  };

  onSetDefaultView = (storeInfo) => {
    if (!isEmpty(storeInfo)) {
      this.setState({
        modeView: storeInfo.business.isGridView === 1 ? "gridView" : "listView",
      });
    }
  };

  deleteItem = (params) => {
    const { intl } = this.props;

    if (this.props.services.results.length === 1) {
      this.setState((prevState) => ({ page: prevState.page - 1 }));
    }

    this.props
      .deleteMyService(params)
      .then(() => {
        this.getServicesByUserId();
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  handlePageClick = (data) => {
    this.setState(
      {
        page: data.selected + 1,
      },
      () => {
        this.props.history.push({
          search: `?page=${this.state.page}`,
        });
      }
    );
  };

  componentWillUnmount() {
    this.unlisten();
  }

  async componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getServicesByUserId();
    });

    this.getServicesByUserId();

    this._notificationSystem = this.refs.notificationSystem;

    const { userInfo } = this.props;
    this.setState({
      dataBusiness: {
        ...this.state.dataBusiness,
        isGridView: userInfo.business.isGridView || 0,
      },
    });
    
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  onSubmit = async () => {
    const { intl, userInfo } = this.props;

    this.setState({ loading: true });

    const data = {
      id: userInfo.business.id,
      isGridView: this.state.dataBusiness.isGridView,
    };

    await this.props
      .updateMyBusinessInfo(data)
      .then((res) => {
        this.props.getUserInfo({ userId: userInfo.id });
        this.setState((prevState) => ({
          modeView: data.isGridView === 0 ? "listView" : "gridView",
          keySort: null,
        }));

        this.setState({ loading: false });
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: "notification.successfully" }),
          level: "success",
        });
      })
      .catch((errors) => {
        this.setState({ loading: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  onSelect = (key) => {
    this.setState({
      keySort: key,
    });
  };

  sortByName = (data) => {
    if (this.state.keySort) {
      const index = data.findIndex((item) => item.name === this.state.keySort);
      const dataNew = update(data, {
        $splice: [
          [index, 1],
          [0, 0, data[index]],
        ],
      });
      return dataNew;
    }
    return data;
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === "gridView" ? "listView" : "gridView",
      keySort: null,
    }));
  };

  handleChangeCheckbox = (e, { value }) => {
    const { intl } = this.props;
    let text = intl.formatMessage({ id: "confirm_show_items_type" });
    if (window.confirm(text) == true) {
      this.setState(
        (prevState) => ({
          dataBusiness: { ...prevState.dataBusiness, isGridView: value },
        }),
        () => {
          this.onSubmit();
        }
      );
    } else {
      return false;
    }
  };

  render() {
    const { userInfo, services, intl } = this.props;
    const { fetching, fetched, dataBusiness, loading } = this.state;
    const items = _.chain(services.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .sort(
        (a, b) =>
          new Date(_.get(b.items[0], "category.createdAt")).getTime() / 1000 -
          new Date(_.get(a.items[0], "category.createdAt")).getTime() / 1000
      )
      .value();

    const { selected } = this.state;
    return (
      <Pane className="overview-container" id="anchor">
        <ServiceOptionModal
          openModal={this.state.openModalServiceDetail}
          onClose={this.onCloseModalServiceDetail}
          detail={this.state.serviceDetail}
        />
        <Pane className="product-container service-container">
          <div className="product-heading">
            <div className="col-lg-9 p-0 m-0">
              <h3 className="block-title pt10 mt-0 mr-0 text-uppercase title-width-header float-left">
                <FormattedMessage id="my_services" defaultMessage="My Services" />
              </h3>
              <div className="scroll-bar">
                <ScrollMenu
                  items={this.sortByName(items)}
                  onSelect={this.onSelect}
                  selected={selected}
                />
              </div>
            </div>
            <div className="col-lg-3 p-0 m-0">
              <Link
                to={`/u/${formatRoute(
                  userInfo.displayName
                )}/services/create?group=service`}
                className="btn btn-circle btn-circle-primary d-none d-lg-block pl-2"
              >
                <FormattedMessage
                  id="create_new_service"
                  defaultMessage="Create a new service"
                />
              </Link>
            </div>
          </div>
          <div className="overview-container2">
            <div className="product-heading">
              <div className="default-view">
                {loading && (
                  <Dimmer active inverted>
                    <Loading />
                  </Dimmer>
                )}
                {!loading && !isEmpty(userInfo.business) && (
                  <Form>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Pane className="default-view__checkbox">
                          <Form.Field className="choose-item">
                            <Checkbox
                              radio
                              label={intl.formatMessage({
                                id: "choose_list_text",
                                defaultMessage: "List View",
                              })}
                              name="isGridView"
                              value={0}
                              checked={dataBusiness.isGridView === 0}
                              onChange={this.handleChangeCheckbox}
                            />
                          </Form.Field>
                          <Form.Field className="choose-item">
                            <Checkbox
                              radio
                              label={intl.formatMessage({
                                id: "choose_grid_text",
                                defaultMessage:
                                  "Grid View (* recommend if you have many picture)",
                              })}
                              name="isGridView"
                              value={1}
                              checked={dataBusiness.isGridView === 1}
                              onChange={this.handleChangeCheckbox}
                            />
                          </Form.Field>
                        </Pane>
                      </Pane>
                    </Pane>
                  </Form>
                )}
                {!loading && <Pane></Pane>}
              </div>
              <Pane className="view d-flex justify-content-end mt-2">
                <span className="list-view" onClick={this.onChangeModeView}>
                  <img
                    src={
                      process.env.PUBLIC_URL + this.state.modeView ===
                      "gridView"
                        ? "/assets/images/icons/list-view.svg"
                        : "/assets/images/icons/grid-view.svg"
                    }
                    className="image-logo"
                    alt="list-view"
                  />
                </span>
              </Pane>
            </div>
          </div>
          <Link
            to={`/u/${formatRoute(
              userInfo.displayName
            )}/services/create?group=service`}
            className="btn btn-circle btn-circle-primary d-lg-none mb-2 w-100"
          >
            <FormattedMessage
              id="create_new_service"
              defaultMessage="Create a new service"
            />
          </Link>
          <Pane className="clearfix" />
          {!fetching && fetched ? (
            <>
              {isEmpty(items) && <Empty />}
              {!isEmpty(this.sortByName(items)) &&
                this.sortByName(items).map((category) => {
                  if (isEmpty(category.items)) {
                    return <></>;
                  }
                  return (
                    <React.Fragment key={category.name}>
                      <Pane className="category-name">
                        <strong>{category.name}</strong>
                      </Pane>
                      <List
                        data={category.items}
                        name={category.name}
                        type="services"
                        control={true}
                        onDelete={this.deleteItem}
                        modeView={this.state.modeView}
                        getServicesByUserId={this.props.getServicesByUserId}
                        userInfo={this.props.userInfo}
                        serviceModal={{
                          serviceDetail: this.state.serviceDetail,
                          openModal: this.onOpenModalServiceDetail,
                          setServiceDetail: this.setServiceDetail,
                        }}
                      />
                      <Pane className="clearfix" />
                    </React.Fragment>
                  );
                })}
            </>
          ) : (
            <LoadingSearch type="white" />
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  services: state.myServices,
});

const bindDispatchToProps = (dispatch) => ({
  getServicesByUserId: bindActionCreators(getServicesByUserId, dispatch),
  deleteMyService: bindActionCreators(deleteMyService, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  updateMyBusinessInfo: bindActionCreators(updateMyBusinessInfo, dispatch),
  updateUserInfo: bindActionCreators(updateInfo, dispatch),
});

export default withRouter(
  connect(bindStateToProps, bindDispatchToProps)(injectIntl(MyService))
);
