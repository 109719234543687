import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = () => {
  return {
    method: 'GET',
    url: `/contacts`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const userContacts = []
  if (response.data) {
    response.data.forEach(item => {
      const userContact = formatJson(response, item.type, item.id)
      userContacts.push({
        id: item.attributes.id,
        follower_id: item.attributes.followerId,
        type: item.attributes.type,
        user: {
          id: userContact.user && userContact.user.id,
          name: userContact.user && userContact.user.fullname,
          profileImage: userContact.user && userContact.user.image && userContact.user.image.url,
        }
      })
    })
  }

  return userContacts
};

export { exec, parseResponse };
