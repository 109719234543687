import React from 'react';
import queryString from 'query-string';
import { Pane } from 'shared/components/layouts';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

class SearchBox extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  state = {
    term: '',
    type: 'all',
  };

  onSelect = (e, s) => {
    this.setState({
      [s.name]: s.value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleKeyDown(evt) {
    const ENTER_KEY = 13;

    if (evt.keyCode === ENTER_KEY) {
      const term = evt.target.value.trim();
      if (term.length > 0) {
        this.setState({ term });
        this.props.history.push(
          `/search/?type=${this.state.type}&query=${encodeURIComponent(term)}`
        );
      }
    }
  }

  onSearch = () => {
    const { term } = this.state;
    this.props.history.push(
      `/search/?type=${this.state.type}&query=${encodeURIComponent(term)}`
    );
  };

  handleSearchInput = () => {
    this.setState({
      term: queryString.parse(this.props.location.search).query || '',
      type: queryString.parse(this.props.location.search).type || 'all',
    });
  };

  onChangeDropdown = (value) => {
    this.setState(
      {
        type: value,
      },
      () => {
        document.getElementById('search-dropdown').checked = false;
        document.getElementById('search-dropdown-responsive').checked = false;
        this.props.history.push(
          `/search/?type=${this.state.type}&query=${encodeURIComponent(
            this.state.term
          )}`
        );
      }
    );
  };

  componentDidMount() {
    this.handleSearchInput();
  }

  renderTitleDropdown = () => {
    const { intl } = this.props;
    if (this.state.type === 'all') return intl.formatMessage({ id: 'all' });
    if (this.state.type === 'stores')
      return intl.formatMessage({ id: 'stores' });
    if (this.state.type === 'menus')
      return intl.formatMessage({ id: 'my_menu' });
    if (this.state.type === 'products')
      return intl.formatMessage({ id: 'products' });
    if (this.state.type === 'services')
      return intl.formatMessage({ id: 'services' });
    // if (this.state.type === 'users')
    //   return intl.formatMessage({ id: 'users' });
    return null;
  };

  render() {
    const { intl } = this.props;
    const options = [
      {
        key: 1,
        value: 'all',
        text: intl.formatMessage({ id: 'all' }),
      },
      {
        key: 2,
        value: 'stores',
        text: intl.formatMessage({ id: 'stores' }),
      },
      {
        key: 3,
        value: 'menus',
        text: intl.formatMessage({ id: 'my_menu' }),
      },
      {
        key: 4,
        value: 'products',
        text: intl.formatMessage({ id: 'products' }),
      },
      {
        key: 5,
        value: 'services',
        text: intl.formatMessage({ id: 'services' }),
      },
      // {
      //   key: 6,
      //   value: 'users',
      //   text: intl.formatMessage({ id: 'users' }),
      // },
    ];

    return (
      <Pane className={this.props.class}>
        <Pane className="search-dropdown">
          <input
            className="search-checkbox"
            type="checkbox"
            id={this.props.id}
          />
          <label className="default-option" htmlFor={this.props.id}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/layouts/store.svg`}
              alt="store"
              className="icon-default"
            />
            {this.renderTitleDropdown()}
            <img
              src={`${
                process.env.PUBLIC_URL
              }/assets/images/layouts/arrow-1.svg`}
              alt="store"
              className="icon-default"
            />
          </label>
          <ul className="list-dropdown">
            {options.map((item) => {
              return (
                <li
                  key={item.key}
                  onClick={() => this.onChangeDropdown(item.value)}
                  className="item-dropdown"
                >
                  {item.text}
                </li>
              );
            })}
          </ul>
        </Pane>
        <Pane className="search-field">
          <input
            type="text"
            name="term"
            className="search-input"
            placeholder={intl.formatMessage({ id: 'search.im_looking_for' })}
            value={this.state.term}
            onChange={this.onChange}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/layouts/search.svg`}
            alt="store"
            className="icon-search"
            onClick={this.props.toggleSearch}
          />
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(SearchBox));
