import _ from 'lodash';
import Config from 'constants/Config'

const exec = params => {
  const type = params.type || ''
  const keyword = params.keyword || ''
  const country = params && params.country || 'aus'
  let search_area = params && params.state ? `&state=${params.state}` : ``;

  return {
    method: 'GET',
    url: `/search-quick?country=${country}${search_area}&name=${keyword}&type=${type}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }