import React, { PureComponent } from 'react';
import { Pane } from 'shared/components/layouts';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';

class Banner extends PureComponent {
  render() {
    return (
      <Pane className="business-container">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-6 offset-lg-3">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="heading-container text-lg-center text-left pb-4">
                  <h2 className="heading-title">
                    <FormattedMessage
                      id="build_your_online_store_no_matter_what_business_your_in"
                      defaultMessage="Build your online store, no matter what business you’re in."
                    />
                  </h2>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-3 col-md-6 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane
                  className="business-card"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL +
                      `/assets/images/landing/food&drink.png`})`,
                  }}
                >
                  <Pane className="business-footer">
                    <p className="business-norm">
                      <FormattedMessage
                        id="food_drink"
                        defaultMessage="FOOD & DRINK"
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-3 col-md-6 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane
                  className="business-card"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL +
                      `/assets/images/landing/spa&beauty.png`})`,
                  }}
                >
                  <Pane className="business-footer">
                    <p className="business-norm">
                      <FormattedMessage
                        id="spa_beauty"
                        defaultMessage="SPA & BEAUTY"
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-3 col-md-6 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane
                  className="business-card"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL +
                      `/assets/images/landing/handyman.png`})`,
                  }}
                >
                  <Pane className="business-footer">
                    <p className="business-norm">
                      <FormattedMessage
                        id="handyman_service"
                        defaultMessage="HANDYMAN SERVICE"
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-3 col-md-6 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane
                  className="business-card"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL +
                      `/assets/images/landing/import.png`})`,
                  }}
                >
                  <Pane className="business-footer">
                    <p className="business-norm">
                      <FormattedMessage
                        id="import_export_goods"
                        defaultMessage="IMPORT & EXPORT GOODS"
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default Banner;
