import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';

class Delivery extends Component {
  render() {
    return (
      <Pane className="delivery-container mt-5">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-6 offset-lg-3">
              <Pane className="heading-container text-center">
                <h3 className="heading-title">
                  <FormattedMessage
                    id="delivery_returns"
                    defaultMessage="Delivery & Returns"
                  />
                </h3>
                <p className="heading-norm">
                  <FormattedMessage
                    id="custom_static_block_for_product_detail"
                    defaultMessage="Custom static block for product detail"
                  />
                </p>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="row mt-4">
            <Pane className="col-lg-4 col-md-6 mt-2">
              <Pane className="delivery-item mt-2">
                <h2 className="delivery-title">
                  <FormattedMessage
                    id="free_shipping"
                    defaultMessage="FREE SHIPPING"
                  />
                </h2>
                <Pane className="delivery-content">
                  <img
                    src={
                      process.env.PUBLIC_URL + '/assets/images/details/ship.svg'
                    }
                    alt="suppor"
                    className="images delivery-img"
                  />
                  <span className="delivery-norm">
                    <FormattedMessage
                      id="free_shipping_on_all_us_order_or_order_above"
                      defaultMessage="Free shipping on all US order or order above $200"
                    />
                  </span>
                </Pane>
              </Pane>
            </Pane>
            <Pane className="col-lg-4 col-md-6 mt-2">
              <Pane className="delivery-item mt-2">
                <h2 className="delivery-title">
                  <FormattedMessage
                    id="support_24_7"
                    defaultMessage="SUPPORT 24/7"
                  />
                </h2>
                <Pane className="delivery-content">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/details/suppor.svg'
                    }
                    alt="suppor"
                    className="images delivery-img"
                  />
                  <span className="delivery-norm">
                    <FormattedMessage
                      id="contact_us_24_hours"
                      defaultMessage="Contact us 24 hours a day. 7 days a week"
                    />
                  </span>
                </Pane>
              </Pane>
            </Pane>
            {/* <Pane className="col-lg-3 col-md-6 mt-2">
              <Pane className="delivery-item mt-2">
                <h2 className="delivery-title">
                  <FormattedMessage
                    id="30_days_return"
                    defaultMessage="30 DAYS RETURN"
                  />
                </h2>
                <Pane className="delivery-content">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/details/return.svg'
                    }
                    alt="suppor"
                    className="images delivery-img"
                  />
                  <span className="delivery-norm">
                    <FormattedMessage
                      id="simply_return_it_within_30_days_for_an_exchange"
                      defaultMessage="Simply return it within 30 days for an exchange."
                    />
                  </span>
                </Pane>
              </Pane>
            </Pane> */}
            <Pane className="col-lg-4 col-md-6 mt-2">
              <Pane className="delivery-item mt-2">
                <h2 className="delivery-title">
                  <FormattedMessage
                    id="payment_secure"
                    defaultMessage="100% PAYMENT SECURE"
                  />
                </h2>
                <Pane className="delivery-content">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/details/payment.svg'
                    }
                    alt="suppor"
                    className="images delivery-img"
                  />
                  <span className="delivery-norm">
                    <FormattedMessage
                      id="we_ensure_secure_payment_with_pev"
                      defaultMessage="We ensure secure payment with PEV"
                    />
                  </span>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

Delivery.propTypes = {};

export default Delivery;
