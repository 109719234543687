import * as types from 'constants/ActionTypes'
import api from 'api'

export const getLikesVideo = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_LIKES, types.RECEIVE_LIKES_SUCCESS, types.RECEIVE_LIKES_FAILURE],
  payload: {
    request: api.likes.get(params)
  }
})

export const getUserLikesVideo = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_USER_LIKES, types.RECEIVE_USER_LIKES_SUCCESS, types.RECEIVE_USER_LIKES_FAILURE],
  payload: {
    request: api.likes.getUser(params)
  }
})

export const likesVideo = (params) => (dispatch) => dispatch({
  type: types.REVIEW_LIKE,
  payload: {
    request: api.likes.like(params)
  }
})
