import * as types from 'constants/ActionTypes'
import LikeAdapter from 'services/likes/adapter'

const initialState = {
  results: [],
  error: null,
  fetching: false,
  fetched: false
}

const likes = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_LIKES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_LIKES_SUCCESS:
      return Object.assign({}, state, {
        results: LikeAdapter.browse.parseResponse(action.payload.data),
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_LIKES_FAILURE:
      return Object.assign({}, state, {
        results: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

const userLike = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_USER_LIKES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_USER_LIKES_SUCCESS:
      return Object.assign({}, state, {
        results: LikeAdapter.browse.parseResponse(action.payload.data),
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_USER_LIKES_FAILURE:
      return Object.assign({}, state, {
        results: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { likes, userLike }
