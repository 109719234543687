import * as types from 'constants/ActionTypes'
import api from 'api'

// Products
export const getVideosByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_VIDEOS, types.RECEIVE_MY_VIDEOS_SUCCESS, types.RECEIVE_MY_VIDEOS_FAILURE],
  payload: {
    request: api.user.videos(params)
  }
})

// Video
export const getVideosByVideoId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_VIDEOS, types.RECEIVE_MY_VIDEOS_SUCCESS, types.RECEIVE_MY_VIDEOS_FAILURE],
  payload: {
    request: api.user.videoData(params)
  }
})

// Create a product
export const createMyVideo = (params) => (dispatch) => dispatch({
  type: types.CREATE_MY_VIDEO,
  payload: {
    request: api.user.createVideo(params)
  }
})

// Get product detail
export const getMyVideostDetail = (params) => (dispatch) => dispatch({
  type: types.MY_VIDEO_DETAIL,
  payload: {
    request: api.user.videoDetails(params)
  }
})

// Edit product detail
export const editMyVideo = (params) => (dispatch) => dispatch({
  type: types.EDIT_MY_VIDEO,
  payload: {
    request: api.user.editVideo(params)
  }
})

// Edit product detail
export const deleteVideo = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_VIDEO,
  payload: {
    request: api.user.deleteVideo(params)
  }
})

// Videos
export const getVideos = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_VIDEOS, types.RECEIVE_VIDEOS_SUCCESS, types.RECEIVE_VIDEOS_FAILURE],
  payload: {
    request: api.user.Allvideos(params)
  }
})

// View Videos
export const viewVideos = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_VIEW_VIDEOS,
  payload: {
    request: api.user.viewVideos(params)
  }
})

// Count Like Videos
export const addLikeVideo = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_COUNT_LIKE_VIDEOS,
  payload: {
    request: api.user.addLikeVideos(params)
  }
})