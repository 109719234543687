import * as types from 'constants/ActionTypes'
import api from 'api'

export const getProductsByStoreId = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_STORE_PRODUCTS,
  params,
  payload: {
    request: api.user.products(params)
  }
})

export const getServicesByStoreId = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_STORE_SERVICES,
  params,
  payload: {
    request: api.services.get(params)
  }
})

export const getStoreInfo = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_STORE_INFORMATION,
  params,
  payload: {
    request: api.stores.get({
      userId: params.store
    })
  }
})

export const getPromotionProductsByStoreId = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_STORE_PROMOTION_PRODUCTS,
  params,
  payload: {
    request: api.user.promotionProducts(params)
  }
})

export const getPromotionServicesByStoreId = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_STORE_PROMOTION_SERVICES,
  params,
  payload: {
    request: api.user.promotionServices(params)
  }
})

export const getSlides = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_STORE_SLIDES,
  params,
  payload: {
    request: api.user.slides(params)
  }
})
