import React, { Component } from "react";
import CartItem from "./CartItem";

class CartList extends Component {
  render() {
    const { cart, type, cartModal } = this.props;

    const cartList =
      cart &&
      cart.map((item, index) => {
        return (
          <CartItem
            onChangeType={this.props.onChangeType}
            cartModal={cartModal}
            key={item.id}
            item={item}
            index={index}
            type={type}
          />
        );
      });

    return (
      <table>
        <tbody>{cartList}</tbody>
      </table>
    );
  }
}

export default CartList;
