import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import ListItem from './ListItem';

class List extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { items } = this.props;
    const list = items.map((item) => {
      return (
        <ListItem
          key={item.id}
          item={item}
          type={this.props.screen_type}
          onLoading={this.onLoading}
          offLoading={this.offLoading}
          getMyDigitalShare={() => this.getMyDigitalShare()}
          editMyDigitalShare={this.props.editMyDigitalShare}
          deleteMyDigitalShare={this.props.deleteMyDigitalShare}
        />
      );
    });

    return (
      <Pane className="table-screens-share">
        <Table className="unstackable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Screen Name
              </Table.HeaderCell>
              <Table.HeaderCell>
                Screen price
              </Table.HeaderCell>
              <Table.HeaderCell>
                Host screen name
              </Table.HeaderCell>
              <Table.HeaderCell>
                File share
              </Table.HeaderCell>
              <Table.HeaderCell>
                View
              </Table.HeaderCell>
              <Table.HeaderCell>
                Status
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {list}
          </Table.Body>
        </Table>
      </Pane>
    );
  }
}

export default List;