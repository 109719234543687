import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Pane } from "shared/components/layouts";
import { injectIntl } from "react-intl";
import { Currency } from "shared/components";
class ListItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    item: PropTypes.object,
  };

  state = {
    activeIndex: 0,
    optionId: null,
  };

  isOptions = () => {
    if (!this.props.item.options) return false;
    if (!this.props.item.options.length) return false;

    return true;
  };

  convertRedirect = (value) => {
    if (this.props.type === "bussiness") {
      return `/${value}`;
    }
    return `/services/${value}`;
  };

  getOptions = () => {
    const { item } = this.props;
    return item.options || [];
  };

  includesOption = (id) => {
    return this.state.optionId === id;
  };

  updateOptionIds = (children, e) => {
    let id = children.id;
    let checked = e.target.checked;

    let isRemove = null;
    if (this.state.optionId && this.state.optionId !== id) {
      isRemove = this.state.optionId;
    }

    this.setState({ optionId: checked ? id : null }, () => {
      this.toggleCbx(checked, children, isRemove)
    });
  };

  toggleCbx = (checked, children = null, optionId = null) => {
    const { item } = this.props;
    if (checked && item) {
      let booking = {
        id: item.id,
        name: item.name,
        originalPrice: item.originalPrice,
        promotion: item.promotion,
        duration: item.duration,
        userId: item.userId
      }
      if (children) booking.children = children
      this.props.addStoreBookingsRequest(booking);
    }

    if ((checked && optionId) || !checked) {
      this.props.deleteStoreBookingsRequest(!checked ? item.id : optionId, children !== null);
    }
  }

  handlePrice = (originalPrice, promotion, childrenPrice) => {
    let price = originalPrice + childrenPrice
    if (promotion && promotion.percent) {
      price = originalPrice - (originalPrice * promotion.percent) / 100;
    }

    return price
  }

  isChecked = (id, isChild) => {
    const { items } = this.props;

    return items.length > 0 && items.find(e => (isChild && e.children ? e.children.id : e.id) === id)
  }

  componentWillReceiveProps = (nextProps) => {
    let item = nextProps.item;
    let items = nextProps.items;
    if (item && items) {
      let service = items.find(e => e.id === item.id);


      if (service && service.children) {
        this.setState({ optionId: service.children.id });
      }
    }
  };

  render() {
    const { item } = this.props;
    return (
      <Pane className="adds-item adds-list-view service-item">
        <Pane className="adds-content">
          <Pane className="service-content">
            <Link
              className=""
              to={
                this.props.type === "bussiness"
                  ? this.convertRedirect(item.slug)
                  : this.convertRedirect(item.id)
              }
            >
              <h5 className="service-item__name" title={item.name}>
                <b>{item.name}</b>
              </h5>
              <p className="service-item__duration">{item.duration * 60}min</p>
            </Link>
          </Pane>
          <Pane className="service-price">
            <Pane className="price-options">
              {this.getOptions().length > 0 ? 'from ' : ''}
              <Currency
                price={item.originalPrice}
              />
            </Pane>
          </Pane>
          {this.getOptions().length === 0 && (
            <Pane className="service-cbx">
              <Pane className="checkbox-wrapper-15">
                <input onChange={(e) => this.toggleCbx(e.target.checked)} value={item.id || ''} checked={this.isChecked(item.id, false) || false} className="inp-cbx hide" id={`cbx-${item.id}`} type="checkbox" />
                <label className="cbx" for={`cbx-${item.id}`}>
                  <span>
                    <svg width="12px" height="9px" viewBox="0 0 12 9">
                      <polyline points="1 5 4 8 11 1"></polyline>
                    </svg>
                  </span>
                </label>
              </Pane>
            </Pane>
          )}
        </Pane>
        <Pane
          className="service-item__desc"
          dangerouslySetInnerHTML={{
            __html: item.description,
          }}
        />
        {this.getOptions().length > 0 ? (
          <Pane className="sidebar-option-content">
            {this.getOptions().map((option) => {
              const { childrens, type, name } = option;

              const jsxOptDetails = childrens.map((children) => {
                let control = (
                  <input
                    type={"checkbox"}
                    id={children.id}
                    name={"checkbox" + "_" + option.name}
                    onChange={(e) => {
                      this.updateOptionIds(children, e);
                    }}
                    checked={this.isChecked(children.id, true) || false}
                  />
                );

                if (type === 1) {
                  control = (
                    <Pane className="checkbox-wrapper-15">
                      <input onChange={(e) => {
                        this.updateOptionIds(
                          children,
                          e
                        );
                      }} value={children.id || ''} name={"radio" + "_" + option.name} checked={this.isChecked(children.id, true) || false} className="inp-cbx hide" id={`child-${children.id}`} type="checkbox" />
                      <label className="cbx" for={`child-${children.id}`}>
                        <span>
                          <svg width="12px" height="9px" viewBox="0 0 12 9">
                            <polyline points="1 5 4 8 11 1"></polyline>
                          </svg>
                        </span>
                      </label>
                    </Pane>
                  );
                }

                return (
                  <Pane className="sidebar-option-item" key={children.id}>
                    <Pane className="option-control">
                      <Pane className="option-control-left">
                        <label className="service-item__name" htmlFor={children.name}><b>{children.name}</b></label>
                        <Pane className="price-options">
                          <Currency
                            price={this.handlePrice(item.originalPrice, item.promotion, children.price)}
                          />
                        </Pane>
                        {control}
                      </Pane>
                    </Pane>
                  </Pane>
                );
              });

              return (
                <Pane key={option.id} className={`sidebar-group-options`}>
                  <Pane className="sidebar-option-list">{jsxOptDetails}</Pane>
                </Pane>
              );
            })}
          </Pane>
        ) : null}
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
