import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BusinessLogoItem from './BussinessItem';
import { getBusinessCboosts } from 'actions/business';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage } from 'react-intl';

class BussinessList extends Component {
  renderList(businessLogos) {
    if (!businessLogos) {
      return;
    }
    const arrays = [
      {
        id: 1,
        name: <FormattedMessage id='home.promotions' />,
        url: '/assets/images/home/Promotion.svg',
        link: 'promotions',
      },
      // {
      //   id: 2,
      //   name: <FormattedMessage id='home.trending' />,
      //   url: '/assets/images/home/trending.svg',
      //   link: '/',
      // },
      // {
      //   id: 3,
      //   name: <FormattedMessage id='giftcards' />,
      //   url: '/assets/images/home/giftcard.svg',
      //   link: '/',
      // },
      {
        id: 4,
        name: <FormattedMessage id='best_picks' />,
        url: '/assets/images/home/bestpick.svg',
        link: 'product-picks',
      },
      {
        id: 5,
        name: <FormattedMessage id='just_listed' />,
        url: '/assets/images/home/newcoming.svg',
        link: 'just-listed',
      },
      // {
      //   id: 6,
      //   name: <FormattedMessage id='oppshop' />,
      //   url: '/assets/images/home/oppshop.svg',
      //   link: '/',
      // },
    ];
    return arrays.map((item) => {
      return (
        <BusinessLogoItem
          key={item.id}
          id={item.id}
          auth={this.props.auth}
          business={item}
        />
      );
    });
  }

  render() {
    return (
      <Pane className="bussiness-tab">
        {this.renderList(this.props.businessLogos.results)}
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  businessLogos: state.businessLogos,
});

const mapDispatchToProps = (dispatch) => ({
  getBusinessCboosts: bindActionCreators(getBusinessCboosts, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BussinessList);
