const exec = params => {
  return {
    method: 'GET',
    url: `/Promotion/GetAllPromotionServicesByUserId/${params.id}`,
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
