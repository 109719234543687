import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { ContactUsForm } from 'components/scenes/contact-us';
import { contact } from 'actions/contact';
import { Pane } from 'shared/components/layouts';

class ContactUsPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    contact: PropTypes.func.isRequired,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="contact-container">
          <Pane className="contact-more" />
          <Pane className="contact-content">
            <Pane className="container">
              <Pane className="row">
                <Pane className="col-lg-6 pr-lg-5 pl-lg-5 pr-2 pl-2 mt-3">
                  <h2 className="contact-title">Contact Us</h2>
                  <Pane className="contact-profile">
                    <span className="profile-name">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/stores/location.svg`
                        }
                        alt="location"
                        className="icon"
                      />
                      Melbourne Victoria. Australia
                    </span>
                    {/* <span className="profile-name">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/stores/phone.svg`
                        }
                        alt="location"
                        className="icon"
                      />
                      +6145 1867 365
                    </span>
                    <span className="profile-name">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/stores/mail.svg`
                        }
                        alt="location"
                        className="icon"
                      />
                      productsource@cardbey.com
                    </span> */}
                  </Pane>
                </Pane>
                <Pane className="col-lg-6 pr-lg-5 pl-lg-5 pr-2 pl-2 mt-3">
                  <h2 className="contact-title">Get In Touch</h2>
                  <ContactUsForm contact={this.props.contact} />
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  contact: bindActionCreators(contact, dispatch),
});

export default connect(
  null,
  bindDispatchToProps
)(ContactUsPage);
