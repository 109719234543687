import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import { Currency } from "shared/components";
import { Button } from "semantic-ui-react";
import CartItem from "./CartItem";
import { get } from "lodash";
import { withRouter } from "react-router-dom";

class FloatCart extends Component {
  handleTotal = () => {
    let total = 0;
    this.props.cart.map((item) => {
      const { options, optionInfo } = item;
      let totalPriceOp = 0;

      if (options && options.length) {
        options.filter((option) => {
          const { childrens } = option;
          childrens.find((child) => {
            const selected = optionInfo.find((o) => o.id === child.id);
            if (selected) {
              totalPriceOp += Number(child.price) * Number(selected.quantity);
            }
          });
        });
      }

      total += (item.promotionPrice + totalPriceOp) * item.quantity;
    });

    return total;
  };

  render() {
    const { cart, storeInfo } = this.props;

    const cartList =
      cart &&
      cart.map((item, index) => {
        return (
          <CartItem
            key={item.id}
            item={item}
            index={index}
            cartModal={this.props.cartModal}
          />
        );
      });

    return (
      <Pane className="float_cart">
        <Pane className="block_cart">
          <Pane className="store_info">
            <Pane className="title">
              <Pane className="title_head">
                <FormattedMessage
                  id="store_order"
                  defaultMessage="STORE'S ORDER"
                ></FormattedMessage>
              </Pane>
              <span className="store_name">
                {storeInfo.business ? storeInfo.business.name : ""}
              </span>
              <Pane className="title_item">
                {cart.length ? cart.length : 0}
                {` ITEM${cart.length > 1 ? "s" : ""}`}
              </Pane>
            </Pane>
            <img
              src={
                get(storeInfo, "profileImage") ||
                process.env.PUBLIC_URL + "/assets/images/user.jpg"
              }
              alt="logo"
              className="logo_store"
            />
          </Pane>
          {cart.length > 0 ? (
            <Pane className="items">{cartList}</Pane>
          ) : (
            <Pane className="empty-container">
              <Pane className="empty-content">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/empty.png"
                  }
                  alt="empty"
                  className="img-empty"
                />
                <h4 className="empty-title">
                  {storeInfo.business && storeInfo.business.type == "Food" ? (
                    <Pane>
                      The food in restaurant is very delicious <br />
                      Let's order some items from the menu
                    </Pane>
                  ) : (
                    <Pane>Your cart is empty, let's shopping</Pane>
                  )}
                </h4>
              </Pane>
            </Pane>
          )}
          {cart.length > 0 && (
            <Pane className="total">
              <Pane className="total_title">
                <FormattedMessage
                  id="total"
                  defaultMessage="TOTAL"
                ></FormattedMessage>
                :
              </Pane>
              <Pane className="total_price">
                <Currency price={this.handleTotal()} />
              </Pane>
            </Pane>
          )}
        </Pane>
        {cart.length > 0 && (
          <Button
            onClick={() => this.props.history.push("/cart")}
            className="btn"
          >
            <FormattedMessage
              id="view_cart"
              defaultMessage="VIEW CART"
            ></FormattedMessage>
          </Button>
        )}
      </Pane>
    );
  }
}

export default withRouter(FloatCart);
