import _ from "lodash";
import Menu from "./Menu";
import PropTypes from "prop-types";
import { RenderRoutes } from "routes";
import React, { Component } from "react";
import { Loader } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import BannerIntro from "./banner/BannerIntro";
import { Pane, Loading } from "shared/components/layouts";
import UserInfo from "./overview/UserInfo";
import { formatJson, isNumberic } from "shared/helpers";
import UserAdapter from "services/users/adapter";
import queryString from "query-string";
class User extends Component {
  static propTypes = {
    storeId: PropTypes.any,
    storeInfo: PropTypes.object,
    onGetStoreInfo: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      tableId: queryString.parse(search).tableId || null,
      isShowQRCode: queryString.parse(search).isShowQRCode || false,
      slides: {},
      products: {},
      services: {},
      storeInfo: {},
      foodCategories: [],
      fetchedStore: false,
      fetchedSlider: false,
      fetchingStore: false,
      serviceCategories: [],
      promotionProducts: {},
      promotionServices: {},
      fetchingSlider: false,
      fetchingProducts: false,
      fetchingServices: false,
      isVideo: this.props.location.pathname.search("/videos") > 0,
      toggleMenu: false,
      height: 0,
    };
  }

  componentDidMount() {
    this.getStoreByName();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      height: window.innerHeight,
    });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        isVideo: this.props.location.pathname.search("/videos") > 0,
      });
    }
  }

  handleScroll = () => {
    this.setState(
      {
        height: window.innerHeight,
      },
      () => {
        if (window.innerWidth < 992) {
        }
      }
    );
  };

  searchStores = (storeId, redirect) => {
    this.setState({
      fetchingStore: true,
      fetchedStore: false,
    });
    this.props.onSearchStores({ keyword: storeId }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        const store = formatJson(
          response.data,
          response.data.data[0].type,
          response.data.data[0].id
        );
        if (store && store.user.id) {
          this.setState(
            {
              storeId: store.user.id,
            },
            () => {
              this.props
                .onGetStoreInfo({ store: this.state.storeId })
                .then(() => {
                  this.setState(
                    {
                      storeInfo: this.props.storeInfo[this.state.storeId],
                      fetchingStore: false,
                      fetchedStore: true,
                    },
                    () => {
                      this.getStoreSlides();
                      if (redirect) {
                        this.props.history.replace(
                          `/${this.state.storeId}`
                        );
                      }
                    }
                  );
                });
            }
          );
        } else {
          this.setState(
            {
              fetchingStore: false,
              fetchedStore: true,
            },
            () => {
              this.props.history.replace(`/page-not-found`);
            }
          );
        }
      } else {
        this.setState(
          {
            fetchingStore: false,
            fetchedStore: true,
          },
          () => {
            this.props.history.replace(`/page-not-found`);
          }
        );
      }
    });
  };

  getStoreByName = () => {
    const storeId = this.props.storeId.replace(
      /[/\\#,\-+_()$~%.'":*?<>{}]/g,
      " "
    );
    const { tableId, isShowQRCode } = this.state;
    if (isNumberic(storeId)) {
      this.props
      .onGetStoreInfo({ store: storeId })
      .then((response) => {
        const store = UserAdapter.read.parseResponse(response.data);
        if (store.business.is_active == 0) {
          this.setState(
              {
                storeInfo: store,
                fetchingStore: false,
                fetchedStore: true,
                storeId,
              },
              () => {
                this.getStoreSlides();
                if (tableId) {
                  this.props.history.replace(
                    `/${storeId}/menu?tableId=${tableId}`
                  );
                } else if (isShowQRCode) {
                  this.props.history.replace(`/users/${storeId}?isShowQRCode=true`);
                }
              }
            );
          } else {
            this.setState(
              {
                storeInfo: store,
                fetchingStore: false,
                fetchedStore: true,
                fetchedSlider: true,
                fetchingSlider: false,
              },
              () => {this.props.history.replace(`/page-not-found`);}
            );
          }
        })
        .catch((error) => {
          this.props.history.replace(`/page-not-found`);
        });
    } else {
      this.searchStores(storeId, false);
    }
  };

  getStoreSlides = () => {
    const { storeId } = this.state;
    this.setState({
      fetchedSlider: false,
      fetchingSlider: true,
    });
    this.props.getSlides({ store: storeId }).then(() => {
      this.setState({
        slides: this.props.stores.slides[storeId],
        fetchedSlider: true,
        fetchingSlider: false,
      });
    });
  };

  onChangeToggleMenu = () => {
    this.setState((prevState) => ({
      toggleMenu: !prevState.toggleMenu,
    }));
  };

  render() {
    const {
      storeInfo,
      toggleMenu,
      fetchedStore,
      fetchedSlider,
      fetchingStore,
      fetchingSlider,
    } = this.state;

    return (
      <>
        {fetchedSlider && !fetchingSlider ? (
          <BannerIntro storeInfo={storeInfo} />
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}
        <Pane className="stores-container">
          {fetchedStore && !fetchingStore && storeInfo.business.is_active == 0 && (
            <Pane className="heading-container" id="info-container">
              <UserInfo
                isShowQRCode={this.state.isShowQRCode}
                storeInfo={storeInfo}
                onChangeToggleMenu={this.onChangeToggleMenu}
              />
            </Pane>
          )} {!fetchedStore && fetchingStore && (
              <Pane className="pt30">
                <Loading />
              </Pane>
          )}
          <Pane className="product-container mt-5">
            <Pane className="container">
              {!_.isEmpty(storeInfo) && (
                <Pane className="row">
                  <Pane className="col-lg-3 col-md-12">
                      <Menu
                        storeId={this.props.storeId}
                        storeInfo={storeInfo}
                        toggleMenu={toggleMenu}
                      />
                  </Pane>
                  <Pane className="col-lg-9 col-md-12">
                    <RenderRoutes
                      routes={this.props.routes}
                      storeInfo={storeInfo}
                      />
                  </Pane>
                </Pane>
              )}
            </Pane>
          </Pane>
        </Pane>
      </>
    );
  }
}

export default withRouter(User);
