import React from "react";

const StockDetailLabel = ({ inStock, type }) => {
  let label = "";

  switch (type) {
    case "products":
      label = inStock ? "In Stock" : "Out Of Stock";
      break;
    case "services":
      label = inStock ? "Available" : "Unavailable";
      break;
    default:
      return <></>;
  }

  let html = <h5 className="category-price category-in-stock">{label}</h5>;
  if (!inStock) html = <h5 className="category-price category-out-stock">{label}</h5>;

  return <>{html}</>;
};

StockDetailLabel.defaultProps = {
  inStock: false,
};

export default StockDetailLabel;
