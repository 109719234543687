import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const parent_id = params.parent_id || null;
  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/categories?type=Food&parent_id=${parent_id}`,
  };
};

const parseResponse = (response) => {
    const data = [];
    if (response.data) {
      response.data.forEach((item) => {
        const service = formatJson(response, item.type, item.id);
        data.push(service);
      });
    }
    return data;
};

export { exec, parseResponse };
