import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import PropTypes from 'prop-types';

class BannerItem extends Component {
  static propTypes = {
    onViewVideo: PropTypes.func,
  };

  onPlay = (second) => {
    this.props.onViewVideo({
      id: this.props.items[0].id,
      view_video: this.props.items[0].viewVideo + 1,
    })
  };

  renderItem(index) {
    if (this.props.items && this.props.items.length > index) {
      return (
        <>
          <video
            src={this.props.items[index].video.url}
            ref="videoPlayer"
            controls
            className="inner-img"
            style={{
              backgroundColor: 'black', 
              borderRadius: 0
            }}
            onPlay={this.onPlay}
          />
        </>
      );
    }
    return '';
  }

  renderItemResponsive() {
    const { items } = this.props;
    if (items) {
      return (
        <video
          src={items.video.url}
          ref="videoPlayer"
          controls
          className="inner-img"
          style={{
            backgroundColor: 'black',
            borderRadius: 0
          }}
          onPlay={this.onPlay}
        />
      );
    }
    return '';
  }

  render() {
    if (this.props.device !== 'responsive') {
      if (this.props.items.length === 4) {
        return (
          <Pane className="slider-item-video">
            <Pane className="inner">{this.renderItem(0)}</Pane>
            <Pane className="inner inner-row">
              <Pane className="inner-children">{this.renderItem(1)}</Pane>
              <Pane className="inner-children">{this.renderItem(2)}</Pane>
            </Pane>
          </Pane>
        );
      }
      if (this.props.items.length === 2 || this.props.items.length === 3) {
        return (
          <Pane className="slider-item-video">
            <Pane className="inner inner-first">{this.renderItem(0)}</Pane>
            <Pane className="inner inner-two">{this.renderItem(1)}</Pane>
          </Pane>
        );
      }
      if (this.props.items.length === 1) {
        return (
          <Pane className="slider-item-video">
            <Pane className="inner inner-only pr-0">{this.renderItem(0)}</Pane>
          </Pane>
        );
      }
      return null;
    }
    return <Pane className="slider-item">{this.renderItemResponsive()}</Pane>;
  }
}

export default BannerItem;
