import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import { Image } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { formatDecimal } from 'shared/helpers';

class OrderedCartItem extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  state = {
    data: this.props.data || {},
  };

  isOptions = (item) => {
    if (!item.options) return false;
    if (!item.options.length) return false;

    return true;
  };

  includesOption = (id, item) => {
    const { optionInfo } = item;
    if (!isEmpty(optionInfo)) {
      return optionInfo.find((child) => child.id === id);
    }
  };

  getSelectedOptions = (item) => {
    if (!this.isOptions(item)) return [];

    const { options } = item;
    let selectdOptions = [];

    if (!isEmpty(options)) {
      options.map((option) => {
        const { childrens } = option;
        childrens.forEach((child) => {
          if (this.includesOption(child.id, item)) {
            selectdOptions.push({
              ...child,
              quantity: this.includesOption(child.id, item).quantity,
            });
          }
        });
      });
    }

    return selectdOptions;
  };

  mappingSelectOptionToText = (item) => {
    const selectedOptions = this.getSelectedOptions(item);
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${child.name
          }`
      )
      .join(", ");
  };

  render() {
    const { data } = this.props;

    return (
      <>
        <Pane className="order-item">
          <Pane className="order-item__body">
            <Pane className="order-item__info">
              <Image src={data.primaryImage} size="tiny" />
              <Pane>
                <Pane className="d-flex">
                  <Pane className="order-item__info-quantity">x {data.quantity}</Pane>
                  <Pane className="order-item__info-name">
                    {data.name}
                    {this.isOptions(data) ? (
                      <Pane className="view-options">
                        <em>
                          <h5
                            className="p-0"
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                          >
                            &nbsp; - {this.mappingSelectOptionToText(data)}
                          </h5>
                        </em>
                      </Pane>
                    ) : null}
                  </Pane>
                </Pane>
                <span className="text-gray">
                  <span className={`badge badge-${data.status.toLowerCase()}`}>
                    <FormattedMessage
                      id={`order.${data.status.toLowerCase()}`}
                      defaultMessage={data.status}
                    />
                  </span>
                </span>
              </Pane>
            </Pane>
            <Pane className="order-item__price">
              AUD ${formatDecimal(data.total)}
            </Pane>
          </Pane>
          <Pane className="order-item__status">
            {this.props.detail.cartItems &&
              this.props.detail.cartItems.find((item) => item.refundStatus) && (
                <Pane className="text-gray pt0 pb0">
                  AUD ${formatDecimal(data.refundAmount)}
                </Pane>
              )}
          </Pane>
        </Pane>
      </>
    );
  }
}

export default OrderedCartItem;
