import React from 'react';
import { create, get } from 'lodash';
import Validator from 'validator';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import queryString from 'query-string';
import { handleError, createPassword } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';
import { InlineMessage } from 'shared/components';
import NotificationSystem from 'react-notification-system';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
class SignupForm extends React.Component {
  static propTypes = {
    signup: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      data: {
        lat: '',
        lng: '',
        phone: '',
        address: '',
        fullName: '',
        password: '',
        postCode: '',
        country: 'aus',
        confirmPassword: '',
        email: get(query, 'email'),
      },
      accept: false,
      loading: false,
      errors: {},
      showPassword: false,
      showConfirmPassword: false,
    };
  }

  _notificationSystem = null;

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  onChangePhone = (phone) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        phone,
      },
    }));
  };
  onClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  onClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }
  generatePassword = () =>{
    const newPassword = createPassword();
    this.setState({
      data: { ...this.state.data,
        ['confirmPassword']: newPassword,
        ['password']: newPassword
      },
      showPassword: true
    });
  }
  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onChangeRadio = (e, { value }) => {
    this.setState({
      data: { ...this.state.data, country: value },
    });
  };

  onChangeAddress = (address) => {
    this.setState(
      {
        data: { ...this.state.data, address },
      },
      () => {
        geocodeByAddress(address)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            this.setState((prevState) => ({
              data: {
                ...prevState.data,
                lat: latLng.lat,
                lng: latLng.lng,
              },
            }));
          })
          .catch(() => {});
      }
    );
  };

  onChangeAccept = () => {
    this.setState({ accept: !this.state.accept });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.fullName) {
      errors.fullName = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (!data.email) {
      errors.email = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
    }
    if (!data.address) {
      errors.address = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (!data.phone) {
      errors.phone = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (!data.postCode) {
      errors.postCode = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (data.postCode && !Validator.isNumeric(data.postCode)) {
      errors.postCode = intl.formatMessage({
        id: 'validate.invalid_post_code',
      });
    }
    if (!data.password) {
      errors.password = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (
      data.password &&
      (data.password.length < 6 ||
        data.password.search(/[0-9]/) < 0 ||
        data.password.search(/[A-Z]/) < 0 ||
        data.password.search(/[a-z]/) < 0)
    ) {
      errors.password = intl.formatMessage({ id: 'validate.invalid_password' });
    }
    if (!data.confirmPassword) {
      errors.confirmPassword = intl.formatMessage({
        id: 'validate.require_field',
      });
    }
    if (data.confirmPassword !== data.password) {
      errors.confirmPassword = intl.formatMessage({
        id: 'validate.invalid_confirm_password',
      });
    }

    return errors;
  };

  onSubmit = () => {
    let errors = {};
    errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new URLSearchParams();

      data.append('fullname', `${this.state.data.fullName.trim()}`);
      data.append('email', this.state.data.email.trim());
      data.append('password', this.state.data.password.trim());
      data.append('phone', this.state.data.phone.trim());
      data.append('address', this.state.data.address.trim());
      data.append('postcode', this.state.data.postCode.trim());
      data.append('country', this.state.data.country.trim());
      data.append('lat', this.state.data.lat);
      data.append('lng', this.state.data.lng);

      this.props
        .signup(data)
        .then((response) => {
          this.setState({ loading: false });

          this._notificationSystem.addNotification({
            message: this.props.intl.formatMessage({
              id: 'notification.your_account_has_been_created_successfully',
            }),
            level: 'success',
            // onRemove: () => {
            //   this.props.history.push('/send-verify-email?email='.response.data.attributes.email);
            // },
          });
          this.props.history.push('/send-verify-email?email='+encodeURIComponent(response.data.data.attributes.email));
          // this.props.history.push(
          //   `/signin${this.props.history.location.search}`
          // );
        })
        .catch((errors) => {
          errors.message = handleError(
            errors,
            this.props.intl.formatMessage({
              id: 'notification.please_try_again',
            })
          );
          this.setState({ errors, loading: false });
        });
    }
  };

  render() {
    const { data, errors, accept, loading, showPassword, showConfirmPassword } = this.state;
    const {
      intl,
      history: { location },
    } = this.props;
    const renderFunc = ({
      getInputProps,
      getSuggestionItemProps,
      suggestions,
    }) => (
      <Pane>
        <input
          {...getInputProps()}
          className="form-input"
          placeholder={intl.formatMessage({ id: 'address' })}
        />
        <Pane className="autocomplete-dropdown-container">
          {suggestions.map((suggestion, index) => (
            <Pane {...getSuggestionItemProps(suggestion)} key={index}>
              <span>{suggestion.description}</span>
            </Pane>
          ))}
        </Pane>
      </Pane>
    );

    return (
      <>
        <Form onSubmit={this.onSubmit} className="login-form" noValidate>
          <Form.Field error={!!errors.fullName} className="form-item">
            <label
              htmlFor="fullName"
              className="form-label form-label-required"
            >
              <FormattedMessage id="full_name" defaultMessage="Full Name" />
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder={intl.formatMessage({ id: 'full_name' })}
              value={data.fullName}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.fullName && <InlineMessage text={errors.fullName} />}
          </Form.Field>
          <Form.Field error={!!errors.email} className="form-item">
            <label htmlFor="email" className="form-label form-label-required">
              <FormattedMessage id="email" defaultMessage="Email" />
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={intl.formatMessage({ id: 'email' })}
              value={data.email}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.email && <InlineMessage text={errors.email} />}
          </Form.Field>
          <Form.Field error={!!errors.password} className="form-item">
            <label
              htmlFor="password"
              className="form-label form-label-required"
            >
              <FormattedMessage id="password" defaultMessage="Password" />

            </label>&nbsp;&nbsp;&nbsp;(<span className='text-generate-password' onClick={this.generatePassword}>Generate password </span>)
            <div className='input-container'>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder={intl.formatMessage({ id: 'password' })}
                value={data.password}
                className="form-input"
                onChange={this.onChange}
                autoComplete="new-password"
              />
              <span className={`fa fa-fw ${showPassword?'fa-eye':'fa-eye-slash'} field-icon toggle-password`} onClick={this.onClickShowPassword}></span>
            </div>
            <span className="help-password">
              <FormattedMessage id="help_password" />
            </span>
            {errors.password && <InlineMessage text={errors.password} />}
          </Form.Field>
          <Form.Field error={!!errors.confirmPassword} className="form-item">
            <label
              htmlFor="confirmPassword"
              className="form-label form-label-required"
            >
              <FormattedMessage
                id="confirm_password"
                defaultMessage="Confirm password"
              />
            </label>
            <div className='input-container'>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                placeholder={intl.formatMessage({ id: 'confirm_password' })}
                value={data.confirmPassword}
                className="form-input"
                onChange={this.onChange}
              />
              <span className={`fa fa-fw ${showConfirmPassword?'fa-eye':'fa-eye-slash'} field-icon toggle-password`} onClick={this.onClickShowConfirmPassword}></span>
            </div>

            {errors.confirmPassword && (
              <InlineMessage text={errors.confirmPassword} />
            )}
          </Form.Field>
          <Form.Field error={!!errors.address} className="form-item">
            <label htmlFor="address" className="form-label form-label-required">
              <FormattedMessage id="address" defaultMessage="Address" />
            </label>
            <PlacesAutocomplete
              value={data.address}
              onChange={this.onChangeAddress}
              searchOptions={{
                componentRestrictions: { country: 'au' },
              }}
            >
              {renderFunc}
            </PlacesAutocomplete>
            {errors.address && <InlineMessage text={errors.address} />}
          </Form.Field>
          <Form.Field error={!!errors.postCode} className="form-item">
            <label
              htmlFor="postCode"
              className="form-label form-label-required"
            >
              <FormattedMessage id="post_code" defaultMessage="Post code" />
            </label>
            <input
              type="text"
              id="postCode"
              name="postCode"
              placeholder={intl.formatMessage({ id: 'post_code' })}
              value={data.postCode}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.postCode && <InlineMessage text={errors.postCode} />}
          </Form.Field>
          <Form.Field error={!!errors.phone} className="form-item">
            <label htmlFor="address" className="form-label form-label-required">
              <FormattedMessage id="phone" defaultMessage="Phone" />
            </label>
            <PhoneInput
              masks={{au: '.. .... ....'}}
              country={'au'}
              value={data.receiverPhone}
              onChange={(phone) => this.onChangePhone(phone)}
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({ id: 'phone' })}
            />
            {errors.phone && <InlineMessage text={errors.phone} />}
          </Form.Field>
          <Form.Field className="form-item">
            <label className="label-checbox">
              <input

                type="checkbox"
                className="checkbox"
                name="accept"
                value={accept}
                onChange={this.onChangeAccept}
              />
              <span className="checkmark" />I have read and agree to the&nbsp;
              <Link to="terms" className="link">
                <strong>Terms and Conditions</strong>
              </Link>
            </label>
          </Form.Field>
          <Form.Field className="form-item">
            {errors.message && (
              <InlineMessage text={errors.message} className="col-md-8" />
            )}
          </Form.Field>
          <Form.Field className="form-item form-button">
            <Button
              type="submit"
              className="btn btn-circle btn-form"
              disabled={!accept || loading}
              loading={loading}
            >
              <FormattedMessage id="sign_up" defaultMessage="Sign up" />
            </Button>
          </Form.Field>
          <p className="text-registered">
            Already registered?&nbsp;
            <Link
              to={`/signin${location.search}`}
              className="link-registered link-not-underline"
            >
              Login!
            </Link>
          </p>
        </Form>
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

export default withRouter(injectIntl(SignupForm));
