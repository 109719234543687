import React, { useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { isEmpty, mapKeys } from 'lodash';
import PropTypes from 'prop-types';
import { checkDay } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';
import TradingHourItem from './TradingHourItem';
import { Rating } from "semantic-ui-react";

const TradingHours = ({ tradingHours, onChangeToggleMenu = () => { } , rating}) => {
  const [toggle, setToggle] = useState(false);
  const paramtradingHours = tradingHours;
  // eslint-disable-next-line no-param-reassign
  tradingHours = {
    Mon: {
      from: '',
      to: '',
      off: false,
    },
    Tue: {
      from: '',
      to: '',
      off: false,
    },
    Wed: {
      from: '',
      to: '',
      off: false,
    },
    Thu: {
      from: '',
      to: '',
      off: false,
    },
    Fri: {
      from: '',
      to: '',
      off: false,
    },
    Sat: {
      from: '',
      to: '',
      off: false,
    },
    Sun: {
      from: '',
      to: '',
      off: false,
    },
  };
  if (paramtradingHours) {
    Object.keys(paramtradingHours).forEach((item) => {
      const key = item.charAt(0).toUpperCase() + item.slice(1);
      // if (this.state.tradingHour[item].off || !this.state.tradingHour[item].from || !this.state.tradingHour[item].to) {
      if (!paramtradingHours[item].from || !paramtradingHours[item].to) {
        tradingHours[key].from = '00:00';
        tradingHours[key].to = '01:00';
        tradingHours[key].off = true;
      } else {
        tradingHours[key].from = paramtradingHours[item].from;
        tradingHours[key].to = paramtradingHours[item].to;
        tradingHours[key].off = paramtradingHours[item].off;
      }
    });
  }

  const onSetOpen = (tradingHours) => {
    if (!isEmpty(tradingHours)) {
      const currentDate = moment().format('ddd');
      const renamed = mapKeys(tradingHours, (value, key) => key.toLowerCase());
      const tradingHoursCurrent = renamed[currentDate.toLowerCase()];
      if (!isEmpty(tradingHoursCurrent)) {
        const fromDate = moment(tradingHoursCurrent.from, 'HH:mm');
        const toDate = moment(tradingHoursCurrent.to, 'HH:mm');
        if (moment().isBetween(fromDate, toDate) && !tradingHoursCurrent.off) {
          return <span className="status status-open">Open</span>;
        }
        return <span className="status status-close">Close</span>;
      }
      return <span className="status status-close">Close</span>;
    }
    return <span className="status status-close">Close</span>;
  };

  const toggleBussiness = () => {
    if (window.innerWidth < 992) {
      setToggle(!toggle);
    }
  };

  return (
    <Pane className="heading-trading">
      <Pane className="heading-top">
        {/* <span className="ic-common-toggle toggle-menu-top" onClick={onChangeToggleMenu} /> */}
        <Pane className="heading-status d-lg-none rating-mobile">
          <Rating
            disabled
            icon='star'
            rating={rating}
            maxRating={1}
            className="mr-1"
            size='large'
          />
          <span>{rating == 0 ? 'No Rating' : rating}</span>
        </Pane>
        <div className="heading-right">
          <h6 className="heading-title trading-hour" onClick={toggleBussiness}>Trading Hour</h6>
          {onSetOpen(tradingHours)}
        </div>
      </Pane>
      <Pane className={classnames('bussiness-content', { 'bussiness-content-active': toggle })}>
        <ul className="list-trading">
          {Object.keys(tradingHours) &&
            Object.keys(tradingHours).map((key, index) => {
              return (
                // tradingHours[key] && (tradingHours[key].from || tradingHours[key].to) && !tradingHours[key].off
                tradingHours[key] &&
                  (tradingHours[key].from || tradingHours[key].to) ? (
                    <TradingHourItem
                      key={key + index}
                      day={checkDay(key)}
                      from={tradingHours[key].from}
                      to={tradingHours[key].to}
                      off={tradingHours[key].off ? ' Closed' : ''}
                    />
                  ) : null
              );
            })}
        </ul>
      </Pane>
    </Pane>
  );
};

TradingHours.propTypes = {
  tradingHours: PropTypes.object,
};

TradingHours.defaultProps = {
  tradingHours: {},
};

export default TradingHours;
