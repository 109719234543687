import React from "react";
import Language from "./Language";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink, Link, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getShoppingCart } from "actions/cart";
import { Pane } from "shared/components/layouts";
import { getAllProducts } from "actions/products";
import { saveUserDevice } from "actions/notification";
import { logout } from "actions/auth";

class Header extends React.Component {
  static propTypes = {
    auth: PropTypes.object,
    cart: PropTypes.any,
  };

  state = {
    showSearch: false,
    visible: false,
    promotions: 0,
    height: 0,
  };

  onSetVisible = () => {
    return this.setState((prevState) => ({
      visible: !prevState.visible,
    }));
  };

  getProducts = () => {
    const params = {
      page: 1,
    };

    this.props.getAllProducts(params, true);
  };

  saveUserDevice = (userId, playerId) => {
    this.props
      .saveUserDevice({ userId: userId.toString(), token: playerId })
      .then(() => {
        localStorage.setItem(
          "deviceInfo",
          JSON.stringify({
            userId,
            playerId,
          })
        );
      });
  };

  UNSAFE_componentWillMount() {
    this.setState({
      height: window.innerHeight,
    });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    const { auth } = this.props;
    const OneSignal = window.OneSignal || [];

    if (localStorage.getItem("cartUUID")) {
      this.props.getShoppingCart();
    }
    // if (OneSignal && auth.isAuthenticated) {
    //   OneSignal.push(() => {
    //     // user has subscribed
    //     OneSignal.getUserId((id) => {
    //       if (id) {
    //         const deviceInfo = localStorage.getItem("deviceInfo")
    //           ? JSON.parse(localStorage.getItem("deviceInfo"))
    //           : {};

    //         if (deviceInfo && deviceInfo.playerId && deviceInfo.userId) {
    //           if (
    //             deviceInfo.playerId !== id ||
    //             Number(deviceInfo.userId) !== Number(auth.user.id)
    //           ) {
    //             // save user's device
    //             this.saveUserDevice(auth.user.id, id);
    //           }
    //         } else {
    //           // save user's device
    //           this.saveUserDevice(auth.user.id, id);
    //         }
    //       }
    //     });
    //   });
    // }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState(
      {
        height: window.innerHeight,
      },
      () => {
        const header = document.getElementById("header-container");
        const sticky = header.offsetTop;
        if (window.pageYOffset - 80 > sticky) {
          header.classList.add("header-sticky");
        } else {
          header.classList.remove("header-sticky");
        }
      }
    );
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        visible: false,
      });
    }
  };

  onChangeMenu = (event) => {
    if (event.target.checked) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }
  };

  logout = () => {
    this.props.history.push("/");
    this.props.logout();
  };

  render() {
    const { user, isAuthenticated } = this.props.auth;
    const name =
      user && user.displayName
        ? user.displayName.replace(/ /g, "_").toLowerCase()
        : "";

    return (
      <header
        className={`header-container header-landing`}
        id="header-container"
      >
        {/* <Pane className="header-logo mr-auto">
          <Link className="navbar-brand" to="/">
            <img
              src={`${process.env.PUBLIC_URL
                }/assets/images/layouts/Logo-Header.png`}
              className="image-logo"
              alt="caterwin logo"
            />
          </Link>
        </Pane> */}
        <Pane className="menu-responsive">
          <li className="menu-item">
            <input
              className="menu-btn"
              type="checkbox"
              id="menu-btn"
              onChange={this.onChangeMenu}
            />
            <label className="menu-icon" htmlFor="menu-btn">
              <img
                src={`${process.env.PUBLIC_URL
                  }/assets/images/icons/arrow-menu.svg`}
                className="icon"
                alt="caterwin logo"
              />
            </label>
            <ul className="nav-responsive">
              {!isAuthenticated && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about-us">
                      <FormattedMessage id="about_us" defaultMessage="ABOUT US" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/blogs">
                      <FormattedMessage id="blogs" defaultMessage="BLOGS" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/investor">
                      <FormattedMessage id="investor" defaultMessage="investor" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/partner-program">
                      <FormattedMessage
                        id="partner_program"
                        defaultMessage="Partner Program"
                      />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      <FormattedMessage id="home" defaultMessage="HOME" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/products">
                      <FormattedMessage id="products" defaultMessage="PRODUCTS" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/services">
                      <FormattedMessage id="services" defaultMessage="SERVICES" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/faqs">
                      <FormattedMessage id="faqs" defaultMessage="FAQs" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      <FormattedMessage
                        id="contact_us"
                        defaultMessage="CONTACT US"
                      />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/signin"
                    >
                      <FormattedMessage id="sign_in" />
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/signup"
                    >
                      <FormattedMessage id="sign_up" />
                    </NavLink>
                  </li>
                </>
              )}
              {isAuthenticated && (
                <>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/u/${name}/`}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/icons/home-white.svg`}
                        alt="booking"
                        className="icon"
                      />
                      <span className="norm">
                        {user.business.is_active === 0 ? (
                          <FormattedMessage id="my_sites" defaultMessage="My Sites" />
                        ) : (
                          <FormattedMessage id="my_stores" defaultMessage="My Stores"
                          />
                        )}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/u/${name}/wallet`}
                    >
                      <i className="icon-wallet text-white pr-3"></i>
                      <span className="norm">
                        <FormattedMessage
                          id="wallet"
                          defaultMessage="Wallet"
                        />
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/u/${name}/orders`}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/icons/order-white.svg`}
                        alt="booking"
                        className="icon"
                      />
                      <span className="norm">
                        <FormattedMessage
                          id="my_order"
                          defaultMessage="My order"
                        />
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/u/${name}/settings`}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/icons/setting-white.svg`}
                        alt="booking"
                        className="icon"
                      />
                      <span className="norm">
                        <FormattedMessage
                          id="profiles_setting"
                          defaultMessage="Profiles Setting"
                        />
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item" onClick={() => this.logout()}>
                    <span className="nav-link">
                      <FormattedMessage id="logout" defaultMessage="Sign out" />
                    </span>
                  </li>
                </>
              )}
              <li className="nav-item">
                <Language for="language-dropdown-responsive" />
              </li>
            </ul>
          </li>
        </Pane>
        <Pane className="menu-desktop">
          <ul className="navbar-menu">
            <li className="menu-item">
              <span className="nav-link">
                <FormattedMessage id="cardbey" defaultMessage="Cardbey" />
                <img
                  src={`${process.env.PUBLIC_URL
                    }/assets/images/icons/arrow-language.svg`}
                  alt="store"
                  className="icon-down"
                />
                <ul className="dropdown-navbar">
                  <li className="menu-item">
                    <Link className="nav-link" to="/about-us">
                      <FormattedMessage
                        id="about_us"
                        defaultMessage="ABOUT US"
                      />
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="nav-link" to="/blogs">
                      <FormattedMessage id="blogs" defaultMessage="BLOGS" />
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="nav-link" to="/investor">
                      <FormattedMessage
                        id="investor"
                        defaultMessage="investor"
                      />
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="nav-link" to="/partner-program">
                      <FormattedMessage
                        id="partner_program"
                        defaultMessage="Partner Program"
                      />
                    </Link>
                  </li>
                </ul>
              </span>
            </li>
            <li className="menu-item">
              <span className="nav-link">
                <FormattedMessage id="buy_sell" defaultMessage="BUY & SELL" />
                <img
                  src={`${process.env.PUBLIC_URL
                    }/assets/images/icons/arrow-language.svg`}
                  alt="store"
                  className="icon-down"
                />
                <ul className="dropdown-navbar">
                  <li className="menu-item">
                    <Link className="nav-link" to="/">
                      <FormattedMessage id="home" defaultMessage="HOME" />
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="nav-link" to="/products">
                      <FormattedMessage
                        id="products"
                        defaultMessage="PRODUCTS"
                      />
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="nav-link" to="/services">
                      <FormattedMessage
                        id="services"
                        defaultMessage="SERVICES"
                      />
                    </Link>
                  </li>
                </ul>
              </span>
            </li>
            <li className="menu-item">
              <span className="nav-link">
                <FormattedMessage id="helpfull" defaultMessage="HELPFULL" />
                <img
                  src={`${process.env.PUBLIC_URL
                    }/assets/images/icons/arrow-language.svg`}
                  alt="store"
                  className="icon-down"
                />
                <ul className="dropdown-navbar">
                  <li className="menu-item">
                    <Link className="nav-link" to="/faqs">
                      <FormattedMessage id="FAQs" defaultMessage="FAQs" />
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="nav-link" to="/contact-us">
                      <FormattedMessage
                        id="contact_us"
                        defaultMessage="CONTACT US"
                      />
                    </Link>
                  </li>
                </ul>
              </span>
            </li>

            {!isAuthenticated && (
              <li className="menu-item">
                <span className="nav-link">
                  <FormattedMessage id="sign_in" />
                  <img
                    src={`${process.env.PUBLIC_URL
                      }/assets/images/icons/arrow-language.svg`}
                    alt="store"
                    className="icon-down"
                  />
                  <ul className="dropdown-navbar">
                    <li className="menu-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/signin"
                      >
                        <FormattedMessage id="sign_in" />
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/signup"
                      >
                        <FormattedMessage id="sign_up" />
                      </NavLink>
                    </li>
                  </ul>
                </span>
              </li>
            )}
            <li className="menu-item menu-last">
              <Language for="language-dropdown" />
            </li>
            {isAuthenticated && (
              <li className="menu-item">
                <div className="profile">
                  <span className="profile-name">{user.displayName}</span>{" "}
                  &nbsp;&nbsp;
                  <img
                    src={`${process.env.PUBLIC_URL
                      }/assets/images/layouts/arrow-2.svg`}
                    alt="arrow"
                    className="icon-down"
                  />
                  <Pane className="dropdown-profiles">
                    <Link to={`/u/${name}/overview`} className="item">
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/icons/home-white.svg`}
                        alt="booking"
                        className="icon"
                      />
                      <span className="norm">
                        <FormattedMessage
                          id="my_stores"
                          defaultMessage="My Stores"
                        />
                      </span>
                    </Link>
                    <Link to={`/`} className="item">
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/icons/message-white.svg`}
                        alt="booking"
                        className="icon"
                      />
                      <span className="norm">
                        <FormattedMessage
                          id="my_message"
                          defaultMessage="New message"
                        />
                      </span>
                    </Link>
                    <Link to={`/cart`} className="item">
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/icons/order-white.svg`}
                        alt="booking"
                        className="icon"
                      />
                      <span className="norm">
                        <FormattedMessage
                          id="my_order"
                          defaultMessage="My order"
                        />
                      </span>
                    </Link>
                    <Link to={`/u/${name}/overview`} className="item">
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/icons/setting-white.svg`}
                        alt="booking"
                        className="icon"
                      />
                      <span className="norm">
                        <FormattedMessage
                          id="profiles_setting"
                          defaultMessage="Profiles Setting"
                        />
                      </span>
                    </Link>
                    <span className="item" onClick={() => this.logout()}>
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/icons/logout-white.svg`}
                        alt="booking"
                        className="icon"
                      />
                      <span className="norm">
                        <FormattedMessage
                          id="logout"
                          defaultMessage="Sign out"
                        />
                      </span>
                    </span>
                  </Pane>
                </div>
              </li>
            )}
          </ul>
        </Pane>
      </header>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

const bindDispatchToProps = (dispatch) => ({
  saveUserDevice: bindActionCreators(saveUserDevice, dispatch),
  getShoppingCart: bindActionCreators(getShoppingCart, dispatch),
  getAllProducts: bindActionCreators(getAllProducts, dispatch),
  logout: bindActionCreators(logout, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(withRouter(Header));
