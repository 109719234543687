import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { formatDecimal } from 'shared/helpers';
import Config from "constants/Config";

class SellingOrderItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    orderDate: PropTypes.string,
    paymentMethod: PropTypes.string,
    total: PropTypes.number,
    status: PropTypes.string,
    customer: PropTypes.object,
    link: PropTypes.string,
  };

  onChangeRoute = () => {
    this.props.history.push(this.props.link);
  }

  render() {
    return (
      <Table.Row className="cursor-pointer" key={this.props.key} onClick={() => this.onChangeRoute()}>
        <Table.Cell>{this.props.id}</Table.Cell>
        <Table.Cell>
          {moment.utc(this.props.orderDate).format('DD-MM-YYYY HH:mm')}
        </Table.Cell>
        {[Config.DELIVERY_METHOD.DINE_IN, Config.DELIVERY_METHOD.TAKE_AWAY].includes(this.props.method) && (
          <Table.Cell>Table {this.props.tableName}</Table.Cell>
        )}
        <Table.Cell>{this.props.mode !== "GUEST" ? (this.props.customer && this.props.customer.email !== 'admin@cardbey.com' ? (this.props.customer ? this.props.customer.name : '') : 'POS Order') || '' : this.props.guestName}</Table.Cell>
        <Table.Cell>AUD ${formatDecimal(this.props.total)}</Table.Cell>
        <Table.Cell>{this.props.paymentMethod}</Table.Cell>
        <Table.Cell>
          <span className={`badge badge-${this.props.status.toLowerCase()}`}>
            <FormattedMessage
              id={`order.${this.props.status.toLowerCase()}`}
              defaultMessage={this.props.status}
            />
          </span>
        </Table.Cell>
        <Table.Cell>
          <Icon name="eye" />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default withRouter(SellingOrderItem);
