import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import classnames from "classnames";
import CartList from "./CartList";
import { pickBy, identity, get, isEmpty } from "lodash";
import CartResponsive from "./CartResponsive";
import CartEmpty from "./CartEmpty";
import TotalPayment from "./TotalPayment";
import { BestMatched } from "./bestmatched";
import Iframe from "react-iframe";
import { LoginModal, ChooseCheckoutModal } from "shared/components";
import { signin, signinSocial } from 'actions/auth';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ProductOptionModal } from "../../../shared/components";
import { changeOption, addItemToCart } from "actions/cart";
import { injectIntl } from "react-intl";
import Config from "constants/Config";

class Cart extends Component {
  static propTypes = {
    cart: PropTypes.any,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.state = {
      start_location: "",
      end_location: "",
      start_postcode: 3031,
      end_postcode: 3127,
      type: "DELIVERY",
      openModalLogin: false,
      openModalChoose: false,
      cartView: {},
      openModalDetail: false
    };
  }

  onOpenModalLogin = () => {
    this.setState({
      openModalLogin: true,
    });
  };

  onCloseModalLogin = (isDone) => {
    if (isDone) {
      const { cart } = this.props;
      this.onSetLatLng(cart);
    }
    this.setState({
      openModalLogin: false,
    });
  };

  onOpenModalChoose = () => {
    this.setState({
      openModalChoose: true,
    });
  };

  onCloseModalChoose = () => {
    this.setState({
      openModalChoose: false,
    });
  }

  onOpenModalDetail = () => {
    this.setState({ openModalDetail: true });
  };

  onCloseModalDetail = () => {
    this.setState({ openModalDetail: false, cartView: {} });
  };

  setCartView = (cartViewData) => {
    this.setState((prev) => ({
      cartView: { ...prev.cartView, ...cartViewData },
    }));
  };

  componentDidMount() {
    const { cart, auth } = this.props;
    this.onSetLatLng(cart);
    if (auth.isAuthenticated) {
      this.activeType(cart);
    } else {
      this.onChangeType("PICKUP");
      const items = !isEmpty(cart.items) && cart.items.filter((item) => item.deliveryMethod === "DINE_IN" || item.deliveryMethod === "TAKE_AWAY");
      if (!isEmpty(items)) {
        this.setState({
          type: "DINE_IN",
        });
      }
    }
  }

  activeType = (cart) => {
    const items =
      !isEmpty(cart.items) &&
      cart.items.filter(
        (item) =>
          item.deliveryMethod === "DINE_IN" ||
          item.deliveryMethod === "TAKE_AWAY"
      );
    if (!isEmpty(items)) {
      this.setState({
        type: "DINE_IN",
      });
    }
  };

  handleCheckout = () => {
    if (!this.props.auth.user.id) {
      this.onOpenModalChoose();
    } else {
      this.goToCheckout();
    }
  };

  goToCheckout = () => {
    this.props.history.push("/checkout");
    localStorage.removeItem("deliveryInfo");
  }

  convertParamSearch = (search) => {
    const arr = [];
    Object.keys(pickBy(search, identity)).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(pickBy(search, identity), key)) {
        arr.push(`${key}=${pickBy(search, identity)[key]}`);
      }
    });
    return arr.join("&");
  };

  onSetLatLng = (cart) => {
    const { auth } = this.props;
    if (!isEmpty(cart.items) && !isEmpty(auth.user)) {
      const latStart = get(cart, "items[0].lat");
      const lngStart = get(cart, "items[0].lng");
      const postCodeStart = get(cart, "items[0].postCode");
      const latEnd = get(auth, "user.lat");
      const lngEnd = get(auth, "user.lng");
      const postCodeEnd = get(auth, "user.postCode");
      this.setState({
        start_location: `${latStart},${lngStart}`,
        end_location: `${latEnd},${lngEnd}`,
        start_postcode: postCodeStart,
        end_postcode: postCodeEnd,
      });
    }
  };

  onChangeType = (type) => {
    this.setState({
      type,
    });
  };

  changeOption = (item, optionIds) => {
    const params = { id: item.id, optionInfo: JSON.stringify(optionIds) };
    this.props.changeOption(params).then(() => {
      this.onCloseModalDetail();
    });
  };

  render() {
    const { cart, auth, signin, signinSocial, } = this.props;
    const { start_location, end_location, start_postcode, end_postcode, type, openModalChoose, openModalLogin } = this.state;
    return (
      <>
        {cart.items && cart.items.length > 0 ? (
          <Pane className="cart-container pt-5 pb-5">
            <LoginModal
              onNotification={this._notificationSystem}
              signin={signin}
              signinSocial={signinSocial}
              auth={auth}
              openModal={openModalLogin}
              onCloseModal={this.onCloseModalLogin}
              isCheckout={true}
              onSetLatLng={this.onSetLatLng}
            />
            <ChooseCheckoutModal
              onCloseModal={this.onCloseModalChoose}
              openModal={openModalChoose}
              onOpenModalLogin={this.onOpenModalLogin}
              onGoToCheckout={this.goToCheckout}
            />
            <ProductOptionModal
              openModal={this.state.openModalDetail}
              onClose={this.onCloseModalDetail}
              detail={this.state.cartView}
              onAddCartItem={(optionIds, item) => {
                this.props
                  .addItemToCart({
                    product_id: item.id,
                    option_info: optionIds.length
                      ? JSON.stringify(optionIds)
                      : null,
                    table_id: item.tableId,
                    delivery_method: item.delivery_method,
                  })
                  .then(() => {
                    this.onCloseModalDetail();
                  });
              }}
              isEdit={true}
              modeCart={this.state.cartView.modeCart || true}
              onUpdateCart={(detail, optionIds) =>
                this.changeOption(detail, optionIds)
              }
            />
            <ProductOptionModal
              openModal={this.state.openModalDetail}
              onClose={this.onCloseModalDetail}
              detail={this.state.cartView}
              onAddCartItem={(optionIds, item) => {
                this.props
                  .addItemToCart({
                    product_id: item.id,
                    option_info: optionIds.length
                      ? JSON.stringify(optionIds)
                      : null,
                    table_id: item.tableId,
                    delivery_method: item.delivery_method,
                  })
                  .then(() => {
                    this.onCloseModalDetail();
                  });
              }}
              isEdit={true}
              modeCart={this.state.cartView.modeCart || true}
              onUpdateCart={(detail, optionIds) =>
                this.changeOption(detail, optionIds)
              }
            />
            <Pane className="container">
              <Pane className="row">
                <Pane className="col-lg-6">
                  <Pane className="cart-content mb-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/cart/cart.svg`
                      }
                      alt="icon"
                      className="icon"
                    />
                    <Pane className="total">
                      <p className="total-name">
                        <FormattedMessage
                          id="cart.your_shopping_cart_contains"
                          defaultMessage="Your shopping cart contains"
                        />
                        :
                      </p>
                      <p className="total-name">
                        <strong>
                          {cart.items.length}
                          {` Item${cart.items.length > 1 ? "s" : ""}`}
                        </strong>
                      </p>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane className="col-lg-3 mb-3 mb-lg-0">
                  <Pane>
                    <button
                      className="btn btn-continue"
                      onClick={() => this.props.history.push("/search?type=Food")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + '/assets/images/layouts/cart.svg'}
                        alt="cart"
                        className="icon"
                      />
                      <FormattedMessage
                        id="cart.continue_shopping"
                        defaultMessage="Continue shopping"
                      />
                    </button>
                  </Pane>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Pane className="tabs-container">
                    {!cart.tableId ? (
                      <>
                        <Pane
                          className={classnames("tabs-item", {
                            active: type === Config.DELIVERY_METHOD.DELIVERY,
                          })}
                          onClick={() => this.onChangeType(Config.DELIVERY_METHOD.DELIVERY)}
                        >
                          <FormattedMessage
                            id="delivery"
                            defaultMessage="DELIVERY"
                          />
                          <span className="ic-common-delivery">
                            <span className="number_items">
                              {!isEmpty(cart.items)
                                ? cart.items.filter(
                                  (item) => item.deliveryMethod === Config.DELIVERY_METHOD.DELIVERY
                                ).length
                                : 0}
                            </span>
                          </span>
                        </Pane>
                        <Pane
                          className={classnames("tabs-item", {
                            active: type === Config.DELIVERY_METHOD.PICKUP,
                          })}
                          onClick={() => this.onChangeType(Config.DELIVERY_METHOD.PICKUP)}
                        >
                          <FormattedMessage id="pickup" defaultMessage="PICKUP" />
                          <span className="ic-common-pickup">
                            <span className="number_items">
                              {!isEmpty(cart.items)
                                ? cart.items.filter(
                                  (item) => item.deliveryMethod === Config.DELIVERY_METHOD.PICKUP
                                ).length
                                : 0}
                            </span>
                          </span>
                        </Pane>
                      </>
                    ) : (
                      <>
                        <Pane
                          className={classnames("tabs-item", {
                            active: type === Config.DELIVERY_METHOD.DINE_IN,
                          })}
                          onClick={() => this.onChangeType(Config.DELIVERY_METHOD.DINE_IN)}
                        >
                          Dine-In <span className="ic-common-table">
                            <span className="number_items">{
                              !isEmpty(cart.items) ? cart.items.filter(
                                (item) => item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
                              ).length : 0
                            }</span>
                          </span>
                        </Pane>
                        <Pane
                          className={classnames("tabs-item", {
                            active: type === Config.DELIVERY_METHOD.TAKE_AWAY,
                          })}
                          onClick={() => this.onChangeType(Config.DELIVERY_METHOD.TAKE_AWAY)}
                        >
                          {Config.DELIVERY_METHOD.TAKE_AWAY} <span className="ic-common-table">
                            <span className="number_items">{
                              !isEmpty(cart.items) ? cart.items.filter(
                                (item) => item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
                              ).length : 0
                            }</span>
                          </span>
                        </Pane>
                      </>
                    )}
                  </Pane>
                </Pane>
              </Pane>
              <Pane className="row">
                {cart.tableId && (type === Config.DELIVERY_METHOD.TAKE_AWAY || type === Config.DELIVERY_METHOD.DINE_IN) && (
                  <Pane className="col-12"><b><em>{cart.tableName}</em></b></Pane>
                )}
                {type === Config.DELIVERY_METHOD.DELIVERY && (
                  <Pane className="col-lg-9">
                    <Pane className="cart-table cart-table-desktop">
                      <CartList
                        cartModal={{
                          cartView: this.state.cartView,
                          openModal: this.onOpenModalDetail,
                          setCartView: this.setCartView,
                        }}
                        cart={
                          !isEmpty(cart.items) &&
                          cart.items.filter(
                            (item) => item.deliveryMethod === Config.DELIVERY_METHOD.DELIVERY
                          )
                        }
                        onChangeType={this.onChangeType}
                      />
                    </Pane>
                    <Pane className="cart-table cart-table-responsive">
                      <CartResponsive
                        cartModal={{
                          cartView: this.state.cartView,
                          openModal: this.onOpenModalDetail,
                          setCartView: this.setCartView,
                        }}
                        cart={
                          !isEmpty(cart.items) &&
                          cart.items.filter(
                            (item) => item.deliveryMethod === Config.DELIVERY_METHOD.DELIVERY
                          )
                        }
                        onChangeType={this.onChangeType}
                      />
                    </Pane>
                  </Pane>
                )}
                {type === Config.DELIVERY_METHOD.PICKUP && (
                  <Pane className="col-lg-9">
                    <Pane className="cart-table cart-table-desktop">
                      <CartList
                        cartModal={{
                          cartView: this.state.cartView,
                          openModal: this.onOpenModalDetail,
                          setCartView: this.setCartView,
                        }}
                        cart={
                          !isEmpty(cart.items) &&
                          cart.items.filter(
                            (item) => item.deliveryMethod === "PICKUP"
                          )
                        }
                        onChangeType={this.onChangeType}
                      />
                    </Pane>
                    <Pane className="cart-table cart-table-responsive">
                      <CartResponsive
                        cartModal={{
                          cartView: this.state.cartView,
                          openModal: this.onOpenModalDetail,
                          setCartView: this.setCartView,
                        }}
                        cart={
                          !isEmpty(cart.items) &&
                          cart.items.filter(
                            (item) => item.deliveryMethod === "PICKUP"
                          )
                        }
                        onChangeType={this.onChangeType}
                      />
                    </Pane>
                  </Pane>
                )}
                {type === Config.DELIVERY_METHOD.DINE_IN && (
                  <Pane className="col-lg-9">
                    <Pane className="cart-table cart-table-desktop">
                      <CartList
                        cartModal={{
                          cartView: this.state.cartView,
                          openModal: this.onOpenModalDetail,
                          setCartView: this.setCartView,
                        }}
                        cart={
                          !isEmpty(cart.items) &&
                          cart.items.filter(
                            (item) => item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
                          )
                        }
                        onChangeType={this.onChangeType}
                        type={Config.DELIVERY_METHOD.DINE_IN}
                      />
                    </Pane>
                    <Pane className="cart-table cart-table-responsive">
                      <CartResponsive
                        cartModal={{
                          cartView: this.state.cartView,
                          openModal: this.onOpenModalDetail,
                          setCartView: this.setCartView,
                        }}
                        cart={
                          !isEmpty(cart.items) &&
                          cart.items.filter(
                            (item) => item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
                          )
                        }
                        onChangeType={this.onChangeType}
                        type={Config.DELIVERY_METHOD.DINE_IN}
                      />
                    </Pane>
                  </Pane>
                )}
                {type === Config.DELIVERY_METHOD.TAKE_AWAY && (
                  <Pane className="col-lg-9">
                    <Pane className="cart-table cart-table-desktop">
                      <CartList
                        cartModal={{
                          cartView: this.state.cartView,
                          openModal: this.onOpenModalDetail,
                          setCartView: this.setCartView,
                        }}
                        cart={
                          !isEmpty(cart.items) &&
                          cart.items.filter(
                            (item) => item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
                          )
                        }
                        onChangeType={this.onChangeType}
                        type={Config.DELIVERY_METHOD.TAKE_AWAY}
                      />
                    </Pane>
                    <Pane className="cart-table cart-table-responsive">
                      <CartResponsive
                        cartModal={{
                          cartView: this.state.cartView,
                          openModal: this.onOpenModalDetail,
                          setCartView: this.setCartView,
                        }}
                        cart={
                          !isEmpty(cart.items) &&
                          cart.items.filter(
                            (item) => item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
                          )
                        }
                        onChangeType={this.onChangeType}
                        type={Config.DELIVERY_METHOD.TAKE_AWAY}
                      />
                    </Pane>
                  </Pane>
                )}
                <Pane className="col-3 d-lg-block d-none">
                  <Pane className="float_checkout">
                    <TotalPayment
                      type="cart"
                      handleCheckout={this.handleCheckout}
                    />
                  </Pane>
                </Pane>
              </Pane>
              <Pane className="row mt-3 mb-5 d-lg-none d-block">
                <Pane className="col-lg-12">
                  <TotalPayment
                    type="cart"
                    handleCheckout={this.handleCheckout}
                  />
                </Pane>
              </Pane>
              <Pane className="row mt-3">
                <Pane className="col-lg-12">
                  <Pane className="cart-table ">
                    <BestMatched
                      data={cart.items}
                      cart={this.props.cart}
                      cartModal={{
                        cartView: this.state.cartView,
                        openModal: this.onOpenModalDetail,
                        setCartView: this.setCartView,
                      }}
                    />
                  </Pane>
                </Pane>
              </Pane>
              {/* <Pane className="row mt-4">
                <Pane className="col-lg-12">
                  <Pane className="map-delivery">
                    {start_location &&
                      end_location &&
                      type === Config.DELIVERY_METHOD.DELIVERY &&
                      process.env.REACT_APP_SHOW_BHD_MAP == "true" ? (
                      <Iframe
                        url={
                          process.env.REACT_APP_MAP_KCATER +
                          `?${this.convertParamSearch({
                            start_postcode,
                            start_location,
                            end_postcode,
                            end_location,
                            length: 2,
                            width: 3,
                            height: 1,
                            weight: 1,
                            map_height: "700px",
                            "access-token":
                              process.env.REACT_APP_ACCESS_TOKEN_KCATER,
                            clientId: process.env.REACT_APP_CLIENTID_KCATER,
                            clientSecret:
                              process.env.REACT_APP_CLIENTSECRET_KCATER,
                          })}`
                        }
                        className="map-container"
                      />
                    ) : null}
                  </Pane>
                </Pane>
              </Pane> */}
            </Pane>
          </Pane>
        ) : (
          <CartEmpty />
        )}
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
  changeOption: bindActionCreators(changeOption, dispatch),
  addItemToCart: bindActionCreators(addItemToCart, dispatch),
});

export default withRouter(
  injectIntl(connect(bindStateToProps, bindDispatchToProps)(Cart))
);
