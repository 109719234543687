import _ from "lodash";
import Menu from "./Menu";
import PropTypes from "prop-types";
import { RenderRoutes } from "routes";
import NotificationSystem from "react-notification-system";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BannerIntro from "./banner/BannerIntro";
import { Pane, Loading } from "shared/components/layouts";
import BusinessInfo from "./show/BusinessInfo";
import { formatJson, isNumberic } from "shared/helpers";
import UserAdapter from "services/users/adapter";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addScanCounts } from "actions/scanCounts";
import { getShoppingCart, addItemToCart, changeOption } from "actions/cart";
import { getUserInfo } from "actions/auth";
import { addCustomer } from "actions/customer";
import FloatCart from "./FloatCart/FloatCart";
import BottomCart from "./FloatCart/BottomCart";
import { isEmpty } from "lodash";
import { ClaimContactModal, ProductOptionModal, Method } from "shared/components";
import { signin, signinSocial } from "actions/auth";
import { injectIntl } from "react-intl";
import { getReviewsVideo, reviewsVideo } from "actions/reviews";
import { getLikesVideo, likesVideo, getUserLikesVideo } from "actions/likes";
import { removeAllCart } from "actions/cart";
import { addStoreBookingsRequest } from "actions/storeBookings";
import { getTablesByUserId } from "actions/myTables";
import Config from "constants/Config";
import { MethodModal, ServiceOptionModal } from "../../../shared/components";
import Modal from "react-responsive-modal";
import PhoneInput from 'react-phone-input-2';
import { Button } from "semantic-ui-react";
class Store extends Component {
  static propTypes = {
    storeId: PropTypes.any,
    storeInfo: PropTypes.object,
    onGetStoreInfo: PropTypes.func,
    addScanCounts: PropTypes.func,
    getShoppingCart: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    const {
      location: { search },
    } = props;
    this.state = {
      tableId: queryString.parse(search).tableId || null,
      isShowQRCode: queryString.parse(search).isShowQRCode || false,
      slides: {},
      products: {},
      services: {},
      storeInfo: {},
      foodCategories: [],
      fetchedStore: false,
      fetchingStore: false,
      serviceCategories: [],
      promotionProducts: {},
      promotionServices: {},
      fetchingProducts: false,
      fetchingServices: false,
      isVideo: this.props.location.pathname.search("/videos") > 0,
      toggleMenu: true,
      height: 0,
      openModal: false,
      isBgVideo: false,
      extVideo: Config.extVideo,
      cartView: {},
      openModalDetail: false,
      serviceDetail: {},
      openModalServiceDetail: false,
      openModalMethod: false,
      userForm:false,
      tables: [],
      table: {
        id: "",
        name: ""
      },
      errors: {},
      customer:{
        fullname:'',
        phone:'',
        email:'',
      },
      showSMSmessage:false,
      methodType: "Dine-In",
      loadingGetCode: false,
      messageNoti: 'You have received a discount code, please check your email or SMS!'
    };
  }

 componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
   this.getStoreByName();
    document.addEventListener("mousedown", this.handleClickOutside);




    // const { auth } = this.props;

    // if (auth.isAuthenticated) {
    //   this.props.getUserInfo({ userId: auth.user.id }).then(() => {
    //     this.props.getShoppingCart();
    //   });
    // } else {
    //   this.props.getShoppingCart();
    // }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      height: window.innerHeight,
    });
    window.addEventListener("scroll", this.handleScroll);
  }

   componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        isVideo: this.props.location.pathname.search("/videos") > 0,
      });
      this.getStoreByName();
    }
  }

  handleScroll = () => {
    this.setState(
      {
        height: window.innerHeight,
      },
      () => {
        if (window.innerWidth < 992) {
        }
      }
    );
  };

  searchStores = (storeId, redirect) => {
    this.setState({
      fetchingStore: true,
      fetchedStore: false,
    });
    this.props.onSearchStores({ keyword: storeId }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        const store = formatJson(
          response.data,
          response.data.data[0].type,
          response.data.data[0].id
        );
        if (store && store.user.id) {
          this.setState(
            {
              storeId: store.user.id,
            },
            () => {
              this.props
                .onGetStoreInfo({ store: this.state.storeId })
                .then(() => {
                  this.setState(
                    {
                      storeInfo: this.props.storeInfo[this.state.storeId],
                      fetchingStore: false,
                      fetchedStore: true,
                    },
                    () => {
                      if (redirect) {
                        this.props.history.replace(`/${this.state.storeId}`);
                      }
                      if (this.state.isShowQRCode) {
                        this.onDetectScan(
                          storeId,
                          this.props.storeInfo[this.state.storeId]
                        );
                      }
                      if (
                        !_.isEmpty(this.props.storeInfo.backgroundImage) &&
                        this.state.extVideo.includes(
                          this.props.storeInfo.backgroundImage.substr(
                            this.props.storeInfo.backgroundImage.lastIndexOf(
                              "."
                            ) + 1
                          )
                        )
                      ) {
                        this.setState({ isBgVideo: true });
                      }
                    }
                  );
                });
            }
          );
        } else {
          this.pageNotFound();
        }
      } else {
        this.pageNotFound();
      }
    });
  };

  getStoreByName = () => {
    const storeId = this.props.storeId.replace(
      /[/\\#,\-+_()$~%.'":*?<>{}]/g,
      " "
    );
    const { tableId, isShowQRCode } = this.state;
    if (isNumberic(storeId)) {
      this.props
        .onGetStoreInfo({ store: storeId })
        .then((response) => {
          const store = UserAdapter.read.parseResponse(response.data);
          if (store.business.is_active === 1) {
            this.setState(
              {
                storeInfo: store,
                fetchingStore: false,
                fetchedStore: true,
                storeId,
              },
              () => {
                if (tableId) {
                  this.props.history.replace(
                    `/${storeId}/menu?tableId=${tableId}`
                  );
                } else if (isShowQRCode) {
                  this.onDetectScan(storeId, store);
                }
                if(isShowQRCode && this.state.storeInfo.business.firstScanFlg == 1){
                  this.setState({userForm:true, isShowQRCode:false});
                }
                if (
                  !_.isEmpty(store.backgroundImage) &&
                  this.state.extVideo.includes(
                    store.backgroundImage.substr(
                      store.backgroundImage.lastIndexOf(".") + 1
                    )
                  )
                ) {
                  this.setState({ isBgVideo: true });
                  let el = document.querySelector(".header-container");
                  let el_sp = document.querySelector(".header-sp");
                  if (el) el.classList.add("head-bg__transparent");
                  if (el_sp) el_sp.classList.add("head-bg__transparent");
                }
                if (store.business.type === Config.BUSINESS_TYPE.FOOD && tableId) {
                  this.getListById(store.business.id)
                }
              }
            );
          } else {
            this.props.history.replace(`/users/${store.id}${this.state.isShowQRCode ? '?isShowQRCode=true' : ''}`);
          }
        })
        .catch((error) => {
          this.props.history.replace(`/page-not-found`);
        });
    } else {
      this.searchStores(storeId, false);
    }
  };

  pageNotFound = () => {
    this.setState(
      {
        fetchingStore: false,
        fetchedStore: true,
      },
      () => {
        this.props.history.replace(`/page-not-found`);
      }
    );
  };

  getListById = (businessId) => {
    this.setState({ loadingTable: true });
    this.props
      .getTablesByUserId({ business_id: businessId })
      .then((response) => {
        if (response) {
          this.setState({
            tables: UserAdapter.tablesBrowse.parseResponse(response.data),
            loadingTable: false,
          }, () => {
            if (!isEmpty(this.state.tables)) {
              let customer = localStorage.getItem("customer");
              if (!customer) {
                this.setState({openModalMethod: true});
              } else {
                let data = JSON.parse(customer);
                let name = "";
                if (this.state.storeId == data.payload.storeId) {
                  if (data.payload.tableId) {
                    let obj = this.state.tables.filter(obj => obj.id == data.payload.tableId)[0];
                    if (obj) {
                      name = obj.name;
                    }
                  }
                  this.setState({methodType: data.method, table: {id: data.payload.tableId, name: name}});
                } else {
                  this.setState({openModalMethod: true});
                }
              }
            }
          });
        }
      })
      .catch(() => {
        this.setState({ loadingTable: false });
      });
  };

  onChangeToggleMenu = () => {
    this.setState((prevState) => ({
      toggleMenu: !prevState.toggleMenu,
    }));
  };

  onDetectScan = (storeId, storeInfo) => {
    if (
      storeInfo.business.is_active === 1 &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.props.addScanCounts({ user_id: storeId }).then(() => {
        this.props.history.replace(this.props.location.pathname);
      });
    } else {
        this.props.history.replace(this.props.location.pathname);
    }
  };

  onSubmitForm = () => {
    const isValid = this.validateForm();
    if(isValid){
      this.setState({loadingGetCode: true});
      this.props.addCustomer({
        ...this.state.customer,
        business_id: this.state.storeInfo.business.id,
        first_scan_promotion_id: this.state.storeInfo.business.firstScanPromotionId,
      }).then((response) => {
        if (response && response.status == 201) {
          this.setState({showSMSmessage:true});
        }
        if (response && response.status == 200) {
          this.setState({showSMSmessage:true});
          this.setState({messageNoti: `This is your ${this.state.storeInfo.business.firstScanDiscount}%.discount promotion code. Thank you`});
        }
        this.setState({loadingGetCode: false});
      }).catch((err) => {
        this.setState({loadingGetCode: false});
        if( err.response.data && err.response.data.errors){
          let errors = {};
          errors[err.response.data.errors[0].source.pointer] = err.response.data.errors[0].detail;
          this.setState({ errors });
        }
      });
    }
  }

  onFormInputChange = (e) => {
    this.setState(
      {customer: {
        ...this.state.customer,
        [e.target.name]: e.target.value
       }});
  };

  onChangePhone = (phone) => {
    this.setState((prevState) => ({
      customer: {
        ...prevState.customer,
        phone,
      },
    }));
  };

  onOpenModal = () => {
    this.setState({
      openModal: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      openModal: false,
    });
  };

  onCloseModalMethod = () => {
    this.setState({ openModalMethod: false });
  };

  onCloseModalDetail = () => {
    this.setState({ openModalDetail: false, cartView: {} });
  };

  onOpenModalDetail = () => {
    this.setState({ openModalDetail: true });
  };

  setCartView = (cartViewData) => {
    this.setState((prev) => ({
      cartView: { ...prev.cartView, ...cartViewData },
    }));
  };

  onCloseModalServiceDetail = () => {
    this.setState({ openModalServiceDetail: false, serviceDetail: {} });
  };

  onOpenModalServiceDetail = () => {
    this.setState({ openModalServiceDetail: true });
  };

  setServiceDetail = (data) => {
    this.setState((prev) => ({
      serviceDetail: { ...prev.serviceDetail, ...data },
    }));
  };

  validateForm = () => {
    const { fullname, email, phone } = this.state.customer;
    let errors = {};
    if (!fullname.trim()) {
      errors.fullname = 'Name is required';
    }
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is not valid';
    }
    if (!phone.trim()) {
      errors.phone = 'Phone is required';
    }

    this.setState({ errors });

    // Return true if there are no errors, false otherwise
    return Object.keys(errors).length === 0;
  };
  onChangeType = (event) => {
    let id = event.target.value;
    if (id) {
      let name = this.state.tables.filter(obj => obj.id == id)[0].name || null;
      this.setState({table: {id: id, name: name}})

      const {storeId} = this.state;
      let data = {
        method: "Dine-In",
        payload: {
          tableId: id,
          storeId: storeId
        }
      }
      localStorage.setItem("customer", JSON.stringify(data));
    }
  }

  onOpenModalMethod = () => {
    this.setState({ openModalMethod: true });
  };

  onChangeMethod = (method) => {
    const {storeId, table} = this.state;
    this.setState({ methodType: method });
    let data = {
      method: method,
      payload: {
        tableId: "",
        storeId: storeId,
      }
    }
    if (method === "Dine-In" || method === "Take-Away") {
      data.payload.tableId = table.id;
    }
    localStorage.setItem("customer", JSON.stringify(data));
  }
  onCloseUserForm = () =>{
    this.setState({ userForm: false });
  }
  render() {
    const {
      storeInfo,
      toggleMenu,
      fetchedStore,
      fetchingStore,
      isShowQRCode,
      openModal,
      openModalMethod,
      isBgVideo,
      tables,
      methodType,
      table,
      loadingTable,
      errors,
      loadingGetCode
    } = this.state;

    const { cart, storeId, intl } = this.props;
    const {customer} = this.state;

    return (
      <>
        {_.get(storeInfo, "business.id") && (
          <ClaimContactModal
            openModal={openModal}
            businessInfo={storeInfo.business}
            onCloseModal={this.onCloseModal}
            onNotification={this._notificationSystem}
          />
        )}
        {fetchedStore && !fetchingStore ? (
          <BannerIntro
            storeInfo={storeInfo}
            isBgVideo={isBgVideo}
            getLikesVideo={this.props.getLikesVideo}
            getUserLikesVideo={this.props.getUserLikesVideo}
            getReviewsVideo={this.props.getReviewsVideo}
            reviewsVideo={this.props.reviewsVideo}
            likesVideo={this.props.likesVideo}
            auth={this.props.auth}
            signin={this.props.signin}
            signinSocial={this.props.signinSocial}
            like={this.props.like}
            reviews={this.props.reviews}
            userLike={this.props.userLike}
          />
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}
        <ProductOptionModal
          openModal={this.state.openModalDetail}
          onClose={this.onCloseModalDetail}
          detail={this.state.cartView}
          onUpdateCart={(item, optionIds) => {
            const params = {
              id: item.id,
              optionInfo: JSON.stringify(optionIds),
            };
            this.props.changeOption(params).then(() => {
              this._notificationSystem.addNotification({
                message: intl.formatMessage({ id: "cart.update_success" }),
                level: "success",
                autoDismiss: 3,
              });
              this.onCloseModalDetail();
            });
          }}
          onAddCartItem={(optionIds, item) => {
            if (cart.items && cart.items.length > 0 && item.userId !== cart.items[0].userId) {
              this.props.removeAllCart();
            }
            this.props
              .addItemToCart({
                product_id: item.id,
                option_info: optionIds.length
                  ? JSON.stringify(optionIds)
                  : null,
                table_id: table.id ? table.id : item.tableId,
                delivery_method: item.delivery_method,
              })
              .then(() => {
                this._notificationSystem.addNotification({
                  message: intl.formatMessage({ id: "cart.add_success" }),
                  level: "success",
                  autoDismiss: 3,
                });
                this.onCloseModalDetail();
              });
          }}
          isEdit={true}
        />
        <ServiceOptionModal
          openModal={this.state.openModalServiceDetail}
          onClose={this.onCloseModalServiceDetail}
          detail={this.state.serviceDetail}
        />
        {!loadingTable && (
          <MethodModal
            openModal={openModalMethod}
            onClose={this.onCloseModalMethod}
            onChangeMethod={this.onChangeMethod}
            onChangeType={this.onChangeType}
            tables={tables}
            methodType={methodType}
            tableId={table.id}
          />
        )}
        <Pane
          className={"stores-container" + (isBgVideo ? " stores-front" : "")}
        >
          {fetchedStore && !fetchingStore ? (
            <Pane className="heading-container" id="info-container">
              {_.get(storeInfo, "business.id") && (
                <BusinessInfo
                  onOpenModalClaim={this.onOpenModal}
                  isShowQRCode={isShowQRCode}
                  businessInfo={storeInfo.business}
                  storeInfo={storeInfo}
                  onChangeToggleMenu={this.onChangeToggleMenu}
                />
              )}
            </Pane>
          ) : (
            <></>
          )}
          <Pane className="product-container mt-5">
            <Pane className="container">
              <Pane className="row">
                <Pane className="col-lg-3 col-md-12">
                  {!_.isEmpty(storeInfo) && (
                    <Menu
                      storeId={storeId}
                      storeInfo={storeInfo}
                      toggleMenu={toggleMenu}
                    />
                  )}
                  {!_.isEmpty(storeInfo) && storeInfo.business.is_active == 1 && (
                    <>
                      {!loadingTable && !_.isEmpty(tables) && (
                        <Pane className="method-block d-lg-flex d-none">
                          <span className="sub-header__type">
                            {methodType}
                            {!_.isEmpty(table.id) && (["Dine-In", "Take-Away"].includes(methodType)) && (
                              <span className="label-table">{table.name}</span>
                            )}
                          </span>
                          <span className="btn-change" onClick={this.onOpenModalMethod}>Change</span>
                        </Pane>
                      )}
                      {!_.isEmpty(storeInfo) && storeInfo.business.type !== Config.BUSINESS_TYPE.SERVICE && (
                        <FloatCart
                          cart={
                            !isEmpty(cart.items) &&
                            cart.items.filter(
                              (item) => item.userId == storeInfo.business.userId
                            )
                          }
                          storeInfo={storeInfo}
                          cartModal={{
                            cartView: this.state.cartView,
                            openModal: this.onOpenModalDetail,
                            setCartView: this.setCartView,
                          }}
                        />
                      )}
                    </>
                  )}
                  {!_.isEmpty(storeInfo) && storeInfo.business.type !== Config.BUSINESS_TYPE.SERVICE && (
                    <BottomCart cart={cart} />
                  )}
                </Pane>
                <Pane className="col-lg-9 col-md-12">
                  {!_.isEmpty(storeInfo) && (
                    <RenderRoutes
                      routes={this.props.routes}
                      storeInfo={storeInfo}
                      cartModal={{
                        cartView: this.state.cartView,
                        openModal: this.onOpenModalDetail,
                        setCartView: this.setCartView,
                      }}
                      addStoreBookingsRequest={this.props.addStoreBookingsRequest}
                      serviceModal={{
                        serviceDetail: this.state.serviceDetail,
                        openModal: this.onOpenModalServiceDetail,
                        setServiceDetail: this.setServiceDetail,
                      }}
                      methodModal={{
                        methodType: methodType,
                        onOpeModalMethod: this.onOpenModalMethod,
                        isShowTable: !loadingTable && !_.isEmpty(tables),
                        tableId: table.id,
                        tableName: table.name
                      }}
                    />
                  )}
                </Pane>

              </Pane>
            </Pane>
          </Pane>
        </Pane>
        {fetchedStore && !fetchingStore && (
          <Modal
              classNames={{
                closeIcon: 'customIconBtn',
                modal: 'popup-promotion-scan',
              }}
              open={this.state.userForm}
              onClose={this.onCloseUserForm}
              center
              showCloseIcon={true}
            >

              <Pane className="card-body popup-body-content">
                <Pane className="block-image">
                  <img className="offer-image" src={`${process.env.PUBLIC_URL}/assets/images/popup/offer.png`} alt="offer" />
                  {!this.state.showSMSmessage && (
                    <h2 className="modal-title">Congrats! You’re eligible for our special <b><em>{storeInfo.business ? storeInfo.business.firstScanDiscount : 0}%</em></b> discount, please enter your info to get the promotion code</h2>
                  )}
                  {!this.state.messageNoti && (
                    <h2 className="modal-title">{this.state.messageNoti}</h2>
                  )}
                </Pane>
                <Pane className="row">
                  {this.state.showSMSmessage &&(
                    <Pane className="col-md-12 form-group">
                    <h3>{this.state.messageNoti}</h3>
                    </Pane>
                  )}
                  {!this.state.showSMSmessage && (
                    <>
                      <Pane className="col-md-12 form-group mb-0">
                        <label htmlFor="name" className="col-form-label">Name:</label>
                        <input className="form-control" type="text" name="fullname" onChange={this.onFormInputChange} />
                        {errors.fullname && <span className="error">{errors.fullname}</span>}
                      </Pane>
                      <Pane className="col-md-12 form-group mb-0">
                        <label htmlFor="email" className="col-form-label">Email:</label>
                        <input className="form-control" type="text" name="email" onChange={this.onFormInputChange} />
                        {errors.email && <span className="error">{errors.email}</span>}
                      </Pane>
                      <Pane className="col-md-12 form-group">
                        <label htmlFor="name" className="col-form-label">Phone:</label>
                        <PhoneInput
                          country={'au'}
                          value={customer.phone}
                          onChange={(phone) => this.onChangePhone(phone)}
                          style={{ width: '100%' }}
                        />
                        {errors.phone && <span className="error">{errors.phone}</span>}
                      </Pane>
                      <Pane className="col-md-12 form-group text-center">
                        <Button disabled={loadingGetCode} loading={loadingGetCode} type="button" className="btn btn-claim" onClick={this.onSubmitForm}>SEND</Button>
                      </Pane>
                    </>
                  )}

                </Pane>
              </Pane>
          </Modal>
        )}
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
  reviews: state.reviews,
  like: state.likes,
  userLike: state.userLike,
});

const bindDispatchToProps = (dispatch) => ({
  addScanCounts: bindActionCreators(addScanCounts, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
  getReviewsVideo: bindActionCreators(getReviewsVideo, dispatch),
  reviewsVideo: bindActionCreators(reviewsVideo, dispatch),
  getLikesVideo: bindActionCreators(getLikesVideo, dispatch),
  likesVideo: bindActionCreators(likesVideo, dispatch),
  getUserLikesVideo: bindActionCreators(getUserLikesVideo, dispatch),
  addItemToCart: bindActionCreators(addItemToCart, dispatch),
  changeOption: bindActionCreators(changeOption, dispatch),
  getTablesByUserId: bindActionCreators(getTablesByUserId, dispatch),
  addStoreBookingsRequest: bindActionCreators(addStoreBookingsRequest, dispatch),
  addCustomer: bindActionCreators(addCustomer, dispatch),
  removeAllCart: bindActionCreators(removeAllCart, dispatch),
});

export default withRouter(
  injectIntl(connect(bindStateToProps, bindDispatchToProps)(Store))
);
