import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { getShoppingCart } from 'actions/cart';
import { getUserInfo } from 'actions/auth';
import { Cart } from 'components/scenes/cart';
import { Pane } from 'shared/components/layouts';

class CartPage extends Component {
  static propTypes = {
    getShoppingCart: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func,
  };

  componentDidMount() {
    // const { auth } = this.props;

    // if (auth.isAuthenticated) {
    //   this.props.getUserInfo({ userId: auth.user.id }).then(() => {
    //     this.props.getShoppingCart();
    //   });
    // } else {
    //   this.props.getShoppingCart();
    // }
  }

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <Cart cart={this.props.cart} auth={this.props.auth} />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

const bindDispatchToProps = (dispatch) => ({
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(CartPage);
