import * as types from 'constants/ActionTypes'
import OrderAdapter from 'services/orders/adapter'

const initialState = {
  results: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const buyingOrders = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BUYING_ORDERS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_BUYING_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        results: OrderAdapter.buyers.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_BUYING_ORDERS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        totalItems: 0,
        totalPages: 0,
        results: [],
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

const sellingOrders = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_SELLING_ORDERS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_SELLING_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        results: OrderAdapter.sellers.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_SELLING_ORDERS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        totalItems: 0,
        totalPages: 0,
        results: [],
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

const ordersBookings = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_ORDERS_BOOKINGS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_ORDERS_BOOKINGS_SUCCESS:
      return Object.assign({}, state, {
        results: OrderAdapter.dashboard.browse.parseResponse(action.payload.data),
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_ORDERS_BOOKINGS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        results: [],
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { buyingOrders, sellingOrders, ordersBookings }
