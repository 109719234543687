import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  getCollectionsByUserId,
  getMyCollectionDetail,
  editMyCollection,
} from 'actions/myCollections';
import { getCollectionCategories, createCategory } from 'actions/category';
import EditCollectionForm from './EditCollectionForm';
import CategoryAdapter from 'services/categories/adapter';

class EditCollection extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    match: PropTypes.object,
    getCollectionCategories: PropTypes.func,
    editMyCollection: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    getMyCollectionDetail: PropTypes.func.isRequired,
    getCollectionsByUserId: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }

  getMyCollections = () => {
    this.props.getCollectionsByUserId({ id: this.props.userInfo.id });
  };

  componentDidMount() {
    this.props.getCollectionCategories({ group: 'Collection' }).then((response) => {
      const categories = CategoryAdapter.productCategoryBrowse.parseResponse(
        response
      );
      this.setState({
        data: categories.data,
      });
    });
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="heading-title mt-2">Edit collection</h3>
        </div>
        <EditCollectionForm
          id={this.props.match.params.productId}
          userInfo={this.props.userInfo}
          categories={data}
          getMyCollections={this.getMyCollections}
          createCategory={this.props.createCategory}
          getMyCollectionDetail={this.props.getMyCollectionDetail}
          editMyCollection={this.props.editMyCollection}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.productCategories,
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getCollectionsByUserId: bindActionCreators(getCollectionsByUserId, dispatch),
  getCollectionCategories: bindActionCreators(getCollectionCategories, dispatch),
  getMyCollectionDetail: bindActionCreators(getMyCollectionDetail, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
  editMyCollection: bindActionCreators(editMyCollection, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(EditCollection);
