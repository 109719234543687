import Config from 'constants/Config'
import { formatJson, formatRoute } from 'shared/helpers'

const exec = params => {
  return {
    method: 'GET',
    url: `/collections/${params.id}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const collection = formatJson(response, response.data.type, response.data.id)

  let user = {}

  if (collection.user) {
    user = {
      id: collection.user.id,
      name: collection.user.fullname,
      // slug: formatRoute(collection.name, '-'),
      slug: formatRoute(collection.user && collection.user.business && collection.user.business.name, '-'),
      email: collection.user.email,
      address: collection.user.address,
      phone: collection.user.phone,
      profileImage: collection.user.image && collection.user.image.url,
      backgroundImage: collection.user.backgroundImage && collection.user.backgroundImage.url,
      qrcode: collection.user.qrcode && collection.user.qrcode.url,
      business: Object.assign({}, collection.user.business)
    }

    delete user.business.user
  }

  const data = {
    name: collection.name,
    primaryImage: collection.primaryImage && collection.primaryImage.url,
    subImage1: collection.subImage1 && collection.subImage1.url,
    subImage2: collection.subImage2 && collection.subImage2.url,
    subImage3: collection.subImage3 && collection.subImage3.url,
    subImage4: collection.subImage4 && collection.subImage4.url,
    subImage5: collection.subImage5 && collection.subImage5.url,
    slug: `${formatRoute(collection.name, '-')}-${collection.id}`,
    description: collection.description,
    type: collection.type,
    averageRating: collection.statistic && collection.statistic.rating ? collection.statistic.rating : 0,
    userId: user && user.id,
    user,
    categoryId: collection.category && collection.category.id,
    categoryName: collection.category && collection.category.name,

  }

  return data
}

export { exec, parseResponse }
