import ListItem from './ListItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';

class List extends Component {
  static propTypes = {
    data: PropTypes.array,
    type: PropTypes.string,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
  };

  render() {
    const listHorizontal =
      this.props.data &&
      this.props.data.map((item, index) => {
        if (item.imageHorizontal) {
          return (
            <ListItem
              key={item.id}
              item={item}
              control={true}
              onDelete={this.props.onDelete}
              id={item.id}
              userId={this.props.userInfo.id}
              screenId={this.props.screenId}
              type={this.props.type}
            />
          );
        }
      });
    const listVertical =
      this.props.data &&
      this.props.data.map((item, index) => {
        if (item.imageVertical) {
          return (
            <ListItem
              key={item.id}
              item={item}
              control={true}
              onDelete={this.props.onDelete}
              id={item.id}
              userId={this.props.userInfo.id}
              screenId={this.props.screenId}
              type={this.props.type}
            />
          );
        }
      });
    return (
      <>
        {/* <h3 className="block-title pt10" style={{ marginRight: 'auto' }}>
          <a
            href={`/slideshow?user_id=${this.props.userInfo.id}&rotate=horizontal`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {window.location.origin +
              `/slideshow?user_id=${this.props.userInfo.id}&rotate=horizontal`}
          </a>
        </h3> */}
        {/* <h3 className="block-title pt10" style={{ marginRight: 'auto' }}>
          <a
            href={`/slideshow?user_id=${this.props.userInfo.id}&rotate=vertical`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {window.location.origin +
              `/slideshow?user_id=${this.props.userInfo.id}&rotate=vertical`}
          </a>
        </h3> */}
        {this.props.type === 'Vertical' ? (
          <Pane className="adds-block mt-1">
            <Pane className="row pt-3 pb-3">{listVertical}</Pane>
          </Pane>
        ) : (
          <Pane className="adds-block mt-1">
            <Pane className="row pt-3 pb-3">{listHorizontal}</Pane>
          </Pane>
        )}
      </>
    );
  }
}

export default List;
