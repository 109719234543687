import Config from "constants/Config";
import { formatJson } from 'shared/helpers';
const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const id = params.id || "";
  const name = params.name || "";
  return {
    method: "GET",
    url: `project?user_id=${id}&name=*${name}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};
const parseResponse = (response) => {
  const projects = [];
  if (response.data) {
    response.data.forEach((item) => {
      const data = formatJson(response, item.type, item.id);
      projects.push(data);
    });
  }
  const data = projects;
  return data;
};
export { exec, parseResponse };