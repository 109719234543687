import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { SignupForm } from 'components/scenes/signup';
import { signup } from 'actions/auth';
import { Pane } from 'shared/components/layouts';

class SignupPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    signup: PropTypes.func.isRequired,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="login-container">
          <Pane className="login-more login-more-signup" />
          <Pane className="wrap-login d-lg-flex d-block align-lg-items-center justify-lg-content-center flex-lg-column">
            <h3 className="login-title"> CREATE YOUR ACCOUNT, ITS FREE</h3>
            <SignupForm signup={this.props.signup} />
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  signup: bindActionCreators(signup, dispatch),
});

export default connect(
  null,
  bindDispatchToProps
)(SignupPage);
