import PropTypes from "prop-types";
import React, { Component } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";

const MenuItem = ({ key, text, selected, image }) => {
	return (
		<div className={`staff-item text-uppercase`}>
			<div className="staff-img">
				<img
					className="ava-img"
					src={image}
					alt=""
				/>
				{selected && (
					<img
						className="icon-check"
						src={`${process.env.PUBLIC_URL}/assets/images/icon-check.png`}
						alt=""
					/>
				)}
			</div>
			<span className="staff-name">{text}</span>
		</div>
	);
};

// All items component
// Important! add unique key
export const Menu = (list, selected) => {
	return list.map((item) => {
		const { id, fullname, images } = item;
		const image = images && images.length > 0 ? images[0].url : `${process.env.PUBLIC_URL}/assets/images/default-image.png`;
		return <MenuItem key={id} text={fullname} selected={parseInt(id) == selected} image={image} />;
	});
};

const Arrow = ({ text, className }) => {
	return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
	text: (
		<img
			src={process.env.PUBLIC_URL + "/assets/images/icons/prev-tab.svg"}
			className="image-logo"
			alt="list-view"
			id="arrow-prev"
		/>
	),
	className: "arrow-prev",
});
const ArrowRight = Arrow({
	text: (
		<img
			src={process.env.PUBLIC_URL + "/assets/images/icons/next-tab.svg"}
			className="image-logo"
			alt="list-view"
			id="arrow-next"
		/>
	),
	className: "arrow-next",
});
class ScrollStaff extends Component {
	render() {
		const { items } = this.props;
		return (
			<ScrollMenu
				data={Menu(items, this.props.selected)}
				selected={this.props.selected}
				arrowLeft={ArrowLeft}
				arrowRight={ArrowRight}
				onSelect={this.props.onSelect}
				translate={0}
				alignCenter={false}
				wheel={false}
			/>
		);
	}
}

ScrollStaff.propTypes = {
	items: PropTypes.array.isRequired,
};

export default ScrollStaff;