import React from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';

class Intro extends React.Component {
  render() {
    const textStyle = {
      background: `url(${
        process.env.PUBLIC_URL
      }/assets/images/bg4.jpg) no-repeat center`,
      backgroundSize: `cover`,
    };

    const { text } = this.props;

    return (
      <Pane className="intro-inner">
        <Pane className="about-intro" style={textStyle}>
          <Pane className="dtable hw100">
            <Pane className="dtable-cell hw100">
              <Pane className="container text-center">
                <h1 className="intro-title animated fadeInDown">{text}</h1>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

Intro.propTypes = {
  text: PropTypes.string,
};

Intro.defaultProps = {
  text: '',
};

export default Intro;
