import Config from 'constants/Config'

const exec = params => {
  return {
    method: 'POST',
    url: `/bookings/${params.id}/items/${params.status}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
