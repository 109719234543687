import * as types from "constants/ActionTypes";
import api from "api";
export const getStaffsByUserId = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_MY_STAFFS,
      types.RECEIVE_MY_STAFFS_SUCCESS,
      types.RECEIVE_MY_STAFFS_FAILURE,
    ],
    payload: {
      request: api.user.staffs(params),
    },
  });
export const getMyStaffAssign = (params) => (dispatch) =>
  dispatch({
    types: types.MY_STAFF_ASSIGN,
    payload: {
      request: api.user.staffAssign(params),
    },
  });
export const getStaffDetail = (params) => (dispatch) =>
  dispatch({
    types: types.MY_STAFF_DETAIL,
    payload: {
      request: api.user.readStaff(params),
    },
  });
export const createMyStaff = (params) => (dispatch) =>
  dispatch({
    types: types.CREATE_MY_STAFF,
    payload: {
      request: api.user.addStaff(params),
    },
  });
export const editMyStaff = (params) => (dispatch) =>
  dispatch({
    types: types.EDIT_MY_STAFF,
    payload: {
      request: api.user.editStaff(params),
    },
  });
export const removeMyStaff = (params) => (dispatch) =>
  dispatch({
    types: types.REMOVE_MY_STAFF,
    payload: {
      request: api.user.removeStaff(params),
    },
  });