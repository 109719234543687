import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';

class Forward extends Component {
  render() {
    return (
      <Pane className="forward-container pt-lg-5 pb-lg-5 pt-3 pb-3 mt-5">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-6 offset-lg-3">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="heading-container text-lg-center text-left pb-4">
                  <h2 className="heading-title">
                    <FormattedMessage
                      id="choose_a_path_forward"
                      defaultMessage="Choose a path forward."
                    />
                  </h2>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-4 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="forward-card">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/landing/launch.png'
                    }
                    alt="stores"
                    className="forward-img"
                  />
                  <Pane className="forward-content">
                    <h3 className="forward-title">
                      <FormattedMessage
                        id="start_your_own_business"
                        defaultMessage="Start your own business"
                      />
                    </h3>
                    <p className="forward-norm">
                      <FormattedMessage
                        id="bring_your_business_ideas_to_life_at_no_cost"
                        defaultMessage="Bring your business ideas to life at no cost. More time, more budget to validate and explore your ideas."
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-4 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="forward-card">
                  <img
                    src={
                      process.env.PUBLIC_URL + '/assets/images/landing/shop.png'
                    }
                    alt="stores"
                    className="forward-img"
                  />
                  <Pane className="forward-content">
                    <h3 className="forward-title">
                      <FormattedMessage
                        id="move_your_business_online"
                        defaultMessage="Move your business online"
                      />
                    </h3>
                    <p className="forward-norm">
                      <FormattedMessage
                        id="got_your_own_brick_and_mortar_store"
                        defaultMessage="Got your own brick-and-mortar store? It’s time to reach
                        more customers and serve them better anywhere, anytime."
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-4 mt-2 mb-2">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <Pane className="forward-card">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/landing/cross.png'
                    }
                    alt="stores"
                    className="forward-img"
                  />
                  <Pane className="forward-content">
                    <h3 className="forward-title">
                      <FormattedMessage
                        id="switch_to_cardbey"
                        defaultMessage="Switch to Cardbey"
                      />
                    </h3>
                    <p className="forward-norm">
                      <FormattedMessage
                        id="no_matter_what_platform_you_using"
                        defaultMessage="No matter what platform you’re using, it’s easy to expand
                        your customer base by switching to Cardbey."
                      />
                    </p>
                  </Pane>
                </Pane>
              </ScrollAnimation>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default Forward;
