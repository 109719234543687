import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Pane } from 'shared/components/layouts';
import { setLocale } from 'actions/locale';

class Language extends React.Component {
  state = {
    selectedLang: this.props.lang,
  };

  onSelect = (value) => {
    this.setState(
      {
        selectedLang: value,
      },
      () => {
        this.props.setLocale(this.state.selectedLang);
        setTimeout(() => {
          document.getElementById('language-dropdown').checked = false;
          window.location.reload();
        }, 1000);
      }
    );
  };

  renderDefault = () => {
    const { selectedLang } = this.state;
    return (
      <>
        <input
          className="language-checkbox"
          type="checkbox"
          id={this.props.for}
        />
        <label className="default-option" htmlFor={this.props.for}>
          <img
            src={
              `${process.env.PUBLIC_URL
              }/assets/images/icons/${selectedLang === 'vi' ? 'vi' : 'en'}.svg`
            }
            alt="store"
            className="icon-flag"
          />
          {selectedLang === 'vi' ? 'VI' : 'EN'}
          <img
            src={
              `${process.env.PUBLIC_URL  }/assets/images/icons/arrow-language.svg`
            }
            alt="store"
            className="icon-down"
          />
        </label>
      </>
    );
  };

  render() {
    const options = [
      {
        key: 'vi',
        value: 'vi',
        text: 'Vietnamese',
        image: `${process.env.PUBLIC_URL  }/assets/images/icons/vi.svg`,
      },
      {
        key: 'en',
        value: 'en',
        text: 'English',
        image: `${process.env.PUBLIC_URL  }/assets/images/icons/en.svg`,
      },
    ];
    return (
      <Pane className="dropdown-language">
        {this.renderDefault()}
        <ul className="list-dropdown">
          {options.map((item) => {
            return (
              <li
                className="item-dropdown"
                key={item.key}
                onClick={() => this.onSelect(item.value)}
              >
                <img src={item.image} alt={item.value} className="icon-flag" />
                {item.text}
              </li>
            );
          })}
        </ul>
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  lang: state.locale.lang,
});

const bindDispatchToProps = (dispatch) => ({
  setLocale: bindActionCreators(setLocale, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(Language);
