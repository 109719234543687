import * as BannerBrowseV1 from './banner.browse.v1.action'
import * as BannerDigitalBrowserV1 from './banner-digital.browse.v1.action';

export default {
  // Get banners
  browse: {
    callAPI: params => BannerBrowseV1.exec(params),
    parseResponse: response => BannerBrowseV1.parseResponse(response)
  },
  digitalBrowse: {
    callAPI: params => BannerDigitalBrowserV1.exec(params),
    parseResponse: response => BannerDigitalBrowserV1.parseResponse(response)
  }
}
