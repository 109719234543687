import * as NotificationSaveV1 from './notification.save.v1.action'
import * as NotificationPushV1 from './notification.push.v1.action'

export default {
  // Save user's devices
  save: {
    callAPI: params => NotificationSaveV1.exec(params),
    parseResponse: response => NotificationSaveV1.parseResponse(response)
  },
  // Push notification
  push: {
    callAPI: id => NotificationPushV1.exec(id),
    parseResponse: response => NotificationPushV1.parseResponse(response)
  }
}
