import { get } from 'lodash';
import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  return {
    method: 'GET',
    url: `/orders-bookings/${params.id}/${params.type}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const data = {
    ...response
  }

  return data
};

export { exec, parseResponse };
