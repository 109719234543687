import * as BusinessSearchV1 from './business.search.v1.action'
import * as SearchAllV1 from './business.all.v1.action'
import * as SearchQuickV1 from './business.quick.v1.action'
import * as BusinessBrowseV1 from './business.browse.v1.action'
import * as BusinessCboostsV1 from './business.cboosts.v1.action'
import * as BusinessProductCategoryV1 from './bussiness.product-category.v1.action';
import * as BusinessReviewV1 from './bussiness.review.v1.action'
import * as BusinessGetReviewV1 from './business.get-review.v1.action'

export default {
  // Search business
  search: {
    callAPI: params => BusinessSearchV1.exec(params),
    parseResponse: response => BusinessSearchV1.parseResponse(response)
  },
  all: {
    callAPI: params => SearchAllV1.exec(params),
    parseResponse: response => SearchAllV1.parseResponse(response)
  },
  quick: {
    callAPI: params => SearchQuickV1.exec(params),
    parseResponse: response => SearchQuickV1.parseResponse(response)
  },
  browse: {
    callAPI: params => BusinessBrowseV1.exec(params),
    parseResponse: response => BusinessBrowseV1.parseResponse(response)
  },
  cboosts: {
    callAPI: params => BusinessCboostsV1.exec(params),
    parseResponse: response => BusinessCboostsV1.parseResponse(response)
  },
  productsCategory: {
    callAPI: params => BusinessProductCategoryV1.exec(params),
    parseResponse: response => BusinessProductCategoryV1.parseResponse(response)
  },
  review: {
    callAPI: id => BusinessReviewV1.exec(id),
    parseResponse: response => BusinessReviewV1.parseResponse(response)
  },
  getReview: {
    callAPI: id => BusinessGetReviewV1.exec(id),
    parseResponse: response => BusinessGetReviewV1.parseResponse(response)
  },
}
