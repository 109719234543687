import Config from 'constants/Config'

const exec = params => {
  const data = {}

  if (params.token_stripe) {
    data.token_stripe = params.token_stripe
  }

  if (params.mode) {
    data.guest_name = params.guestName
    data.guest_email = params.guestEmail
    data.guest_phone = params.guestPhone
    data.guest_note = params.guestNote
    data.mode = params.mode

  }
  data.promotion_code = params.promotionCode
  if (params.note) {
    data.note = params.note
  }

  return {
    method: 'POST',
    url: `/carts/${params.uuid}/checkout`,
    baseURL: Config.NEW_API_URL,
    data
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
