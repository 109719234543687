import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'
const exec = params => {
  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/first-scan-promotion/${params.id}`
  }
}
const parseResponse = response => {
  const product = formatJson(response, response.data.type, response.data.id)
  return product;
}
export { exec, parseResponse }