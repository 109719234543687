import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OrderedCartItem from './OrderedCartItem';
import { formatDecimal } from 'shared/helpers';
import { Pane } from "shared/components/layouts";

class OrderedCartList extends Component {
  static propTypes = {
    detail: PropTypes.object,
    data: PropTypes.object,
  };
  render() {
    const { posCart, total, note } = this.props;
    return (
      <Pane className="order-list">
        {posCart &&
          posCart.map((item) => (
            <OrderedCartItem
              key={item.id}
              data={item}
            />
          ))}
        <Pane className="order-total">
          <FormattedMessage id="total" defaultMessage="TOTAL" />: AUD ${formatDecimal(total)}
        </Pane>
        <Pane className="order-note">
          <FormattedMessage id="note" defaultMessage="Note" />{note || 'None'}
        </Pane>
      </Pane>
    );
  }
}

export default OrderedCartList;
