import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  getServicesByUserId,
  getMyServiceDetail,
  editMyService,
} from 'actions/myServices';
import { getServiceCategories, createCategory } from 'actions/category';
import EditServiceForm from './EditServiceForm';
import { optionsService } from "actions/services";
import {
  createMyOption,
  editMyOption,
  deleteMyOption,
  createMyOptionGroup,
  editMyOptionGroup,
  deleteMyOptionGroup,
} from "actions/options";
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';
import { getStaffsByUserId } from "actions/staffs";
import {
  getServiceMembersByUserId,
  createMyServiceMember,
  removeMyServiceMember,
} from "actions/serviceMembers";


class EditService extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    match: PropTypes.object,
    getServiceCategories: PropTypes.func,
    editMyService: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    getMyServiceDetail: PropTypes.func.isRequired,
    getServicesByUserId: PropTypes.func.isRequired,
  };

  getMyServices = () => {
    this.props.getServicesByUserId({ id: this.props.userInfo.id });
  };

  componentDidMount() {
    this.props.getServiceCategories();
    this.props.getStaffsByUserId({ id: this.props.userInfo.id });

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/services`,
        name: 'Services'
      },
      {
        url: ``,
        name: 'Edit service',
        active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="text-center mb-4" id="anchor">
        </Pane>
        <EditServiceForm
          id={this.props.match.params.serviceId}
          userInfo={this.props.userInfo}
          categories={this.props.categories}
          staffs={this.props.staffs}
          getMyServices={this.getMyServices}
          getMyServiceDetail={this.props.getMyServiceDetail}
          editMyService={this.props.editMyService}
          optionsService={this.props.optionsService}
          createCategory={this.props.createCategory}
          createMyOption={this.props.createMyOption}
          editMyOption={this.props.editMyOption}
          deleteMyOption={this.props.deleteMyOption}
          createMyOptionGroup={this.props.createMyOptionGroup}
          editMyOptionGroup={this.props.editMyOptionGroup}
          deleteMyOptionGroup={this.props.deleteMyOptionGroup}
          getServiceMembersByUserId={this.props.getServiceMembersByUserId}
          createMyServiceMember={this.props.createMyServiceMember}
          removeMyServiceMember={this.props.removeMyServiceMember}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.serviceCategories,
  userInfo: state.auth.user,
  staffs: state.myStaffs,
});

const bindDispatchToProps = (dispatch) => ({
  getServicesByUserId: bindActionCreators(getServicesByUserId, dispatch),
  getServiceCategories: bindActionCreators(getServiceCategories, dispatch),
  getMyServiceDetail: bindActionCreators(getMyServiceDetail, dispatch),
  editMyService: bindActionCreators(editMyService, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
  optionsService: bindActionCreators(optionsService, dispatch),
  createMyOption: bindActionCreators(createMyOption, dispatch),
  editMyOption: bindActionCreators(editMyOption, dispatch),
  deleteMyOption: bindActionCreators(deleteMyOption, dispatch),
  createMyOptionGroup: bindActionCreators(createMyOptionGroup, dispatch),
  editMyOptionGroup: bindActionCreators(editMyOptionGroup, dispatch),
  deleteMyOptionGroup: bindActionCreators(deleteMyOptionGroup, dispatch),
  getServiceMembersByUserId: bindActionCreators(
    getServiceMembersByUserId,
    dispatch
  ),
  createMyServiceMember: bindActionCreators(createMyServiceMember, dispatch),
  removeMyServiceMember: bindActionCreators(removeMyServiceMember, dispatch),
  getStaffsByUserId: bindActionCreators(getStaffsByUserId, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(injectIntl(EditService));
