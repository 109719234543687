import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Placeholder, Segment } from "semantic-ui-react";
import { injectIntl, intlShape } from "react-intl";
import NotificationSystem from "react-notification-system";
import queryString from "query-string";
import { Pane } from "shared/components/layouts";
import { List } from "./list";
import {
  getVideosByUserId,
  deleteVideo,
  getVideosByVideoId,
  viewVideos,
  addLikeVideo,
} from "actions/myVideos";
import { getReviewsVideo, reviewsVideo } from "actions/reviews";
import { getLikesVideo, likesVideo, getUserLikesVideo } from "actions/likes";
import { searchStores } from "actions/search";
import Slideshow from "../show/SlideshowVideo";
import { Scrollbars } from "react-custom-scrollbars";
import { LoginModal } from "shared/components";
import { signin, signinSocial } from 'actions/auth';

class MyVideos extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  state = {
    page: parseInt(queryString.parse(this.props.location.search).page, 10) || 1,
    fetching: false,
    fetched: false,
    keySort: null,
    videos: [],
    videoById: {
      results: [],
    },
    width: 0,
    openModal: false,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
  }

  UNSAFE_componentWillMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  /**
   * Function resize width
   */
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  getVideosByUserId = () => {
    const {
      params: { storeId },
    } = this.props.match;
    this.setState({
      fetched: false,
      fetching: true,
    });
    if (storeId) {
      this.props
        .getVideosByVideoId({
          size: 100,
          id: storeId,
        })
        .then(() => {
          this.setState({
            fetched: true,
            fetching: false,
            videoById: {
              results: this.state.videoById.results.concat(
                this.props.videoById.results
              ),
            },
          });
          this.props
            .getVideosByUserId({
              id: storeId,
              size: 100,
            })
            .then(() => {
              this.setState({
                fetched: true,
                fetching: false,
              });
            })
            .catch(() => {
              this.setState({
                fetched: true,
                fetching: false,
              });
            });
        })
        .catch(() => {
          this.setState({
            fetched: true,
            fetching: false,
          });
        });
    } else {
      this.props
        .getVideosByUserId({
          id: storeId,
          size: 100,
        })
        .then(() => {
          this.setState({
            fetched: true,
            fetching: false,
          });
        })
        .catch(() => {
          this.setState({
            fetched: true,
            fetching: false,
          });
        });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getVideosByUserId();
    });
    this.getVideosByUserId();
    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({behavior: "smooth"});
      }, 1000)
    }
  }

  onChooseVideo = async (record) => {
    await this.setState({
      videos: [],
    });
    await this.setState({
      videos: this.props.myVideos.results.filter(
        (item) => item.id === record.id
      ),
    });
  };

  onOpenModal = () => {
		this.setState({
			openModal: true,
		});
	};

  onCloseModal = () => {
		this.setState({
			openModal: false,
		});
	};

  render() {
    const {
      myVideos,
      auth,
      signin,
      signinSocial,
      match: { params },
    } = this.props;
    const { fetched, fetching, videos, videoById, openModal } = this.state;
    return (
      <Pane className="overview-container" id="anchor">
        <Pane className="row">
          <LoginModal 
            onNotification={this._notificationSystem}
            signin={signin}
            signinSocial={signinSocial}
            auth={auth}
            onCloseModal={this.onCloseModal}
            openModal={openModal}
          />
          <Pane className="col-lg-8">
            {params.storeId && videos.length === 0 ? (
              <Slideshow
                data={videoById.results.length > 0 ? videoById.results : []}
                type="OUR_VIDEO"
                onGetReviewsVideo={this.props.getReviewsVideo}
                onNotification={this._notificationSystem}
                reviews={this.props.reviews}
                onReviewsVideo={this.props.reviewsVideo}
                shareUrl={this.props.match.url}
                onGetLikesVideo={this.props.getLikesVideo}
                onLikesVideo={this.props.likesVideo}
                like={this.props.like}
                auth={auth}
                onGetUserLikesVideo={this.props.getUserLikesVideo}
                userLike={this.props.userLike.results}
                onViewVideo={this.props.viewVideos}
                onAddLikeVideo={this.props.addLikeVideo}
                onOpenModal={this.onOpenModal}
              />
            ) : null}
            {videos.length ? (
              <Slideshow
                data={videos}
                type="OUR_VIDEO"
                onGetReviewsVideo={this.props.getReviewsVideo}
                onNotification={this._notificationSystem}
                reviews={this.props.reviews}
                onReviewsVideo={this.props.reviewsVideo}
                shareUrl={this.props.match.url}
                onGetLikesVideo={this.props.getLikesVideo}
                onLikesVideo={this.props.likesVideo}
                like={this.props.like}
                auth={auth}
                onGetUserLikesVideo={this.props.getUserLikesVideo}
                userLike={this.props.userLike.results}
                onViewVideo={this.props.viewVideos}
                onAddLikeVideo={this.props.addLikeVideo}
                onOpenModal={this.onOpenModal}
              />
            ) : null}
            {videos.length === 0 &&
            myVideos.results &&
            videoById.results.length === 0 ? (
              <Slideshow
                data={
                  myVideos.results.length > 0
                    ? [myVideos.results[0]]
                    : myVideos.results
                }
                type="OUR_VIDEO"
                onGetReviewsVideo={this.props.getReviewsVideo}
                onNotification={this._notificationSystem}
                reviews={this.props.reviews}
                onReviewsVideo={this.props.reviewsVideo}
                shareUrl={this.props.match.url}
                onGetLikesVideo={this.props.getLikesVideo}
                onLikesVideo={this.props.likesVideo}
                like={this.props.like}
                auth={auth}
                onGetUserLikesVideo={this.props.getUserLikesVideo}
                userLike={this.props.userLike.results}
                onViewVideo={this.props.viewVideos}
                onAddLikeVideo={this.props.addLikeVideo}
                onOpenModal={this.onOpenModal}
              />
            ) : null}
          </Pane>
          <Pane className="col-lg-4">
            <Pane className="product-container">
              <div className="product-heading">
                <h3
                  className="block-title text-uppercase"
                  style={{ marginRight: "auto" }}
                >
                  COLLECTION
                </h3>
              </div>
              <Scrollbars
                style={{
                  height: 375,
                  minHeight: "300px",
                }}
              >
                {!fetching && fetched ? (
                  <>
                    {myVideos.results.length <= 0 && (
                      <Pane className="empty-container">
                        <Pane className="empty-content">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/empty.png"
                            }
                            alt="empty"
                            className="img-empty"
                          />
                          <h2 className="empty-title">No items</h2>
                        </Pane>
                      </Pane>
                    )}
                    {myVideos.results.length ? (
                      <List
                        data={myVideos.results}
                        onChooseVideo={this.onChooseVideo}
                        onReviewsVideo={this.props.getReviewsVideo}
                        onGetLikesVideo={this.props.getLikesVideo}
                        like={this.props.like}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Pane className="loading-adds-wrapper loading-white">
                    <Pane className="container">
                      <Pane className="row">
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Segment raised>
                            <Placeholder>
                              <Placeholder.Header className="mb-3">
                                <Placeholder.Image
                                  rectangular
                                  className="mb-3"
                                />
                              </Placeholder.Header>
                              <Placeholder.Paragraph>
                                <Placeholder.Line length="medium" />
                                <Placeholder.Line length="short" />
                              </Placeholder.Paragraph>
                            </Placeholder>
                          </Segment>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                )}
              </Scrollbars>
            </Pane>
            <NotificationSystem ref="notificationSystem" />
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  myVideos: state.myVideos,
  stores: state.stores,
  reviews: state.reviews,
  like: state.likes,
  videoById: state.videoById,
  auth: state.auth,
  userLike: state.userLike,
});

const bindDispatchToProps = (dispatch) => ({
  searchStores: bindActionCreators(searchStores, dispatch),
  getVideosByUserId: bindActionCreators(getVideosByUserId, dispatch),
  deleteVideo: bindActionCreators(deleteVideo, dispatch),
  getReviewsVideo: bindActionCreators(getReviewsVideo, dispatch),
  reviewsVideo: bindActionCreators(reviewsVideo, dispatch),
  getLikesVideo: bindActionCreators(getLikesVideo, dispatch),
  likesVideo: bindActionCreators(likesVideo, dispatch),
  getVideosByVideoId: bindActionCreators(getVideosByVideoId, dispatch),
  getUserLikesVideo: bindActionCreators(getUserLikesVideo, dispatch),
  viewVideos: bindActionCreators(viewVideos, dispatch),
  addLikeVideo: bindActionCreators(addLikeVideo, dispatch),
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(MyVideos))
);
