import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'shared/components/layouts'
import { FormattedMessage } from 'react-intl'

class BusinessInfo extends Component {
  static propTypes = {
    business: PropTypes.object.isRequired
  }

  render() {
    const { business } = this.props

    return (
      <Pane className='tooltip-container'>
        <h4 className="tooltip-title">
          <FormattedMessage
            id='business_profile'
            defaultMessage='BUSINESS PROFILE'
          />
        </h4>
        <Pane className='tooltip-row'>
          <b>
            <FormattedMessage
              id='name'
              defaultMessage='Name'
            />:&nbsp;
          </b>
          <span className="name">{ business.name }</span>
        </Pane>
        <Pane className='tooltip-row'>
          <b>
            <FormattedMessage
              id='address'
              defaultMessage='Address'
            />:&nbsp;
          </b>
          <span className="name">{ business.address }</span>
        </Pane>
        <Pane className='tooltip-row'>
          <b>
            <FormattedMessage
              id='email'
              defaultMessage='Email'
            />:&nbsp;
          </b>
          <span className="email">{ business.email }</span>
        </Pane>
        <Pane className='tooltip-row'>
          <b>
            <FormattedMessage
              id='phone'
              defaultMessage='Phone'
            />:&nbsp;
          </b>
          <span className="phone">{ business.phone }</span>
        </Pane>
        <Pane className='tooltip-row'>
          <b>
            <FormattedMessage
              id='description'
              defaultMessage='Description'
            />:&nbsp;
          </b>
          <span className="description">{ business.description }</span>
        </Pane>
      </Pane>
    )
  }
}

export default BusinessInfo
