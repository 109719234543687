import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  return {
    method: 'GET',
    url: `/videos?page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const data = [];
  if (response.data) {
    response.data.forEach((item) => {
      const video = formatJson(response, item.type, item.id);
      data.push(video);
    });
  }
  return data;
};

export { exec, parseResponse };
