import * as types from 'constants/ActionTypes'
import api from 'api';

export const getAreaByBusiness = () => (dispatch) => dispatch({
    types: [types.RECEIVE_AREAS_BY_BUSINESS, types.RECEIVE_AREAS_BY_BUSINESS_SUCCESS, types.RECEIVE_AREAS_BY_BUSINESS_FAILURE],
    payload: {
        request: api.area.get(),
    },
})

export const getAreas = () => (dispatch) => dispatch({
    types: [types.RECEIVE_AREAS, types.RECEIVE_AREAS_SUCCESS, types.RECEIVE_AREAS_FAILURE],
    payload: {
        request: api.area.getAll(),
    },
})

export const addArea = (params) => (dispatch) => dispatch({
    type: types.ADD_USER_AREA,
    stateArea: params.state,
    cityArea: params.city
})

export const addType = (params) => (dispatch) => dispatch({
    type: types.ADD_TYPE,
    searchType: params,
})