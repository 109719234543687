import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import Banner from './banner/Banner';
import Delivery from './delivery/Delivery';
import Business from './business/Business';
import Forward from './forward/Forward';
import BusinessBanner from './business/BusinessBanner';
import Wherever from './wherever/Wherever';

class Landing extends Component {
  render() {
    return (
      <Pane className="landing-container">
        <Banner
          statistics={this.props.statistics}
          getStatisticUser={this.props.getStatisticUser}
        />
        <Delivery />
        <Business />
        <Forward />
        <Wherever />
        <BusinessBanner />
      </Pane>
    );
  }
}

export default Landing;
