import * as types from 'constants/ActionTypes'
import ServiceAdapter from 'services/services/adapter'

const initialState = {
  data: {},
  error: null,
  fetching: false,
  fetched: false
}

const serviceDetail = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_SERVICE_DETAIL:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_SERVICE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        data: ServiceAdapter.read.parseResponse(action.payload.data),
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_SERVICE_DETAIL_FAILURE:
      return Object.assign({}, state, {
        data: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        data: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { serviceDetail }
