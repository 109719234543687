import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Placeholder, Segment, Select, Form } from "semantic-ui-react";
import { injectIntl, intlShape } from "react-intl";
import NotificationSystem from "react-notification-system";
import queryString from "query-string";
import { Pane } from "shared/components/layouts";
import { List } from "./list";
import {
  deleteVideo,
  viewVideos,
  addLikeVideo,
} from "actions/myVideos";
import { getReviewsVideo, reviewsVideo } from "actions/reviews";
import { getLikesVideo, likesVideo, getUserLikesVideo } from "actions/likes";
import MainCollection from "./MainCollection";
import { Scrollbars } from "react-custom-scrollbars";
import { getCollectionsByUserId } from 'actions/myCollections';
import { getUserInfo } from "actions/auth";
import PropTypes from 'prop-types';
import { ScrollMenuCollection } from "components/common/scrollMenu";
import _ from 'lodash';
import { signin, signinSocial } from 'actions/auth';

class ShowCollection extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    stateCollections: PropTypes.object,
    getCollectionsByUserId: PropTypes.func,
    signin: PropTypes.func.isRequired,
    signinSocial: PropTypes.func.isRequired,
  };

  state = {
    fetching: false,
    fetched: false,
    keySort: null,
    videoById: {
      results: [],
    },
    width: 0,
    type: 'All',
    chooseCollection: [],
    myCollections: [],
    filterCollections: [],
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
  }

  UNSAFE_componentWillMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  /**
   * Function resize width
   */
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getCollectionsByUserId();
    });
    this.getCollectionsByUserId();
    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({behavior: "smooth"});
      }, 1000)
    }
  }

  getCollectionsByUserId = () => {
    this.props
      .getCollectionsByUserId({
        id: this.props.userId,
        size: 100,
        group: 'Menu',
        images: '',
      }).then(() => {
        this.setState({
          fetched: true,
          fetching: false,
          myCollections: this.props.stateCollections,
        });
      });
  };

  onChooseVideo = (record) => {
    this.setState({
      chooseCollection: [],
    });
    this.setState({
      chooseCollection: this.state.myCollections.results.filter(
        (item) => item.id === record.id
      ),
    });
  };

  onSelecType = (e, s) => {
    this.setState({
      type: s.value,
      fetched: false
    }, () => {
      this.filterCollection();
      this.setState({
        fetched: true
      })
    });
  };

  onSelect = (key) => {
    const { keySort } = this.state;
    const newKey = keySort === key ? null : key
    this.setState({
      keySort: newKey,
    }, () => {
      this.filterCollection();
    })
  };

  filterCollection = () => {
    const { type, myCollections, keySort } = this.state;
    const newCollection = myCollections.results.filter((collection) => {
      if (type !== 'All' && keySort !== null) {
        return (collection.category_name === this.state.keySort && collection.typeCollect === this.state.type);
      } else if (type !== 'All') {
        return collection.typeCollect === this.state.type
      } else if (keySort !== null) {
        return collection.category_name === this.state.keySort
      }
    })
    this.setState({
      chooseCollection: [],
      filterCollections: newCollection,
    })
  }

  render() {
    const {
      auth,
      match: { params },
      intl
    } = this.props;
    const types = [
      {
        value: 'All',
        text: intl.formatMessage({ id: 'all' }),
      },
      {
        value: 'Video',
        text: intl.formatMessage({ id: 'videos' }),
      },
      {
        value: 'Image',
        text: intl.formatMessage({ id: 'images' }),
      },
      {
        value: 'Post',
        text: intl.formatMessage({ id: 'posts' }),
      },
    ];
    const { fetched, fetching, keySort, myCollections, chooseCollection, filterCollections, type } = this.state;
    const items = _.chain(myCollections.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .sort(
        (a, b) =>
          new Date(_.get(b.items[0], "category.createdAt")).getTime() / 1000 -
          new Date(_.get(a.items[0], "category.createdAt")).getTime() / 1000
      )
      .value();

    return (
      <Pane className="overview-container" id="anchor">
        <Pane className="row">
          <Pane className="col-lg-8">
            <div className="product-heading">
              <h3
                className="block-title text-uppercase"
                style={{ marginRight: "auto" }}
              >
                <i className="icon-bookmarks" />
                COLLECTION
              </h3>
            </div>
            {!fetching && fetched ? (
              <>
              {chooseCollection.length > 0 ? (
                <MainCollection
                  data={chooseCollection}
                  type="OUR_VIDEO"
                  onGetReviewsVideo={this.props.getReviewsVideo}
                  onNotification={this._notificationSystem}
                  reviews={this.props.reviews}
                  onReviewsVideo={this.props.reviewsVideo}
                  shareUrl={this.props.match.url}
                  onGetLikesVideo={this.props.getLikesVideo}
                  onLikesVideo={this.props.likesVideo}
                  like={this.props.like}
                  auth={auth}
                  onGetUserLikesVideo={this.props.getUserLikesVideo}
                  onGetCollectionsByUserId={this.getCollectionsByUserId}
                  userLike={this.props.userLike.results}
                  onViewVideo={this.props.viewVideos}
                  onAddLikeVideo={this.props.addLikeVideo}
                  isFront={this.props.isFront}
                  signin={this.props.signin}
                  signinSocial={this.props.signinSocial}
                />
              ) : null}
              {chooseCollection.length === 0 && myCollections.results.length > 0 ? (
                <MainCollection
                  data={type !== 'All' || keySort !== null ? filterCollections : myCollections.results}
                  type="OUR_VIDEO"
                  onGetReviewsVideo={this.props.getReviewsVideo}
                  onNotification={this._notificationSystem}
                  reviews={this.props.reviews}
                  onReviewsVideo={this.props.reviewsVideo}
                  shareUrl={this.props.match.url}
                  onGetLikesVideo={this.props.getLikesVideo}
                  onLikesVideo={this.props.likesVideo}
                  like={this.props.like}
                  auth={auth}
                  onGetUserLikesVideo={this.props.getUserLikesVideo}
                  onGetCollectionsByUserId={this.getCollectionsByUserId}
                  userLike={this.props.userLike.results}
                  onViewVideo={this.props.viewVideos}
                  onAddLikeVideo={this.props.addLikeVideo}
                  isFront={this.props.isFront}
                  signin={this.props.signin}
                  signinSocial={this.props.signinSocial}
                />
              ) : null}
            </>
            ) : (
              <Pane className="loading-adds-wrapper loading-white">
                <Pane className="container">
                  <Pane className="row">
                    <Pane className="col-12">
                      <Segment raised>
                        <Placeholder style={{maxWidth: 'none'}}>
                          <Placeholder.Header className="mb-3">
                            <Placeholder.Image
                              rectangular
                              className="mb-3"
                            />
                          </Placeholder.Header>
                          <Placeholder.Paragraph>
                            <Placeholder.Line length="medium" />
                            <Placeholder.Line length="short" />
                          </Placeholder.Paragraph>
                        </Placeholder>
                      </Segment>
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>
          )}
          </Pane>
          <Pane className="col-lg-4 collection-list">
            {!_.isEmpty(items) && (
              <div className="scroll-bar">
                <ScrollMenuCollection
                  items={items}
                  selectedName={keySort}
                  onSelect={this.onSelect}
                />
              </div>
            )}
            <Form.Field
              control={Select}
              scrolling
              className="form-item"
              name="type"
              label={intl.formatMessage({
                id: "type",
                defaultMessage: "Type",
              })}
              value={type}
              options={types}
              onChange={this.onSelecType}
            />
            <Pane className="product-container">
              <Scrollbars
                style={{
                  height: '450px',
                  minHeight: "450px",
                }}
              >
                {!fetching && fetched ? (
                  <>
                    {((type !== 'All' || keySort !== null)  ? filterCollections.length : myCollections.results.length) <= 0 && (
                        <Pane className="empty-container">
                          <Pane className="empty-content">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/assets/images/icons/empty.png'
                              }
                              alt="empty"
                              className="img-empty"
                            />
                            <h2 className="empty-title">No items</h2>
                          </Pane>
                        </Pane>
                      )
                    }
                    {((type !== 'All' || keySort !== null)  ? filterCollections.length : myCollections.results.length) > 0 && (
                      <List
                        data={(type !== 'All' || keySort !== null)  ? filterCollections : myCollections.results}
                        onChooseVideo={this.onChooseVideo}
                        idUser={this.props.auth.user.id}
                        onReviewsVideo={this.props.getReviewsVideo}
                        onGetLikesVideo={this.props.getLikesVideo}
                        onGetUserLikesVideo={this.props.getUserLikesVideo}
                      />
                    )}
                  </>
                ) : (
                  <Pane className="loading-adds-wrapper loading-white">
                    <Pane className="container">
                      <Pane className="row">
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Segment raised>
                            <Placeholder>
                              <Placeholder.Header className="mb-3">
                                <Placeholder.Image
                                  rectangular
                                  className="mb-3"
                                />
                              </Placeholder.Header>
                              <Placeholder.Paragraph>
                                <Placeholder.Line length="medium" />
                                <Placeholder.Line length="short" />
                              </Placeholder.Paragraph>
                            </Placeholder>
                          </Segment>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                        <Pane className="col-lg-3 col-md-4 pt-2 pb-2">
                          <Placeholder>
                            <Placeholder.Header className="mb-3">
                              <Placeholder.Image rectangular className="mb-3" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                              <Placeholder.Line length="medium" />
                              <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                          </Placeholder>
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                )}
              </Scrollbars>
            </Pane>
            <NotificationSystem ref="notificationSystem" />
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  myVideos: state.myVideos,
  stores: state.stores,
  reviews: state.reviews,
  like: state.likes,
  videoById: state.videoById,
  auth: state.auth,
  userLike: state.userLike,
  stateCollections: state.myCollections,
  userInfo: state.auth && state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  deleteVideo: bindActionCreators(deleteVideo, dispatch),
  getReviewsVideo: bindActionCreators(getReviewsVideo, dispatch),
  reviewsVideo: bindActionCreators(reviewsVideo, dispatch),
  getLikesVideo: bindActionCreators(getLikesVideo, dispatch),
  likesVideo: bindActionCreators(likesVideo, dispatch),
  getUserLikesVideo: bindActionCreators(getUserLikesVideo, dispatch),
  viewVideos: bindActionCreators(viewVideos, dispatch),
  addLikeVideo: bindActionCreators(addLikeVideo, dispatch),
  getCollectionsByUserId: bindActionCreators(getCollectionsByUserId, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(ShowCollection))
);
