import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// import { formatDecimal } from 'shared/helpers';
import Currency from './Currency';
import { Pane } from 'shared/components/layouts';

const PriceItem = ({ originalPrice, promotion }) => {
  return (
    <Pane className="price-item">
      {promotion && promotion.id ? (
        promotion.percent ? (
          <h5 className="adds-price">
            <Currency
              price={(originalPrice - (originalPrice * promotion.percent) / 100)}
            />
            <br />
            <span className="text-red text-underline">
              <Currency price={(originalPrice)} />
            </span>
          </h5>
        ) : (
            <h5 className="adds-price">
              <span className="item_price">
                <Currency price={(originalPrice)} />
              </span>
            </h5>
          )
      ) : (
          <h5 className="adds-price">
            <span className="item_price">
              <Currency price={originalPrice} />
            </span>
          </h5>
        )}
    </Pane>
  );
};

PriceItem.propTypes = {
  promotion: PropTypes.any,
  originalPrice: PropTypes.any,
};

PriceItem.defaultProps = {
  promotion: {},
  originalPrice: 0,
};

export default PriceItem;
