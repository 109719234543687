import React, { Component } from 'react';
import { MasterLayout } from 'components/layout';
import { Pane } from 'shared/components/layouts';
import { FoodOrder } from 'components/scenes/food-order';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getFoodOrderCategories,
  getFoodOrderUserCategories,
  getDetailCategories,
} from 'actions/category';
import { getBusinessFoodOrder, getProductByBussiness } from 'actions/products';
import { addItemToCart } from 'actions/cart';

class FoodOrderPage extends Component {
  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <Pane className="product-container pt-5 pb-5">
            <FoodOrder
              getFoodOrderCategories={this.props.getFoodOrderCategories}
              getFoodOrderUserCategories={this.props.getFoodOrderUserCategories}
              getBusinessFoodOrder={this.props.getBusinessFoodOrder}
              addItemToCart={this.props.addItemToCart}
              auth={this.props.auth}
              getDetailCategories={this.props.getDetailCategories}
              getProductByBussiness={this.props.getProductByBussiness}
            />
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  getFoodOrderCategories: bindActionCreators(getFoodOrderCategories, dispatch),
  getFoodOrderUserCategories: bindActionCreators(
    getFoodOrderUserCategories,
    dispatch
  ),
  getBusinessFoodOrder: bindActionCreators(getBusinessFoodOrder, dispatch),
  addItemToCart: bindActionCreators(addItemToCart, dispatch),
  getDetailCategories: bindActionCreators(getDetailCategories, dispatch),
  getProductByBussiness: bindActionCreators(getProductByBussiness, dispatch),
});

const bindStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(FoodOrderPage);
