import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import ListItem from './ListItem';

class ProductList extends Component {
  static propTypes = {
    auth: PropTypes.object,
    products: PropTypes.array,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
  };

  render() {
    const list =
      this.props.products &&
      this.props.products.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.name}
            index={index}
            image={
              this.props.type === 'bussiness'
                ? item.profileImage
                : item.primaryImage
            }
            price={item.originalPrice}
            rating={item.rating}
            onAddItemToCart={this.props.onAddItemToCart}
            onAddItemToGuestCart={this.props.onAddItemToGuestCart}
            onNotification={this.props.onNotification}
            modeView={this.props.modeView}
            auth={this.props.auth}
            type={this.props.type}
          />
        );
      });

    return (
      <Pane className="adds-block">
        <Pane className="row pt-3 pb-3">{list}</Pane>
      </Pane>
    );
  }
}

ProductList.propTypes = {
  type: PropTypes.string,
};

ProductList.defaultProps = {
  type: '',
};

export default ProductList;
