import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Dimmer, TextArea, Button } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import NotificationSystem from 'react-notification-system';
import { Pane, Loading } from 'shared/components/layouts';
import { getBookingDetailsOfBuyers } from 'actions/bookings';
import { pushNotification } from 'actions/notification';
import { requestCancel } from 'actions/bookings';
import { getNotisByUser } from 'actions/notis';
import BookingSummary from './BookingSummary';
import BookingSubDetail from './BookingSubDetail';
import BookedServiceList from './BookedServiceList';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import { handleError, formatRoute } from 'shared/helpers';
import Modal from 'react-responsive-modal';
import Config from 'constants/Config';
import { Breadcumb } from '../../common/breadcumb';

class BookedServiceDetail extends Component {
  static propTypes = {
    detail: PropTypes.object,
    getBookingDetailsOfBuyers: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    detail: {},
    loading: false,
    data: this.props.data || {},
    reason: '',
    open: false,
    errors: {},
    loadingRequestCancel: false,
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  getBookingDetailsOfBuyers = (load) => {
    this.setState({ loading: load });
    this.props
      .getBookingDetailsOfBuyers({
        bookingId: this.props.match.params.bookingId,
      })
      .then((response) => {
        if (load && this.props.detail.updateRead) {
          this.props.getNotisByUser({ id: this.props.userInfo.id });
        }
        this.setState({
          detail: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.getBookingDetailsOfBuyers(true);

    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      this.props &&
      prevProps.match.params.bookingId !== this.props.match.params.bookingId
    ) {
      this.getBookingDetailsOfBuyers(true);
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      errors: {},
    });
  };

  requestCancel = () => {
    const { intl, detail } = this.props;
    const data = detail && detail.bookingItems && detail.bookingItems.length > 0 && detail.bookingItems[0];
    let bookingId = this.props.match.params.bookingId;
    const params = {
      id: bookingId,
      reason: this.state.reason,
    };

    this.setState({ loadingRequestCancel: true });
    this.onCloseModal();
    this.props.requestCancel(params)
      .then((response) => {
        this.getBookingDetailsOfBuyers(false);

        // Push notification to seller
        // this.props.pushNotification({
        //   object_id: bookingId.toString(),
        //   object_type: 'Booking',
        //   target_id: data.seller.id.toString(),
        //   target_type: 'Seller',
        //   summary: `Request cancel in Booking #${bookingId}`,
        //   meta: {
        //     url: `/u/${formatRoute(
        //       data.seller.name,
        //       '-'
        //     )}/bookings/sellers/${bookingId}/detail`,
        //     serviceId: data.serviceId.toString(),
        //     serviceName: data.serviceName,
        //   },
        // })
        //   .catch();

        this._notificationSystem.addNotification({
          message: intl.formatMessage({
            id: 'notification.you_have_sent_request_successfully',
          }),
          level: 'success',
        });

        this.setState({ loadingRequestCancel: false });
      })
      .catch((errors) => {
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({
              id: 'notification.can_not_request_cancel_this_product',
            })
          ),
          level: 'error',
        });

        this.setState({ loadingRequestCancel: false });
      });
  };

  render() {
    const { detail } = this.props;
    const { loading } = this.state;
    const data = detail && detail.bookingItems && detail.bookingItems.length > 0 && detail.bookingItems[0];
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/bookings`,
        name: 'Bookings'
      },
      {
        url: ``, name: 'Booking detail', active: true
      }
    ];

    return (
      <Pane className="overview-container">
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="product-container order-container">
          <h3 className="block-title pt10 text-uppercase">
            <FormattedMessage
              id="manage_booked_services"
              defaultMessage="Manage booked services"
            />
          </h3>
          <Pane className="order-detail" id="anchor">
            {this.state.loading && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {detail && detail.id ? (
              <BookingSummary detail={detail} />
            ) : (
              <Pane className="no-detail" style={{ textAlign: 'center' }}>
                <FormattedMessage
                  id="notification.cannot_get_order_details"
                  defaultMessage="Cannot get order details"
                />
              </Pane>
            )}
          </Pane>
          <Pane className="order-detail mt20">
            <h3 className="block-title order-business-name">
              {detail && detail.bookingItems && !isEmpty(detail.bookingItems[0]) && (
                <>
                  Staff: {detail.bookingItems[0].staff ? detail.bookingItems[0].staff.fullname : 'Store pick for you.'}
                </>
              )}
            </h3>
            <Pane className="products-grids">
              <Pane className="card-body">
                {detail && (
                  <BookedServiceList
                    detail={detail}
                  />
                )}
              </Pane>
            </Pane>
          </Pane>
          <Pane className="order-detail">
            {this.state.loading && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {detail && detail.id ? (
              <BookingSubDetail detail={detail} />
            ) : (
              <Pane className="no-detail" style={{ textAlign: 'center' }}>
                <FormattedMessage
                  id="notification.cannot_get_order_details"
                  defaultMessage="Cannot get order details"
                />
              </Pane>
            )}
          </Pane>
          {!loading && data && (
            <Pane className="change-status">
              {data.status !== 'Cancelled' ? (
                <Pane>
                  {data.cancelStatus === 'REQUESTED' && (
                    <p>
                      <FormattedMessage
                        id="notification.you_have_sent_cancellation_request"
                        defaultMessage="You have sent cancellation request"
                      />
                    </p>
                  )}
                  {data.cancelStatus === 'DENIED' && (
                    <p>
                      <FormattedMessage
                        id="notification.your_request_has_been_denied_by_seller"
                        defaultMessage="Your request has been denied by seller"
                      />
                    </p>
                  )}
                  {!data.cancelStatus && (
                    <Button
                      className="btn-confirm"
                      color="orange"
                      size="mini"
                      onClick={this.onOpenModal}
                      loading={this.state.loadingRequestCancel}
                      disabled={this.state.loadingRequestCancel}
                    >
                      <FormattedMessage
                        id="request_cancel"
                        defaultMessage="Request cancel"
                      />
                    </Button>
                  )}
                </Pane>
              ) : (
                <p>
                  <FormattedMessage
                    id={`order.${Config.ORDER_STATUS.CANCELLED.toLowerCase()}`}
                    defaultMessage={Config.ORDER_STATUS.CANCELLED}
                  />
                </p>
              )}
              <Modal
                open={this.state.open}
                onClose={this.onCloseModal}
                center
                showCloseIcon={false}
              >
                <Pane className="react-confirm-alert-body">
                  <h1>
                    <FormattedMessage
                      id="confirmation"
                      defaultMessage="Confirmation"
                    />
                  </h1>
                  <p>
                    <FormattedMessage
                      id="notification.are_you_sure_you_want_to_cancel_this"
                      defaultMessage="Are you sure you want to cancel this"
                    />
                    ?
                  </p>
                  <TextArea
                    name="reason"
                    className="form-control"
                    value={this.state.reason}
                    placeholder="Your reason"
                    onChange={this.onChange}
                  />
                  {/* { errors.reason && <InlineMessage text={errors.reason} /> } */}
                  <Pane className="react-confirm-alert-button-group">
                    <button onClick={() => this.requestCancel()}>
                      <FormattedMessage id="yes" defaultMessage="Yes" />
                    </button>
                    <button onClick={this.onCloseModal}>
                      <FormattedMessage id="no" defaultMessage="No" />
                    </button>
                  </Pane>
                </Pane>
              </Modal>
            </Pane>
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: state.buyingBookingDetail.data,
  userInfo: state.auth && state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getBookingDetailsOfBuyers: bindActionCreators(
    getBookingDetailsOfBuyers,
    dispatch
  ),
  requestCancel: bindActionCreators(requestCancel, dispatch),
  pushNotification: bindActionCreators(pushNotification, dispatch),
  getNotisByUser: bindActionCreators(getNotisByUser, dispatch),
});

export default injectIntl(connect(
  mapStateToProps,
  bindDispatchToProps
)(BookedServiceDetail));
