import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Validator from 'validator';
import { Pane } from 'shared/components/layouts';
import { InlineMessage } from 'shared/components';
import NotificationSystem from 'react-notification-system';
import { handleError } from 'shared/helpers';

class ContactUsForm extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  state = {
    data: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    loading: false,
    redirect: false,
    errors: {},
  };

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.name) {
      errors.name = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (!data.email) {
      errors.email = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
    }
    if (!data.subject) {
      errors.subject = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (!data.message) {
      errors.message = intl.formatMessage({ id: 'validate.require_field' });
    }

    return errors;
  };

  onSubmit = () => {
    let errors = {};
    const { intl } = this.props;

    errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = {
        name: this.state.data.name,
        email: this.state.data.email,
        subject: this.state.data.subject,
        content: this.state.data.message,
      };

      this.props
        .contact(data)
        .then((res) => {
          this.setState({ loading: false });
          this.clearData();

          this._notificationSystem.addNotification({
            message: intl.formatMessage({ id: 'notification.successfully' }),
            level: 'success',
          });
        })
        .catch((errors) => {
          this.setState({ loading: false });
          this._notificationSystem.addNotification({
            message: handleError(
              errors,
              intl.formatMessage({ id: 'notification.please_try_again' })
            ),
            level: 'error',
          });
        });
    }
  };

  clearData = () => {
    this.setState({
      data: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
    });
  };

  render() {
    const textStyle = {
      height: 'auto',
    };

    const { data, loading, errors } = this.state;
    const { intl } = this.props;

    return (
      <Pane>
        <Form onSubmit={this.onSubmit} className="contact-form" noValidate>
          <Form.Field error={!!errors.name} className="form-item">
            <label htmlFor="name" className="form-label">
              NAME
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={intl.formatMessage({ id: 'name' })}
              value={data.name}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.name && <InlineMessage text={errors.name} />}
          </Form.Field>
          <Form.Field error={!!errors.email} className="form-item">
            <label htmlFor="email" className="form-label">
              EMAIL
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={intl.formatMessage({ id: 'email' })}
              value={data.email}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.email && <InlineMessage text={errors.email} />}
          </Form.Field>
          <Form.Field error={!!errors.subject} className="form-item">
            <label htmlFor="subject" className="form-label">
              SUBJECT
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder={intl.formatMessage({ id: 'subject' })}
              value={data.subject}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.subject && <InlineMessage text={errors.subject} />}
          </Form.Field>
          <Form.Field error={!!errors.message} className="form-item">
            <label for="message" className="form-label">
              MESSAGE
            </label>
            <textarea
              id="message"
              name="message"
              value={data.message}
              placeholder={intl.formatMessage({ id: 'content' })}
              rows="5"
              style={textStyle}
              className="form-input"
              onChange={this.onChange}
            />
            {errors.message && <InlineMessage text={errors.message} />}
          </Form.Field>
          <Form.Field className="form-item form-button">
            <Button
              type="submit"
              className="btn btn-circle btn-form"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="send" defaultMessage="Send" />
            </Button>
          </Form.Field>
        </Form>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

export default injectIntl(ContactUsForm);
