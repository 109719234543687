import Config from 'constants/Config'
import { formatJson, formatRoute } from 'shared/helpers'

const exec = params => {
  const page = params && params.page ? params.page : 1
  const size = params && params.size ? params.size : 100

  return {
    method: 'GET',
    url: `/business/cboosts?page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const businesses = []

  if (response.data) {
    response.data.forEach(item => {
      const business = formatJson(response, item.type, item.id)

      businesses.push({
        id: business.user.id,
        name: business.user.fullname,
        slug: formatRoute(business.name, '-'),
        backgroundImage: business.user.backgroundImage && business.user.backgroundImage.url,
        profileImage: business.user.image && business.user.image.url,
        qrcode: business.user.qrcode && business.user.qrcode.url,
        business: {
          id: business.id,
          name: business.name,
          email: business.email,
          description: business.description,
          phone: business.phone,
          address: business.address,
          postCode: business.postCode
        }
      })
    })
  }

  const data = businesses

  return data
}

export { exec, parseResponse }
