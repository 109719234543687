import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';
import { confirmAlert } from 'react-confirm-alert';
import Config from "constants/Config";
import Slider from 'react-slick';
import { Icon } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      extVideo: Config.extVideo
    };
  }

  changeRoute = () => {
    this.props.history.push(`digital-slideshow/screen/${this.props.id}/edit`);
  };

  showConfirmDelete = () => {
    const { intl } = this.props;

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({
        id: 'notification.are_you_sure_you_want_to_delete_this',
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.deleteItem();
          },
        },
        {
          label: intl.formatMessage({ id: 'no' }),
        },
      ],
    });
  };

  deleteItem = () => {
    this.props.onDelete({ id: this.props.id });
  };

  copyLink = () => {
    let copyText = window.location.origin + `/slideshow?screen_id=${this.props.item.url}`;

    navigator.clipboard.writeText(copyText);
  }

  render() {
    const { item } = this.props;
    return (
      <Pane className="col-lg-6 col-12">
        <Pane className="adds-item">
          <Pane className="adds-content">
            <Pane>
              <h4 className="adds-norm" title={get(item, 'name')}>
                <b>{get(item, 'name')} - {get(item, 'type')}</b>
              </h4>
            </Pane>
            <Pane className="hover-image">
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: get(item, 'primaryImage.url')
                    ? `url(${get(item, 'primaryImage.url')})`
                    : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                }}
              >
              </Pane>
            </Pane>
            <Pane className="d-flex justify-content-between align-items-center">
              {
                item.adminActiveFlg &&
                <>
                    <h3 className="block-title pt10 d-inline-block" style={{ marginRight: 'auto' }}>
                    <a
                      href={`/slideshow?screen_id=${item.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >Preview</a>
                  </h3>
                  <span className="cursor-pointer" onClick={this.copyLink} data-tip data-for="copy">
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/icons/copy.svg`
                      }
                      style={{ width: '20px' }}
                      alt="copy"
                      className="icon-copy"
                    />
                  </span>

                  <ReactTooltip place="top" type="dark" effect="solid" id="copy">
                    Copy
                  </ReactTooltip>
                </>
              }
              {
                !item.adminActiveFlg &&
                <>
                    <h4 className="block-title pt10 d-inline-block" style={{ marginRight: 'auto' }}>
                    Disabled by admin &nbsp;
                    <i className="fas fa-question-circle"  data-tip
                    data-for={`item-info${item.id}`}></i>
                  </h4>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="float"
                    id={`item-info${item.id}`}
                  >
                     {item.disableReason}
                  </ReactTooltip>
                </>
              }

            </Pane>
            {this.props.control && (
              <Pane className="adds-actions position-static">
                <span onClick={this.changeRoute} data-tip data-for="edit">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/edit.svg`
                    }
                    alt="cart"
                    className="icon-edit"
                  />
                </span>
                <span
                  onClick={this.showConfirmDelete}
                  data-tip
                  data-for="remove"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/delete.svg`
                    }
                    alt="cart"
                    className="icon-delete"
                  />
                </span>
              </Pane>
            )}
          </Pane>
        </Pane>
        <ReactTooltip place="top" type="dark" effect="solid" id="edit">
          Update or Create new digital
        </ReactTooltip>
        <ReactTooltip place="top" type="dark" effect="solid" id="remove">
          <FormattedMessage id="delete" defaultMessage="Delete" />
        </ReactTooltip>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
