import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import { formatRoute, handleError, quill } from "shared/helpers";
import InlineMessage from "shared/components/InlineMessage";
import { Button, Form } from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";

class CreateProductForm extends Component {
  static propTypes = {
    products: PropTypes.object,
    intl: intlShape.isRequired,
    categories: PropTypes.object.isRequired,
    getMyProducts: PropTypes.func.isRequired,
    createMyProduct: PropTypes.func.isRequired,
  };

  state = {
    data: {
      name: "",
      description: "",
    },
    errors: {},
    open: false,
    loading: false,
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
    return errors;
  };

  create = () => {
    const { userInfo } = this.props;
    const errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new FormData();
      data.append("name", this.state.data.name);
      data.append("description", this.state.data.description);
      data.append("business_id", userInfo.business.id);

      this.props
        .createMyTables(data)
        .then(() => {
          this.setState({
            loading: false,
            data: {
              ...this.state.data,
              name: "",
              description: "",
            },
          });
          // this.props.getMyProducts()
          this.props.history.push(
            `/u/${formatRoute(this.props.userInfo.displayName)}/tables`
          );
        })
        .catch((error) => {
          const errors = {};
          errors.message = handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );

          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  render() {
    const { errors, data, loading } = this.state;
    return (
      <Form className="form-overview pb-5" onSubmit={this.create}>
        <Pane className="form-content">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.name} className="form-item">
                <label htmlFor="email" className="form-label">
                  <FormattedMessage id="name" defaultMessage="Name" />
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder=""
                  className="form-input"
                  value={data.name}
                  onChange={this.onChange}
                />
                {errors.name && <InlineMessage text={errors.name} />}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.description} className="form-item">
                <label htmlFor="description" className="form-label">
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                </label>
                <ReactQuill
                  value={data.description}
                  onChange={(content) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        description: content,
                      },
                    }));
                  }}
                  formats={quill.formats}
                  modules={quill.modules}
                />
                {errors.description && (
                  <InlineMessage text={errors.description} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              {errors.message && <InlineMessage text={errors.message} />}
            </Pane>
          </Pane>
        </Pane>
        <Pane className="row">
          <Pane className="col-lg-12 text-center">
            <Button
              type="submit"
              className="btn btn-save mt-4"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="create" defaultMessage="Create" />
            </Button>
          </Pane>
        </Pane>
      </Form>
    );
  }
}

export default injectIntl(CreateProductForm);
