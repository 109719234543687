import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';

class BusinessLogoItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    business: PropTypes.object,
  };

  render() {
    const { business } = this.props;

    return (
      <Pane className="bussiness-item">
        <Link to={`/${business.link}`}>
          <img
            src={process.env.PUBLIC_URL + business.url}
            alt={business.name}
            className="bussiness-icon"
          />
          <strong className="bussiness-title">{business.name}</strong>
        </Link>
      </Pane>
    );
  }
}

export default BusinessLogoItem;
