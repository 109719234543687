import * as types from 'constants/ActionTypes'
import api from 'api'

export const searchProducts = (params) => (dispatch) => dispatch({
  type: types.SEARCH_PRODUCTS,
  payload: {
    request: api.products.search(params)
  }
})

export const searchServices = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_SEARCH_SERVICES, types.RECEIVE_SEARCHING_SERVICES_SUCCESS, types.RECEIVE_SEARCHING_SERVICES_FAILURE],
  payload: {
    request: api.services.search(params)
  }
})

export const searchStores = (params) => (dispatch) => dispatch({
  type: types.SEARCH_STORES,
  payload: {
    request: api.business.search(params)
  }
})

export const searchAll = (params) => (dispatch) => dispatch({
  type: types.SEARCH_ALL,
  payload: {
    request: api.business.all(params)
  }
})

export const searchQuick = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_QUICK, types.RECEIVE_QUICK_SUCCESS, types.RECEIVE_QUICK_FAILURE],
  payload: {
    request: api.business.quick(params)
  }
})