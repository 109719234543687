import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Pane } from "shared/components/layouts";
import { getMyTableDetail, editMyTables } from "actions/myTables";
import EditForm from "./EditForm";
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';

class EditProduct extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  componentDidMount() {
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/tables`,
        name: 'Tables'
      },
      {
        url: ``,
        name: 'Edit table',
        active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="text-center mb-4" id="anchor">
        </Pane>
        <EditForm
          id={this.props.match.params.tableId}
          userInfo={this.props.userInfo}
          getMyTableDetail={this.props.getMyTableDetail}
          editMyTables={this.props.editMyTables}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.productCategories,
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getMyTableDetail: bindActionCreators(getMyTableDetail, dispatch),
  editMyTables: bindActionCreators(editMyTables, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(EditProduct);
