import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { injectIntl } from 'react-intl'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { Cookies } from 'react-cookie'
import { Loader } from 'semantic-ui-react'
import { MasterLayout } from 'components/layout'
import { Pane, Loading } from 'shared/components/layouts'
import { getUserInfo } from 'actions/auth'

class GuidePage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  }

  componentDidMount() {
    const cookies = new Cookies()
    const token = queryString.parse(this.props.location.search).token
    const userId = queryString.parse(this.props.location.search).user_id

    if (token) {
      cookies.set('accessToken', token, { path: '/' })

      this.props.getUserInfo({ userId }).then(response => {
        cookies.set('userInfo', JSON.stringify(response.data.data), { path: '/' })

        this.props.history.replace(`/guide/setup`)
      })
      .catch(() => {
        this.props.history.replace(`/signin?redirect=/&notification=${this.props.intl.formatMessage({ id: 'notification.you_have_entered_the_wrong_password', 'defaultMessage': 'You have entered the wrong password. Please log in again' })}`);
      })
    }
  }

  render() {
    return (
      <MasterLayout>
        <Pane className='container' style={{ minHeight: '300px' }}>
          <Pane className='pt30'><Loading /></Pane>
        </Pane>
      </MasterLayout>
    )
  }
}

const bindStateToProps = state => ({
  auth: state.auth
})

const bindDispatchToProps = dispatch => ({
  getUserInfo: bindActionCreators(getUserInfo, dispatch)
})

export default connect(bindStateToProps, bindDispatchToProps)(injectIntl(GuidePage))
