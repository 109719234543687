import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { MasterLayout } from 'components/layout';
import {
  getAllProducts,
  getProductsPromotion,
  getProductsSlidePromotion,
} from 'actions/products';
import { getProductCategories } from 'actions/category';
import { addItemToCart } from 'actions/cart';
import { getBusinesses } from 'actions/business';
import {
  Products,
  BusinessLogoList,
  BannerIntro,
} from 'components/scenes/promotions';
import StoreList from 'components/common/filter/StoreList';
import { Category } from '../../shared/components';
import { checkParams } from '../../shared/helpers';
import { Pane } from 'shared/components/layouts';
import cookie from 'react-cookies';

class PromotionsPage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    products: PropTypes.object.isRequired,
    productsPromotion: PropTypes.object.isRequired,
    productsSlidePromotion: PropTypes.object.isRequired,
    productCategories: PropTypes.object.isRequired,
    businesses: PropTypes.object.isRequired,
    getAllProducts: PropTypes.func.isRequired,
    getProductsPromotion: PropTypes.func.isRequired,
    getProductsSlidePromotion: PropTypes.func.isRequired,
    getProductCategories: PropTypes.func.isRequired,
    addItemToCart: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  state = {
    value: queryString.parse(this.props.location.search).discount,
  };

  handleChange = (e, { value }) => {
    this.setState(
      {
        value: !value,
      },
      () => {
        this.props.history.push(
          `?discount=${!value}${checkParams(
            'discount',
            this.props.location.search
          )}`
        );
      }
    );
  };

  componentDidMount() {
    const params = {
      page: 1,
      discount: true,
    };
    this.props.getProductCategories();
    this.props.getBusinesses();
    this.props.getProductsPromotion(params);
    this.props.getProductsSlidePromotion(params).then((res) => {
      var countPromotions = res.data.countPromotions;
      cookie.save('countPromotions', countPromotions);
    });
  }

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          {/* {this.props.productsSlidePromotion.promotions.length && ( */}
          <BannerIntro data={this.props.productsSlidePromotion} />
          {/* )} */}
          <BusinessLogoList />
          <Pane className="product-container">
            <Pane className="product-container">
              <Pane className="container">
                <Pane className="row">
                  <Pane className="col-md-3">
                    <Pane className="inner-box mb-5">
                      <h2 className="inner-title">
                        <FormattedMessage
                          id="categories"
                          defaultMessage="Categories"
                        />
                      </h2>
                      <ul className="tab-list">
                        <Link to="/promotions" className="tab-item tab-active">
                          <FormattedMessage
                            id="products"
                            defaultMessage="Products"
                          />
                        </Link>
                        <Link to="/services" className="tab-item">
                          <FormattedMessage
                            id="services"
                            defaultMessage="Services"
                          />
                        </Link>
                      </ul>
                      <Pane className="categories-list list-filter">
                        {this.props.productCategories.results.length > 0 && (
                          <Category
                            categories={this.props.productCategories.results}
                            type="products"
                          />
                        )}
                      </Pane>
                    </Pane>
                    <Pane className="inner-box">
                      <h2 className="inner-title">
                        <FormattedMessage id="stores" defaultMessage="Stores" />
                      </h2>
                      <Pane className="categories-list list-filter">
                        {this.props.businesses.results.length > 0 && (
                          <StoreList
                            filters={this.props.businesses.results}
                            type="products"
                          />
                        )}
                      </Pane>
                    </Pane>
                  </Pane>
                  <Products
                    auth={this.props.auth}
                    data={this.props.productsPromotion}
                    onGetAllProducts={this.props.getAllProducts}
                    onAddItemToCart={this.props.addItemToCart}
                    productPromotions={this.props.productsSlidePromotion}
                  />
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  products: state.products,
  productsPromotion: state.productsPromotion,
  productsSlidePromotion: state.productsSlidePromotion,
  productCategories: state.productCategories,
  businesses: state.businesses,
});

const bindDispatchToProps = (dispatch) => ({
  getAllProducts: bindActionCreators(getAllProducts, dispatch),
  getProductsPromotion: bindActionCreators(getProductsPromotion, dispatch),
  getProductsSlidePromotion: bindActionCreators(
    getProductsSlidePromotion,
    dispatch
  ),
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  addItemToCart: bindActionCreators(addItemToCart, dispatch),
  getBusinesses: bindActionCreators(getBusinesses, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(PromotionsPage));
