import * as types from 'constants/ActionTypes'
import api from 'api'

export const requestAllServices = () => ({
  type: types.REQUEST_ALL_SERVICES
})

export const receiveAllServicessByFilterSuccess = (services) => ({
  type: types.RECEIVE_ALL_SERVICES_BY_FILTER_SUCCESS,
  payload: {
    services
  }
})

export const receiveAllServicesByFilterFailure = (errors) => ({
  type: types.RECEIVE_ALL_SERVICES_BY_FILTER_FAILURE,
  payload: {
    errors
  }
})

export const getAllServices = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_ALL_SERVICES, types.RECEIVE_ALL_SERVICES_BY_FILTER_SUCCESS, types.RECEIVE_ALL_SERVICES_BY_FILTER_FAILURE],
  payload: {
    request: api.services.get(params)
  }
})
export const getServicesSlidePromotion = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_ALL_SERVICES, types.RECEIVE_ALL_SERVICES_SLIDER_BY_FILTER_SUCCESS, types.RECEIVE_ALL_SERVICES_SLIDER_BY_FILTER_FAILURE],
  payload: {
    request: api.services.getSlide(params)
  }
})
export const getServiceDetail = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_SERVICE_DETAIL, types.RECEIVE_SERVICE_DETAIL_SUCCESS, types.RECEIVE_SERVICE_DETAIL_FAILURE],
  payload: {
    request: api.services.detail(params)
  }
})

export const reviewService = (params) => (dispatch) => dispatch({
  type: types.REVIEW_SERVICE,
  payload: {
    request: api.services.review(params)
  }
})

export const getTimeSlots = (params) => (dispatch) => dispatch({
  type: types.GET_TIME_SLOTS,
  payload: {
    request: api.services.getTimeSlots(params)
  }
})

export const book = (params) => (dispatch) => dispatch({
  type: types.BOOK_SERVICE,
  payload: {
    request: api.services.book(params)
  }
})

// Featured Service
export const getFeatureServices = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_FEATURE_SERVICES, types.RECEIVE_FEATURE_SERVICES_SUCCESS, types.RECEIVE_FEATURE_SERVICES_FAILURE],
  payload: {
    request: api.services.featured(params)
  }
})

export const optionsService = (params) => (dispatch) => dispatch({
  type: types.OPTIONS_PRODUCT,
  payload: {
    request: api.services.options(params)
  }
})
