import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';

class BannerItem extends Component {
  renderItem(index) {
    if (this.props.items && this.props.items.length > index) {
      return (
        <Link
          className="inner-link"
          to={`/services/${this.props.items[index].slug}`}
        >
          <img
            style={{ objectFit: 'cover' }}
            src={this.props.items[index].image}
            alt="feature1"
            className="inner-img"
          />
        </Link>
      );
    }
    return '';
  }

  renderItemResponsive() {
    const { items } = this.props;
    if (items) {
      return (
        <Link className="inner-link" to={`/services/${items.slug}`}>
          <img src={items.image} alt="feature1" className="inner-img" />
        </Link>
      );
    }
    return '';
  }

  render() {
    if (this.props.type === 'responsive') {
      return <Pane className="slider-item">{this.renderItemResponsive()}</Pane>;
    }
    return (
      <Pane className="slider-item">
        <Pane className="inner">{this.renderItem(0)}</Pane>
        <Pane className="inner-row">
          <Pane className="inner-children">{this.renderItem(1)}</Pane>
          <Pane className="inner-children">{this.renderItem(2)}</Pane>
        </Pane>
      </Pane>
    );
  }
}

export default BannerItem;
