import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { PriceItem } from 'shared/components';
import { Rating } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
class BestMatchedItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    intl: intlShape.isRequired,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
  };

  state = {
    added: false,
    adding: false,
  };

  changeRoute = () => {
    this.props.history.push(`/booking/${this.props.id}`);
  };

  convertRedirect = (value) => {
    if (this.props.type === 'bussiness') {
      return `/${value}`;
    }
    return `/services/${value}`;
  };

  render() {
    const { item, name, image, rating } = this.props;
    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const ext = image != null ? image.substr(image.lastIndexOf('.') + 1) : '';
    return (
      <>
        <Pane className="col-lg-2 col-md-4 col-sm-6 col-12">
          <Pane className="adds-item">
            <Link className="hover-image" to={this.convertRedirect(this.props.item.slug)}>
              {extImage.indexOf(ext) >= 0 ? (
                <Pane
                  className="adds-images"
                  style={{
                    backgroundImage: image
                      ? `url(${image})`
                      : process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`,
                  }}
                />
              ) : (
                <Pane className="adds-images">
                  {ext !== '' ? <i className="fa fa-video" /> : ''}
                  <video
                    className="carbeyvideo-list"
                    playsInline={1}
                    onMouseOver={this.playVideo}
                    onMouseOut={this.stopVideo}
                    muted={!this.state.play}
                  >
                    <source src={image} id="video_here" />
                    Your browser does not support HTML5 video.
                  </video>
                </Pane>
              )}
            </Link>
            <Pane className="adds-content">
              <Link
                to={
                  this.props.type === 'bussiness'
                    ? this.convertRedirect(this.props.item.slug)
                    : this.convertRedirect(this.props.id)
                }
              >
                <h5 className="adds-norm" title={name}>
                  <b>{name}</b>
                </h5>
                <Pane className="adds-description" dangerouslySetInnerHTML={{ __html: this.props.item.description }}></Pane>
              </Link>
              {/* <ul className="list-star my-2">
                <Rating
                  rating={rating}
                  icon="star"
                  size="small"
                  maxRating="5"
                  disabled
                  className="rating-sm-services"
                />
              </ul> */}
              <Pane className="adds-info adds-info-item">
                <PriceItem
                  promotion={item && item.promotion}
                  originalPrice={item && item.originalPrice}
                />
                <button className="btn-order" onClick={this.changeRoute}>
                  <span className="icon-plus" />
                </button>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </>
    );
  }
}
export default withRouter(injectIntl(BestMatchedItem));
