import { isEmpty, isArray } from 'lodash';

const onGetCurrentCart = (data, cart) => {
  if (!isEmpty(data) && isArray(cart.items)) {
    if (!isEmpty(cart.items)) {
      if (!isArray(data)) {
        const cartItem = cart.items.find(
          (itemCart) => itemCart.productId === data.id
        );
        if (cartItem) {
          return {
            ...data,
            added: true,
          };
        }
        return data;
      }
      return data.map((item) => {
        const cartItem = cart.items.find(
          (itemCart) => itemCart.productId === item.id
        );
        if (cartItem) {
          return { ...item, added: true };
        }
        return item;
      });
    }
    return data;
  }
  return data;
};

export default onGetCurrentCart;
