import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl, intlShape } from 'react-intl';
import { MasterLayout } from 'components/layout';
import { getAllProducts, getProductsSlidePromotion } from 'actions/products';
import { getProductCategories } from 'actions/category';
import { addItemToCart, changeOption } from 'actions/cart';
import { getBusinesses, getBusinessByCategory } from 'actions/business';
import {
  Products,
  BannerIntro,
  BusinessLogoList,
} from 'components/scenes/products';
import { InderBox } from 'components/common/categories';
import { checkParams } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';

class ProductPage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    products: PropTypes.object.isRequired,
    productsSlidePromotion: PropTypes.object.isRequired,
    productCategories: PropTypes.object.isRequired,
    businesses: PropTypes.object.isRequired,
    getAllProducts: PropTypes.func.isRequired,
    getProductsSlidePromotion: PropTypes.func.isRequired,
    getProductCategories: PropTypes.func.isRequired,
    addItemToCart: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  state = {
    value: queryString.parse(this.props.location.search).discount,
  };

  handleChange = (e, { value }) => {
    this.setState(
      {
        value,
      },
      () => {
        this.props.history.push(
          `?discount=${value}${checkParams(
            'discount',
            this.props.location.search
          )}`
        );
      }
    );
  };

  componentDidMount() {
    const params = {
      page: 1,
    };
    this.props.getProductCategories();
    this.props.getBusinesses();
    this.props.getProductsSlidePromotion(params);
  }

  onOpenToggle = (type) => {
    this.setState((prevState) => ({
      [type]: !prevState[type],
    }));
  };

  render() {
    const {
      productCategories,
      productsSlidePromotion,
      businesses,
    } = this.props;
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <BannerIntro data={productsSlidePromotion} />
          <BusinessLogoList />
          <Pane className="container product-container product-list-container">
            <Pane className="container">
              <Pane className="row">
                <Pane className="col-lg-3 col-md-12">
                  <InderBox
                    isTab={true}
                    type="products"
                    className="mb-5"
                    title="categories"
                    nameToggle="toggleCategory"
                    onOpenToggle={this.onOpenToggle}
                    category={productCategories.results}
                    toggle={!!this.state.toggleCategory}
                  />
                  <InderBox
                    type="products"
                    title="stores"
                    nameToggle="toggleStores"
                    category={businesses.results}
                    onOpenToggle={this.onOpenToggle}
                    toggle={!!this.state.toggleStores}
                  />
                  <InderBox
                    type="promotion"
                    title="promotions"
                    nameToggle="togglePromotion"
                    onOpenToggle={this.onOpenToggle}
                    toggle={!!this.state.togglePromotion}
                  />
                </Pane>
                <Products
                  cart={this.props.cart}
                  auth={this.props.auth}
                  data={this.props.products}
                  type="products"
                  onGetAllProducts={this.props.getAllProducts}
                  onAddItemToCart={this.props.addItemToCart}
                  productPromotions={this.props.productsSlidePromotion}
                  categories={this.props.productCategories.results}
                  getBusinessByCategory={this.props.getBusinessByCategory}
                  businessesByCategory={this.props.businessesByCategory}
                  changeOption={this.props.changeOption}
                />
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  products: state.products,
  productsSlidePromotion: state.productsSlidePromotion,
  productCategories: state.productCategories,
  businesses: state.businesses,
  businessesByCategory: state.businessesByCategory,
  cart: state.cart,
});

const bindDispatchToProps = (dispatch) => ({
  getAllProducts: bindActionCreators(getAllProducts, dispatch),
  getProductsSlidePromotion: bindActionCreators(
    getProductsSlidePromotion,
    dispatch
  ),
  changeOption: bindActionCreators(changeOption, dispatch),
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  addItemToCart: bindActionCreators(addItemToCart, dispatch),
  getBusinesses: bindActionCreators(getBusinesses, dispatch),
  getBusinessByCategory: bindActionCreators(getBusinessByCategory, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(ProductPage));
