import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { injectIntl } from 'react-intl'
import { Pane } from 'shared/components/layouts'

const ClassisiedOverview = ({ history, intl }) => {
  return (
    <Pane className='page-not-found-content text-center'>
      <img width="10%" aria-hidden alt="img" src="/assets/images/layouts/logo-footer.png" />
      <br />
      <img width="50%" aria-hidden alt="img" src="/assets/images/gallery/comingsoon.png" />
      <br />
      <Button color='orange' icon='home' content={intl.formatMessage({ id: 'go_back_to_home' })} onClick={() => history.push('/')} />
    </Pane>
  )
}


ClassisiedOverview.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object,
}

ClassisiedOverview.defaultProps = {
  history: {}
}

export default withRouter(injectIntl(ClassisiedOverview))
