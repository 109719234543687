import { List } from "../list";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import queryString from "query-string";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import { withRouter, Link } from "react-router-dom";
import { formatRoute, handleError } from "shared/helpers";
import { LoadingSearch } from "components/common/loading";
import NotificationSystem from "react-notification-system";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { getTablesByUserId, deleteMyTables } from "actions/myTables";
import UserAdapter from "services/users/adapter";

let isMounted = true;
/**
 * Set isMounted
 * @param {boolean} value
 * @returns {boolean} value of isMounted
 */
const setIsMounted = (value = true) => {
  isMounted = value;
  return isMounted;
};
/**
 * Get isMounted
 * @returns {boolean} value of isMounted
 */
const getIsMounted = () => isMounted;
class MyProduct extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    products: PropTypes.object,
    deleteMyProduct: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      fetching: false,
      fetched: false,
      dataSource: [],
    };
    this._notificationSystem = null;
    setIsMounted(true);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getListById();
    });
    this.getListById();
    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  componentWillUnmount() {
    setIsMounted(false);
    this.unlisten();
  }

  setStateData = (state, callback) => {
    if (!getIsMounted()) {
      return;
    }
    this.setState(state, callback);
  };

  getListById = (params) => {
    const { userInfo } = this.props;
    this.setStateData({
      fetching: true,
      fetched: false,
    });
    this.props
      .getTablesByUserId({
        ...params,
        business_id: userInfo.business && userInfo.business.id,
      })
      .then((response) => {
        if (response) {
          this.setStateData({
            dataSource: UserAdapter.tablesBrowse.parseResponse(response.data),
            fetching: false,
            fetched: true,
          });
        }
      })
      .catch(() => {
        this.setStateData({
          fetching: false,
          fetched: true,
        });
      });
  };

  deleteItem = (params) => {
    const { intl } = this.props;
    const { dataSource } = this.state;

    if (dataSource.length === 1) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }
    this.props
      .deleteMyTables(params)
      .then(() => {
        this.getListById({ page: this.state.page });
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  render() {
    const { userInfo } = this.props;
    const { fetched, fetching, dataSource } = this.state;

    return (
      <Pane className="overview-container" id="anchor">
        <Pane className="product-container">
          <div className="product-heading d-flex justify-content-between">
            <h3 className="block-title pt10 text-uppercase">
              <FormattedMessage id="my_tables" defaultMessage="My Tables" />
            </h3>
            <Link
              to={`/u/${formatRoute(userInfo.displayName)}/tables/create`}
              className="btn btn-circle btn-circle-primary"
            >
              <FormattedMessage
                id="create_new_table"
                defaultMessage="Create a new table"
              />
            </Link>
          </div>
          {!fetching && fetched ? (
            <>
              {isEmpty(dataSource) && <Empty />}
              {!isEmpty(dataSource) && (
                <List
                  data={dataSource}
                  type="tables"
                  control={true}
                  onDelete={this.deleteItem}
                  modeView={this.state.modeView}
                  getServicesByUserId={this.props.getServicesByUserId}
                  userInfo={this.props.userInfo}
                />
              )}
            </>
          ) : (
            <LoadingSearch type="white" />
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  products: state.myProducts,
});

const bindDispatchToProps = (dispatch) => ({
  deleteMyTables: bindActionCreators(deleteMyTables, dispatch),
  getTablesByUserId: bindActionCreators(getTablesByUserId, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(MyProduct))
);
