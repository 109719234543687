import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  const page = params.page || 1
  const size = params.size || 20

  return {
    method: 'GET',
    url: `/buyers/${params.id}/orders?page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const orders = []

  response.data && response.data.forEach(item => {
    const order = formatJson(response, item.type, item.id)

    let paymentMethod = ''

    if (order.payment && order.payment.transactionType) {
      paymentMethod = order.payment.transactionType
    } else {
      paymentMethod = order.paymentMethod !== '' ? order.paymentMethod : 'Credit card'
    }

    orders.push({
      id: order.id,
      orderDate: order.createdAt,
      total: Number(order.total),
      paymentMethod,
      status: order.status
    })
  })

  return orders
}

export { exec, parseResponse }
