import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Config from 'constants/Config';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Pane, Loading } from 'shared/components/layouts';
import FeatureVideoItem from './FeatureVideoItem';
import { Dimmer, Loader } from 'semantic-ui-react';
import FeatureProductItem from './FeatureProductItem';
import { getFeatureProducts } from 'actions/products';
import { getFeatureServices } from 'actions/services';

class FeatureProducts extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.props.getFeatureProducts({});
    this.props.getFeatureServices({});
  }

  onChangeFeatured = async (type) => {
    if (this.props.type !== type) {
      this.props.onChangeCategory(type);
    }
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    video.play();
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  renderList(products) {
    let productitems = [];
    return products.map((item, index) => {
      productitems.push(item);
      if ((index + 1) % 4 === 0) {
        const productitemsdraw = productitems;
        productitems = [];
        return (
          <FeatureProductItem
            items={productitemsdraw}
            key={'Product' + index}
            type={this.props.type}
          />
        );
      } else if (index === products.length - 1) {
        const productitemsdraw = productitems;
        productitems = [];
        return (
          <FeatureProductItem
            items={productitemsdraw}
            key={'Product' + index}
            type={this.props.type}
          />
        );
      }
      return null;
    });
  }

  renderListResponsive(products) {
    return products.map((item, index) => (
      <FeatureProductItem
        items={item}
        key={'Product' + index}
        type={this.props.type}
        device="responsive"
      />
    ));
  }

  renderListVideos(videos) {
    let videoItems = [];
    return videos.results.map((item, index) => {
      videoItems.push(item);
      if ((index + 1) % 4 === 0) {
        const videoItemsDraw = videoItems;
        videoItems = [];
        return (
          <FeatureVideoItem
            items={videoItemsDraw}
            key={'Video' + index}
            type={this.props.type}
          />
        );
      } else if (index === videos.results.length - 1) {
        const videoItemsDraw = videoItems;
        videoItems = [];
        return (
          <FeatureVideoItem
            items={videoItemsDraw}
            key={'Video' + index}
            type={this.props.type}
          />
        );
      }
      return null;
    });
  }

  renderListVideosResponsive(videos) {
    return videos.results.map((item, index) => {
      return (
        <FeatureVideoItem
          items={item}
          key={'Video' + index}
          type={this.props.type}
          device="responsive"
        />
      );
    });
  }

  render() {
    const { products, services, type, loading } = this.props;
    const settings = {
      dots: false,
      autoplay: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Pane className="featured-container pt-4">
        <Pane className="container">
          <Pane className="featured-content">
            <Pane className="row">
              <Pane className="col-lg-12">
                <Pane className="heading-container text-lg-left text-md-left text-left pb-lg-4 pb-0">
                  <h2 className="heading-title">
                    <FormattedMessage id="featured" defaultMessage="Featured" />
                  </h2>
                  <p className="heading-norm">
                    <FormattedMessage
                      id="home.let_bring_your_excellent_products"
                      defaultMessage="Let’s bring your excellent products and services here."
                    />
                  </p>
                </Pane>
              </Pane>
            </Pane>
            <Pane className="row">
              <Pane className="col-lg-6 col-md-6 col-sm-6 col-6 pt-3 text-left">
                <ul className="featured-list-tab">
                  <li
                    className={`featured-tab ${type === Config.PRODUCT_TYPE &&
                      'tab-active'}`}
                    onClick={() => this.onChangeFeatured(Config.PRODUCT_TYPE)}
                  >
                    <span>
                      <FormattedMessage id="products" />
                    </span>
                  </li>
                  <li
                    className={`featured-tab ${type === Config.SERVICE_TYPE &&
                      'tab-active'} pl-4`}
                    onClick={() => this.onChangeFeatured(Config.SERVICE_TYPE)}
                  >
                    <span>
                      <FormattedMessage id="services" />
                    </span>
                  </li>
                  <li
                    className={`featured-tab ${type === Config.VIDEO_TYPE &&
                      'tab-active'} pl-4`}
                    onClick={() => this.onChangeFeatured(Config.VIDEO_TYPE)}
                  >
                    <span>
                      <FormattedMessage id="videos" defaultMessage="Videos" />
                    </span>
                  </li>
                </ul>
              </Pane>
              <Pane className="col-lg-6 col-md-6 col-sm-6 col-6 pt-3 text-lg-right text-sm-right text-right">
                <Link to={`/${type}`} className="sell-your-item">
                  <span className="view-all">
                    <FormattedMessage id="view_all" defaultMessage="View All" />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/home/arrow-3.svg'
                      }
                      alt="arrow-3"
                      className="icon"
                    />
                  </span>
                </Link>
              </Pane>
            </Pane>
            <Pane className="row pt-5 row-slider">
              {this.props.type !== Config.VIDEO_TYPE && (
                <Slider className="featured-slider" {...settings}>
                  {loading && (
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  )}
                  {!loading && (products.results.length > 0 || services.results.length > 0) && (
                    this.renderList(
                      type === Config.PRODUCT_TYPE
                        ? products.results
                        : services.results
                    )
                  )}
                </Slider>
              )}
              {this.props.type !== Config.VIDEO_TYPE && (
                <Slider className="shop-now-slider-responsive" {...settings}>
                  {loading && (
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  )}
                  {!loading && (products.results.length > 0 || services.results.length > 0) && (
                    this.renderListResponsive(
                      type === Config.PRODUCT_TYPE
                        ? products.results
                        : services.results
                    )
                  )}
                </Slider>
              )}
              {this.props.type === Config.VIDEO_TYPE && (
                <>
                  {loading && (
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  )}
                  {!loading && this.props.videos && (
                    <Slider className="featured-slider" {...settings}>
                      {this.renderListVideos(this.props.videos)}
                    </Slider>
                  )}
                </>
              )}
              {this.props.type === Config.VIDEO_TYPE && (
                <>
                  {loading && (
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  )}
                  {!loading && this.props.videos && (
                    <Slider className="shop-now-slider-responsive" {...settings}>
                      {this.renderListVideosResponsive(this.props.videos)}
                    </Slider>
                  )}
                </>
              )}
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

FeatureProducts.propTypes = {
  getFeatureProducts: PropTypes.func.isRequired,
  products: PropTypes.object,
};

const bindStateToProps = (state) => ({
  products: state.featureProducts,
  services: state.featureServices,
});

const bindDispatchToProps = (dispatch) => ({
  getFeatureProducts: bindActionCreators(getFeatureProducts, dispatch),
  getFeatureServices: bindActionCreators(getFeatureServices, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(FeatureProducts);
