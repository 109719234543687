import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
  getProductsByUserId,
  getMyProductDetail,
  editMyProduct,
} from "actions/myProducts";
import { optionsProduct } from "actions/products";
import { getProductCategories, createCategory } from "actions/category";
import EditMenuForm from "./EditMenuForm";
import CategoryAdapter from "services/categories/adapter";
import {
  createMyOption,
  editMyOption,
  deleteMyOption,
  createMyOptionGroup,
  editMyOptionGroup,
  deleteMyOptionGroup,
} from "actions/options";
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';

class EditMenu extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    match: PropTypes.object,
    getProductCategories: PropTypes.func,
    editMyProduct: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    getMyProductDetail: PropTypes.func.isRequired,
    getProductsByUserId: PropTypes.func.isRequired,
    optionsProduct: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }

  getMyProducts = () => {
    this.props.getProductsByUserId({ id: this.props.userInfo.id });
  };

  componentDidMount() {
    this.props.getProductCategories({ group: "Menu" }).then((response) => {
      const categories = CategoryAdapter.productCategoryBrowse.parseResponse(
        response
      );
      this.setState({
        data: categories.data,
      });
    });

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    const { data } = this.state;
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(
          this.props.userInfo.displayName
        )}/menu`, name: 'Menu'
      },
      {
        url: ``, name: 'Update item', active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <div className="text-center mb-4" id="anchor">
        </div>
        <EditMenuForm
          id={this.props.match.params.productId}
          userInfo={this.props.userInfo}
          categories={data}
          getMyProducts={this.getMyProducts}
          createCategory={this.props.createCategory}
          getMyProductDetail={this.props.getMyProductDetail}
          editMyProduct={this.props.editMyProduct}
          optionsProduct={this.props.optionsProduct}
          createMyOption={this.props.createMyOption}
          editMyOption={this.props.editMyOption}
          deleteMyOption={this.props.deleteMyOption}
          createMyOptionGroup={this.props.createMyOptionGroup}
          editMyOptionGroup={this.props.editMyOptionGroup}
          deleteMyOptionGroup={this.props.deleteMyOptionGroup}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.productCategories,
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  getMyProductDetail: bindActionCreators(getMyProductDetail, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
  editMyProduct: bindActionCreators(editMyProduct, dispatch),
  optionsProduct: bindActionCreators(optionsProduct, dispatch),
  createMyOption: bindActionCreators(createMyOption, dispatch),
  editMyOption: bindActionCreators(editMyOption, dispatch),
  deleteMyOption: bindActionCreators(deleteMyOption, dispatch),
  createMyOptionGroup: bindActionCreators(createMyOptionGroup, dispatch),
  editMyOptionGroup: bindActionCreators(editMyOptionGroup, dispatch),
  deleteMyOptionGroup: bindActionCreators(deleteMyOptionGroup, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(EditMenu);
