import Config from 'constants/Config'

const exec = params => {
  return {
    method: 'POST',
    url: `/orders/${params.id}/items/approve-cancel`,
    baseURL: Config.NEW_API_URL,
    data: {
      reason: params.reason
    }
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
