import Config from "constants/Config";
import { formatJson } from "shared/helpers";

const exec = (params) => {
  return {
    method: "GET",
    url: `/products/${params.id}/options`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const { data } = response;
  const options = [];

  for (const item of data) {
    const option = formatJson(response, item.type, item.id);

    const group = option.optionGroup || {};
    const index = options.findIndex((option) => option.id === group.id);
    if (index === -1) {
      options.push({
        id: group.id,
        name: group.name,
        type: group.type,
        dpOrdSeq: group.dpOrdSeq,
        autoAddOptions: false,
        delt: "N",
        childrens: [
          {
            id: option.id,
            name: option.name,
            price: Number(option.price),
            delt: "N",
            autoAddOptions: false,
            isCheck: !!option.isCheck,
            dpOrdSeq: option.dpOrdSeq,
          },
        ],
      });
    } else {
      options[index].childrens.push({
        id: option.id,
        name: option.name,
        price: Number(option.price),
        delt: "N",
        autoAddOptions: false,
        isCheck: !!option.isCheck,
        dpOrdSeq: option.dpOrdSeq,
      });
    }
  }

  return options;
};

export { exec, parseResponse };
