import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 100;
  const id = params.categoryId || '';
  return {
    method: 'GET',
    url: `/business/filter?category_id=${id}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const data = [];

  if (response.data) {
    response.data.forEach((item) => {
      const data = formatJson(response, item.type, item.id);
      data.push(data);
    });
  }
  return data;
};

export { exec, parseResponse };
