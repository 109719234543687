import * as types from 'constants/ActionTypes';

const initialState = {
  results: {},
  error: null,
  fetching: false,
  fetched: false,
};

const statistics = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_STATISTICS_USERS:
      return Object.assign({}, state, {
        fetching: true,
      });
    case types.RECEIVE_STATISTICS_USERS_SUCCESS:
      return Object.assign({}, state, {
        results: action.payload.data,
        error: {},
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_STATISTICS_USERS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        error: action.error,
        fetching: false,
        fetched: true,
      });
    default:
      return state;
  }
};

export { statistics };
