import * as types from 'constants/ActionTypes'

export const getStoreBookingsRequest = (params) => (dispatch) => dispatch(getStoreBookings(params))

export const getStoreBookings = (storeBookings) => {
  return {
    type: types.RECEIVE_STORE_BOOKING,
    storeBookings: storeBookings
  }
}

export const addStoreBookingsRequest = (booking) => (dispatch) => dispatch(addStoreBookings(booking))

export const addStoreBookings = (booking) => {
  return {
    type: types.ADD_STORE_BOOKING,
    booking: booking
  }
}

export const deleteStoreBookingsRequest = (id, isChildren) => (dispatch) => dispatch(deleteStoreBookings(id, isChildren))

export const deleteStoreBookings = (id, isChildren) => {
  return {
    type: types.DELETE_STORE_BOOKING,
    id: id,
    isChildren: isChildren
  }
}

export const resetStoreBookingsRequest = () => (dispatch) => dispatch(resetStoreBookings())

export const resetStoreBookings = () => {
  return {
    type: types.RESET_STORE_BOOKING
  }
}
