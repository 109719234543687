import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import CreateProductPickForm from './CreateProductPickForm';
import { createMyProductPick, getProductPicksByUserId } from 'actions/myProductPicks';

class CreateProductPick extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    createMyProductPick: PropTypes.func.isRequired,
    getProductPicksByUserId: PropTypes.func.isRequired,
  };

  getMyProducts = () => {
    // this.props.getProductPicksByUserId({ id: this.props.userInfo.id });
  };
  componentDidMount() {
    
  }

  render() {
    return (
      <>
        <Pane className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="heading-title mt-2">
            <FormattedMessage
              id="create_new_product"
              defaultMessage="Create new product"
            />
          </h3>
        </Pane>
        <CreateProductPickForm
          userInfo={this.props.userInfo}
          // getMyProducts={this.getMyProducts}
          createMyProductPick={this.props.createMyProductPick}
          history={this.props.history}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getProductPicksByUserId: bindActionCreators(getProductPicksByUserId, dispatch),
  createMyProductPick: bindActionCreators(createMyProductPick, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(CreateProductPick);
