import Config from "constants/Config";
import { formatJson, formatRoute } from "shared/helpers";

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 1000;
  const category = params.category || "";
  const store = params.store || "";
  const sort = params.sort || "";
  const discount = params.discount === "true" ? `>0` : "";
  const images = params.images === null ? null : "";
  if (category) {
    return {
      method: "GET",
      url: `/categories/${category}/products?user_id=${store}&images.id=${images}&sort=${sort}&promotion.id=${discount}&page[size]=${size}&page[number]=${page}`,
      baseURL: Config.NEW_API_URL,
    };
  }
  return {
    method: "GET",
    url: `/products?category_id=${category}&user_id=${store}&images.id=${images}&sort=${sort}&promotion.id=${discount}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const products = [];
  if (response.data) {
    response.data.forEach((item) => {
      const product = formatJson(response, item.type, item.id);
      let promotion = {};

      if (product.promotion) {
        promotion = {
          id: product.promotion.id,
          percent: Number(product.promotion.percent),
          startDate: product.promotion.startDate,
          endDate: product.promotion.endDate,
        };
      }

      const options = [];

      (product.options || []).forEach((option) => {
        const group = option.optionGroup || {};
        const index = options.findIndex((option) => option.id === group.id);
        if (index === -1) {
          options.push({
            id: group.id,
            name: group.name,
            type: group.type,
            autoAddOptions: false,
            dpOrdSeq: group.dpOrdSeq,
            delt: "N",
            childrens: [
              {
                id: option.id,
                name: option.name,
                dpOrdSeq: option.dpOrdSeq,
                price: Number(option.price),
                delt: "N",
                autoAddOptions: false,
                isCheck: !!option.isCheck,
              },
            ],
          });
        } else {
          options[index].childrens.push({
            id: option.id,
            name: option.name,
            dpOrdSeq: option.dpOrdSeq,
            price: Number(option.price),
            delt: "N",
            autoAddOptions: false,
            isCheck: !!option.isCheck,
          });
        }

        return options;
      });

      options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
      options.forEach((option) =>
        option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq)
      );

      products.push({
        category: product.category,
        categoryName: product.category && product.category.name,
        id: product.id,
        name: product.name,
        description: product.description,
        meta_desc: product.metaDesc,
        slug: `${formatRoute(product.name, "-")}-${product.id}`,
        primaryImage: product.primaryImage && product.primaryImage.url,
        rating:
          product.statistic && product.statistic.rating
            ? Number(product.statistic.rating)
            : 0,
        userId: product.user && product.user.id,
        originalPrice: Number(product.price),
        promotionPrice: Number(product.promotionPrice),
        type: "products",
        inventoryStatus: product.inventoryStatus,
        promotion,
        options,
      });
    });
  }

  return products;
};

export { exec, parseResponse };
