import Config from "constants/Config";
import { formatJson } from "shared/helpers";

const exec = (params) => {
  return {
    method: "GET",
    baseURL: Config.NEW_API_URL,
    url: `/tables/${params.id}`,
  };
};
const parseResponse = (response) => {
  return formatJson(response, response.data.type, response.data.id);
};

export { exec, parseResponse };
