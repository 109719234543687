import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage } from 'react-intl';
import { Currency } from 'shared/components';

class TotalPayment extends Component {
  render() {
    const { cart, type, discountPercent, maxDiscount} = this.props;
    let discountPrice = 0;
    let strMaxDiscount='';
    if(discountPercent){
      discountPrice = cart.total*(discountPercent/100);
    }
    if(maxDiscount > 0 && discountPrice > maxDiscount){
      strMaxDiscount =  `Max AUD ${parseFloat(maxDiscount).toFixed(2)}`;
      discountPrice = maxDiscount;
    }
    if (type === 'cart') {
      return (
        <Pane className="col-lg-12 p-0">
          <Pane className="process-checkout">
              <Pane className="some w-100">
                <Pane className="p-0">
                  <Pane className="my-4">
                    <Pane className="text-total d-flex justify-content-between">
                      <FormattedMessage id="total" defaultMessage="TOTAL" />
                      <span className="text-total text-price">
                        <Currency price={cart.total} />
                      </span>
                    </Pane>
                    <p className="text-norm d-flex justify-content-between">
                      <FormattedMessage
                        id="cart.includes_gst_amount"
                        defaultMessage="Includes GST Amount"
                      />
                      <span className="text-norm">Included</span>
                    </p>
                  </Pane>
                </Pane>
                <Pane className="block-process mt-2 clearfix">
                  <button
                    className="btn btn-process"
                    onClick={this.props.handleCheckout}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/cart/lock.svg`
                      }
                      alt="lock"
                      className="icon"
                    />
                    <FormattedMessage
                      id="cart.proceed_to_checkout"
                      defaultMessage="Proceed to checkout"
                    />
                  </button>
                  <span className="text-description">
                    <FormattedMessage
                      id="by_click_PAYMENT"
                      defaultMessage="By click PAYMENT you agree to our privacy policy an term of server.You also agree to receive periodic email updates, discounts, and specical offers."
                    />
                  </span>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
      );
    }
    return (
      <Pane className="process-checkout mt-3">
        <Pane className="row">
          <Pane className="col-lg-6 col-6 text-left">
            <h3 className="text-total">
              <FormattedMessage id="total" defaultMessage="TOTAL" />
            </h3>
            {
              discountPercent && (
                <>
                  <p className="text-norm">
                    <span>Total Items</span> :
                  </p>
                  <p className="text-norm">
                    <span>Discount ({discountPercent}% {strMaxDiscount})</span> :
                  </p>
                </>
              )
            }
            <p className="text-norm">
              <FormattedMessage
                  id="cart.includes_gst_amount"
                  defaultMessage="Includes GST Amount"
              />
              :
            </p>
          </Pane>
          <Pane className="col-lg-6 col-6 text-right">
            <h3 className="text-total text-price">
              <Currency price={cart.total - discountPrice} />
            </h3>
            {discountPercent && (
              <>
              <p className="text-norm"> <Currency price={cart.total} /></p>
              <p className="text-norm"> <Currency price={discountPrice} /></p>
              </>
            )}

            <p className="text-norm">Included</p>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});
TotalPayment.propTypes = {
  type: PropTypes.string,
};
TotalPayment.defaultProps = {
  type: '',
};
export default connect(mapStateToProps)(TotalPayment);
