// ***** Authentication ***** //
export const RECEIVE_LOGIN_SUCCESS = 'RECEIVE_LOGIN_SUCCESS';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN_FAILURE = 'RECEIVE_LOGIN_FAILURE';
export const LOGOUT = 'Logout';

// ***** NEWSLETTER ***** //
export const REQUEST_SUBSCRIBE = 'REQUEST_SUBSCRIBE';

// ***** Signup ***** //
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATE_QR_CODE = 'CREATE_QR_CODE';

// ***** User info ***** //
export const REQUEST_USER_INFO = 'REQUEST_USER_INFO';
export const RECEIVE_USER_INFO_SUCCESS = 'RECEIVE_USER_INFO_SUCCESS';
export const RECEIVE_USER_INFO_FAILURE = 'RECEIVE_USER_INFO_FAILURE';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_BACKGROUND = 'UPDATE_BACKGROUND';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

// ***** User reference ***** //
export const UPDATE_USER_REFERENCE = 'UPDATE_USER_REFERENCE';
export const RECEIVE_USER_REFERENCE_SUCCESS = 'RECEIVE_USER_REFERENCE_SUCCESS';
export const RECEIVE_USER_REFERENCE_FAILURE = 'RECEIVE_USER_REFERENCE_FAILURE';

// ***** Shopping cart ***** //
export const REQUEST_SHOPPING_CART = 'REQUEST_SHOPPING_CART';
export const RECEIVE_SHOPPING_CART_SUCCESS = 'RECEIVE_SHOPPING_CART_SUCCESS';
export const RECEIVE_SHOPPING_CART_FAILURE = 'RECEIVE_SHOPPING_CART_FAILURE';

export const ADD_TO_CART = 'ADD_TO_CART';
export const CREATE_USER_CART = 'CREATE_USER_CART';
export const ORDER_USER_CART = 'ORDER_USER_CART';
export const ADD_LIST_TO_CART = 'ADD_LIST_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const REMOVE_TO_CART = 'REMOVE_TO_CART';
export const REMOVE_TO_CART_SUCCESS = 'REMOVE_TO_CART_SUCCESS';
export const CHANGE_QUANLITY = 'CHANGE_QUANLITY';
export const CHANGE_QUANLITY_SUCCESS = 'CHANGE_QUANLITY_SUCCESS';
export const CHANGE_DELIVERY = 'CHANGE_DELIVERY';
export const CHANGE_OPTION = 'CHANGE_OPTION';
export const CHANGE_OPTION_SUCCESS = 'CHANGE_OPTION_SUCCESS';
export const CHANGE_DELIVERY_SUCCESS = 'CHANGE_DELIVERY_SUCCESS';
export const REMOVE_ALL_CART_SUCCESS = 'REMOVE_ALL_CART_SUCCESS';
export const REQUEST_CLIENT_AUTHORIZATION = 'REQUEST_CLIENT_AUTHORIZATION';

// Shopping cart - guest
export const RECEIVE_GUEST_CART_SUCCESS = 'RECEIVE_GUEST_CART_SUCCESS';
export const ADD_TO_GUEST_CART_SUCCESS = 'ADD_TO_GUEST_CART_SUCCESS';
export const REMOVE_TO_GUEST_CART_SUCCESS = 'REMOVE_TO_GUEST_CART_SUCCESS';
export const CHANGE_DELIVERY_GUEST_CART_SUCCESS =
  'CHANGE_DELIVERY_GUEST_CART_SUCCESS';
export const CHANGE_QUANTITY_GUEST_CART_SUCCESS =
  'CHANGE_QUANTITY_GUEST_CART_SUCCESS';
export const DECREASE_QUANTITY_GUEST_CART_SUCCESS =
  'DECREASE_QUANTITY_GUEST_CART_SUCCESS';

export const UPDATE_CART_INFO = 'UPDATE_CART_INFO';
export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_POS = 'CHECKOUT_POS';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const PAYMENT_CREDIT = 'PAYMENT_CREDIT';
export const PAYMENT_CREDIT_SUCCESS = 'PAYMENT_CREDIT_SUCCESS';

// ***** Category ***** //
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const REQUEST_PRODUCT_CATEGORIES = 'REQUEST_PRODUCT_CATEGORIES';
export const RECEIVE_PRODUCT_CATEGORIES_SUCCESS =
  'RECEIVE_PRODUCT_CATEGORIES_SUCCESS';
export const RECEIVE_PRODUCT_CATEGORIES_FAILURE =
  'RECEIVE_PRODUCT_CATEGORIES_FAILURE';

// ***** Category Food Order ***** //
export const REQUEST_PRODUCT_CATEGORIES_FOOD_ORDER =
  'REQUEST_PRODUCT_CATEGORIES_FOOD_ORDER';
export const RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_SUCCESS =
  'RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_SUCCESS';
export const RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_FAILURE =
  'RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_FAILURE';

export const REQUEST_SERVICE_CATEGORIES = 'REQUEST_SERVICE_CATEGORIES';
export const RECEIVE_SERVICE_CATEGORIES_SUCCESS =
  'RECEIVE_SERVICE_CATEGORIES_SUCCESS';
export const RECEIVE_SERVICE_CATEGORIES_FAILURE =
  'RECEIVE_SERVICE_CATEGORIES_FAILURE';

  export const REQUEST_COLLECTION_CATEGORIES = 'REQUEST_COLLECTION_CATEGORIES';
export const RECEIVE_COLLECTION_CATEGORIES_SUCCESS =
  'RECEIVE_COLLECTION_CATEGORIES_SUCCESS';
export const RECEIVE_COLLECTION_CATEGORIES_FAILURE =
  'RECEIVE_COLLECTION_CATEGORIES_FAILURE';

// ***** Banner ***** //
export const REQUEST_BANNERS = 'REQUEST_BANNERS';
export const RECEIVE_BANNERS_SUCCESS = 'RECEIVE_BANNERS_SUCCESS';
export const RECEIVE_BANNERS_FAILURE = 'RECEIVE_BANNERS_FAILURE';

// ***** Banner Digital ***** //
export const REQUEST_BANNERS_DIGITAL = 'REQUEST_BANNERS_DIGITAL';
export const RECEIVE_BANNERS_DIGITAL_SUCCESS =
  'RECEIVE_BANNERS_DIGITAL_SUCCESS';
export const RECEIVE_BANNERS_DIGITAL_FAILURE =
  'RECEIVE_BANNERS_DIGITAL_FAILURE';

// ***** Business logo ***** //
export const REQUEST_BUSINESS_LOGOS = 'REQUEST_BUSINESS_LOGOS';
export const RECEIVE_BUSINESS_LOGOS_SUCCESS = 'RECEIVE_BUSINESS_LOGOS_SUCCESS';
export const RECEIVE_BUSINESS_LOGOS_FAILURE = 'RECEIVE_BUSINESS_LOGOS_FAILURE';

// ***** Search business ***** //
export const REQUEST_BUSINESS_SEARCH = 'REQUEST_BUSINESS_SEARCH';
export const RECEIVE_BUSINESS_SEARCH_SUCCESS =
  'RECEIVE_BUSINESS_SEARCH_SUCCESS';
export const RECEIVE_BUSINESS_SEARCH_FAILURE =
  'RECEIVE_BUSINESS_SEARCH_FAILURE';

// ***** Business ***** //
export const REQUEST_BUSINESSES = 'REQUEST_BUSINESSES';
export const RECEIVE_BUSINESSES_SUCCESS = 'RECEIVE_BUSINESSES_SUCCESS';
export const RECEIVE_BUSINESSES_FAILURE = 'RECEIVE_BUSINESSES_FAILURE';

// ***** QUICK SEARCH ***** //
export const REQUEST_QUICK = 'REQUEST_QUICK';
export const RECEIVE_QUICK_SUCCESS = 'RECEIVE_QUICK_SUCCESS';
export const RECEIVE_QUICK_FAILURE = 'RECEIVE_QUICK_FAILURE';

// ***** REVIEW BUSINESS ***** //
export const REVIEW_BUSINESS = 'REVIEW_BUSINESS';
export const RECEIVE_BUSINESS_REVIEW = 'RECEIVE_BUSINESS_REVIEW';

// ***** Business By Products ***** //
export const REQUEST_BUSINESSES_BY_GROUP = 'REQUEST_BUSINESSES_BY_GROUP';
export const REQUEST_BUSINESSES_BY_GROUP_SUCCESS =
  'REQUEST_BUSINESSES_BY_GROUP_SUCCESS';
export const REQUEST_BUSINESSES_BY_GROUP_FAILURE =
  'REQUEST_BUSINESSES_BY_GROUP_FAILURE';

// ***** User ***** //
// My business
export const REQUEST_MY_BUSINESS_INFO = 'REQUEST_MY_BUSINESS_INFO';
export const REQUEST_UPDATE_MY_BUSINESS_INFO =
  'REQUEST_UPDATE_MY_BUSINESS_INFO';
export const REQUEST_CREATE_MY_BUSINESS_INFO =
  'REQUEST_CREATE_MY_BUSINESS_INFO';
export const RECEIVE_MY_BUSINESS_INFO_SUCCESS =
  'RECEIVE_MY_BUSINESS_INFO_SUCCESS';
export const RECEIVE_MY_BUSINESS_INFO_FAILURE =
  'RECEIVE_MY_BUSINESS_INFO_FAILURE';

// My slideshows
export const REQUEST_MY_SLIDES = 'REQUEST_MY_SLIDES';
export const RECEIVE_MY_SLIDES_SUCCESS = 'RECEIVE_MY_SLIDES_SUCCESS';
export const RECEIVE_MY_SLIDES_FAILURE = 'RECEIVE_MY_SLIDES_FAILURE';

// My products
export const REQUEST_MY_PRODUCTS = 'REQUEST_MY_PRODUCTS';
export const RECEIVE_MY_PRODUCTS_SUCCESS = 'RECEIVE_MY_PRODUCTS_SUCCESS';
export const RECEIVE_MY_FOOD_CATEGORY_SUCCESS =
  'RECEIVE_MY_FOOD_CATEGORY_SUCCESS';
export const RECEIVE_MY_OTHER_CATEGORY_SUCCESS =
  'RECEIVE_MY_OTHER_CATEGORY_SUCCESS';
export const RECEIVE_MY_PRODUCTS_FAILURE = 'RECEIVE_MY_PRODUCTS_FAILURE';

// My tables
export const REQUEST_MY_TABLES = 'REQUEST_MY_TABLES';
export const REQUEST_MY_TABLES_SUCCESS = 'REQUEST_MY_TABLES_SUCCESS';;
export const REQUEST_MY_TABLES_FAILURE = 'REQUEST_MY_TABLES_FAILURE';
export const DELETE_MY_TABLES = 'DELETE_MY_TABLES';
export const CREATE_MY_TABLES = 'CREATE_MY_TABLES';
export const EDIT_MY_TABLES = 'EDIT_MY_TABLES';
export const MY_TABLE_DETAIL = 'MY_TABLE_DETAIL';

// My digitals
export const RECEIVE_MY_DIGITALS = 'REQUEST_MY_DIGITALS';
export const RECEIVE_MY_DIGITALS_SUCCESS = 'RECEIVE_MY_DIGITALS_SUCCESS';
export const RECEIVE_MY_DIGITALS_FAILURE = 'RECEIVE_MY_DIGITALS_FAILURE';
export const DELETE_MY_DIGITAL = 'DELETE_MY_DIGITAL';
export const CREATE_MY_DIGITAL = 'CREATE_MY_DIGITAL';
export const MY_DIGITAL_DETAIL = 'MY_DIGITAL_DETAIL';
export const EDIT_MY_DIGITAL = 'EDIT_MY_DIGITAL';

// My screens
export const RECEIVE_MY_SCREENS = 'REQUEST_MY_SCREENS';
export const RECEIVE_MY_SCREENS_SUCCESS = 'RECEIVE_MY_SCREENS_SUCCESS';
export const RECEIVE_MY_SCREENS_FAILURE = 'RECEIVE_MY_SCREENS_FAILURE';
export const DELETE_MY_SCREEN = 'DELETE_MY_SCREEN';
export const CREATE_MY_SCREEN = 'CREATE_MY_SCREEN';
export const MY_SCREEN_DETAIL = 'MY_SCREEN_DETAIL';
export const EDIT_MY_SCREEN = 'EDIT_MY_SCREEN';
export const RECEIVE_SCREEN_SHARE = 'RECEIVE_SCREEN_SHARE';
export const COUNT_MY_SCREEN = 'COUNT_MY_SCREEN';
export const COUNT_MY_SCREEN_SUCCESS = 'COUNT_MY_SCREEN_SUCCESS';
export const UPDATE_MY_SCREEN_ONLINE = 'UPDATE_MY_SCREEN_ONLINE';

// My screens
export const RECEIVE_MY_SCREENS_SHARE = 'REQUEST_MY_SCREENS_SHARE';
export const RECEIVE_MY_SCREENS_SHARE_SUCCESS = 'RECEIVE_MY_SCREENS_SHARE_SUCCESS';
export const RECEIVE_MY_SCREENS_SHARE_FAILURE = 'RECEIVE_MY_SCREENS_SHARE_FAILURE';
export const DELETE_MY_SCREEN_SHARE = 'DELETE_MY_SCREEN_SHARE';
export const CREATE_MY_SCREEN_SHARE = 'CREATE_MY_SCREEN_SHARE';
export const MY_SCREEN_SHARE_DETAIL = 'MY_SCREEN_SHARE_DETAIL';
export const EDIT_MY_DIGITAL_SHARE = 'EDIT_MY_DIGITAL_SHARE';

export const RECEIVE_DIGITALS_SHARE = 'RECEIVE_DIGITALS_SHARE';
//   screen activity
export const ADD_SCREEN_ACTIVITY = 'ADD_SCREEN_ACTIVITY';

// My videos
export const REQUEST_MY_VIDEOS = 'REQUEST_MY_VIDEOS';
export const RECEIVE_MY_VIDEOS_SUCCESS = 'RECEIVE_MY_VIDEOS_SUCCESS';
export const RECEIVE_MY_VIDEOS_FAILURE = 'RECEIVE_MY_VIDEOS_FAILURE';
export const REQUEST_CREATE_MY_VIDEO = 'REQUEST_CREATE_MY_VIDEO';
export const CREATED_MY_VIDEO_SUCCESS = 'CREATED_MY_VIDEO_SUCCESS';
export const CREATED_MY_VIDEO_FAILURE = 'CREATED_MY_VIDEO_FAILURE';
export const CREATE_MY_VIDEO = 'CREATE_MY_VIDEO';
export const MY_VIDEO_DETAIL = 'MY_VIDEO_DETAIL';
export const EDIT_MY_VIDEO = 'EDIT_MY_VIDEO';
export const DELETE_MY_VIDEO = 'DELETE_MY_VIDEO';
export const REVIEW_VIDEO = 'REVIEW_VIDEO';

//
export const REQUEST_VIDEOS = 'REQUEST_VIDEOS';
export const RECEIVE_VIDEOS_SUCCESS = 'RECEIVE_VIDEOS_SUCCESS';
export const RECEIVE_VIDEOS_FAILURE = 'RECEIVE_VIDEOS_FAILURE';
export const RECEIVE_VIEW_VIDEOS = 'RECEIVE_VIEW_VIDEOS';
export const RECEIVE_COUNT_LIKE_VIDEOS = 'RECEIVE_COUNT_LIKE_VIDEOS';

// Create my product
export const REQUEST_CREATE_MY_PRODUCT = 'REQUEST_CREATE_MY_PRODUCT';
export const CREATED_MY_PRODUCT_SUCCESS = 'CREATED_MY_PRODUCT_SUCCESS';
export const CREATED_MY_PRODUCT_FAILURE = 'CREATED_MY_PRODUCT_FAILURE';
export const CREATE_MY_PRODUCT = 'CREATE_MY_PRODUCT';
export const MY_PRODUCT_DETAIL = 'MY_PRODUCT_DETAIL';
export const EDIT_MY_PRODUCT = 'EDIT_MY_PRODUCT';
export const DELETE_MY_PRODUCT = 'DELETE_MY_PRODUCT';

// Create my group option
export const CREATE_MY_GROUP_OPTION = 'CREATE_MY_GROUP_OPTION';
export const EDIT_MY_GROUP_OPTION = 'EDIT_MY_GROUP_OPTION';
export const DELETE_MY_GROUP_OPTION = 'DELETE_MY_GROUP_OPTION';

// Create my option
export const CREATE_MY_OPTION = 'CREATE_MY_OPTION';
export const EDIT_MY_OPTION = 'EDIT_MY_OPTION';
export const DELETE_MY_OPTION = 'DELETE_MY_OPTION';

// Delivery
export const CREATE_DELIVERY = 'CREATE_DELIVERY';
export const REQUEST_CANCEL_DELIVERY = 'REQUEST_DELIVERY';
export const REQUEST_DELIVERY = 'REQUEST_DELIVERY';
export const RECEIVE_DELIVERY_SUCCESS = 'RECEIVE_DELIVERY_SUCCESS';
export const RECEIVE_DELIVERY_FAILURE = 'RECEIVE_DELIVERY_FAILURE';
export const CREATE_DELIVERY_SCHEDULE = 'CREATE_DELIVERY';
export const REQUEST_DELIVERY_SCHEDULE = 'REQUEST_DELIVERY_SCHEDULE';
export const RECEIVE_DELIVERY_SCHEDULE_SUCCESS =
  'RECEIVE_DELIVERY_SCHEDULE_SUCCESS';
export const RECEIVE_DELIVERY_SCHEDULE_FAILURE =
  'RECEIVE_DELIVERY_SCHEDULE_FAILURE';
export const REQUEST_DELIVERY_DETAIL = 'REQUEST_DELIVERY_DETAIL';
export const RECEIVE_DELIVERY_DETAIL_SUCCESS =
  'RECEIVE_DELIVERY_DETAIL_SUCCESS';
export const RECEIVE_DELIVERY_DETAIL_FAILURE =
  'RECEIVE_DELIVERY_DETAIL_FAILURE';

// Get users
export const REQUEST_STATISTICS_USERS = 'REQUEST_STATISTICS_USERS';
export const RECEIVE_STATISTICS_USERS_SUCCESS = 'RECEIVE_STATISTICS_USERS_SUCCESS';
export const RECEIVE_STATISTICS_USERS_FAILURE = 'RECEIVE_STATISTICS_USERS_FAILURE';

// My services
export const REQUEST_MY_SERVICES = 'REQUEST_MY_SERVICES';
export const RECEIVE_MY_SERVICES_SUCCESS = 'RECEIVE_MY_SERVICES_SUCCESS';
export const RECEIVE_MY_SERVICE_CATEGORIES_SUCCESS =
  'RECEIVE_MY_SERVICE_CATEGORIES_SUCCESS';
export const RECEIVE_MY_SERVICES_FAILURE = 'RECEIVE_MY_SERVICES_FAILURE';

// Create my service
export const CREATE_MY_SERVICE = 'CREATE_MY_SERVICE';
export const MY_SERVICE_DETAIL = 'MY_SERVICE_DETAIL';
export const EDIT_MY_SERVICE = 'EDIT_MY_SERVICE';
export const DELETE_MY_SERVICE = 'DELETE_MY_SERVICE';

// My collections
export const REQUEST_MY_COLLECTIONS = 'REQUEST_MY_COLLECTIONS';
export const RECEIVE_MY_COLLECTIONS_SUCCESS = 'RECEIVE_MY_COLLECTIONS_SUCCESS';
export const RECEIVE_MY_COLLECTION_CATEGORIES_SUCCESS =
  'RECEIVE_MY_COLLECTION_CATEGORIES_SUCCESS';
export const RECEIVE_MY_COLLECTIONS_FAILURE = 'RECEIVE_MY_COLLECTIONS_FAILURE';

// Create my collection
export const CREATE_MY_COLLECTION = 'CREATE_MY_COLLECTION';
export const MY_COLLECTION_DETAIL = 'MY_COLLECTION_DETAIL';
export const EDIT_MY_COLLECTION = 'EDIT_MY_COLLECTION';
export const DELETE_MY_COLLECTION = 'DELETE_MY_COLLECTION';

export const GET_TIME_SLOTS = 'GET_TIME_SLOTS';
export const BOOK_SERVICE = 'BOOK_SERVICE';
export const RECEIVE_BOOKING_DETAIL = 'RECEIVE_BOOKING_DETAIL';

// Booking detail of buyers
export const REQUEST_BOOKING_DETAILS_OF_BUYER =
  'REQUEST_BOOKING_DETAILS_OF_BUYER';
export const RECEIVE_BOOKING_DETAILS_OF_BUYER_SUCCESS =
  'RECEIVE_BOOKING_DETAILS_OF_BUYER_SUCCESS';
export const RECEIVE_BOOKING_DETAILS_OF_BUYER_FAILURE =
  'RECEIVE_BOOKING_DETAILS_OF_BUYER_FAILURE';

// Booking detail of sellers
export const REQUEST_BOOKING_DETAILS_OF_SELLER =
  'REQUEST_BOOKING_DETAILS_OF_SELLER';
export const RECEIVE_BOOKING_DETAILS_OF_SELLER_SUCCESS =
  'RECEIVE_BOOKING_DETAILS_OF_SELLER_SUCCESS';
export const RECEIVE_BOOKING_DETAILS_OF_SELLER_FAILURE =
  'RECEIVE_BOOKING_DETAILS_OF_SELLER_FAILURE';

// Order
export const REQUEST_BUYING_ORDERS = 'REQUEST_BUYING_ORDERS';
export const RECEIVE_BUYING_ORDERS_SUCCESS = 'RECEIVE_BUYING_ORDERS_SUCCESS';
export const RECEIVE_BUYING_ORDERS_FAILURE = 'RECEIVE_BUYING_ORDERS_FAILURE';
export const REQUEST_CANCEL_BUYING_ORDER = 'REQUEST_CANCEL_BUYING_ORDER';
// Order detail of buyers
export const REQUEST_BUYING_ORDER_DETAIL = 'REQUEST_BUYING_ORDER_DETAIL';
export const RECEIVE_BUYING_ORDER_DETAIL_SUCCESS =
  'RECEIVE_BUYING_ORDER_DETAIL_SUCCESS';
export const RECEIVE_BUYING_ORDER_DETAIL_FAILURE =
  'RECEIVE_BUYING_ORDER_DETAIL_FAILURE';

export const REQUEST_SELLING_ORDERS = 'REQUEST_SELLING_ORDERS';
export const RECEIVE_SELLING_ORDERS_SUCCESS = 'RECEIVE_SELLING_ORDERS_SUCCESS';
export const RECEIVE_SELLING_ORDERS_FAILURE = 'RECEIVE_SELLING_ORDERS_FAILURE';
// Order detail of sellers
export const REQUEST_SELLING_ORDER_DETAIL = 'REQUEST_SELLING_ORDER_DETAIL';
export const RECEIVE_SELLING_ORDER_DETAIL_SUCCESS =
  'RECEIVE_SELLING_ORDER_DETAIL_SUCCESS';
export const RECEIVE_SELLING_ORDER_DETAIL_FAILURE =
  'RECEIVE_SELLING_ORDER_DETAIL_FAILURE';

export const REQUEST_ORDERS_BOOKINGS = 'REQUEST_ORDERS_BOOKINGS';
export const RECEIVE_ORDERS_BOOKINGS_SUCCESS = 'RECEIVE_ORDERS_BOOKINGS_SUCCESS';
export const RECEIVE_ORDERS_BOOKINGS_FAILURE = 'RECEIVE_ORDERS_BOOKINGS_FAILURE';

export const ACCEPT_CANCEL_SELLING_ORDER = 'ACCEPT_CANCEL_SELLING_ORDER';
export const SELLER_CANCEL_ORDER = 'SELLER_CANCEL_ORDER';
export const DENY_CANCEL_SELLING_ORDER = 'DENY_CANCEL_SELLING_ORDER';
export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';

// User - Booked services
export const REQUEST_BOOKED_SERVICES = 'REQUEST_BOOKED_SERVICES';
export const RECEIVE_BOOKED_SERVICES_SUCCESS =
  'RECEIVE_BOOKED_SERVICES_SUCCESS';
export const RECEIVE_BOOKED_SERVICES_FAILURE =
  'RECEIVE_BOOKED_SERVICES_FAILURE';
export const REQUEST_CANCEL_BOOKING = 'REQUEST_CANCEL_BOOKING';
export const REQUEST_BOOKINGS_FOR_SELLER = 'REQUEST_BOOKINGS_FOR_SELLER';
export const RECEIVE_BOOKINGS_FOR_SELLER_SUCCESS =
  'RECEIVE_BOOKINGS_FOR_SELLER_SUCCESS';
export const RECEIVE_BOOKINGS_FOR_SELLER_FAILURE =
  'RECEIVE_BOOKINGS_FOR_SELLER_FAILURE';
export const ACCEPT_CANCEL_BOOKING = 'ACCEPT_CANCEL_BOOKING';
export const DENY_CANCEL_BOOKING = 'DENY_CANCEL_BOOKING';
export const CHANGE_BOOKING_STATUS = 'CHANGE_BOOKING_STATUS';

// Promotion
export const REQUEST_PROMOTION_PRODUCTS = 'REQUEST_PROMOTION_PRODUCTS';
export const RECEIVE_PROMOTION_PRODUCTS_SUCCESS =
  'RECEIVE_PROMOTION_PRODUCTS_SUCCESS';
export const RECEIVE_PROMOTION_PRODUCTS_FAILURE =
  'RECEIVE_PROMOTION_PRODUCTS_FAILURE';
export const DELETE_PROMOTION_PRODUCTS = 'DELETE_PROMOTION_PRODUCTS';
export const DELETE_PROMOTION_PRODUCTS_SUCCESS =
  'DELETE_PROMOTION_PRODUCTS_SUCCESS';

export const REQUEST_PROMOTION_SERVICES = 'REQUEST_PROMOTION_SERVICES';
export const RECEIVE_PROMOTION_SERVICES_SUCCESS =
  'RECEIVE_PROMOTION_SERVICES_SUCCESS';
export const RECEIVE_PROMOTION_SERVICES_FAILURE =
  'RECEIVE_PROMOTION_SERVICES_FAILURE';
export const DELETE_PROMOTION_SERVICES = 'DELETE_PROMOTION_SERVICES';
export const DELETE_PROMOTION_SERVICES_SUCCESS =
  'DELETE_PROMOTION_SERVICES_SUCCESS';

export const PROMOTION_DETAIL = 'PROMOTION_DETAIL';
export const CREATE_PROMOTION = 'CREATE_PROMOTION';
export const EDIT_PROMOTION = 'EDIT_PROMOTION';
export const BOOST_PROMOTION = 'BOOST_PROMOTION';

// ***** All products ***** //
export const REQUEST_ALL_PRODUCTS = 'REQUEST_ALL_PRODUCTS';
export const RECEIVE_ALL_PRODUCTS_BY_FILTER_SUCCESS =
  'RECEIVE_ALL_PRODUCTS_BY_FILTER_SUCCESS';
export const RECEIVE_ALL_PRODUCTS_BY_FILTER_FAILURE =
  'RECEIVE_ALL_PRODUCTS_BY_FILTER_FAILURE';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const REQUEST_SEARCH_PRODUCTS = 'REQUEST_SEARCH_PRODUCTS';
export const RECEIVE_SEARCHING_PRODUCTS_SUCCESS =
  'RECEIVE_SEARCHING_PRODUCTS_SUCCESS';
export const RECEIVE_SEARCHING_PRODUCTS_FAILURE =
  'RECEIVE_SEARCHING_PRODUCTS_FAILURE';
export const REQUEST_ALL_PRODUCTS_PICKS = 'REQUEST_ALL_PRODUCTS_PICKS';
export const REQUEST_ALL_PRODUCTS_PICKS_SUCCESS =
  'REQUEST_ALL_PRODUCTS_PICKS_SUCCESS';
export const REQUEST_ALL_PRODUCTS_PICKS_FAILURE =
  'REQUEST_ALL_PRODUCTS_PICKS_FAILURE';

// ***** Product detail ***** //
export const REQUEST_PRODUCT_DETAIL = 'REQUEST_PRODUCT_DETAIL';
export const RECEIVE_PRODUCT_DETAIL_SUCCESS = 'RECEIVE_PRODUCT_DETAIL_SUCCESS';
export const RECEIVE_PRODUCT_DETAIL_FAILURE = 'RECEIVE_PRODUCT_DETAIL_FAILURE';

// ***** Options product ***** //
export const OPTIONS_PRODUCT = 'OPTIONS_PRODUCT';
export const REQUEST_OPTIONS = 'REQUEST_OPTIONS';
export const RECEIVE_OPTIONS_SUCCESS = 'RECEIVE_OPTIONS_SUCCESS'
export const RECEIVE_OPTIONS_FAILURE = 'RECEIVE_OPTIONS_FAILURE'

// ***** Rating product ***** //
export const REVIEW_PRODUCT = 'REVIEW_PRODUCT';
export const RECEIVE_PRODUCT_REVIEW = 'RECEIVE_PRODUCT_REVIEW';

// ***** Feature products ***** //
export const REQUEST_FEATURE_PRODUCTS = 'REQUEST_FEATURE_PRODUCTS';
export const RECEIVE_FEATURE_PRODUCTS_SUCCESS =
  'RECEIVE_FEATURE_PRODUCTS_SUCCESS';
export const RECEIVE_FEATURE_PRODUCTS_FAILURE =
  'RECEIVE_FEATURE_PRODUCTS_FAILURE';

// ***** All services ***** //
export const REQUEST_ALL_SERVICES = 'REQUEST_ALL_SERVICES';
export const RECEIVE_ALL_SERVICES_BY_FILTER_SUCCESS =
  'RECEIVE_ALL_SERVICES_BY_FILTER_SUCCESS';
export const RECEIVE_ALL_SERVICES_BY_FILTER_FAILURE =
  'RECEIVE_ALL_SERVICES_BY_FILTER_FAILURE';
export const RECEIVE_ALL_SERVICES_SLIDER_BY_FILTER_SUCCESS =
  'RECEIVE_ALL_SERVICES_SLIDER_BY_FILTER_SUCCESS';
export const RECEIVE_ALL_SERVICES_SLIDER_BY_FILTER_FAILURE =
  'RECEIVE_ALL_SERVICES_SLIDER_BY_FILTER_FAILURE';

// ***** All services ***** //
export const REQUEST_ALL_COLLECTIONS = 'REQUEST_ALL_COLLECTIONS';
export const RECEIVE_ALL_COLLECTIONS_BY_FILTER_SUCCESS =
  'RECEIVE_ALL_COLLECTIONS_BY_FILTER_SUCCESS';
export const RECEIVE_ALL_COLLECTIONS_BY_FILTER_FAILURE =
  'RECEIVE_ALL_COLLECTIONS_BY_FILTER_FAILURE';
export const RECEIVE_ALL_COLLECTIONS_SLIDER_BY_FILTER_SUCCESS =
  'RECEIVE_ALL_COLLECTIONS_SLIDER_BY_FILTER_SUCCESS';
export const RECEIVE_ALL_COLLECTIONS_SLIDER_BY_FILTER_FAILURE =
  'RECEIVE_ALL_COLLECTIONS_SLIDER_BY_FILTER_FAILURE';


export const SEARCH_SERVICES = 'SEARCH_SERVICES';
export const REQUEST_SEARCH_SERVICES = 'REQUEST_SEARCH_SERVICES';
export const RECEIVE_SEARCHING_SERVICES_SUCCESS =
  'RECEIVE_SEARCHING_SERVICES_SUCCESS';
export const RECEIVE_SEARCHING_SERVICES_FAILURE =
  'RECEIVE_SEARCHING_SERVICES_FAILURE';
export const RECEIVE_ALL_PRODUCTS_PROMOTIONS_SLIDE_BY_FILTER_SUCCESS =
  'RECEIVE_ALL_PRODUCTS_PROMOTIONS_SLIDE_BY_FILTER_SUCCESS';
export const RECEIVE_ALL_PRODUCTS_PROMOTIONS_BY_FILTER_FAILURE =
  'RECEIVE_ALL_PRODUCTS_PROMOTIONS_BY_FILTER_FAILURE';
export const RECEIVE_ALL_PRODUCTS_PROMOTIONS_BY_FILTER_SUCCESS =
  'RECEIVE_ALL_PRODUCTS_PROMOTIONS_BY_FILTER_SUCCESS';
export const RECEIVE_ALL_PRODUCTS_PROMOTIONS_SLIDE_BY_FILTER_FAILURE =
  'RECEIVE_ALL_PRODUCTS_PROMOTIONS_SLIDE_BY_FILTER_FAILURE';

// ***** Service detail ***** //
export const REQUEST_SERVICE_DETAIL = 'REQUEST_SERVICE_DETAIL';
export const RECEIVE_SERVICE_DETAIL_SUCCESS = 'RECEIVE_SERVICE_DETAIL_SUCCESS';
export const RECEIVE_SERVICE_DETAIL_FAILURE = 'RECEIVE_SERVICE_DETAIL_FAILURE';

// ***** Rating service ***** //
export const REVIEW_SERVICE = 'REVIEW_SERVICE';

// ***** Feature service ***** //
export const REQUEST_FEATURE_SERVICES = 'REQUEST_FEATURE_SERVICES';
export const RECEIVE_FEATURE_SERVICES_SUCCESS =
  'RECEIVE_FEATURE_SERVICES_SUCCESS';
export const RECEIVE_FEATURE_SERVICES_FAILURE =
  'RECEIVE_FEATURE_SERVICES_FAILURE';

// ***** Feature service ***** //
export const REQUEST_FEATURE_COLLECTIONS = 'REQUEST_FEATURE_COLLECTIONS';
export const RECEIVE_FEATURE_COLLECTIONS_SUCCESS =
  'RECEIVE_FEATURE_COLLECTIONS_SUCCESS';
export const RECEIVE_FEATURE_COLLECTIONS_FAILURE =
  'RECEIVE_FEATURE_COLLECTIONS_FAILURE';

// ***** Contact ***** //
export const SEND_CONTACT_INFO = 'SEND_CONTACT_INFO';

// ***** Store ***** //
export const RECEIVE_STORE_PRODUCTS = 'RECEIVE_STORE_PRODUCTS';
export const RECEIVE_STORE_PRODUCTS_SUCCESS = 'RECEIVE_STORE_PRODUCTS_SUCCESS';
export const RECEIVE_STORE_SERVICES = 'RECEIVE_STORE_SERVICES';
export const RECEIVE_STORE_SERVICES_SUCCESS = 'RECEIVE_STORE_SERVICES_SUCCESS';
export const RECEIVE_STORE_INFORMATION = 'RECEIVE_STORE_INFORMATION';
export const RECEIVE_STORE_INFORMATION_SUCCESS =
  'RECEIVE_STORE_INFORMATION_SUCCESS';
export const RECEIVE_STORE_PROMOTION_PRODUCTS =
  'RECEIVE_STORE_PROMOTION_PRODUCTS';
export const RECEIVE_STORE_PROMOTION_PRODUCTS_SUCCESS =
  'RECEIVE_STORE_PROMOTION_PRODUCTS_SUCCESS';
export const RECEIVE_STORE_PROMOTION_SERVICES =
  'RECEIVE_STORE_PROMOTION_SERVICES';
export const RECEIVE_STORE_PROMOTION_SERVICES_SUCCESS =
  'RECEIVE_STORE_PROMOTION_SERVICES_SUCCESS';
export const RECEIVE_STORE_SLIDES = 'RECEIVE_STORE_SLIDES';
export const RECEIVE_STORE_SLIDES_SUCCESS = 'RECEIVE_STORE_SLIDES_SUCCESS';
export const SEARCH_STORES = 'SEARCH_STORES';
export const SEARCH_ALL = 'SEARCH_ALL';

// ***** Clear all data ***** //
export const CLEAR_DATA = 'CLEAR_DATA';

// ***** Language ***** //
export const LOCALE_SET = 'LOCALE_SET';

// ***** Reviews ***** //
export const REQUEST_REVIEWS = 'REQUEST_REVIEWS';
export const RECEIVE_REVIEWS_SUCCESS = 'RECEIVE_REVIEWS_SUCCESS';
export const RECEIVE_REVIEWS_FAILURE = 'RECEIVE_REVIEWS_FAILURE';
export const EDIT_REVIEWS = 'EDIT_REVIEWS';

// ***** Notification ***** //
export const SAVE_USER_DEVICE = 'SAVE_USER_DEVICE';
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';

// ***** Likes ***** //
export const REQUEST_LIKES = 'REQUEST_LIKES';
export const RECEIVE_LIKES_SUCCESS = 'RECEIVE_LIKES_SUCCESS';
export const RECEIVE_LIKES_FAILURE = 'RECEIVE_LIKES_FAILURE';
export const REQUEST_USER_LIKES = 'REQUEST_USER_LIKES';
export const RECEIVE_USER_LIKES_SUCCESS = 'RECEIVE_USER_LIKES_SUCCESS';
export const RECEIVE_USER_LIKES_FAILURE = 'RECEIVE_USER_LIKES_FAILURE';
export const REVIEW_LIKE = 'REVIEW_LIKE';

export const RECEIVE_STORE_BOOKING = 'RECEIVE_STORE_BOOKING';
export const ADD_STORE_BOOKING = 'ADD_STORE_BOOKING';
export const DELETE_STORE_BOOKING = 'DELETE_STORE_BOOKING';
export const RESET_STORE_BOOKING = 'RESET_STORE_BOOKING';

export const RECEIVE_POS_CART = 'RECEIVE_POS_CART';
export const ADD_POS_CART = 'ADD_POS_CART';
export const UPDATE_POS_CART = 'UPDATE_POS_CART';
export const DELETE_POS_CART = 'DELETE_POS_CART';
export const RESET_POS_CART = 'RESET_POS_CART';

export const RECEIVE_STORE_USER_CONTACTS = 'RECEIVE_STORE_USER_CONTACTS';
export const DELETE_STORE_USER_CONTACTS = 'DELETE_STORE_USER_CONTACTS';
export const ADD_STORE_USER_CONTACTS = 'ADD_STORE_USER_CONTACTS';

export const RECEIVE_COORDINATES = 'RECEIVE_COORDINATES';
export const ADD_COORDINATES = 'ADD_COORDINATES';
export const RECEIVE_COORDINATES_SUCCESS = 'RECEIVE_COORDINATES_SUCCESS';
export const RECEIVE_AREAS_BY_BUSINESS = 'RECEIVE_AREAS_BY_BUSINESS';
export const RECEIVE_AREAS_BY_BUSINESS_SUCCESS = 'RECEIVE_AREAS_BY_BUSINESS_SUCCESS';
export const RECEIVE_AREAS_BY_BUSINESS_FAILURE = 'RECEIVE_AREAS_BY_BUSINESS_FAILURE';
export const RECEIVE_AREAS = 'RECEIVE_AREAS';
export const RECEIVE_AREAS_SUCCESS = 'RECEIVE_AREAS_SUCCESS';
export const RECEIVE_AREAS_FAILURE = 'RECEIVE_AREAS_FAILURE';
export const ADD_USER_AREA = 'ADD_USER_AREA';
export const ADD_TYPE = 'ADD_TYPE';

export const RECEIVE_STORE_MY_CONTACTS = 'RECEIVE_STORE_MY_CONTACTS';
export const RECEIVE_STORE_MY_CONTACTS_SUCCESS = 'RECEIVE_STORE_MY_CONTACTS_SUCCESS';
export const DELETE_STORE_MY_CONTACTS = 'DELETE_STORE_MY_CONTACTS';
export const ADD_STORE_MY_CONTACTS = 'ADD_STORE_MY_CONTACTS';

export const REQUEST_SCAN_COUNT = 'REQUEST_SCAN_COUNT';
export const RECEIVE_SCAN_COUNT_SUCCESS = 'RECEIVE_SCAN_COUNT_SUCCESS';
export const RECEIVE_SCAN_COUNT_FAILURE = 'RECEIVE_SCAN_COUNT_FAILURE';
export const ADD_SCAN_COUNT = 'ADD_SCAN_COUNT';

export const REQUEST_NOTIS = 'REQUEST_NOTIS';
export const RECEIVE_NOTIS_SUCCESS = 'RECEIVE_NOTIS_SUCCESS';
export const RECEIVE_NOTIS_FAILURE = 'RECEIVE_NOTIS_FAILURE';

// Projects
export const REQUEST_MY_PROJECTS = 'REQUEST_MY_PROJECTS';
export const RECEIVE_MY_PROJECTS_SUCCESS = 'RECEIVE_MY_PROJECTS_SUCCESS';
export const RECEIVE_MY_PROJECTS_FAILURE = 'RECEIVE_MY_PROJECTS_FAILURE';
export const MY_PROJECT_DETAIL = 'MY_PROJECT_DETAIL';
export const CREATE_MY_PROJECT = 'CREATE_MY_PROJECT';
export const EDIT_MY_PROJECT = 'EDIT_MY_PROJECT';
export const REMOVE_MY_PROJECT = 'REMOVE_MY_PROJECT';
// Tasks
export const REQUEST_MY_TASKS = 'REQUEST_MY_TASKS';
export const RECEIVE_MY_TASKS_SUCCESS = 'RECEIVE_MY_TASKS_SUCCESS';
export const RECEIVE_MY_TASKS_FAILURE = 'RECEIVE_MY_TASKS_FAILURE';
export const MY_TASK_DETAIL = 'MY_TASK_DETAIL';
export const CREATE_MY_TASK = 'CREATE_MY_TASK';
export const EDIT_MY_TASK = 'EDIT_MY_TASK';
export const REMOVE_MY_TASK = 'REMOVE_MY_TASK';
// Staff
export const MY_STAFF_ASSIGN= 'MY_STAFF_ASSIGN';
export const REQUEST_MY_STAFFS = 'REQUEST_MY_STAFFS';
export const RECEIVE_MY_STAFFS_SUCCESS = 'RECEIVE_MY_STAFFS_SUCCESS';
export const RECEIVE_MY_STAFFS_FAILURE = 'RECEIVE_MY_STAFFS_FAILURE';
export const MY_STAFF_DETAIL = 'MY_STAFF_DETAIL';
export const CREATE_MY_STAFF = 'CREATE_MY_STAFF';
export const EDIT_MY_STAFF = 'EDIT_MY_STAFF';
export const REMOVE_MY_STAFF = 'REMOVE_MY_STAFF';
export const REQUEST_MY_TASK_MEMBERS = 'REQUEST_MY_TASK_MEMBERS';
export const RECEIVE_MY_TASK_MEMBERS_SUCCESS = 'RECEIVE_MY_TASK_MEMBERS_SUCCESS';
export const RECEIVE_MY_TASK_MEMBERS_FAILURE = 'RECEIVE_MY_TASK_MEMBERS_FAILURE';
export const MY_TASK_MEMBER_DETAIL = 'MY_TASK_MEMBER_DETAIL';
export const CREATE_MY_TASK_MEMBER = 'CREATE_MY_TASK_MEMBER';
export const EDIT_MY_TASK_MEMBER = 'EDIT_MY_TASK_MEMBER';
export const REMOVE_MY_TASK_MEMBER = 'REMOVE_MY_TASK_MEMBER';
export const REQUEST_MY_SERVICE_MEMBERS = 'REQUEST_MY_SERVICE_MEMBERS';
export const RECEIVE_MY_SERVICE_MEMBERS_SUCCESS = 'RECEIVE_MY_SERVICE_MEMBERS_SUCCESS';
export const RECEIVE_MY_SERVICE_MEMBERS_FAILURE = 'RECEIVE_MY_SERVICE_MEMBERS_FAILURE';
export const REQUEST_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE = 'REQUEST_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE';
export const RECEIVE_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE_SUCCESS = 'RECEIVE_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE_SUCCESS';
export const RECEIVE_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE_FAILURE = 'RECEIVE_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE_FAILURE';
export const MY_SERVICE_MEMBER_DETAIL = 'MY_SERVICE_MEMBER_DETAIL';
export const CREATE_MY_SERVICE_MEMBER = 'CREATE_MY_SERVICE_MEMBER';
export const EDIT_MY_SERVICE_MEMBER = 'EDIT_MY_SERVICE_MEMBER';
export const REMOVE_MY_SERVICE_MEMBER = 'REMOVE_MY_SERVICE_MEMBER';
//CUSTOMER
export const CUSTOMER_ADD_INFO = 'CUSTOMER_ADD_INFO';
export const CUSTOMER_ADD_CHECK_CODE = 'CUSTOMER_ADD_CHECK_CODE';

//First scan Qr promotion
export const REQUEST_MY_QR_PROMOTION= 'REQUEST_MY_QR_PROMOTION';
export const REQUEST_MY_CUSTOMER_BY_QR_PROMOTION= 'REQUEST_MY_QR_PROMOTION';
export const MY_QR_PROMOTION_ASSIGN= 'MY_QR_PROMOTION_ASSIGN';
export const RECEIVE_MY_QR_PROMOTION_SUCCESS = 'RECEIVE_MY_QR_PROMOTION_SUCCESS';
export const RECEIVE_MY_QR_PROMOTION_FAILURE = 'RECEIVE_MY_QR_PROMOTION_FAILURE';
export const MY_QR_PROMOTION_DETAIL = 'MY_QR_PROMOTION_DETAIL';
export const CREATE_MY_QR_PROMOTION = 'CREATE_MY_QR_PROMOTION';
export const EDIT_MY_QR_PROMOTION = 'EDIT_MY_QR_PROMOTION';
export const REMOVE_MY_QR_PROMOTION = 'REMOVE_MY_QR_PROMOTION';