import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = params => {
  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/digital-share/${params.id}`
  };
};

const parseResponse = response => {
    const data = [];
    if (response.data) {
      response.data.forEach((item) => {
        const itemNew = formatJson(response, item.type, item.id);
        data.push(itemNew);
      });
    }
    return data;
  }

export { exec, parseResponse };