import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Config from 'constants/Config';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share';

class LikeShareVideo extends Component {
  static propTypes = {
    onGetLikesVideo: PropTypes.func,
    onGetUserLikesVideo: PropTypes.func,
    onLikesVideo: PropTypes.func,
    intl: intlShape.isRequired,
    onAddLikeVideo: PropTypes.func,
    onOpenModal:PropTypes.func,
  };
  state = {
    like: [],
  };
  getLikeVideo = () => {
    this.props.onGetLikesVideo({
      id: this.props.id,
    });
    this.props.onGetUserLikesVideo({
      id: this.props.id,
      user_id: this.props.idUser,
    });
    this.props.onAddLikeVideo({
      id: this.props.id,
    });
  };

  likeVideo = () => {
    if (!this.props.idUser) {
      this.props.onOpenModal();
      return false;
    }
    
    const { intl } = this.props;
    this.props
      .onLikesVideo({
        id: this.props.id,
        like: true,
      })
      .then(() => {
        this.getLikeVideo();
        if (this.props.userLike.length === 0) {
          this.props.onNotification.addNotification({
            message: intl.formatMessage({ id: 'notification.thank_for_like' }),
            level: 'success',
          });
        }
      })
      .catch(() => {
        this.setState({ liked: false });
        this.props.onNotification.addNotification({
          message: intl.formatMessage({
            id: 'notification.please_login_to_make_like',
          }),
          level: 'error',
        });
      });
  };
  componentDidMount() {
    this.getLikeVideo();

    this._notificationSystem = this.refs.notificationSystem;
  }
  render() {
    return (
      <Pane className="d-flex">
        <Pane id="sharesocial"
          className="row d-flex justify-content-between col-12 mt-0 p-0 ml-1" style={{fontSize: "12px", alignItems: "center"}}>
          <Pane 
            style={{
              display: 'inline-flex',
              whiteSpace: 'nowrap',
              alignItems: 'center',
            }}
            className="mt-3 mr-1">
            <Pane className="mr-3" style={{fontSize: '14px'}}><b>{this.props.countView} views</b></Pane>
            <Pane onClick={this.likeVideo}>
              {this.props.userLike.length > 0 &&
              this.props.userLike[0].like === 1 ? (
                  <img
                    src={
                      process.env.PUBLIC_URL +
                    '/assets/images/icons/like-blue.svg'
                    }
                    alt="image"
                    aria-hidden
                    className="icon mr-2 mb-1"
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <img
                    src={
                      process.env.PUBLIC_URL +
                    '/assets/images/icons/like-gray.svg'
                    }
                    alt="image"
                    aria-hidden
                    className="icon mr-2 mb-1"
                    style={{ cursor: 'pointer' }}
                  />
                )}
            </Pane>
            <Pane>{this.props.like.results.length === 0 ? 'likes' : this.props.like.results.length}</Pane>
          </Pane>
          <List className="d-flex justify-content-end">
            <List.Item 
              style={{ display: 'inline-flex' }}>
              <List.Content className="d-flex mt-1">
                <i className="fa fa-share-alt mr10" />
                <FormattedMessage id="share" defaultMessage="Share" />:
              </List.Content>
              <List.Content className="item">
                <FacebookShareButton
                  url={Config.SITE_URL + this.props.shareUrl}
                >
                  <FacebookIcon size={20} round={true} />
                </FacebookShareButton>
              </List.Content>
              <List.Content className="item">
                <TwitterShareButton url={Config.SITE_URL + this.props.shareUrl}>
                  <TwitterIcon size={20} round={true} />
                </TwitterShareButton>
              </List.Content>
              <List.Content className="item">
                <EmailShareButton url={Config.SITE_URL + this.props.shareUrl}>
                  <EmailIcon size={20} round={true} />
                </EmailShareButton>
              </List.Content>
              <List.Content className="item">
                <LinkedinShareButton
                  url={Config.SITE_URL + this.props.shareUrl}
                >
                  <LinkedinIcon size={20} round={true} />
                </LinkedinShareButton>
              </List.Content>
              <List.Content className="item">
                <WhatsappShareButton
                  url={Config.SITE_URL + this.props.shareUrl}
                >
                  <WhatsappIcon size={20} round={true} />
                </WhatsappShareButton>
              </List.Content>
            </List.Item>
          </List>
        </Pane>
      </Pane>
    );
  }
}
LikeShareVideo.propTypes = {};
export default withRouter(injectIntl(LikeShareVideo));
