import * as types from 'constants/ActionTypes'
import normalize from 'json-api-normalizer'
import UserContactAdapter from 'services/userContacts/adapter'
const initialState = {
    results: [],
    userContacts: [],
    myContacts: [],
    schema: [],
    totalItems: 0,
    totalPages: 0,
    error: null,
    fetching: false,
    fetched: false
}

const userContacts = (state  = initialState, action) => {
    let { id } = action
    switch(action.type){
			case types.RECEIVE_STORE_USER_CONTACTS:
				state.userContacts = action.userContacts;
				return state;
      case types.DELETE_STORE_USER_CONTACTS:
				let index = state.userContacts.findIndex(x => (x.id === id && x.type === action.typeBusiness));
        state.userContacts.splice(index, 1);
        localStorage.setItem("userContacts", JSON.stringify(state.userContacts));
        return {...state};
      case types.ADD_STORE_USER_CONTACTS:
        let { userContact } = action
        state.userContacts.push(userContact);
        localStorage.setItem("userContacts", JSON.stringify(state.userContacts));
        return {...state};
			case types.RECEIVE_STORE_MY_CONTACTS:
				return Object.assign({}, state, {
					fetching: true
				})
      case types.RECEIVE_STORE_MY_CONTACTS_SUCCESS:
				return Object.assign({}, state, {
						results: UserContactAdapter.browse.parseResponse(action.payload.data),
						myContacts: action.payload.data,
						schema: normalize(action.payload.data, {
						camelizeTypeValues: true,
						camelizeKeys: true
						}),
						totalItems: action.payload.data.meta.totalItems,
						totalPages: action.payload.data.meta.totalPages,
						error: {},
						fetching: false,
						fetched: true
				})
      default:
				return state
    }
}

export default userContacts;