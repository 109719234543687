import * as types from 'constants/ActionTypes'
import UserAdapter from 'services/users/adapter'

const initialState = {
  results: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const mySlides = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_SLIDES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_MY_SLIDES_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.slideBrowse.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_SLIDES_FAILURE:
      return Object.assign({}, state, {
        totalItems: 0,
        totalPages: 0,
        results: [],
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { mySlides }
