import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import CreateMenuForm from "./CreateMenuForm";
import CategoryAdapter from "services/categories/adapter";
import { getProductCategories, createCategory } from "actions/category";
import { getProductsByUserId, createMyProduct } from "actions/myProducts";
import { createMyOption, createMyOptionGroup } from "actions/options";
import { Pane } from 'shared/components/layouts';
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';

class CreateMenu extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getProductCategories: PropTypes.func,
    createMyProduct: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    getProductsByUserId: PropTypes.func.isRequired,
    createMyOption: PropTypes.func.isRequired,
    createMyOptionGroup: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }

  getMyProducts = () => {
    this.props.getProductsByUserId({ id: this.props.userInfo.id });
  };

  componentDidMount() {
    this.props.getProductCategories({ group: "Menu" }).then((response) => {
      const categories = CategoryAdapter.productCategoryBrowse.parseResponse(
        response
      );
      this.setState({
        data: categories.data,
      });
    });

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    const { data } = this.state;
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/menu`,
        name: 'Menu'
      },
      {
        url: ``,
        name: 'Create new menu',
        active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="text-center mb-4" id="anchor">
        </Pane>
        <CreateMenuForm
          userInfo={this.props.userInfo}
          categories={data}
          getMyProducts={this.getMyProducts}
          createMyProduct={this.props.createMyProduct}
          history={this.props.history}
          createCategory={this.props.createCategory}
          createMyOption={this.props.createMyOption}
          createMyOptionGroup={this.props.createMyOptionGroup}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.productCategories,
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  createMyProduct: bindActionCreators(createMyProduct, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
  createMyOption: bindActionCreators(createMyOption, dispatch),
  createMyOptionGroup: bindActionCreators(createMyOptionGroup, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(CreateMenu);
