import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Pane } from 'shared/components/layouts';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';

class YourOnlineShop extends Component {
  render() {
    return (
      <Pane className="online-container">
        <Pane className="container">
          <Pane className="row">
            <Pane className="col-lg-6 col-md-6">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInUp"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + '/assets/images/home/computer.png'
                  }
                  alt="computer"
                  className="online-img"
                />
              </ScrollAnimation>
            </Pane>
            <Pane className="col-lg-6 col-md-6 d-flex align-items-center">
              <Pane className="online-content ">
                <h3 className="online-title">
                  <FormattedMessage id="home.create_your_beautiful_free_online_store_in_minutes" />
                </h3>
                <p className="online-norm">
                  <FormattedMessage id="home.yes_just_in_minutes" />
                </p>
                <Link to="/learn-more" className="online-link">
                  <FormattedMessage id="created_a_free_shop" />
                  <img
                    src={
                      process.env.PUBLIC_URL + '/assets/images/home/arrow-3.svg'
                    }
                    alt="arrow-3"
                    className="icon"
                  />
                </Link>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="row flex-lg-row flex-md-row flex-sm-column-reverse flex-column-reverse">
            <Pane className="col-lg-6 col-md-6 d-flex align-items-center">
              <Pane className="online-content ">
                <h3 className="online-title">
                  <FormattedMessage id="home.and_now_the_the_business_world_is_in_your_palm" />
                </h3>
                <p className="online-norm">
                  <FormattedMessage id="home.with_conscientious_advanced" />
                </p>
                <Link to="/learn-more" className="online-link">
                  <FormattedMessage id="created_a_free_shop" />
                  <img
                    src={
                      process.env.PUBLIC_URL + '/assets/images/home/arrow-3.svg'
                    }
                    alt="arrow-3"
                    className="icon"
                  />
                </Link>
              </Pane>
            </Pane>
            <Pane className="col-lg-6 col-md-6">
              <ScrollAnimation
                duration={3}
                animateOnce={true}
                animateIn="fadeInRight"
              >
                <img
                  src={process.env.PUBLIC_URL + '/assets/images/home/phone.png'}
                  alt="phone"
                  className="online-img"
                />
              </ScrollAnimation>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
const bindStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(bindStateToProps)(
  (injectIntl(YourOnlineShop)))
);
