import * as MyBusinessReadV1 from './myBusiness.read.v1.action'
import * as MyBusinessEditV1 from './myBusiness.edit.v1.action'

export default {
  read: {
    callAPI: params => MyBusinessReadV1.exec(params),
    parseResponse: response => MyBusinessReadV1.parseResponse(response)
  },
  edit: {
    callAPI: params => MyBusinessEditV1.exec(params),
    parseResponse: response => MyBusinessEditV1.parseResponse(response)
  }
}
