import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const group = (params && params.group) || 'Product';
  const user_id = (params && params.user_id) || '';
  const id = (params && params.id) || '';
  const sort = (params && params.sort) || '';
  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/categories?id[]=${id}&user_id=${user_id}&group=${group}&sort=${sort}&page[size]=1000`,
  };
};

const parseResponse = (response) => {
  response.data.data.forEach((item) => {
    const product = formatJson(response.data, item.type, item.id);
    item.children = product.children || [];
    item.name = item.attributes.name;
    item.type = item.attributes.group.toLowerCase();
    item.image =
      item.attributes.primaryImage && item.attributes.primaryImage.url;
    item.category = product.children || [];
  });
  const data = {
    ...response,
    data: response.data.data,
  };

  return data;
};

export { exec, parseResponse };
