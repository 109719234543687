import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import { Pane, Loading } from 'shared/components/layouts';
import FeatureServiceItem from './FeatureServiceItem';
import { getFeatureServices } from 'actions/services';

class FeatureServices extends Component {
  static propTypes = {
    getFeatureServices: PropTypes.func.isRequired,
    services: PropTypes.object
  }

  componentDidMount() {
    this.props.getFeatureServices({});
  }

  renderList(services) {
    if (!services) { return; }

    /*return services.map(item => {
      return (
        <FeatureServiceItem
          key={item.id}
          item={item}
          id={item.id}
          name={item.name}
          price={item.promotionPrice || item.originalPrice}
          unitOfPrice={item.unitOfPrice}
          image={item.primaryImage}
        />
      )
    })*/
    let serviceitems = [];
    return services.map((item, index) => {
      serviceitems.push(item);
      if ((index +1) % 4 === 0) {
        const serviceitemsdraw = serviceitems;
        serviceitems = [];
        return (<FeatureServiceItem
          items={serviceitemsdraw}
        />);
      } else if (index === services.length -1) {
        const serviceitemsdraw = serviceitems;
        serviceitems = [];
        return (<FeatureServiceItem key= {'Service_' + index}
          items={serviceitemsdraw}
        />);
      }
      return null;
    });
  }

  render() {
    const settings = {
      dots: true,
      autoplay:true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Pane className='col-xl-12 content-box feature-products-container'>
        <Pane className='row row-featured'>
          <Pane className='col-xl-12 box-title'>
            <Pane className='inner'>
              <h2><span className="text-lighter"><FormattedMessage id='featured' defaultMessage='Featured' /></span> <FormattedMessage id='services' defaultMessage='Services' /></h2>
            </Pane>
          </Pane>
          <Pane className='clearfix' />
          <Pane className='relative content featured-list-row w100'>
            <Pane className='no-margin featured-list-slider'>
              <Slider {...settings}>
                {
                  !this.props.services.fetched &&
                  <Dimmer active inverted>
                    <Loading />
                  </Dimmer>
                }
                {
                  this.renderList(this.props.services.results)
                }
              </Slider>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const bindStateToProps = state => ({
  services: state.featureServices
});

const bindDispatchToProps = dispatch => ({
  getFeatureServices: bindActionCreators(getFeatureServices, dispatch)
});

export default connect(bindStateToProps, bindDispatchToProps)(FeatureServices);
