import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Dimmer, Button, TextArea, Icon, Image } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-responsive-modal';
import NotificationSystem from 'react-notification-system';
import { Pane, Loading } from 'shared/components/layouts';
import { getBookingDetailsOfSellers, acceptCancelBooking, denyCancelBooking, changeStatus } from 'actions/bookings';
import { getNotisByUser } from 'actions/notis';
import { pushNotification } from 'actions/notification';
import BookingSummary from './BookingSummary';
import BookingSubDetail from './BookingSubDetail';
import BookedServiceList from './BookedServiceList';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import { handleError, formatDecimal, formatRoute } from 'shared/helpers';
import Config from "constants/Config";
import { Breadcumb } from '../../common/breadcumb';

class CustomerBookingDetail extends Component {
  static propTypes = {
    detail: PropTypes.object,
    getBookingDetailsOfSellers: PropTypes.func,
    pushNotification: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    detail: {},
    loading: false,
    data: this.props.data || {},
    reason: '',
    open: false,
    openReason: false,
    status: false,
    loadingAcceptCancel: false,
    orderStatus: "",
    openConfirmModal: false,
    loadingStatus: false
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  getBookingDetailsOfSellers = (load) => {
    this.setState({ loading: load });
    this.props
      .getBookingDetailsOfSellers({
        bookingId: this.props.match.params.bookingId,
      })
      .then((response) => {
        if (load && this.props.detail.updateRead) {
          this.props.getNotisByUser({ id: this.props.userInfo.id });
        }
        this.setState({
          detail: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.getBookingDetailsOfSellers(true);

    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      this.props &&
      prevProps.match.params.bookingId !== this.props.match.params.bookingId
    ) {
      this.getBookingDetailsOfSellers(true);
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onOpenModal = (status) => {
    this.setState({
      open: true,
      reason: '',
      status,
    });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      reason: '',
    });
  };

  onOpenReasonModal = () => {
    this.setState({
      openReason: true,
    });
  };

  onCloseReasonModal = () => {
    this.setState({
      openReason: false,
    });
  };

  onOpenConfirmModal = (status) => {
    this.setState({
      openConfirmModal: true,
      orderStatus: status,
    });
  };

  onCloseConfirmModal = () => {
    this.setState({
      openConfirmModal: false,
    });
  };

  acceptCancelFromBuyer = () => {
    const {
      intl,
      detail,
      acceptCancel,
      pushNotification,
    } = this.props;

    const bookingId = this.props.match.params.bookingId;

    const data = detail.bookingItems && detail.bookingItems.length > 0 && detail.bookingItems[0];

    const params = {
      id: bookingId,
      reason: this.state.reason,
    };

    this.setState({ loadingAcceptCancel: true });
    this.onCloseModal();
    acceptCancel(params)
      .then((response) => {
        this.getBookingDetailsOfSellers(false);

        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: 'notification.successfully' }),
          level: 'success',
        });

        // Push notification to buyer
        // pushNotification({
        //   object_id: bookingId.toString(),
        //   object_type: 'Booking',
        //   target_id: detail.user.id.toString(),
        //   target_type: 'Buyer',
        //   summary: `Your request cancel has been approved in Booking #${bookingId}`,
        //   meta: {
        //     url: `/u/${formatRoute(
        //       detail.user.name,
        //       '-'
        //     )}/bookings/${bookingId}/detail`,
        //     serviceId: data.serviceId.toString(),
        //     serviceName: data.serviceName,
        //   },
        // }).catch();

        this.setState({ loadingAcceptCancel: false });
      })
      .catch((errors) => {
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });

        this.setState({ loadingAcceptCancel: false });
      });
  };

  denyCancelFromBuyer = () => {
    const {
      intl,
      detail,
      denyCancel,
    } = this.props;

    const data = detail.bookingItems && detail.bookingItems.length > 0 && detail.bookingItems[0];

    const bookingId = this.props.match.params.bookingId;

    const params = {
      id: bookingId,
      reason: this.state.reason,
    };

    this.setState({ loadingAcceptCancel: true });
    this.onCloseModal();
    denyCancel(params)
      .then((response) => {
        this.getBookingDetailsOfSellers(false);

        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: 'notification.successfully' }),
          level: 'success',
        });

        // Push notification to buyer
        // pushNotification({
        //   object_id: bookingId.toString(),
        //   object_type: 'Booking',
        //   target_id: detail.user.id.toString(),
        //   target_type: 'Buyer',
        //   summary: `Your request cancel has been denied in Booking #${bookingId}`,
        //   meta: {
        //     url: `/u/${formatRoute(
        //       detail.user.name,
        //       '-'
        //     )}/bookings/${bookingId}/detail`,
        //     serviceId: data.serviceId.toString(),
        //     serviceName: data.serviceName,
        //   },
        // }).catch();

        this.setState({ loadingAcceptCancel: false });
      })
      .catch((errors) => {
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });

        this.setState({ loadingAcceptCancel: false });
      });
  };

  changeItemStatus = (status) => {
    const bookingId = this.props.match.params.bookingId;
    const params = {
      id: bookingId,
      status,
    };
    const { intl, detail } = this.props;

    this.setState({ loadingStatus: true });
    this.onCloseConfirmModal();

    this.props.changeStatus(params)
      .then(() => {
        this.getBookingDetailsOfSellers();
        // Push notification to buyer
        // this.props
        //   .pushNotification({
        //     object_id: bookingId,
        //     object_type: "Booking",
        //     target_id: detail.user.id,
        //     target_type: "Buyer",
        //     summary: `An item ${status.toLowerCase()} for your Booking #${bookingId}`,
        //     meta: {
        //       order_type: "buying",
        //       order_id: bookingId,
        //       url: `/u/${detail.user.name}/bookings/${bookingId}/buying/detail`,
        //     },
        //   })
        //   .catch();

        this.setState({ loadingStatus: false });
      })
      .catch((errors) => {
        this.setState({ loadingStatus: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  render() {
    const { detail, intl } = this.props;
    const { loading } = this.state;
    const data = detail.bookingItems && detail.bookingItems.length > 0 && detail.bookingItems[0];
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/bookings`,
        name: 'Bookings'
      },
      {
        url: ``, name: 'Booking detail', active: true
      }
    ];

    return (
      <Pane className="overview-container">
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="product-container order-container">
          <h3 className="block-title pt10 text-uppercase">
            <FormattedMessage
              id="manage_booked_services"
              defaultMessage="Manage booked services"
            />
          </h3>
          <Pane className="order-detail" id="anchor">
            {this.state.loading && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {detail && detail.id ? (
              <BookingSummary detail={detail} />
            ) : (
              <Pane className="no-detail" style={{ textAlign: 'center' }}>
                <FormattedMessage
                  id="notification.cannot_get_order_details"
                  defaultMessage="Cannot get order details"
                />
              </Pane>
            )}
          </Pane>
          {detail && (
            <Pane className="order-detail mt20">
              <h3 className="block-title order-business-name">
                <>
                  {detail && detail.bookingItems && !isEmpty(detail.bookingItems[0]) && (
                    <>
                      {detail.bookingItems[0].staff ? (
                        <Link to={`/u/${formatRoute(
                          detail.user.name,
                          '-'
                        )}/staff/${detail.bookingItems[0].staff.id}/edit`} >
                          Staff: {detail.bookingItems[0].staff.fullname}
                        </Link>
                      ) : (
                        <>Staff: Store pick</>
                      )}
                    </>
                  )}
                </>
              </h3>
              <Pane className="products-grids">
                <Pane className="card-body">
                  <BookedServiceList
                    detail={detail}
                  />
                </Pane>
              </Pane>
            </Pane>
          )}
          <Pane className="order-detail">
            <h3 className="block-title card-body">
              <FormattedMessage
                id="booking_detail"
                defaultMessage="Booking detail"
              />
            </h3>
            {this.state.loading && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {detail && detail.id ? (
              <BookingSubDetail detail={detail} />
            ) : (
              <Pane className="no-detail" style={{ textAlign: 'center' }}>
                <FormattedMessage
                  id="notification.cannot_get_order_details"
                  defaultMessage="Cannot get order details"
                />
              </Pane>
            )}
          </Pane>
          {loading && (
            <Dimmer active inverted>
              <Loading />
            </Dimmer>
          )}
          {!loading && data && (
            <>
              <Pane className="change-status">
                {data.cancelStatus !== "REQUESTED" &&
                  (data.status === Config.ORDER_STATUS.NEW ||
                    data.status === Config.ORDER_STATUS.PENDING ||
                    !data.status ? (
                    <Pane className="group-button">
                      <Button
                        className="btn-confirm"
                        size="mini"
                        color="orange"
                        onClick={this.onOpenConfirmModal.bind(
                          this,
                          Config.ORDER_STATUS.CONFIRMED
                        )}
                        loading={this.state.loadingStatus}
                        disabled={this.state.loadingStatus}
                      >
                        <FormattedMessage id="confirm" defaultMessage="Confirm" />
                      </Button>
                    </Pane>
                  ) : data.status === Config.ORDER_STATUS.CONFIRMED ? (
                    <Pane className="group-button">
                      <Button
                        className="btn-confirm"
                        size="mini"
                        color="orange"
                        onClick={this.onOpenConfirmModal.bind(
                          this,
                          Config.ORDER_STATUS.DONE
                        )}
                        loading={this.state.loadingStatus}
                        disabled={this.state.loadingStatus}
                      >
                        <FormattedMessage
                          id="order.done"
                          defaultMessage="done"
                        />
                      </Button>
                    </Pane>
                  ) : null)}
                <Modal
                  open={this.state.open}
                  onClose={this.onCloseModal}
                  center
                  showCloseIcon={false}
                >
                  <Pane className="react-confirm-alert-body">
                    <h1>
                      <FormattedMessage
                        id="confirmation"
                        defaultMessage="Confirmation"
                      />
                    </h1>
                    <p>
                      {this.state.status
                        ? `${intl.formatMessage({
                          id:
                            "notification.are_you_sure_you_want_to_accept_this_request",
                        })}?`
                        : `${intl.formatMessage({
                          id:
                            "notification.are_you_sure_you_want_to_deny_this_request",
                        })}?`}
                    </p>
                    <TextArea
                      name="reason"
                      className="form-control"
                      value={this.state.reason}
                      placeholder="Your reason"
                      onChange={this.onChange}
                    />
                    {/* { errors.reason && <InlineMessage text={errors.reason} /> } */}
                    <Pane className="react-confirm-alert-button-group">
                      {this.state.status ? (
                        <button onClick={() => this.acceptCancelFromBuyer(data.id)}>
                          <FormattedMessage id="yes" defaultMessage="Yes" />
                        </button>
                      ) : (
                        <button onClick={() => this.denyCancelFromBuyer(data.id)}>
                          <FormattedMessage id="yes" defaultMessage="Yes" />
                        </button>
                      )}
                      <button onClick={this.onCloseModal}>
                        <FormattedMessage id="no" defaultMessage="No" />
                      </button>
                    </Pane>
                  </Pane>
                </Modal>
                <Modal
                  open={this.state.openConfirmModal}
                  onClose={this.onCloseConfirmModal}
                  center
                  showCloseIcon={false}
                >
                  <Pane className="react-confirm-alert-body">
                    <h1>
                      <FormattedMessage
                        id="confirmation"
                        defaultMessage="Confirmation"
                      />
                    </h1>
                    <p>
                      <FormattedMessage
                        id="notification.are_you_sure_you_want_to_change_status"
                        defaultMessage="Are you sure you want to change status"
                      />
                      ?
                    </p>
                    <Pane className="react-confirm-alert-button-group">
                      <button
                        onClick={() =>
                          this.changeItemStatus(this.state.orderStatus)
                        }
                      >
                        <FormattedMessage id="yes" defaultMessage="Yes" />
                      </button>
                      <button onClick={this.onCloseConfirmModal}>
                        <FormattedMessage id="no" defaultMessage="No" />
                      </button>
                    </Pane>
                  </Pane>
                </Modal>
              </Pane>
              <Pane className="change-status">
                {data.cancelStatus === 'REQUESTED' && (
                  <Pane>
                    <p>
                      <FormattedMessage
                        id="notification.this_item_is_in_cancellation_requesting"
                        defaultMessage="This item is in cancellation requesting"
                      />
                      .
                      <Icon
                        name="info circle"
                        onClick={this.onOpenReasonModal}
                        data-tip
                        data-for={`${data.id}`}
                      />
                    </p>
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="float"
                      id={`${data.id}`}
                    >
                      <span>
                        <FormattedMessage
                          id="notification.click_to_view_detail"
                          defaultMessage="Click to view detail"
                        />
                      </span>
                    </ReactTooltip>
                    <Pane className="group-button">
                      <Button
                        color="orange"
                        size="mini"
                        className="mr5"
                        onClick={this.onOpenModal.bind(this, true)}
                        loading={this.state.loadingAcceptCancel}
                        disabled={this.state.loadingAcceptCancel}
                      >
                        <FormattedMessage id="accept" defaultMessage="Accept" />
                      </Button>
                      <Button
                        color="grey"
                        size="mini"
                        onClick={this.onOpenModal.bind(this, false)}
                        loading={this.state.loadingAcceptCancel}
                        disabled={this.state.loadingAcceptCancel}
                      >
                        <FormattedMessage id="deny" defaultMessage="Deny" />
                      </Button>
                    </Pane>
                  </Pane>
                )}
                <Modal
                  open={this.state.openReason}
                  onClose={this.onCloseReasonModal}
                  center
                  showCloseIcon={false}
                >
                  <Pane className="react-confirm-alert-body">
                    <h1>
                      <FormattedMessage id="reason" defaultMessage="Reason" />
                    </h1>
                    <p>{data.requestCancelReason}</p>
                    <Pane className="react-confirm-alert-button-group">
                      <button onClick={this.onCloseReasonModal}>
                        <FormattedMessage id="close" defaultMessage="Close" />
                      </button>
                    </Pane>
                  </Pane>
                </Modal>
                <Modal
                  open={this.state.open}
                  onClose={this.onCloseModal}
                  center
                  showCloseIcon={false}
                >
                  <Pane className="react-confirm-alert-body">
                    <h1>
                      <FormattedMessage
                        id="confirmation"
                        defaultMessage="Confirmation"
                      />
                    </h1>
                    <p>
                      {this.state.status
                        ? `${intl.formatMessage({
                          id:
                            'notification.are_you_sure_you_want_to_accept_this_request',
                        })}?`
                        : `${intl.formatMessage({
                          id:
                            'notification.are_you_sure_you_want_to_deny_this_request',
                        })}?`}
                    </p>
                    <TextArea
                      name="reason"
                      className="form-control"
                      value={this.state.reason}
                      placeholder="Your reason"
                      onChange={this.onChange}
                    />
                    <Pane className="react-confirm-alert-button-group">
                      {this.state.status ? (
                        <button onClick={() => this.acceptCancelFromBuyer(data.id)}>
                          <FormattedMessage id="yes" defaultMessage="Yes" />
                        </button>
                      ) : (
                        <button onClick={() => this.denyCancelFromBuyer(data.id)}>
                          <FormattedMessage id="yes" defaultMessage="Yes" />
                        </button>
                      )}
                      <button onClick={this.onCloseModal}>
                        <FormattedMessage id="no" defaultMessage="No" />
                      </button>
                    </Pane>
                  </Pane>
                </Modal>
              </Pane>
            </>
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: state.sellingBookingDetail.data,
  userInfo: state.auth && state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getBookingDetailsOfSellers: bindActionCreators(
    getBookingDetailsOfSellers,
    dispatch
  ),
  acceptCancel: bindActionCreators(acceptCancelBooking, dispatch),
  denyCancel: bindActionCreators(denyCancelBooking, dispatch),
  changeStatus: bindActionCreators(changeStatus, dispatch),
  pushNotification: bindActionCreators(pushNotification, dispatch),
  getNotisByUser: bindActionCreators(getNotisByUser, dispatch),
});

export default injectIntl(connect(
  mapStateToProps,
  bindDispatchToProps
)(CustomerBookingDetail));
