import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { MasterLayout } from 'components/layout'
import { ResetPasswordForm } from 'components/scenes/reset-password'
import { resetPassword } from 'actions/user'
import { Pane } from 'shared/components/layouts'

class ResetPasswordPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    resetPassword: PropTypes.func.isRequired
  }

  render() {
    return (
      <MasterLayout>
        <Pane className='container signin-container'>
          <Pane className='row'>
            <Pane className='col-sm-5 login-box'>
              <Pane className='card card-default'>
                <Pane className='panel-intro text-center'>
                  <h2 className="logo-title">
                    <span className="logo-icon"><b><FormattedMessage id='reset_password' defaultMessage='RESET YOUR PASSWORD' /></b></span>
                  </h2>
                </Pane>
                <Pane className='card-body'>
                  <ResetPasswordForm resetPassword={this.props.resetPassword} />
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    )
  }
}

const bindDispatchToProps = dispatch => ({
  resetPassword: bindActionCreators(resetPassword, dispatch)
})

export default connect(null, bindDispatchToProps)(ResetPasswordPage);
