import * as UserContactBrowseV1 from './userContact.browse.v1.action'
import * as UserContactAddV1 from './userContact.add.v1.action'
import * as UserContactRemoveV1 from './userContact.remove.v1.action'

export default {
  // get all userContact
  browse: {
    callAPI: params => UserContactBrowseV1.exec(params),
    parseResponse: response => UserContactBrowseV1.parseResponse(response)
  },
  // add userContact
  add: {
    callAPI: id => UserContactAddV1.exec(id),
    parseResponse: response => UserContactAddV1.parseResponse(response)
  },
  // remove userContact
  remove: {
    callAPI: id => UserContactRemoveV1.exec(id),
    parseResponse: response => UserContactRemoveV1.parseResponse(response)
  },
}
