import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 100;
  const business_id = params.business_id || '';
  return {
    method: 'GET',
    url: `/businesses/${business_id}/tables?${params.method ? `method=${params.method}&` : ``}page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const items = [];

  if (response.data) {
    response.data.forEach((item) => {
      const data = formatJson(response, item.type, item.id);
      items.push(data);
    });
  }
  return items;
};

export { exec, parseResponse };
