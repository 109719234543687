import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Checkbox, Form, Dimmer } from "semantic-ui-react";
import { Pane, Loading } from "shared/components/layouts";
import { PriceItem, PromotionLabel, StockLabel } from "shared/components";
import Config from "constants/Config";
import queryString from "query-string";
import Modal from "react-responsive-modal";

class ListItem extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      tableId: queryString.parse(search).tableId || null,
      added: false,
      adding: false,
      play: 0,
      open: false,
      value: null,
    };
  }

  isProductOptions = () => {
    if (this.props.item.options && this.props.item.options.length) return true;
    return false;
  };

  addCart = () => {
    const { item, posCart } = this.props;
    if (posCart.length > 0 && posCart.some(cart => cart.productId == item.id)) {
      let carts = posCart.filter(cart => cart.productId == item.id);
      if (carts.length > 0) {
        this.props.updatePosCart(true, carts[0].productId, carts[0].quantity + 1);
      }
      return 0;
    }

    if (this.isProductOptions()) {
      this.props.cartModal.setCartView({
        ...this.props.item,
        tableId: undefined,
      });
      this.props.cartModal.openModal();
      return;
    }

    let product = {
      categoryName: item.categoryName,
      productId: item.id,
      primaryImage: item.primaryImage,
      price: item.originalPrice,
      promotionPrice: item.promotionPrice,
      quantity: 1,
      optionInfo: null,
      options: item.options,
      originalPrice: item.originalPrice,
      name: item.name,
      userId: item.userId
    }
    this.props.addPosCart(product);
  }


  renderInfo = () => {
    const { item } = this.props;
    return (
      <Pane className="adds-info">
        <PriceItem
          promotion={item && item.promotion}
          originalPrice={item && item.originalPrice}
        />
      </Pane>
    );
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  onCloseReasonModal = () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (e, { value }) => this.setState({ value });

  render() {
    const { name, image, description, item, posCart } = this.props;
    const extImage = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];
    const ext = image != null ? image.substr(image.lastIndexOf(".") + 1) : "";
    return this.props.modeView === Config.MODE_VIEW_MENU.MODE_LIST ? (
      <Pane className="adds-item adds-list-view">
        <Pane className="adds-content">
          <Pane className="col-lg-7 col-md-6 col-sm-12 p-0">
              <h5 title={name}>
                <b>{name}</b>
                <i>
                  <span
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></span>
                </i>
              </h5>
          </Pane>
          <Pane className="col-lg-2 col-md-3 col-sm-12 p-0">
            <div className="group-price">
              <PriceItem
                promotion={item && item.promotion}
                originalPrice={item && item.originalPrice}
              />
              <button
                className="btn-order-sm d-md-none d-sm-flex d-lg-none"
                onClick={this.addCart}
              >
                <span className="icon-plus" />
              </button>
              <div style={{ clear: "both" }}></div>
            </div>
          </Pane>
          <Pane className="col-lg-3 col-md-3 pr-md-0">
            <button
              className="btn-order d-none d-md-flex d-sm-none d-lg-flex float-right"
              onClick={this.addCart}
            >
              <span className="icon-plus" />
            </button>
          </Pane>
        </Pane>
      </Pane>
    ) :  (
      <Pane className="col-lg-3 col-md-6 col-sm-6 col-12 pl-0 pr-0 pb-0">
        <Pane className="adds-item">
          {extImage.indexOf(ext) >= 0 ? (
            <Pane
              className="adds-images adds-images-stock"
              style={{
                backgroundImage: image
                  ? `url(${image})`
                  : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
              }}
            >
              <PromotionLabel
                promotion={this.props.item && this.props.item.promotion}
                originalPrice={
                  this.props.item && this.props.item.originalPrice
                }
              />
            </Pane>
          ) : (
            <Pane className="adds-images adds-images-stock">
              {image ? (
                <Pane>
                  {ext !== "" ? <i className="fa fa-video" /> : ""}
                  <video
                    className="carbeyvideo-list"
                    playsInline={1}
                    onMouseOver={this.playVideo}
                    onMouseOut={this.stopVideo}
                    muted={!this.state.play}
                  >
                    <source src={image} id="video_here" />
                    Your browser does not support HTML5 video.111
                  </video>
                  <PromotionLabel
                    promotion={this.props.item && this.props.item.promotion}
                    originalPrice={
                      this.props.item && this.props.item.originalPrice
                    }
                  />
                </Pane>
              ) : (
                <Pane
                  className="adds-images"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                  }}
                ></Pane>
              )}
            </Pane>
          )}
          <Pane className="adds-content">
            <h5 className="adds-norm" title={name}>
              <b>{name}</b>
              <span dangerouslySetInnerHTML={{ __html: description }}></span>
            </h5>
            <Pane className="adds-info-item">
              {this.renderInfo()}
              <button className="btn-order" onClick={this.addCart}>
                <span className="icon icon-plus" />
              </button>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
ListItem.propTypes = {
  auth: PropTypes.object,
  item: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  rating: PropTypes.number,
  intl: intlShape.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
};
ListItem.defaultProps = {
  type: "",
};

export default withRouter(injectIntl(ListItem));
