import { get } from "lodash";
import Config from "constants/Config";
import { formatJson } from "shared/helpers";

const exec = (params) => {
  return {
    method: "GET",
    url: `/orders/${params.id}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const orderDetail = formatJson(
    response,
    response.data.type,
    response.data.id
  );
  const cartItems = [];

  orderDetail.items.forEach((item) => {
    const product = item.product;
    const options = [];
    (product.options || []).forEach((option) => {
      const group = option.optionGroup || {};
      const index = options.findIndex((option) => option.id === group.id);
      if (index === -1) {
        options.push({
          id: group.id,
          name: group.name,
          type: group.type,
          autoAddOptions: false,
          dpOrdSeq: group.dpOrdSeq,
          delt: "N",
          childrens: [
            {
              id: option.id,
              name: option.name,
              dpOrdSeq: option.dpOrdSeq,
              price: Number(option.price),
              delt: "N",
              autoAddOptions: false,
              isCheck: !!option.isCheck,
            },
          ],
        });
      } else {
        options[index].childrens.push({
          id: option.id,
          name: option.name,
          dpOrdSeq: option.dpOrdSeq,
          price: Number(option.price),
          delt: "N",
          autoAddOptions: false,
          isCheck: !!option.isCheck,
        });
      }

      return options;
    });

    options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
    options.forEach((option) =>
      option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq)
    );
    cartItems.push({
      id: item.id,
      productId: item.productId,
      name: item.name,
      price: item.price,
      primaryImage: item.primaryImage && item.primaryImage.url || product.primaryImage && product.primaryImage.url,
      promotionPrice: item.promotionPrice,
      quantity: item.quantity,
      refundAmount: item.refundAmount,
      cancelStatus: item.cancelStatus,
      requestCancelReason: item.requestCancelReason,
      total: item.total,
      status: item.status,
      options: options,
      optionInfo: item.optionInfo,
      deliveryMethod: item.deliveryMethod,
    });
  });

  let paymentMethod = "";

  if (orderDetail.payment && orderDetail.payment.transactionType) {
    paymentMethod = orderDetail.payment.transactionType;
  } else {
    paymentMethod =
      orderDetail.paymentMethod !== ""
        ? orderDetail.paymentMethod
        : "Credit card";
  }

  const data = {
    id: orderDetail.id,
    table: orderDetail.table,
    status: orderDetail.status,
    orderDate: orderDetail.createdAt,
    paymentMethod,
    shippingAddress: orderDetail.shippingAddress,
    total: Number(orderDetail.total),
    tax: Number(orderDetail.tax),
    guestName: orderDetail.guestName,
    guestPhone: orderDetail.guestPhone,
    guestEmail: orderDetail.guestEmail,
    guestNote: orderDetail.guestNote,
    mode: orderDetail.mode,
    note: orderDetail.note,
    cartItems,
    deliveryInfo: orderDetail.meta.deliveryInfo,
    updateRead: !!orderDetail.updateRead,
    firstScanDiscount: orderDetail.firstScanDiscount,
    maxDiscount: orderDetail.maxDiscount,
    itemsTotal: orderDetail.itemsTotal
  };

  if (orderDetail.user) {
    data.user = {
      id: orderDetail.user.id,
      name: orderDetail.user.fullname,
      email: orderDetail.user.email,
      phone: orderDetail.user.phone,
      address: orderDetail.user.address,
      postcode: orderDetail.user.postcode,
      lat: get(orderDetail, "user.lat"),
      lng: get(orderDetail, "user.lng"),
    };
  }

  return data;
};

export { exec, parseResponse };
