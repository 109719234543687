import Menu from './Menu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import classnames from 'classnames';
import { RenderRoutes } from 'routes';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BannerIntro from './banner/BannerIntro';
import { Pane } from 'shared/components/layouts';
import BusinessInfo from './overview/BusinessInfo';
import LeftNoti from "./floating-left/LeftNoti";
import { isEmpty } from "lodash";
import UserInfo from "../social-users/overview/UserInfo";
import Config from "constants/Config";
import {
  updateInfo,
} from "actions/user";

class User extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    business: PropTypes.object,
    logout: PropTypes.func,
    updateUserInfo: PropTypes.func,
  };

  state = {
    toggleMenu: true,
    isBgVideo: false,
    extVideo: Config.extVideo,
  }

  onChangeToggleMenu = () => {
    this.setState((prevState) => ({
      toggleMenu: !prevState.toggleMenu,
    }));
  };
  
  render() {
    const { userInfo } = this.props;
    let isBgVideo = userInfo.backgroundImage && Config.extVideo.includes(userInfo.backgroundImage.substr(userInfo.backgroundImage.lastIndexOf(".") + 1));
    return (
      <>
        <BannerIntro updateUserInfo={this.props.updateUserInfo} userInfo={userInfo} />
        <Pane className={"stores-container" + (isBgVideo ? " stores-front" : "")}>
          <Pane
            className={classnames(
              'heading-container'
            )}
          >
            {userInfo.business.is_active == 1 && (
              <BusinessInfo
                businessInfo={userInfo.business}
                userInfo={userInfo}
                onChangeToggleMenu={this.onChangeToggleMenu}
                updateUserInfo={this.props.updateUserInfo}
              />
            )} {userInfo.business.is_active == 0 && (
              <UserInfo
                storeInfo={userInfo}
                updateUserInfo={this.props.updateUserInfo}
                onChangeToggleMenu={this.onChangeToggleMenu}
              />
            )}
          </Pane>
          
          <Pane className={classnames('product-container mt-5')}>
            <Pane className="container">
              <Pane className="row">
                <Pane className="col-lg-3 col-md-12">
                  <Menu
                    userInfo={this.props.userInfo}
                    business={this.props.business}
                    logout={this.props.logout}
                    toggleMenu={this.state.toggleMenu}
                  />
                </Pane>
                <Pane className="col-lg-9 col-md-12">
                  <RenderRoutes routes={this.props.routes} />
                </Pane>
              </Pane>
            </Pane>
          </Pane>
          {userInfo.business.is_active == 0 &&(
            <LeftNoti isCompleted={!isEmpty(userInfo.business.name) ? true : false } />
          )}
        </Pane>
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  business: state.auth.user.business,
});

const bindDispatchToProps = (dispatch) => ({
  updateUserInfo: bindActionCreators(updateInfo, dispatch),
});

export default withRouter(connect(bindStateToProps, bindDispatchToProps)(User));
