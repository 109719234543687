import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getAllServices } from 'actions/services';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BestMatchedItem from './BestMatchedItem';
import { formatJson, formatRoute } from 'shared/helpers';
import { LoadingAdds } from 'components/common/loading';

class BestMatched extends Component {
  static propTypes = {
    auth: PropTypes.object,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      fetching: false,
      fetched: false,
      data: [],
    };
  }

  componentDidMount() {
    const params = {
      category: this.props.categoryId,
      size: 6,
    };
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props
      .getAllServices(params)
      .then((response) => {
        const payload = response.data.data.map((item) =>
          formatJson(response.data, item.type, item.id)
        );
        this.setState({
          fetched: true,
          fetching: false,
          data: payload.map((item) => {
            let promotion = {};
            if (item.promotion) {
              promotion = {
                id: item.promotion.id,
                percent: Number(item.promotion.percent),
                startDate: item.promotion.startDate,
                endDate: item.promotion.endDate,
                primaryImage:
                  item.promotion.primaryImage &&
                  item.promotion.primaryImage.url,
              };
            }
            return {
              category: item.category,
              id: item.id,
              name: item.name,
              description: item.description,
              slug: `${formatRoute(item.name, '-')}-${item.id}`,
              primaryImage: item.primaryImage && item.primaryImage.url,
              rating:
                item.statistic && item.statistic.rating
                  ? Number(item.statistic.rating)
                  : 0,
              userId: item.user && item.user.id,
              originalPrice: Number(item.price),
              promotionPrice: Number(item.promotionPrice),
              type: 'services',
              promotion,
            };
          }),
        });
      })
      .catch(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  }
  render() {
    const { fetched, fetching, data } = this.state;
    return (
      <>
        <Pane className="heading-container mb-3 text-lg-left text-md-left text-center">
          <h3 className="heading-title">
            <FormattedMessage id="best_matched" defaultMessage="Best Matched" />
          </h3>
          <p className="heading-norm">
            <FormattedMessage
              id="people_bought_the_sam_your_alse_buying_these"
              defaultMessage="People bought the same yours also buying these"
            />
          </p>
        </Pane>
        <Pane className="adds-block">
          {!fetching && fetched ? (
            <Pane className="row">
              {data.length &&
                data.map((item, index) => {
                  return (
                    <BestMatchedItem
                      key={item.id}
                      item={item}
                      id={item.id}
                      name={item.name}
                      image={item.primaryImage}
                      price={item.originalPrice}
                      rating={item.rating}
                      onAddItemToCart={this.props.onAddItemToCart}
                      index={index}
                      auth={this.props.auth}
                    />
                  );
                })}
            </Pane>
          ) : (
            <LoadingAdds />
          )}
        </Pane>
      </>
    );
  }
}

BestMatched.propTypes = {
  getAllServices: PropTypes.func.isRequired,
  services: PropTypes.object,
};

const bindStateToProps = (state) => ({
  services: state.services,
});

const bindDispatchToProps = (dispatch) => ({
  getAllServices: bindActionCreators(getAllServices, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(BestMatched));
