import * as UserRegisterV1 from "./user.register.v1.action";
import * as UserQuickRegisterV1 from "./user.quick-register.v1.action";
import * as UserQRCodeV1 from "./user.qrcode.v1.action";
import * as UserReadV1 from "./user.read.v1.action";
import * as UserEditV1 from "./user.edit.v1.action";
import * as UserAvatarEditV1 from "./user.avatar.v1.action";
import * as UserChangePasswordV1 from "./user.change-password.v1.action";
import * as UserForgotPasswordV1 from "./user.forgot-password.v1.action";
import * as UserResettPasswordV1 from "./user.reset-password.v1.action";
import * as UserReferenceV1 from "./user.reference.v1.action";

// User's Products
import * as UserProductBrowseV1 from "./products/user-product.browse.v1.action";
import * as UserProductPicksBrowseV1 from "./products/user-product-picks.browse.v1.action";
import * as UserFoodCategoriesBrowseV1 from "./products/user-category.browse.v1.action";
import * as UserOtherCategoriesBrowseV1 from "./products/user-category.browse.v1.action";
import * as UserProductEditV1 from "./products/user-product.edit.v1.action";
import * as UserProductAddV1 from "./products/user-product.add.v1.action";
import * as UserProductPickAddV1 from "./products/user-product-pick.add.v1.action";
import * as UserProductPickRemoveV1 from "./products/user-product-picks.remove.v1.action";
import * as UserCategoryAddV1 from "./categories/user-category.add.v1.action";
import * as UserProductRemoveV1 from "./products/user-product.remove.v1.action";
// User's Services
import * as UserCategoriesServiceBrowseV1 from "./services/user-category.browse.v1.action";
import * as UserServiceBrowseV1 from "./services/user-service.browse.v1.action";
import * as UserServiceEditV1 from "./services/user-service.edit.v1.action";
import * as UserServiceAddV1 from "./services/user-service.add.v1.action";
import * as UserServiceRemoveV1 from "./services/user-service.remove.v1.action";
// User's Services
import * as UserCategoriesCollectionBrowseV1 from "./collections/user-category.browse.v1.action";
import * as UserCollectionBrowseV1 from "./collections/user-collection.browse.v1.action";
import * as UserCollectionEditV1 from "./collections/user-collection.edit.v1.action";
import * as UserCollectionAddV1 from "./collections/user-collection.add.v1.action";
import * as UserCollectionRemoveV1 from "./collections/user-collection.remove.v1.action";
// User's Promotions
import * as UserPromotionReadV1 from "./promotions/user-promotion.read.v1.action";
import * as UserPromotionEditV1 from "./promotions/user-promotion.edit.v1.action";
import * as UserPromotionAddV1 from "./promotions/user-promotion.add.v1.action";
import * as UserPromotionRemoveV1 from "./promotions/user-promotion.remove.v1.action";
import * as UserPromotionBoostV1 from "./promotions/user-promotion.boost.v1.action";
// User's Product Promotions
import * as UserProductPromotionBrowseV1 from "./promotions/user-product-promotion.browse.v1.action";
// User's Service Promotions
import * as UserServicePromotionBrowseV1 from "./promotions/user-service-promotion.browse.v1.action";
// User's Slides
import * as UserSlideBrowseV1 from "./slides/slide.browse.v1.action";
import * as UserProductPicksReadV1 from "./products/user-product-picks.read.v1.action";
import * as UserProductEditPicksReadV1 from "./products/user-product-picks.edit.v1.action";
import * as UserFoodCategoryBrowseV1 from "./products/user-food.browse.v1.action";
import * as UserProductFoodBrowserV1 from "./products/user-product-food.browse.v1.action";
import * as UserProductFoodOrderBrowserV1 from "./products/user-product-food-order.browse.v1.action";
// User's Videos
import * as UserVideoBrowseV1 from "./videos/user-video.browse.v1.action";
import * as UserVideoBrowseAllV1 from "./videos/user-video.browseAll.v1.action";
import * as UserVideoEditV1 from "./videos/user-video.edit.v1.action";
import * as UserVideoAddV1 from "./videos/user-video.add.v1.action";
import * as UserVideoRemoveV1 from "./videos/user-video.remove.v1.action";
import * as UserVideoReadV1 from "./videos/user-video.read.v1.action";
import * as UserVideoDataBrowseV1 from "./videos/user-video-data.browse.v1.action";
import * as UserViewVideoV1 from "./videos/user-view-video.v1.action";
import * as UserLikeVideoV1 from "./videos/user-like-video.v1.action";
import * as DigitalBrowserV1 from "./digitals/user-digital.browse.v1.action";
import * as DigitalRemoveV1 from "./digitals/user-digital.remove.v1.action";
import * as DigitalAddV1 from "./digitals/user-digital.add.v1.action";
import * as DigitalReadV1 from "./digitals/user-digital-read.v1";
import * as DigitalEditV1 from "./digitals/user-digital.edit.v1.action";
import * as ScreenBrowserV1 from "./screens/user-screen.browse.v1.action";
import * as ScreenCountV1 from "./screens/user-screen.count.v1.action";
import * as ScreenRemoveV1 from "./screens/user-screen.remove.v1.action";
import * as ScreenAddV1 from "./screens/user-screen.add.v1.action";
import * as ScreenReadV1 from "./screens/user-screen-read.v1";
import * as ScreenUpdateOnlineV1 from "./screens/user-screen.online.v1.action";
import * as ScreenEditV1 from "./screens/user-screen.edit.v1.action";
import * as DigitalShareV1 from "./screens/user-digital-share.v1.action";
import * as DigitalShareBrowserV1 from "./screens/user-digital-share.browse.v1.action";

//screen activity
import * as ScreenActivityAddV1 from "./screenActivity/user-screen-activity.add.v1.action";

// TABLES
import * as TablesBrowserV1 from "./tables/user-tables.browse.v1.action";
import * as TablesRemoveV1 from "./tables/user-tables.remove.v1.action";
import * as TablesAddV1 from "./tables/user-tables.add.v1.action";
import * as TablesEditV1 from "./tables/user-tables.edit.v1.action";
import * as TablesReadV1 from "./tables/user-tables-read.v1.action";
// TABLES

// PROJECTS
import * as ProjectBrowserV1 from './project/user-project.browse.v1.action';
import * as ProjectAddV1 from './project/user-project.add.v1.action';
import * as ProjectEditV1 from './project/user-project.edit.v1.action';
import * as ProjectRemoveV1 from './project/user-project.remove.v1.action';
import * as ProjectReadV1 from './project/user-project.read.v1.action';
// TASK
import * as TaskBrowserV1 from './task/user-task.browse.v1.action';
import * as TaskAddV1 from './task/user-task.add.v1.action';
import * as TaskEditV1 from './task/user-task.edit.v1.action';
import * as TaskRemoveV1 from './task/user-task.remove.v1.action';
import * as TaskReadV1 from './task/user-task.read.v1.action';
// TASK MEMEBR
import * as TaskMemberBrowserV1 from './taskMember/user-task-member.browse.v1.action';
import * as TaskMemberAddV1 from './taskMember/user-task-member.add.v1.action';
import * as TaskMemberEditV1 from './taskMember/user-task-member.edit.v1.action';
import * as TaskMemberRemoveV1 from './taskMember/user-task-member.remove.v1.action';
import * as TaskMemberReadV1 from './taskMember/user-task-member.read.v1.action';
// SERVICE MEMEBR
import * as ServiceMemberBrowserV1 from './serviceMember/user-service-member.browse.v1.action';
import * as ServiceMemberAddV1 from './serviceMember/user-service-member.add.v1.action';
import * as ServiceMemberEditV1 from './serviceMember/user-service-member.edit.v1.action';
import * as ServiceMemberRemoveV1 from './serviceMember/user-service-member.remove.v1.action';
import * as ServiceMemberReadV1 from './serviceMember/user-service-member.read.v1.action';
import * as ServiceMemberBookingAvailableV1 from './serviceMember/user-service-member.booking-available.v1.action';
// STAFF
import * as StaffBrowserV1 from './staff/user-staff.browse.v1.action';
import * as StaffAddV1 from './staff/user-staff.add.v1.action';
import * as StaffEditV1 from './staff/user-staff.edit.v1.action';
import * as StaffRemoveV1 from './staff/user-staff.remove.v1.action';
import * as StaffReadV1 from './staff/user-staff.read.v1.action';
import * as StaffAssignV1 from './staff/user-staff.browse-assign.v1.action';

import * as ScanQRPromotionDetailV1 from './qrPromotion/qr-promotion.detail.v1.action';
import * as ScanQRPromotionBrowserV1 from './qrPromotion/qr-promotion.browse.v1.action';
import * as ScanQRPromotionAddV1 from './qrPromotion/qr-promotion.add.v1.action';
import * as ScanQRPromotionEditV1 from './qrPromotion/qr-promotion.edit.v1.action';
import * as ScanQRPromotionRemoveV1 from './qrPromotion/qr-promotion.remove.v1.action';
import * as ScanQRPromotionReadV1 from './qrPromotion/qr-promotion.read.v1.action';

export default {
  //screen activity
  screenActivityAdd : {
    callAPI: (params) => ScreenActivityAddV1.exec(params),
    parseResponse: (response) => ScreenActivityAddV1.parseResponse(response),
  },
  // Project
  projectBrowser : {
    callAPI: (params) => ProjectBrowserV1.exec(params),
    parseResponse: (response) => ProjectBrowserV1.parseResponse(response),
  },
  projectRead : {
    callAPI: (params) => ProjectReadV1.exec(params),
    parseResponse: (response) => ProjectReadV1.parseResponse(response),
  },
  projectAdd : {
    callAPI: (params) => ProjectAddV1.exec(params),
    parseResponse: (response) => ProjectAddV1.parseResponse(response),
  },
  projectRemove : {
    callAPI: (params) => ProjectRemoveV1.exec(params),
    parseResponse: (response) => ProjectRemoveV1.parseResponse(response),
  },
  projectEdit : {
    callAPI: (params) => ProjectEditV1.exec(params),
    parseResponse: (response) => ProjectEditV1.parseResponse(response),
  },
  // Task
  taskBrowser : {
    callAPI: (params) => TaskBrowserV1.exec(params),
    parseResponse: (response) => TaskBrowserV1.parseResponse(response),
  },
  taskRead : {
    callAPI: (params) => TaskReadV1.exec(params),
    parseResponse: (response) => TaskReadV1.parseResponse(response),
  },
  taskAdd : {
    callAPI: (params) => TaskAddV1.exec(params),
    parseResponse: (response) => TaskAddV1.parseResponse(response),
  },
  taskRemove : {
    callAPI: (params) => TaskRemoveV1.exec(params),
    parseResponse: (response) => TaskRemoveV1.parseResponse(response),
  },
  taskEdit : {
    callAPI: (params) => TaskEditV1.exec(params),
    parseResponse: (response) => TaskEditV1.parseResponse(response),
  },
  taskMemberBrowser : {
    callAPI: (params) => TaskMemberBrowserV1.exec(params),
    parseResponse: (response) => TaskMemberBrowserV1.parseResponse(response),
  },
  taskMemberRead : {
    callAPI: (params) => TaskMemberReadV1.exec(params),
    parseResponse: (response) => TaskMemberReadV1.parseResponse(response),
  },
  taskMemberAdd : {
    callAPI: (params) => TaskMemberAddV1.exec(params),
    parseResponse: (response) => TaskMemberAddV1.parseResponse(response),
  },
  taskMemberRemove : {
    callAPI: (params) => TaskMemberRemoveV1.exec(params),
    parseResponse: (response) => TaskMemberRemoveV1.parseResponse(response),
  },
  taskMemberEdit : {
    callAPI: (params) => TaskMemberEditV1.exec(params),
    parseResponse: (response) => TaskMemberEditV1.parseResponse(response),
  },
  // Service Member
  serviceMemberBrowser : {
    callAPI: (params) => ServiceMemberBrowserV1.exec(params),
    parseResponse: (response) => ServiceMemberBrowserV1.parseResponse(response),
  },
  serviceMemberRead : {
    callAPI: (params) => ServiceMemberReadV1.exec(params),
    parseResponse: (response) => ServiceMemberReadV1.parseResponse(response),
  },
  serviceMemberBookingAvailable: {
    callAPI: (params) => ServiceMemberBookingAvailableV1.exec(params),
    parseResponse: (response) => ServiceMemberBookingAvailableV1.parseResponse(response),
  },
  serviceMemberAdd : {
    callAPI: (params) => ServiceMemberAddV1.exec(params),
    parseResponse: (response) => ServiceMemberAddV1.parseResponse(response),
  },
  serviceMemberRemove : {
    callAPI: (params) => ServiceMemberRemoveV1.exec(params),
    parseResponse: (response) => ServiceMemberRemoveV1.parseResponse(response),
  },
  serviceMemberEdit : {
    callAPI: (params) => ServiceMemberEditV1.exec(params),
    parseResponse: (response) => ServiceMemberEditV1.parseResponse(response),
  },
  // Staff
  staffAssign : {
    callAPI: (params) => StaffAssignV1.exec(params),
    parseResponse: (response) => StaffAssignV1.parseResponse(response),
  },
  staffBrowser : {
    callAPI: (params) => StaffBrowserV1.exec(params),
    parseResponse: (response) => StaffBrowserV1.parseResponse(response),
  },
  staffRead : {
    callAPI: (params) => StaffReadV1.exec(params),
    parseResponse: (response) => StaffReadV1.parseResponse(response),
  },
  staffAdd : {
    callAPI: (params) => StaffAddV1.exec(params),
    parseResponse: (response) => StaffAddV1.parseResponse(response),
  },
  staffRemove : {
    callAPI: (params) => StaffRemoveV1.exec(params),
    parseResponse: (response) => StaffRemoveV1.parseResponse(response),
  },
  staffEdit : {
    callAPI: (params) => StaffEditV1.exec(params),
    parseResponse: (response) => StaffEditV1.parseResponse(response),
  },
  // scanQRPromotion

  scanQrPromotionDetail : {
    callAPI: (params) => ScanQRPromotionDetailV1.exec(params),
    parseResponse: (response) => ScanQRPromotionDetailV1.parseResponse(response),
  },
  scanQRPromotionBrowser : {
    callAPI: (params) => ScanQRPromotionBrowserV1.exec(params),
    parseResponse: (response) => ScanQRPromotionBrowserV1.parseResponse(response),
  },
  scanQRPromotionRead : {
    callAPI: (params) => ScanQRPromotionReadV1.exec(params),
    parseResponse: (response) => ScanQRPromotionReadV1.parseResponse(response),
  },
  scanQRPromotionAdd : {
    callAPI: (params) => ScanQRPromotionAddV1.exec(params),
    parseResponse: (response) => ScanQRPromotionAddV1.parseResponse(response),
  },
  scanQRPromotionRemove : {
    callAPI: (params) => ScanQRPromotionRemoveV1.exec(params),
    parseResponse: (response) => ScanQRPromotionRemoveV1.parseResponse(response),
  },
  scanQRPromotionEdit : {
    callAPI: (params) => ScanQRPromotionEditV1.exec(params),
    parseResponse: (response) => ScanQRPromotionEditV1.parseResponse(response),
  },

  // Register
  register: {
    callAPI: (params) => UserRegisterV1.exec(params),
    parseResponse: (response) => UserRegisterV1.parseResponse(response),
  },
  // quick Register
  quickRegister: {
    callAPI: (params) => UserQuickRegisterV1.exec(params),
    parseResponse: (response) => UserQuickRegisterV1.parseResponse(response),
  },
  // Request qrcode
  qrcode: {
    callAPI: (params) => UserQRCodeV1.exec(params),
    parseResponse: (response) => UserQRCodeV1.parseResponse(response),
  },
  // Get user information
  read: {
    callAPI: (params) => UserReadV1.exec(params),
    parseResponse: (response) => UserReadV1.parseResponse(response),
  },
  readPicks: {
    callAPI: (params) => UserProductPicksReadV1.exec(params),
    parseResponse: (response) => UserProductPicksReadV1.parseResponse(response),
  },
  // Get user information
  edit: {
    callAPI: (params) => UserEditV1.exec(params),
    parseResponse: (response) => UserEditV1.parseResponse(response),
  },
  editPicks: {
    callAPI: (params) => UserProductEditPicksReadV1.exec(params),
    parseResponse: (response) =>
      UserProductEditPicksReadV1.parseResponse(response),
  },
  referenceCode: {
    callAPI: (params) => UserReferenceV1.exec(params),
    parseResponse: (response) => UserReferenceV1.parseResponse(response),
  },
  // Update user avatar
  avatar: {
    callAPI: (params) => UserAvatarEditV1.exec(params),
    parseResponse: (response) => UserAvatarEditV1.parseResponse(response),
  },
  // Change password
  changePassword: {
    callAPI: (params) => UserChangePasswordV1.exec(params),
    parseResponse: (response) => UserChangePasswordV1.parseResponse(response),
  },
  // Forgot password
  forgotPassword: {
    callAPI: (params) => UserForgotPasswordV1.exec(params),
    parseResponse: (response) => UserForgotPasswordV1.parseResponse(response),
  },
  // Reset password
  resetPassword: {
    callAPI: (params) => UserResettPasswordV1.exec(params),
    parseResponse: (response) => UserResettPasswordV1.parseResponse(response),
  },
  // Get user's slideshow
  slideBrowse: {
    callAPI: (params) => UserSlideBrowseV1.exec(params),
    parseResponse: (response) => UserSlideBrowseV1.parseResponse(response),
  },
  // Get user's digital
  digitalsBrowse: {
    callAPI: (params) => DigitalBrowserV1.exec(params),
    parseResponse: (response) => DigitalBrowserV1.parseResponse(response),
  },
  digitalRemove: {
    callAPI: (params) => DigitalRemoveV1.exec(params),
    parseResponse: (response) => DigitalRemoveV1.parseResponse(response),
  },
  digitalAdd: {
    callAPI: (params) => DigitalAddV1.exec(params),
    parseResponse: (response) => DigitalAddV1.parseResponse(response),
  },
  digitalRead: {
    callAPI: (params) => DigitalReadV1.exec(params),
    parseResponse: (response) => DigitalReadV1.parseResponse(response),
  },
  DigitalEdit: {
    callAPI: (params) => DigitalEditV1.exec(params),
    parseResponse: (response) => DigitalEditV1.parseResponse(response),
  },
  // Get user's screen
  screensBrowse: {
    callAPI: (params) => ScreenBrowserV1.exec(params),
    parseResponse: (response) => ScreenBrowserV1.parseResponse(response),
  },
  screenCount: {
    callAPI: (params) => ScreenCountV1.exec(params),
    parseResponse: (response) => ScreenCountV1.parseResponse(response),
  },
  screenRemove: {
    callAPI: (params) => ScreenRemoveV1.exec(params),
    parseResponse: (response) => ScreenRemoveV1.parseResponse(response),
  },
  screenAdd: {
    callAPI: (params) => ScreenAddV1.exec(params),
    parseResponse: (response) => ScreenAddV1.parseResponse(response),
  },
  screenRead: {
    callAPI: (params) => ScreenReadV1.exec(params),
    parseResponse: (response) => ScreenReadV1.parseResponse(response),
  },
  screenEdit: {
    callAPI: (params) => ScreenEditV1.exec(params),
    parseResponse: (response) => ScreenEditV1.parseResponse(response),
  },
  screenUpdateOnline: {
    callAPI: (params) => ScreenUpdateOnlineV1.exec(params),
    parseResponse: (response) => ScreenUpdateOnlineV1.parseResponse(response),
  },
  digitalShare: {
    callAPI: (params) => DigitalShareV1.exec(params),
    parseResponse: (response) => DigitalShareV1.parseResponse(response),
  },
  digitalsShareBrowse: {
    callAPI: (params) => DigitalShareBrowserV1.exec(params),
    parseResponse: (response) => DigitalShareBrowserV1.parseResponse(response),
  },
  // Get user's product
  productBrowse: {
    callAPI: (params) => UserProductBrowseV1.exec(params),
    parseResponse: (response) => UserProductBrowseV1.parseResponse(response),
  },
  // Get user's product picks
  productPicksBrowse: {
    callAPI: (params) => UserProductPicksBrowseV1.exec(params),
    parseResponse: (response) =>
      UserProductPicksBrowseV1.parseResponse(response),
  },
  foodCategories: {
    callAPI: (params) => UserFoodCategoriesBrowseV1.exec(params),
    parseResponse: (response) =>
      UserFoodCategoriesBrowseV1.parseResponse(response),
  },
  otherCategories: {
    callAPI: (params) => UserOtherCategoriesBrowseV1.exec(params),
    parseResponse: (response) =>
      UserOtherCategoriesBrowseV1.parseResponse(response),
  },
  // Edit user's product
  productEdit: {
    callAPI: (params) => UserProductEditV1.exec(params),
    parseResponse: (response) => UserProductEditV1.parseResponse(response),
  },
  // Create user's product
  productAdd: {
    callAPI: (params) => UserProductAddV1.exec(params),
    parseResponse: (response) => UserProductAddV1.parseResponse(response),
  },
  // Create user's product
  productPickAdd: {
    callAPI: (params) => UserProductPickAddV1.exec(params),
    parseResponse: (response) => UserProductPickAddV1.parseResponse(response),
  },
  productPickRemove: {
    callAPI: (params) => UserProductPickRemoveV1.exec(params),
    parseResponse: (response) =>
      UserProductPickRemoveV1.parseResponse(response),
  },
  categoryAdd: {
    callAPI: (params) => UserCategoryAddV1.exec(params),
    parseResponse: (response) => UserCategoryAddV1.parseResponse(response),
  },
  // Remove user's product
  productRemove: {
    callAPI: (params) => UserProductRemoveV1.exec(params),
    parseResponse: (response) => UserProductRemoveV1.parseResponse(response),
  },
  // Get user's services
  serviceBrowse: {
    callAPI: (params) => UserServiceBrowseV1.exec(params),
    parseResponse: (response) => UserServiceBrowseV1.parseResponse(response),
  },
  serviceCategories: {
    callAPI: (params) => UserCategoriesServiceBrowseV1.exec(params),
    parseResponse: (response) =>
      UserCategoriesServiceBrowseV1.parseResponse(response),
  },
  // Edit user's service
  serviceEdit: {
    callAPI: (params) => UserServiceEditV1.exec(params),
    parseResponse: (response) => UserServiceEditV1.parseResponse(response),
  },
  // Create user's service
  serviceAdd: {
    callAPI: (params) => UserServiceAddV1.exec(params),
    parseResponse: (response) => UserServiceAddV1.parseResponse(response),
  },
  // Remove user's service
  serviceRemove: {
    callAPI: (params) => UserServiceRemoveV1.exec(params),
    parseResponse: (response) => UserServiceRemoveV1.parseResponse(response),
  },
  // Get user's services
  collectionBrowse: {
    callAPI: (params) => UserCollectionBrowseV1.exec(params),
    parseResponse: (response) => UserCollectionBrowseV1.parseResponse(response),
  },
  collectionCategories: {
    callAPI: (params) => UserCategoriesCollectionBrowseV1.exec(params),
    parseResponse: (response) =>
      UserCategoriesCollectionBrowseV1.parseResponse(response),
  },
  // Edit user's service
  collectionEdit: {
    callAPI: (params) => UserCollectionEditV1.exec(params),
    parseResponse: (response) => UserCollectionEditV1.parseResponse(response),
  },
  // Create user's service
  collectionAdd: {
    callAPI: (params) => UserCollectionAddV1.exec(params),
    parseResponse: (response) => UserCollectionAddV1.parseResponse(response),
  },
  // Remove user's service
  collectionRemove: {
    callAPI: (params) => UserCollectionRemoveV1.exec(params),
    parseResponse: (response) => UserCollectionRemoveV1.parseResponse(response),
  },
  // Get user's product promotions
  productPromotionBrowse: {
    callAPI: (params) => UserProductPromotionBrowseV1.exec(params),
    parseResponse: (response) =>
      UserProductPromotionBrowseV1.parseResponse(response),
  },
  // Get user's service promotions
  servicePromotionBrowse: {
    callAPI: (params) => UserServicePromotionBrowseV1.exec(params),
    parseResponse: (response) =>
      UserServicePromotionBrowseV1.parseResponse(response),
  },
  // Read user's promotion
  promotionRead: {
    callAPI: (params) => UserPromotionReadV1.exec(params),
    parseResponse: (response) => UserPromotionReadV1.parseResponse(response),
  },
  // Edit user's promotion
  promotionEdit: {
    callAPI: (params) => UserPromotionEditV1.exec(params),
    parseResponse: (response) => UserPromotionEditV1.parseResponse(response),
  },
  // Add user's promotion
  promotionAdd: {
    callAPI: (params) => UserPromotionAddV1.exec(params),
    parseResponse: (response) => UserPromotionAddV1.parseResponse(response),
  },
  // Remove user's promotion
  promotionRemove: {
    callAPI: (params) => UserPromotionRemoveV1.exec(params),
    parseResponse: (response) => UserPromotionRemoveV1.parseResponse(response),
  },
  // Boost user's promotion
  promotionBoost: {
    callAPI: (params) => UserPromotionBoostV1.exec(params),
    parseResponse: (response) => UserPromotionBoostV1.parseResponse(response),
  },
  // category food
  getFoodOrderUserCategories: {
    callAPI: (params) => UserFoodCategoryBrowseV1.exec(params),
    parseResponse: (response) =>
      UserFoodCategoryBrowseV1.parseResponse(response),
  },
  getBusinessFoodOrder: {
    callAPI: (params) => UserProductFoodBrowserV1.exec(params),
    parseResponse: (response) =>
      UserProductFoodBrowserV1.parseResponse(response),
  },
  getProductByBussiness: {
    callAPI: (params) => UserProductFoodOrderBrowserV1.exec(params),
    parseResponse: (response) =>
      UserProductFoodOrderBrowserV1.parseResponse(response),
  },
  // Get user's videos
  videoBrowse: {
    callAPI: (params) => UserVideoBrowseV1.exec(params),
    parseResponse: (response) => UserVideoBrowseV1.parseResponse(response),
  },
  videoDataBrowse: {
    callAPI: (params) => UserVideoDataBrowseV1.exec(params),
    parseResponse: (response) => UserVideoDataBrowseV1.parseResponse(response),
  },
  // Edit user's videos
  videoEdit: {
    callAPI: (params) => UserVideoEditV1.exec(params),
    parseResponse: (response) => UserVideoEditV1.parseResponse(response),
  },
  // Create user's videos
  videoAdd: {
    callAPI: (params) => UserVideoAddV1.exec(params),
    parseResponse: (response) => UserVideoAddV1.parseResponse(response),
  },
  // Remove user's videos
  videoRemove: {
    callAPI: (params) => UserVideoRemoveV1.exec(params),
    parseResponse: (response) => UserVideoRemoveV1.parseResponse(response),
  },
  videoRead: {
    callAPI: (params) => UserVideoReadV1.exec(params),
    parseResponse: (response) => UserVideoReadV1.parseResponse(response),
  },
  // Get all videos
  videoBrowseAll: {
    callAPI: (params) => UserVideoBrowseAllV1.exec(params),
    parseResponse: (response) => UserVideoBrowseAllV1.parseResponse(response),
  },

  // Add view videos
  videoView: {
    callAPI: (params) => UserViewVideoV1.exec(params),
    parseResponse: (response) => UserViewVideoV1.parseResponse(response),
  },

  // Add like videos
  addLikeVideos: {
    callAPI: (params) => UserLikeVideoV1.exec(params),
    parseResponse: (response) => UserLikeVideoV1.parseResponse(response),
  },
  // Tables
  tablesBrowse: {
    callAPI: (params) => TablesBrowserV1.exec(params),
    parseResponse: (response) => TablesBrowserV1.parseResponse(response),
  },
  tablesRemove: {
    callAPI: (params) => TablesRemoveV1.exec(params),
    parseResponse: (response) => TablesRemoveV1.parseResponse(response),
  },
  tablesAdd: {
    callAPI: (params) => TablesAddV1.exec(params),
    parseResponse: (response) => TablesAddV1.parseResponse(response),
  },
  tablesRead: {
    callAPI: (params) => TablesReadV1.exec(params),
    parseResponse: (response) => TablesReadV1.parseResponse(response),
  },
  tablesEdit: {
    callAPI: (params) => TablesEditV1.exec(params),
    parseResponse: (response) => TablesEditV1.parseResponse(response),
  },
};
