import normalize from 'json-api-normalizer'
import build from 'redux-object'

const formatJson = (data, dataType, dataId) => {
  const schemaData = normalize(data, {
    camelizeTypeValues: false,
    camelizeKeys: false
  })

  return build(schemaData, dataType, dataId, { includeType: true })
}

export default formatJson
