import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Pane } from 'shared/components/layouts';
import PropTypes from 'prop-types';

class StartYourOnline extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  onRedirect = () => {
    const { user, isAuthenticated } = this.props.auth;
    const name =
      user && user.displayName
        ? user.displayName.replace(/ /g, '_').toLowerCase()
        : '';
    this.props.history.push(
      isAuthenticated ? `/u/${name}/overview` : '/signup'
    );
  };

  render() {
    return (
      <>
        <Pane className="learn-more" />
        <Pane className="wrap-learn d-flex pr-3 pl-3 justify-content-center flex-column">
          <p className="learn-norm">
            <strong>Start your online store in minutes</strong>
          </p>
          <h3 className="learn-title">
            Start your business online and turn it mobile
          </h3>
          <p className="learn-norm">
            Cardbey unlock your business power to sell any where to any one,
            locally or globally at your store or across the internet. Quickly
            and simply create on a single platform with order management,
            pricing and integrated marketing solution. Sell made easy now.
          </p>
          <p className="learn-norm">
            <strong>It's totaly Free in local or global market</strong>
          </p>
          <Pane className="learn-button mt-2">
            <button className="btn btn-circle btn-form" onClick={this.onRedirect}>CREATE NOW</button>
          </Pane>
        </Pane>
      </>
    );
  }
}
const bindStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(bindStateToProps)(
  injectIntl(StartYourOnline))
);
