import * as types from 'constants/ActionTypes'
import api from 'api'

// Collections
export const getCollectionsByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_COLLECTIONS, types.RECEIVE_MY_COLLECTIONS_SUCCESS, types.RECEIVE_MY_COLLECTIONS_FAILURE],
  payload: {
    request: api.user.collections(params)
  }
})

export const getCategoriesByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_COLLECTIONS, types.RECEIVE_MY_COLLECTION_CATEGORIES_SUCCESS, types.RECEIVE_MY_COLLECTIONS_FAILURE],
  payload: {
    request: api.user.collectionCategories(params)
  }
})

// Create a collection
export const createMyCollection = (params) => (dispatch) => dispatch({
  type: types.CREATE_MY_COLLECTION,
  payload: {
    request: api.user.createCollection(params)
  }
})

// Get collection detail
export const getMyCollectionDetail = (params) => (dispatch) => dispatch({
  type: types.MY_COLLECTION_DETAIL,
  payload: {
    request: api.user.collectionDetail(params)
  }
})

// Edit collection detail
export const editMyCollection = (params) => (dispatch) => dispatch({
  type: types.EDIT_MY_COLLECTION,
  payload: {
    request: api.user.editCollection(params)
  }
})

// Edit service detail
export const deleteMyCollection = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_COLLECTION,
  payload: {
    request: api.user.deleteCollection(params)
  }
})
