import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Pane, Loading } from "shared/components/layouts";
import ProductAdapter from "services/products/adapter";
import InlineMessage from "shared/components/InlineMessage";
import { formatRoute, handleError, quill } from "shared/helpers";
import { Button, Form, Loader } from "semantic-ui-react";

class CreateProductForm extends Component {
  static propTypes = {
    products: PropTypes.object,
  };

  state = {
    data: {
      name: "",
      description: "",
    },
    errors: {},
    open: false,
    loading: false,
    fetched: false,
    fetching: false,
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }

    return errors;
  };

  editProduct = () => {
    const { userInfo } = this.props;
    const errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new FormData();

      data.append("name", this.state.data.name);
      data.append("description", this.state.data.description);
      data.append("id", this.props.id);
      data.append("business_id", userInfo.business.id);

      this.props
        .editMyTables(data)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push(
            `/u/${formatRoute(this.props.userInfo.displayName)}/tables`
          );
        })
        .catch((error) => {
          const errors = {};
          errors.message = handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );
          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  componentDidMount() {
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props
      .getMyTableDetail({ id: this.props.id })
      .then((response) => {
        const product = ProductAdapter.read.parseResponse(response.data);

        this.setState({
          data: {
            name: product.name || "",
            description: product.description || "",
          },
          fetched: true,
          fetching: false,
        });
      })
      .catch(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  }

  render() {
    const { errors, data, loading, fetched, fetching } = this.state;
    return (
      <Form className="form-overview pb-5" onSubmit={this.editProduct}>
        {fetched && !fetching ? (
          <>
            <Pane className="form-content">
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field error={!!errors.name} className="form-item">
                    <label htmlFor="name">
                      <FormattedMessage id="name" defaultMessage="Name" />
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder=""
                      className="form-input"
                      value={data.name}
                      onChange={this.onChange}
                    />
                    {errors.name && <InlineMessage text={errors.name} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    error={!!errors.description}
                    className="form-item"
                  >
                    <label htmlFor="description" className="form-label">
                      <FormattedMessage
                        id="description"
                        defaultMessage="Description"
                      />
                    </label>
                    <ReactQuill
                      value={data.description}
                      onChange={(content) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            description: content,
                          },
                        }));
                      }}
                      formats={quill.formats}
                      modules={quill.modules}
                    />
                    {errors.description && (
                      <InlineMessage text={errors.description} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
            </Pane>
            <Pane className="row">
              <Pane className="col-lg-12 text-center">
                <Button
                  type="submit"
                  className="btn btn-save mt-4"
                  disabled={loading}
                  loading={loading}
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </Pane>
            </Pane>
          </>
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}
      </Form>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  products: state.myProducts,
});

export default withRouter(connect(bindStateToProps)(CreateProductForm));
