import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { Pane } from 'shared/components/layouts';
import { formatRoute } from 'shared/helpers';
import { get } from 'lodash';
import { BusinessInfo } from 'shared/components';
import Config from 'constants/Config';
import { FacebookShareButton } from 'react-share';
import classnames from "classnames";

class Menu extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    business: PropTypes.object,
    logout: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      MENU_USERS : [
        {
          url: '',
          icon: 'icon-th-thumb',
          label: 'Dashboard',
          language: 'dashboard',
          isSuperadmin: false,
          fontello: true
        },
        {
          url: 'orders',
          icon: 'order',
          label: 'Orders',
          language: 'orders',
          isSuperadmin: false
        },
        {
          url: 'bookings',
          icon: 'booking',
          label: 'Bookings',
          language: 'bookings',
          isSuperadmin: false
        },
        // {
        //   url: 'overview',
        //   icon: 'overview',
        //   label: 'OVERVIEW',
        //   language: 'overview',
        //   isSuperadmin: false
        // },
        // {
        //   url: 'projects',
        //   icon: 'slide-show',
        //   label: 'Projects',
        //   language: 'projects',
        //   isSuperadmin: false
        // },
        // {
        //   url: 'tasks',
        //   icon: 'slide-show',
        //   label: 'Tasks',
        //   language: 'tasks',
        //   isSuperadmin: false
        // },
        {
          url: 'staffs',
          icon: 'icon-users',
          label: 'Staffs',
          language: 'staffs',
          isSuperadmin: false,
          fontello: true
        },
        {
          url: 'product-picks',
          icon: 'product',
          label: 'Amazon Picks',
          language: 'amazon_picks',
          isSuperadmin: true
        },
        {
          url: 'digital-slideshow',
          icon: 'slide-show',
          label: 'Digital Slideshow',
          language: 'digital_slideshow',
          isSuperadmin: false
        },
        // {
        //   url: 'digitals-share',
        //   icon: 'icon-picture',
        //   label: 'Digitals Share',
        //   language: 'screen_share_requested',
        //   isSuperadmin: false,
        //   fontello: true
        // },
        // {
        //   url: 'screen-advertising',
        //   icon: 'icon-tv',
        //   label: 'C-net',
        //   language: 'screen-advertising',
        //   isUserUrl: true,
        //   fontello: true
        // },
        {
          url: 'store-reviews',
          icon: 'icon-pencil-2',
          label: "Store's reviews",
          language: 'store-reviews',
          isSuperadmin: false,
          fontello: true
        },
        {
          url: 'my-videos',
          icon: 'video-player',
          label: 'My Video',
          language: 'my_video',
          isSuperadmin: false
        },
        {
          url: 'wallet',
          icon: 'icon-wallet',
          label: 'Wallet',
          language: 'my_wallet',
          isSuperadmin: false,
          fontello: true
        },
        {
          url: 'promotions',
          icon: 'promotion',
          label: 'Promotions',
          language: 'promotions',
          isSuperadmin: false
        },
        {
          url: 'first-scan-qr-promotion',
          icon: 'qr-code',
          label: 'Scan QR Promotions',
          language: 'qr_promotions',
          isSuperadmin: false
        },
        // {
        //   url: 'orders',
        //   icon: 'order',
        //   label: 'Orders',
        //   language: 'orders',
        //   isSuperadmin: false
        // },
        // {
        //   url: 'bookings',
        //   icon: 'booking',
        //   label: 'Bookings',
        //   language: 'bookings',
        //   isSuperadmin: false
        // },
        {
          url: 'settings',
          icon: 'setting',
          label: 'Settings',
          language: 'settings',
          isSuperadmin: false
        },
        // {
        //   sharedFacebook: true
        // },
        // {
        //   url: 'delivery-service',
        //   icon: 'delivery',
        //   label: 'Delivery Service',
        //   language: 'delivery_service',
        //   isSuperadmin: false
        // },
        {
          isLogout: true
        },
      ],
      open: false,
    };
  }

  componentDidMount() {
    const { business } = this.props;
    let type = business.type
    if (['Food', 'Product', 'Service'].includes(type) && business.is_active) {
      let myMenu = {
        'Food': {
          url: 'menu',
          icon: 'menu',
          label: 'My menu',
          language: 'my_menu',
          isSuperadmin: false
        },
        'Product': {
          url: 'products',
          icon: 'product',
          label: 'My products',
          language: 'my_products',
          isSuperadmin: false
        },
        'Service': {
          url: 'services',
          icon: 'service',
          label: 'My services',
          language: 'my_services',
          isSuperadmin: false
        },
      }[type]
      let newMENU_STORE = this.state.MENU_USERS
      newMENU_STORE.splice(3, 0 ,myMenu)
      if (['Food', 'Product'].includes(type)) {
        newMENU_STORE.splice(4, 0 ,{
          url: 'services',
          icon: 'service',
          label: 'My services',
          language: 'my_services',
          isSuperadmin: false
        })
      }
      if (type === 'Food') {
        newMENU_STORE.splice(5, 0 ,
        {
          url: 'tables',
          icon: 'menu',
          label: 'My tables',
          language: 'my_tables',
          isSuperadmin: false
        })
      }
      this.setState({
        MENU_USERS: newMENU_STORE
      })
    } else if (!business.is_active) {
      let newMENU_STORE = [
        // {
        //   url: "overview",
        //   icon: "overview",
        //   label: "OVERVIEW",
        //   language: "overview",
        // },
        {
          url: "collections",
          icon: "video-player",
          label: "Collections",
          language: "my_collections",
        },
        {
          url: 'orders',
          icon: 'order',
          label: 'Orders',
          language: 'orders',
          isSuperadmin: false
        },
        {
          url: 'digitals-share',
          icon: 'icon-picture',
          label: 'Digitals Share',
          language: 'digitals_share',
          isSuperadmin: false,
          fontello: true
        },
        {
          url: 'bookings',
          icon: 'booking',
          label: 'Bookings',
          language: 'bookings',
          isSuperadmin: false
        },
        {
          url: 'wallet',
          icon: 'icon-wallet',
          label: 'Wallet',
          language: 'my_wallet',
          isSuperadmin: false,
          fontello: true
        },
        {
          url: 'settings',
          icon: 'setting',
          label: 'Settings',
          language: 'settings',
          isSuperadmin: false
        },
      ]
      this.setState({
        MENU_USERS: newMENU_STORE
      })
    }
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  logout = async (e) => {
    e.preventDefault();
    this.props.logout();
    await this.props.history.push('/');
  };

  render() {
    const { userInfo, business, toggleMenu } = this.props;
    const name =
      userInfo && userInfo.displayName ? formatRoute(userInfo.displayName) : '';
    return (
      <Pane className={classnames("inner-box inner-overview", {
        "inner-overvier-active": toggleMenu,
      })}>
        <Pane className="category-content">
          <ul className="category-list">
            {
              this.state.MENU_USERS.map((item, index) => {
                if (item.isSuperadmin) {
                  return (
                    get(userInfo, 'email') === 'productsource@cardbey.com' && (
                      <li className="category-item" key={index}>
                        <Link
                          className={
                            this.props.location.pathname === `/u/${name}/product-picks`
                              ? 'category-link category-active'
                              : 'category-link'
                          }
                          to={`/u/${name}/product-picks`}
                        >
                          <span className="icon ic-common-product" />
                          <FormattedMessage
                            id="amazon_picks"
                            defaultMessage="Amazon Picks"
                          />
                        </Link>
                      </li>
                    )
                  );
                }
                // if (item.sharedFacebook) {
                //   return (
                //     <li className="category-item" key={index}>
                //       <FacebookShareButton
                //         url={`${Config.SITE_URL}/${business.id}`}
                //         className="category-link"
                //       >
                //         <span className="icon ic-common-share" />
                //         <FormattedMessage
                //           id="sharemystores"
                //           defaultMessage="Share My Stores"
                //         />
                //       </FacebookShareButton>
                //     </li>
                //   );
                // }
                if (item.isLogout) {
                  return (
                    <li className="category-item" key={index}>
                      <span className="category-link" onClick={this.logout}>
                        <span className="icon ic-common-logout" />
                        <FormattedMessage
                          id="logout"
                          defaultMessage="Logout" />
                    </span>
                    </li>
                  );
                }
                if(item.isUserUrl){
                  return (
                    <li className="category-item" key={index}>
                      <Link
                        className={
                          this.props.location.pathname === `/${item.url}`
                            ? 'category-link category-active'
                            : 'category-link'
                        }
                        to={`/${item.url}`}
                      >
                        <span className={`icon ${item.fontello ? item.icon : `ic-common-${item.icon}`}`} />
                        <FormattedMessage id={item.language} defaultMessage={item.label} />
                      </Link>
                    </li>
                  );
                    }
                return (
                  <li className="category-item" key={index}>
                    <Link
                      className={
                        (item.url !== '' ? this.props.location.pathname.includes(`/u/${name}/${item.url}`) : this.props.location.pathname === `/u/${name}/${item.url}`)
                          ? 'category-link category-active'
                          : 'category-link'
                      }
                      to={`/u/${name}/${item.url}`}
                    >
                      <span className={`icon ${item.fontello ? item.icon : `ic-common-${item.icon}`}`} />
                      <FormattedMessage id={item.language} defaultMessage={item.label} />
                    </Link>
                  </li>
                );
              })
            }
          </ul>
          {business &&
            business.id &&
            business.name &&
            business.email && (
              <ReactTooltip
                place="right"
                type="dark"
                effect="float"
                id="business-profile"
              >
                <BusinessInfo business={business} />
              </ReactTooltip>
            )}
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(Menu);
