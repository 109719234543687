import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio, Form } from 'semantic-ui-react';
import { Pane } from 'shared/components/layouts';

class TimeSlotItem extends Component {
  static propTypes = {
    fromTime: PropTypes.any,
    toTime: PropTypes.any,
    index: PropTypes.any,
    available: PropTypes.bool,
    onChangeTimeSlot: PropTypes.func,
  };

  state = {
    detail: {},
    loading: false,
  };

  onChange = () => {
    this.props.handleSubmit(this.props.index);
  };

  render() {
    const { available, fromTime } = this.props;

    return (
      <Pane onClick={this.onChange} className="time-slot-item">
        <Pane className="time">
          <p>
            {available ? (
              fromTime
            ) : (
              <span style={{ color: '#c00' }}>{fromTime}</span>
            )}
          </p>
        </Pane>
        <Pane className="time-icon">
          &#10095;
        </Pane>
        {/* <Pane className="row pt10">
          <Pane className="col-1">
            <Pane className="checkbox-wrapper-15">
              <input onChange={this.onChange} value={index} className="inp-cbx hide" id={`cbx-${index}`} type="checkbox" />
              <label className="cbx" for={`cbx-${index}`}>
                <span>
                  <svg width="12px" height="9px" viewBox="0 0 12 9">
                    <polyline points="1 5 4 8 11 1"></polyline>
                  </svg>
                </span>
                <span>{available ? (
                  fromTime
                ) : (
                  <span style={{ color: '#c00' }}>{fromTime}</span>
                )}</span>
              </label>
            </Pane>
          </Pane>
        </Pane> */}
      </Pane>
    );
  }
}

export default TimeSlotItem;
