import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Wrapper extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    as: PropTypes.string,
  }

  render() {
    const { as } = this.props
    const childrenWithProps = React.Children.map(this.props.children, (child) => React.cloneElement(child, {}))
    const props = Object.assign({}, this.props)

    delete props.as

    const Tag = `${as}`

    return (
      <Tag { ...props }>
        { childrenWithProps }
      </Tag>
    )
  }
}

Wrapper.defaultProps = {
  as: 'div'
}

export default Wrapper
