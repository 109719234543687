import Config from "constants/Config";
import { formatRoute, formatJson } from "shared/helpers";

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const category = params.category || "";
  const store = params.store || "";
  const sort = params.sort || "";
  const discount = params.discount === "true" ? `>0` : "";
  const images = params.images === null ? null : "";

  return {
    method: "GET",
    baseURL: Config.NEW_API_URL,
    url: `/services?category_id=${category}&images.id=${images}&user_id=${store}&sort=${sort}&promotion.id=${discount}&page[size]=${size}&page[number]=${page}`,
  };
};

const parseResponse = (response) => {
  const services = [];

  if (response.data) {
    response.data.forEach((item) => {
      const service = formatJson(response, item.type, item.id);

      let promotion = {};

      if (service.promotion) {
        promotion = {
          id: service.promotion.id,
          percent: Number(service.promotion.percent),
          startDate: service.promotion.startDate,
          endDate: service.promotion.endDate,
          primaryImage:
            service.promotion.primaryImage &&
            service.promotion.primaryImage.url,
        };
      }

      const options = [];

      (service.options || []).forEach((option) => {
        const group = option.optionGroup || {};
        const index = options.findIndex((option) => option.id === group.id);
        if (index === -1) {
          options.push({
            id: group.id,
            name: group.name,
            type: group.type,
            autoAddOptions: false,
            dpOrdSeq: group.dpOrdSeq,
            delt: "N",
            childrens: [
              {
                id: option.id,
                name: option.name,
                dpOrdSeq: option.dpOrdSeq,
                price: Number(option.price),
                delt: "N",
                autoAddOptions: false,
                isCheck: !!option.isCheck,
              },
            ],
          });
        } else {
          options[index].childrens.push({
            id: option.id,
            name: option.name,
            dpOrdSeq: option.dpOrdSeq,
            price: Number(option.price),
            delt: "N",
            autoAddOptions: false,
            isCheck: !!option.isCheck,
          });
        }

        return options;
      });

      options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
      options.forEach((option) =>
        option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq)
      );

      services.push({
        category: service.category,
        id: service.id,
        name: service.name,
        categoryId: service.category && service.category.id,
        categoryName: service.category && service.category.name,
        description: service.description,
        slug: `${formatRoute(service.name, "-")}-${service.id}`,
        primaryImage: service.primaryImage && service.primaryImage.url,
        rating:
          service.statistic && service.statistic.rating
            ? Number(service.statistic.rating)
            : 0,
        userId: service.user && service.user.id,
        originalPrice: Number(service.price),
        promotionPrice: Number(service.promotionPrice),
        duration: service.duration,
        inventoryStatus: service.inventoryStatus,
        type: "services",
        promotion,
        options,
      });
    });
  }

  return services;
};

export { exec, parseResponse };
