import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Rating } from 'semantic-ui-react';
import { Pane } from 'shared/components/layouts';
import { PriceItem, PromotionLabel } from 'shared/components';
import Config from 'constants/Config';

class ListItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    intl: intlShape.isRequired,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
  };

  state = {
    added: false,
    adding: false,
    play: 0,
  };

  addItemToCart = () => {

    this.setState({ added: false, adding: true });

    this.props
      .onAddItemToCart({ product_id: this.props.id })
      .then(() => {
        this.setState({ added: true, adding: false });
      })
      .catch(() => {
        this.setState({ adding: false });
        // this.props.onNotification.addNotification({
        //   message: intl.formatMessage({ id: 'notification.please_try_again' }),
        //   level: 'error',
        // });
      });
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  }

  playVideo = (event) => {
    this.setState({
      play: 1
    });
    const video = event.currentTarget;
    // var isPlaying = video.currentTime > 0 && !video.paused;
    // if(isPlaying){
    //   video.pause();
    // }
    video.currentTime = 0;
    setTimeout((v) => {
      //this.stopVideo.apply(null,target);
      v.currentTime = 0;
      v.pause();
    }, Config.VIDEO_TIME, video);
  };

  renderInfo = () => {
    const { item } = this.props;
    return (
      <Pane className="adds-info">
        <PriceItem
          promotion={item && item.promotion}
          originalPrice={item && item.originalPrice}
        />
      </Pane>
    );
  };

  onRedirect = (value) => {
    const { tableId } = this.state;
    if (this.props.type === "bussiness") {
      return `/${value}`;
    }
    if (tableId) {
      return `/products/${value}?tableId=${tableId}`;
    }
    return `/products/${value}`;
  };


  render() {
    const { item, name, image, rating } = this.props;
    const { added } = this.state;
    const extImage = ['jpg','jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const ext =  image != null ? image.substr(image.lastIndexOf('.') + 1) :'';
    return (
      <Pane className="col-lg-3 col-md-4 col-sm-6 col-12">
        <Pane className="adds-item">
            <Link className="hover-image" to={this.onRedirect(item.slug)}>
              {extImage.indexOf(ext) >= 0 ? (
                <Pane
                  className="adds-images"
                  style={{
                    backgroundImage: image ? `url(${image})` : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                  }} >
                  <PromotionLabel
                    promotion={item && item.promotion}
                    originalPrice={
                      item && item.originalPrice
                    }
                  />
                  {(added) && (
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/icons/check.svg`
                      }
                      alt="check"
                      className="icon-check"
                    />
                  )}
                </Pane>
              ) : (
                <Pane className="adds-images">
                  {image ? (
                    <Pane>
                      {ext !== "" ? <i className="fa fa-video" /> : ""}
                      <video
                        className="carbeyvideo-list"
                        playsInline={1}
                        onMouseOver={this.playVideo}
                        onMouseOut={this.stopVideo}
                        muted={!this.state.play}
                      >
                        <source src={image} id="video_here" />
                        Your browser does not support HTML5 video.111
                      </video>
                      <PromotionLabel
                        promotion={item && item.promotion}
                        originalPrice={
                          item && item.originalPrice
                        }
                      />
                      {(added) && (
                        <img
                          src={
                            process.env.PUBLIC_URL + `/assets/images/icons/check.svg`
                          }
                          alt="check"
                          className="icon-check"
                        />
                      )}
                    </Pane>
                  ) : (
                    <Link className="hover-image" to={this.onRedirect(item.slug)}>
                      <Pane
                        className="adds-images"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                        }}
                      ></Pane>
                    </Link>
                  )}
                </Pane>
              )}
            </Link>
            <button className="btn-order" onClick={this.addItemToCart}>
              <span className="icon-plus" />
            </button>
            <Pane className="adds-content">
              <Link
                to={
                  this.props.type === "bussiness"
                    ? this.onRedirect(item.slug)
                    : this.onRedirect(this.props.id)
                }
              >
                <h5 className="adds-norm" title={name} >
                  <b>{name}</b>
                </h5>
              </Link>
              {this.renderInfo()}
              <button className="btn-order-sm" onClick={this.addItemToCart}>
                <span className="icon-plus" />
              </button>
            </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
