import React, { Component } from 'react';
import { Pane, Loading } from 'shared/components/layouts';
import { Dimmer } from 'semantic-ui-react';
import Config from "constants/Config";

class BannerIntro extends Component {

  state = {
    loading: false,
    imageRender: "",
    imageFile: "",
    imageRenderName: ""
  };

  onChangeBg = (e) => {
    this.setState({ loading: true });
    this.cancelUpload();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      this.setState({imageRenderName: file.name});
      reader.onloadend = () => {
        this.setState({
          imageRender: reader.result,
          imageFile: file,
        });
      };
      reader.readAsDataURL(file);
    }
    this.setState({ loading: false });
  };

  uploadBackground = () => {
    const data = new FormData();

    if (this.state.imageFile) {
      data.append("id", this.props.userInfo.id);
      data.append("background_image", this.state.imageFile);

      this.setState({ loading: true });

      this.props
        .updateUserInfo(data)
        .then((response) => {
          this.setState({ loading: false });
          window.location.reload();
        })
        .catch((errors) => {
          this.setState({ loading: false });
        });
    }
  };

  cancelUpload = () => {
    this.setState({ imageRender: "" });
  }

  render() {
    const { userInfo } = this.props;
    const { imageRender, imageRenderName, loading } = this.state;
    let imgCheck = imageRender ? imageRenderName : userInfo.backgroundImage;
    let imgShow = imageRender ? imageRender : userInfo.backgroundImage;
    let isBgVideo = imgCheck && Config.extVideo.includes(imgCheck.substr(imgCheck.lastIndexOf(".") + 1));
    return (
      <Pane className={"banner-container banner-setting banner-store" + (isBgVideo ? ' banner-store_video' : '')} id="banner-container">
        {!loading ? (
          <>
            {!userInfo.backgroundImage && !imageRender && (
              <Pane
                className="banner-item"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + `/assets/images/default-image.png`})`,
                }}
              />
            )}
            {imgShow && !isBgVideo && (
              <Pane
                className="banner-item"
                style={{
                  backgroundImage: `url(${imgShow
                    ? `${imgShow ||
                    process.env.PUBLIC_URL +
                    `/assets/images/default-image.png`}`
                    : process.env.PUBLIC_URL + `/assets/images/default-image.png`
                    })`,
                }}
              />
            )}
            {imgShow && isBgVideo && (
              <>
                <Pane className="bg-blur"></Pane>
                <video muted autoPlay playsInline loop allowFullScreen={false} width="100%" height="450">
                  <source src={imgShow} id="video_here" type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
              </>
            )}
            <input
              type="file"
              id="backgroundImg"
              className="form-input-file d-none"
              name="backgroundImage"
              placeholder=""
              value=""
              disabled={loading}
              onChange={this.onChangeBg}
            />
            <label htmlFor="backgroundImg" className="edit-bg">
              <i className="icon-camera" />
              <span className="d-lg-inline d-none">Edit background</span>
            </label>
            {imageRender && (
              <Pane className="block-action">
                <Pane className="block-action__btn btn-cancel" onClick={this.cancelUpload}>Cancel</Pane>
                <Pane className="block-action__btn btn-save" onClick={this.uploadBackground}>Save</Pane>
              </Pane>
            )}
          </>
        ) : (
          <Pane className="banner-item">
            <Loading />
          </Pane>
        )}
      </Pane>
    );
  }
}

BannerIntro.propTypes = {};

export default BannerIntro;
