import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { Currency } from "shared/components";
import { Button } from "semantic-ui-react";
import { withRouter } from 'react-router-dom';

class BottomCart extends Component {
	handleTotal = () => {
		let total = 0;
		if (this.props.items.length > 0) {
			this.props.items.map((item) => {
				let totalPriceOp = 0;

				if (item.children) {
					totalPriceOp += item.children.price;
				}

				total += item.originalPrice + totalPriceOp;
			})
		}

		return total;
	}

	render() {
		const { storeInfo, items, discountPercent, maxDiscount } = this.props;
		let discountPrice = 0;
		let strMaxDiscount='';
		let total = this.handleTotal();
		if(discountPercent){
			discountPrice =  total*(discountPercent/100);
		}
		if(maxDiscount > 0 && discountPrice > maxDiscount){
		strMaxDiscount =  `Max AUD ${parseFloat(maxDiscount).toFixed(2)}`;
		discountPrice = maxDiscount;
		}
		return (
			<Pane className="bottom_cart">
				<Pane className="block_cart">
					<Pane
						className="build-cart"
						onClick={() => this.props.history.push("/cart")}
					>
						{items.length} {`service${items.length > 1 ? 's' : ''}`}
					</Pane>
					<Pane className="total">
						{this.handleTotal() > 0 ? (
							<Currency price={this.handleTotal()} />
						) : (
							<>free</>
						)}
					</Pane>
				</Pane>
				<Button onClick={() => this.props.handleStep(2)}>
					<FormattedMessage
						id="book_now"
						defaultMessage="Book now"
					></FormattedMessage>
				</Button>
			</Pane>
		);
	}
}

export default withRouter(BottomCart);