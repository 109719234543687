import { get, isEmpty, mapKeys } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { QRCode } from "shared/components";
import Modal from "react-responsive-modal";
import { Pane } from "shared/components/layouts";
import { Rating, Button, List } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import { TradingHours } from "../../user/common/trading-hours";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Content from "../../../common/readmore/ReadMore";
import { FormattedMessage } from "react-intl";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  ViberIcon,
  ViberShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LineIcon,
  LineShareButton,
} from "react-share";
import Config from "constants/Config";

class BusinessInfo extends Component {
  static propTypes = {
    businessInfo: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
      flag: false,
    };
    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  onOpenModal = () => {
    this.setState({ open: true },
      () => {
        let tawkBtn = document.querySelector('[title="chat widget"]');
        if (tawkBtn) {
          tawkBtn.style.display = 'none';
        }
      })
  };

  onCloseModal = () => {
    this.setState({ open: false },
      () => {
        let tawkBtn = document.querySelector('[title="chat widget"]');
        if (tawkBtn) {
          tawkBtn.style.display = 'block';
        }
        this.props.history.replace(this.props.location.pathname);
      });
  };

  renderStatus = (tradingHours) => {
    if (!isEmpty(tradingHours)) {
      const currentDate = moment().format("ddd");
      const renamed = mapKeys(tradingHours, (value, key) => key.toLowerCase());
      const tradingHoursCurrent = renamed[currentDate.toLowerCase()];
      if (!isEmpty(tradingHoursCurrent)) {
        const fromDate = moment(tradingHoursCurrent.from, "HH:mm");
        const toDate = moment(tradingHoursCurrent.to, "HH:mm");
        if (moment().isBetween(fromDate, toDate) && !tradingHoursCurrent.off) {
          return <div className="box-active" />;
        }
        return <div className="box-inactive" />;
      }
      return <div className="box-inactive" />;
    }
    return <div className="box-inactive" />;
  };

  componentDidMount(prevProps, prevState) {
    const name = this.props.isShowQRCode;
    if (name && name === 'true') {
      this.onOpenModal();
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        flag: false,
      });
    }
  }

  render() {
    const { storeInfo, businessInfo } = this.props;
    const shareURL = `https://cardbey.com/${storeInfo.id}`;
    return (
      <Pane className="container">
        <Pane className="row">
          <Pane className="col-lg-3 col-md-12 col-12">
            <Pane className="logo-stores">
              {this.renderStatus(businessInfo.tradingHours)}
              <img
                style={{ objectFit: "cover" }}
                src={
                  get(storeInfo, "profileImage") ||
                  process.env.PUBLIC_URL + "/assets/images/user.jpg"
                }
                alt="logo"
                className="logo-img"
              />
              <div className="block-qr-code" onClick={this.onOpenModal} data-tip data-for={`tooltip-qr-card`}>
                <span className="ic-common-qr-code" />
              </div>
              <div className="popover__content">Digital card</div>
              <ReactTooltip
                place="top"
                type="dark"
                effect="float"
                id={"tooltip-qr-card"}
              >
                Click to get digital card
              </ReactTooltip>
              <Modal
                className="popup-qrcode"
                classNames={{
                  closeIcon: 'customIconBtn'
                }}
                open={this.state.open}
                onClose={this.onCloseModal}
                center
                showCloseIcon={true}
              >
                <QRCode
                  onClose={this.onCloseModal}
                  qrcode={this.props.storeInfo.qrcode}
                  storeInfo={this.props.storeInfo}
                />
              </Modal>
            </Pane>
          </Pane>
          <Pane className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-end">
            <Pane className="heading-content">
              <Pane className="heading-info">
                <Pane className="heading-left">
                  <h2 className="heading-title store-title-page">{businessInfo.name || ""}</h2>
                  <Pane className="d-none heading-status d-lg-flex align-items-start">
                    <Rating
                      disabled
                      icon='star'
                      rating={get(businessInfo, "rating") || 0}
                      maxRating={1}
                      className="mr-1"
                      size='huge'
                    />
                    <span>{get(businessInfo, "rating") || 'No Rating'}</span>
                  </Pane>
                  <Pane className="heading-norm bussiness-description font-italic font-weight-normal pb-2">{businessInfo.subTitle || ""}
                    &nbsp;<Content text={businessInfo.description || ""} />
                  </Pane>
                  {/* <Pane className="heading-norm bussiness-description" title={businessInfo.description || ""}>
                    <Content text={businessInfo.description || ""} />
                  </Pane> */}
                </Pane>
                <Pane className="heading-profile w-md-50">
                  <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                    <a
                      href={`https://www.google.com/maps?q=${businessInfo.address
                        }`}
                      className="link"
                      target={"_blank"}
                    >
                      <div className="box-icon" data-tip data-for={`location`}>
                        <span className="icon-map" />
                      </div>
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="float"
                        id={"location"}>
                        {businessInfo.address || ""}
                      </ReactTooltip>
                    </a>
                    <Pane className="directions-text">DIRECTIONS</Pane>
                  </span>
                  <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                    <a
                      href={`/${storeInfo.id}/reference?code=${storeInfo.uuid}`}
                      className="link"
                      target={"_blank"}
                    >
                      <div className="box-icon" data-tip data-for={`referral`}>
                        <span className="icon-link" />
                      </div>
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="float"
                        id={"referral"}>
                        Here is your exclusive referral link to sign up to instantly get a 5% discount on your service fees.<br /> Plus, when any of your friends use our services through your link, you will get 10% cashback based on the value of your friend’s purchase.
                      </ReactTooltip>
                    </a>
                    <Pane className="directions-text">REFERRAL</Pane>
                  </span>
                  <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                    <a href={`tel:${businessInfo.phone}`} className="link">
                      <div className="box-icon" data-tip data-for={`phone`}>
                        <span className="ic-common-phone icon" />
                      </div>
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="float"
                        id={"phone"}
                      >
                        {businessInfo.phone || ""}
                      </ReactTooltip>
                    </a>
                    <Pane className="directions-text">CALL</Pane>
                  </span>
                  <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                    <a href={`mailto:${businessInfo.email}`} className="link">
                      <div className="box-icon" data-tip data-for={`email`}>
                        <span className="ic-common-mail icon" />
                      </div>
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="float"
                        id={"email"}
                      >
                        {businessInfo.email || ""}
                      </ReactTooltip>
                    </a>
                    <Pane className="directions-text">EMAIL</Pane>
                  </span>
                  <span className="profile-name float-sm-left float-lg-left float-md-left w-10">
                    <a href={businessInfo.websiteUrl != null ? `${businessInfo.websiteUrl}` : `https://cardbey.com/${storeInfo.id}`} className="link" target={"_blank"}>
                      <div className="box-icon" data-tip data-for={`websiteUrl`}>
                        <span className="icon-globe" />
                      </div>
                      {
                        businessInfo.websiteUrl && (
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="float"
                            id={"websiteUrl"}
                          >
                            {businessInfo.websiteUrl || ""}
                          </ReactTooltip>
                        )
                      }
                    </a>
                    <Pane className="directions-text">WEBSITE</Pane>
                  </span>
                  <span ref={this.wrapperRef} className="position-relative profile-name float-sm-left float-lg-left float-md-left w-10">
                    <div onClick={() => this.setState({ flag: !this.state.flag })} className="box-icon" data-tip data-for={`share`}>
                      <span className="icon ic-common-share" />
                    </div>
                    <Pane className="directions-text">SHARE</Pane>
                    <List horizontal className={`share-list share-list-ref ${this.state.flag ? 'd-flex' : 'hide'} `}>
                      <List.Content>
                        <FacebookShareButton className="share-item" url={shareURL}>
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                      </List.Content>
                      <List.Content>
                        <TwitterShareButton className="share-item" url={shareURL}>
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                      </List.Content>
                      <List.Content>
                        <EmailShareButton className="share-item" url={shareURL}>
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                      </List.Content>
                      <List.Content>
                        <LinkedinShareButton className="share-item" url={shareURL}>
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                      </List.Content>
                      <List.Content>
                        <ViberShareButton className="share-item" url={shareURL}>
                          <ViberIcon size={32} round={true} />
                        </ViberShareButton>
                      </List.Content>

                      <List.Content>
                        <TelegramShareButton className="share-item" url={shareURL}>
                          <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>
                      </List.Content>

                      <List.Content>
                        <WhatsappShareButton className="share-item" url={shareURL}>
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                      </List.Content>
                      <List.Content>
                        <LineShareButton className="share-item" url={shareURL}>
                          <LineIcon size={32} round={true} />
                        </LineShareButton>
                      </List.Content>

                    </List>
                  </span>
                </Pane>
              </Pane>
              <TradingHours
                rating={get(businessInfo, "rating") || 0}
                tradingHours={businessInfo.tradingHours}
                onChangeToggleMenu={this.props.onChangeToggleMenu}
              />
            </Pane>
          </Pane>
          {/* {storeInfo.claimed === 0 && (
            <Pane className="col-12 d-flex justify-content-end">
              <Button
                size="mini"
                icon
                className="btn btn-claim"
                onClick={() => this.props.onOpenModalClaim()}
              >
                <FormattedMessage id="claim_your_business" defaultMessage="Claim your business" />
              </Button>
            </Pane>
          )} */}
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(BusinessInfo);
