import * as types from 'constants/ActionTypes'
import ProductAdapter from 'services/products/adapter'
import UserAdapter from 'services/users/adapter'
import normalize from 'json-api-normalizer'
import PromotionsAdapter from 'services/promotions/adapter'

const initialState = {
  foodCategories: [],
  otherCategories: [],
  results: [],
  schema: [],
  promotions:[],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const products = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_ALL_PRODUCTS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_ALL_PRODUCTS_BY_FILTER_SUCCESS:
      return Object.assign({}, state, {
        results: ProductAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_ALL_PRODUCTS_BY_FILTER_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}
const productsSlidePromotion = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_ALL_PRODUCTS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_ALL_PRODUCTS_PROMOTIONS_SLIDE_BY_FILTER_SUCCESS: 
      return Object.assign({}, state, {
        promotions:PromotionsAdapter.browse.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_ALL_PRODUCTS_PROMOTIONS_SLIDE_BY_FILTER_FAILURE:
      return Object.assign({}, state, {
        promotions: [],
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const productsPromotion = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_ALL_PRODUCTS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_ALL_PRODUCTS_PROMOTIONS_BY_FILTER_SUCCESS: 
      return Object.assign({}, state, {
        promotions:PromotionsAdapter.browse.parseResponse(action.payload.data),
        results: ProductAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_ALL_PRODUCTS_PROMOTIONS_BY_FILTER_FAILURE:
      return Object.assign({}, state, {
        promotions: [],
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const featureProducts = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_FEATURE_PRODUCTS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_FEATURE_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        results: ProductAdapter.featured.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_FEATURE_PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const myProducts = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_PRODUCTS:
      return Object.assign({}, state, {
        ...state,
        fetching: true
      })
    case types.RECEIVE_MY_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.productBrowse.parseResponse(action.payload.data),
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_FOOD_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        foodCategories: action.payload.data ? UserAdapter.foodCategories.parseResponse(action.payload.data) : [],
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_OTHER_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        otherCategories: action.payload.data ? UserAdapter.otherCategories.parseResponse(action.payload.data) : [],
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { products, featureProducts, myProducts, productsPromotion, productsSlidePromotion }
