import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";
import { Pane, Loading } from "shared/components/layouts";
import { Rating, Segment, Radio, Dimmer } from 'semantic-ui-react';
import Config from "constants/Config";
import { handleError } from "shared/helpers";
import NotificationSystem from "react-notification-system";

class ListItem extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      bgColor: [
        '#e7e8ff',
        '#deee8c',
        '#ee8cad',
        '#8eee8c',
        '#aab9bf',
        '#f7ceaa'
      ],
      selectedColor: '',
      loading: false,
      is_hide: props.item.isHide
    };
    this._notificationSystem = null;
  }

  randomBgColor = () => {
    let item = this.state.bgColor[Math.floor(Math.random() * this.state.bgColor.length)];
    this.setState({
      selectedColor: item,
    })
  }

  componentDidMount() {
    this.randomBgColor();

    this._notificationSystem = this.refs.notificationSystem;
  }

  toggleHide = () => {
    const { intl } = this.props;
    let text = intl.formatMessage({ id: "confirm_hide_review" });

    if (!window.confirm(text) == true) {
      return false;
    }
    this.setState({ is_hide: !this.state.is_hide }, () => {
      this.onSubmitToggleHide();
    });
  };

  onSubmitToggleHide = async () => {
    const { intl, item, storeId } = this.props;

    this.setState({ loading: true });

    const data = {
      id: item.id,
      is_hide: Number(this.state.is_hide),
    };

    await this.props
      .editReview(data)
      .then((res) => {
        this.setState({ loading: false });
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: "notification.successfully" }),
          level: "success"
        });
        this.props.getUserInfo({ userId: storeId });
      })
      .catch((errors) => {
        this.setState({ loading: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  render() {
    const { item } = this.props;
    const { loading, is_hide } = this.state;
    const extImage = Config.extVideo;
    const extImage1 =
      item.image1 != null
        ? item.image1.substr(item.image1.lastIndexOf(".") + 1)
        : "";
    const extImage2 =
      item.image2 != null
        ? item.image2.substr(item.image2.lastIndexOf(".") + 1)
        : "";
    const extImage3 =
      item.image3 != null
        ? item.image3.substr(item.image3.lastIndexOf(".") + 1)
        : "";
    const extImage4 =
      item.image4 != null
        ? item.image4.substr(item.image4.lastIndexOf(".") + 1)
        : "";
    const extImage5 =
      item.image5 != null
        ? item.image5.substr(item.image5.lastIndexOf(".") + 1)
        : "";
    return (
      <>
        {loading ? (
          <Dimmer active inverted>
            <Loading />
          </Dimmer>
        ) : (
          <Pane className="review-item">
            <Pane className="d-flex align-items-center justify-content-between">
              <Pane className="user-info">
                {!item.user.profileImage.includes('default-avatar.png') ? (
                  <Pane className="user-info__avatar" style={{
                    backgroundImage: item.user.profileImage
                      ? `url(${item.user.profileImage})`
                      : process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`,
                  }}>
                  </Pane>
                ) : (
                  <Pane className="user-info__avatar" style={{ backgroundColor: this.state.selectedColor }}>
                    {item.user.name.charAt(0)}
                  </Pane>
                )}
                <Pane>
                  <Pane className="user-info__name">{item.user.name}</Pane>
                  <Pane className="user-info__date">{item.createdAtDiff}</Pane>
                  <Pane className="user-info__rate">
                    <Rating disabled maxRating={5} rating={item.stars} icon='star' size='mini' />
                  </Pane>
                </Pane>
              </Pane>
              <Pane className="action mr-2">
                <Segment compact>
                  <Radio
                    label='Hide'
                    toggle
                    onChange={this.toggleHide}
                    checked={is_hide} />
                </Segment>
              </Pane>
            </Pane>
            <Pane className="review-content">
              <Pane className="review-content__desc">
                {item.comment}
              </Pane>
              <Pane className="review-content__images">
                {item.image1 ? (
                  <Pane className="img">
                    {extImage.includes(extImage1) ? (
                      <video className="carbeyvideo" controls>
                        <source src={item.image1} id="video_here" type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    ) : (
                      <img src={item.image1 || ""} alt="img-review" />
                    )}
                  </Pane>
                ) : null}
                {item.image2 ? (
                  <Pane className="img">
                    {extImage.includes(extImage2) ? (
                      <video className="carbeyvideo" controls>
                        <source src={item.image2} id="video_here" type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    ) : (
                      <img src={item.image2 || ""} alt="img-review" />
                    )}
                  </Pane>
                ) : null}
                {item.image3 ? (
                  <Pane className="img">
                    {extImage.includes(extImage3) ? (
                      <video className="carbeyvideo" controls>
                        <source src={item.image3} id="video_here" type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    ) : (
                      <img src={item.image3 || ""} alt="img-review" />
                    )}
                  </Pane>
                ) : null}
                {item.image4 ? (
                  <Pane className="img">
                    {extImage.includes(extImage4) ? (
                      <video className="carbeyvideo" controls>
                        <source src={item.image4} id="video_here" type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    ) : (
                      <img src={item.image4 || ""} alt="img-review" />
                    )}
                  </Pane>
                ) : null}
                {item.image5 ? (
                  <Pane className="img">
                    {extImage.includes(extImage5) ? (
                      <video className="carbeyvideo" controls>
                        <source src={item.image5} id="video_here" type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    ) : (
                      <img src={item.image5 || ""} alt="img-review" />
                    )}
                  </Pane>
                ) : null}
              </Pane>
            </Pane>
            <NotificationSystem ref="notificationSystem" />
          </Pane>
        )}
      </>
    );
  }
}

export default withRouter(injectIntl(ListItem));