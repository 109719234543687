import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Image, Icon } from 'semantic-ui-react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import moment from 'moment'
import Datetime from 'react-datetime'
import { Pane } from 'shared/components/layouts'
import InlineMessage from 'shared/components/InlineMessage'

class EditPromotionForm extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    getAllPromotions: PropTypes.func.isRequired,
    getPromotionDetail: PropTypes.func.isRequired,
    editPromotion: PropTypes.func.isRequired,
    intl: intlShape.isRequired
  }

  state = {
    userInfo: {},
    image: '',
    imageFile: '',
    data: {
      title: '',
      image: '',
      percentDiscount: 0,
      description: '',
      items: []
    },
    startDate: '',
    endDate: '',
    errors: {},
    deletedMedia: false,
    loading: false,
    open: false
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    })
  }

  onChangeAvatar = (e) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        this.setState({
          image: reader.result,
          imageFile: file,
          deletedMedia: true
        })
      };
      reader.readAsDataURL(file)
    }
  }

  onChangeTime(name, value) {
    this.setState({
      [name]: value.format('DD/MM/YYYY')
    })
  }

  validate = (data) => {
    const errors = {}
    const { intl } = this.props

    if (!this.state.startDate) {
      errors.startDate = intl.formatMessage({ id: 'validate.require_field' })
    }
    if (!this.state.endDate) {
      errors.endDate = intl.formatMessage({ id: 'validate.require_field' })
    }
    if (moment(this.state.startDate, 'DD/MM/YYYY').unix() > moment(this.state.endDate, 'DD/MM/YYYY').unix()) {
      errors.startDate = intl.formatMessage({ id: 'validate.invalid_compare_date' })
    }

    return errors
  }

  removeImage = () => {
    this.setState({
      image: '',
      imageFile: '',
      deletedMedia: true
    })
  }

  getPromotionDetail = () => {
    this.props.getPromotionDetail({ id: this.props.id }).then(response => {
      if (response && response.data) {
        const data = response.data.data

        this.setState({
          image: data.attributes.primaryImage ? data.attributes.primaryImage.url : '',
          data: {
            ...this.state.data,
            description: data.attributes.description || '',
            percentDiscount: data.attributes.percent || 0
          },
          startDate: moment(data.attributes.startDate).format('DD/MM/YYYY'),
          endDate: moment(data.attributes.endDate).format('DD/MM/YYYY')
        })
      }
    })
  }

  editPromotion = () => {
    const { intl } = this.props
    const errors = this.validate(this.state.data)
    const name = this.props.userInfo && this.props.userInfo.displayName ? (this.props.userInfo.displayName).replace(/ /g, '_').toLowerCase() : ''

    this.setState({ errors })
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })

      const data = new FormData()
      data.append('id', this.props.id)
      this.state.imageFile && data.append('primary_image', this.state.imageFile)
      data.append('percent', this.state.data.percentDiscount)
      data.append('description', this.state.data.description)
      data.append('start_date', moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
      data.append('end_date', moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))

      this.props.editPromotion(data).then(response => {
        this.setState({
          loading: false,
          open: false
        })
        this.props.getAllPromotions()

        this.props.history.push(`/u/${name}/promotions`)
      }).catch(() => {
        errors.message = intl.formatMessage({ id: 'notification.please_try_again' })
        this.setState({
          loading: false,
          errors
        })
      })
    }
  }

  componentDidMount() {
    this.getPromotionDetail()
  }

  render() {
    const { data, errors, loading } = this.state

    const yesterday = Datetime.moment().subtract(1, 'day')
    const valid = (current) => {
      return current.isAfter(yesterday)
    }

    return (
      <Form onSubmit={this.editPromotion}>
        <Form.Field className='form-group'>
          <label htmlFor="email"><FormattedMessage id='promotion_banner' defaultMessage='Promotion Banner' /></label>
          <input
            type="file"
            id="image"
            name="image"
            placeholder=""
            className="form-control"
            value={data.image}
            onChange={this.onChangeAvatar}
          />
          {
            this.state.image &&
            <Pane className='slider-content' style={{ padding: '10px 0', overflow: 'hidden' }}>
              <Pane className='slide-item' style={{ float: 'left', padding: '0 5px 0 0' }}>
                <Icon name='remove circle' onClick={this.removeImage.bind(this)} />
                {/* <Image src={this.state.image} size='small' /> */}
                {
                  this.state.image.indexOf('video') < 0 ? // Image
                    <Image src={this.state.image} size="small" />
                    : // Video
                        <video width="150" controls >
                          <source src={this.state.image} id="video_here"/>
                            Your browser does not support HTML5 video.
                        </video>

                }
              </Pane>
            </Pane>
          }
        </Form.Field>
        <Pane className='clearfix' />
        <Form.Field error={!!errors.startDate} className='form-group'>
          <label htmlFor="openTime"><FormattedMessage id='start_date' defaultMessage='Start date' /></label>
          <Datetime
            closeOnSelect={true}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            onChange={this.onChangeTime.bind(this, 'startDate')}
            inputProps={{ 'placeholder': 'DD/MM/YYYY', 'name': 'startDate', readOnly: true }}
            isValidDate={valid}
            value={this.state.startDate}
          />
          { errors.startDate && <InlineMessage text={errors.startDate} /> }
        </Form.Field>
        <Form.Field error={!!errors.endDate} className='form-group'>
          <label htmlFor="closeTime"><FormattedMessage id='end_date' defaultMessage='End date' /></label>
          <Datetime
            closeOnSelect={true}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            onChange={this.onChangeTime.bind(this, 'endDate')}
            inputProps={{ 'placeholder': 'DD/MM/YYYY', 'name': 'endDate', readOnly: true }}
            isValidDate={valid}
            value={this.state.endDate}
          />
          { errors.endDate && <InlineMessage text={errors.endDate} /> }
        </Form.Field>
        <Form.Field error={!!errors.percentDiscount} className='form-group'>
          <label htmlFor="email"><FormattedMessage id='percent_discount' defaultMessage='Percent Discount' /> (%)</label>
          <input
            type="number"
            id="percentDiscount"
            name="percentDiscount"
            placeholder=""
            className="form-control"
            value={data.percentDiscount}
            onChange={this.onChange}
          />
          { errors.percentDiscount && <InlineMessage text={errors.percentDiscount} /> }
        </Form.Field>
        <Form.Field error={!!errors.description} className='form-group'>
          <label htmlFor="email"><FormattedMessage id='description' defaultMessage='Description' /></label>
          <input
            type="text"
            id="description"
            name="description"
            placeholder=""
            className="form-control"
            value={data.description}
            onChange={this.onChange}
          />
          { errors.description && <InlineMessage text={errors.description} /> }
        </Form.Field>
        { errors.message && <InlineMessage text={errors.message} /> }
        <Button type='submit' className='submit' color='yellow' disabled={loading} loading={loading} fluid>
          <FormattedMessage id='edit' defaultMessage='Edit' />
        </Button>
      </Form>
    )
  }
}

export default injectIntl(EditPromotionForm)
