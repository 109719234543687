import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import ListItem from './ListItem';

class ListProducts extends Component {
  static propTypes = {
    auth: PropTypes.object,
    products: PropTypes.array,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
  };

  render() {
    const list =
      this.props.products &&
      this.props.products.map((item) => {
        return (
          <ListItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.name}
            image={item.primaryImage ? item.primaryImage.url : ''}
            price={item.originalPrice}
            rating={item.rating}
            onAddItemToCart={this.props.onAddItemToCart}
            onAddItemToGuestCart={this.props.onAddItemToGuestCart}
            onNotification={this.props.onNotification}
            auth={this.props.auth}
          />
        );
      });

    return (
      <Pane className="category-list">
        <Pane className="adds-block">{list}</Pane>
      </Pane>
    );
  }
}

export default ListProducts;
