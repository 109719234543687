import * as types from 'constants/ActionTypes'
import api from 'api'

export const getAllProducts = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_ALL_PRODUCTS, types.RECEIVE_ALL_PRODUCTS_BY_FILTER_SUCCESS, types.RECEIVE_ALL_PRODUCTS_BY_FILTER_FAILURE],
  payload: {
    request: api.products.get(params)
  }
})

export const getProductsPromotion = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_ALL_PRODUCTS, types.RECEIVE_ALL_PRODUCTS_PROMOTIONS_BY_FILTER_SUCCESS, types.RECEIVE_ALL_PRODUCTS_PROMOTIONS_BY_FILTER_FAILURE],
  payload: {
    request: api.promotions.get(params)
  }
})

export const getProductsSlidePromotion = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_ALL_PRODUCTS, types.RECEIVE_ALL_PRODUCTS_PROMOTIONS_SLIDE_BY_FILTER_SUCCESS, types.RECEIVE_ALL_PRODUCTS_PROMOTIONS_SLIDE_BY_FILTER_FAILURE],
  payload: {
    request: api.promotions.getSlide(params)
  }
})

export const getProductDetail = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_ALL_PRODUCTS, types.RECEIVE_PRODUCT_DETAIL_SUCCESS, types.RECEIVE_PRODUCT_DETAIL_FAILURE],
  payload: {
    request: api.products.detail(params)
  }
})

export const reviewProduct = (params) => (dispatch) => dispatch({
  type: types.REVIEW_PRODUCT,
  payload: {
    request: api.products.review(params)
  }
})

export const optionsProduct = (params) => (dispatch) => dispatch({
  type: types.OPTIONS_PRODUCT,
  payload: {
    request: api.products.options(params)
  }
})

// Featured Products
export const getFeatureProducts = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_FEATURE_PRODUCTS, types.RECEIVE_FEATURE_PRODUCTS_SUCCESS, types.RECEIVE_FEATURE_PRODUCTS_FAILURE],
  payload: {
    request: api.products.featured(params)
  }
})

export const getAllProductPicks = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_ALL_PRODUCTS_PICKS, types.REQUEST_ALL_PRODUCTS_PICKS_SUCCESS, types.REQUEST_ALL_PRODUCTS_PICKS_FAILURE],
  payload: {
    request: api.products.browsePicks(params)
  }
})

export const getBusinessFoodOrder = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_PRODUCT_CATEGORIES_FOOD_ORDER, types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_SUCCESS, types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_FAILURE],
  payload: {
    request: api.user.getBusinessFoodOrder(params)
  }
})

export const getProductByBussiness = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_PRODUCT_CATEGORIES_FOOD_ORDER, types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_SUCCESS, types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_FAILURE],
  payload: {
    request: api.user.getProductByBussiness(params)
  }
})

export const getOptionsByProduct = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_OPTIONS, types.RECEIVE_OPTIONS_SUCCESS, types.RECEIVE_OPTIONS_FAILURE],
  payload: {
    request: api.products.options(params)
  }
})

