import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { MasterLayout } from 'components/layout'
import { getServiceDetail, book } from 'actions/services'
import { BookingPayment } from 'components/scenes/services/payment'
import { requestClientAuthorization } from 'actions/cart'
import { Pane } from 'shared/components/layouts'

class BookingPaymentPage extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    getServiceDetail: PropTypes.func,
    requestClientAuthorization: PropTypes.func,
    book: PropTypes.func
  }

  render() {
    return (
      <MasterLayout>
        <Pane className='main-container main-category'>
          <BookingPayment
            userInfo={this.props.userInfo}
            detail={this.props.serviceDetail}
            location={this.props.location}
            serviceId={this.props.match.params.id}
            onGetServiceDetail={this.props.getServiceDetail}
            onRequestClientAuthorization={this.props.requestClientAuthorization}
            onBook={this.props.book}
            history={this.props.history}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = state => ({
  userInfo: state.auth && state.auth.user,
  serviceDetail: state.serviceDetail.data
})

const bindDispatchToProps = dispatch => ({
  getServiceDetail: bindActionCreators(getServiceDetail, dispatch),
  requestClientAuthorization: bindActionCreators(requestClientAuthorization, dispatch),
  book: bindActionCreators(book, dispatch)
})

export default connect(bindStateToProps, bindDispatchToProps)(BookingPaymentPage)
