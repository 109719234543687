import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Pane } from 'shared/components/layouts';

class ListItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    html_code: PropTypes.string,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };

  render() {
    const { item } = this.props;
    return (
      <Pane className="col-lg-2 col-md-3 col-sm-4 col-6">
        <Pane className="adds-item">
          <Pane className="adds-content">
            <Pane
              dangerouslySetInnerHTML={{
                __html: item.htmlCode,
              }}
            />
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
