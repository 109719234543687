import PropTypes from 'prop-types';
import { ProductList } from './list';
import { get, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import { Empty } from 'components/common/empty';
import { Pane } from 'shared/components/layouts';
import userAdapter from 'services/users/adapter';
import { LoadingSearch } from 'components/common/loading';
import foodOrderAdapter from 'services/categories/adapter';

class Search extends Component {
  static propTypes = {
    auth: PropTypes.object,
    type: PropTypes.string,
    query: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      results: [],
      totalPages: 0,
      totalItems: 0,
      loadingMore: false,
      hasMoreItems: true,
      category: {},
    };
  }

  componentDidMount() {
    this.onLoadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match !== prevProps.match) {
      this.onLoadData();
    }
  }

  onLoadData = () => {
    const {
      match: { params },
    } = this.props;
    if (!get(params, 'type')) {
      const data = {
        parent_id: null,
      };
      this.setState({
        loading: true,
        results: [],
      });
      this.props.getFoodOrderCategories(data).then((response) => {
        this.setState({
          results: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
            response.data
          ),
          totalPages: response.data.meta.totalPages,
          totalItems: response.data.meta.totalItems,
          loading: false,
        });
      });
    }
    if (get(params, 'type') && !get(params, 'id')) {
      const data = {
        parent_id: get(params, 'type'),
      };
      this.setState({
        loading: true,
        results: [],
      });
      this.props
        .getFoodOrderCategories(data)
        .then((response) => {
          this.setState({
            results: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
              response.data
            ),
            totalPages: response.data.meta.totalPages,
            totalItems: response.data.meta.totalItems,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    }
    if (get(params, 'type') && get(params, 'id') && !get(params, 'idChidren')) {
      const data = {
        categoryId: get(params, 'id'),
      };
      this.setState({
        loading: true,
        results: [],
      });
      this.props
        .getDetailCategories({ id: get(params, 'id') })
        .then((response) => {
          this.setState({
            category: foodOrderAdapter.detailCategory.parseResponse(
              response.data
            ),
          });
        });
      this.props
        .getBusinessFoodOrder(data)
        .then((response) => {
          this.setState({
            results: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
              response.data
            ),
            totalPages: response.data.meta.totalPages,
            totalItems: response.data.meta.totalItems,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    }
    if (get(params, 'type') && get(params, 'id') && get(params, 'idChidren')) {
      this.setState({
        loading: true,
        results: [],
      });
      this.props
        .getDetailCategories({ id: get(params, 'id') })
        .then((response) => {
          this.setState({
            category: foodOrderAdapter.detailCategory.parseResponse(
              response.data
            ),
          });
        });
      this.props
        .getProductByBussiness({ id: get(params, 'idChidren') })
        .then((response) => {
          this.setState({
            results: userAdapter.getProductByBussiness.parseResponse(
              response.data
            ),
            totalPages: response.data.meta.totalPages,
            totalItems: response.data.meta.totalItems,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    }
  };

  getData = (data) => {
    if (!isEmpty(data)) {
      return data;
    }
    return [];
  };

  renderBreadCrumb = () => {
    const {
      match: { params },
    } = this.props;
    const { results, loading, category } = this.state;
    if (!get(params, 'type')) {
      return (
        <Breadcrumb className="breadcrumb-list">
          <Breadcrumb.Section className="item">Food Order</Breadcrumb.Section>
        </Breadcrumb>
      );
    }
    if (get(params, 'type') && !get(params, 'id')) {
      return (
        <Breadcrumb className="breadcrumb-list">
          <Breadcrumb.Section className="item">
            <Link to="/food-order" className="item">
              Food Order
            </Link>
          </Breadcrumb.Section>
          {!loading ? (
            <Breadcrumb.Section className="item">
              {results.length ? get(results[0], 'parent.name') : ''}
            </Breadcrumb.Section>
          ) : null}
        </Breadcrumb>
      );
    }
    if (get(params, 'type') && get(params, 'id')) {
      return (
        <Breadcrumb className="breadcrumb-list">
          <Breadcrumb.Section className="item">
            <Link to="/food-order" className="item">
              Food Order
            </Link>
          </Breadcrumb.Section>
          {!loading ? (
            <>
              <Breadcrumb.Section className="item">
                <Link
                  to={`/food-order/${get(category, 'parent.id') || ''}`}
                  className="item"
                >
                  {get(category, 'parent.name') || ''}
                </Link>
              </Breadcrumb.Section>
              <Breadcrumb.Section className="item">
                {get(category, 'name') || ''}
              </Breadcrumb.Section>
            </>
          ) : null}
        </Breadcrumb>
      );
    }
    return (
      <Breadcrumb className="breadcrumb-list">
        <Breadcrumb.Section className="item" href={`/food-order`}>
          Food Order
        </Breadcrumb.Section>
        <Breadcrumb.Section className="item active" active>
          <span className="item-norm">Fresh Food</span>
        </Breadcrumb.Section>
      </Breadcrumb>
    );
  };

  render() {
    const { results, loading } = this.state;
    return (
      <Pane className="container">
        <Pane className="row">
          <Pane className="col-lg-12">
            <div className="tab-food">
              <Pane className="breadcrumb-container">
                <Pane className="row">
                  <Pane className="col-lg-12">{this.renderBreadCrumb()}</Pane>
                </Pane>
              </Pane>
            </div>
            {loading ? <LoadingSearch type="white" /> : null}
            {!isEmpty(results) ? (
              <ProductList
                products={this.getData(results)}
                onAddItemToCart={this.props.addItemToCart}
                auth={this.props.auth}
              />
            ) : null}
            {!loading && isEmpty(results) ? <Empty /> : null}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(Search);
