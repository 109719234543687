import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Pane } from 'shared/components/layouts';

class Video extends Component {
  render() {
    const detail = this.props.detail;
    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const extprimaryImage =
      detail && detail.primaryImage !== null
        ? detail.primaryImage.substr(detail.primaryImage.lastIndexOf('.') + 1)
        : '';
    const extsubImage1 =
      detail && detail.subImage1 !== null
        ? detail.subImage1.substr(detail.subImage1.lastIndexOf('.') + 1)
        : '';
    const extsubImage2 =
      detail && detail.subImage2 !== null
        ? detail.subImage2.substr(detail.subImage2.lastIndexOf('.') + 1)
        : '';
    const extsubImage3 =
      detail && detail.subImage3 !== null
        ? detail.subImage3.substr(detail.subImage3.lastIndexOf('.') + 1)
        : '';
    const extsubImage4 =
      detail && detail.subImage4 !== null
        ? detail.subImage4.substr(detail.subImage4.lastIndexOf('.') + 1)
        : '';
    const extsubImage5 =
      detail && detail.subImage5 !== null
        ? detail.subImage5.substr(detail.subImage5.lastIndexOf('.') + 1)
        : '';
    return (
      <Pane className="tab-videos">
        {extprimaryImage !== '' && extImage.indexOf(extprimaryImage) < 0 ? (
          <video className="carbeyvideo" controls>
            <source src={detail.primaryImage} id="video_here" />
            Your browser does not support HTML5 video.
          </video>
        ) : (
          ''
        )}
        {extsubImage1 !== '' && extImage.indexOf(extsubImage1) < 0 ? (
          <video className="carbeyvideo" controls>
            <source src={detail.subImage1} id="video_here" />
            Your browser does not support HTML5 video.
          </video>
        ) : (
          ''
        )}
        {extsubImage2 !== '' && extImage.indexOf(extsubImage2) < 0 ? (
          <video className="carbeyvideo" controls>
            <source src={detail.subImage2} id="video_here" />
            Your browser does not support HTML5 video.
          </video>
        ) : (
          ''
        )}
        {extsubImage3 !== '' && extImage.indexOf(extsubImage3) < 0 ? (
          <video className="carbeyvideo" controls>
            <source src={detail.extsubImage3} id="video_here" />
            Your browser does not support HTML5 video.
          </video>
        ) : (
          ''
        )}
        {extsubImage4 !== '' && extImage.indexOf(extsubImage4) < 0 ? (
          <video className="carbeyvideo" controls>
            <source src={detail.extsubImage4} id="video_here" />
            Your browser does not support HTML5 video.
          </video>
        ) : (
          ''
        )}
        {extsubImage5 !== '' && extImage.indexOf(extsubImage5) < 0 ? (
          <video className="carbeyvideo" controls>
            <source src={detail.extsubImage5} id="video_here" />
            Your browser does not support HTML5 video.
          </video>
        ) : (
          ''
        )}
      </Pane>
    );
  }
}

Video.propTypes = {};

export default injectIntl(Video);
