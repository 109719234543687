import React from "react";
import { POS } from 'components/scenes/user/pos';
import { Wrapper, Pane } from "shared/components/layouts";

class POSPage extends React.Component {

	render() {
		return (
			<Pane className="pos">
				<POS />
			</Pane>
		);
	}
}


export default POSPage;
