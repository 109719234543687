import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Config from "constants/Config";
import ReactTooltip from "react-tooltip";
import { withRouter, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Table, Icon } from 'semantic-ui-react';
import { isEmpty, get } from "lodash";
import Currency from 'shared/components/Currency';

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      extVideo: Config.extVideo
    };
  }

  render() {
    const { item, type } = this.props;
    let image = item.imageHorizontal && item.imageHorizontal.url ? item.imageHorizontal.url : (item.imageVertical && item.imageVertical.url ? item.imageVertical.url : `${process.env.PUBLIC_URL}/assets/images/default-image.png`);
    let isVideo = image && !isEmpty(image) && this.state.extVideo.includes(image.substr(image.lastIndexOf(".") + 1))
    return (
      <Table.Row key={item.id}>
        <Table.Cell>{item.screen.name}</Table.Cell>
        <Table.Cell><Currency price={(item.screen.price)} /> /play <br /></Table.Cell>
        <Table.Cell>
          <Link target="_blank" to={`/${item.host.id}`}>{item.host.business.name}</Link>
          <Icon
            name="info circle"
            data-tip
            data-for={`item-info${item.id}`}
          />
          <ReactTooltip
            place="top"
            type="dark"
            effect="float"
            id={`item-info${item.id}`}
          >
            {item.host.business.email} - {`${item.host.business.address} ${item.host.business.address && item.host.business.postcode
              ? `, ${item.host.business.postcode}`
              : item.host.business.postcode
              }`}
          </ReactTooltip>
        </Table.Cell>
        <Table.Cell>
          {isVideo ? (
            <video controls playsInline allowFullScreen={false} width="100%" height="100">
              <source src={image} id="video_here" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          ) : (
            <img src={image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src=process.env.PUBLIC_URL + `/assets/images/default-image.png`;
            }}/>
          )}
        </Table.Cell>
        <Table.Cell>
          {item.adminActiveFlg && item.status ? (
            <Link target="_blank" to={`/slideshow?screen_id=${item.screen.url}`}>View my file share</Link>
          ) : (
            <span>After being approved by the host you will be able to view your file share</span>
          )}
        </Table.Cell>
        <Table.Cell>
          {item.adminActiveFlg && item.status ? 'Approved' : 'Waiting for Approval'}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default withRouter(injectIntl(ListItem));
