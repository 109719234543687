export default {
  parseResponse: response => {
    response.items.forEach(item => {
      item.productId = item.id
    })

    const data = {
      ...response
    }

    return data
  }
}
