import Config from 'constants/Config'

const exec = params => {
  let from_store = !!params.from_store;
  let date = params.date ? `?date_from=${params.date}` : '';
  let duration = params.duration ? `&duration=${params.duration}` : '';
  return {
    method: 'GET',
    url: `/services/${params.businessId}/${params.staffId}/${from_store ? 'timeslots' : 'slots'}/available${date}${duration}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
