import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Radio, Button, Icon } from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import { InlineMessage } from "shared/components";
import Config from "constants/Config";
import { Currency } from "shared/components";
import { handleError } from "shared/helpers";
import { StripeProvider, Elements } from "react-stripe-elements";
import PaymentForm from "components/scenes/cart/payment/PaymentForm";
import NotificationSystem from "react-notification-system";

class BookingPayment extends Component {
  static propTypes = {
    auth: PropTypes.object,
    getServiceDetail: PropTypes.func,
    getTimeSlots: PropTypes.func,
    book: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    userInfo: {},
    serviceId: "",
    date: "",
    items: [],
    note: "",
    detail: {},
    paymentMethod: Config.PAYMENT_METHOD.PAY_BY_CREDIT,
    orderId: "",
    cardNumber: "",
    cardFirstName: "",
    cardLastName: "",
    cardType: Config.CARD_TYPES.MASTER_CARD,
    cardExpireMonth: "",
    cardExpireYear: "",
    cvv: "",
    cardExpiry: "",
    errors: {},
    loading: false,
    memberServices: [],
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  isOptions = () => {
    if (!this.props.detail.options) return false;
    if (!this.props.detail.options.length) return false;

    if (!this.state.optionIds) return false;
    if (!this.state.optionIds.length) return false;

    return true;
  };

  getSelectedOptions = () => {
    if (!this.isOptions()) return [];

    const { options } = this.props.detail;
    let selectdOptions = [];

    options.map((option) => {
      const { childrens } = option;
      childrens.forEach((child) => {
        if (this.includesOption(child.id)) {
          selectdOptions.push({
            ...child,
            quantity: this.includesOption(child.id).quantity,
          });
        }
      });
    });
    return selectdOptions;
  };

  mappingSelectOptionToText = () => {
    const selectedOptions = this.getSelectedOptions();
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${child.name
          }`
      )
      .join(", ");
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeRadio = (e, { value }) => this.setState({ paymentMethod: value });

  getServiceDetail = (id) =>
    this.props.onGetServiceDetail({ id }).then((response) => {
      this.setState({ detail: response.data });
    });

  handleBooking = (token) => {
    const { intl } = this.props;
    const bookingTime = [];

    this.state.items.forEach((item) => {
      bookingTime.push({
        from_time: item.from,
        to_time: item.to,
      });
    });

    const data = {
      service_id: this.state.serviceId,
      date: this.state.date,
      note: this.state.note,
      payment_method: this.state.paymentMethod,
      items: bookingTime,
      members: this.state.memberServices || []
    };

    if (this.state.optionIds && this.state.optionIds.length) {
      data.option_info = JSON.stringify(this.state.optionIds);
    }

    if (token) {
      data.token_stripe = token;
    }

    this.setState({ loadingBooking: true });
    this.props
      .onBook(data)
      .then((response) => {
        this.setState({ loadingBooking: false });
        if (this.props.history) {
          this.props.history.push(
            `/booking-success?orderId=${response.data.data.id}`
          );
        } else {
          window.location = `/booking-success?orderId=${response.data.data.id}`;
        }
      })
      .catch((errors) => {
        this.setState({ loadingBooking: false });

        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  getTotalPriceOptions = () => {
    let totalPirce = 0;

    if (
      !this.props.detail ||
      !this.props.detail.options ||
      !this.props.detail.options.length
    )
      return 0;

    this.props.detail.options.forEach((option) => {
      const { childrens } = option;

      childrens.forEach((children) => {
        const childIncludes = this.includesOption(children.id);
        if (childIncludes) {
          totalPirce +=
            Number(children.price || 0) * Number(childIncludes.quantity);
        }
      });
    });
    return totalPirce;
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    const cardElementContainer = document.getElementsByClassName(
      "StripeElement"
    )[0];
    if (
      cardElementContainer &&
      this.state.paymentMethod === Config.PAYMENT_METHOD.PAY_BY_CREDIT
    ) {
      let cardElementEmpty = cardElementContainer.classList.contains(
        "StripeElement--empty"
      );
      let cardElementInvalid = cardElementContainer.classList.contains(
        "StripeElement--invalid"
      );
      if (cardElementInvalid || cardElementEmpty) {
        errors.creditCard = intl.formatMessage({
          id: "validate.invalid_credit_info",
        });
      }
    }

    if (!data.paymentMethod) {
      errors.paymentMethod = intl.formatMessage({
        id: "validate.please_select_a_payment_method",
      });
    }

    return errors;
  };

  checkout = () => {
    const errors = this.validate(this.state) || {};

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (this.state.paymentMethod === Config.PAYMENT_METHOD.PAY_BY_CASH) {
        this.handleBooking();
      } else {
        this.createCreditCardForm();
      }
    }
  };

  createCreditCardForm = async () => {
    const { intl } = this.props;

    try {
      const { token } = await this.requestPaymentToken();
      if (token && token.id) {
        this.handleBooking(token.id);
      }
    } catch (e) {
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: "notification.please_try_again" }),
        level: "error",
      });
    }
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
    const params = new URLSearchParams(this.props.location.search);

    this.setState(
      {
        serviceId: params.get("serviceId"),
        date: params.get("date"),
        items: JSON.parse(params.get("items")),
        note: params.get("note"),
        optionIds: JSON.parse(params.get("optionIds")) || [],
        memberServices: JSON.parse(params.get("staffs")) || [],
      },
      () => {
        this.getServiceDetail(this.state.serviceId);
      }
    );
  }

  includesOption = (id) => {
    if (!this.state.optionIds) return null;
    return this.state.optionIds.find((child) => child.id === id);
  };

  render() {
    const { userInfo, detail, intl } = this.props;
    const { errors, serviceId } = this.state;

    return (
      <>
        <Pane className="checkout-container pt-5 pb-5">
          <Pane className="container">
            <Pane className="row mb-4">
              <Pane className="col-lg-5 col-12 mt-lg-0 mt-3">
                <Link
                  to={`/booking/${serviceId}?optionIds=${JSON.stringify(
                    this.state.optionIds
                  )}`}
                  className="btn btn-process back_to_booking"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/shopnow/booking.png`
                    }
                    alt="lock"
                    className="icon"
                  />
                  <FormattedMessage
                    id="cart.back_to_booking"
                    defaultMessage="Back to booking"
                  />
                </Link>
              </Pane>
            </Pane>
            <Pane className="row">
              <Pane className="col-lg-5">
                <Pane className="checkout-content">
                  <Pane className="checkout-info">
                    <h2 className="checkout-title mb-2">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/cart/user.svg`
                        }
                        onChange={this.onChangeRadio}
                      />
                      <FormattedMessage
                        id="customer_information"
                        defaultMessage="Customer Information"
                      />
                    </h2>
                    <p className="check-label">
                      <FormattedMessage
                        id="full_name"
                        defaultMessage="Full name"
                      />
                      :{" "}
                      <span className="check-name">{userInfo.displayName}</span>
                    </p>
                    <p className="check-label">
                      <FormattedMessage id="email" defaultMessage="Email" />:
                      <span className="check-name">{userInfo.email}</span>
                    </p>
                    <p className="check-label">
                      <FormattedMessage id="phone" defaultMessage="Phone" />:
                      <span className="check-name">{userInfo.phoneNumber}</span>
                    </p>
                    <p className="check-label">
                      <FormattedMessage id="address" defaultMessage="Address" />
                      :<span className="check-name">{userInfo.address}</span>
                    </p>
                    <p className="check-label">
                      <FormattedMessage
                        id="post_code"
                        defaultMessage="Post code"
                      />
                      :<span className="check-name">{userInfo.postCode}</span>
                    </p>
                    <h2 className="checkout-title">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/cart/payment.svg`
                        }
                        alt="users"
                        className="icon"
                      />
                      <FormattedMessage
                        id="payment_method"
                        defaultMessage="PAYMENT METHOD"
                      />
                    </h2>
                    <Pane className="choose-payment">
                      <Form.Field className="choose-item mr-3">
                        <Radio
                          label={intl.formatMessage({ id: "pay_at_store" })}
                          name="paymentMethod"
                          className="choose-radio"
                          value={Config.PAYMENT_METHOD.PAY_BY_CASH}
                          checked={
                            this.state.paymentMethod ===
                            Config.PAYMENT_METHOD.PAY_BY_CASH
                          }
                          onChange={this.onChangeRadio}
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL + `/assets/images/cash.png`
                          }
                          alt="users"
                          className="icon"
                        />
                      </Form.Field>
                      <Form.Field className="choose-item">
                        <Radio
                          label={intl.formatMessage({ id: "pay_by_credit" })}
                          name="paymentMethod"
                          className="choose-radio"
                          value={Config.PAYMENT_METHOD.PAY_BY_CREDIT}
                          checked={
                            this.state.paymentMethod ===
                            Config.PAYMENT_METHOD.PAY_BY_CREDIT
                          }
                          onChange={this.onChangeRadio}
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/cart/credit.png`
                          }
                          alt="users"
                          className="icon"
                        />
                      </Form.Field>
                      <Pane className="clearfix pb10" />
                    </Pane>
                    {errors.paymentMethod && (
                      <InlineMessage text={errors.paymentMethod} />
                    )}
                  </Pane>
                  {this.state.paymentMethod ===
                    Config.PAYMENT_METHOD.PAY_BY_CREDIT && (
                      <Pane className="checkout-info">
                        <h2 className="checkout-title">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/cart/payment.svg`
                            }
                            alt="users"
                            className="icon"
                          />
                          <FormattedMessage
                            id="payment_information"
                            defaultMessage="Payment information"
                          />
                        </h2>
                        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                          <Elements>
                            <PaymentForm
                              requestPaymentToken={(request) =>
                                (this.requestPaymentToken = request)
                              }
                            />
                          </Elements>
                        </StripeProvider>
                        {errors.creditCard && (
                          <InlineMessage text={errors.creditCard} />
                        )}
                      </Pane>
                    )}
                </Pane>
              </Pane>
              <Pane className="col-lg-7 mt-lg-0 mt-3">
                <Pane className="cart-table cart-service">
                  <h2 className="cart-title mb-3">
                    <FormattedMessage
                      id="booking_information"
                      defaultMessage="Booking information"
                    />
                  </h2>
                  <Pane className="service-content">
                    <Pane className="row pl-2 pr-2">
                      <Pane className="col-lg-2">
                        <Pane className="image-service">
                          <img
                            src={detail.primaryImage}
                            alt={detail.name}
                            className="img-serivce"
                          />
                        </Pane>
                      </Pane>
                      <Pane className="col-lg-10">
                        <Pane className="service-info pr-2 pl-2">
                          <Pane className="item d-flex justify-content-between align-items-center pt-1">
                            <h2 className="service-title">Service NAME</h2>
                            <span className="service-norm">
                              <strong>{detail.name}</strong>
                            </span>
                          </Pane>
                          {this.isOptions() ? (
                            <Pane className="item d-flex justify-content-between align-items-center pt-1">
                              <h2 className="service-title">Service OPTION</h2>
                              <span className="service-norm">
                                <strong>
                                  {this.mappingSelectOptionToText()}
                                </strong>
                              </span>
                            </Pane>
                          ) : null}
                          <Pane className="item d-flex justify-content-between align-items-center pt-1">
                            <h2 className="service-title">Booking date</h2>
                            <span className="service-norm">
                              <strong>{this.state.date}</strong>
                            </span>
                          </Pane>
                          <Pane className="item d-flex justify-content-between align-items-center pt-1">
                            <h2 className="service-title">Booking time</h2>
                            <span className="service-norm">
                              <strong>
                                {this.state.items &&
                                  this.state.items.map((item, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className="pl10"
                                        style={{ display: "block" }}
                                      >
                                        <i className="icon-clock mr5" />
                                        {item.from} - {item.to}
                                      </span>
                                    );
                                  })}
                              </strong>
                            </span>
                          </Pane>
                          <Pane className="item d-flex justify-content-between align-items-center pt-1">
                            <h2 className="service-title">Staff Booking</h2>
                            <span className="service-norm">
                              <strong>
                                {this.state.memberServices &&
                                  this.state.memberServices.map(
                                    (item, index) => {
                                      return (
                                        <span
                                          key={index}
                                          className="pl10"
                                          style={{ display: "block" }}
                                        >
                                          <i className="check mr5" />
                                          {item.fullname}
                                        </span>
                                      );
                                    }
                                  )}
                              </strong>
                            </span>
                          </Pane>
                          <Pane className="item d-flex justify-content-between align-items-center pt-1">
                            <h2 className="service-title">Notes</h2>
                            <span className="service-norm">
                              <strong>{this.state.note}</strong>
                            </span>
                          </Pane>
                          <Pane className="item d-flex justify-content-between align-items-center pt-1">
                            <h2 className="service-title">Unit price</h2>
                            <span className="service-norm">
                              <strong>
                                {detail.promotion &&
                                  detail.promotion.percentDiscount ? (
                                  <span>
                                    <Currency
                                      price={
                                        detail.originalPrice -
                                        (detail.originalPrice *
                                          detail.promotion.percentDiscount) /
                                        100
                                      }
                                    />
                                    -
                                    <span className="text-red text-underline">
                                      <Currency price={detail.originalPrice} />
                                    </span>
                                  </span>
                                ) : (
                                  <span>
                                    <Currency price={detail.originalPrice} />
                                  </span>
                                )}
                              </strong>
                            </span>
                          </Pane>
                          <Pane className="item d-flex justify-content-between align-items-center pt-1">
                            <h2 className="service-title">Total price</h2>
                            <span className="service-norm">
                              <strong>
                                {detail.promotion &&
                                  detail.promotion.percentDiscount ? (
                                  <Currency
                                    price={
                                      (detail.originalPrice -
                                        (detail.originalPrice *
                                          detail.promotion.percentDiscount) /
                                        100) *
                                      this.state.items.length
                                    }
                                  />
                                ) : (
                                  <Currency
                                    price={
                                      detail.originalPrice *
                                      this.state.items.length
                                    }
                                  />
                                )}
                              </strong>
                            </span>
                          </Pane>
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>

                <Pane className="process-checkout mt-3">
                  <Pane className="row">
                    <Pane className="col-lg-6 text-left">
                      <h3 className="text-total">Total</h3>
                      <p className="text-norm">Includes GST Amount:</p>
                    </Pane>
                    <Pane className="col-lg-6 text-right">
                      <h3 className="text-total text-price">
                        {detail.promotion &&
                          detail.promotion.percentDiscount ? (
                          <Currency
                            price={
                              (detail.originalPrice -
                                (detail.originalPrice *
                                  detail.promotion.percentDiscount) /
                                100 +
                                this.getTotalPriceOptions()) *
                              this.state.items.length
                            }
                          />
                        ) : (
                          <Currency
                            price={
                              (Number(detail.originalPrice || 0) +
                                this.getTotalPriceOptions()) *
                              this.state.items.length
                            }
                          />
                        )}
                      </h3>
                      <p className="text-norm">Included</p>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12 text-right">
                    <Button
                      color="yellow"
                      className="btn btn-process btn-process-yellow mt-5"
                      id="payment-button"
                      loading={this.state.loadingBooking}
                      disabled={this.state.loadingBooking}
                      onClick={this.checkout}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/cart/lock.svg`
                        }
                        alt="lock"
                        className="icon"
                      />
                      PROCEED TO CHECK OUT
                    </Button>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
          <NotificationSystem ref="notificationSystem" />
        </Pane>
      </>
    );
  }
}

export default withRouter(injectIntl(BookingPayment));
