import * as types from 'constants/ActionTypes'
import api from 'api'

// My slides
export const getMySlides = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_SLIDES, types.RECEIVE_MY_SLIDES_SUCCESS, types.RECEIVE_MY_SLIDES_FAILURE],
  payload: {
    request: api.user.slides(params)
  }
})
