import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Form } from 'semantic-ui-react';
import { Pane, Loading } from 'shared/components/layouts';
import TimeSlotItem from './TimeSlotItem';

const TimeSlots = ({ staff, timeSlots, loading, handleSubmit }) => {
  return (
    <Pane
      className={`time-slot-content ${timeSlots.length > 10 ? `col-sm-12 f-two-column` : `col-sm-12`
        }`}
      style={{
        position: 'relative',
      }}
    >
      <Form>

        {loading && (
          <Dimmer active inverted>
            <Loading />
          </Dimmer>
        )}
        {!loading && timeSlots.length > 0 && (
          timeSlots.map((item, index) => {
            return (
              <TimeSlotItem
                key={index}
                index={index}
                fromTime={item.from}
                toTime={item.to}
                available={true}
                handleSubmit={handleSubmit}
              />
            );
          })
        )}
        {!loading && timeSlots.length === 0 && (
          <Pane className="timeslots-full">
            <img src={
              process.env.PUBLIC_URL +
              `/assets/images/calendar-full.png`
            } alt="icon admin" />
            <Pane className="text-center">
              <strong>{staff ? staff.fullname : `We're`} fully booked today</strong>
            </Pane>
            <Pane className="text-center">
              Please book another date, Thanks!
            </Pane>
          </Pane>
        )}
      </Form>
    </Pane>
  );
};

TimeSlots.propTypes = {
  timeSlots: PropTypes.array,
  loading: PropTypes.bool,
  onChangeTimeSlot: PropTypes.func,
};

TimeSlots.defaultProps = {
  timeSlots: [],
  loading: false,
};

export default TimeSlots;
