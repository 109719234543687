import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import 'react-tabs/style/react-tabs.css';
import Slider from 'react-slick';
import { Pane } from 'shared/components/layouts';

class Testimonials extends Component {
  renderListTestimonials() {
    const results = [];
    for (let i = 0; i < 2; i++) {
      results.push(
        <Pane key={i}>
          <Pane className="row">
            <Pane className="col-lg-4 col-md-4">
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/home/testermonial.png'
                }
                alt="testermonial"
                className="image testimontals-img"
              />
            </Pane>
            <Pane className="col-lg-8 col-md-8 d-flex align-items-center">
              <Pane className="testimontals-content">
                <h2 className="testimontals-title">
                  <q>
                    Cardbey is simple and user friendly. I have recommended many
                    of my friends and family.Well done and keep up the good work
                  </q>
                </h2>
                <Pane className="testimontals-info">
                  <h2 className="testimontals-name">Indriana Melly</h2>
                  <p className="testimontals-norm">EKAMIRA autobus</p>
                  <p className="testimontals-norm">company</p>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      );
    }
    return results;
  }
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // initialSlide: 2
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Pane className="testimontals-container">
        <Pane className="container">
          <Slider className="testimontals-slider" {...settings}>
            {this.renderListTestimonials()}
          </Slider>
        </Pane>
      </Pane>
    );
  }
}
export default injectIntl(Testimonials);
