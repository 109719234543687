import * as CartBrowseV1 from './cart.browse.v1.action'
import * as CartUpdateV1 from './cart.update.v1.action'
import * as CartAddV1 from './cart.add.v1.action'
import * as CartCreateV1 from './cart.create.v1.action'
import * as CartEditQuantityV1 from './cart.edit-quantity.v1.action'
import * as CartEditMethodV1 from './cart.edit-method.v1.action'
import * as CartRemoveV1 from './cart.remove.v1.action'
import * as CartCheckoutV1 from './cart.checkout.v1.action'
import * as CartCheckoutPOSV1 from './cart.checkout-pos.action'
import * as CartPaymentV1 from './cart.payment.v1.action'
import * as OrderKcater from './order.kcater.action';
import * as CartEditOptionInfoV1 from './cart.edit-option-info.v1.action';

export default {
  // Get cart
  browse: {
    callAPI: params => CartBrowseV1.exec(params),
    parseResponse: response => CartBrowseV1.parseResponse(response)
  },
  // Update cart
  update: {
    callAPI: params => CartUpdateV1.exec(params),
    parseResponse: response => CartUpdateV1.parseResponse(response)
  },
  // Add item to cart
  add: {
    callAPI: params => CartAddV1.exec(params),
    parseResponse: response => CartAddV1.parseResponse(response)
  },
  // Create user cart
  create: {
    callAPI: params => CartCreateV1.exec(params),
    parseResponse: response => CartCreateV1.parseResponse(response)
  },
  editOptionInfo: {
    callAPI: params => CartEditOptionInfoV1.exec(params),
    parseResponse: response => CartEditOptionInfoV1.parseResponse(response)
  },
  // Update quantity
  editQuantity: {
    callAPI: params => CartEditQuantityV1.exec(params),
    parseResponse: response => CartEditQuantityV1.parseResponse(response)
  },
  // Update Method
  editMethod: {
    callAPI: params => CartEditMethodV1.exec(params),
    parseResponse: response => CartEditMethodV1.parseResponse(response)
  },
  // Remove item to cart
  remove: {
    callAPI: params => CartRemoveV1.exec(params),
    parseResponse: response => CartRemoveV1.parseResponse(response)
  },
  // Checkout
  checkout: {
    callAPI: params => CartCheckoutV1.exec(params),
    parseResponse: response => CartCheckoutV1.parseResponse(response)
  },
  // Payment
  payment: {
    callAPI: params => CartPaymentV1.exec(params),
    parseResponse: response => CartPaymentV1.parseResponse(response)
  },
  // Payment
  order: {
    callAPI: params => OrderKcater.exec(params),
    parseResponse: response => OrderKcater.parseResponse(response)
  },
  // Checkout
  checkoutPOS: {
    callAPI: params => CartCheckoutPOSV1.exec(params),
    parseResponse: response => CartCheckoutPOSV1.parseResponse(response)
  },
}
