import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Route from 'react-router/Route';
import withRouter from 'react-router/withRouter';
import { Redirect, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import i18n from 'lang';
import { Pane } from 'shared/components/layouts';

const RenderRoutes = ({ routes, auth, lang, storeInfo = {}, cartModal = {}, serviceModal = {}, methodModal = {} }) => {
  if (!routes) {
    return null;
  }

  routes.forEach((route) => {
    if (!route.requireLogin || !route.routes) {
      return;
    }
    const requireLogin = route.requireLogin;
    route.routes.forEach((route) => (route.requireLogin = requireLogin));
  });
  return (
    <IntlProvider locale={lang} messages={i18n[lang]}>
      <Switch>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={(props) => {
              if (route.requireLogin && !auth.isAuthenticated) {
                return (
                  <Redirect
                    to={{
                      pathname: route.requireLogin,
                      state: { from: route.path },
                    }}
                  />
                );
              }
              if (auth && auth.isAuthenticated && route.type) {
                return (
                  <Redirect
                    to={{
                      pathname: '/',
                      state: { from: route.path },
                    }}
                  />
                );
              }
              return (
                <Pane>
                  <route.component {...props} route={route} storeInfo={storeInfo} cartModal={cartModal} serviceModal={serviceModal} methodModal={methodModal} />
                </Pane>
              );
            }}
          />
        ))}
      </Switch>
    </IntlProvider>
  );
};

RenderRoutes.propTypes = {
  routes: PropTypes.array.isRequired,
  parent: PropTypes.array,
  auth: PropTypes.object,
  lang: PropTypes.string,
};

const bindStateToProps = (state) => ({
  auth: state.auth,
  lang: state.locale.lang,
});

export default withRouter(
  connect(
    bindStateToProps,
    null
  )(RenderRoutes)
);
