import React from 'react';
import PropTypes from 'prop-types';

const TradingHourItem = ({ from, to, off }) => {
  return (
    <li className="bussiness-item business-trading">
      {/* <span className="category-norm">{day}</span> */}
      <span className="category-norm">
        {from} - {to}
      </span>
      <span className="category-norm" style={{ color: 'red' }}>
        {off}
      </span>
    </li>
  );
};

TradingHourItem.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  off: PropTypes.string,
};

TradingHourItem.defaultProps = {
  from: '',
  to: '',
  off: '',
};

export default TradingHourItem;
