const exec = (params) => {
  return {
    method: 'POST',
    url: `/api/v1/orders/create`,
    baseURL: process.env.REACT_APP_KCATER,
    data: params,
    headers: {
      clientId: process.env.REACT_APP_CLIENTID_KCATER,
      clientSecret: process.env.REACT_APP_CLIENTSECRET_KCATER,
      'access-token': process.env.REACT_APP_ACCESS_TOKEN_KCATER,
      isKcater: true
    },
  };
};

const parseResponse = (response) => {
  const data = {
    ...response,
  };

  return data;
};

export { exec, parseResponse };
