import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';

class FeatureProductItem extends Component {
  static propTypes = {
    items: PropTypes.array,
  };

  renderItem(index) {
    if (this.props.items && this.props.items.length > index) {
      return (
        <Link
          className="inner-link"
          to={`/products/${this.props.items[index].slug}`}
        >
          <img
            style={{ objectFit: 'cover' }}
            src={this.props.items[index].image}
            alt="feature1"
            className="inner-img"
          />
        </Link>
      );
    }
    return '';
  }

  render() {
    return (
      <Pane className="slider-item">
        <Pane className="inner">{this.renderItem(0)}</Pane>
        <Pane className="inner-row">
          <Pane className="inner-children">{this.renderItem(1)}</Pane>
          <Pane className="inner-children">{this.renderItem(2)}</Pane>
        </Pane>
      </Pane>
    );
  }
}

export default FeatureProductItem;
