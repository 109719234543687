import Video from "./Video";
import { get } from "lodash";
import Detail from "./Detail";
import Reviews from "./Reviews";
import Delivery from "./Delivery";
import PropTypes from "prop-types";
import SlideImage from "./SlideImage";
import Config from "constants/Config";
import NewsLetter from "./NewsLetter";
import BestMatched from "./BestMatched";
import CartOption from "./CartOption";
import React, { Component } from "react";
import { Currency } from "shared/components";
import { withRouter } from "react-router-dom";
import { StoreInfo } from "shared/components";
import { Container } from "shared/components";
import { formatDecimal } from "shared/helpers";
import { Pane, Loading } from "shared/components/layouts";
import NotificationSystem from "react-notification-system";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import queryString from "query-string";
import { Breadcrumb, Tab, Checkbox, Form } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import { LoginModal, StockDetailLabel } from "shared/components";
import { ProductOptionModal } from "../../../../shared/components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeAllCart } from "actions/cart";

class ProductDetail extends Component {
  static propTypes = {
    id: PropTypes.any,
    auth: PropTypes.object,
    detail: PropTypes.object,
    reviews: PropTypes.object,
    options: PropTypes.object,
    onGetProductDetail: PropTypes.func,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
    onAddItemToGuestCart: PropTypes.func,
    onReviewProduct: PropTypes.func,
    onGetProductReview: PropTypes.func,
    onGetOptionsByProduct: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      tableId: queryString.parse(search).tableId || null,
      detail: {},
      reviews: [],
      added: false,
      adding: false,
      rating: 0,
      comment: "",
      loading: false,
      pageLoading: false,
      open: false,
      fetching: false,
      fetched: false,
      openConfirm: false,
      value: null,
      openModal: false,
      optionIds: [],
      quantity: 1,
      cartView: {},
      openModalDetail: false,
    };
    this._notificationSystem = null;
  }

  changeQuantity = (operator = "-") => {
    let number = 1;
    if (operator === "-") number = -1;
    this.setState((prev) => ({
      quantity: prev.quantity + number <= 0 ? 1 : prev.quantity + number,
    }));
  };

  componentDidMount() {
    this.getDetail();
    this._notificationSystem = this.refs.notificationSystem;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getDetail();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.detail) {
      this.getOptionIds(nextProps.detail.data.options);
    }
  }

  getDetail = () => {
    this.setState({
      fetching: true,
      fetched: false,
    });
    this.props
      .onGetProductDetail({ id: this.props.id })
      .then(() => {
        this.setState({
          fetching: false,
          fetched: true,
        });
      })
      .catch(() => {
        this.setState({
          fetching: false,
          fetched: true,
        });
      });

    this.getOptionIds(this.props.detail.data.options);
    this.props.onGetProductReview({
      id: this.props.id,
      type: Config.PRODUCT_TYPE,
    });
  };

  updateOptionQuantity = (id, quantity, type, item) => {
    if (type !== 3) return;
    let updateOptionIds = this.state.optionIds || [];
    const index = updateOptionIds.findIndex((item) => item.id === id);

    if (index === -1) return;
    const qty = updateOptionIds[index].quantity + quantity;
    if (qty <= 0) return;

    updateOptionIds[index].quantity = qty;
    this.setState({ optionIds: updateOptionIds });
  };

  updateOptionIds = (id, check, deleteIds = []) => {
    let updateOptionIds = this.state.optionIds || [];

    if (deleteIds && deleteIds.length) {
      updateOptionIds = updateOptionIds.filter(
        (item) => !deleteIds.includes(item.id)
      );
    }

    if (!check) {
      updateOptionIds = updateOptionIds.filter((item) => item.id !== id);
    } else {
      const updateOptionId = updateOptionIds.find((item) => item.id === id);
      if (!updateOptionId) {
        updateOptionIds.push({ id: id, quantity: 1 });
      }
    }

    this.setState({ optionIds: updateOptionIds });
  };

  addItemToCart = () => {
    const { intl } = this.props;
    const { tableId, quantity } = this.state;
    if (tableId) {
      this.setState({
        openConfirm: true,
      });
      return;
    }

    this.setState({ added: false, adding: true });

    this.props
      .onAddItemToCart({
        product_id: this.props.id,
        table_id: tableId || undefined,
        quantity: quantity,
        option_info: this.state.optionIds.length
          ? JSON.stringify(this.state.optionIds)
          : null,
      })
      .then(() => {
        this.setState({ added: false, adding: false });
        this._notificationSystem.addNotification({
          message: intl.formatMessage({
            id: "cart.add_success",
          }),
          level: "success",
          autoDismiss: 3,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ adding: false });
        this._notificationSystem.addNotification({
          message: intl.formatMessage({
            id: "notification.please_try_again",
          }),
          level: "error",
          autoDismiss: 3,
        });
      });
  };

  save = () => {
    const { intl } = this.props;
    const { tableId, value, quantity } = this.state;
    this.setState({ added: false, adding: true });

    this.props
      .onAddItemToCart({
        product_id: this.props.id,
        table_id: tableId || undefined,
        delivery_method: value,
        quantity: quantity,
        option_info: this.state.optionIds.length
          ? JSON.stringify(this.state.optionIds)
          : null,
      })
      .then(() => {
        this._notificationSystem.addNotification({
          message: intl.formatMessage({
            id: "cart.add_success",
          }),
          level: "success",
          autoDismiss: 3,
        });
        this.setState({ adding: false, openConfirm: false, value: null });
      })
      .catch(() => {
        this.setState({ adding: false });
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: "notification.please_try_again" }),
          level: "error",
          autoDismiss: 3,
        });
      });
  };

  renderTab = () => {
    const { intl } = this.props;
    const panes = [
      {
        menuItem: intl.formatMessage({
          id: "details",
          defaultMessage: "Details",
        }),
        render: () => (
          <Tab.Pane attached={false}>
            <Detail detail={this.props.detail.data.description} />
          </Tab.Pane>
        ),
      },
      // {
      //   menuItem: intl.formatMessage({
      //     id: "more_infomation",
      //     defaultMessage: "MORE INFOMATION",
      //   }),
      //   render: () => <Tab.Pane attached={false} />,
      // },
      {
        menuItem: intl.formatMessage({
          id: "videos",
          defaultMessage: "VIDEOS",
        }),
        render: () => (
          <Tab.Pane attached={false}>
            <Video detail={this.props.detail.data} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: intl.formatMessage({
          id: "review",
          defaultMessage: "Review",
        }),
        render: () => (
          <Tab.Pane attached={false}>
            <Reviews
              onReviewProduct={this.props.onReviewProduct}
              onGetProductDetail={this.props.onGetProductDetail}
              onGetProductReview={this.props.onGetProductReview}
              onNotification={this._notificationSystem}
              id={this.props.id}
              reviews={this.props.reviews}
              idUser={this.props.auth.user.id}
              onOpenModal={this.onOpenModal}
            />
          </Tab.Pane>
        ),
      },
    ];
    return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
  };

  onGetCurrent = (originalPrice, promotion) => {
    if (promotion && promotion.percent) {
      return originalPrice - (originalPrice * promotion.percent) / 100;
    }
    return originalPrice;
  };

  onCloseReasonModal = () => {
    this.setState({
      openConfirm: false,
    });
  };

  handleChange = (e, { value }) => this.setState({ value });

  onOpenModal = () => {
    this.setState({
      openModal: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      openModal: false,
    });
  };

  getOptionIds(options) {
    if (options && options.length) {
      const ids = [];
      options.forEach((option) => {
        const { childrens } = option;
        ids.push(
          ...childrens
            .filter((child) => child.isCheck)
            .map((child) => ({ id: child.id, quantity: 1 }))
        );
      });

      this.setState({ optionIds: ids });
      return;
    }

    this.setState({ optionIds: [] });
  }

  includesOption = (id) => {
    return this.state.optionIds.find((child) => child.id === id);
  };

  getTotalPriceOptions = () => {
    let totalPirce = 0;

    if (
      !this.props.detail ||
      !this.props.detail.data.options ||
      !this.props.detail.data.options.length
    )
      return 0;

    this.props.detail.data.options.forEach((option) => {
      const { childrens } = option;

      childrens.forEach((children) => {
        const childIncludes = this.includesOption(children.id);
        if (childIncludes) {
          totalPirce +=
            Number(children.price || 0) * Number(childIncludes.quantity);
        }
      });
    });

    return totalPirce;
  };

  onCloseModalDetail = () => {
    this.setState({ openModalDetail: false, cartView: {} });
  };

  onOpenModalDetail = () => {
    this.setState({ openModalDetail: true });
  };

  setCartView = (cartViewData) => {
    this.setState((prev) => ({
      cartView: { ...prev.cartView, ...cartViewData },
    }));
  };

  render() {
    const {
      detail,
      auth,
      cart,
      signin,
      signinSocial,
      onNotification,
      intl
    } = this.props;

    const { fetched, fetching, added, adding, value, openConfirm } = this.state;
    return (
      <>
        <Modal
          open={openConfirm}
          onClose={this.onCloseReasonModal}
          center
          showCloseIcon={false}
        >
          <Pane className="popup-confirm-container">
            <Form>
              <Form.Field>
                <Checkbox
                  radio
                  label="Dine in"
                  name="checkboxRadioGroup"
                  value={Config.DELIVERY_METHOD.DINE_IN}
                  checked={value === Config.DELIVERY_METHOD.DINE_IN}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label="Take away"
                  name="checkboxRadioGroup"
                  value={Config.DELIVERY_METHOD.TAKE_AWAY}
                  checked={value === Config.DELIVERY_METHOD.TAKE_AWAY}
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form>
            <Pane className="react-confirm-alert-button-group">
              <button onClick={this.save}>
                <FormattedMessage id="save" defaultMessage="save" />
              </button>
            </Pane>
          </Pane>
        </Modal>

        {fetched && !fetching ? (
          <Container data={detail && detail.data}>
            <Pane className="breadcrumb-container">
              <Pane className="container">
                <LoginModal
                  onNotification={onNotification}
                  signin={signin}
                  signinSocial={signinSocial}
                  auth={auth}
                  onCloseModal={this.onCloseModal}
                  openModal={this.state.openModal}
                />
                <ProductOptionModal
                  openModal={this.state.openModalDetail}
                  onClose={this.onCloseModalDetail}
                  detail={this.state.cartView}
                  isEdit={true}
                  modeCart={this.state.cartView.modeCart}
                  onAddCartItem={(optionIds, item) => {
                    if (cart && cart.items && cart.items.length > 0 && item.userId !== cart.items[0].userId) {
                      this.props.removeAllCart();
                    }
                    this.props
                      .onAddItemToCart({
                        product_id: item.id,
                        option_info: optionIds.length
                          ? JSON.stringify(optionIds)
                          : null,
                        table_id: item.tableId,
                        delivery_method: item.delivery_method,
                      })
                      .then(() => {
                        this._notificationSystem.addNotification({
                          message: intl.formatMessage({
                            id: "cart.add_success",
                          }),
                          level: "success",
                          autoDismiss: 3,
                        });
                        this.onCloseModalDetail();
                      });
                  }}
                />
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Breadcrumb className="breadcrumb-list">
                      <Breadcrumb.Section className="item" href={`/`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/details/home.svg"
                          }
                          alt="home"
                          className="icon"
                        />
                      </Breadcrumb.Section>
                      <Breadcrumb.Section
                        className="item"
                        href={`/products?category=${detail.data.categoryId}`}
                      >
                        {detail.data.categoryName}
                      </Breadcrumb.Section>
                      <Breadcrumb.Section className="item active" active>
                        <span className="item-norm">
                          T-
                          {detail.data.name}
                        </span>
                      </Breadcrumb.Section>
                    </Breadcrumb>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
            <Pane className="details-container">
              <Pane className="container">
                <Pane className="row">
                  <Pane className="col-lg-9 col-md-12 col-sm-12">
                    <Pane className="category-details mb-5">
                      <Pane className="row">
                        <Pane className="col-lg-6">
                          <SlideImage
                            cart={cart}
                            added={added}
                            adding={adding}
                            addItemToCart={this.addItemToCart}
                            detail={detail.data}
                          />
                          <Pane className="category-content">
                            <h2 className="category-title">
                              {detail.data.name}
                            </h2>
                            {get(detail, "data.promotion.percent") && (
                              <>
                                <h4 className="category-price">
                                  <Currency
                                    price={
                                      (Number(detail.data.promotionPrice) +
                                        this.getTotalPriceOptions()) *
                                      this.state.quantity
                                    }
                                  />
                                </h4>
                                <h4 className="category-price text-underline">
                                  <Currency
                                    price={
                                      (Number(
                                        get(detail, "data.originalPrice")
                                      ) +
                                        this.getTotalPriceOptions()) *
                                      this.state.quantity
                                    }
                                  />
                                </h4>
                              </>
                            )}
                            {!get(detail, "data.promotion.percent") && (
                              <h4 className="category-price category-price-red">
                                <Currency
                                  price={
                                    (Number(detail.data.promotionPrice) +
                                      this.getTotalPriceOptions()) *
                                    this.state.quantity
                                  }
                                />
                              </h4>
                            )}
                            <StockDetailLabel
                              inStock={detail.data.inventoryStatus}
                              type="products"
                            />
                            <Pane className="product-detail-quantity">
                              <span
                                className="operator pointer"
                                onClick={() => this.changeQuantity("-")}
                              >
                                -
                              </span>
                              <span className="operator">
                                {this.state.quantity}
                              </span>
                              <span
                                className="operator pointer"
                                onClick={() => this.changeQuantity("+")}
                              >
                                +
                              </span>
                            </Pane>
                            {/* <Button
                                loading={adding}
                                disabled={
                                  adding ||
                                  added ||
                                  !!currentCart(detail.data, cart).added
                                }
                                className="btn btn-circle btn-category mt-3"
                                onClick={this.addItemToCart}
                              >
                                <Cart
                                  added={
                                    !!currentCart(detail.data, cart).added ||
                                    added
                                  }
                                  type="details"
                                />
                                <FormattedMessage
                                  id="add_to_cart"
                                  defaultMessage="Add to cart"
                                />
                              </Button> */}
                          </Pane>
                        </Pane>
                        <Pane className="col-lg-6">
                          <Pane className="comment-container mb-3">
                            {this.renderTab()}
                          </Pane>
                        </Pane>
                      </Pane>
                      {this.props.detail.data.options &&
                      this.props.detail.data.options.length ? (
                        <Pane className="row">
                          <Pane className="col-12">
                            <CartOption
                              updateOptionQuantity={this.updateOptionQuantity}
                              updateOptionIds={this.updateOptionIds}
                              optionIds={this.state.optionIds}
                              options={this.props.detail.data.options}
                            />
                          </Pane>
                        </Pane>
                      ) : null}
                    </Pane>
                  </Pane>
                  <Pane className="col-lg-3 col-md-12 col-sm-12">
                    {detail.data.userId && (
                      <Pane className="bussiness-reponsive">
                        <StoreInfo auth={auth} detail={detail.data} />
                      </Pane>
                    )}
                  </Pane>
                </Pane>
                <BestMatched
                  onAddItemToCart={this.props.onAddItemToCart}
                  onNotification={this._notificationSystem}
                  categoryId={detail.data.categoryId}
                  cartModal={{
                    cartView: this.state.cartView,
                    openModal: this.onOpenModalDetail,
                    setCartView: this.setCartView,
                  }}
                />
              </Pane>
            </Pane>
            <Delivery />
            <NewsLetter />
          </Container>
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  removeAllCart: bindActionCreators(removeAllCart, dispatch),
});

export default connect(null, bindDispatchToProps)(injectIntl(withRouter(ProductDetail)));
