import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Select,
  Image as ImageS,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import InlineMessage from "shared/components/InlineMessage";
import { Pane } from "shared/components/layouts";
import { formatRoute, handleError, quill } from "shared/helpers";
import createClass from "create-react-class";
import SelectImage from "react-select";
import Config from "constants/Config";
import ReactQuill from "react-quill";
import TreeSelect from "rc-tree-select";

class CreateCollectionForm extends Component {
  static propTypes = {
    categories: PropTypes.object.isRequired,
    getMyCollections: PropTypes.func.isRequired,
    createMyCollection: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    history: PropTypes.object,
    intl: intlShape.isRequired,
  };

  state = {
    imageCategory: {
      label: "/images/category/18.Collectables.png",
      value: "/images/category/18.Collectables.png",
    },
    default_images: [
      {
        label: "/images/category/18.Collectables.png",
        value: "/images/category/18.Collectables.png",
      },
      {
        label: "/images/category/17.Coins.png",
        value: "/images/category/17.Coins.png",
      },
      {
        label: "/images/category/16.Clothing,Shoes,Accessories.png",
        value: "/images/category/16.Clothing,Shoes,Accessories.png",
      },
      {
        label: "/images/category/7.Cameras.png",
        value: "/images/category/7.Cameras.png",
      },
      {
        label: "/images/category/6.Business.png",
        value: "/images/category/6.Business.png",
      },
      {
        label: "/images/category/5.Books,Magazines.png",
        value: "/images/category/5.Books,Magazines.png",
      },
      {
        label: "/images/category/3.Art.png",
        value: "/images/category/3.Art.png",
      },
      {
        label: "/images/category/2.Antiques.png",
        value: "/images/category/2.Antiques.png",
      },
      {
        label: "/images/category/56.Consultingservices.png",
        value: "/images/category/56.Consultingservices.png",
      },
      {
        label: "/images/category/57.Educationservices.png",
        value: "/images/category/57.Educationservices.png",
      },
      {
        label: "/images/category/58.Legalservices.png",
        value: "/images/category/58.Legalservices.png",
      },
      {
        label: "/images/category/59.Healthandbeautyservices.png",
        value: "/images/category/59.Healthandbeautyservices.png",
      },
      {
        label: "/images/category/65.Handymanservices.png",
        value: "/images/category/65.Handymanservices.png",
      },
      {
        label: "/images/category/66.Cleaningservices.png",
        value: "/images/category/66.Cleaningservices.png",
      },
      {
        label: "/images/category/67.Constructionservices.png",
        value: "/images/category/67.Constructionservices.png",
      },
      {
        label: "/images/category/71.Entertainmentservices.png",
        value: "/images/category/71.Entertainmentservices.png",
      },
      {
        label: "/images/category/72.House-careservices.png",
        value: "/images/category/72.House-careservices.png",
      },
      {
        label: "/images/category/73.Financialservices.png",
        value: "/images/category/73.Financialservices.png",
      },
      {
        label: "/images/category/77.Foodservices.png",
        value: "/images/category/77.Foodservices.png",
      },
      {
        label: "/images/category/78.Informationservices.png",
        value: "/images/category/78.Informationservices.png",
      },
      {
        label: "/images/category/19.Computers-Tablets&Networking.png",
        value: "/images/category/19.Computers-Tablets&Networking.png",
      },
      {
        label: "/images/category/15.Cars,Bikes,Boats.png",
        value: "/images/category/15.Cars,Bikes,Boats.png",
        width: "70px",
      },
      {
        label: "/images/category/4.Baby.png",
        value: "/images/category/4.Baby.png",
      },
    ],
    newImageCategory: false,
    images: [],
    category_images: [],
    imageFiles: [],
    imageCategoryFiles: [],
    data: {
      name: "",
      categoryId: 1,
      description: "",
      type: "",
    },
    dataCategory: {
      name: "",
      group: "",
    },
    newCategory: false,
    errors: {},
    categories: [],
    groups: [{
      text: "Collection",
      value: "Collection",
    }],
    open: false,
    loading: false,
    types: [
      {
        text: "Video",
        value: "Video",
      },
      {
        text: "Image",
        value: "Image",
      },
      {
        text: "Post",
        value: "Post",
      },
    ],
    isUpload: false,
    accept: ''
  };

  componentDidMount() {
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  onChangeCategory = (e) => {
    this.setState({
      dataCategory: {
        ...this.state.dataCategory,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSelectGroup = (e, s) => {
    this.setState({
      dataCategory: { ...this.state.dataCategory, [s.name]: s.value },
    });
  };

  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.images.length < 6 &&
          this.state.images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  handleChangeImageCategory = (e, { value }) => {
    this.setState({
      newImageCategory: value,
    });
  };

  onChangeCategoryImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.category_images.length < 6 &&
          this.state.category_images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.category_images;
            const imageFiles = this.state.imageCategoryFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              category_images: images,
              imageCategoryFiles: imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  onChangeType = (e, s) => {
    this.setState({
      data: { ...this.state.data, type: s.value },
    }, () => {
      let type = s.value;
      if (type === 'Image') {
        this.setState({
          isUpload: true,
          accept: 'image/*'
        })
      } else if (type === 'Video') {
        this.setState({
          isUpload: true,
          accept: 'video/*'
        })
      } else {
        this.setState({
          isUpload: false,
        })
      }
    });
  };

  handleChangeCheckbox = (e, { value }) => {
    this.setState({
      newCategory: value,
    });
  };

  handleCategory = (categories) => {
    const array = [];
    categories &&
      categories.results &&
      categories.results.forEach((item) => {
        array.push({
          key: item.id,
          value: item.id,
          text: item.name,
        });
      });

    this.setState({
      categories: array,
    });
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  removeCategoryImage = (index) => {
    const images = this.state.category_images;
    const imageFiles = this.state.imageCategoryFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      category_images: images,
      imageCategoryFiles: imageFiles,
    });
  };

  validate = (data, dataCategory) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.type) {
      errors.type = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
    if (this.state.newCategory === false && !data.categoryId) {
      errors.categoryId = intl.formatMessage({ id: "validate.require_field" });
    }
    if (this.state.newCategory === true) {
      if (!dataCategory.name) {
        errors.category_name = intl.formatMessage({
          id: "validate.require_field",
        });
      }
      if (!dataCategory.group) {
        errors.category_group = intl.formatMessage({
          id: "validate.require_field",
        });
      }
    }

    return errors;
  };

  createCollection = async () => {
    const errors = this.validate(this.state.data, this.state.dataCategory);
    const { userInfo } = this.props;
    let categoryId;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      if (this.state.newCategory === true) {
        const dataCategory = new FormData();
        if (this.state.imageCategoryFiles[0]) {
          dataCategory.append(
            "primary_image",
            this.state.imageCategoryFiles[0]
          );
        }
        if (this.state.newImageCategory === false) {
          dataCategory.append("primary_image", this.state.imageCategory.value);
        }
        dataCategory.append("name", this.state.dataCategory.name);
        dataCategory.append("group", this.state.dataCategory.group);
        dataCategory.append("user_id", userInfo.id);
        dataCategory.append(
          "sub_image_1",
          this.state.imageCategoryFiles[1] || ""
        );
        dataCategory.append(
          "sub_image_2",
          this.state.imageCategoryFiles[2] || ""
        );
        dataCategory.append(
          "sub_image_3",
          this.state.imageCategoryFiles[3] || ""
        );
        dataCategory.append(
          "sub_image_4",
          this.state.imageCategoryFiles[4] || ""
        );
        dataCategory.append(
          "sub_image_5",
          this.state.imageCategoryFiles[5] || ""
        );

        try {
          const {
            data: { data },
          } = await this.props.createCategory(dataCategory);
          this.setState({
            open: false,
            dataCategory: {
              ...this.state.dataCategory,
            },
          });
          categoryId = data.id;
          this.addCollection(categoryId).then(() => {
            this.setState({
              loading: false,
              errors,
            });
          });
        } catch (error) {
          const errors = {};
          errors.message =
            "Category: " +
            handleError(
              error,
              this.props.intl.formatMessage({
                id: "notification.please_try_again",
              })
            );

          this.setState({
            loading: false,
            errors,
          });
        }
      } else {
        this.addCollection(this.state.data.categoryId);
      }
    }
  };

  addCollection = (categoryId) => {
    const data = new FormData();
    data.append("name", this.state.data.name);
    data.append("description", this.state.data.description);
    data.append("type", this.state.data.type);
    data.append("category_id", categoryId);
    data.append("primary_image", this.state.imageFiles[0] || "");

    this.props
      .createMyCollection(data)
      .then(() => {
        this.props.history.push(
          `/u/${formatRoute(this.props.userInfo.displayName)}/collections`
        );
      })
      .catch((error) => {
        const errors = {};
        errors.message =
          "Collection: " +
          handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );

        this.setState({
          loading: false,
          errors,
        });
      });
  };

  setImageCategory = (a) => {
    this.setState({
      imageCategory: a,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCategory(nextProps.categories);
  }

  nestPath = (data) => {
    if (!isEmpty(data)) {
      return data.map((item, index) => ({
        index,
        key: item.id,
        pId: item.id,
        title: item.name,
        value: item.id,
        children: this.nestPath(item.children),
      }));
    }
    return [];
  };

  onSelectTreeSelect = (value) => {
    this.setState((prevState) => ({
      data: { ...prevState.data, categoryId: value },
    }));
  };

  render() {
    const { errors, data, loading, dataCategory, accept, isUpload, types } = this.state;
    const { intl } = this.props;
    return (
      <Form className="form-overview pb-5" onSubmit={this.createCollection}>
        <Pane className="form-content">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.name} className="form-item">
                <Form.Field
                    control={Select}
                    scrolling
                    className="form-item"
                    name="group"
                    error={!!errors.type}
                    label={intl.formatMessage({
                      id: "type",
                      defaultMessage: "Type",
                    })}
                    value={data.type}
                    options={types}
                    placeholder={this.props.intl.formatMessage({
                      id: "select_type",
                      defaultMessage: "Select Type",
                    })}
                    onChange={this.onChangeType}
                  />
                {errors.name && <InlineMessage text={errors.type} />}
              </Form.Field>
            </Pane>
          </Pane>
          {isUpload && (
            <Pane className="row">
              <Pane className="col-lg-12">
                <Form.Field className="form-item">
                  <label htmlFor="Image" className="form-label">
                    {data.type}
                  </label>
                  <input
                    type="file"
                    id="image-menu"
                    className="form-input-file"
                    accept={accept}
                    value={data.image}
                    onChange={this.onChangeImage}
                  />
                  <br />
                  <label htmlFor="image-menu" className="form-label-file">
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/images/icons/upload.svg`
                      }
                      alt="upload"
                      className="icon"
                    />
                    Upload a {data.type}
                  </label>
                  <Pane
                    className="slider-content-main d-flex"
                    style={{ padding: "10px 0" }}
                  >
                    {this.state.images &&
                      this.state.images.map((item, index) => {
                        if (item.indexOf("video") < 0) {
                          // Image
                          return (
                            <Pane
                              className="slide-item mr-2"
                              key={index}
                            >
                              <Icon
                                name="remove circle"
                                onClick={this.removeImage.bind(this, index)}
                              />
                              <ImageS src={item} size="small" />
                            </Pane>
                          );
                        }
                        // Video
                        return (
                          <Pane
                            className="slide-item"
                            key={index}
                            style={{ float: "left" }}
                          >
                            <Icon
                              name="remove circle"
                              onClick={this.removeImage.bind(this, index)}
                            />
                            <video width="150" controls>
                              <source src={item} id="video_here" />
                              Your browser does not support HTML5 video.
                            </video>
                          </Pane>
                        );
                      })}
                  </Pane>
                </Form.Field>
              </Pane>
            </Pane>
          )}
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.name} className="form-item">
                <label htmlFor="name" className="form-label">
                  <FormattedMessage id="name" defaultMessage="Name" />
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder=""
                  className="form-input"
                  value={data.name}
                  onChange={this.onChange}
                />
                {errors.name && <InlineMessage text={errors.name} />}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field error={!!errors.description} className="form-item">
                <label htmlFor="description" className="form-label">
                  <FormattedMessage
                    id="description"
                    defaultMessage="Description"
                  />
                </label>
                <ReactQuill
                  value={this.state.data.description || ''}
                  onChange={(content) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        description: content,
                      },
                    }));
                  }}
                  formats={quill.formats}
                  modules={quill.modules}
                />
                {errors.description && (
                  <InlineMessage text={errors.description} />
                )}
              </Form.Field>
            </Pane>
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12">
              <Form.Field>
                <Checkbox
                  radio
                  label={intl.formatMessage({
                    id: "select.category",
                    defaultMessage: "Select category",
                  })}
                  name="isNewCategory"
                  value={false}
                  checked={this.state.newCategory === false}
                  onChange={this.handleChangeCheckbox}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label={intl.formatMessage({
                    id: "select.category_add",
                    defaultMessage: "Add new category",
                  })}
                  name="isNewCategory"
                  value={true}
                  checked={this.state.newCategory === true}
                  onChange={this.handleChangeCheckbox}
                />
              </Form.Field>
            </Pane>
          </Pane>
          {this.state.newCategory === false ? (
            <Pane className="row">
              <Pane className="col-lg-12">
                <Form.Field className="form-item" error={!!errors.categoryId}>
                  <label htmlFor="categoryId" className="form-label">
                    <FormattedMessage
                      id="category"
                      defaultMessage="Unit Price"
                    />
                  </label>
                  <TreeSelect
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
                    placeholder={<i>Select</i>}
                    searchPlaceholder="Search..."
                    treeDefaultExpandAll
                    treeData={this.nestPath(this.props.categories.results)}
                    treeNodeFilterProp="title"
                    onChange={this.onMultipleChange}
                    onSelect={this.onSelectTreeSelect}
                    allowClear
                  />
                  {errors.categoryId && (
                    <InlineMessage text={errors.categoryId} />
                  )}
                </Form.Field>
              </Pane>
            </Pane>
          ) : (
            <>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    error={!!errors.category_name}
                    className="form-group"
                  >
                    <label htmlFor="category_name" className="form-label">
                      <FormattedMessage
                        id="category_name"
                        defaultMessage="Category Name"
                      />
                    </label>
                    <input
                      type="text"
                      id="category_name"
                      name="name"
                      placeholder=""
                      className="form-control"
                      value={dataCategory.name}
                      onChange={this.onChangeCategory}
                    />
                    {errors.category_name && (
                      <InlineMessage text={errors.category_name} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    control={Select}
                    scrolling
                    className="form-item"
                    name="group"
                    error={!!errors.category_group}
                    label={intl.formatMessage({
                      id: "group",
                      defaultMessage: "Group",
                    })}
                    value={dataCategory.group}
                    options={this.state.groups}
                    placeholder={this.props.intl.formatMessage({
                      id: "select",
                      defaultMessage: "Select Group",
                    })}
                    onChange={this.onSelectGroup}
                  />
                </Pane>
              </Pane>
              <Form.Field>
                <Checkbox
                  radio
                  label={intl.formatMessage({
                    id: "select.image",
                    defaultMessage: "Select a image",
                  })}
                  name="newImageCategory"
                  value={false}
                  checked={this.state.newImageCategory === false}
                  onChange={this.handleChangeImageCategory}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label={intl.formatMessage({
                    id: "select.image_add",
                    defaultMessage: "Add new image",
                  })}
                  name="newImageCategory"
                  value={true}
                  checked={this.state.newImageCategory === true}
                  onChange={this.handleChangeImageCategory}
                />
              </Form.Field>
              {this.state.newImageCategory === false ? (
                <Form.Field className="form-item">
                  <label htmlFor="category_image" className="form-label">
                    <FormattedMessage
                      id="category_image"
                      defaultMessage="Category Image"
                    />
                  </label>
                  <SelectImage
                    valueComponent={createClass({
                      render() {
                        return (
                          <Pane
                            className="Select-value"
                            title={
                              this.props.option ? this.props.option.label : ""
                            }
                          >
                            <span className="Select-value-label">
                              {this.props.option && (
                                <img
                                  width="70px"
                                  height="50px"
                                  alt="selectImage"
                                  src={
                                    Config.NEW_API_URL + this.props.option.value
                                  }
                                />
                              )}
                              {this.props.children}
                            </span>
                          </Pane>
                        );
                      },
                    })}
                    options={this.state.default_images}
                    placeholder={intl.formatMessage({
                      id: "select.image",
                      defaultMessage: "Select a image",
                    })}
                    onChange={this.setImageCategory}
                    value={this.state.imageCategory}
                    optionComponent={createClass({
                      propTypes: {
                        children: PropTypes.node,
                        className: PropTypes.string,
                        isDisabled: PropTypes.bool,
                        isFocused: PropTypes.bool,
                        isSelected: PropTypes.bool,
                        onFocus: PropTypes.func,
                        onSelect: PropTypes.func,
                        option: PropTypes.object.isRequired,
                      },
                      handleMouseDown(event) {
                        event.preventDefault();
                        event.stopPropagation();
                        this.props.onSelect(this.props.option, event);
                      },
                      handleMouseEnter(event) {
                        this.props.onFocus(this.props.option, event);
                      },
                      handleMouseMove(event) {
                        if (this.props.isFocused) return;
                        this.props.onFocus(this.props.option, event);
                      },
                      render() {
                        return (
                          <Pane
                            className={this.props.className}
                            onMouseDown={this.handleMouseDown}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseMove={this.handleMouseMove}
                            title={this.props.option.label}
                          >
                            <img
                              width={
                                this.props.option.width
                                  ? this.props.option.width
                                  : "50px"
                              }
                              height={
                                this.props.option.height
                                  ? this.props.option.height
                                  : "50px"
                              }
                              alt="img"
                              src={Config.NEW_API_URL + this.props.option.value}
                            />
                          </Pane>
                        );
                      },
                    })}
                  />
                </Form.Field>
              ) : (
                <Form.Field className="form-group">
                  <label htmlFor="category_image">
                    <FormattedMessage
                      id="category_image"
                      defaultMessage="Category Image"
                    />
                  </label>
                  <input
                    type="file"
                    id="category_image"
                    name="category_image"
                    placeholder=""
                    className="form-control"
                    value={dataCategory.image}
                    onChange={this.onChangeCategoryImage}
                  />
                  <Pane
                    className="slider-content-main d-flex"
                    style={{ padding: "10px 0" }}
                  >
                    {this.state.category_images &&
                      this.state.category_images.map((item, index) => {
                        return (
                          <Pane
                            className="slide-item mr-2"
                            key={index}
                          >
                            <Icon
                              name="remove circle"
                              onClick={this.removeCategoryImage.bind(
                                this,
                                index
                              )}
                            />
                            <ImageS src={item} size="small" />
                          </Pane>
                        );
                      })}
                  </Pane>
                </Form.Field>
              )}
            </>
          )}
          {errors.categoryId && <InlineMessage text={errors.categoryId} />}
          {errors.message && <InlineMessage text={errors.message} />}
          {errors.category_message && (
            <InlineMessage text={errors.category_message} />
          )}
        </Pane>
        <Pane className="row">
          <Pane className="col-lg-12 text-center">
            <Button
              type="submit"
              className="btn btn-save mt-4"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="create" defaultMessage="Create" />
            </Button>
          </Pane>
        </Pane>
      </Form>
    );
  }
}

export default injectIntl(CreateCollectionForm);
