import * as types from 'constants/ActionTypes'
import api from 'api'

export const saveUserDevice = (params) => (dispatch) => dispatch({
  type: types.SAVE_USER_DEVICE,
  payload: {
    request: api.notification.save(params)
  }
})

export const pushNotification = (params) => (dispatch) => dispatch({
  type: types.PUSH_NOTIFICATION,
  payload: {
    request: api.notification.push(params)
  }
})
