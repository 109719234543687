import axios from 'axios';
import { get } from 'lodash';
import Config from 'constants/Config';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
export default {
  format: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'background',
    'code',
    'video',
    `clean`,
  ],
  modules: {
    toolbar: {
      container: [
        [{ header: [3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
        [{ color: [] }, { background: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['image', 'video'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  },
};

function imageHandler() {
  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();
    const self = this;
    formData.append('photo', file);

    // Save current cursor state

    const range = this.quill.getSelection(true);

    // Move cursor to right side of image (easier to continue typing)
    this.quill.setSelection(range.index + 1);

    await axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${cookies.get('accessToken')}`,
        'Content-Type': 'multipart/form-data',
      },
      url: `${Config.NEW_API_URL}/uploads`,
      data: formData,
    })
      .then((response) => {
        self.quill.deleteText(range.index, 1);
        self.quill.insertEmbed(
          range.index,
          'image',
          get(response, 'data.data.attributes.url')
        );
      })
      .catch(() => {
        //handle error
      });
  };
}
