import { List } from './list';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Pane } from 'shared/components/layouts';
import { withRouter, Link } from 'react-router-dom';
import { formatRoute, handleError } from 'shared/helpers';
import { LoadingSearch } from 'components/common/loading';
import NotificationSystem from 'react-notification-system';
import { getVideosByUserId, deleteVideo } from 'actions/myVideos';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

const MenuItem = ({ text, selected }) => {
  return <div className={`menu-item ${selected ? 'active' : ''}`}>{text}</div>;
};

// All items component
// Important! add unique key
export const Menu = (list, selected) => {
  return list.map((el) => {
    const { name } = el;
    return <MenuItem text={name} key={name} selected={selected} />;
  });
};

class MyVideos extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = this.props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      fetching: false,
      fetched: false,
      keySort: null,
    };
    this._notificationSystem = null;
  }

  getVideosByUserId = (params) => {
    const { userInfo } = this.props;
    this.props
      .getVideosByUserId(
        {
          id: userInfo.id,
          size: 100,
        },
        params.load
      )
      .then(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      })
      .catch(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  };

  deleteItem = (params) => {
    const { intl } = this.props;

    if (this.props.myVideos.results.length === 1) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }

    this.props
      .deleteVideo(params)
      .then(() => {
        this.getVideosByUserId({ page: this.state.page });
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });
      });
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getVideosByUserId({ load: true });
    });

    this.getVideosByUserId({ load: true });

    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    const { userInfo, myVideos } = this.props;
    const { fetched, fetching } = this.state;
    return (
      <Pane className="overview-container" id="anchor">
        <Pane className="product-container">
          <div className="product-heading">
            <h3
              className="block-title pt10 text-uppercase"
              style={{ marginRight: 'auto' }}
            >
              Video
            </h3>
            <Link
              to={`/u/${formatRoute(userInfo.displayName)}/my-videos/create`}
              className="btn btn-circle btn-circle-primary"
            >
              <FormattedMessage
                id="create_new_video"
                defaultMessage="Create a new Video"
              />
            </Link>
          </div>
          {!fetching && fetched ? (
            <>
              {myVideos.results.length <= 0 && (
                <Pane className="empty-container">
                  <Pane className="empty-content">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/icons/empty.png'
                      }
                      alt="empty"
                      className="img-empty"
                    />
                    <h2 className="empty-title">No items</h2>
                  </Pane>
                </Pane>
              )}
              {myVideos.results.length ? (
                <List
                  data={myVideos.results}
                  onDelete={this.deleteItem}
                  getServicesByUserId={this.props.getServicesByUserId}
                  userInfo={this.props.userInfo}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <LoadingSearch type="white" />
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  myVideos: state.myVideos,
});

const bindDispatchToProps = (dispatch) => ({
  getVideosByUserId: bindActionCreators(getVideosByUserId, dispatch),
  deleteVideo: bindActionCreators(deleteVideo, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(MyVideos))
);
