const exec = params => {
  return {
    method: 'POST',
    url: `/Paypal/PaymentWithCreditCard`,
    data: params
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
