import { get } from "lodash";
import Slider from "react-slick";
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import ReviewsVideo from "../videos/ReviewsVideo";
import LikeShareVideo from "../videos/LikeShareVideo";
import PropTypes from "prop-types";

class Slideshow extends Component {
  static propTypes = {
    onGetReviewsVideo: PropTypes.func,
    onNotification: PropTypes.object,
    onReviewsVideo: PropTypes.func,
    onGetLikesVideo: PropTypes.func,
    onGetUserLikesVideo: PropTypes.func,
    onLikesVideo: PropTypes.func,
    onViewVideo: PropTypes.func,
    onGetVideosByUserId: PropTypes.func,
    onAddLikeVideo: PropTypes.func,
  };

  onPlay = (second) => {
    this.props.onViewVideo({
      id: this.props.data[0].id,
      view_video: this.props.data[0].viewVideo + 1,
    });
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      auto: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const slideItem =
      this.props.data &&
      this.props.data.map((item, index) => {
        return (
          <Pane className="list-slider" key={index}>
            <div className="product-heading">
              <h3
                className="block-title text-uppercase"
                style={{ marginRight: "auto", fontWeight: "bold" }}
              >
                {item.title}
              </h3>
            </div>
            <Pane className="slider-content-main">
              <video
                ref="videoPlayer"
                controls
                className="banner-image-over"
                key={index}
                onPlay={this.onPlay}
              >
                <source src={get(item, "video.url")} />
                Your browser does not support HTML5 video.
              </video>
            </Pane>
            <LikeShareVideo
              id={item.id}
              shareUrl={this.props.shareUrl}
              onGetLikesVideo={this.props.onGetLikesVideo}
              onGetUserLikesVideo={this.props.onGetUserLikesVideo}
              onLikesVideo={this.props.onLikesVideo}
              onNotification={this.props.onNotification}
              like={this.props.like}
              idUser={this.props.auth.user.id}
              userLike={this.props.userLike}
              countView={get(item, "viewVideo")}
              onAddLikeVideo={this.props.onAddLikeVideo}
              onOpenModal={this.props.onOpenModal}
            />
            <ReviewsVideo
              onGetReviewsVideo={this.props.onGetReviewsVideo}
              onNotification={this.props.onNotification}
              reviews={this.props.reviews}
              id={item.id}
              onReviewsVideo={this.props.onReviewsVideo}
              idUser={this.props.auth.user.id}
              onOpenModal={this.props.onOpenModal}
            />
          </Pane>
        );
      });

    return (
      <Pane
        className="video-container video-category"
        style={{ boxShadow: "none" }}
      >
        <Slider className="videos-slider" {...settings}>
          {slideItem}
        </Slider>
      </Pane>
    );
  }
}

export default Slideshow;
