import * as types from 'constants/ActionTypes'
import api from 'api'

// Get booked services
export const getBookedServices = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BOOKED_SERVICES, types.RECEIVE_BOOKED_SERVICES_SUCCESS, types.RECEIVE_BOOKED_SERVICES_FAILURE],
  payload: {
    request: api.bookings.buyers.get(params)
  }
})

export const getBookingDetailsOfBuyers = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BOOKING_DETAILS_OF_BUYER, types.RECEIVE_BOOKING_DETAILS_OF_BUYER_SUCCESS, types.RECEIVE_BOOKING_DETAILS_OF_BUYER_FAILURE],
  payload: {
    request: api.bookings.buyers.detail(params)
  }
})

// Buyers request cancel
export const requestCancel = (params) => (dispatch) => dispatch({
  type: types.REQUEST_CANCEL_BOOKING,
  payload: {
    request: api.bookings.buyers.requestCancel(params)
  }
})

// Get bookings
export const getBookings = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BOOKINGS_FOR_SELLER, types.RECEIVE_BOOKINGS_FOR_SELLER_SUCCESS, types.RECEIVE_BOOKINGS_FOR_SELLER_FAILURE],
  payload: {
    request: api.bookings.sellers.get(params)
  }
})

// Get booking detail of seller
export const getBookingDetailsOfSellers = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_BOOKING_DETAILS_OF_SELLER, types.RECEIVE_BOOKING_DETAILS_OF_SELLER_SUCCESS, types.RECEIVE_BOOKING_DETAILS_OF_SELLER_FAILURE],
  payload: {
    request: api.bookings.sellers.detail(params)
  }
})

// Sellers cancel booking
export const acceptCancelBooking = (params) => (dispatch) => dispatch({
  type: types.ACCEPT_CANCEL_BOOKING,
  payload: {
    request: api.bookings.sellers.acceptCancelBooking(params)
  }
})

// Sellers cancel booking
export const denyCancelBooking = (params) => (dispatch) => dispatch({
  type: types.DENY_CANCEL_BOOKING,
  payload: {
    request: api.bookings.sellers.denyCancelBooking(params)
  }
})

export const changeStatus = (params) => (dispatch) => dispatch({
  type: types.CHANGE_BOOKING_STATUS,
  payload: {
    request: api.bookings.sellers.changeStatus(params)
  }
})
