const formatRoute = (displayName, key) => {
  return displayName
    ? displayName
        .replace(/[&/\\#,+_()$~%.'":*?<>{}]/g, "")
        .replace(/ /g, key || "_")
        .replace(/--/g, key || "_")
        .toLowerCase()
    : "";
};

export default formatRoute;
