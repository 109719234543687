import * as types from "constants/ActionTypes";
import api from "api";
export const getTasksByUserId = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_MY_TASKS,
      types.RECEIVE_MY_TASKS_SUCCESS,
      types.RECEIVE_MY_TASKS_FAILURE,
    ],
    payload: {
      request: api.user.tasks(params),
    },
  });
export const getTaskDetail = (params) => (dispatch) =>
  dispatch({
    types: types.MY_TASK_DETAIL,
    payload: {
      request: api.user.readTask(params),
    },
  });
export const createMyTask = (params) => (dispatch) =>
  dispatch({
    types: types.CREATE_MY_TASK,
    payload: {
      request: api.user.addTask(params),
    },
  });
export const editMyTask = (params) => (dispatch) =>
  dispatch({
    types: types.EDIT_MY_TASK,
    payload: {
      request: api.user.editTask(params),
    },
  });
export const removeMyTask = (params) => (dispatch) =>
  dispatch({
    types: types.REMOVE_MY_TASK,
    payload: {
      request: api.user.removeTask(params),
    },
  });