import * as types from 'constants/ActionTypes'
import { Cookies } from 'react-cookie'
import api from 'api'
import UserAdapter from 'services/users/adapter'

const cookies = new Cookies()

export const requestUpdateAvatar = (params) => (dispatch) => dispatch({
  types: [types.UPDATE_USER_AVATAR, types.RECEIVE_USER_INFO_SUCCESS, types.RECEIVE_USER_INFO_FAILURE],
  payload: {
    request: api.user.updateImage(params)
  }
})
export const requestUpdateReferenceCode = (params) => (dispatch) => dispatch({
  types: [types.UPDATE_USER_REFERENCE, types.RECEIVE_USER_REFERENCE_SUCCESS, types.RECEIVE_USER_REFERENCE_FAILURE],
  payload: {
    request: api.user.referenceCode(params)
  }
})
export const requestQuickSignup = (params) => (dispatch) =>
  dispatch({
    type: types.CREATE_NEW_USER,
    payload: {
      request: api.user.quickSignup(params),
    },
  });
export const requestUpdateUserInfo = (params) => (dispatch) => dispatch({
  types: [types.UPDATE_USER_INFO, types.RECEIVE_USER_INFO_SUCCESS, types.RECEIVE_USER_INFO_FAILURE],
  payload: {
    request: api.user.updateInfo(params)
  }
})

export const requestUpdateBackground = (params) => (dispatch) => dispatch({
  types: [types.UPDATE_BACKGROUND, types.RECEIVE_USER_INFO_SUCCESS, types.RECEIVE_USER_INFO_FAILURE],
  payload: {
    request: api.user.updateImage(params)
  }
})

export const requestChangePassword = (params) => (dispatch) => dispatch({
  type: types.CHANGE_PASSWORD,
  payload: {
    request: api.user.changePassword(params)
  }
})

export const requestNewPassword = (params) => (dispatch) => dispatch({
  type: types.FORGOT_PASSWORD,
  payload: {
    request: api.user.forgotPassword(params)
  }
})

export const resetNewPassword = (params) => (dispatch) => dispatch({
  type: types.RESET_PASSWORD,
  payload: {
    request: api.user.resetPassword(params)
  }
})
// quick Signup
export const quickSignup = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestQuickSignup(params))
      .then((response) => {
        resolve(response);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
};
export const updateInfo = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestUpdateUserInfo(params)).then(response => {
      // Success
      cookies.set('userInfo', JSON.stringify(UserAdapter.read.parseResponse(response.data)), { path: '/' })
      resolve(response)
    }).catch(errors => {
      reject(errors)
    })
  })
}

export const changePassword = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestChangePassword(params)).then(response => {
      // Success
      resolve(response)
    }).catch(errors => {
      reject(errors)
    })
  })
}
export const updateReferenceCode= (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestUpdateReferenceCode(params)).then(response => {
      const userInfo = cookies.get('userInfo') || {}
       if( response.data.success){
          userInfo.reference = response.data.reference;
          cookies.set('userInfo', JSON.stringify(userInfo),  { path: '/' });
       }
      resolve(response);
    }).catch(error => {
      reject(error)
    })
  })
}
export const updateAvatar = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestUpdateAvatar(params)).then(response => {
      const userInfo = cookies.get('userInfo') || {}
      // const date = new Date()
      userInfo.profileImage = response.data.profileImage
      // userInfo.qRCode = `${response.data.qRCode}?${date.getTime()}`

      cookies.set('userInfo', JSON.stringify(userInfo), { path: '/' })

      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

export const updateBackground = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestUpdateBackground(params)).then(response => {
      const userInfo = cookies.get('userInfo') || {}
      userInfo.backgroundImage = response.data.backgroundImage;
      cookies.set('userInfo', JSON.stringify(userInfo), { path: '/' })

      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

export const forgotPassword = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(requestNewPassword(params)).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

export const resetPassword = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(resetNewPassword(params)).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}