import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { getServicesByUserId, createMyService } from 'actions/myServices';
import { getServiceCategories, createCategory } from 'actions/category';
import CreateServiceForm from './CreateServiceForm';
import { createMyOption, createMyOptionGroup } from "actions/options";
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';
import { getStaffsByUserId } from "actions/staffs";
import { createMyServiceMember } from "actions/serviceMembers";

class CreateService extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getServiceCategories: PropTypes.func,
    createMyService: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    getServicesByUserId: PropTypes.func.isRequired,
  };

  getMyServices = () => {
    this.props.getServicesByUserId({ id: this.props.userInfo.id });
  };

  componentDidMount() {
    this.props.getServiceCategories();
    this.props.getStaffsByUserId({ id: this.props.userInfo.id });

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/services`,
        name: 'Services'
      },
      {
        url: ``,
        name: 'Create new service',
        active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="d-flex justify-content-between align-items-center mb-4" id="anchor">
        </Pane>
        <CreateServiceForm
          userInfo={this.props.userInfo}
          categories={this.props.categories}
          staffs={this.props.staffs}
          getMyServices={this.getMyServices}
          createMyService={this.props.createMyService}
          createCategory={this.props.createCategory}
          history={this.props.history}
          createMyOption={this.props.createMyOption}
          createMyOptionGroup={this.props.createMyOptionGroup}
          createMyServiceMember={this.props.createMyServiceMember}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.serviceCategories,
  userInfo: state.auth.user,
  staffs: state.myStaffs,
});

const bindDispatchToProps = (dispatch) => ({
  getServicesByUserId: bindActionCreators(getServicesByUserId, dispatch),
  getServiceCategories: bindActionCreators(getServiceCategories, dispatch),
  createMyService: bindActionCreators(createMyService, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
  createMyOption: bindActionCreators(createMyOption, dispatch),
  createMyOptionGroup: bindActionCreators(createMyOptionGroup, dispatch),
  getStaffsByUserId: bindActionCreators(getStaffsByUserId, dispatch),
  createMyServiceMember: bindActionCreators(createMyServiceMember, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(CreateService);
