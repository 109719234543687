import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Loader, Button, Breadcrumb, Icon, Form } from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import moment from "moment";
import "react-dates/initialize";
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
} from "react-dates";
import { Pane, Loading } from "shared/components/layouts";
import { InlineMessage } from "shared/components";
import { StoreInfo } from "shared/components";
import { Container } from "shared/components";
import { formatJson } from "shared/helpers";
import { TradingHours } from "../common/trading-hours";
import { TimeSlots } from "../common/time-slots";

class BookingService extends Component {
  static propTypes = {
    auth: PropTypes.object,
    serviceId: PropTypes.any,
    onGetServiceDetail: PropTypes.func,
    onGetTimeSlots: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    date: moment(),
    timeSlots: [],
    closedDay: [],
    timeSlotSelected: [],
    rating: 0,
    detail: {},
    comment: "",
    paymentMethod: "Pay by Credit",
    errors: {},
    loadingBooking: false,
    loadingTimeSlots: false,
    loadingPage: false,
    loading: false,
    members: [],
    membersSelected: [],
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onDateChange = (date) => {
    this.setState({ date, membersSelected: [] }, () => {
      this.getTimeslots(moment(date).format("YYYY-MM-DD"));
    });
  };

  onChangeTimeSlot = (index) => {
    const timeSlotSelected = this.state.timeSlotSelected;
    const existedItem = timeSlotSelected.findIndex((item) => item === index);

    if (existedItem < 0) {
      timeSlotSelected.push(index);
    } else {
      timeSlotSelected.splice(existedItem, 1);
    }

    this.setState({
      timeSlotSelected: timeSlotSelected.sort((a, b) => a > b),
    });
  };

  handleChangePaymentMethod = (e, { value }) => {
    this.setState({ paymentMethod: value });
  };

  getTimeslots = (date) => {
    this.setState({ loadingTimeSlots: true });
    this.props
      .onGetTimeSlots({ serviceId: this.props.id, date })
      .then((response) => {
        const timeSlot = formatJson(
          response.data,
          response.data.data.type,
          response.data.data.id
        );
        let data = [];
        if (timeSlot.availableTimes) {
          data = Object.keys(timeSlot.availableTimes[date]).map(
            (k) => timeSlot.availableTimes[date][k]
          );
        }
        this.props
          .onGetServiceMembers({
            serviceId: this.props.id,
            date: date,
            times: JSON.stringify(data),
          })
          .then((response) => {
            if (response && response.data) {
              let members = [];
              if (response && response.data) {
                response.data.data.forEach((item) => {
                  const data = formatJson(response.data, item.type, item.id);
                  members.push(data);
                });
              }
              this.setState({ members: members });
            }
          });
        this.setState(
          {
            loadingTimeSlots: false,
            timeSlots: data,
          },
          () => { }
        );
      })
      .catch(() => {
        this.setState({ loadingTimeSlots: false });
      });
  };

  getDetail = () => {
    this.props.onGetServiceDetail({ id: this.props.id }).then(() => {
      this.setState({
        closedDay: this.closedDay(),
      });
    });
  };

  closedDay = () => {
    const { detail } = this.props;
    const closedDay = [];

    detail.data.tradingHours &&
      Object.keys(detail.data.tradingHours) &&
      Object.keys(detail.data.tradingHours).forEach((key) => {
        if (
          detail.data.tradingHours[key] &&
          detail.data.tradingHours[key].off
        ) {
          closedDay.push(key);
        }
      });

    return closedDay;
  };

  validateBooking = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (data.timeSlotSelected.length <= 0) {
      errors.timeSlots = intl.formatMessage({
        id: "validate.please_choose_time_slots",
      });
    }
    if (!data.paymentMethod) {
      errors.paymentMethod = intl.formatMessage({
        id: "validate.please_choose_payment_method",
      });
    }

    if (data.members && data.members.length) {
      if (!data.membersSelected || !data.membersSelected.length) {
        errors.paymentMethod = intl.formatMessage({
          id: "validate.please_choose_staff_service",
        });
      }
    }

    return errors;
  };

  continueBooking = () => {
    const errors = this.validateBooking(this.state);
    const { auth } = this.props;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const timeSlotSelected = [];

      this.state.timeSlotSelected.forEach((key) => {
        timeSlotSelected.push({
          from: this.state.timeSlots[key].from,
          to: this.state.timeSlots[key].to,
        });
      });

      let staffSelected = "";
      if (this.state.membersSelected && this.state.membersSelected.length) {
        const staffs = this.state.membersSelected.map((id) => {
          const mem = this.state.members.find(({ staff }) => staff.id === id);
          return {
            id: mem.staff.id,
            fullname: mem.staff.fullname,
          };
        });
        staffSelected = `&staffs=${JSON.stringify(staffs)}`;
      }

      const query = `booking-payment?serviceId=${this.props.serviceId
        }&date=${moment(this.state.date).format(
          "YYYY-MM-DD"
        )}&items=${JSON.stringify(timeSlotSelected)}&note=${this.state.comment
        }&optionIds=${this.getOptionsId()}${staffSelected}`;

      if (auth && auth.isAuthenticated) {
        this.props.history.push(`/${query}`);
      } else {
        this.props.history.push(
          `/signin?redirect=${encodeURIComponent(query)}`
        );
      }
    }
  };

  onSelectStaff = (id) => {
    if (this.state.membersSelected.includes(id)) {
      this.setState((prev) => ({
        membersSelected: prev.membersSelected.filter(
          (staffId) => staffId !== id
        ),
      }));
      return;
    }
    this.setState((prev) => ({
      membersSelected: [...prev.membersSelected, id],
    }));
  };

  getOptionsId = () => {
    const params = new URLSearchParams(this.props.location.search);

    if (this.props.location.search) {
      return params.get("optionIds");
    }

    const { detail } = this.props;

    if (detail.data && detail.data.options && detail.data.options.length) {
      let optionIds = [];

      detail.data.options.forEach((option) => {
        const { childrens } = option;

        if (childrens && childrens.length) {
          optionIds = [
            ...optionIds,
            ...childrens
              .filter((children) => !!children.isCheck)
              .map((children) => ({
                id: children.id,
                quantity: 1,
              })),
          ];
        }
      });

      return JSON.stringify(optionIds);
    }

    return JSON.stringify([]);
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);

    if (this.props.location.search) {
      this.setState(
        {
          date: moment(params.get("date"), "YYYY-MM-DD"),
          comment: params.get("note"),
        },
        () => {
          this.getTimeslots(moment(this.state.date).format("YYYY-MM-DD"));
        }
      );
    } else {
      this.getTimeslots(moment(this.state.date).format("YYYY-MM-DD"));
    }

    this.getDetail();

    this._notificationSystem = this.refs.notificationSystem;
  }

  render() {
    const { detail } = this.props;
    const { errors } = this.state;
    const { serviceId } = this.props;
    return (
      <Pane>
        {!detail.fetching ? (
          <Container data={detail.data}>
            <Pane className="breadcrumb-container">
              <Pane className="container">
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Breadcrumb className="breadcrumb-list">
                      <Breadcrumb.Section className="item" href={`/`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/details/home.svg"
                          }
                          alt="home"
                          className="icon"
                        />
                      </Breadcrumb.Section>
                      <Breadcrumb.Section
                        className="item"
                        href={`/services?category=${detail.data.categoryId}`}
                      >
                        {detail.data.categoryName}
                      </Breadcrumb.Section>
                      <Breadcrumb.Section className="item active" active>
                        <span className="item-norm">
                          T-
                          {detail.data.name}
                        </span>
                      </Breadcrumb.Section>
                    </Breadcrumb>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
            <Pane className="details-container">
              <Pane className="container">
                <Pane className="row">
                  <Pane className="col-lg-9 col-md-12 col-sm-12">
                    <div className="booking-conainer">
                      <div className="booking-content">
                        <h2 className="booking-title">
                          <FormattedMessage
                            id="booking"
                            defaultMessage="Booking"
                          />
                        </h2>
                        <form className="booking-form">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-item">
                                <label className="form-label">
                                  <FormattedMessage
                                    id="booking_date"
                                    defaultMessage="Booking date"
                                  />
                                </label>
                                <DayPickerSingleDateController
                                  isOutsideRange={(day) =>
                                    !isInclusivelyAfterDay(day, moment())
                                  }
                                  enableOutsideDays={false}
                                  numberOfMonths={1}
                                  date={this.state.date || new moment()}
                                  onDateChange={this.onDateChange}
                                  isDayBlocked={(momentDate) => {
                                    const dayString = momentDate.format("ddd");
                                    return (
                                      this.state.closedDay.indexOf(
                                        dayString.toLowerCase()
                                      ) !== -1
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-item">
                                <label className="form-label">
                                  <FormattedMessage
                                    id="time"
                                    defaultMessage="Time"
                                  />
                                </label>
                                <TimeSlots
                                  timeSlots={this.state.timeSlots}
                                  loading={this.state.loadingTimeSlots}
                                  onChangeTimeSlot={this.onChangeTimeSlot}
                                />
                                {errors.timeSlots && (
                                  <InlineMessage text={errors.timeSlots} />
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="row mt-3">
                            <div className="col-lg-6">
                              <div className="form-item">
                                <label for="postcode" className="form-label">
                                  Choose staff
                                </label>
                                <input type="text" className="form-input" />
                              </div>
                            </div>
                          </div> */}
                          <Pane className="row">
                            <Pane className="col-lg-12">
                              <Form.Field className="form-item">
                                <label
                                  htmlFor="staff-list"
                                  className="form-label"
                                >
                                  <FormattedMessage
                                    id="staffList"
                                    defaultMessage="Staffs"
                                  />
                                </label>
                                <Pane className="service-staff-list">
                                  {this.state.members.map(({ staff }) => {
                                    let url =
                                      process.env.PUBLIC_URL +
                                      "/assets/images/default-avatar.png";
                                    if (staff.images && staff.images.length) {
                                      const [image] = staff.images;
                                      url = image.url;
                                    }
                                    return (
                                      <Pane
                                        key={staff.id}
                                        className="staff-container"
                                        onClick={() =>
                                          this.onSelectStaff(staff.id)
                                        }
                                      >
                                        <Pane className="staff-image">
                                          <img src={url} />
                                        </Pane>
                                        <Pane className="staff-info">
                                          {staff.fullname}
                                        </Pane>
                                        {this.state.membersSelected.includes(
                                          staff.id
                                        ) ? (
                                          <Pane className="staff-check">
                                            <Icon name="check" size="large" />
                                          </Pane>
                                        ) : null}
                                      </Pane>
                                    );
                                  })}
                                </Pane>
                              </Form.Field>
                            </Pane>
                          </Pane>
                          <div className="row mt-3">
                            <div className="col-lg-12">
                              <div className="form-item">
                                <label className="form-label">
                                  <FormattedMessage
                                    id="note"
                                    defaultMessage="Note"
                                  />
                                </label>
                                <textarea
                                  name="comment"
                                  className="form-input"
                                  value={this.state.comment || ""}
                                  onChange={this.onChange}
                                  style={{ minHeight: 100 }}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="booking-footer d-flex justify-content-between">
                        <Link
                          to={`/services/${serviceId}`}
                          className="btn btn-process mt-4"
                        >
                          <FormattedMessage id="back" defaultMessage="Back" />
                        </Link>
                        <Button
                          className="btn btn-process btn-continue mt-4"
                          onClick={this.continueBooking}
                          loading={this.state.loadingBooking}
                          disabled={this.state.loadingBooking}
                        >
                          <FormattedMessage
                            id="cart.continue"
                            defaultMessage="Continue"
                          />
                        </Button>
                      </div>
                    </div>
                  </Pane>
                  <Pane className="col-lg-3 col-md-12 col-sm-12 mt-lg-0 mt-3">
                    {detail.data.userId && (
                      <StoreInfo auth={this.props.auth} detail={detail.data} />
                    )}
                    <Pane className="inner-box mt-4">
                      <h2 className="inner-title">
                        <FormattedMessage
                          id="duration"
                          defaultMessage="Duration"
                        />
                      </h2>
                      <Pane className="bussiness-content">
                        <ul className="bussiness-list">
                          <li className="bussiness-item">
                            <span className="category-norm">
                              {detail.data.duration}
                            </span>
                          </li>
                        </ul>
                      </Pane>
                    </Pane>
                    <Pane className="inner-box mt-4">
                      <h2 className="inner-title">
                        <strong>
                          <FormattedMessage
                            id="trading_hours"
                            defaultMessage="Trading Hours"
                          />{" "}
                        </strong>
                      </h2>
                      <TradingHours tradingHours={detail.data.tradingHours} />
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Container>
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}
      </Pane>
    );
  }
}

export default injectIntl(BookingService);
