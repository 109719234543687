import Config from 'constants/Config'
import { formatJson, formatRoute } from 'shared/helpers'

const exec = params => {
  const page = params.page || 1
  const size = params.size || 20
  const category = params.category || ''
  const store = params.store || ''
  const sort = params.sort || ''
  const discount = `>0`

  return {
    method: 'GET',
    url: `/products?category_id=${category}&user_id=${store}&sort=${sort}&promotion.id=${discount}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const products = []

  if (response.data) {
    response.data.forEach(item => {
      const product = formatJson(response, item.type, item.id)

      let promotion = {}
      if (product.promotion) {
        promotion = {
          id: product.promotion.id,
          percent: Number(product.promotion.percent),
          startDate: product.promotion.startDate,
          endDate: product.promotion.endDate,
          primaryImage: product.promotion.primaryImage && product.promotion.primaryImage.url,
        }
      }

      products.push({
        id: product.id,
        name: product.name,
        description: product.description,
        slug: `${formatRoute(product.name, '-')}-${product.id}`,
        primaryImage: product.primaryImage && product.primaryImage.url,
        rating: product.statistic && product.statistic.rating ? Number(product.statistic.rating) : 0,
        userId: product.user && product.user.id,
        originalPrice: Number(product.price),
        promotionPrice: Number(product.promotionPrice),
        type: 'products',
        promotion
      })
    })
  }

  return products
}

export { exec, parseResponse }
