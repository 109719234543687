import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import ReactTooltip from 'react-tooltip';
import { Rating } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { PriceItem, PromotionLabel } from 'shared/components';
import Config from 'constants/Config';

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    play: 0,
  };

  changeRoute = () => {
    this.props.history.push(`${this.props.type}/${this.props.id}/edit`);
  };

  showConfirmDelete = () => {
    const { intl } = this.props;

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({
        id: 'notification.are_you_sure_you_want_to_delete_this',
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.deleteItem();
          },
        },
        {
          label: intl.formatMessage({ id: 'no' }),
        },
      ],
    });
  };

  deleteItem = () => {
    this.props.onDelete({ id: this.props.id });
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  render() {
    const { item, name, image, rating } = this.props;
    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const ext = image != null ? image.substr(image.lastIndexOf('.') + 1) : '';
    if (this.props.modeView === Config.MODE_VIEW_MENU.MODE_LIST) {
      return (
          <Pane className="adds-item adds-list-view adds-user-list-services">
            <Pane className="adds-content">
              <Pane className="col-lg-6 col-md-6 col-sm-12 p-0">
                <Link
                  className="adds-norm"
                  to={`/${this.props.type == 'services' ? 'services' : 'products'}/${this.props.item.slug}`}
                >
                  <h5 className="adds-norm" title={name}>
                    <b>{name}</b>
                  </h5>
                </Link>
              </Pane>
              <Pane className="col-lg-2 col-md-3 col-sm-12 p-0">
                <div className="group-price">
                  <PriceItem
                    promotion={item && item.promotion}
                    originalPrice={item && item.originalPrice}
                  />
                </div>
              </Pane>
              <Pane className="col-lg-4 col-md-3 pr-md-0 text-right">
              {this.props.control && (
                <Pane className="adds-actions">
                  <span onClick={this.changeRoute} data-tip data-for="edit" className="update-service">
                    <i className="icon-pencil" />
                    <FormattedMessage id="edit" defaultMessage="Update" />
                  </span>
                  <span
                    onClick={this.showConfirmDelete}
                    data-tip
                    data-for="remove"
                    className="update-service"
                  >
                    <i className="icon-trash" />
                    <FormattedMessage id="delete" defaultMessage="Delete" />
                  </span>
                </Pane>
              )}
            </Pane>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className="col-lg-3 col-md-6 col-sm-6 col-12 pl-1 pr-1 pb-lg-2">
        <Pane className="adds-item adds-user-services">
          <Link className="hover-image" to={`/${this.props.type == 'services' ? 'services' : 'products'}/${this.props.item.slug}`}>
            {extImage.indexOf(ext) >= 0 ? (
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                }}
              >
                <PromotionLabel
                  promotion={this.props.item && this.props.item.promotion}
                  originalPrice={
                    this.props.item && this.props.item.originalPrice
                  }
                />
              </Pane>
            ) : (
              <Pane className="adds-images">
                {image ? (
                  <Pane>
                    {ext !== '' ? <i className="fa fa-video" /> : ''}
                    <video
                      className="carbeyvideo-list"
                      playsInline={1}
                      onMouseOver={this.playVideo}
                      onMouseOut={this.stopVideo}
                      muted={!this.state.play}
                    >
                      <source src={image} id="video_here" />
                      Your browser does not support HTML5 video.
                    </video>
                    <PromotionLabel
                      promotion={this.props.item && this.props.item.promotion}
                      originalPrice={
                        this.props.item && this.props.item.originalPrice
                      }
                    />
                  </Pane>
                ) : (
                  <Pane
                    className="adds-images"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                    }}
                  ></Pane>
                )}
              </Pane>
            )}
          </Link>
          <Pane className="adds-content">
            <Link to={`/${this.props.type == 'services' ? 'services' : 'products'}/${this.props.item.slug}`}>
              <h5 className="adds-norm" title={name}>
                <b>{name}</b>
              </h5>
            </Link>
            <PriceItem
              promotion={item && item.promotion}
              originalPrice={item && item.originalPrice}
            />
            {this.props.control && (
              <Pane className="adds-actions">
                <span onClick={this.changeRoute} data-tip data-for="edit" className="update-service">
                  <i className="icon-pencil" />
                  <FormattedMessage id="edit" defaultMessage="Update" />
                </span>
                <span
                  onClick={this.showConfirmDelete}
                  data-tip
                  data-for="remove"
                  className="update-service"
                >
                  <i className="icon-trash" />
                  <FormattedMessage id="delete" defaultMessage="Delete" />
                </span>
                <div className="clear"></div>
              </Pane>
            )}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
