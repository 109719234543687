import * as types from 'constants/ActionTypes'
import NotiAdapter from 'services/notis/adapter'

const initialState = {
  results: [],
  error: null,
  fetching: false,
  fetched: false
}

const notis = (state = initialState, action = {}) => {
  switch (action.type) {

    case types.REQUEST_NOTIS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_NOTIS_SUCCESS:
      return Object.assign({}, state, {
        results: NotiAdapter.browseByUser.parseResponse(action.payload.data.notis),
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_NOTIS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        results: [],
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

export {notis}