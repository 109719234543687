import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { MasterLayout } from 'components/layout';
import { Pane } from 'shared/components/layouts';

class FAQPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="policy-container mt-5">
          <Pane className="container">
            <Pane className="row">
              <Pane className="col-lg-10 offset-lg-1">
                <h3 className="policy-title mb-4">FAQs</h3>
                <FormattedHTMLMessage id="faq.template" />
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

export default injectIntl(FAQPage);
