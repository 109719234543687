import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  const page = params.page || 1
  const size = params.size || 20
  const id = params.id || ''
  const sort = params.sort || ''
  return {
    method: 'GET',
    url: `/users/${id}/categories?user_id=${id}&group=collection&page[size]=${size}&page[number]=${page}&sort=${sort}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const categories = []

  if (response.data) {
    response.data.forEach(item => {
      const collection = formatJson(response, item.type, item.id)

      categories.push(collection)
    })
  }

  const data = categories

  return data
}

export { exec, parseResponse }
