import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Intro from '../partials/Intro';

class StaticLayout extends Component {
  render() {
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {})
    );
    const text = this.props.text;

    return (
      <Pane id="wrapper">
        <Header />
        <Intro text={text} />
        <main className="main-container">{childrenWithProps}</main>
        <Footer />
      </Pane>
    );
  }
}

StaticLayout.propTypes = {
  text: PropTypes.string,
};

export default StaticLayout;
