import * as LikeBrowseV1 from './like.browse.v1.action'
import * as LikeVideoV1 from './like.like.v1.action'
import * as UserLikeBrowseV1 from './like.userBrowse.v1.action'

export default {
  // get user like video
  userBrowse: {
    callAPI: params => UserLikeBrowseV1.exec(params),
    parseResponse: response => UserLikeBrowseV1.parseResponse(response)
  },
  // get like video
  browse: {
    callAPI: params => LikeBrowseV1.exec(params),
    parseResponse: response => LikeBrowseV1.parseResponse(response)
  },
  // like video
  like: {
    callAPI: params => LikeVideoV1.exec(params),
    parseResponse: response => LikeVideoV1.parseResponse(response)
  }
}