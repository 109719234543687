import * as ReviewBrowseV1 from './review.browse.v1.action'
import * as ReviewEditV1 from './review.edit.v1.action'
import * as ReviewVideoBrowseV1 from './review.browsevideo.v1.action'
import * as VideoReviewV1 from './review.reviewvideo.v1.action'

export default {
  // get review product
  browse: {
    callAPI: params => ReviewBrowseV1.exec(params),
    parseResponse: response => ReviewBrowseV1.parseResponse(response)
  },
  // get review video
  browseVideo: {
    callAPI: params => ReviewVideoBrowseV1.exec(params),
    parseResponse: response => ReviewVideoBrowseV1.parseResponse(response)
  },
  // get review video
  review: {
    callAPI: params => VideoReviewV1.exec(params),
    parseResponse: response => VideoReviewV1.parseResponse(response)
  },
  // Edit review
  reviewEdit: {
    callAPI: (params) => ReviewEditV1.exec(params),
    parseResponse: (response) => ReviewEditV1.parseResponse(response),
  },
}