import * as LoginV1 from './login.v1';
import * as SocialLoginV1 from './socical.v1';
import * as Subscribe from './subscribe.v1';

const parseResponse = (response) => {
  const data = {
    accessToken: response.data.attributes.accessToken,
    refreshToken: response.data.attributes.refreshToken,
    expiresIn: response.data.attributes.expiresIn,
    userId: response.data.attributes.userId,
  };

  return data;
};

export default {
  login: {
    callAPI: (params) => LoginV1.exec(params),
    parseResponse: (response) => parseResponse(response),
  },
  socialLogin: {
    callAPI: (params) => SocialLoginV1.exec(params),
    parseResponse: (response) => parseResponse(response),
  },
  subScribe: {
    callAPI: (params) => Subscribe.exec(params),
    parseResponse: (response) => parseResponse(response),
  },
};
