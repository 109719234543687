import * as types from "constants/ActionTypes";
import api from "api";
export const getFirstScanQrPromotionsByUserId = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_MY_QR_PROMOTION,
      types.RECEIVE_MY_QR_PROMOTION_SUCCESS,
      types.RECEIVE_MY_QR_PROMOTION_FAILURE,
    ],
    payload: {
      request: api.user.scanQrPromotions(params),
    },
  });
export const getCustomerByFirstScanQrPromotion = (params) => (dispatch) =>
  dispatch({
    types: types.REQUEST_MY_CUSTOMER_BY_QR_PROMOTION,
    payload: {
      request: api.user.scanQrPromotionDetail(params),
    },
  });
export const getFirstScanQrPromotionsDetail = (params) => (dispatch) =>
  dispatch({
    types: types.MY_QR_PROMOTION_DETAIL,
    payload: {
      request: api.user.readScanQrPromotion(params),
    },
  });
export const createFirstScanQrPromotion = (params) => (dispatch) =>
  dispatch({
    types: types.CREATE_MY_QR_PROMOTION,
    payload: {
      request: api.user.addScanQrPromotion(params),
    },
  });
export const editFirstScanQrPromotion = (params) => (dispatch) =>
  dispatch({
    types: types.EDIT_MY_QR_PROMOTION,
    payload: {
      request: api.user.editScanQrPromotion(params),
    },
  });
export const removeFirstScanQrPromotion = (params) => (dispatch) =>
  dispatch({
    types: types.REMOVE_MY_QR_PROMOTION,
    payload: {
      request: api.user.removeScanQrPromotion(params),
    },
  });