import PropTypes from 'prop-types';
import Config from 'constants/Config';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';
import { confirmAlert } from 'react-confirm-alert';
import { PromotionLabel } from 'shared/components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    play: 0,
  };

  changeRoute = () => {
    this.props.history.push(`my-videos/${this.props.id}/edit`);
  };

  showConfirmDelete = () => {
    const { intl } = this.props;

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({
        id: 'notification.are_you_sure_you_want_to_delete_this',
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.deleteItem();
          },
        },
        {
          label: intl.formatMessage({ id: 'no' }),
        },
      ],
    });
  };

  deleteItem = () => {
    this.props.onDelete({ id: this.props.id });
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    video.play();
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  render() {
    const { name, image, viewVideo, likeVideo } = this.props;
    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const ext = image != null ? image.substr(image.lastIndexOf('.') + 1) : '';
    return (
      <Pane className="col-lg-3 col-md-4 col-sm-6 col-12">
        <Pane className="adds-item">
          <Pane>
            {extImage.indexOf(ext) >= 0 ? (
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : process.env.PUBLIC_URL +
                    `/assets/images/default-image.png`,
                  height: 'auto',
                }}
              >
                <PromotionLabel
                  promotion={this.props.item && this.props.item.promotion}
                  originalPrice={
                    this.props.item && this.props.item.originalPrice
                  }
                />
              </Pane>
            ) : (
              <Pane
                className="adds-videos-wrapper"
                style={{ height: 100 }}
              >
                {ext !== '' ? <i className="fa fa-video" /> : ''}
                <video
                  className="myvideo-list"
                  playsInline={1}
                  onMouseOver={this.playVideo}
                  onMouseOut={this.stopVideo}
                  muted={!this.state.play}
                >
                  <source src={image} id="video_here" />
                  Your browser does not support HTML5 video.
                </video>
                <PromotionLabel
                  promotion={this.props.item && this.props.item.promotion}
                  originalPrice={
                    this.props.item && this.props.item.originalPrice
                  }
                />
              </Pane>
            )}
          </Pane>
          <Pane className="adds-content">
            <Pane>
              <h5 className="" title={name}>
                <p className='mb-1'>
                  <b>{name}</b>
                </p>
                <Pane className="d-flex justify-content-between" style={{ fontStyle: 'italic' }}>
                  <Pane>
                    {viewVideo} view
                  </Pane>
                  <Pane className="d-flex">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/icons/like-gray.svg'
                      }
                      alt="image"
                      aria-hidden
                      className="icon mr-1 ml-1"
                      style={{
                        height: 15,
                        width: 15,
                      }}
                    />
                    {likeVideo} like{likeVideo > 0 && ('s')}
                  </Pane>
                </Pane>
              </h5>
            </Pane>
            {this.props.control && (
              <Pane className="adds-actions">
                <span onClick={this.changeRoute} data-tip data-for="edit">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/edit.svg`
                    }
                    alt="cart"
                    className="icon-edit"
                  />
                </span>
                <span
                  onClick={this.showConfirmDelete}
                  data-tip
                  data-for="remove"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/delete.svg`
                    }
                    alt="cart"
                    className="icon-delete"
                  />
                </span>
              </Pane>
            )}
          </Pane>
        </Pane>
        <ReactTooltip place="top" type="dark" effect="solid" id="edit">
          <FormattedMessage id="edit" defaultMessage="Edit" />
        </ReactTooltip>
        <ReactTooltip place="top" type="dark" effect="solid" id="remove">
          <FormattedMessage id="delete" defaultMessage="Delete" />
        </ReactTooltip>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
