import * as types from 'constants/ActionTypes';
import api from 'api';

// category type = product
export const getProductCategories = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_PRODUCT_CATEGORIES,
      types.RECEIVE_PRODUCT_CATEGORIES_SUCCESS,
      types.RECEIVE_PRODUCT_CATEGORIES_FAILURE,
    ],
    payload: {
      request: api.category.productCategories(params),
    },
  });

// category type = service
export const getServiceCategories = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_SERVICE_CATEGORIES,
      types.RECEIVE_SERVICE_CATEGORIES_SUCCESS,
      types.RECEIVE_SERVICE_CATEGORIES_FAILURE,
    ],
    payload: {
      request: api.category.serviceCategories(params),
    },
  });

// category type = service
export const getCollectionCategories = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_COLLECTION_CATEGORIES,
      types.RECEIVE_COLLECTION_CATEGORIES_SUCCESS,
      types.RECEIVE_COLLECTION_CATEGORIES_FAILURE,
    ],
    payload: {
      request: api.category.collectionCategories(params),
    },
  });

// Create a product
export const createCategory = (params) => (dispatch) =>
  dispatch({
    type: types.CREATE_CATEGORY,
    payload: {
      request: api.user.categoryAdd(params),
    },
  });

// category type = service
export const getFoodOrderCategories = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_PRODUCT_CATEGORIES_FOOD_ORDER,
      types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_SUCCESS,
      types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_FAILURE,
    ],
    payload: {
      request: api.category.foodOrderCategories(params),
    },
  });

// category type = service
export const getFoodOrderUserCategories = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_PRODUCT_CATEGORIES_FOOD_ORDER,
      types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_SUCCESS,
      types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_FAILURE,
    ],
    payload: {
      request: api.user.getFoodOrderUserCategories(params),
    },
  });

// category type = service
export const getDetailCategories = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_PRODUCT_CATEGORIES_FOOD_ORDER,
      types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_SUCCESS,
      types.RECEIVE_PRODUCT_CATEGORIES_FOOD_ORDER_FAILURE,
    ],
    payload: {
      request: api.category.detailCategory(params),
    },
  });
