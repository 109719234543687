import * as types from 'constants/ActionTypes';
import api from 'api';

// Products
export const screenActivityAdd = (params) => (dispatch) => dispatch({
  types: [types.ADD_SCREEN_ACTIVITY],
  payload: {
    request: api.user.screenActivityAdd(params)
  }
});

