import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Loader } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Pane, Loading } from 'shared/components/layouts';
import InlineMessage from 'shared/components/InlineMessage';
import { formatRoute, handleError } from 'shared/helpers';
import ProductAdapter from 'services/users/adapter';

class CreateProductPickForm extends Component {
  static propTypes = {
    products: PropTypes.object,
    intl: intlShape.isRequired,
  };

  state = {
    data: {
      html_code: '',
      is_amazon: 0
    },
    errors: {},
    open: false,
    loading: false,
    fetching: false,
    fetched: false,
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.html_code) {
      errors.html_code = intl.formatMessage({ id: 'validate.require_field' });
    }
    return errors;
  };

  editProduct = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new FormData();
      data.append('html_code', this.state.data.html_code);
      data.append('is_amazon', this.state.is_amazon || 1);
      data.append('id', this.props.id);
      this.props
        .editMyProduct(data)
        .then(() => {
          this.setState({
            loading: false,
            open: false,
            data: {
              ...this.state.data,
            },
          });
          this.props.history.push(
            `/u/${formatRoute(this.props.userInfo.displayName)}/product-picks`
          );
        })
        .catch((error) => {
          const errors = {};
          errors.message = handleError(
            error,
            this.props.intl.formatMessage({
              id: 'notification.please_try_again',
            })
          );

          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  componentDidMount() {
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props
      .getMyProductPicksDetail({ id: this.props.id })
      .then((response) => {
        const product = ProductAdapter.readPicks.parseResponse(response.data);
        this.setState({
          fetched: true,
          fetching: false,
          data: {
            html_code: product.htmlCode
          },
        });
      });
  }

  render() {
    const { errors, data, loading, fetched, fetching } = this.state;
    return (
      <Form className="form-overview pb-5" onSubmit={this.editProduct}>
        {fetched && !fetching ? (
          <>
            <Pane className="form-content">
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field error={!!errors.name} className="form-item">
                    <label htmlFor="email" className="form-label">
                      <FormattedMessage
                        id="html_code"
                        defaultMessage="Html Code"
                      />
                    </label>
                    <textarea
                      id="html_code"
                      name="html_code"
                      placeholder=""
                      className="form-input"
                      value={data.html_code}
                      onChange={this.onChange}
                      rows="6"
                    />
                    {errors.html_code && (
                      <InlineMessage text={errors.html_code} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  {errors.message && <InlineMessage text={errors.message} />}
                </Pane>
              </Pane>
            </Pane>
            <Pane className="row">
              <Pane className="col-lg-12 text-center">
                <Button
                  type="submit"
                  className="btn btn-save mt-4"
                  disabled={loading}
                  loading={loading}
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </Pane>
            </Pane>
          </>
        ) : (
          <>
            <Pane className="pt30">
              <Loading />
            </Pane>
          </>
        )}
      </Form>
    );
  }
}

export default injectIntl(CreateProductPickForm);
