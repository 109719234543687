import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";
import { Pane } from "shared/components/layouts";
import { PromotionLabel } from "shared/components";
import Config from "constants/Config";
import ReactPlayer from "react-player";

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
    onReviewsVideo: PropTypes.func,
    onGetLikesVideo: PropTypes.func,
  };

  state = {
    play: 0,
    like: 0,
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    video.play();
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  onChooseVideo = () => {
    this.props.onChooseVideo(this.props.item);
    this.props.onReviewsVideo({
      id: this.props.item.id,
      type: Config.VIDEO_TYPE,
    });
    this.props.onGetLikesVideo({
      id: this.props.item.id,
    });
  };

  render() {
    const { name, image, viewVideo, likeVideo } = this.props;
    const extImage = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];
    const ext = image != null ? image.substr(image.lastIndexOf(".") + 1) : "";
    return (
      <Pane className="col-12">
        <Pane className="adds-item" onClick={this.onChooseVideo}>
          <Pane className="adds-item-video">
            {extImage.indexOf(ext) >= 0 ? (
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : process.env.PUBLIC_URL +
                    `/assets/images/default-image.png`,
                  height: "auto",
                }}
              >
                <PromotionLabel
                  promotion={this.props.item && this.props.item.promotion}
                  originalPrice={
                    this.props.item && this.props.item.originalPrice
                  }
                />
              </Pane>
            ) : (
              <Pane
                className="adds-videos-wrapper"
                style={{
                  height: "auto",
                  cursor: "pointer"
                }}
              >
                <ReactPlayer
                  url={image}
                  className="react-player banner-video-over"
                  // controls
                  playsinline={false}
                  playing={false}
                  config={{
                    file: {
                      attributes: {
                        autoPlay: false,
                        muted: false,
                      },
                    },
                  }}
                />
                <span className="play-icon"></span>
                <PromotionLabel
                  promotion={this.props.item && this.props.item.promotion}
                  originalPrice={
                    this.props.item && this.props.item.originalPrice
                  }
                />
              </Pane>
            )}
          </Pane>
          <Pane className="adds-content">
            <Pane className="adds-item-video">
              <h5 className="" title={name}>
                <p className='mb-1'>
                  <b>{name}</b>
                </p>
                <Pane className="d-flex justify-content-between" style={{ fontStyle: 'italic' }}>
                  <Pane>
                    {viewVideo} view
                  </Pane>
                  <Pane className="d-flex">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/icons/like-gray.svg'
                      }
                      alt="image"
                      aria-hidden
                      className="icon mr-1 ml-1"
                      style={{
                        height: 15,
                        width: 15,
                      }}
                    />
                    {likeVideo} like{likeVideo > 0 && ('s')}
                  </Pane>
                </Pane>
              </h5>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
