import auth from './auth';
import { locale } from './locale';
import myBusiness from './myBusiness';
import { combineReducers } from 'redux';
import { cart, guestCart } from './cart';
import { routerReducer } from 'react-router-redux';
import {
  products,
  myProducts,
  featureProducts,
  productsPromotion,
  productsSlidePromotion,
} from './products';
import { productDetail } from './productDetail';
import { myProductPicks } from './product-picks';
import {
  services,
  myServices,
  featureServices,
  servicesSlidePromotion,
} from './services';
import {
  myCollections,
} from './collections';
import { stores } from './stores';
import { banners } from './banner';
import { reviews } from './reviews';
import { options } from './options';
import { mySlides } from './slides';
import { allVideos } from './allVideo';
import { likes, userLike } from './like';
import { serviceDetail } from './serviceDetail';
import { bannersDigital } from './bannerDigital';
import { buyingOrders, sellingOrders, ordersBookings } from './orders';
import { myVideos, videos, videoById } from './myVideo';
import { searchProducts, searchServices } from './search';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { bookedServices, bookingsForSeller } from './bookings';
import { productCategories, serviceCategories, collectionCategories } from './category';
import { promotionProducts, promotionServices } from './promotions';
import { buyingOrderDetail, sellingOrderDetail } from './orderDetail';
import { buyingBookingDetail, sellingBookingDetail } from './bookingDetail';
import { deliveries, deliveriesSchedule, deliveryDetail } from './deliveries';
import { businesses, businessLogos, businessesByCategory } from './business';
import { statistics } from './statistics';
import { digitals } from './digitals';
import { screens } from './screens';
import userContacts from './userContacts';
import { screenCount } from './screenCount';
import { scanCounts } from './scanCounts';
import storeBookings from './storeBookings';
import posCart from './posCart';
import coordinates from './coordinates';
import area from './area';
import {notis} from './notis';
import { myProjects } from './projects'
import { myTasks } from './tasks';
import { myStaffs } from './staffs';
import { myFirstScanQrPromotions } from './firstScanQrPromotion';


const rootReducer = combineReducers({
  digitals,
  screens,
  auth,
  cart,
  likes,
  locale,
  stores,
  videos,
  banners,
  reviews,
  products,
  services,
  mySlides,
  myVideos,
  userLike,
  guestCart,
  allVideos,
  videoById,
  deliveries,
  myProducts,
  myServices,
  myCollections,
  myBusiness,
  businesses,
  statistics,
  buyingOrders,
  productDetail,
  serviceDetail,
  businessLogos,
  sellingOrders,
  ordersBookings,
  myProductPicks,
  bannersDigital,
  bookedServices,
  searchProducts,
  searchServices,
  deliveryDetail,
  featureProducts,
  featureServices,
  productsPromotion,
  productCategories,
  serviceCategories,
  collectionCategories,
  promotionProducts,
  promotionServices,
  bookingsForSeller,
  buyingOrderDetail,
  deliveriesSchedule,
  sellingOrderDetail,
  buyingBookingDetail,
  businessesByCategory,
  sellingBookingDetail,
  productsSlidePromotion,
  servicesSlidePromotion,
  routing: routerReducer,
  loadingBar: loadingBarReducer,
  userContacts,
  scanCounts,
  screenCount,
  options,
  storeBookings,
  coordinates,
  area,
  notis,
  myProjects,
  myTasks,
  myStaffs,
  posCart,
  myFirstScanQrPromotions
});

export default rootReducer;
