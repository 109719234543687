import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Select,
  Image as ImageS,
  Icon,
  Checkbox,
  Loader,
} from "semantic-ui-react";
import { isEmpty } from "lodash";
import Config from "constants/Config";
import queryString from "query-string";
import SelectImage from "react-select";
import TreeSelect from "rc-tree-select";
import createClass from "create-react-class";
import { Pane, Loading } from "shared/components/layouts";
import CollectionAdapter from "services/collections/adapter";
import { injectIntl, FormattedMessage } from "react-intl";
import { formatRoute, handleError, quill } from "shared/helpers";
import InlineMessage from "shared/components/InlineMessage";
import ReactQuill from "react-quill";

class EditCollectionForm extends Component {
  static propTypes = {
    collections: PropTypes.object,
    categories: PropTypes.object.isRequired,
    getMyCollections: PropTypes.func.isRequired,
    getMyCollectionDetail: PropTypes.func.isRequired,
  };

  state = {
    images: [],
    imageCategory: {
      label: "/images/category/18.Collectables.png",
      value: "/images/category/18.Collectables.png",
    },
    default_images: [
      {
        label: "/images/category/18.Collectables.png",
        value: "/images/category/18.Collectables.png",
      },
      {
        label: "/images/category/17.Coins.png",
        value: "/images/category/17.Coins.png",
      },
      {
        label: "/images/category/16.Clothing,Shoes,Accessories.png",
        value: "/images/category/16.Clothing,Shoes,Accessories.png",
      },
      {
        label: "/images/category/7.Cameras.png",
        value: "/images/category/7.Cameras.png",
      },
      {
        label: "/images/category/6.Business.png",
        value: "/images/category/6.Business.png",
      },
      {
        label: "/images/category/5.Books,Magazines.png",
        value: "/images/category/5.Books,Magazines.png",
      },
      {
        label: "/images/category/3.Art.png",
        value: "/images/category/3.Art.png",
      },
      {
        label: "/images/category/2.Antiques.png",
        value: "/images/category/2.Antiques.png",
      },
      {
        label: "/images/category/56.Consultingservices.png",
        value: "/images/category/56.Consultingservices.png",
      },
      {
        label: "/images/category/57.Educationservices.png",
        value: "/images/category/57.Educationservices.png",
      },
      {
        label: "/images/category/58.Legalservices.png",
        value: "/images/category/58.Legalservices.png",
      },
      {
        label: "/images/category/59.Healthandbeautyservices.png",
        value: "/images/category/59.Healthandbeautyservices.png",
      },
      {
        label: "/images/category/65.Handymanservices.png",
        value: "/images/category/65.Handymanservices.png",
      },
      {
        label: "/images/category/66.Cleaningservices.png",
        value: "/images/category/66.Cleaningservices.png",
      },
      {
        label: "/images/category/67.Constructionservices.png",
        value: "/images/category/67.Constructionservices.png",
      },
      {
        label: "/images/category/71.Entertainmentservices.png",
        value: "/images/category/71.Entertainmentservices.png",
      },
      {
        label: "/images/category/72.House-careservices.png",
        value: "/images/category/72.House-careservices.png",
      },
      {
        label: "/images/category/73.Financialservices.png",
        value: "/images/category/73.Financialservices.png",
      },
      {
        label: "/images/category/77.Foodservices.png",
        value: "/images/category/77.Foodservices.png",
      },
      {
        label: "/images/category/78.Informationservices.png",
        value: "/images/category/78.Informationservices.png",
      },
      {
        label: "/images/category/19.Computers-Tablets&Networking.png",
        value: "/images/category/19.Computers-Tablets&Networking.png",
      },
      {
        label: "/images/category/15.Cars,Bikes,Boats.png",
        value: "/images/category/15.Cars,Bikes,Boats.png",
        width: "70px",
      },
      {
        label: "/images/category/4.Baby.png",
        value: "/images/category/4.Baby.png",
      },
    ],
    newImageCategory: false,
    category_images: [],
    imageFiles: [],
    imageCategoryFiles: [],
    data: {
      name: "",
      categoryId: 1,
      description: "",
      type: "",
    },
    dataCategory: {
      name: "",
      group: "",
    },
    newCategory: false,
    errors: {},
    categories: [],
    groups: [],
    open: false,
    loading: false,
    group: queryString.parse(this.props.history.location.search).group || "",
    fetching: false,
    fetched: false,
    types: [
      {
        text: "Video",
        value: "Video",
      },
      {
        text: "Image",
        value: "Image",
      },
      {
        text: "Post",
        value: "Post",
      },
    ],
    isUpload: false,
    accept: ''
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  onChangeCategory = (e) => {
    this.setState({
      dataCategory: {
        ...this.state.dataCategory,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeImageCategory = (e, { value }) => {
    this.setState({
      newImageCategory: value === "NEW",
    });
  };

  convertImgToDataURLviaCanvas = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // works for me
      img.onload = function() {
        let canvas = document.createElement("CANVAS");
        const ctx = canvas.getContext("2d");
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
        canvas = null;
      };
      img.onerror = reject;
      img.src = src;
    });
  };

  onSelectGroup = (e, s) => {
    this.setState({
      dataCategory: { ...this.state.dataCategory, [s.name]: s.value },
    });
  };

  handleGroup = () => {
    const groups = [
      {
        text: "Collection",
        value: "Collection",
      },
    ];
    this.setState({
      groups,
    });
  };

  handleChangeCheckbox = (e, { value }) => {
    this.setState({
      newCategory: value,
    });
  };

  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.images.length < 6 &&
          this.state.images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  onChangeCategoryImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.category_images.length < 6 &&
          this.state.category_images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.category_images;
            const imageFiles = this.state.imageCategoryFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              category_images: images,
              imageCategoryFiles: imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  onChangeType = (e, s) => {
    this.setState({
      data: { ...this.state.data, type: s.value },
    }, () => {
      let type = s.value;
      if (type === 'Image') {
        this.setState({
          isUpload: true,
          accept: 'image/*'
        })
      } else if (type === 'Video') {
        this.setState({
          isUpload: true,
          accept: 'video/*'
        })
      } else {
        this.setState({
          isUpload: false,
        })
      }
    });
  };

  handleCategory = (categories) => {
    const array = [];
    categories &&
      categories.forEach((item) => {
        array.push({
          key: item.id,
          value: item.id,
          text: item.name,
        });
      });

    this.setState({
      categories: array,
    });
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  removeCategoryImage = (index) => {
    const images = this.state.category_images;
    const imageFiles = this.state.imageCategoryFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      category_images: images,
      imageCategoryFiles: imageFiles,
    });
  };

  validate = (data, dataCategory) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.type) {
      errors.type = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
    if (this.state.newCategory === false && !data.categoryId) {
      errors.categoryId = intl.formatMessage({ id: "validate.require_field" });
    }
    if (this.state.newCategory === true) {
      if (!dataCategory.name) {
        errors.category_name = intl.formatMessage({
          id: "validate.require_field",
        });
      }
      if (!dataCategory.group) {
        errors.category_group = intl.formatMessage({
          id: "validate.require_field",
        });
      }
    }

    return errors;
  };

  editCollection = async () => {
    const errors = this.validate(this.state.data, this.state.dataCategory);
    const { userInfo } = this.props;
    let categoryId;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      if (this.state.newCategory === true) {
        const dataCategory = new FormData();
        if (this.state.imageCategoryFiles[0]) {
          dataCategory.append(
            "primary_image",
            this.state.imageCategoryFiles[0]
          );
        }
        if (this.state.newImageCategory === false) {
          dataCategory.append("primary_image", this.state.imageCategory.value);
        }
        dataCategory.append("name", this.state.dataCategory.name);
        dataCategory.append("group", this.state.dataCategory.group);
        dataCategory.append("user_id", userInfo.id);
        dataCategory.append(
          "sub_image_1",
          this.state.imageCategoryFiles[1] || ""
        );
        dataCategory.append(
          "sub_image_2",
          this.state.imageCategoryFiles[2] || ""
        );
        dataCategory.append(
          "sub_image_3",
          this.state.imageCategoryFiles[3] || ""
        );
        dataCategory.append(
          "sub_image_4",
          this.state.imageCategoryFiles[4] || ""
        );
        dataCategory.append(
          "sub_image_5",
          this.state.imageCategoryFiles[5] || ""
        );

        try {
          const {
            data: { data },
          } = await this.props.createCategory(dataCategory);
          this.setState({
            open: false,
            dataCategory: {
              ...this.state.dataCategory,
            },
          });
          categoryId = data.id;
          this.saveCollection(categoryId).then(() => {
            this.setState({
              loading: false,
              errors,
            });
          });
        } catch (error) {
          const errors = {};
          errors.category_message =
            "Category: " +
            handleError(
              error,
              this.props.intl.formatMessage({
                id: "notification.please_try_again",
              })
            );

          this.setState({
            loading: false,
            errors,
          });
        }
      } else {
        this.saveCollection(this.state.data.categoryId);
      }
    }
  };

  saveCollection = (categoryId) => {
    const data = new FormData();
    this.state.imageFiles[0] &&
      this.state.imageFiles[0].type &&
      data.append("primary_image", this.state.imageFiles[0]);
    data.append("category_id", categoryId);
    data.append("name", this.state.data.name);
    data.append("description", this.state.data.description);
    data.append("type", this.state.data.type);
    data.append("id", this.props.id);

    this.props
      .editMyCollection(data)
      .then((response) => {
        this.setState({ loading: false });

        this.props.history.push(
          `/u/${formatRoute(this.props.userInfo.displayName)}/collections`
        );
      })
      .catch((error) => {
        const errors = {};
        errors.message =
          "Collection: " +
          handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );

        this.setState({
          loading: false,
          errors,
        });
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCategory(nextProps.categories);
    this.handleGroup();
  }

  componentDidMount() {
    const images = [];
    const imageFiles = [];
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props.getMyCollectionDetail({ id: this.props.id }).then((response) => {
      const collection = CollectionAdapter.read.parseResponse(response.data);

      if (collection.primaryImage) {
        images.push(collection.primaryImage);
        imageFiles.push(collection.primaryImage);
      }

      this.setState({
        images,
        imageFiles,
        fetched: true,
        fetching: false,
        data: {
          name: collection.name || "",
          categoryId: collection.categoryId || "",
          description: collection.description || "",
          type: collection.type || "",
        },
      }, () => {
        let type = this.state.data.type || "";
        if ( type === 'Image') {
          this.setState({
            isUpload: true,
            accept: 'image/*'
          })
        } else if (type === 'Video') {
          this.setState({
            isUpload: true,
            accept: 'video/*'
          })
        } else {
          this.setState({
            isUpload: false,
          })
        }
      });
    });
  }

  nestPath = (data) => {
    if (!isEmpty(data)) {
      return data.map((item, index) => ({
        index,
        key: item.id,
        pId: item.id,
        title: item.name,
        value: item.id,
        children: this.nestPath(item.children),
      }));
    }
    return [];
  };

  onSelectTreeSelect = (value) => {
    this.setState((prevState) => ({
      data: { ...prevState.data, categoryId: value },
    }));
  };

  render() {
    const {
      errors,
      data,
      dataCategory,
      loading,
      fetched,
      fetching,
      accept, 
      isUpload, 
      types,
    } = this.state;
    const { intl } = this.props;

    return (
      <Form className="form-overview pb-5" onSubmit={this.editCollection}>
        {fetched && !fetching ? (
          <>
            <Pane className="form-content">
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field error={!!errors.name} className="form-item">
                    <Form.Field
                        control={Select}
                        scrolling
                        className="form-item"
                        name="group"
                        error={!!errors.type}
                        label={intl.formatMessage({
                          id: "type",
                          defaultMessage: "Type",
                        })}
                        value={data.type}
                        options={types}
                        placeholder={this.props.intl.formatMessage({
                          id: "select_type",
                          defaultMessage: "Select Type",
                        })}
                        onChange={this.onChangeType}
                      />
                    {errors.name && <InlineMessage text={errors.type} />}
                  </Form.Field>
                </Pane>
              </Pane>
              {isUpload && (
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      {data.type}
                      <input
                        type="file"
                        id="image-menu"
                        className="form-input-file"
                        accept={accept}
                        value={data.image}
                        onChange={this.onChangeImage}
                      />
                      <br />
                      <label htmlFor="image-menu" className="form-label-file">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/icons/upload.svg`
                          }
                          alt="upload"
                          className="icon"
                        />
                        Upload a {data.type}
                      </label>
                      <Pane
                        className="slider-content-main d-flex"
                        style={{ padding: "10px 0" }}
                      >
                        {this.state.images &&
                          this.state.images.map((item, index) => {
                            if (data.type === 'Image') {
                              // Image
                              return (
                                <Pane
                                  className="slide-item mr-2"
                                  key={index}
                                  style={{ float: "left" }}
                                >
                                  <Icon
                                    name="remove circle"
                                    onClick={this.removeImage.bind(this, index)}
                                  />
                                  <ImageS src={item} size="small" />
                                </Pane>
                              );
                            }
                            // Video
                            return (
                              <Pane
                                className="slide-item"
                                key={index}
                                style={{ float: "left" }}
                              >
                                <Icon
                                  name="remove circle"
                                  onClick={this.removeImage.bind(this, index)}
                                />
                                <video width="150" controls>
                                  <source src={item} id="video_here" />
                                  Your browser does not support HTML5 video.
                                </video>
                              </Pane>
                            );
                          })}
                      </Pane>
                    </Form.Field>
                  </Pane>
                </Pane>
              )}
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field error={!!errors.name} className="form-item">
                    <label htmlFor="name" className="form-label">
                      <FormattedMessage id="name" defaultMessage="Name" />
                    </label>
                    <input
                      className="form-input"
                      value={data.name}
                      onChange={this.onChange}
                      type="text"
                      id="name"
                      name="name"
                    />
                    {errors.name && <InlineMessage text={errors.name} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    error={!!errors.description}
                    className="form-item"
                  >
                    <label htmlFor="description" className="form-label">
                      <FormattedMessage
                        id="description"
                        defaultMessage="Description"
                      />
                    </label>
                    <ReactQuill
                      value={this.state.data.description}
                      onChange={(content) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            description: content,
                          },
                        }));
                      }}
                      formats={quill.formats}
                      modules={quill.modules}
                    />
                    {errors.description && (
                      <InlineMessage text={errors.description} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <div className="choose-payment">
                    <Form.Field className="choose-item">
                      <Checkbox
                        radio
                        label={intl.formatMessage({
                          id: "select.category",
                          defaultMessage: "Select category",
                        })}
                        name="isNewCategory"
                        value={false}
                        checked={this.state.newCategory === false}
                        onChange={this.handleChangeCheckbox}
                      />
                    </Form.Field>
                    <Form.Field className="choose-item">
                      <Checkbox
                        radio
                        label={intl.formatMessage({
                          id: "select.category_add",
                          defaultMessage: "Add new category",
                        })}
                        name="isNewCategory"
                        value={true}
                        checked={this.state.newCategory === true}
                        onChange={this.handleChangeCheckbox}
                      />
                    </Form.Field>
                  </div>
                </Pane>
              </Pane>
              {this.state.newCategory === false ? (
                <Form.Field className="form-item" error={!!errors.categoryId}>
                  <label htmlFor="categoryId" className="form-label">
                    <FormattedMessage
                      id="category"
                      defaultMessage="Unit Price"
                    />
                  </label>
                  <TreeSelect
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
                    placeholder={<i>Select</i>}
                    searchPlaceholder="Search..."
                    treeDefaultExpandAll
                    treeData={this.nestPath(this.props.categories)}
                    treeNodeFilterProp="title"
                    value={data.categoryId}
                    onChange={this.onMultipleChange}
                    onSelect={this.onSelectTreeSelect}
                    allowClear
                  />
                  {errors.categoryId && (
                    <InlineMessage text={errors.categoryId} />
                  )}
                </Form.Field>
              ) : (
                <>
                  <Pane className="row">
                    <Pane className="col-lg-12">
                      <Form.Field
                        error={!!errors.category_name}
                        className="form-item"
                      >
                        <label htmlFor="category" className="form-label">
                          <FormattedMessage
                            id="category_name"
                            defaultMessage="Category Name"
                          />
                        </label>
                        <input
                          type="text"
                          id="category_name"
                          name="name"
                          placeholder=""
                          className="form-input"
                          value={dataCategory.name}
                          onChange={this.onChangeCategory}
                        />
                        {errors.category_name && (
                          <InlineMessage text={errors.category_name} />
                        )}
                      </Form.Field>
                    </Pane>
                  </Pane>
                  <Pane className="row">
                    <Pane className="col-lg-12">
                      <Form.Field
                        control={Select}
                        scrolling
                        className="form-item"
                        name="group"
                        error={!!errors.category_group}
                        label={
                          <label htmlFor="nameCategory" className="form-label">
                            {intl.formatMessage({
                              id: "group",
                              defaultMessage: "Group",
                            })}
                          </label>
                        }
                        value={dataCategory.group}
                        options={this.state.groups}
                        placeholder="Select"
                        onChange={this.onSelectGroup}
                      />
                    </Pane>
                  </Pane>
                  <Form.Field>
                    <Checkbox
                      radio
                      label="Select a image"
                      name="newImageCategory"
                      value="SELECT"
                      checked={this.state.newImageCategory === false}
                      onChange={this.handleChangeImageCategory}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      radio
                      label="Add new image"
                      name="newImageCategory"
                      value="NEW"
                      checked={this.state.newImageCategory === true}
                      onChange={this.handleChangeImageCategory}
                    />
                  </Form.Field>
                  {this.state.newImageCategory === false ? (
                    <Form.Field className="form-group">
                      <label htmlFor="category_image">
                        <FormattedMessage
                          id="category_image"
                          defaultMessage="Category Image"
                        />
                      </label>
                      <SelectImage
                        valueComponent={createClass({
                          render() {
                            return (
                              <Pane
                                className="Select-value"
                                title={
                                  this.props.option
                                    ? this.props.option.label
                                    : ""
                                }
                              >
                                <span className="Select-value-label">
                                  {this.props.option && (
                                    <img
                                      crossOrigin="anonymous"
                                      width="70px"
                                      alt="anonymous"
                                      height="50px"
                                      src={
                                        Config.NEW_API_URL +
                                        this.props.option.value
                                      }
                                    />
                                  )}
                                  {this.props.children}
                                </span>
                              </Pane>
                            );
                          },
                        })}
                        options={this.state.default_images}
                        placeholder={"Select image"}
                        onChange={this.setImageCategory}
                        value={this.state.imageCategory}
                        optionComponent={createClass({
                          propTypes: {
                            children: PropTypes.node,
                            className: PropTypes.string,
                            isDisabled: PropTypes.bool,
                            isFocused: PropTypes.bool,
                            isSelected: PropTypes.bool,
                            onFocus: PropTypes.func,
                            onSelect: PropTypes.func,
                            option: PropTypes.object.isRequired,
                          },
                          handleMouseDown(event) {
                            event.preventDefault();
                            event.stopPropagation();
                            this.props.onSelect(this.props.option, event);
                          },
                          handleMouseEnter(event) {
                            this.props.onFocus(this.props.option, event);
                          },
                          handleMouseMove(event) {
                            if (this.props.isFocused) return;
                            this.props.onFocus(this.props.option, event);
                          },
                          render() {
                            return (
                              <Pane
                                className={this.props.className}
                                onMouseDown={this.handleMouseDown}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseMove={this.handleMouseMove}
                                title={this.props.option.label}
                              >
                                <img
                                  crossOrigin="anonymous"
                                  width={
                                    this.props.option.width
                                      ? this.props.option.width
                                      : "50px"
                                  }
                                  alt="anonymous"
                                  height={
                                    this.props.option.height
                                      ? this.props.option.height
                                      : "50px"
                                  }
                                  src={
                                    Config.NEW_API_URL + this.props.option.value
                                  }
                                />
                              </Pane>
                            );
                          },
                        })}
                      />
                    </Form.Field>
                  ) : (
                    <Form.Field className="form-group">
                      <label htmlFor="category_image">
                        <FormattedMessage
                          id="category_image"
                          defaultMessage="Category Image"
                        />
                      </label>
                      <input
                        type="file"
                        id="category_image"
                        name="category_image"
                        placeholder=""
                        className="form-control"
                        value={dataCategory.image}
                        onChange={this.onChangeCategoryImage}
                      />
                      <Pane
                        className="slider-content-main d-flex"
                        style={{ padding: "10px 0" }}
                      >
                        {this.state.category_images &&
                          this.state.category_images.map((item, index) => {
                            return (
                              <Pane
                                className="slide-item mr-2"
                                key={index}
                              >
                                <Icon
                                  name="remove circle"
                                  onClick={this.removeCategoryImage.bind(
                                    this,
                                    index
                                  )}
                                />
                                <ImageS src={item} size="small" />
                              </Pane>
                            );
                          })}
                      </Pane>
                    </Form.Field>
                  )}
                  {errors.category_message && (
                    <InlineMessage text={errors.category_message} />
                  )}
                </>
              )}
              {errors.message && <InlineMessage text={errors.message} />}
            </Pane>
            <Pane className="row">
              <Pane className="col-lg-12 text-center">
                <Button
                  type="submit"
                  className="btn btn-save mt-4"
                  disabled={loading}
                  loading={loading}
                >
                  Edit
                </Button>
              </Pane>
            </Pane>
          </>
        ) : (
          <>
            <Pane className="pt30">
              <Loading />
            </Pane>
          </>
        )}
      </Form>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  collections: state.myCollections,
});

export default withRouter(connect(bindStateToProps)(injectIntl(EditCollectionForm)));
