import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  const model = params.model ? params.model : '';
  return {
    method: 'GET',
    url: `/getUserLikeVideo/${params.id}/${params.user_id}${model ? `?model=${model}` : ''}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const userLike = []
  if (response.data) {
    response.data.forEach(item => {
      const like = formatJson(response, item.type, item.id)
      userLike.push({
        id: item.attributes.id,
        createdAt: item.attributes.createdAt,
        like: item.attributes.like,
        user: {
          id: like.user && like.user.id,
          name: like.user && like.user.fullname,
          email: like.user && like.user.email,
          address: like.user && like.user.address,
          phone: like.user && like.user.phone,
          profileImage: like.user && like.user.image && like.user.image.url,
        }
      })
    })
  }

  return userLike
}

export { exec, parseResponse }
