import * as types from 'constants/ActionTypes';
import api from 'api';

export const getScanCount = () => (dispatch) =>
	dispatch({
		types: [
			types.REQUEST_SCAN_COUNT,
			types.RECEIVE_SCAN_COUNT_SUCCESS,
			types.RECEIVE_SCAN_COUNT_FAILURE,
		],
		payload: {
			request: api.scanCounts.get(),
		},
});

export const addScanCounts = (params) => (dispatch) => dispatch({
  type: types.ADD_SCAN_COUNT,
  payload: {
    request: api.scanCounts.add(params)
  }
})



