import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { MasterLayout } from 'components/layout';
import { quickSignup } from 'actions/user';
import { Pane } from 'shared/components/layouts';
import { QuickSignupForm } from 'components/scenes/quick-signup';

class QuickRegistrationPage extends React.Component {
  static propTypes = {
    quickSignup: PropTypes.func,
  }
  constructor(props, context) {
    super(props, context);


  }
  async componentDidMount() {

  }
  render() {
    const redirect = queryString.parse(this.props.location.search).redirect;
    const {
      auth,
      history: { location },
    } = this.props;
    return (
      <MasterLayout>
        <Pane className="login-container">
          <Pane className="login-more" />
          <Pane className="wrap-login d-flex align-items-center justify-content-center flex-column">
            <h3 className="login-title">CREATE YOUR ACCOUNT, ITS FREE </h3>
            <QuickSignupForm quickSignup={this.props.quickSignup}/>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,

});
const bindDispatchToProps = (dispatch) => ({
  quickSignup: bindActionCreators(quickSignup, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(QuickRegistrationPage);
