import Config from 'constants/Config'
import { formatJson, formatRoute } from 'shared/helpers'

const exec = params => {
  const page = params.page || 1
  const size = params.size || 20

  return {
    method: 'GET',
    url: `/users/${params.store}/promotions?page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const promotions = []

  if (response.data) {
    response.data.forEach(item => {
      const promotion = formatJson(response, item.type, item.id)

      let objItem = {}
      let slug = ''

      if (promotion.service) {
        slug = `${formatRoute(promotion.service.name, '-')}-${promotion.service.id}`
        objItem = {
          name: promotion.service.name,
          slug,
          price: promotion.service.price,
          promotionPrice: promotion.service.promotionPrice,
          primaryImage: promotion.service.primaryImage && promotion.service.primaryImage.url,
        }
      }
      if (promotion.product) {
        slug = `${formatRoute(promotion.product.name, '-')}-${promotion.product.id}`
        objItem = {
          name: promotion.product.name,
          slug,
          price: promotion.product.price,
          promotionPrice: promotion.product.promotionPrice,
          primaryImage: promotion.product.primaryImage && promotion.product.primaryImage.url,

        }
      }

      promotions.push({
        id: promotion.id,
        type: promotion.productId ? 'products' : 'services',
        itemId: promotion.productId || promotion.serviceId,
        primaryImage: promotion.primaryImage && promotion.primaryImage.url,
        slug,
        percent: Number(promotion.percent),
        description: promotion.description,
        masterPromotionId: promotion.masterPromotionId,
        startDate: promotion.startDate,
        endDate: promotion.endDate,
        item: objItem
      })
    })
  }

  return promotions
}

export { exec, parseResponse }
