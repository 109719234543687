import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

const TradingHourItem = ({ day, from, to, off }) => {
  return (
    <>
      <li className={`item-trading ${off.trim() === 'Closed' ? 'status-close' : '' }`}>
        <span className="item-norm">{day}</span>
        <span className="item-norm">
          {from}
          {moment(from,'HH:mm').format('A')} - {to}
          {moment(to,'HH:mm').format('A')}
        </span>
      </li>
    </>
  );
};

TradingHourItem.propTypes = {
  day: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  off: PropTypes.string,
};

TradingHourItem.defaultProps = {
  day: '',
  from: '',
  to: '',
  off: '',
};

export default TradingHourItem;
