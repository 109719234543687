import * as types from 'constants/ActionTypes'
import ProductAdapter from 'services/products/adapter'
import ServiceAdapter from 'services/services/adapter'
import UserAdapter from 'services/users/adapter'

const initialState = {
  products: {},
  services: {},
  promotionProducts: {},
  promotionServices: {},
  slides: {},
  info: {}
}

const stores = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.RECEIVE_STORE_INFORMATION_SUCCESS:
      state.info[action.meta.previousAction.params.store] = UserAdapter.read.parseResponse(action.payload.data)

      return Object.assign({}, state, {
        products: state.products
      })
    case types.RECEIVE_STORE_PRODUCTS_SUCCESS:
      state.products[action.meta.previousAction.params.store] = {
        results: ProductAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
      }

      return Object.assign({}, state, {
        products: state.products
      })
    case types.RECEIVE_STORE_SERVICES_SUCCESS:
      state.services[action.meta.previousAction.params.store] = {
        results: ServiceAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
      }

      return Object.assign({}, state, {
        services: state.services
      })
    case types.RECEIVE_STORE_PROMOTION_PRODUCTS_SUCCESS:
      state.promotionProducts[action.meta.previousAction.params.store] = {
        results: UserAdapter.productPromotionBrowse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
      }

      return Object.assign({}, state, {
        promotionProducts: state.promotionProducts
      })
    case types.RECEIVE_STORE_PROMOTION_SERVICES_SUCCESS:
      state.promotionServices[action.meta.previousAction.params.store] = action.payload.data

      return Object.assign({}, state, {
        promotionServices: state.promotionServices
      })
    case types.RECEIVE_STORE_SLIDES_SUCCESS:
      state.slides[action.meta.previousAction.params.store] = {
        results: UserAdapter.slideBrowse.parseResponse(action.payload.data)
      }

      return Object.assign({}, state, {
        slides: state.slides
      })
    default:
      return state
  }
}

export { stores }
