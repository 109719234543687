import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import StorePromotionItem from './StorePromotionItem';

class StorePromotionList extends Component {
  static propTypes = {
    promotions: PropTypes.array,
    type: PropTypes.string,
  };

  render() {
    const promotionList =
      this.props.promotions &&
      this.props.promotions.map((promotion) => {
        return (
          <StorePromotionItem
            key={promotion.id}
            id={promotion.id}
            item={promotion}
            name={promotion.item.name}
            image={promotion.primaryImage}
            startDate={promotion.startDate}
            endDate={promotion.endDate}
            price={promotion.item.price}
            promotionPrice={promotion.item.promotionPrice}
            percentDiscount={promotion.percent}
            type={promotion.type}
          />
        );
      });

    return (
      <Pane className="category-list">
        <Pane className="adds-block">
          <Pane className="row pb-0 pl-lg-2 pl-md-2 pr-lg-2 pr-md-2 pt-lg-2 pt-md-2 pl-sm-0 pr-sm-0 pt-sm-0 pb-lg-2">
            {promotionList}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default StorePromotionList;
