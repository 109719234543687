import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import {
  LeftArea,
  Newsletter,
  CategoryList,
  BestPicksList,
  FeatureProduct,
  BannerSlideShow,
  BusinessLogoList,
} from ".";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getVideos } from "actions/myVideos";
import { getProductCategories, getServiceCategories } from "actions/category";

import Config from "constants/Config";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

class Home extends Component {
  PropTypes = {
    getProductCategories: PropTypes.func.isRequired,
    getServiceCategories: PropTypes.func.isRequired,
    productCats: PropTypes.object,
    serviceCats: PropTypes.object,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      type: Config.PRODUCT_TYPE,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    // this.props.getProductCategories({
    //   id: "30,5,28,124,45,32,91,16,90,4,89,82",
    //   sort: "name",
    // }).then(() => {
    //   this.setState({loading: false})
    // });
    // this.props.getVideos({
    //   page: 1,
    //   size: 20
    // });
  }

  onChangeCategory = (type) => {
    this.setState({
      type,
    });
    if (type === Config.PRODUCT_TYPE) {
      this.setState({ loading: true });
      this.props
        .getProductCategories({
          id: "30,5,28,124,45,32,91,16,90,4,89,82",
          sort: "name",
        })
        .then(() => {
          this.setState({ loading: false });
        });
    } else if (type === Config.SERVICE_TYPE) {
      this.setState({ loading: true });
      this.props
        .getServiceCategories({
          id: "88,87,86,85,84,81,79,77,73,72,71,67",
          sort: "name",
        })
        .then(() => {
          this.setState({ loading: false });
        });
    } else if (type === Config.VIDEO_TYPE) {
      this.setState({ loading: true });
      this.props.getVideos({ page: 1, size: 10 }).then(() => {
        this.setState({ loading: false });
      });
    }
  };

  render() {
    const { productCats, serviceCats, videos, auth } = this.props;
    const { type, loading } = this.state;
    const name =
      auth.user && auth.user.displayName
        ? auth.user.displayName.replace(/ /g, "_").toLowerCase()
        : "";

    return (
      <Pane className="homepage-container">
        <Pane className="home-content">
          <Pane className="left-menu">
            <Pane className="nav-content">
              <ul className="nav-list">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <span className="icon icon-home" />
                    <FormattedMessage id="home" defaultMessage="Home" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/videos">
                    <span className="icon icon-camera" />
                    <FormattedMessage id="show" defaultMessage="Show" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={auth.isAuthenticated ? `/u/${name}/wallet` : "/sign-in"}
                  >
                    <span className="icon icon-users" />
                    <FormattedMessage id="friends" defaultMessage="Friends" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={auth.isAuthenticated ? `/u/${name}/` : "/sign-in"}
                  >
                    <span className="icon icon-user" />
                    <FormattedMessage id="profile" defaultMessage="Profile" />
                  </Link>
                </li>
              </ul>
            </Pane>
            <Pane className="nav-content">
              <ul className="nav-list second-nav-list">
                <li className="nav-item">
                  <Link className="nav-link" to="/promotions">
                    <FormattedMessage id="promotions" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/product-picks">
                    <FormattedMessage id="best_pick" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/just-listed">
                    <FormattedMessage id="just_listed" />
                  </Link>
                </li>
              </ul>
            </Pane>
            <Pane className="nav-content">
              <ul className="nav-list term-nav-list">
                <li className="nav-item">
                  <Link className="nav-link" to="/about-us">
                    <FormattedMessage id="company" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/terms">
                    <FormattedMessage id="term_and_policy" />
                  </Link>
                </li>
                <li className="nav-item">
                  <span className="nav-link">&copy; Cardbey 2024</span>
                </li>
              </ul>
            </Pane>
          </Pane>
          <Pane className="main-content">
            <BannerSlideShow getSlides={this.props.getSlides} />
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
Home.propTypes = {
  getProductCategories: PropTypes.func.isRequired,
  productCats: PropTypes.object,
  videos: PropTypes.object,
};

const bindStateToProps = (state) => ({
  auth: state.auth,
  productCats: state.productCategories,
  serviceCats: state.serviceCategories,
  videos: state.videos,
});

const bindDispatchToProps = (dispatch) => ({
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  getServiceCategories: bindActionCreators(getServiceCategories, dispatch),
  getVideos: bindActionCreators(getVideos, dispatch),
});

export default connect(bindStateToProps, bindDispatchToProps)(Home);

// export default Home;
