import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Currency } from "shared/components";
import { Pane } from "shared/components/layouts";
import { getOptionsByProduct } from "actions/products";
import { Icon } from "semantic-ui-react";

class CartItem extends Component {
  state = {
    optionIds: [],
    options: [],
  };

  includesOption = (id) => {
    if (!this.props.item.optionInfo) return null;
    return this.props.item.optionInfo.find((child) => child.id === id);
  };

  getTotalPriceOptions = () => {
    const list = this.getSelectedOptions();

    let total = 0;

    list.forEach((item) => {
      total += item.price * item.quantity;
    });

    return total;
  };

  getTotalPriceItemCart = () => {
    const { item } = this.props;

    return (item.promotionPrice + this.getTotalPriceOptions()) * item.quantity;
  };

  getSelectedOptions = () => {
    if (!this.isOptions()) return [];

    const { options } = this.props.item;
    let selectdOptions = [];

    options.map((option) => {
      const { childrens } = option;
      childrens.forEach((child) => {
        if (this.includesOption(child.id)) {
          selectdOptions.push({
            ...child,
            quantity: this.includesOption(child.id).quantity,
          });
        }
      });
    });
    return selectdOptions;
  };

  mappingSelectOptionToText = () => {
    const selectedOptions = this.getSelectedOptions();
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${
            child.name
          }`
      )
      .join(", ");
  };

  isOptions = () => {
    if (!this.props.item.options) return false;
    if (!this.props.item.options.length) return false;

    if (!this.props.item.optionInfo) return false;
    if (!this.props.item.optionInfo.length) return false;

    return true;
  };

  renderOptions = () => {
    if (!this.isOptions()) return null;

    const options = this.props.item.options
      .map((option) => ({
        ...option,
        childrens: option.childrens.filter((child) => {
          const filter = this.includesOption(child.id);
          return filter;
        }),
      }))
      .filter((option) => option.childrens && option.childrens.length);

    return options.map((option) => {
      const { childrens, type, id } = option;

      const result = [];
      result.push(
        <tr key={id}>
          <td
            style={{ verticalAlign: "middle" }}
            rowspan={`${childrens.length}`}
          >
            {option.name}
          </td>
          <td className="left aligned">{childrens[0].name}</td>
          <td className="left aligned">
            <span>+</span>
            <span className="price">
              <Currency price={childrens[0].price} />
            </span>
          </td>
          <td>
            <Pane className="table-quantily">
              <span style={{ minWidth: "40px", textAlign: "center" }}>
                {this.includesOption(childrens[0].id)
                  ? this.includesOption(childrens[0].id).quantity
                  : 1}
              </span>
            </Pane>
          </td>
        </tr>
      );

      for (let i = 1; i < childrens.length; i++) {
        const { id, name, price } = childrens[i];

        result.push(
          <tr key={id}>
            <td className="left aligned">{name}</td>
            <td className="left aligned">
              <span>+</span>
              <span className="price">
                <Currency price={price} />
              </span>
            </td>
            <td>
              <Pane className="table-quantily">
                <span style={{ minWidth: "40px", textAlign: "center" }}>
                  {this.includesOption(id)
                    ? this.includesOption(id).quantity
                    : 1}
                </span>
              </Pane>
            </td>
          </tr>
        );
      }

      return result;
    });
  };

  render() {
    const { item, index } = this.props;

    if (this.props.type === "responsive") {
      return (
        <div className="cart-item">
          <div className="cart-image">
            <Pane className="position-relative">
              <img
                src={
                  item.primaryImage
                    ? item.primaryImage
                    : process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`
                }
                size="tiny"
                className="image img-table w-100px"
                alt={item.name}
                ref={(img) => (this.img = img)}
                onError={() =>
                  (this.img.src =
                    process.env.PUBLIC_URL + `/assets/images/default-image.png`)
                }
              />
              <span className="quantity_prod">
                <span className="number">{item.quantity}</span>
              </span>
            </Pane>

            <Pane>
              <Link to={`/products/${item.productId}`}>
                <p className="cart-norm">{item.name}</p>
              </Link>
              {this.isOptions() ? (
                <Pane className="view-options">
                  <span style={{ fontSize: "11px", marginLeft: "16px" }}>
                    <Icon name="check" />
                    {this.mappingSelectOptionToText()}
                  </span>
                </Pane>
              ) : null}
              <Pane>
                <span className="price">
                  <Currency price={item.promotionPrice} />
                </span>
              </Pane>
              <div className="cart-quanlity mt-3 float-right">
                <div className="total">
                  <span className="price total-price">
                    <Currency price={this.getTotalPriceItemCart()} />
                  </span>
                </div>
              </div>
            </Pane>
          </div>
        </div>
      );
    }
    return (
      <>
        <tr>
          <td className="w-100px">
            <Link
              to={`/products/${item.productId}`}
              className="pb5 btn-block position-relative"
            >
              <img
                src={
                  item.primaryImage
                    ? item.primaryImage
                    : process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`
                }
                size="tiny"
                className="image img-table w-100px"
                alt={item.name}
                ref={(img) => (this.img = img)}
                onError={() =>
                  (this.img.src =
                    process.env.PUBLIC_URL + `/assets/images/default-image.png`)
                }
              />
              <span className="quantity_prod">
                <span className="number">{item.quantity}</span>
              </span>
            </Link>
          </td>
          <td className="col-name">
            <Link to={`/products/${item.productId}`} className="table-name">
              {item.name}
            </Link>
            {this.isOptions() ? (
              <Pane className="view-options">
                <span style={{ fontSize: "11px" }}>
                  <Icon name="check" />
                  {this.mappingSelectOptionToText()}
                </span>
              </Pane>
            ) : null}
            <Pane>
              <b>
                <Currency price={item.promotionPrice} />
              </b>
              {item.price !== item.promotionPrice && (
                <span className="text-red text-underline">
                  <Currency price={item.price} />
                </span>
              )}
            </Pane>
          </td>
          <td style={{ color: "red" }}>
            <Currency price={this.getTotalPriceItemCart()} />
          </td>
        </tr>
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  options: state.options,
});

const bindDispatchToProps = (dispatch) => ({
  getOptionsByProduct: bindActionCreators(getOptionsByProduct, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(withRouter(CartItem)));
