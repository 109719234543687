import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { LandingLayout } from 'components/layout';
import { Landing } from 'components/scenes/landing';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStatisticUser } from 'actions/statistics';

class LandingPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <LandingLayout>
        <Landing
          statistics={this.props.statistics}
          getStatisticUser={this.props.getStatisticUser}
        />
      </LandingLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  statistics: state.statistics,
});

const mapDispatchToProps = (dispatch) => ({
  getStatisticUser: bindActionCreators(getStatisticUser, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LandingPage));
