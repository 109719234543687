import moment from "moment";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import Config from "constants/Config";
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import { handleError, formatDecimal } from "shared/helpers";
import { FormattedMessage } from "react-intl";
import { Image } from "semantic-ui-react";

class OrderedCartItem extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: this.props.data || {},
    };
  }

  isOptions = (item) => {
    if (!item.options) return false;
    if (!item.options.length) return false;

    return true;
  };

  includesOption = (id, item) => {
    const { optionInfo } = item;
    if (!isEmpty(optionInfo)) {
      return optionInfo.find((child) => child.id === id);
    }
  };

  getSelectedOptions = (item) => {
    if (!this.isOptions(item)) return [];

    const { options } = item;
    let selectdOptions = [];

    if (!isEmpty(options)) {
      options.map((option) => {
        const { childrens } = option;
        childrens.forEach((child) => {
          if (this.includesOption(child.id, item)) {
            selectdOptions.push({
              ...child,
              quantity: this.includesOption(child.id, item).quantity,
            });
          }
        });
      });
    }

    return selectdOptions;
  };

  mappingSelectOptionToText = (item) => {
    const selectedOptions = this.getSelectedOptions(item);
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${child.name
          }`
      )
      .join(", ");
  };

  render() {
    const { data } = this.props;
    if (
      data.deliveryMethod === Config.DELIVERY_METHOD.PICKUP ||
      data.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
    ) {
      return (
        <Pane className="order-item">
          <Pane className="order-item__body">
            <Pane className="order-item__info">
              <Image src={data.primaryImage ? data.primaryImage : `${process.env.PUBLIC_URL}/assets/images/default-image.png`} size="tiny" />
              <Pane>
                <Pane className="d-flex">
                  <Pane className="order-item__info-quantity">x {data.quantity}</Pane>
                  <Pane className="order-item__info-name">
                    {data.name}
                    {this.isOptions(data) ? (
                      <Pane className="view-options">
                        <em>
                          <h5
                            className="p-0"
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                            }}
                          >
                            &nbsp; - {this.mappingSelectOptionToText(data)}
                          </h5>
                        </em>
                      </Pane>
                    ) : null}
                  </Pane>
                </Pane>
                <span className="text-gray">
                  <span className={`badge badge-${data.status.toLowerCase()}`}>
                    <FormattedMessage
                      id={`order.${data.status.toLowerCase()}`}
                      defaultMessage={data.status}
                    />
                  </span>
                </span>
              </Pane>
            </Pane>
            <Pane className="order-item__price">
              AUD ${formatDecimal(data.total)}
            </Pane>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className="order-item">
        <Pane className="order-item__body">
          <Pane className="order-item__info">
            <Image src={data.primaryImage} size="tiny" />
            <Pane>
              <Pane className="d-flex">
                <Pane className="order-item__info-quantity">x {data.quantity}</Pane>
                <Pane className="order-item__info-name">
                  {data.name}
                  {this.isOptions(data) && this.mappingSelectOptionToText(data) ? (
                    <Pane className="view-options">
                      <em>
                        <h5
                          className="p-0"
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                          }}
                        >
                          &nbsp; - {this.mappingSelectOptionToText(data)}
                        </h5>
                      </em>
                    </Pane>
                  ) : null}
                </Pane>
              </Pane>
              <span className="text-gray">
                <span className={`badge badge-${data.status.toLowerCase()}`}>
                  <FormattedMessage
                    id={`order.${data.status.toLowerCase()}`}
                    defaultMessage={data.status}
                  />
                </span>
              </span>
            </Pane>
          </Pane>
          <Pane className="order-item__price">
            AUD ${formatDecimal(data.total)}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default OrderedCartItem;
