import React from 'react'
import { MasterLayout } from 'components/layout'
import { ClassisiedOverview } from 'shared/components'
import { Pane } from 'shared/components/layouts'

class Classisied extends React.Component {
  render() {
    return (
      <MasterLayout>
        <Pane className='container not-found-container'>
          <ClassisiedOverview />
        </Pane>
      </MasterLayout>
    )
  }
}

export default Classisied
