import Config from 'constants/Config';

const exec = (params) => {
  return {
    method: 'POST',
    url: `/oauth/login`,
    baseURL: Config.NEW_API_URL,
    data: params,
  };
};

export { exec };
