import _ from "lodash";
import PropTypes from "prop-types";
import { ProductList } from "./list";
import queryString from "query-string";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import InfiniteScroll from "react-infinite-scroller";
import { checkParams, currentCart } from "shared/helpers";
import { FormattedMessage, injectIntl } from "react-intl";
import NotificationSystem from "react-notification-system";
import { ScrollMenuProduct } from "components/common/scrollMenu";
import { LoadingAdds, LoadingContainer } from "components/common/loading";
import { ProductOptionModal } from "../../../shared/components";

let isMounted = true;
/**
 * Set isMounted
 * @param {boolean} value
 * @returns {boolean} value of isMounted
 */
const setIsMounted = (value = true) => {
  isMounted = value;
  return isMounted;
};
/**
 * Get isMounted
 * @returns {boolean} value of isMounted
 */
const getIsMounted = () => isMounted;
class Products extends Component {
  static propTypes = {
    auth: PropTypes.object,
    data: PropTypes.object,
    onGetAllProducts: PropTypes.func,
    onAddItemToCart: PropTypes.func,
    productPromotions: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const items = [33, 112, 104, 50, 33, 32, 16];
    const {
      location: { search },
    } = props;
    this.state = {
      category:
        queryString.parse(search).category ||
        items[Math.floor(Math.random() * items.length)],
      size: 20,
      keySort: null,
      fetched: false,
      fetching: false,
      hasMoreItems: true,
      loadingMore: false,
      modeView: "gridView",
      sort: queryString.parse(search).sort,
      store: queryString.parse(search).store,
      discount: queryString.parse(search).discount,
      page: parseInt(queryString.parse(search).page, 10) || 1,
      cartView: {},
      openModalDetail: false,
      data: {
        results: [],
      },
    };
    this._notificationSystem = null;
    setIsMounted(true);
  }

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
      const qp = queryString.parse(location.search);
      const params = {};
      Object.keys(qp) &&
        Object.keys(qp).forEach((key) => {
          params[key] = qp[key];
        });
      this.getProducts(params);
    });
  }

  componentWillUnmount() {
    setIsMounted(false);
    this.unlisten();
  }

  componentDidMount() {
    const { data, match } = this.props;
    const params = {
      page: this.state.page,
      store: this.state.store,
      discount: this.state.discount,
      sort: this.state.sort,
      category: this.state.category,
    };
    if (match && match.params.id) {
      params.id = match.params.id;
      this.getProducts(params);
    } else if (data && data.fetched) {
      this.getProducts(params);
    } else {
      this.getProducts(params);
    }
    this._notificationSystem = this.refs.notificationSystem;
  }

  componentDidUpdate(prevProps) {
    const { match, location } = this.props;
    if (
      prevProps &&
      this.props &&
      prevProps.match.params.id !== match.params.id
    ) {
      const params = {
        page: this.state.page,
        store: queryString.parse(location.search).store,
        discount: queryString.parse(location.search).discount,
      };

      if (match && match.params.id) {
        params.id = match.params.id;
      }
      this.getProducts(params);
    }
  }

  options = [
    {
      value: "+price",
      label: this.props.intl.formatMessage({
        id: "price.low_to_high",
        defaultMessage: "Price: Low to High",
      }),
    },
    {
      value: "-price",
      label: this.props.intl.formatMessage({
        id: "price.high_to_low",
        defaultMessage: "Price: High to Low",
      }),
    },
    {
      value: "statistic.rating",
      label: this.props.intl.formatMessage({
        id: "rating.high_to_low",
        defaultMessage: "Rating: Low to High",
      }),
    },
    {
      value: "-statistic.rating",
      label: this.props.intl.formatMessage({
        id: "rating.low_to_high",
        defaultMessage: "Rating: High to Low",
      }),
    },
  ];

  setStateData = (state, callback) => {
    if (!getIsMounted()) {
      return;
    }
    this.setState(state, callback);
  };

  handleChangeSelect = (selectedOption) => {
    const { history, location } = this.props;
    const { value } = selectedOption.target;
    if (!value) {
      this.setStateData(
        {
          sort: "",
        },
        () => {
          history.push({
            search: ``,
          });
        }
      );
    } else {
      this.setStateData(
        {
          sort: value,
        },
        () => {
          history.push({
            search: `?sort=${this.state.sort}${checkParams(
              "sort",
              location.search
            )}`,
          });
        }
      );
    }
  };

  getProducts = (params) => {
    if (params.id) {
      this.setStateData({ category: params.id });
    }
    this.setStateData({
      fetching: true,
      fetched: false,
    });
    this.props
      .onGetAllProducts({
        ...params,
        images: this.state.modeView === "gridView" ? "" : null,
      })
      .then(() => {
        this.setStateData({
          fetching: false,
          fetched: true,
          data: this.props.data,
        });
      });
  };

  handlePageClick = (data) => {
    const { history, location } = this.props;
    this.setStateData(
      {
        page: data.selected + 1,
      },
      () => {
        history.push({
          search: `?page=${this.state.page}${checkParams(
            "page",
            location.search
          )}`,
        });
        window.scrollTo(0, 0);
      }
    );
  };

  onSelect = (key) => {
    const { history } = this.props;
    this.setState(
      {
        keySort: key,
        category: key,
        page: 1,
        size: 20,
      },
      () => {
        if (this.state.keySort) {
          history.push(`/products?category=${this.state.keySort}`);
        } else {
          history.push(`/products`);
        }
      }
    );
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === "gridView" ? "listView" : "gridView",
    }));
  };

  loadItems = () => {
    const params = {
      page: this.state.page + 1,
      store: this.state.store,
      discount: this.state.discount,
      sort: this.state.sort,
      category: this.state.category,
    };
    if (
      !this.state.loadingMore &&
      this.props.data.totalItems > 20 &&
      this.props.data.totalItems > this.state.data.results.length
    ) {
      this.setState({
        hasMoreItems: false,
        loadingMore: true,
      });
      this.props.onGetAllProducts(params).then(() => {
        this.setState((prevState) => ({
          hasMoreItems: true,
          page: prevState.page + 1,
          data: {
            results: prevState.data.results.concat(this.props.data.results),
          },
          loadingMore: false,
        }));
      });
    }
  };

  onCloseModalDetail = () => {
    this.setState({ openModalDetail: false, cartView: {} });
  };

  onOpenModalDetail = () => {
    this.setState({ openModalDetail: true });
  };

  setCartView = (cartViewData) => {
    this.setState((prev) => ({
      cartView: { ...prev.cartView, ...cartViewData },
    }));
  };

  render() {
    const { fetched, fetching, selected, data } = this.state;
    const { categories, cart, intl } = this.props;
    return (
      <Pane className="col-lg-9 col-md-12">
        <ProductOptionModal
          openModal={this.state.openModalDetail}
          onClose={this.onCloseModalDetail}
          detail={this.state.cartView}
          onUpdateCart={(item, optionIds) => {
            const params = {
              id: item.id,
              optionInfo: JSON.stringify(optionIds),
            };
            this.props.changeOption(params).then(() => {
              this._notificationSystem.addNotification({
                message: intl.formatMessage({ id: "cart.update_success" }),
                level: "success",
                autoDismiss: 3,
              });
              this.onCloseModalDetail();
            });
          }}
          onAddCartItem={(optionIds, item) => {
            this.props
              .onAddItemToCart({
                product_id: item.id,
                option_info: optionIds.length
                  ? JSON.stringify(optionIds)
                  : null,
                table_id: item.tableId,
                delivery_method: item.delivery_method,
              })
              .then(() => {
                this._notificationSystem.addNotification({
                  message: intl.formatMessage({ id: "cart.add_success" }),
                  level: "success",
                  autoDismiss: 3,
                });
                this.onCloseModalDetail();
              });
          }}
          isEdit={true}
        />
        <Pane className="tab-heading">
          <Pane className="category-list">
            <ScrollMenuProduct
              items={categories}
              onSelect={this.onSelect}
              selected={selected}
            />
            <span onClick={() => this.onSelect(null)} className="category-link">
              <FormattedMessage id="view_all" defaultMessage="View All" />
            </span>
          </Pane>
          <Pane className="category-sort">
            <span className="sort-title">SORT BY</span>
            <select
              className="category-select"
              name="format"
              value={this.state.sort}
              onChange={this.handleChangeSelect}
              id="format"
            >
              {this.options.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </Pane>
          <span className="list-view" onClick={this.onChangeModeView}>
            <img
              src={
                process.env.PUBLIC_URL + this.state.modeView === "gridView"
                  ? "/assets/images/icons/list-view.svg"
                  : "/assets/images/icons/grid-view.svg"
              }
              className="image-logo"
              alt="list-view"
            />
          </span>
        </Pane>
        <Pane className="product-list">
          {!fetching && fetched ? (
            <>
              {!_.isEmpty(data.results) ? (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadItems}
                  hasMore={this.state.hasMoreItems}
                  isReverse={false}
                >
                  <ProductList
                    auth={this.props.auth}
                    products={currentCart(data.results, cart)}
                    modeView={this.state.modeView}
                    onNotification={this._notificationSystem}
                    onAddItemToCart={this.props.onAddItemToCart}
                    onAddItemToGuestCart={this.props.onAddItemToGuestCart}
                    type={this.props.type}
                    cartModal={{
                      cartView: this.state.cartView,
                      openModal: this.onOpenModalDetail,
                      setCartView: this.setCartView,
                    }}
                  />
                  {this.state.loadingMore && <LoadingContainer />}
                </InfiniteScroll>
              ) : (
                <Empty />
              )}
            </>
          ) : (
            <LoadingAdds />
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

export default injectIntl(withRouter(Products));
