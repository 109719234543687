import * as types from 'constants/ActionTypes'

export const getPosCartRequest = (params) => (dispatch) => dispatch(getPosCart(params))

export const getPosCart = (posCart) => {
  return {
    type: types.RECEIVE_POS_CART,
    posCart: posCart
  }
}

export const addPosCartRequest = (item) => (dispatch) => dispatch(addPosCart(item))

export const addPosCart = (item) => {
  return {
    type: types.ADD_POS_CART,
    item: item
  }
}

export const updatePosCartRequest = (isQuantity, productId, data) => (dispatch) => dispatch(updatePosCart(isQuantity, productId, data))

export const updatePosCart = (isQuantity, productId, data) => {
  return {
    type: types.UPDATE_POS_CART,
    payload: {
      isQuantity: isQuantity,
      productId: productId,
      data: data
    }
  }
}

export const deletePosCartRequest = (productId) => (dispatch) => dispatch(deletePosCart(productId))

export const deletePosCart = (productId) => {
  return {
    type: types.DELETE_POS_CART,
    productId: productId,
  }
}

export const resetPosCartRequest = () => (dispatch) => dispatch(resetPosCart())

export const resetPosCart = () => {
  return {
    type: types.RESET_POS_CART
  }
}
