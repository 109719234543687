import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Icon, Table } from "semantic-ui-react";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactPaginate from "react-paginate";
import { Pane, Loading } from "shared/components/layouts";
import queryString from "query-string";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatRoute, handleError } from "shared/helpers";
import { getProjectsByUserId, removeMyProject } from "actions/projects";
import { confirmAlert } from "react-confirm-alert";
class Projects extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      srName: "",
      size: 20,
      page: parseInt(queryString.parse(search).page, 10) || 1,
    };
  }
  componentWillUnmount() {
    this.unlisten();
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getProjectsByUserId();
    });
    this.getProjectsByUserId();
  }
  getProjectsByUserId = (params) => {
    const { userInfo } = this.props;
    this.props.getProjectsByUserId(
      {
        id: userInfo.id,
        size: this.state.size,
        name: this.state.srName,
      },
      true
    );
  };
  deleteItem = (id) => {
    this.props.removeMyProject({ id }).then(() => this.getProjectsByUserId());
  };
  onSearch = () => {
    this.getProjectsByUserId();
  };
  handlePageClick = (data) => {
    const params = {
      id: this.props.userInfo.id,
      page: data.selected + 1,
      size: this.state.size,
      name: this.state.srName,
    };
    this.props.getProjectsByUserId(params, true);
  };
  changeRoute = (id) => {
    const { userInfo } = this.props;
    this.props.history.push(
      `/u/${formatRoute(userInfo.displayName)}/project/${id}/edit`
    );
  };
  showConfirmDelete = (id) => {
    const { intl } = this.props;
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: `${intl.formatMessage({
        id: "notification.are_you_sure_you_want_to_delete_this",
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => {
            this.deleteItem(id);
          },
        },
        {
          label: intl.formatMessage({ id: "no" }),
        },
      ],
    });
  };
  render() {
    const { userInfo, projects } = this.props;
    return (
      <Pane className="overview-container">
        <Pane className="product-container order-container">
          <h3 className="block-title pt10 text-uppercase">
            <FormattedMessage id="project_list" defaultMessage="Project List" />
          </h3>
          <Pane className="product-heading">
            <Pane className="area-search">
              <Pane className="search-box">
                <Pane className="search-field">
                  <input
                    type="text"
                    name="term"
                    className="search-input"
                    value={this.state.srName}
                    placeholder="Search Name ..."
                    onChange={(e) => {
                      this.setState({ srName: e.target.value });
                    }}
                  />
                </Pane>
              </Pane>
              <button
                onClick={this.onSearch}
                className="white btn btn-circle btn-circle-primary d-none d-lg-block"
              >
                <FormattedMessage id="search" defaultMessage="Search" />
              </button>
            </Pane>
            <Link
              to={`/u/${formatRoute(userInfo.displayName)}/project/create`}
              className="btn btn-circle btn-circle-primary d-none d-lg-block"
            >
              <FormattedMessage
                id="create_new_project"
                defaultMessage="Create New Project"
              />
            </Link>
          </Pane>
          <Pane className="products-grids">
            {!projects.fetched && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id="name" defaultMessage="Name" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="project_start_date"
                      defaultMessage="Start Date"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="project_end_date"
                      defaultMessage="End Date"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="project_actions"
                      defaultMessage="Actions"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {projects.results &&
                  projects.results.map((project) => (
                    <Table.Row>
                      <Table.Cell>{project.name}</Table.Cell>
                      <Table.Cell>
                        {moment
                          .utc(project.startDate, "YYYY-MM-DD")
                          .format("YYYY-MM-DD")}
                      </Table.Cell>
                      <Table.Cell>
                        {moment
                          .utc(project.endDate, "YYYY-MM-DD")
                          .format("YYYY-MM-DD")}
                      </Table.Cell>
                      <Table.Cell>
                        <Pane className="adds-actions">
                          <a onClick={() => this.changeRoute(project.id)}>
                            <Icon name="pencil" />
                          </a>
                          <a onClick={() => this.showConfirmDelete(project.id)}>
                            <Icon name="trash" />
                          </a>
                        </Pane>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            {projects.fetched &&
              !projects.fetching &&
              projects.results &&
              projects.results.length <= 0 && (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.you_have_not_had_any_services_yet"
                      defaultMessage={`You haven't had any services yet.`}
                    />
                  </p>
                </Pane>
              )}
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {projects.totalPages > 1 ? (
                <ReactPaginate
                  previousLabel={
                    <FormattedMessage
                      id="pagination.previous"
                      defaultMessage="previous"
                    />
                  }
                  nextLabel={
                    <FormattedMessage
                      id="pagination.next"
                      defaultMessage="next"
                    />
                  }
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={"break-me"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  pageCount={projects.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              ) : null}
            </nav>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  projects: state.myProjects,
});
const bindDispatchToProps = (dispatch) => ({
  getProjectsByUserId: bindActionCreators(getProjectsByUserId, dispatch),
  removeMyProject: bindActionCreators(removeMyProject, dispatch),
});
export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(Projects));