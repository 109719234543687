import React from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-container">
        <Pane className="container">
          <Pane className="row pb-4">
            <Pane className="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-lg-left text-md-left text-left">
              <Pane className="footer-logo">
                <img
                  src={`${
                    process.env.PUBLIC_URL
                  }/assets/images/layouts/logo-footer.png`}
                  className="footer-img"
                  alt="caterwin logo"
                />
              </Pane>
            </Pane>
            <div className="col-lg-2 col-md-6">
              <div className="row footer-menu footer-menu-landing">
                <div className="col">
                  <div className="footer-col">
                    <h3 className="title-menu title-cardbey">
                      <FormattedMessage id="cardbey" defaultMessage="Cardbey" />
                    </h3>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/about-us"
                    >
                      <FormattedMessage
                        id="about_us"
                        defaultMessage="ABOUT US"
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/blogs"
                    >
                      <FormattedMessage id="blogs" defaultMessage="BLOGS" />
                    </NavLink>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/investor"
                    >
                      <FormattedMessage
                        id="investor"
                        defaultMessage="investor"
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/partner-program"
                    >
                      <FormattedMessage
                        id="partner_program"
                        defaultMessage="Partner Program"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="row footer-menu footer-menu-landing">
                <div className="col">
                  <div className="footer-col">
                    <h3 className="title-menu">
                      <FormattedMessage
                        id="buy_sell"
                        defaultMessage="BUY & SELL"
                      />
                    </h3>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/"
                    >
                      <FormattedMessage id="home" defaultMessage="HOME" />
                    </NavLink>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/products"
                    >
                      <FormattedMessage
                        id="products"
                        defaultMessage="PRODUCTS"
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/services"
                    >
                      <FormattedMessage
                        id="services"
                        defaultMessage="SERVICES"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="row footer-menu footer-menu-landing">
                <div className="col">
                  <div className="footer-col">
                    <h3 className="title-menu">
                      <FormattedMessage
                        id="helpfull"
                        defaultMessage="HELPFULL"
                      />
                    </h3>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/faqs"
                    >
                      <FormattedMessage id="faqs" defaultMessage="FAQs" />
                    </NavLink>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <NavLink
                      className="footer-link"
                      activeClassName="active"
                      to="/contact-us"
                    >
                      <FormattedMessage
                        id="contact_us"
                        defaultMessage="CONTACT US"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="row footer-menu footer-menu-landing">
                <div className="col">
                  <div className="footer-col">
                    <h3 className="title-menu">
                      <FormattedMessage
                        id="stay_conect_with_us"
                        defaultMessage="STAY CONNECT WITH US"
                      />
                    </h3>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-col">
                    <ul className="footer-social">
                      <li className="social-item">
                        <a
                          href="https://www.instagram.com/cardbeymarketplace"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/about/instgram.svg`}
                            alt="instgram"
                          />
                        </a>
                      </li>
                      <li className="social-item">
                        <a
                          href="https://twitter.com/cardbey"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/about/twiter.svg`}
                            alt="tweeter"
                          />
                        </a>
                      </li>
                      <li className="social-item">
                        <a
                          href="https://www.facebook.com/cardbeymarketplace"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/about/fb.svg`}
                            alt="facebook"
                          />
                        </a>
                      </li>
                      <li className="social-item">
                        <a
                          href="https://www.pinterest.com/cardbeymarketplace/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/about/pinterest.svg`}
                            alt="pinterest"
                          />
                        </a>
                      </li>
                      {/* <li className="social-item">
                        <NavLink to="/">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/assets/images/about/gg.svg'
                            }
                            alt="gg"
                          />
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Pane>
          <hr className="footer-dot" />
          <Pane className="row pt-4 text-center">
            <Pane className="col-lg-12 col-md-12">
              <Pane className="info">
                <h2 className="footer-title">
                  © 2023 Cardbey. All Rights Reserved.
                </h2>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </footer>
    );
  }
}

export default Footer;
