import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { get, isEmpty } from 'lodash';
import ListItem from './ListItem';
import Config from "constants/Config";

class ServiceList extends Component {
  static propTypes = {
    auth: PropTypes.object,
    services: PropTypes.array,
    onAddItemToCart: PropTypes.func,
  };

  onGetImageCategory = (data) => {
    if (!isEmpty(data)) {
      return get(data[0], 'category.primaryImage.url');
    }
    return null;
  };

  render() {
    const list =
      this.props.services &&
      this.props.services.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.name}
            description= {item.description}
            image={
              this.props.type === 'bussiness'
                ? item.profileImage
                : item.primaryImage
            }
            price={item.originalPrice}
            rating={item.rating}
            modeView={this.props.modeView}
            index={index}
            auth={this.props.auth}
            type={this.props.type}
            serviceModal={this.props.serviceModal}
            addStoreBookingsRequest={this.props.addStoreBookingsRequest}
          />
        );
      });
    if (this.props.modeView === Config.MODE_VIEW_MENU.MODE_LIST) {
      return (
        <Pane className="adds-block block-menu-image-list-view">
          <Pane className="row pt-lg-3 pb-lg-3 align-items-center">
            <Pane className="col-lg-9 col-md-9 col-sm-12">
              <Pane className="row">{list}</Pane>
            </Pane>
            <Pane className="col-lg-3 col-md-3 d-none d-lg-block d-sm-none d-md-block background-menu" style={{
                  backgroundImage: `url(`+ this.onGetImageCategory(this.props.services) || process.env.PUBLIC_URL + `/assets/images/default-image.png` +`)`
                }}>
            </Pane>
          </Pane>
        </Pane>
    );
    }
    return (
      <Pane className="adds-block">
        <Pane className="row pt-3 pb-3row pb-0 pl-lg-2 pl-md-2 pr-lg-2 pr-md-2 pt-lg-2 pt-md-2 pl-sm-0 pr-sm-0 pt-sm-0">{list}</Pane>
      </Pane>
    );
  }
}
ServiceList.propTypes = {
  type: PropTypes.string,
};

ServiceList.defaultProps = {
  type: '',
};

export default ServiceList;
