import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';
import { Rating } from 'semantic-ui-react';
import { PriceItem } from 'shared/components';
import { formatRoute } from "shared/helpers";
import { isEmpty, mapKeys } from "lodash";
import moment from "moment";

class StoreItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    item: PropTypes.object,
    id: PropTypes.any,
    name: PropTypes.string,
    image: PropTypes.string,
    qrcode: PropTypes.string,
    businessInfo: PropTypes.object,
    avatar: PropTypes.string,
  };

  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onRedirect = () => {
    const { businessInfo } = this.props;
    if (businessInfo && businessInfo.is_active === 0) {
      return `/users/${this.props.id}`;
    } else if (businessInfo && businessInfo.type) {
      switch (businessInfo.type) {
        case 'Food':
          return `/${this.props.id}/menu`;
        case 'Product':
          return `/${this.props.id}/products`;
        case 'Service':
          return `/${this.props.id}/services`;
        default:
          return `/${this.props.id}`;
      }
    } else {
      return `/${this.props.id}`;
    }
  };

  renderStatus = (tradingHours) => {
    if (!isEmpty(tradingHours)) {
      const currentDate = moment().format("ddd");
      const renamed = mapKeys(tradingHours, (value, key) => key.toLowerCase());
      const tradingHoursCurrent = renamed[currentDate.toLowerCase()];
      if (!isEmpty(tradingHoursCurrent)) {
        const fromDate = moment(tradingHoursCurrent.from, "HH:mm");
        const toDate = moment(tradingHoursCurrent.to, "HH:mm");
        if (moment().isBetween(fromDate, toDate) && !tradingHoursCurrent.off) {
          return <div className="dot-active" />;
        }
        return <div className="dot-inactive" />;
      }
      return <div className="dot-inactive" />;
    }
    return <div className="dot-inactive" />;
  };

  render() {
    const { name, businessInfo, avatar, item } = this.props;
    let items = (businessInfo && businessInfo.type) !== 'Service' ? item.products : item.services;
    return (
      <Pane
        className="col-lg-3 col-md-6 col-sm-12 col-12 adds__search"
        data-tip
        data-for={`${this.props.id}`}
      >
        <Pane className="adds-item">
          <Link className="adds-images__link" to={this.onRedirect()}>
            <Pane
              className="adds-images"
              style={{
                backgroundImage: avatar
                  ? `url(${avatar})`
                  : `url(${process.env.PUBLIC_URL}/assets/images/user.jpg)`,
              }}
            >
              {this.renderStatus(businessInfo.tradingHours)}
              {this.props.showNew && (
                <Pane className="cornernew">New</Pane>
              )}
            </Pane>
          </Link>
          {businessInfo && (
            <>
              <Pane className="adds-info">
                <Pane className="adds-content">
                  <Link to={this.onRedirect()}>
                    <h5 className="adds-norm adds-norm-store" style={{ height: '60px' }} title={businessInfo.is_active == 1 ? businessInfo.name : name}>
                      <span className="store-name">{businessInfo.is_active == 1 ? businessInfo.name : name}</span><em className="store-address">{businessInfo.address}</em>
                    </h5>
                  </Link>
                  {businessInfo.is_active == 1 && (
                    <ul className="list-star ml-star">
                      <Rating
                        disabled
                        icon='star'
                        rating={businessInfo.rating || 0}
                        maxRating={1}
                        className="mr-1"
                        size='small'
                      />
                      <span>{businessInfo.rating || 'No Rating'}</span>
                    </ul>
                  )}
                  {/* <Pane className="text-xs">
                    {this.renderStatus(businessInfo.tradingHours)}
                  </Pane> */}
                </Pane>
                <Pane className="sub-items mt-2">
                  {items && items.length > 0 && items.map((item) => {
                    return <Pane className="sub-item" key={item.id}>
                      <Link
                        to={item.type === 'Service' ? `/services/${`${formatRoute(item.name, '-')}-${item.id}`}` : `/products/${`${formatRoute(item.name, '-')}-${item.id}`}`}
                      >
                        <img
                          className="item-image"
                          src={`${item.primaryImage ? item.primaryImage.url : `${process.env.PUBLIC_URL}/assets/images/default-image.png`}`}
                        />
                        <Pane className="item-info">
                          <p className="item-name">{item.name}</p>
                          <PriceItem
                            promotion={item && item.promotionPrice}
                            originalPrice={item && item.price}
                          />
                        </Pane>
                      </Link>
                    </Pane>
                  })}
                </Pane>
              </Pane>
            </>
          )}
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(StoreItem);
