import PropTypes from "prop-types";
import React, { Component } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";

const MenuItem = ({ key, text, selected, total }) => {
  return (
    <div className={`menu-item ${key === selected ? "active" : ""} text-uppercase`}>
      {text} {`(${total})`}
    </div>
  );
};

// All items component
// Important! add unique key
export const Menu = (list, selected) => {
  return list.map((el) => {
    const { name } = el;
    return <MenuItem total={el.items.length} text={name} key={name} selected={selected} />;
  });
};

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
  text: (
    <img
      src={process.env.PUBLIC_URL + "/assets/images/icons/prev-tab.svg"}
      className="image-logo"
      alt="list-view"
      id="arrow-prev"
    />
  ),
  className: "arrow-prev",
});
const ArrowRight = Arrow({
  text: (
    <img
      src={process.env.PUBLIC_URL + "/assets/images/icons/next-tab.svg"}
      className="image-logo"
      alt="list-view"
      id="arrow-next"
    />
  ),
  className: "arrow-next",
});
class ScrollMenuCustome extends Component {
  render() {
    const { items, selected } = this.props;
    return (
      <ScrollMenu
        data={Menu(items, selected)}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={selected}
        onSelect={this.props.onSelect}
        translate={0}
        alignCenter={false}
        wheel={false}
      />
    );
  }
}

ScrollMenuCustome.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ScrollMenuCustome;
