import Config from "constants/Config";
import { formatJson, formatRoute } from "shared/helpers";

const exec = (params) => {
  return {
    method: "GET",
    baseURL: Config.NEW_API_URL,
    url: `/products/${params.id}`,
  };
};
const parseResponse = (response) => {
  const product = formatJson(response, response.data.type, response.data.id);

  let promotion = {};
  let user = {};

  if (product.promotion) {
    promotion = {
      id: product.promotion.id,
      percent: Number(product.promotion.percent),
      startDate: product.promotion.startDate,
      endDate: product.promotion.endDate,
    };
  }

  if (product.user) {
    user = {
      id: product.user.id,
      name: product.user.fullname,
      slug: formatRoute(
        product.user && product.user.business && product.user.business.name,
        "-"
      ),
      email: product.user.email,
      address: product.user.address,
      phone: product.user.phone,
      profileImage: product.user.image && product.user.image.url,
      backgroundImage:
        product.user.backgroundImage && product.user.backgroundImage.url,
      qrcode: product.user.qrcode && product.user.qrcode.url,
      business: Object.assign({}, product.user.business),
    };

    delete user.business.user;
  }

  const options = [];

  (product.options || []).forEach((option) => {
    const group = option.optionGroup || {};
    const index = options.findIndex((option) => option.id === group.id);
    if (index === -1) {
      options.push({
        id: group.id,
        name: group.name,
        type: group.type,
        autoAddOptions: false,
        dpOrdSeq: group.dpOrdSeq,
        delt: "N",
        childrens: [
          {
            id: option.id,
            name: option.name,
            dpOrdSeq: option.dpOrdSeq,
            price: Number(option.price),
            delt: "N",
            autoAddOptions: false,
            isCheck: !!option.isCheck,
          },
        ],
      });
    } else {
      options[index].childrens.push({
        id: option.id,
        name: option.name,
        dpOrdSeq: option.dpOrdSeq,
        price: Number(option.price),
        delt: "N",
        autoAddOptions: false,
        isCheck: !!option.isCheck,
      });
    }

    return options;
  });

  options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
  options.forEach((option) =>
    option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq)
  );

  const data = {
    id: product.id,
    name: product.name,
    originalPrice: product.price,
    primaryImage: product.primaryImage && product.primaryImage.url,
    subImage1: product.subImage1 && product.subImage1.url,
    subImage2: product.subImage2 && product.subImage2.url,
    subImage3: product.subImage3 && product.subImage3.url,
    subImage4: product.subImage4 && product.subImage4.url,
    subImage5: product.subImage5 && product.subImage5.url,
    description: product.description,
    promotionPrice: Number(product.promotionPrice),
    meta_desc: product.metaDesc,
    averageRating:
      product.statistic && product.statistic.rating
        ? product.statistic.rating
        : 0,
    userId: user && user.id,
    user,
    inventoryStatus: product.inventoryStatus,
    categoryId: product.category && product.category.id,
    categoryName: product.category && product.category.name,
    promotion,
    options: options,
  };
  return data;
};

export { exec, parseResponse };
