import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { ProductList } from '../../products/list';
import { ServiceList } from '../../services/list';
import {
  getProductsByUserId,
  getFoodCategoriesByUserId,
  getOtherCategoriesByUserId,
} from 'actions/myProducts';
import _, { isEmpty } from 'lodash';
import map from 'async/map';
import Slideshow from './Slideshow';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';
import { getMySlides } from 'actions/mySlides';
import { getServicesByUserId, getCategoriesByUserId } from 'actions/myServices';
import { ShowCollection } from 'components/common/collections/show-collections';

class Overview extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    business: PropTypes.object,
    products: PropTypes.object,
    services: PropTypes.object,
    getProductsByUserId: PropTypes.func,
    getServicesByUserId: PropTypes.func,
    getOtherCategoriesByUserId: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      foodCategories: [],
      serviceCategories: [],
      products: [],
      open: false,
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  getProductsByUserId = () => {
    const { userInfo } = this.props;
    this.props.getProductsByUserId({ id: userInfo.id }).then((results) => {
      const {
        data: { data },
      } = results;
      this.setState({
        products: data,
      });
    });
  };

  getServicesByUserId = () => {
    const { userInfo } = this.props;

    this.props.getServicesByUserId({ id: userInfo.id });
  };

  getServiceCategoriesByUserId = async () => {
    const { userInfo } = this.props;

    const {
      data: { data },
    } = await this.props.getServiceCategoriesByUserId({ id: userInfo.id });

    map(
      data,
      async (category, cb) => {
        const {
          data: { data },
        } = await this.props.getServicesByUserId({
          id: userInfo.id,
          category_id: category.id,
        });
        category.services = data;
        category.total = data.length;
        cb(null, category);
      },
      (e, r) => {
        this.setState({
          serviceCategories: r,
        });
      }
    );
  };

  getFoodCategoriesByUserId = async () => {
    const { userInfo } = this.props;

    const {
      data: { data },
    } = await this.props.getFoodCategoriesByUserId({
      id: userInfo.id,
      type: 'food',
    });

    map(
      data,
      async (category, cb) => {
        const {
          data: { data },
        } = await this.props.getProductsByUserId({
          id: userInfo.id,
          category_id: category.id,
        });

        category.products = data;
        category.total = data.length;

        cb(null, category);
      },
      (e, r) => {
        this.setState({
          foodCategories: r,
        });
      }
    );
  };

  getMySlides = () => {
    const { userInfo } = this.props;

    this.props.getMySlides({ store: userInfo.id });
  };

  async componentDidMount() {
    // await this.getProductsByUserId({ load: true });
    // await this.getServicesByUserId();
    await this.getMySlides();
  }

  getElement = (key, categories) => {
    switch (key) {
      case 'Menu':
        return (
          <Pane key={key}>
            <h3 className="tab-heading tab-heading-stores service-before">
              <FormattedMessage id="my_menu" defaultMessage="Menu" />
            </h3>
            {!isEmpty(categories) &&
              categories.map((category, index) => {
                if (isEmpty(category.items)) {
                  return <></>;
                }
                return (
                  <React.Fragment key={index}>
                    <Pane className="category-name"><strong>{category.name}</strong></Pane>
                    <Pane className="products-grids">
                      <ProductList
                        auth={this.props.auth}
                        products={category.items}
                        onAddItemToCart={this.props.addItemToCart}
                        type="menu"
                      />
                      <Pane className="clearfix" />
                    </Pane>
                  </React.Fragment>
                );
              })}
            {isEmpty(categories) && (
              <Pane className="block-no-content">
                <p>
                  <FormattedMessage
                    id="notification.you_have_not_had_any_products_yet"
                    defaultMessage={`You haven't had any products yet.`}
                  />
                </p>
              </Pane>
            )}
            <Pane className="clearfix" />
          </Pane>
        );
      case 'Product':
        return (
          <Pane key={key}>
              <h3 className="tab-heading tab-heading-stores product-store-user">
                <FormattedMessage id="my_products" defaultMessage="Products" />
              </h3>
              {!isEmpty(categories) &&
                categories.map((category, index) => {
                  if (isEmpty(category.items)) {
                    return <></>;
                  }
                  return (
                    <React.Fragment key={index}>
                      <Pane className="category-name"><strong>{category.name}</strong></Pane>
                      <Pane className="products-grids">
                        <ProductList
                          auth={this.props.auth}
                          products={category.items}
                          onAddItemToCart={this.props.addItemToCart}
                          type="Product"
                        />
                        <Pane className="clearfix" />
                      </Pane>
                    </React.Fragment>
                  );
                })}
              {isEmpty(categories) && (
                <Pane className="block-no-content">
                  <p>
                    <FormattedMessage
                      id="notification.you_have_not_had_any_products_yet"
                      defaultMessage={`You haven't had any products yet.`}
                    />
                  </p>
                </Pane>
              )}
            

          </Pane>
        );
      case 'Service':
        return (
          <Pane key={key}>
            <h3 className="tab-heading tab-heading-stores service-before d-none d-sm-none d-lg-flex d-sm-none">
              <FormattedMessage id="my_services" defaultMessage="My Services" />
            </h3>
            {!isEmpty(categories) &&
              categories.map((category, index) => {
                if (isEmpty(category.items)) {
                  return <></>;
                }
                return (
                  <React.Fragment key={index}>
                    <Pane className="category-name"><strong>{category.name}</strong></Pane>
                    <ServiceList
                      auth={this.props.auth}
                      services={category.items}
                    />
                  </React.Fragment>
                );
              })}
            {isEmpty(categories) && (
              <Pane className="block-no-content">
                <p>
                  <FormattedMessage
                    id="notification.you_have_not_had_any_products_yet"
                    defaultMessage={`You haven't had any products yet.`}
                  />
                </p>
              </Pane>
            )}
          </Pane>
        );
      default:
        return <></>;
    }
  };

  orderCategories = (services, products) => {
    return _.concat(products, [{ key: 'Service', value: services }]).sort(
      (a, b) => {
        return b.value.length - a.value.length;
      }
    );
  };

  sortByKeys = (object) => {
    return Object.keys(object)
      .map((k) => Object.assign({}, { key: k, value: object[k] }))
      .sort((a, b) => b.value.length - a.value.length);
  };

  groupByCategory = (items) => {
    return _.chain(items)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .sort(
        (a, b) =>
          a.items[0] &&
          new Date(_.get(b.items[0], 'category.createdAt')).getTime() / 1000 -
          new Date(_.get(a.items[0], 'category.createdAt')).getTime() / 1000
      )
      .value();
  };

  renderElement = (key, item) => {
    return this.getElement(key, this.groupByCategory(item));
  };

  render() {
    const { slides, products, services, business, userInfo } = this.props;
    const { open } = this.state;
    const items = this.sortByKeys(
      _.chain(products.results)
        .groupBy((x) => x.category && x.category.group)
        .value()
    );

    return (
      <>
        {business.is_active === 0 && (
          <ShowCollection isFront={false} userId={userInfo.id} />
        )}
        {business.is_active === 1 && slides.results.length > 0 && (
          <>
            <Slideshow data={slides.results} />
            {/* {this.orderCategories(services.results, items).map((item) => {
              if (!isEmpty(item.value)) {
                return this.renderElement(item.key, item.value);
              }
              return null;
            })} */}
          </>
        )}
        <Modal open={open} onClose={this.onCloseModal} top showCloseIcon={true}>
          <Pane className="card-body popup-body-content popup-body-business rows">
            <h3 className="card-title">
              <FormattedMessage
                id="notification.title"
                defaultMessage="NOTIFICATION"
              />
            </h3>
            <p>
              <FormattedMessage
                id="notification.empty_bussiness"
                defaultMessage="Please complete your business information so your store and products will show on the website.  Thanks"
              />
            </p>
          </Pane>
        </Modal>
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  slides: state.mySlides,
  products: state.myProducts,
  services: state.myServices,
  userInfo: state.auth && state.auth.user,
  business: state.auth.user.business ? state.auth.user.business : {},
});

const bindDispatchToProps = (dispatch) => ({
  getMySlides: bindActionCreators(getMySlides, dispatch),
  getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
  getServicesByUserId: bindActionCreators(getServicesByUserId, dispatch),
  getServiceCategoriesByUserId: bindActionCreators(
    getCategoriesByUserId,
    dispatch
  ),
  getFoodCategoriesByUserId: bindActionCreators(
    getFoodCategoriesByUserId,
    dispatch
  ),
  getOtherCategoriesByUserId: bindActionCreators(
    getOtherCategoriesByUserId,
    dispatch
  ),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(Overview);
