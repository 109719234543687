import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import BusinessLogoItem from "./BusinessLogoItem";
import { getBusinessCboosts } from "actions/business";
class BusinessLogoList extends Component {
  static propTypes = {
    auth: PropTypes.object,
    businessLogos: PropTypes.object,
  };

  renderList() {
    const arrays = [
      {
        id: 1,
        name: <FormattedMessage id="home.promotions" />,
        url: "/assets/images/home/Promotion.svg",
        link: "promotions",
      },
      {
        id: 4,
        name: <FormattedMessage id="best_picks" />,
        url: "/assets/images/home/bestpick.svg",
        link: "/product-picks",
      },
      {
        id: 5,
        name: <FormattedMessage id="just_listed" />,
        url: "/assets/images/home/newcoming.svg",
        link: "/just-listed",
      },
    ];
    return arrays.map((item) => (
      <BusinessLogoItem
        key={item.id}
        id={item.id}
        auth={this.props.auth}
        business={item}
      />
    ));
  }

  render() {
    return (
      <Pane className="bussiness-container">
        <Pane className="container">
          <Pane className="row">{this.renderList()}</Pane>
        </Pane>
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  businessLogos: state.businessLogos,
});

const mapDispatchToProps = (dispatch) => ({
  getBusinessCboosts: bindActionCreators(getBusinessCboosts, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessLogoList);
