const vi = {
  // Common
  home: "Trang chủ",
  stores: "Cửa hàng",
  store: "Cửa hàng",
  products: "Sản phẩm",
  promotionalProducts: "Sản phẩm khuyến mãi",
  product: "Sản phẩm",
  services: "Dịch vụ",
  service: "Dịch vụ",
  promotionalServices: "Dịch vụ khuyến mãi",
  contact: "Liên hệ",
  policy: "Chính sách",
  details: "Chi Tiết",
  policy_welcome: "Chào mừng bạn đến với Chính sách Cardbey",
  "terms-conditions": "Điều khoản",
  terms: "Các điều khoản và điều kiện",
  terms_welcome: "Chào mừng bạn đến với Chính sách Cardbey",
  faqs: "Câu hỏi thường gặp",
  cities: "Thành phố",
  categories: "Danh mục",
  category: "Danh mục",
  category_name: "Tên danh mục",
  category_image: "Thể loại hình ảnh",
  search: "Tiềm kiếm",
  promotions: "Khuyến mãi",
  promotion: "Khuyến mãi",
  sharemystores: "Chia sẻ",
  my_stores: "Cửa hàng của tôi",
  my_message: "Tin nhắn của tôi",
  my_order: "Đơn hàng của tôi",
  name: "Tên",
  full_name: "Họ và tên",
  first_name: "Tên",
  last_name: "Họ",
  phone: "Điện thoại",
  email: "Email",
  password: "Mật khẩu",
  confirm_password: "Nhập lại mật khẩu",
  old_password: "Mật khẩu cũ",
  new_password: "Mật khẩu mới",
  confirm_new_password: "Xác nhận mật khẩu mới",
  address: "Địa chỉ",
  post_code: "Mã bưu chính",
  position: "Vị trí",
  description: "Mô tả",
  sub_desc: "Mô tả nhanh",
  content: "Nội dung",
  subject: "Tiêu đề",
  sign_in: "Đăng Nhập",
  forgot_password: "Quên mật khẩu?",
  reset_password: "Đặt lại mật khẩu",
  website: "Website",
  sign_up: "Đăng Ký",
  trending: "Xu hướng",
  giftcards: "THẺ QUÀ TẶNG",
  promotion_banner: "Banner khuyến mãi",
  best_pick: "Được chọn nhiều nhất",
  best_picks: "CHỌN NHIỀU NHẤT",
  picks: "Nhiều nhất",
  profiles_setting: "Cài đặt",
  just_listed: "DANH SÁCH",
  begin_the_bests_in_the_lits: "Hãy là những nhà cung cấp tốt nhất.",
  newcoming: "ĐIỀU MỚI ĐANG ĐẾN",
  classified_ad: "QUẢNG CÁO, RAO VẶT",
  food_order: "Food order",
  created_a_free_shop: "TẠO SHOP MIỄN PHÍ NGAY",
  oppshop: "RAO VẶT",
  "home.promotions": "KHUYẾN MÃI",
  "home.trending": "XU HƯỚNG",
  all: "Tất cả",
  this_is_your_site: "Đây là trang web của bạn",
  start_now: "Bắt đầu ngay",
  create_your_store: "TẠO CỬA HÀNG CỦA BẠN",
  create_for_free: "TẠO MIỄN PHÍ",
  testimonials: "PHẢN HỒI",
  best_matched: "Phù hợp nhất",
  people_bought_the_sam_your_alse_buying_these:
    "Mọi người mua cùng của bạn cũng mua những",
  testimonials_carbey:
    "Cardbey đơn giản và thân thiện với người dùng. Tôi đã giới thiệu nhiều bạn bè và gia đình của tôi. Đã hoàn thành và tiếp tục công việc tốt",
  testimonials_carbey_kim: `Cardbey cung cấp một bộ công cụ tuyệt vời để giúp các doanh nghiệp nhỏ thành công trong không gian thương mại điện tử. Trong 10 phút tôi đã hoàn thành cửa hàng trực tuyến của riêng mình và sẵn sàng nhận đơn đặt hàng. Kinh ngạc`,
  start_now_uppercase: "BẮT ĐẦU BÂY GIỜ",
  your_online_shop: "Cửa hàng trực tuyến của bạn",
  your_online_shop_description:
    "Cửa hàng trực tuyến tuyệt đẹp của bạn có ở đây trên nền tảng thương mại điện tử tích hợp mạnh mẽ và mạnh mẽ của chúng tôi để giúp bạn mở khóa tiềm năng và sáng tạo khổng lồ của mình mà không giới hạn để phát triển doanh nghiệp của bạn",
  call_to_you: "Call To You",
  browse_by: "Duyệt theo",
  product_category: "Danh mục sản phẩm",
  service_category: "Danh mục dịch vụ",
  featured: "Nổi bật",
  image: "Hình ảnh",
  product_name: "Tên sản phẩm",
  quantity: "Số lượng",
  unit_price: "Giá từng đơn vị",
  total_price: "Tổng tiền",
  total_item: "Tổng số mặt hàng:",
  total: "Tổng",
  avg_rating: "Trung bình Xếp hạng:",
  customer_information: "Thông tin khách hàng",
  shipping_address: "Thông tin giao hàng",
  ship_to_my_address: "Gửi đến địa chỉ của tôi",
  order_infomation: "THÔNG TIN ĐẶT HÀNG",
  booking_infomation: "Thông tin về đặt vé",
  payment_method: "Phương thức thanh toán",
  payment_status: "Trạng thái thanh toán",
  payment_information: "Thông tin thanh toán",
  order_number: "Mã đơn hàng",
  order_date: "Ngày đặt hàng",
  total_payment: "Tổng tiền thanh toán",
  order_status: "Trạng thái đơn hàng",
  delivery_address: "Địa chỉ giao hàng",
  delivery_suburb: "Giao hàng ngoại ô",
  delivery_postcode: "Mã bưu chính",
  delivery_state: "Quận / Huyện",
  delivery_country: "Quốc gia",
  delivery_city: "Tỉnh / Thành phố",
  delivery_method: "Phương thức vận chuyển",
  delivery_returns: "Giao hàng & Trả hàng",
  view_all: "Xem tất cả",
  proceed_to_check_out: "TIẾN HÀNH KIỂM TRA",
  custom_static_block_for_product_detail:
    "Khối tĩnh tùy chỉnh cho chi tiết sản phẩm",
  free_shipping: "MIỄN PHÍ VẬN CHUYỂN",
  free_shipping_on_all_us_order_or_order_above:
    "Giao hàng miễn phí cho tất cả các đơn đặt hàng tại Hoa Kỳ hoặc đặt hàng trên $ 200",
  support_24_7: "HỖ TRỢ 24/7",
  "30_days_return": "30 NGÀY TRẢ LẠI",
  contact_us_24_hours:
    "Liên lạc với chúng tôi 24 giờ một ngày. 7 ngày một tuần",
  simply_return_it_within_30_days_for_an_exchange:
    "Đơn giản chỉ cần trả lại trong vòng 30 ngày để trao đổi.",
  payment_secure: "100% AN TOÀN THANH TOÁN",
  we_ensure_secure_payment_with_pev:
    "Chúng tôi đảm bảo thanh toán an toàn với PEV",
  sign_up_newsletter: "ĐĂNG KÝ BẢN TIN",
  sign_up_our_newsletter_and_save_25_off_for_the_next_purchase:
    "Đăng ký để nhận bản tin của chúng tôi và không bỏ lỡ những sản phẩm và dịch vụ mới nhất với các chương trình khuyến mãi trên hệ thống lên tới tận 50%.",
  subscribe_to_get_your_15_off_first_order:
    "Đăng ký để được giảm giá 15% cho đơn hàng đầu tiên của bạn",
  subscirbe_to_our_newsletters: ` `,
  enter_your_email_address: "Nhập email của bạn",
  subscribe: `Đăng ký ngay`,
  your_information_will_never_be_shared_with_any_third_party:
    "Thông  tin của bạn sẽ không bao giờ được chia sẻ với bất kỳ bên thứ ba nào.",
  dont_show_this_popup_again: `ĐỪNG HIỂN THỊ LẠI POPUP NÀY`,
  pay_at_store: "Thanh toán tại cửa hàng",
  pay_by_cash: "Thanh toán bằng tiền mặt",
  pay_by_credit: "Thanh toán bằng thẻ tín dụng",
  pay_at_counter: "Thanh toán tại quầy",
  overview: "Tổng quan",
  my_menu: "Thực đơn",
  our_menu: "Thực đơn của chúng tôi",
  my_products: "Sản phẩm",
  my_catalog: "Sản phẩm",
  my_services: "Dịch vụ",
  my_tables: "Bàn ăn",
  orders: "Đơn hàng",
  result_search: "Kết quả tìm kiếm",
  bookings: "Lịch hẹn",
  settings: "Cài đặt tài khoản",
  logout: "Đăng xuất",
  store_information: "Thông tin cửa hàng",
  business_information: "Thông tin doanh nghiệp",
  create_new_service: "Tạo mới dịch vụ",
  create_new_collection: "Tạo mới bộ sưu tập",
  create_new_product: "Tạo mới item",
  create_new_table: "Tạo mới bàn ăn",
  create_new_video: "Tạo mới video",
  edit_product: "Chỉnh sửa sản phẩm",
  edit_table: "Chỉnh sửa bàn ăn",
  edit_service: "Chỉnh sửa dịch vụ",
  create_new_promotion: "Tạo mới khuyến mãi",
  create_now: "HÃY TẠO NGAY",
  duration: "Thời gian",
  minutes: "Phút",
  trading_hours: "Thời gian làm việc",
  booking_times: "Thời gian đã chọn",
  note: "Chú thích",
  create: "Tạo",
  from: "Từ",
  to: "Đến",
  expired: "Hết hạn",
  confirmation: "Xác nhận",
  in_promotion: "Khuyến mãi",
  start_date: "Bắt đầu",
  end_date: "Kết thúc",
  promotion_name: "Tên khuyến mãi",
  percent_discount: "Phần trăm giảm giá",
  edit_promotion: "Chỉnh sửa khuyến mãi",
  buying_orders: "Đơn hàng mua",
  selling_orders: "Đơn hàng bán",
  summary: "Tóm tắt",
  order_list: "Danh sách sản phẩm",
  your_name: "Tên",
  your_email: "Email",
  your_phone: "Điện thoại",
  your_address: "Địa chỉ",
  customer_name: "Tên khách hàng",
  customer_email: "Email khách hàng",
  customer_phone: "Điện thoại khách hàng",
  customer_address: "Địa chỉ khách hàng",
  refund_price: "Tiền hoàn lại",
  refunded: "Số tiền đã hoàn lại",
  seller: "Người bán",
  status: "Trạng thái",
  request_cancel: "Yêu cầu hủy",
  cancel_request: "Yêu cầu hủy",
  manage_booked_services: "Quản lý những dịch vụ đã đặt lịch",
  manage_your_services: "Quản lý dịch vụ của bạn",
  our_services: "Dịch vụ của chúng tôi",
  service_name: "Tên dịch vụ",
  service_provider: "Nhà cung cấp dịch vụ",
  booking_detail: "Chi tiết lịch hẹn",
  booking_list: "Danh sách lịch hẹn",
  booking_date: "Ngày đặt lịch",
  appointment_date: "Ngày hẹn",
  customer_appointment: "Lịch hẹn của khách hàng",
  customer: "Khách hàng",
  choose_date: "Chọn ngày",
  time_slot: "Khoảng thời gian",
  change_your_password: "Thay đổi mật khẩu",
  background_image: "Ảnh nền",
  create_new_menu: "Tạo menu mới",
  frequently: "Các câu hỏi thường gặp",
  frequently_welcome: "Chào mừng bạn đến với câu hỏi thường gặp của Cardbey",
  view_my_qr_code: "Xem mã QR của tôi",
  print_qr_code: "In mã QR",
  was: "là",
  welcome_to_cardbey: "Chào mừng đến với Cardbey",
  you_have_had_a_online_store_click_to_view:
    "Bạn đã có một cửa hàng trực tuyến, bấm vào để xem",
  view_your_store: "Xem cửa hàng của bạn",
  update_your_profile: "Cập nhật hồ sơ của bạn",
  you_can_update_your_profile:
    "Bạn có thể cập nhật hồ sơ của mình: Tên, hình đại diện, điện thoại, địa chỉ, hồ sơ doanh nghiệp của bạn ...",
  create_your_product_now: "Tạo sản phẩm của bạn bây giờ",
  create_your_serivce_now: "Tạo dịch vụ của bạn ngay bây giờ",
  it_totaly_free: "Hoàn toàn miễn phí",
  in_local_or_global_market: "Tại thị trường địa phương hoặc toàn cầu",
  start_your_online_store_in_minutes:
    "Bắt đầu cửa hàng trực tuyến của bạn trong vài phút",
  start_your_business_online_and_turn_it_mobile:
    "HÃY BẮT ĐẦU VIỆC KINH DOANH CỦA BẠN TRỰC TUYẾN VÀ DI ĐỘNG.",
  cardbey_unlock_your_business: `Cardbey giải phóng tiềm năng và sức mạnh doanh nghiệp của bạn để giúp bạn bán sản phẩm, dịch vụ tới bất kỳ ai và bất kỳ nơi nào, tại thị trường địa phương hoặc toàn cầu. Hỗ trợ tại cửa hàng hay giao dịch trực tuyến. Nhanh chóng và đơn giản trên chỉ một nền tảng với khả năng quản lý, mua bán và các giải pháp quảng cáo tích hợp.`,
  sell_made_easy_now: "Bán hàng giờ sẽ dễ hơn.",
  // catalog
  our_catalog: "Danh mục của chúng tôi",
  // business
  business_profile: "Hồ sơ doanh nghiệp",
  business_name: "Tên doanh nghiệp",
  business_email: "Email doanh nghiệp",
  business_address: "Địa chỉ doanh nghiệp",
  business_phone: "Số điện thoại doanh nghiệp",
  edit_business_info: "Chỉnh sửa thông tin doanh nghiệp",
  off: "Đóng cửa",
  your_order: "Đơn hàng của bạn",
  your_booking: "Lịch hẹn của bạn",
  time: "Thời gian",
  reviews: "Đánh giá",
  card_number: "Số thẻ",
  about_us: "Về chúng tôi",
  blogs: "BLOGS",
  investor: "Nhà đầu tư",
  stay_conect_with_us: "LƯU TRỮ KẾT NỐI VỚI CHÚNG TÔI",
  contact_us: "Liên hệ",
  cardbey: "Cardbey Shop",
  buy_sell: "Mua & Bán",
  helpfull: "Hữu ích",
  partner_program: "Đối tác kinh doanh",
  your_comment: "Bình luận của bạn",
  booking: "Đặt lịch",
  booking_information: "Thông tin lịch hẹn",
  booking_management: "Quản lý đặt phòng",
  found_for: "tìm được cho",
  result: "kết quả",
  results: "kết quả",
  // action
  save: "Lưu",
  accept: "Đồng ý",
  deny: "Từ chối",
  confirm: "Xác nhận",
  yes: "Có",
  no: "Không",
  edit: "Cập nhật",
  delete: "Xóa",
  boost: "Tăng cường",
  delivery: "Giao hàng",
  pickup: "Lấy tại quầy",
  dine_in: "Ăn tại bàn",
  take_away: "Mang về",
  remove: "Hủy",
  view_more: "Xem thêm",
  send: "Gửi",
  back: "Quay lại",
  add_to_cart: "Thêm vào giỏ",
  book_now: "Đặt lịch ngay",
  sort_by: "Sắp xếp theo",
  share: "Chia sẻ",
  go_back_to_home: "Quay về trang chủ",
  only_promotions: "Chỉ khuyến mãi",
  learn_more: "TÌM HIỂU THÊM",
  more_infomation: "THÊM THÔNG TIN",
  custom_tab: "TAB TÙY CHỈNH",
  review: "Đánh giá",
  business_infomation: "THÔNG TIN KINH DOANH",
  help_password:
    "Vui lòng nhập 8 kí tự trở lên cho mật khẩu của bạn.(Bao gồm: chữ thường, hoa và số).",
  videos: "Video",
  shows: "Shows",
  users_business_partners: "Người dùng/Đối tác Kinh doanh",
  instant_delivery: "giao hàng ngay",
  // Navigation
  "nav.login": "Đăng nhập",
  "nav.shop_now": "Mua Sắm Ngay",
  "nav.show_more": "Xem thêm",
  "nav.build_a_shop": "Tạo cửa hàng",
  // Search
  "search.im_looking_for": "Tìm kiếm theo",
  // validate
  "validate.require_field": "Bạn không thể bỏ trống chỗ này",
  "validate.special_field": "Không được sử dụng kí tự đặc biệt",
  "validate.invalid_email": "Email không hợp lệ",
  "validate.invalid_post_code": "Mã bưu chính không hợp lệ",
  "validate.invalid_price": "Giá không hợp lệ",
  "validate.invalid_password": "Mật khẩu không đúng định dạng",
  "validate.invalid_phone":
    "Số điện thoại của bạn không đúng định dạng, lớn hơn hoặc bằng 11 ký tự",
  "validate.invalid_confirm_password": "Nhập lại mật khẩu không đúng",
  "validate.invalid_compare_date":
    "Ngày bắt đầu phải nhỏ hơn hoặc bằng ngày kết thúc",
  "validate.invalid_card_number": "Số thẻ không hợp lệ",
  "validate.invalid_cvv": "Số CVV không hợp lệ",
  "validate.please_select_a_payment_method":
    "Vui lòng chọn phương thức thanh toán",
  "validate.choose_products_or_services": "Vui lòng chọn sản phẩm hoặc dịch vụ",
  "validate.please_enter_your_reason": "Vui lòng nhập lý do bạn muốn hủy",
  "validate.please_choose_time_slots": "Vui lòng chọn lịch hẹn",
  "validate.please_choose_payment_method":
    "Vui lòng chọn phương thức thanh toán",
  "validate.date_expect_delivery":
    "Dự kiến thời gian giao hàng cao hơn 50 phút so với thời gian nhận hàng",
  "validate.date_pickup_now": "Thời gian đón cao hơn hiện tại 1 giờ",
  "validate.invalid_credit_info": "Thông tin của credit không hợp lệ",
  // notification
  "notification.title": "THÔNG BÁO",
  "notification.empty_bussiness":
    "Vui lòng hoàn thiện thông tin doanh nghiệp để gian hàng, sản phẩm của bạn hiển thị trên website. Cảm ơn",
  "notification.please_try_again": "Vui lòng thử lại sau!",
  "notification.please_login_to_make_like":
    "Vui lòng đăng nhập để tạo lượt thích",
  "notification.please_login_to_make_comments":
    "Vui lòng đăng nhập để bình luận",
  "notification.please_choose_rating": "Vui lòng đánh giá trước khi bình luận",
  "notification.thank_for_like": "Cảm ơn bạn đã thích",
  "notification.require_character":
    "Mật khẩu của bạn phải có ít nhất 6 ký tự với tối thiểu 1 ký tự chữ thường, 1 ký tự chữ hoa và một ký tự số",
  "notification.you_must_login_before_you_can_continue":
    "Bạn phải đăng nhập để tiếp tục!",
  "notification.your_account_has_been_created_successfully":
    "Tài khoản của bạn đã được tạo thành công",
  "notification.you_have_not_had_any_products_yet":
    "Bạn chưa có bất kỳ sản phẩm nào",
  "notification.you_have_not_had_any_services_yet":
    "Bạn chưa có bất kỳ dịch vụ nào",
  "notification.you_have_not_had_any_orders_yet":
    "Bạn chưa có bất kỳ đơn hàng nào",
  "notification.you_have_not_had_any_bookings_yet":
    "Bạn chưa có bất kỳ lịch hẹn nào",
  "notification.you_have_not_had_any_promotions_yet":
    "Bạn chưa có bất kỳ khuyến mãi nào",
  "notification.this_store_has_not_had_any_products_yet":
    "Cửa hàng này chưa có bất kỳ sản phẩm nào.",
  "notification.this_store_has_not_had_any_services_yet":
    "Cửa hàng này chưa có bất kỳ dịch vụ nào.",
  "notification.you_have_not_had_any_digitals_share_yet":
    "Bạn chưa có bất kỳ khuyến digitals share nào",
  "notification.this_store_has_not_had_any_promotions_yet":
    "Cửa hàng này chưa có bất kỳ khuyến mãi nào.",
  "notification.are_you_sure_you_want_to_delete_this":
    "Bạn có chắc chắn muốn xóa",
  "notification.are_you_sure_you_want_to_cancel_this":
    "Bạn có chắc chắn muốn hủy",
  "notification.are_you_sure_you_want_to_accept_this_request":
    "Bạn có chắc chắn muốn đồng ý với yêu cầu này",
  "notification.are_you_sure_you_want_to_deny_this_request":
    "Bạn có chắc chắn muốn từ chối yêu cầu này",
  "notification.are_you_sure_you_want_to_change_status":
    "Bạn có chắc chắn muốn thay đổi trạng thái",
  "notification.your_request_has_been_denied_by_seller":
    "Yêu cầu hủy lịch của bạn đã bị hủy",
  "notification.successfully": "Thành công",
  "notification.select_your_products_and_services":
    "Chọn sản phẩm hoặc dịch vụ",
  "notification.are_you_sure_you_want_to_decline_this_request":
    "Bạn có chắc chắn muốn từ chối yêu cầu này",
  our_products: "Sản phẩm của chúng tôi",
  our_video: "Video của chúng tôi",
  "notification.cannot_get_order_details": "Không lấy được chi tiết đơn hàng",
  "notification.can_not_request_cancel_this_product":
    "Không thể gửi yêu cầu hủy sản phẩm",
  "notification.you_have_sent_request": "Bạn đã gửi yêu cầu hủy",
  "notification.you_have_sent_cancellation_request": "Bạn đã gửi yêu cầu hủy",
  "notification.you_have_sent_request_successfully":
    "Bạn đã gửi yêu cầu hủy sản phẩm thành công",
  "notification.you_have_cancelled_this_booking_successfully":
    "Bạn đã hủy lịch hẹn này thành công",
  "notification.your_password_has_been_changed_successfully":
    "Bạn đã đổi mật khẩu thành công",
  "notification.recommended_png_or_jpg_at_resolution":
    "Đề xuất bạn sử dụng tập tin hình ảnh PNG hoặc JPG có độ phân giải 1280x768",
  "notification.your_order_has_been_saved_successfully":
    "Bạn đã đặt hàng thành công",
  "notification.your_booking_has_been_saved_successfully":
    "Bạn đã đặt lịch hẹn thành công",
  "notification.thank_for_review": "Cảm ơn những đánh giá của bạn",
  "notification.no_comments": "Chưa có bình luận nào",
  "notification.the_page_you_requested_was_not_found":
    "Xin lỗi! Chúng tôi không thể tìm thấy trang này",
  "notification.click_to_view_detail": "Nhấp để xem chi tiết",
  "notification.this_item_is_in_cancellation_requesting":
    "Món hàng này được người mua yêu cầu hủy",
  "notification.forgot_password_success":
    "Chúng tôi sẽ gửi hướng dẫn khôi phục mật khẩu đến email đã đăng ký của bạn. Vui lòng kiểm tra hộp thư chính và hộp thư rác.",
  "notification.you_have_entered_the_wrong_password":
    "Bạn đã nhập sai mật khẩu. Xin vui lòng đăng nhập lại",
  "notification.you_have_successfully_signed_up_for_the_newsletter":
    "Bạn đăng ký bản tin thành công",
  "notification.you_have_not_had_any_notifications_yet":
    "Bạn chưa có thông báo nào.",
  // Home page
  "home.build_your_free_webstore_in_minutes":
    "Xây dựng cửa hàng web miễn phí của bạn trong vài phút",
  "home.be_there_locally_or_globally_with_no_limits":
    "Be there locally or globally with no limits",
  "home.free_to_cut_cost_and_earn_thousands_more":
    "Miễn phí để cắt giảm chi phí và kiếm thêm hàng ngàn",
  "home.managing_building_your_dream":
    "Bạn đang quản lý một doanh nghiệp, xây dựng ước mơ của bạn hoặc nghĩ về Khởi nghiệp",
  "home.with_just_few_simple_steps":
    "Chỉ với vài bước đơn giản, bạn sẽ mở khóa tiềm năng to lớn của mình với công cụ Cardbey mạnh mẽ, đơn giản và hiệu quả này để tiết kiệm chi phí, thời gian, tăng doanh số và giữ kết nối mạnh mẽ với khách hàng của bạn",
  "home.cardbey_help_you_to_make_it_easier":
    "Chúng ta đều biết kinh doanh đôi khi rất khó khăn, vì vậy hãy để Cardbey giúp bạn làm cho nó dễ dàng hơn",
  "home.every_second_a_new_thing_on_air_with_cardbey":
    "Mỗi giây một điều mới được phát sóng với Cardbey",
  "home.download_our_new_app": "TẢI XUỐNG ỨNG DỤNG MỚI CỦA CHÚNG TÔI",
  "home.with_conscientious_advanced":
    "Với sự tận tâm mang lại giải pháp tối ưu để giúp xử lý các vấn đề kinh doanh của bạn. Một quy trình được tích hợp để tự động chuyển cửa hàng trực tuyến của bạn thành ứng dụng di động, giúp việc quản lý doanh nghiệp trở nên dễ dàng hơn bao giờ hết. Việc kinh doanh bây giờ nằm trong túi của bạn.",
  "home.going_socialized": "Kết nối mạng xã hội",
  "home.we_are_standing_out_with_millions":
    "Chúng tôi đang có mặt cùng hàng triệu doanh nghiệp và người tiêu dùng trên các kênh và mạng lưới xã hội cùng bạn vượt qua những giới hạn để vươn tới những tiềm năng kỳ diệu.",
  "home.download_apps_here": "Tải ứng dụng tại:",
  "home.we_are_here_on_app_stores": "Apps đã có trên kho ứng dụng.",
  "home.let_go_mobile_new":
    "Kinh doanh di động, mọi lúc, mọi nơi. Tải ứng dụng Cardbey để truy cập phiên bản di động của bạn theo các liên kết dưới đây.",
  "home.book_your_service": "Đăng Ký Dịch Vụ",
  "home.yes_just_in_minutes":
    "Vâng, chỉ trong vài phút, với một số bước đơn giản, bạn sẽ đưa doanh nghiệp của mình lên sóng để bắt đầu cung cấp các giá trị ra thế giới. Tiếp cận đối tượng không giới hạn, khám phá cơ hội vô tận. Mà lại miễn phí.",

  // Sign in page
  "signin.do_not_have_an_account": "Chưa có tài khoản?",
  // Sign up page
  "signup.create_your_account": "Tạo tài khoản miễn phí",
  "signup.agree_term_condition": "Tôi đã đọc và đồng ý với",
  "signup.term_condition": "Điều khoản và điều kiện",
  // Cart
  "cart.no_items_in_your_cart": "Chưa có sản phẩm nào trong giỏ hàng",
  "cart.continue": "Tiếp tục",
  "cart.continue_shopping": "Tiếp tục mua sắm",
  "cart.includes_gst_amount": "Bao gồm GST",
  "cart.proceed_to_checkout": "Xác nhận giỏ hàng",
  "cart.your_shopping_cart_contains": "Giỏ hàng của bạn hiện có",
  "cart.back_to_cart": "Trở lại giỏ hàng",
  "cart.back_to_booking": "Trở lại đặt lịch",
  // Order
  "order.new": "Mới",
  "order.confirmed": "Đã xác nhận",
  "order.inprogress": "Đang xử lý",
  "order.cancelled": "Đã hủy",
  "order.preparing": "Đang chuẩn bị",
  "order.delivered": "Đã giao hàng",
  "order.done": "Đã hoàn thành",
  "order.detail": "Chi tiết đơn hàng",
  "order.cancel_requested": "Đã yêu cầu hủy",
  "order.requested_cancel": "Đã yêu cầu hủy",
  // Select
  select: "Lựa chọn...",
  "select.category": "Chọn danh mục",
  "select.category_add": "Thêm danh mục mới",
  "select.image": "Chọn một hình ảnh",
  "select.image_add": "Thêm hình ảnh mới",
  // price
  "price.low_to_high": "Giá: Thấp đến cao",
  "price.high_to_low": "Giá: Cao đến thấp",
  // rating
  "rating.low_to_high": "Đánh giá: Thấp đến cao",
  "rating.high_to_low": "Đánh giá: Cao đến thấp",
  // pagination
  "pagination.previous": "Trước",
  "pagination.next": "Kế tiếp",
  // group
  group: "Nhóm",
  by_click_PAYMENT:
    "Bằng cách nhấp vào THANH TOÁN, bạn đồng ý với chính sách bảo mật của chúng tôi về thời hạn của máy chủ. Bạn cũng đồng ý nhận các cập nhật email, giảm giá và ưu đãi cụ thể theo định kỳ.",
  // video
  video_id_youtue: "Y0mmqexQ5SA",
  // homepage
  "home.let_bring_your_excellent_products":
    "Hãy để các sản phẩm và dịch vụ tuyệt vời của bạn ở đây.",
  "home.browse_by_product_category": "Duyệt theo danh mục sản phẩm",
  "home.browse_by_service_category": "Duyệt theo danh mục dịch vụ",
  "home.enjoy_exploring_or_picking_up":
    "Tận hưởng khám phá và lựa chọn những sản phẩm, dịch vụ yêu thích từ nguồn cung cấp không giới hạn.",
  "home.we_all_know_business_sometimes":
    "Việc kinh doanh đôi khi thật khó. Hãy để Cardbey giúp bạn làm được dễ hơn.",
  "home.create_your_beautiful_free_online_store_in_minutes":
    "Tạo gian hàng trực tuyến tuyệt vời trong vài phút.",
  "home.and_now_the_the_business_world_is_in_your_palm":
    "...và bây giờ, thế giới kinh doanh đã nằm trong lòng bàn tay.",
  "home.": "",
  // FAQ
  "faq.template": `<h3><strong>1. Bắt đầu với Cardbey</strong></h3>
  <h4><strong>1.1 Cardbey là gì? Cách Cardbey hoạt động?</strong></h4>
  <p>Cardbey là nền tảng thương mại điện tử hoàn chỉnh để bạn bắt đầu, phát triển và quản lý một doanh nghiệp.</p>
  <ul class="list-dot">
    <li>
      <p>Tạo và tuỳ chỉnh cửa hàng trực tuyến của bạn</p>
    </li>
    <li>
      <p>Bán hàng qua website, điện thoại di động, mạng xã hội, cửa hàng trực tuyến và cửa hàng pop-up (quầy bán hàng)</p>
    </li>
    <li>
    <p>Quản lý sản phẩm, thanh toán, giao hàng, và rao vặt trực tuyến cũng như ngoại tuyến</p>
    </li>
  </ul>
  <p>Với Cardbey, bạn không phải lo lắng về việc nâng cấp hoặc bảo trì phần mềm hoặc máy chủ web, cho phép bạn linh hoạt truy cập và điều hành doanh nghiệp của mình từ bất kỳ đâu có kết nối internet.</p>
  <h4><strong>1.2 Cardbey có giá bao nhiêu?</strong></h4>
  <p>Nền tảng Cardbey hoàn toàn miễn phí cho chủ doanh nghiệp và không cần thẻ tín dụng. Bạn có thể nâng cấp lên tài khoản trả phí để trở thành Đối tác kinh doanh của chúng tôi với phí thiết lập là $150 và $30 phí hàng tháng để tận dụng tất cả các lợi ích đi kèm với tài khoản trả phí. Không có hợp đồng và không có ràng buộc nào với Cardbey. Bạn có thể hủy bất cứ lúc nào bằng cách gửi cho chúng tôi thông báo trước một tháng qua email tại <a href="mailto:customersupport@cardbey.com">customersupport@cardbey.com</a> </p>
  <h4><strong>1.3 Tôi có cần biết thiết kế hoặc lập trình để tạo cửa hàng của mình trên Cardbey?</strong></h4>
  <p>Bạn không cần bất kỳ kỹ năng lập trình hoặc thiết kế nào để tạo cửa hàng của riêng bạn trên Cardbey.</p>
  <p>Tùy chỉnh giao diện cửa hàng của bạn với logo, ảnh và video. Nếu bạn cần trợ giúp thêm, đừng chần chừ liên hệ với Team Hỗ trợ Khách hàng của chúng tôi thông qua Chat (góc dưới bên phải) hoặc gửi email cho chúng tôi theo địa chỉ <a href="mailto:customersupport@cardbey.com">customersupport@cardbey.com</a> </p>
  <h3><strong>2. Bán hàng trên Cardbey</strong></h3>
  <h4><strong>2.1 Tôi cần gì để bắt đầu bán hàng trên Cardbey?</strong></h4>
  <p>Để bắt đầu bán hàng trên Cardbey, bạn cần phải đăng ký tài khoản, chọn Tài khoản thành viên (MIỄN PHÍ) hoặc Tài khoản cao cấp (trả phí). Sau đó bạn bắt đầu đăng sản phẩm / dịch vụ để bán.</p>
  <p>Nếu bạn chưa có sản phẩm hay dịch vụ, hãy đăng ký làm Đối tác kinh doanh của chúng tôi và nhận được hoa hồng 6,5% khi bán hàng bằng cách bán sản phẩm do Cardbey cung cấp.</p>
  <h4><strong>2.2 Tôi có thể bán hàng ở đâu với Cardbey?</strong></h4>
  <p>Cardbey cho phép bạn bán hầu hết mọi nơi có khách hàng của bạn, bao gồm trực tuyến, ngoại tuyến và qua mạng xã hội.</p>
  <h4><strong>2.3 Làm cách nào để tạo cửa hàng của tôi trên Cardbey?</strong></h4>
  <ul class="list-dot">
    <li>
      <p>Trên trang web</p>
      <p>
        Bước 1: Truy cập <a href="https://cardbey.com/">https://cardbey.com/</a><br />
        Bước 2: Chọn "Đăng ký"<br />
        Bước 3: Điền thông tin của bạn<br />
        Bước 4: Nhận email xác nhận đăng ký và làm theo hướng dẫn trong email<br />
        Bước 5: Đăng nhập và cập nhật chi tiết doanh nghiệp của bạn<br />
        Bước 6: Thêm logo, bắt đầu đăng sản phẩm và dịch vụ<br />
      </p>
    </li>
    <li>
      <p>Trên ứng dụng</p>
      <p>
        Bước 1: Tải ứng dụng Cardbey trên <a href="https://apps.apple.com/vn/app/cardbey/id1289061763" target="_blank">iOS App Store</a>  hoặc <a href="https://play.google.com/store/apps/details?id=com.cardbey.app&hl=en" target="_blank"> Google Play Store</a><br />
        Bước 2: Nhấp vào Đăng ký trực tuyến<br />
        Bước 3: Điền thông tin của bạn<br />
        Bước 4: Điền thông tin cửa hàng hoặc doanh nghiệp<br />
        Bước 5: Đăng sản phẩm và dịch vụ bạn cung cấp<br />
      </p>
    </li>
  </ul>
  <p>Nếu bạn cần trợ giúp, bộ phận hỗ trợ khách hàng của chúng tôi luôn sẵn sàng 24/7 qua Chat, Email hoặc Điện thoại. Vui lòng tới trang <a href="https://cardbey.com/contact-us" target="_blank"> Liên hệ</a> để biết chi tiết.</p>
  <h4><strong>2.4 Làm sao để cửa hàng của tôi hiển thị trên Cardbey?</strong></h4>
  <p>Bạn có thể truy cập Cửa hàng của tôi > Cài đặt tài khoản > Chỉnh sửa thông tin doanh nghiệp để nhập hoặc chỉnh sửa thông tin cơ bản về doanh nghiệp của bạn. Xin lưu ý rằng bạn cần nhập thông tin doanh nghiệp của mình để cửa hàng của bạn hiển thị trên Cardbey.</p>
  <h4><strong>2.5 Điều gì xảy ra khi tôi nhận được một đơn đặt hàng?</strong></h4>
  <p>Bạn sẽ nhận được email, thông báo ứng dụng di động hoặc thông báo RSS mỗi khi bạn nhận được đơn đặt hàng.</p>
  <h3><strong>3. Thanh toán</strong></h3>
  <h4><strong>3.1 Cardbey xử lý thanh toán như thế nào?</strong></h4>
  <p>Cardbey sử dụng bộ xử lý thanh toán của bên thứ ba (còn được gọi là nhà cung cấp thanh toán hoặc cổng thanh toán). Cardbey được bảo vệ với giao thức bảo mật SSL (Lớp cổng bảo mật) để đảm bảo thông tin nhạy cảm được an toàn.</p>
  <h4><strong>3.2 Làm sao để tôi nhận được thu nhập của mình?</strong></h4>
  <p>Cardbey xử lý các khoản thanh toán (trừ tiền hoa hồng) vào thứ Hai hàng tuần và chuyển phần còn lại vào tài khoản ngân hàng mà bạn đã đăng ký với chúng tôi. Bạn có thể xem số liệu bán hàng và các dữ liệu khác trong trang quản trị của mình sau khi đăng nhập.</p>
  <h4><strong>3.3 Có tuỳ chọn thanh toán tiền mặt không?</strong></h4>
  <p>Cardbey cung cấp hai phương thức thanh toán "Thanh toán bằng tiền mặt" và "Thanh toán bằng thẻ". Thanh toán bằng tùy chọn tiền mặt chỉ có sẵn đối với sản phẩm/dịch vụ mà khách hàng trực tiếp đến nhận hàng.</p>
  <h3><strong>4. Giao hàng tận nơi</strong></h3>
  <h4><strong>4.1 Tôi có cần tự vận chuyển hàng không?</strong></h4>
  <p>Không, bạn không cần phải tự vận chuyển đơn hàng tới người mua. Bạn có thể sử dụng Dịch vụ Giao hàng Cardbey để chuẩn bị và giao đơn hàng.</p>
  <h4><strong>4.2 Tôi có thể tự lấy sản phẩm của mình tại cửa hàng không?</strong></h4>
  <p>Có, Cardbey cung cấp tùy chọn Lấy tại quầy cho các sản phẩm trên cửa hàng trực tuyến Cardbey. Hãy chọn Lấy tại quầy khi Thanh toán sản phẩm.</p>
  <h4><strong>4.3 Chi phí giao hàng là bao nhiêu?</strong></h4>
  <p>Chi phí thay đổi tùy theo kích thước, trọng lượng và thời gian giao hàng. Vui lòng truy cập trang web Cardbey để biết thêm thông tin. Đối với người mua hàng, phí giao hàng được tính tại thanh toán.</p>

  <h3><strong>5.Giải quyết tranh chấp</strong></h3>
  <h4><strong>5.1 Tôi có khiếu nại, tôi phải liên hệ với ai?</strong></h4>
  <p>
  Nếu bạn có bất kỳ ý kiến, khiếu nại hoặc vấn đề nào khác, vui lòng liên hệ với Nhóm Hỗ trợ Khách hàng qua email customersupport@cardbey.com. Chúng tôi luôn cố gắng giải quyết mọi vấn đề trong vòng 48 giờ. Nếu bạn là khách hàng và không hài lòng với sản phẩm/dịch vụ bạn đã mua, vui lòng liên hệ trực tiếp với doanh nghiệp và cửa hàng liên quan, đồng thời thông báo cho chúng tôi để cùng bạn giải quyết vấn đề. Mục tiêu của Cardbey là cả người mua và người bán cùng được hài lòng, và giải quyết mọi vấn đề càng sớm càng tốt với kết quả hợp lý cho tất cả các bên liên quan.
  </p>
  <br /><br /><br />`,
  // Term Page
  "term.template": `
  <p>Các điều khoản và điều kiện này (&quot;Điều khoản&quot;) chi phối việc bạn truy cập và sử dụng trang
  web và ứng dụng di động của Cardbey có liên kết hoặc tham chiếu các Điều khoản này
  (&quot;Trang web&quot;, “Trang”, “Ứng dụng”). Bằng cách truy cập hoặc sử dụng Trang web, bạn đồng ý
  với các Điều khoản này và ký kết hợp đồng ràng buộc về mặt pháp lý với Công ty Giới hạn
  Tư nhân Caterwin (“Caterwin”). Nếu bạn không muốn hoặc không thể bị ràng buộc bởi Điều
  khoản, vui lòng không truy cập hoặc sử dụng Trang web.</p>

  <h3><strong>1. ĐỊNH NGHĨA</strong></h3>
  <h4><strong>1.1 Các bên</strong></h4>
  <p>&quot;Bạn&quot; và &quot;của bạn&quot; đề cập đến bạn, với tư cách là người dùng Trang web. Một &quot;người dùng&quot;
  là người truy cập, duyệt, thu thập dữ liệu, cạo dữ liệu hoặc sử dụng Trang web bằng bất kỳ
  cách nào. &quot;Chúng tôi&quot; và &quot;của chúng tôi&quot; đề cập đến Cardbey.</p>
  <h4><strong>1.2 Trang web</strong></h4>
  <p>&quot;Trang web&quot; có nghĩa là các cửa hàng trực tuyến của chúng tôi, có thể được truy cập tại (<a href="https://cardbey.com">https://cardbey.com</a>)</p>
  <h4><strong>1.3 Ứng dụng</strong></h4>
  <p>&quot;Ứng dụng&quot; nghĩa là phần mềm ứng dụng trực tuyến Cardbey do Caterwin cung cấp mà bạn
  cài đặt hoặc tải xuống từ cửa hàng ứng dụng trực tuyến và truy cập qua thiết bị di động, bao
  gồm mọi thiết bị điện thoại thông minh và / hoặc máy tính bảng.</p>
  <h4><strong>1.4 Trang web</strong></h4>
  <p>&quot;Trang web&quot; có nghĩa là Trang web và Ứng dụng như đã đề cập ở trên.</p>
  <h4><strong>1.5 Nội dung</strong></h4>
  <p>&quot;Nội dung&quot; có nghĩa là văn bản, hình ảnh, hình ảnh, âm thanh, video, dữ liệu vị trí và tất cả
  các dạng dữ liệu hoặc giao tiếp khác. &quot;Nội dung của bạn&quot; nghĩa là Nội dung bạn gửi hoặc
  truyền tới, thông qua hoặc liên quan đến Trang web, chẳng hạn như xếp hạng, đánh giá,
  khen ngợi, lời mời, đăng ký, tin nhắn và thông tin mà bạn hiển thị công khai hoặc hiển thị
  trong hồ sơ tài khoản của bạn. &quot;Nội dung người dùng&quot; nghĩa là Nội dung mà người dùng gửi
  hoặc truyền tới, thông qua hoặc liên quan đến Trang web. &quot;Nội dung Cardbey&quot; có nghĩa là
  Nội dung mà chúng tôi tạo và cung cấp có liên quan đến Trang web. &quot;Nội dung của bên thứ
  ba&quot; có nghĩa là Nội dung bắt nguồn từ các bên khác ngoài Cardbey hoặc người dùng của nó,
  được cung cấp cùng với Trang web. &quot;Nội dung trang web&quot; nghĩa là tất cả Nội dung được
  cung cấp liên quan đến Trang web, bao gồm Nội dung của bạn, Nội dung người dùng, Nội
  dung của bên thứ ba và Nội dung Cardbey.</p>
  <h4><strong>1.6 Sản phẩm</strong></h4>
  <p>&quot;Sản phẩm&quot; có nghĩa là tất cả các sản phẩm hoặc dịch vụ được hiển thị để bán trên trang
  web của chúng tôi, bao gồm cả dịch vụ giao hàng.</p>
  <h4><strong>1.7 Đơn hàng</strong></h4>
  <p>&quot;Đơn hàng&quot; nghĩa là tập hợp những vật phẩm, sản phẩm bạn đặt mua từ trang web của
  chúng tôi.</p>
  <h4><strong>1.8 Chính sách</strong></h4>
  <p>&quot;Chính sách&quot; nghĩa là các chính sách khác nhau có trong hoặc được đề cập ở đây và trên
  Trang web của chúng tôi, bao gồm các câu hỏi thường gặp (&quot;Câu hỏi thường gặp&quot;) và thông
  tin sản phẩm trực tuyến được hiển thị, là một phần của Điều khoản và Điều kiện này và đôi
  khi có thể được cập nhật mà không cần thông báo trước</p>

  <h3><strong>2. THAY ĐỔI CÁC ĐIỀU KHOẢN DỊCH VỤ</strong></h3>
  <p>Chúng tôi có thể sửa đổi các Điều khoản theo thời gian. Phiên bản mới nhất của các Điều
  khoản này sẽ được đặt trên Trang web. Bạn hiểu và đồng ý rằng quyền truy cập hoặc sử
  dụng Trang web của bạn nằm trong phạm vi quản trị của Điều khoản có hiệu lực tại thời
  điểm bạn truy cập hoặc sử dụng Trang web. Nếu chúng tôi thực hiện thay đổi quan trọng đối
  với các Điều khoản này, chúng tôi sẽ thông báo cho bạn qua email hoặc bằng cách đăng
  thông báo trên Trang web trước ngày thay đổi có hiệu lực. Chúng tôi cũng sẽ chỉ ra ở đầu
  trang này ngày sửa đổi được thực hiện lần cuối. Bạn nên xem lại các Điều khoản này một
  cách thường xuyên vì các phiên bản sửa đổi sẽ ràng buộc bạn. Mọi sửa đổi như vậy sẽ có
  hiệu lực khi chúng tôi đăng Điều khoản mới. Bạn hiểu và đồng ý rằng việc bạn tiếp tục truy
  cập hoặc sử dụng Trang web sau ngày sửa đổi có hiệu lực đối với Điều khoản đồng nghĩa
  với việc bạn chấp nhận sửa đổi.</p>

  <h3><strong>3. PHIÊN DỊCH</strong></h3>
  <p>Chúng tôi có thể phiên dịch các Điều khoản này sang các ngôn ngữ khác để thuận tiện cho
  bạn. Tuy nhiên, phiên bản tiếng Anh chi phối mối quan hệ của bạn với chúng tôi và mọi mâu
  thuẫn giữa các phiên bản khác nhau sẽ được giải quyết theo hướng có lợi cho phiên bản
  tiếng Anh.</p>

  <h3><strong>4. SỬ DỤNG TRANG WEB</strong></h3>
  <h4><strong>4.1 Điều kiện và thẩm quyền</strong></h4>
  <p>Để truy cập hoặc sử dụng Trang web, bạn phải có quyền hạn và thẩm quyền cần thiết để
  tham gia vào các Điều khoản này. Bạn không được truy cập hoặc sử dụng Trang nếu trước
  đó chúng tôi đã cấm bạn khỏi Trang hoặc đóng tài khoản của bạn.</p>

  <h3><strong>4.2 Quyền sử dụng trang web</strong></h3>
  <p>Chúng tôi cung cấp cho bạn quyền sử dụng Trang web theo các hạn chế trong các Điều
  khoản này. Việc bạn sử dụng Trang web có nguy cơ của riêng bạn, bao gồm rủi ro rằng bạn
  có thể tiếp xúc với Nội dung gây khó chịu, không đứng đắn, không chính xác, phản cảm hoặc
  không phù hợp.</p>

  <h3><strong>4.3 Tính khả dụng của Trang</strong></h3>
  <p>Trang web có thể được sửa đổi, cập nhật, gián đoạn, đình chỉ hoặc ngừng hoạt động bất cứ
  lúc nào mà không cần thông báo hoặc chịu trách nhiệm pháp lý.</p>

  <h3><strong>4.4 Tài khoản người dùng</strong></h3>
  <p>Chúng tôi khuyến khích bạn tạo một tài khoản và cung cấp thông tin nhất định về bản thân để
  sử dụng một số tính năng được cung cấp thông qua Trang web. Bạn có trách nhiệm duy trì
  tính bảo mật của mật khẩu tài khoản của bạn. Bạn cũng chịu trách nhiệm cho tất cả các hoạt

  động xảy ra liên quan đến tài khoản của bạn. Bạn đồng ý thông báo cho chúng tôi ngay lập
  tức về bất kỳ việc sử dụng trái phép tài khoản của bạn. Chúng tôi có quyền đóng tài khoản
  của bạn bất cứ lúc nào vì bất kỳ lý do nào hoặc không có lý do.</p>
  <p>Tài khoản của bạn là dành cho cá nhân bạn. Khi tạo tài khoản, chúng tôi yêu cầu bạn cung
  cấp thông tin đầy đủ và chính xác về bản thân để củng cố uy tín của bạn với tư cách là người
  đóng góp cho Trang web. Bạn không được mạo danh người khác (ví dụ: thông qua danh tính
  của người nổi tiếng hoặc người hàng xóm của bạn), tạo hoặc sử dụng tài khoản cho bất kỳ ai
  khác ngoài bạn, cung cấp địa chỉ email không phải của riêng bạn hoặc tạo nhiều tài khoản.
  Nếu bạn sử dụng bút danh, hãy lưu ý rằng những người khác vẫn có thể nhận dạng bạn nếu,
  ví dụ, bạn bao gồm thông tin nhận dạng trong đánh giá của mình, sử dụng cùng thông tin tài
  khoản trên các trang web khác hoặc cho phép các trang web khác chia sẻ thông tin về bạn
  với chúng tôi. Vui lòng đọc Chính sách quyền riêng tư của chúng tôi để biết thêm thông tin.</p>

  <h3><strong>4.5 Liên lạc từ Cardbey và những người dùng khác</strong></h3>
  <p>Bằng cách tạo tài khoản, bạn đồng ý nhận một số liên lạc nhất định liên quan đến Trang web.
  Ví dụ: bạn có thể nhận được lời khen, xếp hạng, tin nhắn, v.v. từ những Người dùng khác.
  Bạn cũng sẽ nhận được bản tin email hàng tuần của chúng tôi về những sự kiện xảy ra trong
  khu vực của bạn. Bạn có thể từ chối các liên lạc không cần thiết ở đây.</p>

  <h3><strong>5. NỘI DUNG</strong></h3>
  <h3><strong>5.1 Trách nhiệm đối với nội dung của bạn</strong></h3>
  <p>Một mình bạn tự chịu trách nhiệm về Nội dung của mình và sau khi công bố, không phải lúc
  nào cũng có thể rút lại. Bạn chịu mọi rủi ro liên quan đến Nội dung của bạn, bao gồm việc bất
  kỳ ai đó phụ thuộc vào chất lượng, độ chính xác hoặc độ tin cậy của thông tin, hoặc việc bất
  kỳ ai bị tiết lộ thông tin trong Nội dung của bạn khiến dẫn đến khả năng nhận dạng cá nhân
  (bao gồm chính bạn). Bạn tuyên bố rằng bạn sở hữu hoặc có các quyền cần thiết để sử dụng
  và cho phép sử dụng Nội dung của bạn như được mô tả trong tài liệu này. Bạn không được
  ám chỉ rằng Nội dung của bạn được Cardbey tài trợ hoặc chứng thực theo bất kỳ cách nào.</p>
  <p>Bạn có thể chịu trách nhiệm pháp lý nếu, ví dụ, Nội dung của bạn chứa tài liệu sai lệch, cố ý
  gây hiểu lầm hoặc nói xấu; vi phạm bất kỳ quyền của bên thứ ba, bao gồm mọi bản quyền,
  nhãn hiệu, bằng sáng chế, bí mật thương mại, quyền đạo đức, quyền riêng tư, quyền công
  khai hoặc bất kỳ quyền sở hữu trí tuệ hoặc quyền sở hữu nào khác; chứa tài liệu bất hợp
  pháp, bao gồm lời nói hoặc nội dung khiêu dâm bất hợp pháp; khai thác hoặc gây hại cho trẻ
  vị thành niên; hoặc vi phạm hoặc ủng hộ việc vi phạm bất kỳ luật hoặc quy định nào.</p>
  <h3><strong>5.2 Quyền sử dụng nội dung của bạn</strong></h3>
  <p>Chúng tôi có thể sử dụng Nội dung của bạn theo nhiều cách khác nhau, bao gồm hiển thị
  công khai, định dạng lại, kết hợp nó vào quảng cáo và các tác phẩm khác, tạo ra các tác
  phẩm phái sinh từ nó, quảng bá, phân phối và cho phép những người khác làm điều tương
  tự với trang web và nền tảng truyền thông của riêng họ. Do đó, bạn đồng ý cấp cho chúng tôi
  toàn quyền, vĩnh viễn, không độc quyền, miễn phí bản quyền, có thể chuyển nhượng, cấp
  phép, có thể chuyển nhượng để sử dụng Nội dung của bạn cho bất kỳ mục đích nào. Xin lưu
  ý rằng bạn cũng có thể cấp cho người dùng Trang web và mọi Phương tiện khác quyền truy
  cập Nội dung của bạn liên quan đến việc họ sử dụng Trang web và mọi nền tảng phương tiện
  khác. Cuối cùng, bạn khước từ và từ bỏ việc chống lại Cardbey và người dùng của nó với bất
  kỳ khiếu nại hay khẳng định nào về quyền đạo đức hoặc sự quy kết liên quan đến Nội dung
  của bạn. &quot;Sử dụng&quot; có nghĩa là sử dụng, sao chép, thực hiện công khai và hiển thị, tái tạo,

  phân phối, sửa đổi, dịch, xóa, phân tích, thương mại hóa và chuẩn bị các tác phẩm phái sinh
  của Nội dung của bạn.</p>
  <h3><strong>5.3 Quyền sở hữu</strong></h3>
  <p>Giữa bạn và Cardbey, bạn sở hữu Nội dung của bạn. Chúng tôi sở hữu Nội dung Cardbey,
  bao gồm nhưng không giới hạn giao diện trực quan, tính năng tương tác, đồ họa, thiết kế,
  biên dịch, bao gồm nhưng không giới hạn ở phần tổng hợp Nội dung người dùng và Nội dung
  trang web khác, mã máy tính, sản phẩm, phần mềm, tổng hợp đánh giá của người dùng xếp
  hạng và tất cả các yếu tố và thành phần khác của Trang web ngoại trừ Nội dung của bạn, Nội
  dung người dùng và Nội dung của bên thứ ba. Chúng tôi cũng sở hữu bản quyền, nhãn hiệu,
  nhãn hiệu dịch vụ, tên thương mại và các quyền sở hữu trí tuệ và quyền sở hữu khác trên
  toàn thế giới (&quot;Quyền IP&quot;) được liên kết với Nội dung Cardbey và Trang web, được bảo vệ
  bởi bản quyền, trang phục thương mại, bằng sáng chế, nhãn hiệu luật pháp và tất cả các
  quyền và luật sở hữu trí tuệ và quyền sở hữu khác. Do đó, bạn không được sửa đổi, sao
  chép, phân phối, tạo tác phẩm phái sinh hoặc điều chỉnh, hiển thị công khai hoặc bằng bất kỳ
  cách nào khai thác bất kỳ Nội dung Cardbey nào hoặc toàn bộ trừ khi được chúng tôi ủy
  quyền rõ ràng. Trừ khi được cung cấp rõ ràng ở đây, chúng tôi không cấp cho bạn bất kỳ
  quyền rõ ràng hay ngụ ý nào, và tất cả các quyền trong và đối với Trang web và Nội dung
  Cardbey đều được chúng tôi giữ.</p>
  <h3><strong>5.4 Quảng cáo</strong></h3>
  <p>Cardbey và những người được cấp phép của nó có thể hiển thị công khai quảng cáo và
  thông tin khác liền kề hoặc kèm theo Nội dung của bạn. Bạn không được hưởng bất kỳ
  khoản bồi thường nào cho các quảng cáo đó. Cách thức, chế độ và mức độ của quảng cáo
  như vậy có thể thay đổi mà không cần thông báo cụ thể cho bạn.</p>
  <h3><strong>5.5 Điều khoản khác</strong></h3>
  <p>Nội dung người dùng (bao gồm bất kỳ nội dung nào có thể được tạo bởi người dùng được
    thuê hoặc ký hợp đồng bởi Cardbey) không nhất thiết phản ánh ý kiến của Cardbey. Chúng
    tôi có quyền xóa, sàng lọc, chỉnh sửa hoặc khôi phục Nội dung Người dùng theo thời gian
    theo quyết định của chúng tôi vì bất kỳ lý do nào hoặc không có lý do và không cần thông báo
    cho bạn. Ví dụ: chúng tôi có thể xóa đánh giá nếu chúng tôi tin rằng nó vi phạm Nguyên tắc
    nội dung của chúng tôi. Chúng tôi không có nghĩa vụ giữ lại hoặc cung cấp cho bạn các bản
    sao Nội dung của bạn, chúng tôi cũng không đảm bảo bất kỳ sự bảo mật nào đối với Nội
    dung của bạn.</p>

  <h3><strong>6. HƯỚNG DẪN VÀ CHÍNH SÁCH</strong></h3>
  <h3><strong>6.1 Nguyên tắc nội dung</strong></h3>
  <p>Bạn tuyên bố rằng bạn đã đọc và hiểu Nguyên tắc nội dung của chúng tôi như dưới đây.</p>
  <h3><strong>i. Hướng dẫn chung</strong></h3>
  <p>Cardbey cho phép người dùng đóng góp các loại nội dung khác nhau, bao gồm đánh giá,
  ảnh, video, sự kiện, phiếu bầu, mẹo, tin nhắn trực tiếp, v.v. Vui lòng đọc hướng dẫn bên dưới
  để biết các loại nội dung cụ thể mà bạn có thể đóng góp cho trang web.</p>
  <ul class="list-dot">
    <li><p><strong>Nội dung không phù hợp:</strong> mang tính đe dọa, quấy rối, dâm ô, ngôn từ kích động thù
    địch và các biểu thị khác của sự cuồng tín được coi là nội dung không phù hợp. Việc

    sử dụng những nội dung đó đều bị nghiêm cấm, Cardbey có quyền cấm nội dung
    không phù hợp trên Trang web.</p></li>
    <li><p><strong>Xung đột lợi ích:</strong> Đóng góp của bạn nên không thiên vị và khách quan. Ví dụ: bạn
    không nên viết đánh giá về doanh nghiệp hoặc nhà tuyển dụng của riêng bạn, doanh
    nghiệp của bạn bè hoặc người thân, đồng nghiệp hoặc đối thủ trong ngành của bạn
    hoặc doanh nghiệp trong nhóm kết nối của bạn. Chủ doanh nghiệp không nên yêu cầu
    khách hàng viết đánh giá.</p></li>
    <li><p><strong>Sự liên quan:</strong> Hãy chắc chắn rằng đóng góp của bạn có liên quan và phù hợp với
    diễn đàn. Ví dụ: các bài đánh giá không phải là nơi dành cho những lời ca ngợi về
    thực tiễn việc làm của một doanh nghiệp, hệ tư tưởng chính trị, hoàn cảnh đặc biệt
    hoặc các vấn đề khác không liên quan đến cốt lõi của trải nghiệm người tiêu dùng.</p></li>
    <li><p><strong>Quyền riêng tư:</strong> Không công khai thông tin cá nhân của người khác. Vui lòng không
    đăng ảnh cận cảnh hoặc video của những khách hàng quen khác mà không có sự cho
    phép của họ và vui lòng không đăng tên đầy đủ của người khác trừ khi bạn đề cập đến
    các nhà cung cấp dịch vụ thường được xác định bởi hoặc thường chia sẻ tên đầy đủ
    của họ.</p></li>
    <li><p><strong>Sở hữu trí tuệ:</strong> Không lấy và sử dụng nội dung từ các trang web hoặc người dùng
    khác.</p></li>
  </ul>

  <h3><strong>ii. Hướng dẫn bổ sung</strong></h3>
  <ul class="list-dot">
    <li>
      <p><strong>Quy tắc đánh giá sản phẩm, dịch vụ</strong></p>
      <ul class="list-dot">
        <li><p><strong>Kinh nghiệm cá nhân:</strong> Việc đánh giá sản phẩm, dịch vụ phải đến từ kinh nghiệm cá
        nhân của khách hàng.</p></li>
        <li><p><strong>Độ chính xác:</strong> Hãy đảm bảo rằng đánh giá của bạn chính xác với thực tế. Hãy thoải
        mái nêu lên ý kiến của bạn, nhưng vui lòng không phóng đại hoặc phát biểu sai lệch
        so với kinh nghiệm của bạn. Chúng tôi không đứng về phía nào khi xảy ra tranh chấp
        thực tế, vì vậy chúng tôi hy vọng bạn sẽ đảm bảo cho sự trung thực trong đánh giá
        của mình.</p></li>
        <li><p><strong>Cập nhật đánh giá:</strong> Những cập nhật mới về đánh giá sẽ phản ánh trải nghiệm hoặc
        tương tác mới của bạn với doanh nghiệp. Nếu bạn muốn thêm thông tin chi tiết mới
        vào trải nghiệm cũ, vui lòng chỉnh sửa đánh giá của bạn thay vì tạo ra bản cập nhật
        mới.</p></li>
      </ul>
    </li>
    <li>
      <p><strong>Hướng dẫn về hình ảnh và video</strong></p>
      <ul class="list-dot">
        <li><p>Ảnh và video về doanh nghiệp phải phù hợp một cách rộng rãi đối với doanh nghiệp và phản
        ánh trải nghiệm tiêu dùng điển hình (ví dụ: doanh nghiệp trông như thế nào, doanh nghiệp
        cung cấp những gì, v.v.).</p></li>
        <li><p>Nếu bạn đăng ảnh hoặc video giới thiệu trải nghiệm cá nhân độc đáo hơn với doanh nghiệp
        (ví dụ: nhóm bạn bè đang cười của bạn tại quán bar, hay có vật lạ trong súp của bạn), chúng

        tôi có thể xóa nó khỏi thư viện ảnh của doanh nghiệp, nhưng nó vẫn có thể xuất hiện cùng
        với đánh giá của bạn nếu bạn viết bài đánh giá về doanh nghiệp.</p></li>
      </ul>
    </li>
    <li>
      <p><strong>Nguyên tắc hồ sơ người dùng</strong></p>
      <ul class="list-dot">
        <li><p>Chúng tôi khuyến khích bạn sử dụng hồ sơ tài khoản của bạn để cho mọi người biết bạn là ai
        và đối tác kinh doanh của bạn hoặc bạn bè của bạn có thể tìm thấy bạn dễ dàng. Người dùng
        muốn đọc các nhận xét từ những người họ biết và tin tưởng (không phải những người có hồ
        sơ trống hoặc có nội dung không phù hợp).</p></li>
      </ul>
    </li>
    <li>
      <p><strong>Nguyên tắc nhắn tin</strong></p>
      <ul class="list-dot">
        <li><p>Cardbey có một tính năng nhắn tin tiện dụng để giúp bạn kết nối với bạn bè và đối tác kinh
        doanh. Trước hết, vui lòng không sử dụng tính năng này để quấy rối người dùng khác hoặc
        gửi nội dung không phù hợp khác. Thứ hai, tài khoản người dùng chỉ dành cho sử dụng cá
        nhân, phi thương mại.</p></li>
      </ul>
    </li>
    <li>
      <p><strong>Nguyên tắc chủ cửa hàng</strong></p>
      <ul class="list-dot">
        <li><p><strong>Nhận xét công khai:</strong> Chủ cửa hàng có thể đăng bình luận công khai. Cũng như đối
        với nhắn tin trực tiếp, chúng tôi yêu cầu chủ doanh nghiệp tải lên một bức ảnh rõ ràng
        về bản thân để giúp cá nhân hóa tin nhắn của họ. Không sử dụng các bình luận công
        khai để khởi động các cuộc tấn công cá nhân, quảng cáo hoặc khuyến khích thay đổi
        đánh giá.</p></li>
        <li><p><strong>Video và hình ảnh:</strong> Video là một cách tuyệt vời để các nhà quảng cáo quảng bá bản
        thân trên Cardbey. Hãy sáng tạo hết mức bạn muốn, nhưng mọi sự sáng tạo đó nên
        tuân theo hướng dẫn của Cardbey.</p></li>
      </ul>
    </li>
  </ul>

  <h3><strong>6.2 Quyền riêng tư</strong></h3>
  <p>Bạn tuyên bố rằng bạn đã đọc và hiểu Chính sách bảo mật của chúng tôi. Lưu ý rằng chúng
  tôi có thể tiết lộ thông tin về bạn cho các bên thứ ba nếu chúng tôi tin chắc rằng việc tiết lộ
  như vậy là cần thiết một cách hợp lý để:</p>
  <ul class="list-dot">
    <li>Hành động liên quan đến các hoạt động mà chúng tôi nghi ngờ là bất hợp pháp;</li>
    <li>Thực thi hoặc áp dụng Điều khoản và Chính sách bảo mật của chúng tôi;</li>
    <li>Tuân thủ quy trình pháp lý hoặc yêu cầu khác của chính phủ, như lệnh khám xét, trát
    đòi hầu tòa, thời hiệu, thủ tục tố tụng tư pháp hoặc quy trình pháp lý khác phục vụ
    chúng tôi; hoặc là</li>
    <li>Bảo vệ quyền, danh tiếng và tài sản của chúng tôi hoặc của người dùng, chi nhánh
    của chúng tôi, hoặc công chúng</li>
  </ul>
  <p>Chúng tôi có quyền đồng bộ hóa số liên lạc trên điện thoại của bạn với hồ sơ của bạn trên
  Ứng dụng. Chúng tôi có quyền gửi tin nhắn mời và thông tin liên quan đến liên hệ của bạn
  dưới tên của bạn với sự chấp thuận của bạn.</p>

  <h3><strong>6.3 Tranh chấp bản quyền và thương hiệu</strong></h3>
  <p>Chúng tôi không coi trọng những người lạm dụng quyền sở hữu trí tuệ của người khác. Nếu
  bạn tin rằng bản quyền hoặc nhãn hiệu của bạn đang bị xâm phạm trên Trang web, vui lòng
  gửi cho chúng tôi thông báo bằng văn bản với thông tin sau:</p>
  <ul class="list-dot">
    <li>Xác định tác phẩm có bản quyền hoặc nhãn hiệu mà bạn cho là đã bị vi phạm;</li>
    <li>Nhận dạng nội dung bị cáo buộc vi phạm và thông tin đầy đủ để Cardbey có thể định vị
    nội dung đó trên Trang web (ví dụ: URL cho trang web có nội dung xuất hiện);</li>
    <li>Tuyên bố của bạn rằng bạn thực sự tin rằng việc sử dụng nội dung được xác định
    trong thông báo của bạn theo cách bị khiếu nại không được ủy quyền bởi chủ sở hữu
    bản quyền / nhãn hiệu, đại lý hoặc luật pháp;</li>
    <li>Tuyên bố của bạn rằng bạn chứng thực, trong phạm vi có thể chịu hình phạt về tội khai
    man, rằng thông tin trong thông báo của bạn là chính xác và bạn là chủ sở hữu bản
    quyền / nhãn hiệu hoặc được ủy quyền hành động thay mặt chủ sở hữu; và</li>
    <li>Chữ ký vật lý hoặc điện tử của bạn, cùng với thông tin liên lạc của bạn (địa chỉ, số điện
      thoại và, nếu có, địa chỉ email).</li>
  </ul>
  <p>Đồng thời, chúng tôi không coi trọng những người lạm dụng phạm vi quyền sở hữu trí tuệ
  của họ. Nếu bạn tin rằng nội dung của bạn không nên bị xóa vì bị cáo buộc vi phạm bản
  quyền hoặc thương hiệu, bạn có thể gửi cho chúng tôi thông báo phản đối bằng văn bản với
  thông tin sau:</p>

  <ul class="list-dot">
    <li>Xác định tác phẩm có bản quyền hoặc nhãn hiệu đã bị xóa, và vị trí của tác phẩm đó
    trên Trang web trước khi nó bị xoá;</li>
    <li>Tuyên bố của bạn, dưới hình phạt khai man, rằng bạn tin rằng nội dung đã bị xóa do
    nhầm lẫn hoặc xác định sai. Đối với việc tranh chấp nhãn hiệu: thông tin đủ hợp lý giải
    thích lý do tại sao bạn tin rằng bạn không vi phạm tác phẩm đã đăng ký nhãn hiệu;</li>
    <li>Một tuyên bố rằng bạn đồng ý với quyền tài phán. Ngoài ra, vui lòng đính kèm theo
    một tuyên bố rằng bạn sẽ chấp nhận dịch vụ xử lý từ người đã gửi thông báo vi phạm
    ban đầu cho Cardbey hoặc đại diện của người đó;</li>
    <li>Chữ ký vật lý hoặc điện tử của bạn, cùng với thông tin liên lạc của bạn (địa chỉ, số điện
      thoại và, nếu có, địa chỉ email).</li>
  </ul>

  <p>Chúng tôi sẽ trả lời tất cả các thông báo như vậy và tuân thủ luật pháp hiện hành. Chúng tôi
  có quyền xóa nội dung được cho là vi phạm mà không cần thông báo trước và theo quyết
  định riêng của chúng tôi. Chúng tôi cũng có quyền chấm dứt tài khoản người dùng nếu người
  dùng được xác định là người vi phạm nhiều lần.</p>
  <p>Bạn có thể gửi cho chúng tôi thông báo bản quyền hoặc nhãn hiệu của bạn đến địa chỉ được
  nêu trên Trang web.</p>

  <h3><strong>7. ĐƠN ĐẶT HÀNG VÀ HÀNG CÓ SẴN TRONG KHO</strong></h3>
  <p>Bạn sẽ được yêu cầu cung cấp một số thông tin nhất định để mua Sản phẩm được hiển thị
  trên trang web của chúng tôi, có thể bao gồm thông tin cá nhân. Vui lòng tham khảo Chính
  sách bảo mật của chúng tôi để xem cách chúng tôi xử lý và sử dụng thông tin cá nhân của
  bạn. Các giá trị hiển thị trên Trang web của chúng tôi có thể thay đổi mà không cần thông báo
  trước. Giá sẽ bao gồm thuế, trong đó thuế này được yêu cầu bởi luật pháp quốc gia cụ thể.</p>
  <p>Chúng tôi có thể từ chối hoặc hủy đơn đặt hàng trong trường hợp Sản phẩm hết hàng, Sản
  phẩm bị hỏng, thông tin Sản phẩm không chính xác, giá hiển thị không chính xác, do không
  nhận được thanh toán cho Sản phẩm hoặc thanh toán không được Cardbey ủy quyền.</p>
  <p>Chúng tôi sẽ thông báo cho bạn ngay khi có thể nếu bất kỳ Sản phẩm bạn đặt mua không có
  sẵn.</p>
  <p>Khi bạn đã chọn phương thức thanh toán, bạn sẽ được chuyển đến một liên kết đến một
  trang web bảo mật để thanh toán giá mua áp dụng cho Sản phẩm.</p>

  <h3><strong>8. LỰA CHỌN THANH TOÁN</strong></h3>
  <p>Thanh toán luôn luôn tuân theo các thỏa thuận của Cardbey với các chủ ngân hàng và bất kỳ
  nhà cung cấp dịch vụ giao dịch tài chính bên thứ ba nào.</p>
  <p>Bạn có thể thanh toán cho Đơn đặt hàng trực tuyến và chi phí giao hàng bằng các lựa chọn
  thanh toán khác nhau, tùy thuộc vào quốc gia bạn chọn trên Trang web của chúng tôi, bao
  gồm các lựa chọn sau ở nơi nào khả dụng:</p>
  <ul class="list-dot">
    <li>
      <p>Thẻ tín dụng hoặc thẻ ghi nợ:</p>
      <ul class="list-dot">
        <li>Chúng tôi đăng ký các tiêu chuẩn bảo mật và mã hóa cao cho phép tất cả khách
        hàng mua sắm hoàn toàn yên tâm. Chúng tôi đã thực hiện tất cả các biện pháp bảo mật hợp
        lý để đảm bảo rằng thanh toán và chi tiết thẻ, cũng như thông tin bí mật liên quan, không thể
        được truy cập bởi bất kỳ ai không có phận sự.</li>
        <li>Khi đặt hàng và chứng minh các chi tiết cá nhân cần thiết, bao gồm cả chi tiết thẻ
        thanh toán của bạn, bạn đảm bảo rằng bạn được phép thanh toán bằng thẻ thanh toán và có
        đủ tiền để thanh toán cho đơn đặt hàng của bạn.</li>
      </ul>
    </li>
    <li><p>Paypal (vui lòng tham khảo các điều khoản và điều kiện của Paypal, bạn sẽ bị ràng
      buộc khi sử dụng phương thức thanh toán này: Thỏa thuận pháp lý cho Dịch vụ
      PayPal)</p></li>
    <li>
      <p>Tiền mặt</p>
      <ul class="list-dot">
        <li><p>Thanh toán tiền mặt chỉ áp dụng cho việc nhận hàng tại cửa hàng.</p></li>
      </ul>
    </li>
  </ul>
  <p>Khi khoản thanh toán của bạn đã thành công, bạn sẽ được cấp một xác nhận thanh toán điện
  tử thông qua Trang web Cardbey.</p>

  <h3><strong>9. GIAO HÀNG VÀ ĐỔI TRẢ</strong></h3>
  <h4><strong>9.1 Giao hàng và thu tiền</strong></h4>
  <p>Đơn hàng được giao cho bạn bằng dịch vụ của Đối tác bên thứ ba. Carbey được bồi thường
  và bảo hiểm từ bất kỳ mất mát, thiệt hại nào hoặc các vấn đề khác có thể xảy ra trong quá
  trình giao hàng.</p>
  <p>Đơn hàng có thể được giao cho bạn qua chuyển phát nhanh hoặc bạn có thể chọn để nhận
  Đơn hàng tại các cửa hàng.</p>
  <p>Phí giao hàng khác nhau tùy thuộc vào phương thức giao hàng mà bạn đã chọn và Sản
  phẩm bạn đặt mua. Phí giao hàng sẽ được hiển thị trong quá trình thanh toán đơn hàng,
  trước khi hoàn tất thanh toán.</p>
  <p>Chúng tôi sẽ thông báo cho bạn qua thông báo trên Trang web về tiến trình đặt hàng của
  bạn.</p>
  <h4><strong>9.2 Đổi trả</strong></h4>
  <p>Chính sách đổi trả có thể khác nhau tùy thuộc vào cửa hàng bạn mua Sản phẩm. Vui lòng
  truy cập trang web của cửa hàng hoặc liên hệ với chủ cửa hàng để biết thông tin chi tiết. </p>

  <h3><strong>10. HỦY BỎ</strong></h3>
  <p>Bạn có thể hủy Đơn hàng tại bất kỳ khi nào trước khi xác nhận đơn hàng. Sau khi xác nhận,
  người mua và người bán có thể thương lượng việc huỷ bỏ đơn hàng.</p>

  <h3><strong>11. BẢO HÀNH</strong></h3>
  <p>Chúng tôi sẽ không chịu trách nhiệm cho bất kỳ hành động hoặc khiếu nại nào về thương tích
  hoặc thiệt hại do bất kỳ tính chất nào phát sinh từ hoặc liên quan đến việc sử dụng Sản phẩm
  vi phạm các Điều khoản và Điều khoản này.</p>

  <h3><strong>12. GIỚI HẠN TRÁCH NHIỆM</strong></h3>
  <p>Tuy Cardbey thực hiện mọi nỗ lực đảm bảo an ninh cho bạn khi sử dụng Trang, do tính chất
  của Internet, chúng tôi không thể đảm bảo rằng mọi sản phẩm và dịch vụ hoặc bất kỳ trang
  web nào có thể truy cập qua Trang web của chúng tôi sẽ không bị gián đoạn, không có vi-rút
  hoặc không có lỗi. Do đó, chúng tôi khuyên bạn nên kiểm tra tất cả email, tệp đính kèm và
  các files trước khi tải xuống.</p>
  <p>Chúng tôi có thể cung cấp các liên kết đến các trang web, trang mạng xã hội hoặc các tài
  nguyên khác. Chúng tôi không thể chịu trách nhiệm, và cũng không chịu trách nhiệm đối với
  các trang web, trang mạng xã hội hoặc tài nguyên này; chúng tôi cũng không chứng thực hay
  ủng hộ nội dung, sản phẩm hoặc dịch vụ của họ chỉ vì chúng có thể truy cập được thông qua
  Trang web.</p>
  <p>Mặc dù chúng tôi thực hiện mọi nỗ lực hợp lý để đảm bảo rằng tất cả thông tin do chúng tôi
  cung cấp liên quan đến Trang web là chính xác tại thời điểm đưa vào Trang web, bạn thừa
  nhận và hiểu rằng có thể có lỗi, bao gồm giá không chính xác, thiếu chính xác hoặc thiếu sót,
  trong đó chúng tôi loại trừ tất cả trách nhiệm pháp lý. Chúng tôi không tuyên bố, bảo đảm
  hoặc bảo hành về bất kỳ tính chất nào liên quan đến thông tin có trên Trang web và trang
  web của chúng tôi (bao gồm, nhưng không giới hạn, liên kết đến các trang web của bên thứ
  ba). Bạn phải tự chịu trách nhiệm về mọi quyết định hoặc hành động của mình dựa trên
  thông tin có trên các trang web đó.</p>
  <p>Mặc dù chúng tôi thực hiện tất cả các bước hợp lý để bảo vệ tính bảo mật của bất kỳ thông
  tin nào bạn nhập hoặc gửi cho chúng tôi liên quan đến Trang web Cardbey bằng cách sử
  dụng các dịch vụ bảo mật và công nghệ mã hóa khi chúng tôi thấy phù hợp, chúng tôi không
  chịu trách nhiệm hay trách nhiệm pháp lý nào, hết mức mà pháp luật hiện hành cho phép, đối
  với bất kỳ thiệt hại nào bạn có thể phải chịu do vi phạm tính bảo mật của thông tin đó.</p>
  <p>Bạn đồng ý, theo như luật pháp cho phép, bồi thường cho Cardbey trước mọi mất mát, yêu
  cầu hoặc thiệt hại, hoặc trách nhiệm pháp lý do bạn hoặc bất kỳ bên thứ ba nào thực hiện
  cho bạn, do bạn hoặc họ sử dụng Trang web của chúng tôi và / hoặc bất kỳ ba mươi trang
  web bên liên kết đến trang web của chúng tôi.</p>
  <p>Chúng tôi sẽ không chịu trách nhiệm với bạn trong hợp đồng, vi phạm pháp luật (bao gồm cả
    sơ suất) hoặc:</p>
  <ul class="list-dot">
    <li><p>Đối với bất kỳ số tiền nào liên quan đến bất kỳ thiệt hại hoặc mất mát nào phát sinh do
    hậu quả của việc bạn sử dụng Trang web, các vi-rút mà bạn nhận được thông qua
    Trang web hoặc về việc chúng tôi không cung cấp Trang web theo Thỏa thuận này;
    hoặc</p></li>
    <li><p>Đối với bất kỳ tổn thất hoặc thiệt hại kinh tế nào, bất kỳ tổn thất gián tiếp, tổn thất đặc
    biệt hoặc hậu quả nào (bao gồm nhưng không giới hạn mất dữ liệu, thiện chí hoặc
    danh tiếng hoặc bất kỳ chi phí lãng phí nào) bao gồm nhưng không giới hạn các tổn
    thất phát sinh từ việc bạn sử dụng Trang web hoặc thông qua bất kỳ vi-rút nào; hoặc</p></li>
    <li><p>Đối với bất kỳ thất bại nào trong việc thực hiện nghĩa vụ của chúng tôi hoặc không
    thực hiện đúng nghĩa vụ của chúng tôi là kết quả của việc chúng tôi bị ngăn cản bởi
    một sự kiện nằm ngoài sự kiểm soát hợp lý của chúng tôi (có thể bao gồm, nhưng
    không giới hạn, đình công, tranh chấp lao động; thiên tai; bạo loạn, hành động dân sự,
    hành vi độc hại hoặc thiệt hại, việc tuân thủ luật pháp, lệnh chính phủ hoặc quy định,
    quy tắc, hoặc chỉ đạo; bất kỳ hành động hoặc thiếu sót của bất kỳ chính phủ hoặc cơ
    quan có thẩm quyền khác; tai nạn, thất bại về thiết bị hoặc dịch vụ, bao gồm việc dịch
    vụ viễn thông không khả dụng, đường dây hoặc thiết bị khác, việc các nhà cung cấp
    bên thứ ba không tuân thủ nghĩa vụ của họ đối với chúng tôi; hỏa hoạn, lũ lụt).</p></li>
  </ul>

  <p>Mỗi điều khoản của Điều khoản này hoạt động riêng biệt. Nếu bất kỳ phần nào được tòa án
  tuyên bố là không thể thi hành do vô hiệu, không hợp lệ, bất hợp pháp hoặc trái luật, thì các
  phần khác có thể cắt đứt khỏi điều khoản vô hiệu đó, và vẫn sẽ được áp dụng toàn bộ.</p>

  <h3><strong>13. Vi phạm hợp đồng</strong></h3>
  <p>Nếu bạn vi phạm bất kì điều khoản nào trong Điều khoản và Điều kiện này, chúng tôi có
  quyền hủy bất kỳ Đơn đặt hàng và / hoặc đăng ký trang web nào của bạn, bất kể quyền của
  chúng tôi thực hiện các biện pháp pháp lý khác khả dụng cho chúng tôi.</p>

  <br /><br /><br />
  `,
  // page policy
  "policy.template": `
  <h3><strong>Thông báo về bảo mật người dùng</strong></h3>
  <p>Cardbey biết rằng bạn quan tâm đến cách thông tin của bạn được sử dụng và chia sẻ, vì vậy,
  việc đảm bảo an toàn cho thông tin mà bạn đã tin tưởng giao cho chúng tôi là một trong
  những ưu tiên hàng đầu của Cardbey.</p>
  <p>Chính sách bảo mật của chúng tôi thể hiện chi tiết những thông tin chúng tôi thu thập từ
  người dùng và cách chúng tôi sử dụng thông tin đó để cải thiện trải nghiệm của bạn trên
  Cardbey. Bạn có thể đọc các chi tiết đầy đủ dưới đây:</p>
  <p><strong>Cardbey thu thập thông tin cá nhân nào?</strong></p>
  <p>Khi bạn đăng ký với chúng tôi, chúng tôi thu thập tên và chi tiết liên lạc của bạn. Khi bạn mua
  sản phẩm và dịch vụ thông qua trang web Cardbey, chúng tôi yêu cầu chi tiết thanh toán và
  địa chỉ giao hàng.</p>
  <p>Nếu bạn là người bán, chúng tôi yêu cầu bạn cung cấp tên doanh nghiệp và chi tiết doanh
  nghiệp của bạn.</p>
  <p>Chúng tôi cũng tự động thu thập một số thông tin như cookie và thiết bị bạn sử dụng để xem
  trang web của chúng tôi.</p>
  <p>Khi bạn tải xuống hoặc sử dụng các ứng dụng của chúng tôi trên Apple Store hoặc Google
  Store, chúng tôi có thể nhận được vị trí và danh sách liên hệ của bạn nếu bạn cho phép
  chúng tôi làm như vậy.</p>
  <p><strong>Cardbey sử dụng thông tin cá nhân như thế nào?</strong></p>
  <p>Thông tin chúng tôi có được từ khách hàng giúp chúng tôi cá nhân hóa và liên tục cải thiện
  trải nghiệm Cardbey của bạn bằng cách đề xuất các mặt hàng mà chúng tôi nghĩ là bạn thích
  dựa trên những gì bạn đã mua trước đó.</p>
  <p>Chúng tôi cũng sử dụng thông tin của bạn để giúp người bán quảng cáo hoặc tiếp thị hiệu
  quả.</p>
  <p><strong>Cardbey có chia sẻ thông tin cá nhân không?</strong></p>
  <p>Thông tin cá nhân là một phần quan trọng trong hoạt động kinh doanh của chúng tôi. Chúng
  tôi chỉ chia sẻ thông tin cá nhân của bạn với các thành viên Cardbey khác để tạo điều kiện
  cho các giao dịch, và cho phép các thành viên Cardbey liên hệ với bạn để hoàn thành giao
  dịch.</p>
  <p>Chúng tôi có thể chia sẻ thông tin với các nhà cung cấp dịch vụ bên thứ ba để thực hiện các
  chức năng của họ. Ví dụ bao gồm giao nhận và vận chuyển đơn hàng, xử lý thanh toán thẻ
  tín dụng và chứng minh dịch vụ khách hàng.</p>
  <p><strong>Thông tin của bạn có an toàn không?</strong></p>
  <p>Chúng tôi làm việc để bảo vệ tính bảo mật thông tin của bạn trong quá trình truyền thông tin
  bằng cách sử dụng phần mềm Lớp cổng bảo mật (SSL) để mã hóa thông tin bạn cung cấp.</p>
  <h3><strong>Thỏa thuận người dùng</strong></h3>
  <h4><strong>Chính sách bán hàng</strong></h4>
  <p>Là người bán, bạn phải:</p>
  <ul class="list-dot">
    <li><p>Cung cấp sản phẩm hoặc dịch vụ của bạn một cách chi tiết, rõ ràng cho người mua</p></li>
    <li><p>Đảm bảo các đơn hàng được giao tới tay người mua.</p></li>
    <li><p>Cập nhật trạng thái sản phẩm qua Cardbey để người mua có thể theo dõi dễ dàng</p></li>
    <li><p>Cung cấp chính sách đổi trả cụ thể của bạn cho người mua</p></li>
    <li><p>Chịu trách nhiệm liên hệ với khách hàng và giao sản phẩm cho khách hàng đúng hẹn,
    đồng thời cập nhật trạng thái trên hệ thống của chúng tôi.</p></li>
  </ul><p>Chúng tôi không cho phép người bán giao hàng thiếu sót hoặc mặt hàng có tình trạng xấu
  cho người mua. Nếu trường hợp này xảy ra, người mua có quyền báo cáo người bán này với
  chúng tôi và chúng tôi có thể chặn tài khoản này trên hệ thống Cardbey.</p>
  <p>Người bán phải tuân theo chính sách hoàn trả để hoàn tiền cho người mua.</p>
  <p>Đối với yêu cầu hủy từ người mua, chúng tôi khuyến khích người bán liên lạc trực tiếp với
  người mua trước khi đưa ra quyết định. Người bán nên đưa ra lý do cụ thể cho người mua
  nếu người bán từ chối yêu cầu hủy bỏ của người mua.</p>
  <h4><strong>Chính sách mua hàng</strong></h4>
  <p>Là người mua, bạn có thể mua bất kỳ sản phẩm nào từ các cửa hàng hoặc đặt bất kỳ dịch vụ
  nào trên hệ thống của chúng tôi.</p>
  <p>Chúng tôi muốn tất cả người dùng Cardbey có thể mua hoặc bán trên Cardbey một cách tự
  tin. Chúng tôi cố gắng bảo vệ người bán khỏi hoạt động mua bán không công bằng hoặc
  không an toàn từ người mua. Chúng tôi không cho phép người mua có các hành vi sau:</p>
  <ul>
    <li><p>Không trả tiền cho các mặt hàng</p></li>
    <li><p>Lạm dụng chính sách đổi trả</p><p>Chúng tôi khuyến khích tất cả người bán đặt ra chính sách đổi trả rõ ràng khi mở cửa hàng
    qua Cardbey. Nếu bạn muốn trả lại một mặt hàng, vui lòng xem lại chính sách hoàn trả của
    người bán trước khi bắt đầu quá trình đổi trả.</p>
      <ul class="list-dot">
        <li>
          <p>Chúng tôi cho phép:</p>
          <ul class="list-dot">
            <li><p>Trả lại hàng dựa trên luật tiêu dùng của Úc</p></li>
            <li><p>Trả lại một mặt hàng trong các điều khoản của chính sách hoàn trả của người bán</p></li>
          </ul>
        </li>
        <li>
          <p>Chúng tôi không cho phép:</p>
          <ul class="list-dot">
            <li><p>Trả lại một mặt hàng khác với sản phẩm bạn đã đặt mua</p></li>
            <li><p>Trả lại hàng trong điều kiện khác với khi nó được giao cho bạn</p></li>
            <li><p>Trả lại một mặt hàng đã bị hư hỏng sau khi được giao</p></li>
            <li><p>Không tuân theo các điều khoản của chính sách hoàn trả của người bán</p></li>
          </ul>
        </li>
      </ul>
    </li>
    <li><p>Hãy đảm bảo rằng các mặt hàng được giao cho người mua.</p></li>
    <li><p>Vui lòng cập nhật trạng thái sản phẩm qua Cardbey để người mua có thể theo dõi dễ dàng</p></li>
    <li><p>Cung cấp chính sách đổi trả cụ thể cho người mua.</p></li>
  </ul>

  <h4><strong>Chính sách hủy và hoàn tiền</strong></h4>
  <h5><strong>Hủy bỏ</strong></h5>
  <p>Là người mua, bạn có thể gửi Yêu cầu Hủy cho người bán đối với các mặt hàng bạn đã đặt
  qua Cardbey nếu người bán không giao những mặt hàng này cho bạn.</p>
  <p>Là người bán, khi bạn nhận được yêu cầu hủy từ người mua, chúng tôi khuyến khích bạn
  liên hệ trực tiếp với người mua để xác nhận lý do hủy. Tùy thuộc vào đặc điểm hoặc điều kiện
  của mặt hàng, bạn có thể chấp nhận hoặc từ chối yêu cầu hủy bỏ của người mua. Tuy nhiên,
  hãy đảm bảo rằng khách hàng hiểu rõ quyết định của bạn.</p>
  <h5><strong>Hoàn tiền</strong></h5><p>Nếu đơn hàng bị hủy đã được thanh toán qua hệ thống Cardbey, chúng tôi sẽ chịu trách
  nhiệm hoàn tiền cho người mua trong vòng 15 ngày làm việc.</p>
  <p>Nếu đơn đặt hàng đã được giao cho người mua, người bán sẽ chịu trách nhiệm hoàn tiền
  cho các mặt hàng bị trả lại dựa trên luật người tiêu dùng Úc và chính sách hoàn trả của
  người bán.</p>
  <h4><strong>Chính sách giao hàng</strong></h4>
  <p>Hiện tại, Cardbey không hỗ trợ giao nhận sản phẩm hoặc dịch vụ. Người bán phải chịu trách
  nhiệm cho quá trình giao hàng. Chúng tôi khuyên người bán nên liên hệ với người mua để
  xác nhận thông tin trước khi giao hàng. Người bán phải cập nhật trạng thái Mục hàng đã đặt
  hàng trên Cardbey để người mua có thể theo dõi đơn hàng của họ một cách dễ dàng.</p>
  <br /><br /><br />
  `,
  // ABOUT US
  "about_us.template": `<h4><strong>Cardbey là thị trường toàn cầu với sự hỗ trợ của địa phương</strong></h4>
  <p>Cardbey là nền tảng mới phù hợp với các khu vực, cung cấp cho khách hàng trải nghiệm mua sắm trực tuyến dễ dàng, an toàn và thuận tiện thông qua hỗ trợ mạnh mẽ của địa phương. Chúng tôi kết nối hàng ngàn doanh nghiệp và người dùng trong một nền tảng Thương mại điện tử tích hợp bao gồm phương tiện trực tuyến và ngoại tuyến.</p>
  <h4><strong>Nhiệm vụ của chúng tôi - Làm cho thương mại tốt hơn cho mọi người</strong></h4>
  <p>Cardbey được thành lập vào năm 2015 tại Melbourne, Úc với sứ mệnh giảm chi phí, loại bỏ các rào cản và nâng cao kinh nghiệm mua / bán trực tuyến. Điều này được thực hiện tại địa phương và xuyên biên giới để cung cấp cho người tiêu dùng những sản phẩm và dịch vụ có giá trị tốt nhất trên thị trường. Chúng tôi làm điều này trong khi cũng là một công ty có trách nhiệm xã hội và đạo đức, đóng góp cho sự bền vững vì chúng tôi tin rằng tương lai chỉ có thể có được bằng cách chăm sóc hành tinh của chúng ta. </p>
  <h4><strong>Mục đích của chúng tôi</strong></h4>
  <p>Chúng tôi tin vào sức mạnh biến đổi của công nghệ để cho phép mọi người bắt đầu, điều hành và phát triển doanh nghiệp của riêng họ. Chúng tôi làm việc để kết nối người mua và người bán trong một cộng đồng mạnh mẽ.</p>
  <h4><strong>Cộng đồng của chúng tôi</strong></h4>
  <p>Chúng tôi làm việc để phục vụ các doanh nghiệp vừa và nhỏ trên toàn thế giới. Thực tế bất cứ ai có sản phẩm / dịch vụ để bán và những người quan tâm đến việc tiếp cận khách hàng mới và nhiều hơn nữa. Sự hài lòng của khách hàng là điều tối quan trọng đối với Cardbey và chúng tôi luôn hướng đến sự hài lòng cao nhất của khách hàng.  </p>
  <h4><strong>Chọn Cardbey</strong></h4>
  <p>Chúng tôi liên tục thay đổi và thích nghi với thị trường và công nghệ và cố gắng trở thành người dẫn đầu thị trường trên thị trường trực tuyến. Chúng tôi liên tục bổ sung các tính năng, công cụ, chức năng và dịch vụ mới để theo dõi chúng tôi trên phương tiện truyền thông xã hội để luôn cập nhật.</p>
  <h4><strong>PHẢN HỒI</strong></h4>
  `,
  stay_connected_widt_us: "Luôn kết nối với chúng tôi.",
  cardbey_has_helped_me_increase:
    "Cardbey đã giúp tôi tăng doanh số lên 25%. Tôi rất hài lòng với kết quả này và đã giới thiệu Cardbey cho bạn bè và gia đình tôi.Cardbey has helped me increase my sales by 25%. I am very pleased with the result and have recommended Cardbey to my friends and family.",
  cardbey_is_a_complete_product:
    "Cardbey là một sản phẩm hoàn chỉnh, cung cấp một thị trường nhộn nhịp, một nền tảng quảng cáo và dịch vụ giao hàng tận nhà. Tôi không thể tin rằng số lượng giá trị được đóng gói chỉ trong một Tài khoản đối tác.",
  cardbey_is_in_a_class:
    "Cardbey thuộc một đẳng cấp riêng khi nói đến thương mại điện tử. Tôi đã bắt đầu dễ dàng và giao bữa ăn kể từ tuần đầu tiên. Trình điều khiển rất thân thiện và hỗ trợ khách hàng. Một hệ thống thực sự không ma sát",
  // animation
  "animmation-mac": "/assets/images/gallery/animation-mac-vi.gif",
  "animation-phone": "/assets/images/gallery/mobile&phone-vi.gif",
  "animation-phone-straight": "/assets/images/gallery/phone-vi.gif",
  cardbey_is_the_global_marketplace_with_local_support:
    "Cardbey là thị trường toàn cầu với sự hỗ trợ của địa phương",
  cardbey_is_the_new_global_platform_tailored_for_regions: `Cardbey là nền tảng mới phù hợp với các khu vực, cung cấp cho khách hàng trải nghiệm mua sắm trực tuyến dễ dàng, an toàn và thuận tiện thông qua hỗ trợ mạnh mẽ của địa phương. Chúng tôi kết nối hàng ngàn doanh nghiệp và người dùng trong một nền tảng Thương mại điện tử tích hợp bao gồm phương tiện trực tuyến và ngoại tuyến.`,
  our_mission: "Nhiệm vụ của chúng tôi",
  making_commerce_better_for_everyone:
    "Làm cho thương mại tốt hơn cho mọi người",
  cardbey_was_founded: `Cardbey được thành lập vào năm 2015 tại Melbourne, Úc với sứ mệnh giảm chi phí, loại bỏ các rào cản và nâng cao kinh nghiệm mua / bán trực tuyến. Điều này được thực hiện tại địa phương và xuyên biên giới để cung cấp cho người tiêu dùng những sản phẩm và dịch vụ có giá trị tốt nhất trên thị trường. Chúng tôi làm điều này trong khi cũng là một công ty có trách nhiệm xã hội và đạo đức, đóng góp cho sự bền vững vì chúng tôi tin rằng tương lai chỉ có thể có được bằng cách chăm sóc hành tinh của chúng ta. `,
  our_purpose: "Mục đích của chúng tôi",
  we_believe_in_the_transformative: `Chúng tôi tin vào sức mạnh biến đổi của công nghệ để cho phép mọi người bắt đầu, điều hành và phát triển doanh nghiệp của riêng họ. Chúng tôi làm việc để kết nối người mua và người bán trong một cộng đồng mạnh mẽ.`,
  our_community: "Cộng đồng của chúng tôi",
  we_work_to_serve_smes:
    "Chúng tôi làm việc để phục vụ các doanh nghiệp vừa và nhỏ trên toàn thế giới. Thực tế bất cứ ai có sản phẩm / dịch vụ để bán và những người quan tâm đến việc tiếp cận khách hàng mới và nhiều hơn nữa. Sự hài lòng của khách hàng là điều tối quan trọng đối với Cardbey và chúng tôi luôn hướng đến sự hài lòng cao nhất của khách hàng.  ",
  choose_cardbey: "Chọn Cardbey",
  we_are_continually_changing_and_adapting:
    "Chúng tôi liên tục thay đổi và thích nghi với thị trường và công nghệ và cố gắng trở thành người dẫn đầu thị trường trên thị trường trực tuyến. Chúng tôi liên tục bổ sung các tính năng, công cụ, chức năng và dịch vụ mới để theo dõi chúng tôi trên phương tiện truyền thông xã hội để luôn cập nhật.",
  sell_online_with_cardbey: "Bán hàng với Cardbey",
  the_simpler_way_to_build_your_online:
    "Xây dựng cửa hàng online đơn giản nhất, miễn phí mãi mãi!",
  get_started_for_free: "Bắt đầu miễn phí",
  go_to_stores: "Vào cửa hàng",
  try_cardbey_at_no_risk:
    "Dùng thử Cardbey không rủi ro, không cần thẻ tín dụng. Bằng cách nhập email, bạn đồng ý nhận email tiếp thị từ Cardbey.",
  get_your_store_ready_in_3_minutes: "Cửa hàng sẵn sàng trong ba phút",
  whether_you_selling_physical:
    "Dù bạn cung cấp sản phẩm hay dịch vụ, cửa hàng Cardbey dành cho bạn. Bắt đầu bán hàng mọi lúc, mọi nơi, hoàn toàn miễn phí.",
  cheaper_simpler_home_delivery_service: "Giao hàng đơn giản hơn, giá rẻ hơn",
  one_click_shipping_availabel_at_checkout_for_your_customers:
    "Ship hàng chỉ với một click tại Thanh toán với Dịch vụ Giao hàng Cardbey. Tiết kiệm chi phí, không cần bên vận chuyển thứ ba.",
  all_in_one_service_for_store_owners: "Dịch vụ trọn gói dành cho nhà bán hàng",
  widen_your_reach:
    "Mở rộng khách hàng, giảm chi phí và công sức Marketing với tính năng Cardbey Quảng cáo.",
  build_your_online_store_no_matter_what_business_your_in:
    "Xây dựng cửa hàng trực tuyến, bất kể bạn làm trong ngành nghề nào.",
  food_drink: "NHÀ HÀNG & CAFE",
  spa_beauty: "SPA & LÀM ĐẸP",
  handyman_service: "THỢ ĐA NGÀNH NGHỀ",
  import_export_goods: "HÀNG HOÁ XUẤT NHẬP KHẨU",
  choose_a_path_forward: "Chọn con đường phía trước.",
  start_your_own_business: "Bắt đầu khởi nghiệp",
  bring_your_business_ideas_to_life_at_no_cost:
    "Biến ý tưởng kinh doanh thành hiện thực miễn phí. Tiết kiệm thời gian & chi phí thử nghiệm ý tưởng kinh doanh của bạn.",
  move_your_business_online: "Chuyển cửa hàng truyền thống thành trực tuyến",
  got_your_own_brick_and_mortar_store:
    "Đã đến lúc mở rộng tập khách hàng online, phục vụ mọi lúc mọi nơi, và tăng doanh thu bằng cách mở thêm cửa hàng trực tuyến.",
  switch_to_cardbey: "Chuyển sang nền tảng Cardbey",
  no_matter_what_platform_you_using:
    "Cho dù bạn đang sử dụng nền tảng bán hàng nào, chuyển sang Cardbey cũng cực kì đơn giản. Mở rộng tập khách hàng của bạn tới Úc & Đông Nam Á.",
  with_you_wherever_you_going: "Bên bạn dù ở bất kỳ nơi đâu",
  sell_deliver_your_products_everywhere: "Bán hàng & giao hàng mọi nơi",
  use_one_platform_to_sell_to_anyone:
    "Dùng một nền tảng bán hàng đến mọi người, mọi nơi. Giao hàng không cần bên thứ ba.",
  explore_ways_to_sell: "Khám phá các cách bán hàng online",
  get_booked_on_the_go: "Nhận đặt lịch trực tuyến",
  whether_you_an_agency:
    "Dù bạn là agency, khách sạn, nhà hàng, spa, hay bất cứ dịch vụ nào khác, hãy bắt đầu nhận đặt lịch từ khách hàng địa phương & quốc tế ngay hôm nay.",
  explore_ways_to_get_bookings: "Tìm hiểu cách nhận đặt lịch",
  let_get_social: "Cùng kết nối",
  connect_with_customers_and_businesses_to_make_buying:
    "Kết nối chặt chẽ với các nhà bán hàng và khách hàng trong cộng đồng Cardbey để trải nghiệm mua bán dễ chịu hơn.",
  stay_connected: "Bắt đầu kết nối",
  get_help_at_every_step_of_the_way: "Nhận hỗ trợ trong từng bước đi",
  cardbey_support: "Hỗ trợ Khách hàng Cardbey",
  contact_support_24_7:
    "Liên hệ Hỗ trợ 24/7, dù bạn đang xử lý vấn đề hay tìm kiếm lời khuyên kinh doanh.",
  contact_support: "Liên hệ Hỗ trợ",
  start_your_business_journey_with_cardbey:
    "Bắt đầu hành trình kinh doanh với Cardbey",
  explore_the_tools_and_services_that_help_you_start:
    "Khám phá những công cụ và dịch vụ giúp bạn bắt đầu và phát triển kinh doanh",
  create_your_online_business_for_free: "Xây dựng cửa hàng miễn phí",
  add_to_order: "ĐẶT HÀNG THÊM",
  choose_items_to_add_in_your_order:
    "Chọn các mục để thêm vào đơn hàng của bạn",
  delivery_service: "Dịch vụ giao hàng",
  delivery_now: "Giao hàng ngay",
  we_want_to_give_you: "Chúng tôi GIẢM GIÁ 20% cho đơn hàng đầu tiên của bạn",
  wait: "Chờ đợi!",
  return_to_store: "Trở lại cửa hàng",
  digital_slideshow: "Digital Slideshow",
  create_new_digital: "Tạo mới digital",
  create_new_screen: "Tạo mới digital screen",
  cover_image: "Ảnh bìa",
  title: "Tiêu đề",
  my_contact: "Liên hệ của tôi",
  "notification.contact.add": "Thêm liên hệ thành công",
  "notification.contact.delete": "Xóa liên hệ thành công",
  qr_code_shows: "Shows",
  qr_code_map: "Bản đồ",
  qr_code_call: "Gọi",
  qr_code_share: "Chia sẻ",
  choose_list_text: "Hiển thị kiểu danh sách",
  choose_grid_text:
    "Hiển thị kiểu lưới, ô (* Khuyến khích chọn nếu bạn có nhiều hình ảnh)",
  confirm_show_items_type: "Bạn chắc chắn muốn thay đổi kiểu hiển thị?",
  confirm_account_type: "Bạn chắc chắn muốn thay đổi kiểu người dùng?",
  confirm_hide_review: "Bạn chắc chắn muốn ẩn bình luận này?",
  create_business: "Tạo tài khoản doanh nghiệp",
  create_business_content:
    "Bạn cần hoàn thành thông tin doanh nghiệp để hiện thị cửa hàng của bạn",
  switch_account: "Chuyển sang tài khoản doanh nghiệp",
  switch_account_content:
    "Chuyển sang tài khoản doanh nghiệp để hiện thị cửa hàng của bạn",
  my_collections: "Bộ sưu tập",
  images: "Hình ảnh",
  posts: "Bài viết",
  users: "Người dùng",
  switch_normal_account: "Chuyển sang tài khoản thường",
  switch_business_account: "Chuyển sang tài khoản doanh nghiệp",
  menu_store_food: "Đặt món",
  menu_store_service: "Đặt lịch hẹn",
  menu_store_product: "Mua ngay",
  store_order: "Đơn hàng",
  store_booking: "Đặt lịch",
  view_cart: "Xem giỏ hàng",
  "cart.add_success": "Sản phẩm đã thêm vào giỏ hàng.",
  bussiness_website: "Website",
  subTitle: "Tiêu đề ngắn",
  claim_your_business: "Yêu cầu doanh nghiệp của bạn",
  claim_success_message:
    "Cảm ơn bạn đã gửi yêu cầu, chúng tôi sẽ liên lạc với bạn sớm nhất!",
  country: "Quốc gia",
  post: "Đăng",
  already_account: "Bạn đã có tài khoản? \n Đăng nhập.",
  back_to_store: "Quay lại cửa hàng",
  inventory_name: "Còn hàng",
  inventory_service_name: "Sẵn sàng",
  option_group_name: "Nhóm Tùy Chọn",
  option_group_type: "Loại Hiển Thị",
  option_children_name: "Tên Loại",
  option_children_price: "Giá",
  food: "Đồ ăn",
  term_and_policy: "Điều khoản & Chính sách",
};

export default vi;
