import * as types from 'constants/ActionTypes'
import BusinessAdapter from 'services/businesses/adapter'

const initialState = {
  results: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false,
  resultsGroup: [],
  errorGroup: null,
  fetchingGroup: false,
  fetchedGroup: false,
}

const businessLogos = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BUSINESS_LOGOS:
      return {
        ...state,
        fetching: true
      }
    case types.RECEIVE_BUSINESS_LOGOS_SUCCESS:
      return {
        ...state,
        results: BusinessAdapter.browse.parseResponse(action.payload.data),
        fetching: false,
        fetched: true
      }
    case types.RECEIVE_BUSINESS_LOGOS_FAILURE:
      return {
        ...state,
        error: action.error,
        results: [],
        fetching: false,
        fetched: true
      }
    default:
      return state
  }
}

const businesses = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BUSINESSES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_BUSINESSES_SUCCESS:
      return Object.assign({}, state, {
        results: BusinessAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_BUSINESSES_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        results: [],
        totalItems: 0,
        totalPages: 0,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const businessesByCategory = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BUSINESSES_BY_GROUP:
      return Object.assign({}, state, {
        fetchingGroup: true
      })
    case types.REQUEST_BUSINESSES_BY_GROUP_SUCCESS:
      return Object.assign({}, state, {
        resultsGroup: BusinessAdapter.productsCategory.parseResponse(action.payload.data),
        errorGroup: {},
        fetchingGroup: false,
        fetchedGroup: true
      })
    case types.REQUEST_BUSINESSES_BY_GROUP_FAILURE:
      return Object.assign({}, state, {
        errorGroup: action.error,
        resultsGroup: [],
        fetchingGroup: false,
        fetchedGroup: true
      })
    default:
      return state
  }
}


export {
  businesses,
  businessLogos,
  businessesByCategory
}