import React, { Component } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Pane } from "shared/components/layouts";
import ListItem from "./ListItem";

class List extends Component {
	static propTypes = {
		data: PropTypes.array,
	};

	render() {
		const { reviews } = this.props;
		const list =
			reviews &&
			reviews.length > 0 &&
			reviews.map((item, index) => {
				return (
					<ListItem
						key={index}
						item={item}
						editReview={this.props.editReview}
						getUserInfo={this.props.getUserInfo}
						storeId={this.props.storeId}
					/>
				);
			});
		return (
			<Pane>
				{list}
			</Pane>
		);
	}
}

export default List;