import * as types from 'constants/ActionTypes'
import ServiceAdapter from 'services/services/adapter'
import UserAdapter from 'services/users/adapter'
import normalize from 'json-api-normalizer'

const initialState = {
  results: [],
  categories: [],
  services: [],
  schema: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const services = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_ALL_SERVICES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_ALL_SERVICES_BY_FILTER_SUCCESS:
      return Object.assign({}, state, {
        results: ServiceAdapter.browse.parseResponse(action.payload.data),
        services: action.payload.data,
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_ALL_SERVICES_BY_FILTER_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const servicesSlidePromotion = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_ALL_SERVICES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_ALL_SERVICES_SLIDER_BY_FILTER_SUCCESS:
      return Object.assign({}, state, {
        results: ServiceAdapter.browse.parseResponse(action.payload.data),
        promotions: ServiceAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_ALL_SERVICES_SLIDER_BY_FILTER_FAILURE:
      return Object.assign({}, state, {
        results: [],
        promotions: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const featureServices = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_FEATURE_SERVICES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_FEATURE_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        results: ServiceAdapter.browse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_FEATURE_SERVICES_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true
      })
    default:
      return state
  }
}

const myServices = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_SERVICES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_MY_SERVICE_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: UserAdapter.serviceCategories.parseResponse(action.payload.data),
        services: action.payload.data,
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.serviceBrowse.parseResponse(action.payload.data),
        services: action.payload.data,
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_SERVICES_FAILURE:
      return Object.assign({}, state, {
        totalItems: 0,
        totalPages: 0,
        results: [],
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { services, featureServices, myServices, servicesSlidePromotion }
