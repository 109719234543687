import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dimmer, Loader } from 'semantic-ui-react';
import ReactPaginate from 'react-paginate';
import NotificationSystem from 'react-notification-system';
import queryString from 'query-string';
import _ from 'lodash';
import { Pane, Loading } from 'shared/components/layouts';
import { ProductList } from './list';
import { isNumberic, checkParams } from 'shared/helpers';

class Products extends Component {
  static propTypes = {
    auth: PropTypes.object,
    data: PropTypes.object,
    onGetAllProducts: PropTypes.func,
    onAddItemToCart: PropTypes.func,
    productPromotions: PropTypes.object,
  };

  state = {
    category: queryString.parse(this.props.location.search).category,
    sort: queryString.parse(this.props.location.search).sort,
    store: queryString.parse(this.props.location.search).store,
    discount: queryString.parse(this.props.location.search).discount,
    page: parseInt(queryString.parse(this.props.location.search).page, 10) || 1,
    fetching: false,
    fetched: false,
  };

  constructor(props, context) {
    super(props, context);

    this._notificationSystem = null;
  }

  options = [
    {
      value: '+price',
      label: this.props.intl.formatMessage({
        id: 'price.low_to_high',
        defaultMessage: 'Price: Low to High',
      }),
    },
    {
      value: '-price',
      label: this.props.intl.formatMessage({
        id: 'price.high_to_low',
        defaultMessage: 'Price: High to Low',
      }),
    },
    {
      value: 'statistic.rating',
      label: this.props.intl.formatMessage({
        id: 'rating.high_to_low',
        defaultMessage: 'Rating: Low to High',
      }),
    },
    {
      value: '-statistic.rating',
      label: this.props.intl.formatMessage({
        id: 'rating.low_to_high',
        defaultMessage: 'Rating: High to Low',
      }),
    },
  ];

  handleChangeSelect = (selectedOption) => {
    if (!selectedOption.target.value) {
      this.setState(
        {
          sort: '',
        },
        () => {
          this.props.history.push({
            search: ``,
          });
        }
      );
    } else {
      this.setState(
        {
          sort: selectedOption.target.value,
        },
        () => {
          this.props.history.push({
            search: `?sort=${this.state.sort}${checkParams(
              'sort',
              this.props.location.search
            )}`,
          });
        }
      );
    }
  };

  getProducts = (params) => {
    if (params.id) {
      this.setState({ category: params.id });
    }
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props.onGetAllProducts(params).then(() => {
      this.setState({
        fetched: true,
        fetching: false,
      });
    });
  };

  handlePageClick = (data) => {
    this.setState(
      {
        page: data.selected + 1,
      },
      () => {
        this.props.history.push({
          search: `?page=${this.state.page}${checkParams(
            'page',
            this.props.location.search
          )}`,
        });
      }
    );
  };

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
      const qp = queryString.parse(location.search);
      let params = {};
      Object.keys(qp) &&
        Object.keys(qp).forEach((key) => {
          params[key] = qp[key];
        });

      if (_.isEmpty(params)) {
        this.setState(
          {
            page: 1,
          },
          () => {
            params = this.state;
          }
        );
      }

      this.getProducts(params);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    const { data } = this.props;
    const params = {
      page: this.state.page,
      store: this.state.store,
      discount: this.state.discount,
      sort: this.state.sort,
      category: this.state.category,
    };

    if (this.props.match && this.props.match.params.id) {
      params.id = this.props.match.params.id;

      this.getProducts(params);
    } else if (data && data.fetched) {
      this.getProducts(params);
    } else {
      this.getProducts(params);
    }

    this._notificationSystem = this.refs.notificationSystem;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      this.props &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      const params = {
        page: this.state.page,
        store: queryString.parse(this.props.location.search).store,
        discount: queryString.parse(this.props.location.search).discount,
      };

      if (this.props.match && this.props.match.params.id) {
        params.id = this.props.match.params.id;
      }
      this.getProducts(params);
    }

    if (!prevProps.location.search && prevState.page !== 1) {
      this.setState({ page: 1 });
    }

    if (
      prevProps.location.search &&
      isNumberic(queryString.parse(prevProps.location.search).page) &&
      parseInt(queryString.parse(prevProps.location.search).page, 10) !==
        parseInt(prevState.page, 10)
    ) {
      this.setState({
        page: parseInt(queryString.parse(prevProps.location.search).page, 10),
      });
    }
  }

  groupCategory = (data) => {
    const dataGroup = [];
    if (!_.isEmpty(data)) {
      const groups = [];
      data.forEach((item) => {
        let groupId = [];
        if (!_.isEmpty(item.category)) {
          groupId = item.category.id;
          if (!groups[groupId]) {
            groups[groupId] = [];
          }
          groups[groupId].push(item);
        }
      });
      for (const groupName in groups) {
        dataGroup.push({
          ...this.props.data.results.find(
            (item) => _.get(item, 'category.id') === groupName
          ),
        });
      }
    }
    return dataGroup;
  };

  render() {
    const { fetched, fetching } = this.state;
    return (
      <Pane className="col-lg-9 col-md-12">
        <Pane className="tab-heading">
          <Pane className="category-list">
            {this.groupCategory(this.props.data.results).map((item) => {
              return (
                <Link
                  key={item.id}
                  to={`?category=${_.get(item, 'category.id')}${checkParams(
                    'category',
                    this.props.location.search
                  )}`}
                  className="category-link"
                >
                  {_.get(item, 'category.name')}
                </Link>
              );
            })}
            <Link
              to={`/promotions?page=${_.get(
                queryString.parse(this.props.location.search),
                'page'
              ) || '1'}`}
              className="category-link"
            >
              <FormattedMessage id="view_all" defaultMessage="View All" />
            </Link>
          </Pane>
          <Pane className="category-sort">
            <span className="sort-title">SORT BY</span>
            <select
              className="category-select"
              name="format"
              value={this.state.sort}
              onChange={this.handleChangeSelect}
              id="format"
            >
              {this.options.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </Pane>
        </Pane>
        <Pane className="clearfix" />
        <Pane className="product-list">
          {!fetching && fetched ? (
            <>
              {!_.isEmpty(this.props.data.results) ? (
                <ProductList
                  auth={this.props.auth}
                  products={this.props.data.results}
                  onAddItemToCart={this.props.onAddItemToCart}
                  onNotification={this._notificationSystem}
                  onAddItemToGuestCart={this.props.onAddItemToGuestCart}
                />
              ) : (
                <Pane className="empty-container">
                  <Pane className="empty-content">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/icons/empty.png'
                      }
                      alt="empty"
                      className="img-empty"
                    />
                    <h2 className="empty-title">No items</h2>
                  </Pane>
                </Pane>
              )}
            </>
          ) : (
            <>
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            </>
          )}
        </Pane>
        <Pane className="pagination-bar text-center mb-5">
          <nav className="d-inline-b">
            {this.props.data.fetched && this.props.data.totalPages > 1 ? (
              <ReactPaginate
                initialPage={this.state.page - 1}
                forcePage={this.state.page - 1}
                previousLabel={
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/pagination/icon-prev.svg`
                    }
                    alt="icon-prev"
                    className="icon icon-prev"
                  />
                }
                nextLabel={
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/pagination/icon-next.svg`
                    }
                    alt="icon-prev"
                    className="icon icon-next"
                  />
                }
                breakLabel={<button className="btn-break-paging">...</button>}
                breakClassName={'break-me'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                pageCount={this.props.data.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                disableInitialCallback={true}
              />
            ) : null}
          </nav>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

export default injectIntl(withRouter(Products));
