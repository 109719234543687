import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Cart extends Component {
  render() {
    const { added, type } = this.props;
    if (type === 'products') {
      if (added) {
        return (
          <img
            src={process.env.PUBLIC_URL + `/assets/images/icons/check.svg`}
            alt="check"
            className="icon-check"
          />
        );
      }
      return null;
    }
    if (added) {
      return (
        <img
          src={process.env.PUBLIC_URL + '/assets/images/icons/check-white.svg'}
          width="30px"
          alt="cart"
          className="icon"
        />
      );
    }
    return (
      <img
        src={process.env.PUBLIC_URL + '/assets/images/layouts/cart.svg'}
        alt="cart"
        className="icon"
      />
    );
  }
}

Cart.propTypes = {
  added: PropTypes.bool,
};
Cart.defaultProps = {
  added: false,
};

export default Cart;
