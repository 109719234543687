import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Rating } from "semantic-ui-react";
import { PriceItem } from "shared/components";
import { Pane } from "shared/components/layouts";
import { injectIntl, intlShape } from "react-intl";
import { withRouter, Link } from "react-router-dom";
import { Cart } from "components/common/cart";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeAllCart } from "actions/cart";

class BestMatchedItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    auth: PropTypes.object,
    item: PropTypes.object,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    intl: intlShape.isRequired,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
  };

  state = {
    added: false,
    adding: false,
  };

  addItemToCart = () => {
    const { intl, item, cartItems } = this.props;
    const { options } = item;

    if (options && options.length) {
      this.props.cartModal.openModal();
      this.props.cartModal.setCartView({ ...item, modeCart: false });
      return;
    }

    this.setState({ added: false, adding: true });

    if (cartItems && cartItems.length > 0 && item.userId !== cartItems[0].userId) {
      this.props.removeAllCart();
    }

    this.props
      .onAddItemToCart({ product_id: this.props.id })
      .then(() => {
        this.setState({ added: true, adding: false });
      })
      .catch(() => {
        this.setState({ adding: false });
        this.props.onNotification.addNotification({
          message: intl.formatMessage({ id: "notification.please_try_again" }),
          level: "error",
        });
      });
  };

  renderInfo = () => {
    const { item, auth } = this.props;
    if (_.get(item, "userId") === _.get(auth, "user.id")) {
      return null;
    }
    return (
      <div className="adds-info">
        <PriceItem
          promotion={item && item.promotion}
          originalPrice={item && item.originalPrice}
        />
      </div>
    );
  };

  render() {
    const { name, image, rating, item, id } = this.props;
    const { added } = this.state;
    return (
      <>
        <Pane className="col-lg-2 col-md-6 col-sm-6 col-12 pl-0 pr-0 pb-0">
          <Pane className="adds-item">
            <Link className="hover-image" to={`/products/${item.slug}`}>
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png)`,
                }}
              >
                <Cart added={added || !!item.added} type="products" />
              </Pane>
            </Link>
            <Pane className="adds-content">
              <Link to={`/products/${item.slug}`}>
                <h5 className="adds-norm" title={name}>
                  <b>{name}</b>
                </h5>
              </Link>
              <Pane className="adds-info-item">
                {this.renderInfo()}
                {!(added || !!item.added) ? (
                  <button className="btn-order" onClick={this.addItemToCart}>
                    <span className="icon-plus" />
                  </button>
                ) : null}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  removeAllCart: bindActionCreators(removeAllCart, dispatch),
});

export default connect(null, bindDispatchToProps)(injectIntl(withRouter(BestMatchedItem)));
