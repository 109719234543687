import * as AreaBrowseV1 from './area.browse.v1.action';
import * as AreasBrowseV1 from './areas.browse.v1.action';

export default {
  browseByBusiness: {
    callAPI: (params) => AreaBrowseV1.exec(params),
    parseResponse: (response) => AreaBrowseV1.parseResponse(response),
  },

  browse: {
    callAPI: () => AreasBrowseV1.exec(),
    parseResponse: (response) => AreasBrowseV1.parseResponse(response),
  },
};