import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BuyingOrderItem from './BuyingOrderItem';

class BuyingOrderList extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
  };

  render() {
    const {
      location: { pathname },
    } = this.props;
    const listOrder = this.props.orders.results.map((item) => {
      return (
        <BuyingOrderItem
          key={item.id}
          id={item.id}
          orderDate={item.orderDate}
          paymentMethod={item.paymentMethod}
          total={item.total}
          status={item.status}
          link={`${pathname}/${item.id}/buying/detail`}
        />
      );
    });

    return (
      <Table className="unstackable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="order_date" defaultMessage="Order date" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="total_payment"
                defaultMessage="Total payment"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="payment_method"
                defaultMessage="Payment method"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="order_status"
                defaultMessage="Order status"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{listOrder}</Table.Body>
      </Table>
    );
  }
}

export default withRouter(BuyingOrderList);
