import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Table, Icon } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import { formatDecimal } from 'shared/helpers'

class BuyingOrderItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    orderDate: PropTypes.string,
    paymentMethod: PropTypes.string,
    total: PropTypes.number,
    status: PropTypes.string,
    link: PropTypes.string
  }

  onChangeRoute = () => {
    this.props.history.push(this.props.link);
  }

  render() {
    return (
      <Table.Row className="cursor-pointer" key={this.props.key} onClick={() => this.onChangeRoute()}>
        <Table.Cell>{this.props.id}</Table.Cell>
        <Table.Cell>{moment.utc(this.props.orderDate).format('DD-MM-YYYY HH:mm')}</Table.Cell>
        <Table.Cell>AUD ${this.props.total && formatDecimal(this.props.total)}</Table.Cell>
        <Table.Cell>{this.props.paymentMethod}</Table.Cell>
        <Table.Cell><span className={`badge badge-${this.props.status.toLowerCase()}`}><FormattedMessage id={`order.${this.props.status.toLowerCase()}`} defaultMessage={this.props.status} /></span></Table.Cell>
        <Table.Cell><Icon name="eye" /></Table.Cell>
      </Table.Row>
    )
  }
}

export default withRouter(BuyingOrderItem);
