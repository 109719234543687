import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import { formatDecimal } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';
import { Link } from 'react-router-dom';
import Config from "constants/Config";

class BookingSummary extends Component {
  static propTypes = {
    detail: PropTypes.object,
  };

  handleEst = () => {
    let est = 0;
    let time = 0;
    const { detail } = this.props;
    let items = detail.bookingItems;
    items && items.map((item) => {
      est = Math.round((parseFloat(est) + parseFloat(item.duration)) * 1e12) / 1e12
    });
    let timeSlotSelected = items && items[0].fromTime;
    if (est > 0) {
      let duration = est * 60;
      time = duration;
      let hours = Math.trunc(duration / 60);
      let minutes = duration % 60;
      est = `${hours}h${minutes > 0 ? minutes : ''}`;
    }

    return `${est} duration, ends at ${moment(timeSlotSelected, 'HH:mm').add(parseInt(time), 'minutes').format('HH:mm')}`;
  }

  render() {
    const { detail } = this.props;
    const seller = detail.bookingItems && detail.bookingItems.length > 0 ? detail.bookingItems[0].seller : null;

    return (
      <>
        <Pane className="order-summary card-body">
          <Pane className="summary-body">
            <Pane className="summary-item">
              AUD ${formatDecimal(detail.total)} - {detail.bookingItems.length} {`item${detail.bookingItems.length > 1 ? 's' : ''}`} - {detail.paymentMethod} - <span className={`badge badge-${detail.status.toLowerCase()}`}>
                {detail.status}
              </span>
            </Pane>
            {seller && (
              <>
                <Link to={`/${seller.id}/services`}>
                  <Pane className="summary-user-info">
                    {seller.businessName} - Phone ({seller.phone})
                    <Icon
                      name="info circle"
                      data-tip
                      data-for="item-info"
                    />
                  </Pane>
                </Link>
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="float"
                  id="item-info"
                >
                  {seller.email} - {`${seller.address} ${seller.address}`}
                </ReactTooltip>
              </>
            )}
            <Pane>Appointment Date: {moment.utc(detail.appointmentDate).format("DD-MM-YYYY")} at {detail.bookingItems ? detail.bookingItems[0].fromTime : ''} - {this.handleEst()}</Pane>
          </Pane>
          <Pane className="summary-body">
            <em className="summary-time">Booking Time: {moment.utc(detail.bookingDate).format("DD-MM-YYYY HH:mm")}</em>
          </Pane>
        </Pane>
      </>
    );
  }
}

export default BookingSummary;