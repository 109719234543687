import * as types from 'constants/ActionTypes'
import UserAdapter from 'services/users/adapter'

const initialState = {
  results: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const promotionProducts = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_PROMOTION_PRODUCTS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_PROMOTION_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.productPromotionBrowse.parseResponse(action.payload.data),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        fetching: false,
        fetched: true
      })
    case types.DELETE_PROMOTION_PRODUCTS_SUCCESS:
      const results = state.results.filter(item => item.id !== action.meta.previousAction.params.id)
      return Object.assign({}, state, {
        results: results,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

const promotionServices = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_PROMOTION_SERVICES:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_PROMOTION_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.servicePromotionBrowse.parseResponse(action.payload.data).items || [],
        fetching: false,
        fetched: true
      })
    case types.DELETE_PROMOTION_SERVICES_SUCCESS:
      const results = state.results.filter(item => item.id !== action.meta.previousAction.params.id)
      return Object.assign({}, state, {
        results: results,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { promotionProducts, promotionServices }
