import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";

class LeftNoti extends Component {

  goToSetting = () => {
    let routes = this.props.isCompleted ? 'settings' : 'settings?showBusiness=true';
    this.props.history.push(routes);
  }

  close = () => {
    document.getElementById("leftnotiarea").style.display = "none";
  }
  
  render() {
    return (
      <Pane className="leftNoti" id="leftnotiarea">
        <Pane className="block-body">
          <i className="far fa-times-circle" aria-hidden="true" onClick={this.close} />
          <button className="btn btn-circle btn-circle-primary wherever-button mt-3" onClick={this.goToSetting}  data-tip data-for={`tooltip`}>
            <FormattedMessage
              id={this.props.isCompleted ? 'switch_account' : 'create_business'}
              defaultMessage="Create business account"
            />
          </button>
        </Pane>
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          id={"tooltip"}
        >
          <FormattedMessage
            id={this.props.isCompleted ? 'switch_account_content' : 'create_business_content'}
            defaultMessage="Create business account"
          />
        </ReactTooltip>
      </Pane>
    );
  }
}

export default (injectIntl(withRouter(LeftNoti)));
