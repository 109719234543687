import React, { Component } from "react";
import ReactQuill from "react-quill";
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Image,
  Image as ImageS,
  Loader,
} from "semantic-ui-react";
import { Pane } from "shared/components/layouts";
import { formatRoute, handleError, quill } from "shared/helpers";
import InlineMessage from "shared/components/InlineMessage";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import Datetime from "react-datetime";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete from "react-places-autocomplete";
class CreatePromotionForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };
  state = {
    errors: {},
    loading: false,
    image: "",
    imageRender: "",
    imageFile: "",
    loadingAvatar: false,
    data: {
      name: "",
      startDate: "",
      endDate: "",
      percent: "",
      maxDiscount: "",
      oneTimeDiscount: true,

    },
  };
  validate = (data) => {
    const errors = {};
    const { intl } = this.props;
    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (data.percent && data.percent <= 0 && data.percent >100) {
      errors.percent = 'Invalid percent';
    }
    if (data.maxDiscount && data.maxDiscount <= 0) {
      errors.maxDiscount = 'Invalid max price discount';
    }
    if (!data.startDate) {
      errors.startDate = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.endDate) {
      errors.endDate = intl.formatMessage({ id: "validate.require_field" });
    }
    if (
      moment(data.startDate, "YYYY-MM-DD").unix() >
      moment(data.endDate, "YYYY-MM-DD").unix()
    ) {
      errors.startDate = intl.formatMessage({
        id: "validate.invalid_compare_date",
      });
    }
    return errors;
  };
  createPromotion = async () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length > 0) return;
    this.setState({ loading: true });
    this.addPromotion();
  };
  addPromotion = () => {
    const { userInfo } = this.props;
    const dataQr = new FormData();
    dataQr.append("name", this.state.data.name);
    dataQr.append("start_date", this.state.data.startDate);
    dataQr.append("end_date", this.state.data.endDate);
    dataQr.append("user_id", userInfo.id);
    dataQr.append("business_id", userInfo.business.id);
    dataQr.append("percent", this.state.data.percent);
    dataQr.append("max_discount", this.state.data.maxDiscount);
    dataQr.append("one_time_discount", this.state.data.oneTimeDiscount==true?1:0);
    this.props
      .createFirstScanQrPromotion(dataQr)
      .then(() => {
        this.setState({
          loading: false,
          data: {
            ...this.state.data,
            name: "",
            startDate: "",
            endDate: "",
            percent: "",
            maxDiscount: ""
          },
        });
        this.props.history.push(
          `/u/${formatRoute(this.props.userInfo.displayName)}/first-scan-qr-promotion`
        );
      })
      .catch((error) => {
        const errors = {};
        errors.message =
          "Promotion: " +
          handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );
        this.setState({
          loading: false,
          errors,
        });
      });
  };
  onChangeTime(name, value) {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value.format("YYYY-MM-DD"),
      },
    });
  }
  onChangeText = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  onChangeCheckbox = (e) => {
    this.setState({
      data: { ...this.state.data, oneTimeDiscount: e.target.checked },
    });
  };
  onChangePhone = (phone) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        phone,
      },
    }));
  };
  onChangeRadioStatus = (e, { value }) => {
    this.setState({
      data: { ...this.state.data, status: value },
    });
  };
  onChangeRadioGender = (e, { value }) => {
    this.setState({
      data: { ...this.state.data, gender: value },
    });
  };
  onChangeAvatar = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          imageRender: reader.result,
          imageFile: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  onChangeAddress = (address) => {
    this.setState({
      data: { ...this.state.data, address },
    });
  };
  render() {
    const { errors, data, loading } = this.state;
    const { intl } = this.props;
    const renderFunc = ({
      getInputProps,
      getSuggestionItemProps,
      suggestions,
    }) => (
      <Pane className="autocomplete-root form-item">
        <input {...getInputProps()} className="form-input" placeholder="" />
        <Pane className="autocomplete-dropdown-container">
          {suggestions.map((suggestion, index) => (
            <Pane {...getSuggestionItemProps(suggestion)} key={index}>
              <span>{suggestion.description}</span>
            </Pane>
          ))}
        </Pane>
      </Pane>
    );
    return (
      <Form className="form-overview pb-5" onSubmit={this.createPromotion}>
        <Pane className="form-content">
          <Pane className="row">
            <Pane className="col-lg-12">
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="full_name" className="form-label">
                      <FormattedMessage
                        id="full_name"
                        defaultMessage="Full Name"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      id="name"
                      name="name"
                      value={data.name}
                      placeholder=""
                      onChange={(e) =>
                        this.onChangeText("name", e.target.value)
                      }
                    />
                    {errors.name && <InlineMessage text={errors.name} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="percent" className="form-label">
                     <span>Percent</span>
                    </label>
                    <input
                      type="number"
                      className="form-input"
                      id="percent"
                      name="percent"
                      onChange={(e) =>
                        this.onChangeText("percent", e.target.value)
                      }
                      value={data.percent}
                      placeholder=""
                    />
                    {errors.percent && <InlineMessage text={errors.percent} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="maxDiscount" className="form-label">
                      <span>Max Discount</span>
                    </label>
                    <input
                      type="number"
                      className="form-input"
                      id="maxDiscount"
                      name="maxDiscount"
                      value={data.maxDiscount}
                      onChange={(e) =>
                        this.onChangeText("maxDiscount", e.target.value)
                      }
                      placeholder=""
                    />
                    {errors.maxDiscount && <InlineMessage text={errors.maxDiscount} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="oneTimeDiscount" className="form-label">
                    <input
                      type="checkbox"

                      id="oneTimeDiscount"
                      name="oneTimeDiscount"
                      value='1'
                      checked={data.oneTimeDiscount==1}
                      onChange={(e) =>
                        this.onChangeCheckbox( e)
                      }
                      placeholder=""
                    />
                     <span> One-time discount (not check Discount every time a customer pays)</span>
                    </label>
                    {errors.oneTimeDiscount && <InlineMessage text={errors.oneTimeDiscount} />}
                  </Form.Field>
                </Pane>
              </Pane>

              <Pane className="row">
                <Pane className="col-lg-6">
                  <Form.Field error={!!errors.startDate} className="form-item">
                    <label htmlFor="openTime" className="form-label">
                      <FormattedMessage
                        id="start_date"
                        defaultMessage="Start date"
                      />
                    </label>
                    <Datetime
                      closeOnSelect={true}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={this.onChangeTime.bind(this, "startDate")}
                      inputProps={{
                        placeholder: "YYYY-MM-DD",
                        name: "startDate",
                        readOnly: true
                      }}
                      value={data.startDate}
                    />
                    {errors.startDate && (
                      <InlineMessage text={errors.startDate} />
                    )}
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-6">
                  <Form.Field error={!!errors.endDate} className="form-item">
                    <label htmlFor="closeTime" className="form-label">
                      <FormattedMessage
                        id="end_date"
                        defaultMessage="End date"
                      />
                    </label>
                    <Datetime
                      closeOnSelect={true}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={this.onChangeTime.bind(this, "endDate")}
                      inputProps={{
                        placeholder: "YYYY-MM-DD",
                        name: "endDate",
                        readOnly: true
                      }}
                      value={data.endDate}
                    />
                    {errors.endDate && <InlineMessage text={errors.endDate} />}
                  </Form.Field>
                </Pane>
              </Pane>

            </Pane>

          </Pane>
        </Pane>
        <Pane className="row">
          <Pane className="col-lg-12 text-center">
            <Button
              type="submit"
              className="btn btn-save mt-4"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="create" defaultMessage="Create" />
            </Button>
          </Pane>
        </Pane>
      </Form>
    );
  }
}
export default injectIntl(CreatePromotionForm);