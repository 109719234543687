import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Rating } from 'semantic-ui-react';
import { Pane } from 'shared/components/layouts';
import { PriceItem } from 'shared/components';
import Config from 'constants/Config';
import { injectIntl, FormattedMessage } from "react-intl";

class ListItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    description: PropTypes.string,
  };

  state = {
    added: false,
    adding: false,
    play: 0,
  };

  isOptions = () => {
    if (!this.props.item.options) return false;
    if (!this.props.item.options.length) return false;

    return true;
  };

  changeRoute = () => {
    const {item} = this.props;
    if (this.isOptions()) {
      this.props.serviceModal.openModal();
      this.props.serviceModal.setServiceDetail(item);
      return;
    }

    let booking = {
      id: item.id,
      name: item.name,
      originalPrice: item.originalPrice,
      promotion: item.promotion,
      duration: item.duration,
      userId: item.userId
    }

    this.props.addStoreBookingsRequest(booking);

    this.props.history.push(`/store-booking/${item.userId}`);
  };

  convertRedirect = (value) => {
    if (this.props.type === 'bussiness') {
      return `/${value}`;
    }
    return `/services/${value}`;
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    // var isPlaying = video.currentTime > 0 && !video.paused;
    // if(isPlaying){
    //   video.pause();
    // }
    video.currentTime = 0;
    setTimeout(
      (v) => {
        //this.stopVideo.apply(null,target);
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  render() {
    const { item, name, image, rating, description } = this.props;
    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const ext = image != null ? image.substr(image.lastIndexOf('.') + 1) : '';
    if (this.props.modeView === Config.MODE_VIEW_MENU.MODE_LIST) {
      return (
        <Pane className="adds-item adds-list-view adds-list-service">
          <Pane className="adds-content">
            <Pane className="col-lg-6 col-md-6 col-sm-12 p-0">
              <Link
                className="adds-norm"
                to={
                  this.props.type === 'bussiness'
                    ? this.convertRedirect(this.props.item.slug)
                    : this.convertRedirect(this.props.id)
                }
              >
                <h5 className="adds-norm" title={name}>
                  <b>{name}</b>
                </h5>
                <Pane className="adds-description" dangerouslySetInnerHTML={{ __html: description }}></Pane>
              </Link>
            </Pane>
            <Pane className="col-lg-3 col-md-3 col-sm-12 p-0">
              <div className="group-price">
                <PriceItem
                  promotion={item && item.promotion}
                  originalPrice={item && item.originalPrice}
                />
                <button className="btn-order-sm d-md-none d-sm-block d-lg-none" onClick={this.changeRoute}>
                  <span className="icon-plus" />
                </button>
                <div style={{ clear: "both" }}></div>
              </div>
            </Pane>
            <Pane className="col-lg-3 col-md-3 pr-md-0">
              <button className="btn-order d-none d-md-block d-sm-none d-lg-block float-right" onClick={this.changeRoute}>
                <span className="icon-plus" />
              </button>
            </Pane>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className="col-lg-3 col-md-6 col-sm-6 col-12 pl-0 pr-0 pb-0">
        <Pane className="adds-item">
          <Link className="hover-image" to={this.convertRedirect(this.props.item.slug)}>
            {image ? (
              <>
                {
                  extImage.indexOf(ext) >= 0 ? (
                    <Pane
                      className="adds-images"
                      style={{
                        backgroundImage: image
                          ? `url(${image})`
                          : process.env.PUBLIC_URL +
                          `/assets/images/default-image.png`,
                      }}
                    />
                  ) : (
                    <Pane className="adds-images">
                      {ext !== '' ? <i className="fa fa-video" /> : ''}
                      <video
                        className="carbeyvideo-list"
                        playsInline={1}
                        onMouseOver={this.playVideo}
                        onMouseOut={this.stopVideo}
                        muted={!this.state.play}
                      >
                        <source src={image} id="video_here" />
                        Your browser does not support HTML5 video.
                      </video>
                    </Pane>
                  )
                }
              </>
            ) : (
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/default-image.png)`
                }}
              />
            )}
          </Link>
          <Pane className="adds-content">
            <Link
              to={
                this.props.type === 'bussiness'
                  ? this.convertRedirect(this.props.item.slug)
                  : this.convertRedirect(this.props.id)
              }
            >
              <h5 className="adds-norm" title={name}>
                <b>{name}</b>
              </h5>
              <Pane className="adds-description" dangerouslySetInnerHTML={{ __html: description }}></Pane>
            </Link>
            <Pane className="adds-info adds-info-item">
              <PriceItem
                promotion={item && item.promotion}
                originalPrice={item && item.originalPrice}
              />
              <button className="btn-order" onClick={this.changeRoute}>
                <span className="icon-plus" />
              </button>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
