import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Config from "constants/Config";
import { Pane, Loading } from "shared/components/layouts";
import { LoadingSearch } from "components/common/loading";
import { Rating, Icon, Button, Tab } from 'semantic-ui-react';
import List from "./reviews/List";
import { List  as ListReact} from "semantic-ui-react";
import NotificationSystem from "react-notification-system";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { ReviewStoreModal, LoginModal } from "shared/components";
import { TradingHours } from "../../user/common/trading-hours";
import ReactMapGL, { Marker } from 'react-map-gl';
import { signin, signinSocial } from 'actions/auth';
import { reviewBusiness, getReviewBusiness } from 'actions/business';
import BusinessAdapter from "services/businesses/adapter";
import ReactTooltip from "react-tooltip";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  ViberIcon,
  ViberShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LineIcon,
  LineShareButton
} from "react-share";
class StoreOverview extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      openModal: false,
    openModalLogin: false,
    loading: false,
    reviews: [],
    viewState: {
      latitude: -37.7841368,
      longitude: 144.8319178,
      zoom: 14
    },
    flag: false,
    };
    this.wrapperRef1 = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside1 = this.handleClickOutside1.bind(this);
    this._notificationSystem = null;
  }

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  onCloseModalLogin = () => {
    this.setState({ openModalLogin: false });
  };

  onOpenModal = () => {
    if (!this.props.auth.user.id) {
      this.onOpenModalLogin()
      return false;
    }

    this.setState({ openModal: true });
  };

  onOpenModalLogin = () => {
    this.setState({ openModalLogin: true });
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside1);
    this.getReviewBusiness();

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }

    this._notificationSystem = this.refs.notificationSystem;
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside1);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef1 = node;
  };

  handleClickOutside1(event) {
    if (this.wrapperRef1 && this.wrapperRef1.current && !this.wrapperRef1.current.contains(event.target)) {
      this.setState({
        flag: false,
      });
    }
  }

  getReviewBusiness = () => {
    this.setState({ loading: true });
    this.props.getReviewBusiness({
      id: this.props.storeInfo.business.id,
    }).then((response) => {
      const data = BusinessAdapter.getReview.parseResponse(response.data);
      this.setState({ loading: false, reviews: data });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  renderGeo = (lng, lat) => {
		return (
			<Marker longitude={lng} latitude={lat}>
				<img className="marker-img" src={process.env.PUBLIC_URL + '/assets/images/stores/location.svg'}
			/>
			</Marker>
		)
	}
  
  copyLink = (id, code) => {
    let copyText = window.location.origin + `/${id}/reference?code=${code}`;
    navigator.clipboard.writeText(copyText);
  }

  renderTab = () => {
    const { loading, reviews } = this.state;
    const { storeInfo, intl } = this.props;
    let shareURL = window.location.origin + `/${storeInfo.id}/reference?code=${storeInfo.uuid}`;
    const panes = [

      {
        menuItem: intl.formatMessage({
          id: "overview",
          defaultMessage: "Overview",
        }),
        render: () => (
          <Tab.Pane attached={false}>
            <Pane className="overview">
              <Pane className="overview-info">
                <Pane className="overview-info__item"><strong><FormattedMessage id="address" defaultMessage="Address" />: </strong>{storeInfo.business.address}</Pane>
                <Pane className="overview-info__item"><strong><FormattedMessage id="phone" defaultMessage="Phone" />: </strong>{storeInfo.business.phone}</Pane>
                <Pane className="overview-info__item"><strong><FormattedMessage id="email" defaultMessage="Email" />: </strong>{storeInfo.business.email}</Pane>
                <Pane className="overview-info__map">
                  {process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN && (
                    <ReactMapGL
                      ref={this.mapRef}
                      initialViewState={{
                          latitude: storeInfo.business.lat,
                          longitude: storeInfo.business.lng,
                          zoom: 14
                      }}
                      mapboxAccessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}
                      mapStyle="mapbox://styles/summap/cljsdhrgw018w01r59jklecqo"
                    >
                      {this.renderGeo(storeInfo.business.lng, storeInfo.business.lat)}
                    </ReactMapGL>
                  )}
                </Pane>
              </Pane>
            </Pane>
          </Tab.Pane>
        ),
      },
      {
        menuItem: intl.formatMessage({
          id: "reviews",
          defaultMessage: "Reviews",
        }),
        render: () => (
          <>
            <Pane className="d-flex align-items-center justify-content-end mb-2">
              <span style={{marginRight:20}} >Referral link &nbsp;
              <i className="fas fa-info-circle" data-tip data-for={`referralLink`}></i>
              </span>
              <ReactTooltip
                place="top"
                type="dark"
                effect="float"
                id={"referralLink"}>
                Here is your exclusive referral link to sign up to instantly get a 5% discount on your service fees.<br/> Plus, when any of your friends use our services through your link, you will get 10% cashback based on the value of your friend’s purchase.
              </ReactTooltip>
            </Pane>
            <div ref={this.wrapperRef1} className="d-flex align-items-center justify-content-end mb-2">

              <Button type='button' className='btn btn-default'
                 onClick={() => this.copyLink(storeInfo.id, storeInfo.uuid)}
              >
                <i className="far fa-copy"></i> Copy
              </Button>
              <Button type='button' className='btn btn-default btn-url' onClick={() => this.setState({ flag: !this.state.flag })}  data-tip data-for={`share`}>
                  <span className=" ">
                      <div className="box-icon" >
                        <span className="icon ic-common-share" />
                      </div>

                    <ListReact horizontal className={`share-list-refer share-list share-list-ref ${this.state.flag ? 'd-flex' : 'hide'} `}>
                      <ListReact.Content>
                        <FacebookShareButton className="share-item" url={shareURL}>
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                      </ListReact.Content>
                      <ListReact.Content>
                        <TwitterShareButton className="share-item" url={shareURL}>
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                      </ListReact.Content>
                      <ListReact.Content>
                        <EmailShareButton className="share-item" url={shareURL}>
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                      </ListReact.Content>
                      <ListReact.Content>
                        <LinkedinShareButton className="share-item" url={shareURL}>
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                      </ListReact.Content>
                      <ListReact.Content>
                        <ViberShareButton className="share-item" url={shareURL}>
                          <ViberIcon size={32} round={true} />
                        </ViberShareButton>
                      </ListReact.Content>

                      <ListReact.Content>
                        <TelegramShareButton className="share-item" url={shareURL}>
                          <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>
                      </ListReact.Content>

                      <ListReact.Content>
                        <WhatsappShareButton className="share-item" url={shareURL}>
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                      </ListReact.Content>
                      <ListReact.Content>
                        <LineShareButton className="share-item" url={shareURL}>
                          <LineIcon size={32} round={true} />
                        </LineShareButton>
                      </ListReact.Content>

                    </ListReact>
                </span>
              </Button>

            </div>
            <Pane className="d-flex align-items-center justify-content-end">
              <Button
                onClick={() => this.onOpenModal()}
                size="mini"
                icon
                className="btn btn-claim"
              >
                <Icon className="mr-1" name="edit" />
                <span className="pl-1">Review or rate</span>
              </Button>
            </Pane>

            <Pane className="review-nums">
              <ul className="list-star">
                { storeInfo.business.rating > 0 ? (
                    <>
                      <Pane className="total-star-title">{storeInfo.business.rating > 0 ? storeInfo.business.rating : 'No Rating'}</Pane>
                      <Rating
                        disabled
                        icon='star'
                        rating={storeInfo.business.rating}
                        maxRating={5}
                        className="mr-1"
                        size='huge'
                      />
                    </>
                  ):(
                    <Pane className="total-review-title">No rating Yet</Pane>
                  )
                }
              </ul>
              {!loading && (
                <Pane className="total-review-title">{reviews.length > 0 ? `${reviews.length} reviews` : 'No reviews yet'}</Pane>
              )}
            </Pane>
            {!loading ? (
              <>
                {reviews.length > 0 ? (
                  <Pane className="review-list">
                    <List reviews={reviews} />
                  </Pane>
                ) : (
                  <Pane className="text-center mt-5">
                    No reviews before yet, let's the first to rate and reviews.
                  </Pane>
                )}
              </>
            ) : (
              <LoadingSearch type="white" />
            )}
          </>
        ),
      },
    ];
    return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
  };


  render() {
    const { openModal, openModalLogin, loading, reviews } = this.state;
    const { storeInfo, auth, signin, signinSocial } = this.props;
    return (
      <Pane className="store-overview" id="anchor">
        <ReviewStoreModal
          onCloseModal={this.onCloseModal}
          openModal={openModal}
          storeInfo={storeInfo}
          reviewBusiness={this.props.reviewBusiness}
          onNotification={this._notificationSystem}
          getReviewBusiness={this.getReviewBusiness}
        />
        <LoginModal
          onNotification={this._notificationSystem}
          signin={signin}
          signinSocial={signinSocial}
          auth={auth}
          onCloseModal={this.onCloseModalLogin}
          openModal={openModalLogin}
          isCheckout={true}
        />
        {this.renderTab()}
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
  reviewBusiness: bindActionCreators(reviewBusiness, dispatch),
  getReviewBusiness: bindActionCreators(getReviewBusiness, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(StoreOverview));