import * as types from 'constants/ActionTypes'

const initialState = {
  lang: 'en'
}

const locale = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOCALE_SET:
      return {
        lang: action.lang
      }
    default:
      return state
  }
}

export { locale }
