import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { multiClientMiddleware } from 'redux-axios-middleware'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { apiClients, middlewareConfig } from 'middlewares/Api'
import rootReducer from 'reducers';
import { parse, stringify } from "flatted";

export const transformCircular = createTransform(
  (inboundState) => stringify(inboundState),
  (outboundState) => parse(outboundState)
);
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'loadingBar'],
  transforms: [transformCircular],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(
        thunk,
        multiClientMiddleware(apiClients, middlewareConfig)
      )
    )
  )

  const persistor = persistStore(store)

  return { store, persistor }
}

export default configureStore
