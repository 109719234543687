import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = params => {
  const page = params.page || 1;
  const size = params.size || 20;
  let query = params.id ? `?user_id=${params.id}` : `?activate=${params.activate}`;
  const id = params.id || '';
  query += params.admin_active_flg ? `&admin_active_flg=${params.admin_active_flg}`:``;
  query += params.bus_type ? `&bus_type=${params.bus_type}`:``;
  query += params.category_id ? `&category_id=${params.category_id}`:``;
  query += params.city ? `&city=${params.city}`:``;
  query += params.state ? `&state=${params.state}`:``;
  query += params.filter ? `&filter=${params.filter}`:``;
  return {
    method: 'GET',
    url: `screens${query}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  };
};

const parseResponse = response => {
  if (response.data) {
    return response.data.map(item => ({
      ...formatJson(response, item.type, item.id)
    }));
  }
  return [];
};

export { exec, parseResponse };
