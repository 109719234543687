import Validator from 'validator';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { subScribe } from 'actions/auth';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { Pane } from 'shared/components/layouts';
import { FormattedMessage, injectIntl } from 'react-intl';
import NotificationSystem from 'react-notification-system';

class NewsLetter extends Component {
  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.state = {
      email: '',
      loading: false,
    };
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = (email) => {
    const errors = {};
    const { intl } = this.props;

    if (!email) {
      errors.email = intl.formatMessage({ id: 'validate.require_field' });
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: 'validate.require_field' }),
        level: 'error',
      });
    }
    if (email && !Validator.isEmail(email)) {
      errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: 'validate.invalid_email' }),
        level: 'error',
      });
    }
    return errors;
  };

  onSubmit = (event) => {
    event.preventDefault();
    let errors = {};
    errors = this.validate(this.state.email);
    const { intl } = this.props;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({
        loading: true,
      });
      this.props.subScribe({ email: this.state.email }).then(() => {
        this._notificationSystem.addNotification({
          message: intl.formatMessage({
            id: 'notification.you_have_successfully_signed_up_for_the_newsletter',
          }),
          level: 'success',
        });
        this.setState({
          loading: false,
          open: false,
        });
      });
    }
  };

  render() {
    const { loading } = this.state;
    const { intl } = this.props;
    return (
      <Pane className="newsletter-container">
        <Pane className="container">
          <Pane className="row ">
            <Pane className="col-lg-6 col-md-12 text-lg-left text-md-left text-left">
              <h2 className="newsletter-title pb-2">
                <FormattedMessage
                  id="sign_up_newsletter"
                  defaultMessage=" SIGN UP NEWSLETTER"
                />
              </h2>
              <p className="newsletter-norm">
                <FormattedMessage
                  id="sign_up_our_newsletter_and_save_25_off_for_the_next_purchase"
                  defaultMessage="Sign up our newsletter and save 25% off for the next purchase!"
                />
              </p>
              <p className="newsletter-norm">
                <FormattedMessage
                  id="subscirbe_to_our_newsletters"
                  defaultMessage="Subscirbe to our newsletters and don't miss new arrivals, the
                  latest fashion updates and our promotions."
                />
              </p>
            </Pane>
            <Pane className="col-lg-6 col-md-12 text-right d-flex justify-content-lg-end justify-content-md-start flex-wrap align-items-center pt-3">
              <form className="newsletter-form" onSubmit={this.onSubmit}>
                <input
                  type="text"
                  className="newsletter-input"
                  placeholder={intl.formatMessage({ id: 'enter_your_email_address' })}
                  name="email"
                  onChange={this.onChange}
                />
                <Button
                  type="submit"
                  className="btn btn-circle btn-subscribe"
                  loading={loading}
                >
                  <FormattedMessage id="subscribe" defaultMessage="SUBSCRIBE" />
                </Button>
              </form>
            </Pane>
          </Pane>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

NewsLetter.propTypes = {};
const bindDispatchToProps = (dispatch) => ({
  subScribe: bindActionCreators(subScribe, dispatch),
});
export default connect(
  null,
  bindDispatchToProps
)(injectIntl(NewsLetter));
