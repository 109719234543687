import _ from "lodash";
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import { Card, Checkbox, Feed, Radio, Icon } from "semantic-ui-react";
import { Currency } from "shared/components";

class ServiceOptionItem extends Component {
  render() {
    const { childrens, type, name, index, activeIndex } = this.props;

    const subtitle = (name || "").split("-");

    const classContent = activeIndex === index ? "active" : "";

    return (
      <Pane className="cart-item-card" key={index}>
        <Pane className="con-cart-item">
          <Pane
            className="cart-item-header"
            onClick={() => this.props.setActiveIndex(index)}
          >
            <div>
              <span className="inner-title">
                {subtitle && subtitle.length ? subtitle[0] || "" : ""}
              </span>
              <span className="sub-title">
                {subtitle && subtitle.length ? "- " + (subtitle[1] || "") : ""}
              </span>
            </div>
            <div style={{ cursor: "pointer" }}>
              <Icon
                name={classContent === "active" ? "angle down" : "angle up"}
                size="small"
              />
            </div>
          </Pane>
          <Pane className={`cart-item-content ${classContent}`}>
            {childrens.map((children, indexChildren) => {
              let control = (
                <Radio
                  label={children.name}
                  name={"radio" + index}
                  value={indexChildren}
                  checked={
                    !!this.props.optionIds.find(
                      (item) => item.id === children.id
                    )
                  }
                  onChange={(e) => {
                    this.props.updateOptionIds(
                      children.id,
                      true,
                      childrens.map((chil) => chil.id)
                    );
                  }}
                />
              );

              if (type === 2 || type === 3) {
                control = (
                  <Checkbox
                    label={children.name}
                    name={"check" + index}
                    checked={
                      !!this.props.optionIds.find(
                        (item) => item.id === children.id
                      )
                    }
                    onChange={(e, { checked }) => {
                      this.props.updateOptionIds(children.id, checked, []);
                    }}
                  />
                );
              }

              return (
                <Pane className="cart-body" key={children.id}>
                  <Pane className="cart-option-item">
                    <Pane className="label-control-cart">{control}</Pane>
                    <Pane className="price-cart">
                      <span>+</span>
                      <Currency price={children.price} />
                    </Pane>
                  </Pane>
                  {type === 3 ? (
                    <Pane className="cart-quantity">
                      <span
                        className="operator"
                        onClick={() =>
                          this.props.updateOptionQuantity(children.id, -1, type)
                        }
                      >
                        -
                      </span>
                      <span className="operator number">
                        {!!this.props.optionIds.find(
                          (item) => item.id === children.id
                        )
                          ? this.props.optionIds.find(
                              (item) => item.id === children.id
                            ).quantity
                          : 1}
                      </span>
                      <span
                        className="operator"
                        onClick={() =>
                          this.props.updateOptionQuantity(children.id, 1, type)
                        }
                      >
                        +
                      </span>
                    </Pane>
                  ) : null}
                </Pane>
              );
            })}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
export default ServiceOptionItem;
