import Config from 'constants/Config'
import { formatJson, formatRoute } from 'shared/helpers'

const exec = () => {
  return {
    method: 'GET',
    url: `/scan-counts`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const scanCount = formatJson(response, response.data.type, response.data.id)

  const data = {
    scans: scanCount.scans,
    total_scans: scanCount.totalScans
  }

  return data
}

export { exec, parseResponse }
