import * as types from "constants/ActionTypes";
import api from "api";
export const getTaskMembersByUserId = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_MY_TASK_MEMBERS,
      types.RECEIVE_MY_TASK_MEMBERS_SUCCESS,
      types.RECEIVE_MY_TASK_MEMBERS_FAILURE,
    ],
    payload: {
      request: api.user.taskMembers(params),
    },
  });
export const getTaskMemberDetail = (params) => (dispatch) =>
  dispatch({
    types: types.MY_TASK_MEMBER_DETAIL,
    payload: {
      request: api.user.readTaskMember(params),
    },
  });
export const createMyTaskMember = (params) => (dispatch) =>
  dispatch({
    types: types.CREATE_MY_TASK_MEMBER,
    payload: {
      request: api.user.addTaskMember(params),
    },
  });
export const editMyTaskMember = (params) => (dispatch) =>
  dispatch({
    types: types.EDIT_MY_TASK_MEMBER,
    payload: {
      request: api.user.editTaskMember(params),
    },
  });
export const removeMyTaskMember = (params) => (dispatch) =>
  dispatch({
    types: types.REMOVE_MY_TASK_MEMBER,
    payload: {
      request: api.user.removeTaskMember(params),
    },
  });