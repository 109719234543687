import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import { Comment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { InlineMessage } from 'shared/components';

class ReviewsCollection extends Component {
  static propTypes = {
    onGetReviewsVideo: PropTypes.func,
    onNotification: PropTypes.object,
    intl: intlShape.isRequired,
    onReviewsVideo: PropTypes.func,
    onOpenModal:PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      detail: {},
      reviews: [],
      added: false,
      adding: false,
      comment: '',
      loading: false,
      pageLoading: false,
      open: false,
      errors: ''
    };
  }

  getDetail = () => {
    this.props.onGetReviewsVideo({
      id: this.props.id,
      type: 'collections',
    });
  };

  onSubmit = () => {
    const { intl } = this.props;

    if (!this.props.idUser) {
      this.props.onOpenModal();
      return false;
    }
    
    let errors = {};

    if (!this.state.comment) {
      errors.comment = intl.formatMessage({ id: 'validate.require_field' });
    }

    this.setState({ errors });

    const data = {
      id: this.props.id,
      comment: this.state.comment,
      model: 'Collection',
    };


    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this.props
        .onReviewsVideo(data)
        .then(() => {
          this.setState({
            loading: false,
            comment: '',
          });
          this.getDetail();
          this.props.onNotification.addNotification({
            message: intl.formatMessage({
              id: 'notification.thank_for_review',
            }),
            level: 'success',
          });
        })
        .catch(() => {
          this.setState({ loading: false });
          this.props.onNotification.addNotification({
            message: intl.formatMessage({ id: 'notification.please_try_again' }),
            level: 'error',
          });
        });
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.getDetail();
    this._notificationSystem = this.refs.notificationSystem;
  }

  render() {
    const { reviews } = this.props;
    const { errors, loading } = this.state;

    return (
      <Pane className="tab-details py-3">
        {this.props.isFront && (
          <Form onSubmit={this.onSubmit} className="mt-2">
            <Pane className="comment-form">
              <Form.Field error={!!errors.comment} className="form-item">
              <textarea
                placeholder="Write your own review"
                name="comment"
                className="form-input"
                value={this.state.comment}
                onChange={this.onChange}
                style={{ height: 150, width: '100%', padding: '10px' }}
              />
              {errors.comment && <InlineMessage text={errors.comment} />}
            </Form.Field>
            </Pane>
            <Pane className="comment-form">
              <Button
                size="tiny"
                disabled={loading}
                loading={loading}
                className="btn btn-circle-primary btn-comment mt-1"
                style={{color: '#fff'}}
              >
                <FormattedMessage id="send" defaultMessage="Send" />
              </Button>
            </Pane>
          </Form>
        )}
        <Pane className="list-comment mt-4">
          {reviews && reviews.results.length > 0 ? (
            <Comment.Group>
              {reviews.results.map((item, index) => {
                return (
                  <Pane className="comment-item" key={index}>
                    <Pane className="comment-avatar col-2">
                      <img
                        src={item.user.profileImage}
                        alt="ava1"
                        className="comment-img"
                      />
                    </Pane>
                    <Pane className="comment-content col-10">
                      <h2 className="comment-name">{item.user.name}</h2>
                      <p className="comment-norm">{item.comment}</p>
                    </Pane>
                  </Pane>
                );
              })}
            </Comment.Group>
          ) : (
            <Pane className="block-no-content">
              <p>
                <FormattedMessage
                  id="notification.no_comments"
                  defaultMessage="No comments"
                />
              </p>
            </Pane>
          )}
        </Pane>
      </Pane>
    );
  }
}

ReviewsCollection.propTypes = {};
export default withRouter(injectIntl(ReviewsCollection));
