import React from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'shared/components/layouts'

const Cover = ({ image }) => {
  return (
    <Pane className='profile-cover mb30' style={{ backgroundImage: `url(${image})`, marginTop: '-30px' }}>
      <img src={`${image}`} alt="" />
    </Pane>
  )
}

Cover.propTypes = {
  image: PropTypes.string
}

Cover.defaultProps = {
  image: ''
}

export default Cover
