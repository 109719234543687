import React from 'react'
import PropTypes from 'prop-types'
import { Rating, Comment } from 'semantic-ui-react'
import { Pane } from 'shared/components/layouts'

const CommentItem = ({ name, stars, comment, profileImage }) => {
  return (
    <Comment className='pb10'>
      <Comment.Avatar src={profileImage || process.env.PUBLIC_URL + '/assets/images/default-avatar.png'} />
      <Comment.Content>
        <Comment.Author>
          <span className="mr10">{name}</span>
          <Rating
            rating={stars}
            disabled
            icon='star'
            maxRating='5'
            size='small'
          />
        </Comment.Author>
        <Comment.Text>{comment}</Comment.Text>
      </Comment.Content>
      <Pane className='clearfix' />
    </Comment>
  )
}

CommentItem.propTypes = {
  name: PropTypes.string,
  stars: PropTypes.number,
  comment: PropTypes.string,
  profileImage: PropTypes.string
}

CommentItem.defaultProps = {
  name: '',
  stars: 0,
  comment: '',
  profileImage: ''
}

export default CommentItem
