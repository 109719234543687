import React from 'react'
import ReactDOM from 'react-dom'
import Root from 'containers/Root'
import configureStore from 'stores/configureStore'
import { createBrowserHistory } from 'history'
import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import vi from 'react-intl/locale-data/vi'
import { routes } from 'routes'
import { Cookies } from 'react-cookie'
import { localeSet } from 'actions/locale'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const { store, persistor } = configureStore()
const history = createBrowserHistory()
const cookies = new Cookies()
const localLang = cookies.get('localLang')

addLocaleData(vi)
addLocaleData(en)
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

if (localLang && typeof localLang !== undefined) {
  store.dispatch(localeSet(localLang))
}

ReactDOM.render(
  <Root
    store={store}
    routes={routes}
    history={history}
    persistor={persistor}
  />
, document.getElementById('root'))
// serviceWorker.unregister();
