import Config from 'constants/Config'
import { formatJson, formatRoute } from 'shared/helpers'

const exec = params => {
  const page = params && params.page ? params.page : 1
  const size = params && params.size ? params.size : 100
  const country = params && params.country || 'aus'

  return {
    method: 'GET',
    url: `/business?page[size]=${size}&page[number]=${page}&country=${country}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const businesses = []

  if (response.data) {
    response.data.forEach(item => {
      const business = formatJson(response, item.type, item.id)

       if (business.user) {
        businesses.push({
          id: business.user ? business.user.id : null,
          name: business.user.fullname,
          slug: formatRoute(business.name, '-'),
          backgroundImage: business.user.backgroundImage && business.user.backgroundImage.url,
          profileImage: business.user.image && business.user.image.url,
          qrcode: business.user.qrcode && business.user.qrcode.url,
          rating: 5,
          business: {
            id: business.id,
            name: business.name,
            email: business.email,
            description: business.description,
            phone: business.phone,
            address: business.address,
            postCode: business.postCode,
            createAt: business.createdAt,
            updatedAt: business.updatedAt,
            is_active: business.isActive,
            firstScanFlg: business.firstScanFlg,
            firstScanDiscount: business.firstScanDiscount,
            type: business.type,
          },
          // products: business.products ? business.products : [],
          // services: business.services ? business.services : []
        })
      }
    })
  }

  const data = businesses

  return data
}

export { exec, parseResponse }
