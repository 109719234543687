import * as types from 'constants/ActionTypes'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export const localeSet = (lang) => ({
  type: types.LOCALE_SET,
  lang
})

export const setLocale = (lang) => (dispatch) => {
  cookies.set('localLang', lang, { path: '/' })
  dispatch(localeSet(lang))
}
