import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { getServiceDetail, reviewService } from 'actions/services';
import { getReviews } from 'actions/reviews';
import { ServiceDetail } from 'components/scenes/services/detail';
import { getIdFromSlug } from 'shared/helpers';
import { Pane } from 'shared/components/layouts';
import { addStoreBookingsRequest } from "actions/storeBookings";
import BussinessList from 'components/common/bussiness/BussinessList'

class ServiceDetailPage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    getServiceDetail: PropTypes.func,
    rateService: PropTypes.func,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <BussinessList />
          <ServiceDetail
            id={getIdFromSlug(this.props.match.params.id)}
            auth={this.props.auth}
            detail={this.props.serviceDetail}
            reviews={this.props.reviews}
            onGetServiceDetail={this.props.getServiceDetail}
            onReviewService={this.props.reviewService}
            onGetReviews={this.props.getReviews}
            addStoreBookingsRequest={this.props.addStoreBookingsRequest}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  serviceDetail: state.serviceDetail,
  reviews: state.reviews,
});

const bindDispatchToProps = (dispatch) => ({
  getServiceDetail: bindActionCreators(getServiceDetail, dispatch),
  reviewService: bindActionCreators(reviewService, dispatch),
  getReviews: bindActionCreators(getReviews, dispatch),
  addStoreBookingsRequest: bindActionCreators(addStoreBookingsRequest, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(ServiceDetailPage);
