import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { formatDecimal } from "shared/helpers";
import Config from "constants/Config";
import { Pane } from 'shared/components/layouts';

class OrderSummary extends Component {
  static propTypes = {
    detail: PropTypes.object,
  };

  isDineIn = (detail) => {
    if (detail.cartItems) {
      const items = detail.cartItems.find(
        (item) =>
          item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN ||
          item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
      );
      return !!items;
    }
    return false;
  };

  render() {
    const { detail } = this.props;

    return (
      <Pane className="order-summary card-body">
        {detail.mode !== "GUEST" && (
          <>
            <Pane className="summary-body">
              <Pane className="summary-item">
                AUD ${formatDecimal(detail.total)} - {detail.cartItems.length} {`item${detail.cartItems.length > 1 ? 's' : ''}`} - {detail.paymentMethod} - <span className={`badge badge-${detail.status.toLowerCase()}`}>
                  {detail.status}
                </span>
              </Pane>
              <Pane className="summary-user-info">
                {parseInt(detail.user.id) !== 1 ? (
                  <>
                    {detail.user.name} - Phone ({detail.user.phone})
                    <Icon
                      name="info circle"
                      data-tip
                      data-for="item-info"
                    />
                  </>
                ) : (
                  <Pane>POS Order</Pane>
                )}
              </Pane>
              <ReactTooltip
                place="top"
                type="dark"
                effect="float"
                id="item-info"
              >
                {detail.user.email} - {`${detail.user.address} ${detail.user.address && detail.user.postcode
                  ? `, ${detail.user.postcode}`
                  : detail.user.postcode
                  }`}
              </ReactTooltip>
              {detail.paymentMethod !== Config.PAYMENT_METHOD.PAY_BY_CASH && (
                <Pane className="summary-body">
                  Payment method:&nbsp;
                  {detail.paymentStatus}
                </Pane>
              )}
            </Pane>
          </>
        )}
        {detail.mode === "GUEST" && (
          <>
            <Pane className="summary-body">
              <Pane className="summary-item">
                AUD ${formatDecimal(detail.total)} - {detail.cartItems.length} {`item${detail.cartItems.length > 1 ? 's' : ''}`} - {detail.paymentMethod} - <span className={`badge badge-${detail.status.toLowerCase()}`}>
                  {detail.status}
                </span>
              </Pane>
              <Pane className="summary-user-info">
                {detail.guestName} - {detail.guestPhone}
                <Icon
                  name="info circle"
                  data-tip
                  data-for="item-info"
                />
              </Pane>
              <ReactTooltip
                place="top"
                type="dark"
                effect="float"
                id="item-info"
              >
                {detail.guestEmail}
              </ReactTooltip>
            </Pane>
          </>
        )}
        {detail.refundAmount > 0 && (
          <Pane className="summary-body">
            <FormattedMessage
              id="refund_price"
              defaultMessage="Refund price"
            />
            : {formatDecimal(detail.refundAmount)}
          </Pane>
        )}
        {!this.isDineIn(detail) && (
          <>
            <Pane className="summary-method">{detail.cartItems[0] ? detail.cartItems[0].deliveryMethod : ''}</Pane>
            <Pane className="summary-body">
              {detail.shippingAddress}
            </Pane>
          </>
        )}
        {this.isDineIn(detail) && (
          <>
            <Pane className="summary-method">{detail.cartItems[0] ? detail.cartItems[0].deliveryMethod : ''}</Pane>
            <Pane className="summary-body">
              Table:&nbsp;
              <span className={`badge badge-done`}>
                {detail.table && detail.table.name}
              </span>
            </Pane>
          </>
        )}
        <Pane className="summary-body">
          <em className="summary-time">Order Time: {moment.utc(detail.orderDate).format("DD-MM-YYYY HH:mm")}</em>
        </Pane>
      </Pane>
    );
  }
}

export default OrderSummary;
