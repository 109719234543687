import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import {formatJson } from "shared/helpers";
import { bindActionCreators } from "redux";
import { getCustomerByFirstScanQrPromotion } from "actions/scanQrPromotion";
import { Pane } from 'shared/components/layouts';
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';
import queryString from "query-string";
import { Table } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import ReactPaginate from "react-paginate";
class DetailQrPromotion extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      size: 20,
      page: parseInt(queryString.parse(search).page, 10) || 1,
      members: [],
      totalPages: 0,
      totalItems: 0
    };
  }
  componentDidMount = () => {
    const { userInfo } = this.props;
    this.getCustomerByFirstScanQrPromotion({ id: this.props.match.params.promotionId, page: this.state.page, size: this.state.size});

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  };
  getCustomerByFirstScanQrPromotion = (params) => {
    this.props.getCustomerByFirstScanQrPromotion(params).then((response) => {

      if (response && response.data) {
          let members = [];
          if (response && response.data) {
            response.data.data.forEach((item) => {
              const data = formatJson(response.data, item.type, item.id);
              members.push(data);
            });
            this.setState({ members: members });
            this.setState({ totalPages: response.data.meta.totalPages });
            this.setState({ totalItems: response.data.meta.totalItems });
          }


      }
    });
  };
  handlePageClick = (data) => {
    const params = {
      id: this.props.match.params.promotionId,
      page: data.selected + 1,
      size: this.state.size,
    };
    this.getCustomerByFirstScanQrPromotion(params);
  };
  render() {
    let {members, totalPages, totalItems} = this.state;
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/first-scan-qr-promotion`,
        name: 'First scan qr promotion'
      },
      {
        url: ``,
        name: `Customers (${totalItems})`,
        active: true
      }
    ];

    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="text-center mb-4" id="anchor">
        </Pane>
        <Pane className="products-grids">

            <Table singleLine>
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage id="id" defaultMessage="#Id" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="name" defaultMessage="Name" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="phone"
                      defaultMessage="Phone"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="email"
                      defaultMessage="Email"
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {members && members.length
                  ? members.map((member) => (
                      <Table.Row key={member.id}>
                        <Table.Cell>#{member.id}</Table.Cell>
                        <Table.Cell>{member.fullname}</Table.Cell>
                        <Table.Cell>
                          {member.phone}
                        </Table.Cell>
                        <Table.Cell>
                          {member.email}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  : null}
              </Table.Body>
            </Table>
            <Pane className="clearfix" />
          </Pane>
          <Pane className="pagination-bar text-center">
            <nav className="d-inline-b">
              {totalPages > 1 ? (
                <ReactPaginate
                  previousLabel={
                    <FormattedMessage
                      id="pagination.previous"
                      defaultMessage="previous"
                    />
                  }
                  nextLabel={
                    <FormattedMessage
                      id="pagination.next"
                      defaultMessage="next"
                    />
                  }
                  breakLabel={<button className="btn-break-paging">...</button>}
                  breakClassName={"break-me"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              ) : null}
            </nav>
          </Pane>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});
const bindDispatchToProps = (dispatch) => ({
  getCustomerByFirstScanQrPromotion: bindActionCreators(getCustomerByFirstScanQrPromotion, dispatch),
});
export default connect(mapStateToProps, bindDispatchToProps)(DetailQrPromotion);