import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = params => {
  const page = params.page || 1;
  const size = params.size || 20;
  const id = params.id || '';
  const screen_id = params.screenId || ''
  const condition = screen_id !== '' ? `&screen_id=${screen_id}` : ''
  return {
    method: 'GET',
    url: `digitals?user_id=${id}${condition}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  };
};

const parseResponse = response => {
  if (response.data) {
    return response.data.map(item => ({
      ...formatJson(response, item.type, item.id)
    }));
  }
  return [];
};

export { exec, parseResponse };
