import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';

class FeatureServiceItem extends Component {
  static propTypes = {
    items: PropTypes.array,
  };

  renderItem(index) {
    if (this.props.items && this.props.items.length > index) {
      return (
        <Link to={`/services/${this.props.items[index].slug}`}>
          <img
            src={this.props.items[index].primaryImage ? `${this.props.items[index].primaryImage}?w=400` : this.props.items[index].primaryImage}
            alt={this.props.name}
            className="img-responsive"
          />
        </Link>
      );
    }

    return '';
  }
  render() {
    return (
      <Pane className="item featured-products-services">
        <Pane className="col-xl-5 col-md-5 col-sm-5 col-xs-5 f-feature">
          <Pane className="inner">{this.renderItem(0)}</Pane>
        </Pane>
        <Pane className="col-xl-3 col-md-3 col-sm-3 col-xs-3 f-feature">
          <Pane className="inner row1">{this.renderItem(1)}</Pane>
          <Pane className="inner row2">{this.renderItem(2)}</Pane>
        </Pane>
        <Pane className="col-xl-4 col-md-4 col-sm-4 col-xs-4 f-feature">
          <Pane className="inner">{this.renderItem(3)}</Pane>
        </Pane>
      </Pane>
    );
  }
}

export default FeatureServiceItem;
