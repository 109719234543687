import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Pane } from "shared/components/layouts";
import { Cart } from "components/common/cart";
import { Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { currentCart } from "shared/helpers";

class SlideImage extends Component {
  static propTypes = {
    id: PropTypes.any,
    auth: PropTypes.object,
  };

  checkSubImageEmpty = (data) => {
    if (
      data.subImage1 ||
      data.subImage2 ||
      data.subImage3 ||
      data.subImage4 ||
      data.subImage5
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { detail, adding, added, cart } = this.props;
    const extImage = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];
    const settings = {
      customPaging(i) {
        if (i === 0) {
          const ext = detail.primaryImage.substr(
            detail.primaryImage.lastIndexOf(".") + 1
          );
          if (extImage.indexOf(ext) >= 0) {
            return (
              <Pane
                className="image-dot"
                style={{
                  backgroundImage: detail.primaryImage
                    ? `url(${detail.primaryImage})`
                    : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                }}
              >
                <img
                  src={`${
                    detail.primaryImage
                      ? detail.primaryImage
                      : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`
                  }`}
                  alt=""
                />
              </Pane>
            );
          }
          return (
            <Pane className="image-dot">
              <video className="carbeyvideo">
                <source src={detail.primaryImage} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            </Pane>
          );
        }
        if (i >= 1 && i <= 5) {
          const name = "subImage" + i.toString();
          const ext = detail[name].substr(detail[name].lastIndexOf(".") + 1);
          if (extImage.indexOf(ext) >= 0) {
            return (
              <Pane
                className="image-dot"
                style={{
                  backgroundImage: detail[name]
                    ? `url(${detail[name]})`
                    : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                }}
              >
                <img
                  src={`${
                    detail[name]
                      ? detail[name]
                      : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`
                  }`}
                  alt=""
                />
              </Pane>
            );
          }
          return (
            <Pane className="image-dot">
              <Pane className="overlay-video" />
              <video className="carbeyvideo" controls>
                <source src={detail[name]} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            </Pane>
          );
        }
        /*if (i === 1) {
          var ext = detail.subImage1.substr(detail.subImage1.lastIndexOf('.') + 1);
          if(extImage.indexOf(ext) >= 0) {
            return (
              <Pane
                className="image-dot"
                style={{ backgroundImage: `url(${detail.subImage1 || ''})` }}
              >
                <img src={`${detail.subImage1}`} alt="" />
              </Pane>
            );
          } else {
            return (
              <Pane
                className="image-dot"
              >
              <video className="carbeyvideo" controls >
                <source src={detail.subImage1} id="video_here"/>
                Your browser does not support HTML5 video.
              </video>
              </Pane>
            );
          }

        }
        if (i === 2) {
          var ext = detail.subImage2.substr(detail.subImage2.lastIndexOf('.') + 1);
          if(extImage.indexOf(ext) >= 0) {
            return (
              <Pane
                className="image-dot"
                style={{ backgroundImage: `url(${detail.subImage2 || ''})` }}
              >
                <img src={`${detail.subImage2}`} alt="" />
              </Pane>
            );
          } else {
            return (
              <Pane
                className="image-dot"
              >
              <video className="carbeyvideo" controls >
                <source src={detail.subImage2} id="video_here"/>
                Your browser does not support HTML5 video.
              </video>
              </Pane>
            );
          }
        }
        if (i === 3) {
          var ext = detail.subImage3.substr(detail.subImage3.lastIndexOf('.') + 1);
          if(extImage.indexOf(ext) >= 0) {
            return (
              <Pane
                className="image-dot"
                style={{ backgroundImage: `url(${detail.subImage3 || ''})` }}
              >
                <img src={`${detail.subImage3}`} alt="" />
              </Pane>
            );
          } else {
            return (
              <Pane
                className="image-dot"
              >
              <video className="carbeyvideo" controls >
                <source src={detail.subImage2} id="video_here"/>
                Your browser does not support HTML5 video.
              </video>
              </Pane>
            );
          }
        }
        if (i === 4) {
          return (
            <Pane
              className="image-dot"
              style={{ backgroundImage: `url(${detail.subImage4 || ''})` }}
            >
              <img src={`${detail.subImage4}`} alt="" />
            </Pane>
          );
        }
        if (i === 5) {
          return (
            <Pane
              className="image-dot"
              style={{ backgroundImage: `url(${detail.subImage5 || ''})` }}
            >
              <img src={`${detail.subImage5}`} alt="" />
            </Pane>
          );
        }*/
      },
      arrows: true,
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      arrows: false,
    };
    const extprimaryImage =
      detail.primaryImage != null
        ? detail.primaryImage.substr(detail.primaryImage.lastIndexOf(".") + 1)
        : "";
    const extsubImage1 =
      detail.subImage1 != null
        ? detail.subImage1.substr(detail.subImage1.lastIndexOf(".") + 1)
        : "";
    const extsubImage2 =
      detail.subImage2 != null
        ? detail.subImage2.substr(detail.subImage2.lastIndexOf(".") + 1)
        : "";
    const extsubImage3 =
      detail.subImage3 != null
        ? detail.subImage3.substr(detail.subImage3.lastIndexOf(".") + 1)
        : "";
    const extsubImage4 =
      detail.subImage4 != null
        ? detail.subImage4.substr(detail.subImage4.lastIndexOf(".") + 1)
        : "";
    const extsubImage5 =
      detail.subImage5 != null
        ? detail.subImage5.substr(detail.subImage5.lastIndexOf(".") + 1)
        : "";

    return (
      <Slider
        className={`slider-details ${!this.checkSubImageEmpty(detail) &&
          "slider-empty"}`}
        {...settings}
      >
        {detail.primaryImage ? (
          <Pane className="thumb-image">
            {extImage.indexOf(extprimaryImage) >= 0 ? (
              <img
                src={
                  detail.primaryImage ||
                  process.env.PUBLIC_URL + `/assets/images/default-image.png`
                }
                alt={detail.name || ""}
                className="category-img"
                ref={(img) => (this.img = img)}
                onError={() =>
                  (this.img.src =
                    process.env.PUBLIC_URL + `/assets/images/default-image.png`)
                }
              />
            ) : (
              <video className="carbeyvideo" controls>
                <source src={detail.primaryImage} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            )}
            <Button
              loading={adding}
              disabled={adding || added}
              className="btn btn-circle btn-category"
              onClick={this.props.addItemToCart}
            >
              <Cart added={added} type="details" />
              <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
            </Button>
          </Pane>
        ) : (
          <Pane className="thumb-image">
            <img
              src={process.env.PUBLIC_URL + `/assets/images/default-image.png`}
              className="category-img"
              onError={() =>
                (this.img.src =
                  process.env.PUBLIC_URL + `/assets/images/default-image.png`)
              }
            />
            <Button
              loading={adding}
              disabled={adding || added}
              className="btn btn-circle btn-category"
              onClick={this.props.addItemToCart}
            >
              <Cart added={added} type="details" />
              <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
            </Button>
          </Pane>
        )}
        {detail.subImage1 ? (
          <Pane className="thumb-image">
            {extImage.indexOf(extsubImage1) >= 0 ? (
              <img
                src={detail.subImage1 || ""}
                alt={detail.name || ""}
                className="category-img"
              />
            ) : (
              <video className="carbeyvideo" controls>
                <source src={detail.subImage1} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            )}
            <Button
              loading={adding}
              disabled={adding || added}
              className="btn btn-circle btn-category"
              onClick={this.props.addItemToCart}
            >
              <Cart added={added} type="details" />
              <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
            </Button>
          </Pane>
        ) : null}
        {detail.subImage2 ? (
          <Pane className="thumb-image">
            {extImage.indexOf(extsubImage2) >= 0 ? (
              <img
                src={detail.subImage2 || ""}
                alt={detail.name || ""}
                className="category-img"
              />
            ) : (
              <video className="carbeyvideo" controls>
                <source src={detail.subImage2} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            )}
            <Button
              loading={adding}
              disabled={adding || added}
              className="btn btn-circle btn-category"
              onClick={this.props.addItemToCart}
            >
              <Cart added={added} type="details" />
              <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
            </Button>
          </Pane>
        ) : null}
        {detail.subImage3 ? (
          <Pane className="thumb-image">
            {extImage.indexOf(extsubImage3) >= 0 ? (
              <img
                src={detail.subImage3 || ""}
                alt={detail.name || ""}
                className="category-img"
              />
            ) : (
              <video className="carbeyvideo" controls>
                <source src={detail.subImage3} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            )}
            <Button
              loading={adding}
              disabled={adding || added}
              className="btn btn-circle btn-category"
              onClick={this.props.addItemToCart}
            >
              <Cart added={added} type="details" />
              <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
            </Button>
          </Pane>
        ) : null}
        {detail.subImage4 ? (
          <Pane className="thumb-image">
            {extImage.indexOf(extsubImage4) >= 0 ? (
              <img
                src={detail.subImage4 || ""}
                alt={detail.name || ""}
                className="category-img"
              />
            ) : (
              <video className="carbeyvideo" controls>
                <source src={detail.subImage4} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            )}
            <Button
              loading={adding}
              disabled={adding || added}
              className="btn btn-circle btn-category"
              onClick={this.props.addItemToCart}
            >
              <Cart added={added} type="details" />
              <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
            </Button>
          </Pane>
        ) : null}
        {detail.subImage5 ? (
          <Pane className="thumb-image">
            {extImage.indexOf(extsubImage5) >= 0 ? (
              <img
                src={detail.subImage5 || ""}
                alt={detail.name || ""}
                className="category-img"
              />
            ) : (
              <video className="carbeyvideo" controls>
                <source src={detail.subImage5} id="video_here" />
                Your browser does not support HTML5 video.
              </video>
            )}
            <Button
              loading={adding}
              disabled={adding || added}
              className="btn btn-circle btn-category"
              onClick={this.props.addItemToCart}
            >
              <Cart added={added} type="details" />
              <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
            </Button>
          </Pane>
        ) : null}
      </Slider>
    );
  }
}

export default SlideImage;
