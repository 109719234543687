import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'semantic-ui-react';
import { RenderRoutes } from '../routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/components/dimmer.css';
import 'semantic-ui-css/components/button.css';
import 'semantic-ui-css/components/table.css';
import 'semantic-ui-css/components/image.css';
import 'semantic-ui-css/components/loader.css';
import 'semantic-ui-css/components/rating.css';
import 'semantic-ui-css/components/modal.css';
import 'semantic-ui-css/components/tab.css';
import 'semantic-ui-css/components/icon.css';
import 'semantic-ui-css/components/label.css';
import 'semantic-ui-css/components/dropdown.css';
import 'semantic-ui-css/components/flag.css';
import 'semantic-ui-css/components/comment.css';
import 'semantic-ui-css/components/transition.css';
import 'semantic-ui-css/components/checkbox.css';
import 'semantic-ui-css/components/list.css';
import 'semantic-ui-css/components/message.css';
import 'semantic-ui-css/components/breadcrumb.css';
import 'semantic-ui-css/components/placeholder.css';
import 'react-select/dist/react-select.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datetime/css/react-datetime.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-dates/lib/css/_datepicker.css';
import 'assets/styles/main.scss';
import 'babel-polyfill';
import 'react-quill/dist/quill.snow.css';
import 'animate.css/animate.min.css';
import 'rc-tree-select/assets/index.css';
import 'react-phone-input-2/lib/style.css';

// import branch from 'branch-sdk'

import { Pane, Loading } from 'shared/components/layouts';
import { getProductDetail } from 'actions/products';
import { getServiceDetail } from 'actions/services';

class App extends React.Component {
  static propTypes = {
    route: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      document.body.style.overflowY = 'unset';
      window.scrollTo(0, 0);
    }
  }

  // UNSAFE_componentWillMount() {
  //   if (this.props.history.location.search) {
  //     this.setState({ loading: true })
  //     branch.init(process.env.REACT_APP_DEEPLINKING_KEY, (error, data) => {
  //       if (data && data.data_parsed) {
  //         if (!data.data_parsed.user_id && !data.data_parsed.product && !data.data_parsed.service) {
  //           this.setState({ loading: false })
  //         }
  //         if (data.data_parsed.user_id) {
  //           this.props.history.push(`/${data.data_parsed.user_id}`)
  //           this.setState({ loading: false })
  //         }
  //         if (data.data_parsed.product) {
  //           this.props.getProductDetail(data.data_parsed.product).then(response => {
  //             this.setState({ loading: false })
  //             this.props.history.push(`/products/${formatRoute(response.data.name, '-')}-${response.data.id}`)
  //           }).catch(() => {
  //             this.setState({ loading: false })
  //           })
  //         }
  //         if (data.data_parsed.service) {
  //           this.props.getServiceDetail(data.data_parsed.service).then(response => {
  //             this.setState({ loading: false })
  //             this.props.history.push(`/services/${formatRoute(response.data.name, '-')}-${response.data.id}`)
  //           }).catch(() => {
  //             this.setState({ loading: false })
  //           })
  //         }
  //       } else {
  //         this.setState({ loading: false })
  //       }
  //     })
  //   }
  // }

  render() {
    return (
      <Pane className="route-container">
        {!this.state.loading ? (
          <RenderRoutes routes={this.props.route.routes} />
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}
      </Pane>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  getProductDetail: bindActionCreators(getProductDetail, dispatch),
  getServiceDetail: bindActionCreators(getServiceDetail, dispatch),
});

export default withRouter(
  connect(
    null,
    bindDispatchToProps
  )(App)
);
