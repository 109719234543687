import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import Config from 'constants/Config';

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    intl: intlShape.isRequired,
  };

  state = {
    play: 0,
    like: 0,
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    video.play();
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  render() {
    const { name, image, viewVideo, likeVideo, type, slug, id } = this.props;
    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const ext = image != null ? image.substr(image.lastIndexOf('.') + 1) : '';
    return (
      <Pane className="col-12">
        <Pane className="adds-item">
          <Pane>
            {extImage.indexOf(ext) >= 0 ? (
              <Link to={`/${type}/${slug}`}>
                <Pane
                  className="adds-images"
                  style={{
                    backgroundImage: image
                      ? `url(${image})`
                      : process.env.PUBLIC_URL +
                        `/assets/images/default-image.png`,
                      height: 150, witdh: '100%'
                  }}
                />
                <p style={{ color: 'black' }}>{name}</p>
              </Link>
            ) : (
              <Pane
                className="adds-videos-wrapper"
                style={{
                  height: 'auto',
                }}
              >
                {ext !== '' ? <i className="fa fa-video" /> : ''}
                <Link to={`/${slug}/${type}/${id}`}>
                  <video
                    className="myvideo-list"
                    playsInline={1}
                    muted={!this.state.play}
                  >
                    <source src={image} id="video_here" />
                    Your browser does not support HTML5 video.
                  </video>
                </Link>
              </Pane>
            )}
          </Pane>
          <Pane className="adds-content">
            {extImage.indexOf(ext) <= 0 && (
              <Pane>
                <h5 className="adds-norm" title={name}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                    '/assets/images/icons/view.png'
                    }
                    alt="image"
                    aria-hidden
                    className="icon mr-1 ml-1 mb-1"
                    style={{
                      cursor: 'pointer',
                      height: 21,
                      width: 21
                    }}
                  />
                  {viewVideo} views
                  <img
                    src={
                      process.env.PUBLIC_URL +
                    '/assets/images/icons/like-gray.svg'
                    }
                    alt="image"
                    aria-hidden
                    className="icon mr-1 ml-1 mb-1"
                    style={{
                      cursor: 'pointer',
                      height: 16,
                      width: 16
                    }}
                  />
                  {likeVideo} likes
                </h5>
              </Pane>
            )}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
