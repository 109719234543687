import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import EditDigitalForm from './EditDigitalForm';
import { getMyDigitalDetail, editMyDigital } from 'actions/digitals';
import { formatRoute } from 'shared/helpers';
import { Breadcumb } from '../../common/breadcumb';

class EditScreen extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  componentDidMount() {
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    const { match: { params }, userInfo, getMyDigitalDetail, editMyDigital } = this.props;
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/digital-slideshow`,
        name: 'Digital Screens'
      },
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/digital-slideshow/screen/${params.screenId}/edit`,
        name: 'Digital screen detail'
      },
      {
        url: ``, name: 'Edit digital display', active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="d-flex justify-content-between align-items-center mb-4" id="anchor">
        </Pane>
        <EditDigitalForm
          id={params.digitalId}
          userInfo={userInfo}
          screenId={params.screenId}
          type={params.type}
          getMyDigitalDetail={getMyDigitalDetail}
          editMyDigital={editMyDigital}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.productCategories,
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  editMyDigital: bindActionCreators(editMyDigital, dispatch),
  getMyDigitalDetail: bindActionCreators(getMyDigitalDetail, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(EditScreen);
