import queryString from 'query-string'

const checkParams = (p, params) => {
  const qp = queryString.parse(params)
  let url = ''

  delete qp.page

  Object.keys(qp) && Object.keys(qp).forEach(key => {
    url += p !== key ? `&${key}=${qp[key]}` : ``
  })

  return p !== 'page' ? `${url}&page=1` : url
}

export default checkParams
