import PropTypes from 'prop-types';
import { get } from 'lodash';
import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';

const MenuItem = ({ text, selected }) => {
  return <div className={`menu-item ${selected ? 'active' : ''}`}>{text}</div>;
};

export const Menu = (list, selected) =>
  list.map((el) => (
    <MenuItem
      text={get(el, 'name')}
      key={get(el, 'id')}
      selected={selected}
    />
  ));

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
  text: (
    <img
      src={process.env.PUBLIC_URL + '/assets/images/icons/prev-tab.svg'}
      className="image-logo"
      alt="list-view"
    />
  ),
  className: 'arrow-prev',
});
const ArrowRight = Arrow({
  text: (
    <img
      src={process.env.PUBLIC_URL + '/assets/images/icons/next-tab.svg'}
      className="image-logo"
      alt="list-view"
    />
  ),
  className: 'arrow-next',
});
class ScrollMenuCustome extends Component {
  render() {
    const { items, selected } = this.props;
    return (
      <ScrollMenu
        data={Menu(items, selected)}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={selected}
        onSelect={this.props.onSelect}
        translate={0}
        alignCenter={false}
        wheel={false}
      />
    );
  }
}

ScrollMenuCustome.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ScrollMenuCustome;
