import { get } from 'lodash';
import Config from 'constants/Config';
import { formatRoute, formatJson } from 'shared/helpers';

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const id = params.id || '';
  const category_id = params.category_id || '';
  const images = params.images === null ? null : '';
  const country = params && params.country || 'aus'

  return {
    method: 'GET',
    url: `/services?user_id=${id}&images.id=${images}&country=${country}&category_id=${category_id}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const services = [];

  if (response.data) {
    response.data.forEach((item) => {
      const service = formatJson(response, item.type, item.id);
      if (!service.user) {
        return;
      }
      let promotion = {};

      if (service.promotion) {
        promotion = {
          id: service.promotion.id,
          percent: Number(service.promotion.percent),
          startDate: service.promotion.startDate,
          endDate: service.promotion.endDate,
          primaryImage:
            service.promotion.primaryImage &&
            service.promotion.primaryImage.url,
        };
      }

      services.push({
        id: service.id,
        name: service.name,
        slug: `${formatRoute(service.name, '-')}-${service.id}`,
        category_id: get(service, 'category.id'),
        category: get(service, 'category'),
        primaryImage: service.primaryImage && service.primaryImage.url,
        rating: service.statistic && service.statistic.rating,
        userId: service.user.id,
        originalPrice: Number(service.price),
        promotionPrice: Number(service.promotionPrice),
        promotion,
        type: Config.SERVICE_TYPE,
        description: service.description,
      });
    });
  }

  const data = services;

  return data;
};

export { exec, parseResponse };
