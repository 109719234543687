import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const PromotionLabel = ({ promotion }) => {
  const currentDate = moment(
    moment().format('DD/MM/YYYY'),
    'DD/MM/YYYY'
  ).unix();
  const startDate = moment(
    moment(promotion.startDate).format('DD/MM/YYYY'),
    'DD/MM/YYYY'
  ).unix();
  const endDate = moment(
    moment(promotion.endDate).format('DD/MM/YYYY'),
    'DD/MM/YYYY'
  ).unix();

  return (
    <>
      {promotion && promotion.id ? (
        currentDate >= startDate && currentDate <= endDate ? (
          <img
            src={process.env.PUBLIC_URL + `/assets/images/shopnow/gift.svg`}
            alt="gift"
            className="icon-gift"
          />
        ) : null
      ) : null}
    </>
  );
};

PromotionLabel.propTypes = {
  promotion: PropTypes.object,
};

PromotionLabel.defaultProps = {
  promotion: {},
};

export default PromotionLabel;
