import React, { Component } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { Radio } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class TradingHourItem extends Component {
  static propTypes = {
    intl: PropTypes.func,
  };
  constructor(props, context) {
    super(props, context);

    this.state = {
      from: '',
      to: '',
      off: false,
    };
  }

  onChange = (name, value) => {
    this.setState({
      [name]: value,
    });
    this.props.onChangeHours(
      this.props.textMonth,
      name,
      value !== '' ? moment(value).format('HH:mm') : ''
    );
  };

  formatTime = (name, value) => {
    const number = parseInt(value, 10);
    if (value !== '' && typeof value === 'string') {
      const time =
        number && number <= 23
          ? moment.utc(value * 3600 * 1000).format('HH:mm')
          : moment.utc(0).format('HH:mm');
      this.setState(
        {
          [name]: time,
        },
        () => {
          this.props.onChangeHours(this.props.textMonth, name, time);
        }
      );
    }
  };

  componentDidMount() {
    this.setState({
      from: this.props.from,
      to: this.props.to,
      off: this.props.off,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isApplyForAll !== prevProps.isApplyForAll) {
      this.setState({
        from: this.props.from,
        to: this.props.to,
        off: this.props.off
      })
    }
  }

  toggle = () => {
    this.setState({
      off: !this.state.off,
    });
    this.props.onChangeToggle(this.props.textMonth, 'off', !this.state.off);
  };

  render() {
    return (
      <Pane className="row trading-hour-item pb10">
        <Pane className="col-sm-1">
          <label>{this.props.textMonth}</label>
        </Pane>
        <Pane className="col-sm-3">
          <label>
            <FormattedMessage id="from" defaultMessage="From" />
          </label>
          <Datetime
            closeOnSelect={true}
            dateFormat={false}
            timeFormat="HH:mm"
            onChange={this.onChange.bind(this, 'from')}
            inputProps={{ placeholder: 'HH:mm', name: 'From', disabled: this.state.off }}
            value={this.state.from}
            onBlur={this.formatTime.bind(this, 'from')}
          />
        </Pane>
        <Pane className="col-sm-3">
          <label>
            <FormattedMessage id="to" defaultMessage="To" />
          </label>
          <Datetime
            closeOnSelect={true}
            dateFormat={false}
            timeFormat="HH:mm"
            onChange={this.onChange.bind(this, 'to')}
            inputProps={{ placeholder: 'HH:mm', name: 'To', disabled: this.state.off }}
            value={this.state.to}
            onBlur={this.formatTime.bind(this, 'to')}
          />
        </Pane>
        <Pane className="col-sm-3">
          <label>
            <FormattedMessage id="off" defaultMessage="" />
          </label>
          <Pane className="rdt">
            <Radio
              // label='Close'
              toggle
              onChange={this.toggle}
              checked={this.state.off}
            />
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default TradingHourItem;
