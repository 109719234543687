import * as types from 'constants/ActionTypes'
import api from 'api'

// Products
export const getProductPicksByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_PRODUCTS, types.RECEIVE_MY_PRODUCTS_SUCCESS, types.RECEIVE_MY_PRODUCTS_FAILURE],
  payload: {
    request: api.user.productPicks(params)
  }
})

// Create a product
export const createMyProductPick = (params) => (dispatch) => dispatch({
  type: types.CREATE_MY_PRODUCT,
  payload: {
    request: api.user.createProductPick(params)
  }
})

// Get product detail
export const getMyProductPicksDetail = (params) => (dispatch) => dispatch({
  type: types.MY_PRODUCT_DETAIL,
  payload: {
    request: api.user.readPicks(params)
  }
})

// Edit product detail
export const editMyProduct = (params) => (dispatch) => dispatch({
  type: types.EDIT_MY_PRODUCT,
  payload: {
    request: api.user.editPicks(params)
  }
})

// Edit product detail
export const deleteMyProduct = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_PRODUCT,
  payload: {
    request: api.user.deleteProduct(params)
  }
})
