import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { MasterLayout } from 'components/layout';
import { signin, signinSocial } from 'actions/auth';
import { updateReferenceCode } from 'actions/user';
import { Pane } from 'shared/components/layouts';
import { Link } from 'react-router-dom';
import { formatRoute } from 'shared/helpers';

class ReferencePage extends React.Component {
  static propTypes = {
    updateReferenceCode: PropTypes.func
  }
  constructor(props, context) {
    super(props, context);


  }
  async componentDidMount() {
    const {auth} = this.props;
    if(auth.isAuthenticated){

      const data = new FormData();
      data.append("code", queryString.parse(this.props.history.location.search).code || '');
      data.append("id", auth.user.id);
      await this.props
      .updateReferenceCode(data)
        .then((res) => {
            this.props.history.push(
              //`/u/${formatRoute(this.props.auth.user.displayName)}`
              `/u/${formatRoute(this.props.auth.user.displayName)}/settings`
            );
        });
    } else {
    }
  }
  render() {
    const redirect = queryString.parse(this.props.location.search).redirect;
    const {
      auth,
      history: { location },
    } = this.props;
    return (
      <MasterLayout>
        <Pane className="login-container">
          <Pane className="login-more" />
          <Pane className="wrap-login d-flex align-items-center justify-content-center flex-column">
            <h3 className="login-title">Sign In  With Reference Code </h3>
            <Pane className="login-form row mb-6 ">
                <Pane className="col-md-12 text-center">
                  <Link to={`/signin${location.search}`} className="ui button btn btn-circle btn-form login-btn-cs">
                    <strong>Sign in</strong>
                  </Link>
                </Pane>
                <Pane className="col-md-12 text-center">
                  <p className="text-registered">Not Registerd?&nbsp;<a className="link-registered" href={`/quick-registration${location.search}`}><strong>Sign Up</strong></a></p>
                </Pane>

            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  signin: bindActionCreators(signin, dispatch),
  updateReferenceCode: bindActionCreators(updateReferenceCode, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(ReferencePage);
