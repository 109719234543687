import { get } from "lodash";
import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Pane } from "shared/components/layouts";
import ScrollAnimation from "react-animate-on-scroll";
import { FormattedMessage, injectIntl } from "react-intl";

class Banner extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: "",
    };
  }

  componentDidMount() {
    this.props.getStatisticUser();
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.history.push(`/signup?email=${this.state.email}`);
  };

  render() {
    const { intl, statistics } = this.props;
    return (
      <ScrollAnimation duration={2} animateOnce={true} animateIn="fadeIn">
        <Pane className="banner-container">
          <Pane className="banner-content text-center">
            <h2 className="banner-title">
              <FormattedMessage
                id="sell_online_with_cardbey"
                defaultMessage="Sell online with Cardbey"
              />
            </h2>
            <p className="banner-norm">
              <FormattedMessage
                id="the_simpler_way_to_build_your_online"
                defaultMessage="The simpler way to build your online store for free, forever!"
              />
            </p>
            <form className="banner-form mt-3" onSubmit={this.onSubmit}>
              <input
                type="text"
                placeholder={intl.formatMessage({
                  id: "enter_your_email_address",
                })}
                name="email"
                onChange={this.onChange}
                className="form-input mt-2 mb-2"
              />
              <button
                type="submit"
                className="btn btn-circle btn-circle-primary ml-2 mt-2 mb-2"
              >
                <FormattedMessage
                  id="get_started_for_free"
                  defaultMessage="Get started for free"
                />
              </button>
            </form>
            <p className="banner-norm mt-3">
              {get(statistics, "results.users", 0)} Users &{" "}
              {get(statistics, "results.bussinessPartner", 0)} Business Partner
            </p>
            <button
              className="btn btn-circle btn-learn-more mt10"
              onClick={() => this.props.history.push(`/`)}
            >
              <FormattedMessage
                id="go_to_stores"
                defaultMessage="Go to stores"
              />
            </button>
          </Pane>
          <Pane className="banner-footer text-center">
            <span className="banner-description">
              <FormattedMessage
                id="try_cardbey_at_no_risk"
                defaultMessage="Try Cardbey at no risk, no credit card required. By entering your email, you agree to receive marketing emails from Cardbey."
              />
            </span>
          </Pane>
        </Pane>
      </ScrollAnimation>
    );
  }
}

export default injectIntl(withRouter(Banner));
