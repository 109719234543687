import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import ListItem from './ListItem';
import { get, isEmpty } from 'lodash';
import Config from "constants/Config";

class ProductList extends Component {
  static propTypes = {
    auth: PropTypes.object,
    products: PropTypes.array,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
  };

  onGetImageCategory = (data) => {
    if (!isEmpty(data)) {
      return get(data[0], 'category.primaryImage.url');
    }
    return null;
  };

  render() {
    const list =
      this.props.products &&
      this.props.products.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.name}
            description= {item.description}
            index={index}
            image={
              this.props.type === 'bussiness'
                ? item.profileImage
                : item.primaryImage
            }
            price={item.originalPrice}
            rating={item.rating}
            onAddItemToCart={this.props.onAddItemToCart}
            onAddItemToGuestCart={this.props.onAddItemToGuestCart}
            onNotification={this.props.onNotification}
            modeView={this.props.modeView}
            auth={this.props.auth}
            type={this.props.type}
            cartItems={this.props.cartItems || null}
            cartModal={this.props.cartModal}
          />
        );
      });
      if (this.props.modeView === Config.MODE_VIEW_MENU.MODE_LIST) {
        return (
            <Pane className="adds-block block-menu-image-list-view">
              <Pane className="row pt-lg-3 pb-lg-3 align-items-center">
                <Pane className="col-lg-9 col-md-9 col-sm-12">
                  <Pane className="row">{list}</Pane>
                </Pane>
                <Pane className="col-lg-3 col-md-3 d-none d-lg-block d-sm-none d-md-block background-menu" style={{
                      backgroundImage: `url(`+ this.onGetImageCategory(this.props.products) || process.env.PUBLIC_URL + `/assets/images/default-image.png` +`)`
                    }}>
                </Pane>
              </Pane>
            </Pane>
        );
      }
    return (
        <Pane className="adds-block">
          <Pane className="row pb-0 pl-lg-2 pl-md-2 pr-lg-2 pr-md-2 pt-lg-2 pt-md-2 pl-sm-0 pr-sm-0 pt-sm-0">{list}</Pane>
        </Pane>
      
    );
  }
}

ProductList.propTypes = {
  type: PropTypes.string,
};

ProductList.defaultProps = {
  type: '',
};

export default ProductList;
