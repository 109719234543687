import * as types from "constants/ActionTypes";
import UserAdapter from "services/users/adapter";
import normalize from "json-api-normalizer";

const initialState = {
  results: [],
  schema: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false,
};

const myTasks = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_TASKS:
      return Object.assign({}, state, {
        ...state,
        fetching: true,
        fetched: false,
      });
    case types.RECEIVE_MY_TASKS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.taskBrowser.parseResponse(action.payload.data),
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true,
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_MY_TASKS_FAILURE:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: action.error,
        fetching: false,
        fetched: true,
      });
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false,
      });
    default:
      return state;
  }
};

export { myTasks };