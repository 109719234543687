import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import ListItem from './ListItem';

class List extends Component {
  static propTypes = {
    data: PropTypes.array,
    type: PropTypes.string,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    onReviewsVideo: PropTypes.func,
    onGetLikesVideo: PropTypes.func,
  };

  render() {
    const list =
      this.props.data &&
      this.props.data.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.title}
            viewVideo={item.viewVideo}
            likeVideo={item.likeVideo}
            like={this.props.like}
            image={get(item, 'video.url')}
            index={index}
            control={true}
            onChooseVideo={this.props.onChooseVideo}
            onReviewsVideo={this.props.onReviewsVideo}
            onGetLikesVideo={this.props.onGetLikesVideo}
          />
        );
      });
    return (
      <>
        <Pane className="adds-block adds-block-video mt-0">
          <Pane className="row pt-3 pb-3">{list}</Pane>
        </Pane>
      </>
    );
  }
}

export default List;
