import React from "react";

const StockLabel = ({ inStock, type }) => {
  let label = "";

  inStock = inStock === 1 ? true : false;

  switch (type) {
    case "menus":
    case "products":
      label = inStock ? "In Stock" : "Out Of Stock";
      break;
    case "services":
      label = inStock ? "Available" : "Unavailable";
      break;
    default:
      return <></>;
  }

  let html = <div className="in-stock">{label}</div>;
  if (!inStock) html = <div className="out-stock">{label}</div>;

  return <>{html}</>;
};

StockLabel.defaultProps = {
  inStock: 0,
};

export default StockLabel;
