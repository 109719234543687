import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { formatDecimal } from 'shared/helpers';
import { Link } from 'react-router-dom';

class BookedServiceItem extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  state = {
    data: this.props.data || {},
  };

  render() {
    const { data } = this.props;

    let duration = data.duration * 60;
    return (
      <>
        <Pane className="order-item">
          <Pane className="booking-item__body">
            <Pane className="booking-info">
              <Image src={data.primaryImage} size="tiny" />
              <Pane>
                <Pane className="order-item__price">
                  AUD ${formatDecimal(data.total)}
                </Pane>
                <span className="text-gray">
                  <span className={`badge badge-${data.status.toLowerCase()}`}>
                    <FormattedMessage
                      id={`order.${data.status.toLowerCase()}`}
                      defaultMessage={data.status}
                    />
                  </span>
                </span>
              </Pane>
            </Pane>
            <Pane className="booking-time__from">
            <Link to={`/services/${data.serviceId}`} >
              {data.serviceName}
            </Link>
            </Pane>
            <Pane className="booking-time__to">
              {duration}min duration
            </Pane>
          </Pane>
        </Pane>
      </>
    );
  }
}

export default BookedServiceItem;
