import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Pane, Loading } from 'shared/components/layouts';
import { Button, Form, Select, Radio, Icon, Image as ImageS } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import { formatRoute, handleError } from 'shared/helpers';
import { List } from '../../list';
import { DigitalShare } from '../../share';
import { LoadingSearch } from 'components/common/loading';
import NotificationSystem from 'react-notification-system';
import UserAdapter from "services/users/adapter";
import InlineMessage from "shared/components/InlineMessage";
import { getDigitalsByUserId, deleteMyProduct } from 'actions/digitals';
import { getMyScreenDetail, deleteMyScreen, editMyScreen } from 'actions/screens';
import { getNotisByUser } from 'actions/notis';
import { RangeSlider } from '../../../common/range-slider';
import ReactTooltip from 'react-tooltip';
import Datetime from 'react-datetime';
import { Breadcumb } from '../../../common/breadcumb';
import moment from 'moment';
class EditScreen extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    match: PropTypes.object,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = this.props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      types: [
        {
          text: "Horizontal",
          value: "Horizontal",
        },
        {
          text: "Vertical",
          value: "Vertical",
        },
      ],
      data: {
        name: "",
        type: "",
        duration: 0,
        activate: false,
        slot: 0,
        share_slot: 0,
        remain_slot: 0,
        from:'00:00',
        to:'00:00',
        price: 0,
        percent_share: 0,
      },
      imageFiles: [],
      images: [],
      typeScreen: "",
      errors: {},
      fetching: false,
      fetched: false,
      loading: false,
      loadingEdit: false,

    };
    this._notificationSystem = null;
  }




  onChangeType = (e, value) => {
    this.setState({ data: { ...this.state.data, type: value } });
  };

  onChange = (e) => {
    let isUpdate = false;
    let slot = 0;
    let remain_slot = 0;
    let share_slot = 0;
    //let count_items = this.props.digitals && this.props.digitals.results.length > 0 ? this.props.digitals.results.length : 1;

    if (e.target.name == 'duration') {
      isUpdate = true;
      slot = e.target.value * 3600 / 5;
    }
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    }, (e) => {
      if (isUpdate) {
        share_slot = slot * this.state.data.percent_share / 100;
        remain_slot =  slot - share_slot;
        this.setState({
          data: { ...this.state.data, 'slot': slot, 'remain_slot': remain_slot, 'share_slot': share_slot },
        });
      }
    });
  };

  handleChange = (e) => {
    let share_slot = this.state.data.slot * e.target.value / 100;
    let remain_slot = this.state.data.slot - share_slot;
    this.setState({
      data: { ...this.state.data, 'percent_share': e.target.value },
    }, () => {
      this.setState({
        data: { ...this.state.data, 'share_slot': share_slot, 'remain_slot': remain_slot }
      });
    })
  }


  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {

          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images[0] = (reader.result);
            imageFiles[0] = (files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);

      });
    }
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  getDigitalsByUserId = (param) => {
    const { userInfo, match: { params } } = this.props;
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props
      .getDigitalsByUserId(
        {
          id: userInfo.id,
          size: 100,
          screenId: params.screenId
        })
      .then(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      })
      .catch(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  };

  deleteItem = (params) => {
    const { intl } = this.props;

    if (this.props.digitals.results.length === 1) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }

    this.props
      .deleteMyProduct(params)
      .then(() => {
        this.getMyScreenDetail();
        this.getDigitalsByUserId({ page: this.state.page });
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });
      });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.type) {
      errors.type = intl.formatMessage({ id: "validate.require_field" });
    }

    return errors;
  };

  editScreen = () => {
    const { data, imageFiles } = this.state;
    const errors = this.validate(data);
    const { match: { params } } = this.props;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {


      this.setState({ loadingEdit: true });

      const dataForm = new FormData();

      imageFiles[0] && imageFiles[0].type && dataForm.append("primary_image", imageFiles[0]);
      imageFiles[1] && imageFiles[1].type && dataForm.append("sub_image_1", imageFiles[1]);
      imageFiles[2] && imageFiles[2].type && dataForm.append("sub_image_2", imageFiles[2]);
      imageFiles[3] && imageFiles[3].type && dataForm.append("sub_image_3", imageFiles[3]);
      imageFiles[4] && imageFiles[4].type && dataForm.append("sub_image_4", imageFiles[4]);
      imageFiles[5] && imageFiles[5].type && dataForm.append("sub_image_5", imageFiles[5]);

      dataForm.append("name", data.name);
      dataForm.append("type", data.type);
      dataForm.append("activate", data.activate ? 1 : 0);
      if (data.activate) {
        dataForm.append("duration", data.duration);
        dataForm.append("slot", data.slot);
        dataForm.append("share_slot", data.share_slot);
        dataForm.append("percent_share", data.percent_share);
        dataForm.append("price", data.price);
      }
      dataForm.append("user_id", this.props.userInfo.id || "");
      dataForm.append("id", params.screenId);

      this.props
        .editMyScreen(dataForm)
        .then(() => {
          this.getMyScreenDetail();
          this.setState({ loadingEdit: false });
          this.props.history.push(`/u/${formatRoute( this.props.userInfo.displayName )}/digital-slideshow`);

        })
        .catch((error) => {
          this.setState({ loadingEdit: false });
          const errors = {};
          errors.message = handleError(error, "Can't edit a screen");
          this.setState({ errors });
        });
    }
  };

  getMyScreenDetail() {
    const { match: { params }, userInfo } = this.props;
    const images = [];
    const imageFiles = [];

    this.setState({
      loading: true,
    });
    this.props
      .getMyScreenDetail({ id: params.screenId })
      .then((response) => {
        const item = UserAdapter.screenRead.parseResponse(response.data);
        if (item.updateRead) {
          this.props.getNotisByUser({ id: userInfo.id });
        }

        if (item.primaryImage && item.primaryImage.url !== "null") {
          images.push(item.primaryImage.url);
          imageFiles.push(item.primaryImage.url);
        }
        if (item.subImage1 && item.subImage1.url !== "null") {
          images.push(item.subImage1.url);
          imageFiles.push(item.subImage1.url);
        }
        if (item.subImage2 && item.subImage2.url !== "null") {
          images.push(item.subImage2.url);
          imageFiles.push(item.subImage2.url);
        }
        if (item.subImage3 && item.subImage3.url !== "null") {
          images.push(item.subImage3.url);
          imageFiles.push(item.subImage3.url);
        }
        if (item.subImage4 && item.subImage4.url !== "null") {
          images.push(item.subImage4.url);
          imageFiles.push(item.subImage4.url);
        }
        if (item.subImage5 && item.subImage5.url !== "null") {
          images.push(item.subImage5.url);
          imageFiles.push(item.subImage5.url);
        }
        this.setState({
          images,
          imageFiles,
          typeScreen: item.type || "",
          data: {
            ...this.state.data,
            name: item.name || "",
            type: item.type || "",
            activate: item.activate || "",
            duration: item.duration || "",
            admin_active_flg: item.adminActiveFlg,
            disable_reason: item.disableReason || '',
            slot: item.slot || "",
            percent_share: item.percentShare || "",
            share_slot: item.shareSlot || "",
            price: item.price || "",
            remain_slot: (item.slot - item.shareSlot) || "",
            id: item.id,
            url:item.url
          },
          loading: false,
        }, () => {
          this.getDigitalsByUserId();
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.getMyScreenDetail();
    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  toggle = () => {
    this.setState({
      data: { ...this.state.data, 'activate': !this.state.data.activate }
    });
  };
  copyLink = ( ) => {
    let copyText = window.location.origin + `/slideshow?screen_id=${this.state.data.url}`;
    navigator.clipboard.writeText(copyText);
  }

  render() {
    const { match: { params }, userInfo, digitals, intl } = this.props;
    const { fetched, fetching, types, loading, loadingEdit, errors, data, typeScreen } = this.state;
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/digital-slideshow`,
        name: 'Digital Screens'
      },
      {
        url: ``, name: 'Edit digital screen', active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Form className="form-overview pb-5 form-edit-screen" id="anchor" onSubmit={this.editScreen}>
          {!loading ? (
            <>
              <Pane className="form-content">
                <Pane className="row">

                  { data.admin_active_flg &&
                    <>
                      <Pane className="col-lg-12  ">
                          <label className="form-label">Preview URL</label>
                      </Pane>
                      <Pane className="col-lg-8">
                          <Form.Field className="form-item">
                            <input
                              disabled
                              type="text"
                              className="form-input"
                              value={`${window.location.protocol}://${window.location.hostname}/slideshow?screen_id=${data.url}`}

                            />

                          </Form.Field>
                        </Pane>
                        <Pane className="col-lg-4">
                          <Button type='button' className='btn btn-default btn-url'>
                            <a href={`/slideshow?screen_id=${data.url}`} target="_blank" rel="noopener noreferrer"  >Preview</a>
                          </Button>
                          <Button type='button' className='btn btn-default btn-url'><span className="cursor-pointer" data-tip data-for="copy"  onClick={this.copyLink}>
                            <img
                              src={
                                process.env.PUBLIC_URL + `/assets/images/icons/copy.svg`
                              }

                              style={{ width: '20px' }}
                              alt="copy"
                              className="icon-copy"
                            />
                            </span>
                          </Button>
                        </Pane>
                    </>
                  }
                  { !data.admin_active_flg &&
                      <Pane className="col-lg-12  ">
                          <label className="form-label error">
                            Disabled by admin &nbsp;
                            <i className="fas fa-question-circle"  data-tip
                            data-for='item-info'></i></label>
                            <ReactTooltip
                            place="top"
                            type="dark"
                            effect="float"
                            id='item-info'
                          >
                            {data.disable_reason}
                          </ReactTooltip>
                      </Pane>
                  }

                <Pane className="col-lg-12">
                        <Form.Field className="form-item">
                          <label htmlFor="Image" className="form-label">
                            <FormattedMessage id="cover_image" defaultMessage="Image" />
                          </label>
                          <input
                            type="file"
                            id="image-menu"
                            className="form-input-file"
                            multiple
                            value={data.image}
                            onChange={this.onChangeImage}
                          />
                          <br />
                          <label htmlFor="image-menu" className="form-label-file">
                            <img
                              src={
                                process.env.PUBLIC_URL + `/assets/images/icons/upload.svg`
                              }
                              alt="upload"
                              className="icon"
                            />
                            Upload a photo/video
                          </label>
                          <Pane
                            className="slider-content-main d-flex"
                            style={{ padding: "10px 0" }}
                          >
                            {this.state.images &&
                              this.state.images.map((item, index) => {
                                return (
                                  <Pane className="slide-item mr-2" key={index}>
                                    <Icon
                                      name="remove circle"
                                      onClick={this.removeImage.bind(this, index)}
                                    />
                                    <ImageS src={item} size="small" />
                                  </Pane>
                                );
                              })}
                          </Pane>
                        </Form.Field>
                      </Pane>
                  <Pane className="col-lg-12">
                    <Form.Field error={!!errors.name} className="form-item">
                      <label htmlFor="name" className="form-label">
                        <FormattedMessage id="name" defaultMessage="name" />
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder=""
                        className="form-input"
                        value={data.name}
                        onChange={this.onChange}
                      />
                      {errors.name && <InlineMessage text={errors.name} />}
                    </Form.Field>
                  </Pane>
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="name" className="form-label">
                        <FormattedMessage
                          id="option_group_type"
                          defaultMessage="Name"
                        />
                      </label>
                      <Select
                        value={data.type}
                        options={types}
                        name="type"
                        onChange={(e, { value }) => this.onChangeType(e, value)}
                      />
                      <Pane className="row mt-2 mb-2">
                        <Pane className="col-lg-12">
                          <Radio
                            label='C-net activate'
                            toggle
                            onChange={this.toggle}
                            checked={data.activate}
                          />
                        </Pane>
                      </Pane>
                    </Form.Field>
                  </Pane>
                </Pane>
                {data.activate && (
                  <>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.duration}>
                          <label htmlFor="email" className="form-label">
                            Duration(hours)
                          </label>
                          <input
                            type="text"
                            id="duration"
                            name="duration"
                            className="form-input"
                            placeholder=""
                            value={data.duration}
                            onChange={this.onChange}
                          />
                          {errors.duration && (
                            <InlineMessage text={errors.duration} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.slot}>
                          <label htmlFor="email" className="form-label">
                            {/* <FormattedMessage
                        id="business_email"
                        defaultMessage="Business email"
                      /> */}
                            Slot
                          </label>
                          <input
                            type="text"
                            id="slot"
                            name="slot"
                            className="form-input"
                            placeholder=""
                            value={data.slot}
                            onChange={this.onChange}
                            disabled
                          />
                          {errors.slot && (
                            <InlineMessage text={errors.slot} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.price}>
                          <label htmlFor="email" className="form-label">
                            {/* <FormattedMessage
                        id="business_email"
                        defaultMessage="Business email"
                      /> */}
                            Price
                          </label>
                          <input
                            type="text"
                            id="price"
                            name="price"
                            className="form-input"
                            placeholder=""
                            value={data.price}
                            onChange={this.onChange}
                          />
                          {errors.price && (
                            <InlineMessage text={errors.price} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.percent_share}>
                          <label htmlFor="email" className="form-label">
                            {/* <FormattedMessage
                          id="business_email"
                          defaultMessage="Business email"
                        /> */}
                            Percent share
                          </label>
                          <RangeSlider min={0} max={100} value={data.percent_share} handleChange={this.handleChange} />
                          {errors.percent_share && (
                            <InlineMessage text={errors.percent_share} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.share_slot}>
                          <label htmlFor="email" className="form-label">
                            {/* <FormattedMessage
                        id="business_email"
                        defaultMessage="Business email"
                      /> */}
                            Share Slot
                          </label>
                          <input
                            type="text"
                            id="slot"
                            name="slot"
                            className="form-input"
                            placeholder=""
                            value={data.share_slot}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.share_slot}>
                          <label htmlFor="email" className="form-label">
                            {/* <FormattedMessage
                        id="business_email"
                        defaultMessage="Business email"
                      /> */}
                            Remain Slot
                          </label>
                          <input
                            type="text"
                            id="slot"
                            name="slot"
                            className="form-input"
                            placeholder=""
                            value={data.remain_slot}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Field>
                      </Pane>
                    </Pane>
                  </>
                )}
              </Pane>
              <Pane className="mb-2">
          <h3 className="heading-title mt-2">
            Your Digital Slideshow
          </h3>
        </Pane>
        <Pane className="overview-container">
          <Pane className="product-container">
            <div className="product-heading justify-content-end">
              <Link
                to={`/u/${formatRoute(
                  userInfo.displayName
                )}/digital-slideshow/screen/${params.screenId}/digital-create/${typeScreen}`}
                className="btn btn-circle btn-circle-primary"
              >
                <span>Create A Slideshow</span>
              </Link>
            </div>
            {!fetching && fetched ? (
              <>
                {digitals.results.length <= 0 && (
                  <Pane className="text-center">
                    <h2 className="empty-title">No items</h2>
                  </Pane>
                )}
                {digitals.results.length ? (
                  <List
                    type={typeScreen}
                    data={digitals.results}
                    onDelete={this.deleteItem}
                    userInfo={this.props.userInfo}
                    screenId={params.screenId}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <LoadingSearch type="white" />
            )}
          </Pane>
        </Pane>
              <Pane className="row">
                <Pane className="col-lg-12 text-center">
                  <Button
                    type="submit"
                    className="btn btn-save mt-4"
                    disabled={loadingEdit}
                    loading={loadingEdit}
                  >
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </Button>
                </Pane>
              </Pane>
            </>
          ) : (
            <Pane className="pt30 position-relative">
              <Loading />
            </Pane>
          )}
        </Form>

        <Pane className="mb-2">
          <h3 className="heading-title mt-2">
            Screen Digital Share List
          </h3>
        </Pane>
        <DigitalShare onNotification={this._notificationSystem} screen_type={data.type} />
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  digitals: state.digitals,
});

const bindDispatchToProps = (dispatch) => ({
  deleteMyProduct: bindActionCreators(deleteMyProduct, dispatch),
  getDigitalsByUserId: bindActionCreators(getDigitalsByUserId, dispatch),
  getNotisByUser: bindActionCreators(getNotisByUser, dispatch),
  deleteMyScreen: bindActionCreators(deleteMyScreen, dispatch),
  editMyScreen: bindActionCreators(editMyScreen, dispatch),
  getMyScreenDetail: bindActionCreators(getMyScreenDetail, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(injectIntl(EditScreen));
