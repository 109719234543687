import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getMyDigitalShare, editMyDigitalShare, deleteMyDigitalShare } from 'actions/digitalShare';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table } from 'semantic-ui-react';
import Adapter from "services/digitalShares/adapter";
import { FormattedMessage } from 'react-intl';
import { Pane, Loading } from 'shared/components/layouts';
import ListItem from './ListItem';

class DigitalShare extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      digitals_share: []
    };
  }

  getMyDigitalShare() {
    const { match: { params } } = this.props;

    this.onLoading();
    this.props
      .getMyDigitalShare({ screen_id: params.screenId })
      .then((response) => {
        const items = Adapter.browse.parseResponse(response.data);
        if (items) this.setState({ digitals_share: items });
        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
      });
  }

  onLoading = () => {
    this.setState({ loading: true });
  }

  offLoading = () => {
    this.setState({ loading: false });
  }

  componentDidMount() {
    this.getMyDigitalShare();
  }

  render() {
    const { loading, digitals_share } = this.state;
    const list = digitals_share.map((item) => {
      return (
        <ListItem
          key={item.id}
          item={item}
          type={this.props.screen_type}
          onLoading={this.onLoading}
          offLoading={this.offLoading}
          getMyDigitalShare={() => this.getMyDigitalShare()}
          editMyDigitalShare={this.props.editMyDigitalShare}
          deleteMyDigitalShare={this.props.deleteMyDigitalShare}
        />
      );
    });

    return (
      <Pane className="table-screens-share">
        <Table className="unstackable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Screen Id
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="customer_name"
                  defaultMessage="Customer name"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                  File share
              </Table.HeaderCell>
              <Table.HeaderCell>Accept</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!loading ? (
              <>
                {digitals_share.length <= 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan="4" className="text-center">
                      No items
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <>
                    {list}
                  </>
                )}
              </>
            ) : (
              <Table.Row className="position-relative">
                <Table.Cell>
                  <Pane style={{ minHeight: '60px' }}>
                    <Loading />
                  </Pane>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Pane>
    );
  }
}


const bindDispatchToProps = (dispatch) => ({
  getMyDigitalShare: bindActionCreators(getMyDigitalShare, dispatch),
  editMyDigitalShare: bindActionCreators(editMyDigitalShare, dispatch),
  deleteMyDigitalShare: bindActionCreators(deleteMyDigitalShare, dispatch),
});

export default withRouter(connect(
  null,
  bindDispatchToProps
)(DigitalShare));