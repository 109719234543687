import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { MasterLayout } from 'components/layout'
import { getServiceDetail, getTimeSlots } from 'actions/services'
import { getServiceMembersBookingAvailable } from "actions/serviceMembers";
import { BookingService } from 'components/scenes/services/booking'
import { Pane } from 'shared/components/layouts'
import BussinessList from 'components/common/bussiness/BussinessList'

class BookingServicePage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    getServiceDetail: PropTypes.func,
    getTimeSlots: PropTypes.func
  }

  render() {
    return (
      <MasterLayout>
        <Pane className='main-container main-category pt2 pb-5'>
          <BussinessList />
          <BookingService
            id={this.props.match.params.id}
            auth={this.props.auth}
            detail={this.props.serviceDetail}
            location={this.props.location}
            serviceId={this.props.match.params.id}
            onGetServiceDetail={this.props.getServiceDetail}
            onGetTimeSlots={this.props.getTimeSlots}
            history={this.props.history}
            storeBookings={this.props.storeBookings}
            onGetServiceMembers={this.props.getServiceMembersBookingAvailable}
          />
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = state => ({
  auth: state.auth,
  serviceDetail: state.serviceDetail,
  storeBookings: state.storeBookings
})

const bindDispatchToProps = dispatch => ({
  getServiceDetail: bindActionCreators(getServiceDetail, dispatch),
  getTimeSlots: bindActionCreators(getTimeSlots, dispatch),
  getServiceMembersBookingAvailable: bindActionCreators(
    getServiceMembersBookingAvailable,
    dispatch
  ),
})

export default connect(bindStateToProps, bindDispatchToProps)(BookingServicePage)
