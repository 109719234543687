import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import moment from 'moment'
import NotificationSystem from 'react-notification-system'
import ReactTooltip from 'react-tooltip'
import { confirmAlert } from 'react-confirm-alert'
import { Pane } from 'shared/components/layouts'
import { deletePromotionProduct, deletePromotionService, getPromotionProductsByUserId, getPromotionServicesByUserId } from 'actions/myPromotions'
import { Currency } from 'shared/components'
import Config from 'constants/Config';


class PromotionItem extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    type: PropTypes.string,
    deletePromotionProduct: PropTypes.func.isRequired,
    deletePromotionService: PropTypes.func.isRequired,
    getPromotionProductsByUserId: PropTypes.func.isRequired,
    getPromotionServicesByUserId: PropTypes.func.isRequired,
    boostPromotion: PropTypes.func.isRequired,
    intl: intlShape.isRequired
  }

  state = {
    userInfo: {},
    image: '',
    data: {
      title: '',
      image: '',
      percentDiscount: '',
      description: ''
    },
    deletedMedia: false,
    startDate: '',
    endDate: '',
    errors: {},
    loading: false,
    loadingBoost: false,
    loadingDelete: false,
    play: 0,
  }

  constructor(props, context) {
    super(props, context)

    this._notificationSystem = null
  }

  changeRoute = () => {
    const name = this.props.userInfo && this.props.userInfo.displayName ? (this.props.userInfo.displayName).replace(/ /g, '_').toLowerCase() : ''

    this.props.history.push(`/u/${name}/promotions/${this.props.id}/edit`)
  }

  validate = (data) => {
    const { intl } = this.props
    const errors = {}

    if (!data.title) {
      errors.title = intl.formatMessage({ id: 'validate.require_field' })
    }
    if (!this.state.endDate) {
      errors.endDate = intl.formatMessage({ id: 'validate.require_field' })
    }
    if (!this.state.startDate) {
      errors.startDate = intl.formatMessage({ id: 'validate.require_field' })
    }

    return errors
  }

  boostPromotion = () => {
    const data = new FormData()
    const { intl } = this.props

    this.setState({ loadingBoost: true })
    data.append('Items', JSON.stringify([this.props.id]))

    this.props.boostPromotion(data).then(response => {
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: 'notification.successfully' }),
        level: 'success'
      })
      this.setState({
        loadingBoost: false
      })
    }).catch(error => {
      this._notificationSystem.addNotification({
        message: intl.formatMessage({ id: 'validate.require_field' }),
        level: 'error'
      })
      this.setState({
        loadingBoost: false
      })
    })
  }

  confirmDeletePromotion = () => {
    const { intl } = this.props

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({ id: 'notification.are_you_sure_you_want_to_delete_this' })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.deletePromotion()
          }
        },
        {
          label: intl.formatMessage({ id: 'no' })
        }
      ]
    })
  }

  deletePromotion = () => {
    const { intl } = this.props
    this.setState({ loadingDelete: true })

    if (this.props.item.type === 'products') {
      this.props.deletePromotionProduct({ id: this.props.id }).catch(() => {
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: 'notification.please_try_again' }),
          level: 'error'
        })
        this.setState({ loadingDelete: false })
      })
    } else {
      this.props.deletePromotionProduct({ id: this.props.id }).catch(() => {
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: 'notification.please_try_again' }),
          level: 'error'
        })
        this.setState({ loadingDelete: false })
      })
    }
  }

  componentDidMount() {
    this.setState({
      image: this.props.image || '',
      data: {
        title: this.props.item.productName || '',
        description: this.props.item.description || '',
        percentDiscount: this.props.item.percentDiscount || ''
      },
      startDate: moment(this.props.item.startDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      endDate: moment(this.props.item.endDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
    })

    this._notificationSystem = this.refs.notificationSystem
  }

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  }

  playVideo = (event) => {
    this.setState({
      play: 1
    });
    const video = event.currentTarget;
    // var isPlaying = video.currentTime > 0 && !video.paused;
    // if(isPlaying){
    //   video.pause();
    // }
    video.currentTime = 0;
    setTimeout((v) => {
      //this.stopVideo.apply(null,target);
      v.currentTime = 0;
      v.pause();
    }, Config.VIDEO_TIME, video);
  };


  render() {
    const currentDate = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').unix();
    const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    var ext = this.props.item.item.primaryImage != null ? this.props.item.item.primaryImage.substr(this.props.item.item.primaryImage.lastIndexOf('.') + 1) : '';

    return (
      <Pane className='col-lg-3 col-md-6 col-sm-6 col-12 pl-lg-2 pr-lg-2 pb-lg-2 pt-lg-2 pl-md-2 pr-md-2 pb-md-0 pt-md-2'>
      <Pane className='item-list make-grid'>
        {
          currentDate > moment(this.props.endDate, 'DD/MM/YYYY').unix()
            ? <Pane className='cornerRibbons expired text'><FormattedMessage id='expired' defaultMessage='Expired' /></Pane>
            : (
              this.props.percentDiscount
                ? <Pane className='cornerRibbons promotion'>{`${this.props.percentDiscount}%`}</Pane>
                : <Pane className='cornerRibbons promotion text'><FormattedMessage id='in_promotion' defaultMessage='In promotion' /></Pane>
            )
        }
        <Pane className='no-padding photobox'>
          <Pane className='add-image'>
            <Link to={`/${this.props.type}/${this.props.item.item.slug}`}>
              {/* <Pane className='list-item-image' style={{ backgroundImage: `url(${this.props.item.item.primaryImage || ''})` }}>
                { this.props.item.item.primaryImage && <img src={this.props.item.item.primaryImage} alt="" className="img-responsive" /> }
              </Pane> */}
              {
                extImage.indexOf(ext) >= 0 ?
                  <Pane className='list-item-image' style={{ backgroundImage: `url(${this.props.item.item.primaryImage || ''})` }}>
                    {this.props.item.item.primaryImage && <img src={this.props.item.item.primaryImage} alt="" className="img-responsive" />}
                  </Pane>
                  :
                  <Pane className='list-item-image'>
                    {
                      ext !== '' ? <i className="fa fa-video">
                      </i> : ''
                    }
                    <video className="carbeyvideo-list" playsInline={1}
                      onMouseOver={this.playVideo}
                      onMouseOut={this.stopVideo}
                      muted={!this.state.play}
                    >
                      <source src={this.props.item.item.primaryImage} id="video_here" />
                      Your browser does not support HTML5 video.
                    </video>
                  </Pane>
              }
            </Link>
          </Pane>
        </Pane>
        <Pane className='add-desc-box pb5'>
          <Pane className='ads-details'>
            <h5 className="add-title pb0">
              <Link to={`/${this.props.type}/${this.props.item.item.slug}`} title={this.props.name}>{this.props.name}</Link></h5>
          </Pane>
        </Pane>
        <Pane className='text-left price-box'>
          {
            this.props.percentDiscount
              ? <h2 className="item-price pb10"><span className="item_price text-block"><Currency price={this.props.promotionPrice} /> <span className="text-block text-red text-underline"><Currency price={this.props.price} /></span></span></h2>
              : <h2 className="item-price pb10"><span className="item_price"><Currency price={this.props.price} /><span className="text-block text-red">&nbsp;</span></span></h2>
          }
        </Pane>
        <Pane className='info-row'>
          <p className="font-weight-normal mb5"><i className="icon-clock" /><FormattedMessage id='start_date' defaultMessage='Start date' />: {moment(this.props.startDate).format('DD/MM/YYYY')}</p>
          <p className="font-weight-normal mb5"><i className="icon-clock" /><FormattedMessage id='end_date' defaultMessage='End date' />: {moment(this.props.endDate).format('DD/MM/YYYY')}</p>
        </Pane>
        <Pane className='text-left price-box pt10'>
          <Pane className='button-edit-item'>
            <Button size='mini' color='yellow' icon='lightning' onClick={this.boostPromotion} disabled={this.state.loadingBoost} loading={this.state.loadingBoost} data-tip data-for='boost' />
            <Button size='mini' color='yellow' icon='write' onClick={this.changeRoute} data-tip data-for='edit' />
            <Button size='mini' color='yellow' icon='delete' onClick={this.confirmDeletePromotion} disabled={this.state.loadingDelete} loading={this.state.loadingDelete} data-tip data-for='delete' />
          </Pane>
        </Pane>
        <ReactTooltip place='top' type='dark' effect='solid' id='boost'>
          <FormattedMessage id='boost' defaultMessage='Boost' />
        </ReactTooltip>
        <ReactTooltip place='top' type='dark' effect='solid' id='edit'>
          <FormattedMessage id='edit' defaultMessage='Edit' />
        </ReactTooltip>
        <ReactTooltip place='top' type='dark' effect='solid' id='delete'>
          <FormattedMessage id='delete' defaultMessage='Delete' />
        </ReactTooltip>
        <NotificationSystem ref='notificationSystem' />
      </Pane>
      </Pane>
    )
  }
}

const bindDispatchToProps = dispatch => ({
  deletePromotionProduct: bindActionCreators(deletePromotionProduct, dispatch),
  deletePromotionService: bindActionCreators(deletePromotionService, dispatch),
  getPromotionProductsByUserId: bindActionCreators(getPromotionProductsByUserId, dispatch),
  getPromotionServicesByUserId: bindActionCreators(getPromotionServicesByUserId, dispatch)
})

export default connect(null, bindDispatchToProps)(injectIntl(PromotionItem))
