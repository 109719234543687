import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Dimmer, Select } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import ReactPaginate from 'react-paginate';
import { isEmpty } from 'lodash';
import { Pane, Loading } from 'shared/components/layouts';
import { getSellingOrders } from 'actions/orders';
import SellingOrderList from './seller/SellingOrderList';
import Config from "constants/Config";

class OrderList extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    getSellingOrders: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loadingSelling: false,
      method: 'DINE_IN',
      methods: [
        {
          text: "DINE_IN",
          value: "DINE_IN",
        },
        {
          text: "TAKE_AWAY",
          value: "TAKE_AWAY",
        }
      ]
    };
  }

  getSellingOrders = (params, load) => {
    this.setState({
      loadingSelling: true,
    });
    this.props
      .getSellingOrders(params, load)
      .then(() => {
        this.setState({
          loadingSelling: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingSelling: false,
        });
      });
  };

  handleSellingPageClick = (data) => {
    const params = {
      page: data.selected + 1,
      id: this.props.userInfo.id,
      method: this.state.method,
      user_id: Config.ID_ADMIN
    };

    this.getSellingOrders(params, true);
  };

  componentDidMount() {
    const params = {
      page: 1,
      id: this.props.userInfo.id,
      method: this.state.method,
      user_id: Config.ID_ADMIN
    };

    this.getSellingOrders(params);
  }

  filterDeliveryMethod = (e, value) => {
    this.setState({
      method: value,
    }, () => {
      const params = {
        page: 1,
        id: this.props.userInfo.id,
        method: this.state.method,
        user_id: Config.ID_ADMIN
      };

      this.getSellingOrders(params);
    });
  }

  render() {
    const { sellingOrders, userInfo } = this.props;
    const { loadingSelling, method, methods } = this.state;
    return (
      <Pane className="overview-container">
        <Pane className="product-container order-container">
          <Pane className="product-container order-container">
            <h3 className="block-title pt10 text-uppercase d-flex justify-content-between">
              <span>{`${method} ORDERS`}</span>
              <Pane>
                <Select
                  className="text-xs"
                  placeholder='Select delivery method'
                  value={method}
                  options={methods}
                  onChange={(e, { value }) => this.filterDeliveryMethod(e, value)}
                />
              </Pane>
            </h3>
            <Pane className="products-grids table-orders">
              {loadingSelling && (
                <Dimmer active inverted>
                  <Loading />
                </Dimmer>
              )}
              {!loadingSelling && (
                <SellingOrderList viewOrderDetail={this.props.viewOrderDetail} method={method} userInfo={userInfo} orders={sellingOrders} />
              )}
              {!loadingSelling &&
                isEmpty(sellingOrders.results) && (
                  <Pane className="block-no-content">
                    <p>
                      <FormattedMessage
                        id="notification.you_have_not_had_any_orders_yet"
                        defaultMessage={`You haven't had any orders yet.`}
                      />
                    </p>
                  </Pane>
                )}
              <Pane className="clearfix" />
            </Pane>
            <Pane className="pagination-bar text-center">
              <nav className="d-inline-b">
                {sellingOrders.totalPages > 1 ? (
                  <ReactPaginate
                    previousLabel="❮"
                    nextLabel="❯"
                    breakLabel={<button className="btn-break-paging">...</button>}
                    breakClassName={'break-me'}
                    previousClassName={'page-item'}
                    nextClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    pageCount={sellingOrders.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handleSellingPageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                ) : null}
              </nav>
            </Pane>
          </Pane>
        </Pane>

      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  sellingOrders: state.sellingOrders,
});

const bindDispatchToProps = (dispatch) => ({
  getSellingOrders: bindActionCreators(getSellingOrders, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(OrderList);
