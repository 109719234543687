import * as types from 'constants/ActionTypes'
import api from 'api'

// Promotion product
export const getPromotionProductsByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_PROMOTION_PRODUCTS, types.RECEIVE_PROMOTION_PRODUCTS_SUCCESS, types.RECEIVE_PROMOTION_PRODUCTS_FAILURE],
  payload: {
    request: api.user.promotionProducts(params)
  }
})

export const deletePromotionProduct = (params) => (dispatch) => dispatch({
  type: types.DELETE_PROMOTION_PRODUCTS,
  params,
  payload: {
    request: api.user.deletePromotions(params)
  }
})

// Promotion service
export const getPromotionServicesByUserId = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_PROMOTION_SERVICES, types.RECEIVE_PROMOTION_SERVICES_SUCCESS, types.RECEIVE_PROMOTION_SERVICES_FAILURE],
  payload: {
    request: api.user.promotionServices(params)
  }
})

export const deletePromotionService = (params) => (dispatch) => dispatch({
  type: types.DELETE_PROMOTION_SERVICES,
  params,
  payload: {
    request: api.user.deletePromotions(params)
  }
})

// Create promotion
export const createPromotion = (params) => (dispatch) => dispatch({
  type: types.CREATE_PROMOTION,
  payload: {
    request: api.user.createPromotion(params)
  }
})

// Get promotion detail
export const getPromotionDetail = (params) => (dispatch) => dispatch({
  type: types.PROMOTION_DETAIL,
  payload: {
    request: api.user.promotionDetail(params)
  }
})

// Edit promotion
export const editPromotion = (params) => (dispatch) => dispatch({
  type: types.EDIT_PROMOTION,
  payload: {
    request: api.user.editPromotion(params)
  }
})

// Boost promotion
export const boostPromotion = (params) => (dispatch) => dispatch({
  type: types.BOOST_PROMOTION,
  payload: {
    request: api.user.boostPromotions(params)
  }
})
