import * as types from 'constants/ActionTypes';
import api from 'api';

// Edit screen detail
export const deleteMyDigitalShare = (params) => (dispatch) => dispatch({
  type: types.DELETE_MY_SCREEN_SHARE,
  payload: {
    request: api.digitalShare.deleteDigitalShare(params)
  }
});

// Create a digital
export const createMyDigitalShare = (params) => (dispatch) => dispatch({
  type: types.CREATE_MY_SCREEN_SHARE,
  payload: {
    request: api.digitalShare.createDigitalShare(params)
  }
});

export const editMyDigitalShare = (params) => (dispatch) =>
  dispatch({
    type: types.EDIT_MY_DIGITAL_SHARE,
    payload: {
      request: api.digitalShare.editDigitalShare(params),
    },
  });

// Get screen detail
export const getMyDigitalShareDetail = (params) => (dispatch) => dispatch({
  type: types.MY_SCREEN_SHARE_DETAIL,
  payload: {
    request: api.digitalShare.screenRead(params)
  }
});

// get digital detail
export const getMyDigitalShare = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_MY_SCREENS_SHARE,
  payload: {
    request: api.digitalShare.get(params)
  }
});

// get digitals share
export const getDigitalsShareByUserId = (params) => (dispatch) => dispatch({
  type: types.RECEIVE_DIGITALS_SHARE,
  payload: {
    request: api.user.digitalsShare(params)
  }
});