import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';

class Explore extends Component {
  render() {
    return (
      <Pane className="explore-container">
        <Pane className="container">
          <ScrollAnimation duration={3} animateOnce={true} animateIn="fadeInUp">
            <Pane className="explore-content text-center">
              <h3 className="explore-title pr-lg-5 pl-lg-5 pr-0 pl-0">
                <FormattedMessage
                  id="cardbey_is_the_global_marketplace_with_local_support"
                  defaultMessage="Cardbey is the new global marketplace with local support"
                />
              </h3>
              <p className="explore-norm">
                <FormattedMessage
                  id="cardbey_is_the_new_global_platform_tailored_for_regions"
                  defaultMessage="Cardbey is the new global platform tailored for regions..."
                />
              </p>
              <button className="btn btn-circle btn-circle-primary mt-2 mb-2">
                EXPLORE AT CARDBEY.COM
              </button>
            </Pane>
          </ScrollAnimation>
        </Pane>
      </Pane>
    );
  }
}

export default Explore;
