import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Form, Select, Image, Icon } from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import InlineMessage from "shared/components/InlineMessage";
import { handleError } from "shared/helpers";
import queryString from "query-string";

class CreateCategoryForm extends Component {
  static propTypes = {
    products: PropTypes.object,
    categories: PropTypes.object.isRequired,
    getMyProducts: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  state = {
    images: [],
    imageFiles: [],
    data: {
      name: "",
      group: "",
      sort: "",
    },
    errors: {},
    categories: [],
    open: false,
    loading: false,
    group: queryString.parse(this.props.history.location.search).group || "",
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.images.length < 6 &&
          this.state.images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  handleCategory = () => {
    let categories = [];
    if (this.state.group === "") {
      categories = [
        {
          text: "Menu",
          value: "Menu",
        },
        {
          text: "Service",
          value: "Service",
        },
      ];
    } else if (this.state.group === "menu") {
      categories = [
        {
          text: "Menu",
          value: "Menu",
        },
      ];
    } else {
      categories = [
        {
          text: "Service",
          value: "Service",
        },
      ];
    }
    this.setState({
      categories,
    });
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.group) {
      errors.group = intl.formatMessage({ id: "validate.require_field" });
    }

    return errors;
  };

  createCategory = () => {
    const { userInfo } = this.props;
    const errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new FormData();
      if (this.state.imageFiles[0]) {
        data.append("primary_image", this.state.imageFiles[0]);
      }
      data.append("name", this.state.data.name);
      data.append("group", this.state.data.group);
      data.append("sort", this.state.data.sort);
      data.append("user_id", userInfo.id);
      data.append("sub_image_1", this.state.imageFiles[1] || "");
      data.append("sub_image_2", this.state.imageFiles[2] || "");
      data.append("sub_image_3", this.state.imageFiles[3] || "");
      data.append("sub_image_4", this.state.imageFiles[4] || "");
      data.append("sub_image_5", this.state.imageFiles[5] || "");

      this.props
        .createCategory(data)
        .then((response) => {
          this.setState({
            loading: false,
            open: false,
            data: {
              ...this.state.data,
            },
          });
          // this.props.getMyProducts()
          this.props.history.goBack();
        })
        .catch((error) => {
          const errors = {};
          errors.message = handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );

          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCategory(nextProps.categories);
  }

  render() {
    const { errors, data, loading } = this.state;
    const { intl } = this.props;

    return (
      <Form onSubmit={this.createCategory}>
        <Form.Field className="form-group">
          <label htmlFor="image">
            <FormattedMessage id="image" defaultMessage="Image" />
          </label>
          <input
            type="file"
            id="image"
            name="image"
            placeholder=""
            className="form-control"
            multiple
            value={data.image}
            onChange={this.onChangeImage}
          />
          <Pane className="slider-content-main" style={{ padding: "10px 0" }}>
            {this.state.images &&
              this.state.images.map((item, index) => {
                return (
                  <Pane
                    className="slide-item"
                    key={index}
                    style={{ float: "left" }}
                  >
                    <Icon
                      name="remove circle"
                      onClick={this.removeImage.bind(this, index)}
                    />
                    <Image src={item} size="small" />
                  </Pane>
                );
              })}
          </Pane>
        </Form.Field>
        <Pane className="clearfix" />
        <Form.Field error={!!errors.name} className="form-group">
          <label htmlFor="name">
            <FormattedMessage id="name" defaultMessage="Name" />
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder=""
            className="form-control"
            value={data.name}
            onChange={this.onChange}
          />
          {errors.name && <InlineMessage text={errors.name} />}
        </Form.Field>
        {
          <Form.Field
            control={Select}
            scrolling
            className="form-group"
            name="group"
            error={!!errors.group}
            label={intl.formatMessage({ id: "group", defaultMessage: "Group" })}
            value={data.group}
            options={this.state.categories}
            placeholder="Select"
            onChange={this.onSelect}
          />
        }
        <Form.Field error={!!errors.name} className="form-group">
          <label htmlFor="sort">
            <FormattedMessage id="sort" defaultMessage="Sort" />
          </label>
          <input
            type="number"
            id="sort"
            name="sort"
            placeholder=""
            className="form-control"
            value={data.sort}
            onChange={this.onChange}
          />
          {errors.name && <InlineMessage text={errors.name} />}
        </Form.Field>
        {errors.group && <InlineMessage text={errors.group} />}
        {errors.message && <InlineMessage text={errors.message} />}
        <Button
          type="submit"
          className="submit"
          color="yellow"
          disabled={loading}
          loading={loading}
          fluid
        >
          <FormattedMessage id="create" defaultMessage="Create" />
        </Button>
      </Form>
    );
  }
}

export default injectIntl(CreateCategoryForm);
