import * as types from 'constants/ActionTypes';
import api from 'api';

export const getStatisticUser = () => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_STATISTICS_USERS,
      types.RECEIVE_STATISTICS_USERS_SUCCESS,
      types.RECEIVE_STATISTICS_USERS_FAILURE,
    ],
    payload: {
      request: api.statistic.get(),
    },
  });
