import React, { Component } from "react";
import { Currency } from "shared/components";
import { Pane } from "shared/components/layouts";

class ListItem extends Component {
  render() {
    const { item } = this.props;
    return (
      <Pane className="service-item">
        <Pane className="service-content">
          <p className="service-item__name" title={item.name}>
            {item.name}
          </p>
          <p className="service-item__duration">{item.duration * 60}min {item.children ? item.children.name : ''}</p>
        </Pane>
        <Pane className="service-price">
          <Pane className="group-price">
            <Pane className="price-item">
              <h5 className="adds-price">
                <span className="item_price">
                  <Currency price={item.children ? item.originalPrice + item.children.price : item.originalPrice} />
                </span>
              </h5>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default ListItem;
