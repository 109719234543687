import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = params => {
  return {
    method: 'GET',
    url: `/bookings/${params.bookingId}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const bookingDetail = formatJson(response, response.data.type, response.data.id)

  const bookingItems = []

  bookingDetail.bookingItems.forEach(item => {
    const bookingItem = formatJson(response, item.type, item.id)

    const options = [];
    const service = bookingItem.service;

    (service.options || []).forEach((option) => {
      const group = option.optionGroup || {};
      const index = options.findIndex((option) => option.id === group.id);
      const rs = (response.included || []).find((item) => item.id === option.id);
      if (index === -1) {
        options.push({
          id: group.id,
          name: group.name,
          type: group.type,
          autoAddOptions: false,
          dpOrdSeq: group.dpOrdSeq,
          delt: "N",
          childrens: [
            {
              id: option.id,
              name: option.name,
              dpOrdSeq: option.dpOrdSeq,
              price: Number(option.price),
              delt: "N",
              autoAddOptions: false,
              isCheck: !!option.isCheck,
            },
          ],
        });
      } else {
        options[index].childrens.push({
          id: option.id,
          name: option.name,
          dpOrdSeq: option.dpOrdSeq,
          price: Number(option.price),
          delt: "N",
          autoAddOptions: false,
          isCheck: !!option.isCheck,
        });
      }
  
      return options;
    });
  
    options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
    options.forEach((option) =>
      option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq)
    );

    bookingItems.push({
      id: bookingItem.id,
      serviceId: bookingItem.serviceId,
      serviceName: service.name,
      fromTime: bookingItem.fromTime,
      toTime: bookingItem.toTime,
      price: bookingItem.price,
      promotionPrice: bookingItem.promotionPrice,
      total: bookingItem.total,
      status: bookingItem.status,
      cancelStatus: bookingItem.cancelStatus,
      requestCancelReason: item.requestCancelReason,
      approveCancelReason: item.approveCancelReason,
      denyCancelReason: item.denyCancelReason,
      staff: bookingItem.staff ? bookingItem.staff : null,
      optionInfo: bookingItem.optionInfo,
      options: options,
      primaryImage: service.primaryImage && service.primaryImage.url,
      duration: service.duration,
      seller: {
        id: bookingItem.seller.id,
        businessName: item.seller && item.seller.business && item.seller.business.name ,
        name: bookingItem.seller.fullname,
        email: bookingItem.seller.email,
        phone: bookingItem.seller.phone
      }
    })
  })

  let paymentMethod = ''

  if (bookingDetail.payment && bookingDetail.payment.transactionType) {
    paymentMethod = bookingDetail.payment.transactionType
  } else {
    paymentMethod = bookingDetail.paymentMethod !== '' ? bookingDetail.paymentMethod : 'Credit card'
  }

  const data = {
    id: bookingDetail.id,
    status: bookingDetail.status,
    serviceName: bookingDetail.meta.serviceName,
    bookingDate: bookingDetail.createdAt,
    appointmentDate: bookingDetail.date,
    paymentMethod,
    total: Number(bookingDetail.total),
    tax: Number(bookingDetail.tax),
    note: bookingDetail.note,
    user: {
      id:  bookingDetail.user.id,
      name: bookingDetail.user.fullname,
      email: bookingDetail.user.email,
      phone: bookingDetail.user.phone,
      address: bookingDetail.user.address
    },
    bookingItems,
    updateRead: !!bookingDetail.updateRead,
    firstScanDiscount: bookingDetail.firstScanDiscount,
    maxDiscount: bookingDetail.maxDiscount,
    itemsTotal: bookingDetail.itemsTotal
  }

  return data
}

export { exec, parseResponse }
