import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';

class RangeSlider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value || props.min
        }
    }

    percent() {
        const { min, max } = this.props
        return (this.props.value - min) * 100 / (max - min)
    }

    renderNumber(index) {
        const value = this.props.value.toString()
        const { max } = this.props
        const length = max.toString().length
        const visible = index + 1 > length - value.length
        const position = visible ? "-" + (value[index - (length - value.length)] * 10) + '%' : '10%';

        let style = {
            transform: "translateY(" + position + ")",
            opacity: visible ? "1" : "0"
        }

        return (
            <Pane className="slider-value-number" key={index}>
                <ul style={style}>
                    {Array.from({ length: 10 }, (_, i) => (
                        <li key={i}>{i}</li>
                    ))}
                </ul>
            </Pane>
        )
    }

    render() {
        const { min, max, step } = this.props
        const length = max.toString().length
        let numbers = []

        for (let i = 0; i < length; i++) {
            numbers.push(this.renderNumber(i))
        }

        return (
            <Pane className="slider">
                <input
                    type="range"
                    min={min}
                    max={max}
                    defaultValue={this.props.value}
                    step={step}
                    onInput={this.props.handleChange}
                    style={{
                        backgroundSize: this.percent() + '% 100%'
                    }}
                />
                <Pane className="slider-value">{numbers}<span className="position-absolute">%</span></Pane>
            </Pane>
        )
    }
}

RangeSlider.propTypes = {};

export default RangeSlider;
