import * as types from 'constants/ActionTypes';
import ScanCountAdapter from "services/scanCounts/adapter";

const initialState = {
  results: {},
  error: null,
  fetching: false,
  fetched: false,
};

const scanCounts = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_SCAN_COUNT:
      return Object.assign({}, state, {
        fetching: true,
      });
    case types.RECEIVE_SCAN_COUNT_SUCCESS:
      return Object.assign({}, state, {
        results: ScanCountAdapter.read.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true,
      });
    case types.RECEIVE_SCAN_COUNT_FAILURE:
      return Object.assign({}, state, {
        results: [],
        error: action.error,
        fetching: false,
        fetched: true,
      });
    default:
      return state;
  }
};

export { scanCounts };
