import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import BestMatchedItem from "./BestMatchedItem";
import { Pane } from "shared/components/layouts";
import { getAllProducts } from "actions/products";
import { LoadingAdds } from "components/common/loading";
import { formatJson, formatRoute, currentCart } from "shared/helpers";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import ProductAdapter from "services/products/adapter";

class BestMatched extends Component {
  static propTypes = {
    auth: PropTypes.object,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
    intl: intlShape.isRequired,
  };

  state = {
    added: false,
    adding: false,
    fetching: false,
    fetched: false,
    data: [],
  };

  componentDidMount() {
    const params = {
      category: this.props.categoryId,
      size: 6,
    };
    this.setState({
      fetched: false,
      fetching: true,
      data: {
        results: [],
      },
    });
    this.props
      .getAllProducts(params)
      .then((response) => {
        const products = ProductAdapter.browse.parseResponse(response.data);
        this.setState({
          fetched: true,
          fetching: false,
          data: products,
        });
      })
      .catch(() => {
        this.setState({
          fetched: true,
          fetching: false,
        });
      });
  }

  render() {
    const { fetched, fetching, data, auth } = this.state;
    const { cart } = this.props;
    return (
      <>
        <Pane className="heading-container mb-3 text-lg-left text-md-left text-center">
          <h3 className="heading-title">
            <FormattedMessage id="best_matched" defaultMessage="Best Matched" />
          </h3>
          <p className="heading-norm">
            <FormattedMessage
              id="people_bought_the_sam_your_alse_buying_these"
              defaultMessage="People bought the same yours also buying these"
            />
          </p>
        </Pane>
        <Pane className="adds-block">
          {!fetching && fetched ? (
            <Pane className="row">
              {!isEmpty(data) &&
                currentCart(data, cart).map((item, index) => (
                  <BestMatchedItem
                    item={item}
                    auth={auth}
                    id={item.id}
                    key={item.id}
                    index={index}
                    name={item.name}
                    rating={item.rating}
                    image={item.primaryImage}
                    price={item.originalPrice}
                    onNotification={this.props.onNotification}
                    onAddItemToCart={this.props.onAddItemToCart}
                    onAddItemToGuestCart={this.props.onAddItemToGuestCart}
                    cartItems={cart && cart.items}
                    cartModal={this.props.cartModal}
                  />
                ))}
            </Pane>
          ) : (
            <LoadingAdds type="BESTMATCHED" />
          )}
        </Pane>
      </>
    );
  }
}
BestMatched.propTypes = {
  getAllProducts: PropTypes.func.isRequired,
  products: PropTypes.object,
};

const bindStateToProps = (state) => ({
  products: state.products,
  auth: state.auth,
  cart: state.cart,
});

const bindDispatchToProps = (dispatch) => ({
  getAllProducts: bindActionCreators(getAllProducts, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(BestMatched));
