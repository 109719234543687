import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Category } from 'shared/components';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import StoreList from 'components/common/filter/StoreList';
import { checkParams } from 'shared/helpers';

class InderBox extends Component {
  render() {
    const {
      type,
      title,
      isTab,
      toggle,
      category,
      className,
      nameToggle,
      match: { url },
    } = this.props;
    if (type === 'promotion') {
      return (
        <Pane className="inner-box">
          <h2
            className="inner-title"
            onClick={() => this.props.onOpenToggle(nameToggle)}
          >
            <FormattedMessage id={title} defaultMessage={title} />
            <span className="dropdown-inner">
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/icons/arrow-inner.svg'
                }
                alt="arrow"
                className={`icon ${toggle ? 'icon-open' : ''}`}
              />
            </span>
          </h2>
          <Pane
            className={`inner-responsive ${
              toggle ? 'inner-responsive-open' : ''
            }`}
          >
            <Pane className="category-content">
              <ul className="category-list">
                <li className="category-item">
                  <Link to={url} className="category-link category-all">
                    <FormattedMessage id="all" defaultMessage="All" />
                  </Link>
                </li>
                <li className="category-item">
                  <Link
                    to={`?discount=true${checkParams(
                      'discount',
                      this.props.location.search
                    )}`}
                    className="category-link"
                  >
                    <FormattedMessage
                      id="only_promotions"
                      defaultMessage="Only promotions"
                    />
                  </Link>
                </li>
                <li className="category-item">
                  <Link
                    to={`?discount=false${checkParams(
                      'discount',
                      this.props.location.search
                    )}`}
                    className="category-link"
                  >
                    <FormattedMessage
                      id="not_promotions"
                      defaultMessage="Not Promotions"
                    />
                  </Link>
                </li>
              </ul>
            </Pane>
          </Pane>
        </Pane>
      );
    }
    return (
      <Pane className={classnames('inner-box', className)}>
        <h2
          className="inner-title"
          onClick={() => this.props.onOpenToggle(nameToggle)}
        >
          <FormattedMessage id={title} defaultMessage={title} />
          <span className="dropdown-inner">
            <img
              src={
                process.env.PUBLIC_URL + '/assets/images/icons/arrow-inner.svg'
              }
              alt="arrow"
              className={`icon ${toggle ? 'icon-open' : ''}`}
            />
          </span>
        </h2>
        <Pane
          className={`inner-responsive ${
            toggle ? 'inner-responsive-open' : ''
          }`}
        >
          {isTab && (
            <ul className="tab-list">
              <Link
                to="/products"
                className={`tab-item ${type === 'products' && 'tab-active'}`}
              >
                <FormattedMessage id="products" defaultMessage="Products" />
              </Link>
              <Link
                to="/services"
                className={`tab-item ${type === 'services' && 'tab-active'}`}
              >
                <FormattedMessage id="services" defaultMessage="Services" />
              </Link>
            </ul>
          )}
          {(type === 'products' || type === 'services') && (
            <Pane className="categories-list list-filter">
              {!isEmpty(category) && (
                <Category categories={category} type={type} />
              )}
            </Pane>
          )}
          {type === 'stores' && (
            <Pane className="categories-list list-filter">
              {!isEmpty(category) && (
                <StoreList filters={category} type={type} />
              )}
            </Pane>
          )}
        </Pane>
      </Pane>
    );
  }
}

InderBox.propTypes = {};

export default withRouter(InderBox);
