import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Empty } from "components/common/empty";
import { Pane } from "shared/components/layouts";
import { StoreList } from "components/common/stores";
import InfiniteScroll from "react-infinite-scroller";
import BusinessAdapter from "services/businesses/adapter";
import { LoadingSearch } from "components/common/loading";
import { N_InderBox } from 'components/common/n_categories';

class N_Search extends Component {
	static propTypes = {
		auth: PropTypes.object,
		type: PropTypes.string,
		query: PropTypes.string,
		onSearchStores: PropTypes.func,
		onAddArea: PropTypes.func,
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: false,
			results: [],
			page: 1,
			totalPages: 0,
			totalItems: 0,
			cates: [],
			cate: null,
			loadingMore: false,
			hasMoreItems: true,
			data: {
				results: [],
			},
			country: "aus",
			countrys: [
				{
					text: "Australia",
					value: "aus",
				},
				{
					text: "Việt Nam",
					value: "vi",
				},
			],
			area: {
				state: "VIC",
				city: null
			},
			cities: [],
			loadingArea: false,
			key_search: ''
		};
	}

	searchStores = (params, load) => {
		this.setState({ loading: load });
		this.props
			.onSearchAll(params)
			.then((response) => {
				this.setState({
					results: BusinessAdapter.all.parseResponse(response.data),
					totalPages: response.data.meta.totalPages,
					totalItems: response.data.meta.totalItems,
					loading: false,
					cates: response.data.cates || [],
				});
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	onChangeKeySearch = (event) => {
		const self = this;

		if (self.state.typingTimeout) {
			clearTimeout(self.state.typingTimeout);
		}

		self.setState({
			key_search: event.target.value,
			typing: false,
			typingTimeout: setTimeout(function () {
				self.search();
			}, 800)
		});
	}

	handlePageClick = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				this.search();
			}
		);
	};

	search = () => {
		const { area, page, country, key_search, cate } = this.state;
		const params = {
			page: page,
			name: key_search,
			cate: cate,
			type: this.props.area.type,
			country: country,
			state: area.state,
			city: area.city,
		};
		this.searchStores(params, true);
	};

	componentDidMount() {
		const { area } = this.props;
		if (area && area.user_area) {
			this.setState({ area: { ...this.state.area, ...area.user_area } }, () => {
				this.search();
			});
		} else {
			this.search();
		}
		this.getAreas();
	}

	getAreas = () => {
		if (this.props.area.areaBusiness.cities) {
			let cities = [];
			cities = [...this.getCities(this.state.area.state, this.props.area.areaBusiness.cities)];
			this.setState({ cities: cities })
		}
	}

	getCities = (stateId, cities) => {
		return this.props.area.areas.cities.filter(city => cities.includes(city.name) && city.stateCode == stateId).map((city) => ({ text: city.name, value: city.name })) || [];
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps &&
			this.props &&
			prevProps.location.search !== this.props.location.search
		) {
			this.setState(
				{
					results: [],
					page: 1,
					totalPages: 0,
					totalItems: 0,
					loading: false,
				},
				() => {
					this.search();
				}
			);
		}
	}

	loadItems = () => {
		const { area } = this.state;
		const params = {
			page: this.state.page + 1,
			type: this.props.area.type,
			country: this.state.country,
			state: area.state,
			city: area.city,
		};
		if (
			!this.state.loadingMore &&
			this.state.totalItems > 20 &&
			this.state.totalItems > this.state.results.length
		) {
			this.setState({
				hasMoreItems: false,
				loadingMore: true,
			});
			this.props
				.onSearchAll(params)
				.then((response) => {
					this.setState((prevState) => ({
						hasMoreItems: true,
						page: prevState.page + 1,
						results: prevState.results.concat(
							BusinessAdapter.all.parseResponse(response.data)
						),
						loadingMore: false,
						cates: prevState.results.concat(response.data.cates || []),
					}));
				})
				.catch(() => {
					this.setState({ loading: false, loadingMore: false });
				});
		}
	};

	filterCountry = (e, value) => {
		this.setState({ country: value }, () => {
			this.search(value);
		});
	};

	filterArea = (value) => {
		const { area } = this.state;
		this.setState({ area: { ...area, city: value }, cate: null }, () => {
			this.props.onAddArea(this.state.area)
			this.search();
		})
	}

	changeCate = (cate) => {
		this.setState({ cate: this.state.cate !== cate ? cate : '' }, () => {
			this.search();
		});

	}

	onSelectTreeSelect = (value) => {
		this.setState({
			cate: value,
		}, () => {
			this.search();
		});
	}

	nestPath = (data, city = false) => {
		if (!_.isEmpty(data)) {
			return data.map((item, index) => ({
				index,
				key: !city ? item.id : item.value,
				pId: !city ? item.id : item.value,
				title: !city ? item.name : item.text,
				value: !city ? item.id : item.value,
				disabled: !_.isEmpty(item.children),
				children: this.nestPath(item.children, city),
			}));
		}
		return [];
	};

	onOpenToggle = (type) => {
		this.setState((prevState) => ({
		  [type]: !prevState[type],
		}));
	};

	render() {
		const { auth, type, query } = this.props;
		const {
			results,
			loading,
			loadingMore,
			loadingArea,
			totalItems,
			hasMoreItems,
			key_search,
			area,
			cities,
			cates,
			cate
		} = this.state;

		return (
			<Pane id="n_search" className="row">
				<Pane className="col-lg-3 col-md-12 product-container">
					{!loading && cates.length > 0 && (
						<N_InderBox
							type="products"
							className="mb-5"
							title="categories"
							nameToggle="toggleCategory"
							onOpenToggle={this.onOpenToggle}
							onChangeCate={this.changeCate}
							onFilterArea={this.filterArea}
							category={cates}
							cities={cities}
							cate={cate}
							city={area.city || ''}
							loadingArea={loadingArea}
							toggle={!!this.state.toggleCategory}
						/>
					)}
					{!loadingArea && (
						<N_InderBox
							type="city"
							className="mb-5"
							title="cities"
							nameToggle="toggleCity"
							onOpenToggle={this.onOpenToggle}
							onChangeCate={this.changeCate}
							onFilterArea={this.filterArea}
							category={cates}
							cities={cities}
							cate={cate}
							city={area.city || ''}
							loadingArea={loadingArea}
							toggle={!!this.state.toggleCity}
						/>
					)}
				</Pane>
				<Pane className="col-lg-9 col-md-12 page-content">
					<Pane className="search-container">
						<Pane className="heading-container">
							{!loading && (
								<p className="heading-results mr-2 mb-0">
									Stores
									<i>
										{totalItems > 20 ? " (20+)" : ` (${totalItems})`}{" "}
									</i>
									<b>
										<strong>{query}</strong>
									</b>
								</p>
							)}
							<Pane className="form-overview">
								<Pane className="form-item">
									<input
										type="text"
										name="name"
										value={key_search}
										autoComplete="off"
										onChange={this.onChangeKeySearch}
										placeholder="Store's name..."
										className="form-input s_name"
									/></Pane>
							</Pane>
						</Pane>
						<Pane>
							{loading && <LoadingSearch type="white" />}
							{!loading && (
								<>
									{!_.isEmpty(results) ? (
										<InfiniteScroll
											pageStart={0}
											loadMore={this.loadItems}
											hasMore={hasMoreItems}
											isReverse={false}
										>
											<StoreList auth={auth} stores={results} type={type} />
											{loadingMore && <LoadingSearch />}
										</InfiniteScroll>
									) : (
										<></>
									)}
								</>
							)}
							{!loading && _.isEmpty(results) ? <Empty /> : null}
						</Pane>
					</Pane>
				</Pane>
			</Pane>
		);
	}
}

export default withRouter(N_Search);
