import Config from 'constants/Config';

const exec = () => {
  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/areas`,
  };
};

const parseResponse = (response) => {
  return response;
};

export { exec, parseResponse };