import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react'

class Breadcumb extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { breadcrumbs } = this.props;
		return (
			<Pane className="row">
				<Pane className="col-lg-12 breadcrumb-container">
					<Breadcrumb className="breadcrumb-list">
						{breadcrumbs.map((item) => {
							return (
								<Breadcrumb.Section className={`item ${item.active ? 'active' : ''}`} active={item.active}>
									{item.url !== '' ? (
										<Link to={item.url}>
											{item.name}
										</Link>
									) : (
										<>
											{item.name}
										</>
									)}
								</Breadcrumb.Section>
							)
						})
						}
					</Breadcrumb>
				</Pane>
			</Pane>
		)
	}
}

export default withRouter(Breadcumb);
