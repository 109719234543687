import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';
import { checkParams } from 'shared/helpers';

class StoreItem extends Component {
  static propTypes = {
    id: PropTypes.any,
    name: PropTypes.string,
    type: PropTypes.string,
    onLoad: PropTypes.func,
  };

  static defaultProps = {
    id: 0,
    name: '',
    type: 'products',
  };

  checkStore = (userId) => {
    return parseInt(userId, 10) ===
      parseInt(queryString.parse(this.props.location.search).store, 10)
      ? true
      : false;
  };

  render() {
    return (
      <li className="category-item">
        <Link
          to={`?store=${this.props.id}${checkParams(
            'store',
            this.props.location.search
          )}`}
          className="category-link"
        >
          {this.props.name}
        </Link>
      </li>
    );
  }
}

export default withRouter(StoreItem);
