const createPassword = () => {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const special = '!@#$%^&*()_+[]{}|;:,.<>?';

    const allCharacters = lowercase + uppercase + numbers + special;

    let password = '';

    // Ensure the password includes at least one lowercase letter, one uppercase letter, one number, and one special character
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += special[Math.floor(Math.random() * special.length)];

    // Generate the remaining characters randomly to meet the minimum length of 8 characters
    for (let i = 4; i < 8; i++) {
      password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Shuffle the password to ensure the characters are in random order
    password = password.split('').sort(() => Math.random() - 0.5).join('');

    return password;

}

export default createPassword