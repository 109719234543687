import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';
import StoreItem from './StoreItem';

class StoreList extends Component {
  static propTypes = {
    auth: PropTypes.object,
    stores: PropTypes.array,
  };

  
  render() {
    const list =
      this.props.stores &&
      this.props.stores.map((item, index) => {
        return (
          <StoreItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.name}
            image={item.backgroundImage}
            avatar={item.profileImage}
            qrcode={item.qrcode}
            rating={item.rating}
            businessInfo={item.business}
            auth={this.props.auth}
            index={index}
            type={this.props.type}
            showNew={this.props.justListed ? true : false}
          />
        );
      });

    return (
      <Pane className="adds-block">
        <Pane className="row pt-3 pb-3">{list}</Pane>
      </Pane>
    );
  }
}

export default StoreList;
