import { Button } from 'semantic-ui-react';
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { injectIntl, intlShape } from 'react-intl';


class MethodModal extends Component {
	static propTypes = {};

	constructor(props, context) {
		super(props, context);

		this.state = {};
	}


	render() {
		const {
			openModal,
			tables,
			methodType,
			tableId
		} = this.props;

		return (
			<Modal
				open={openModal}
				onClose={this.props.onClose}
			>
				<Pane className="popup-method-container">
					<Pane className="method-wrap">
						<span onClick={() => this.props.onChangeMethod('Take-Away')} className={`sub-header__type ${methodType == 'Take-Away' ? 'method-active' : ''}`}>Take Away</span>
						<span onClick={() => this.props.onChangeMethod('Dine-In')} className={`sub-header__type ${methodType == 'Dine-In' ? 'method-active' : ''}`}>Dine in</span>
					</Pane>
					<Pane className="method-body">
						{["Dine-In", "Take-Away"].includes(methodType) ? (
							<Pane className="form-overview">
								<Pane className="form-item">
									<select
										className="form-select"
										name="format"
										id="format"
										value={tableId}
										onChange={this.props.onChangeType}
									>
										<option value="">
											--- Select table ---
										</option>
										{tables.map((item) => {
											return (
												<option value={item.id} key={item.id}>
													{item.name}
												</option>
											);
										})}
									</select>
								</Pane>
							</Pane>
						) : (
							<Pane className="method-content">
								The food in restaurant is very delicious, let's explore and order some items from the menu
							</Pane>
						)}
						<Pane className="block-btn">
							<Button
								className="btn btn-circle btn-category"
								onClick={this.props.onClose}
							>
								Feed Me
							</Button>
						</Pane>
					</Pane>
				</Pane>
			</Modal>
		);
	}
}

export default withRouter(injectIntl(MethodModal));
