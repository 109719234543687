import AuthAdapter from "services/auth/adapter";
import UserAdapter from "services/users/adapter";
import CartAdapter from "services/carts/adapter";
import LikeAdapter from "services/likes/adapter";
import OrderAdapter from "services/orders/adapter";
import VideoAdapter from "services/videos/adapter";
import BannerAdapter from "services/banners/adapter";
import ReviewAdapter from "services/reviews/adapter";
import SlideAdapter from "services/payments/adapter";
import ContactAdapter from "services/contact/adapter";
import CustomerAdapter from "services/customer/adapter";
import ProductAdapter from "services/products/adapter";
import ServiceAdapter from "services/services/adapter";
import BookingAdapter from "services/bookings/adapter";
import PaymentAdapter from "services/payments/adapter";
import DeliveryAdapter from "services/delivery/adapter";
import CategoryAdapter from "services/categories/adapter";
import BusinessAdapter from "services/businesses/adapter";
import MyBusinessAdapter from "services/myBusiness/adapter";
import PromotionsAdapter from "services/promotions/adapter";
import NotificationAdapter from "services/notification/adapter";
import StatisticAdapter from "services/statistics/adapter";
import CollectionAdapter from "services/collections/adapter";
import UserContactAdapter from "services/userContacts/adapter";
import ScanCountAdapter from "services/scanCounts/adapter";
import OptionsAdapter from "services/options/adapter";
import OptionGroupAdatper from "services/optionGroup/adapter";
import DigitalShareAdapter from "services/digitalShares/adapter";
import AreaBrowseV1 from "services/area/adapter";
import NotiAdapter from "services/notis/adapter";

export default {
  user: {
    subscribe: (params) => AuthAdapter.subScribe.callAPI(params),
    login: (params) => AuthAdapter.login.callAPI(params),
    socialLogin: (params) => AuthAdapter.socialLogin.callAPI(params),
    signup: (params) => UserAdapter.register.callAPI(params),
    quickSignup: (params) => UserAdapter.quickRegister.callAPI(params),
    requestQRCode: (params) => UserAdapter.qrcode.callAPI(params),
    getInfo: (params) => UserAdapter.read.callAPI(params),
    updateInfo: (params) => UserAdapter.edit.callAPI(params),
    updateImage: (params) => UserAdapter.avatar.callAPI(params),
    referenceCode: (params) => UserAdapter.referenceCode.callAPI(params),

    changePassword: (params) => UserAdapter.changePassword.callAPI(params),
    forgotPassword: (params) => UserAdapter.forgotPassword.callAPI(params),
    resetPassword: (params) => UserAdapter.resetPassword.callAPI(params),
    getMyBusinessInfo: (params) => MyBusinessAdapter.read.callAPI(params),
    updateMyBusinessInfo: (params) => MyBusinessAdapter.edit.callAPI(params),
    slides: (params) => UserAdapter.slideBrowse.callAPI(params),
    products: (params) => UserAdapter.productBrowse.callAPI(params),
    // TABLES
    tables: (params) => UserAdapter.tablesBrowse.callAPI(params),
    deleteTables: (params) => UserAdapter.tablesRemove.callAPI(params),
    createTables: (params) => UserAdapter.tablesAdd.callAPI(params),
    readTables: (params) => UserAdapter.tablesRead.callAPI(params),
    editTables: (params) => UserAdapter.tablesEdit.callAPI(params),
    // TABLES
    productPicks: (params) => UserAdapter.productPicksBrowse.callAPI(params),
    foodCategories: (params) => UserAdapter.foodCategories.callAPI(params),
    otherCategories: (params) => UserAdapter.otherCategories.callAPI(params),
    productDetail: (params) => ProductAdapter.read.callAPI(params),
    createProduct: (params) => UserAdapter.productAdd.callAPI(params),
    createProductPick: (params) => UserAdapter.productPickAdd.callAPI(params),
    deleteProductPick: (params) =>
      UserAdapter.productPickRemove.callAPI(params),
    readPicks: (params) => UserAdapter.readPicks.callAPI(params),
    editPicks: (params) => UserAdapter.editPicks.callAPI(params),
    categoryAdd: (params) => UserAdapter.categoryAdd.callAPI(params),
    editProduct: (params) => UserAdapter.productEdit.callAPI(params),
    deleteProduct: (params) => UserAdapter.productRemove.callAPI(params),
    services: (params) => UserAdapter.serviceBrowse.callAPI(params),
    serviceCategories: (params) =>
      UserAdapter.serviceCategories.callAPI(params),
    serviceDetail: (params) => ServiceAdapter.read.callAPI(params),
    createService: (params) => UserAdapter.serviceAdd.callAPI(params),
    editService: (params) => UserAdapter.serviceEdit.callAPI(params),
    deleteService: (params) => UserAdapter.serviceRemove.callAPI(params),
    collections: (params) => UserAdapter.collectionBrowse.callAPI(params),
    collectionCategories: (params) =>
      UserAdapter.collectionCategories.callAPI(params),
    collectionDetail: (params) => CollectionAdapter.read.callAPI(params),
    createCollection: (params) => UserAdapter.collectionAdd.callAPI(params),
    editCollection: (params) => UserAdapter.collectionEdit.callAPI(params),
    deleteCollection: (params) => UserAdapter.collectionRemove.callAPI(params),
    promotionProducts: (params) =>
      UserAdapter.productPromotionBrowse.callAPI(params),
    promotionServices: (params) =>
      UserAdapter.servicePromotionBrowse.callAPI(params),
    promotionDetail: (params) => UserAdapter.promotionRead.callAPI(params),
    createPromotion: (params) => UserAdapter.promotionAdd.callAPI(params),
    editPromotion: (params) => UserAdapter.promotionEdit.callAPI(params),
    deletePromotions: (params) => UserAdapter.promotionRemove.callAPI(params),
    boostPromotions: (params) => UserAdapter.promotionBoost.callAPI(params),
    getFoodOrderUserCategories: (params) =>
      UserAdapter.getFoodOrderUserCategories.callAPI(params),
    getBusinessFoodOrder: (params) =>
      UserAdapter.getBusinessFoodOrder.callAPI(params),
    getProductByBussiness: (params) =>
      UserAdapter.getProductByBussiness.callAPI(params),
    videos: (params) => UserAdapter.videoBrowse.callAPI(params),
    videoData: (params) => UserAdapter.videoDataBrowse.callAPI(params),
    videoDetails: (params) => UserAdapter.videoRead.callAPI(params),
    createVideo: (params) => UserAdapter.videoAdd.callAPI(params),
    editVideo: (params) => UserAdapter.videoEdit.callAPI(params),
    deleteVideo: (params) => UserAdapter.videoRemove.callAPI(params),
    Allvideos: (params) => UserAdapter.videoBrowseAll.callAPI(params),
    viewVideos: (params) => UserAdapter.videoView.callAPI(params),
    addLikeVideos: (params) => UserAdapter.addLikeVideos.callAPI(params),
    digitals: (params) => UserAdapter.digitalsBrowse.callAPI(params),
    digitalRemove: (params) => UserAdapter.digitalRemove.callAPI(params),
    digitalAdd: (params) => UserAdapter.digitalAdd.callAPI(params),
    digitalRead: (params) => UserAdapter.digitalRead.callAPI(params),
    DigitalEdit: (params) => UserAdapter.DigitalEdit.callAPI(params),
    screens: (params) => UserAdapter.screensBrowse.callAPI(params),
    screenRemove: (params) => UserAdapter.screenRemove.callAPI(params),
    screenCount: (params) => UserAdapter.screenCount.callAPI(params),
    screenAdd: (params) => UserAdapter.screenAdd.callAPI(params),
    screenRead: (params) => UserAdapter.screenRead.callAPI(params),
    screenEdit: (params) => UserAdapter.screenEdit.callAPI(params),
    screenUpdateOnline: (params) => UserAdapter.screenUpdateOnline.callAPI(params),
    screenActivityAdd: (params) => UserAdapter.screenActivityAdd.callAPI(params),
    digitalShare: (params) => UserAdapter.digitalShare.callAPI(params),
    projects: (params) => UserAdapter.projectBrowser.callAPI(params),
    readProject: (params) => UserAdapter.projectRead.callAPI(params),
    addProject: (params) => UserAdapter.projectAdd.callAPI(params),
    editProject: (params) => UserAdapter.projectEdit.callAPI(params),
    removeProject: (params) => UserAdapter.projectRemove.callAPI(params),
    tasks: (params) => UserAdapter.taskBrowser.callAPI(params),
    readTask: (params) => UserAdapter.taskRead.callAPI(params),
    addTask: (params) => UserAdapter.taskAdd.callAPI(params),
    editTask: (params) => UserAdapter.taskEdit.callAPI(params),
    removeTask: (params) => UserAdapter.taskRemove.callAPI(params),
    taskMembers: (params) => UserAdapter.taskMemberBrowser.callAPI(params),
    readTaskMember: (params) => UserAdapter.taskMemberRead.callAPI(params),
    addTaskMember: (params) => UserAdapter.taskMemberAdd.callAPI(params),
    editTaskMember: (params) => UserAdapter.taskMemberEdit.callAPI(params),
    removeTaskMember: (params) => UserAdapter.taskMemberRemove.callAPI(params),
    serviceMembersBookingAvailable: (params) => UserAdapter.serviceMemberBookingAvailable.callAPI(params),
    serviceMembers: (params) => UserAdapter.serviceMemberBrowser.callAPI(params),
    readServiceMember: (params) => UserAdapter.serviceMemberRead.callAPI(params),
    addServiceMember: (params) => UserAdapter.serviceMemberAdd.callAPI(params),
    editServiceMember: (params) => UserAdapter.serviceMemberEdit.callAPI(params),
    removeServiceMember: (params) => UserAdapter.serviceMemberRemove.callAPI(params),
    staffAssign: (params) => UserAdapter.staffAssign.callAPI(params),
    staffs: (params) => UserAdapter.staffBrowser.callAPI(params),
    readStaff: (params) => UserAdapter.staffRead.callAPI(params),
    addStaff: (params) => UserAdapter.staffAdd.callAPI(params),
    editStaff: (params) => UserAdapter.staffEdit.callAPI(params),
    removeStaff: (params) => UserAdapter.staffRemove.callAPI(params),
    scanQrPromotionDetail: (params) => UserAdapter.scanQrPromotionDetail.callAPI(params),
    scanQrPromotions: (params) => UserAdapter.scanQRPromotionBrowser.callAPI(params),
    readScanQrPromotion: (params) => UserAdapter.scanQRPromotionRead.callAPI(params),
    addScanQrPromotion: (params) => UserAdapter.scanQRPromotionAdd.callAPI(params),
    editScanQrPromotion: (params) => UserAdapter.scanQRPromotionEdit.callAPI(params),
    removeScanQrPromotion: (params) => UserAdapter.scanQRPromotionRemove.callAPI(params),
    digitalsShare: (params) => UserAdapter.digitalsShareBrowse.callAPI(params),
  },
  delivery: {
    createDelivery: (params) => DeliveryAdapter.delivery.callAPI(params),
    get: (params) => DeliveryAdapter.browse.callAPI(params),
    detail: (params) => DeliveryAdapter.read.callAPI(params),
    request: (params) => DeliveryAdapter.request.callAPI(params),
  },
  options: {
    createOption: (params) => OptionsAdapter.add.callAPI(params),
    editOption: (params) => OptionsAdapter.edit.callAPI(params),
    deleteOption: (params) => OptionsAdapter.delete.callAPI(params),
  },
  optionGroup: {
    createOptionGroup: (params) => OptionGroupAdatper.add.callAPI(params),
    editOptionGroup: (params) => OptionGroupAdatper.edit.callAPI(params),
    deleteOptionGroup: (params) => OptionGroupAdatper.delete.callAPI(params),
  },
  digitalShare: {
    get: (params) => DigitalShareAdapter.browse.callAPI(params),
    createDigitalShare: (params) => DigitalShareAdapter.add.callAPI(params),
    editDigitalShare: (params) => DigitalShareAdapter.edit.callAPI(params),
    deleteDigitalShare: (params) => DigitalShareAdapter.delete.callAPI(params),
  },
  cart: {
    get: (params) => CartAdapter.browse.callAPI(params),
    update: (params) => CartAdapter.update.callAPI(params),
    create: () => CartAdapter.create.callAPI(),
    add: (params) => CartAdapter.add.callAPI(params),
    changeDeliveryMethod: (params) => CartAdapter.editMethod.callAPI(params),
    remove: (params) => CartAdapter.remove.callAPI(params),
    updateQuantity: (params) => CartAdapter.editQuantity.callAPI(params),
    updateOption: (params) => CartAdapter.editOptionInfo.callAPI(params),
    checkout: (params) => CartAdapter.checkout.callAPI(params),
    paymentPaypal: (params) => CartAdapter.payment.callAPI(params),
    order: (params) => CartAdapter.order.callAPI(params),
    checkoutPOS: (params) => CartAdapter.checkoutPOS.callAPI(params),
  },
  payment: {
    requestClientAuthorization: () =>
      PaymentAdapter.requestClientAuthorization.callAPI(),
  },
  banner: {
    getAll: (params) => BannerAdapter.browse.callAPI(params),
    getDigitalAll: (params) => BannerAdapter.digitalBrowse.callAPI(params),
  },
  category: {
    productCategories: (params) =>
      CategoryAdapter.productCategoryBrowse.callAPI(params),
    serviceCategories: (params) =>
      CategoryAdapter.serviceCategoryBrowse.callAPI(params),
    foodOrderCategories: (params) =>
      CategoryAdapter.foodOrderCategoryBrowse.callAPI(params),
    collectionCategories: (params) =>
      CategoryAdapter.collectionCategoryBrowse.callAPI(params),
    detailCategory: (params) => CategoryAdapter.detailCategory.callAPI(params),
  },
  business: {
    search: (params) => BusinessAdapter.search.callAPI(params),
    quick: (params) => BusinessAdapter.quick.callAPI(params),
    all: (params) => BusinessAdapter.all.callAPI(params),
    browse: (params) => BusinessAdapter.browse.callAPI(params),
    getBusinessLogos: (params) => BusinessAdapter.browse.callAPI(params),
    getBusinessCboosts: (params) => BusinessAdapter.cboosts.callAPI(params),
    getBussinessByCatogory: (params) =>
      BusinessAdapter.productsCategory.callAPI(params),
    review: (params) => BusinessAdapter.review.callAPI(params),
    getReview: (params) => BusinessAdapter.getReview.callAPI(params),
  },
  orders: {
    buyers: {
      get: (params) => OrderAdapter.buyers.browse.callAPI(params),
      detail: (params) => OrderAdapter.buyers.read.callAPI(params),
      requestCancel: (params) =>
        OrderAdapter.buyers.requestCancel.callAPI(params),
    },
    sellers: {
      get: (params) => OrderAdapter.sellers.browse.callAPI(params),
      detail: (params) => OrderAdapter.sellers.read.callAPI(params),
      acceptCancel: (params) => OrderAdapter.sellers.acceptCancel.callAPI(params),
      cancel: (params) => OrderAdapter.sellers.cancel.callAPI(params),
      denyCancel: (params) => OrderAdapter.sellers.denyCancel.callAPI(params),
      changeStatus: (params) =>
        OrderAdapter.sellers.changeStatus.callAPI(params),
    },
    dashboard: {
      get: (params) => OrderAdapter.dashboard.browse.callAPI(params),
    }
  },
  contact: {
    send: (params) => ContactAdapter.send.callAPI(params),
  },
  customer: {
    add: (params) => CustomerAdapter.add.callAPI(params),
    checkCode: (params) => CustomerAdapter.checkCode.callAPI(params),
  },
  products: {
    get: (params) => ProductAdapter.browse.callAPI(params),
    detail: (params) => ProductAdapter.read.callAPI(params),
    review: (params) => ProductAdapter.review.callAPI(params),
    search: (params) => ProductAdapter.search.callAPI(params),
    featured: (params) => ProductAdapter.featured.callAPI(params),
    browsePicks: (params) => ProductAdapter.browsePicks.callAPI(params),
    options: (params) => ProductAdapter.options.callAPI(params),
  },
  promotions: {
    get: (params) => PromotionsAdapter.browse.callAPI(params),
    getSlide: (params) => PromotionsAdapter.browseslide.callAPI(params),
  },
  reviews: {
    get: (params) => ReviewAdapter.browse.callAPI(params),
    getReviewsVideo: (params) => ReviewAdapter.browseVideo.callAPI(params),
    review: (params) => ReviewAdapter.review.callAPI(params),
    editReview: (params) => ReviewAdapter.reviewEdit.callAPI(params),
  },
  services: {
    get: (params) => ServiceAdapter.browse.callAPI(params),
    detail: (params) => ServiceAdapter.read.callAPI(params),
    review: (params) => ServiceAdapter.review.callAPI(params),
    getTimeSlots: (params) => ServiceAdapter.getTimeSlots.callAPI(params),
    book: (params) => ServiceAdapter.book.callAPI(params),
    search: (params) => ServiceAdapter.search.callAPI(params),
    featured: (params) => ServiceAdapter.featured.callAPI(params),
    getSlide: (params) => ServiceAdapter.browseslide.callAPI(params),
    options: (params) => ServiceAdapter.options.callAPI(params),
  },
  bookings: {
    buyers: {
      get: (params) => BookingAdapter.buyers.browse.callAPI(params),
      detail: (params) => BookingAdapter.buyers.read.callAPI(params),
      requestCancel: (params) =>
        BookingAdapter.buyers.requestCancel.callAPI(params),
    },
    sellers: {
      get: (params) => BookingAdapter.sellers.browse.callAPI(params),
      detail: (params) => BookingAdapter.sellers.read.callAPI(params),
      acceptCancelBooking: (params) =>
        BookingAdapter.sellers.acceptCancel.callAPI(params),
      denyCancelBooking: (params) =>
        BookingAdapter.sellers.denyCancel.callAPI(params),
      changeStatus: (params) =>
        BookingAdapter.sellers.changeStatus.callAPI(params),
    },
  },
  stores: {
    get: (params) => UserAdapter.read.callAPI(params),
  },
  slides: {
    get: (params) => SlideAdapter.read.callAPI(params),
  },
  videos: {
    get: (params) => VideoAdapter.browse.callAPI(params),
  },
  notification: {
    save: (params) => NotificationAdapter.save.callAPI(params),
    push: (params) => NotificationAdapter.push.callAPI(params),
  },
  likes: {
    get: (params) => LikeAdapter.browse.callAPI(params),
    getUser: (params) => LikeAdapter.userBrowse.callAPI(params),
    like: (params) => LikeAdapter.like.callAPI(params),
  },
  statistic: {
    get: (params) => StatisticAdapter.browse.callAPI(params),
  },
  userContacts: {
    get: (params) => UserContactAdapter.browse.callAPI(params),
    add: (params) => UserContactAdapter.add.callAPI(params),
    remove: (params) => UserContactAdapter.remove.callAPI(params),
  },
  scanCounts: {
    get: (params) => ScanCountAdapter.read.callAPI(params),
    add: (params) => ScanCountAdapter.add.callAPI(params),
  },
  area: {
    get: (params) => AreaBrowseV1.browseByBusiness.callAPI(params),
    getAll: () => AreaBrowseV1.browse.callAPI()
  },
  notis: {
    get: (params) => NotiAdapter.browseByUser.callAPI(params)
  },
};
