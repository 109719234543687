import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import { createMyScreen } from 'actions/screens';
import { formatRoute, handleError } from "shared/helpers";
import InlineMessage from "shared/components/InlineMessage";
import NotificationSystem from "react-notification-system";
import { Button, Form, Select, Radio, Icon, Image as ImageS } from "semantic-ui-react";
import { RangeSlider } from '../../../common/range-slider';
import { Breadcumb } from '../../../common/breadcumb';

class CreateScreen extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    createMyDigital: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.state = {
      data: {
        name: "",
        type: "Horizontal",
        duration: 0,
        activate: false,
        slot: 0,
        share_slot: 0,
        remain_slot: 0,
        price: 0,
        percent_share: 0,
      },
      imageFiles: [],
      images: [],
      errors: {},
      loading: false,
      types: [
        {
          text: "Horizontal",
          value: "Horizontal",
        },
        {
          text: "Vertical",
          value: "Vertical",
        },
      ],
    };
  }

  onChangeType = (e, value) => {
    this.setState({ data: { ...this.state.data, type: value } });
  };

  onChange = (e) => {
    let isUpdate = false;
    let slot = 0;
    let remain_slot = 0;
    let share_slot = 0;
    let count_items = this.props.digitals && this.props.digitals.results.length > 0 ? this.props.digitals.results.length : 1;
    if (e.target.name == 'duration') {
      isUpdate = true;
      slot = e.target.value * 3600 / 5;
      remain_slot = slot / count_items;
      share_slot = 0;
    }
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    }, (e) => {
      if (isUpdate) {
        this.setState({
          data: { ...this.state.data, 'slot': remain_slot, 'remain_slot': remain_slot, 'share_slot': share_slot, 'percent_share': 0 },
        });
      }
    });
  };

  handleChange = (e) => {
    let share_slot = this.state.data.slot * e.target.value / 100;
    let remain_slot = this.state.data.slot - share_slot;
    this.setState({
      data: { ...this.state.data, 'percent_share': e.target.value },
    }, () => {
      this.setState({
        data: { ...this.state.data, 'share_slot': share_slot, 'remain_slot': remain_slot }
      });
    })
  }

  toggle = () => {
    this.setState({
      data: { ...this.state.data, 'activate': !this.state.data.activate }
    });
  };

  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images[0] = (reader.result);
            imageFiles[0]=(files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
      });
    }
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }

    return errors;
  };

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  createMyScreen = () => {
    const { intl } = this.props;
    const { data, imageFiles } = this.state;
    const errors = this.validate(data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const dataForm = new FormData();
      if (imageFiles[0]) {
        dataForm.append("primary_image", imageFiles[0]);
      }
      dataForm.append("sub_image_1", imageFiles[1] || "");
      dataForm.append("sub_image_2", imageFiles[2] || "");
      dataForm.append("sub_image_3", imageFiles[3] || "");
      dataForm.append("sub_image_4", imageFiles[4] || "");
      dataForm.append("sub_image_5", imageFiles[5] || "");
      dataForm.append("name", data.name);
      dataForm.append("type", data.type);
      dataForm.append("activate", data.activate ? 1 : 0);
      if (data.activate) {
        dataForm.append("duration", data.duration);
        dataForm.append("slot", data.slot);
        dataForm.append("share_slot", data.share_slot);
        dataForm.append("percent_share", data.percent_share);
        dataForm.append("price", data.price);
      }
      dataForm.append("user_id", this.props.userInfo.id || "");

      this.props
        .createMyScreen(dataForm)
        .then(() => {
          this.setState({
            loading: false,
            data: {
              ...this.state.data,
              name: "",
              type: "Horizontal",
            },
          });
          this.props.history.push(
            `/u/${formatRoute(
              this.props.userInfo.displayName
            )}/digital-slideshow`
          );
        })
        .catch((error) => {
          this._notificationSystem.addNotification({
            message: intl.formatMessage({ id: 'notification.please_try_again' }),
            level: 'error',
          });

          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  render() {
    const { errors, data, loading, types } = this.state;
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/digital-slideshow`,
        name: 'Digital Screens'
      },
      {
        url: ``, name: 'Create new digital screen', active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="d-flex justify-content-between align-items-center mb-4" id="anchor">
        </Pane>
        <Form className="form-overview pb-5 form-create-screen" onSubmit={this.createMyScreen}>
          <Pane className="form-content">
            <Pane className="row">
              <Pane className="col-lg-12">
                      <Form.Field className="form-item">
                        <label htmlFor="Image" className="form-label">
                          <FormattedMessage id="cover_image" defaultMessage="Cover Image" />
                        </label>
                        <input
                          type="file"
                          id="image-menu"
                          className="form-input-file"
                          multiple
                          value={data.image}
                          onChange={this.onChangeImage}
                        />
                        <br />
                        <label htmlFor="image-menu" className="form-label-file">
                          <img
                            src={
                              process.env.PUBLIC_URL + `/assets/images/icons/upload.svg`
                            }
                            alt="upload"
                            className="icon"
                          />
                          Upload a photo/video
                        </label>
                        <Pane
                          className="slider-content-main d-flex"
                          style={{ padding: "10px 0" }}
                        >
                          {this.state.images &&
                            this.state.images.map((item, index) => {
                              return (
                                <Pane className="slide-item mr-2" key={index}>
                                  <Icon
                                    name="remove circle"
                                    onClick={this.removeImage.bind(this, index)}
                                  />
                                  <ImageS src={item} size="small screen-cover-image" />
                                </Pane>
                              );
                            })}
                        </Pane>
                      </Form.Field>
                </Pane>
              <Pane className="col-lg-12">
                <Form.Field error={!!errors.name} className="form-item">
                  <label htmlFor="name" className="form-label">
                    <FormattedMessage id="name" defaultMessage="name" />
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder=""
                    className="form-input"
                    value={data.name}
                    onChange={this.onChange}
                  />
                  {errors.name && <InlineMessage text={errors.name} />}
                </Form.Field>
              </Pane>
              <Pane className="col-lg-12">
                <Form.Field className="form-item">
                  <label htmlFor="name" className="form-label">
                    <FormattedMessage
                      id="option_group_type"
                      defaultMessage="Name"
                    />
                  </label>
                  <Select
                    value={data.type}
                    options={types}
                    name="type"
                    onChange={(e, { value }) => this.onChangeType(e, value)}
                  />
                </Form.Field>
              </Pane>


              <Pane className="col-lg-12">
                <Form.Field className="form-item">
                  <Pane className="row mt-2 mb-2">
                    <Pane className="col-lg-12">
                      <Radio
                        label='C-net activate'
                        toggle
                        onChange={this.toggle}
                        checked={data.activate}
                      />
                    </Pane>
                  </Pane>
                </Form.Field>
              </Pane>

            </Pane>
            {data.activate && (
              <>

                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item" error={!!errors.duration}>
                      <label htmlFor="email" className="form-label">

                        Duration(hours)
                      </label>
                      <input
                        type="text"
                        id="duration"
                        name="duration"
                        className="form-input"
                        placeholder=""
                        value={data.duration}
                        onChange={this.onChange}
                      />
                      {errors.duration && (
                        <InlineMessage text={errors.duration} />
                      )}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item" error={!!errors.slot}>
                      <label htmlFor="email" className="form-label">
                        {/* <FormattedMessage
                        id="business_email"
                        defaultMessage="Business email"
                      /> */}
                        Slot
                      </label>
                      <input
                        type="text"
                        id="slot"
                        name="slot"
                        className="form-input"
                        placeholder=""
                        value={data.slot}
                        onChange={this.onChange}
                        disabled
                      />
                      {errors.slot && (
                        <InlineMessage text={errors.slot} />
                      )}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item" error={!!errors.price}>
                      <label htmlFor="email" className="form-label">
                        {/* <FormattedMessage
                        id="business_email"
                        defaultMessage="Business email"
                      /> */}
                        Price
                      </label>
                      <input
                        type="text"
                        id="price"
                        name="price"
                        className="form-input"
                        placeholder=""
                        value={data.price}
                        onChange={this.onChange}
                      />
                      {errors.price && (
                        <InlineMessage text={errors.price} />
                      )}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item" error={!!errors.percent_share}>
                      <label htmlFor="email" className="form-label">
                        {/* <FormattedMessage
                          id="business_email"
                          defaultMessage="Business email"
                        /> */}
                        Percent share
                      </label>
                      <RangeSlider min={0} max={100} value={data.percent_share} handleChange={this.handleChange} />
                      {errors.percent_share && (
                        <InlineMessage text={errors.percent_share} />
                      )}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item" error={!!errors.share_slot}>
                      <label htmlFor="email" className="form-label">
                        {/* <FormattedMessage
                        id="business_email"
                        defaultMessage="Business email"
                      /> */}
                        Share Slot
                      </label>
                      <input
                        type="text"
                        id="slot"
                        name="slot"
                        className="form-input"
                        placeholder=""
                        value={data.share_slot}
                        onChange={this.onChange}
                        disabled
                      />
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item" error={!!errors.share_slot}>
                      <label htmlFor="email" className="form-label">
                        {/* <FormattedMessage
                        id="business_email"
                        defaultMessage="Business email"
                      /> */}
                        Remain Slot
                      </label>
                      <input
                        type="text"
                        id="slot"
                        name="slot"
                        className="form-input"
                        placeholder=""
                        value={data.remain_slot}
                        onChange={this.onChange}
                        disabled
                      />
                    </Form.Field>
                  </Pane>
                </Pane>
              </>
            )}
          </Pane>
          <Pane className="row">
            <Pane className="col-lg-12 text-center">
              <Button
                type="submit"
                className="btn btn-save mt-4"
                disabled={loading}
                loading={loading}
              >
                <FormattedMessage id="create" defaultMessage="Create" />
              </Button>
            </Pane>
          </Pane>
        </Form>
        <NotificationSystem ref="notificationSystem" />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  createMyScreen: bindActionCreators(createMyScreen, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(injectIntl(CreateScreen));
