import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = (params) => {
  const size = params.size || 4;
  const page = params.page || 1;
  const type = params.type || 'Food'
  const state = params.state || 'VIC'

  return {
    method: 'GET',
    baseURL: Config.NEW_API_URL,
    url: `/n_banners?page[size]=${size}&page[number]=${page}&state=${state}&type=${type}`
  }
}

const parseResponse = response => {
  const data = [];
  if (response.data) {
    response.data.forEach((item) => {
      const itemNew = formatJson(response, item.type, item.id);
      data.push(itemNew);
    });
  }
  return data;
}

export { exec, parseResponse }
