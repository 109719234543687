import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getStoreInfo } from 'actions/stores';
import { Pane } from 'shared/components/layouts';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { QRCode } from 'shared/components';
import Modal from 'react-responsive-modal';
import ReactTooltip from "react-tooltip";

class StoreInfo extends Component {
  static propTypes = {
    auth: PropTypes.object,
    detail: PropTypes.object,
  };

  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { detail } = this.props;
    return (
      <>
          <Pane className="logo-stores serivice-detail">
          <Link to={`/${detail.user.id}`}>
              <img
                src={detail.user.profileImage}
                alt="logo"
                ref={(img) => (this.img = img)}
                onError={() =>
                  (this.img.src =
                    process.env.PUBLIC_URL + `/assets/images/default-image.png`)
                }
                className="logo-img"
              />
          </Link>

          <div className="block-qr-code" onClick={this.onOpenModal} data-tip data-for={`tooltip-qr-card`}>
                <span className="ic-common-qr-code" />
              </div>
              <div className="popover__content">Digital card</div>
              <ReactTooltip
                place="top"
                type="dark"
                effect="float"
                id={"tooltip-qr-card"}
              >
                Click to get digital card
              </ReactTooltip>
              <Modal
                className="popup-qrcode"
                classNames={{
                  closeIcon: 'customIconBtn'
                }}
                open={this.state.open}
                onClose={this.onCloseModal}
                center
                showCloseIcon={true}
              >
                <QRCode
                  onClose={this.onCloseModal}
                  qrcode={detail.user.qrcode}
                  storeInfo={detail.user}
                />
              </Modal>

            {/* <Icon name='qrcode' onClick={this.onOpenModal} style={{ cursor: 'pointer' }} />
              <Modal className="popup-qrcode"
                classNames={{
                  closeIcon: 'customIconBtn'
                }}
                open={this.state.open}
                onClose={this.onCloseModal}
                center
                showCloseIcon={true}>
                <QRCode
                  onClose={this.onCloseModal}
                  qrcode={detail.user.qrcode}
                  storeInfo={detail.user}
                />
              </Modal> */}
          </Pane>
        <Pane className="inner-box mt-4">
          <h2 className="inner-title">
            <FormattedMessage
              id="business_infomation"
              defaultMessage="BUSINESS INFOMARTION"
            />
          </h2>
          <Pane className="bussiness-content">
            <ul className="bussiness-list">
              <li className="bussiness-item">
                <span className="category-norm">
                  <FormattedMessage id="name" defaultMessage="Name" />:
                   &nbsp;{detail.user.name}
                </span>
              </li>
              <li className="bussiness-item">
                <span className="category-norm">
                  <FormattedMessage id="address" defaultMessage="Address" />:
                   &nbsp;{detail.user.address}
                </span>
              </li>
              <li className="bussiness-item">
                <span className="category-norm">
                  <FormattedMessage id="email" defaultMessage="Email" />:
                   &nbsp;{detail.user.email}
                </span>
              </li>
              <li className="bussiness-item">
                <span className="category-norm">
                  <FormattedMessage id="phone" defaultMessage="Phone" />:
                   &nbsp;{detail.user.phone}
                </span>
              </li>
            </ul>
          </Pane>
        </Pane>
        {/* <Pane className='card sidebar-card bg-contact-seller'>
          <Pane className='card-header'><FormattedMessage id='stores' defaultMessage='Stores' /></Pane>
          <Pane className='card-content user-info'>
            {
              detail && detail.user && detail.user.business && detail.user.business.name
              ? <Pane className='card-body'>
                  <Pane className='seller-info'>
                    <h3 className="no-margin">
                      <Link to={`/${this.props.detail.user.slug}`}>
                        { detail.user.business.name }
                      </Link>
                    </h3>
                    <Pane className='avatar mb15' data-tip data-for='business-profile'>
                      <Link to={`/${this.props.detail.user.slug}`} className='background-image' style={{ backgroundImage: `url(${detail.user.profileImage || process.env.PUBLIC_URL + '/assets/images/default-avatar.png'})` }}>
                        <img src={detail.user.profileImage || process.env.PUBLIC_URL + '/assets/images/default-avatar.png'} alt="" />
                      </Link>
                      <Pane className='qrcode'>
                        <Icon name='qrcode' onClick={this.onOpenModal} />
                      </Pane>
                    </Pane>
                    <Pane className='info'>
                      <span className="email btn-block"><i className="icon-mail-2" /> {detail.user.email}</span>
                      <span className="email btn-block mt5"><i className="icon-location" /> {detail.user.address}</span>
                      <span className="phone btn-block mt5"><i className="icon-phone-1" /> {detail.user.phone}</span>
                    </Pane>
                  </Pane>
                </Pane>
              : (
                  detail && detail.user && detail.user.id &&
                  <Pane className='card-body'>
                    <Pane className='seller-info'>
                      <h3 className='no-margin'>
                        <Link to={`/${this.props.detail.user.slug}`}>
                          { detail.user.name }
                        </Link>
                      </h3>
                      <Pane className='avatar mb15' data-tip data-for='business-profile'>
                        <Link to={`/${this.props.detail.user.slug}`} className='background-image' style={{ backgroundImage: `url(${detail.user.profileImage || process.env.PUBLIC_URL + '/assets/images/default-avatar.png'})` }}>
                          <img src={detail.user.profileImage || process.env.PUBLIC_URL + '/assets/images/default-avatar.png'} alt="" />
                        </Link>
                        <Pane className='qrcode'>
                          <Icon name='qrcode' onClick={this.onOpenModal} />
                        </Pane>
                      </Pane>
                      <Pane className='info'>
                        <span className="email btn-block"><i className="icon-mail-2" /> {detail.user.email}</span>
                        <span className="email btn-block mt5"><i className="icon-location" /> {detail.user.address}</span>
                        <span className="phone btn-block mt5"><i className="icon-phone-1" /> {detail.user.phone}</span>
                      </Pane>
                    </Pane>
                  </Pane>
                )
            }
          </Pane>
          <Modal className='popup-qrcode' open={this.state.open} onClose={this.onCloseModal} center showCloseIcon={false}>
            <QRCode
              qrcode={detail.user.qrcode}
              backgroundImage={detail.user.backgroundImage}
            />
          </Modal>
        </Pane>
        {
          detail && detail.user && detail.user.business && detail.user.business.id && detail.user.business.name && detail.user.business.email &&
          <ReactTooltip
            id="business-profile"
            place="top"
            type="dark"
            effect="float"
          >
            <BusinessInfo business={detail.user.business} />
          </ReactTooltip>
        } */}
      </>
    );
  }
}

const bindDispatchToProps = (dispatch) => ({
  getStoreInfo: bindActionCreators(getStoreInfo, dispatch),
});

export default connect(
  null,
  bindDispatchToProps
)(StoreInfo);
