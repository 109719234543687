import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { LandingLayout } from 'components/layout';
import { Pane } from 'shared/components/layouts';
import AboutUs from 'components/scenes/about-us/AboutUs';

class AboutUsPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <LandingLayout>
        <Pane className="about-container">
          <AboutUs />
          {/* <Pane className="container">
            <Pane className="row">
              <Pane className="col-lg-10 offset-lg-1">
                <h3 className="policy-title mb-4">About Us</h3>
                <FormattedHTMLMessage id="about_us.template" />
                <div className="testimonials">
                  <div className="row">
                    <div className="col-lg-3">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/testimonials/image_1.jpeg"
                        }
                        alt="gg"
                        className="image-testimonials"
                      />
                      <p className="text-norm">
                        <FormattedHTMLMessage id="cardbey_has_helped_me_increase" />
                      </p>
                    </div>
                    <div className="col-lg-5">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/testimonials/image_3.jpeg"
                        }
                        alt="gg"
                        className="image-testimonials"
                      />
                      <p className="text-norm">
                        <FormattedHTMLMessage id="cardbey_is_a_complete_product" />
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/testimonials/image_2.jpeg"
                        }
                        alt="gg"
                        className="image-testimonials"
                      />
                      <p className="text-norm">
                        <FormattedHTMLMessage id="cardbey_is_in_a_class" />
                      </p>
                    </div>
                  </div>
                </div>

                <h4>
                  <strong>
                    <FormattedHTMLMessage
                      id="stay_connected_widt_us"
                      defaultMessage="Stay connected with us."
                    />
                  </strong>
                </h4>
              </Pane>
            </Pane>
          </Pane> */}
        </Pane>
      </LandingLayout>
    );
  }
}

export default injectIntl(AboutUsPage);
