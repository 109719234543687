import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Image,
  Image as ImageS,
  Loader,
} from "semantic-ui-react";
import { Pane } from "shared/components/layouts";
import { formatRoute, handleError, formatJson } from "shared/helpers";
import InlineMessage from "shared/components/InlineMessage";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import Datetime from "react-datetime";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete from "react-places-autocomplete";
class EditStaffForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };
  state = {
    errors: {},
    loading: false,
    image: "",
    imageRender: "",
    imageFile: "",
    loadingAvatar: false,
    data: {
      image: "",
      name: "",
      email: "",
      startDate: "",
      endDate: "",
      phone: "",
      address: "",
      gender: "Male",
      status: "Working",
      birthday: "",
      salary: "",
    },
  };
  validate = (data) => {
    const errors = {};
    const { intl } = this.props;
    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.email) {
      errors.email = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.address) {
      errors.address = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.phone) {
      errors.birthday = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.birthday) {
      errors.birthday = intl.formatMessage({ id: "validate.require_field" });
    }
    if (data.salary && data.salary <= 0) {
      errors.salary = intl.formatMessage({ id: "validate.invalid_price" });
    }
    if (!data.startDate) {
      errors.startDate = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.endDate) {
      errors.endDate = intl.formatMessage({ id: "validate.require_field" });
    }
    if (
      moment(data.startDate, "YYYY-MM-DD").unix() >
      moment(data.endDate, "YYYY-MM-DD").unix()
    ) {
      errors.startDate = intl.formatMessage({
        id: "validate.invalid_compare_date",
      });
    }
    return errors;
  };
  componentDidMount() {
    this.getStaffDetail();
  }
  getStaffDetail = () => {
    this.props.getStaffDetail({ id: this.props.id }).then((response) => {
      if (response && response.data) {
        const staff = formatJson(
          response.data,
          response.data.data.type,
          response.data.data.id
        );
        const stringFormat = "YYYY-MM-DD";
        let url = "";
        if (staff.images && staff.images.length) {
          const [image] = staff.images;
          url = image.url;
        }
        this.setState({
          imageRender: url,
          imageFile: url,
          data: {
            ...this.state.data,
            image: url,
            name: staff.fullname,
            email: staff.email,
            startDate: moment(staff.startDate, stringFormat).format(
              stringFormat
            ),
            endDate: moment(staff.endDate, stringFormat).format(stringFormat),
            phone: staff.phone,
            address: staff.address,
            gender: staff.gender,
            status: staff.status,
            birthday: moment(staff.birthday, stringFormat).format(stringFormat),
            salary: +staff.salary,
          },
        });
      }
    });
  };
  editStaff = async () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (errors && errors.length) return;
    this.setState({ loading: true });
    this.addStaff();
  };
  addStaff = () => {
    const { userInfo } = this.props;
    const dataStaff = new FormData();
    dataStaff.append("fullname", this.state.data.name);
    dataStaff.append("gender", this.state.data.gender);
    dataStaff.append("start_date", this.state.data.startDate);
    dataStaff.append("end_date", this.state.data.endDate);
    dataStaff.append("birthday", this.state.data.birthday);
    dataStaff.append("email", this.state.data.email);
    dataStaff.append("phone", this.state.data.phone);
    dataStaff.append("address", this.state.data.address);
    dataStaff.append("salary", +this.state.data.salary);
    dataStaff.append("status", this.state.data.status);
    dataStaff.append("user_id", userInfo.id);
    
    if (this.state.imageFile && this.state.imageFile.type) {
      dataStaff.append("avatar", this.state.imageFile || "");
    }
    dataStaff.append("id", this.props.id);
    this.props
      .editMyStaff(dataStaff)
      .then(() => {
        this.setState({
          loading: false,
          data: {
            ...this.state.data,
            image: "",
            name: "",
            email: "",
            startDate: "",
            endDate: "",
            phone: "",
            address: "",
            gender: "Male",
            status: "Working",
            birthday: "",
          },
        });
        this.props.history.push(
          `/u/${formatRoute(this.props.userInfo.displayName)}/staffs`
        );
      })
      .catch((error) => {
        const errors = {};
        errors.message =
          "Staff: " +
          handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );
        this.setState({
          loading: false,
          errors,
        });
      });
  };
  onChangeTime(name, value) {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value.format("YYYY-MM-DD"),
      },
    });
  }
  onChangeText = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };
  onChangePhone = (phone) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        phone,
      },
    }));
  };
  onChangeRadioStatus = (e, { value }) => {
    this.setState({
      data: { ...this.state.data, status: value },
    });
  };
  onChangeRadioGender = (e, { value }) => {
    this.setState({
      data: { ...this.state.data, gender: value },
    });
  };
  onChangeAvatar = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          imageRender: reader.result,
          imageFile: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  onChangeAddress = (address) => {
    this.setState({
      data: { ...this.state.data, address },
    });
  };
  render() {
    const { errors, data, loading } = this.state;
    const { intl } = this.props;
    const renderFunc = ({
      getInputProps,
      getSuggestionItemProps,
      suggestions,
    }) => (
      <Pane className="autocomplete-root form-item">
        <input {...getInputProps()} className="form-input" placeholder="" />
        <Pane className="autocomplete-dropdown-container">
          {suggestions.map((suggestion, index) => (
            <Pane {...getSuggestionItemProps(suggestion)} key={index}>
              <span>{suggestion.description}</span>
            </Pane>
          ))}
        </Pane>
      </Pane>
    );
    return (
      <Form className="form-overview pb-5" onSubmit={this.editStaff}>
        <Pane className="form-content">
          <Pane className="row">
            <Pane className="col-lg-8">
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="full_name" className="form-label">
                      <FormattedMessage
                        id="full_name"
                        defaultMessage="Full Name"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      id="name"
                      name="name"
                      value={data.name}
                      placeholder=""
                      onChange={(e) =>
                        this.onChangeText("name", e.target.value)
                      }
                    />
                    {errors.name && <InlineMessage text={errors.name} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-6">
                  <Form.Field error={!!errors.startDate} className="form-item">
                    <label htmlFor="openTime" className="form-label">
                      <FormattedMessage
                        id="birthday"
                        defaultMessage="Date of birth"
                      />
                    </label>
                    <Datetime
                      closeOnSelect={true}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={this.onChangeTime.bind(this, "birthday")}
                      inputProps={{
                        placeholder: "YYYY-MM-DD",
                        name: "birthday",
                        readOnly: true
                      }}
                      value={data.birthday}
                    />
                    {errors.birthday && (
                      <InlineMessage text={errors.birthday} />
                    )}
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-6"></Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="gender" className="form-label">
                      <FormattedMessage id="gender" defaultMessage="Gender" />
                    </label>
                    <Pane className="d-flex">
                      <Pane className="d-flex align-items-center mr-5">
                        <Checkbox
                          className="mr-3"
                          radio
                          name="gender"
                          value="Male"
                          checked={data.gender === "Male"}
                          onChange={this.onChangeRadioGender}
                        />
                        <span>Male</span>
                      </Pane>
                      <Pane className="d-flex align-items-center">
                        <Checkbox
                          className="mr-3"
                          radio
                          name="gender"
                          value="Female"
                          checked={data.gender === "Female"}
                          onChange={this.onChangeRadioGender}
                        />
                        <span>Female</span>
                      </Pane>
                    </Pane>
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="email" className="form-label">
                      <FormattedMessage id="email" defaultMessage="Email" />
                    </label>
                    <input
                      type="email"
                      className="form-input"
                      id="email"
                      name="email"
                      onChange={(e) =>
                        this.onChangeText("email", e.target.value)
                      }
                      value={data.email}
                      placeholder=""
                    />
                    {errors.email && <InlineMessage text={errors.email} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="salary" className="form-label">
                      <FormattedMessage id="salary" defaultMessage="Salary" />
                    </label>
                    <input
                      type="number"
                      className="form-input"
                      id="salary"
                      name="salary"
                      value={data.salary}
                      onChange={(e) =>
                        this.onChangeText("salary", e.target.value)
                      }
                      placeholder=""
                    />
                    {errors.salary && <InlineMessage text={errors.salary} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="address" className="form-label">
                      <FormattedMessage id="address" defaultMessage="Address" />
                    </label>
                    <PlacesAutocomplete
                      value={data.address}
                      name="address"
                      onChange={this.onChangeAddress}
                      searchOptions={{
                        componentRestrictions: { country: "au" },
                      }}
                    >
                      {renderFunc}
                    </PlacesAutocomplete>
                    {errors.address && <InlineMessage text={errors.address} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="email" className="form-label">
                      <FormattedMessage
                        id="phone"
                        defaultMessage="Phone number"
                      />
                    </label>
                    <PhoneInput
                      country={"au"}
                      value={data.phone}
                      onChange={(phone) => this.onChangePhone(phone)}
                      style={{ width: "100%" }}
                      placeholder={intl.formatMessage({ id: "phone" })}
                    />
                    {errors.phone && <InlineMessage text={errors.phone} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-6">
                  <Form.Field error={!!errors.startDate} className="form-item">
                    <label htmlFor="openTime" className="form-label">
                      <FormattedMessage
                        id="start_date"
                        defaultMessage="Start date"
                      />
                    </label>
                    <Datetime
                      closeOnSelect={true}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={this.onChangeTime.bind(this, "startDate")}
                      inputProps={{
                        placeholder: "YYYY-MM-DD",
                        name: "startDate",
                        readOnly: true
                      }}
                      value={data.startDate}
                    />
                    {errors.startDate && (
                      <InlineMessage text={errors.startDate} />
                    )}
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-6">
                  <Form.Field error={!!errors.endDate} className="form-item">
                    <label htmlFor="closeTime" className="form-label">
                      <FormattedMessage
                        id="end_date"
                        defaultMessage="End date"
                      />
                    </label>
                    <Datetime
                      closeOnSelect={true}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={this.onChangeTime.bind(this, "endDate")}
                      inputProps={{
                        placeholder: "YYYY-MM-DD",
                        name: "endDate",
                        readOnly: true
                      }}
                      value={data.endDate}
                    />
                    {errors.endDate && <InlineMessage text={errors.endDate} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="status" className="form-label">
                      <FormattedMessage id="status" defaultMessage="Status" />
                    </label>
                    <Pane className="d-flex">
                      <Pane className="d-flex align-items-center mr-3">
                        <Checkbox
                          className="mr-3"
                          radio
                          name="status"
                          value="Working"
                          checked={data.status === "Working"}
                          onChange={this.onChangeRadioStatus}
                        />
                        <span>Working</span>
                      </Pane>
                      <Pane className="d-flex align-items-center">
                        <Checkbox
                          className="mr-3"
                          radio
                          name="status"
                          value="Terminated"
                          checked={data.status === "Terminated"}
                          onChange={this.onChangeRadioStatus}
                        />
                        <span>Not Working</span>
                      </Pane>
                    </Pane>
                  </Form.Field>
                </Pane>
              </Pane>
            </Pane>
            <Pane className="col-lg-4">
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label className="form-label">
                      <FormattedMessage id="image" defaultMessage="Image" />
                    </label>
                    <input
                      type="file"
                      id="image-menu"
                      className="form-input-file"
                      name="image"
                      placeholder=""
                      value=""
                      accept="image/gif, image/jpeg, image/png"
                      disabled={this.state.loadingAvatar}
                      onChange={this.onChangeAvatar}
                    />
                    <br />
                    <label htmlFor="image-menu" className="form-label-file">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/icons/upload.svg`
                        }
                        alt="upload"
                        className="icon"
                      />
                      Upload a photo
                    </label>
                    <Pane
                      className="slider-content-main"
                      style={{ padding: "0px 0" }}
                    >
                      {this.state.loadingAvatar ? (
                        <Pane
                          style={{
                            position: "relative",
                            top: "-24px",
                            float: "right",
                            paddingRight: "50px",
                          }}
                        >
                          <Dimmer active inverted>
                            <Loader size="small" />
                          </Dimmer>
                        </Pane>
                      ) : null}
                      {this.state.imageRender ? (
                        <Image
                          src={this.state.imageRender}
                          size="small"
                          style={{
                            marginTop: "10px",
                            height: "200px",
                            width: "300px",
                          }}
                        />
                      ) : (
                        <Image
                          src={
                            this.state.image
                              ? this.state.image
                              : process.env.PUBLIC_URL +
                                "/assets/images/default-avatar.png"
                          }
                          size="small"
                          style={{
                            marginTop: "10px",
                            height: "200px",
                            width: "300px",
                          }}
                        />
                      )}
                      {errors.image && <InlineMessage text={errors.image} />}
                    </Pane>
                  </Form.Field>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
        <Pane className="row">
          <Pane className="col-lg-12 text-center">
            <Button
              type="submit"
              className="btn btn-save mt-4"
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="edit" defaultMessage="Edit" />
            </Button>
          </Pane>
        </Pane>
      </Form>
    );
  }
}
export default injectIntl(EditStaffForm);