import { Form, Button } from 'semantic-ui-react';
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import {
	FacebookLoginButton,
	GoogleLoginButton,
} from 'react-social-login-buttons';
import Validator from 'validator';
import { InlineMessage } from 'shared/components';
import _ from 'lodash';
import SocialButton from 'components/scenes/signin/SocialButton';
import Config from 'constants/Config';
import { Link } from 'react-router-dom';


class LoginModal extends Component {
	static propTypes = {
		onNotification: PropTypes.object,
		intl: intlShape.isRequired,
		signin: PropTypes.func.isRequired,
		auth: PropTypes.object.isRequired,
	};

	constructor(props, context) {
		super(props, context);

		this.state = {
			data: {
				email: '',
				password: ''
			},
			loading: false,
			errors: {},
			authError: '',
		};
	}

	handleSocialLogin = (res) => {
		if (!res && !res.token.accessToken) return;
		const data = {
			token: res.token.accessToken,
			provider: res.provider,
		};
		this.props
			.signinSocial(data)
			.then(() => {
				this.setState({ loading: false });
				this.props.onCloseModal();
			})
	};

	handleSocialLoginFailure = () => { };

	onChange = (e) => {
		this.setState({
			data: { ...this.state.data, [e.target.name]: e.target.value },
		});
	};

	validate = (data) => {
		const errors = {};
		const { intl } = this.props;

		if (!data.email) {
			errors.email = intl.formatMessage({ id: 'validate.require_field' });
		}
		if (data.email && !Validator.isEmail(data.email)) {
			errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
		}
		if (!data.password) {
			errors.password = intl.formatMessage({ id: 'validate.require_field' });
		}

		return errors;
	};

	onSubmit = () => {
		let errors = {};
		errors = this.validate(this.state.data);

		this.setState({ errors });
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true });

			const data = new URLSearchParams();

			data.append('email', this.state.data.email);
			data.append('password', this.state.data.password);

			this.props.signin(data).then(() => {
				this.setState({ loading: false });
				// if (this.props.isCheckout) {
				// 	this.props.history.push("/checkout");
    			// 	localStorage.removeItem("deliveryInfo");
				// }
				if (this.props.isCheckout) {
					this.props.onCloseModal(true);
				}
			});
		}
	};

	render() {
		const { errors, authError } = this.state;
		const {
			auth,
			openModal,
			onCloseModal
		} = this.props;

		return (
			<Modal
				open={openModal}
				onClose={onCloseModal}
				center
				showCloseIcon={true}
			>
				<Pane className="login-container popup-login">
					<Pane className="wrap-login d-flex align-items-center justify-content-center flex-column">
						<h4 className="login-title" style={{ fontSize: '25px' }}>Sign In</h4>
						{!this.props.isCheckout &&(
							<h5 className='align-self-start p-0'>Please sign in to comment or like.</h5>
						)}
						<Form onSubmit={this.onSubmit} noValidate>
							<Form.Field error={!!errors.email} className="form-item">
								<label htmlFor="email" className="form-label">
									E-MAIL
								</label>
								<input
									className="form-input"
									id="email"
									type="text"
									name="email"
									placeholder="Enter email"
									onChange={this.onChange}
								/>
								{errors.email && <InlineMessage text={errors.email} />}
							</Form.Field>
							<Form.Field error={!!errors.password} className="form-item">
								<label htmlFor="password" className="form-label">
									PASSWORD
								</label>
								<input
									className="form-input"
									id="password"
									type="password"
									name="password"
									placeholder="Enter your password"
									onChange={this.onChange}
								/>
								<span className="help-password">
									<FormattedMessage id="help_password" />
								</span>
								{errors.password && <InlineMessage text={errors.password} />}
							</Form.Field>
							{_.isEmpty(errors) &&
								!authError &&
								!auth.fetching &&
								auth.errors &&
								auth.errors.detail && <InlineMessage text={auth.errors.detail} />}
							{authError && <InlineMessage text={authError} />}
							<Pane className="form-item form-button">
								<Button
									type="submit"
									disabled={auth.fetching}
									loading={auth.fetching && authError === ''}
									className="btn btn-circle btn-form"
								>
									SIGN IN
								</Button>
							</Pane>
						</Form>
						<Link to="/forgot-password" className="form-link">
							Forgot Password ?
						</Link>
						<span className="text-or w-100">OR</span>
						<ul className="form-social">
							<li className="social-item social-fb">
								<SocialButton
									provider="facebook"
									appId={Config.FACEBOOK_CLIENT_ID}
									element={FacebookLoginButton}
									scope={['email']}
									onLoginSuccess={this.handleSocialLogin}
									onLoginFailure={this.handleSocialLoginFailure}
								>
									<img
										src={process.env.PUBLIC_URL + `/assets/images/login/fb.svg`}
										alt="facebook"
										className="social-icon"
									/>
								</SocialButton>
							</li>
							<li className="social-item social-gm">
								<SocialButton
									provider="google"
									appId={Config.GOOGLE_CLIENT_ID}
									element={GoogleLoginButton}
									scope={['email']}
									onLoginSuccess={this.handleSocialLogin}
									onLoginFailure={this.handleSocialLoginFailure}
								>
									<img
										src={process.env.PUBLIC_URL + `/assets/images/login/gg.svg`}
										alt="gg "
										className="social-icon"
									/>
								</SocialButton>
							</li>
						</ul>
						<p className="text-registered">
							Not Registerd?&nbsp;
							<Link to={`/signup`} className="link-registered">
								<strong>Sign Up</strong>
							</Link>
						</p>
					</Pane>
				</Pane>
			</Modal>
		);
	}
}

export default withRouter(injectIntl(LoginModal));
