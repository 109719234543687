import Config from "constants/Config";
import React, { Component } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import { FacebookShareButton } from "react-share";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";

class Menu extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      tableId: queryString.parse(search).tableId || null,
      MENU_STORE: [
        // {
        //   url: "",
        //   icon: "overview",
        //   label: "OVERVIEW",
        //   language: "overview",
        // },
        {
          url: "",
          icon: "video-player",
          label: "My Collections",
          language: "my_collections",
        },
      ]
    };
  }

  render() {
    const {
      storeInfo,
      location: { pathname },
      toggleMenu,
    } = this.props;


    const { tableId } = this.state;
    return (
      <Pane
        className={classnames("inner-box inner-overview", {
          "inner-overvier-active": toggleMenu,
        })}
      >
        <Pane className="category-content">
          {storeInfo &&
            storeInfo.business && (
              <ul className="category-list">
                {this.state.MENU_STORE.map((item, index) => {
                  return (
                    <li className="category-item" key={index}>
                        <Link
                          className={
                            pathname === `/${storeInfo.id}/${item.url}`
                              ? "category-link category-active"
                              : "category-link"
                          }
                          to={`/users/${storeInfo.id}${item.url ? `/${item.url}` : ''}`}
                        >
                          <span className={`icon ic-common-${item.icon}`} />
                          <FormattedMessage
                            id={item.language}
                            defaultMessage={item.label}
                          />
                        </Link>
                    </li>
                  );
                })}
              </ul>
            )}
        </Pane>
      </Pane>
    );
  }
}
export default withRouter(Menu);
