import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-responsive-modal";
import { injectIntl } from "react-intl";
import { formatDecimal } from "shared/helpers";
import { Currency } from "shared/components";
import { get } from "lodash";
import StockDetailLabel from "./StockDetailLabel";
import SlideImage from "../../components/scenes/services/detail/SlideImage";
import { addStoreBookingsRequest } from "actions/storeBookings";

class ServiceOptionModal extends Component {
  static propTypes = {};
  state = {
    activeIndex: 0,
    optionIds: [],
    children: {}
  };

  constructor(props, context) {
    super(props, context);
  }

  setActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };

  onGetCurrent = (originalPrice, promotion) => {
    if (promotion && promotion.percent) {
      return formatDecimal(
        originalPrice - (originalPrice * promotion.percent) / 100
      );
    }
    return formatDecimal(originalPrice);
  };

  getTotalPriceOptions = () => {
    let totalPirce = 0;

    const { detail } = this.props;

    if (!detail || !detail.options || !detail.options.length) return 0;

    this.getOptions().forEach((option) => {
      const { childrens } = option;

      childrens.forEach((children) => {
        const childIncludes = this.includesOption(children.id);
        if (childIncludes) {
          totalPirce +=
            Number(children.price || 0) * Number(childIncludes.quantity);
        }
      });
    });

    return totalPirce;
  };

  getOptions = () => {
    const { detail } = this.props;
    return detail.options || [];
  };

  updateOptionQuantity = (id, quantity, type) => {
    if (type !== 3) return;
    let updateOptionIds = this.state.optionIds || [];
    const index = updateOptionIds.findIndex((item) => item.id === id);

    if (index === -1) return;
    const qty = updateOptionIds[index].quantity + quantity;
    if (qty <= 0) return;

    updateOptionIds[index].quantity = qty;
    this.setState({ optionIds: updateOptionIds });
  };

  updateOptionIds = (id, check, deleteIds = [], children = {}) => {
    let updateOptionIds = this.state.optionIds || [];

    if (deleteIds && deleteIds.length) {
      updateOptionIds = updateOptionIds.filter(
        (item) => !deleteIds.includes(item.id)
      );
    }

    if (!check) {
      updateOptionIds = updateOptionIds.filter((item) => item.id !== id);
    } else {
      const updateOptionId = updateOptionIds.find((item) => item.id === id);
      if (!updateOptionId) {
        updateOptionIds.push({ id: id, quantity: 1 });
      }
    }

    if (children) this.setState({ children });

    this.setState({ optionIds: updateOptionIds });
  };

  getOptionIds(detail) {
    const { options, optionInfo } = detail;
    if (optionInfo && optionInfo.length) {
      this.setState({ optionIds: optionInfo });
      return;
    }

    if (options && options.length) {
      const ids = [];
      let children = {};
      options.forEach((option) => {
        const { childrens } = option;
        children = childrens.find((child) => child.isCheck);
        ids.push(
          ...childrens
            .filter((child) => child.isCheck)
            .map((child) => ({ id: child.id, quantity: 1 }))
        );
      });

      if (children) this.setState({ children });

      this.setState({ optionIds: ids });
      return;
    }

    this.setState({ optionIds: [] });
  }

  includesOption = (id) => {
    return this.state.optionIds.find((child) => child.id === id);
  };

  onSubmit = () => {
    const { detail } = this.props;
    if (detail) {
      let booking = {
        id: detail.id,
        name: detail.name,
        originalPrice: detail.originalPrice,
        promotion: detail.promotion,
        duration: detail.duration,
        userId: detail.userId
      }
      if (this.state.children) booking.children = this.state.children
      this.props.addStoreBookingsRequest(booking);

      this.props.history.push(`/store-booking/${detail.userId}`);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.detail) {
      this.getOptionIds(nextProps.detail);
    }
  }

  render() {
    const { detail } = this.props;
    return (
      <Modal
        open={this.props.openModal}
        showCloseIcon={false}
        onClose={this.props.onClose}
        onEntered={() => {
          this.getOptionIds(this.props.detail);
        }}
      >
        <Pane className="sidebar-container sidebar-option">
          <Pane className="sidebar-header">
            <span className="text-header">{detail.categoryName}</span>
            <span className="icon-close" onClick={this.props.onClose}>
              X
            </span>
          </Pane>
          <Pane className="sidebar-detail">
            <Pane className="details-container">
              <Pane className="category-details">
                <SlideImage
                  id={detail.id}
                  optionIds={this.state.optionIds || []}
                  onSubmit={this.onSubmit}
                  detail={detail}
                />
              </Pane>
            </Pane>
          </Pane>
          <Pane className="sidebar-content">
            <Pane className="sidebar-title">{detail.name}</Pane>
            {/* <Pane className="sidebar-des">
              <Rating
                rating={detail.averageRating}
                icon="star"
                size="huge"
                maxRating="5"
                disabled
              />
            </Pane> */}
            {/* <Pane className="details-container">
              <Pane className="category-details">
                <Pane className="category-content">
                  <StockDetailLabel
                    inStock={detail.inventoryStatus}
                    type="services"
                  />
                </Pane>
              </Pane>
            </Pane> */}
            <Pane className="details-container">
              <Pane className="category-details">
                <Pane className="category-content">
                  {get(detail, "promotion.percent") && (
                    <>
                      <h4 className="category-price">
                        <Currency
                          price={this.onGetCurrent(
                            Number(get(detail, "originalPrice")) +
                            this.getTotalPriceOptions(),
                            detail.promotion
                          )}
                        />
                      </h4>
                      <h4 className="category-price text-underline">
                        <Currency
                          price={formatDecimal(
                            Number(get(detail, "originalPrice")) +
                            this.getTotalPriceOptions()
                          )}
                        />
                      </h4>
                    </>
                  )}
                  {!get(detail, "promotion.percent") && (
                    <h4 className="category-price category-price-red">
                      <Currency
                        price={
                          Number(detail.originalPrice) +
                          this.getTotalPriceOptions()
                        }
                      />
                    </h4>
                  )}
                </Pane>
              </Pane>
            </Pane>
          </Pane>
          {this.getOptions().length !== 0 ? (
            <Pane className="sidebar-option-content">
              {this.getOptions().map((option) => {
                const { childrens, type, name } = option;

                const jsxOptDetails = childrens.map((children) => {
                  let control = (
                    <input
                      type={"checkbox"}
                      id={children.id}
                      name={"checkbox" + "_" + option.name}
                      onChange={(e) => {
                        this.updateOptionIds(children.id, e.target.checked, [], children);
                      }}
                      checked={!!this.includesOption(children.id)}
                    />
                  );

                  if (type === 1) {
                    control = (
                      <input
                        type={"radio"}
                        id={children.id}
                        name={"radio" + "_" + option.name}
                        onChange={(e) => {
                          this.updateOptionIds(
                            children.id,
                            true,
                            childrens.map((chil) => chil.id),
                            children
                          );
                        }}
                        checked={!!this.includesOption(children.id)}
                      />
                    );
                  }

                  return (
                    <Pane className="sidebar-option-item" key={children.id}>
                      <Pane className="option-control">
                        <Pane className="option-control-left">
                          {control}
                          <label htmlFor={children.name}>{children.name}</label>
                        </Pane>
                        <Pane className="price-options">
                          {children.price > 0 && (
                            <Pane>
                              <span>+</span>
                              <Currency price={formatDecimal(children.price)} />
                            </Pane>
                          )}

                          {type === 3 ? (
                            <Pane className="option-quantity">
                              <Pane
                                className="option-button"
                                onClick={() => {
                                  this.updateOptionQuantity(
                                    children.id,
                                    1,
                                    type
                                  );
                                }}
                              >
                                +
                              </Pane>
                              <Pane className="option-number">
                                {!!this.state.optionIds.find(
                                  (item) => item.id === children.id
                                )
                                  ? this.state.optionIds.find(
                                    (item) => item.id === children.id
                                  ).quantity
                                  : 1}
                              </Pane>
                              <Pane
                                className="option-button"
                                onClick={() => {
                                  this.updateOptionQuantity(
                                    children.id,
                                    -1,
                                    type
                                  );
                                }}
                              >
                                -
                              </Pane>
                            </Pane>
                          ) : null}
                        </Pane>
                      </Pane>
                    </Pane>
                  );
                });

                return (
                  <Pane key={option.id} className={`sidebar-group-options`}>
                    <Pane className="sidebar-group-top">
                      <span className="sidebar-group-text">{name}</span>
                    </Pane>
                    <Pane className="sidebar-option-list">{jsxOptDetails}</Pane>
                  </Pane>
                );
              })}
            </Pane>
          ) : null}
        </Pane>
      </Modal>
    );
  }
}


const bindDispatchToProps = (dispatch) => ({
  addStoreBookingsRequest: bindActionCreators(addStoreBookingsRequest, dispatch),
});

export default withRouter(injectIntl(connect(
  null,
  bindDispatchToProps
)(ServiceOptionModal)));
