import PropTypes from 'prop-types';
import Config from 'constants/Config';
import React, { Component } from 'react';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import { Pane } from 'shared/components/layouts';
import { confirmAlert } from 'react-confirm-alert';
import { PromotionLabel } from 'shared/components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
  };

  state = {
    play: 0,
    extVideo: Config.extVideo
  };

  changeRoute = () => {
    this.props.history.push(`digital-edit/${this.props.id}/${this.props.type}`);
  };

  showConfirmDelete = () => {
    const { intl } = this.props;

    confirmAlert({
      title: intl.formatMessage({ id: 'confirmation' }),
      message: `${intl.formatMessage({
        id: 'notification.are_you_sure_you_want_to_delete_this',
      })}?`,
      buttons: [
        {
          label: intl.formatMessage({ id: 'yes' }),
          onClick: () => {
            this.deleteItem();
          },
        },
        {
          label: intl.formatMessage({ id: 'no' }),
        },
      ],
    });
  };

  deleteItem = () => {
    this.props.onDelete({ id: this.props.id });
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    video.play();
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  render() {
    const { item, type } = this.props;
    const { extVideo } = this.state
    //const extImage = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
    const file_src = type === 'Horizontal' ? get(item, 'imageHorizontal.url') : get(item, 'imageVertical.url')
    const ext = file_src ? file_src.substr(file_src.lastIndexOf('.') + 1) : '';
    return (
      <Pane className="col-lg-3 col-md-4 col-sm-6 col-12">
        <Pane className="adds-item">
          <Pane className="adds-container overflow-hidden">
            {!extVideo.includes(ext) ? (
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: file_src
                    ? `url(${file_src})`
                    : process.env.PUBLIC_URL +
                    `/assets/images/default-image.png`,
                  backgroundSize: 'contain'
                }}
              >
                <PromotionLabel
                  promotion={this.props.item && this.props.item.promotion}
                  originalPrice={
                    this.props.item && this.props.item.originalPrice
                  }
                />
              </Pane>
            ) : (
                <Pane
                  className="adds-videos-wrapper"
                  style={{height: '100px'}}
                >
                  {ext !== '' ? <i className="fa fa-video" /> : ''}
                  <video
                    className="myvideo-list"
                    playsInline={1}
                    onMouseOver={this.playVideo}
                    onMouseOut={this.stopVideo}
                    muted={!this.state.play}
                  >
                    <source src={file_src} id="video_here" />
                  Your browser does not support HTML5 video.
                </video>
                  <PromotionLabel
                    promotion={this.props.item && this.props.item.promotion}
                    originalPrice={
                      this.props.item && this.props.item.originalPrice
                    }
                  />
                </Pane>
              )}
          </Pane>
          <Pane className="adds-content">
            <Pane>
              <h5 className="adds-norm" title={get(item, 'title')}>
                {get(item, 'title')}
              </h5>
            </Pane>
            {this.props.control && (
              <Pane className="adds-actions">
                <span onClick={this.changeRoute} data-tip data-for="edit">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/edit.svg`
                    }
                    alt="cart"
                    className="icon-edit"
                  />
                </span>
                <span
                  onClick={this.showConfirmDelete}
                  data-tip
                  data-for="remove"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/delete.svg`
                    }
                    alt="cart"
                    className="icon-delete"
                  />
                </span>
              </Pane>
            )}
          </Pane>
        </Pane>
        <ReactTooltip place="top" type="dark" effect="solid" id="edit">
          <FormattedMessage id="edit" defaultMessage="Edit" />
        </ReactTooltip>
        <ReactTooltip place="top" type="dark" effect="solid" id="remove">
          <FormattedMessage id="delete" defaultMessage="Delete" />
        </ReactTooltip>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
