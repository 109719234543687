import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import ScrollAnimation from "react-animate-on-scroll";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";

class BusinessBanner extends Component {
  render() {
    return (
      <Pane className="business-container">
        <Pane className="business-banner">
          <Pane className="container">
            <Pane className="row">
              <Pane className="col-lg-12">
                <ScrollAnimation
                  duration={3}
                  animateOnce={true}
                  animateIn="fadeIn"
                >
                  <Pane className="business-content text-center">
                    <h2 className="banner-title">
                      <FormattedMessage
                        id="start_your_business_journey_with_cardbey"
                        defaultMessage="Start your business journey with Cardbey"
                      />
                    </h2>
                    <p className="banner-norm">
                      <FormattedMessage
                        id="explore_the_tools_and_services_that_help_you_start"
                        defaultMessage="Explore the tools and services that help you start, run,
                      and grow your business"
                      />
                    </p>
                    <button
                      className="btn btn-circle btn-circle-primary business-button mt-3"
                      onClick={() => this.props.history.push("/signup")}
                    >
                      <FormattedMessage
                        id="create_your_online_business_for_free"
                        defaultMessage="Create your online business for free"
                      />
                    </button>
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <button
                        className="btn btn-circle btn-learn-more d-block"
                        onClick={() => this.props.history.push(`/`)}
                      >
                        <FormattedMessage
                          id="go_to_stores"
                          defaultMessage="Go to stores"
                        />
                      </button>
                    </div>
                  </Pane>
                </ScrollAnimation>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(BusinessBanner);
