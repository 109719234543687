import React, { Component } from "react";
import PropTypes from "prop-types";
import { Pane } from "shared/components/layouts";
import moment from "moment";
import { FormattedMessage } from "react-intl";

class OrderSummary extends Component {
  static propTypes = {
    detail: PropTypes.object,
  };

  render() {
    const { detail } = this.props;

    return (
      <>
        <Pane className="order-sub">
          <Pane className="order-sub__block">
            <FormattedMessage
              id="order_number"
              defaultMessage="Order number"
            />
            <Pane>
              {detail.id}
            </Pane>
          </Pane>
          <Pane className="order-sub__block">
            <FormattedMessage
              id="payment_method"
              defaultMessage="Payment method"
            />
            <Pane>
              {detail.paymentMethod}
            </Pane>
          </Pane>
          <Pane className="order-sub__block">
            <Pane>Order Time</Pane>
            <Pane>
              {moment.utc(detail.orderDate).format("DD-MM-YYYY HH:mm")}
            </Pane>
          </Pane>
        </Pane>
      </>
    );
  }
}

export default OrderSummary;
