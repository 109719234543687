import * as types from 'constants/ActionTypes'
import UserAdapter from 'services/users/adapter'
import normalize from 'json-api-normalizer'

const initialState = {
  results: [],
  categories: [],
  collections: [],
  schema: [],
  totalItems: 0,
  totalPages: 0,
  error: null,
  fetching: false,
  fetched: false
}

const myCollections = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_MY_COLLECTIONS:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_MY_COLLECTION_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: UserAdapter.collectionCategories.parseResponse(action.payload.data),
        collections: action.payload.data,
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_COLLECTIONS_SUCCESS:
      return Object.assign({}, state, {
        results: UserAdapter.collectionBrowse.parseResponse(action.payload.data),
        collections: action.payload.data,
        schema: normalize(action.payload.data, {
          camelizeTypeValues: true,
          camelizeKeys: true
        }),
        totalItems: action.payload.data.meta.totalItems,
        totalPages: action.payload.data.meta.totalPages,
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_MY_COLLECTIONS_FAILURE:
      return Object.assign({}, state, {
        totalItems: 0,
        totalPages: 0,
        results: [],
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        results: [],
        totalItems: 0,
        totalPages: 0,
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export {  myCollections }
