import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { MasterLayout } from 'components/layout';
import { searchAll } from 'actions/search';
import { addArea, getAreaByBusiness } from 'actions/area';
import N_Search from 'components/scenes/search/N_Search';
import { Pane } from 'shared/components/layouts';

class SearchPage extends Component {
  static propTypes = {
    auth: PropTypes.object,
    searchAll: PropTypes.func.isRequired,
  };

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <Pane className="details-container pt-4 pb-4">
            <Pane className="container">
              <N_Search
                auth={this.props.auth}
                type={queryString.parse(this.props.location.search).type}
                query={queryString.parse(this.props.location.search).query}
                onSearchAll={this.props.searchAll}
                onAddArea={this.props.addArea}
                onGetAreaByBusiness={this.props.getAreaByBusiness}
                area={this.props.area}
              />
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  area: state.area
});

const bindDispatchToProps = (dispatch) => ({
  searchAll: bindActionCreators(searchAll, dispatch),
  getAreaByBusiness: bindActionCreators(getAreaByBusiness, dispatch),
  addArea: bindActionCreators(addArea, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(SearchPage);
