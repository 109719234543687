import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _, { get } from "lodash";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Pane, Loading } from "shared/components/layouts";
import { Dimmer } from 'semantic-ui-react';
import { Currency } from "shared/components";
import { formatRoute } from "shared/helpers";
import axios from 'axios';

class BookingSuccess extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    onGetBookingDetailsOfBuyers: PropTypes.func,
  };

  state = {
    detail: {},
    loading: false
  };

  getBookingDetail = (bookingId) => {
    const { detail } = this.props;

    this.setState({ loading: true });

    this.props.onGetBookingDetailsOfBuyers({ bookingId, firstRead: true }).then(() => {
      // Push notification to seller
      // if (detail && detail.id) {
      //   this.props
      //     .onPushNotification({
      //       object_id: bookingId.toString(),
      //       object_type: "Booking",
      //       target_id: detail.bookingItems[0].seller.id.toString(),
      //       target_type: "Seller",
      //       summary: `New Booking #${bookingId}`,
      //       meta: {
      //         url: `/u/${formatRoute(
      //           detail.bookingItems[0].seller.name,
      //           "-"
      //         )}/bookings/sellers/${bookingId}/detail`,
      //         serviceId: detail.bookingItems[0].serviceId.toString(),
      //         serviceName: detail.serviceName,
      //       },
      //     })
      //     .catch(() => {
      //       // this._notificationSystem.addNotification({
      //       //   message: 'Something\'s wrong about notification',
      //       //   level: 'error'
      //       // })
      //     });
      // }
      if (detail && detail.bookingItems && detail.bookingItems.length > 0) {
        this.notifi(detail, bookingId);
      }
      this.setState({ loading: false });
    });
  };

  notifi = (detail, bookingId) => {
    let params = {
      receiverIds: [],
      heading: {},
      content: '',
      customData: {}
    };
    params.receiverIds = [detail.bookingItems[0].seller.id.toString()];
    params.heading = 'Cardbey';
    params.content = `New Booking #${bookingId}`;
    params.customData = {
      screen: 'BookingDetail',
      data: {
        bookingId,
      },
    }
    axios({
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: 'Basic MjRmNTU5MWEtZmNiYi00MGVjLTlhMjktZGJjNmIzNTZmNGUw',
        'content-type': 'application/json',
      },
      url: 'https://onesignal.com/api/v1/notifications',
      data: JSON.stringify({
        app_id: '9b9e4918-465c-4dbd-83a3-8958c7d2b07e',
        include_aliases: {
          external_id: params.receiverIds
        },
        headings: { en: params.heading },
        contents: { en: params.content },
        name: 'NEW_ORDER',
        target_channel: 'push',
        data: params.customData,
        ios_badgeType: 'Increase',
        ios_badgeCount: 1,
      })
    });
  }

  isOptions = (item) => {
    if (!item.options) return false;
    if (!item.options.length) return false;

    return true;
  };

  includesOption = (id, item) => {
    const { optionInfo } = item;
    if (!optionInfo) return null;

    return optionInfo.find((child) => child.id === id);
  };

  getSelectedOptions = (item) => {
    if (!this.isOptions(item)) return [];

    const { options } = item;
    let selectdOptions = [];

    options.map((option) => {
      const { childrens } = option;
      childrens.forEach((child) => {
        if (this.includesOption(child.id, item)) {
          selectdOptions.push({
            ...child,
            quantity: this.includesOption(child.id, item).quantity,
          });
        }
      });
    });
    return selectdOptions;
  };

  mappingSelectOptionToText = (item) => {
    if (!item) return "";
    const selectedOptions = this.getSelectedOptions(item);
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${child.name
          }`
      )
      .join(", ");
  };

  getTotalPriceOptions = (item) => {
    let totalPirce = 0;

    if (!item || !item.options || !item.options.length) return 0;

    item.options.forEach((option) => {
      const { childrens } = option;

      childrens.forEach((children) => {
        const childIncludes = this.includesOption(children.id, item);
        if (childIncludes) {
          totalPirce +=
            Number(children.price || 0) * Number(childIncludes.quantity);
        }
      });
    });
    return Number(item.quantity || 1) * totalPirce;
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);

    if (params) {
      this.getBookingDetail(params.get("orderId"));
    }
  }

  handleEst = () => {
    let est = 0;
    let time = 0;
    const { detail } = this.props;
    let items = detail.bookingItems;
    items && items.map((item) => {
      est = Math.round((parseFloat(est) + parseFloat(item.duration)) * 1e12) / 1e12
    });
    let timeSlotSelected = items && items[0].fromTime;
    if (est > 0) {
      let duration = est * 60;
      time = duration;
      let hours = Math.trunc(duration / 60);
      let minutes = duration % 60;
      est = `${hours}h${minutes > 0 ? minutes : ''}`;
    }



    return `${est} duration, ends at ${moment(timeSlotSelected, 'HH:mm').add(parseInt(time), 'minutes').format('HH:mm')}`;
  }

  render() {
    const { detail, userInfo } = this.props;

    let linkStore = `${detail.bookingItems
      ? detail.bookingItems[0]
        ? detail.bookingItems[0].seller.id
        : ""
      : ""
      }`;

    let storeInfo = detail.bookingItems && detail.bookingItems[0] ? detail.bookingItems[0].seller : null;
    return (
      <>
        {!this.state.loading ? (
          <Pane className="checkout-container pt-5 pb-5">
            <Pane className="container">
              <Pane className="row">
                <h2 className="title-checkout mb-3">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/check-green.svg"
                    }
                    alt="green"
                    className="icon"
                  />
                  <FormattedMessage
                    id="notification.your_booking_has_been_saved_successfully"
                    defaultMessage="Your booking has been saved successfully"
                  />
                </h2>
                <Pane className="checkout-content">
                  <Pane className="row">
                    <Pane className="col-lg-4">
                      <Pane className="checkout-info">
                        <h2 className="checkout-title mb-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/cart/user.svg"
                            }
                            alt="users"
                            className="icon"
                          />
                          <FormattedMessage
                            id="booking_infomation"
                            defaultMessage="BOOKING INFOMATION"
                          />
                        </h2>
                        <p className="check-label">
                          <FormattedMessage
                            id="order_date"
                            defaultMessage="Order date"
                          />
                          :
                          <br />
                          <Pane className="time-info">
                            <Pane className="time-info__book">
                            Staff: {detail.bookingItems && detail.bookingItems[0].staffFullname ? `${detail.bookingItems[0].staffFullname} -` : 'Store pick for you -'} {moment(detail.date).format("D MMMM YYYY")} at {detail.bookingItems && detail.bookingItems[0].fromTime}
                            </Pane>
                            <Pane className="time-info__est">
                              {this.handleEst()}
                            </Pane>
                          </Pane>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="note" defaultMessage="Note" />:
                          <span className="check-name">
                            {_.get(detail, "note")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="payment_method"
                            defaultMessage="Payment method"
                          />
                          :{" "}
                          <span className="check-name">
                            {detail.paymentMethod !== ""
                              ? detail.paymentMethod
                              : "Credit card"}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="status" defaultMessage="Status" />
                          :&nbsp;
                          <span className="check-name">
                            {detail.status && (
                              <span
                                className={`badge badge-${detail.status.toLowerCase()}`}
                              >
                                <FormattedMessage
                                  id={`order.${detail.status.toLowerCase()}`}
                                  defaultMessage={detail.status}
                                />
                              </span>
                            )}
                          </span>
                        </p>
                        {
                          detail.firstScanDiscount > 0 &&(
                            <p className="check-label">
                              <FormattedMessage id="discount" defaultMessage="Discount" />
                              :&nbsp;
                              <span className="check-name">
                                 {detail.firstScanDiscount}% (Max <Currency price={detail.maxDiscount} />)&nbsp;
                                <Currency price={detail.itemsTotal - detail.total} />
                              </span>
                            </p>
                          )
                        }
                      </Pane>
                    </Pane>
                    <Pane className="col-lg-4">
                      <Pane className="checkout-info">
                        <h2 className="checkout-title mb-2">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/cart/user.svg"
                            }
                            alt="users"
                            className="icon"
                          />
                          <FormattedMessage
                            id="customer_information"
                            defaultMessage="ORDER INFOMATION"
                          />
                        </h2>
                        <p className="check-label">
                          <FormattedMessage
                            id="full_name"
                            defaultMessage="Full name"
                          />
                          :{" "}
                          <span className="check-name">
                            {_.get(userInfo, "fullName")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="email" defaultMessage="Email" />:
                          <span className="check-name">
                            {_.get(userInfo, "email")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="phone" defaultMessage="Phone" />:
                          <span className="check-name">
                            {_.get(userInfo, "phoneNumber")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage id="address" defaultMessage="Address" />
                          :
                          <span className="check-name">
                            {_.get(userInfo, "address")}
                          </span>
                        </p>
                        <p className="check-label">
                          <FormattedMessage
                            id="post_code"
                            defaultMessage="Postcode"
                          />
                          :&nbsp;
                          <span className="check-name">
                            {_.get(userInfo, "postCode")}
                          </span>
                        </p>
                      </Pane>
                    </Pane>
                    <Pane className="col-lg-4 cart-order">
                      <Pane className="cart-table cart-table-desktop checkout-info">
                        <h2 className="checkout-title mb-2">
                          <span>Booking list</span>
                        </h2>
                        {storeInfo && (
                          <Pane className="info-booking">
                            <Link
                              className=""
                              to={`/${storeInfo.id}/services`}
                            >
                              <Pane className="store_info">
                                <Pane className="title">
                                  <Pane className="store_name">
                                    {storeInfo.name}
                                  </Pane>
                                  <Pane className="store_addr">
                                    {storeInfo.address}
                                  </Pane>
                                </Pane>
                              </Pane>
                            </Link>
                          </Pane>
                        )}
                        {detail.bookingItems && detail.bookingItems.map((item, key) => {
                          return (
                            <Pane key={key}>
                              <Pane className="service-name">
                                <Link
                                  to={`/services/${item.serviceId}`}
                                >
                                  {key + 1}. {item.serviceName}
                                </Link>
                                <Pane className="view-options">
                                  <h5
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {this.mappingSelectOptionToText(item ? item : null)}
                                  </h5>
                                </Pane>
                              </Pane>
                            </Pane>
                          )
                        })}
                        <Pane className="total">
                          <Pane className="total_title">
                            <FormattedMessage
                              id="total"
                              defaultMessage="TOTAL"
                            ></FormattedMessage>
                            :
                          </Pane>
                          <Pane className="total_price">
                            <Currency price={detail.total} />
                          </Pane>
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>

              </Pane>
              <Pane className="row mt-4">
                <Pane className="col-lg-12 text-center">
                  <Link
                    to={`${linkStore}/services`}
                    className="btn btn-process btn-continue-dark mr-2"
                  >
                    <i className="ic-common-overview font-weight-bold mr-2"></i>
                    <FormattedMessage
                      id="back_to_store"
                      defaultMessage="Back to Store"
                    />
                  </Link>
                  <Link
                    to="/search?type=Service"
                    className="btn btn-process btn-continue-dark"
                  >
                    <FormattedMessage
                      id="cart.continue_shopping"
                      defaultMessage="Continue Shopping"
                    />
                  </Link>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        ) : (
          <Dimmer active inverted>
            <Loading />
          </Dimmer>
        )
        }
      </>
    );
  }
}

export default withRouter(BookingSuccess);
