import Config from 'constants/Config'

const exec = params => {
  return {
    method: 'POST',
    url: `/clients/cardbey/notifications`,
    baseURL: Config.NOTIFICATION_API_URL,
    data: {
      data: {
        object_id: params.object_id.toString(),
        object_type: params.object_type,
        target_id: params.target_id.toString(),
        target_type: params.target_type,
        summary: params.summary,
        meta: params.meta
      }
    }
  }
}

const parseResponse = response => {
  const data = {
    ...response
  }

  return data
}

export { exec, parseResponse }
