import * as types from 'constants/ActionTypes'
const initialState = {
    results: [],
}

const storeBookings = (state = initialState, action) => {
    switch (action.type) {
        case types.RECEIVE_STORE_BOOKING:
            state.storeBookings = action.storeBookings;
            return state;
        case types.DELETE_STORE_BOOKING:
            let { id, isChildren } = action
            let index = state.results.findIndex(x => isChildren && x.children ? x.children.id : x.id === id);
            state.results.splice(index, 1);
            return { ...state };
        case types.ADD_STORE_BOOKING:
            let { booking } = action
            state.results.push(booking);
            return { ...state };
        case types.RESET_STORE_BOOKING:
            state.results = []
            return { ...state };
        default:
            return state
    }
}

export default storeBookings;