import * as types from "constants/ActionTypes";
import api from "api";

export const createMyOption = (params) => (dispatch) =>
  dispatch({
    type: types.CREATE_MY_OPTION,
    payload: {
      request: api.options.createOption(params),
    },
  });
export const editMyOption = (params) => (dispatch) =>
  dispatch({
    type: types.EDIT_MY_OPTION,
    payload: {
      request: api.options.editOption(params),
    },
  });

export const deleteMyOption = (params) => (dispatch) =>
  dispatch({
    type: types.DELETE_MY_OPTION,
    payload: {
      request: api.options.deleteOption(params),
    },
  });

//   Group
export const createMyOptionGroup = (params) => (dispatch) =>
  dispatch({
    type: types.CREATE_MY_GROUP_OPTION,
    payload: {
      request: api.optionGroup.createOptionGroup(params),
    },
  });
export const editMyOptionGroup = (params) => (dispatch) =>
  dispatch({
    type: types.EDIT_MY_GROUP_OPTION,
    payload: {
      request: api.optionGroup.editOptionGroup(params),
    },
  });

// Edit product detail
export const deleteMyOptionGroup = (params) => (dispatch) =>
  dispatch({
    type: types.DELETE_MY_GROUP_OPTION,
    payload: {
      request: api.optionGroup.deleteOptionGroup(params),
    },
  });
