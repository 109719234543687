import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import { last } from "lodash";
import { Pane } from "shared/components/layouts";
import List from "./List";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import ReactPlayer from "react-player";

class Slideshow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  /**
   * Function resize width
   */
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  static propTypes = {
    onGetReviewsVideo: PropTypes.func,
    onNotification: PropTypes.object,
    onReviewsVideo: PropTypes.func,
    onGetLikesVideo: PropTypes.func,
    onGetUserLikesVideo: PropTypes.func,
    onLikesVideo: PropTypes.func,
    onViewVideo: PropTypes.func,
    onGetVideosByUserId: PropTypes.func,
    onAddLikeVideo: PropTypes.func,
  };

  onPlay = (second) => {
    this.props.onViewVideo({
      id: this.props.data[0].id,
      view_video: this.props.data[0].viewVideo + 1,
    });
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      auto: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    const arrayVideos = [];
    const arrayProducts = [];
    const TYPE_VIDEOS = ["mp4", "avi", "mov"];
    const slideItem =
      this.props.data &&
      this.props.data.map((item, index) => {
        const typeMedia =
          item.primaryImage && last(item.primaryImage.split("."));
        if (TYPE_VIDEOS.includes(typeMedia)) {
          arrayVideos.push(item);
        } else {
          arrayProducts.push(item);
        }
        return (
          <Pane className="list-slider" key={index}>
            <div className="product-heading">
              <h3
                className="block-title block-title-slider text-uppercase"
                style={{ marginRight: "auto", fontWeight: "bold" }}
              >
                {item.name}
              </h3>
            </div>
            <Pane className="slider-content-main">
              {TYPE_VIDEOS.includes(typeMedia) && (
                <ReactPlayer
                  url={item.primaryImage}
                  className="react-player banner-video-over"
                  controls
                  playsinline={false}
                  playing={false}
                  config={{
                    file: {
                      attributes: {
                        autoPlay: false,
                        muted: false,
                      },
                    },
                  }}
                />
              )}
              {!TYPE_VIDEOS.includes(typeMedia) && (
                <Link to={`/${item.type}/${item.slug}`} key={item.id}>
                  <img
                    src={`${item.primaryImage ? item.primaryImage : `${process.env.PUBLIC_URL}/assets/images/default-image.png`}`}
                    alt="banner2"
                    className="banner-image-over"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                    }}
                  />
                </Link>
              )}
            </Pane>
            {/* {item.viewVideo && (
              <Pane className="d-flex justify-content-end mt-1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/icons/view.png"}
                  alt="image"
                  aria-hidden
                  className="mr-1"
                  style={{
                    cursor: "pointer",
                    width: 21,
                    height: 21,
                  }}
                />
                <p className="mr-2">{item.viewVideo} views -</p>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/like-gray.svg"
                  }
                  alt="image"
                  aria-hidden
                  className="mr-1"
                  style={{
                    cursor: "pointer",
                    width: 17,
                    height: 17,
                  }}
                />
                <p className="mr-2">{item.likeVideo} likes</p>
              </Pane>
            )} */}
          </Pane>
        );
      });
    return (
      <Pane
        className="row video-container store_overview"
        style={{ boxShadow: "none" }}
        id="video-container"
      >
        <Slider
          className="videos-slider col-xl-9 col-lg-9 col-sm-12 col-12"
          {...settings}
        >
          {slideItem}
        </Slider>
        <Pane className="video-list col-xl-3 col-lg-3 col-sm-12 col-12">
          <div className="product-heading">
            <h3
              className="block-title text-uppercase"
              style={{ marginRight: "auto" }}
            >
              COLLECTION
            </h3>
          </div>
          {/* {arrayVideos.length > 0 && (
            <Scrollbars
              style={{
                height: "83%",
                minHeight: "300px",
              }}
            >
              <List data={arrayVideos} />
            </Scrollbars>
          )} */}
              <Scrollbars
                style={{
                  height: "90%",
                  minHeight: "300px",
                }}
              >
                <List data={this.props.data} />
              </Scrollbars>
        </Pane>
      </Pane>
    );
  }
}

export default Slideshow;
