import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';

class OrderSummary extends Component {
  static propTypes = {
    detail: PropTypes.object,
  };

  render() {
    const { detail } = this.props;
    const arrayImage = [1, 2, 3, 4, 5];
    return (
      <Table basic="very">
        <Table.Body>
          <Table.Row>
            <Table.Cell width="4">
              <p className="text-gray">ID :</p>
            </Table.Cell>
            <Table.Cell>
              <p className="text-gray">{detail.id}</p>
            </Table.Cell>
          </Table.Row>
          {arrayImage.map((item, index) => (
            <React.Fragment key={index}>
              {get(detail, `image${index + 1}.url`) && (
                <Table.Row>
                  <Table.Cell>
                    <p className="text-gray">Images {index + 1}:</p>
                  </Table.Cell>
                  <Table.Cell>
                    <img
                      src={get(detail, `image${index + 1}.url`)}
                      size="tiny"
                      ref={(img) => (this.img = img)}
                      alt="products"
                      className="image img-table"
                      style={{ width: '250px' }}
                      onError={() =>
                        (this.img.src =
                          process.env.PUBLIC_URL +
                          `/assets/images/default-image.png`)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </React.Fragment>
          ))}
          <Table.Row>
            <Table.Cell>Deliver to:</Table.Cell>
            <Table.Cell>
              <p className="text-gray">{detail.receiverName}</p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Delivery address:</Table.Cell>
            <Table.Cell>
              <p className="text-gray">{detail.receiverAddress}</p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p className="text-gray">Recipient's email:</p>
            </Table.Cell>
            <Table.Cell>
              <p className="text-gray">{detail.receiverEmail}</p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p className="text-gray">Recipient's phone number:</p>
            </Table.Cell>
            <Table.Cell>
              <p className="text-gray">+{detail.receiverPhone}</p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p className="text-gray">Type:</p>
            </Table.Cell>
            <Table.Cell>
              <p className="text-gray">
                {detail.receiverPhone === 'DELIVERY_NOW'
                  ? 'Delivery Now'
                  : 'Delivery Schedule'}
              </p>
            </Table.Cell>
          </Table.Row>
          {detail.pickupTime && (
            <Table.Row>
              <Table.Cell>
                <p className="text-gray">Pickup Time:</p>
              </Table.Cell>
              <Table.Cell>
                <p className="text-gray">
                  {moment(detail.pickupTime, 'x').format('DD/MM/YYYY HH:mm')}
                </p>
              </Table.Cell>
            </Table.Row>
          )}
          {detail.expectDeliveryTime && (
            <Table.Row>
              <Table.Cell>
                <p className="text-gray">Expect Delivery Time:</p>
              </Table.Cell>
              <Table.Cell>
                <p className="text-gray">
                  {moment(detail.expectDeliveryTime, 'x').format(
                    'DD/MM/YYYY HH:mm'
                  )}
                </p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }
}

export default OrderSummary;
