import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Checkbox, Form, Dimmer } from "semantic-ui-react";
import { Pane, Loading } from "shared/components/layouts";
import { PriceItem, PromotionLabel } from "shared/components";
import Config from "constants/Config";
import queryString from "query-string";
import Modal from "react-responsive-modal";
import { StockLabel } from "../../../../shared/components";

class ListItem extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      tableId: queryString.parse(search).tableId || null,
      added: false,
      adding: false,
      play: 0,
      open: false,
      value: null,
      loadingAddCart: false
    };
  }

  isProductOptions = () => {
    if (this.props.item.options && this.props.item.options.length) return true;
    return false;
  };

  addItemToCart = () => {
    const { intl, item, cartItems, location: { search } } = this.props;
    const { tableId } = this.state;
    if (!_.isEmpty(queryString.parse(search).tableId)) {
      this.setState({open: true});
      return;
    }

    if (this.isProductOptions()) {
      this.props.cartModal.setCartView({
        ...this.props.item,
        tableId: tableId || undefined,
      });
      this.props.cartModal.openModal();
      return;
    }

    this.setState({ added: false, adding: true });
    this.setState({ loadingAddCart: true });

    if (cartItems && cartItems.length > 0 && item.userId !== cartItems[0].userId) {
      this.props.onRemoveAllCart();
    }

    this.props
      .onAddItemToCart({
        product_id: this.props.id,
        table_id: tableId || undefined,
      })
      .then(() => {
        this.setState({ added: true, adding: false });
        this.setState({ loadingAddCart: false });
        document
          .getElementsByClassName("cart-total")[0]
          .classList.add("cart-animation");
        setTimeout(() => {
          document
            .getElementsByClassName("cart-total")[0]
            .classList.remove("cart-animation");
        }, 1000);
      })
      .catch(() => {
        this.setState({ adding: false });
        this.setState({ loadingAddCart: false });
        this.props.onNotification.addNotification({
          message: intl.formatMessage({ id: "notification.please_try_again" }),
          level: "error",
        });
      });
  };

  componentWillReceiveProps(nextProps) {
    const {
      location: { search },
    } = nextProps;
    let methodModal = nextProps.methodModal;
    this.setState({value:methodModal && methodModal.methodType === "Take-Away" ? Config.DELIVERY_METHOD.TAKE_AWAY : Config.DELIVERY_METHOD.DINE_IN})
    if (!_.isEmpty(methodModal) && !_.isEmpty(methodModal.tableId) && ["Dine-In", "Take-Away"].includes(methodModal.methodType) && !_.isEmpty(queryString.parse(search).tableId)) {
      this.setState({ tableId: methodModal.tableId });
    }
  }

  save = () => {
    const { intl, item, cartItems } = this.props;
    const { tableId, value } = this.state;
    if (!value) {
      return;
    }

    if (this.isProductOptions()) {
      this.props.cartModal.setCartView({
        ...this.props.item,
        tableId: tableId || undefined,
        delivery_method: value,
      });
      this.props.cartModal.openModal();
      this.setState({ open: false});
      return;
    }

    this.setState({ added: false, adding: true });
    this.setState({ loadingAddCart: true });

    if (cartItems && cartItems.length > 0 && item.userId !== cartItems[0].userId) {
      this.props.onRemoveAllCart();
    }

    this.props
      .onAddItemToCart({
        product_id: this.props.id,
        table_id: tableId || undefined,
        delivery_method: value,
      })
      .then(() => {
        this.setState({ added: true, adding: false, open: false, value: null });
        this.setState({ loadingAddCart: false });
      })
      .catch(() => {
        this.setState({ adding: false });
        this.setState({ loadingAddCart: false });
        this.props.onNotification.addNotification({
          message: intl.formatMessage({ id: "notification.please_try_again" }),
          level: "error",
        });
      });
  };

  renderInfo = () => {
    const { item } = this.props;
    return (
      <Pane className="adds-info">
        <PriceItem
          promotion={item && item.promotion}
          originalPrice={item && item.originalPrice}
        />
      </Pane>
    );
  };

  onRedirect = (value) => {
    const { tableId } = this.state;
    if (this.props.type === "bussiness") {
      return `/${value}`;
    }
    if (tableId) {
      return `/products/${value}?tableId=${tableId}`;
    }
    return `/products/${value}`;
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  onCloseReasonModal = () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (e, { value }) => this.setState({ value });

  render() {
    const { item, name, image, description, checked } = this.props;
    const { added, open, value, loadingAddCart } = this.state;
    const extImage = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];
    const ext = image != null ? image.substr(image.lastIndexOf(".") + 1) : "";
    return this.props.modeView === Config.MODE_VIEW_MENU.MODE_LIST ? (
      <Pane className="adds-item adds-list-view">
        <Modal
          open={open}
          onClose={this.onCloseReasonModal}
          center
          showCloseIcon={false}
        >
          <Pane className="popup-confirm-container">
            <Form>
              <Pane className="d-flex">
                <Form.Field>
                  <Checkbox
                    radio
                    label="Dine in"
                    name="checkboxRadioGroup"
                    value={Config.DELIVERY_METHOD.DINE_IN}
                    checked={value === Config.DELIVERY_METHOD.DINE_IN}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <span className="ic-common-pickup ml-1"></span>
              </Pane>
              <Pane className="d-flex">
                <Form.Field>
                  <Checkbox
                    radio
                    label="Take away"
                    name="checkboxRadioGroup"
                    value={Config.DELIVERY_METHOD.TAKE_AWAY}
                    checked={value === Config.DELIVERY_METHOD.TAKE_AWAY}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <span className="ic-common-delivery ml-1 mt-1"></span>
              </Pane>
            </Form>
            <Pane className="react-confirm-alert-button-group">
              <button className="btn-claim" onClick={this.save}>
                <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
              </button>
            </Pane>
          </Pane>
        </Modal>
        {loadingAddCart && (
          <Dimmer active inverted>
            <Loading />
          </Dimmer>
        )}
        <Pane className="adds-content">
          <Pane className="col-lg-7 col-md-6 col-sm-12 p-0">
            <Link
              className="adds-norm"
              to={
                this.props.type === "bussiness"
                  ? this.onRedirect(this.props.item.slug)
                  : this.onRedirect(this.props.id)
              }
            >
              <h5 title={name}>
                <b>{name}</b>
                <i>
                  <span
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></span>
                </i>
              </h5>
            </Link>
          </Pane>
          <Pane className="col-lg-2 col-md-3 col-sm-12 p-0">
            <div className="group-price">
              <PriceItem
                promotion={item && item.promotion}
                originalPrice={item && item.originalPrice}
              />
              <button
                className="btn-order-sm d-md-none d-sm-flex d-lg-none"
                onClick={this.addItemToCart}
              >
                <span className="icon-plus" />
              </button>
              <div style={{ clear: "both" }}></div>
            </div>
          </Pane>
          <Pane className="col-lg-3 col-md-3 pr-md-0">
            <button
              className="btn-order d-none d-md-flex d-sm-none d-lg-flex float-right"
              onClick={this.addItemToCart}
            >
              <span className="icon-plus" />
            </button>
          </Pane>
        </Pane>
      </Pane>
    ) : (
      <Pane className="col-lg-3 col-md-6 col-sm-6 col-12 pl-0 pr-0 pb-0">
        {loadingAddCart && (
          <Dimmer active inverted>
            <Loading />
          </Dimmer>
        )}
        <Modal
          open={open}
          onClose={this.onCloseReasonModal}
          center
          showCloseIcon={false}
        >
          <Pane className="popup-confirm-container">
            <Form>
              <Pane className="d-flex">
                <Form.Field>
                  <Checkbox
                    radio
                    label="Dine in"
                    name="checkboxRadioGroup"
                    value={Config.DELIVERY_METHOD.DINE_IN}
                    checked={value === Config.DELIVERY_METHOD.DINE_IN}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <span className="ic-common-pickup ml-1"></span>
              </Pane>
              <Pane className="d-flex">
                <Form.Field>
                  <Checkbox
                    radio
                    label="Take away"
                    name="checkboxRadioGroup"
                    value={Config.DELIVERY_METHOD.TAKE_AWAY}
                    checked={value === Config.DELIVERY_METHOD.TAKE_AWAY}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <span className="ic-common-delivery ml-1 mt-1"></span>
              </Pane>
            </Form>
            <Pane className="react-confirm-alert-button-group">
              <button className="btn-claim" onClick={this.save}>
                <FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />
              </button>
            </Pane>
          </Pane>
        </Modal>
        <Pane className="adds-item">
          <Link
            className="hover-image"
            to={this.onRedirect(this.props.item.slug)}
          >
            {extImage.indexOf(ext) >= 0 ? (
              <Pane
                className="adds-images adds-images-stock"
                style={{
                  backgroundImage: image
                    ? `url(${image})`
                    : `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                }}
              >
                <StockLabel
                  inStock={this.props.item && this.props.item.inventoryStatus}
                  type={this.props.item.type}
                />
                <PromotionLabel
                  promotion={this.props.item && this.props.item.promotion}
                  originalPrice={
                    this.props.item && this.props.item.originalPrice
                  }
                />
                {(added || checked) && (
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/icons/check.svg`
                    }
                    alt="check"
                    className="icon-check"
                  />
                )}
              </Pane>
            ) : (
              <Pane className="adds-images adds-images-stock">
                {image ? (
                  <Pane>
                    {ext !== "" ? <i className="fa fa-video" /> : ""}
                    <video
                      className="carbeyvideo-list"
                      playsInline={1}
                      onMouseOver={this.playVideo}
                      onMouseOut={this.stopVideo}
                      muted={!this.state.play}
                    >
                      <source src={image} id="video_here" />
                      Your browser does not support HTML5 video.111
                    </video>
                    <StockLabel
                      inStock={
                        this.props.item && this.props.item.inventoryStatus
                      }
                      type={this.props.item.type}
                    />
                    <PromotionLabel
                      promotion={this.props.item && this.props.item.promotion}
                      originalPrice={
                        this.props.item && this.props.item.originalPrice
                      }
                    />
                    {(added || checked) && (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/icons/check.svg`
                        }
                        alt="check"
                        className="icon-check"
                      />
                    )}
                  </Pane>
                ) : (
                  <Link
                    className="hover-image"
                    to={this.onRedirect(this.props.item.slug)}
                  >
                    <Pane
                      className="adds-images"
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/default-image.png`,
                      }}
                    ></Pane>
                  </Link>
                )}
              </Pane>
            )}
          </Link>
          <Pane className="adds-content">
            <Link
              to={
                this.props.type === "bussiness"
                  ? this.onRedirect(this.props.item.slug)
                  : this.onRedirect(this.props.id)
              }
            >
              <h5 className="adds-norm" title={name}>
                <b>{name}</b>
                <span dangerouslySetInnerHTML={{ __html: description }}></span>
              </h5>
            </Link>
            <Pane className="adds-info-item">
              {this.renderInfo()}
              <button className="btn-order" onClick={this.addItemToCart}>
                <span className="icon icon-plus" />
              </button>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}
ListItem.propTypes = {
  auth: PropTypes.object,
  item: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  rating: PropTypes.number,
  intl: intlShape.isRequired,
  onAddItemToCart: PropTypes.func,
  onNotification: PropTypes.object,
  type: PropTypes.string,
  description: PropTypes.string,
};
ListItem.defaultProps = {
  type: "",
};

export default withRouter(injectIntl(ListItem));
