import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Button, Checkbox, Icon, Radio } from "semantic-ui-react";
import { Pane } from "shared/components/layouts";
import Config from "constants/Config";
import {
  removeToCart,
  changeQuantity,
  changeDelivery,
  changeOption,
} from "actions/cart";
import { Currency } from "shared/components";
import { getOptionsByProduct } from "actions/products";

class CartList extends Component {
  state = {
    loadingDelivery: false,
    loadingRemove: false,
    totalPrice: 0,
    cart: [],
    optionIds: {},
  };

  removeItem = (item) => {
    const params = {
      id: item.id,
    };

    this.setState({ loadingRemove: true });

    this.props
      .removeToCart(params)
      .then(() => this.setState({ loadingRemove: false }))
      .catch(() => this.setState({ loadingRemove: false }));
  };

  changeQuanlity = (item, action) => {
    const params = {
      id: item.id,
      quantity: item.quantity,
    };

    this.setState({ loadingRemove: false });

    switch (action) {
      case "inc":
        params.quantity = item.quantity + 1;
        this.props.changeQuantity(params);
        break;
      case "dec":
        params.quantity = item.quantity - 1;
        this.props.changeQuantity(params);
        break;
      default:
        break;
    }
  };

  changeDeliveryMethod = (item) => {
    const params = {
      id: item.id,
      deliveryMethod:
        item.deliveryMethod === Config.DELIVERY_METHOD.DELIVERY
          ? Config.DELIVERY_METHOD.PICKUP
          : Config.DELIVERY_METHOD.DELIVERY,
    };

    this.setState({ loadingDelivery: true });

    this.props.changeDelivery(params).then(() => {
      this.setState({ loadingDelivery: false });
      this.props.onChangeType(params.deliveryMethod)
    });
  };

  changeDeliveryMethodDineIn = (item) => {
    const params = {
      id: item.id,
      deliveryMethod:
        item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN
          ? Config.DELIVERY_METHOD.TAKE_AWAY
          : Config.DELIVERY_METHOD.DINE_IN,
    };

    this.setState({ loadingDelivery: true });

    this.props
      .changeDelivery(params)
      .then(() => {
        this.setState({ loadingDelivery: false });
      })
      .catch(() => {
        this.setState({ loadingDelivery: false });
      });
  };

  getTotalPriceOptions = (item) => {
    let totalPirce = 0;

    if (!item || !item.options || !item.options.length) return 0;

    item.options.forEach((option) => {
      const { childrens } = option;

      childrens.forEach((children) => {
        const childIncludes = this.includesOption(children.id, item);
        if (childIncludes) {
          totalPirce +=
            Number(children.price || 0) * Number(childIncludes.quantity);
        }
      });
    });

    return Number(item.quantity || 1) * totalPirce;
  };

  getCartOptionsValue = async () => {
    const optionIds = {};

    this.props.cart.forEach((item) => {
      optionIds[item.id] = item.optionInfo;
    });
    this.setState({ optionIds: optionIds });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.cart) {
      this.getCartOptionsValue();
    }
  };

  isOptions = (item) => {
    if (!item.options) return false;
    if (!item.options.length) return false;

    return true;
  };

  includesOption = (id, item) => {
    const { optionInfo } = item;
    return optionInfo.find((child) => child.id === id);
  };

  getSelectedOptions = (item) => {
    if (!this.isOptions(item)) return [];

    const { options } = item;
    let selectdOptions = [];

    options.map((option) => {
      const { childrens } = option;
      childrens.forEach((child) => {
        if (this.includesOption(child.id, item)) {
          selectdOptions.push({
            ...child,
            quantity: this.includesOption(child.id, item).quantity,
          });
        }
      });
    });
    return selectdOptions;
  };

  onViewCartOption = (item) => {
    this.props.cartModal.setCartView({ ...item, modeCart: true });
    this.props.cartModal.openModal();
  };

  mappingSelectOptionToText = (item) => {
    const selectedOptions = this.getSelectedOptions(item);
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${
            child.name
          }`
      )
      .join(", ");
  };

  render() {
    const { type, cart } = this.props;
    return (
      <>
        {cart.map((item) => {
          return (
            <Pane className="cart-item" key={item.id}>
              <Pane className="cart-image">
                <img
                  src={
                    item.primaryImage
                      ? item.primaryImage
                      : process.env.PUBLIC_URL +
                        `/assets/images/default-image.png`
                  }
                  size="tiny"
                  ref={(img) => (this.img = img)}
                  alt="products"
                  className="image img-table"
                  onError={() =>
                    (this.img.src =
                      process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`)
                  }
                />

                <Pane>
                  <Link to={`/products/${item.productId}`}>
                    <p className="cart-norm">{item.name}</p>
                  </Link>
                  <span className="price">
                    <Currency price={item.price} />
                  </span>
                  {this.isOptions(item) ? (
                    <Pane className="view-options">
                      <p
                        style={{ marginLeft: "18px" }}
                        onClick={() => this.onViewCartOption(item)}
                      >
                        <Icon name="edit" />
                        {this.mappingSelectOptionToText(item)}
                      </p>
                    </Pane>
                  ) : null}
                </Pane>
              </Pane>
              <Pane className="cart-quanlity mt-3 mb-3">
                <Pane className="quanlity">
                  <span
                    className="operator"
                    onClick={this.changeQuanlity.bind(this, item, "dec")}
                  >
                    -
                  </span>
                  <span className="operator">{item.quantity}</span>
                  <span
                    className="operator"
                    onClick={this.changeQuanlity.bind(this, item, "inc")}
                  >
                    +
                  </span>
                </Pane>
                <Pane className="total">
                  <span className="price total-price">
                    <Currency
                      price={
                        (item.promotionPrice +
                          this.getTotalPriceOptions(item)) *
                        item.quantity
                      }
                    />
                  </span>
                </Pane>
              </Pane>

              {[Config.DELIVERY_METHOD.DINE_IN, Config.DELIVERY_METHOD.TAKE_AWAY].includes(type) && (
                <Pane className="group-button">
                  {item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN ? (
                    <Button
                      size="mini"
                      icon
                      color="yellow"
                      className="btn-table btn-pickup"
                      loading={this.state.loadingDelivery}
                      disabled={this.state.loadingDelivery}
                      onClick={this.changeDeliveryMethodDineIn.bind(this, item)}
                    >
                      <FormattedMessage
                        id="take_away"
                        defaultMessage="Take Away"
                      />
                    </Button>
                  ) : (
                    <Button
                      size="mini"
                      icon
                      color="yellow"
                      className="btn-table btn-pickup"
                      loading={this.state.loadingDelivery}
                      disabled={this.state.loadingDelivery}
                      onClick={this.changeDeliveryMethodDineIn.bind(this, item)}
                    >
                      <FormattedMessage id="dine_in" defaultMessage="Dine in" />
                    </Button>
                  )}
                  <Button
                    size="mini"
                    className="btn-table"
                    icon
                    loading={this.state.loadingRemove}
                    disabled={this.state.loadingRemove}
                    onClick={this.removeItem.bind(this, item)}
                  >
                    <FormattedMessage id="remove" defaultMessage="Remove" />
                  </Button>
                </Pane>
              )}
              {![Config.DELIVERY_METHOD.DINE_IN, Config.DELIVERY_METHOD.TAKE_AWAY].includes(type) && (
                <Pane className="group-button">
                  {item.deliveryMethod === Config.DELIVERY_METHOD.PICKUP ? (
                    <Button
                      size="mini"
                      icon
                      color="yellow"
                      className="btn-table btn-pickup"
                      loading={this.state.loadingDelivery}
                      disabled={this.state.loadingDelivery}
                      onClick={this.changeDeliveryMethod.bind(this, item)}
                    >
                      <span className="ic-common-delivery mr-2" />
                      <FormattedMessage
                        id="delivery"
                        defaultMessage="Delivery"
                      />
                    </Button>
                  ) : (
                    <Button
                      size="mini"
                      icon
                      color="yellow"
                      className="btn-table btn-pickup"
                      loading={this.state.loadingDelivery}
                      disabled={this.state.loadingDelivery}
                      onClick={this.changeDeliveryMethod.bind(this, item)}
                    >
                      <span className="ic-common-pickup mr-2" />
                      <FormattedMessage id="pickup" defaultMessage="Pickup" />
                    </Button>
                  )}
                  <Button
                    size="mini"
                    className="btn-table"
                    icon
                    loading={this.state.loadingRemove}
                    disabled={this.state.loadingRemove}
                    onClick={this.removeItem.bind(this, item)}
                  >
                    <span className="icon-trash cart_trash mr-2" />
                    <FormattedMessage id="remove" defaultMessage="Remove" />
                  </Button>
                </Pane>
              )}
            </Pane>
          );
        })}
      </>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  removeToCart: bindActionCreators(removeToCart, dispatch),
  changeQuantity: bindActionCreators(changeQuantity, dispatch),
  changeDelivery: bindActionCreators(changeDelivery, dispatch),
  changeOption: bindActionCreators(changeOption, dispatch),
  getOptionsByProduct: bindActionCreators(getOptionsByProduct, dispatch),
});

export default connect(bindStateToProps, bindDispatchToProps)(CartList);
