import Config from 'constants/Config';
import { formatJson } from 'shared/helpers';

const exec = (params) => {
  return {
    method: 'GET',
    url: `/deliveries/${params.id}`,
    baseURL: Config.NEW_API_URL,
  };
};

const parseResponse = (response) => {
  const orderDetail = formatJson(
    response,
    response.data.type,
    response.data.id
  );
  return orderDetail;
};

export { exec, parseResponse };
