import React, { Component } from "react";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Select,
  Image as ImageS,
  Icon,
  Checkbox,
  Loader,
  Radio,
} from "semantic-ui-react";
import { isEmpty } from "lodash";
import Config from "constants/Config";
import queryString from "query-string";
import SelectImage from "react-select";
import TreeSelect from "rc-tree-select";
import createClass from "create-react-class";
import { Pane, Loading } from "shared/components/layouts";
import ProductAdapter from "services/products/adapter";
import { injectIntl, FormattedMessage } from "react-intl";
import { formatRoute, handleError, quill } from "shared/helpers";
import InlineMessage from "shared/components/InlineMessage";

class CreateProductForm extends Component {
  static propTypes = {
    products: PropTypes.object,
    categories: PropTypes.object.isRequired,
    getMyProducts: PropTypes.func.isRequired,
    getMyProductDetail: PropTypes.func.isRequired,
  };

  state = {
    images: [],
    imageCategory: {
      label: "/images/category/18.Collectables.png",
      value: "/images/category/18.Collectables.png",
    },
    default_images: [
      {
        label: "/images/category/18.Collectables.png",
        value: "/images/category/18.Collectables.png",
      },
      {
        label: "/images/category/17.Coins.png",
        value: "/images/category/17.Coins.png",
      },
      {
        label: "/images/category/16.Clothing,Shoes,Accessories.png",
        value: "/images/category/16.Clothing,Shoes,Accessories.png",
      },
      {
        label: "/images/category/7.Cameras.png",
        value: "/images/category/7.Cameras.png",
      },
      {
        label: "/images/category/6.Business.png",
        value: "/images/category/6.Business.png",
      },
      {
        label: "/images/category/5.Books,Magazines.png",
        value: "/images/category/5.Books,Magazines.png",
      },
      {
        label: "/images/category/3.Art.png",
        value: "/images/category/3.Art.png",
      },
      {
        label: "/images/category/2.Antiques.png",
        value: "/images/category/2.Antiques.png",
      },
      {
        label: "/images/category/56.Consultingservices.png",
        value: "/images/category/56.Consultingservices.png",
      },
      {
        label: "/images/category/57.Educationservices.png",
        value: "/images/category/57.Educationservices.png",
      },
      {
        label: "/images/category/58.Legalservices.png",
        value: "/images/category/58.Legalservices.png",
      },
      {
        label: "/images/category/59.Healthandbeautyservices.png",
        value: "/images/category/59.Healthandbeautyservices.png",
      },
      {
        label: "/images/category/65.Handymanservices.png",
        value: "/images/category/65.Handymanservices.png",
      },
      {
        label: "/images/category/66.Cleaningservices.png",
        value: "/images/category/66.Cleaningservices.png",
      },
      {
        label: "/images/category/67.Constructionservices.png",
        value: "/images/category/67.Constructionservices.png",
      },
      {
        label: "/images/category/71.Entertainmentservices.png",
        value: "/images/category/71.Entertainmentservices.png",
      },
      {
        label: "/images/category/72.House-careservices.png",
        value: "/images/category/72.House-careservices.png",
      },
      {
        label: "/images/category/73.Financialservices.png",
        value: "/images/category/73.Financialservices.png",
      },
      {
        label: "/images/category/77.Foodservices.png",
        value: "/images/category/77.Foodservices.png",
      },
      {
        label: "/images/category/78.Informationservices.png",
        value: "/images/category/78.Informationservices.png",
      },
      {
        label: "/images/category/19.Computers-Tablets&Networking.png",
        value: "/images/category/19.Computers-Tablets&Networking.png",
      },
      {
        label: "/images/category/15.Cars,Bikes,Boats.png",
        value: "/images/category/15.Cars,Bikes,Boats.png",
        width: "70px",
      },
      {
        label: "/images/category/4.Baby.png",
        value: "/images/category/4.Baby.png",
      },
    ],
    newImageCategory: false,
    category_images: [],
    imageFiles: [],
    imageCategoryFiles: [],
    data: {
      name: "",
      categoryId: 1,
      description: "",
      price: "",
    },
    dataCategory: {
      name: "",
      group: "",
    },
    newCategory: false,
    errors: {},
    categories: [],
    groups: [],
    open: false,
    loading: false,
    group: queryString.parse(this.props.history.location.search).group || "",
    fetching: false,
    fetched: false,
    types: [
      { key: "1", value: 1, text: "Single Value" },
      { key: "2", value: 2, text: "Multi Value" },
      { key: "3", value: 3, text: "Included Quantity" },
    ],
    options: [],
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  onChangeCategory = (e) => {
    this.setState({
      dataCategory: {
        ...this.state.dataCategory,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeImageCategory = (e, { value }) => {
    this.setState({
      newImageCategory: value === "NEW",
    });
  };

  convertImgToDataURLviaCanvas = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // works for me
      img.onload = function() {
        let canvas = document.createElement("CANVAS");
        const ctx = canvas.getContext("2d");
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
        canvas = null;
      };
      img.onerror = reject;
      img.src = src;
    });
  };

  onSelectGroup = (e, s) => {
    this.setState({
      dataCategory: { ...this.state.dataCategory, [s.name]: s.value },
    });
  };

  handleGroup = () => {
    const groups = [
      {
        text: "Product",
        value: "Product",
      },
    ];
    this.setState({
      groups,
    });
  };

  handleChangeCheckbox = (e, { value }) => {
    this.setState({
      newCategory: value,
    });
  };

  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.images.length < 6 &&
          this.state.images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  onChangeCategoryImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.category_images.length < 6 &&
          this.state.category_images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.category_images;
            const imageFiles = this.state.imageCategoryFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              category_images: images,
              imageCategoryFiles: imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  handleCategory = (categories) => {
    const array = [];
    categories &&
      categories.forEach((item) => {
        array.push({
          key: item.id,
          value: item.id,
          text: item.name,
        });
      });

    this.setState({
      categories: array,
    });
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  removeCategoryImage = (index) => {
    const images = this.state.category_images;
    const imageFiles = this.state.imageCategoryFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      category_images: images,
      imageCategoryFiles: imageFiles,
    });
  };

  validate = (data, dataCategory) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.price) {
      errors.price = intl.formatMessage({ id: "validate.require_field" });
    }
    if (data.price && data.price <= 0) {
      errors.price = intl.formatMessage({ id: "validate.invalid_price" });
    }
    if (this.state.newCategory === false && !data.categoryId) {
      errors.categoryId = intl.formatMessage({ id: "validate.require_field" });
    }
    if (this.state.newCategory === true) {
      if (!dataCategory.name) {
        errors.category_name = intl.formatMessage({
          id: "validate.require_field",
        });
      }
      if (!dataCategory.group) {
        errors.category_group = intl.formatMessage({
          id: "validate.require_field",
        });
      }
    }

    return errors;
  };

  editProduct = async () => {
    const errors = this.validate(this.state.data, this.state.dataCategory);
    const { userInfo } = this.props;
    let categoryId;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      if (this.state.newCategory === true) {
        const dataCategory = new FormData();
        if (this.state.imageCategoryFiles[0]) {
          dataCategory.append(
            "primary_image",
            this.state.imageCategoryFiles[0]
          );
        }
        if (this.state.newImageCategory === false) {
          dataCategory.append("primary_image", this.state.imageCategory.value);
        }
        dataCategory.append("name", this.state.dataCategory.name);
        dataCategory.append("group", this.state.dataCategory.group);
        dataCategory.append("user_id", userInfo.id);
        dataCategory.append(
          "sub_image_1",
          this.state.imageCategoryFiles[1] || ""
        );
        dataCategory.append(
          "sub_image_2",
          this.state.imageCategoryFiles[2] || ""
        );
        dataCategory.append(
          "sub_image_3",
          this.state.imageCategoryFiles[3] || ""
        );
        dataCategory.append(
          "sub_image_4",
          this.state.imageCategoryFiles[4] || ""
        );
        dataCategory.append(
          "sub_image_5",
          this.state.imageCategoryFiles[5] || ""
        );

        try {
          const {
            data: { data },
          } = await this.props.createCategory(dataCategory);
          this.setState({
            loading: false,
            open: false,
            dataCategory: {
              ...this.state.dataCategory,
            },
          });
          categoryId = data.id;
          this.saveProduct(categoryId);
        } catch (error) {
          const errors = {};
          errors.category_message =
            "Category: " +
            handleError(
              error,
              this.props.intl.formatMessage({
                id: "notification.please_try_again",
              })
            );

          this.setState({
            loading: false,
            errors,
          });
        }
      } else {
        this.saveProduct(this.state.data.categoryId);
      }
    }
  };

  buildFormData = (object) => {
    const formData = new FormData();
    for (const key in object) {
      formData.append(key, object[key]);
    }

    return formData;
  };

  onUpdateOptions = async (option, product_id) => {
    const resOptGrp = await this.props.editMyOptionGroup(
      this.buildFormData({
        id: option.id,
        name: option.name,
        type: option.type,
        dp_ord_seq: option.dp_ord_seq,
      })
    );
    const { id: option_group_id } = resOptGrp.data.data;

    const handlers = [];

    for (const children of option.childrens) {
      if (children.flag === "I") {
        handlers.push(
          this.props.createMyOption(
            this.buildFormData({
              name: children.name,
              price: Number(children.price || 0),
              isCheck: children.isCheck ? 1 : 0,
              check: children.isCheck ? 1 : 0,
              product_id: product_id,
              option_group_id: option_group_id,
              dp_ord_seq: children.dp_ord_seq,
            })
          )
        );
      }

      if (children.flag === "U") {
        handlers.push(
          this.props.editMyOption(
            this.buildFormData({
              id: children.id,
              name: children.name,
              price: Number(children.price || 0),
              isCheck: children.isCheck ? 1 : 0,
              check: children.isCheck ? 1 : 0,
              product_id: product_id,
              option_group_id: option_group_id,
              dp_ord_seq: children.dp_ord_seq,
            })
          )
        );
      }

      if (children.flag === "D") {
        handlers.push(this.props.deleteMyOption({ id: children.id }));
      }
    }
    await Promise.all(handlers);
    this.onSuccess();
  };

  onAddOptions = async (option, product_id) => {
    const resOptGrp = await this.props.createMyOptionGroup(
      this.buildFormData({
        name: option.name,
        type: option.type,
        dp_ord_seq: option.dp_ord_seq,
      })
    );

    const handlers = [];
    const { id: option_group_id } = resOptGrp.data.data;
    for (const children of option.childrens) {
      handlers.push(
        this.props.createMyOption(
          this.buildFormData({
            name: children.name,
            price: children.price,
            isCheck: children.isCheck ? 1 : 0,
            check: children.isCheck ? 1 : 0,
            product_id: product_id,
            option_group_id: option_group_id,
            dp_ord_seq: children.dp_ord_seq,
          })
        )
      );
    }
    await Promise.all(handlers);
    this.onSuccess();
  };

  onDeleteOptions = async (option) => {
    const deleteAsync = [];
    for (const children of option.childrens) {
      deleteAsync.push(this.props.deleteMyOption({ id: children.id }));
    }
    await Promise.all(deleteAsync);
    await this.props.deleteMyOptionGroup({ id: option.id });
    this.onSuccess();
  };

  getDataSaveOptions = () => {
    return this.state.options
      .filter((option) => option.name && this.notEmptyNumber(option.type))
      .map((option, index) => {
        option.childrens = option.childrens
          .filter(
            (children) => children.name && this.notEmptyNumber(children.price)
          )
          .map((children, indexChildren) => {
            if (children.id && children.delt === "N") {
              return { ...children, dp_ord_seq: indexChildren, flag: "U" };
            }

            if (children.id && children.delt === "Y") {
              return { ...children, dp_ord_seq: indexChildren, flag: "D" };
            }

            if (!children.id) {
              return { ...children, dp_ord_seq: indexChildren, flag: "I" };
            }
          });

        if (option.id && option.delt === "N") {
          return { ...option, dp_ord_seq: index, flag: "U" };
        }

        if (option.id && option.delt === "Y") {
          return { ...option, dp_ord_seq: index, flag: "D" };
        }

        if (!option.id) {
          return { ...option, dp_ord_seq: index, flag: "I" };
        }
      });
  };

  createChildrenOption = (autoAddOptions = false) => {
    return { name: "", price: 0, delt: "N", autoAddOptions, isCheck: false };
  };

  createOption = (autoAddOptions = false) => {
    return {
      name: "",
      type: 1,
      autoAddOptions,
      delt: "N",
      childrens: [],
    };
  };

  handleDeleteGroupOption = (index) => {
    const { options } = this.state;

    let option = options[index];

    if (!option.id) {
      options.splice(index, 1);
    } else {
      option.delt = "Y";
      if (option.childrens && option.childrens.length) {
        option.childrens = option.childrens
          .map((child) => {
            if (child.id) {
              return { ...child, delt: "Y" };
            }

            return null;
          })
          .filter((child) => child);
      }

      options[index] = option;
    }

    this.setState({ options });
  };

  handleDeleteChildOption = (index, indexChildren) => {
    const { options } = this.state;
    if (options[index].childrens[indexChildren].id) {
      options[index].childrens[indexChildren].delt = "Y";
    } else {
      options[index].childrens.splice(indexChildren, 1);
    }

    this.setState({ options });
  };

  onChangeRadio = (indexParent, indexChildren) => {
    const { options } = this.state;

    let { childrens } = options[indexParent];

    childrens = childrens.map((child, indexChil) => ({
      ...child,
      isCheck: indexChil === indexChildren,
    }));

    options[indexParent].childrens = childrens;

    this.setState({ options });
  };

  onChangeCheckbox = (checked, indexParent, indexChildren) => {
    const { options } = this.state;
    let { childrens } = options[indexParent];
    childrens[indexChildren].isCheck = checked;
    options[indexParent].childrens = childrens;
    this.setState({ options });
  };

  autoAddNextChildren = (index, indexChildren) => {
    const { options } = this.state;
    if (!options.length || !options[index]) return;

    const option = options[index];
    const { childrens } = option;

    const children = childrens[indexChildren];

    const isEnterPrice = this.notEmptyNumber(children.price);

    if (children.autoAddOptions && children.name && isEnterPrice) {
      childrens[indexChildren].autoAddOptions = false;
      childrens.push(this.createChildrenOption(true));
      options[index].childrens = childrens;
      this.setState({ options });
    }
  };

  onChangeOrder = (index, up = true) => {
    const options = this.state.options;
    if (!options || !options.length) return;

    if (index < 0) return;
    if (index > options.length - 1) return;

    let nextIndex = index - 1;
    if (!up) nextIndex = index + 1;

    if (nextIndex < 0) return;
    if (nextIndex > options.length - 1) return;

    const currentOption = options[index];
    const nextOption = options[nextIndex];

    options[index] = nextOption;
    options[nextIndex] = currentOption;

    this.setState({ options });
  };

  onChangeOrderChildren = (indexGrp, index, up = true) => {
    const options = this.state.options;

    if (!options || !options.length) return;
    const { childrens } = options[indexGrp];
    if (!childrens || !childrens.length) return;

    let nextIndex = index - 1;
    if (!up) nextIndex = index + 1;

    if (nextIndex < 0) return;
    if (nextIndex > childrens.length - 1) return;

    const currentOption = childrens[index];
    const nextOption = childrens[nextIndex];

    childrens[index] = nextOption;
    childrens[nextIndex] = currentOption;

    options[indexGrp].childrens = childrens;
    this.setState({ options });
  };

  onChangeOption = (e, index) => {
    const options = this.state.options;
    const option = options[index];

    option[e.target.name] = e.target.value;
    options[index] = option;

    if (e.target.name === "type" && e.target.value == 1) {
      const indexChil = option.childrens.findIndex((el) => !!el.isCheck);

      if (indexChil !== -1 && option.childrens) {
        option.childrens = option.childrens.map((o, index) => {
          if (index === indexChil) {
            return { ...o, isCheck: true };
          }

          return { ...o, isCheck: false };
        });
      }
    }

    this.setState({ options }, () => {
      this.autoAddOptionChildren(index);
    });
  };

  onChangeChildren = (e, indexOption, indexChildren) => {
    const { options } = this.state;
    const children = options[indexOption].childrens[indexChildren];
    children[e.target.name] = e.target.value;

    options[indexOption].childrens[indexChildren] = children;

    this.setState({ options }, () => {
      this.autoAddNextChildren(indexOption, indexChildren);
    });
  };

  notEmptyNumber = (number) => {
    return number !== null && number !== undefined && number !== "";
  };

  autoAddOptionChildren = (index) => {
    const options = this.state.options;
    if (!options.length || !options[index]) return;

    const option = options[index];
    const { autoAddOptions, name, type } = option;

    if (autoAddOptions && name && type) {
      const filters = option.childrens.filter((o) => !!o.isCheck);

      if (!filters || !filters.length) {
        option.childrens.push({
          ...this.createChildrenOption(true),
          isCheck: true,
        });
      } else {
        option.childrens.push(this.createChildrenOption(true));
      }

      option.autoAddOptions = false;
      options[index] = option;
      this.setState({ options });
    }
  };

  getOptions = () => {
    if (!this.state.options || !this.state.options.length) return [];

    const options = this.state.options.map((option) => {
      let { childrens } = option;

      childrens = childrens.filter((children) => children.delt === "N");
      option.childrens = childrens;

      return option;
    });
    const result = options.filter((option) => option.delt === "N");

    return result;
  };

  addGroupOption = () => {
    const options = [...this.state.options, this.createOption(true)];
    this.setState({ options });
  };

  renderGroupOptions = () => {
    const { errors } = this.state;
    const result = [];

    this.state.options.forEach(({ name, type, delt, childrens }, index) => {
      if (delt === "N") {
        result.push(
          <React.Fragment key={index}>
            <Pane className="col-lg-5">
              <Form.Field className="form-item">
                <label htmlFor="name" className="form-label">
                  <FormattedMessage
                    id="option_group_name"
                    defaultMessage="Name"
                  />
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder=""
                  className="form-input"
                  value={name}
                  onChange={(e) => this.onChangeOption(e, index)}
                />
              </Form.Field>
            </Pane>
            <Pane className="col-lg-5">
              <Form.Field className="form-item">
                <label htmlFor="name" className="form-label">
                  <FormattedMessage
                    id="option_group_type"
                    defaultMessage="Name"
                  />
                </label>
                <Select
                  value={type}
                  options={this.state.types}
                  name="type"
                  onChange={(e, { value }) => {
                    e.target.name = "type";
                    e.target.value = value;
                    this.onChangeOption(e, index);
                  }}
                />
              </Form.Field>
            </Pane>
            <Pane className="col-lg-2 align-items-center d-lg-flex">
              {name && type ? (
                <Icon
                  name="trash alternate"
                  className="mt-4"
                  size="large"
                  onClick={() => this.handleDeleteGroupOption(index)}
                />
              ) : null}
              <Icon
                name="arrow alternate circle down"
                className="mt-4"
                size="large"
                onClick={() => this.onChangeOrder(index, false)}
              />
              <Icon
                name="arrow alternate circle up"
                className="mt-4"
                size="large"
                onClick={() => this.onChangeOrder(index, true)}
              />
            </Pane>
          </React.Fragment>
        );

        childrens.forEach((children, indexChildren) => {
          if (children.delt === "N") {
            result.push(
              <React.Fragment>
                <Pane className="col-lg-1 align-items-center pl-5 justify-content-lg-center d-lg-flex mt-4">
                  <Form.Field>
                    {children.name && this.notEmptyNumber(children.price) ? (
                      type === 1 ? (
                        <Radio
                          name={"radio" + index}
                          value={indexChildren}
                          checked={children.isCheck}
                          onChange={(e) =>
                            this.onChangeRadio(index, indexChildren)
                          }
                        />
                      ) : (
                        <Checkbox
                          name={"check" + index}
                          checked={children.isCheck}
                          onChange={(e, { checked }) => {
                            this.onChangeCheckbox(
                              checked,
                              index,
                              indexChildren
                            );
                          }}
                        />
                      )
                    ) : null}
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-4">
                  <Form.Field className="form-item">
                    <label htmlFor="name" className="form-label">
                      <FormattedMessage
                        id="option_children_name"
                        defaultMessage="Name"
                      />
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder=""
                      className="form-input"
                      value={children.name}
                      onChange={(e) =>
                        this.onChangeChildren(e, index, indexChildren)
                      }
                    />
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-4">
                  <Form.Field className="form-item">
                    <label htmlFor="name" className="form-label">
                      <FormattedMessage
                        id="option_children_price"
                        defaultMessage="Price"
                      />
                    </label>
                    <input
                      type="number"
                      name="price"
                      placeholder=""
                      className="form-input"
                      value={children.price}
                      onChange={(e) =>
                        this.onChangeChildren(e, index, indexChildren)
                      }
                    />
                  </Form.Field>
                </Pane>
                <Pane className="col-lg-3 align-items-center d-lg-flex">
                  {children.name && this.notEmptyNumber(children.price) ? (
                    <>
                      <Icon
                        name="trash alternate"
                        className="mt-4"
                        size="large"
                        onClick={() =>
                          this.handleDeleteChildOption(index, indexChildren)
                        }
                      />
                      <Icon
                        name="arrow alternate circle down"
                        className="mt-4"
                        size="large"
                        onClick={() =>
                          this.onChangeOrderChildren(
                            index,
                            indexChildren,
                            false
                          )
                        }
                      />
                      <Icon
                        name="arrow alternate circle up"
                        className="mt-4"
                        size="large"
                        onClick={() =>
                          this.onChangeOrderChildren(index, indexChildren, true)
                        }
                      />
                    </>
                  ) : null}
                </Pane>
              </React.Fragment>
            );
          }
        });
      }
    });

    return result;
  };

  onSuccess() {
    this.setState({ loading: false });
    this.props.history.push(
      `/u/${formatRoute(this.props.userInfo.displayName)}/products`
    );
  }
  compareOptions(a, b) {
    if (Number(a.dpOrdSeq) < Number(b.dpOrdSeq)) {
      return -1;
    }

    if (Number(a.dpOrdSeq) > Number(b.dpOrdSeq)) {
      return 1;
    }
  }

  saveProduct = (categoryId) => {
    const data = new FormData();
    this.state.imageFiles[0] &&
      this.state.imageFiles[0].type &&
      data.append("primary_image", this.state.imageFiles[0]);
    this.state.imageFiles[1] &&
      this.state.imageFiles[1].type &&
      data.append("sub_image_1", this.state.imageFiles[1]);
    this.state.imageFiles[2] &&
      this.state.imageFiles[2].type &&
      data.append("sub_image_2", this.state.imageFiles[2]);
    this.state.imageFiles[3] &&
      this.state.imageFiles[3].type &&
      data.append("sub_image_3", this.state.imageFiles[3]);
    this.state.imageFiles[4] &&
      this.state.imageFiles[4].type &&
      data.append("sub_image_4", this.state.imageFiles[4]);
    this.state.imageFiles[5] &&
      this.state.imageFiles[5].type &&
      data.append("sub_image_5", this.state.imageFiles[5]);
    data.append("category_id", categoryId);
    data.append("name", this.state.data.name);
    data.append("description", this.state.data.description);
    data.append("meta_desc", this.state.data.meta_desc);
    data.append("currency_unit", "AUD");
    data.append("price", this.state.data.price);
    data.append("id", this.props.id);
    data.append("inventory_status", this.state.data.inventoryStatus ? 1 : 0);

    this.props
      .editMyProduct(data)
      .then((response) => {
        const { id: product_id } = response.data.data;
        const options = this.getDataSaveOptions();

        if (options && options.length) {
          for (const option of options) {
            const { flag } = option;

            if (flag === "I") {
              this.onAddOptions(option, product_id);
            }

            if (flag === "U") {
              this.onUpdateOptions(option, product_id);
            }

            if (flag === "D") {
              this.onDeleteOptions(option);
            }
          }
        } else {
          this.setState({ loading: false });
          // this.props.getMyProducts()
          this.props.history.push(
            `/u/${formatRoute(this.props.userInfo.displayName)}/products`
          );
        }
      })
      .catch((error) => {
        const errors = {};
        errors.message =
          "Product: " + handleError(error, "Can't create new a product");

        this.setState({
          loading: false,
          errors,
        });
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCategory(nextProps.categories);
    this.handleGroup();
  }

  componentDidMount() {
    const images = [];
    const imageFiles = [];
    this.setState({
      fetched: false,
      fetching: true,
    });

    this.props.optionsProduct({ id: this.props.id }).then((response) => {
      let options = ProductAdapter.options.parseResponse(response.data);

      options.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
      for (const option of options) {
        option.childrens.sort((a, b) => a.dpOrdSeq - b.dpOrdSeq);
        option.childrens.push(this.createChildrenOption(true));
      }

      this.setState({ options });
    });

    this.props.getMyProductDetail({ id: this.props.id }).then((response) => {
      const product = ProductAdapter.read.parseResponse(response.data);

      if (product.primaryImage) {
        images.push(product.primaryImage);
        imageFiles.push(product.primaryImage);
      }
      if (product.subImage1 && product.subImage1 !== "null") {
        images.push(product.subImage1);
        imageFiles.push(product.subImage1);
      }
      if (product.subImage2 && product.subImage2 !== "null") {
        images.push(product.subImage2);
        imageFiles.push(product.subImage2);
      }
      if (product.subImage3 && product.subImage3 !== "null") {
        images.push(product.subImage3);
        imageFiles.push(product.subImage3);
      }
      if (product.subImage4 && product.subImage4 !== "null") {
        images.push(product.subImage4);
        imageFiles.push(product.subImage4);
      }
      if (product.subImage5 && product.subImage5 !== "null") {
        images.push(product.subImage5);
        imageFiles.push(product.subImage5);
      }

      this.setState({
        images,
        imageFiles,
        fetched: true,
        fetching: false,
        data: {
          name: product.name || "",
          categoryId: product.categoryId || "",
          description: product.description || "",
          meta_desc: product.meta_desc || "",
          price: parseInt(product.originalPrice, 10) || 0,
          unitOfPrice: product.currencyUnit | "",
          inventoryStatus: product.inventoryStatus,
        },
      });
    });
  }

  nestPath = (data) => {
    if (!isEmpty(data)) {
      return data.map((item, index) => ({
        index,
        key: item.id,
        pId: item.id,
        title: item.name,
        value: item.id,
        children: this.nestPath(item.children),
      }));
    }
    return [];
  };

  onSelectTreeSelect = (value) => {
    this.setState((prevState) => ({
      data: { ...prevState.data, categoryId: value },
    }));
  };

  render() {
    const {
      errors,
      data,
      dataCategory,
      loading,
      fetched,
      fetching,
    } = this.state;
    const { intl } = this.props;

    return (
      <Form className="form-overview pb-5" onSubmit={this.editProduct}>
        {fetched && !fetching ? (
          <>
            <Pane className="form-content">
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="Image" className="form-label">
                      <FormattedMessage id="image" defaultMessage="Image" />
                    </label>
                    <input
                      type="file"
                      id="image-menu"
                      className="form-input-file"
                      multiple
                      value={data.image}
                      onChange={this.onChangeImage}
                    />
                    <br />
                    <label htmlFor="image-menu" className="form-label-file">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/icons/upload.svg`
                        }
                        alt="upload"
                        className="icon"
                      />
                      Upload a photo
                    </label>
                    <Pane
                      className="slider-content-main d-flex"
                      style={{ padding: "10px 0" }}
                    >
                      {this.state.images &&
                        this.state.images.map((item, index) => {
                          return (
                            <Pane className="slide-item mr-2" key={index}>
                              <Icon
                                name="remove circle"
                                onClick={this.removeImage.bind(this, index)}
                              />
                              <ImageS src={item} size="small" />
                            </Pane>
                          );
                        })}
                    </Pane>
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field error={!!errors.name} className="form-item">
                    <label htmlFor="name" className="form-label">
                      <FormattedMessage id="name" defaultMessage="Name" />
                    </label>
                    <input
                      className="form-input"
                      value={data.name}
                      onChange={this.onChange}
                      type="text"
                      id="name"
                      name="name"
                    />
                    {errors.name && <InlineMessage text={errors.name} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    error={!!errors.description}
                    className="form-item"
                  >
                    <label htmlFor="description" className="form-label">
                      <FormattedMessage
                        id="description"
                        defaultMessage="Description"
                      />
                    </label>
                    <ReactQuill
                      value={data.description}
                      onChange={(content) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            description: content,
                          },
                        }));
                      }}
                      formats={quill.formats}
                      modules={quill.modules}
                    />
                    {errors.description && (
                      <InlineMessage text={errors.description} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field error={!!errors.meta_desc} className="form-item">
                    <label for="name" className="form-label">
                      <FormattedMessage
                        id="sub_desc"
                        defaultMessage="Sub Description"
                      />
                    </label>
                    <input
                      maxLength={155}
                      className="form-input"
                      value={data.meta_desc}
                      onChange={this.onChange}
                      type="text"
                      id="meta_desc"
                      name="meta_desc"
                    />
                    {errors.meta_desc && (
                      <InlineMessage text={errors.meta_desc} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-6">
                  <Form.Field error={!!errors.price} className="form-item">
                    <label htmlFor="price" className="form-label">
                      <FormattedMessage
                        id="unit_price"
                        defaultMessage="Unit Price"
                      />
                    </label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      placeholder=""
                      className="form-input"
                      value={data.price}
                      onChange={this.onChange}
                    />
                    {errors.price && <InlineMessage text={errors.price} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="categoryId" className="form-label">
                      <FormattedMessage
                        id="inventory_name"
                        defaultMessage="In stock"
                      />
                    </label>
                    <Pane className="rdt">
                      <Radio
                        toggle
                        checked={this.state.data.inventoryStatus}
                        onChange={() =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              inventoryStatus: !this.state.data.inventoryStatus,
                            },
                          })
                        }
                      />
                    </Pane>
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <div className="choose-payment">
                    <Form.Field className="choose-item">
                      <Checkbox
                        radio
                        label={intl.formatMessage({
                          id: "select.category",
                          defaultMessage: "Select category",
                        })}
                        name="isNewCategory"
                        value={false}
                        checked={this.state.newCategory === false}
                        onChange={this.handleChangeCheckbox}
                      />
                    </Form.Field>
                    <Form.Field className="choose-item">
                      <Checkbox
                        radio
                        label={intl.formatMessage({
                          id: "select.category_add",
                          defaultMessage: "Add new category",
                        })}
                        name="isNewCategory"
                        value={true}
                        checked={this.state.newCategory === true}
                        onChange={this.handleChangeCheckbox}
                      />
                    </Form.Field>
                  </div>
                </Pane>
              </Pane>
              {this.state.newCategory === false ? (
                <Form.Field className="form-item" error={!!errors.categoryId}>
                  <label htmlFor="categoryId" className="form-label">
                    <FormattedMessage
                      id="category"
                      defaultMessage="Unit Price"
                    />
                  </label>
                  <TreeSelect
                    transitionName="rc-tree-select-dropdown-slide-up"
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
                    placeholder={<i>Select</i>}
                    searchPlaceholder="Search..."
                    treeDefaultExpandAll
                    treeData={this.nestPath(this.props.categories)}
                    treeNodeFilterProp="title"
                    value={data.categoryId}
                    onChange={this.onMultipleChange}
                    onSelect={this.onSelectTreeSelect}
                    allowClear
                  />
                  {errors.categoryId && (
                    <InlineMessage text={errors.categoryId} />
                  )}
                </Form.Field>
              ) : (
                <>
                  <Pane className="row">
                    <Pane className="col-lg-12">
                      <Form.Field
                        error={!!errors.category_name}
                        className="form-item"
                      >
                        <label htmlFor="category" className="form-label">
                          <FormattedMessage
                            id="category_name"
                            defaultMessage="Category Name"
                          />
                        </label>
                        <input
                          type="text"
                          id="category_name"
                          name="name"
                          placeholder=""
                          className="form-input"
                          value={dataCategory.name}
                          onChange={this.onChangeCategory}
                        />
                        {errors.category_name && (
                          <InlineMessage text={errors.category_name} />
                        )}
                      </Form.Field>
                    </Pane>
                  </Pane>
                  <Pane className="row">
                    <Pane className="col-lg-12">
                      <Form.Field
                        control={Select}
                        scrolling
                        className="form-item"
                        name="group"
                        error={!!errors.category_group}
                        label={
                          <label htmlFor="nameCategory" className="form-label">
                            {intl.formatMessage({
                              id: "group",
                              defaultMessage: "Group",
                            })}
                          </label>
                        }
                        value={dataCategory.group}
                        options={this.state.groups}
                        placeholder="Select"
                        onChange={this.onSelectGroup}
                      />
                    </Pane>
                  </Pane>
                  <Form.Field>
                    <Checkbox
                      radio
                      label="Select a image"
                      name="newImageCategory"
                      value="SELECT"
                      checked={this.state.newImageCategory === false}
                      onChange={this.handleChangeImageCategory}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      radio
                      label="Add new image"
                      name="newImageCategory"
                      value="NEW"
                      checked={this.state.newImageCategory === true}
                      onChange={this.handleChangeImageCategory}
                    />
                  </Form.Field>
                  {this.state.newImageCategory === false ? (
                    <Form.Field className="form-group">
                      <label htmlFor="category_image">
                        <FormattedMessage
                          id="category_image"
                          defaultMessage="Category Image"
                        />
                      </label>
                      <SelectImage
                        valueComponent={createClass({
                          render() {
                            return (
                              <Pane
                                className="Select-value"
                                title={
                                  this.props.option
                                    ? this.props.option.label
                                    : ""
                                }
                              >
                                <span className="Select-value-label">
                                  {this.props.option && (
                                    <img
                                      crossOrigin="anonymous"
                                      width="70px"
                                      alt="anonymous"
                                      height="50px"
                                      src={
                                        Config.NEW_API_URL +
                                        this.props.option.value
                                      }
                                    />
                                  )}
                                  {this.props.children}
                                </span>
                              </Pane>
                            );
                          },
                        })}
                        options={this.state.default_images}
                        placeholder={"Select image"}
                        onChange={this.setImageCategory}
                        value={this.state.imageCategory}
                        optionComponent={createClass({
                          propTypes: {
                            children: PropTypes.node,
                            className: PropTypes.string,
                            isDisabled: PropTypes.bool,
                            isFocused: PropTypes.bool,
                            isSelected: PropTypes.bool,
                            onFocus: PropTypes.func,
                            onSelect: PropTypes.func,
                            option: PropTypes.object.isRequired,
                          },
                          handleMouseDown(event) {
                            event.preventDefault();
                            event.stopPropagation();
                            this.props.onSelect(this.props.option, event);
                          },
                          handleMouseEnter(event) {
                            this.props.onFocus(this.props.option, event);
                          },
                          handleMouseMove(event) {
                            if (this.props.isFocused) return;
                            this.props.onFocus(this.props.option, event);
                          },
                          render() {
                            return (
                              <Pane
                                className={this.props.className}
                                onMouseDown={this.handleMouseDown}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseMove={this.handleMouseMove}
                                title={this.props.option.label}
                              >
                                <img
                                  crossOrigin="anonymous"
                                  width={
                                    this.props.option.width
                                      ? this.props.option.width
                                      : "50px"
                                  }
                                  alt="anonymous"
                                  height={
                                    this.props.option.height
                                      ? this.props.option.height
                                      : "50px"
                                  }
                                  src={
                                    Config.NEW_API_URL + this.props.option.value
                                  }
                                />
                              </Pane>
                            );
                          },
                        })}
                      />
                    </Form.Field>
                  ) : (
                    <Form.Field className="form-group">
                      <label htmlFor="category_image">
                        <FormattedMessage
                          id="category_image"
                          defaultMessage="Category Image"
                        />
                      </label>
                      <input
                        type="file"
                        id="category_image"
                        name="category_image"
                        placeholder=""
                        className="form-control"
                        value={dataCategory.image}
                        onChange={this.onChangeCategoryImage}
                      />
                      <Pane
                        className="slider-content-main d-flex"
                        style={{ padding: "10px 0" }}
                      >
                        {this.state.category_images &&
                          this.state.category_images.map((item, index) => {
                            return (
                              <Pane className="slide-item mr-2" key={index}>
                                <Icon
                                  name="remove circle"
                                  onClick={this.removeCategoryImage.bind(
                                    this,
                                    index
                                  )}
                                />
                                <ImageS src={item} size="small" />
                              </Pane>
                            );
                          })}
                      </Pane>
                    </Form.Field>
                  )}
                  {errors.category_message && (
                    <InlineMessage text={errors.category_message} />
                  )}
                  {errors.message && <InlineMessage text={errors.message} />}
                </>
              )}
              <Pane className="row">
                <Pane className="col-lg-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="optionGroup" className="form-label">
                      <FormattedMessage
                        id="option_group"
                        defaultMessage="Group Option"
                      />
                    </label>
                    <Button
                      type="button"
                      className="btn btn-save mt-4"
                      onClick={this.addGroupOption}
                    >
                      Add Group
                    </Button>
                  </div>
                </Pane>
                {this.renderGroupOptions()}
              </Pane>
            </Pane>
            <Pane className="row">
              <Pane className="col-lg-12 text-center">
                <Button
                  type="submit"
                  className="btn btn-save mt-4"
                  disabled={loading}
                  loading={loading}
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </Pane>
            </Pane>
          </>
        ) : (
          <>
            <Pane className="pt30">
              <Loading />
            </Pane>
          </>
        )}
      </Form>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  products: state.myProducts,
});

export default withRouter(
  connect(bindStateToProps)(injectIntl(CreateProductForm))
);
