import Config from 'constants/Config'
import { formatJson, formatRoute } from 'shared/helpers';

const exec = params => {
  const page = params.page || 1
  const size = params.size || 100
  const id = params.id || ''
  const group = params.group || ''
  const category_id = params.category_id || ''
  const images = params.images === null ? null : '';
  return {
    method: 'GET',
    url: `users/${id}/products?user_id=${id}&images.id=${images}&category_id=${category_id}&group=${group}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = (response) => {
  const products = [];
  if (response.data) {
    response.data.forEach((item) => {
      const product = formatJson(response, item.type, item.id);
      let promotion = {};

      if (product.promotion) {
        promotion = {
          id: product.promotion.id,
          percent: Number(product.promotion.percent),
          startDate: product.promotion.startDate,
          endDate: product.promotion.endDate,
        };
      }

      products.push({
        category: product.category,
        id: product.id,
        name: product.name,
        description: product.description,
        slug: `${formatRoute(product.name, '-')}-${product.id}`,
        primaryImage: product.primaryImage && product.primaryImage.url,
        rating:
          product.statistic && product.statistic.rating
            ? Number(product.statistic.rating)
            : 0,
        userId: product.user && product.user.id,
        originalPrice: Number(product.price),
        promotionPrice: Number(product.promotionPrice),
        type: 'products',
        inventoryStatus: product.inventoryStatus,
        promotion,
      });
    });
  }

  return products;
};
export { exec, parseResponse }
