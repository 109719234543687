import * as types from 'constants/ActionTypes'
import api from 'api'

// Get collection detail
export const getMyContacts = () => (dispatch) => dispatch({
  types: [types.RECEIVE_STORE_MY_CONTACTS, types.RECEIVE_STORE_MY_CONTACTS_SUCCESS],
  payload: {
    request: api.userContacts.get()
  }
})

// Create a collection
export const addMyContacts = (params) => (dispatch) => dispatch({
  type: types.ADD_STORE_MY_CONTACTS,
  payload: {
    request: api.userContacts.add(params)
  }
})

// Edit service detail
export const deleteMyContacts = (params) => (dispatch) => dispatch({
  type: types.DELETE_STORE_MY_CONTACTS,
  payload: {
    request: api.userContacts.remove(params)
  }
})


// //-----------For not auth------------------
export const getUserContactsRequest = (params) => (dispatch) => dispatch(getUserContacts(params))

export const getUserContacts = (userContacts) => {
  return {
    type: types.RECEIVE_STORE_USER_CONTACTS,
    userContacts: userContacts
  }
}

export const addUserContactsRequest = (userContact) => (dispatch) => dispatch(addUserContacts(userContact))

export const addUserContacts = (userContact) => {
  return {
    type: types.ADD_STORE_USER_CONTACTS,
    userContact: userContact
  }
}

export const deleteUserContactsRequest = (id, typeBusiness) => (dispatch) => dispatch(deleteUserContacts(id, typeBusiness))

export const deleteUserContacts = (id, typeBusiness) => {
  return {
    type: types.DELETE_STORE_USER_CONTACTS,
    id: id,
    typeBusiness: typeBusiness
  }
}

