import {
  HomePage,
  SigninPage,
  CartPage,
  UserPage,
  PolicyPage,
  ContactUsPage,
  SignupPage,
  ProductPage,
  PromotionsPage,
  ProductDetailPage,
  ServicePage,
  ServiceDetailPage,
  BookingServicePage,
  BookingPage,
  BookingPaymentPage,
  BookingSuccessPage,
  StorePage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  SearchPage,
  NotFoundPage,
  Classisied,
  SendVerifyEmail,
  VerifyEmail,
  TermPage,
  FAQPage,
  GuidePage,
  UserTokenPage,
  LearnMore,
  NoticePage,
  AboutUsPage,
  BestPicks,
  JustListedPage,
  LandingPage,
  FoodOrderPage,
  SlideShow,
  ScreenAdvPage,
  VideoPage,
  DeliveryServicePage,
  SocialUserPage,
  ReferencePage,
  QuickRegistrationPage,
  POSPage
} from "containers/pages";
import { Overview } from "components/scenes/user/overview";
import { Products } from "components/scenes/user/products";
import { ProductPicks } from "components/scenes/user/product-picks";
import { MyMenu } from "components/scenes/user/my_menu";
import { CreateProduct } from "components/scenes/user/products/create";
import { CreateProductPick } from "components/scenes/user/product-picks/create";
import { EditProduct as EditProductPick } from "components/scenes/user/product-picks/edit";
import { CreateCategory } from "components/scenes/user/categories/create";
import { EditProduct } from "components/scenes/user/products/edit";
import { CreateMenu } from "components/scenes/user/my_menu/create";
import { Collections } from "components/scenes/user/collections";
import { CreateCollection } from "components/scenes/user/collections/create";
import { EditCollection } from "components/scenes/user/collections/edit";
import { Dashboard } from "components/scenes/user/dashboard";
// TABLES
import { ListTable } from "components/scenes/user/tables";
import { EditTable } from "components/scenes/user/tables/edit";
import { CreateTable } from "components/scenes/user/tables/create";
// TABLES
import { EditMenu } from "components/scenes/user/my_menu/edit";
import { Services } from "components/scenes/user/services";
import { CreateService } from "components/scenes/user/services/create";
import { EditService } from "components/scenes/user/services/edit";
import { Promotions } from "components/scenes/user/promotions";
import { CreatePromotion } from "components/scenes/user/promotions/create";
import { EditPromotion } from "components/scenes/user/promotions/edit";
import { Orders } from "components/scenes/user/orders";
import { BuyingOrderDetail } from "components/scenes/user/orders";
import { SellingOrderDetail } from "components/scenes/user/orders";
import { Settings } from "components/scenes/user/settings";
import { Bookings } from "components/scenes/user/bookings";
import { BookedServiceDetail } from "components/scenes/user/bookings";
import { CustomerBookingDetail } from "components/scenes/user/bookings";
import { MyVideos } from "components/scenes/user/my-videos";
import { Review } from "components/scenes/user/reviews";
import { CreateVideo } from "components/scenes/user/my-videos/create";
import { EditVideo } from "components/scenes/user/my-videos/edit";
import { DeliveryService } from "components/scenes/user/delivery-service";
import { DeliveryDetail } from "components/scenes/user/delivery-service/detail";
import { DeliveryNowService } from "components/scenes/user/delivery-service/now";
import { Digitals } from "components/scenes/user/digitals";
import { CreateDigital } from "components/scenes/user/digitals/create";
import { EditDigital } from "components/scenes/user/digitals/edit";
import { CreateScreen } from "components/scenes/user/digitals/screens/create";
import { EditScreen } from "components/scenes/user/digitals/screens/edit";
import { UserContacts } from "components/scenes/user/contacts";
import { DigitalsShare } from "components/scenes/user/digitals-share";
// Store
import { StoreOverview } from "components/scenes/store/overview";
import { StoreShow } from "components/scenes/store/show";
import { StoreMenu } from "components/scenes/store/store_menu";
import { StoreProducts } from "components/scenes/store/products";
import { StoreServices } from "components/scenes/store/services";
import { StorePromotions } from "components/scenes/store/promotions";
import { StoreVideos } from "components/scenes/store/videos";
//User
import { UserOverview } from "components/scenes/social-users/overview";

import { Projects } from "components/scenes/user/projects";
import { CreateProject } from "components/scenes/user/projects/create";
import { EditProject } from "components/scenes/user/projects/edit";
import { Tasks } from "components/scenes/user/tasks";
import { CreateTask } from "components/scenes/user/tasks/create";
import { EditTask } from "components/scenes/user/tasks/edit";
import { Staffs } from "components/scenes/user/staffs";
import { CreateStaff } from "components/scenes/user/staffs/create";
import { EditStaff } from "components/scenes/user/staffs/edit";

import { ScanQrPromotions } from "components/scenes/user/scan-qr-promotion";
import { CreateQrPromotion } from "components/scenes/user/scan-qr-promotion/create";
import { EditQrPromotion } from "components/scenes/user/scan-qr-promotion/edit";
import { DetailQrPromotion } from "components/scenes/user/scan-qr-promotion/detail";


import App from "containers/App";

const routes = [
  {
    component: App,
    routes: [
      {
        path: "/",
        exact: true,
        component: HomePage,
      },
      {
        path: "/slideshow",
        exact: true,
        component: SlideShow,
      },
      {
        path: "/:id/reference",
        exact: true,
        component: ReferencePage,
      },
      {
        path: "/quick-registration",
        exact: true,
        component: QuickRegistrationPage,
      },
      {
        path: "/screen-advertising",
        exact: true,
        component: ScreenAdvPage,
      },
      {
        path: "/landing",
        exact: true,
        component: LandingPage,
      },
      {
        path: "/food-order",
        exact: true,
        component: FoodOrderPage,
      },
      {
        path: "/food-order/:type",
        exact: true,
        component: FoodOrderPage,
      },
      {
        path: "/food-order/:type/:id",
        exact: true,
        component: FoodOrderPage,
      },
      {
        path: "/food-order/:type/:id/:idChidren",
        exact: true,
        component: FoodOrderPage,
      },
      {
        path: "/notice",
        excat: true,
        component: NoticePage,
      },
      {
        path: "/learn-more",
        exact: true,
        component: LearnMore,
      },
      {
        path: "/guide/setup",
        exact: true,
        // type: 'auth',
        component: GuidePage,
      },
      {
        path: "/user",
        exact: true,
        // type: 'auth',
        component: UserTokenPage,
      },
      {
        path: "/signin",
        exact: true,
        // type: 'auth',
        component: SigninPage,
      },
      {
        path: "/search",
        exact: true,
        component: SearchPage,
      },
      {
        path: "/product-picks",
        exact: true,
        component: BestPicks,
      },
      {
        path: "/just-listed",
        exact: true,
        component: JustListedPage,
      },
      {
        path: "/products",
        exact: true,
        component: ProductPage,
      },
      {
        path: "/promotions",
        exact: true,
        component: PromotionsPage,
      },
      {
        path: "/products/categories/:name/:id",
        exact: true,
        component: ProductPage,
      },
      {
        path: "/videos",
        exact: true,
        component: VideoPage,
      },
      {
        path: "/products/:id",
        exact: true,
        component: ProductDetailPage,
      },
      {
        path: "/services",
        exact: true,
        component: ServicePage,
      },
      {
        path: "/services/categories/:name/:id",
        exact: true,
        component: ServicePage,
      },
      {
        path: "/services/:id",
        exact: true,
        component: ServiceDetailPage,
      },
      // {
      //   path: "/booking/:id",
      //   exact: true,
      //   // requireLogin: '/signin',
      //   component: BookingServicePage,
      // },
      {
        path: "/store-booking/:storeId",
        exact: true,
        component: BookingPage,
      },
      {
        path: "/booking-payment",
        exact: true,
        requireLogin: "/signin",
        component: BookingPaymentPage,
      },
      {
        path: "/booking-success",
        exact: true,
        requireLogin: "/signin",
        component: BookingSuccessPage,
      },
      {
        path: "/cart",
        exact: true,
        // requireLogin: '/signin',
        component: CartPage,
      },
      {
        path: "/delivery-services",
        exact: true,
        // requireLogin: '/signin',
        component: DeliveryServicePage,
      },
      {
        path: "/checkout",
        exact: true,
        // requireLogin: '/signin',
        component: CheckoutPage,
      },
      {
        path: "/payment",
        exact: true,
        requireLogin: "/signin",
        component: PaymentPage,
      },
      {
        path: "/checkout-success",
        exact: true,
        // requireLogin: "/signin",
        component: OrderSuccessPage,
      },
      {
        path: "/u/:id/",
        requireLogin: "/signin",
        component: UserPage,
        routes: [
          {
            path: "/u/:id/",
            exact: true,
            requireLogin: "/signin",
            component: Dashboard,
          },
          // TABLES
          {
            path: "/u/:id/tables",
            exact: true,
            requireLogin: "/signin",
            component: ListTable,
          },
          {
            path: "/u/:id/tables/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateTable,
          },
          {
            path: "/u/:id/tables/:tableId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditTable,
          },
          // TABLES
          {
            path: "/u/:id/digital-slideshow",
            exact: true,
            requireLogin: "/signin",
            component: Digitals,
          },
          {
            path: "/u/:id/digital-slideshow/screen/:screenId/digital-create/:type",
            exact: true,
            requireLogin: "/signin",
            component: CreateDigital,
          },
          {
            path: "/u/:id/digital-slideshow/screen/:screenId/digital-edit/:digitalId/:type",
            exact: true,
            requireLogin: "/signin",
            component: EditDigital,
          },
          {
            path: "/u/:id/digital-slideshow/screen/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateScreen,
          },
          {
            path: "/u/:id/digital-slideshow/screen/:screenId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditScreen,
          },
          {
            path: "/u/:id/digitals-share",
            exact: true,
            requireLogin: "/signin",
            component: DigitalsShare,
          },
          {
            path: "/u/:id/overview",
            exact: true,
            requireLogin: "/signin",
            component: Overview,
          },
          {
            path: "/u/:id/menu",
            exact: true,
            requireLogin: "/signin",
            component: MyMenu,
          },
          {
            path: "/u/:id/menu/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateMenu,
          },
          {
            path: "/u/:id/menu/:productId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditMenu,
          },
          {
            path: "/u/:id/products",
            exact: true,
            requireLogin: "/signin",
            component: Products,
          },
          {
            path: "/u/:id/store-reviews",
            exact: true,
            requireLogin: "/signin",
            component: Review,
          },
          {
            path: "/u/:id/my-videos",
            exact: true,
            requireLogin: "/signin",
            component: MyVideos,
          },
          {
            path: "/u/:id/my-videos/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateVideo,
          },
          {
            path: "/u/:id/my-videos/:videoId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditVideo,
          },
          {
            path: "/u/:id/product-picks",
            exact: true,
            requireLogin: "/signin",
            component: ProductPicks,
          },
          {
            path: "/u/:id/products/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateProduct,
          },
          {
            path: "/u/:id/delivery-service/create",
            exact: true,
            requireLogin: "/signin",
            component: DeliveryNowService,
          },
          {
            path: "/u/:id/delivery-service",
            exact: true,
            requireLogin: "/signin",
            component: DeliveryService,
          },
          {
            path: "/u/:id/delivery-service/:deliveryId/detail",
            exact: true,
            requireLogin: "/signin",
            component: DeliveryDetail,
          },
          {
            path: "/u/:id/product-picks/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateProductPick,
          },
          {
            path: "/u/:id/amazon/:productId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditProductPick,
          },
          {
            path: "/u/:id/categories/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateCategory,
          },
          {
            path: "/u/:id/products/:productId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditProduct,
          },
          {
            path: "/u/:id/services",
            exact: true,
            requireLogin: "/signin",
            component: Services,
          },
          {
            path: "/u/:id/services/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateService,
          },
          {
            path: "/u/:id/services/:serviceId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditService,
          },
          {
            path: "/u/:id/promotions",
            exact: true,
            requireLogin: "/signin",
            component: Promotions,
          },
          {
            path: "/u/:id/promotions/create",
            exact: true,
            requireLogin: "/signin",
            component: CreatePromotion,
          },
          {
            path: "/u/:id/promotions/:promotionId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditPromotion,
          },
          {
            path: "/u/:id/orders",
            exact: true,
            requireLogin: "/signin",
            component: Orders,
          },
          {
            path: "/u/:id/orders/:orderId/buying/detail",
            exact: true,
            requireLogin: "/signin",
            component: BuyingOrderDetail,
          },
          {
            path: "/u/:id/orders/:orderId/selling/detail",
            exact: true,
            requireLogin: "/signin",
            component: SellingOrderDetail,
          },
          {
            path: "/u/:id/settings",
            exact: true,
            requireLogin: "/signin",
            component: Settings,
          },
          {
            path: "/u/:id/bookings",
            exact: true,
            requireLogin: "/signin",
            component: Bookings,
          },
          {
            path: "/u/:id/bookings/:bookingId/detail",
            exact: true,
            requireLogin: "/signin",
            component: BookedServiceDetail,
          },
          {
            path: "/u/:id/bookings/sellers/:bookingId/detail",
            exact: true,
            requireLogin: "/signin",
            component: CustomerBookingDetail,
          },
          {
            path: "/u/:id/wallet",
            exact: true,
            requireLogin: "/signin",
            component: UserContacts,
          },
          {
            path: "/u/:id/collections",
            exact: true,
            requireLogin: "/signin",
            component: Collections,
          },
          {
            path: "/u/:id/collections/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateCollection,
          },
          {
            path: "/u/:id/collections/:productId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditCollection,
          },
          {
            path: "/u/:id/projects",
            exact: true,
            requireLogin: "/signin",
            component: Projects,
          },
          {
            path: "/u/:id/project/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateProject,
          },
          {
            path: "/u/:id/project/:projectId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditProject,
          },
          {
            path: "/u/:id/tasks",
            exact: true,
            requireLogin: "/signin",
            component: Tasks,
          },
          {
            path: "/u/:id/task/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateTask,
          },
          {
            path: "/u/:id/task/:taskId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditTask,
          },
          {
            path: "/u/:id/staffs",
            exact: true,
            requireLogin: "/signin",
            component: Staffs,
          },
          {
            path: "/u/:id/staff/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateStaff,
          },
          {
            path: "/u/:id/staff/:staffId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditStaff,
          },
          {
            path: "/u/:id/first-scan-qr-promotion",
            exact: true,
            requireLogin: "/signin",
            component: ScanQrPromotions,
          },
          {
            path: "/u/:id/first-scan-qr-promotion/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateQrPromotion,
          },
          {
            path: "/u/:id/first-scan-qr-promotion/:promotionId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditQrPromotion,
          },
          {
            path: "/u/:id/first-scan-qr-promotion/:promotionId/detail",
            exact: true,
            requireLogin: "/signin",
            component: DetailQrPromotion,
          },
        ],
      },
      {
        path: "/:id/pos",
        exact: true,
        requireLogin: "/signin",
        component: POSPage,
      },
      {
        path: "/orders/:orderId/buying",
        exact: true,
        requireLogin: "/signin",
        component: BuyingOrderDetail,
      },
      {
        path: "/orders/:orderId/selling",
        exact: true,
        requireLogin: "/signin",
        component: SellingOrderDetail,
      },
      {
        path: "/policy",
        exact: true,
        component: PolicyPage,
      },
      {
        path: "/about-us",
        exact: true,
        component: AboutUsPage,
      },
      {
        path: "/contact-us",
        exact: true,
        component: ContactUsPage,
      },
      {
        path: "/signup",
        exact: true,
        type: "auth",
        component: SignupPage,
      },
      {
        path: "/forgot-password",
        exact: true,
        component: ForgotPasswordPage,
      },
      {
        path: "/change-password",
        exact: true,
        component: ResetPasswordPage,
      },
      {
        path: "/terms",
        exact: true,
        component: TermPage,
      },
      {
        path: "/faqs",
        exact: true,
        component: FAQPage,
      },
      {
        path: "/page-not-found",
        exact: true,
        component: NotFoundPage,
      },
      {
        path: "/send-verify-email",
        exact: true,
        component: SendVerifyEmail,
      },
      {
        path: "/verify-email",
        exact: true,
        component: VerifyEmail,
      },
      {
        path: "/classisied",
        exact: true,
        component: Classisied,
      },
      {
        path: "/users/:userId",
        component: SocialUserPage,
        routes: [
          {
            path: "/users/:userId",
            exact: true,
            component: UserOverview,
          },
          {
            path: "/users/:userId/videos",
            exact: true,
            component: StoreVideos,
          },
          {
            path: "/users/:userId/videos/:id",
            exact: true,
            component: StoreVideos,
          },
        ],
      },
      {
        path: "/:storeId",
        component: StorePage,
        routes: [
          {
            path: "/:storeId",
            exact: true,
            component: StoreOverview,
          },
          {
            path: "/:storeId/menu",
            exact: true,
            component: StoreMenu,
          },
          {
            path: "/:id/menu/create",
            exact: true,
            requireLogin: "/signin",
            component: CreateMenu,
          },
          {
            path: "/u/:id/menu/:productId/edit",
            exact: true,
            requireLogin: "/signin",
            component: EditMenu,
          },
          {
            path: "/:storeId/products",
            exact: true,
            component: StoreProducts,
          },
          {
            path: "/:storeId/services",
            exact: true,
            component: StoreServices,
          },
          {
            path: "/:storeId/videos",
            exact: true,
            component: StoreVideos,
          },
          {
            path: "/:storeId/videos/:id",
            exact: true,
            component: StoreVideos,
          },
          {
            path: "/:storeId/promotions",
            exact: true,
            component: StorePromotions,
          },
        ],
      },
    ],
  },
];

export default routes;
