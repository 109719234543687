import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Pane } from 'shared/components/layouts';

class BusinessLogoItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    business: PropTypes.object,
  };

  render() {
    const { business } = this.props;

    return (
      <Pane className="col-lg-4 col-md-4 col-md-4 col-6">
        <Link to={`/${business.link}`}>
          <Pane className="bussiness-item">
            <img
              src={process.env.PUBLIC_URL + business.url}
              alt="Promotion"
              className="bussiness-icon"
            />
            <strong className="bussiness-title text-uppercase">
              {business.name}
            </strong>
          </Pane>
        </Link>
      </Pane>
    );
  }
}

export default BusinessLogoItem;
