import PropTypes from "prop-types";
import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import ServiceOptionItem from "./ServiceOptionItem";

class ServiceOption extends Component {
  static propTypes = {
    auth: PropTypes.object,
    onAddItemToCart: PropTypes.func,
    onNotification: PropTypes.object,
  };

  state = {
    added: false,
    adding: false,
    fetching: false,
    fetched: false,
    data: [],
    activeIndex: 0,
  };

  setActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };

  renderOptions = () => {
    if (!this.props.options || !this.props.options.length) {
      return null;
    }

    return this.props.options.map((item, index) => (
      <ServiceOptionItem
        {...item}
        key={index}
        index={index}
        activeIndex={this.state.activeIndex}
        setActiveIndex={this.setActiveIndex}
        updateOptionQuantity={this.props.updateOptionQuantity}
        updateOptionIds={this.props.updateOptionIds}
        optionIds={this.props.optionIds}
      />
    ));
  };

  render() {
    return (
      <Pane className="adds-block">
        <Pane className="row d-flex justify-content-start gap-5 m-4 align-items-start flex-column ">
          {this.renderOptions()}
        </Pane>
      </Pane>
    );
  }
}

export default ServiceOption;
