import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Pane } from "shared/components/layouts";
import CreateForm from "./CreateForm";
import { getProductCategories } from "actions/category";
import { getProductsByUserId } from "actions/myProducts";
import { createMyTables } from "actions/myTables";
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';

class CreateProduct extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getProductCategories: PropTypes.func,
    createMyProduct: PropTypes.func.isRequired,
    getProductsByUserId: PropTypes.func.isRequired,
  };

  getMyProducts = () => {
    this.props.getProductsByUserId({ id: this.props.userInfo.id });
  };

  componentDidMount() {
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/tables`,
        name: 'Tables'
      },
      {
        url: ``,
        name: 'Create new table',
        active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="text-center mb-4" id="anchor">
        </Pane>
        <CreateForm
          userInfo={this.props.userInfo}
          categories={this.props.categories}
          getMyProducts={this.getMyProducts}
          createMyTables={this.props.createMyTables}
          history={this.props.history}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.productCategories,
  userInfo: state.auth.user,
});

const bindDispatchToProps = (dispatch) => ({
  getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  createMyTables: bindActionCreators(createMyTables, dispatch),
});

export default connect(
  mapStateToProps,
  bindDispatchToProps
)(CreateProduct);
