import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import { List } from "semantic-ui-react";
import NotificationSystem from "react-notification-system";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
} from "react-share";
import { ReviewModal, LoginModal } from "shared/components";
import Config from "constants/Config";

class BannerIntro extends Component {
  static propTypes = {
    onNotification: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
  }

  state = {
    reviewsUser: {
      results: []
    },
    userLikes: 0,
    flag: false,
    extVideo: Config.extVideo,
    loading: false,
    loadingLike: false,
    loadingReview: false,
    isLike: false,
    openModal: false,
    openModalLogin: false
  };

  componentDidMount() {
    this.getDetail();
    this.getLikeVideo();
    this._notificationSystem = this.refs.notificationSystem;
  }

  getDetail = () => {
		this.props.getReviewsVideo({
			id: this.props.storeInfo.id,
			type: 'users',
		}).then(() => {
      this.setState({reviewsUser: this.props.reviews})
    });
	}

  getLikeVideo = async () => {
    await this.props.getLikesVideo({
      id: this.props.storeInfo.id,
      model: 'User',
    });
    if (this.props.auth.user.id) {
      await this.props.getUserLikesVideo({
        id: this.props.storeInfo.id,
        user_id: this.props.auth.user.id,
        model: 'User',
      }).then(() => {
        this.setState({ isLike: (this.props.like.results.length > 0 && this.props.userLike.results.length > 0 && this.props.userLike.results[0].like === 1 && this.props.like.results[0].objectId === parseInt(this.props.storeInfo.id)) })
        this.setState({ loadingLike: false })
        this.setState({ userLikes: this.props.like.results.length })
      })
    } else {
      this.setState({ userLikes: this.props.like.results.length })
    }
  };

  like = () => {
    if (!this.props.auth.user.id) {
      this.onOpenModal('login')
      return false;
    }

    if (this.state.loadingLike) {
      return false;
    }
    this.setState({ isLike: !this.state.isLike }, () => {
      this.setState({ userLikes: this.state.isLike ? (this.state.userLikes + 1) : ( this.state.userLikes -1 ) })
    })
    this.setState({ loadingLike: true })

    this.props
      .likesVideo({
        id: this.props.storeInfo.id,
        model: 'User',
        like: true,
      })
      .then(() => {
        this.getLikeVideo();
      })
  }

  onOpenReview = () => {
    this.onOpenModal('review')
  }

  onOpenModal = (param) => {
    if (param === 'login') {
      this.setState({ openModalLogin: true });
    } else {
      this.setState({ openModal: true });
    }

    // document.documentElement.style.width = "unset";
  };

  onCloseModal = (param) => {
    if (param === 'login') {
      this.setState({ openModalLogin: false });
    } else {
      this.setState({ openModal: false });
    }
  };

  render() {
    const { storeInfo, isBgVideo } = this.props;
    const { reviewsUser,isLike, openModal, openModalLogin, loading, flag, userLikes } = this.state;
    const shareURL = `https://cardbey.com/${storeInfo.id}`;
    return (
      <Pane className={"banner-container banner-store" + (isBgVideo ? ' banner-store_video' : '')} id="banner-container">
        <ReviewModal
          onCloseModal={() => this.onCloseModal('review')}
          openModal={openModal}
          onOpenModal={this.onOpenModal}
          auth={this.props.auth}
          onReview={this.props.reviewsVideo}
          getDetail={this.getDetail}
          onNotification={this._notificationSystem}
          idStore={storeInfo.id}
          nameBanner={storeInfo.name}
          reviews={reviewsUser}
        />
        <LoginModal
          onNotification={this._notificationSystem}
          signin={this.props.signin}
          signinSocial={this.props.signinSocial}
          auth={this.props.auth}
          onCloseModal={() => this.onCloseModal('login')}
          openModal={openModalLogin}
        />
        {isBgVideo && (
          <>
            <Pane className="bg-blur"></Pane>
            <video muted autoPlay playsInline loop allowFullScreen={false} width="100%" height="450">
              <source src={storeInfo.backgroundImage} id="video_here" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </>
        )}
        {!isBgVideo && (
          <Pane
            className="banner-item"
            style={{
              backgroundImage: `url(${storeInfo
                ? `${storeInfo.backgroundImage ||
                process.env.PUBLIC_URL +
                `/assets/images/default-image.png`}`
                : process.env.PUBLIC_URL + `/assets/images/default-image.png`
                })`,
            }}
          />
        )}
        <Pane>
          <ul className="d-flex justify-content-end action">
            <li className="mb-3 d-flex flex-column align-items-center">
              <i onClick={this.like} className={`${!isLike ? 'icon-heart-empty-1' : 'icon-heart red-heart'}`}></i>
              <Pane className="react-num">{userLikes}</Pane>
            </li>
            <li className="mb-3 d-flex flex-column align-items-center">
              <img
                onClick={this.onOpenReview}
                src={`${process.env.PUBLIC_URL
                  }/assets/images/icons/comments.svg`}
                alt="comments"
                className="icon-down"
                style={{filter: "brightness(0) invert(1)"}}
              />
              <Pane className="react-num mt-0">{reviewsUser.results.length}</Pane>
            </li>
            <li>
              <i onClick={() => this.setState({ flag: !this.state.flag })} className="icon ic-common-share font-weight-bold position-relative">
                <List horizontal className={`share-list ${flag ? 'd-flex' : 'hide'} `}>
                  <List.Content>
                    <FacebookShareButton className="share-item" url={shareURL}>
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                  </List.Content>
                  <List.Content>
                    <TwitterShareButton className="share-item" url={shareURL}>
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                  </List.Content>
                  <List.Content>
                    <EmailShareButton className="share-item" url={shareURL}>
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                  </List.Content>
                  <List.Content>
                    <LinkedinShareButton className="share-item" url={shareURL}>
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                  </List.Content>
                </List>
              </i></li>
          </ul>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

BannerIntro.propTypes = {};

export default BannerIntro;
