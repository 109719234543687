import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import { Pane } from 'shared/components/layouts';
import CityItem from './CityItem';

class City extends Component {
  static propTypes = {
    cities: PropTypes.array,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    cities: [],
  };

  state = {
    keyword: '',
    cities: [],
  };

  onChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.searchCity(this.state.keyword);
      }
    );
  };
  compare(cityA, cityB) {
    return cityA.text.localeCompare(cityB.text);
  }
  searchCity = (keyword) => {
    const results = this.props.cities.filter(
      (item) => item.text.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
    );

    this.setState({
      cities: results.sort((a, b) => this.compare(a, b)),
    });
  };

  componentDidMount() {
    this.setState({
      cities: this.props.cities.sort((a, b) => this.compare(a, b)),
    });
  }

  render() {
    const { intl, city } = this.props;

    return (
      <>
        <Pane className="inner-search">
          <input
            type="text"
            name="keyword"
            className="inner-input"
            placeholder={intl.formatMessage({ id: 'search' })}
            value={this.state.keyword}
            onChange={this.onChange}
          />
          <img
            src={process.env.PUBLIC_URL + `/assets/images/icons/search.svg`}
            alt="icons"
            className="icon-search"
          />
        </Pane>
        <Scrollbars className="category-content" style={{ height: 300 }}>
          <ul className="category-list">
            <li className="category-item">
              <Pane
                className="category-link category-all"
                onClick={() => this.props.onFilterArea('')}
              >
                All
              </Pane>
            </li>
            {this.state.cities.map((item) => {
              return (
                <CityItem
                  onFilterArea={this.props.onFilterArea}
                  key={item.value}
                  id={item.value}
                  name={item.text}
                  city={city}
                />
              );
            })}
          </ul>
        </Scrollbars>
      </>
    );
  }
}

export default injectIntl(City);
