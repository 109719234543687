import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { editFirstScanQrPromotion, getFirstScanQrPromotionsDetail } from "actions/scanQrPromotion";
import EditPromotionForm from "./EditPromotionForm";
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';
class EditQrPromotion extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(
          this.props.userInfo.displayName
        )}/first-scan-qr-promotion`, name: 'First scan qr promotion'
      },
      {
        url: ``, name: 'Update scan qr promotion', active: true
      }
    ];
    return (
      <>
        <Breadcumb breadcrumbs={breadcrumbs} />
        <div className="text-center mb-4" id="anchor">
        </div>
        <EditPromotionForm
          id={this.props.match.params.promotionId}
          userInfo={this.props.userInfo}
          history={this.props.history}
          editFirstScanQrPromotion={this.props.editFirstScanQrPromotion}
          getFirstScanQrPromotionsDetail={this.props.getFirstScanQrPromotionsDetail}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
});
const bindDispatchToProps = (dispatch) => ({
  editFirstScanQrPromotion: bindActionCreators(editFirstScanQrPromotion, dispatch),
  getFirstScanQrPromotionsDetail: bindActionCreators(getFirstScanQrPromotionsDetail, dispatch),
});
export default connect(mapStateToProps, bindDispatchToProps)(EditQrPromotion);