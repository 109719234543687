import * as types from "constants/ActionTypes";
import api from "api";
export const getServiceMembersByUserId = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_MY_SERVICE_MEMBERS,
      types.RECEIVE_MY_SERVICE_MEMBERS_SUCCESS,
      types.RECEIVE_MY_SERVICE_MEMBERS_FAILURE,
    ],
    payload: {
      request: api.user.serviceMembers(params),
    },
  });
export const getServiceMembersBookingAvailable = (params) => (dispatch) =>
  dispatch({
    types: [
      types.REQUEST_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE,
      types.RECEIVE_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE_SUCCESS,
      types.RECEIVE_MY_SERVICE_MEMBERS_BOOKING_AVAILABLE_FAILURE,
    ],
    payload: {
      request: api.user.serviceMembersBookingAvailable(params),
    },
  });
export const getServiceMemberDetail = (params) => (dispatch) =>
  dispatch({
    types: types.MY_SERVICE_MEMBER_DETAIL,
    payload: {
      request: api.user.readServiceMember(params),
    },
  });
export const createMyServiceMember = (params) => (dispatch) =>
  dispatch({
    types: types.CREATE_MY_SERVICE_MEMBER,
    payload: {
      request: api.user.addServiceMember(params),
    },
  });
export const editMyServiceMember = (params) => (dispatch) =>
  dispatch({
    types: types.EDIT_MY_SERVICE_MEMBER,
    payload: {
      request: api.user.editServiceMember(params),
    },
  });
export const removeMyServiceMember = (params) => (dispatch) =>
  dispatch({
    types: types.REMOVE_MY_SERVICE_MEMBER,
    payload: {
      request: api.user.removeServiceMember(params),
    },
  });