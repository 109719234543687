import Config from 'constants/Config';

const exec = params => {
  let data = {};

  data.payment_method = params.paymentMethod;

  if (!params.payAtStore) {
    data = {
      payment_method: params.paymentMethod,
      delivery_info: {
        delivery_address: params.deliveryAddress,
        delivery_postcode: params.deliveryPostCode,
      }
    };
  }

  return {
    method: 'PATCH',
    url: `/carts/${params.uuid}`,
    baseURL: Config.NEW_API_URL,
    data
  };
};

const parseResponse = response => {
  const data = {
    ...response
  };

  return data;
};

export { exec, parseResponse };
