import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import Slider from 'react-slick';
import { Pane, Loading } from 'shared/components/layouts';
import BannerItem from './BannerItem';

class BannerInfo extends Component {
  renderList(products) {
    let productitems = [];
    return products.map((item, index) => {
      productitems.push(item);
      if ((index + 1) % 4 === 0) {
        const productitemsdraw = productitems;
        productitems = [];
        return <BannerItem items={productitemsdraw} key={'Product' + index} />;
      } else if (index === products.length - 1) {
        const productitemsdraw = productitems;
        productitems = [];
        return <BannerItem items={productitemsdraw} key={'Product' + index} />;
      }
      return null;
    });
  }

  renderListResponsive(products) {
    return products.map((item, index) => {
      return (
        <BannerItem type="responsive" items={item} key={'Product' + index} />
      );
    });
  }

  render() {
    const arrayData1 = [
      {
        id: 1,
        image: `https://api.cardbey.com/images/service/3033.KmxZVA1xZDtXZ7TW2NMpNDibW0dU94kNoRzMNVdn.png`,
        slug: 'children-cut-3033',
      },
      {
        id: 2,
        image: `https://api.cardbey.com/images/service/3035.mXcLjhlmcCZPwQo0pdSYeKU7TQJbCNSQL4lMAzZj.png`,
        slug: 'hair-styling-3035',
      },
      {
        id: 3,
        image: `https://api.cardbey.com/images/service/3005.LxqJ3rqyxW0I5MsHruphFHC6KDtXGGnDNjlrVkD5.png`,
        slug: 'office-cleaning-3006',
      },
    ];
    const arrayData2 = [
      {
        id: 1,
        image: `https://api.cardbey.com/images/service/3031.4Xans8snb6nVXu379S3AUkZMVb3BCO4mfYSvZ6zv.png`,
        slug: 'acrylic-nails-3031',
      },
      {
        id: 2,
        image: `https://api.cardbey.com/images/service/3015.hhfIovaC6smpekQgODOHK2ECKt7YGyat9qJc1Ja2.jpeg`,
        slug: 'lee-construction-group-pty-ltd',
      },
      {
        id: 3,
        image: `https://api.cardbey.com/images/service/3008.xsxyHoSWhlHQQWDlTnoJa8hAqVNU6Ow9w9GfHr4x.jpeg`,
        slug: 'chin-waxing-3008',
      },
    ];
    const settings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Pane className="featured-container pt-4">
        <Pane className="container">
          <Pane className="featured-content">
            <Pane className="row">
              <Pane className="col-lg-12 col-md-12">
                <Slider className="shop-now-slider" {...settings}>
                  {!this.props.data.fetched && (
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  )}
                  {/* {this.renderList(this.props.data.promotions)} */}
                  {this.renderList(arrayData1)}
                  {this.renderList(arrayData2)}
                </Slider>
                <Slider className="shop-now-slider-responsive" {...settings}>
                  {!this.props.data.fetched && (
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  )}
                  {this.renderListResponsive(arrayData1)}
                  {this.renderListResponsive(arrayData2)}
                </Slider>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default BannerInfo;
