import React, { Component } from "react";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";
import { Pane } from "shared/components/layouts";
import ListItem from "./ListItem";
import ListAmazonItem from "./ListAmazonItem";
import ListTableItem from "./ListTableItem";
import ListCollection from "./ListCollection";
import Config from "constants/Config";

class List extends Component {
  static propTypes = {
    data: PropTypes.array,
    type: PropTypes.string,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
  };

  onGetImageCategory = (data) => {
    if (!isEmpty(data)) {
      return get(data[0], "category.primaryImage.url");
    }
    return null;
  };

  render() {
    const list =
      this.props.data &&
      this.props.data.map((item, index) => {
        if (this.props.type === "amazon") {
          return (
            <ListAmazonItem
              key={item.id}
              item={item}
              id={item.id}
              type={this.props.type}
              control={this.props.control}
              onDelete={this.props.onDelete}
              index={index}
              modeView={this.props.modeView}
            />
          );
        }
        if (this.props.type === "tables") {
          return (
            <ListTableItem
              key={item.id}
              item={item}
              id={item.id}
              type={this.props.type}
              control={this.props.control}
              onDelete={this.props.onDelete}
              index={index}
            />
          );
        }
        if (this.props.type === "collections") {
          return (
            <ListCollection
              key={item.id}
              item={item}
              id={item.id}
              name={item.name}
              type={this.props.type}
              image={item.primaryImage}
              rating={item.averageRating}
              control={this.props.control}
              onDelete={this.props.onDelete}
              index={index}
              modeView={this.props.modeView}
            />
          );
        }
        return (
          <ListItem
            key={item.id}
            item={item}
            id={item.id}
            name={item.name}
            type={this.props.type}
            image={item.primaryImage}
            rating={item.averageRating}
            control={this.props.control}
            onDelete={this.props.onDelete}
            index={index}
            modeView={this.props.modeView}
          />
        );
      });
    if (this.props.modeView === Config.MODE_VIEW_MENU.MODE_LIST) {
      return (
        <Pane className="adds-block block-menu-image-list-view">
          <Pane className="row pt-lg-3 pb-lg-3 align-items-center">
            <Pane className="col-lg-9 col-md-9 col-sm-12">
              <Pane className="row">{list}</Pane>
            </Pane>
            <Pane className="col-lg-3 col-md-3 d-none d-lg-block d-sm-none d-md-block background-menu" style={{
                  backgroundImage: `url(`+ this.onGetImageCategory(this.props.data) || process.env.PUBLIC_URL + `/assets/images/default-image.png` +`)`
                }}>
            </Pane>
          </Pane>
        </Pane>
    );
    }
    return (
      <Pane className="adds-block">
          <Pane className="row pb-0 pl-lg-2 pl-md-2 pr-lg-2 pr-md-2 pt-lg-2 pt-md-2 pl-sm-0 pr-sm-0 pt-sm-0 pb-sm-2">{list}</Pane>
      </Pane>
    );
  }
}

export default List;
