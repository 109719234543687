import { List } from '../list';
import PropTypes from 'prop-types';
import _, { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { Component } from 'react';
import update from 'immutability-helper';
import { bindActionCreators } from 'redux';
import { Empty } from 'components/common/empty';
import { Pane, Loading } from 'shared/components/layouts';
import { withRouter, Link } from 'react-router-dom';
import { formatRoute, handleError } from 'shared/helpers';
import { LoadingSearch } from 'components/common/loading';
import { ScrollMenu } from 'components/common/scrollMenu';
import NotificationSystem from 'react-notification-system';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { getProductsByUserId, deleteMyProduct } from 'actions/myProducts';
import {
  Form,
  Loader,
  Dimmer,
  Checkbox
} from "semantic-ui-react";
import {
  updateInfo,
} from "actions/user";
import { getUserInfo } from "actions/auth";
import {
  updateMyBusinessInfo,
} from "actions/myBusiness";

let isMounted = true;
/**
 * Set isMounted
 * @param {boolean} value
 * @returns {boolean} value of isMounted
 */
const setIsMounted = (value = true) => {
  isMounted = value;
  return isMounted;
};
/**
 * Get isMounted
 * @returns {boolean} value of isMounted
 */
const getIsMounted = () => isMounted;
class MyProduct extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    products: PropTypes.object,
    getProductsByUserId: PropTypes.func,
    deleteMyProduct: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this.state = {
      page: parseInt(queryString.parse(search).page, 10) || 1,
      fetching: false,
      fetched: false,
      selected: '',
      keySort: null,
      modeView: 'gridView',
      dataBusiness: { 
        isGridView: 1,
      },
      loading: false
    };
    this._notificationSystem = null;
    setIsMounted(true);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.getProductsByUserId({ load: true });
    });
    this.getProductsByUserId({ group: 'Product', load: true });
    this._notificationSystem = this.refs.notificationSystem;

    const { userInfo } = this.props;
    this.setState({
      dataBusiness: {
        ...this.state.dataBusiness,
        isGridView: userInfo.business.isGridView || 0,
      }
    })
  }

  onSubmit = async () => {
    const { intl, userInfo } = this.props;

    this.setState({ loading: true });

        const data = {
          id: userInfo.business.id,
          isGridView: this.state.dataBusiness.isGridView,
        };
      
      await this.props
        .updateMyBusinessInfo(data)
        .then((res) => {
          this.props.getUserInfo({ userId: userInfo.id })
          this.setState((prevState) => ({
            modeView: data.isGridView === 0 ? 'listView' : 'gridView',
            keySort: null,
          }));

          this.setState({ loading: false });
          this._notificationSystem.addNotification({
            message: intl.formatMessage({ id: "notification.successfully" }),
            level: "success"
          });
        })
        .catch((errors) => {
          this.setState({ loading: false });
          this._notificationSystem.addNotification({
            message: handleError(
              errors,
              intl.formatMessage({ id: "notification.please_try_again" })
            ),
            level: "error",
          });
        });
  };

  onSelect = (key) => {
    this.setState({
      keySort: key,
    });
  };

  componentWillUnmount() {
    setIsMounted(false);
    this.unlisten();
  }

  setStateData = (state, callback) => {
    if (!getIsMounted()) {
      return;
    }
    this.setState(state, callback);
  };

  getProductsByUserId = (params) => {
    const { userInfo } = this.props;
    this.props
      .getProductsByUserId(
        {
          id: userInfo.id,
          group: params.group,
          type: "Product",
          size: 1000,
          images: this.state.modeView === 'gridView' ? '' : null,
        },
        params.load
      )
      .then(() => {
        this.onSetDefaultView(userInfo);
        this.setStateData({
          fetched: true,
          fetching: false,
        });
      })
      .catch((error) => {
        this.setStateData({
          fetched: true,
          fetching: false,
        });
      });
  };

  onSetDefaultView = (storeInfo) => {
    if (!isEmpty(storeInfo)) {
      this.setState({
        modeView: storeInfo.business.isGridView === 1 ? 'gridView' : 'listView',
      });
    }
  };

  deleteItem = (params) => {
    const { intl } = this.props;

    if (this.props.products.results.length === 1) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }
    this.props
      .deleteMyProduct(params)
      .then(() => {
        this.getProductsByUserId({ page: this.state.page });
      })
      .catch((error) => {
        this._notificationSystem.addNotification({
          message: handleError(
            error,
            intl.formatMessage({ id: 'notification.please_try_again' })
          ),
          level: 'error',
        });
      });
  };

  sortByName = (data) => {
    if (this.state.keySort) {
      const index = data.findIndex((item) => item.name === this.state.keySort);
      const dataNew = update(data, {
        $splice: [[index, 1], [0, 0, data[index]]],
      });
      return dataNew;
    }
    return data;
  };

  onChangeModeView = () => {
    this.setState((prevState) => ({
      modeView: prevState.modeView === 'gridView' ? 'listView' : 'gridView',
      keySort: null,
    }));
  };

  handleChangeCheckbox = (e, { value }) => {
    const { intl } = this.props;
    let text = intl.formatMessage({ id: "confirm_show_items_type" });;
    if (window.confirm(text) == true) {
      this.setState(prevState => ({
        dataBusiness: {...prevState.dataBusiness, isGridView: value},
      }), () => {
        this.onSubmit();
      });
    } else {
      return false;
    }
  };

  render() {
    const { products, userInfo, intl } = this.props;
    const { fetched, fetching, selected, dataBusiness, loading } = this.state;
    const items = _.chain(products.results)
      .groupBy((x) => x.category && x.category.name)
      .map((item, key) => Object.assign({}, { name: key, items: item }))
      .sort(
        (a, b) =>
          new Date(_.get(b.items[0], 'category.createdAt')).getTime() / 1000 -
          new Date(_.get(a.items[0], 'category.createdAt')).getTime() / 1000
      )
      .value();

    return (
      <Pane className="overview-container">
        <Pane className="product-container">
          <div className="product-heading">
            <h3 className="block-title pt10 mt-0 mr-0 text-uppercase title-width-header">
              <FormattedMessage id="my_catalog" defaultMessage="Catalog" />
            </h3>
            <div className="scroll-bar">
              <ScrollMenu
                items={this.sortByName(items)}
                onSelect={this.onSelect}
                selected={selected}
              />
            </div>
            <Link
              to={`/u/${formatRoute(userInfo.displayName)}/products/create`}
              className="btn btn-circle btn-circle-primary d-none d-lg-block"
            >
              <FormattedMessage
                id="create_new_product"
                defaultMessage="Create a new product"
              />
            </Link>
          </div>
          <div className="overview-container2">
            <div className="product-heading">
              <div className="default-view">
                {loading && (
                  <Dimmer active inverted>
                    <Loading />
                  </Dimmer>
                )}
                {!loading && !isEmpty(userInfo.business) && (
                  <Form>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                      <Pane className="default-view__checkbox">
                        <Form.Field className="choose-item">
                          <Checkbox
                            radio
                            label={intl.formatMessage({
                              id: "choose_list_text",
                              defaultMessage: "List View",
                            })}
                            name="isGridView"
                            value={0}
                            checked={dataBusiness.isGridView === 0}
                            onChange={this.handleChangeCheckbox}
                          />
                        </Form.Field>
                        <Form.Field className="choose-item">
                          <Checkbox
                            radio
                            label={intl.formatMessage({
                              id: "choose_grid_text",
                              defaultMessage: "Grid View (have pictures)",
                            })}
                            name="isGridView"
                            value={1}
                            checked={dataBusiness.isGridView === 1}
                            onChange={this.handleChangeCheckbox}
                          />
                        </Form.Field>
                      </Pane>
                      </Pane>
                    </Pane>
                  </Form>
                )}
                {!loading && (
                  <Pane></Pane>
                )}
              </div>
              <Pane className="view d-flex justify-content-end mt-2">
                <span className="list-view" onClick={this.onChangeModeView}>
                  <img
                    src={
                      process.env.PUBLIC_URL + this.state.modeView === 'gridView'
                        ? '/assets/images/icons/list-view.svg'
                        : '/assets/images/icons/grid-view.svg'
                    }
                    className="image-logo"
                    alt="list-view"
                  />
                </span>
              </Pane>
            </div>
          </div>
          <Link
            to={`/u/${formatRoute(userInfo.displayName)}/products/create`}
            className="btn btn-circle btn-circle-primary d-lg-none mb-2 w-100"
          >
            <FormattedMessage
              id="create_new_product"
              defaultMessage="Create a new product"
            />
          </Link>
          {!fetching && fetched ? (
            <>
              {isEmpty(items) && <Empty />}
              {!isEmpty(items) &&
                this.sortByName(items).map((category) => {
                  if (isEmpty(category.items)) {
                    return <></>;
                  }
                  return (
                    <React.Fragment key={category.name}>
                      <Pane className="category-name"><strong>{category.name}</strong></Pane>
                      <List
                        data={category.items}
                        name={category.name}
                        type="products"
                        control={true}
                        onDelete={this.deleteItem}
                        modeView={this.state.modeView}
                        getServicesByUserId={this.props.getServicesByUserId}
                        userInfo={this.props.userInfo}
                      />
                      <Pane className="clearfix" />
                    </React.Fragment>
                  );
                })}
            </>
          ) : (
            <LoadingSearch type="white" />
          )}
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  products: state.myProducts,
});

const bindDispatchToProps = (dispatch) => ({
  getProductsByUserId: bindActionCreators(getProductsByUserId, dispatch),
  deleteMyProduct: bindActionCreators(deleteMyProduct, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  updateMyBusinessInfo: bindActionCreators(updateMyBusinessInfo, dispatch),
  updateUserInfo: bindActionCreators(updateInfo, dispatch),
});

export default withRouter(
  connect(
    bindStateToProps,
    bindDispatchToProps
  )(injectIntl(MyProduct))
);
