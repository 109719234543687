import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";
import { Pane } from "shared/components/layouts";
import Config from "constants/Config";
import ReactPlayer from "react-player";
import moment from "moment";

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    rating: PropTypes.number,
    control: PropTypes.bool,
    onDelete: PropTypes.func,
    intl: intlShape.isRequired,
    onReviewsVideo: PropTypes.func,
    onGetLikesVideo: PropTypes.func,
    onGetUserLikesVideo: PropTypes.func,
  };

  state = {
    play: 0,
    like: 0,
    extVideo : Config.extVideo
  };

  stopVideo = (event) => {
    const video = event.currentTarget;
    video.currentTime = 0;
    video.pause();
  };

  playVideo = (event) => {
    this.setState({
      play: 1,
    });
    const video = event.currentTarget;
    video.currentTime = 0;
    video.play();
    setTimeout(
      (v) => {
        v.currentTime = 0;
        v.pause();
      },
      Config.VIDEO_TIME,
      video
    );
  };

  onChooseVideo = () => {
    this.props.onChooseVideo(this.props.item);
    this.props.onReviewsVideo({
      id: this.props.item.id,
      type: 'collections',
    });
    this.props.onGetLikesVideo({
      id: this.props.item.id,
      model: 'Collection',
    });
    this.props.onGetUserLikesVideo({
      id: this.props.item.id,
      user_id: this.props.idUser,
      model: 'Collection',
    });
  };

  render() {
    const { item } = this.props;
    const {
      extVideo
    } = this.state;
    const extImage = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"];
    const ext = item.primaryImage != null ? item.primaryImage.substr(item.primaryImage.lastIndexOf(".") + 1) : "";
    return (
      <Pane className="col-12">
        <Pane className="adds-item m-0 flex-column">
          <Pane className="adds-item-video m-0" onClick={this.onChooseVideo}>
            {extImage.includes(ext) && (
              <Pane
                className="adds-images"
                style={{
                  backgroundImage: item.primaryImage
                    ? `url(${item.primaryImage})`
                    : process.env.PUBLIC_URL +
                      `/assets/images/default-image.png`,
                  height: "100px !important",
                }}
              >
              </Pane>
            )} {extVideo.includes(ext) && (
              <Pane
                className="adds-videos-wrapper"
                style={{
                  height: "auto",
                }}
              >
                <ReactPlayer
                  url={item.primaryImage}
                  className="react-player banner-video-over"
                  // controls
                  playsinline={false}
                  playing={false}
                  config={{
                    file: {
                      attributes: {
                        autoPlay: false,
                        muted: false,
                      },
                    },
                  }}
                ></ReactPlayer>
                <span className="play-icon"></span>
              </Pane>
            )} { !ext && !extVideo.includes(ext) && !extVideo.includes(ext) && (
              <Pane
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
              />
            )}
          </Pane>
          <Pane className="collection-name">{item.name}</Pane>
          <Pane className="adds-content p-0">
            <Pane className="collection-action">
              <h5 className="adds-norm" title={item.name}></h5>
              <Pane className="collection-time">
                <Pane>
                  { item.typeCollect === 'Video' &&(
                    <span><b>{item.views} views&nbsp;&nbsp;•&nbsp;</b></span>
                  )}
                  <em>{moment().diff(moment(item.created_at), 'hours') > 24 ? moment(item.created_at).format(" MMMM D YYYY") : moment(item.created_at).fromNow()}</em>
                </Pane>
                <Pane>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/like-gray.svg"
                    }
                    alt="image"
                    aria-hidden
                    className="icon mr-1 ml-1 mb-1"
                    style={{
                      height: 16,
                      width: 16,
                    }}
                  />
                  {item.likes == 0 ? 'like' : item.likes}
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default withRouter(injectIntl(ListItem));
