import NowItem from './NowItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

class BuyingOrderList extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
  };

  render() {
    const {
      type,
      location: { pathname },
    } = this.props;
    const listOrder = this.props.deliveries.results.map((item) => (
      <NowItem
        item={item}
        key={item.id}
        link={`${pathname}/${item.id}/detail`}
        type={this.props.type}
        notificationSystem={this.props.notificationSystem}
        getDelivery={this.props.getDelivery}
        getDeliverySchedule={this.props.getDeliverySchedule}
      />
    ));
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell style={{ minWidth: '150px' }}>
              Deliver to
            </Table.HeaderCell>
            <Table.HeaderCell style={{ minWidth: '150px' }}>
              Delivery Info
            </Table.HeaderCell>
            {type === 'DELIVERY_SCHEDULE' && (
              <Table.HeaderCell style={{ minWidth: '150px' }}>
                Time
              </Table.HeaderCell>
            )}
            <Table.HeaderCell style={{ minWidth: '100px' }}>
              Status
            </Table.HeaderCell>
            <Table.HeaderCell style={{ minWidth: '150px' }}>
              Cancel request
            </Table.HeaderCell>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{listOrder}</Table.Body>
      </Table>
    );
  }
}

export default withRouter(BuyingOrderList);
