import React, {  createRef}from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Pane, Loading } from 'shared/components/layouts';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import Slider from 'react-slick';
import queryString from 'query-string'
import Config from "constants/Config";
import UserAdapter from "services/users/adapter";
import Adapter from "services/digitalShares/adapter";
import { getMyScreenDetail, updateScreenOnline } from 'actions/screens';
import { screenActivityAdd } from 'actions/screenActivities'
import { getMyDigitalShare } from 'actions/digitalShare';
const SPEED_SLIDER = process.env.SPEED_SLIDER||5000;
class SlideShow extends React.Component {

  constructor(props, context) {
    super(props, context);
    const search = queryString.parse(this.props.location.search);
    this.state = {
      slideshow: [],
      screen: [],
      sliderchange: false,
      activeslide: 0,
      loading: false,
      extVideo: Config.extVideo,
      activeSlide:0,
      setSpeed :SPEED_SLIDER,
      deviceName:'',
      startTime:'',


    };
    this.slideItemRefs = [];
  };
  componentDidMount() {
    this.setState({startTime:  Date.now()});
    this.setState({deviceName:  this.detectDeviceType()});
    const search = queryString.parse(this.props.location.search);
    this.getMyScreenDetail(search);
    // this.getMyDigitalShare(search.screen_id);
    this.interval = setInterval(() => {
      this.getMyScreenDetail(search);
    }, 3600000);
    this.intervalUpdateOnline = setInterval(() => {
      this.updateScreenOnline(search);
    }, 60000); //1 minutes in milliseconds
    this.screenActivityAdd();
  }

  getMyScreenDetail = async (data) => {
    this.setState({
      loading: true,
    });
    await this.props.getMyScreenDetail({ hash: data.screen_id }).then(async (response) => {
      const item = UserAdapter.screenRead.parseResponse(response.data);
      let slideshow = [];
      let digitals = [];
      let digitals_share = [];
      if (item.items) {
        digitals = item.items;
      }
      await this.props.getMyDigitalShare({ hash: data.screen_id, status: 1 }).then((response) => {
        const items = Adapter.browse.parseResponse(response.data);

        if (items) {
          digitals_share = items;
        }
      });
      slideshow = [...digitals, ...digitals_share];
      if (slideshow.length > 0) {
        this.setState({ slideshow: slideshow, screen: item});
      }
      this.setState({loading: false });
    })
      .catch(() => {
        this.setState({ loading: false });
        this.props.history.replace(`/page-not-found`);
      });
  }

  getMyDigitalShare(screen_id) {
    this.props.getMyDigitalShare({ screen_id: screen_id, status: 1 }).then((response) => {
      const items = Adapter.browse.parseResponse(response.data);
    });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.intervalUpdateOnline);

  }
  detectDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (/Mobi/.test(userAgent) || /Android/.test(userAgent)) {
      return 'Mobile';
    } else if (/Tablet/.test(userAgent) || /iPad/.test(userAgent)) {
      return 'Tablet';
    } else if (/TV/.test(userAgent)) {
      return 'Tivi';
    } else {
      return 'Desktop';
    }
  };
  updateScreenOnline = async () => {
    const search = queryString.parse(this.props.location.search);
    await this.props.updateScreenOnline({ id: search.screen_id, deviceName: this.state.deviceName })
    .then(async (response) => {

    }).catch(() => {

    });
  }
  screenActivityAdd = async () => {
    const search = queryString.parse(this.props.location.search);
    await this.props.screenActivityAdd({ digital_screen_id: search.screen_id, device_name: this.detectDeviceType() })
    .then(async (response) => {
    }).catch(() => {

    });
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    const slideshow = [];
    nextProps.screens.results &&
      nextProps.screens.results.forEach((item, index) => {
        if (index === 0) {
          item.active = true;
        }
        if (item.items) {
          slideshow.push(item.items);
        }
      });

    this.setState({ slideshow });
  }
  onChangeSlide = (index) => {
    index = index == this.state.slideshow.length ? 0 : index;
    if(this.slideItemRefs[index] && this.slideItemRefs[index].ref ){
        this.setState({ setSpeed: this.slideItemRefs[index].type == 'video'? this.slideItemRefs[index].ref.current.duration *1000 : SPEED_SLIDER});
    } else {
        this.setState({ setSpeed:SPEED_SLIDER});
    }

  }

  render() {
    const settings = {
      autoplay: true,
      dots: false,
      speed: this.state.setSpeed,
      infinite: true,
      fade: true,
      arrows: false,
      cssEase: 'ease-in-out',
      pauseOnHover: false,
      pauseOnFocus: false,
      beforeChange: (current, next) => this.onChangeSlide(next),
    };
    const { slideshow, screen, extVideo, loading } = this.state;
    let slideItem = []
    if (screen.type === 'Horizontal') {
      slideItem = slideshow.length &&
        slideshow.filter((item) => get(item, 'imageHorizontal.url')).map((item, index) => {

          extVideo.includes(get(item, 'imageHorizontal.url').substr(get(item, 'imageHorizontal.url').lastIndexOf(".") + 1))?
          this.slideItemRefs.push({ref: createRef(), 'type': 'video'}):
          this.slideItemRefs.push({ref: createRef(), 'type': 'image'});

          return (
            <Pane key={index}>
              {extVideo.includes(get(item, 'imageHorizontal.url').substr(get(item, 'imageHorizontal.url').lastIndexOf(".") + 1)) ? (
                <Pane>
                  <video ref={this.slideItemRefs[index].ref} className="banner-item" autoPlay muted playsInline loop width="100%" height="600">
                    <source src={`${get(item, 'imageHorizontal.url')}`} preload="none" id="video_here" type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </Pane>
              ) : (
                <Pane ref={this.slideItemRefs[index].ref}
                  className="banner-item"
                  style={{
                    backgroundImage: `url(${get(item, 'imageHorizontal.url')})`,
                    transform: 'rotate(0deg)'
                  }}
                />
              )}
            </Pane>
          );
        });
    } else {
      slideItem = slideshow.length &&
        slideshow.filter((item) => get(item, 'imageVertical.url')).map((item, index) => {

          extVideo.includes(get(item, 'imageVertical.url').substr(get(item, 'imageVertical.url').lastIndexOf(".") + 1))?
          this.slideItemRefs.push({ref: createRef(), 'type': 'video'}):
          this.slideItemRefs.push({ref: createRef(), 'type': 'image'});

          return (
            <Pane key={index}>
              {extVideo.includes(get(item, 'imageVertical.url').substr(get(item, 'imageVertical.url').lastIndexOf(".") + 1)) ? (
                <Pane>
                  <video ref={this.slideItemRefs[index].ref} className={`banner-item banner-item-vertical`} autoPlay muted playsInline loop width="100%" height="600">
                    <source src={`${get(item, 'imageVertical.url')}`} preload="none" id="video_here" type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </Pane>
              ) : (
                <Pane  ref={this.slideItemRefs[index].ref}
                  className={`banner-item banner-item-vertical`}
                  style={{
                    backgroundImage: `url(${get(item, 'imageVertical.url')})`
                  }}
                />
              )}
            </Pane>
          );
        });
    }
    return (
      <>
      { screen && screen.adminActiveFlg &&
        <Pane className="slideshow-container">
          {!loading ? (
            <Pane>
              {
                (slideItem.length)
                  ?
                  <Slider className="banner-slider" {...settings}>
                    {slideItem}
                  </Slider>
                  :
                  <Pane className="text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/empty.png'
                      }
                      alt="empty"
                      className="img-empty"
                    />
                  </Pane>
              }
            </Pane>
          ) : (
            <Loading />
          )
          }
        </Pane>
      }
      { screen && screen.adminActiveFlg == false &&
        <Pane>
          <h1>Screen Disabled by admin</h1>
        </Pane>
      }
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  screens: state.screens,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getMyScreenDetail: bindActionCreators(getMyScreenDetail, dispatch),
  getMyDigitalShare: bindActionCreators(getMyDigitalShare, dispatch),
  updateScreenOnline: bindActionCreators(updateScreenOnline, dispatch),
  screenActivityAdd: bindActionCreators(screenActivityAdd, dispatch),

});
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SlideShow));
