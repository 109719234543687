import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { editMyTask, getTaskDetail } from "actions/tasks";
import EditTaskForm from "./EditTaskForm";
import { getProjectsByUserId } from "actions/projects";
class EditTask extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
    };
  }
  componentDidMount = () => {
    const { userInfo } = this.props;
    this.props.getProjectsByUserId({ id: userInfo.id, size: 999999 });
  };
  render() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="heading-title mt-2">
            <FormattedMessage id="edit_task" defaultMessage="Edit Task" />
          </h3>
        </div>
        <EditTaskForm
          id={this.props.match.params.taskId}
          userInfo={this.props.userInfo}
          history={this.props.history}
          editMyTask={this.props.editMyTask}
          getTaskDetail={this.props.getTaskDetail}
          projects={this.props.projects.results || []}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  projects: state.myProjects,
});
const bindDispatchToProps = (dispatch) => ({
  editMyTask: bindActionCreators(editMyTask, dispatch),
  getTaskDetail: bindActionCreators(getTaskDetail, dispatch),
  getProjectsByUserId: bindActionCreators(getProjectsByUserId, dispatch),
});
export default connect(mapStateToProps, bindDispatchToProps)(EditTask);