import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import ReactPaginate from 'react-paginate'
import { Pane, Loading } from 'shared/components/layouts'
import ProductList from './ProductList'
import { getProductsByUserId } from 'actions/myProducts'

class MyProducts extends Component {

  state = {
    data: [],
    dataTmp: []
  }

  getProducts = (params) => {
    this.props.getProductsByUserId(params).then(() => {
      if (this.props.products && this.props.products.results.length > 0) {
        this.setState({data: this.props.products.results, dataTmp: this.props.products.results});
      }
    })
  }

  onSelectItem = (id) => {
    this.props.onSelectItem(id)
  }

  handlePageClick = (data) => {
    const params = {
      id: this.props.userId,
      page: data.selected + 1
    }

    this.getProducts(params)
  }

  onChange = (e) => {
    if (e.target.value) {
      let products = this.state.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
      this.setState({
        dataTmp: products
      })
    } else {
      this.setState({
        dataTmp: this.state.data
      })
    }
  }

  componentDidMount() {
    this.getProducts({ id: this.props.userId, page: 1 })
  }

  render() {
    const { products } = this.props

    return (
      <Pane className='choose-data'>
        <Pane className='products-grids'>
          {
            products.fetching
            ? <Dimmer active inverted>
                <Loading />
              </Dimmer>
            : (
                products && products.results.length > 0
                ? <>
                  <input
                    placeholder="Search some item..."
                    className="form-control"
                    onChange={this.onChange}
                  />
                  <ProductList
                    products={this.state.dataTmp}
                    onSelectItem={this.onSelectItem}
                    itemsSelected={this.props.itemsSelected}
                  />
                  </>
                : <Pane className='block-no-content'>
                    <p><FormattedMessage id='notification.you_have_not_had_any_products_yet' defaultMessage={`You haven't had any products yet.`} /></p>
                  </Pane>
              )
          }
          <Pane className='clearfix' />
          <Pane className='pagination-bar text-center mt15 mb15'>
            <nav className="d-inline-b">
              {
                products.totalPages > 1
                ? <ReactPaginate
                    previousLabel="❮"
                    nextLabel="❯"
                    breakLabel={<button className="btn-break-paging">...</button>}
                    breakClassName={'break-me'}
                    previousClassName={'page-item'}
                    nextClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    pageCount={products.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    disableInitialCallback={true}
                  />
                : null
              }
            </nav>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const mapStateToProps = state => ({
  products: state.myProducts
})

export default connect(mapStateToProps, { getProductsByUserId })(MyProducts)
