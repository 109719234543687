import * as types from 'constants/ActionTypes'

export const getCoordinates = () => (dispatch) => dispatch({
    type: types.RECEIVE_COORDINATES
})

export const addCoordinate = (params) => (dispatch) => dispatch({
    type: types.ADD_COORDINATES,
    lat: params.lat,
    lng: params.lng
})
