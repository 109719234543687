import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Pane } from 'shared/components/layouts'
import { FormattedMessage } from 'react-intl'
import { getPromotionProductsByUserId, getPromotionServicesByUserId, createPromotion } from 'actions/myPromotions'
import CreatePromotionForm from './CreatePromotionForm'
import { Breadcumb } from '../../common/breadcumb';
import { formatRoute } from 'shared/helpers';

class CreatePromotion extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    getPromotionProductsByUserId: PropTypes.func.isRequired,
    createPromotion: PropTypes.func.isRequired,
    getPromotionServicesByUserId: PropTypes.func.isRequired
  }

  getAllPromotions = () => {
    this.props.getPromotionProductsByUserId({ id: this.props.userInfo.id })
    this.props.getPromotionServicesByUserId({ id: this.props.userInfo.id })
  }

  componentDidMount() {
    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  render() {
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/promotions`,
        name: 'Promotions'
      },
      {
        url: ``,
        name: 'Create new promotion',
        active: true
      }
    ];
    return (
      <Pane className='overview-container create-promotion' id="anchor">
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className='products-grids'>
          <Pane className='card-body'>
            <CreatePromotionForm
              userInfo={this.props.userInfo}
              getAllPromotions={this.getAllPromotions}
              createPromotion={this.props.createPromotion}
              history={this.props.history}
            />
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.user
})

const bindDispatchToProps = dispatch => ({
  getPromotionProductsByUserId: bindActionCreators(getPromotionProductsByUserId, dispatch),
  getPromotionServicesByUserId: bindActionCreators(getPromotionServicesByUserId, dispatch),
  createPromotion: bindActionCreators(createPromotion, dispatch)
})

export default connect(mapStateToProps, bindDispatchToProps)(CreatePromotion)
