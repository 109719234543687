import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Pane } from 'shared/components/layouts';
import { Currency } from 'shared/components';

class StorePromotionItem extends Component {
  static propTypes = {
    id: PropTypes.any,
    item: PropTypes.any,
    name: PropTypes.any,
    image: PropTypes.any,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    price: PropTypes.any,
    percentDiscount: PropTypes.any,
    type: PropTypes.string,
  };

  render() {
    const currentDate = moment(
      moment().format('DD/MM/YYYY'),
      'DD/MM/YYYY'
    ).unix();

    return (
<Pane className="col-lg-3 col-md-6 col-sm-6 col-12 pl-lg-2 pr-lg-2 pb-lg-2 pt-lg-2 pl-md-2 pr-md-2 pb-md-0 pt-md-2">
      <Pane className="item-list make-grid">
        {currentDate > moment(this.props.endDate, 'DD/MM/YYYY').unix() ? (
          <Pane className="cornerRibbons expired text">
            <FormattedMessage id="expired" defaultMessage="Expired" />
          </Pane>
        ) : this.props.percentDiscount ? (
          <Pane className="cornerRibbons promotion">{`${
            this.props.percentDiscount
          }%`}</Pane>
        ) : (
          <Pane className="cornerRibbons promotion text">
            <FormattedMessage id="in_promotion" defaultMessage="In promotion" />
          </Pane>
        )}
        <Pane className="no-padding photobox">
          <Pane className="add-image">
            <Link to={`/${this.props.type}/${this.props.item.item.slug}`}>
              <Pane
                className="list-item-image"
                style={{ backgroundImage: `url(${this.props.image})` }}
              >
                <img src={this.props.image} alt="" className="img-responsive" />
              </Pane>
            </Link>
          </Pane>
        </Pane>
        <Pane className="add-desc-box pb5">
          <Pane className="add-details">
            <h5 className="add-title pb0">
              <Link to={`/${this.props.type}/${this.props.item.item.slug}`} title={this.props.name}>
                {this.props.name}
              </Link>
            </h5>
          </Pane>
        </Pane>
        <Pane className="text-left price-box">
          {this.props.percentDiscount ? (
            <h2 className="item-price pb10">
              <span className="item_price text-block">
                <Currency price={this.props.promotionPrice} />{' '}
                <span className="text-block text-red text-underline">
                  <Currency price={this.props.price} />
                </span>
              </span>
            </h2>
          ) : (
            <h2 className="item-price pb10">
              <span className="item_price">
                <Currency price={this.props.price} />
                <span className="text-block text-red">&nbsp;</span>
              </span>
            </h2>
          )}
        </Pane>
        <Pane className="info-row">
          <p className="font-weight-normal mb5">
            <i className="icon-clock" />
            <FormattedMessage
              id="start_date"
              defaultMessage="Start date"
            />: {moment(this.props.startDate).format('DD/MM/YYYY')}
          </p>
          <p className="font-weight-normal mb5">
            <i className="icon-clock" />
            <FormattedMessage id="end_date" defaultMessage="End date" />:{' '}
            {moment(this.props.endDate).format('DD/MM/YYYY')}
          </p>
        </Pane>
      </Pane>
      </Pane>

    );
  }
}

export default StorePromotionItem;
