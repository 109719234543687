import * as types from 'constants/ActionTypes'
import BookingAdapter from 'services/bookings/adapter'

const initialState = {
  data: {},
  error: null,
  fetching: false,
  fetched: false
}

const buyingBookingDetail = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BOOKING_DETAILS_OF_BUYER:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_BOOKING_DETAILS_OF_BUYER_SUCCESS:
      return Object.assign({}, state, {
        data: BookingAdapter.buyers.read.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_BOOKING_DETAILS_OF_BUYER_FAILURE:
      return Object.assign({}, state, {
        data: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        data: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

const sellingBookingDetail = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_BOOKING_DETAILS_OF_SELLER:
      return Object.assign({}, state, {
        fetching: true
      })
    case types.RECEIVE_BOOKING_DETAILS_OF_SELLER_SUCCESS:
      return Object.assign({}, state, {
        data: BookingAdapter.sellers.read.parseResponse(action.payload.data),
        error: {},
        fetching: false,
        fetched: true
      })
    case types.RECEIVE_BOOKING_DETAILS_OF_SELLER_FAILURE:
      return Object.assign({}, state, {
        data: {},
        error: action.error,
        fetching: false,
        fetched: true
      })
    case types.CLEAR_DATA:
      return Object.assign({}, state, {
        data: {},
        error: null,
        fetching: false,
        fetched: false
      })
    default:
      return state
  }
}

export { buyingBookingDetail, sellingBookingDetail }
