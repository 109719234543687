import Config from 'constants/Config'
import { formatJson, formatRoute } from 'shared/helpers'

const exec = params => {
  
  const category = params.category || ''
  const type = params.type || ''
  return {
    method: 'GET',
    url: `/business/filter?category_id=${category}&type=${type}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const businesses = []
  if (response.data) {
    response.data.forEach(item => {
      const business = formatJson(response, item.type, item.id)
       if (business.user) {
        businesses.push({
          id: business.user ? business.user.id : null,
          name: business.user.fullname,
          slug: formatRoute(business.name, '-'),
          backgroundImage: business.user.backgroundImage && business.user.backgroundImage.url,
          profileImage: business.user.image && business.user.image.url,
          qrcode: business.user.qrcode && business.user.qrcode.url,
          business: {
            id: business.id,
            name: business.name,
            email: business.email,
            description: business.description,
            phone: business.phone,
            address: business.address,
            postCode: business.postCode,
            createAt: business.createdAt,
            updatedAt: business.updatedAt
          }
        })
      }
    })
  }

  const data = businesses

  return data
}

export { exec, parseResponse }
