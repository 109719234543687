import React, { Component } from 'react';
import { Pane } from 'shared/components/layouts';
import Banner from './banner/Banner';
import Explore from './explore/Explore';
import Business from './business/Business';
import Choose from './choose/Choose';
import Customer from './customer/Customer';

class AboutUs extends Component {
  render() {
    return (
      <Pane className="about-container">
        <Banner />
        <Explore />
        <Business />
        <Choose />
        <Customer />
        {/* 2020/07/31 delete  */}
        {/* <Stay /> */}
      </Pane>
    );
  }
}

export default AboutUs;
