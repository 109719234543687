import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Pane } from "shared/components/layouts";
import cookie from "react-cookies";
import { getAllProducts } from "actions/products";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class BusinessLogoItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    business: PropTypes.object,
    getAllProducts: PropTypes.func,
  };

  state = {
    promotions: 0,
  };

  getPromotion() {
    const params = {
      page: 1,
    };

    this.props.getAllProducts(params).then((res) => {
      let cookiescountPromotions = cookie.load("countPromotions");
      const cookiespreviouscountPromotions = cookie.load(
        "previouscountPromotions"
      );
      const countPromotions = res.data.countPromotions;
      if (cookiescountPromotions === undefined) {
        cookiescountPromotions = 0;
      }
      let diff =
        parseInt(countPromotions, 10) - parseInt(cookiescountPromotions, 10);
      if (diff < 0) {
        diff = 0;
      }
      const diffprevious =
        parseInt(countPromotions, 10) -
        parseInt(cookiespreviouscountPromotions, 10);
      if (cookiespreviouscountPromotions && diffprevious > 0) {
        const audioEl = document.getElementsByClassName("audio-element")[0];
        audioEl.play();
      }
      cookie.save("previouscountPromotions", countPromotions);
      this.setState(
        {
          promotions: diff,
        },
        () => {}
      );
    });
  }

  componentDidMount() {
    // if (this.props.business.link === "promotions") {
    //   this.getPromotionWithTimeOut();
    // }
  }

  getPromotionWithTimeOut() {
    this.getPromotion();
  }

  render() {
    const { business } = this.props;
    return (
      <Pane className="col-lg-4 col-md-4 col-12">
        <Link to={`${business.link}`}>
          <Pane className="bussiness-item">
            <img
              src={process.env.PUBLIC_URL + business.url}
              alt="Promotion"
              className="bussiness-icon"
            />
            {business.link === "promotions" &&
              this.state.promotions > 0 && (
                <span className="promotions-count">
                  {this.state.promotions}
                </span>
              )}
            {business.link === "promotions" && (
              <audio className="audio-element">
                <source src="/assets/sounds/noti.mp3" />
              </audio>
            )}
            <strong className="bussiness-title">{business.name}</strong>
          </Pane>
        </Link>
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
});
const bindDispatchToProps = (dispatch) => ({
  getAllProducts: bindActionCreators(getAllProducts, dispatch),
});
export default withRouter(connect(
  bindStateToProps,
  bindDispatchToProps
)(BusinessLogoItem));
