import Config from 'constants/Config'
import { formatJson } from 'shared/helpers'

const exec = (params) => {
  const page = params.page || 1;
  const size = params.size || 20;
  const store = params.store || '';
  return {
    method: 'GET',
    url: `/videos?&user_id=${store}&page[size]=${size}&page[number]=${page}`,
    baseURL: Config.NEW_API_URL
  }
}

const parseResponse = response => {
  const allVideos = []
  if (response.data) {
    response.data.forEach(item => {
      const video = formatJson(response, item.type, item.id)
      allVideos.push(video)
    })
  }

  return allVideos
}

export { exec, parseResponse }
