import * as types from 'constants/ActionTypes'
import api from 'api'

// Business
export const getMyBusinessInfo = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_MY_BUSINESS_INFO, types.RECEIVE_MY_BUSINESS_INFO_SUCCESS, types.RECEIVE_MY_BUSINESS_INFO_FAILURE],
  payload: {
    request: api.user.getMyBusinessInfo(params)
  }
})

export const updateMyBusinessInfo = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_UPDATE_MY_BUSINESS_INFO, types.RECEIVE_MY_BUSINESS_INFO_SUCCESS, types.RECEIVE_MY_BUSINESS_INFO_FAILURE],
  payload: {
    request: api.user.updateMyBusinessInfo(params)
  }
})

export const createMyBusinessInfo = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_CREATE_MY_BUSINESS_INFO, types.RECEIVE_MY_BUSINESS_INFO_SUCCESS, types.RECEIVE_MY_BUSINESS_INFO_FAILURE],
  payload: {
    request: api.user.createMyBusinessInfo(params)
  }
})
