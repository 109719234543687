import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { Pane } from 'shared/components/layouts';
import StoreItem from './StoreItem';

class StoreList extends Component {
  static propTypes = {
    filters: PropTypes.array,
    type: PropTypes.string,
    onLoad: PropTypes.func,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    filters: [],
    type: 'videos',
  };

  state = {
    keyword: '',
    filters: [],
  };

  onChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.searchStore(this.state.keyword);
      }
    );
  };

  searchStore = (keyword) => {
    const results = this.props.filters.filter(
      (item) => item.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
    );

    this.setState({
      filters: results,
    });
  };

  componentDidMount() {
    this.setState({
      filters: this.props.filters,
    });
  }

  render() {
    const { intl } = this.props;
    return (
      <>
        <Pane className="inner-search">
          <input
            type="text"
            name="keyword"
            className="inner-input"
            placeholder={intl.formatMessage({ id: 'search' })}
            value={this.state.keyword}
            onChange={this.onChange}
          />
          <img
            src={process.env.PUBLIC_URL + `/assets/images/icons/search.svg`}
            alt="icons"
            className="icon-search"
          />
        </Pane>
        <Scrollbars className="category-content" style={{ height: 300 }}>
          <ul className="category-list">
            <li className="category-item">
              <Link
                to={`/${this.props.type}`}
                className="category-link category-all"
              >
                <FormattedMessage id="all" defaultMessage="All" />
              </Link>
            </li>
            {this.state.filters.map((item) => {
              return (
                <StoreItem
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  type={this.props.type}
                  onLoad={this.props.onLoad}
                />
              );
            })}
          </ul>
        </Scrollbars>
      </>
    );
  }
}

export default injectIntl(StoreList);
