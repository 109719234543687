import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import Slider from 'react-slick';
import { Pane, Loading } from 'shared/components/layouts';
import BannerItem from './BannerItem';

class BannerInfo extends Component {
  renderList(products) {
    let productitems = [];

    return products.map((item, index) => {
      productitems.push(item);
      if ((index + 1) % 4 === 0) {
        const productitemsdraw = productitems;
        productitems = [];
        return <BannerItem items={productitemsdraw} key={'Product' + index} />;
      } else if (index === products.length - 1) {
        const productitemsdraw = productitems;
        productitems = [];
        return <BannerItem items={productitemsdraw} key={'Product' + index} />;
      }
      return null;
    });
  }

  renderListResponsive(products) {
    return products.map((item, index) => {
      return (
        <BannerItem type="responsive" items={item} key={'Product' + index} />
      );
    });
  }

  render() {
    const arrayData1 = [
      {
        id: 1,
        image: `https://api.cardbey.com/images/product/3002.aehDtkLZ3MAWp5z9l6e9urWjxWXTbLN3L4495m78.jpeg`,
        slug: 'sign-3002',
      },
      {
        id: 2,
        image: `https://api.cardbey.com/images/product/3019.eJ7uXheN45Qk4468u0lHKCMVc83pjIn6LoVU7J7G.png`,
        slug: '02-3019',
      },
      {
        id: 3,
        image: `https://api.cardbey.com/images/product/3018.YSA8iCv9qCufyVsXhpzx6aK8heWcW3dLJzCt26pH.jpeg`,
        slug: 'oranges-3018',
      },
    ];
    const arrayData2 = [
      {
        id: 1,
        image: `https://api.cardbey.com/images/product/3003.ApfqYbhEKoDXxuosVOTMDzC4SaMXZcHPrw5bVAwZ.jpeg`,
        slug: 'light-box-3003',
      },
      {
        id: 2,
        image: `https://api.cardbey.com/images/product/3000.3XrHswsRbEgZ5pKmaZWgHplYRxsJSk7MuNpgUTcI.jpeg`,
        slug:
          'clinical-fever-digital-laser-temperature-measurement-forehead-gun-hygrometer-baby-care-product-infrared-thermometer-3000',
      },
      {
        id: 3,
        image: `https://api.cardbey.com/images/product/3023.VY3s5cxhFC8zH4j7f9J9WmFrkPEqPIcGooSPgtf3.jpeg`,
        slug: 'designer-quality-photo-stock-3023',
      },
    ];
    const settings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Pane className="featured-container pt-4">
        <Pane className="container">
          <Pane className="featured-content">
            <Pane className="row">
              <Pane className="col-lg-12 col-md-12">
                <Slider className="shop-now-slider" {...settings}>
                  {!this.props.data.fetched && (
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  )}
                  {this.renderList(arrayData1)}
                  {this.renderList(arrayData2)}
                </Slider>
                <Slider className="shop-now-slider-responsive" {...settings}>
                  {!this.props.data.fetched && (
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  )}
                  {this.renderListResponsive(arrayData1)}
                  {this.renderListResponsive(arrayData2)}
                </Slider>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default BannerInfo;
