import { isNaN } from 'lodash';

const isNumberic = (x) => {
  return ((typeof x === 'number' || typeof x === 'string') && !isNaN(Number(x)));
};

const formatDecimal = (number, dec) => {
  return isNumberic(number) ? (dec ? Number(number).toFixed(dec).replace(/\d(?=(\d{3})+\.)/g, '$&,') : Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')) : '';
};

export default formatDecimal;
