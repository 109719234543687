import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';
import { Wrapper, Pane } from 'shared/components/layouts';
import Header from '../partials/HeaderLanding';
import Footer from '../partials/FooterLanding';

class MasterLayout extends Component {
  render() {
    const {
      match: { path },
    } = this.props;
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {})
    );
    if (
      path === '/signin' ||
      path === '/signup' ||
      path === '/forgot-password'
    ) {
      return (
        <Wrapper id="wrapper">
          <LoadingBar className="loading-top-bar" />
          <Header />
          <Pane as="main" className="main-container">
            {childrenWithProps}
          </Pane>
        </Wrapper>
      );
    }
    return (
      <Wrapper id="wrapper">
        <LoadingBar className="loading-top-bar" />
        <Header />
        <Pane as="main" className="main-container">
          {childrenWithProps}
        </Pane>
        <Footer />
      </Wrapper>
    );
  }
}
MasterLayout.propTypes = {};
MasterLayout.defaultProps = {};
export default withRouter(MasterLayout);
