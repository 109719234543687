import { get } from "lodash";
import ReactQuill from "react-quill";
import React, { Component } from "react";
import { Pane, Loading } from "shared/components/layouts";
import UserAdapter from "services/users/adapter";
import InlineMessage from "shared/components/InlineMessage";
import { formatRoute, handleError, quill } from "shared/helpers";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Button, Form, Image, Icon, Loader } from "semantic-ui-react";

class CreateProductForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  state = {
    images: [],
    imageFiles: [],
    data: {
      title: "",
      description: "",
    },
    errors: {},
    open: false,
    loading: false,
    fetched: false,
    fetching: true,
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSelect = (e, s) => {
    this.setState({
      data: { ...this.state.data, [s.name]: s.value },
    });
  };

  onChangeImage = (e) => {
    const files = e.target.files;
    if (files) {
      Object.keys(files).forEach((key, index) => {
        if (
          index < 6 &&
          this.state.images.length < 6 &&
          this.state.images.length + index < 6
        ) {
          const reader = new FileReader();
          const value = files[key];
          reader.onloadend = () => {
            const images = this.state.images;
            const imageFiles = this.state.imageFiles;

            images.push(reader.result);
            imageFiles.push(files[index]);

            this.setState({
              images,
              imageFiles,
            });
          };
          reader.readAsDataURL(value);
        }
      });
    }
  };

  removeImage = (index) => {
    const images = this.state.images;
    const imageFiles = this.state.imageFiles;

    images.splice(index, 1);
    imageFiles.splice(index, 1);

    this.setState({
      images,
      imageFiles,
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.title) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.description) {
      errors.description = intl.formatMessage({ id: "validate.require_field" });
    }
    return errors;
  };

  createProduct = () => {
    const errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      const data = new FormData();
      if (
        this.state.imageFiles.length &&
        this.state.imageFiles[0] &&
        this.state.imageFiles[0].type
      ) {
        data.append("video", this.state.imageFiles[0] || "");
      }
      data.append("title", this.state.data.title);
      data.append("description", this.state.data.description);
      data.append("id", this.props.id);

      this.props
        .editMyVideo(data)
        .then(() => {
          this.setState({
            loading: false,
            open: false,
            images: [],
            imageFiles: [],
            data: {
              ...this.state.data,
              name: "",
              categoryId: 1,
              description: "",
              price: "",
            },
          });
          this.props.history.push(
            `/u/${formatRoute(this.props.userInfo.displayName)}/my-videos`
          );
        })
        .catch((error) => {
          const errors = {};
          errors.message = handleError(
            error,
            this.props.intl.formatMessage({
              id: "notification.please_try_again",
            })
          );

          this.setState({
            loading: false,
            errors,
          });
        });
    }
  };

  componentDidMount() {
    const images = [];
    const imageFiles = [];
    this.setState({
      fetched: false,
      fetching: true,
    });
    this.props
      .getMyVideostDetail({
        id: this.props.id,
      })
      .then((response) => {
        const video = UserAdapter.videoRead.parseResponse(response.data);
        if (get(video, "video.url")) {
          images.push(get(video, "video.url"));
          imageFiles.push(get(video, "video.url"));
        }
        this.setState({
          images,
          imageFiles,
          data: {
            title: video.title || "",
            description: video.description || "",
          },
          fetched: true,
          fetching: false,
        });
      });
  }

  render() {
    const { errors, data, loading, fetched, fetching } = this.state;
    return (
      <Form className="form-overview pb-5" onSubmit={this.createProduct}>
        {fetched && !fetching ? (
          <>
            <Pane className="form-content">
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field className="form-item">
                    <label htmlFor="Image" className="form-label">
                      Video
                    </label>
                    {this.state.images.length === 0 && (
                      <>
                        <input
                          type="file"
                          id="image-menu"
                          className="form-input-file"
                          multiple
                          value={data.image}
                          onChange={this.onChangeImage}
                        />
                        <br />
                        <label htmlFor="image-menu" className="form-label-file">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/icons/upload.svg`
                            }
                            alt="upload"
                            className="icon"
                          />
                          Upload a video
                        </label>
                      </>
                    )}
                    <Pane
                      className="slider-content-main"
                      style={{ padding: "10px 0" }}
                    >
                      {this.state.images &&
                        this.state.images.map((item, index) => {
                          if (
                            item.indexOf("video") < 0 &&
                            item.indexOf("mp4") < 0
                          ) {
                            // Image
                            return (
                              <Pane
                                className="slide-item"
                                key={index}
                                style={{ float: "left" }}
                              >
                                <Icon
                                  name="remove circle"
                                  onClick={this.removeImage.bind(this, index)}
                                />
                                <Image src={item} size="small" />
                              </Pane>
                            );
                          }
                          // Video
                          return (
                            <Pane
                              className="slide-item"
                              key={index}
                              style={{ float: "left" }}
                            >
                              <Icon
                                name="remove circle"
                                onClick={this.removeImage.bind(this, index)}
                              />
                              <video width="300" height="300" controls>
                                <source src={item} id="video_here" />
                                Your browser does not support HTML5 video.
                              </video>
                            </Pane>
                          );
                        })}
                    </Pane>
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field error={!!errors.title} className="form-item">
                    <label htmlFor="email" className="form-label">
                      <FormattedMessage id="name" defaultMessage="Name" />
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      placeholder=""
                      className="form-input"
                      value={data.title}
                      onChange={this.onChange}
                    />
                    {errors.title && <InlineMessage text={errors.title} />}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  <Form.Field
                    error={!!errors.description}
                    className="form-item"
                  >
                    <label htmlFor="description" className="form-label">
                      <FormattedMessage
                        id="description"
                        defaultMessage="Description"
                      />
                    </label>
                    <ReactQuill
                      value={this.state.data.description}
                      onChange={(content) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            description: content,
                          },
                        }));
                      }}
                      formats={quill.formats}
                      modules={quill.modules}
                    />
                    {errors.description && (
                      <InlineMessage text={errors.description} />
                    )}
                  </Form.Field>
                </Pane>
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12">
                  {errors.message && <InlineMessage text={errors.message} />}
                </Pane>
              </Pane>
            </Pane>
            <Pane className="row">
              <Pane className="col-lg-12 text-center">
                <Button
                  type="submit"
                  className="btn btn-save mt-4"
                  disabled={loading}
                  loading={loading}
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </Pane>
            </Pane>
          </>
        ) : (
          <Pane className="pt30">
            <Loading />
          </Pane>
        )}
      </Form>
    );
  }
}

export default injectIntl(CreateProductForm);
