import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MasterLayout } from 'components/layout';
import { getAllProductPicks } from 'actions/products';
import { Pane } from 'shared/components/layouts';
import BestPick from 'components/scenes/best-picks/BestPicks';

class BestPicks extends Component {
  static propTypes = {};

  render() {
    return (
      <MasterLayout>
        <Pane className="main-container main-category">
          <Pane className="details-container pt-4 pb-4">
            <Pane className="container">
              <BestPick getAllProductPicks={this.props.getAllProductPicks} />
            </Pane>
          </Pane>
        </Pane>
      </MasterLayout>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
});

const bindDispatchToProps = (dispatch) => ({
  getAllProductPicks: bindActionCreators(getAllProductPicks, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(BestPicks);
