import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Pane, Loading } from 'shared/components/layouts';
import { Dimmer } from 'semantic-ui-react';
import NotificationSystem from 'react-notification-system';
import Modal from "react-responsive-modal";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  getSellingOrderDetail,
  acceptCancel,
  denyCancel,
  changeStatus,
  cancel
  ,
} from 'actions/orders';
import OrderSummary from './OrderSummary';
import OrderSubDetail from './OrderSubDetail';
import { orderUserCart } from 'actions/cart';
import { getNotisByUser } from 'actions/notis';
import OrderedCartList from './OrderedCartList';
import { formatDecimal, handleError } from "shared/helpers";
import { pushNotification } from 'actions/notification';
import { Button, TextArea, Icon } from "semantic-ui-react";
import Config from "constants/Config";
import { Breadcumb } from '../../../common/breadcumb';
import { formatRoute } from 'shared/helpers';

class SellingOrderDetail extends Component {
  static propTypes = {
    detail: PropTypes.object,
    getSellingOrderDetail: PropTypes.func,
    acceptCancel: PropTypes.func,
    cancel: PropTypes.func,
    changeStatus: PropTypes.func,
    pushNotification: PropTypes.func,
    userInfo: PropTypes.object,
    business: PropTypes.object,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      detail: {},
      loading: false,
      reason: "",
      errors: {},
      open: false,
      status: false,
      orderStatus: "",
      openReason: false,
      loadingStatus: false,
      openConfirmModal: false,
      loadingAcceptCancel: false,
      openCancelModal: false,
      reasonValidate: ""
    };
    this._notificationSystem = null;
  }

  getSellingOrderDetail = (load) => {
    const orderId = get(this.props.match, 'params.orderId');

    this.setState({ loading: load });
    this.props
      .getSellingOrderDetail({ id: orderId })
      .then((response) => {
        if (load && this.props.detail.updateRead) {
          this.props.getNotisByUser({ id: this.props.userInfo.id });
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  print = () => {
    const prtContent = document.getElementById("print-document");

    const WinPrint = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(prtContent.innerHTML);

    setTimeout(function () {
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    }, 500);
  };

  isDineIn = (detail) => {
    if (detail.cartItems) {
      const items = detail.cartItems.find(
        (item) =>
          item.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN ||
          item.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY
      );
      return !!items;
    }
    return false;
  };

  componentDidMount() {
    this.getSellingOrderDetail(true);
    this._notificationSystem = this.refs.notificationSystem;

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      this.props &&
      prevProps.match.params.orderId !== this.props.match.params.orderId
    ) {
      //fetchnewProduct and set state to reload
      this.getSellingOrderDetail(true);
    }
  }

  isOptions = (item) => {
    if (!item.options) return false;
    if (!item.options.length) return false;

    return true;
  };

  includesOption = (id, item) => {
    const { optionInfo } = item;
    if (!isEmpty(optionInfo)) {
      return optionInfo.find((child) => child.id === id);
    }
  };

  getSelectedOptions = (item) => {
    if (!this.isOptions(item)) return [];

    const { options } = item;
    let selectdOptions = [];

    if (!isEmpty(options)) {
      options.map((option) => {
        const { childrens } = option;
        childrens.forEach((child) => {
          if (this.includesOption(child.id, item)) {
            selectdOptions.push({
              ...child,
              quantity: this.includesOption(child.id, item).quantity,
            });
          }
        });
      });
    }

    return selectdOptions;
  };

  mappingSelectOptionToText = (item) => {
    const selectedOptions = this.getSelectedOptions(item);
    return selectedOptions
      .map(
        (child) =>
          `${Number(child.quantity) > 1 ? `${child.quantity}x` : ""} ${child.name
          }`
      )
      .join(", ");
  };

  acceptCancelFromBuyer = () => {
    const { intl, detail } = this.props;
    const orderId = this.props.match.params.orderId;
    const params = {
      id: orderId,
      reason: this.state.reason,
    };

    this.setState({ loadingAcceptCancel: true });
    this.onCloseModal();
    this.props
      .acceptCancel(params)
      .then(() => {
        this.setState({ loadingAcceptCancel: false });

        this.getSellingOrderDetail();

        // Push notification to buyer
        // this.props
        //   .pushNotification({
        //     object_id: orderId,
        //     object_type: "Order",
        //     target_id: detail.user.id,
        //     target_type: "Buyer",
        //     summary: `Your request cancel has been approved in Order #${orderId}`,
        //     meta: {
        //       order_type: "buying",
        //       order_id: orderId,
        //       url: `/u/${detail.user.name}/orders/${orderId}/buying/detail`,
        //     },
        //   })
        //   .catch();
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: "notification.successfully" }),
          level: "success",
        });
      })
      .catch((errors) => {
        this.setState({ loadingAcceptCancel: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };
  cancelFromSeller = () => {
    const { intl, detail } = this.props;
    const orderId = this.props.match.params.orderId;
    if (this.state.reason == '') {
      this.setState({ reasonValidate: 'Please input reason!' });
      return false;
    }
    const params = {
      id: orderId,
      reason: this.state.reason,
    };

    this.setState({ loadingAcceptCancel: true });
    this.onCloseCancelModal();
    this.props
      .cancel(params)
      .then(() => {
        this.setState({ loadingAcceptCancel: false });

        this.getSellingOrderDetail();
        this.setState({ reasonValidate: '' });
        // Push notification to buyer
        // this.props
        //   .pushNotification({
        //     object_id: orderId,
        //     object_type: "Order",
        //     target_id: detail.user.id,
        //     target_type: "Buyer",
        //     summary: `Your Order #${orderId}  been cancelled `,
        //     meta: {
        //       order_type: "buying",
        //       order_id: orderId,
        //       url: `/u/${detail.user.name}/orders/${orderId}/buying/detail`,
        //     },
        //   })
        //   .catch();
        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: "notification.successfully" }),
          level: "success",
        });
      })
      .catch((errors) => {
        this.setState({ loadingAcceptCancel: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  denyCancelFromBuyer = () => {
    const { intl, detail } = this.props;

    const orderId = this.props.match.params.orderId;
    const params = {
      id: orderId,
      reason: this.state.reason,
    };


    this.setState({ loadingAcceptCancel: true });
    this.onCloseModal();

    this.props
      .denyCancel(params)
      .then(() => {
        this.setState({ loadingAcceptCancel: false });

        this.getSellingOrderDetail();

        // Push notification to buyer
        // this.props
        //   .pushNotification({
        //     object_id: orderId,
        //     object_type: "Order",
        //     target_id: detail.user.id,
        //     target_type: "Buyer",
        //     summary: `Your request cancel has been denied in Order #${orderId}`,
        //     meta: {
        //       order_type: "buying",
        //       order_id: orderId,
        //       url: `/u/${detail.user.name}/orders/${orderId}/buying/detail`,
        //     },
        //   })
        //   .catch();

        this._notificationSystem.addNotification({
          message: intl.formatMessage({ id: "notification.successfully" }),
          level: "success",
        });
      })
      .catch((errors) => {
        this.setState({ loadingAcceptCancel: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  changeItemStatus = (status) => {
    const orderId = this.props.match.params.orderId;
    const params = {
      id: orderId,
      status,
    };
    const { intl, detail } = this.props;

    this.setState({ loadingStatus: true });
    this.onCloseConfirmModal();

    this.props
      .changeStatus(params)
      .then(() => {
        this.getSellingOrderDetail();
        // Push notification to buyer
        // this.props
        //   .pushNotification({
        //     object_id: orderId,
        //     object_type: "Order",
        //     target_id: detail.user.id,
        //     target_type: "Buyer",
        //     summary: `An item ${status.toLowerCase()} for your Order #${orderId}`,
        //     meta: {
        //       order_type: "buying",
        //       order_id: orderId,
        //       url: `/u/${detail.user.name}/orders/${orderId}/buying/detail`,
        //     },
        //   })
        //   .catch();

        this.setState({ loadingStatus: false });
      })
      .catch((errors) => {
        this.setState({ loadingStatus: false });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onOpenModal = (status) => {
    this.setState({
      open: true,
      reason: "",
      errors: {},
      status,
    });
  };

  onCloseModal = (status) => {
    this.setState({
      open: false,
      reason: "",
      errors: {},
      status,
    });
  };

  onOpenReasonModal = () => {
    this.setState({
      openReason: true,
    });
  };

  onCloseReasonModal = () => {
    this.setState({
      openReason: false,
    });
  };

  onOpenConfirmModal = (status) => {
    this.setState({
      openConfirmModal: true,
      orderStatus: status,
    });
  };
  onOpenCancelModal = (status) => {
    this.setState({
      openCancelModal: true,
    });
  };

  onCloseCancelModal = () => {
    this.setState({
      openCancelModal: false,
    });
  };
  onCloseConfirmModal = () => {
    this.setState({
      openConfirmModal: false,
    });
  };

  render() {
    const { detail, userInfo, intl } = this.props;
    const { loading } = this.state;
    const data = detail.cartItems && detail.cartItems.length > 0 && detail.cartItems[0];
    let breadcrumbs = [
      {
        url: `/u/${formatRoute(this.props.userInfo.displayName)}/orders`,
        name: 'Orders'
      },
      {
        url: ``, name: 'Order detail', active: true
      }
    ];
    return (
      <Pane className="overview-container">
        <Breadcumb breadcrumbs={breadcrumbs} />
        <Pane className="product-container order-container mb-3">
          <Pane className="d-flex justify-content-between align-items-center">
            <h3 className="block-title pt10 text-uppercase">
              <FormattedMessage
                id="selling_orders"
                defaultMessage="Selling orders"
              />
            </h3>
            <span style={{ cursor: "pointer" }} onClick={this.print}>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/icon-print.png"}
                alt=""
                width="40"
                style={{ height: '24px', objectFit: 'contain' }}
              />
              <span>
                <b>Print Receipt</b>
              </span>
            </span>
          </Pane>
          <Pane className="order-detail mt20" id="anchor">
            {!loading &&
              get(detail, 'id') && <OrderSummary detail={detail} />}
            <Pane className="products-grids table-order">
              <Pane className="card-body">
                {loading && (
                  <Dimmer active inverted>
                    <Loading />
                  </Dimmer>
                )}
                {!loading &&
                  get(detail, 'cartItems') && (
                    <OrderedCartList
                      detail={detail}
                      userInfo={this.props.userInfo}
                      orderUserCart={this.props.orderUserCart}
                    />
                  )}
              </Pane>
            </Pane>
          </Pane>
          <Pane className="order-detail">
            <h3 className="block-title card-body">
              <FormattedMessage
                id="order.detail"
                defaultMessage="Order details"
              />
            </h3>
            {loading && (
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            )}
            {!loading &&
              get(detail, 'id') && <OrderSubDetail detail={detail} />}
            {!loading &&
              !get(detail, 'id') && (
                <Pane className="no-detail" style={{ textAlign: 'center' }}>
                  <FormattedMessage
                    id="notification.cannot_get_order_details"
                    defaultMessage="Cannot get order details"
                  />
                </Pane>
              )}
          </Pane>
          {loading && (
            <Dimmer active inverted>
              <Loading />
            </Dimmer>
          )}
          {!loading && data && (
            <>
              {(data.deliveryMethod === Config.DELIVERY_METHOD.PICKUP || data.deliveryMethod === Config.DELIVERY_METHOD.DINE_IN || data.deliveryMethod === Config.DELIVERY_METHOD.TAKE_AWAY) ? (
                <>
                  <Pane className="change-status">
                    {data.cancelStatus !== "REQUESTED" &&
                      (data.status === Config.ORDER_STATUS.NEW ||
                        data.status === Config.ORDER_STATUS.PENDING ||
                        !data.status ? (
                        <Pane className="group-button">
                          <Button
                            className="btn-confirm"
                            size="mini"
                            color="orange"
                            onClick={this.onOpenConfirmModal.bind(
                              this,
                              Config.ORDER_STATUS.CONFIRMED
                            )}
                            loading={this.state.loadingStatus}
                            disabled={this.state.loadingStatus}
                          >
                            <FormattedMessage id="confirm" defaultMessage="Confirm" />
                          </Button>
                          <Button
                            size="mini"
                            color="orange"
                            className="mr5"
                            onClick={this.onOpenCancelModal.bind(this, true)}
                            loading={this.state.loadingAcceptCancel}
                            disabled={this.state.loadingAcceptCancel}
                          >
                            Cancel Order
                          </Button>
                        </Pane>
                      ) : data.status === Config.ORDER_STATUS.CONFIRMED ? (
                        <Pane className="group-button">
                          <Button
                            className="btn-confirm"
                            size="mini"
                            color="orange"
                            onClick={this.onOpenConfirmModal.bind(
                              this,
                              Config.ORDER_STATUS.DONE
                            )}
                            loading={this.state.loadingStatus}
                            disabled={this.state.loadingStatus}
                          >
                            <FormattedMessage
                              id="order.done"
                              defaultMessage="done"
                            />
                          </Button>
                        </Pane>
                      ) : null)}
                    <Modal
                      open={this.state.open}
                      onClose={this.onCloseModal}
                      center
                      showCloseIcon={false}
                    >
                      <Pane className="react-confirm-alert-body">
                        <h1>
                          <FormattedMessage
                            id="confirmation"
                            defaultMessage="Confirmation"
                          />
                        </h1>
                        <p>
                          {this.state.status
                            ? `${intl.formatMessage({
                              id:
                                "notification.are_you_sure_you_want_to_accept_this_request",
                            })}?`
                            : `${intl.formatMessage({
                              id:
                                "notification.are_you_sure_you_want_to_deny_this_request",
                            })}?`}
                        </p>
                        <TextArea
                          name="reason"
                          className="form-control"
                          value={this.state.reason}
                          placeholder="Your reason"
                          onChange={this.onChange}
                        />
                        {/* { errors.reason && <InlineMessage text={errors.reason} /> } */}
                        <Pane className="react-confirm-alert-button-group">
                          {this.state.status ? (
                            <button onClick={() => this.acceptCancelFromBuyer()}>
                              <FormattedMessage id="yes" defaultMessage="Yes" />
                            </button>
                          ) : (
                            <button onClick={() => this.denyCancelFromBuyer()}>
                              <FormattedMessage id="yes" defaultMessage="Yes" />
                            </button>
                          )}
                          <button onClick={this.onCloseModal}>
                            <FormattedMessage id="no" defaultMessage="No" />
                          </button>
                        </Pane>
                      </Pane>
                    </Modal>
                    <Modal
                      open={this.state.openConfirmModal}
                      onClose={this.onCloseConfirmModal}
                      center
                      showCloseIcon={false}
                    >
                      <Pane className="react-confirm-alert-body">
                        <h1>
                          <FormattedMessage
                            id="confirmation"
                            defaultMessage="Confirmation"
                          />
                        </h1>
                        <p>
                          <FormattedMessage
                            id="notification.are_you_sure_you_want_to_change_status"
                            defaultMessage="Are you sure you want to change status"
                          />
                          ?
                        </p>
                        <Pane className="react-confirm-alert-button-group">
                          <button
                            onClick={() =>
                              this.changeItemStatus(this.state.orderStatus)
                            }
                          >
                            <FormattedMessage id="yes" defaultMessage="Yes" />
                          </button>
                          <button onClick={this.onCloseConfirmModal}>
                            <FormattedMessage id="no" defaultMessage="No" />
                          </button>
                        </Pane>
                      </Pane>
                    </Modal>
                    <Modal
                      open={this.state.openCancelModal}
                      onClose={this.onCloseCancelModal}
                      center
                      showCloseIcon={false}
                    >
                      <Pane className="react-confirm-alert-body">
                        <h1>
                          <FormattedMessage
                            id="confirmation"
                            defaultMessage="Confirmation"
                          />
                        </h1>
                        <p>
                          Are you sure you want to cancel order?
                        </p>
                        <TextArea
                          name="reason"
                          className="form-control"
                          value={this.state.reason}
                          placeholder="Your reason"
                          onChange={this.onChange}
                        />
                        <label className='error'>{this.state.reasonValidate}</label>
                        <Pane className="react-confirm-alert-button-group">
                          <button
                            onClick={() =>
                              this.cancelFromSeller()
                            }
                          >
                            <FormattedMessage id="yes" defaultMessage="Yes" />
                          </button>
                          <button onClick={this.onCloseCancelModal}>
                            <FormattedMessage id="no" defaultMessage="No" />
                          </button>
                        </Pane>
                      </Pane>
                    </Modal>


                  </Pane>
                  <Pane className="change-status">
                    {data.cancelStatus === "REQUESTED" && (
                      <>
                        <p className="text-cancel">
                          *
                          <FormattedMessage
                            id="notification.this_item_is_in_cancellation_requesting"
                            defaultMessage="This item is in cancellation requesting"
                          />
                          .
                          <Icon
                            name="info circle"
                            onClick={this.onOpenReasonModal}
                            data-tip
                            data-for={`${data.id}`}
                          />
                        </p>
                        <ReactTooltip
                          place="top"
                          type="dark"
                          effect="float"
                          id={`${data.id}`}
                        >
                          <span>
                            <FormattedMessage
                              id="notification.click_to_view_detail"
                              defaultMessage="Click to view detail"
                            />
                          </span>
                        </ReactTooltip>
                        <Pane className="group-button">
                          <Button
                            size="mini"
                            color="orange"
                            className="mr5"
                            onClick={this.onOpenModal.bind(this, true)}
                            loading={this.state.loadingAcceptCancel}
                            disabled={this.state.loadingAcceptCancel}
                          >
                            <FormattedMessage id="accept" defaultMessage="Accept" />
                          </Button>
                          <Button
                            size="mini"
                            color="grey"
                            onClick={this.onOpenModal.bind(this, false)}
                            loading={this.state.loadingAcceptCancel}
                            disabled={this.state.loadingAcceptCancel}
                          >
                            <FormattedMessage id="deny" defaultMessage="Deny" />
                          </Button>
                        </Pane>
                      </>
                    )}
                    <Modal
                      open={this.state.openReason}
                      onClose={this.onCloseReasonModal}
                      center
                      showCloseIcon={false}
                    >
                      <Pane className="react-confirm-alert-body">
                        <h1>
                          <FormattedMessage id="reason" defaultMessage="Reason" />
                        </h1>
                        <p>{data.requestCancelReason}</p>
                        <Pane className="react-confirm-alert-button-group">
                          <button onClick={this.onCloseReasonModal}>
                            <FormattedMessage id="close" defaultMessage="Close" />
                          </button>
                        </Pane>
                      </Pane>
                    </Modal>
                  </Pane>
                </>
              ) : (
                <>
                  <Pane className="change-status">
                    {data.cancelStatus !== "REQUESTED" &&
                      (data.status === Config.ORDER_STATUS.NEW ||
                        data.status === Config.ORDER_STATUS.PENDING ||
                        !data.status ? (

                        <Pane className="group-button">
                          <Button
                            className="btn-confirm"
                            size="mini"
                            color="orange"
                            onClick={this.onOpenConfirmModal.bind(
                              this,
                              Config.ORDER_STATUS.CONFIRMED
                            )}
                            loading={this.state.loadingStatus}
                            disabled={this.state.loadingStatus}
                          >
                            <FormattedMessage id="confirm" defaultMessage="Confirm" />
                          </Button>
                          <Button
                            size="mini"
                            color="orange"
                            className="mr5"
                            onClick={this.onOpenCancelModal.bind(this, true)}
                            loading={this.state.loadingAcceptCancel}
                            disabled={this.state.loadingAcceptCancel}
                          >
                            Cancel Order
                          </Button>
                        </Pane>
                      ) : data.status === Config.ORDER_STATUS.CONFIRMED ? (
                        <Pane className="group-button">
                          <Button
                            className="btn-confirm"
                            size="mini"
                            color="orange"
                            onClick={this.onOpenConfirmModal.bind(
                              this,
                              Config.ORDER_STATUS.PREPARING
                            )}
                            loading={this.state.loadingStatus}
                            disabled={this.state.loadingStatus}
                          >
                            <FormattedMessage
                              id="order.preparing"
                              defaultMessage="Preparing"
                            />
                          </Button>
                        </Pane>
                      ) : data.status === Config.ORDER_STATUS.PREPARING ? (
                        <Pane className="group-button">
                          <Button
                            className="btn-confirm"
                            size="mini"
                            color="orange"
                            onClick={this.onOpenConfirmModal.bind(
                              this,
                              Config.ORDER_STATUS.DELIVERED
                            )}
                            loading={this.state.loadingStatus}
                            disabled={this.state.loadingStatus}
                          >
                            <FormattedMessage
                              id="order.delivered"
                              defaultMessage="Delivered"
                            />
                          </Button>
                        </Pane>
                      ) : data.status === Config.ORDER_STATUS.DELIVERED ? (
                        <Pane className="group-button">
                          <Button
                            className="btn-confirm"
                            size="mini"
                            color="orange"
                            onClick={this.onOpenConfirmModal.bind(
                              this,
                              Config.ORDER_STATUS.DONE
                            )}
                            loading={this.state.loadingStatus}
                            disabled={this.state.loadingStatus}
                          >
                            <FormattedMessage id="order.done" defaultMessage="Done" />
                          </Button>
                        </Pane>
                      ) : null)}
                    <Modal
                      open={this.state.openCancelModal}
                      onClose={this.onCloseCancelModal}
                      center
                      showCloseIcon={false}
                    >
                      <Pane className="react-confirm-alert-body">
                        <h1>
                          <FormattedMessage
                            id="confirmation"
                            defaultMessage="Confirmation"
                          />
                        </h1>
                        <p>
                          Are you sure you want to cancel order?
                        </p>
                        <TextArea
                          name="reason"
                          className="form-control"
                          value={this.state.reason}
                          placeholder="Your reason"
                          onChange={this.onChange}
                        />
                        <label className='error'>{this.state.reasonValidate}</label>
                        <Pane className="react-confirm-alert-button-group">
                          <button
                            onClick={() =>
                              this.cancelFromSeller()
                            }
                          >
                            <FormattedMessage id="yes" defaultMessage="Yes" />
                          </button>
                          <button onClick={this.onCloseCancelModal}>
                            <FormattedMessage id="no" defaultMessage="No" />
                          </button>
                        </Pane>
                      </Pane>
                    </Modal>
                    <Modal
                      open={this.state.open}
                      onClose={this.onCloseModal}
                      center
                      showCloseIcon={false}
                    >
                      <Pane className="react-confirm-alert-body">
                        <h1>
                          <FormattedMessage
                            id="confirmation"
                            defaultMessage="Confirmation"
                          />
                        </h1>
                        <p>
                          {this.state.status
                            ? `${intl.formatMessage({
                              id:
                                "notification.are_you_sure_you_want_to_accept_this_request",
                            })}?`
                            : `${intl.formatMessage({
                              id:
                                "notification.are_you_sure_you_want_to_deny_this_request",
                            })}?`}
                        </p>
                        <TextArea
                          name="reason"
                          className="form-control"
                          value={this.state.reason}
                          placeholder="Your reason"
                          onChange={this.onChange}
                        />
                        {/* { errors.reason && <InlineMessage text={errors.reason} /> } */}
                        <Pane className="react-confirm-alert-button-group">
                          {this.state.status ? (
                            <button onClick={() => this.acceptCancelFromBuyer()}>
                              <FormattedMessage id="yes" defaultMessage="Yes" />
                            </button>
                          ) : (
                            <button onClick={() => this.denyCancelFromBuyer()}>
                              <FormattedMessage id="yes" defaultMessage="Yes" />
                            </button>
                          )}
                          <button onClick={this.onCloseModal}>
                            <FormattedMessage id="no" defaultMessage="No" />
                          </button>
                        </Pane>
                      </Pane>
                    </Modal>
                    <Modal
                      open={this.state.openConfirmModal}
                      onClose={this.onCloseConfirmModal}
                      center
                      showCloseIcon={false}
                    >
                      <Pane className="react-confirm-alert-body">
                        <h1>
                          <FormattedMessage
                            id="confirmation"
                            defaultMessage="Confirmation"
                          />
                        </h1>
                        <p>
                          <FormattedMessage
                            id="notification.are_you_sure_you_want_to_change_status"
                            defaultMessage="Are you sure you want to change status"
                          />
                          ?
                        </p>
                        <Pane className="react-confirm-alert-button-group">
                          <button
                            onClick={() =>
                              this.changeItemStatus(this.state.orderStatus)
                            }
                          >
                            <FormattedMessage id="yes" defaultMessage="Yes" />
                          </button>
                          <button onClick={this.onCloseConfirmModal}>
                            <FormattedMessage id="no" defaultMessage="No" />
                          </button>
                        </Pane>
                      </Pane>
                    </Modal>
                  </Pane>
                  <Pane className="change-status">
                    {data.cancelStatus === "REQUESTED" && (
                      <>
                        <p>
                          <FormattedMessage
                            id="notification.this_item_is_in_cancellation_requesting"
                            defaultMessage="This item is in cancellation requesting"
                          />
                          .
                          <Icon
                            name="info circle"
                            onClick={this.onOpenReasonModal}
                            data-tip
                            data-for={`${data.id}`}
                          />
                        </p>
                        <ReactTooltip
                          place="top"
                          type="dark"
                          effect="float"
                          id={`${data.id}`}
                        >
                          <span>
                            <FormattedMessage
                              id="notification.click_to_view_detail"
                              defaultMessage="Click to view detail"
                            />
                          </span>
                        </ReactTooltip>
                        <Pane className="group-button">
                          <Button
                            size="mini"
                            color="orange"
                            className="mr5"
                            onClick={this.onOpenModal.bind(this, true)}
                            loading={this.state.loadingAcceptCancel}
                            disabled={this.state.loadingAcceptCancel}
                          >
                            <FormattedMessage id="accept" defaultMessage="Accept" />
                          </Button>
                          <Button
                            size="mini"
                            color="grey"
                            onClick={this.onOpenModal.bind(this, false)}
                            loading={this.state.loadingAcceptCancel}
                            disabled={this.state.loadingAcceptCancel}
                          >
                            <FormattedMessage id="deny" defaultMessage="Deny" />
                          </Button>
                        </Pane>
                      </>
                    )}
                    <Modal
                      open={this.state.openReason}
                      onClose={this.onCloseReasonModal}
                      center
                      showCloseIcon={false}
                    >
                      <Pane className="react-confirm-alert-body">
                        <h1>
                          <FormattedMessage id="reason" defaultMessage="Reason" />
                        </h1>
                        <p>{data.requestCancelReason}</p>
                        <Pane className="react-confirm-alert-button-group">
                          <button onClick={this.onCloseReasonModal}>
                            <FormattedMessage id="close" defaultMessage="Close" />
                          </button>
                        </Pane>
                      </Pane>
                    </Modal>
                  </Pane>
                </>
              )}
            </>
          )}
        </Pane>
        <Pane className="block-print" style={{display:" none"}}>
          <div id="print-document" style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica' }}>
            <h2 style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "center", paddingBottom: '0px', marginBottom: '0px' }}>{userInfo.business.name || ''}</h2>
            <h4 style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "center", paddingBottom: '0px', marginBottom: '0px' }}>{userInfo.business.address || ''}</h4>
            <div style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "center" }}>Tel: {userInfo.business.phone || ''}</div>
            <h2 style={{fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica'}}>Table: {detail.table && detail.table.name}</h2>
            <div style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "center" }}>*************************************************</div>
            <table style={{ width: "100%" }}>
              <tr>
                <th style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "left" }}>Order No: </th>
                <td style={{ tfontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', extAlign: "left", fontSize: "18px" }}>{detail.id}</td>
              </tr>
              <tr>
                <th style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "left" }}>Order date: </th>
                <td style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "left", fontSize: "18px" }}>{moment.utc(detail.orderDate).format("DD-MM-YYYY HH:mm")}</td>
              </tr>
            </table>
            <hr style={{ borderTop: "1px dashed" }} />
            <table style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "left", fontSize: "18px" }}>Name</th>
                  <th style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "left", fontSize: "18px" }}>Quantity</th>
                  <th style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "left", fontSize: "18px" }}>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {detail.cartItems &&
                  detail.cartItems.map((item, i) => (
                    <tr key={i}>
                      <td style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', fontSize: "18px" }}>
                        {item.name}
                        {this.isOptions(item) ? (
                          <Pane className="view-options">
                            <Pane
                              className="p-0"
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica'
                              }}
                            >
                              ({this.mappingSelectOptionToText(item)})
                            </Pane>
                          </Pane>
                        ) : null}</td>
                      <td style={{fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica'}}>{item.quantity}</td>
                      <td style={{fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica'}}>AUD ${formatDecimal(item.total)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <hr style={{ borderTop: "1px dashed" }} />
            <table style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', width: "100%" }}>
              <tr>
                <th style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "left", fontSize: "20px" }}>Total: </th>
                <td style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "right", fontSize: "20px" }}>AUD ${formatDecimal(detail.total)}</td>
              </tr>
              <tr>
                <th style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "left" }}><b>{detail.paymentMethod}</b></th>
              </tr>
            </table>
            <br />
            <div style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: "center" }}>*************************************************</div>
            <div style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: 'center' }}>THANK YOU FOR DINING WITH US!</div>
            <div style={{ fontFamily: '"Gill Sans", "Gill Sans MT", Helvetica', textAlign: 'center' }}>PLEASE COME AGAIN</div>
            {userInfo.qrcodeMenu && (
              <div style={{ textAlign: 'center' }}>
                <img style={{ width: '150px' }} src={userInfo.qrcodeMenu} alt="" />
              </div>
            )}
          </div>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  detail: state.sellingOrderDetail.data,
  business: state.auth.user.business,
});

const bindDispatchToProps = (dispatch) => ({
  denyCancel: bindActionCreators(denyCancel, dispatch),
  acceptCancel: bindActionCreators(acceptCancel, dispatch),
  cancel: bindActionCreators(cancel, dispatch),
  changeStatus: bindActionCreators(changeStatus, dispatch),
  orderUserCart: bindActionCreators(orderUserCart, dispatch),
  pushNotification: bindActionCreators(pushNotification, dispatch),
  getSellingOrderDetail: bindActionCreators(getSellingOrderDetail, dispatch),
  getNotisByUser: bindActionCreators(getNotisByUser, dispatch),
});

export default withRouter(injectIntl(connect(mapStateToProps, bindDispatchToProps)(SellingOrderDetail)));
