import * as types from 'constants/ActionTypes'
import api from 'api'

export const getReviews = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_REVIEWS, types.RECEIVE_REVIEWS_SUCCESS, types.RECEIVE_REVIEWS_FAILURE],
  payload: {
    request: api.reviews.get(params)
  }
})

export const getReviewsVideo = (params) => (dispatch) => dispatch({
  types: [types.REQUEST_REVIEWS, types.RECEIVE_REVIEWS_SUCCESS, types.RECEIVE_REVIEWS_FAILURE],
  payload: {
    request: api.reviews.getReviewsVideo(params)
  }
})

export const reviewsVideo = (params) => (dispatch) => dispatch({
  type: types.REVIEW_VIDEO,
  payload: {
    request: api.reviews.review(params)
  }
})

export const editReview = (params) => (dispatch) =>
  dispatch({
    types: types.EDIT_REVIEWS,
    payload: {
      request: api.reviews.editReview(params),
    },
  });
