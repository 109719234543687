import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';


class ChooseCheckoutModal extends Component {

	onSubmit = () => {
		this.props.onCloseModal();
		this.props.onOpenModalLogin();
	};

	render() {
		const {
			openModal,
			onCloseModal
		} = this.props;

		return (
			<Modal
				open={openModal}
				onClose={onCloseModal}
				center
				showCloseIcon={true}
			>
				<Pane className="login-container popup-login" style={{ minWidth: "350px" }}>
					<Pane className="modal-choose-checkout">
						<button
							className="btn btn-choose-checkout__login"
							onClick={() => this.onSubmit()}
						>
							<img
								src={
									process.env.PUBLIC_URL + `/assets/images/cart/lock.svg`
								}
								alt="lock"
								className="icon"
							/>
							<FormattedMessage
								id="already_account"
								defaultMessage="Already have an account? \n Login."
							/>
						</button>
						<span className="text-or">OR</span>
						<button
							className="btn btn-choose-checkout__guest"
							onClick={() => this.props.onGoToCheckout()}
						>
							<img
								onClick={this.onOpenReview}
								src={`${process.env.PUBLIC_URL
								}/assets/images/icons/guest.png`}
								alt="guest-icon"
							/>
							<FormattedMessage
								id="already_account1"
								defaultMessage="Checkout as guest"
							/>
						</button>
					</Pane>
				</Pane>
			</Modal>
		);
	}
}

export default withRouter(injectIntl(ChooseCheckoutModal));